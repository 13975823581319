import { SubtaskItemStatus } from '../constants';

const {
  PENDING,
  IN_PROGRESS,
  WAITING_FOR_SALES,
  WAITING_FOR_CUSTOMER,
  REJECT,
  CUSTOMER_REJECT,
  DONE,
  CLOSE,
  WAITING_FOR_NPD,
  APPROVE_BY_NPD,
} = SubtaskItemStatus;

const subtaskItemStatusRule = [
  {
    currentStatus: PENDING,
    nextStatus: [],
    subtaskTypeArtwork: { nextStatus: [IN_PROGRESS] },
    subtaskTypeProof: { nextStatus: [IN_PROGRESS] },
    subtaskTypeMockup: { nextStatus: [IN_PROGRESS] },
    subtaskTypeVerifyData: { nextStatus: [IN_PROGRESS] },
    isCommon: false,
  },
  {
    currentStatus: IN_PROGRESS,
    nextStatus: [],
    subtaskTypeArtwork: { nextStatus: [PENDING, WAITING_FOR_NPD] },
    subtaskTypeProof: { nextStatus: [PENDING, WAITING_FOR_CUSTOMER] },
    subtaskTypeMockup: { nextStatus: [PENDING, WAITING_FOR_CUSTOMER] },
    subtaskTypeVerifyData: { nextStatus: [PENDING, DONE] },
    isCommon: false,
  },
  {
    currentStatus: WAITING_FOR_NPD,
    nextStatus: [],
    subtaskTypeArtwork: { nextStatus: [IN_PROGRESS, APPROVE_BY_NPD] },
    subtaskTypeProof: { nextStatus: [] },
    subtaskTypeMockup: { nextStatus: [] },
    subtaskTypeVerifyData: { nextStatus: [] },
    isCommon: false,
  },
  {
    currentStatus: APPROVE_BY_NPD,
    nextStatus: [],
    subtaskTypeArtwork: { nextStatus: [WAITING_FOR_NPD, WAITING_FOR_CUSTOMER] },
    subtaskTypeProof: { nextStatus: [] },
    subtaskTypeMockup: { nextStatus: [] },
    subtaskTypeVerifyData: { nextStatus: [] },
    isCommon: false,
  },
  {
    currentStatus: WAITING_FOR_CUSTOMER,
    nextStatus: [],
    subtaskTypeArtwork: { nextStatus: [APPROVE_BY_NPD, DONE] },
    subtaskTypeProof: { nextStatus: [IN_PROGRESS, DONE] },
    subtaskTypeMockup: { nextStatus: [IN_PROGRESS, DONE] },
    subtaskTypeVerifyData: { nextStatus: [] },
    isCommon: false,
  },
  { currentStatus: PENDING, nextStatus: [CLOSE, IN_PROGRESS], isCommon: true },
  { currentStatus: IN_PROGRESS, nextStatus: [CLOSE, WAITING_FOR_SALES], isCommon: true },
  {
    currentStatus: WAITING_FOR_SALES,
    nextStatus: [IN_PROGRESS, REJECT, CLOSE, WAITING_FOR_CUSTOMER],
    isCommon: true,
  },
  {
    currentStatus: WAITING_FOR_CUSTOMER,
    nextStatus: [CUSTOMER_REJECT, CLOSE, DONE],
    isCommon: true,
  },
  {
    currentStatus: DONE,
    nextStatus: [],
    isCommon: false,
  },
  {
    currentStatus: REJECT,
    nextStatus: [IN_PROGRESS, CLOSE],
    isCommon: true,
  },
  {
    currentStatus: CUSTOMER_REJECT,
    nextStatus: [IN_PROGRESS, CLOSE],
    isCommon: true,
  },
  {
    currentStatus: DONE,
    nextStatus: [],
    isCommon: true,
  },
  {
    currentStatus: CLOSE,
    nextStatus: [],
    isCommon: true,
  },
];

export default subtaskItemStatusRule;
