import { Grid } from '@material-ui/core';
import React from 'react';
import { BlockListFilter } from '../../../state/block';
import { FilterContainer, SearchField } from '../../components';
import { IFilterContainerConfigProps } from '../../components/FilterContainer';

const BlockFilter: React.FC<IFilterContainerConfigProps<BlockListFilter>> = props => {
  return (
    <FilterContainer
      {...props}
      render={filterProps => {
        const { localFilter, handleChangeFilter } = filterProps;
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Block No"
                filterKey={'BlockNo.startsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Program"
                filterKey={'Program.Name.startsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Brand"
                filterKey={'Brand.Name.startsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Item"
                filterKey={'Item.startsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default BlockFilter;
