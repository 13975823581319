import { PageFilter } from '../state/app';
import {
  ProgramRequestItem,
  ProgramRequestItemListFilter,
} from '../state/programRequestItem';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/programRequestItems`;

export const fetchProgramRequestItemList = async ({
  filters,
  paging,
}: {
  filters: ProgramRequestItemListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchProgramRequestItem = async (id: ProgramRequestItem['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createProgramRequestItem = async (
  programRequestItem: ProgramRequestItem,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, programRequestItem);
};

export const updateProgramRequestItem = async (
  programRequestItem: ProgramRequestItem,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, programRequestItem);
};

export const deleteProgramRequestItem = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};
