import React from 'react';
import { IEBlockPattern } from '../../models/eBlockPattern';
import { fetchEBlockPatternList } from '../../services/eBlockPatternService';
import AsyncAutoComplete from './AsyncAutoComplete';

interface EBlockPatternComboBoxProps {
  handleChange: (result: IEBlockPattern) => void;
  selectedValue: IEBlockPattern;
  isDisabled?: boolean;
  error?: boolean;
  helperText?: string;
  eBlockGroupProductId: number;
}

const EBlockPatternComboBox: React.FC<EBlockPatternComboBoxProps> = props => {
  const {
    handleChange,
    selectedValue,
    isDisabled = false,
    error,
    helperText,
    eBlockGroupProductId,
  } = props;

  const eBlockPatternPromiseOptions = async (inputValue: string) => {
    const { data: rows } = await fetchEBlockPatternList({
      filters: {
        'CodeAndDesc.Contains': inputValue,
        'EBlockGroupProductId.EqualsTo': eBlockGroupProductId.toString(),
      },
      paging: { pageNumber: 0, pageSize: 10 },
    });
    return rows;
  };

  return (
    <AsyncAutoComplete
      name=""
      value={selectedValue}
      getOptionValue={(option: IEBlockPattern) => {
        return option.id!.toString();
      }}
      getOptionLabel={(option: IEBlockPattern) => {
        return `${option.code!}: ${option.description!}`;
      }}
      promiseOptions={eBlockPatternPromiseOptions}
      placeholder={''}
      handleChange={handleChange}
      isDisabled={isDisabled}
      error={error}
      helperText={helperText}
    />
  );
};

export default EBlockPatternComboBox;
