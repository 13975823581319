import React from 'react';
import { FilterContainer, SearchDropdownField, SearchField } from '../../components';
import { IFilterContainerConfigProps } from '../../components/FilterContainer';
import { ResponsibilityListFilter } from '../../../state/responsibility';
import { Grid, MenuItem } from '@material-ui/core';
import ResponsibilityStatus, {
  ResponsibilityStatusLabel,
} from '../../../constants/responsibilityStatus';

const ResponsibilityFilter: React.FC<
  IFilterContainerConfigProps<ResponsibilityListFilter>
> = (props) => {
  return (
    <FilterContainer
      {...props}
      render={(filterProps) => {
        const { localFilter, handleChangeFilter } = filterProps;
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Company.Name.StartsWith"
                label="Company"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Program.Name.StartsWith"
                label="Program"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="ResponsibilityBrand.Brand.Name.Contains"
                label="Brand"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="ResponsibilityCustomer.Customer.DisplayName.Contains"
                label="Customer"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="ResponsibilitySalesTeamMember.SalesTeamMember.SalesTeam.Name.contains"
                label="Sales Team"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="ResponsibilitySalesTeamMember.SalesTeamMember.User.FullNameWithUserName.contains"
                label="SSO"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="ResponsibilityWorkingTeamMember.SmWorkingTeamMember.User.FullNameWithUserName.contains"
                label="CS"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>

            <Grid item xs={12} md={2} lg={2}>
              <SearchDropdownField
                label="Status"
                filterKey={'Status.equalsTo'}
                filters={localFilter}
                onChange={handleChangeFilter}
                id="status"
              >
                <MenuItem value={''}>All</MenuItem>
                {Object.entries(ResponsibilityStatus).map(([key, value]) => {
                  return (
                    <MenuItem key={key} value={value}>
                      {ResponsibilityStatusLabel[value]}
                    </MenuItem>
                  );
                })}
              </SearchDropdownField>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default ResponsibilityFilter;
