import { Button, makeStyles } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import React from 'react';
import ProjectStatus from '../../../../constants/projectStatus';
import { Send as SendIcon } from '@material-ui/icons';
import { IProject } from '../../../../models/project.model';
import { PageTopMenuContainer } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  ProjectState,
  submitForApprovalProject,
  submitForUnlockApprovedProject,
} from '../../../../state/project';
import { useState } from 'react';
import ConfirmUnlockApprovedDialog from './ConfirmUnlockApprovedDialog';
import { AppState } from '../../../../state/configureStore';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#7cb342',
    '&:hover': {
      backgroundColor: '#689f38',
    },
    color: 'white',
  },
}));

const statusAllowForSubmitForApprovalProject = [
  ProjectStatus.PENDING_FOR_APPROVAL,
  ProjectStatus.REJECTED,
];

const statusAllowForSubmitForUnlockApprovedProject = [ProjectStatus.APPROVED];

interface ProjectFormActionMenuProps {
  project: IProject;
}

const ProjectFormActionMenu: React.FC<ProjectFormActionMenuProps> = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  //const { project } = props;

  const useProjectStore = () => {
    const { item: project } = useSelector<AppState, ProjectState>(
      (state) => state.project,
    );
    return {
      project,
    };
  };

  const { project } = useProjectStore();
  const approved = project.status === ProjectStatus.APPROVED;
  const isUpdateMode = !!project.id;
  const [openConfirmUnlockApprovedDialog, setOpenConfirmUnlockApprovedDialog] = useState(
    false,
  );

  return (
    <>
      <PageTopMenuContainer>
        {statusAllowForSubmitForApprovalProject.includes(project.status!) && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              dispatch(submitForApprovalProject(project.id));
            }}
            startIcon={<SendIcon />}
          >
            {`Submit for Approval`}
          </Button>
        )}
        {statusAllowForSubmitForUnlockApprovedProject.includes(project.status!) && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setOpenConfirmUnlockApprovedDialog(true);
            }}
            startIcon={<SendIcon />}
          >
            {`Submit for Unlock Approved`}
          </Button>
        )}

        <Button
          type={'submit'}
          className={classes.button}
          variant="contained"
          startIcon={<SaveIcon />}
          disabled={approved}
        >
          {!isUpdateMode ? `Create` : `Update`}
        </Button>
      </PageTopMenuContainer>
      <ConfirmUnlockApprovedDialog
        projectId={project.id!}
        open={openConfirmUnlockApprovedDialog}
        handleClose={() => {
          setOpenConfirmUnlockApprovedDialog(false);
        }}
      />
    </>
  );
};

export default ProjectFormActionMenu;
