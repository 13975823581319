import { AppAction, PageFilter } from '../app';
import { HanesProjectFilter } from './hanesProject.type';

export const FETCH_HANES_PROJECT_LIST = 'FETCH_HANES_PROJECT_LIST';

export const fetchHanesProjectList = (
  filters: HanesProjectFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_HANES_PROJECT_LIST,
    payload: {
      filters,
      paging,
    },
  };
};
