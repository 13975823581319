import React, { useState } from 'react';
import { SearchItemResult } from '../searchItemAndAddMultipleBlockDialog.reducer';
import { Link } from '@material-ui/core';
import ResolveNonQualifiedBlockDialog from './ResolveNonQualifiedBlockDialog';

interface ResolveNonQualifiedBlockButtonProps {
  searchItemResult: SearchItemResult;
  onAnswer: (answer: 'forceCreate' | 'ignore') => void;
}

const ResolveNonQualifiedBlockButton: React.FC<ResolveNonQualifiedBlockButtonProps> = props => {
  const { searchItemResult, onAnswer } = props;
  const [open, setOpen] = useState(false);

  return <>
    <Link
      onClick={() => setOpen(true)}
    >
      Review
    </Link>
    <ResolveNonQualifiedBlockDialog
      open={open}
      onClose={() => setOpen(false)}
      blocks={searchItemResult.blocks}
      onAnswer={(answer) => {
        onAnswer(answer);
        setOpen(false);
      }}
    />
  </>;
};

export default ResolveNonQualifiedBlockButton;
