import { Tooltip } from '@material-ui/core';
import { Check, HighlightOff, HourglassEmpty, Timer, Update } from '@material-ui/icons';
import React from 'react';

import { ProjectStatusLabel } from '../../../constants';

const projectStatusIcon = {
  [ProjectStatusLabel.PENDING_FOR_APPROVAL]: (
    <Tooltip title={ProjectStatusLabel.PENDING_FOR_APPROVAL}>
      <HourglassEmpty />
    </Tooltip>
  ),
  [ProjectStatusLabel.IN_REQUEST]: (
    <Tooltip title={ProjectStatusLabel.IN_REQUEST}>
      <Timer />
    </Tooltip>
  ),
  [ProjectStatusLabel.REJECTED]: (
    <Tooltip title={ProjectStatusLabel.REJECTED}>
      <HighlightOff />
    </Tooltip>
  ),
  [ProjectStatusLabel.APPROVED]: (
    <Tooltip title={ProjectStatusLabel.APPROVED}>
      <Check />
    </Tooltip>
  ),
  [ProjectStatusLabel.PENDING_FOR_UNLOCK_APPROVED]: (
    <Tooltip title={ProjectStatusLabel.PENDING_FOR_UNLOCK_APPROVED}>
      <Update />
    </Tooltip>
  ),
};

export default projectStatusIcon;
