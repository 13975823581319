import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import React from 'react';

interface EBlockArtTemplateAddDialogProps {
  open: boolean;
  onClose: (autoGenerateTask: boolean) => void;
}

const EBlockArtTemplateAddDialog: React.FC<EBlockArtTemplateAddDialogProps> = props => {
  const { open, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle>Do you want to auto generate task?</DialogTitle>

      <DialogActions>
        <Button
          variant={'contained'}
          color="secondary"
          onClick={() => {
            onClose(false);
          }}
        >
          NO
        </Button>
        <Button
          variant={'contained'}
          color="primary"
          onClick={() => {
            onClose(true);
          }}
        >
          YES
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EBlockArtTemplateAddDialog;
