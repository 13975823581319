import React from 'react';
import { fetchBlockList as fetchBlockListService } from '../../services/blockService';
import { Block } from '../../state/block';
import AsyncAutoComplete from './AsyncAutoComplete';

interface BlockComboBoxProps {
  handleChange: (result: Block) => void;
  selectedValue: Block;
  isDisabled?: boolean;
  programId?: number;
  brandId?: number;
  error?: boolean;
  helperText?: string;
  label?: string;
}

const BlockComboBox: React.FC<BlockComboBoxProps> = props => {
  const {
    handleChange,
    selectedValue,
    isDisabled = false,
    programId,
    brandId,
    error,
    helperText,
    label = 'Block No'
  } = props;

  const blockPromiseOptions = async (inputValue: string) => {
    const { data: rows } = await fetchBlockListService({
      filters: {
        'BlockNo.startsWith': inputValue,
        'ProgramId.equalsTo': programId,
        'BrandId.equalsTo': brandId,
      },
      paging: { pageNumber: 0, pageSize: 10 },
    });

    return rows;
  };

  return (
    <AsyncAutoComplete
      name="Block"
      value={selectedValue}
      getOptionValue={(option: Block) => {
        return option.id!.toString();
      }}
      getOptionLabel={(option: Block) => {
        return option.blockNo!;
      }}
      promiseOptions={blockPromiseOptions}
      placeholder={'Type Block Name'}
      handleChange={handleChange}
      isDisabled={isDisabled}
      error={error}
      helperText={helperText}
      label={label}
    />
  );
};

export default BlockComboBox;
