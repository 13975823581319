import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Form, Formik, FormikHelpers } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { ISubtask } from '../../../../models/subtask.model';
import { AppState } from '../../../../state/configureStore';
import {
  clearSubtaskItemSection,
  createSubtask,
  fetchSubtask,
  updateSubtask,
} from '../../../../state/subtask';
import { SubtaskState } from '../../../../state/subtask';
import { fetchSubtaskTypeList, SubtaskTypeState } from '../../../../state/subtaskType';
import { TaskState } from '../../../../state/task';
import { User } from '../../../../state/user';
import UserComboBox from '../../../components/UserComboBox';
import { AuthState } from '../../../../state/auth';
import { Close as CloseIcon, Save as SaveIcon } from '@material-ui/icons';
import { TransitionProps } from '@material-ui/core/transitions';

const SubtaskCreateSchema = Yup.object().shape({
  subject: Yup.string().required('Subject is required'),
  dueDate: Yup.string().required('Due Date is required'),
  smSubtaskAssignees: Yup.array()
    .of(
      Yup.object().shape({
        userId: Yup.number(),
      }),
    )
    .required('Assignee is required'),
});

interface SubtaskCreateDialogProps {
  open: boolean;
  handleClose: () => void;
  subtaskId: number | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    subtaskType: {
      marginLeft: theme.spacing(0),
      minWidth: 300,
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

const SubtaskCreateDialog: React.FC<SubtaskCreateDialogProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, handleClose, subtaskId } = props;
  const { item: task } = useSelector<AppState, TaskState>((state) => state.task);
  const { item: subtask } = useSelector<AppState, SubtaskState>((state) => state.subtask);
  const { items: subtaskType } = useSelector<AppState, SubtaskTypeState>(
    (state) => state.subtaskType,
  );
  const { userId } = useSelector<AppState, AuthState>((state) => state.auth);

  const initDueDate = moment(task && task.dueDate ? task.dueDate : undefined).format(
    'YYYY-MM-DD',
  );
  const {
    dueDate = initDueDate,
    subject = '',
    description = '',
    status,
    smSubtaskTypeId = 1,
    assignee,
    smSubtaskAssignees,
  } = subtask;
  const isUpdateMode = subtaskId !== null;

  const handleSubmit = (values: ISubtask, actions: FormikHelpers<ISubtask>) => {
    const valueWithSmTaskId = { ...values, smTaskId: task.id };

    if (isUpdateMode) {
      const updateValue = { ...valueWithSmTaskId, id: subtaskId as number };
      dispatch(updateSubtask(updateValue, handleClose));
    } else {
      const createValue = { ...valueWithSmTaskId, requesterId: userId };
      dispatch(createSubtask(createValue, handleClose));
    }
    actions.setSubmitting(false);
  };

  const handleCloseDialog = () => {
    handleClose();
    dispatch(clearSubtaskItemSection());
  };

  useEffect(() => {
    if (subtaskId) {
      dispatch(fetchSubtask(subtaskId));
    }
    dispatch(
      fetchSubtaskTypeList(
        { 'IsSpecialType.EqualsTo': 'false' },
        {
          pageNumber: 0,
          pageSize: 15,
        },
      ),
    );
  }, [dispatch, subtaskId]);

  useEffect(() => {
    if (open) {
      dispatch(
        fetchSubtaskTypeList(
          { 'IsSpecialType.EqualsTo': 'false' },
          {
            pageNumber: 0,
            pageSize: 15,
          },
        ),
      );
    }
  }, [open]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        subject,
        dueDate,
        description,
        status,
        smSubtaskTypeId,
        assignee,
        smSubtaskAssignees,
      }}
      validationSchema={SubtaskCreateSchema}
      onSubmit={handleSubmit}
      validateOnChange={false}
    >
      {({ values, handleChange, errors, submitForm, setFieldValue, resetForm }) => {
        return (
          <Form>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              onClose={handleCloseDialog}
              onExit={() => resetForm()}
              maxWidth="md"
              fullWidth={true}
            >
              <DialogTitle>Subtask Create</DialogTitle>
              <DialogContent>
                <TextField
                  name="subject"
                  type="input"
                  label="Subject"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={values.subject}
                  onChange={handleChange}
                  error={!!errors.subject}
                  helperText={errors.subject}
                />
                <TextField
                  name="description"
                  type="input"
                  label="Description"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={values.description}
                  onChange={handleChange}
                />

                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel>Subtask Type</InputLabel>
                  <Select
                    className={classes.subtaskType}
                    value={values.smSubtaskTypeId || ''}
                    onChange={handleChange}
                    inputProps={{ name: 'smSubtaskTypeId' }}
                  >
                    {subtaskType.map((item) => {
                      return (
                        <MenuItem key={item.id!} value={item.id!}>
                          {item.name!}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <UserComboBox
                  handleChange={(users: User[]) => {
                    setFieldValue('assignee', users ? users : null);
                    setFieldValue(
                      'smSubtaskAssignees',
                      users ? users.map((user) => ({ userId: user.id })) : 0,
                    );
                  }}
                  selectedValue={values.assignee as User[]}
                  error={!!errors.smSubtaskAssignees}
                  helperText={errors.smSubtaskAssignees}
                  label="Assignee"
                  isMulti
                />
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  id="date-picker-dialog"
                  label="Due Date"
                  format="yyyy-MM-dd"
                  value={values.dueDate}
                  onChange={(selectedDate, selectedStringDate) => {
                    setFieldValue('dueDate', selectedStringDate);
                  }}
                />
              </DialogContent>
              <DialogActions style={{ marginRight: '5px', marginBottom: '10px' }}>
                <Button
                  onClick={handleCloseDialog}
                  color="default"
                  variant="contained"
                  startIcon={<CloseIcon />}
                >
                  Close
                </Button>
                <Button
                  onClick={() => {
                    submitForm();
                  }}
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SubtaskCreateDialog;
