import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createOpportunityCustomer,
  deleteOpportunityCustomer,
  featOpporutnityCustomerIncludeMember,
  fetchOpportunityCustomerList,
  updateOpportunityCustomer,
} from '../../services/opportunityCustomerService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_OPPORTUNITY_CUSTOMER,
  CREATE_OPPORTUNITY_CUSTOMERS,
  DELETE_OPPORTUNITY_CUSTOMER,
  FETCH_OPPORTUNITY_CUSTOMER_LIST,
  fetchOpportunityCustomerListByOpportunityId,
  UPDATE_OPPORTUNITY_CUSTOMER,
  FETCH_OPPORTUNITY_CUSTOMER_INCLUDE_MEMBER_LIST,
  featOpporutnityCustomerIncludeMember as featOpporutnityCustomerIncludeMemberAction,
} from './opportunityCustomer.actions';

function* createOpportunityCustomerSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createOpportunityCustomer, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Added customer successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));

    yield put(fetchOpportunityCustomerListByOpportunityId(action.payload.opportunityId));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createOpportunityCustomersSaga(action: AppAction) {
  try {
    const { payload, callback } = action;
    yield put({ type: REQUEST(action.type) });

    for (const opCust of payload) {
      yield call(createOpportunityCustomer, opCust);
    }

    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Added customers successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));

    if (callback) {
      callback();
    }

    const opportunityId = payload[0].opportunityId;
    yield put(fetchOpportunityCustomerListByOpportunityId(opportunityId));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchOpportunityCustomerListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchOpportunityCustomerList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchOpportunityCustomerIncludeMemberListSaga(action: AppAction) {
  console.log('========>', action);
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(
      featOpporutnityCustomerIncludeMember,
      action.payload.opportunityId,
    );
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteOpportunityCustomerSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(deleteOpportunityCustomer, action.payload.id);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Deleted customer successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put(fetchOpportunityCustomerListByOpportunityId(action.payload.opportunityId));

    yield put(
      featOpporutnityCustomerIncludeMemberAction({
        opportunityId: action.payload.opportunityId,
      }),
    );
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateOpportunityCustomerSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateOpportunityCustomer, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Updated customer successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put(fetchOpportunityCustomerListByOpportunityId(data.opportunityId));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchOpportunityCustomer() {
  yield takeLatest(CREATE_OPPORTUNITY_CUSTOMER, createOpportunityCustomerSaga);
  yield takeLatest(CREATE_OPPORTUNITY_CUSTOMERS, createOpportunityCustomersSaga);
  yield debounce(250, FETCH_OPPORTUNITY_CUSTOMER_LIST, fetchOpportunityCustomerListSaga);
  yield takeLatest(DELETE_OPPORTUNITY_CUSTOMER, deleteOpportunityCustomerSaga);
  yield takeLatest(UPDATE_OPPORTUNITY_CUSTOMER, updateOpportunityCustomerSaga);
  yield debounce(
    250,
    FETCH_OPPORTUNITY_CUSTOMER_INCLUDE_MEMBER_LIST,
    fetchOpportunityCustomerIncludeMemberListSaga,
  );
}
