import {
  CardContent,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import {
  CheckBoxFastField,
  TextFieldFastField,
  UnitOfMeasurementsFastField,
  SubstrateComboBox,
} from '../../../components';
import { ISubstrate } from '../../../../models/substrate.model';
import { cloneDeep } from 'lodash';

interface SampleDevelopmentHlMaterialProps {
  setFieldValue: any;
  values: any;
}

const SampleDevelopmentHlMaterial: React.FC<SampleDevelopmentHlMaterialProps> = (
  props,
) => {
  const { setFieldValue, values } = props;
  const [options, setDefaultOptions] = useState([1]);

  const addOption = () => {
    const newOption = options.length + 1;
    setDefaultOptions([...options, newOption]);
  };
  useEffect(() => {
    const newOptions: number[] = [1];
    if (values.sampleDevelopmentHlSubstrates) {
      values.sampleDevelopmentHlSubstrates.forEach(
        (sampleDevelopmentHlSubstrate: ISubstrate) => {
          const substrateOption = sampleDevelopmentHlSubstrate.substrateOption!;
          if (!newOptions.includes(substrateOption)) {
            newOptions.push(substrateOption);
          }
        },
      );
      setDefaultOptions(newOptions);
    }
  }, [values.sampleDevelopmentHlSubstrates]);

  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Material</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>วัตถุดิบ(Substrate): </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextFieldFastField
                name="substrateOther"
                label="Substrate Other"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel id="primary-option">Primary Option</InputLabel>
                <Select
                  labelId="primary-option"
                  value={values.substratePrimaryOption}
                  onChange={(
                    event: React.ChangeEvent<{
                      value: unknown;
                    }>,
                  ) => {
                    setFieldValue('substratePrimaryOption', event.target.value as string);
                  }}
                >
                  {options.map((option, index) => {
                    return (
                      <MenuItem key={option} value={option}>
                        {index + 1}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={8}>
              {options.map((option, index) => {
                const groupByOptionSampleDevelopmentSubstrates =
                  values.sampleDevelopmentHlSubstrates
                    ? values.sampleDevelopmentHlSubstrates.filter(
                        (sampleDevelopmentHlSubstrate: ISubstrate) => {
                          return sampleDevelopmentHlSubstrate.substrateOption! === option;
                        },
                      )
                    : null;
                return (
                  <SubstrateComboBox
                    key={option}
                    isMulti
                    handleChange={(substrates: ISubstrate[]) => {
                      const cloneSampleDevelopmentHlSubstrates: ISubstrate[] = cloneDeep(
                        values.sampleDevelopmentHlSubstrates,
                      );

                      const sampleDevelopmentHlSubstratesWithNewValue =
                        values.sampleDevelopmentHlSubstrates
                          ? cloneSampleDevelopmentHlSubstrates.filter(
                              (cloneSampleDevelopmentHlSubstrate) => {
                                return (
                                  cloneSampleDevelopmentHlSubstrate.substrateOption !==
                                  option
                                );
                              },
                            )
                          : [];

                      if (substrates !== null) {
                        const substratesWithOption = substrates.map((substrate) => ({
                          ...substrate,
                          substrateOption: option,
                        }));
                        sampleDevelopmentHlSubstratesWithNewValue.push(
                          ...(substratesWithOption as any),
                        );
                      }

                      setFieldValue(
                        'sampleDevelopmentHlSubstrates',
                        sampleDevelopmentHlSubstratesWithNewValue,
                      );
                    }}
                    selectedValue={groupByOptionSampleDevelopmentSubstrates}
                    label={`Substrate ${index + 1}`}
                  />
                );
              })}
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={10}>
              <Button variant="contained" onClick={addOption}>
                Add Option
              </Button>
            </Grid>

            <Grid item xs={12} md={1}>
              <Typography>Size: </Typography>
            </Grid>
            <Grid item xs={12} md={1}>
              <TextFieldFastField
                type="number"
                step="any"
                name="labelDiWidth"
                label="W"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextFieldFastField
                type="number"
                step="any"
                name="labelDiLength"
                label="L"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1} />
            <Grid item xs={12} md={1}>
              <Typography>Finished Size: </Typography>
            </Grid>
            <Grid item xs={12} md={1}>
              <TextFieldFastField
                type="number"
                step="any"
                name="finishSizeWidth"
                label="W"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextFieldFastField
                type="number"
                step="any"
                name="finishSizeLength"
                label="L"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextFieldFastField
                type="number"
                step="any"
                name="finishSizeHigh"
                label="H"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <UnitOfMeasurementsFastField name="finishSizeUnit" label="Unit" />
            </Grid>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={1}>
              <Typography>Color: </Typography>
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="colorProcess" label="Process" />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="colorSolid" label="Solid" />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="colorCoated" label="Coated" />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="colorUncoated" label="Uncoated" />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="colorTextile" label="Textile" />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="colorFoil" label="Foil" />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="colorPastel" label="Pastel" />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="colorSolidProcess" label="Solid Process" />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="colorMetalic" label="Metalic" />
            </Grid>
            <Grid item xs={12} md={3} />

            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                name="frontColorQuantity"
                label="Front Color Quantity"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="frontColorProcess" label="Process" />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="frontColorSolid" label="Solid" />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextFieldFastField
                name="frontColorSolidData"
                label="Solid"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="frontColorPMS" label="PMS" />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="frontColorPMSData"
                label="PMS"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3} />

            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                name="backColorQuantity"
                label="Back Color Quantity"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="backColorProcess" label="Process" />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="backColorSolid" label="Solid" />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextFieldFastField
                name="backColorSolidData"
                label="Solid"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="backColorPT" label="PMS" />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="backColorPTData"
                label="PMS"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3} />
            <Grid item xs={12}>
              <TextFieldFastField
                name="colorSizeChart"
                label="Color Size Chart"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentHlMaterial;
