import { Box, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import {
  CheckBoxFastField,
  TextFieldFastField,
  UnitOfQuantitiesFastField,
  UnitOfPriceFastField,
} from '../../../components';

interface SampleDevelopmentTlMarketingProps {}

const SampleDevelopmentTlMarketing: React.FC<SampleDevelopmentTlMarketingProps> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Marketing</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>Level: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="topProductLevel" label="Top" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="intermediateProductLevel" label="intermediate" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="lowerProductLevel" label="low" />
            </Grid>
            <Grid item xs={12} md={4} />
            <Grid item xs={2}>
              <Typography>Quotation: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="withoutQuotation" label="No" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="quotation" label="Yes" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="quotationNumber"
                label="QN"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="flatPrice" label="Flat Price" />
            </Grid>
            <Grid item xs={12} md={12} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="progressiveRate" label="Progressive Rate" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                name="progressiveRateQty"
                label="Qty"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <UnitOfQuantitiesFastField
                name="progressiveRateQtyUnit"
                label="Unit"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                name="progressiveRatePrice"
                label="Price"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <UnitOfPriceFastField
                name="progressiveRatePriceUnit"
                label="Unit"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                name="forecast"
                label="Forecast"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={8} />
            <Grid item xs={12} md={2}>
              <Typography>Regulation: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="haveRegulation" label="Yes" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="withoutRegulation" label="No" />
            </Grid>
            <Grid item xs={12} md={6} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="regulationFreePVC" label="Free PVC" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="regulationFreePhalate" label="Free Phalate" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="regulationFreeEtc" label="Etc" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="regulationFreeEtcData"
                label="Etc"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="regulationStandardUS" label="US Standard" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="regulationStandardEU" label="EU Standard" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="regulationStandardEtc" label="Etc" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="regulationStandardEtcData"
                label="Etc"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentTlMarketing;
