import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    container: {
      padding: theme.spacing(1),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    filterContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      margin: theme.spacing(1),
    },
  }),
);

export default useStyles;
