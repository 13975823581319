import { DrawerListLink } from './drawer.types';

export const artworkDesignLinks: DrawerListLink[] = [
  {
    name: 'Tasks',
    nestedList: [
      {
        name: 'Task',
        path: '/tasks',
      },
      {
        name: 'Subtask List',
        path: '/tasks/subtasks',
      },
      {
        name: 'Subtask Item List',
        path: '/tasks/subtasks/subtaskItems',
      },
      {
        name: 'Task Item List',
        path: '/tasks/taskItems',
      },
    ],
  },
];
