import { ISampleDevelopmentHl } from '../../models/sampleDevelopmentHl.model';
import { AppAction, PageFilter } from '../app';
import { SampleDevelopmentHlListFilter } from './sampleDevelopmentHl.types';

export const FETCH_SAMPLE_DEVELOPMENT_HL_LIST = 'FETCH_SAMPLE_DEVELOPMENT_HL_LIST';
export const FETCH_SAMPLE_DEVELOPMENT_HL = 'FETCH_SAMPLE_DEVELOPMENT_HL';
export const CREATE_SAMPLE_DEVELOPMENT_HL = 'CREATE_SAMPLE_DEVELOPMENT_HL';
export const UPDATE_SAMPLE_DEVELOPMENT_HL = 'UPDATE_SAMPLE_DEVELOPMENT_HL';
export const CLEAR_SAMPLE_DEVELOPMENT_HL = 'CLEAR_SAMPLE_DEVELOPMENT_HL';
export const SUBMIT_SAMPLE_DEVELOPMENT_HL_FOR_APPROVAL =
  'SUBMIT_SAMPLE_DEVELOPMENT_HL_FOR_APPROVAL';
export const DOWNLOAD_SAMPLE_DEVELOPMENT_HL = 'DOWNLOAD_SAMPLE_DEVELOPMENT_HL';

export const fetchSampleDevelopmentHlList = (
  filters: SampleDevelopmentHlListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SAMPLE_DEVELOPMENT_HL_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const downloadSampleDevelopmentHl = (id: number): AppAction => {
  return {
    type: DOWNLOAD_SAMPLE_DEVELOPMENT_HL,
    payload: id,
  };
};

export const fetchSampleDevelopmentHl = (id: number): AppAction => ({
  type: FETCH_SAMPLE_DEVELOPMENT_HL,
  payload: id,
});

export const createSampleDevelopmentHl = (payload: {
  values: ISampleDevelopmentHl;
}): AppAction => ({
  type: CREATE_SAMPLE_DEVELOPMENT_HL,
  payload,
});

export const updateSampleDevelopmentHl = (payload: {
  values: ISampleDevelopmentHl;
}): AppAction => ({
  type: UPDATE_SAMPLE_DEVELOPMENT_HL,
  payload,
});

export const clearSampleDevelopmentHl = () => ({
  type: CLEAR_SAMPLE_DEVELOPMENT_HL,
});

export const submitSampleDevelopmentHlForApproval = (id: number): AppAction => ({
  type: SUBMIT_SAMPLE_DEVELOPMENT_HL_FOR_APPROVAL,
  payload: id,
});
