import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  downloadCheckAccountOwnerReport,
  ICheckAccountOwnerCriteria,
} from '../../../../state/report';

interface ICheckAccountOwnerDlg {
  open: boolean;
  handleClose: () => void;
}

const CheckAccountOwnerDlg: React.FC<ICheckAccountOwnerDlg> = props => {
  const dispatch = useDispatch();
  const { open, handleClose } = props;
  const date = new Date();
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 2);
  const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, +1);

  const initCriteria = {
    'CreatedAt.GreaterThanOrEqual': firstDayOfMonth.toISOString().split('T')[0],
    'CreatedAt.LessThanOrEqual': lastDayOfMonth.toISOString().split('T')[0],
    'Program.Name.StartsWith': '',
    'Brand.Name.StartsWith': '',
  };

  const [criteria, setCriteria] = useState<ICheckAccountOwnerCriteria>(initCriteria);

  const handleClickOK = () => {
    dispatch(downloadCheckAccountOwnerReport(criteria));
    setCriteria(initCriteria);
    handleClose();
  };

  const handleClickCancel = () => {
    setCriteria(initCriteria);
    handleClose();
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Check Account Owner Criteria</DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <KeyboardDatePicker
            showTodayButton={true}
            fullWidth={true}
            margin="normal"
            label="From Created Date"
            format="yyyy-MM-dd"
            value={criteria['CreatedAt.GreaterThanOrEqual']}
            onChange={(selectedDate: Date | null) => {
              const newCriteria = {
                ...criteria,
                'CreatedAt.GreaterThanOrEqual': selectedDate!.toISOString().split('T')[0],
              };
              setCriteria(newCriteria);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <KeyboardDatePicker
            showTodayButton={true}
            fullWidth={true}
            margin="normal"
            label="To Created Date"
            format="yyyy-MM-dd"
            value={criteria['CreatedAt.LessThanOrEqual']}
            onChange={(selectedDate: Date | null) => {
              const newCriteria = {
                ...criteria,
                'CreatedAt.LessThanOrEqual': selectedDate!.toISOString().split('T')[0],
              };
              setCriteria(newCriteria);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="Program"
            type="input"
            label="Program"
            variant="outlined"
            margin="dense"
            fullWidth
            value={criteria['Program.Name.StartsWith']}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newCriteria = {
                ...criteria,
                'Program.Name.StartsWith': event.target.value.toUpperCase(),
              };
              setCriteria(newCriteria);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="Brand"
            type="input"
            label="Brand"
            variant="outlined"
            margin="dense"
            fullWidth
            value={criteria['Brand.Name.StartsWith']}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newCriteria = {
                ...criteria,
                'Brand.Name.StartsWith': event.target.value.toUpperCase(),
              };
              setCriteria(newCriteria);
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClickCancel}>
          CANCEL
        </Button>
        <Button variant="contained" color="primary" onClick={handleClickOK}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CheckAccountOwnerDlg;
