import { saveAs } from 'file-saver';
import { IResponsibility } from '../models/responsibility.model';
import { PageFilter, ExcelPagingFilter } from '../state/app';
import { ResponsibilityListFilter } from '../state/responsibility';
import { buildUrlSearchParams, buildUrlExcelSearchParams } from '../utils/apiRequestUtil';
import axios, { getResponseFileName } from '../utils/axios';
import { generateCacheBusterParamString } from './cacheBusterService';
import { IResponsibilityImportData } from '../views/pages/responsibility/responsibilityImportDialog/responsibilityImportColumnDefinition';

const baseUrl = `/api/responsibilities`;

export const createResponsibility = async (responsibility: IResponsibility) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, responsibility);
};

export const updateResponsibility = async (responsibility: IResponsibility) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, responsibility);
};

export const deleteResponsibility = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};

export const fetchResponsibility = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const fetchResponsibilityList = async ({
  filters,
  paging,
}: {
  filters: ResponsibilityListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const exportResponsibilityFilterAsExcel = async (
  filters: ResponsibilityListFilter,
) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  const response = await axios.get(`${requestUrl}/export/excel?${params.toString()}`, {
    responseType: 'blob',
  });
  const fileName = getResponseFileName(response);
  saveAs(response.data, fileName);
};

export const downloadResponsibilityFilterAsExcel = async (
  filters: ResponsibilityListFilter,
  paging: ExcelPagingFilter,
) => {
  const params = buildUrlExcelSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/export`;
  const response = await axios.get(`${requestUrl}?${params.toString()}`, {
    responseType: 'blob',
  });
  const fileName = getResponseFileName(response);
  saveAs(response.data, fileName);
};

export const downloadResponsibilityFilterAsImportTemplate = async (
  filters: ResponsibilityListFilter,
  paging: ExcelPagingFilter,
) => {
  const params = buildUrlExcelSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/exportAsImportResponsibilityTemplate`;
  const response = await axios.get(`${requestUrl}?${params.toString()}`, {
    responseType: 'blob',
  });
  const fileName = getResponseFileName(response);
  saveAs(response.data, fileName);
};

export const downloadResponsibilityTemplateExcel = async () => {
  const response = await axios.get(
    `${baseUrl}/template?${generateCacheBusterParamString()}`,
    {
      responseType: 'blob',
    },
  );
  const fileName = getResponseFileName(response);
  saveAs(response.data, fileName);
};

export const getCurrentImportTemplateVersion = async () => {
  return await axios.get(
    `${baseUrl}/templateVersion?${generateCacheBusterParamString()}`,
  );
};

export const createOrUpdateWithRowTemplate = async (data: IResponsibilityImportData) => {
  return await axios.post(`${baseUrl}/createOrUpdateWithRowTemplate`, data);
};

export const publishMessage = async (respIds: number[]) => {
  return await axios.post(`${baseUrl}/publish`, respIds);
};

export const submitForApprovalResponsibility = async (id: IResponsibility['id']) => {
  const requestUrl = `${baseUrl}/${id}/submitForApproval`;
  return await axios.post(requestUrl);
};

export const submitForUnlockApprovedResponsibility = async (
  id: IResponsibility['id'],
  description: string,
) => {
  const requestUrl = `${baseUrl}/${id}/submitForUnlockApproved`;
  return await axios.post(requestUrl, { description });
};
