import { CardMedia, Grid, Typography } from '@material-ui/core';
import React from 'react';
import labelDimensionImg from './images/labelDimension.png';
import useStyles from './sampleDevelopmentNl.style';
import {
  TextFieldFastField,
  UnitOfMeasurementsFastField,
  UnitOfQuantitiesFastField,
  CheckBoxFastField,
} from '../../../components';

interface SamDevNlSpecLabelDiProps {}

const SampleDevelopmentNlSpecLabelDimension: React.FC<SamDevNlSpecLabelDiProps> = (
  props,
) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} direction="row" justifyContent="flex-start">
      <Typography className={classes.numberTitle} display="inline">
        1. Label Dimension
      </Typography>
      <Grid item xs={12} md={4}>
        <CardMedia
          className={classes.labelDimensionImg}
          image={labelDimensionImg}
          title="Label Dimension"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={12} md={4}>
            <TextFieldFastField
              type="number"
              step="any"
              className={classes.textInput}
              name="labelDiWidth"
              label="Sizing Width"
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextFieldFastField
              type="number"
              step="any"
              className={classes.textInput}
              name="labelDiLength"
              label="Sizing Length"
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <UnitOfMeasurementsFastField name="labelDiUnit" label="Unit" />
          </Grid>

          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} md={2}>
              <Typography display="inline">Dimension</Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <CheckBoxFastField name="dimensionRequired" label="Required กำหนด" />
            </Grid>
            <Grid item xs={12} md={5}>
              <CheckBoxFastField
                name="noDimensionRequired"
                label="Not Required ไม่กำหนด"
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} md={4}>
              <TextFieldFastField
                className={classes.textInput}
                type="number"
                step="any"
                name="gapCD"
                label="CD"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldFastField
                type="number"
                step="any"
                className={classes.textInput}
                name="gapMD"
                label="MD"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <UnitOfMeasurementsFastField name="gapUnit" label="Unit" />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} md={8}>
              <TextFieldFastField
                type="number"
                step="any"
                className={classes.textInput}
                name="noCD"
                label="No. CD"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={1} md={4}>
              <UnitOfQuantitiesFastField name="noCDUnit" label="Unit" />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} md={8}>
              <TextFieldFastField
                type="number"
                step="any"
                className={classes.textInput}
                name="minGapCD"
                label="Min Gap CD"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={1} md={4}>
              <UnitOfMeasurementsFastField name="minGapCDUnit" label="Unit" />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} md={8}>
              <TextFieldFastField
                type="number"
                step="any"
                className={classes.textInput}
                name="minGapMD"
                label="Min Gap MD"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={1} md={4}>
              <UnitOfMeasurementsFastField name="minGapMDUnit" label="Unit" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SampleDevelopmentNlSpecLabelDimension;
