import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_EBLOCK_SIZE,
  CREATE_EBLOCK_SIZE,
  FETCH_EBLOCK_SIZE,
  FETCH_EBLOCK_SIZE_LIST,
  UPDATE_EBLOCK_SIZE,
} from './eBlockSize.action';
import { EBlockSizeState } from './eBlockSize.type';
import { IEBlockSize } from '../../models/eBlockSize';

const initialState: EBlockSizeState = {
  errorMessage: '',
  item: {} as Readonly<IEBlockSize>,
  items: [] as ReadonlyArray<IEBlockSize>,
  loading: false,
  totalItems: 0,
};

export default (
  state: EBlockSizeState = initialState,
  action: AppAction,
): EBlockSizeState => {
  switch (action.type) {
    case REQUEST(FETCH_EBLOCK_SIZE_LIST):
    case REQUEST(CREATE_EBLOCK_SIZE):
    case REQUEST(FETCH_EBLOCK_SIZE):
    case REQUEST(UPDATE_EBLOCK_SIZE):
      return { ...state, loading: true };
    case SUCCESS(FETCH_EBLOCK_SIZE_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_EBLOCK_SIZE_LIST):
    case FAILURE(CREATE_EBLOCK_SIZE):
    case FAILURE(FETCH_EBLOCK_SIZE):
    case FAILURE(UPDATE_EBLOCK_SIZE):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_EBLOCK_SIZE):
    case SUCCESS(FETCH_EBLOCK_SIZE):
    case SUCCESS(UPDATE_EBLOCK_SIZE):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_EBLOCK_SIZE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
