import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createEBlockMaterial,
  fetchEBlockMaterial,
  fetchEBlockMaterialList,
  updateEBlockMaterial,
} from '../../services/eBlockMaterialService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CLEAR_EBLOCK_MATERIAL,
  CREATE_EBLOCK_MATERIAL,
  FETCH_EBLOCK_MATERIAL,
  FETCH_EBLOCK_MATERIAL_LIST,
  UPDATE_EBLOCK_MATERIAL,
} from './eBlockMaterial.action';
import { EBlockMaterialCreateOrUpdatePayload } from './eBlockMaterial.type';

const listPageUrl = `/settings/eblockMaterials`;

function* fetchEBlockMaterialListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchEBlockMaterialList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchEBlockMaterialSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchEBlockMaterial, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createEBlockMaterialSaga(action: AppAction) {
  try {
    const { values, history } = action.payload as EBlockMaterialCreateOrUpdatePayload;
    const successMessage = `Created ${values.code} eblock material successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createEBlockMaterial, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    const formUrl = `${listPageUrl}/form/${data.id}`;
    history.replace(formUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateEBlockMaterialSaga(action: AppAction) {
  try {
    const { values } = action.payload as EBlockMaterialCreateOrUpdatePayload;
    const successMessage = `Updated ${values.code} eblock material successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateEBlockMaterial, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchEBlockMaterial() {
  yield debounce(250, FETCH_EBLOCK_MATERIAL_LIST, fetchEBlockMaterialListSaga);
  yield takeLatest(FETCH_EBLOCK_MATERIAL, fetchEBlockMaterialSaga);
  yield takeLatest(CREATE_EBLOCK_MATERIAL, createEBlockMaterialSaga);
  yield takeLatest(UPDATE_EBLOCK_MATERIAL, updateEBlockMaterialSaga);
}
