import { AppAction } from '../app';

export const SYNC_USER_FROM_LDAP = 'SYNC_USER_FROM_LDAP';
export const CLEAR_ADMIN = 'CLEAR_ADMIN';

export const syncUserFromLdap = (): AppAction => ({
  type: SYNC_USER_FROM_LDAP,
});

export const clearAdmin = (): AppAction => ({
  type: CLEAR_ADMIN,
});
