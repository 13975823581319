import { Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../state/configureStore';
import { NotificationMessage, NotificationState } from './Notification.types';
import NotificationContentWrapper from './NotificationContentWrapper';
import { useHistory } from 'react-router-dom';
import { clearNotificationMessage } from './notification.action';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

interface TopSnackBarProps extends NotificationMessage {}

const TopSnackbar: React.FC<TopSnackBarProps> = (props) => {
  const [open, setOpen] = useState(true);
  const { message, type } = props;
  const classes = useStyles(props);

  function handleCloseSnackBar(
    event: React.SyntheticEvent<any, Event>,
    reason: string,
  ): void {
    if (reason !== 'clickaway') {
      setOpen(false);
    }
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      onClose={handleCloseSnackBar}
      autoHideDuration={3000}
    >
      <NotificationContentWrapper
        variant={type}
        className={classes.margin}
        onClose={handleCloseSnackBar}
        message={message}
      />
    </Snackbar>
  );
};

const Notification: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { messages } = useSelector<AppState, NotificationState>(
    (state) => state.notification,
  );

  const locationPath = useMemo(() => {
    const pathname = history.location.pathname;
    return pathname.split('/')[1];
  }, [history.location.pathname]);

  useEffect(() => {
    dispatch(clearNotificationMessage());
  }, [dispatch, locationPath]);

  return (
    <div>
      {messages
        .map((v, i) => {
          return { ...v, messageID: i };
        })
        .map(({ type, message, messageID }) => {
          return <TopSnackbar type={type} message={message} key={messageID} />;
        })}
    </div>
  );
};

export default Notification;
