import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearPendingSubtaskList,
  fetchPendingSubtaskList,
} from './pendingTaskList.actions';
import { PageFilter } from '../../../../state/app';
import { AppState } from '../../../../state/configureStore';
import { PendingTaskListState } from './pendingTaskList.reducer';
import {
  Avatar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { TableLoading, UserLetterAvatar } from '../../../components';
import { DateDisplay, DateTimeDisplay } from '../../../components/DateTimeDisplay';
import useStyles from './pendingTaskList.styles';
import TaskNumberLinkButton from '../../../components/TaskNumberLinkButton';
import Loading from '../../../components/AwLoading';
import { TablePageSize } from '../../../../constants';
import { fetchSubtaskStatus } from '../../../../state/subtask/subtask.actions';
import { SubtaskListFilter } from '../../../../state/subtask';
import { useFilter } from '../../../hooks';
import { AvatarGroup } from '@material-ui/lab';

const PendingTaskList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 10,
    sort: { orderBy: 'createdAt', direction: 'asc' },
  });

  const {
    items: subtasks,
    totalItems,
    loading,
  } = useSelector<AppState, PendingTaskListState>((state) => state.pendingTaskList);

  useEffect(() => {
    dispatch(fetchPendingSubtaskList(paging));
    return function cleanup() {
      dispatch(clearPendingSubtaskList());
    };
  }, [dispatch, paging]);

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  return (
    <Paper className={classes.root} elevation={2}>
      <Typography variant="h6" className={classes.title}>
        Pending Sub Tasks
      </Typography>
      <Loading loading={loading} height={800}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Task Number</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Requester</TableCell>
              <TableCell>Assignee</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Overdue (Days)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subtasks.map((subtask, index) => {
              const assigneeUsers = subtask.smSubtaskAssignees
                ?.map((u) => u.user.fullName)
                .join(',  ');
              return (
                <TableRow key={subtask.id}>
                  <TableCell>
                    <TaskNumberLinkButton
                      task={subtask.smTask}
                      smSubtaskId={subtask.id}
                    />
                  </TableCell>
                  <TableCell>
                    {subtask.subject?.length! > 25
                      ? `${subtask.subject?.substring(0, 25)}...`
                      : subtask.subject}
                  </TableCell>
                  <TableCell>
                    {subtask.smSubtaskType ? subtask.smSubtaskType.name : ''}
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        <Typography variant="body2">
                          {subtask.smTask?.requester?.fullName}
                        </Typography>
                      }
                    >
                      <Avatar>
                        {subtask.smTask?.requester?.firstName?.substring(0, 1)}
                      </Avatar>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={<Typography variant="body2">{assigneeUsers}</Typography>}
                    >
                      <AvatarGroup max={2}>
                        {subtask.smSubtaskAssignees?.map((assignee) => {
                          return (
                            <Avatar>{assignee.user.firstName?.substring(0, 1)}</Avatar>
                          );
                        })}
                      </AvatarGroup>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <DateDisplay value={subtask.dueDate} />
                  </TableCell>
                  <TableCell>
                    <DateDisplay value={subtask.createdAt} />
                    {/* <DateTimeDisplay value={subtask.createdAt} /> */}
                  </TableCell>
                  <TableCell>{subtask.overdue}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[...TablePageSize]}
          component="div"
          count={totalItems}
          rowsPerPage={paging.pageSize}
          page={paging.pageNumber}
          onPageChange={handleChangePageNumber}
          onRowsPerPageChange={handleChangePageSize}
        />
      </Loading>
    </Paper>
  );
};

export default PendingTaskList;
