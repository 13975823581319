import { AppAction, PageFilter } from '../app';
import {
  WorkingTeam,
  WorkingTeamCreateOrUpdatePayload,
  WorkingTeamListFilter,
} from './workingTeam.type';

export const FETCH_WORKING_TEAM = 'FETCH_WORKING_TEAM';
export const FETCH_WORKING_TEAM_LIST = 'FETCH_WORKING_TEAM_LIST';
export const CREATE_WORKING_TEAM = 'CREATE_WORKING_TEAM';
export const UPDATE_WORKING_TEAM = 'UPDATE_WORKING_TEAM';
export const CLEAR_WORKING_TEAM = 'CLEAR_WORKING_TEAM';

export const fetchWorkingTeamList = (
  filters: WorkingTeamListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_WORKING_TEAM_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchWorkingTeam = (id: WorkingTeam['id']): AppAction => ({
  type: FETCH_WORKING_TEAM,
  payload: id,
});

export const createWorkingTeam = (
  payload: WorkingTeamCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_WORKING_TEAM,
  payload,
});

export const updateWorkingTeam = (
  payload: WorkingTeamCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_WORKING_TEAM,
  payload,
});

export const clearWorkingTeam = () => ({
  type: CLEAR_WORKING_TEAM,
});
