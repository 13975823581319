import { AppAction } from '../../../../state/app';
import { ISearchBlockForm } from './addMultipleBlockDialog.types';

export const SET_SEARCH_BLOCK_FORM = 'ADD_MULTIPLE_BLOCK/SET_SEARCH_BLOCK_FORM';
export const SEARCH_ALL = 'ADD_MULTIPLE_BLOCK/SEARCH_ALL';
export const SEARCH_ITEM = 'ADD_MULTIPLE_BLOCK/SEARCH_ITEM';
export const CONFIRM_ITEM = 'ADD_MULTIPLE_BLOCK/CONFIRM_ITEM';
export const CONFIRM_ALL = 'ADD_MULTIPLE_BLOCK/CONFIRM_ALL';
export const CLEAR_DIALOG = 'ADD_MULTIPLE_BLOCK/CLEAR_DIALOG';

export const searchAll = () => ({
  type: SEARCH_ALL,
});

export const confirmAll = () => ({
  type: CONFIRM_ALL,
});

export const setSearchBlockForm = (payload: ISearchBlockForm): AppAction => {
  const { searchBlocks } = payload;
  return {
    type: SET_SEARCH_BLOCK_FORM,
    payload: {
      ...payload,
      searchBlocks: searchBlocks.map(s => ({ ...s, hasVerifyError: false, message: '', status: '' })),
    },
  };
};

export const clearDialog = () => ({
  type: CLEAR_DIALOG,
});
