import { Button, createStyles, makeStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { useState } from 'react';
import { WorkingTeamMember } from '../../state/workingTeamMember';
import AsyncAutoComplete from './AsyncAutoComplete';
import { fetchWorkingTeamMemberList } from '../../services/workingTeamMemberService';
import WorkingTeamMemberDialogSearch from './WorkingTeamMemberDialogSearch';

interface WorkingTeamMemberComboBoxSearchProps {
  handleChange: (result: WorkingTeamMember) => void;
  selectedValue: WorkingTeamMember;
  isDisabled?: boolean;
  error?: boolean;
  helperText?: string;
  handleClose: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    autoComplete: {
      flexGrow: 20,
    },
    button: {
      marginBottom: '8px',
      flexGrow: 1,
    },
  }),
);

const WorkingTeamMemberComboBoxSearch: React.FC<
  WorkingTeamMemberComboBoxSearchProps
> = props => {
  const classes = useStyles();
  const { handleChange, selectedValue, isDisabled = false, error, helperText } = props;
  const [openSearch, setOpenSearch] = useState(false);

  const workingTeamMemberPromiseOptions = async (inputValue: string) => {
    const { data: rows } = await fetchWorkingTeamMemberList({
      filters: {
        'User.FullNameWithUserName.Contains': inputValue!,
      },
      paging: { pageNumber: 0, pageSize: 10 },
    });
    return rows;
  };

  return (
    <div className={classes.container}>
      <AsyncAutoComplete
        name="WorkingTeam Member"
        value={selectedValue}
        className={classes.autoComplete}
        getOptionValue={(option: WorkingTeamMember) => {
          return option.id!.toString();
        }}
        getOptionLabel={(option: WorkingTeamMember) => {
          return `${option.smWorkingTeam!.name} ${option.user!.fullNameWithUserName!}`;
        }}
        promiseOptions={workingTeamMemberPromiseOptions}
        placeholder={'Type WorkingTeam Member Name'}
        handleChange={handleChange}
        isDisabled={isDisabled}
        error={error}
        helperText={helperText}
      />
      <Button
        className={classes.button}
        color="primary"
        variant="outlined"
        startIcon={<Search />}
        disabled={isDisabled}
        onClick={() => {
          setOpenSearch(true);
        }}
        style={{ marginLeft: 10 }}
      >
        Search
      </Button>

      <WorkingTeamMemberDialogSearch
        open={openSearch}
        handleClose={selectedWorkingTeamMembers => {
          if (selectedWorkingTeamMembers) {
            handleChange(selectedWorkingTeamMembers);
          }
          setOpenSearch(false);
        }}
      />
    </div>
  );
};

export default WorkingTeamMemberComboBoxSearch;
