import { PageFilter } from '../state/app';
import { SalesTeamMember, SalesTeamMemberListFilter } from '../state/salesTeamMember';
import axios, { getResponseFileName } from '../utils/axios';
import { saveAs } from 'file-saver';

const baseUrl = `/api/salesTeamMembers`;

export const createSalesTeamMember = async (salesTeamMember: SalesTeamMember) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, salesTeamMember);
};

export const updateSalesTeamMember = async (salesTeamMember: SalesTeamMember) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, salesTeamMember);
};

export const deleteSalesTeamMember = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};

export const fetchSalesTeamMemberList = async ({
  filters,
  paging,
}: {
  filters: SalesTeamMemberListFilter;
  paging: PageFilter;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const exportSalesTeamMembersAsExcel = async (
  filters: SalesTeamMemberListFilter,
) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  const response = await axios.get(`${requestUrl}/export/excel?${params.toString()}`, {
    responseType: 'blob',
  });
  const fileName = getResponseFileName(response);
  saveAs(response.data, fileName);
};
