import { PageFilter } from '../state/app';
import { EBlockGroupProductListFilter } from '../state/eBlockGroupProduct';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';
import { IEBlockGroupProduct } from '../models/eBlockGroupProduct';

const baseUrl = `/api/eBlockGroupProducts`;

export const fetchEBlockGroupProductList = async ({
  filters,
  paging,
}: {
  filters: EBlockGroupProductListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchEBlockGroupProduct = async (id: IEBlockGroupProduct['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createEBlockGroupProduct = async (
  eBlockGroupProduct: IEBlockGroupProduct,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, eBlockGroupProduct);
};

export const updateEBlockGroupProduct = async (
  eBlockGroupProduct: IEBlockGroupProduct,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, eBlockGroupProduct);
};
