import React from 'react';

import { Grid, MenuItem } from '@material-ui/core';

import { ProjectStatus, ProjectStatusLabel } from '../../../constants';
import { ProjectListFilter } from '../../../state/project';
import { FilterContainer, SearchDropdownField, SearchField } from '../../components';
import { IFilterContainerConfigProps } from '../../components/FilterContainer';

const ProjectFilter: React.FC<IFilterContainerConfigProps<ProjectListFilter>> = (
  props,
) => {
  return (
    <FilterContainer
      {...props}
      render={(filterProps) => {
        const { localFilter, handleChangeFilter } = filterProps;
        return (
          <Grid container spacing={1}>
            {/* <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Company.Name.startsWith"
                filters={localFilter}
                label="Company"
                onChange={handleChangeFilter}
              />
            </Grid> */}
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Name.startsWith"
                filters={localFilter}
                label="Name"
                onChange={handleChangeFilter}
              />
            </Grid>

            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="SmProjectPrograms.Program.Name.contains"
                filters={localFilter}
                label="Program"
                onChange={handleChangeFilter}
              />
            </Grid>

            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="SmProjectPrograms.SmProjectProgramBrands.Brand.Name.contains"
                filters={localFilter}
                label="Brand"
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Owner.FullName.Contains"
                filters={localFilter}
                label="Owner"
                onChange={handleChangeFilter}
              />
            </Grid>
            {/* <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="SmProjectSquads.SmSquad.Name.StartsWith"
                filters={localFilter}
                label="Squad"
                onChange={handleChangeFilter}
              />
            </Grid> */}

            <Grid item xs={12} md={2} lg={2}>
              <SearchDropdownField
                label="Status"
                filterKey={'Status.equalsTo'}
                filters={localFilter}
                onChange={handleChangeFilter}
                id="status"
              >
                <MenuItem value={''}>All</MenuItem>
                {Object.entries(ProjectStatus).map(([key, value]) => {
                  return (
                    <MenuItem key={key} value={value}>
                      {ProjectStatusLabel[value]}
                    </MenuItem>
                  );
                })}
              </SearchDropdownField>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <SearchDropdownField
                label="Is High Priority"
                filterKey={'IsHighPriority.equalsTo'}
                filters={localFilter}
                onChange={handleChangeFilter}
                id="IsHighPriority"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="true">High Priority</MenuItem>
                <MenuItem value="false">Low Priority</MenuItem>
              </SearchDropdownField>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default ProjectFilter;
