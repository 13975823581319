import { CardContent, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { CheckBoxFastField, TextFieldFastField } from '../../../components';
import useStyles from './sampleDevelopmentNl.style';

interface SampleDevelopmentNlJobTypeProps {}

const SampleDevelopmentNlJobType: React.FC<SampleDevelopmentNlJobTypeProps> = () => {
  const classes = useStyles();

  return (
    <CardContent>
      <Paper variant="outlined">
        <Typography className={classes.title}>Type งาน</Typography>
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography className={classes.numberTitle} display="inline">
                  1.
                </Typography>
                <CheckBoxFastField name="prodInfoSticker" label="Sticker" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="prodInfoFilm" label="Film" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="prodInfoPaper" label="Paper" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="prodInfoFabric" label="Fabric" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="prodInfoOther" label="Others" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldFastField
                  className={classes.textInputRightCheckbox}
                  name="prodInfoOtherData"
                  label="Others"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography className={classes.numberTitle} display="inline">
                  2.
                </Typography>
                <CheckBoxFastField name="prodInfoFlexo" label="Flexo" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="prodInfoDigital" label="Digital" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="prodInfoOffset" label="Offset" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="prodInfoSilkscreen" label="Silkscreen" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="prodInfoGravure" label="Gravure" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="prodInfoColdStamping" label="Cold Stamping" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="prodInfoHotStamping" label="Hot Stamping" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField
                  name="prodInfoLetterPress"
                  label="Letter Press (Labelman)"
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </CardContent>
  );
};

export default SampleDevelopmentNlJobType;
