import { Grid } from '@material-ui/core';
import React from 'react';
import { UserListFilter } from '../../../state/user';
import { FilterContainer, SearchField } from '../../components';
import { IFilterContainerConfigProps } from '../../components/FilterContainer';

const UserFilter: React.FC<IFilterContainerConfigProps<UserListFilter>> = props => {
  return (
    <FilterContainer
      {...props}
      render={({ localFilter, handleChangeFilter }) => {
        return (
          <Grid container spacing={1} alignItems={'center'}>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Username"
                filterKey={'login.startsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="First Name"
                filterKey={'FirstName.startsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Last Name"
                filterKey={'LastName.startsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default UserFilter;
