import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  fetchSubtaskItemMoqList,
  removeAndCreateSubtaskItemMoqs,
} from '../../services/subtaskItemMoqService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_MULTIPLE_SUBTASK_ITEM_MOQ_LIST,
  CREATE_SUBTASK_ITEM_MOQ_LIST,
  FETCH_SUBTASK_ITEM_MOQ_LIST,
  fetchSubtaskItemMoqListBySmSubtaskItemId,
} from './subtaskItemMoq.actions';

function* fetchSubtaskItemMoqListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSubtaskItemMoqList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createSubtaskItemMoqListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { smSubtaskItemId, smSubtaskItemMoqs } = action.payload;
    yield call(removeAndCreateSubtaskItemMoqs, smSubtaskItemId, smSubtaskItemMoqs);
    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Update SubtaskItems MOQ Successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put(fetchSubtaskItemMoqListBySmSubtaskItemId(smSubtaskItemId));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createMultipleSubtaskItemMoqListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const subtaskItems = action.payload.subtaskItems;
    const smSubtaskItemMoqs = action.payload.multipleSubtaskItemMoqList;
    const selectedSubtaskItemOnTableId = action.payload.selectedSubtaskItemOnTableId;

    for (const subtaskItem of subtaskItems) {
      const smSubtaskItemId = subtaskItem.id;
      yield call(removeAndCreateSubtaskItemMoqs, smSubtaskItemId, smSubtaskItemMoqs);
    }

    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Update Multiple SubtaskItems MOQ Successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    if (selectedSubtaskItemOnTableId) {
      yield put(fetchSubtaskItemMoqListBySmSubtaskItemId(selectedSubtaskItemOnTableId));
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSubtaskItemMoq() {
  yield debounce(250, FETCH_SUBTASK_ITEM_MOQ_LIST, fetchSubtaskItemMoqListSaga);
  yield takeLatest(CREATE_SUBTASK_ITEM_MOQ_LIST, createSubtaskItemMoqListSaga);
  yield takeLatest(
    CREATE_MULTIPLE_SUBTASK_ITEM_MOQ_LIST,
    createMultipleSubtaskItemMoqListSaga,
  );
}
