export type SampleDevelopmentStatusType =
  | 'PENDING_FOR_APPROVAL'
  | 'IN_REQUEST'
  | 'REJECTED'
  | 'APPROVED';

const SampleDevelopmentStatus = Object.freeze({
  PENDING_FOR_APPROVAL: 'PENDING_FOR_APPROVAL' as SampleDevelopmentStatusType,
  IN_REQUEST: 'IN_REQUEST' as SampleDevelopmentStatusType,
  REJECTED: 'REJECTED' as SampleDevelopmentStatusType,
  APPROVED: 'APPROVED' as SampleDevelopmentStatusType,
});

export default SampleDevelopmentStatus;

export const SampleDevelopmentStatusLabel = Object.freeze({
  [SampleDevelopmentStatus.PENDING_FOR_APPROVAL]: 'Pending For Approval',
  [SampleDevelopmentStatus.IN_REQUEST]: 'In Request',
  [SampleDevelopmentStatus.REJECTED]: 'Rejected',
  [SampleDevelopmentStatus.APPROVED]: 'Approved',
});
