import React from 'react';
import AsyncAutoComplete from './AsyncAutoComplete';

import { fetchHanesProjectList } from '../../services/hanesProjectService';
import { HanesProject } from '../../state/hanesProject';

interface HanesProjectComboBoxProps {
  handleChange: (result: HanesProject) => void;
  selectedValue: HanesProject;
  isDisabled?: boolean;
  error?: boolean;
  helperText?: string;
}

const HanesProjectComboBox = (props: HanesProjectComboBoxProps) => {
  const { handleChange, selectedValue, isDisabled = false, error, helperText } = props;

  const companyPromiseOptions = async (inputValue: string) => {
    const { data: rows } = await fetchHanesProjectList({
      filters: { 'ProjectName.StartsWith': inputValue },
      paging: { pageNumber: 0, pageSize: 10 },
    });

    return rows;
  };

  return (
    <AsyncAutoComplete
      name=""
      value={selectedValue}
      getOptionValue={(option: HanesProject) => {
        return option?.name;
      }}
      getOptionLabel={(option: HanesProject) => {
        return option?.name;
      }}
      promiseOptions={companyPromiseOptions}
      placeholder={'Select Hanes Project Name'}
      handleChange={handleChange}
      isDisabled={isDisabled}
      error={error}
      helperText={helperText}
    />
  );
};

export default HanesProjectComboBox;
