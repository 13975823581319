import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import 'ag-grid-community/dist/styles/ag-grid.min.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import 'typeface-roboto';
import App from './App';
import history from './history';
import store from './store';
import defaultTheme from './theme';
import SMDateFnsUtils from './utils/SMDateFnsUtils';

ReactDOM.render(
  <ThemeProvider theme={defaultTheme}>
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={SMDateFnsUtils}>
        <Router history={history}>
          <App />
        </Router>
      </MuiPickersUtilsProvider>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
);
