import axios, { AxiosResponse } from 'axios';
import { getAuthToken } from './authUtil';

const http = axios.create();

export const setAuthorizationBearerToken = () => {
  const token = getAuthToken();
  if (token) {
    http.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

setAuthorizationBearerToken();

export default http;

export const getResponseFileName = (response: AxiosResponse<any>) => {
  const contentDisposition = response.headers['content-disposition'];
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename=(.+);/);
    if (fileNameMatch.length === 2) return fileNameMatch[1];
  }
  return null;
};
