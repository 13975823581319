import { IOpportunityChangeLog } from '../../models/opportunityChangeLog';

export interface ChangeLogState {
  loading: boolean;
  opportunity: IOpportunityChangeLog[];
}

export const initChangeLogState = (): ChangeLogState => {
  return {
    loading: false,
    opportunity: [],
  };
};
