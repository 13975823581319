import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  categoryContainer: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  categoryPaper: {
    padding: theme.spacing(1),
  },
  categoryTitle: {
    padding: theme.spacing(1),
  },
}));
