import { Grid } from '@material-ui/core';
import React from 'react';

import { IOpportunityApprovalRequestReportFilter } from '../../../../state/report';
import { SearchField } from '../../../components';
import {
  FilterContainer,
  IFilterContainerConfigProps,
} from '../../../components/FilterContainer';

const OpportunityApprovalRequestReportFilter: React.FC<
  IFilterContainerConfigProps<IOpportunityApprovalRequestReportFilter>
> = props => {
  return (
    <FilterContainer
      {...props}
      render={filterProps => {
        const { localFilter, handleChangeFilter } = filterProps;
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                label="Company"
                filterKey="Opportunity.Company.Name.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                label="Assignment Number"
                filterKey="Opportunity.AssignmentNumber.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                label="Project"
                filterKey="Opportunity.SmProject.Name.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                label="Program"
                filterKey="Opportunity.Program.Name.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                label="Brand"
                filterKey="Opportunity.Brand.Name.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                label="Requested By"
                filterKey="RequestedByUser.FullNameWithUserName.Contains"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default OpportunityApprovalRequestReportFilter;
