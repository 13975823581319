import { defaultValue, IOpportunity } from '../../models/opportunity.model';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_OPPORTUNITY,
  CREATE_OPPORTUNITY,
  FETCH_OPPORTUNITY,
  FETCH_OPPORTUNITY_LIST,
  SUBMIT_FOR_APPROVAL,
  UPDATE_OPPORTUNITY,
  CHANGE_PROGRAM_AND_BRAND,
  SUBMIT_FOR_REASSIGN_RESPONSIBILITY,
  MOVE_ITEM_TO_NEW_OPPORTUNITY,
} from './opportunity.actions';
import { OpportunityState } from './opportunity.types';

const initialState: OpportunityState = {
  errorMessage: '',
  item: defaultValue,
  items: [] as ReadonlyArray<IOpportunity>,
  loading: false,
  totalItems: 0,
};

export default (
  state: OpportunityState = initialState,
  action: AppAction,
): OpportunityState => {
  switch (action.type) {
    case REQUEST(FETCH_OPPORTUNITY_LIST):
    case REQUEST(CREATE_OPPORTUNITY):
    case REQUEST(FETCH_OPPORTUNITY):
    case REQUEST(UPDATE_OPPORTUNITY):
    case REQUEST(CHANGE_PROGRAM_AND_BRAND):
    case REQUEST(SUBMIT_FOR_REASSIGN_RESPONSIBILITY):
    case REQUEST(MOVE_ITEM_TO_NEW_OPPORTUNITY):
      return { ...state, loading: true };
    case SUCCESS(FETCH_OPPORTUNITY_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_OPPORTUNITY_LIST):
    case FAILURE(CREATE_OPPORTUNITY):
    case FAILURE(FETCH_OPPORTUNITY):
    case FAILURE(UPDATE_OPPORTUNITY):
    case FAILURE(CHANGE_PROGRAM_AND_BRAND):
    case FAILURE(SUBMIT_FOR_REASSIGN_RESPONSIBILITY):
    case FAILURE(MOVE_ITEM_TO_NEW_OPPORTUNITY):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_OPPORTUNITY):
    case SUCCESS(FETCH_OPPORTUNITY):
    case SUCCESS(UPDATE_OPPORTUNITY):
    case SUCCESS(SUBMIT_FOR_APPROVAL):
    case SUCCESS(CHANGE_PROGRAM_AND_BRAND):
    case SUCCESS(SUBMIT_FOR_REASSIGN_RESPONSIBILITY):
    case SUCCESS(MOVE_ITEM_TO_NEW_OPPORTUNITY):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_OPPORTUNITY:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
