import { Button, makeStyles } from '@material-ui/core';
import {
  Send as SendIcon,
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
} from '@material-ui/icons';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SubtaskItemStatusLabel,
  SubtaskItemStatusType,
} from '../../../../constants/subtaskItemStatus';
import { ISubtaskItem } from '../../../../models/subtaskItem.model';
import { ISubtaskType } from '../../../../models/subtaskType.model';
import { AppState } from '../../../../state/configureStore';
import { updateSubtaskItemStatus } from '../../../../state/subtaskItem';
import { SubtaskTypeState } from '../../../../state/subtaskType';
import subtaskItemStatusRules from '../../../../utils/subtaskItemStatusRule';
import ArtworkLinkDialog from './ArtworkLinkDialog';
import SubtaskItemCloseDialog from './subtaskItem/SubtaskItemCloseDialog';
import SubtaskItemDoneDialog from './subtaskItem/SubtaskItemDoneDialog';

const useStyles = makeStyles((theme) => ({
  actionButton: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
}));

interface ISubtaskItemActionMenuProps {
  subtaskItem: ISubtaskItem;
}

const SubtaskItemActionMenu: React.FC<ISubtaskItemActionMenuProps> = (props) => {
  const { subtaskItem } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [openSubtaskItemCloseDialog, setOpenSubtaskItemCloseDialog] =
    useState<boolean>(false);
  const [openSubtaskItemDoneDialog, setOpenSubtaskItemDoneDialog] =
    useState<boolean>(false);
  const [openArtworkLinkDialog, setOpenArtworkLinkDialog] = useState<boolean>(false);
  const { items: subtaskTypeList } = useSelector<AppState, SubtaskTypeState>(
    (state) => state.subtaskType,
  );

  const subtaskTypeArtwork = subtaskTypeList.find(
    (subtaskType: ISubtaskType) => subtaskType.code === 'ARTWORK',
  );

  const onClickChangeStatus = (
    selectedSubtaskItem: ISubtaskItem,
    subtaskItemStatus: SubtaskItemStatusType,
  ) => {
    if (
      subtaskItemStatus === 'WAITING_FOR_SALES' &&
      subtaskTypeArtwork &&
      selectedSubtaskItem.smSubtask!.smSubtaskTypeId === subtaskTypeArtwork.id
    ) {
      setOpenArtworkLinkDialog(true);
    } else if (subtaskItemStatus !== 'CLOSE' && subtaskItemStatus !== 'DONE') {
      const selectedSubtaskItemClone = { ...selectedSubtaskItem };
      selectedSubtaskItemClone.status = subtaskItemStatus;
      dispatch(updateSubtaskItemStatus(selectedSubtaskItemClone));
    } else if (subtaskItemStatus === 'CLOSE') {
      setOpenSubtaskItemCloseDialog(true);
    } else if (subtaskItemStatus === 'DONE') {
      setOpenSubtaskItemDoneDialog(true);
    }
  };

  const useCommonStatus = subtaskItem.smSubtask?.useCommonStatus;
  const subtaskTypeCode = subtaskItem.smSubtask?.smSubtaskType?.code;

  const subtaskItemStatusRule = subtaskItemStatusRules.find(
    (_) => _.currentStatus === subtaskItem.status && _.isCommon === useCommonStatus,
  );
  const [nextStatusList, setNextStatusList] = useState<
    SubtaskItemStatusType[] | undefined
  >(subtaskItemStatusRule?.nextStatus);

  useEffect(() => {
    if (!subtaskItem.smSubtask?.useCommonStatus) {
      if (subtaskItem.smSubtask?.smSubtaskType?.code === 'PROOF') {
        setNextStatusList(subtaskItemStatusRule?.subtaskTypeProof?.nextStatus);
      } else if (subtaskItem.smSubtask?.smSubtaskType?.code === 'MOCKUP') {
        setNextStatusList(subtaskItemStatusRule?.subtaskTypeMockup?.nextStatus);
      } else if (subtaskItem.smSubtask?.smSubtaskType?.code === 'VERIFY DATA') {
        setNextStatusList(subtaskItemStatusRule?.subtaskTypeVerifyData?.nextStatus);
      } else {
        setNextStatusList(subtaskItemStatusRule?.subtaskTypeArtwork?.nextStatus);
      }

      // if (subtaskItem.smSubtask?.smSubtaskType?.code === 'ARTWORK') {
      //   setNextStatusList(subtaskItemStatusRule?.subtaskTypeArtwork?.nextStatus);
      // } else if (subtaskItem.smSubtask?.smSubtaskType?.code === 'PROOF') {
      //   setNextStatusList(subtaskItemStatusRule?.subtaskTypeProof?.nextStatus);
      // } else if (subtaskItem.smSubtask?.smSubtaskType?.code === 'MOCKUP') {
      //   setNextStatusList(subtaskItemStatusRule?.subtaskTypeMockup?.nextStatus);
      // }
    } else {
      const commonStatus = subtaskItemStatusRules.find(
        (itemStatus) =>
          itemStatus.currentStatus === subtaskItem.status && itemStatus.isCommon === true,
      );
      setNextStatusList(commonStatus?.nextStatus);
    }
  }, [subtaskItem, subtaskItemStatusRule]);

  // const { nextStatus: nextStatusList } = subtaskItemStatusRules.find(
  //   (itemStatus) =>
  //     itemStatus.currentStatus === subtaskItem.status &&
  //     itemStatus.isCommon === useCommonStatus,
  // )!;

  const handleOkInArtworkLinkDialog = (subtaskItems: ISubtaskItem[]) => {
    const selectedSubtaskItem = subtaskItems[0];
    const selectedSubtaskItemClone = { ...selectedSubtaskItem };
    selectedSubtaskItemClone.status = 'WAITING_FOR_SALES';
    dispatch(updateSubtaskItemStatus(selectedSubtaskItemClone));
    setOpenArtworkLinkDialog(false);
  };

  return (
    <>
      <SubtaskItemCloseDialog
        subtaskItems={[subtaskItem]}
        open={openSubtaskItemCloseDialog}
        onClose={() => {
          setOpenSubtaskItemCloseDialog(false);
        }}
      />
      <SubtaskItemDoneDialog
        subtaskItems={[subtaskItem]}
        open={openSubtaskItemDoneDialog}
        onClose={() => {
          setOpenSubtaskItemDoneDialog(false);
        }}
      />
      <ArtworkLinkDialog
        open={openArtworkLinkDialog}
        onOk={handleOkInArtworkLinkDialog}
        subtaskItems={[subtaskItem]}
      />

      {nextStatusList?.map((nextStatus, index, rows) => (
        <Button
          key={index}
          className={classes.actionButton}
          variant="contained"
          size={'small'}
          onClick={() => onClickChangeStatus(subtaskItem, nextStatus)}
          startIcon={
            rows.length === index + 1 ? <NavigateNextIcon /> : <NavigateBeforeIcon />
          }
          color={rows.length === index + 1 ? 'primary' : 'default'}
        >
          {SubtaskItemStatusLabel[nextStatus]}
        </Button>
      ))}
    </>
  );
};

export default SubtaskItemActionMenu;
