import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IForecast, IForecastWithAddedProps } from '../../../models/forecast.model';
import { ITaskItemWithForecasts } from '../../../models/taskItem.model';
import { AppState } from '../../../state/configureStore';
import { TaskCreateState } from '../../../state/taskCreate';
import ForecastTable from './ForecastTable';

interface MultipleTaskItemForecastEditDialogProps {
  open: boolean;
  taskItemToEditForecasts: ITaskItemWithForecasts[];
  onClose: () => void;
  onSubmit: (multipleTaskItemWithForecasts: ITaskItemWithForecasts[]) => void;
}

const MultipleTaskItemForecastEditDialog: React.FC<
  MultipleTaskItemForecastEditDialogProps
> = props => {
  const { open, taskItemToEditForecasts, onClose, onSubmit } = props;
  const [forecasts, setForecasts] = useState<IForecastWithAddedProps[]>([]);
  const { selectedUnitOfQuantity } = useSelector<AppState, TaskCreateState>(
    state => state.taskCreate,
  );

  const defaultNewRowValue = useMemo<IForecast>(() => {
    return {
      quantity: '',
      unitOfQuantity: selectedUnitOfQuantity,
      frequency: '',
    };
  }, [selectedUnitOfQuantity]);

  const handleUpdate = (createForecastList: IForecast[]) => {
    const mapCreateForecastList = createForecastList.map(forecast => ({
      ...forecast,
      added: true,
    }));
    setForecasts(mapCreateForecastList);
    const editSelectedTaskItemForecasts = taskItemToEditForecasts.map(
      taskItemToEditForecast => {
        return { ...taskItemToEditForecast, forecasts: mapCreateForecastList };
      },
    );
    onSubmit(editSelectedTaskItemForecasts);
  };

  const handleClose = () => {
    setForecasts([]);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onExiting={() => setForecasts([])}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>Edit Multiple Forecast</DialogTitle>
      <DialogContent>
        <ForecastTable
          defaultNewRowValue={defaultNewRowValue}
          onUpdate={handleUpdate}
          forecastList={forecasts}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MultipleTaskItemForecastEditDialog;
