import { CardContent, Grid, Paper, Typography, Box } from '@material-ui/core';
import React from 'react';
import { TextFieldFastField } from '../../../components';

interface SampleDevelopmentWlSpecialInstructionProps {}

const SampleDevelopmentWlSpecialInstruction: React.FC<
  SampleDevelopmentWlSpecialInstructionProps
> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Special Instruction</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextFieldFastField
                name="specialInstruction"
                label="Special Instruction"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentWlSpecialInstruction;
