import { ISubtask } from '../../models/subtask.model';
import { AppAction, PageFilter, AppCallback } from '../app';
import { SubtaskListFilter } from './subtask.types';

export const FETCH_SUBTASK = 'FETCH_SUBTASK';
export const FETCH_SUBTASK_LIST = 'FETCH_SUBTASK_LIST';
export const FETCH_RELATED_SUBTASK_LIST = 'FETCH_RELATED_SUBTASK_LIST';
export const CREATE_SUBTASK = 'CREATE_SUBTASK';
export const CREATE_SUBTASKS = 'CREATE_SUBTASKS';
export const UPDATE_SUBTASK = 'UPDATE_SUBTASK';
export const CLEAR_SUBTASK = 'CLEAR_SUBTASK';
export const DELETE_SUBTASK = 'DELETE_SUBTASK';
export const CLEAR_SUBTASK_ITEM_SECTION = 'CLEAR_SUBTASK_ITEM_SECTION';
export const UPDATE_SUBTASK_STATUS = 'UPDATE_SUBTASK_STATUS';
export const FETCH_SUBTASK_STATUS = 'FETCH_SUBTASK_STATUS';

export const fetchSubtaskStatus = (): AppAction => ({
  type: FETCH_SUBTASK_STATUS,
});

export const fetchSubtask = (id: ISubtask['id']): AppAction => ({
  type: FETCH_SUBTASK,
  payload: id,
});

export const fetchSubtaskList = (
  filters: SubtaskListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SUBTASK_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchRelatedSubtaskListByTaskId = (taskId: number): AppAction => {
  return {
    type: FETCH_RELATED_SUBTASK_LIST,
    payload: {
      filters: { 'SMTaskId.EqualsTo': taskId },
      paging: { pageSize: 9999, pageNumber: 0 },
    },
  };
};

export const fetchSubtaskListByTaskId = (taskId: number): AppAction => {
  return {
    type: FETCH_SUBTASK_LIST,
    payload: {
      filters: { 'SMTaskId.EqualsTo': taskId },
      paging: { pageSize: 9999, pageNumber: 0 },
    },
  };
};

export const createSubtask = (payload: ISubtask, callback?: AppCallback): AppAction => ({
  type: CREATE_SUBTASK,
  payload,
  callback,
});

export const createSubtasks = (
  payload: ISubtask[],
  callback?: AppCallback,
): AppAction => ({
  type: CREATE_SUBTASKS,
  payload,
  callback,
});

export const updateSubtask = (payload: ISubtask, callback?: AppCallback): AppAction => ({
  type: UPDATE_SUBTASK,
  payload,
  callback,
});

export const clearSubtask = () => ({
  type: CLEAR_SUBTASK,
});

export const deleteSubtask = (payload: any): AppAction => ({
  type: DELETE_SUBTASK,
  payload,
});

export const clearSubtaskItemSection = () => ({
  type: CLEAR_SUBTASK_ITEM_SECTION,
});

export const updateSubtaskStatus = (
  subtask: ISubtask,
  callback?: (error?: Error) => void,
) => {
  return {
    type: UPDATE_SUBTASK_STATUS,
    payload: { subtask },
    callback,
  };
};
