import { Option } from '../views/components/EditableTable/SelectOptionCellEditor';

export type UnitOfQuantityType = 'PCS' | 'SHEET' | 'ROLL' | 'DOZEN' | 'YARD';

const UnitOfQuantity = Object.freeze({
  PCS: 'PCS' as UnitOfQuantityType,
  SHEET: 'SHEET' as UnitOfQuantityType,
  ROLL: 'ROLL' as UnitOfQuantityType,
  DOZEN: 'DOZEN' as UnitOfQuantityType,
  YARD: 'YARD' as UnitOfQuantityType,
});

export default UnitOfQuantity;

export const UnitOfQuantityLabel = Object.freeze({
  [UnitOfQuantity.PCS]: 'PCS',
  [UnitOfQuantity.SHEET]: 'SHEET',
  [UnitOfQuantity.ROLL]: 'ROLL',
  [UnitOfQuantity.DOZEN]: 'DOZEN',
  [UnitOfQuantity.YARD]: 'YARD',
});

export const UnitOfQuantityOptions: Array<Option> = Object.keys(UnitOfQuantity).map(
  (u) => {
    return { label: UnitOfQuantityLabel[u], value: u };
  },
);
