import { IResponsibilityBrand } from '../../models/responsibilityBrand.model';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_RESPONSIBILITY_BRAND,
  FETCH_RESPONSIBILITY_BRAND,
  FETCH_RESPONSIBILITY_BRAND_LIST,
  UPDATE_RESPONSIBILITY_BRAND,
  CREATE_RESPONSIBILITY_BRAND,
  REPLACE_RESPONSIBILITY_BRAND,
} from './responsibilityBrand.actions';
import { ResponsibilityBrandState } from './responsibilityBrand.types';

const getInitialResponsibilityBrandState = (): IResponsibilityBrand => ({});

const initialState: ResponsibilityBrandState = {
  errorMessage: '',
  item: getInitialResponsibilityBrandState(),
  items: [],
  loading: false,
  totalItems: 0,
};

export default (
  state: ResponsibilityBrandState = initialState,
  action: AppAction,
): ResponsibilityBrandState => {
  switch (action.type) {
    case REQUEST(FETCH_RESPONSIBILITY_BRAND_LIST):
    case REQUEST(CREATE_RESPONSIBILITY_BRAND):
    case REQUEST(FETCH_RESPONSIBILITY_BRAND):
    case REQUEST(UPDATE_RESPONSIBILITY_BRAND):
    case REQUEST(REPLACE_RESPONSIBILITY_BRAND):
      return { ...state, loading: true };
    case SUCCESS(FETCH_RESPONSIBILITY_BRAND_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_RESPONSIBILITY_BRAND_LIST):
    case FAILURE(CREATE_RESPONSIBILITY_BRAND):
    case FAILURE(FETCH_RESPONSIBILITY_BRAND):
    case FAILURE(UPDATE_RESPONSIBILITY_BRAND):
    case FAILURE(REPLACE_RESPONSIBILITY_BRAND):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_RESPONSIBILITY_BRAND):
    case SUCCESS(FETCH_RESPONSIBILITY_BRAND):
    case SUCCESS(UPDATE_RESPONSIBILITY_BRAND):
    case SUCCESS(REPLACE_RESPONSIBILITY_BRAND):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_RESPONSIBILITY_BRAND:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
