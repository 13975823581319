import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './sampleDevelopmentNl.style';
import { CheckBoxFastField, TextFieldFastField } from '../../../components';

interface SamDevNlSpecCustomerGrpProps {}

const SampleDevelopmentNlSpecCustomerGrp: React.FC<SamDevNlSpecCustomerGrpProps> = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Typography>Customer Group:</Typography>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="custGrpTireIndustry" label="Tire Industry" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="custGrpTicket" label="Ticket" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="custGrpFood" label="Food" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="custGrpConsumerProd" label="Consumer Product" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="custGrpHouseWare" label="House Ware" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="custGrpElectronic" label="Electronic" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="custGrpCosmetic" label="Cosmetic" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="custGrpPharmaceutical" label="Pharmaceutical" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="custGrpLogistic" label="Logistic" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="custGrpAirline" label="Airline" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="custGrpGarment" label="Garment" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField
            name="custGrpElectricAppliances"
            label="Electric Appliances"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="custGrpOther" label="Others" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldFastField
            className={classes.textInput}
            name="custGrpOtherData"
            label="Others"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SampleDevelopmentNlSpecCustomerGrp;
