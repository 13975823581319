import { call, put, takeLatest, select } from 'redux-saga/effects';
import { AppAction } from '../../../../state/app';
import { AppState } from '../../../../state/configureStore';
import { fetchBlockByBlockNo } from '../../../../services/blockService';
import { createOpportunityItem } from '../../../../services/opportunityItemService';
import { FAILURE, REQUEST, SUCCESS } from '../../../../utils/actionTypeUtil';
import {
  CONFIRM_ALL,
  CONFIRM_ITEM,
  SEARCH_ALL,
  SEARCH_ITEM,
} from './addMultipleBlockDialog.actions';
import { IOpportunityItem } from '../../../../models/opportunityItem.model';

function* searchAllSaga(action: AppAction) {
  yield put({ type: REQUEST(SEARCH_ALL) });
  const form = yield select((state: AppState) => state.addMultipleBlockDialog.form);
  const { searchBlocks, program, brand } = form;
  let index = -1;
  for (const searchBlock of searchBlocks) {
    index++;
    try {
      yield put({ type: REQUEST(SEARCH_ITEM), payload: { index } });
      const block = yield call(fetchBlockByBlockNo, searchBlock.blockNo);
      if (!block) {
        yield put({
          type: FAILURE(SEARCH_ITEM),
          payload: { index, errorMessage: 'Not found' },
        });
        continue;
      }
      if (block.programId !== program.id || block.brandId !== brand.id) {
        yield put({
          type: FAILURE(SEARCH_ITEM),
          payload: {
            index,
            errorMessage: `Block doesn't belongs to Program:${program.name}, Brand:${brand.name}`,
          },
        });
        continue;
      }
      yield put({ type: SUCCESS(SEARCH_ITEM), payload: { index, data: block } });
    } catch (error) {
      const errorMessage: string = error.response.data.message;
      yield put({ type: FAILURE(SEARCH_ITEM), payload: { index, errorMessage } });
    }
  }

  yield put({ type: SUCCESS(SEARCH_ALL) });
}

function* confirmAllSaga(action: AppAction) {
  yield put({ type: REQUEST(CONFIRM_ALL) });
  const form = yield select((state: AppState) => state.addMultipleBlockDialog.form);
  const { searchBlocks, userId, program } = form;
  const opportunity = yield select((state: AppState) => state.opportunity.item);
  const opportunityItems = yield select((state: AppState) => state.opportunityItem.items);

  const lastSequence =
    opportunityItems.length === 0
      ? 0
      : Math.max(
          ...opportunityItems
            .filter((o: IOpportunityItem) => !!o.sequence)
            .map((o: IOpportunityItem) => o.sequence),
        );

  let index = -1;
  for (const searchBlock of searchBlocks) {
    index++;
    if (searchBlock.hasVerifyError) {
      continue;
    }
    try {
      yield put({ type: REQUEST(CONFIRM_ITEM), payload: { index } });
      yield call(createOpportunityItem, {
        sequence: lastSequence + index + 1,
        blockId: searchBlock.block.id,
        opportunityId: opportunity.id,
        sampleDevUserId: program.isUseNpd ? undefined : userId,
        npdUserId: program.isUseNpd ? userId : undefined,
      });
      yield put({ type: SUCCESS(CONFIRM_ITEM), payload: { index } });
    } catch (error) {
      const errorMessage: string = error.response.data.message;
      yield put({ type: FAILURE(CONFIRM_ITEM), payload: { index, errorMessage } });
    }
  }

  yield put({ type: SUCCESS(CONFIRM_ALL) });
}

export default function* watchAddMultipleBlockDialog() {
  yield takeLatest(SEARCH_ALL, searchAllSaga);
  yield takeLatest(CONFIRM_ALL, confirmAllSaga);
}
