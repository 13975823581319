import { SettingsApplications as SettingsApplicationsIcon } from '@material-ui/icons/';
import { DrawerListLink } from './drawer.types';

export const guestLinks: DrawerListLink[] = [
  {
    name: 'Login',
    path: '/',
    Icon: SettingsApplicationsIcon,
  },
];
