import React, { useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  MenuItem,
  DialogTitle,
  Grid,
  IconButton,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppState } from '../../../../state/configureStore';
import { TaskItemState } from '../../../../state/taskItem';
import { useSelector, useDispatch } from 'react-redux';
import { TaskItemChooseDialog, OpportunityLinkButton } from '../../../components/';
import { Edit as EditIcon } from '@material-ui/icons';
import {
  createTaskSubtaskSubtaskItemAndEtc,
  addSelectedTaskItemInTaskCreate,
  addSelectedTaskItemMoqInTaskCreate,
  TaskCreateState,
  addSelectedTaskItemForecastInTaskCreate,
  addSelectedUnitOfQuantityInTaskCreate,
} from '../../../../state/taskCreate';
import { SubtaskTypeState } from '../../../../state/subtaskType';
import { ISubtaskType } from '../../../../models/subtaskType.model';
import UnitOfQuantities, {
  UnitOfQuantityLabel,
} from '../../../../constants/unitOfQuantity';
import {
  Close as CloseIcon,
  Save as SaveIcon,
  SkipNext as NextIcon,
  SkipPrevious as PreviousIcon,
  Search as SearchIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
  }),
);

interface TaskItemListDialogProps {
  nextClick: () => void;
  backClick: () => void;
  finishClick: () => void;
  closeClick: () => void;
}

const TaskItemListDialog: React.FC<TaskItemListDialogProps> = (props) => {
  const classes = useStyles();
  const { nextClick, backClick, finishClick, closeClick } = props;
  const { selectedUnitOfQuantity } = useSelector<AppState, TaskCreateState>(
    (state) => state.taskCreate,
  );
  const [unitOfQuantity, setUnitOfQuantity] = useState(selectedUnitOfQuantity);
  const dispatch = useDispatch();

  const [openTaskItemChooseDialog, setOpenTaskItemChooseDialog] = useState(false);
  const { selectedItems } = useSelector<AppState, TaskItemState>(
    (state) => state.taskItem,
  );
  const {
    createSubtasksPayload,
    selectedTaskItemsWithMoqs,
    selectedTaskItemsWithForecasts,
  } = useSelector<AppState, TaskCreateState>((state) => state.taskCreate);
  const { items: subtaskTypeList } = useSelector<AppState, SubtaskTypeState>(
    (state) => state.subtaskType,
  );

  const addTaskItem = () => {
    setOpenTaskItemChooseDialog(true);
  };

  const handleNextClick = () => {
    dispatch(addSelectedTaskItemInTaskCreate(selectedItems));

    const subtaskTypePriceId = subtaskTypeList.find(
      (subtaskType: ISubtaskType) => subtaskType.code === 'PRICE',
    )!.id!;

    if (
      createSubtasksPayload.findIndex(
        (createSubtask) => createSubtask.smSubtaskTypeId! === subtaskTypePriceId,
      ) !== -1
    ) {
      const selectedItemsWithMoq = selectedItems.map((selectedItem) => {
        const foundSelectedTaskItemWithMoqs = selectedTaskItemsWithMoqs.find(
          (row) => row.opportunityItemId! === selectedItem.opportunityItemId!,
        );
        if (foundSelectedTaskItemWithMoqs) {
          return foundSelectedTaskItemWithMoqs;
        }
        return { ...selectedItem, moqs: [] };
      });

      const selectedItemsWithForecast = selectedItems.map((selectedItem) => {
        const foundSelectedTaskItemWithForecasts = selectedTaskItemsWithForecasts.find(
          (row) => row.opportunityItemId! === selectedItem.opportunityItemId!,
        );
        if (foundSelectedTaskItemWithForecasts) {
          return foundSelectedTaskItemWithForecasts;
        }
        return { ...selectedItem, forecasts: [] };
      });

      dispatch(addSelectedTaskItemMoqInTaskCreate(selectedItemsWithMoq));
      dispatch(addSelectedTaskItemForecastInTaskCreate(selectedItemsWithForecast));
      dispatch(addSelectedUnitOfQuantityInTaskCreate(unitOfQuantity));

      nextClick();
    } else {
      dispatch(createTaskSubtaskSubtaskItemAndEtc());
      dispatch(addSelectedUnitOfQuantityInTaskCreate(unitOfQuantity));
      finishClick();
    }
  };
  const disabledNextButton = selectedItems.length === 0 || unitOfQuantity === '';
  return (
    <>
      <TaskItemChooseDialog
        open={openTaskItemChooseDialog}
        handleClose={() => {
          setOpenTaskItemChooseDialog(false);
        }}
      />
      <DialogTitle style={{ paddingTop: '8px', paddingBottom: '8px' }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          Task Item & Unit of Quantity
          <IconButton
            color="inherit"
            aria-label="close"
            onClick={() => {
              closeClick();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Button
            startIcon={<SearchIcon />}
            color={selectedItems.length === 0 ? 'primary' : 'default'}
            variant="contained"
            onClick={addTaskItem}
          >
            Select Item
          </Button>
          <FormControl className={classes.formControl}>
            <InputLabel>Unit of Quantity</InputLabel>
            <Select
              value={unitOfQuantity}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setUnitOfQuantity(event.target.value as string);
              }}
              inputProps={{ name: 'unitOfQuantity' }}
            >
              {Object.entries(UnitOfQuantities).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={value}>
                    {UnitOfQuantityLabel[value]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Table style={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Assignment Number</TableCell>
              <TableCell>Program</TableCell>
              <TableCell>Brand</TableCell>
              <TableCell>Block</TableCell>
              <TableCell>Item</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedItems.map((item, index) => {
              const block = item.opportunityItem!.block!;
              const opportunity = item.opportunityItem!.opportunity!;
              return (
                <TableRow key={item.opportunityItemId!}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <OpportunityLinkButton opportunity={opportunity} />
                  </TableCell>
                  <TableCell>{opportunity.program!.name}</TableCell>
                  <TableCell>{opportunity.brand!.name}</TableCell>
                  <TableCell>{block.blockNo}</TableCell>
                  <TableCell>{block.item!}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions style={{ marginRight: '5px', marginBottom: '10px' }}>
        {/* <Button
          onClick={() => {
            closeClick();
          }}
          variant="contained"
          color="default"
          startIcon={<CloseIcon />}
        >
          Close
        </Button> */}
        {/* <Button
          startIcon={<SearchIcon />}
          color={selectedItems.length === 0 ? 'primary' : 'default'}
          variant="contained"
          onClick={addTaskItem}
        >
          Select Item
        </Button> */}
        <Button
          startIcon={<PreviousIcon />}
          color="default"
          variant="contained"
          onClick={backClick}
        >
          Back
        </Button>
        <Button
          onClick={handleNextClick}
          variant="contained"
          color="primary"
          startIcon={<NextIcon />}
          disabled={disabledNextButton}
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
};

export default TaskItemListDialog;
