import { AppAction, PageFilter } from '../app';
import { SalesTeamMemberListFilter } from './salesTeamMember.types';

export const CREATE_SALESTEAM_MEMBER = 'CREATE_SALESTEAM_MEMBER';
export const UPDATE_SALESTEAM_MEMBER = 'UPDATE_SALESTEAM_MEMBER';
export const FETCH_SALESTEAM_MEMBER_LIST = 'FETCH_SALESTEAM_MEMBER_LIST';
export const CLEAR_SALESTEAM_MEMBER = 'CLEAR_SALESTEAM_MEMBER';
export const DELETE_SALESTEAM_MEMBER = 'DELETE_SALESTEAM_MEMBER';

export const createSalesTeamMember = (payload: any): AppAction => ({
  type: CREATE_SALESTEAM_MEMBER,
  payload,
});

export const updateSalesTeamMember = (payload: any): AppAction => ({
  type: UPDATE_SALESTEAM_MEMBER,
  payload,
});

export const deleteSalesTeamMember = (payload: any): AppAction => ({
  type: DELETE_SALESTEAM_MEMBER,
  payload,
});

export const fetchSalesTeamMemberList = (
  filters: SalesTeamMemberListFilter,
  paging: PageFilter,
): AppAction => ({
  type: FETCH_SALESTEAM_MEMBER_LIST,
  payload: {
    filters,
    paging,
  },
});

export const fetchSalesTeamMemberListBySalesTeamId = (salesTeamId: number) => {
  return fetchSalesTeamMemberList(
    { 'SalesTeamId.EqualsTo': salesTeamId },
    { pageNumber: 0, pageSize: 9999 },
  );
};

export const clearSalesTeamMember = () => ({
  type: CLEAR_SALESTEAM_MEMBER,
});
