import { CardContent, Grid, Paper, Typography } from '@material-ui/core';
import { TextFieldFastField } from '../../../components';
import React from 'react';
import useStyles from './sampleDevelopmentNl.style';
import SampleDevelopmentNlSpecCustomerGrp from './SampleDevelopmentNlSpecCustomerGrp';
import SampleDevelopmentNlSpecDetail from './SampleDevelopmentNlSpecDetail';
import SampleDevelopmentNlSpecLabelDimension from './SampleDevelopmentNlSpecLabelDimension';
import SampleDevelopmentNlSpecSubstrate from './SampleDevelopmentNlSpecSubstrate';
import SampleDevelopmentNlSpecSubstrateInfo from './SampleDevelopmentNlSpecSubstrateInfo';

interface SampleDevelopmentNlSpecProps {
  values: any;
  handleChange: any;
  errors: any;
  setFieldValue: any;
}

const SampleDevelopmentNlSpec: React.FC<SampleDevelopmentNlSpecProps> = (props) => {
  const { values, handleChange, setFieldValue } = props;
  const classes = useStyles();

  return (
    <Paper variant="outlined">
      <Typography className={classes.title}>Specification</Typography>
      <CardContent>
        <SampleDevelopmentNlSpecLabelDimension />

        <SampleDevelopmentNlSpecDetail />

        <SampleDevelopmentNlSpecSubstrate
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />

        <SampleDevelopmentNlSpecCustomerGrp />

        <SampleDevelopmentNlSpecSubstrateInfo />
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Typography className={classes.title}>4. Special Requirement</Typography>
          <Grid item xs={12} sm={8}>
            <TextFieldFastField
              className={classes.textInput}
              name="specialRequirementData"
              label="Special Requirement"
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
        </Grid>
      </CardContent>
    </Paper>
  );
};

export default SampleDevelopmentNlSpec;
