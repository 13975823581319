import { CardContent, Grid, Paper, Typography, Box } from '@material-ui/core';
import React from 'react';
import { CheckBoxFastField, TextFieldFastField } from '../../../components';

interface SampleDevelopmentTlJobProps {}

const SampleDevelopmentTlJob: React.FC<SampleDevelopmentTlJobProps> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Job</Typography>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              item
              xs={12}
            >
              <Grid item xs={12} md={2}>
                <Typography>Job Type:</Typography>
              </Grid>
              <Grid item xs={12} md={2} container alignItems="center" spacing={1}>
                <Grid item xs={12} md={1}>
                  <Typography display="inline">1 </Typography>
                </Grid>
                <Grid item xs={12} md={11}>
                  <CheckBoxFastField name="jobTypeDirectPrint" label="Direct Print" />
                </Grid>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeHeatTransfer" label="Heat Transfer" />
              </Grid>
              <Grid item xs={12} md={5} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2} container alignItems="center" spacing={1}>
                <Grid item xs={12} md={1}>
                  <Typography display="inline">2 </Typography>
                </Grid>
                <Grid item xs={12} md={11}>
                  <CheckBoxFastField name="jobTypePlastisol" label="Plastisol" />
                </Grid>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField
                  name="jobTypeProcessPlastisol"
                  label="4 Process Plastisol"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField
                  name="jobTypeLithoOffsetPlastisol"
                  label="Litho/Offset Plastisol"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeSublimation" label="Sublimation" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField
                  name="jobTypeOffsetSublimation"
                  label="Offset Sublimation"
                />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeSolventBase" label="Solvent Base" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeWaterBase" label="Water Base" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeOilBase" label="Oil Base" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeStickerDieCut" label="Sticker DieCut" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeEtc" label="Etc" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldFastField
                  name="jobTypeEtcData"
                  label="Etc"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2} container alignItems="center" spacing={1}>
                <Grid item xs={12} md={1}>
                  <Typography display="inline">3 </Typography>
                </Grid>
                <Grid item xs={12} md={11}>
                  <CheckBoxFastField name="jobTypeReflective" label="Reflective" />
                </Grid>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField
                  name="jobTypeRainbowReflective"
                  label="Rainbow Reflective"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeFoil" label="Foil" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeFlock" label="Flock" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypePet" label="Pet" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypePet75" label="Pet75" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypePet105" label="Pet105" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypePaper75" label="Paper75" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypePaper105" label="Paper105" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeHotPeelPaper" label="Hot Peel Paper" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeMattePaper" label="Matte Paper" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeGlossPaper" label="Gloss Paper" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeTexturePaper" label="Texture Paper" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField
                  name="jobTypeTransparentPaper"
                  label="Transparent Paper"
                />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2} container alignItems="center" spacing={1}>
                <Grid item xs={12} md={1}>
                  <Typography display="inline">4 </Typography>
                </Grid>
                <Grid item xs={12} md={11}>
                  <CheckBoxFastField name="jobTypeMetallic" label="Metallic" />
                </Grid>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeIridescent" label="Iridescent" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeGlitter" label="Glitter" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeGlowDark" label="Glow in the dark" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeSuede" label="Suede" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypePuff" label="Puff" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeThermachromic" label="Thermachromic" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeEtc2" label="Etc" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldFastField
                  name="jobTypeEtc2Data"
                  label="Etc"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentTlJob;
