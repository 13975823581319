import { AppAction, PageFilter } from '../app';
import {
  WorkingTeamMemberListFilter,
  WorkingTeamMemberCreateOrUpdatePayload,
} from './workingTeamMember.type';

export const CREATE_WORKING_TEAM_MEMBER = 'CREATE_WORKING_TEAM_MEMBER';
export const UPDATE_WORKING_TEAM_MEMBER = 'UPDATE_WORKING_TEAM_MEMBER';
export const FETCH_WORKING_TEAM_MEMBER_LIST = 'FETCH_WORKING_TEAM_MEMBER_LIST';
export const FETCH_WORKING_TEAM_MEMBER_LIST_NOT_OWNER =
  'FETCH_WORKING_TEAM_MEMBER_LIST_NOT_OWNER';
export const CLEAR_WORKING_TEAM_MEMBER = 'CLEAR_WORKING_TEAM_MEMBER';
export const DELETE_WORKING_TEAM_MEMBER = 'DELETE_WORKING_TEAM_MEMBER';

export const fetchWorkingTeamMemberList = (
  filters: WorkingTeamMemberListFilter,
  paging: PageFilter,
): AppAction => ({
  type: FETCH_WORKING_TEAM_MEMBER_LIST,
  payload: {
    filters,
    paging,
  },
});

export const fetchWorkingTeamMemberNotOwnerList = (
  filters: WorkingTeamMemberListFilter,
  paging: PageFilter,
): AppAction => ({
  type: FETCH_WORKING_TEAM_MEMBER_LIST_NOT_OWNER,
  payload: {
    filters,
    paging,
  },
});

export const fetchWorkingTeamMemberListByWorkingTeamId = (workingTeamId: number) => {
  return fetchWorkingTeamMemberList(
    { 'SmWorkingTeamId.EqualsTo': workingTeamId },
    { pageNumber: 0, pageSize: 9999 },
  );
};

export const createWorkingTeamMember = (payload: any): AppAction => ({
  type: CREATE_WORKING_TEAM_MEMBER,
  payload,
});

export const updateWorkingTeamMember = (payload: any): AppAction => ({
  type: UPDATE_WORKING_TEAM_MEMBER,
  payload,
});

export const clearWorkingTeamMember = () => ({
  type: CLEAR_WORKING_TEAM_MEMBER,
});

export const deleteWorkingTeamMember = (payload: any): AppAction => ({
  type: DELETE_WORKING_TEAM_MEMBER,
  payload,
});
