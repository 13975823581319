import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createProjectCustomer,
  deleteProjectCustomer,
  fetchProjectCustomerList,
  updateProjectCustomer,
  fetchProjectCustomer,
} from '../../services/projectCustomerService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_PROJECT_CUSTOMER,
  CREATE_PROJECT_CUSTOMERS,
  DELETE_PROJECT_CUSTOMER,
  FETCH_PROJECT_CUSTOMER_LIST,
  fetchProjectCustomerListBySmProjectId,
  UPDATE_PROJECT_CUSTOMER,
  FETCH_PROJECT_CUSTOMER,
} from './projectCustomer.actions';

function* fetchProjectCustomerSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchProjectCustomer, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createProjectCustomerSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createProjectCustomer, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Added customer successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));

    yield put(fetchProjectCustomerListBySmProjectId(action.payload.smProjectId));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createProjectCustomersSaga(action: AppAction) {
  try {
    const { payload, callback } = action;
    yield put({ type: REQUEST(action.type) });

    for (const opCust of payload) {
      yield call(createProjectCustomer, opCust);
    }

    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Added customers successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));

    if (callback) {
      callback();
    }

    const smProjectId = payload[0].smProjectId;
    yield put(fetchProjectCustomerListBySmProjectId(smProjectId));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchProjectCustomerListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchProjectCustomerList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteProjectCustomerSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(deleteProjectCustomer, action.payload.id);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Deleted customer successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put(fetchProjectCustomerListBySmProjectId(action.payload.smProjectId));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateProjectCustomerSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateProjectCustomer, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Updated customer successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put(fetchProjectCustomerListBySmProjectId(data.smProjectId));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchProjectCustomer() {
  yield takeLatest(FETCH_PROJECT_CUSTOMER, fetchProjectCustomerSaga);
  yield takeLatest(CREATE_PROJECT_CUSTOMER, createProjectCustomerSaga);
  yield takeLatest(CREATE_PROJECT_CUSTOMERS, createProjectCustomersSaga);
  yield debounce(250, FETCH_PROJECT_CUSTOMER_LIST, fetchProjectCustomerListSaga);
  yield takeLatest(DELETE_PROJECT_CUSTOMER, deleteProjectCustomerSaga);
  yield takeLatest(UPDATE_PROJECT_CUSTOMER, updateProjectCustomerSaga);
}
