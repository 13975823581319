import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import history from '../../history';
import {
  createSampleDevelopmentNl,
  fetchSampleDevelopmentNl,
  fetchSampleDevelopmentNlList,
  submitSampleDevelopmentNlForApproval,
  updateSampleDevelopmentNl,
  downloadSampleDevelopmentNl,
} from '../../services/sampleDevelopmentNlService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_SAMPLE_DEVELOPMENT_NL,
  DOWNLOAD_SAMPLE_DEVELOPMENT_NL,
  FETCH_SAMPLE_DEVELOPMENT_NL,
  FETCH_SAMPLE_DEVELOPMENT_NL_LIST,
  SUBMIT_SAMPLE_DEVELOPMENT_NL_FOR_APPROVAL,
  UPDATE_SAMPLE_DEVELOPMENT_NL,
} from './sampleDevelopmentNl.actions';

const formPageUrl = `/sampleDevelopmentNl/form`;

function* fetchSampleDevelopmentNlSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchSampleDevelopmentNl, action.payload);
    if (data.salesTeamMember) {
      data.salesTeam = data.salesTeamMember.salesTeam;
    }
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchSampleDevelopmentNlListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSampleDevelopmentNlList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createSampleDevelopmentNlSaga(action: AppAction) {
  try {
    const { values } = action.payload;
    const successMessage = `Created SampleDevelopmentNl successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createSampleDevelopmentNl, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(`${formPageUrl}/${data.id}`);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateSampleDevelopmentNlSaga(action: AppAction) {
  try {
    const { values } = action.payload;
    const successMessage = `Updated SampleDevelopmentNl successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateSampleDevelopmentNl, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* submitSampleDevelopmentNlForApprovalSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const successMessage = `Submit SampleDevelopmentNl for Approval successfully.`;
    const { data } = yield call(submitSampleDevelopmentNlForApproval, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* downloadSampleDevelopmentNlSaga(action: AppAction) {
  try {
    const response = yield call(downloadSampleDevelopmentNl, action.payload);
    saveAs(response.data, 'SampleDevelopment.pdf');
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSalesTeam() {
  yield takeLatest(FETCH_SAMPLE_DEVELOPMENT_NL, fetchSampleDevelopmentNlSaga);
  yield debounce(250, FETCH_SAMPLE_DEVELOPMENT_NL_LIST, fetchSampleDevelopmentNlListSaga);
  yield takeLatest(CREATE_SAMPLE_DEVELOPMENT_NL, createSampleDevelopmentNlSaga);
  yield takeLatest(UPDATE_SAMPLE_DEVELOPMENT_NL, updateSampleDevelopmentNlSaga);
  yield takeLatest(
    SUBMIT_SAMPLE_DEVELOPMENT_NL_FOR_APPROVAL,
    submitSampleDevelopmentNlForApprovalSaga,
  );
  yield takeLatest(DOWNLOAD_SAMPLE_DEVELOPMENT_NL, downloadSampleDevelopmentNlSaga);
}
