import React from 'react';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import { SearchItemAndAddMultipleBlockStep } from './SearchItemAndAddMultipleBlockDialog';

interface SearchItemAndAddMultipleBlockStepperProps {
  activeStep: SearchItemAndAddMultipleBlockStep;
}

const SearchItemAndAddMultipleBlockStepper: React.FC<SearchItemAndAddMultipleBlockStepperProps> = props => {
  const { activeStep } = props;
  return <Stepper activeStep={activeStep}>
    <Step active={activeStep === SearchItemAndAddMultipleBlockStep.InputItem}>
      <StepLabel>Input Item to Search</StepLabel>
    </Step>
    <Step active={activeStep === SearchItemAndAddMultipleBlockStep.SearchResult}>
      <StepLabel>Search Result</StepLabel>
    </Step>
    <Step active={activeStep === SearchItemAndAddMultipleBlockStep.Summary}>
      <StepLabel>Summary</StepLabel>
    </Step>
  </Stepper>;
};

export default SearchItemAndAddMultipleBlockStepper;
