import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useTheme } from '@material-ui/core';

export type ISearchDateFieldProps = {
  onChange: (name: string, value?: string | null) => void;
  filters: any;
  filterKey: string;
  fullWidth?: boolean;
  [key: string]: any;
};

const SearchDateField: React.FC<ISearchDateFieldProps> = (props) => {
  const theme = useTheme();
  const { onChange, filters, filterKey, fullWidth, ...rest } = props;
  return (
    <div style={{ padding: theme.spacing(1) }}>
      <KeyboardDatePicker
        style={{ width: fullWidth ? '100%' : '200px' }}
        showTodayButton={true}
        margin="dense"
        format="yyyy-MM-dd"
        value={filters[filterKey]}
        onChange={(selectedDate, selectedStringDate) => {
          onChange(filterKey, selectedStringDate);
        }}
        {...rest}
      />
    </div>
  );
};

export default SearchDateField;
