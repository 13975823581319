import { CardContent, Grid, Paper, Typography, Box } from '@material-ui/core';
import React from 'react';
import { CheckBoxFastField, TextFieldFastField } from '../../../components';

interface SampleDevelopmentTlTransferProps {}

const SampleDevelopmentTlTransfer: React.FC<SampleDevelopmentTlTransferProps> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Transfer</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>Machine: </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="transferInfoMacBrand"
                label="Brand"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="transferInfoMacSeries"
                label="Brand"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={2}>
              <Typography>Requirement Spec: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="transferInfoTemperature" label="Temperature" />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="transferInfoTemperatureData"
                label="Temperature"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="transferInfoTemperatureC" label="C" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="transferInfoTemperatureF" label="F" />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="transferInfoPressure" label="Pressure" />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="transferInfoPressureData"
                label="Pressure"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="transferInfoPressurePsiBar" label="Psi Bar" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="transferInfoPressureKgCm2" label="kg/cm2" />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="transferInfoDwellTime" label="Dwell Time" />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="transferInfoDwellTimeData"
                label="Dwell"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="transferInfoDwellTimeSec" label="Sec" />
            </Grid>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={2}>
              <Typography>Variable Data: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                name="variableAmount"
                label="Variable Amount"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={8}></Grid>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={10}>
              <TextFieldFastField
                name="variableOtherData"
                label="Other"
                variant="outlined"
                margin="dense"
                fullWidth
                multiline={true}
                rows={3}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>Packing: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingPlain" label="Plain" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingEtc" label="Etc" />
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentTlTransfer;
