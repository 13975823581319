import React from 'react';
import { useUtils } from '@material-ui/pickers';
import BaseDateDisplay, { DateTimeDisplayProps } from './BaseDateTimeDisplay';

const DateTimeDisplay: React.FC<DateTimeDisplayProps> = (props) => {
  const pickerContext = useUtils()!;
  const { value } = props;
  return <BaseDateDisplay value={value} format={pickerContext.dateTime24hFormat} />;
};

export default DateTimeDisplay;
