import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Form, Formik, FormikHelpers } from 'formik';
import {
  submitForApprovalResponsibility,
  submitForUnlockApprovedResponsibility,
} from '../../../../state/responsibility';

interface ConfirmUnlockApprovedDialogProps {
  handleClose: () => void;
  open: boolean;
  responsibilityId: number;
}

const ConfirmSchema = Yup.object().shape({
  description: Yup.string().required('Description is required'),
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      marginTop: '0.5rem',
      color: 'red',
    },
    dialog: {
      minHeight: '53vh',
    },
  }),
);

const ConfirmUnlockApprovedDialog: React.FC<ConfirmUnlockApprovedDialogProps> = (
  props,
) => {
  const { open, handleClose, responsibilityId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleSubmit = (values: { description: string }, actions: FormikHelpers<any>) => {
    dispatch(submitForUnlockApprovedResponsibility(responsibilityId, values.description));
    actions.setSubmitting(false);
    handleClose();
  };

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth={true}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          description: '',
        }}
        validationSchema={ConfirmSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, errors, touched, setFieldValue }) => {
          return (
            <Form>
              <DialogTitle>Please Confirm!</DialogTitle>
              <DialogContent>
                <TextField
                  name="description"
                  type="input"
                  label="Description"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={values.description}
                  onChange={handleChange}
                  error={!!errors.description && !!touched.description}
                  helperText={errors.description}
                  multiline={true}
                  rows={4}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Confirm
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ConfirmUnlockApprovedDialog;
