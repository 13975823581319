import { PageFilter, ExcelPagingFilter } from '../state/app';

export function buildUrlSearchParams(filters: any, paging: PageFilter): URLSearchParams {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());
  const { sort } = paging;
  if (sort) {
    const direction = sort.direction === 'asc' ? sort.orderBy : `-${sort.orderBy}`;
    params.append('sort.orders', direction);
  }
  Object.entries(filters).forEach(([key, value]) => {
    if (typeof value === 'string' && value.length > 0) {
      params.append(key, value);
    } else if (value) {
      params.append(key, String(value));
    }
  });
  return params;
}

export function buildUrlExcelSearchParams(
  filters: any,
  paging: ExcelPagingFilter,
): URLSearchParams {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  const { sort } = paging;
  if (sort) {
    const direction = sort.direction === 'asc' ? sort.orderBy : `-${sort.orderBy}`;
    params.append('sort.orders', direction);
  }
  Object.entries(filters).forEach(([key, value]) => {
    if (typeof value === 'string' && value.length > 0) {
      params.append(key, value);
    } else if (value) {
      params.append(key, String(value));
    }
  });
  return params;
}
