import { call, put, takeLatest, debounce } from 'redux-saga/effects';
import {
  createOpportunityItem,
  deleteOpportunityItem,
  fetchOpportunityItemList,
  updateOpportunityItem,
  fetchOpportunityItem,
} from '../../services/opportunityItemService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_OPPORTUNITY_ITEM,
  DELETE_OPPORTUNITY_ITEM,
  FETCH_OPPORTUNITY_ITEM,
  FETCH_OPPORTUNITY_ITEM_LIST,
  fetchOpportunityItemListByOpportunityId,
  UPDATE_OPPORTUNITY_ITEM,
} from './opportunityItem.actions';

function* createOpportunityItemSaga(action: AppAction) {
  try {
    const opportunityItem = action.payload;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createOpportunityItem, opportunityItem);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Added item successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put(fetchOpportunityItemListByOpportunityId(opportunityItem.opportunityId));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchOpportunityItemListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchOpportunityItemList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchOpportunityItemSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchOpportunityItem, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteOpportunityItemSaga(action: AppAction) {
  const { payload } = action;
  const { id, opportunityId } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(deleteOpportunityItem, id);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Deleted item successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put(fetchOpportunityItemListByOpportunityId(opportunityId));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateOpportunityItemSaga(action: AppAction) {
  const opportunityItem = action.payload;
  try {
    yield put({ type: REQUEST(action.type) });
    yield call(updateOpportunityItem, action.payload);
    const { data } = yield call(fetchOpportunityItem, action.payload.id);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Updated item successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put(fetchOpportunityItemListByOpportunityId(opportunityItem.opportunityId));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchOpportunityItem() {
  yield takeLatest(CREATE_OPPORTUNITY_ITEM, createOpportunityItemSaga);
  yield debounce(250, FETCH_OPPORTUNITY_ITEM_LIST, fetchOpportunityItemListSaga);
  yield takeLatest(FETCH_OPPORTUNITY_ITEM, fetchOpportunityItemSaga);
  yield takeLatest(DELETE_OPPORTUNITY_ITEM, deleteOpportunityItemSaga);
  yield takeLatest(UPDATE_OPPORTUNITY_ITEM, updateOpportunityItemSaga);
}
