import { AppAction, PageFilter } from '../app';

export const FETCH_SUBTASK_TYPE_LIST = 'FETCH_SUBTASK_TYPE_LIST';

export const fetchSubtaskTypeList = (filters: unknown, paging: PageFilter): AppAction => {
  return {
    type: FETCH_SUBTASK_TYPE_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchAllSubtaskTypeList = (): AppAction => {
  return fetchSubtaskTypeList(
    {},
    {
      pageNumber: 0,
      pageSize: 9999,
    },
  );
};
