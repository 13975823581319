import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { ICreateBlockForm } from './BlockCreateDialog';
import useStyles from './BlockCreateSummary.style';

interface IBlockCreateSummaryProps {
  formValues: ICreateBlockForm;
}

const BlockCreateSummary: React.FC<IBlockCreateSummaryProps> = (props) => {
  const { formValues } = props;
  const classes = useStyles();
  return (
    <Table className={classes.table}>
      <TableBody>
        <TableRow>
          <TableCell>Product Type</TableCell>
          <TableCell className={classes.textBold}>
            {!!formValues.productType ? formValues.productType.name : ''}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Use Thermal Printing (.TM Block)</TableCell>
          <TableCell className={classes.textBold}>
            {formValues.isThermalPrinting ? 'Yes' : 'No'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Program</TableCell>
          <TableCell className={classes.textBold}>
            {!!formValues.program ? formValues.program.name : ''}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Brand</TableCell>
          <TableCell className={classes.textBold}>
            {!!formValues.brand ? formValues.brand.name : ''}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Block Prefix</TableCell>
          <TableCell className={classes.textBold}>
            {!!formValues.blockPrefix ? formValues.blockPrefix.prefix : ''}
          </TableCell>
        </TableRow>
        {/* <TableRow>
          <TableCell>Sample Dev/NPD</TableCell>
          <TableCell className={classes.textBold}>
            {!!formValues.user
              ? `(${formValues.user.login}) ${formValues.user.firstName} ${formValues.user.lastName}`
              : ''}
          </TableCell>
        </TableRow> */}
      </TableBody>
    </Table>
  );
};

export default BlockCreateSummary;
