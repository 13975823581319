import { Box, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { CheckBoxFastField } from '../../../components';

interface SampleDevelopmentWlProductLevelProps {}

const SampleDevelopmentWlProductLevel: React.FC<
  SampleDevelopmentWlProductLevelProps
> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Product Level</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>Product Level:</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="topProductLevel" label="Top Product" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField
                name="intermediateProductLevel"
                label="Intermediate Product"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="lowerProductLevel" label="Lower Product" />
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentWlProductLevel;
