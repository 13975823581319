import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogTitle,
  InputAdornment,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { IOpportunity } from '../../../../models/opportunity.model';
import { Brand } from '../../../../state/brand';
import {
  changeProgramAndBrand,
  ChangeProgramBrandPayload,
} from '../../../../state/opportunity';
import { Program } from '../../../../state/program';
import { BrandComboBox, ProgramComboBox } from '../../../components';
import { IOpportunityFormValue } from './OpportunityForm';
import { ProjectComboBox } from '../../../components/ProjectComboBox';
import { IProject } from '../../../../models/project.model';
import {
  Close as CloseIcon,
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
  Done as DoneIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';

interface OpportunityChangeProgramBrandDialogProps {
  opportunity: IOpportunity | null;
  open: boolean;
  onClose: () => void;
}

const OpportunitySchema = Yup.object().shape({
  program: Yup.object().nullable(true).required('Program is required'),
  brand: Yup.object().nullable(true).required('Brand is required'),
});

type dialogStep = 'changeProgramBrand' | 'confirmChangeProgramBrand';

const OpportunityChangeProgramBrandDialog: React.FC<
  OpportunityChangeProgramBrandDialogProps
> = (props) => {
  const dispatch = useDispatch();
  const { open, onClose, opportunity } = props;

  const [changeProgramBrandDlgStep, setChangeProgramBrandDlgStep] =
    useState<dialogStep>('changeProgramBrand');
  const [changeProgramBrandPayload, setChangeProgramBrandPayload] =
    useState<ChangeProgramBrandPayload>({});

  const initialFormValues: IOpportunityFormValue = useMemo(() => {
    const { program, brand, smProject } = opportunity!;
    return { program, brand, smProject };
  }, [opportunity]);

  const handleSubmit = (
    values: IOpportunityFormValue,
    actions: FormikHelpers<IOpportunityFormValue>,
  ) => {
    setChangeProgramBrandPayload({
      opportunityId: opportunity?.id,
      program: values.program,
      programId: values.program?.id,
      brand: values.brand,
      brandId: values.brand?.id,
      updateItem: true,
      smProject: values.smProject,
      smProjectId: values.smProject?.id,
    });

    setChangeProgramBrandDlgStep('confirmChangeProgramBrand');
    actions.setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'} fullWidth>
      <Formik
        enableReinitialize={true}
        initialValues={initialFormValues}
        validationSchema={OpportunitySchema}
        onSubmit={handleSubmit}
        validateOnChange={true}
      >
        {({ values, submitForm, errors, touched, setFieldValue, setFieldTouched }) => {
          const isError = (fieldName: keyof IOpportunityFormValue) =>
            !!touched[fieldName] && !!errors[fieldName];
          const errorMessage = (fieldName: keyof IOpportunityFormValue) =>
            !!touched[fieldName] ? errors[fieldName] : '';
          return (
            <Form>
              {changeProgramBrandDlgStep === 'changeProgramBrand' && (
                <>
                  <DialogTitle>Change Program And Brand</DialogTitle>
                  <CardContent>
                    <TextField
                      name="company"
                      type="input"
                      label="Company"
                      variant="outlined"
                      margin="dense"
                      value={opportunity?.company?.name}
                      fullWidth
                    />
                    <TextField
                      name="assignmentNumber"
                      type="input"
                      label="Assignment Number"
                      variant="outlined"
                      margin="dense"
                      value={opportunity?.assignmentNumber}
                      fullWidth
                    />
                    <ProjectComboBox
                      handleChange={(result) => {
                        setFieldValue('smProject', result);
                      }}
                      selectedValue={values.smProject as IProject}
                      error={isError('smProject')}
                      helperText={errorMessage('smProject')}
                    />
                    <ProgramComboBox
                      handleChange={(result) => {
                        setFieldValue('program', result ? result : null);
                        setFieldTouched('program', true, false);
                      }}
                      selectedValue={values.program as Program}
                      error={isError('program')}
                      helperText={errorMessage('program')}
                    />
                    <BrandComboBox
                      handleChange={(result) => {
                        setFieldValue('brand', result ? result : null);
                        setFieldTouched('brand', true, false);
                      }}
                      selectedValue={values.brand as Brand}
                      error={isError('brand')}
                      helperText={errorMessage('brand')}
                    />
                  </CardContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={() => {
                        onClose();
                      }}
                      startIcon={<CloseIcon />}
                    >
                      Close
                    </Button>
                    <Button
                      variant={'contained'}
                      color="primary"
                      onClick={() => {
                        submitForm();
                      }}
                      startIcon={<NavigateNextIcon />}
                    >
                      NEXT
                    </Button>
                  </DialogActions>
                </>
              )}
              {changeProgramBrandDlgStep === 'confirmChangeProgramBrand' && (
                <>
                  <DialogTitle>Confirm Change Program And Brand</DialogTitle>
                  <CardContent>
                    <TextField
                      name="company"
                      type="input"
                      label="Company"
                      variant="outlined"
                      margin="dense"
                      value={opportunity?.company?.name}
                      fullWidth
                    />
                    <TextField
                      name="assignmentNumber"
                      type="input"
                      label="Assignment Number"
                      variant="outlined"
                      margin="dense"
                      value={opportunity?.assignmentNumber}
                      fullWidth
                    />
                    <TextField
                      name="project"
                      type="input"
                      label="Project"
                      variant="outlined"
                      margin="dense"
                      value={opportunity?.smProject?.name}
                      fullWidth
                    />
                    <TextField
                      name="project"
                      type="input"
                      label="Change To Project"
                      variant="filled"
                      margin="dense"
                      value={changeProgramBrandPayload.smProject?.name}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {opportunity?.smProject?.name !==
                            changeProgramBrandPayload.smProject?.name ? (
                              <>
                                <Tooltip title="Confirm Change Project">
                                  <WarningIcon style={{ color: 'red' }} />
                                </Tooltip>
                              </>
                            ) : (
                              <DoneIcon style={{ color: 'green' }} />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      name="programName"
                      type="input"
                      label="Program"
                      variant="outlined"
                      margin="dense"
                      value={opportunity?.program?.name}
                      fullWidth
                    />
                    <TextField
                      name="changeToProgramName"
                      type="input"
                      label="Change To Program"
                      variant="filled"
                      margin="dense"
                      value={changeProgramBrandPayload.program?.name}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {opportunity?.program?.name !==
                            changeProgramBrandPayload.program?.name ? (
                              <>
                                <Tooltip title="Confirm Change Program">
                                  <WarningIcon style={{ color: 'red' }} />
                                </Tooltip>
                              </>
                            ) : (
                              <DoneIcon style={{ color: 'green' }} />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      name="brandName"
                      type="input"
                      label="Brand Name"
                      variant="outlined"
                      margin="dense"
                      value={opportunity?.brand?.name}
                      fullWidth
                    />
                    <TextField
                      name="changeToBrandName"
                      type="input"
                      label="Change To Brand Name"
                      variant="filled"
                      margin="dense"
                      value={changeProgramBrandPayload.brand?.name}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {opportunity?.brand?.name !==
                            changeProgramBrandPayload.brand?.name ? (
                              <>
                                <Tooltip title="Confirm Change Brand">
                                  <WarningIcon style={{ color: 'red' }} />
                                </Tooltip>
                              </>
                            ) : (
                              <DoneIcon style={{ color: 'green' }} />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </CardContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={() => {
                        setChangeProgramBrandDlgStep('changeProgramBrand');
                      }}
                      startIcon={<NavigateBeforeIcon />}
                    >
                      BACK
                    </Button>
                    <Button
                      variant={'contained'}
                      color="primary"
                      onClick={() => {
                        dispatch(changeProgramAndBrand(changeProgramBrandPayload));
                        onClose();
                      }}
                      startIcon={<DoneIcon />}
                    >
                      Confirm
                    </Button>
                  </DialogActions>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default OpportunityChangeProgramBrandDialog;
