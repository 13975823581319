import { AppAction, PageFilter } from '../../../../state/app';

export const FETCH_PENDING_APPROVAL_SAMPLE_DEV_TL_LIST =
  'FETCH_PENDING_APPROVAL_SAMPLE_DEV_TL_LIST';

export const fetchPendingApprovalSampleDevTlList = (paging: PageFilter): AppAction => {
  return {
    type: FETCH_PENDING_APPROVAL_SAMPLE_DEV_TL_LIST,
    payload: {
      filters: { 'HasResponded.EqualsTo': 'false' },
      paging,
    },
  };
};
