import React, { useState } from 'react';
import { ISubtaskItem, IUpdatedSubtaskItem } from '../../../../models/subtaskItem.model';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { SubtaskItemStatusLabel, SubtaskItemStatus } from '../../../../constants';
import { SubtaskItemStatusType } from '../../../../constants/subtaskItemStatus';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateSubtaskItemsStatus,
  SubtaskItemState,
  clearUpdatedItems,
} from '../../../../state/subtaskItem';
import { AppState } from '../../../../state/configureStore';
import SubtaskItemCloseDialog from './subtaskItem/SubtaskItemCloseDialog';
import SubtaskItemDoneDialog from './subtaskItem/SubtaskItemDoneDialog';
import { ISubtaskType } from '../../../../models/subtaskType.model';
import { SubtaskTypeState } from '../../../../state/subtaskType';
import ArtworkLinkDialog from './ArtworkLinkDialog';
import { cloneDeep } from 'lodash';
import { Close as CloseIcon, Save as SaveIcon } from '@material-ui/icons';

interface SubtaskItemEditStatusProps {
  onClose: () => void;
  open: boolean;
  subtaskItems: ReadonlyArray<ISubtaskItem>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
    },
    formContainer: {
      display: 'flex',
    },
  }),
);

const SubtaskItemEditStatusDialog: React.FC<SubtaskItemEditStatusProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { onClose, open, subtaskItems } = props;
  const [openSubtaskItemCloseDialog, setOpenSubtaskItemCloseDialog] =
    useState<boolean>(false);
  const [openSubtaskItemDoneDialog, setOpenSubtaskItemDoneDialog] =
    useState<boolean>(false);
  const [openArtworkLinkDialog, setOpenArtworkLinkDialog] = useState<boolean>(false);
  const { items: subtaskTypeList } = useSelector<AppState, SubtaskTypeState>(
    (state) => state.subtaskType,
  );
  const subtaskTypeArtwork = subtaskTypeList.find(
    (subtaskType: ISubtaskType) => subtaskType.code === 'ARTWORK',
  );

  const [selectedStatus, setSelectedStatus] = useState<SubtaskItemStatusType>('PENDING');
  const { updatedItems } = useSelector<AppState, SubtaskItemState>(
    (state) => state.subtaskItem,
  );
  const subtaskItemList: IUpdatedSubtaskItem[] =
    updatedItems.length === 0
      ? (subtaskItems as ISubtaskItem[])
      : (updatedItems as IUpdatedSubtaskItem[]);
  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedStatus(event.target.value as SubtaskItemStatusType);
  };
  const handleUpdate = () => {
    if (
      selectedStatus === 'WAITING_FOR_SALES' &&
      subtaskTypeArtwork &&
      subtaskItemList[0].smSubtask!.smSubtaskTypeId === subtaskTypeArtwork.id
    ) {
      setOpenArtworkLinkDialog(true);
    } else if (selectedStatus === 'CLOSE') {
      setOpenSubtaskItemCloseDialog(true);
    } else if (selectedStatus === 'DONE') {
      setOpenSubtaskItemDoneDialog(true);
    } else {
      const newSubtaskItemList = subtaskItemList.map((subtaskItem) => ({
        ...subtaskItem,
        status: selectedStatus,
      }));
      dispatch(updateSubtaskItemsStatus(newSubtaskItemList));
    }
  };

  const handleClose = () => {
    dispatch(clearUpdatedItems());
    setSelectedStatus('PENDING');
    onClose();
  };

  const handleOkInArtworkLinkDialog = (subtaskItems: ISubtaskItem[]) => {
    const subtaskItemListClone = cloneDeep(subtaskItems);
    const newSubtaskItemList = subtaskItemListClone.map((subtaskItem) => ({
      ...subtaskItem,
      status: 'WAITING_FOR_SALES',
    }));
    dispatch(updateSubtaskItemsStatus(newSubtaskItemList as ISubtaskItem[]));
    setOpenArtworkLinkDialog(false);
  };

  return (
    <>
      <SubtaskItemCloseDialog
        open={openSubtaskItemCloseDialog}
        onClose={() => {
          setOpenSubtaskItemCloseDialog(false);
        }}
        subtaskItems={subtaskItems}
      />
      <SubtaskItemDoneDialog
        open={openSubtaskItemDoneDialog}
        onClose={() => {
          setOpenSubtaskItemDoneDialog(false);
        }}
        subtaskItems={subtaskItems}
      />
      <ArtworkLinkDialog
        open={openArtworkLinkDialog}
        onOk={handleOkInArtworkLinkDialog}
        subtaskItems={subtaskItems}
      />
      <Dialog onClose={handleClose} open={open} maxWidth="md">
        <DialogTitle>Edit Subtask Item Status</DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <InputLabel>Status</InputLabel>
            <Select value={selectedStatus} onChange={handleSelectChange}>
              {Object.values(SubtaskItemStatus).map((status) => {
                return (
                  <MenuItem key={status} value={status}>
                    {SubtaskItemStatusLabel[status]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>Block</TableCell>
                <TableCell>Program</TableCell>
                <TableCell>Brand</TableCell>
                <TableCell>Item</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>UpdatedStatus</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subtaskItemList.map((subtaskItem, index) => {
                return (
                  <TableRow key={subtaskItem.id!}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {subtaskItem.smTaskItem!.opportunityItem!.block!.blockNo}
                    </TableCell>
                    <TableCell>
                      {
                        subtaskItem.smTaskItem!.opportunityItem!.opportunity!.program!
                          .name
                      }
                    </TableCell>
                    <TableCell>
                      {subtaskItem.smTaskItem!.opportunityItem!.opportunity!.brand!.name}
                    </TableCell>
                    <TableCell>
                      {subtaskItem.smTaskItem!.opportunityItem!.block!.item}
                    </TableCell>
                    <TableCell>{SubtaskItemStatusLabel[subtaskItem.status!]}</TableCell>
                    <TableCell>
                      {subtaskItem.updateStatus ? subtaskItem.updateStatus : ''}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="default"
            variant="contained"
            startIcon={<CloseIcon />}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleUpdate}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubtaskItemEditStatusDialog;
