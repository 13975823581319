import { Chip } from '@material-ui/core';
import React from 'react';
import { UserStatus } from '../pages/opportunity/OpportunityForm/OpportunityForm';

interface ChipListProps {
  list: UserStatus[];
  noAllLabel?: boolean;
}

const ChipListUser: React.FC<ChipListProps> = (props) => {
  const { list } = props;
  if (list.length <= 0) {
    return <></>;
  } else {
    return (
      <>
        {list.map((chipLabel) => {
          return (
            <Chip
              key={chipLabel.name}
              label={chipLabel.name}
              variant="outlined"
              color={chipLabel.activated ? 'default' : 'secondary'}
            />
          );
        })}
      </>
    );
  }
};

export default ChipListUser;
