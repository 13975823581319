import { AppAction, PageFilter } from '../app';
import { Brand, BrandCreateOrUpdatePayload, BrandListFilter } from './brand.types';

export const FETCH_BRAND = 'FETCH_BRAND';
export const FETCH_BRAND_LIST = 'FETCH_BRAND_LIST';
export const CREATE_BRAND = 'CREATE_BRAND';
export const UPDATE_BRAND = 'UPDATE_BRAND';
export const CLEAR_BRAND = 'CLEAR_BRAND';

export const fetchBrand = (id: Brand['id']): AppAction => ({
  type: FETCH_BRAND,
  payload: id,
});

export const fetchBrandList = (
  filters: BrandListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_BRAND_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createBrand = (payload: BrandCreateOrUpdatePayload): AppAction => ({
  type: CREATE_BRAND,
  payload,
});

export const updateBrand = (payload: BrandCreateOrUpdatePayload): AppAction => ({
  type: UPDATE_BRAND,
  payload,
});

export const clearBrand = () => ({
  type: CLEAR_BRAND,
});
