import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './sampleDevelopmentNl.style';
import { CheckBoxFastField, TextFieldFastField } from '../../../components';

interface SamDevNlDesignInformationProps {}

const SampleDevelopmentNlDesignInformation: React.FC<SamDevNlDesignInformationProps> = (
  props,
) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Typography className={classes.numberTitle} display="inline">
        2. Information
      </Typography>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="infoOriginal" label="Original" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="infoFile" label="File" />
        </Grid>

        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="infoSpecsheet" label="Specsheet" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="infoBlockFilmPlate" label="Block/Film/Plate" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="infoOther" label="Others" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="infoOtherData"
            label="Others"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SampleDevelopmentNlDesignInformation;
