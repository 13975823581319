import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createEBlockPattern,
  fetchEBlockPattern,
  fetchEBlockPatternList,
  updateEBlockPattern,
} from '../../services/eBlockPatternService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_EBLOCK_PATTERN,
  FETCH_EBLOCK_PATTERN,
  FETCH_EBLOCK_PATTERN_LIST,
  UPDATE_EBLOCK_PATTERN,
} from './eBlockPattern.action';
import { EBlockPatternCreateOrUpdatePayload } from './eBlockPattern.type';

const listPageUrl = `/settings/eBlockPatterns`;

function* fetchEBlockPatternListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchEBlockPatternList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchEBlockPatternSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchEBlockPattern, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createEBlockPatternSaga(action: AppAction) {
  try {
    const { values, history } = action.payload as EBlockPatternCreateOrUpdatePayload;
    const successMessage = `Created ${values.code} eblock pattern successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createEBlockPattern, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    const formUrl = `${listPageUrl}/form/${data.id}`;
    history.replace(formUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateEBlockPatternSaga(action: AppAction) {
  try {
    const { values } = action.payload as EBlockPatternCreateOrUpdatePayload;
    const successMessage = `Updated ${values.code} eblock size successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateEBlockPattern, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchEBlockPattern() {
  yield debounce(250, FETCH_EBLOCK_PATTERN_LIST, fetchEBlockPatternListSaga);
  yield takeLatest(FETCH_EBLOCK_PATTERN, fetchEBlockPatternSaga);
  yield takeLatest(CREATE_EBLOCK_PATTERN, createEBlockPatternSaga);
  yield takeLatest(UPDATE_EBLOCK_PATTERN, updateEBlockPatternSaga);
}
