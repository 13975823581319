import React from 'react';
import { CircularProgress } from '@material-ui/core';

interface LoadingProps {
  isLoading: boolean;
  loadingWrapper?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
}

const Loading: React.FC<LoadingProps> = (props) => {
  const { isLoading, children, loadingWrapper } = props;
  const LoadingWrapperComponent = !!loadingWrapper ? loadingWrapper : 'div';
  if (isLoading) {
    return (<LoadingWrapperComponent>
      <CircularProgress/>
    </LoadingWrapperComponent>);
  }
  return <React.Fragment>{children}</React.Fragment>;
};

export default Loading;
