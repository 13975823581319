import { ISampleDevelopmentWl } from '../../models/sampleDevelopmentWl.model';
import { AppAction, PageFilter } from '../app';
import { SampleDevelopmentWlListFilter } from './sampleDevelopmentWl.types';

export const FETCH_SAMPLE_DEVELOPMENT_WL_LIST = 'FETCH_SAMPLE_DEVELOPMENT_WL_LIST';
export const FETCH_SAMPLE_DEVELOPMENT_WL = 'FETCH_SAMPLE_DEVELOPMENT_WL';
export const CREATE_SAMPLE_DEVELOPMENT_WL = 'CREATE_SAMPLE_DEVELOPMENT_WL';
export const UPDATE_SAMPLE_DEVELOPMENT_WL = 'UPDATE_SAMPLE_DEVELOPMENT_WL';
export const CLEAR_SAMPLE_DEVELOPMENT_WL = 'CLEAR_SAMPLE_DEVELOPMENT_WL';
export const SUBMIT_SAMPLE_DEVELOPMENT_WL_FOR_APPROVAL =
  'SUBMIT_SAMPLE_DEVELOPMENT_WL_FOR_APPROVAL';
export const DOWNLOAD_SAMPLE_DEVELOPMENT_WL = 'DOWNLOAD_SAMPLE_DEVELOPMENT_WL';

export const fetchSampleDevelopmentWlList = (
  filters: SampleDevelopmentWlListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SAMPLE_DEVELOPMENT_WL_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const downloadSampleDevelopmentWl = (id: number): AppAction => {
  return {
    type: DOWNLOAD_SAMPLE_DEVELOPMENT_WL,
    payload: id,
  };
};

export const fetchSampleDevelopmentWl = (id: number): AppAction => ({
  type: FETCH_SAMPLE_DEVELOPMENT_WL,
  payload: id,
});

export const createSampleDevelopmentWl = (payload: {
  values: ISampleDevelopmentWl;
}): AppAction => ({
  type: CREATE_SAMPLE_DEVELOPMENT_WL,
  payload,
});

export const updateSampleDevelopmentWl = (payload: {
  values: ISampleDevelopmentWl;
}): AppAction => ({
  type: UPDATE_SAMPLE_DEVELOPMENT_WL,
  payload,
});

export const clearSampleDevelopmentWl = () => ({
  type: CLEAR_SAMPLE_DEVELOPMENT_WL,
});

export const submitSampleDevelopmentWlForApproval = (id: number): AppAction => ({
  type: SUBMIT_SAMPLE_DEVELOPMENT_WL_FOR_APPROVAL,
  payload: id,
});
