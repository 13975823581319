export type ProjectStatusType =
  | 'PENDING_FOR_APPROVAL'
  | 'IN_REQUEST'
  | 'REJECTED'
  | 'APPROVED'
  | 'PENDING_FOR_UNLOCK_APPROVED';

const ProjectStatus = Object.freeze({
  PENDING_FOR_APPROVAL: 'PENDING_FOR_APPROVAL' as ProjectStatusType,
  IN_REQUEST: 'IN_REQUEST' as ProjectStatusType,
  REJECTED: 'REJECTED' as ProjectStatusType,
  APPROVED: 'APPROVED' as ProjectStatusType,
  PENDING_FOR_UNLOCK_APPROVED: 'PENDING_FOR_UNLOCK_APPROVED' as ProjectStatusType,
});

export default ProjectStatus;

export const ProjectStatusLabel = Object.freeze({
  [ProjectStatus.PENDING_FOR_APPROVAL]: 'Pending For Approval',
  [ProjectStatus.IN_REQUEST]: 'In Request',
  [ProjectStatus.REJECTED]: 'Rejected',
  [ProjectStatus.APPROVED]: 'Approved',
  [ProjectStatus.PENDING_FOR_UNLOCK_APPROVED]: 'Pending For Unlock Approved',
});
