import { Grid } from '@material-ui/core';
import React from 'react';
import { SearchField } from '..';
import { SampleDevelopmentQueueListFilter } from '../../../state/sampleDevelopmentQueue';
import { FilterContainer, IFilterContainerConfigProps } from '../FilterContainer';

const SampleDevelopmentQueueDialogFilter: React.FC<
  IFilterContainerConfigProps<SampleDevelopmentQueueListFilter>
> = (props) => {
  return (
    <FilterContainer
      {...props}
      render={(filterProps) => {
        const { localFilter, handleChangeFilter } = filterProps;

        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <SearchField
                label="Digital Proof Code"
                onChange={handleChangeFilter}
                filterKey="QueueDigitalProofCode.StartsWith"
                filters={localFilter}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <SearchField
                label="Digital Proof Name"
                onChange={handleChangeFilter}
                filterKey="QueueDigitalProofName.StartsWith"
                filters={localFilter}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <SearchField
                label="Plate Code"
                onChange={handleChangeFilter}
                filterKey="QueuePlateCode.StartsWith"
                filters={localFilter}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <SearchField
                label="Plate Name"
                onChange={handleChangeFilter}
                filterKey="QueuePlateName.StartsWith"
                filters={localFilter}
                fullWidth
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default SampleDevelopmentQueueDialogFilter;
