import { CardContent, Grid, Paper, Typography, Box } from '@material-ui/core';
import React from 'react';
import { CheckBoxFastField, TextFieldFastField } from '../../../components';

interface SampleDevelopmentWlAssemblyProps {}

const SampleDevelopmentWlAssembly: React.FC<SampleDevelopmentWlAssemblyProps> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>WL Assembly</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>WL Assembly:</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField
                name="accessoriesAdhesive"
                label="กาวการ์ตูน(Adhesive)"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="accessoriesVerane" label="วีราเน่(Verane)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="accessoriesColor1"
                label="Color1"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="accessoriesColor2"
                label="Color2"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="accessoriesCapoc" label="ยัดนุ่น" />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="accessoriesSmockingStitch" label="แซ็กริม" />
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentWlAssembly;
