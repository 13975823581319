import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Close as CloseIcon, Check as CheckIcon } from '@material-ui/icons';
import { DialogContent } from '@material-ui/core';

interface ConfirmDialogProps {
  open: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
  handleClose: () => void;
  content: string;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const { open, handleCancel, handleConfirm, handleClose, content } = props;
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{content}</DialogTitle>
      <DialogContent style={{ height: 30 }}></DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          color="default"
          variant="contained"
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
