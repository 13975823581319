import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createEBlockSize,
  fetchEBlockSize,
  fetchEBlockSizeList,
  updateEBlockSize,
} from '../../services/eBlockSizeService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_EBLOCK_SIZE,
  FETCH_EBLOCK_SIZE,
  FETCH_EBLOCK_SIZE_LIST,
  UPDATE_EBLOCK_SIZE,
} from './eBlockSize.action';
import { EBlockSizeCreateOrUpdatePayload } from './eBlockSize.type';

const listPageUrl = `/settings/eBlockSizes`;

function* fetchEBlockSizeListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchEBlockSizeList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchEBlockSizeSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchEBlockSize, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createEBlockSizeSaga(action: AppAction) {
  try {
    const { values, history } = action.payload as EBlockSizeCreateOrUpdatePayload;
    const successMessage = `Created ${values.code} eblock size successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createEBlockSize, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    const formUrl = `${listPageUrl}/form/${data.id}`;
    history.replace(formUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateEBlockSizeSaga(action: AppAction) {
  try {
    const { values } = action.payload as EBlockSizeCreateOrUpdatePayload;
    const successMessage = `Updated ${values.code} eblock size successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateEBlockSize, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchEBlockSize() {
  yield debounce(250, FETCH_EBLOCK_SIZE_LIST, fetchEBlockSizeListSaga);
  yield takeLatest(FETCH_EBLOCK_SIZE, fetchEBlockSizeSaga);
  yield takeLatest(CREATE_EBLOCK_SIZE, createEBlockSizeSaga);
  yield takeLatest(UPDATE_EBLOCK_SIZE, updateEBlockSizeSaga);
}
