import { AppAction, PageFilter } from '../app';
import {
  CustomerGroupMember,
  CustomerGroupMemberCreateOrUpdatePayload,
  CustomerGroupMemberListFilter,
} from './customerGroupMember.types';

export const FETCH_CUSTOMERGROUPMEMBER = 'FETCH_CUSTOMERGROUPMEMBER';
export const FETCH_CUSTOMERGROUPMEMBER_LIST = 'FETCH_CUSTOMERGROUPMEMBER_LIST';
export const CREATE_CUSTOMERGROUPMEMBER = 'CREATE_CUSTOMERGROUPMEMBER';
export const UPDATE_CUSTOMERGROUPMEMBER = 'UPDATE_CUSTOMERGROUPMEMBER';
export const CLEAR_CUSTOMERGROUPMEMBER = 'CLEAR_CUSTOMERGROUPMEMBER';
export const DELETE_CUSTOMERGROUPMEMBER = 'DELETE_CUSTOMERGROUPMEMBER';

export const fetchCustomerGroupMember = (id: CustomerGroupMember['id']): AppAction => ({
  type: FETCH_CUSTOMERGROUPMEMBER,
  payload: id,
});

export const fetchCustomerGroupMemberList = (
  filters: CustomerGroupMemberListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_CUSTOMERGROUPMEMBER_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createCustomerGroupMember = (payload: CustomerGroupMemberCreateOrUpdatePayload): AppAction => ({
  type: CREATE_CUSTOMERGROUPMEMBER,
  payload,
});

export const updateCustomerGroupMember = (payload: CustomerGroupMemberCreateOrUpdatePayload): AppAction => ({
  type: UPDATE_CUSTOMERGROUPMEMBER,
  payload,
});

export const clearCustomerGroupMember = () => ({
  type: CLEAR_CUSTOMERGROUPMEMBER,
});

export const deleteCustomerGroupMember = (payload: { id: number }) => ({
  type: DELETE_CUSTOMERGROUPMEMBER,
  payload
});


