import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import history from '../../history';
import {
  createSampleDevelopmentWl,
  fetchSampleDevelopmentWl,
  fetchSampleDevelopmentWlList,
  submitSampleDevelopmentWlForApproval,
  updateSampleDevelopmentWl,
  downloadSampleDevelopmentWl,
} from '../../services/sampleDevelopmentWlService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_SAMPLE_DEVELOPMENT_WL,
  DOWNLOAD_SAMPLE_DEVELOPMENT_WL,
  FETCH_SAMPLE_DEVELOPMENT_WL,
  FETCH_SAMPLE_DEVELOPMENT_WL_LIST,
  SUBMIT_SAMPLE_DEVELOPMENT_WL_FOR_APPROVAL,
  UPDATE_SAMPLE_DEVELOPMENT_WL,
} from './sampleDevelopmentWl.actions';

const formPageUrl = `/sampleDevelopmentWl/form`;

function* fetchSampleDevelopmentWlSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchSampleDevelopmentWl, action.payload);
    if (data.salesTeamMember) {
      data.salesTeam = data.salesTeamMember.salesTeam;
    }
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchSampleDevelopmentWlListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSampleDevelopmentWlList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createSampleDevelopmentWlSaga(action: AppAction) {
  try {
    const { values } = action.payload;
    const successMessage = `Created SampleDevelopmentWl successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createSampleDevelopmentWl, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(`${formPageUrl}/${data.id}`);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateSampleDevelopmentWlSaga(action: AppAction) {
  try {
    const { values } = action.payload;
    const successMessage = `Updated SampleDevelopmentWl successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateSampleDevelopmentWl, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* submitSampleDevelopmentWlForApprovalSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const successMessage = `Submit SampleDevelopmentWl for Approval successfully.`;
    const { data } = yield call(submitSampleDevelopmentWlForApproval, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* downloadSampleDevelopmentWlSaga(action: AppAction) {
  try {
    const response = yield call(downloadSampleDevelopmentWl, action.payload);
    saveAs(response.data, 'SampleDevelopment.pdf');
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSalesTeam() {
  yield takeLatest(FETCH_SAMPLE_DEVELOPMENT_WL, fetchSampleDevelopmentWlSaga);
  yield debounce(250, FETCH_SAMPLE_DEVELOPMENT_WL_LIST, fetchSampleDevelopmentWlListSaga);
  yield takeLatest(CREATE_SAMPLE_DEVELOPMENT_WL, createSampleDevelopmentWlSaga);
  yield takeLatest(UPDATE_SAMPLE_DEVELOPMENT_WL, updateSampleDevelopmentWlSaga);
  yield takeLatest(
    SUBMIT_SAMPLE_DEVELOPMENT_WL_FOR_APPROVAL,
    submitSampleDevelopmentWlForApprovalSaga,
  );
  yield takeLatest(DOWNLOAD_SAMPLE_DEVELOPMENT_WL, downloadSampleDevelopmentWlSaga);
}
