import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import React from 'react';
import * as Yup from 'yup';
import {
  ResponsibilityApprovalRequest,
  ResponsibilityApprovalResponse,
  updateResponsibilityApprovalResponse,
} from '../../../state/responsibilityApprovalRequest';
import { useDispatch } from 'react-redux';
import { Form, Formik, FormikHelpers } from 'formik';
import useStyles from './confirmDialog.style';
import { ResponsibilityStatusType } from '../../../constants/responsibilityStatus';
import { IResponsibilityApprovalRequest } from '../../../models/responsibilityApprovalRequest.model';
import { Close as CloseIcon, Check as CheckIcon } from '@material-ui/icons';

interface ConfirmDialogProps {
  handleClose: () => void;
  open: boolean;
  responseResult: ResponsibilityStatusType;
  responsibilityApprovalRequest: IResponsibilityApprovalRequest;
}

const ConfirmSchema = Yup.object().shape({
  comment: Yup.string().nullable(),
  responseResult: Yup.string(),
});

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const {
    open,
    handleClose,
    responseResult,
    responsibilityApprovalRequest: { comment, id: responsibilityApprovalRequestId = 0 },
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleSubmit = (
    values: ResponsibilityApprovalResponse,
    actions: FormikHelpers<any>,
  ) => {
    dispatch(updateResponsibilityApprovalResponse(values));
    actions.setSubmitting(false);
    handleClose();
  };

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          comment: comment ? comment : '',
          responseResult,
          responsibilityApprovalRequestId,
        }}
        validationSchema={ConfirmSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, errors, touched, setFieldValue }) => {
          return (
            <Form>
              <DialogTitle>Please Confirm!</DialogTitle>
              <DialogContent>
                <DialogContentText>Status: {values.responseResult}</DialogContentText>
                <TextField
                  name="comment"
                  type="input"
                  label="Comment"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={values.comment}
                  onChange={handleChange}
                  error={!!errors.comment && !!touched.comment}
                  helperText={errors.comment}
                  multiline={true}
                  rows={4}
                />
              </DialogContent>
              <DialogActions style={{ marginRight: '5px', marginBottom: '10px' }}>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  variant="outlined"
                  startIcon={<CloseIcon />}
                >
                  Cancel
                </Button>
                <Button
                  type={'submit'}
                  color="primary"
                  variant="outlined"
                  startIcon={<CheckIcon />}
                >
                  Confirm
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ConfirmDialog;
