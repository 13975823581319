import { AppAction, PageFilter } from '../app';
import { EBlockSizeCreateOrUpdatePayload, EBlockSizeListFilter } from './eBlockSize.type';
import { IEBlockSize } from '../../models/eBlockSize';

export const FETCH_EBLOCK_SIZE = 'FETCH_EBLOCK_SIZE';
export const FETCH_EBLOCK_SIZE_LIST = 'FETCH_EBLOCK_SIZE_LIST';
export const CREATE_EBLOCK_SIZE = 'CREATE_EBLOCK_SIZE';
export const UPDATE_EBLOCK_SIZE = 'UPDATE_EBLOCK_SIZE';
export const CLEAR_EBLOCK_SIZE = 'CLEAR_EBLOCK_SIZE';

export const fetchEBlockSize = (id: IEBlockSize['id']): AppAction => ({
  type: FETCH_EBLOCK_SIZE,
  payload: id,
});

export const fetchEBlockSizeList = (
  filters: EBlockSizeListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_EBLOCK_SIZE_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createEBlockSize = (
  payload: EBlockSizeCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_EBLOCK_SIZE,
  payload,
});

export const updateEBlockSize = (
  payload: EBlockSizeCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_EBLOCK_SIZE,
  payload,
});

export const clearEBlockSize = () => ({
  type: CLEAR_EBLOCK_SIZE,
});
