import { Box, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { CheckBoxFastField } from '../../../components';

interface SampleDevelopmentPlFinishingProps {}

const SampleDevelopmentPlFinishing: React.FC<SampleDevelopmentPlFinishingProps> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Finishing</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>Coating WL:</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="coatingChemical" label="Chemical" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="coatingSoft" label="Soft" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="coatingMedium" label="Medium" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="coatingHard" label="Hard" />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <Typography>Cut Type:</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeHeat" label="Heat Cut" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeUltrasonic" label="Ultrasonic Cut" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeMosonic" label="Mosonic Cut" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeLaser" label="Laser Cut" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeStraight" label="Straight Cut" />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeCenter" label="Center Cut" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeBooklet" label="Booklet Cut" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeEmd" label="Emd Cut" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeMitre" label="Mitre Cut" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeCool" label="Cool Cut" />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeDieCut" label="Diecut Cut" />
            </Grid>
            <Grid item xs={12} md={8} />
            <Grid item xs={12} md={2}>
              <Typography>Packing:</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingPiece" label="Piece" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingFold" label="Fold" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingRoll" label="Roll" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingTape" label="Tape" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingFoldHead" label="Fold Head Tail" />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingFoldHalf" label="Fold Half" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingWishbone" label="Wish Bone" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingSheet" label="Sheet" />
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentPlFinishing;
