import React from 'react';
import { Link } from '@material-ui/core';
import { ITaskActivity } from '../../models/taskActivity.model';

interface TaskAndSubtaskLinkButtonProps {
  taskActivity: ITaskActivity;
}

const TaskAndSubtaskLinkButton: React.FC<TaskAndSubtaskLinkButtonProps> = props => {
  const { taskActivity } = props;
  const link = taskActivity.smSubtask
    ? `/tasks/form/${taskActivity.smTaskId}?subtask=${taskActivity.smSubtask!.id}`
    : `/tasks/form/${taskActivity.smTaskId}`;
  return (
    <Link href={link} rel="noopener">
      {taskActivity.smTask!.taskNumber}{' '}
      {taskActivity.smSubtask
        ? ` ${taskActivity.smSubtask.subject} `
        : `${taskActivity.smTask!.subject} `}
    </Link>
  );
};

export default TaskAndSubtaskLinkButton;
