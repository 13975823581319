import React from 'react';
import { Table, TableCell, TableRow, TableBody, TableHead } from '@material-ui/core';
import { IBlockCreate } from '../../../state/block';

interface ConfirmTableProps {
  blockCreates: ReadonlyArray<IBlockCreate>;
}

const ConfirmTable = (props: ConfirmTableProps) => {
  const { blockCreates } = props;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Block No</TableCell>
          <TableCell>Item</TableCell>
          <TableCell>Spec Detail/Description</TableCell>
          <TableCell>Hanes Project</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Message</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {blockCreates.map((blockCreate, index) => {
          const { block, status, errorMessage } = blockCreate;
          return (
            <TableRow key={index}>
              <TableCell>{block?.blockNo}</TableCell>
              <TableCell>{block?.item}</TableCell>
              <TableCell>{block?.itemDescription}</TableCell>
              <TableCell>{block?.hanesProjectName}</TableCell>
              <TableCell>{status}</TableCell>
              <TableCell>{errorMessage}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ConfirmTable;
