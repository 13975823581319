import React from 'react';
import { fetchCompanyList as fetchCompanyListService } from '../../services/companyService';
import { Company } from '../../state/company';
import AsyncAutoComplete from './AsyncAutoComplete';

interface CompanyComboBoxProps {
  handleChange: (result: Company) => void;
  selectedValue: Company;
  isDisabled?: boolean;
  error?: boolean;
  helperText?: string;
}

const CompanyComboBox: React.FC<CompanyComboBoxProps> = props => {
  const { handleChange, selectedValue, isDisabled = false, error, helperText } = props;

  const companyPromiseOptions = async (inputValue: string) => {
    const { data: rows } = await fetchCompanyListService({
      filters: { 'Name.startsWith': inputValue },
      paging: { pageNumber: 0, pageSize: 10 },
    });

    return rows;
  };

  return (
    <AsyncAutoComplete
      name="Company"
      value={selectedValue}
      getOptionValue={(option: Company) => {
        return option.id!.toString();
      }}
      getOptionLabel={(option: Company) => {
        return option.name;
      }}
      promiseOptions={companyPromiseOptions}
      placeholder={'Type Company Name'}
      handleChange={handleChange}
      isDisabled={isDisabled}
      error={error}
      helperText={helperText}
    />
  );
};

export default CompanyComboBox;
