import { PageFilter } from '../state/app';
import {
  SampleDevelopmentTlApprovalRequest,
  SampleDevelopmentTlApprovalRequestListFilter,
} from '../state/sampleDevelopmentTlApprovalRequest/sampleDevelopmentTlApprovalRequest.types';
import axios from '../utils/axios';
const baseUrl = `/api/sampleDevelopmentTlApprovalRequests`;

export const fetchSampleDevelopmentTlApprovalRequestList = async ({
  filters,
  paging,
}: {
  filters: SampleDevelopmentTlApprovalRequestListFilter;
  paging: PageFilter;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const updateSampleDevelopmentTlApprovalRequesResponse = async (
  sampleDevelopmentTlApprovalRequest: SampleDevelopmentTlApprovalRequest,
) => {
  const requestUrl = `${baseUrl}/response`;
  return await axios.put(requestUrl, sampleDevelopmentTlApprovalRequest);
};
