import React from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { FilterContainer, SearchDropdownField, SearchField } from '../../components';
import { OpportunityApprovalRequestListFilter } from '../../../state/opportunityApprovalRequest';
import { IFilterContainerConfigProps } from '../../components/FilterContainer';

const OpportunityApprovalRequestFilter: React.FC<IFilterContainerConfigProps<OpportunityApprovalRequestListFilter>> = props => {
  return (
    <FilterContainer
      {...props}
      render={(filterProps) => {
        const { localFilter, handleChangeFilter } = filterProps;
        return <Grid container spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <SearchField
              fullWidth={true}
              filterKey="Opportunity.AssignmentNumber.startsWith"
              filters={localFilter}
              label="Assignment Number"
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <SearchField
              fullWidth={true}
              filterKey="Opportunity.Program.Name.startsWith"
              filters={localFilter}
              label="Program"
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <SearchField
              fullWidth={true}
              filterKey="Opportunity.Brand.Name.startsWith"
              filters={localFilter}
              label="Brand"
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <SearchField
              fullWidth={true}
              filterKey="Opportunity.SalesTeam.Name.startsWith"
              filters={localFilter}
              label="Sales Team"
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <SearchField
              fullWidth={true}
              filterKey="RequestedByUser.FullNameWithUserName.contains"
              filters={localFilter}
              label="Requested By"
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <SearchDropdownField
              id={'has-response-filter'}
              label={'Responded'}
              onChange={handleChangeFilter}
              filters={localFilter}
              filterKey={'HasResponded.EqualsTo'}>
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              <MenuItem value="true">True</MenuItem>
              <MenuItem value="false">False</MenuItem>
            </SearchDropdownField>
          </Grid>
        </Grid>;
      }}
    />
  );
};

export default OpportunityApprovalRequestFilter;

