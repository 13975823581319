import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  label: {
    marginLeft: '5px',
    marginRight: '10px',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default useStyles;
