import React, { useMemo } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { User } from '../../../../state/user';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { FieldArray, Form, Formik, getIn } from 'formik';
import UserComboBox from '../../../components/UserComboBox';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ISubtaskType } from '../../../../models/subtaskType.model';
import { AppState } from '../../../../state/configureStore';
import {
  createSubtasksInTaskCreate,
  TaskCreateState,
} from '../../../../state/taskCreate';
import { AuthState } from '../../../../state/auth';
import {
  Close as CloseIcon,
  Save as SaveIcon,
  SkipNext as NextIcon,
  SkipPrevious as PreviousIcon,
} from '@material-ui/icons';

interface SubtaskListDetailDialogProps {
  nextClick: () => void;
  backClick: () => void;
  closeClick: () => void;
  subtaskTypeList: ISubtaskType[];
}

const SubtaskListDetailDialogSchema = yup.object().shape({
  subtasks: yup.array().of(
    yup.object().shape({
      subject: yup.string().required('Subject is required'),
      smSubtaskTypeId: yup.number().required('Subtask type is required'),
      smSubtaskAssignees: yup
        .array()
        .of(
          yup.object().shape({
            userId: yup.number(),
          }),
        )
        .required('Assignee is required'),
      dueDate: yup
        .string()
        .nullable(true)
        .test(
          'dueDateFormat',
          'Invalid date format',
          (value) => !isNaN(new Date(value).getTime()),
        )
        .required('Due date is required'),
    }),
  ),
});

function defaultSubjectSubtaskPrefix(subtaskType: ISubtaskType): string {
  return `[${subtaskType.name!}] `;
}

const SubtaskListDetailDialog: React.FC<SubtaskListDetailDialogProps> = (props) => {
  const { nextClick, backClick, closeClick, subtaskTypeList } = props;
  const dispatch = useDispatch();
  const { createTaskPayload, selectedSubtaskType, createSubtasksPayload } = useSelector<
    AppState,
    TaskCreateState
  >((state) => state.taskCreate);

  const { userId } = useSelector<AppState, AuthState>((state) => state.auth);

  const handleSubmit = (values: any) => {
    const createSubtasks = values.subtasks.map((subtask: any) => {
      return { ...subtask, requesterId: userId };
    });
    dispatch(createSubtasksInTaskCreate(createSubtasks));
    nextClick();
  };

  const getInitialValue = useMemo(
    () => () =>
      selectedSubtaskType.map((subtaskTypeName) => {
        const { subject, dueDate } = createTaskPayload;
        const subtaskType = subtaskTypeList.find((st) => st.name === subtaskTypeName)!;
        const defaultSubtaskSubject = `${defaultSubjectSubtaskPrefix(
          subtaskType,
        )}${subject}`;
        const createdSubtask = createSubtasksPayload.find(
          (st) =>
            st.subject === defaultSubtaskSubject &&
            st.smSubtaskTypeId === subtaskType.id!,
        )!;

        return {
          subject: `${defaultSubjectSubtaskPrefix(subtaskType)}${subject}`,
          smSubtaskTypeId: subtaskType.id,
          assignee: createdSubtask ? createdSubtask.assignee : null,
          smSubtaskAssignees: createdSubtask ? createdSubtask.smSubtaskAssignees : null,
          dueDate,
        };
      }),
    [subtaskTypeList, createTaskPayload, createSubtasksPayload, selectedSubtaskType],
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ subtasks: getInitialValue() }}
        validationSchema={SubtaskListDetailDialogSchema}
        onSubmit={handleSubmit}
        validateOnChange={true}
      >
        {({ values, errors, setFieldValue }) => {
          const disabledNextButton = values.subtasks.length === 0;
          return (
            <Form>
              <DialogTitle style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  Create Subtasks
                  <IconButton
                    color="inherit"
                    aria-label="close"
                    onClick={() => {
                      closeClick();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Subject</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Assignee</TableCell>
                      <TableCell>Due date</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray
                      name="subtasks"
                      render={(arrayHelpers) => (
                        <>
                          {values.subtasks.map((value, index) => {
                            const getErrorMessage = (fieldName: string, index: number) =>
                              getIn(errors, `subtasks[${index}].${fieldName}`);
                            const subtaskType = subtaskTypeList.find(
                              (s) => s.id === value.smSubtaskTypeId,
                            );
                            return (
                              <TableRow key={index}>
                                <TableCell style={{ width: '30%' }}>
                                  <TextField
                                    value={value.subject}
                                    label="Subject"
                                    margin="dense"
                                    onChange={(event) => {
                                      const newSubject = event.target.value;
                                      arrayHelpers.replace(index, {
                                        ...values.subtasks[index],
                                        subject: newSubject,
                                      });
                                    }}
                                    fullWidth
                                    error={!!getErrorMessage('subject', index)}
                                    helperText={getErrorMessage('subject', index)}
                                  />
                                </TableCell>
                                <TableCell style={{ width: '10%' }}>
                                  {subtaskType ? subtaskType.name : ''}
                                </TableCell>
                                <TableCell style={{ width: '35%' }}>
                                  <UserComboBox
                                    handleChange={(users: User[]) => {
                                      arrayHelpers.replace(index, {
                                        ...values.subtasks[index],
                                        assignee: users ? users : null,
                                        smSubtaskAssignees: users
                                          ? users.map((user) => ({ userId: user.id }))
                                          : [],
                                      });
                                    }}
                                    selectedValue={value.assignee as unknown as User[]}
                                    error={!!getErrorMessage('smSubtaskAssignees', index)}
                                    helperText={getErrorMessage(
                                      'smSubtaskAssignees',
                                      index,
                                    )}
                                    isMulti
                                  />
                                </TableCell>
                                <TableCell style={{ width: '25%' }}>
                                  <KeyboardDatePicker
                                    margin="dense"
                                    label="Due Date"
                                    format="yyyy-MM-dd"
                                    value={value.dueDate}
                                    onChange={(selectedDate, selectedStringDate) => {
                                      setFieldValue(
                                        `subtasks[${index}].dueDate` as 'subtasks',
                                        selectedStringDate,
                                      );
                                    }}
                                    error={!!getErrorMessage('dueDate', index)}
                                    helperText={getErrorMessage('dueDate', index)}
                                  />
                                </TableCell>
                                <TableCell>
                                  {/* <Button
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }}
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<DeleteIcon />}
                                  >
                                    DELETE
                                  </Button> */}
                                  <Tooltip title="Delete">
                                    <span>
                                      <IconButton
                                        color="default"
                                        size="small"
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      )}
                    />
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions style={{ marginRight: '5px', marginBottom: '10px' }}>
                {/* <Button
                  onClick={() => {
                    closeClick();
                  }}
                  color="default"
                  variant="contained"
                  startIcon={<CloseIcon />}
                >
                  Close
                </Button> */}
                <Button
                  onClick={backClick}
                  startIcon={<PreviousIcon />}
                  variant="contained"
                  color="default"
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<NextIcon />}
                  disabled={disabledNextButton}
                >
                  Next
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SubtaskListDetailDialog;
