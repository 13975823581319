import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const LargeScreenFilterContainer: React.FC = props => {
  const classes = useStyles();
  return <div className={classes.root}>{props.children}</div>;
};

export default LargeScreenFilterContainer;
