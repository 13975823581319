import { IResponsibilityCustomer } from '../../models/responsibilityCustomer.model';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_RESPONSIBILITY_CUSTOMER,
  FETCH_RESPONSIBILITY_CUSTOMER,
  FETCH_RESPONSIBILITY_CUSTOMER_LIST,
  UPDATE_RESPONSIBILITY_CUSTOMER,
  CREATE_RESPONSIBILITY_CUSTOMER,
  REPLACE_RESPONSIBILITY_CUSTOMER,
} from './responsibilityCustomer.actions';
import { ResponsibilityCustomerState } from './responsibilityCustomer.types';

const getInitialResponsibilityCustomerState = (): IResponsibilityCustomer => ({});

const initialState: ResponsibilityCustomerState = {
  errorMessage: '',
  item: getInitialResponsibilityCustomerState(),
  items: [],
  loading: false,
  totalItems: 0,
};

export default (
  state: ResponsibilityCustomerState = initialState,
  action: AppAction,
): ResponsibilityCustomerState => {
  switch (action.type) {
    case REQUEST(FETCH_RESPONSIBILITY_CUSTOMER_LIST):
    case REQUEST(CREATE_RESPONSIBILITY_CUSTOMER):
    case REQUEST(FETCH_RESPONSIBILITY_CUSTOMER):
    case REQUEST(UPDATE_RESPONSIBILITY_CUSTOMER):
    case REQUEST(REPLACE_RESPONSIBILITY_CUSTOMER):
      return { ...state, loading: true };
    case SUCCESS(FETCH_RESPONSIBILITY_CUSTOMER_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_RESPONSIBILITY_CUSTOMER_LIST):
    case FAILURE(CREATE_RESPONSIBILITY_CUSTOMER):
    case FAILURE(FETCH_RESPONSIBILITY_CUSTOMER):
    case FAILURE(UPDATE_RESPONSIBILITY_CUSTOMER):
    case FAILURE(REPLACE_RESPONSIBILITY_CUSTOMER):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_RESPONSIBILITY_CUSTOMER):
    case SUCCESS(FETCH_RESPONSIBILITY_CUSTOMER):
    case SUCCESS(UPDATE_RESPONSIBILITY_CUSTOMER):
    case SUCCESS(REPLACE_RESPONSIBILITY_CUSTOMER):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_RESPONSIBILITY_CUSTOMER:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
