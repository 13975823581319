import React from 'react';
import { Box, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import { TextFieldFastField } from '../../../components';

interface SampleDevelopmentWlSpecialProps {}

const SampleDevelopmentWlSpecial: React.FC<SampleDevelopmentWlSpecialProps> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Special Command</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Special Material:</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextFieldFastField
                name="specialMaterial"
                label="Material"
                variant="outlined"
                margin="dense"
                fullWidth
                multiline
                rows={10}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Special Color:</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextFieldFastField
                name="specialColor"
                label="Color"
                variant="outlined"
                margin="dense"
                fullWidth
                multiline
                rows={10}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Special Design:</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextFieldFastField
                name="specialDesign"
                label="Design"
                variant="outlined"
                margin="dense"
                fullWidth
                multiline
                rows={10}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Special MainProduction:</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextFieldFastField
                name="specialMainProduction"
                label="MainProduction"
                variant="outlined"
                margin="dense"
                fullWidth
                multiline
                rows={10}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Special Finishing:</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextFieldFastField
                name="specialFinishing"
                label="Finishing"
                variant="outlined"
                margin="dense"
                fullWidth
                multiline
                rows={10}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Special QC:</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextFieldFastField
                name="specialQC"
                label="QC"
                variant="outlined"
                margin="dense"
                fullWidth
                multiline
                rows={10}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Special Packing:</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextFieldFastField
                name="specialPacking"
                label="Packing"
                variant="outlined"
                margin="dense"
                fullWidth
                multiline
                rows={10}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Special Other:</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextFieldFastField
                name="specialOther"
                label="Other"
                variant="outlined"
                margin="dense"
                fullWidth
                multiline
                rows={10}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentWlSpecial;
