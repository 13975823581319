import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearProgram,
  fetchProgramList,
  Program,
  ProgramListFilter,
  ProgramState,
} from '../../../state/program/';
import useStyles from './programList.style';
import { PageContainer, PageTitle, SearchField, TableLoading } from '../../components';
import { useFilter, useHasRole } from '../../hooks';
import roleRoute from '../roleRoute.config';

const ProgramList: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const classes = useStyles();
  const [filters, setFilters] = useFilter<ProgramListFilter>({});
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });
  const isDisabled = !useHasRole(roleRoute.createProgram);

  const {
    items: programs,
    totalItems,
    loading,
  } = useSelector<AppState, ProgramState>((state) => state.program);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchProgramList(filters, paging));
    return function cleanUp() {
      dispatch(clearProgram());
    };
  }, [dispatch, paging, filters]);

  const editProgram = (id: Program['id']): void => {
    history.push(`/settings/programs/form/${id}`);
  };

  return (
    <PageContainer>
      <PageTitle>Program</PageTitle>
      <div className={classes.filterContainer}>
        <SearchField
          filterKey="Name.startsWith"
          filters={filters}
          label="Name"
          onChange={handleChangeFilter}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>External ID</TableCell>
            <TableCell>Use NPD</TableCell>
            <TableCell>Is High Priority</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={4}>
            {programs.map((program, index) => (
              <TableRow key={program.id}>
                <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                <TableCell>{program.name}</TableCell>
                <TableCell>{program.externalId}</TableCell>
                <TableCell>{`${program.isUseNpd}`}</TableCell>
                <TableCell>
                  <Checkbox color="secondary" checked={program.isHighPriority!} />
                </TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <span>
                      <IconButton
                        size="small"
                        onClick={() => {
                          editProgram(program?.id);
                        }}
                        disabled={isDisabled}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default ProgramList;
