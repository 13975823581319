import {
  AppBar,
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close as CloseIcon, Save as SaveIcon } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { SubtaskItemStatusLabel } from '../../../../constants';
import { ISubtask } from '../../../../models/subtask.model';
import { AppState } from '../../../../state/configureStore';
import {
  clearSubtaskItemSection,
  fetchSubtask,
  SubtaskState,
  updateSubtask,
} from '../../../../state/subtask';
import {
  clearSubtaskItem,
  fetchSubtaskItemListBySubtaskId,
} from '../../../../state/subtaskItem';
import { fetchAllSubtaskTypeList, SubtaskTypeState } from '../../../../state/subtaskType';
import { TaskState } from '../../../../state/task';
import { clearTaskActivity } from '../../../../state/taskActivity';
import { User } from '../../../../state/user';
import {
  Loading,
  PageContainer,
  PageTitle,
  PageTopMenuContainer,
} from '../../../components';
import UserComboBox from '../../../components/UserComboBox';
import { TaskActivityComment, TaskActivityList } from '../taskActivity';
import SubtaskItemTable from './SubtaskItemTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    buttonGroup: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(1, 2),
      marginTop: '-30px',
      marginRight: '-25px',
    },
    tab: {
      '& .MuiTabs-indicator': {
        backgroundColor: '#3f51b5',
        height: 4,
      },
      '& .MuiTab-root.Mui-selected': {
        background: '#d9d9d9',
      },
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

const SubtaskEditSchema = Yup.object().shape({
  subject: Yup.string().required('Subject is required'),
  description: Yup.string().nullable(),
  dueDate: Yup.string()
    .nullable()
    .test(
      'dueDateFormat',
      'Invalid date format',
      (value) => !isNaN(new Date(value).getTime()),
    )
    .required('Due Date is required'),
  smSubtaskAssignees: Yup.array()
    .of(
      Yup.object().shape({
        userId: Yup.number(),
      }),
    )
    .required('Assignee is required'),
});

interface SubtaskEditDialogProps {
  handleClose: () => void;
  open: boolean;
  subtaskId: number | null;
}

enum SubtaskFormTabs {
  item,
  activity,
}

interface ISubtaskEditDialogValues extends ISubtask {
  subtaskAssigneeUsers?: User[];
}

const SubtaskEditDialog: React.FC<SubtaskEditDialogProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleClose, open, subtaskId } = props;
  const { item: task } = useSelector<AppState, TaskState>((state) => state.task);
  const { item: subtask, loading: subtaskIsLoading } = useSelector<
    AppState,
    SubtaskState
  >((state) => state.subtask);
  const { items: subtaskTypes, loading: subtaskTypeIsLoading } = useSelector<
    AppState,
    SubtaskTypeState
  >((state) => state.subtaskType);

  const [tabIndex, setTabIndex] = useState(SubtaskFormTabs.item);

  const {
    id,
    dueDate,
    subject = '',
    description = '',
    status,
    useCommonStatus,
    smSubtaskTypeId,
    smSubtaskAssignees = [],
  } = subtask;

  const subtaskAssigneeUsers = useMemo(() => {
    return smSubtaskAssignees!.map((smSubtaskAssignee) => smSubtaskAssignee.user);
  }, [smSubtaskAssignees]);

  const handleSubmit = (
    values: ISubtaskEditDialogValues,
    actions: FormikHelpers<ISubtaskEditDialogValues>,
  ) => {
    const updateValue = { ...values, id, smTaskId: task.id };
    dispatch(updateSubtask(updateValue));
    actions.setSubmitting(false);
  };

  useEffect(() => {
    if (subtaskId) {
      dispatch(fetchSubtask(subtaskId));
      dispatch(fetchSubtaskItemListBySubtaskId(subtaskId!));
    }
    dispatch(fetchAllSubtaskTypeList());

    function cleanUp() {
      dispatch(clearSubtaskItemSection());
      dispatch(clearSubtaskItem());
      dispatch(clearTaskActivity());
    }

    return cleanUp();
  }, [dispatch, subtaskId]);

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Edit Subtask
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <PageContainer>
            <Loading isLoading={subtaskTypeIsLoading || subtaskIsLoading}>
              <Box p={2}>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    subject,
                    dueDate,
                    description,
                    status,
                    useCommonStatus,
                    smSubtaskTypeId,
                    subtaskAssigneeUsers,
                    smSubtaskAssignees,
                  }}
                  validationSchema={SubtaskEditSchema}
                  onSubmit={handleSubmit}
                  validateOnChange={false}
                >
                  {({ values, handleChange, errors, submitForm, setFieldValue }) => {
                    return (
                      <Form>
                        <div className={classes.buttonGroup}>
                          <Button
                            color="default"
                            variant="contained"
                            startIcon={<CloseIcon />}
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            Close
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              submitForm();
                            }}
                            startIcon={<SaveIcon />}
                          >
                            Update
                          </Button>
                        </div>

                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                              name="subject"
                              type="input"
                              label="Subject"
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              value={values.subject || ''}
                              onChange={handleChange}
                              error={!!errors.subject}
                              helperText={errors.subject}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                              name="description"
                              type="input"
                              label="Description"
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              value={values.description || ''}
                              onChange={handleChange}
                              error={!!errors.description}
                              helperText={errors.description}
                              multiline
                              rows={3}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <UserComboBox
                              handleChange={(users: User[]) => {
                                setFieldValue(
                                  'subtaskAssigneeUsers',
                                  users ? users : undefined,
                                );
                                setFieldValue(
                                  'smSubtaskAssignees',
                                  users
                                    ? users.map((user) => {
                                        return { userId: user.id };
                                      })
                                    : [],
                                );
                              }}
                              selectedValue={values.subtaskAssigneeUsers as User[]}
                              error={!!errors.smSubtaskAssignees}
                              helperText={errors.smSubtaskAssignees}
                              label="Assignee"
                              isMulti
                            />
                          </Grid>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <FormControl fullWidth>
                                <InputLabel>Subtask Type</InputLabel>
                                <Select
                                  fullWidth
                                  value={values.smSubtaskTypeId || ''}
                                  readOnly={true}
                                >
                                  {subtaskTypes.map((item) => {
                                    return (
                                      <MenuItem key={item.id!} value={item.id!}>
                                        {item.name!}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <KeyboardDatePicker
                                id="date-picker-dialog"
                                label="Due Date"
                                format="yyyy-MM-dd"
                                value={values.dueDate}
                                onChange={(selectedDate, selectedStringDate) => {
                                  setFieldValue('dueDate', selectedStringDate);
                                }}
                                error={!!errors.dueDate}
                                helperText={errors.dueDate}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                name="status"
                                type="input"
                                label="Status"
                                variant="outlined"
                                margin="dense"
                                value={
                                  values.status
                                    ? SubtaskItemStatusLabel[values.status!]
                                    : ''
                                }
                                disabled
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.useCommonStatus}
                                    onChange={(event) => {
                                      setFieldValue(
                                        'useCommonStatus',
                                        event.target.checked,
                                      );
                                    }}
                                    name="useCommonStatus"
                                    color="primary"
                                  />
                                }
                                label="Use Common Status"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </Loading>
          </PageContainer>
          <PageContainer>
            <Card>
              <Tabs
                value={tabIndex}
                indicatorColor="primary"
                textColor="primary"
                onChange={(event, newIndex) => setTabIndex(newIndex)}
                variant="scrollable"
                className={classes.tab}
              >
                <Tab label="items" />
                <Tab label="Activities" />
              </Tabs>
            </Card>
            {tabIndex === SubtaskFormTabs.item && <SubtaskItemTable />}
            {tabIndex === SubtaskFormTabs.activity && subtask.smTaskId! && (
              <Box p={2}>
                <TaskActivityComment
                  smTaskId={subtask.smTaskId!}
                  smSubtaskId={subtask.id!}
                />
                <TaskActivityList
                  smTaskId={subtask.smTaskId!}
                  smSubtaskId={subtask.id!}
                />
              </Box>
            )}
          </PageContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SubtaskEditDialog;
