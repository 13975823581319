import { Box, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { CheckBoxFastField, TextFieldFastField } from '../../../components';
import { KeyboardDatePicker } from '@material-ui/pickers';

interface SampleDevelopmentTlServiceProps {
  values: any;
  handleChange: any;
  errors: any;
  setFieldValue: any;
}

const SampleDevelopmentTlService: React.FC<SampleDevelopmentTlServiceProps> = (props) => {
  const { values, errors, setFieldValue } = props;

  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Service</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>Service: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="serviceTransfer" label="Transfer" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="serviceTransferWashing" label="Transfer+Washing" />
            </Grid>
            <Grid item xs={12} md={6} />
            <Grid item xs={2}>
              <Typography>Sample Piece: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="samplePieceOriginal" label="Original" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                name="samplePieceOriginalQty"
                label="Qty"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="samplePieceWe" label="Sample WE" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                name="samplePieceWeQty"
                label="Qty"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="samplePieceBlock"
                label="Block"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="samplePieceSpec"
                label="Spec"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>Fabric: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="fabricCustomer" label="Customer Fabric" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                name="fabricCustomerQty"
                label="Qty"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="fabricCustomerType"
                label="Type"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="fabricWe" label="WE Fabric" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                name="fabricWeQty"
                label="Qty"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="fabricWeType"
                label="Type"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={12}>
              <TextFieldFastField
                name="specialRequirement"
                label="Special Requirement"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <KeyboardDatePicker
                margin="normal"
                label="Delivery Date"
                format="yyyy-MM-dd"
                value={values.deliveryDate}
                onChange={(selectedDate, selectedStringDate) => {
                  setFieldValue('deliveryDate', selectedStringDate);
                }}
                error={!!errors.deliveryDate}
                helperText={errors.deliveryDate}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={9} />
            <Grid item xs={12} md={2}>
              <Typography>Type of Trading: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="tradingSaleDecal" label="Decal" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="tradingSaleRollingMac" label="RollingMac" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="tradingSaleHire" label="Hire" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="tradingSalePress" label="Press" />
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentTlService;
