import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { IResponsibilityBrand } from '../../../../models/responsibilityBrand.model';
import { Brand } from '../../../../state/brand';
import {
  replaceResponsibilityBrand,
  ResponsibilityBrandReplacePayLoad,
} from '../../../../state/responsibilityBrand';
import { BrandComboBox } from '../../../components';

interface ResponsibilityBrandReplaceDialogProps {
  responsibilityBrand: IResponsibilityBrand | null;
  open: boolean;
  onClose: () => void;
}

interface ResponsibilityBrandReplaceValues {
  brand?: Brand | null;
  brandId?: number | null;
}

const ResponsibilityBrandReplaceSchema = Yup.object().shape({
  brandId: Yup.number()
    .nullable(true)
    .required('Brand is required'),
});

type dialogStep = 'replaceResponsibilityBrand' | 'confirmResponsibilityBrand';

const ResponsibilityBrandReplaceDialog: React.FC<
  ResponsibilityBrandReplaceDialogProps
> = props => {
  const dispatch = useDispatch();
  const { open, onClose, responsibilityBrand } = props;

  const [
    replaceResponsibilityBrandDlgStep,
    setReplaceResponsibilityBrandDlgStep,
  ] = useState<dialogStep>('replaceResponsibilityBrand');
  const [
    responsibilityReplaceBrandPayLoad,
    setResponsibilityReplaceBrandPayLoad,
  ] = useState<ResponsibilityBrandReplacePayLoad>();

  const initialFormValues: ResponsibilityBrandReplaceValues = useMemo(() => {
    const { brand, brandId } = responsibilityBrand!;
    return { brand, brandId };
  }, [responsibilityBrand]);

  const handleSubmit = (
    values: ResponsibilityBrandReplaceValues,
    actions: FormikHelpers<ResponsibilityBrandReplaceValues>,
  ) => {
    setResponsibilityReplaceBrandPayLoad({
      responsibilityBrandId: responsibilityBrand!.id!,
      newBrand: values.brand!,
      newBrandId: values.brandId!,
    });

    setReplaceResponsibilityBrandDlgStep('confirmResponsibilityBrand');
    actions.setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'} fullWidth>
      <Formik
        enableReinitialize={true}
        initialValues={initialFormValues}
        validationSchema={ResponsibilityBrandReplaceSchema}
        onSubmit={handleSubmit}
        validateOnChange={true}
      >
        {({ values, submitForm, errors, touched, setFieldValue, setFieldTouched }) => {
          const isError = (fieldName: keyof ResponsibilityBrandReplaceValues) =>
            !!touched[fieldName] && !!errors[fieldName];
          const errorMessage = (fieldName: keyof ResponsibilityBrandReplaceValues) =>
            !!touched[fieldName] ? (errors[fieldName] as string) : '';
          return (
            <Form>
              {replaceResponsibilityBrandDlgStep === 'replaceResponsibilityBrand' && (
                <>
                  <DialogTitle>Replace Responsibility Brand</DialogTitle>
                  <CardContent>
                    <BrandComboBox
                      handleChange={result => {
                        setFieldValue('brand', result ? result : null);
                        setFieldTouched('brand', true, false);
                        setFieldValue('brandId', result ? result.id : null);
                        setFieldTouched('brandId', true, false);
                      }}
                      selectedValue={values.brand as Brand}
                      error={isError('brandId')}
                      helperText={errorMessage('brandId')}
                    />
                  </CardContent>
                  <DialogActions>
                    <Button
                      variant={'contained'}
                      color="secondary"
                      onClick={() => {
                        onClose();
                      }}
                    >
                      CANCEL
                    </Button>
                    <Button
                      variant={'contained'}
                      color="primary"
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      NEXT
                    </Button>
                  </DialogActions>
                </>
              )}
              {replaceResponsibilityBrandDlgStep === 'confirmResponsibilityBrand' && (
                <>
                  <DialogTitle>Confirm Replace Responsibility Brand</DialogTitle>
                  <CardContent>
                    <TextField
                      name="brandName"
                      type="input"
                      label="Brand Name"
                      variant="outlined"
                      margin="dense"
                      value={responsibilityBrand!.brand!.name}
                      fullWidth
                    />
                    <TextField
                      name="changeToBrandName"
                      type="input"
                      label="Change To Brand Name"
                      variant="outlined"
                      margin="dense"
                      value={responsibilityReplaceBrandPayLoad!.newBrand!.name}
                      fullWidth
                    />
                  </CardContent>
                  <DialogActions>
                    <Button
                      variant={'contained'}
                      color="secondary"
                      onClick={() => {
                        setReplaceResponsibilityBrandDlgStep(
                          'replaceResponsibilityBrand',
                        );
                      }}
                    >
                      BACK
                    </Button>
                    <Button
                      variant={'contained'}
                      color="primary"
                      onClick={() => {
                        dispatch(
                          replaceResponsibilityBrand(responsibilityReplaceBrandPayLoad!),
                        );
                        setReplaceResponsibilityBrandDlgStep(
                          'replaceResponsibilityBrand',
                        );
                        onClose();
                      }}
                    >
                      OK
                    </Button>
                  </DialogActions>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ResponsibilityBrandReplaceDialog;
