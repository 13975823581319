import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createOpportunityApprovalRequest,
  fetchOpportunityApprovalRequest,
  fetchOpportunityApprovalRequestList,
  updateOpportunityApprovalRequest,
  updateOpportunityApprovalResponse,
} from '../../services/opportunityApprovalRequestService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  closeOpportunityApprovalRequestDialog,
  CREATE_OPPORTUNITYAPPROVALREQUEST,
  FETCH_OPPORTUNITYAPPROVALREQUEST,
  FETCH_OPPORTUNITYAPPROVALREQUEST_LIST,
  UPDATE_OPPORTUNITY_APPROVAL_RESPONSE,
  UPDATE_OPPORTUNITYAPPROVALREQUEST,
} from './opportunityApprovalRequest.actions';
import { OpportunityApprovalRequestCreateOrUpdatePayload } from './opportunityApprovalRequest.types';

const listPageUrl = `/settings/opportunityApprovalRequests`;

function* fetchOpportunityApprovalRequestSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchOpportunityApprovalRequest, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchOpportunityApprovalRequestListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchOpportunityApprovalRequestList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createOpportunityApprovalRequestSaga(action: AppAction) {
  try {
    const {
      values,
      history,
    } = action.payload as OpportunityApprovalRequestCreateOrUpdatePayload;
    const successMessage = `Created opportunityApprovalRequest successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createOpportunityApprovalRequest, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(listPageUrl);
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateOpportunityApprovalRequestSaga(action: AppAction) {
  try {
    const {
      values,
      history,
    } = action.payload as OpportunityApprovalRequestCreateOrUpdatePayload;
    const successMessage = `Updated opportunityApprovalRequest successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateOpportunityApprovalRequest, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(listPageUrl);
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateOpportunityApprovalResponseSaga(action: AppAction) {
  try {
    const successMessage = `Updated opportunityApprovalResponse successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateOpportunityApprovalResponse, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put(closeOpportunityApprovalRequestDialog());
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchOpportunityApprovalRequest() {
  yield takeLatest(FETCH_OPPORTUNITYAPPROVALREQUEST, fetchOpportunityApprovalRequestSaga);
  yield debounce(
    250,
    FETCH_OPPORTUNITYAPPROVALREQUEST_LIST,
    fetchOpportunityApprovalRequestListSaga,
  );
  yield takeLatest(
    CREATE_OPPORTUNITYAPPROVALREQUEST,
    createOpportunityApprovalRequestSaga,
  );
  yield takeLatest(
    UPDATE_OPPORTUNITYAPPROVALREQUEST,
    updateOpportunityApprovalRequestSaga,
  );
  yield takeLatest(
    UPDATE_OPPORTUNITY_APPROVAL_RESPONSE,
    updateOpportunityApprovalResponseSaga,
  );
}
