import { AppAction } from '../../../../state/app';
import { SampleDevelopmentPlApprovalRequest } from '../../../../state/sampleDevelopmentPlApprovalRequest/sampleDevelopmentPlApprovalRequest.types';
import { FAILURE, REQUEST, SUCCESS } from '../../../../utils/actionTypeUtil';
import { FETCH_PENDING_APPROVAL_SAMPLE_DEV_PL_LIST } from './pendingApprovalSampleDevPlRequestList.actions';

export interface PendingApprovalSampleDevPlRequestListState {
  loading: boolean;
  items: ReadonlyArray<SampleDevelopmentPlApprovalRequest>;
  errorMessage: string;
  totalItems: number;
}

const initialState: PendingApprovalSampleDevPlRequestListState = {
  errorMessage: '',
  items: [],
  loading: false,
  totalItems: 0,
};

export default (
  state: PendingApprovalSampleDevPlRequestListState = initialState,
  action: AppAction,
): PendingApprovalSampleDevPlRequestListState => {
  switch (action.type) {
    case REQUEST(FETCH_PENDING_APPROVAL_SAMPLE_DEV_PL_LIST):
      return { ...state, loading: true };
    case SUCCESS(FETCH_PENDING_APPROVAL_SAMPLE_DEV_PL_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_PENDING_APPROVAL_SAMPLE_DEV_PL_LIST):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    default:
      return state;
  }
};
