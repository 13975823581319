import axios from '../utils/axios';
import { PageFilter } from '../state/app';
import {
  FindAllOpportunityApprovalRequestListFilter,
  OpportunityApprovalRequest,
  OpportunityApprovalRequestListFilter,
  OpportunityApprovalResponse,
} from '../state/opportunityApprovalRequest';

const baseUrl = `/api/opportunityApprovalRequests`;

export const fetchOpportunityApprovalRequestList = async ({
  filters,
  paging,
}: {
  filters: OpportunityApprovalRequestListFilter;
  paging: PageFilter;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const findAllOpportunityApprovalRequestList = async ({
  filters,
  paging,
}: {
  filters: FindAllOpportunityApprovalRequestListFilter;
  paging: PageFilter;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}/findAllOpportunityApprovalRequest`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchOpportunityApprovalRequest = async (
  id: OpportunityApprovalRequest['id'],
) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createOpportunityApprovalRequest = async (
  opportunityApprovalRequest: OpportunityApprovalRequest,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, opportunityApprovalRequest);
};

export const updateOpportunityApprovalRequest = async (
  opportunityApprovalRequest: OpportunityApprovalRequest,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, opportunityApprovalRequest);
};

export const updateOpportunityApprovalResponse = async (
  opportunityApprovalResponse: OpportunityApprovalResponse,
) => {
  const requestUrl = `${baseUrl}/${opportunityApprovalResponse.opportunityApprovalRequestId}/response`;
  return await axios.put(requestUrl, opportunityApprovalResponse);
};
