import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflow: 'visible',
    padding: '0.05px',
    position: 'relative',
  },
  tabBadge: {
    padding: theme.spacing(0, 2),
  },
  button: {
    marginRight: '50px',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  buttonSave: {
    position: 'relative',

    backgroundColor: '#7cb342',
    '&:hover': {
      backgroundColor: '#689f38',
    },
    color: 'white',
  },
  buttonGroup: {
    width: '100%',
    right: theme.spacing(2),
    top: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      position: 'absolute',
    },
  },
}));

export default useStyles;
