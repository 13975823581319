import { PageFilter } from '../state/app';
import axios from '../utils/axios';
import { ISampleDevelopmentWl } from '../models/sampleDevelopmentWl.model';
import { SampleDevelopmentWlListFilter } from '../state/sampleDevelopmentWl';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';

const baseUrl = `/api/sampleDevelopmentWls`;

export const fetchSampleDevelopmentWlList = async ({
  filters,
  paging,
}: {
  filters: SampleDevelopmentWlListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchSampleDevelopmentWl = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const downloadSampleDevelopmentWl = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}/export`;
  return await axios.get(requestUrl, { responseType: 'blob' });
};

export const createSampleDevelopmentWl = async (
  sampleDevelopmentWl: ISampleDevelopmentWl,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, sampleDevelopmentWl);
};

export const updateSampleDevelopmentWl = async (
  sampleDevelopmentWl: ISampleDevelopmentWl,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, sampleDevelopmentWl);
};

export const submitSampleDevelopmentWlForApproval = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}/submitForApproval`;
  return await axios.post(requestUrl);
};
