import React, { FC, useState } from 'react';
import { DrawerListLink } from './drawer.types';
import { Link } from 'react-router-dom';
import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

interface DrawerItemProps {
  link: DrawerListLink;
  level: number;
  handleDrawerClose: () => void;
}

const useStyles = (level: number) =>
  makeStyles(theme => {
    return {
      listItem: {
        paddingLeft: theme.spacing(level * 2),
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      },
      link: {
        color: 'inherit',
        textDecoration: 'none',
      },
    };
  })();

const DrawerItem: FC<DrawerItemProps> = props => {
  const { link, level, handleDrawerClose } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles(level);
  const { name, path, nestedList, component } = link;

  if (nestedList && nestedList.length > 0) {
    return (
      <DrawerItemDropdown
        link={link}
        level={level}
        handleDrawerClose={handleDrawerClose}
      />
    );
  }

  const ItemComponent = component ? component : () => <ListItemText primary={name} />;

  return (
    <Link to={path!} className={classes.link}>
      <ListItem
        onClick={() => {
          if (isSmallScreen) {
            handleDrawerClose();
          }
        }}
        className={classes.listItem}
      >
        <ItemComponent />
      </ListItem>
    </Link>
  );
};

const DrawerItemDropdown: FC<DrawerItemProps> = props => {
  const { link, level, handleDrawerClose } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles(level);
  return (
    <>
      <ListItem button onClick={() => setOpen(!open)} className={classes.listItem}>
        <ListItemText primary={link.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {link.nestedList!.map((innerLink, index) => {
            return (
              <DrawerItem
                key={index}
                link={innerLink}
                level={level + 1}
                handleDrawerClose={handleDrawerClose}
              />
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

export default DrawerItem;
