import { AppAction, PageFilter, AppCallback } from '../app';
import { SubtaskItemListFilter } from './subtaskItem.types';
import { ISubtaskItem } from '../../models/subtaskItem.model';

export const FETCH_SUBTASK_ITEM_LIST = 'FETCH_SUBTASK_ITEM_LIST';
export const UPDATE_SUBTASK_ITEM_STATUS = 'UPDATE_SUBTASK_ITEM_STATUS';
export const UPDATE_SUBTASK_ITEMS_STATUS = 'UPDATE_SUBTASK_ITEMS_STATUS';
export const CLEAR_SUBTASK_ITEM = 'CLEAR_SUBTASK_ITEM';
export const CLEAR_UPDATED_ITEMS = 'CLEAR_UPDATED_ITEMS';

export const fetchSubtaskItemList = (
  filters: SubtaskItemListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SUBTASK_ITEM_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchSubtaskItemListBySubtaskId = (subtaskId: number): AppAction => {
  return {
    type: FETCH_SUBTASK_ITEM_LIST,
    payload: {
      filters: { 'SMSubtaskId.EqualsTo': subtaskId },
      paging: { pageSize: 9999, pageNumber: 0 },
    },
  };
};

export const updateSubtaskItemStatus = (
  payload: ISubtaskItem,
  callback?: AppCallback,
): AppAction => {
  return { type: UPDATE_SUBTASK_ITEM_STATUS, payload, callback };
};

export const updateSubtaskItemsStatus = (
  payload: ISubtaskItem[],
  callback?: AppCallback,
): AppAction => {
  return { type: UPDATE_SUBTASK_ITEMS_STATUS, payload, callback };
};

export const clearSubtaskItem = () => ({
  type: CLEAR_SUBTASK_ITEM,
});

export const clearUpdatedItems = () => ({
  type: CLEAR_UPDATED_ITEMS,
});
