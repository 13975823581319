import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProgramAndBrandRequestStatusLabel, TablePageSize } from '../../../../constants';
import { PageFilter } from '../../../../state/app';
import { AppState } from '../../../../state/configureStore';
import { ProgramAndBrandRequest } from '../../../../state/programAndBrandRequest';
import { TableLoading } from '../../../components';
import { DateTimeDisplay } from '../../../components/DateTimeDisplay';
import {
  clearPendingApprovalProgramAndBrandRequestList,
  fetchPendingApprovalProgramAndBrandRequestList,
} from './pendingApprovalProgramBrandRequestList.actions';
import { PendingApprovalProgramBrandRequestListState } from './pendingApprovalProgramBrandRequestList.reducer';

const PendingApprovalProgramBrandRequestList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 10,
  });

  const {
    loading,
    totalItems,
    items: pendingApprovalProgramBrandRequestList,
  } = useSelector<AppState, PendingApprovalProgramBrandRequestListState>(
    (state) => state.pendingApprovalProgramBrandRequestList,
  );

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const editProgramAndBrandRequest = (id: ProgramAndBrandRequest['id']): void => {
    history.push(`/programAndBrandRequests/form/${id}`);
  };

  useEffect(() => {
    dispatch(fetchPendingApprovalProgramAndBrandRequestList(paging));
    return function cleanup() {
      dispatch(clearPendingApprovalProgramAndBrandRequestList());
    };
  }, [dispatch, paging]);
  return (
    <>
      <Typography variant="h6">Pending Approval Program Brand Request</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Request Number</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Requested By</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={7}>
            {pendingApprovalProgramBrandRequestList.map(
              (pendingApprovalProgramBrandRequest, index) => {
                return (
                  <TableRow key={pendingApprovalProgramBrandRequest.id}>
                    <TableCell>
                      {index + paging.pageSize * paging.pageNumber + 1}
                    </TableCell>
                    <TableCell>
                      {pendingApprovalProgramBrandRequest.requestNumber}
                    </TableCell>
                    <TableCell>
                      {pendingApprovalProgramBrandRequest.description}
                    </TableCell>
                    <TableCell>
                      {pendingApprovalProgramBrandRequest.requestedByUser
                        ? pendingApprovalProgramBrandRequest.requestedByUser
                            .fullNameWithUserName
                        : ''}
                    </TableCell>
                    <TableCell>
                      <DateTimeDisplay
                        value={pendingApprovalProgramBrandRequest.createdAt}
                      />
                    </TableCell>
                    <TableCell>
                      {
                        ProgramAndBrandRequestStatusLabel[
                          pendingApprovalProgramBrandRequest.status!
                        ]
                      }
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={() =>
                          editProgramAndBrandRequest(
                            pendingApprovalProgramBrandRequest.id,
                          )
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              },
            )}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[...TablePageSize]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </>
  );
};

export default PendingApprovalProgramBrandRequestList;
