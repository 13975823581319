import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageFilter } from '../../../../state/app';
import { AppState } from '../../../../state/configureStore';
import {
  fetchSubtaskItemArtTemplateList,
  SubtaskItemArtTemplateListFilter,
  SubtaskItemArtTemplateState,
} from '../../../../state/subtaskItemArtTemplate';
import { PageContainer, PageTitle, SortLabel, TableLoading } from '../../../components';

import { useFilter, useTableSorting } from '../../../hooks';
import TaskSubtaskItemArtTemplateFilter from './TaskSubtaskItemArtTemplateFilter';
import useStyles from './taskSubtaskItemArtTemplateList.style';

const TaskSubtaskItemArtTemplateList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    items: subtaskItemArtTemplates,
    totalItems,
    loading,
  } = useSelector<AppState, SubtaskItemArtTemplateState>(
    (state) => state.subtaskItemArtTemplate,
  );

  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const [newSort, setNewSort] = useTableSorting({
    orderBy: 'SmSubtaskItem.SmSubtask.SMTask.taskNumber',
    direction: 'desc',
  });

  const initialSubtaskItemArtTemplateListFilter: SubtaskItemArtTemplateListFilter = {
    'BlockArtTemplate.ArtTemplateNumber.StartsWith': '',
  };
  const [filters, setFilters] = useFilter<SubtaskItemArtTemplateListFilter>(
    initialSubtaskItemArtTemplateListFilter,
  );

  useEffect(() => {
    dispatch(fetchSubtaskItemArtTemplateList(filters, { ...paging, sort: newSort }));
  }, [dispatch, filters, paging, newSort]);

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const handleChangeFilter = (newFilter: SubtaskItemArtTemplateListFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  return (
    <PageContainer>
      <PageTitle>Subtask Item Art Template List</PageTitle>

      <TaskSubtaskItemArtTemplateFilter
        filters={filters}
        onChangeFilter={handleChangeFilter}
        initialFilter={initialSubtaskItemArtTemplateListFilter}
      />

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="SmSubtaskItem.SmSubtask.SMTask.taskNumber"
              >
                Task Number
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="BlockArtTemplate.Block.Program.name"
              >
                Program
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="BlockArtTemplate.Block.Brand.name"
              >
                Brand Name
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="BlockArtTemplate.Block.item"
              >
                Item
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="BlockArtTemplate.Block.blockNo"
              >
                Block No.
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="SmSubtaskItem.SmSubtask.subject"
              >
                Subtask Subject
              </SortLabel>
            </TableCell>
            {/* <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="SmSubtaskItem.SmSubtask.SmSubtaskType.name "
              >
                Subtask Type
              </SortLabel>
            </TableCell> */}
            <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="BlockArtTemplate.artTemplateNumber "
              >
                Art Template No.
              </SortLabel>
            </TableCell>
            {/* <TableCell>
              <SortLabel sort={newSort} handleSortChange={setNewSort} field="status">
                Art Template Status
              </SortLabel>
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={3}>
            {subtaskItemArtTemplates.map((subtaskItemArtTemplate, index) => {
              const { blockArtTemplate, smSubtaskItem } = subtaskItemArtTemplate;
              const { block } = blockArtTemplate!;
              const { program, brand } = block!;
              const { smSubtask } = smSubtaskItem!;
              const { smTask, smSubtaskType } = smSubtask!;
              return (
                <TableRow key={subtaskItemArtTemplate.id}>
                  <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                  <TableCell>{smTask!.taskNumber}</TableCell>
                  <TableCell>{program!.name}</TableCell>
                  <TableCell>{brand!.name}</TableCell>
                  <TableCell>{block!.item}</TableCell>
                  <TableCell>{block!.blockNo}</TableCell>
                  <TableCell>{smSubtask!.subject}</TableCell>
                  {/* <TableCell>{smSubtaskType!.name}</TableCell> */}
                  <TableCell>{blockArtTemplate!.artTemplateNumber}</TableCell>
                  {/* <TableCell>{subtaskItemArtTemplate!.status}</TableCell> */}
                </TableRow>
              );
            })}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default TaskSubtaskItemArtTemplateList;
