import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchField, TableLoading } from '.';
import { TablePageSize } from '../../constants';
import { IProjectProgramBrand } from '../../models/projectProgramBrand.model';
import { PageFilter } from '../../state/app';
import { AppState } from '../../state/configureStore';
import {
  clearProjectProgramBrand,
  fetchProjectProgramBrandList,
  ProjectProgramBrandListFilter,
  ProjectProgramBrandState,
} from '../../state/projectProgramBrand';

export interface ProjectProgramBrandDialogSearchProps {
  open: boolean;
  smProjectId: number;
  smProjectProgramId: number;
  onClose: (res: any) => void;
}

const ProjectProgramBrandDialogSearch: React.FC<ProjectProgramBrandDialogSearchProps> = (
  props,
) => {
  const { open, onClose, smProjectId, smProjectProgramId } = props;
  const dispatch = useDispatch();
  const {
    items: projectProgramBrands,
    totalItems,
    loading,
  } = useSelector<AppState, ProjectProgramBrandState>(
    (state) => state.projectProgramBrand,
  );

  const initialPaging = { pageNumber: 0, pageSize: TablePageSize[0] };
  const [paging, setPaging] = useState<PageFilter>(initialPaging);
  const [selectedBrandId, setSelectedBrandId] = useState<number>();
  const [selectedProjectProgramBrand, setSelectedProjectProgramBrand] =
    useState<IProjectProgramBrand>();
  const [filters, setFilters] = useState<ProjectProgramBrandListFilter>({
    'Brand.Name.StartsWith': '',
  });

  useEffect(() => {
    setFilters((filter) => {
      return {
        ...filter,
        'SmProjectId.EqualsTo': smProjectId,
        'SmProjectProgramId.EqualsTo': smProjectProgramId,
      };
    });
  }, [setFilters, smProjectId, smProjectProgramId]);

  useEffect(() => {
    if (open) {
      dispatch(fetchProjectProgramBrandList(filters, paging));
    }
    return function cleanUp() {
      dispatch(clearProjectProgramBrand());
    };
  }, [dispatch, filters, open, paging]);

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Select Brand</DialogTitle>
      <DialogContent>
        <div>
          <SearchField
            label="Name"
            onChange={handleChangeFilter}
            filterKey="Brand.Name.StartsWith"
            filters={filters}
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Brand</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={10}>
              {projectProgramBrands.map((projectProgramBrand, index) => {
                return (
                  <TableRow key={projectProgramBrand.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked,
                        ) => {
                          if (checked) {
                            setSelectedProjectProgramBrand(projectProgramBrand!.brand);
                            setSelectedBrandId(projectProgramBrand!.brandId);
                          } else {
                            setSelectedBrandId(undefined);
                            setSelectedProjectProgramBrand(undefined);
                          }
                        }}
                        checked={selectedBrandId === projectProgramBrand!.brandId}
                      />
                    </TableCell>
                    <TableCell>{projectProgramBrand.brand!.name}</TableCell>
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[...TablePageSize]}
          component="div"
          count={totalItems}
          rowsPerPage={paging.pageSize}
          page={paging.pageNumber}
          onPageChange={handleChangePageNumber}
          onRowsPerPageChange={handleChangePageSize}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            if (selectedProjectProgramBrand) {
              setSelectedBrandId(undefined);
              setSelectedProjectProgramBrand(undefined);
              onClose(selectedProjectProgramBrand);
            }
          }}
        >
          Confirm
        </Button>
        <Button
          onClick={() => {
            setSelectedBrandId(undefined);
            setSelectedProjectProgramBrand(undefined);
            onClose(undefined);
          }}
          color="default"
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectProgramBrandDialogSearch;
