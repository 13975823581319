import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  downloadSubtaskItemWorkDurationSummaryReport,
  ISubtaskItemWorkDurationSummaryCriteria,
} from '../../../../state/report';

interface ISubtaskItemWorkDurationSummaryDlg {
  open: boolean;
  handleClose: () => void;
}

const SubtaskItemWorkDurationSummaryDlg: React.FC<
  ISubtaskItemWorkDurationSummaryDlg
> = props => {
  const dispatch = useDispatch();
  const { open, handleClose } = props;
  const date = new Date();
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 2);
  const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, +1);
  const initCriteria = {
    'SmSubtaskItem.SmSubtask.SMTask.CreatedAt.GreaterThanOrEqual': firstDayOfMonth
      .toISOString()
      .split('T')[0],
    'SmSubtaskItem.SmSubtask.SMTask.CreatedAt.LessThanOrEqual': lastDayOfMonth
      .toISOString()
      .split('T')[0],
    'SmSubtaskItem.SmTaskItem.OpportunityItem.Block.Program.Name.StartsWith': '',
    'SmSubtaskItem.SmTaskItem.OpportunityItem.Block.Brand.Name.StartsWith': '',
  };

  const [criteria, setCriteria] = useState<ISubtaskItemWorkDurationSummaryCriteria>(
    initCriteria,
  );

  const handleClickOK = () => {
    dispatch(downloadSubtaskItemWorkDurationSummaryReport(criteria));
    setCriteria(initCriteria);
    handleClose();
  };

  const handleClickCancel = () => {
    setCriteria(initCriteria);
    handleClose();
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Subtask Item Work Duration Summary Criteria</DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <KeyboardDatePicker
            showTodayButton={true}
            fullWidth={true}
            margin="normal"
            label="From Subtask Created Date"
            format="yyyy-MM-dd"
            value={
              criteria['SmSubtaskItem.SmSubtask.SMTask.CreatedAt.GreaterThanOrEqual']
            }
            onChange={(selectedDate: Date | null) => {
              const newCriteria = {
                ...criteria,
                'SmSubtaskItem.SmSubtask.SMTask.CreatedAt.GreaterThanOrEqual': selectedDate!
                  .toISOString()
                  .split('T')[0],
              };
              setCriteria(newCriteria);
            }}
          />
          <KeyboardDatePicker
            showTodayButton={true}
            fullWidth={true}
            margin="normal"
            label="To Subtask Created Date"
            format="yyyy-MM-dd"
            value={criteria['SmSubtaskItem.SmSubtask.SMTask.CreatedAt.LessThanOrEqual']}
            onChange={(selectedDate: Date | null) => {
              const newCriteria = {
                ...criteria,
                'SmSubtaskItem.SmSubtask.SMTask.CreatedAt.LessThanOrEqual': selectedDate!
                  .toISOString()
                  .split('T')[0],
              };
              setCriteria(newCriteria);
            }}
          />
          <TextField
            name="Program"
            type="input"
            label="Program"
            variant="outlined"
            margin="dense"
            fullWidth
            value={
              criteria[
                'SmSubtaskItem.SmTaskItem.OpportunityItem.Block.Program.Name.StartsWith'
              ]
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newCriteria = {
                ...criteria,
                'SmSubtaskItem.SmTaskItem.OpportunityItem.Block.Program.Name.StartsWith': event.target.value.toUpperCase(),
              };
              setCriteria(newCriteria);
            }}
          />
          <TextField
            name="Brand"
            type="input"
            label="Brand"
            variant="outlined"
            margin="dense"
            fullWidth
            value={
              criteria[
                'SmSubtaskItem.SmTaskItem.OpportunityItem.Block.Brand.Name.StartsWith'
              ]
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newCriteria = {
                ...criteria,
                'SmSubtaskItem.SmTaskItem.OpportunityItem.Block.Brand.Name.StartsWith': event.target.value.toUpperCase(),
              };
              setCriteria(newCriteria);
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClickCancel}>
          CANCEL
        </Button>
        <Button variant="contained" color="primary" onClick={handleClickOK}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubtaskItemWorkDurationSummaryDlg;
