import React from 'react';
import { Grid } from '@material-ui/core';
import { FilterContainer, SearchField } from '../../components';
import { IFilterContainerConfigProps } from '../../components/FilterContainer';
import { OpportunityCustomerListFilter } from '../../../state/opportunityCustomer';

const OpportunityCustomerGroupFilter: React.FC<
  IFilterContainerConfigProps<OpportunityCustomerListFilter>
> = props => {
  return (
    <FilterContainer
      {...props}
      render={filterProps => {
        const { localFilter, handleChangeFilter } = filterProps;
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Opportunity.AssignmentNumber.startsWith"
                label="Assignment Number"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Opportunity.Company.Name.startsWith"
                label="Our Company"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Opportunity.Program.Name.startsWith"
                label="Program"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Opportunity.Brand.Name.startsWith"
                label="Brand"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Opportunity.Responsibility.ResponsibilitySalesTeamMember.SalesTeamMember.User.FullNameWithUserName.Contains"
                label="Sales"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Opportunity.Responsibility.ResponsibilityWorkingTeamMember.SmWorkingTeamMember.User.FullNameWithUserName.Contains"
                label="CS"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="CustomerGroup.Name.startsWith"
                label="Customer Group"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default OpportunityCustomerGroupFilter;
