import React, { useMemo } from 'react';
import { Loading, Show } from '../../../../components';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  BaseSearchItemAndAddMultipleBlockStepProps,
  SearchItemAndAddMultipleBlockStep,
} from '../SearchItemAndAddMultipleBlockDialog';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../state/configureStore';
import { SearchItemAndAddMultipleBlockState } from '../searchItemAndAddMultipleBlockDialog.reducer';
import {
  selectCreateOpportunityItemResultIsError,
  selectCreateOpportunityItemResultIsNotError,
  selectSearchItemAndAddMultipleBlock,
  selectSearchItemResultWhereBlockNotFound,
} from '../searchItemAndAddMultipleBlockDialog.selector';
import SearchItemAndAddMultipleBlockStepper from '../SearchItemAndAddMultipleBlockStepper';
import useStyles from './summaryStep.style';
import copyToClipboard from 'copy-to-clipboard';
import { fetchOpportunityItemListByOpportunityId } from '../../../../../state/opportunityItem';
import { OpportunityState } from '../../../../../state/opportunity';

const SummaryStep: React.FC<BaseSearchItemAndAddMultipleBlockStepProps> = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    loading,
    listOfForceCreateItems,
  } = useSelector<AppState, SearchItemAndAddMultipleBlockState>(selectSearchItemAndAddMultipleBlock);
  const { item: opportunity } = useSelector<AppState, OpportunityState>(s => s.opportunity);

  const listOfSuccessBlocks = useSelector(selectCreateOpportunityItemResultIsNotError);
  const listOfFailedBlocks = useSelector(selectCreateOpportunityItemResultIsError);
  const listOfNotFoundBlocks = useSelector(selectSearchItemResultWhereBlockNotFound);

  const itemTextToBeCreate = useMemo<ReadonlyArray<string>>(() => {
    return listOfNotFoundBlocks.map(s => s.itemText)
      .concat(listOfForceCreateItems)
      .sort((a, b) => a.toString().localeCompare(b));
  }, [listOfForceCreateItems, listOfNotFoundBlocks]);

  const { onClose } = props;

  const onClickCopyItems = () => {
    copyToClipboard(itemTextToBeCreate.join('\n'));
  };
  const onClickCopyBlocks = () => {
    copyToClipboard(listOfFailedBlocks.map(b => b.block.blockNo).join('\n'));
  };
  const onClickClose = () => {
    dispatch(fetchOpportunityItemListByOpportunityId(opportunity.id!));
    onClose();
  };

  return <>
    <Loading isLoading={loading}>
      <DialogContent>
        <SearchItemAndAddMultipleBlockStepper activeStep={SearchItemAndAddMultipleBlockStep.Summary}/>
        <Show show={listOfSuccessBlocks.length > 0}>
          <Box mt={2} mb={2}>
            <Typography variant={'h5'}>Blocks success fully link to this assignment</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Block</TableCell>
                  <TableCell>Item</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listOfSuccessBlocks.map((result, index) => {
                  return <TableRow key={index}>
                    <TableCell>{result.block.blockNo}</TableCell>
                    <TableCell>{result.block.item}</TableCell>
                  </TableRow>;
                })}
              </TableBody>
            </Table>
          </Box>
        </Show>
        <Show show={listOfFailedBlocks.length > 0}>
          <Box mt={3} mb={2}>
            <Typography variant={'h5'}>
              Blocks failed to link to this assignment
              <Button
                onClick={onClickCopyBlocks}
                variant={'contained'}
                className={classes.copyButton}
              >
                Copy Block Number
              </Button>
            </Typography>
            <Typography variant={'caption'}>Please copy block numbers and try again, or block might already exists in
              this assignment.</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Block</TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell>Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listOfFailedBlocks.map((result, index) => {
                  return <TableRow key={index}>
                    <TableCell>{result.block.blockNo}</TableCell>
                    <TableCell>{result.block.item}</TableCell>
                    <TableCell>{result.message}</TableCell>
                  </TableRow>;
                })}
              </TableBody>
            </Table>
          </Box>
        </Show>
        <Show show={itemTextToBeCreate.length > 0}>
          <Box mt={3} mb={2}>
            <Typography variant={'h5'}>
              Items to be create
              <Button
                variant={'contained'}
                onClick={onClickCopyItems}
                className={classes.copyButton}>
                Copy Items
              </Button>
            </Typography>
            <Typography variant={'caption'}>Please copy items and create new blocks.</Typography>
            <TextField
              variant={'outlined'}
              fullWidth
              multiline
              rows={10}
              rowsMax={20}
              inputProps={{
                readOnly: true,
              }}
              value={itemTextToBeCreate.join('\n')}
            />
          </Box>
        </Show>
      </DialogContent>
      <DialogActions>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={onClickClose}>
          Close
        </Button>
      </DialogActions>
    </Loading>
  </>;
};

export default SummaryStep;
