import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    overflowX: 'scroll',
  },
  title: {
    padding: theme.spacing(1),
  },
  userContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));
