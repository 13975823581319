import { PageFilter } from '../state/app';
import { Program, ProgramListFilter } from '../state/program';
import axios from '../utils/axios';

const baseUrl = `/api/programs`;

export const fetchProgramList = async ({
  filters,
  paging,
}: {
  filters: ProgramListFilter;
  paging: PageFilter;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchProgram = async (id: Program['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createProgram = async (program: Program) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, program);
};

export const updateProgram = async (program: Program) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, program);
};
