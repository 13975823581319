import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubtaskItemBlockArtTemplateStatusLabel } from '../../../../constants/subtaskItemBlockArtTemplateStatus';
import { ISubtaskItem } from '../../../../models/subtaskItem.model';
import { AppState } from '../../../../state/configureStore';
import {
  deleteSubtaskItemArtTemplates,
  fetchSubtaskItemArtTemplateList,
  SubtaskItemArtTemplateState,
} from '../../../../state/subtaskItemArtTemplate';
import { TableLoading } from '../../../components';
import SubtaskItemArtTemplateCreateDialog from './SubtaskItemArtTemplateCreateDialog';

interface SubtaskItemArtTemplateTableProps {
  subtaskItem: ISubtaskItem;
}

const SubtaskItemArtTemplateTable: React.FC<SubtaskItemArtTemplateTableProps> = props => {
  const dispatch = useDispatch();
  const { subtaskItem } = props;
  const block = subtaskItem!.smTaskItem!.opportunityItem!.block;
  const [openSubtaskItemArtTemplateDlg, setOpenSubtaskItemArtTemplateDlg] = useState(
    false,
  );

  const { items: subtaskItemArtTemplates, totalItems, loading } = useSelector<
    AppState,
    SubtaskItemArtTemplateState
  >(state => state.subtaskItemArtTemplate);

  useEffect(() => {
    dispatch(
      fetchSubtaskItemArtTemplateList(
        { 'SmSubtaskItemId.EqualsTo': subtaskItem.id! },
        { pageNumber: 0, pageSize: 99 },
      ),
    );
  }, [dispatch, subtaskItem]);

  const deleteSubtaskItemArtTemplate = (
    smSubtaskItemArtTemplateId: number,
    smSubtaskItemArtId: number,
  ) => {
    dispatch(
      deleteSubtaskItemArtTemplates({ smSubtaskItemArtTemplateId, smSubtaskItemArtId }),
    );
  };

  return (
    <>
      <SubtaskItemArtTemplateCreateDialog
        open={openSubtaskItemArtTemplateDlg}
        handleClose={() => {
          setOpenSubtaskItemArtTemplateDlg(false);
        }}
        block={block!}
        subtaskItem={subtaskItem}
      />

      <Button
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => {
          setOpenSubtaskItemArtTemplateDlg(true);
        }}
      >
        {`Add Art Template`}
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Art Template Number</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={2}>
            {subtaskItemArtTemplates.map((subtaskItemArtTemplate, index) => (
              <TableRow key={subtaskItemArtTemplate.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  {subtaskItemArtTemplate.blockArtTemplate!.artTemplateNumber}
                </TableCell>
                <TableCell>
                  {SubtaskItemBlockArtTemplateStatusLabel[subtaskItemArtTemplate.status!]}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      deleteSubtaskItemArtTemplate(
                        subtaskItemArtTemplate.id!,
                        subtaskItemArtTemplate.smSubtaskItemId!,
                      );
                    }}
                  >
                    <DeleteIcon />
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableLoading>
        </TableBody>
      </Table>
    </>
  );
};

export default SubtaskItemArtTemplateTable;
