import { IResponsibilitySalesTeamMember } from '../../models/responsibilitySalesTeamMember.model';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_RESPONSIBILITY_SALESTEAM_MEMBER,
  FETCH_RESPONSIBILITY_SALESTEAM_MEMBER,
  FETCH_RESPONSIBILITY_SALESTEAM_MEMBER_LIST,
  UPDATE_RESPONSIBILITY_SALESTEAM_MEMBER,
  CREATE_RESPONSIBILITY_SALESTEAM_MEMBER,
  REPLACE_RESPONSIBILITY_SALESTEAM_MEMBER,
  SET_RESPONSIBILITY_SALETEAM_MEMBER,
} from './responsibilitySalesTeamMember.actions';
import { ResponsibilitySalesTeamMemberState } from './responsibilitySalesTeamMember.types';

const getInitialResponsibilitySalesTeamMemberState = (): IResponsibilitySalesTeamMember => ({});

const initialState: ResponsibilitySalesTeamMemberState = {
  errorMessage: '',
  item: getInitialResponsibilitySalesTeamMemberState(),
  items: [],
  loading: false,
  totalItems: 0,
};

export default (
  state: ResponsibilitySalesTeamMemberState = initialState,
  action: AppAction,
): ResponsibilitySalesTeamMemberState => {
  switch (action.type) {
    case REQUEST(FETCH_RESPONSIBILITY_SALESTEAM_MEMBER_LIST):
    case REQUEST(CREATE_RESPONSIBILITY_SALESTEAM_MEMBER):
    case REQUEST(FETCH_RESPONSIBILITY_SALESTEAM_MEMBER):
    case REQUEST(UPDATE_RESPONSIBILITY_SALESTEAM_MEMBER):
    case REQUEST(REPLACE_RESPONSIBILITY_SALESTEAM_MEMBER):
      return { ...state, loading: true };
    case SUCCESS(FETCH_RESPONSIBILITY_SALESTEAM_MEMBER_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_RESPONSIBILITY_SALESTEAM_MEMBER_LIST):
    case FAILURE(CREATE_RESPONSIBILITY_SALESTEAM_MEMBER):
    case FAILURE(FETCH_RESPONSIBILITY_SALESTEAM_MEMBER):
    case FAILURE(UPDATE_RESPONSIBILITY_SALESTEAM_MEMBER):
    case FAILURE(REPLACE_RESPONSIBILITY_SALESTEAM_MEMBER):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_RESPONSIBILITY_SALESTEAM_MEMBER):
    case SUCCESS(FETCH_RESPONSIBILITY_SALESTEAM_MEMBER):
    case SUCCESS(UPDATE_RESPONSIBILITY_SALESTEAM_MEMBER):
    case SUCCESS(REPLACE_RESPONSIBILITY_SALESTEAM_MEMBER):
      return { ...state, loading: false, item: action.payload.data };
    case SET_RESPONSIBILITY_SALETEAM_MEMBER:
      return { ...state, items: action.payload };
    case CLEAR_RESPONSIBILITY_SALESTEAM_MEMBER:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
