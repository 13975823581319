import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import {
  createProgramAndBrandRequestItem,
  fetchProgramAndBrandRequestItem,
  fetchProgramAndBrandRequestItemList,
  updateProgramAndBrandRequestItem,
  deleteProgramAndBrandRequestItem,
} from '../../services/programAndBrandRequestItemService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import { AppState } from '../configureStore';
import { fetchProgramAndBrandRequest } from '../programAndBrandRequest/programAndBrandRequest.actions';
import { ProgramAndBrandRequest } from '../programAndBrandRequest/programAndBrandRequest.types';
import {
  CREATE_PROGRAMANDBRANDREQUESTITEM,
  FETCH_PROGRAMANDBRANDREQUESTITEM,
  FETCH_PROGRAMANDBRANDREQUESTITEM_LIST,
  UPDATE_PROGRAMANDBRANDREQUESTITEM,
  DELETE_PROGRAMANDBRANDREQUESTITEM,
} from './programAndBrandRequestItem.actions';

function* fetchProgramAndBrandRequestItemSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchProgramAndBrandRequestItem, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchProgramAndBrandRequestItemListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchProgramAndBrandRequestItemList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createProgramAndBrandRequestItemSaga(action: AppAction) {
  try {
    const { payload: newProgramAndBrandRequestItem, callback } = action;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(
      createProgramAndBrandRequestItem,
      newProgramAndBrandRequestItem,
    );
    const programAndBrandRequest = yield select(getProgramAndBrandRequestFromState);
    if (programAndBrandRequest.id) {
      yield put(fetchProgramAndBrandRequest(programAndBrandRequest.id!));
    }
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Created ProgramAndBrandRequestItem successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateProgramAndBrandRequestItemSaga(action: AppAction) {
  try {
    const { payload: newProgramAndBrandRequestItem, callback } = action;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(
      updateProgramAndBrandRequestItem,
      newProgramAndBrandRequestItem,
    );
    const programAndBrandRequest = yield select(getProgramAndBrandRequestFromState);
    if (programAndBrandRequest.id) {
      yield put(fetchProgramAndBrandRequest(programAndBrandRequest.id!));
    }
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Updated ProgramAndBrandRequestItem successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteProgramAndBrandRequestItemSaga(action: AppAction) {
  const { payload } = action;
  try {
    yield put({ type: REQUEST(action.type) });
    yield call(deleteProgramAndBrandRequestItem, payload);
    const programAndBrandRequest = yield select(getProgramAndBrandRequestFromState);
    if (programAndBrandRequest.id) {
      yield put(fetchProgramAndBrandRequest(programAndBrandRequest.id!));
    }
    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Deleted successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function getProgramAndBrandRequestFromState(state: AppState): ProgramAndBrandRequest {
  const { item } = state.programAndBrandRequest;
  return item;
}

export default function* watchProgramAndBrandRequestItem() {
  yield takeLatest(FETCH_PROGRAMANDBRANDREQUESTITEM, fetchProgramAndBrandRequestItemSaga);
  yield debounce(
    250,
    FETCH_PROGRAMANDBRANDREQUESTITEM_LIST,
    fetchProgramAndBrandRequestItemListSaga,
  );
  yield takeLatest(
    CREATE_PROGRAMANDBRANDREQUESTITEM,
    createProgramAndBrandRequestItemSaga,
  );
  yield takeLatest(
    UPDATE_PROGRAMANDBRANDREQUESTITEM,
    updateProgramAndBrandRequestItemSaga,
  );
  yield takeLatest(
    DELETE_PROGRAMANDBRANDREQUESTITEM,
    deleteProgramAndBrandRequestItemSaga,
  );
}
