import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLOSE_SAMPLE_DEV_HL_APPROVAL_REQUEST_DIALOG,
  FETCH_SAMPLE_DEV_HL_APPROVAL_REQUEST_LIST,
  OPEN_SAMPLE_DEV_HL_APPROVAL_REQUEST_DIALOG,
  UPDATE_SAMPLE_DEV_HL_APPROVAL_REQUEST_RESPONSE,
} from './sampleDevelopmentHlApprovalRequest.actions';
import {
  SampleDevelopmentHlApprovalRequest,
  SampleDevelopmentHlApprovalRequestState,
} from './sampleDevelopmentHlApprovalRequest.types';

const getInitialSampleDevelopmentHlApprovalRequestState = (): SampleDevelopmentHlApprovalRequest => ({
  id: 0,
  hasResponded: false,
  requestedByUser: {
    id: 0,
    firstName: '',
    lastName: '',
    activated: true,
    email: '',
    login: '',
  },
  comment: '',
});

const initialState: SampleDevelopmentHlApprovalRequestState = {
  errorMessage: '',
  item: getInitialSampleDevelopmentHlApprovalRequestState(),
  items: [],
  loading: false,
  totalItems: 0,
  openDialog: false,
};

export default (
  state: SampleDevelopmentHlApprovalRequestState = initialState,
  action: AppAction,
): SampleDevelopmentHlApprovalRequestState => {
  switch (action.type) {
    case REQUEST(FETCH_SAMPLE_DEV_HL_APPROVAL_REQUEST_LIST):
    case REQUEST(UPDATE_SAMPLE_DEV_HL_APPROVAL_REQUEST_RESPONSE):
      return { ...state, loading: true };
    case SUCCESS(FETCH_SAMPLE_DEV_HL_APPROVAL_REQUEST_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_SAMPLE_DEV_HL_APPROVAL_REQUEST_LIST):
    case FAILURE(UPDATE_SAMPLE_DEV_HL_APPROVAL_REQUEST_RESPONSE):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(UPDATE_SAMPLE_DEV_HL_APPROVAL_REQUEST_RESPONSE):
      return { ...state, loading: false };
    case OPEN_SAMPLE_DEV_HL_APPROVAL_REQUEST_DIALOG:
      return {
        ...state,
        openDialog: true,
      };
    case CLOSE_SAMPLE_DEV_HL_APPROVAL_REQUEST_DIALOG:
      return {
        ...state,
        openDialog: false,
      };
    default:
      return state;
  }
};
