import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';

import { Edit as EditIcon, Save as SaveIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { FieldArray, Form, Formik, FormikHelpers, getIn } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { AppState } from '../../../../state/configureStore';
import { createSubtasks } from '../../../../state/subtask';
import { fetchSubtaskTypeList, SubtaskTypeState } from '../../../../state/subtaskType';
import { TaskState } from '../../../../state/task';
import { Delete as DeleteIcon } from '@material-ui/icons';
import UserComboBox from '../../../components/UserComboBox';
import { User } from '../../../../state/user';
import { TaskItemChooseDialog } from '../../../components/TaskItemChooseDialog/';
import { TaskItemState, createTaskItems } from '../../../../state/taskItem';
import { OpportunityLinkButton } from '../../../components';
import { pushNotificationMessage } from '../../../components/Notification';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    assignee: {
      width: 300,
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
    },
    subtaskType: {
      marginLeft: theme.spacing(0),
      minWidth: 300,
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    subtaskTable: {
      marginBottom: theme.spacing(2),
    },
  }),
);

interface SubtaskAutoCreateDialogProps {
  open: boolean;
  handleClose: () => void;
}

const SubtaskAutoCreateSchema = yup.object().shape({
  subtasks: yup.array().of(
    yup.object().shape({
      subject: yup.string().required('Subject is required'),
      smSubtaskTypeId: yup.number().required('Subtask type is required'),
      assigneeId: yup
        .number()
        .required('Assignee is required')
        .typeError('Assignee is required'),
      dueDate: yup
        .string()
        .nullable(true)
        .test(
          'dueDateFormat',
          'Invalid date format',
          (value) => !isNaN(new Date(value).getTime()),
        )
        .required('Due date is required'),
    }),
  ),
});

const SubtaskAutoCreateDialog: React.FC<SubtaskAutoCreateDialogProps> = (props) => {
  const classes = useStyles();
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const [openTaskItemChooseDialog, setOpenTaskItemChooseDialog] = useState(false);
  const { item: task } = useSelector<AppState, TaskState>((state) => state.task);
  const { selectedItems } = useSelector<AppState, TaskItemState>(
    (state) => state.taskItem,
  );
  const { items: subtaskTypeList } = useSelector<AppState, SubtaskTypeState>(
    (state) => state.subtaskType,
  );

  const callback = () => {
    handleClose();
  };

  const handleSubmit = (values: any, action: FormikHelpers<any>) => {
    if (selectedItems.length === 0) {
      dispatch(
        pushNotificationMessage({ message: 'Please add task item', type: 'error' }),
      );
    } else {
      const afterCreateTaskItem = () => {
        dispatch(createSubtasks(values.subtasks, callback));
      };
      dispatch(createTaskItems(selectedItems, afterCreateTaskItem));
    }
  };

  const addTaskItem = () => {
    setOpenTaskItemChooseDialog(true);
  };

  const getInitialValue = useMemo(
    () => () =>
      subtaskTypeList.map((subtaskType) => {
        const { subject, dueDate, id } = task;
        return {
          smTaskId: id,
          subject: `${subject} ${subtaskType.name}`,
          smSubtaskTypeId: subtaskType.id,
          assignee: null,
          assigneeId: null,
          dueDate,
        };
      }),
    [subtaskTypeList, task],
  );

  useEffect(() => {
    dispatch(
      fetchSubtaskTypeList(
        { 'IsSpecialType.EqualsTo': 'false' },
        { pageNumber: 0, pageSize: 15 },
      ),
    );
  }, [dispatch]);

  return (
    <>
      <TaskItemChooseDialog
        open={openTaskItemChooseDialog}
        handleClose={() => {
          setOpenTaskItemChooseDialog(false);
        }}
      />
      <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth={true}>
        <Formik
          enableReinitialize={true}
          initialValues={{ subtasks: getInitialValue() }}
          validationSchema={SubtaskAutoCreateSchema}
          onSubmit={handleSubmit}
          validateOnChange={true}
        >
          {({
            values,
            handleChange,
            errors,
            submitForm,
            setFieldValue,
            resetForm,
            setStatus,
            setFieldError,
          }) => {
            return (
              <Form>
                <DialogTitle>Subtask Create</DialogTitle>
                <DialogContent>
                  <Typography variant="h6" gutterBottom>
                    Subtask
                  </Typography>
                  <Table className={classes.subtaskTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Subject</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Assignee</TableCell>
                        <TableCell>Due date</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <FieldArray
                        name="subtasks"
                        render={(arrayHelpers) => (
                          <>
                            {values.subtasks.map((value, index) => {
                              const getErrorMessage = (
                                fieldName: string,
                                index: number,
                              ) => getIn(errors, `subtasks[${index}].${fieldName}`);
                              return (
                                <TableRow key={index}>
                                  <TableCell>
                                    <TextField
                                      className={classes.textField}
                                      value={value.subject}
                                      label="Subject"
                                      margin="normal"
                                      onChange={(event) => {
                                        const newSubject = event.target.value;
                                        arrayHelpers.replace(index, {
                                          ...values.subtasks[index],
                                          subject: newSubject,
                                        });
                                      }}
                                      error={!!getErrorMessage('subject', index)}
                                      helperText={getErrorMessage('subject', index)}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <FormControl className={classes.formControl}>
                                      <InputLabel>Subtask Type</InputLabel>
                                      <Select
                                        className={classes.subtaskType}
                                        value={value.smSubtaskTypeId}
                                        onChange={(event) => {
                                          const newSubtaskType = event.target.value;
                                          arrayHelpers.replace(index, {
                                            ...values.subtasks[index],
                                            smSubtaskTypeId: newSubtaskType,
                                          });
                                        }}
                                        inputProps={{ name: 'smSubtaskTypeId' }}
                                      >
                                        {subtaskTypeList.map((item) => {
                                          return (
                                            <MenuItem key={item.id!} value={item.id!}>
                                              {item.name!}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </TableCell>
                                  <TableCell>
                                    <div className={classes.assignee}>
                                      <UserComboBox
                                        handleChange={(result: User) => {
                                          arrayHelpers.replace(index, {
                                            ...values.subtasks[index],
                                            assignee: result ? result : null,
                                            assigneeId: result ? result.id : 0,
                                          });
                                        }}
                                        selectedValue={value.assignee as unknown as User}
                                        error={!!getErrorMessage('assigneeId', index)}
                                        helperText={getErrorMessage('assigneeId', index)}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <KeyboardDatePicker
                                      margin="normal"
                                      label="Due Date"
                                      format="yyyy-MM-dd"
                                      value={value.dueDate}
                                      onChange={(selectedDate, selectedStringDate) => {
                                        setFieldValue(
                                          `subtasks[${index}].dueDate` as 'subtasks',
                                          selectedStringDate,
                                        );
                                      }}
                                      error={!!getErrorMessage('dueDate', index)}
                                      helperText={getErrorMessage('dueDate', index)}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                      }}
                                      variant="contained"
                                      color="secondary"
                                    >
                                      <DeleteIcon className={classes.leftIcon} />
                                      DELETE
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </>
                        )}
                      />
                    </TableBody>
                  </Table>
                  <Typography variant="h6" gutterBottom>
                    Task Item
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>No.</TableCell>
                        <TableCell>Assignment Number</TableCell>
                        <TableCell>Program</TableCell>
                        <TableCell>Brand</TableCell>
                        <TableCell>Block</TableCell>
                        <TableCell>Item</TableCell>
                        <TableCell>Assignee</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedItems.map((item, index) => {
                        const block = item.opportunityItem!.block!;
                        const opportunity = item.opportunityItem!.opportunity!;
                        const assignee = item.opportunityItem!.npdUser
                          ? item.opportunityItem!.npdUser!.fullName
                          : item.opportunityItem!.sampleDevUser!.fullName;
                        return (
                          <TableRow key={item.id}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              <OpportunityLinkButton opportunity={opportunity} />
                            </TableCell>
                            <TableCell>{opportunity.program!.name}</TableCell>
                            <TableCell>{opportunity.brand!.name}</TableCell>
                            <TableCell>{block.blockNo}</TableCell>
                            <TableCell>{block.item!}</TableCell>
                            <TableCell>{assignee}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </DialogContent>
                <DialogActions>
                  <Button color="primary" variant="contained" onClick={addTaskItem}>
                    <EditIcon className={classes.leftIcon} />
                    Manage Task Item
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={submitForm}
                  >
                    <SaveIcon className={classes.leftIcon} />
                    Save
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default SubtaskAutoCreateDialog;
