import { ISampleDevelopmentTl } from '../../models/sampleDevelopmentTl.model';
import { AppAction, PageFilter } from '../app';
import { SampleDevelopmentTlListFilter } from './sampleDevelopmentTl.types';

export const FETCH_SAMPLE_DEVELOPMENT_TL_LIST = 'FETCH_SAMPLE_DEVELOPMENT_TL_LIST';
export const FETCH_SAMPLE_DEVELOPMENT_TL = 'FETCH_SAMPLE_DEVELOPMENT_TL';
export const CREATE_SAMPLE_DEVELOPMENT_TL = 'CREATE_SAMPLE_DEVELOPMENT_TL';
export const UPDATE_SAMPLE_DEVELOPMENT_TL = 'UPDATE_SAMPLE_DEVELOPMENT_TL';
export const CLEAR_SAMPLE_DEVELOPMENT_TL = 'CLEAR_SAMPLE_DEVELOPMENT_TL';
export const SUBMIT_SAMPLE_DEVELOPMENT_TL_FOR_APPROVAL =
  'SUBMIT_SAMPLE_DEVELOPMENT_TL_FOR_APPROVAL';
export const DOWNLOAD_SAMPLE_DEVELOPMENT_TL = 'DOWNLOAD_SAMPLE_DEVELOPMENT_TL';

export const fetchSampleDevelopmentTlList = (
  filters: SampleDevelopmentTlListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SAMPLE_DEVELOPMENT_TL_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const downloadSampleDevelopmentTl = (id: number): AppAction => {
  return {
    type: DOWNLOAD_SAMPLE_DEVELOPMENT_TL,
    payload: id,
  };
};

export const fetchSampleDevelopmentTl = (id: number): AppAction => ({
  type: FETCH_SAMPLE_DEVELOPMENT_TL,
  payload: id,
});

export const createSampleDevelopmentTl = (payload: {
  values: ISampleDevelopmentTl;
}): AppAction => ({
  type: CREATE_SAMPLE_DEVELOPMENT_TL,
  payload,
});

export const updateSampleDevelopmentTl = (payload: {
  values: ISampleDevelopmentTl;
}): AppAction => ({
  type: UPDATE_SAMPLE_DEVELOPMENT_TL,
  payload,
});

export const clearSampleDevelopmentTl = () => ({
  type: CLEAR_SAMPLE_DEVELOPMENT_TL,
});

export const submitSampleDevelopmentTlForApproval = (id: number): AppAction => ({
  type: SUBMIT_SAMPLE_DEVELOPMENT_TL_FOR_APPROVAL,
  payload: id,
});
