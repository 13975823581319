import React from 'react';
import { fetchProductTypeList as fetchProductTypeListService } from '../../services/productTypeService';
import { ProductType } from '../../state/productType';
import AsyncAutoComplete from './AsyncAutoComplete';

interface EBlockInterCompProdTypeComboProps {
  handleChange: (result: ProductType) => void;
  selectedValue: ProductType;
  isDisabled?: boolean;
  error?: boolean;
  helperText?: string;
}

const EBlockInterCompProdTypeCombo: React.FC<
  EBlockInterCompProdTypeComboProps
> = props => {
  const { handleChange, selectedValue, isDisabled = false, error, helperText } = props;

  const productTypePromiseOptions = async (inputValue: string) => {
    const { data: rows } = await fetchProductTypeListService({
      filters: { 'Name.startsWith': inputValue },
      paging: { pageNumber: 0, pageSize: 10 },
    });

    return rows;
  };

  return (
    <AsyncAutoComplete
      name=""
      value={selectedValue}
      label={''}
      getOptionValue={(option: ProductType) => {
        return option.id!.toString();
      }}
      getOptionLabel={(option: ProductType) => {
        return option.name;
      }}
      promiseOptions={productTypePromiseOptions}
      placeholder={'HL/NL/PL/TL/WL'}
      handleChange={handleChange}
      isDisabled={isDisabled}
      error={error}
      helperText={helperText}
    />
  );
};

export default EBlockInterCompProdTypeCombo;
