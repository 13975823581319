import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
  },
  error: {
    marginLeft: theme.spacing(1),
    marginTop: '0.5rem',
    color: 'red',
  }
}));

export default useStyles;
