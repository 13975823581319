import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, UserState, updateUserRole } from '../../../../state/user';
import { AppState } from '../../../../state/configureStore';
import { fetchAllRoleList, RoleState } from '../../../../state/role';
import { Autocomplete } from '@material-ui/lab';
import { IRole } from '../../../../models/role.model';
import { Save, Save as SaveIcon, Close as CloseIcon } from '@material-ui/icons';

interface UserEditDialogProps {
  open: boolean;
  onClose: () => void;
  userId: number | null;
}

const UserEditDialog: React.FC<UserEditDialogProps> = (props) => {
  const { open, onClose, userId } = props;
  const dispatch = useDispatch();
  const { item: user } = useSelector<AppState, UserState>((state) => state.user);
  const { items: roleList } = useSelector<AppState, RoleState>((state) => state.role);

  const [userRole, setUserRole] = useState<IRole[]>([
    {
      id: 0,
      name: '',
    },
  ]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUser(userId));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    dispatch(fetchAllRoleList());
  }, [dispatch]);

  useEffect(() => {
    if (user.roles !== undefined) {
      const userRoles = user.roles.map((r) => {
        const role = roleList.find((f) => f.name === r);
        return { id: role?.id, name: role?.name };
      });

      setUserRole(userRoles);
    }
  }, [user, roleList]);

  const updateUser = () => {
    const update = {
      userId: user.id,
      roleIds: userRole.map((_) => _.id),
    };
    dispatch(updateUserRole(update));
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                name="login"
                type="input"
                label="Username"
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
                value={user.login}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="userRole"
                size="small"
                options={roleList}
                value={userRole}
                getOptionLabel={(option) => option.name!}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Role"
                    placeholder="Select Role"
                  />
                )}
                onChange={(event, value) => {
                  setUserRole(value);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            onClick={() => onClose()}
            style={{ marginBottom: 10 }}
            size="small"
            startIcon={<CloseIcon />}
          >
            Close
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              updateUser();
            }}
            size="small"
            startIcon={<SaveIcon />}
            style={{ marginRight: 15, marginBottom: 10 }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserEditDialog;
