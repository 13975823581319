import { ISubtaskItemArtTemplate } from '../models/subtaskItemArtTemplate.model';
import { PageFilter } from '../state/app';
import { SubtaskItemArtTemplateListFilter } from '../state/subtaskItemArtTemplate/subtaskItemArtTemplate.type';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/smSubtaskItemBlockArtTemplates`;

export const fetchSubtaskItemArtTemplateList = async ({
  filters,
  paging,
}: {
  filters: SubtaskItemArtTemplateListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const createSubtaskItemArtTemplate = async (
  ubtaskItemArtTemplate: ISubtaskItemArtTemplate,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, ubtaskItemArtTemplate);
};

export const deleteSubtaskItemArtTemplate = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};
