import { IProjectProgram } from '../../models/projectProgram.model';
import { AppAction, PageFilter, AppCallback } from '../app';
import {
  ProjectProgramCreateOrUpdatePayload,
  ProjectProgramListFilter,
} from './projectProgram.types';

export const FETCH_PROJECT_PROGRAM = 'FETCH_PROJECT_PROGRAM';
export const FETCH_PROJECT_PROGRAM_LIST = 'FETCH_PROJECT_PROGRAM_LIST';
export const CREATE_PROJECT_PROGRAM = 'CREATE_PROJECT_PROGRAM';
export const UPDATE_PROJECT_PROGRAM = 'UPDATE_PROJECT_PROGRAM';
export const CLEAR_PROJECT_PROGRAM = 'CLEAR_PROJECT_PROGRAM';
export const DELETE_PROJECT_PROGRAM = 'DELETE_PROJECT_PROGRAM';
export const CREATE_PROJECT_PROGRAMS = 'CREATE_PROJECT_PROGRAMS';

export const fetchProjectProgram = (id: IProjectProgram['id']): AppAction => ({
  type: FETCH_PROJECT_PROGRAM,
  payload: id,
});

export const fetchProjectProgramList = (
  filters: ProjectProgramListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_PROJECT_PROGRAM_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchProjectProgramListBySmProjectId = (smProjectId: number): AppAction => ({
  type: FETCH_PROJECT_PROGRAM_LIST,
  payload: {
    filters: { 'smProjectId.equalsTo': smProjectId },
    paging: { pageNumber: 0, pageSize: 99999 },
  },
});

export const createProjectProgram = (
  payload: ProjectProgramCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_PROJECT_PROGRAM,
  payload,
});

export const createProjectPrograms = (
  payload: any,
  callback?: AppCallback,
): AppAction => ({
  type: CREATE_PROJECT_PROGRAMS,
  payload,
  callback,
});

export const updateProjectProgram = (
  payload: ProjectProgramCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_PROJECT_PROGRAM,
  payload,
});

export const clearProjectProgram = () => ({
  type: CLEAR_PROJECT_PROGRAM,
});

export const deleteProjectProgram = (id: number) => ({
  type: DELETE_PROJECT_PROGRAM,
  payload: { id },
});
