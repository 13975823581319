import { AppAction, PageFilter } from '../app';
import { TeamMemberListFilter } from './teamMember.type';

export const FETCH_TEAM_MEMBER_LIST = 'FETCH_TEAM_MEMBER_LIST';
export const CLEAR_TEAM_MEMBER = 'CLEAR_TEAM_MEMBER';
export const FIND_ALL_TEAM_MEMBER = 'FIND_ALL_TEAM_MEMBER';

export const fetchTeamMemberList = (
  filters: TeamMemberListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_TEAM_MEMBER_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const findAllTeamMemberList = (
  filters: TeamMemberListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FIND_ALL_TEAM_MEMBER,
    payload: {
      filters,
      paging,
    },
  };
};

export const clearTeamMember = () => ({
  type: CLEAR_TEAM_MEMBER,
});
