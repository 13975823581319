import { FETCH_PENDING_APPROVAL_ASSIGNMENT_LIST } from './pendingApprovalAssignmentList.actions';
import { AppAction } from '../../../../state/app';
import { FAILURE, REQUEST, SUCCESS } from '../../../../utils/actionTypeUtil';
import { findAllOpportunityApprovalRequestList } from '../../../../services/opportunityApprovalRequestService';
import { pushNotificationMessage } from '../../../components/Notification';
import { call, debounce, put } from 'redux-saga/effects';

function* fetchPendingApprovalAssignmentListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(findAllOpportunityApprovalRequestList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchPendingApprovalAssignmentList() {
  yield debounce(
    250,
    FETCH_PENDING_APPROVAL_ASSIGNMENT_LIST,
    fetchPendingApprovalAssignmentListSaga,
  );
}
