import { call, debounce, put, takeLatest, select } from 'redux-saga/effects';
import { cloneDeep } from 'lodash';
import {
  createSubtask,
  deleteSubtask,
  fetchSubtask,
  fetchSubtaskList,
  fetchSubtaskStatus,
  updateSubtask,
} from '../../services/subtaskService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_SUBTASK,
  DELETE_SUBTASK,
  FETCH_SUBTASK,
  FETCH_SUBTASK_LIST,
  UPDATE_SUBTASK,
  UPDATE_SUBTASK_STATUS,
  CREATE_SUBTASKS,
  FETCH_RELATED_SUBTASK_LIST,
  fetchRelatedSubtaskListByTaskId,
  FETCH_SUBTASK_STATUS,
} from './subtask.actions';
import { ISubtask } from '../../models/subtask.model';
import { fetchSubtaskItemListBySubtaskId } from '../subtaskItem';
import { AppState } from '../configureStore';
import { ISubtaskAssignee } from '../../models/subtaskAssignee.model';
import { createSubtaskAssignee } from '../../services/subtaskAssigneeService';

function* fetchSubtaskStatusSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchSubtaskStatus);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchSubtaskSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchSubtask, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchSubtaskListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSubtaskList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}
function* fetchRelatedSubtaskListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const userId = yield select(getUserIdFromState);
    const subtaskRelatedToRequesterIdFilter = {
      ...action.payload.filters,
      'RequesterId.EqualsTo': userId,
    };
    const subtaskRelatedToRequesterIdPayload = cloneDeep(action.payload);
    subtaskRelatedToRequesterIdPayload.filters = subtaskRelatedToRequesterIdFilter;
    const { data: subtaskRelatedToRequesterIdList } = yield call(
      fetchSubtaskList,
      subtaskRelatedToRequesterIdPayload,
    );

    const subtaskRelatedToAssigneeIdFilter = {
      ...action.payload.filters,
      'AssigneeId.EqualsTo': userId,
    };
    const subtaskRelatedToAssigneeIdPayload = cloneDeep(action.payload);
    subtaskRelatedToAssigneeIdPayload.filters = subtaskRelatedToAssigneeIdFilter;
    const { data: subtaskRelatedToAssigneeIdList } = yield call(
      fetchSubtaskList,
      subtaskRelatedToAssigneeIdPayload,
    );

    const subtaskList = [...subtaskRelatedToRequesterIdList];
    subtaskRelatedToAssigneeIdList.forEach((subtaskRelatedToAssigneeId: any) => {
      const isSubtaskExistInList = subtaskList.find(
        (subtask) => subtask.id === subtaskRelatedToAssigneeId.id,
      );
      if (!isSubtaskExistInList) {
        subtaskList.push(subtaskRelatedToAssigneeId);
      }
    });
    yield put({
      type: SUCCESS(action.type),
      payload: { items: subtaskList, totalItems: subtaskList.length },
    });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createSubtaskSaga(action: AppAction) {
  try {
    const { payload, callback } = action;
    const { assigneeId: assigneeIds, ...newSubtask } = payload as ISubtask;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createSubtask, newSubtask);
    const createdSubtaskId = data.id;
    if (assigneeIds) {
      for (const assigneeId of assigneeIds) {
        const subtaskAssignee = { smSubtaskId: createdSubtaskId, userId: assigneeId };
        yield call(createSubtaskAssignee, subtaskAssignee as ISubtaskAssignee);
      }
    }
    yield put({ type: SUCCESS(action.type), payload: { data } });

    const successMessage = `Created subtask successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    if (callback) {
      callback();
    }
    const smTaskId = newSubtask.smTaskId!;
    yield put(fetchRelatedSubtaskListByTaskId(smTaskId));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createSubtasksSaga(action: AppAction) {
  try {
    const { payload, callback } = action;
    const subtasks = payload as ISubtask[];

    yield put({ type: REQUEST(action.type) });

    for (const subtask of subtasks) {
      yield call(createSubtask, subtask);
    }
    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Created subtasks successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateSubtaskSaga(action: AppAction) {
  try {
    const { payload, callback } = action;
    const subtask = payload as ISubtask;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateSubtask, subtask);
    yield put({ type: SUCCESS(action.type), payload: { data } });

    const successMessage = `Updated subtask successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    if (callback) {
      callback();
    }

    yield put(fetchRelatedSubtaskListByTaskId(subtask.smTaskId!));
    yield put(fetchSubtaskItemListBySubtaskId(subtask.id!));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteSubtaskSaga(action: AppAction) {
  const { payload } = action;
  const { id, smTaskId } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(deleteSubtask, id);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Deleted subtask successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put(fetchRelatedSubtaskListByTaskId(smTaskId));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateSubtaskStatusSaga(action: AppAction) {
  const { payload, type, callback } = action;
  const { subtask } = payload;
  const { smTaskId } = subtask;
  try {
    yield put({ type: REQUEST(type) });
    const response = yield call(updateSubtask, subtask);
    yield put({ type: SUCCESS(type), payload: response });
    const successMessage = 'Update Subtask Status Success.';
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put(fetchRelatedSubtaskListByTaskId(smTaskId));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(type), payload: { errorMessage: error.message } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
    yield put(fetchRelatedSubtaskListByTaskId(smTaskId));
    if (callback) {
      callback(error);
    }
    return;
  }

  if (callback) {
    callback();
  }
}

function getUserIdFromState(state: AppState) {
  const { userId } = state.auth;
  return userId;
}

export default function* watchSubtask() {
  yield takeLatest(FETCH_SUBTASK, fetchSubtaskSaga);
  yield debounce(250, FETCH_SUBTASK_LIST, fetchSubtaskListSaga);
  yield debounce(250, FETCH_RELATED_SUBTASK_LIST, fetchRelatedSubtaskListSaga);
  yield takeLatest(CREATE_SUBTASK, createSubtaskSaga);
  yield takeLatest(UPDATE_SUBTASK, updateSubtaskSaga);
  yield takeLatest(DELETE_SUBTASK, deleteSubtaskSaga);
  yield takeLatest(UPDATE_SUBTASK_STATUS, updateSubtaskStatusSaga);
  yield takeLatest(CREATE_SUBTASKS, createSubtasksSaga);
  yield takeLatest(FETCH_SUBTASK_STATUS, fetchSubtaskStatusSaga);
}
