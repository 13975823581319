import React from 'react';
import { Paper, Grid, Typography, Box } from '@material-ui/core';
import useStyles from './sampleDevelopmentPl.style';
import { TextFieldFastField } from '../../../components';

interface SampleDevelopmentPlFilePathProps {}

const SampleDevelopmentPlFilePath: React.FC<SampleDevelopmentPlFilePathProps> = () => {
  const classes = useStyles();

  return (
    <Box m={1}>
      <Paper variant="outlined">
        <Box m={1}>
          <Grid container spacing={1} direction="row" justifyContent="flex-start">
            <Grid sm={12} item>
              <Typography className={classes.title}>File Path</Typography>
            </Grid>
            <Grid sm={12} item>
              <TextFieldFastField
                name="filePath"
                label="File Path"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentPlFilePath;
