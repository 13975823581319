import React from 'react';
import { IEBlockMaterial } from '../../models/eBlockMaterial.model';
import { fetchEBlockMaterialList } from '../../services/eBlockMaterialService';
import AsyncAutoComplete from './AsyncAutoComplete';

interface EBlockMaterialComboBoxProps {
  handleChange: (result: IEBlockMaterial) => void;
  selectedValue: IEBlockMaterial;
  isDisabled?: boolean;
  error?: boolean;
  helperText?: string;
}

const EBlockMaterialComboBox: React.FC<EBlockMaterialComboBoxProps> = props => {
  const { handleChange, selectedValue, isDisabled = false, error, helperText } = props;

  const eBlockMaterialPromiseOptions = async (inputValue: string) => {
    const { data: rows } = await fetchEBlockMaterialList({
      filters: { 'CodeAndDesc.Contains': inputValue },
      paging: { pageNumber: 0, pageSize: 10 },
    });
    return rows;
  };

  return (
    <AsyncAutoComplete
      name=""
      value={selectedValue}
      getOptionValue={(option: IEBlockMaterial) => {
        return option.id!.toString();
      }}
      getOptionLabel={(option: IEBlockMaterial) => {
        return `${option.code!}: ${option.description!}`;
      }}
      promiseOptions={eBlockMaterialPromiseOptions}
      placeholder={''}
      handleChange={handleChange}
      isDisabled={isDisabled}
      error={error}
      helperText={helperText}
    />
  );
};

export default EBlockMaterialComboBox;
