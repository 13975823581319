import { IProjectProgramBrand } from '../../models/projectProgramBrand.model';
import { AppAction, PageFilter, AppCallback } from '../app';
import {
  ProjectProgramBrandCreateOrUpdatePayload,
  ProjectProgramBrandListFilter,
} from './projectProgramBrand.types';

export const FETCH_PROJECT_PROGRAM_BRAND = 'FETCH_PROJECT_PROGRAM_BRAND';
export const FETCH_PROJECT_PROGRAM_BRAND_LIST = 'FETCH_PROJECT_PROGRAM_BRAND_LIST';
export const CREATE_PROJECT_PROGRAM_BRAND = 'CREATE_PROJECT_PROGRAM_BRAND';
export const UPDATE_PROJECT_PROGRAM_BRAND = 'UPDATE_PROJECT_PROGRAM_BRAND';
export const CLEAR_PROJECT_PROGRAM_BRAND = 'CLEAR_PROJECT_PROGRAM_BRAND';
export const DELETE_PROJECT_PROGRAM_BRAND = 'DELETE_PROJECT_PROGRAM_BRAND';
export const CREATE_PROJECT_PROGRAM_BRANDS = 'CREATE_PROJECT_PROGRAM_BRANDS';
export const FETCH_BRAND_BY_PROJECT_ID = 'FETCH_BRAND_BY_PROJECT_ID';

export const fetchBrandByProjectId = (projectId?: number): AppAction => ({
  type: FETCH_BRAND_BY_PROJECT_ID,
  payload: projectId,
});

export const fetchProjectProgramBrand = (id: IProjectProgramBrand['id']): AppAction => ({
  type: FETCH_PROJECT_PROGRAM_BRAND,
  payload: id,
});

export const fetchProjectProgramBrandList = (
  filters: ProjectProgramBrandListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_PROJECT_PROGRAM_BRAND_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchProjectProgramBrandListBySmProjectIdSmProjectProgramId = (
  smProjectId: number,
  smProjectProgramId: number,
): AppAction => ({
  type: FETCH_PROJECT_PROGRAM_BRAND_LIST,
  payload: {
    filters: {
      'SmProjectId.equalsTo': smProjectId,
      'SmProjectProgramId.EqualsTo': smProjectProgramId,
    },
    paging: { pageNumber: 0, pageSize: 99999 },
  },
});

export const createProjectProgramBrand = (
  payload: ProjectProgramBrandCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_PROJECT_PROGRAM_BRAND,
  payload,
});

export const createProjectProgramBrands = (
  payload: any,
  callback?: AppCallback,
): AppAction => ({
  type: CREATE_PROJECT_PROGRAM_BRANDS,
  payload,
  callback,
});

export const updateProjectProgramBrand = (
  payload: ProjectProgramBrandCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_PROJECT_PROGRAM_BRAND,
  payload,
});

export const clearProjectProgramBrand = () => ({
  type: CLEAR_PROJECT_PROGRAM_BRAND,
});

export const deleteProjectProgramBrand = (id: number) => ({
  type: DELETE_PROJECT_PROGRAM_BRAND,
  payload: { id },
});
