import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import UnitOfPrice, { UnitOfPriceOptions } from '../../../constants/unitOfPrice';
import UnitOfQuantity, { UnitOfQuantityOptions } from '../../../constants/unitOfQuantity';
import { IMoq, IMoqWithAddedProps } from '../../../models/moq.model';
import { emptyStringToNull } from '../../../utils/yupUtil';
import { getForwardRefEditableTable } from '../EditableTable/EditableTable';
import SelectOptionCellEditor from '../EditableTable/SelectOptionCellEditor';

const EditableTable = getForwardRefEditableTable<IMoq>();

const MoqRowValidationSchema = Yup.object().shape({
  unitOfPrice: Yup.string()
    .oneOf(Object.values(UnitOfPrice))
    .required()
    .nullable(),
  quantity: Yup.number()
    .min(0)
    .required()
    .nullable(),
  unitOfQuantity: Yup.string()
    .oneOf(Object.values(UnitOfQuantity))
    .required()
    .nullable(),
  price: Yup.number()
    .min(0)
    .transform(emptyStringToNull)
    .nullable(),
});

const columnDefs = [
  {
    headerName: 'Currency',
    sortable: true,
    filter: true,
    editable: true,
    field: 'unitOfPrice',
    cellEditorFramework: SelectOptionCellEditor,
    cellEditorParams: {
      options: UnitOfPriceOptions,
    },
  },
  {
    headerName: 'Quantity',
    sortable: true,
    filter: true,
    editable: true,
    field: 'quantity',
    valueSetter: (params: any): boolean => {
      if (!isNaN(params.newValue)) {
        params.data.quantity = params.newValue;
        return true;
      }
      return false;
    },
  },
  {
    headerName: 'Unit of Quantity',
    sortable: true,
    filter: true,
    editable: true,
    field: 'unitOfQuantity',
    cellEditorFramework: SelectOptionCellEditor,
    cellEditorParams: {
      options: UnitOfQuantityOptions,
    },
  },
  {
    headerName: 'Target Price',
    sortable: true,
    filter: true,
    editable: true,
    field: 'price',
    valueSetter: (params: any): boolean => {
      if (!isNaN(params.newValue)) {
        params.data.price = params.newValue;
        return true;
      }
      return false;
    },
  },
];

interface MoqTableProps {
  defaultNewRowValue: IMoq;
  onUpdate: (createMoqListPayload: IMoq[]) => void;
  moqList: IMoqWithAddedProps[];
  blockNo?: string;
}

const MoqTable: React.FC<MoqTableProps> = props => {
  const { defaultNewRowValue, blockNo = '', onUpdate, moqList } = props;
  const localRows = useMemo<IMoq[]>(() => {
    return moqList.map(moq => ({ ...moq }));
  }, [moqList]);

  return (
    <>
      {blockNo && <Typography>MOQ {blockNo}</Typography>}
      <EditableTable
        rowValidationSchema={MoqRowValidationSchema}
        columnDefs={columnDefs}
        rows={localRows}
        onSave={(rows => {
          onUpdate(rows);
        })}
        getNewRow={() => {
          return defaultNewRowValue as IMoq;
        }}
        domLayout='autoHeight'
      />
    </>
  );
};

export default MoqTable;
