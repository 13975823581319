import { AppAction, PageFilter } from '../app';
import { SubtaskItemChangeStatusLogListFilter } from './subtaskItemChangeStatusLog.types';

export const FETCH_SUBTASK_ITEM_CHANGE_STATUS_LOG_LIST =
  'FETCH_SUBTASK_ITEM_CHANGE_STATUS_LOG_LIST';
export const CLEAR_SUBTASK_ITEM_CHANGE_STATUS_LOG =
  'CLEAR_SUBTASK_ITEM_CHANGE_STATUS_LOG';

export const fetchSubtaskItemChangeStatusLogList = (
  filters: SubtaskItemChangeStatusLogListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SUBTASK_ITEM_CHANGE_STATUS_LOG_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const clearSubtaskItemChangeStatusLog = () => ({
  type: CLEAR_SUBTASK_ITEM_CHANGE_STATUS_LOG,
});
