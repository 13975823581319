import { AppAction, PageFilter } from '../app';
import {
  SampleDevelopmentHlApprovalRequest,
  SampleDevelopmentHlApprovalRequestListFilter,
} from './sampleDevelopmentHlApprovalRequest.types';

export const FETCH_SAMPLE_DEV_HL_APPROVAL_REQUEST_LIST =
  'FETCH_SAMPLE_DEV_HL_APPROVAL_REQUEST_LIST';
export const OPEN_SAMPLE_DEV_HL_APPROVAL_REQUEST_DIALOG =
  'OPEN_SAMPLE_DEV_HL_APPROVAL_REQUEST_DIALOG';

export const CLOSE_SAMPLE_DEV_HL_APPROVAL_REQUEST_DIALOG =
  'CLOSE_SAMPLE_DEV_HL_APPROVAL_REQUEST_DIALOG';

export const UPDATE_SAMPLE_DEV_HL_APPROVAL_REQUEST_RESPONSE =
  'UPDATE_SAMPLE_DEV_HL_APPROVAL_REQUEST_RESPONSE';
export const fetchSampleDevelopmentHlApprovalRequestList = (
  filters: SampleDevelopmentHlApprovalRequestListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SAMPLE_DEV_HL_APPROVAL_REQUEST_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const updateSampleDevelopmentHlApprovalRequestResponse = (
  payload: SampleDevelopmentHlApprovalRequest,
) => ({
  type: UPDATE_SAMPLE_DEV_HL_APPROVAL_REQUEST_RESPONSE,
  payload,
});

export const openSampleDevelopmentHlApprovalRequestDialog = () => ({
  type: OPEN_SAMPLE_DEV_HL_APPROVAL_REQUEST_DIALOG,
});

export const closeSampleDevelopmentHlApprovalRequestDialog = () => ({
  type: CLOSE_SAMPLE_DEV_HL_APPROVAL_REQUEST_DIALOG,
});
