import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createCompany,
  fetchCompany,
  fetchCompanyList,
  updateCompany,
} from '../../services/companyService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_COMPANY,
  FETCH_COMPANY,
  FETCH_COMPANY_LIST,
  UPDATE_COMPANY,
} from './company.actions';
import { CompanyCreateOrUpdatePayload } from './company.types';

const listPageUrl = `/settings/companies`;

function* fetchCompanySaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchCompany, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchCompanyListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchCompanyList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createCompanySaga(action: AppAction) {
  try {
    const { values, history } = action.payload as CompanyCreateOrUpdatePayload;
    const successMessage = `Created ${values.name} company successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createCompany, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(listPageUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateCompanySaga(action: AppAction) {
  try {
    const { values, history } = action.payload as CompanyCreateOrUpdatePayload;
    const successMessage = `Updated ${values.name} company successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateCompany, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(listPageUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchCompany() {
  yield takeLatest(FETCH_COMPANY, fetchCompanySaga);
  yield debounce(250, FETCH_COMPANY_LIST, fetchCompanyListSaga);
  yield takeLatest(CREATE_COMPANY, createCompanySaga);
  yield takeLatest(UPDATE_COMPANY, updateCompanySaga);
}
