export type ResponsibilityStatusType =
  | 'PENDING_FOR_APPROVAL'
  | 'IN_REQUEST'
  | 'REJECTED'
  | 'APPROVED'
  | 'PENDING_FOR_UNLOCK_APPROVED';

const ResponsibilityStatus = Object.freeze({
  PENDING_FOR_APPROVAL: 'PENDING_FOR_APPROVAL' as ResponsibilityStatusType,
  IN_REQUEST: 'IN_REQUEST' as ResponsibilityStatusType,
  REJECTED: 'REJECTED' as ResponsibilityStatusType,
  APPROVED: 'APPROVED' as ResponsibilityStatusType,
  PENDING_FOR_UNLOCK_APPROVED: 'PENDING_FOR_UNLOCK_APPROVED' as ResponsibilityStatusType,
});

export default ResponsibilityStatus;

export const ResponsibilityStatusLabel = Object.freeze({
  [ResponsibilityStatus.PENDING_FOR_APPROVAL]: 'Pending For Approval',
  [ResponsibilityStatus.IN_REQUEST]: 'In Request',
  [ResponsibilityStatus.REJECTED]: 'Rejected',
  [ResponsibilityStatus.APPROVED]: 'Approved',
  [ResponsibilityStatus.PENDING_FOR_UNLOCK_APPROVED]: 'Pending For Unlock Approved',
});
