import React from 'react';
import { IProject } from '../../../models/project.model';
import { fetchProjectList } from '../../../services/projectService';
import AsyncAutoComplete, { AsyncAutoCompleteOption } from '../AsyncAutoComplete';
import useStyles from './projectComboBox.style';

interface ProjectComboBoxWithoutSearchDialogProps {
  handleChange: (result: IProject & AsyncAutoCompleteOption) => void;
  selectedValue:
    | IProject
    | undefined
    | null
    | { label: string | null; value: string | null };
  isDisabled?: boolean;
  error?: boolean;
  helperText?: string;
  label?: string;
  approved?: boolean;
  creatable?: boolean;
}

const ProjectComboBoxWithoutSearchDialog: React.FC<ProjectComboBoxWithoutSearchDialogProps> = (
  props,
) => {
  const {
    handleChange,
    selectedValue,
    isDisabled = false,
    error,
    helperText,
    label = 'Project',
    approved,
    creatable = false,
  } = props;
  const classes = useStyles();
  const status = approved ? 'APPROVED' : '';

  const projectComboBoxWithoutSearchDialogPromiseOptions = async (inputValue: string) => {
    const { data: rows } = await fetchProjectList({
      filters: {
        'Name.StartsWith': inputValue,
        'Status.equalsTo': status,
      },
      paging: {
        pageNumber: 0,
        pageSize: 10,
        sort: { orderBy: 'name', direction: 'asc' },
      },
    });
    return rows;
  };

  return (
    <div className={classes.container}>
      <AsyncAutoComplete
        name="Project"
        className={classes.autoComplete}
        value={selectedValue}
        getOptionValue={(option: IProject & AsyncAutoCompleteOption) => {
          return option.id ? option.id!.toString() : option.value;
        }}
        getOptionLabel={(option: IProject & AsyncAutoCompleteOption) => {
          return option.name ? option.name : option.label;
        }}
        promiseOptions={projectComboBoxWithoutSearchDialogPromiseOptions}
        placeholder={'Type Project Name'}
        handleChange={handleChange}
        isDisabled={isDisabled}
        error={error}
        helperText={helperText}
        label={label}
        creatable={creatable}
      />
    </div>
  );
};

export default ProjectComboBoxWithoutSearchDialog;
