import DownloadResponsibilityAsTemplateButton from '../../components/DownloadResponsibilityAsImportTemplateButton';
import React, { useEffect, useState } from 'react';
import ResponsibilityFilter from './ResponsibilityFilter';
import ResponsibilityImportDialog from './responsibilityImportDialog';
import ResponsibilityStatusIcon from '../responsibility/ResponsibilityStatusIcon';
import roleRoute from '../roleRoute.config';
import useStyles from './Responsibility.style';
import { AppState } from '../../../state/configureStore';
import { downloadResponsibilityFilterAsExcel } from '../../../services/responsibilityService';
import { PageFilter } from '../../../state/app';
import { useFilter, useHasRole } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Publish as UploadIcon,
  HelpOutline as HelpIcon,
  AccountCircle as FaceIcon,
} from '@material-ui/icons';

import {
  clearResponsibility,
  fetchResponsibilityList,
  ResponsibilityListFilter,
  ResponsibilityState,
} from '../../../state/responsibility';
import {
  ChipList,
  PageButtonGroup,
  PageContainer,
  PageTitle,
  TableLoading,
  UserLetterAvatar,
} from '../../components';
import ResponsibilityStatus, {
  ResponsibilityStatusLabel,
} from '../../../constants/responsibilityStatus';
import ChipListUser from '../../components/ChipListUser';
import { defaultListLoadingHeight } from '../../../theme';
import Loading from '../../components/AwLoading';
import { TablePageSize } from '../../../constants';
import { constant } from 'lodash';

const initialResponsibilityFilter: ResponsibilityListFilter = {
  'Status.equalsTo': '',
  'IsHighPriority.equalsTo': '',
};

const ResponsibilityList: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isDisabled = !useHasRole(roleRoute.createResponsibility);

  const [filters, setFilters] = useFilter<ResponsibilityListFilter>(
    initialResponsibilityFilter,
  );
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 10,
  });
  const {
    items: responsibilities,
    totalItems,
    loading,
  } = useSelector<AppState, ResponsibilityState>((state) => state.responsibility);

  useEffect(() => {
    dispatch(fetchResponsibilityList(filters, paging));

    return function cleanUp() {
      dispatch(clearResponsibility());
    };
  }, [dispatch, paging, filters]);

  const editResponsibility = (id: number): void => {
    history.push(`/responsibilities/form/${id}`);
  };

  const handleChangeFilter = (newFilter: ResponsibilityListFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const addNewResponsibility = (): void => {
    history.push('/responsibilities/form');
  };

  const exportExcel = () => {
    downloadResponsibilityFilterAsExcel(filters, {
      pageSize: totalItems,
    }).catch((err) => console.error(err));
  };

  const [openImportDialog, setOpenImportDialog] = useState(false);

  return (
    <PageContainer>
      <PageTitle>Responsibility</PageTitle>
      <ResponsibilityFilter
        filters={filters}
        onChangeFilter={handleChangeFilter}
        initialFilter={initialResponsibilityFilter}
      />
      <PageButtonGroup>
        {/* <Button variant="contained" onClick={exportExcel}>
          Export Excel
        </Button> */}
        <DownloadResponsibilityAsTemplateButton
          filters={filters}
          paging={paging}
          disabled={isDisabled}
        />
        <Button
          startIcon={<UploadIcon />}
          color="primary"
          variant="contained"
          onClick={() => {
            setOpenImportDialog(true);
          }}
          disabled={isDisabled}
        >
          Import
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={addNewResponsibility}
          startIcon={<AddIcon />}
          disabled={isDisabled}
        >
          New Responsibility
        </Button>
      </PageButtonGroup>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Program</TableCell>
            <TableCell>Priority</TableCell>
            {/* <TableCell>Brand</TableCell> */}
            <TableCell>Customer</TableCell>
            <TableCell>Sales</TableCell>
            <TableCell>SSO</TableCell>
            <TableCell>CS</TableCell>
            <TableCell>NPD</TableCell>
            {/* <TableCell>Created By</TableCell> */}
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={14}>
            {responsibilities.map((responsibility, index) => {
              const customerNames =
                responsibility?.responsibilityCustomers
                  ?.filter((rc) => rc.active === true)
                  ?.map((rc) => {
                    return rc.customer?.displayName || '';
                  }) || [];
              const brandNames =
                responsibility?.responsibilityBrands
                  ?.filter((rb) => rb.active === true)
                  ?.map((rb) => {
                    return rb.brand?.name || '';
                  }) || [];
              const salesNames =
                responsibility?.responsibilitySalesTeamMembers
                  ?.filter((rs) => rs.active === true)
                  ?.map((rs) => {
                    const status =
                      rs.status === 'P' ? 'Sales' : `Sales${rs.status?.replace('S', '')}`;

                    return {
                      name: rs.salesTeamMember?.user?.activated
                        ? `${rs.salesTeamMember?.user?.fullName} (${status})`
                        : `${rs.salesTeamMember?.user?.fullName} (${status}) (Inactive)`,
                      activated: rs.salesTeamMember?.user?.activated,
                      userLeaveTotalDay: rs.salesTeamMember?.user?.userLeaveTotalDay,
                    };
                  }) || [];
              const csNames =
                responsibility?.responsibilityWorkingTeamMembers
                  ?.filter(
                    (rc) =>
                      rc.active === true &&
                      rc.smWorkingTeamMember?.smWorkingTeam?.smWorkingGroup?.role
                        ?.name === 'CS',
                  )
                  ?.map((rs) => {
                    const status = rs.status === 'P' ? 'CS' : `C${rs.status}`;
                    return {
                      name: rs.smWorkingTeamMember?.user?.activated
                        ? `${rs.smWorkingTeamMember?.user?.fullName} (${status})`
                        : `${rs.smWorkingTeamMember?.user?.fullName} (${status}) (Inactive)`,
                      activated: rs.smWorkingTeamMember?.user?.activated,
                      userLeaveTotalDay: rs.smWorkingTeamMember?.user?.userLeaveTotalDay,
                    };
                  }) || [];
              const ssoNames =
                responsibility?.responsibilityWorkingTeamMembers
                  ?.filter(
                    (rc) =>
                      rc.active === true &&
                      rc.smWorkingTeamMember?.smWorkingTeam?.smWorkingGroup?.role
                        ?.name === 'SSO',
                  )
                  ?.map((rs) => {
                    const status =
                      rs.status === 'P' ? 'SSO' : `SSO${rs.status?.replace('S', '')}`;
                    return {
                      name: rs.smWorkingTeamMember?.user?.activated
                        ? `${rs.smWorkingTeamMember?.user?.fullName} (${status})`
                        : `${rs.smWorkingTeamMember?.user?.fullName} (${status}) (Inactive)`,
                      activated: rs.smWorkingTeamMember?.user?.activated,
                      userLeaveTotalDay: rs.smWorkingTeamMember?.user?.userLeaveTotalDay,
                    };
                    // return (
                    //   `${rs.smWorkingTeamMember?.user?.fullNameWithUserName} (${status})` ||
                    //   ''
                    // );
                  }) || [];

              const npdNames =
                responsibility?.responsibilityWorkingTeamMembers
                  ?.filter(
                    (rc) =>
                      rc.active === true &&
                      rc.smWorkingTeamMember?.smWorkingTeam?.smWorkingGroup?.role
                        ?.name === 'NPD',
                  )
                  .sort(
                    (a, b) =>
                      a.smWorkingTeamMember?.smWorkingTeam?.description?.localeCompare(
                        b.smWorkingTeamMember?.smWorkingTeam?.description || '',
                      ) || 0,
                  )
                  ?.map((rs) => {
                    const npdType = rs.smWorkingTeamMember?.smWorkingTeam?.description;
                    return {
                      name: rs.smWorkingTeamMember?.user?.activated
                        ? `${rs.smWorkingTeamMember?.user?.fullName} (${npdType})`
                        : `${rs.smWorkingTeamMember?.user?.fullName} (${npdType})(Inactive)`,
                      activated: rs.smWorkingTeamMember?.user?.activated,
                      userLeaveTotalDay: rs.smWorkingTeamMember?.user?.userLeaveTotalDay,
                      npdType,
                    };
                  }) || [];

              return (
                <TableRow key={responsibility.id}>
                  <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                  <TableCell>{responsibility?.id}</TableCell>
                  <TableCell>{responsibility?.company?.name}</TableCell>
                  <TableCell>{responsibility?.program?.name}</TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        responsibility?.program?.isHighPriority
                          ? 'High Priority'
                          : 'Low Priority'
                      }
                    >
                      <Checkbox
                        color="secondary"
                        checked={responsibility?.program?.isHighPriority}
                      ></Checkbox>
                    </Tooltip>
                  </TableCell>
                  {/* <TableCell>
                    <ChipList list={brandNames} />
                  </TableCell> */}
                  <TableCell>
                    <ChipList list={customerNames} />
                  </TableCell>
                  <TableCell>
                    <ChipListUser list={salesNames} noAllLabel />
                  </TableCell>
                  <TableCell>
                    <ChipListUser list={ssoNames} noAllLabel />
                  </TableCell>
                  <TableCell>
                    <ChipListUser list={csNames} noAllLabel />
                  </TableCell>
                  <TableCell>
                    <ChipListUser list={npdNames} noAllLabel />
                    {/* {npdNames.map((npd) => {
                      return (
                        <>
                          <Tooltip
                            title={npd.name}
                            TransitionComponent={Zoom}
                            key={npd.name}
                          >
                            <span>
                              <Chip
                                icon={<FaceIcon />}
                                key={npd.name}
                                label={npd.npdType}
                                variant="outlined"
                                color={npd.activated ? 'default' : 'secondary'}
                              />
                            </span>
                          </Tooltip>
                        </>
                      );
                    })} */}
                  </TableCell>

                  {/* <TableCell>{responsibility?.createdBy}</TableCell> */}
                  <TableCell>
                    <div>
                      {
                        ResponsibilityStatusIcon[
                          ResponsibilityStatusLabel[responsibility.status!]
                        ]
                      }
                    </div>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Edit">
                      <span>
                        <IconButton
                          size="small"
                          onClick={() => {
                            editResponsibility(responsibility.id!);
                          }}
                          disabled={isDisabled}
                        >
                          <EditIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[...TablePageSize]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
      <ResponsibilityImportDialog
        open={openImportDialog}
        onClose={() => setOpenImportDialog(false)}
      />
    </PageContainer>
  );
};

export default ResponsibilityList;
