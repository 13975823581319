import { ISquad } from '../../models/squad.model';
import { AppAction, PageFilter } from '../app';
import {
  SquadSalesMember,
  SquadSalesMemberCreateOrUpdatePayload,
  SquadSalesMemberListFilter,
} from './squadSalesMember.type';

export const FETCH_SQUAD_SALES_MEMBER = 'FETCH_SQUAD_SALES_MEMBER';
export const FETCH_SQUAD_SALES_MEMBER_LIST = 'FETCH_SQUAD_SALES_MEMBER_LIST';
export const CREATE_SQUAD_SALES_MEMBER = 'CREATE_SQUAD_SALES_MEMBER';
export const CREATE_SQUAD_SALES_MEMBERS = 'CREATE_SQUAD_SALES_MEMBERS';
export const UPDATE_SQUAD_SALES_MEMBER = 'UPDATE_SQUAD_SALES_MEMBER';
export const CLEAR_SQUAD_SALES_MEMBER = 'CLEAR_SQUAD_SALES_MEMBER';
export const DELETE_SQUAD_SALES_MEMBER = 'DELETE_SQUAD_SALES_MEMBER';

export const fetchSquadSalesMemberList = (
  filters: SquadSalesMemberListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SQUAD_SALES_MEMBER_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchSquadSalesMember = (id: SquadSalesMember['id']): AppAction => ({
  type: FETCH_SQUAD_SALES_MEMBER,
  payload: id,
});

export const fetchSquadSalesMemberListBySmSquadId = (smSquadId: number) => {
  return fetchSquadSalesMemberList(
    { 'SmSquadId.EqualsTo' : smSquadId },
    { pageNumber: 0, pageSize: 9999 },
  );
};

export const createSquadSalesMember = (
  payload: SquadSalesMemberCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_SQUAD_SALES_MEMBER,
  payload,
});

export const createSquadSalesMembers = (payload: SquadSalesMember[]): AppAction => ({
  type: CREATE_SQUAD_SALES_MEMBERS,
  payload,
});

export const updateSquadSalesMember = (
  payload: SquadSalesMemberCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_SQUAD_SALES_MEMBER,
  payload,
});

export const clearSquadSalesMember = () => ({
  type: CLEAR_SQUAD_SALES_MEMBER,
});

export const deleteSquadSalesMember = (
  smSquadSalesMemberId: SquadSalesMember['id'],
  smSquadId: ISquad['id'],
): AppAction => ({
  type: DELETE_SQUAD_SALES_MEMBER,
  payload: {
    smSquadSalesMemberId,
    smSquadId,
  },
});
