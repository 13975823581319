import { Grid, MenuItem } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../state/configureStore';
import { SubtaskItemListFilter } from '../../../state/subtaskItem';
import { fetchSubtaskTypeList, SubtaskTypeState } from '../../../state/subtaskType';
import { FilterContainer, SearchDropdownField, SearchField } from '../../components';
import { IFilterContainerConfigProps } from '../../components/FilterContainer';

const TaskSubtaskItemFilter: React.FC<
  IFilterContainerConfigProps<SubtaskItemListFilter>
> = (props) => {
  const dispatch = useDispatch();
  const { items: subtaskTypes } = useSelector<AppState, SubtaskTypeState>(
    (state) => state.subtaskType,
  );

  useEffect(() => {
    dispatch(
      fetchSubtaskTypeList(
        { 'IsSpecialType.EqualsTo': 'false' },
        {
          pageNumber: 0,
          pageSize: 15,
        },
      ),
    );
  }, [dispatch]);

  return (
    <FilterContainer
      {...props}
      render={({ localFilter, handleChangeFilter, handleChangeDateFilter }) => {
        return (
          <Grid container spacing={1} alignItems={'center'}>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Task Number"
                filterKey={'SmSubtask.SMTask.TaskNumber.startsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>

            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Assignment Number"
                filterKey={
                  'SmTaskItem.OpportunityItem.Opportunity.AssignmentNumber.StartsWith'
                }
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Program"
                filterKey={
                  'SmTaskItem.OpportunityItem.Opportunity.Program.Name.StartsWith'
                }
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Brand"
                filterKey={'SmTaskItem.OpportunityItem.Opportunity.Brand.Name.StartsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Block"
                filterKey={'SmTaskItem.OpportunityItem.Block.BlockNo.StartsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Item"
                filterKey={'SmTaskItem.OpportunityItem.Block.Item.StartsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchDropdownField
                id={'subtask-type-filter'}
                label={'Subtask Type'}
                onChange={handleChangeFilter}
                filters={localFilter}
                filterKey={'SmSubtask.SmSubTaskType.Name.EqualsTo'}
              >
                <MenuItem value="">All</MenuItem>
                {subtaskTypes.map((subtaskType) => {
                  return (
                    <MenuItem key={subtaskType.id} value={subtaskType.name!}>
                      {subtaskType.name!}
                    </MenuItem>
                  );
                })}
              </SearchDropdownField>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default TaskSubtaskItemFilter;
