import { Card, CardContent, TextField, Typography } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Brand,
  BrandState,
  clearBrand,
  createBrand,
  fetchBrand,
  updateBrand,
} from '../../../state/brand';
import { AppState } from '../../../state/configureStore';
import { FixedPositionButtons } from '../../components';
import useStyles from './brandForm.style';

const BrandSchema = Yup.object().shape({
  name: Yup.string()
    .strict(true)
    .trim()
    .required('Name is required'),
  externalId: Yup.string()
    .strict(true)
    .trim()
    .required('ExternalId is required'),
});

interface BrandFormRouteParamsProps {
  id: string;
}

interface BrandFormProps extends RouteComponentProps<BrandFormRouteParamsProps> {}

const BrandForm: React.FC<BrandFormProps> = props => {
  const { match, history } = props;
  const {
    params: { id: paramsId },
  } = match;
  const classes = useStyles();
  const {
    item: { name = '', id = 0, externalId = '' },
  } = useSelector<AppState, BrandState>(state => state.brand);
  const dispatch = useDispatch();

  const redirectToBrandList = () => {
    history.push('/settings/brands');
  };

  const handleSubmit = (values: Brand, actions: FormikHelpers<any>) => {
    if (paramsId) {
      dispatch(updateBrand({ values, history }));
    } else {
      dispatch(createBrand({ values, history }));
    }
    actions.setSubmitting(false);
  };

  useEffect(() => {
    if (paramsId) {
      dispatch(fetchBrand(+paramsId));
    } else {
      dispatch(clearBrand());
    }

    return function cleanUp() {
      dispatch(clearBrand());
    };
  }, [paramsId, dispatch]);

  return (
    <Card className={classes.root}>
      <Formik
        enableReinitialize={true}
        initialValues={{ name, id, externalId }}
        validationSchema={BrandSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, errors, touched, submitForm }) => {
          return (
            <Form>
              <Typography variant="h4" className={classes.title}>
                {paramsId === undefined ? `New Brand` : `Update Brand`}
              </Typography>
              <CardContent>
                <TextField
                  name="name"
                  type="input"
                  label="Name"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={values.name}
                  onChange={handleChange}
                  error={!!errors.name && !!touched.name}
                  helperText={errors.name}
                />
                <TextField
                  name="externalId"
                  type="input"
                  label="ExternalId"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={values.externalId}
                  onChange={handleChange}
                  error={!!errors.externalId && !!touched.externalId}
                  helperText={errors.externalId}
                />
              </CardContent>
              <FixedPositionButtons
                actions={[
                  {
                    name: paramsId === undefined ? `Create` : `Update`,
                    onClick: () => {
                      submitForm();
                    },
                    color: 'primary' as 'primary',
                    disabled: false,
                    icon: 'save',
                  },
                  {
                    name: 'CANCEL',
                    onClick: redirectToBrandList,
                    color: 'default' as 'default',
                    disabled: false,
                  },
                ]}
                open
              />
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default BrandForm;
