import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableLoading } from '..';
import { AppState } from '../../../state/configureStore';
import { PageFilter } from '../../../state/app';
import {
  clearCustomerGroupMember,
  CustomerGroupMemberState,
  fetchCustomerGroupMemberList,
} from '../../../state/customerGroupMember';

interface CustomerGroupMemberDialogProps {
  handleClose: () => void;
  open: boolean;
  customerGroupId: number;
}

const CustomerGroupMemberDialog: React.FC<CustomerGroupMemberDialogProps> = (props) => {
  const { open, handleClose, customerGroupId } = props;
  const dispatch = useDispatch();
  const {
    items: customerGroupMemberList,
    loading,
    totalItems,
  } = useSelector<AppState, CustomerGroupMemberState>(
    (state) => state.customerGroupMember,
  );

  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    if (customerGroupId) {
      dispatch(
        fetchCustomerGroupMemberList(
          { 'CustomerGroupId.equalsTo': customerGroupId },
          { ...paging },
        ),
      );
    }
    return function cleanUp() {
      dispatch(clearCustomerGroupMember());
    };
  }, [dispatch, paging, open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle>CustomerGroupMember</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>CustomerGroup</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={2}>
              {customerGroupMemberList.map((customerGroupMember, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{customerGroupMember.customerGroup!.name}</TableCell>
                    <TableCell>{customerGroupMember.customer!.displayName}</TableCell>
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[15, 25, 35]}
          component="div"
          count={totalItems}
          rowsPerPage={paging.pageSize}
          page={paging.pageNumber}
          onPageChange={handleChangePageNumber}
          onRowsPerPageChange={handleChangePageSize}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerGroupMemberDialog;
