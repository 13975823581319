import { IResponsibility } from '../../models/responsibility.model';
import { AppAction, PageFilter } from '../app';
import {
  ResponsibilityCreateOrUpdatePayload,
  ResponsibilityListFilter,
} from './responsibility.types';
export const FETCH_RESPONSIBILITY_LIST = 'FETCH_RESPONSIBILITY_LIST';
export const FETCH_RESPONSIBILITY = 'FETCH_RESPONSIBILITY';
export const CREATE_RESPONSIBILITY = 'CREATE_RESPONSIBILITY';
export const UPDATE_RESPONSIBILITY = 'UPDATE_RESPONSIBILITY';
export const CLEAR_RESPONSIBILITY = 'CLEAR_RESPONSIBILITY';
export const SUBMIT_FOR_APPROVAL_RESPONSIBILITY = 'SUBMIT_FOR_APPROVAL_RESPONSIBILITY';
export const SUBMIT_FOR_UNLOCK_APPROVED_RESPONSIBILITY =
  'SUBMIT_FOR_UNLOCK_APPROVED_RESPONSIBILITY';

export const fetchResponsibility = (id: IResponsibility['id']): AppAction => ({
  type: FETCH_RESPONSIBILITY,
  payload: id,
});

export const fetchResponsibilityList = (
  filters: ResponsibilityListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_RESPONSIBILITY_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createResponsibility = (
  payload: ResponsibilityCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_RESPONSIBILITY,
  payload,
});

export const updateResponsibility = (
  payload: ResponsibilityCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_RESPONSIBILITY,
  payload,
});

export const clearResponsibility = () => ({
  type: CLEAR_RESPONSIBILITY,
});

export const submitForApprovalResponsibility = (
  id: IResponsibility['id'],
): AppAction => ({
  type: SUBMIT_FOR_APPROVAL_RESPONSIBILITY,
  payload: id,
});

export const submitForUnlockApprovedResponsibility = (
  id: IResponsibility['id'],
  description: string,
): AppAction => ({
  type: SUBMIT_FOR_UNLOCK_APPROVED_RESPONSIBILITY,
  payload: { id, description },
});
