import { ISubtask } from '../models/subtask.model';
import { PageFilter } from '../state/app';
import { SubtaskListFilter } from '../state/subtask';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/subtasks`;

export const fetchSubtaskList = async ({
  filters,
  paging,
}: {
  filters: SubtaskListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchSubtaskReferenceList = async ({
  filters,
  paging,
}: {
  filters: SubtaskListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);

  const requestUrl = `${baseUrl}/reference`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchSubtaskStatus = async () => {
  const requestUrl = `${baseUrl}/status`;
  return await axios.get(requestUrl);
};

export const fetchSubtask = async (id: ISubtask['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createSubtask = async (subtask: ISubtask) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, subtask);
};

export const updateSubtask = async (subtask: ISubtask) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, subtask);
};

export const deleteSubtask = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};
