import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import history from '../../history';
import {
  createProject,
  fetchProject,
  fetchProjectList,
  updateProject,
  submitForApprovalProject,
  submitForUnlockApprovedProject,
} from '../../services/projectService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_PROJECT,
  FETCH_PROJECT,
  FETCH_PROJECT_LIST,
  UPDATE_PROJECT,
  FETCH_PROJECT_DETAIL,
  SUBMIT_FOR_APPROVAL_PROJECT,
  SUBMIT_FOR_UNLOCK_APPROVED_PROJECT,
} from './project.actions';
import { ProjectCreatePayload, ProjectUpdatePayload } from './project.types';

const formPageUrl = `/projects/form`;

function* fetchProjectSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchProject, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchProjectListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchProjectList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createProjectSaga(action: AppAction) {
  try {
    const { values } = action.payload as ProjectCreatePayload;
    const successMessage = `Created project successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createProject, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history!.push(`${formPageUrl}/${data.id}`);
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateProjectSaga(action: AppAction) {
  try {
    const { values } = action.payload as ProjectUpdatePayload;
    const successMessage = `Updated project successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateProject, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* submitForApprovalProjectSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const successMessage = `Submit for Approval Project successfully.`;
    const { data } = yield call(submitForApprovalProject, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* submitForUnlockApprovedProjectSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const successMessage = `Submit for Unlock Approved Project successfully.`;
    const { data } = yield call(
      submitForUnlockApprovedProject,
      action.payload.id,
      action.payload.description,
    );
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchProject() {
  yield takeLatest(FETCH_PROJECT, fetchProjectSaga);
  yield takeLatest(FETCH_PROJECT_DETAIL, fetchProjectSaga);
  yield debounce(250, FETCH_PROJECT_LIST, fetchProjectListSaga);
  yield takeLatest(CREATE_PROJECT, createProjectSaga);
  yield takeLatest(UPDATE_PROJECT, updateProjectSaga);
  yield takeLatest(SUBMIT_FOR_APPROVAL_PROJECT, submitForApprovalProjectSaga);
  yield takeLatest(
    SUBMIT_FOR_UNLOCK_APPROVED_PROJECT,
    submitForUnlockApprovedProjectSaga,
  );
}
