import { ISubtaskAssignee } from '../models/subtaskAssignee.model';
import axios from '../utils/axios';

const baseUrl = `/api/smSubtaskAssignees`;

export const fetchSubtaskAssignee = async (id: ISubtaskAssignee['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createSubtaskAssignee = async (subtaskAssignee: ISubtaskAssignee) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, subtaskAssignee);
};

export const updateSubtaskAssignee = async (subtaskAssignee: ISubtaskAssignee) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, subtaskAssignee);
};

export const deleteSubtaskAssignee = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};
