import { Box, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { CheckBoxFastField } from '../../../components';

interface SampleDevelopmentPlLawProps {}

const SampleDevelopmentPlLaw: React.FC<SampleDevelopmentPlLawProps> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Law</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>Law:</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="withoutLaw" label="Without Law" />
            </Grid>
            <Grid item xs={12} md={8} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="haveLaw" label="Have Law" />
            </Grid>
            <Grid item xs={12} md={8} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="lawFormaldehyde" label="Formaldehyde" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="lawPentachlorophenol" label="Pentachlorophenol" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="lawNickel" label="Nickel" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="lawAzoDyes" label="AzoDyes" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="lawChromium" label="Chromium" />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="lawTrademarks" label="Trademarks" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="lawCodeOfConduct" label="CodeOfConduct" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="lawFSC" label="FSC" />
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentPlLaw;
