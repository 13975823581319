import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import {
  createProjectProgram,
  deleteProjectProgram,
  fetchProjectProgram,
  fetchProjectProgramList,
  updateProjectProgram,
} from '../../services/projectProgramService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_PROJECT_PROGRAM,
  CREATE_PROJECT_PROGRAMS,
  DELETE_PROJECT_PROGRAM,
  FETCH_PROJECT_PROGRAM,
  FETCH_PROJECT_PROGRAM_LIST,
  fetchProjectProgramListBySmProjectId,
  UPDATE_PROJECT_PROGRAM,
} from './projectProgram.actions';
import { ProjectProgramCreateOrUpdatePayload } from './projectProgram.types';
import { AppState } from '../configureStore';
import { IProject } from '../../models/project.model';

function* fetchProjectProgramSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchProjectProgram, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchProjectProgramListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchProjectProgramList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createProjectProgramSaga(action: AppAction) {
  try {
    const { values } = action.payload as ProjectProgramCreateOrUpdatePayload;
    const successMessage = `Created project program successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createProjectProgram, values);
    yield put(fetchProjectProgramListBySmProjectId(data.smProjectId));
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createProjectProgramsSaga(action: AppAction) {
  try {
    const { payload, callback } = action;

    yield put({ type: REQUEST(action.type) });

    for (const opCustGrp of payload) {
      yield call(createProjectProgram, opCustGrp);
    }

    const successMessage = `Created project program successfully.`;
    yield put(fetchProjectProgramListBySmProjectId(payload[0].smProjectId));

    yield put({ type: SUCCESS(action.type), payload });

    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));

    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateProjectProgramSaga(action: AppAction) {
  try {
    const { values } = action.payload as ProjectProgramCreateOrUpdatePayload;
    const successMessage = `Updated project program successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateProjectProgram, values);
    yield put(fetchProjectProgramListBySmProjectId(data.smProjectId));
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteProjectProgramSaga(action: AppAction) {
  const { payload } = action;
  const { id } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    yield call(deleteProjectProgram, id);
    const project = yield select(getProjectFromState);
    if (project.id) {
      yield put(fetchProjectProgramListBySmProjectId(project.id));
    }
    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Deleted successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function getProjectFromState(state: AppState): IProject {
  const { item } = state.project;
  return item;
}

export default function* watchProjectProgram() {
  yield takeLatest(FETCH_PROJECT_PROGRAM, fetchProjectProgramSaga);
  yield debounce(250, FETCH_PROJECT_PROGRAM_LIST, fetchProjectProgramListSaga);
  yield takeLatest(CREATE_PROJECT_PROGRAM, createProjectProgramSaga);
  yield takeLatest(UPDATE_PROJECT_PROGRAM, updateProjectProgramSaga);
  yield takeLatest(DELETE_PROJECT_PROGRAM, deleteProjectProgramSaga);
  yield takeLatest(CREATE_PROJECT_PROGRAMS, createProjectProgramsSaga);
}
