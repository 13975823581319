import { PageFilter } from '../state/app';
import { ProjectCustomerGroupListFilter } from '../state/projectCustomerGroup';
import axios from '../utils/axios';
import { IProjectCustomerGroup } from '../models/projectCustomerGroup.model';

const baseUrl = `/api/smProjectCustomerGroups`;

export const createProjectCustomerGroup = async (
  projectCustomerGroup: IProjectCustomerGroup,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, projectCustomerGroup);
};

export const fetchProjectCustomerGroup = async (id: IProjectCustomerGroup['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const fetchProjectCustomerGroupList = async ({
  filters,
  paging,
}: {
  filters: ProjectCustomerGroupListFilter;
  paging: PageFilter;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const deleteProjectCustomerGroup = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};

export const updateProjectCustomerGroup = async (
  projectCustomerGroup: IProjectCustomerGroup,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, projectCustomerGroup);
};
