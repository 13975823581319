import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { useEffect } from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import { useDispatch, useSelector } from 'react-redux';
import {
  SubtaskItemChangeStatusLogState,
  fetchSubtaskItemChangeStatusLogList,
} from '../../../../state/subtaskItemChangeStatusLog';
import { AppState } from '../../../../state/configureStore';
import { SubtaskItemStatusLabel } from '../../../../constants';
import { DateTimeDisplay } from '../../../components';
import { Close as CloseIcon } from '@material-ui/icons';

interface SubtaskItemTimelineDialogProps {
  open: boolean;
  subtaskItemId: number;
  handleClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const SubtaskItemTimelineDialog: React.FC<SubtaskItemTimelineDialogProps> = (props) => {
  const dispatch = useDispatch();
  const { open, handleClose, subtaskItemId } = props;
  const classes = useStyles();

  const { items: subtaskItemChangeStatusLogs, loading } = useSelector<
    AppState,
    SubtaskItemChangeStatusLogState
  >((state) => state.subtaskItemChangeStatusLog);

  useEffect(() => {
    if (open && subtaskItemId > 0) {
      dispatch(
        fetchSubtaskItemChangeStatusLogList(
          { 'SmSubtaskItemId.EqualsTo': subtaskItemId },
          {
            pageNumber: 0,
            pageSize: 999,
          },
        ),
      );
    }
  }, [dispatch, open, subtaskItemId]);

  return (
    <Dialog open={open} TransitionComponent={Transition} maxWidth="md" fullWidth={true}>
      <DialogTitle>Timeline</DialogTitle>
      <DialogContent>
        <Timeline align="alternate">
          {subtaskItemChangeStatusLogs.map((item, index) => {
            return (
              <TimelineItem key={index}>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    <DateTimeDisplay value={item.startDate?.toString()} />
                    {'  -  '}
                    <DateTimeDisplay value={item.endDate?.toString()} />
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot></TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">
                      {`${SubtaskItemStatusLabel[item.statusFrom!]} - ${
                        SubtaskItemStatusLabel[item.statusTo!]
                      }`}
                    </Typography>
                    <Typography>{`By. ${item.userResponded?.fullName}`}</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </DialogContent>
      <DialogActions style={{ marginRight: '5px', marginBottom: '10px' }}>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          startIcon={<CloseIcon />}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubtaskItemTimelineDialog;
