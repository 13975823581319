import { Credentials } from '../state/auth';
import axios from '../utils/axios';

export const login = async (credentials: Credentials) => {
  return await axios.post(`/api/authenticate`, credentials);
};

export const logout = async () => {
  return await axios.post(`/api/logout`);
};
