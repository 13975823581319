import {
  ITaskAttachment,
  ITaskAttachmentUpload,
  ITaskAttachmentDownload,
} from '../models/taskAttachment.model';
import { PageFilter } from '../state/app';
import { TaskAttachmentListFilter } from '../state/taskAttachment';
import axios from '../utils/axios';

export const baseUrl = `/api/taskAttachments`;

export const fetchTaskAttachmentList = async ({
  filters,
  paging,
}: {
  filters: TaskAttachmentListFilter;
  paging: PageFilter;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchTaskAttachment = async (id: ITaskAttachment['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createTaskAttachment = async (taskAttachment: ITaskAttachment) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, taskAttachment);
};

export const updateTaskAttachment = async (taskAttachment: ITaskAttachment) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, taskAttachment);
};

export const uploadTaskAttachment = async (
  taskAttachmentUpload: ITaskAttachmentUpload,
) => {
  const requestUrl = `${baseUrl}/uploadAndCreate`;
  const formData = new FormData();
  formData.append('file', taskAttachmentUpload.file!);
  formData.append('smTaskId', taskAttachmentUpload.smTaskId!.toString());

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    //TODO: upload progress bar
    // onUploadProgress: function(progressEvent: any) {
    //   const percentCompleted = Math.round(
    //     (progressEvent.loaded * 100) / progressEvent.total,
    //   );
    // },
  };

  return await axios.post(requestUrl, formData, config);
};

export const downloadTaskAttachment = async (
  taskAttachmentDownload: ITaskAttachmentDownload,
) => {};
