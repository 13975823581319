import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SampleDevelopmentStatusLabel } from '../../../../constants/sampleDevelopmentStatus';
import { ISampleDevelopmentTl } from '../../../../models/sampleDevelopmentTl.model';
import { PageFilter } from '../../../../state/app';
import { AppState } from '../../../../state/configureStore';
import {
  closeSampleDevelopmentTlApprovalRequestDialog,
  openSampleDevelopmentTlApprovalRequestDialog,
  SampleDevelopmentTlApprovalRequest,
  SampleDevelopmentTlApprovalRequestState,
} from '../../../../state/sampleDevelopmentTlApprovalRequest';
import { DateTimeDisplay, TableLoading } from '../../../components';
import SampleDevelopmentTlApprovalRequestDialog from '../../sampleDevelopmentTlApprovalRequest/SampleDevelopmentTlApprovalRequestDialog';
import { fetchPendingApprovalSampleDevTlList } from './pendingApprovalSampleDevTlRequestList.actions';
import { PendingApprovalSampleDevTlRequestListState } from './pendingApprovalSampleDevTlRequestList.reducer';
import { useLocation } from 'react-router-dom';
import history from '../../../../history';

const PendingApprovalSampleDevTlRequestList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const sampleDevNumber = queryString.get('sampleDevNumber');
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const {
    loading,
    totalItems,
    items: pendingApprovalSampleDevTlRequestList,
  } = useSelector<AppState, PendingApprovalSampleDevTlRequestListState>(
    (state) => state.pendingApprovalSampleDevTlRequestList,
  );

  const { openDialog } = useSelector<AppState, SampleDevelopmentTlApprovalRequestState>(
    (state) => state.sampleDevelopmentTlApprovalRequest,
  );

  const [sampleDevTlAppRequestDlg, setsampleDevTlAppRequestDlg] = useState({
    sampleDevelopmentTl: {} as ISampleDevelopmentTl,
    sampleDevelopmentTlApprovalRequest: {} as SampleDevelopmentTlApprovalRequest,
  });

  useEffect(() => {
    if (!openDialog) {
      dispatch(fetchPendingApprovalSampleDevTlList(paging));
    }
  }, [dispatch, paging, openDialog]);

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const handleCloseSampleDevTlAppRequestDlg = () => {
    dispatch(closeSampleDevelopmentTlApprovalRequestDialog());
    history.replace(`${history.location.pathname}`);
  };

  const editSampleDevTl = useCallback(
    (sampleDevTlAppRequest: SampleDevelopmentTlApprovalRequest): void => {
      setsampleDevTlAppRequestDlg({
        sampleDevelopmentTl: sampleDevTlAppRequest.sampleDevelopmentTl!,
        sampleDevelopmentTlApprovalRequest: sampleDevTlAppRequest,
      });
      dispatch(openSampleDevelopmentTlApprovalRequestDialog());
    },
    [dispatch],
  );

  useEffect(() => {
    const sampleDevTlPrefix = 'SPTL';
    if (sampleDevNumber && sampleDevNumber.startsWith(sampleDevTlPrefix)) {
      const selectedPendingApprovalSampleDevTlRequest =
        pendingApprovalSampleDevTlRequestList.filter(
          (pendingApprovalSampleDevTlRequest) =>
            pendingApprovalSampleDevTlRequest.sampleDevelopmentTl!.sampleDevNumber ===
            sampleDevNumber,
        );
      if (selectedPendingApprovalSampleDevTlRequest.length > 0) {
        editSampleDevTl(selectedPendingApprovalSampleDevTlRequest[0]);
      }
    }
  }, [sampleDevNumber, editSampleDevTl, pendingApprovalSampleDevTlRequestList]);

  return (
    <>
      <Typography variant="h6">Pending Approval Sample Development TL</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Sample Dev. Number</TableCell>
            <TableCell>Program</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Block</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={10}>
            {pendingApprovalSampleDevTlRequestList.map(
              (pendingApprovalSampleDevTl, index) => {
                return (
                  <TableRow key={pendingApprovalSampleDevTl.id}>
                    <TableCell>
                      {index + paging.pageSize * paging.pageNumber + 1}
                    </TableCell>
                    <TableCell>
                      {pendingApprovalSampleDevTl!.sampleDevelopmentTl!.sampleDevNumber}
                    </TableCell>
                    <TableCell>
                      {
                        pendingApprovalSampleDevTl!.sampleDevelopmentTl!.block!.program!
                          .name
                      }
                    </TableCell>
                    <TableCell>
                      {
                        pendingApprovalSampleDevTl!.sampleDevelopmentTl!.block!.brand!
                          .name
                      }
                    </TableCell>
                    <TableCell>
                      {pendingApprovalSampleDevTl!.sampleDevelopmentTl!.block!.item}
                    </TableCell>
                    <TableCell>
                      {pendingApprovalSampleDevTl!.sampleDevelopmentTl!.block!.blockNo}
                    </TableCell>
                    <TableCell>
                      {
                        SampleDevelopmentStatusLabel[
                          pendingApprovalSampleDevTl!.sampleDevelopmentTl!.status!
                        ]
                      }
                    </TableCell>
                    <TableCell>
                      <DateTimeDisplay
                        value={pendingApprovalSampleDevTl!.sampleDevelopmentTl!.createdAt}
                      />
                    </TableCell>
                    <TableCell>
                      {pendingApprovalSampleDevTl!.sampleDevelopmentTl!.createdBy}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={() => editSampleDevTl(pendingApprovalSampleDevTl)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              },
            )}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
      <SampleDevelopmentTlApprovalRequestDialog
        handleClose={handleCloseSampleDevTlAppRequestDlg}
        sampleDevelopmentTl={sampleDevTlAppRequestDlg.sampleDevelopmentTl}
        sampleDevelopmentTlApprovalRequest={
          sampleDevTlAppRequestDlg.sampleDevelopmentTlApprovalRequest
        }
      />
    </>
  );
};

export default PendingApprovalSampleDevTlRequestList;
