import React, { useEffect, useState } from 'react';
import {
  BaseSearchItemAndAddMultipleBlockStepProps,
  SearchItemAndAddMultipleBlockStep,
} from '../SearchItemAndAddMultipleBlockDialog';
import { Button, DialogActions, DialogContent, TextField } from '@material-ui/core';
import SearchItemAndAddMultipleBlockStepper from '../SearchItemAndAddMultipleBlockStepper';
import { useDispatch, useSelector } from 'react-redux';
import { searchForItem } from '../searchItemAndAddMultipleBlockDialog.actions';
import { AppState } from '../../../../../state/configureStore';
import { SearchItemAndAddMultipleBlockState } from '../searchItemAndAddMultipleBlockDialog.reducer';

const InputItemStep: React.FC<BaseSearchItemAndAddMultipleBlockStepProps> = props => {
  const dispatch = useDispatch();
  const { onClose, onChangeStep } = props;
  const { searchText } = useSelector<AppState, SearchItemAndAddMultipleBlockState>(s => s.searchItemAndAddMultipleBlock);
  const [localSearchText, setLocalSearchText] = useState(searchText);

  useEffect(() => {
    setLocalSearchText(searchText);
  }, [searchText, setLocalSearchText]);

  const onClickNext = () => {
    dispatch(searchForItem(localSearchText));
    onChangeStep(SearchItemAndAddMultipleBlockStep.SearchResult);
  };

  return <>
    <DialogContent>
      <SearchItemAndAddMultipleBlockStepper activeStep={SearchItemAndAddMultipleBlockStep.InputItem}/>
      <TextField
        variant={'outlined'}
        name="blockNoText"
        type="input"
        label="Item name"
        fullWidth
        value={localSearchText}
        onChange={(e) => setLocalSearchText(e.target.value)}
        error={localSearchText.length <= 2}
        helperText={'Item Name is too short.'}
        multiline={true}
        rows={10}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>
        Close
      </Button>
      <Button variant={'contained'} color={'primary'} onClick={onClickNext}>
        Next
      </Button>
    </DialogActions>
  </>;
};

export default InputItemStep;



