import {
  Card,
  CardContent,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { AppState } from '../../../state/configureStore';
import {
  clearProgram,
  createProgram,
  fetchProgram,
  Program,
  ProgramState,
  updateProgram,
} from '../../../state/program';
import { FixedPositionButtons } from '../../components';

import useStyles from './programForm.style';

const ProgramSchema = Yup.object().shape({
  name: Yup.string().strict(true).trim().required('Name is required'),
  externalId: Yup.string().strict(true).trim().required('ExternalId is required'),
  isUseNpd: Yup.boolean(),
  isHighPriority: Yup.boolean(),
});

interface ProgramFormRouteParamsProps {
  id: string;
}

interface ProgramFormProps extends RouteComponentProps<ProgramFormRouteParamsProps> {}

const ProgramForm: React.FC<ProgramFormProps> = (props) => {
  const { match, history } = props;
  const {
    params: { id: paramsId },
  } = match;
  const classes = useStyles();
  const {
    item: {
      name = '',
      externalId = '',
      id = 0,
      isUseNpd = false,
      isHighPriority = false,
    },
  } = useSelector<AppState, ProgramState>((state) => state.program);
  const dispatch = useDispatch();

  const redirectToProgramList = () => {
    history.push('/settings/programs');
  };

  const handleSubmit = (values: Program, actions: FormikHelpers<any>) => {
    if (paramsId) {
      dispatch(updateProgram({ values, history }));
    } else {
      dispatch(createProgram({ values, history }));
    }
    actions.setSubmitting(false);
  };

  useEffect(() => {
    if (paramsId) {
      dispatch(fetchProgram(+paramsId));
    } else {
      dispatch(clearProgram());
    }

    return function cleanUp() {
      dispatch(clearProgram());
    };
  }, [paramsId, dispatch]);

  return (
    <Card className={classes.root}>
      <Formik
        enableReinitialize={true}
        initialValues={{ name, externalId, id, isUseNpd, isHighPriority }}
        validationSchema={ProgramSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, errors, touched, submitForm }) => {
          return (
            <Form>
              <Typography variant="h4" className={classes.title}>
                {paramsId === undefined ? `New Program` : `Update Program`}
              </Typography>
              <CardContent>
                <TextField
                  name="name"
                  type="input"
                  label="Name"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={values.name}
                  onChange={handleChange}
                  error={!!errors.name && !!touched.name}
                  helperText={errors.name}
                  disabled
                />
                {/* <TextField
                  name="externalId"
                  type="input"
                  label="ExternalId"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={values.externalId}
                  onChange={handleChange}
                  error={!!errors.externalId && !!touched.externalId}
                  helperText={errors.externalId}
                  disabled
                /> */}
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isUseNpd"
                        checked={values.isUseNpd}
                        onChange={handleChange}
                        value={values.isUseNpd}
                        // disabled
                      />
                    }
                    label="Is Use NPD"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isHighPriority"
                        checked={values.isHighPriority!}
                        onChange={handleChange}
                        value={values.isHighPriority}
                      />
                    }
                    label="Is High Priority"
                  />
                </FormGroup>
              </CardContent>
              <FixedPositionButtons
                actions={[
                  {
                    name: paramsId === undefined ? `Create` : `Update`,
                    onClick: () => {
                      submitForm();
                    },
                    color: 'primary' as 'primary',
                    disabled: false,
                    icon: 'save',
                  },
                  {
                    name: 'CANCEL',
                    onClick: redirectToProgramList,
                    color: 'default' as 'default',
                    disabled: false,
                  },
                ]}
                open
              />
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default ProgramForm;
