import { AppAction, PageFilter, AppCallback } from '../app';
import {
  ProgramAndBrandRequestItem,
  ProgramAndBrandRequestItemListFilter,
} from './programAndBrandRequestItem.types';

export const FETCH_PROGRAMANDBRANDREQUESTITEM = 'FETCH_PROGRAMANDBRANDREQUESTITEM';
export const FETCH_PROGRAMANDBRANDREQUESTITEM_LIST =
  'FETCH_PROGRAMANDBRANDREQUESTITEM_LIST';
export const CREATE_PROGRAMANDBRANDREQUESTITEM = 'CREATE_PROGRAMANDBRANDREQUESTITEM';
export const UPDATE_PROGRAMANDBRANDREQUESTITEM = 'UPDATE_PROGRAMANDBRANDREQUESTITEM';
export const CLEAR_PROGRAMANDBRANDREQUESTITEM = 'CLEAR_PROGRAMANDBRANDREQUESTITEM';
export const DELETE_PROGRAMANDBRANDREQUESTITEM = 'DELETE_PROGRAMANDBRANDREQUESTITEM';

export const fetchProgramAndBrandRequestItem = (
  id: ProgramAndBrandRequestItem['id'],
): AppAction => ({
  type: FETCH_PROGRAMANDBRANDREQUESTITEM,
  payload: id,
});

export const fetchProgramAndBrandRequestItemList = (
  filters: ProgramAndBrandRequestItemListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_PROGRAMANDBRANDREQUESTITEM_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createProgramAndBrandRequestItem = (
  newProgramAndBrandRequestItem: ProgramAndBrandRequestItem,
  callback?: AppCallback,
): AppAction => ({
  type: CREATE_PROGRAMANDBRANDREQUESTITEM,
  payload: newProgramAndBrandRequestItem,
  callback,
});

export const updateProgramAndBrandRequestItem = (
  newProgramAndBrandRequestItem: ProgramAndBrandRequestItem,
  callback?: AppCallback,
): AppAction => ({
  type: UPDATE_PROGRAMANDBRANDREQUESTITEM,
  payload: newProgramAndBrandRequestItem,
  callback,
});

export const changeProgramAndBrandRequestItemStatus = (
  ProgramAndBrandRequestItem: ProgramAndBrandRequestItem,
  status: string,
  callback?: AppCallback,
): AppAction => {
  const localProgramAndBrandRequestItem = { ...ProgramAndBrandRequestItem, status };
  return {
    type: UPDATE_PROGRAMANDBRANDREQUESTITEM,
    payload: localProgramAndBrandRequestItem,
    callback,
  };
};

export const clearProgramAndBrandRequestItem = (
  id: ProgramAndBrandRequestItem['id'],
) => ({
  type: CLEAR_PROGRAMANDBRANDREQUESTITEM,
});

export const deleteProgramAndBrandRequestItem = (
  payload: ProgramAndBrandRequestItem,
) => ({
  type: DELETE_PROGRAMANDBRANDREQUESTITEM,
  payload,
});
