import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  fetchSampleDevelopmentWlApprovalRequestList,
  updateSampleDevelopmentWlApprovalRequesResponse,
} from '../../services/sampleDevelopmentWlApprovalRequestService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  closeSampleDevelopmentWlApprovalRequestDialog,
  FETCH_SAMPLE_DEV_WL_APPROVAL_REQUEST_LIST,
  UPDATE_SAMPLE_DEV_WL_APPROVAL_REQUEST_RESPONSE,
} from './sampleDevelopmentWlApprovalRequest.actions';

function* fetchSasmpleDevelopmentWlApprovalRequestListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(
      fetchSampleDevelopmentWlApprovalRequestList,
      action.payload,
    );
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateSasmpleDevelopmentWlApprovalRequestResponseSaga(action: AppAction) {
  try {
    const successMessage = `Updated sample development wl approval request successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(
      updateSampleDevelopmentWlApprovalRequesResponse,
      action.payload,
    );
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put(closeSampleDevelopmentWlApprovalRequestDialog());
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSampleDevelopmentWlApprovalRequest() {
  yield debounce(
    250,
    FETCH_SAMPLE_DEV_WL_APPROVAL_REQUEST_LIST,
    fetchSasmpleDevelopmentWlApprovalRequestListSaga,
  );
  yield takeLatest(
    UPDATE_SAMPLE_DEV_WL_APPROVAL_REQUEST_RESPONSE,
    updateSasmpleDevelopmentWlApprovalRequestResponseSaga,
  );
}
