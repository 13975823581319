import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  Close as CloseIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { TableLoading } from '../../../components';
import { AppState } from '../../../../state/configureStore';
import {
  OpportunityCustomerState,
  deleteOpportunityCustomer,
  featOpporutnityCustomerIncludeMember,
  fetchOpportunityCustomerList,
} from '../../../../state/opportunityCustomer';
import { useDispatch, useSelector } from 'react-redux';
import {
  ResponsibilityState,
  fetchResponsibilityList,
} from '../../../../state/responsibility';
import ChipListUser from '../../../components/ChipListUser';
import { TransitionProps } from '@material-ui/core/transitions';

interface OpportunityCustomerVerifyDialogProps {
  opportunityId: number;
  open: boolean;
  onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

const OpportunityCustomerVerifyDialog: React.FC<OpportunityCustomerVerifyDialogProps> = (
  props,
) => {
  const dispatch = useDispatch();
  const { open, onClose, opportunityId } = props;

  const { items: opportunityCustomers, loading: isLoading } = useSelector<
    AppState,
    OpportunityCustomerState
  >((state) => state.opportunityCustomer);

  useEffect(() => {
    if (open) {
      dispatch(featOpporutnityCustomerIncludeMember({ opportunityId }));
    }

    return function cleanUp() {};
  }, [dispatch, opportunityId, open]);

  const deleteOppCustomer = (id?: number) => {
    if (id) {
      dispatch(deleteOpportunityCustomer({ id, opportunityId }));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={'md'}
      fullWidth
      TransitionComponent={Transition}
    >
      <DialogTitle>Verify Customer</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '10%' }}>No.</TableCell>
              <TableCell style={{ width: '40%' }}>Customer</TableCell>
              <TableCell style={{ width: '40%' }}>SSO</TableCell>
              <TableCell style={{ width: '10%' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={isLoading} colSpan={4}>
              {opportunityCustomers.map((opc, index) => {
                const ssoUsers =
                  opc.ssoUsers?.map((s) => {
                    return {
                      name: s.userFullName,
                      activated: s.active,
                      userLeaveTotalDay: 0,
                    };
                  }) || [];

                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{opc.customer?.displayName}</TableCell>
                    <TableCell>
                      <ChipListUser list={ssoUsers} noAllLabel />
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Delete">
                        <IconButton
                          color="secondary"
                          size="small"
                          onClick={() => deleteOppCustomer(opc.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions style={{ marginRight: '5px', marginBottom: '10px' }}>
        <Button
          onClick={onClose}
          color="primary"
          variant="outlined"
          startIcon={<CloseIcon />}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OpportunityCustomerVerifyDialog;
