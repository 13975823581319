import React, { useEffect, useState } from 'react';
import { PendingApprovalAssignmentListState } from './pendingApprovalAssignmentList.reducer';
import { AppState } from '../../../../state/configureStore';
import { DateTimeDisplay } from '../../../components/DateTimeDisplay';
import { IFindAllOpportunityApprovalRequest } from '../../../../models/findAllOpportunityApprovalRequest.model';
import { IOpportunity } from '../../../../models/opportunity.model';
import { OpportunityApprovalRequestDialog } from '../../opportunityApprovalRequest/OpportunityApprovalRequestDialog';
import { OpportunityStatusLabel } from '../../../../constants/opportunityStatus';
import { PageFilter } from '../../../../state/app';
import { TableLoading } from '../../../components';
import { Edit as EditIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  closeOpportunityApprovalRequestDialog,
  openOpportunityApprovalRequestDialog,
  OpportunityApprovalRequestState,
} from '../../../../state/opportunityApprovalRequest';
import {
  clearPendingApprovalAssignmentList,
  fetchPendingApprovalAssignmentList,
} from './pendingApprovalAssignmentList.actions';
import { TablePageSize } from '../../../../constants';

const PendingApprovalProgramBrandRequestList = () => {
  const dispatch = useDispatch();
  const [
    opportunityApprovalRequestDialogValue,
    setOpportunityApprovalRequestDialogValue,
  ] = useState({
    opportunity: {} as IOpportunity,
    opportunityApprovalRequest: {} as IFindAllOpportunityApprovalRequest,
  });
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 10,
  });

  const {
    loading,
    totalItems,
    items: pendingApprovalAssignmentList,
  } = useSelector<AppState, PendingApprovalAssignmentListState>(
    (state) => state.pendingApprovalAssignmentList,
  );

  const { openDialog } = useSelector<AppState, OpportunityApprovalRequestState>(
    (state) => state.opportunityApprovalRequest,
  );

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const editOpportunity = (
    opportunityApprovalRequest: IFindAllOpportunityApprovalRequest,
  ): void => {
    setOpportunityApprovalRequestDialogValue({
      opportunity: opportunityApprovalRequest.opportunity!,
      opportunityApprovalRequest,
    });
    dispatch(openOpportunityApprovalRequestDialog());
  };

  const handleCloseOpportunityApprovalRequestDialog = () => {
    dispatch(closeOpportunityApprovalRequestDialog());
  };

  useEffect(() => {
    if (!openDialog) {
      dispatch(fetchPendingApprovalAssignmentList(paging));
    }
    return function cleanup() {
      dispatch(clearPendingApprovalAssignmentList());
    };
  }, [dispatch, paging, openDialog]);

  return (
    <>
      <Typography variant="h6">Pending Approval Assignment</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Assignment Number</TableCell>
            <TableCell>Project Name</TableCell>
            <TableCell>Program</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>SalesTeam</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={10}>
            {pendingApprovalAssignmentList.map((pendingApprovalAssignment, index) => {
              return (
                <TableRow key={pendingApprovalAssignment.id}>
                  <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                  <TableCell>
                    {pendingApprovalAssignment?.opportunity?.assignmentNumber}
                  </TableCell>
                  <TableCell>{pendingApprovalAssignment?.smProject?.name}</TableCell>
                  <TableCell>{pendingApprovalAssignment.program}</TableCell>
                  <TableCell>{pendingApprovalAssignment.brand}</TableCell>
                  <TableCell>
                    {pendingApprovalAssignment.smSquad
                      ? pendingApprovalAssignment?.smSquad?.name
                      : ''}
                  </TableCell>
                  <TableCell>
                    {
                      OpportunityStatusLabel[
                        pendingApprovalAssignment.opportunity!.status!
                      ]
                    }
                  </TableCell>
                  <TableCell>
                    <DateTimeDisplay
                      value={pendingApprovalAssignment.opportunity!.createdAt}
                    />
                  </TableCell>
                  <TableCell>
                    {pendingApprovalAssignment.opportunity!.createdBy}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={() => editOpportunity(pendingApprovalAssignment)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[...TablePageSize]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
      <OpportunityApprovalRequestDialog
        handleClose={handleCloseOpportunityApprovalRequestDialog}
        opportunity={opportunityApprovalRequestDialogValue.opportunity}
        opportunityApprovalRequest={
          opportunityApprovalRequestDialogValue.opportunityApprovalRequest
        }
      />
    </>
  );
};

export default PendingApprovalProgramBrandRequestList;
