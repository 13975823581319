import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_EBLOCK_ART_TEMPLATE,
  CREATE_EBLOCK_ART_TEMPLATE,
  FETCH_EBLOCK_ART_TEMPLATE,
  FETCH_EBLOCK_ART_TEMPLATE_LIST,
  UPDATE_EBLOCK_ART_TEMPLATE,
} from './eBlockArtTemplate.action';
import { EBlockArtTemplate, EBlockArtTemplateState } from './eBlockArtTemplate.type';

const initialState: EBlockArtTemplateState = {
  errorMessage: '',
  item: {} as Readonly<EBlockArtTemplate>,
  items: [] as ReadonlyArray<EBlockArtTemplate>,
  loading: false,
  totalItems: 0,
  selectedItems: [] as EBlockArtTemplate[],
};

export default (
  state: EBlockArtTemplateState = initialState,
  action: AppAction,
): EBlockArtTemplateState => {
  switch (action.type) {
    case REQUEST(FETCH_EBLOCK_ART_TEMPLATE_LIST):
    case REQUEST(FETCH_EBLOCK_ART_TEMPLATE):
    case REQUEST(CREATE_EBLOCK_ART_TEMPLATE):
    case REQUEST(UPDATE_EBLOCK_ART_TEMPLATE):
      return { ...state, loading: true };
    case SUCCESS(FETCH_EBLOCK_ART_TEMPLATE_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_EBLOCK_ART_TEMPLATE_LIST):
    case FAILURE(FETCH_EBLOCK_ART_TEMPLATE):
    case FAILURE(CREATE_EBLOCK_ART_TEMPLATE):
    case FAILURE(UPDATE_EBLOCK_ART_TEMPLATE):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_EBLOCK_ART_TEMPLATE):
    case SUCCESS(UPDATE_EBLOCK_ART_TEMPLATE):
    case SUCCESS(FETCH_EBLOCK_ART_TEMPLATE):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_EBLOCK_ART_TEMPLATE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
