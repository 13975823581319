import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import {
  createResponsibilityWorkingTeamMember,
  deleteResponsibilityWorkingTeamMember,
  fetchResponsibilityWorkingTeamMember,
  fetchResponsibilityWorkingTeamMemberList,
  inactiveResponsibilityWorkingTeamMember,
  updateResponsibilityWorkingTeamMember,
  replaceResponsibilityWorkingTeamMember,
} from '../../services/responsibilityWorkingTeamMemberService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_RESPONSIBILITY_WORKINGTEAM_MEMBER,
  DELETE_RESPONSIBILITY_WORKINGTEAM_MEMBER,
  FETCH_RESPONSIBILITY_WORKINGTEAM_MEMBER_LIST,
  FETCH_RESPONSIBILITY_WORKINGTEAM_MEMBER,
  CREATE_RESPONSIBILITY_WORKINGTEAM_MEMBERS,
  REPLACE_RESPONSIBILITY_WORKINGTEAM_MEMBER,
  fetchResponsibilityWorkingTeamMemberListByresponsibilityId,
  UPDATE_RESPONSIBILITY_WORKINGTEAM_MEMBER,
  INACTIVE_RESPONSIBILITY_WORKINGTEAM_MEMBER,
} from './responsibilityWorkingTeamMember.actions';
import {
  ResponsibilityWorkingTeamMemberCreateOrUpdatePayload,
  ResponsibilityWorkingTeamMemberReplacePayLoad,
} from './responsibilityWorkingTeamMember.types';
import { AppState } from '../configureStore';
import { IResponsibilityWorkingTeamMember } from '../../models/responsibilityWorkingTeamMember.model';

function* fetchResponsibilityWorkingTeamMemberSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchResponsibilityWorkingTeamMember, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchResponsibilityWorkingTeamListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchResponsibilityWorkingTeamMemberList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createResponsibilityWorkingTeamMemberSaga(action: AppAction) {
  try {
    const {
      values,
    } = action.payload as ResponsibilityWorkingTeamMemberCreateOrUpdatePayload;
    const successMessage = `Created responsibility workingTeam member  successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createResponsibilityWorkingTeamMember, values);
    yield put(
      fetchResponsibilityWorkingTeamMemberListByresponsibilityId(data.responsibilityId),
    );
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createResponsibilityWorkingTeamMembersSaga(action: AppAction) {
  try {
    const { payload, callback } = action;

    yield put({ type: REQUEST(action.type) });

    for (const opCustGrp of payload) {
      yield call(createResponsibilityWorkingTeamMember, opCustGrp);
    }

    const successMessage = `Created responsibility workingTeam member successfully.`;
    yield put(
      fetchResponsibilityWorkingTeamMemberListByresponsibilityId(
        payload[0].responsibilityId,
      ),
    );

    yield put({ type: SUCCESS(action.type), payload });

    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));

    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateResponsibilityWorkingTeamMemberSaga(action: AppAction) {
  try {
    const {
      values,
    } = action.payload as ResponsibilityWorkingTeamMemberCreateOrUpdatePayload;
    const successMessage = `Updated responsibility workingTeam member successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateResponsibilityWorkingTeamMember, values);
    yield put(
      fetchResponsibilityWorkingTeamMemberListByresponsibilityId(data.responsibilityId),
    );
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteResponsibilityWorkingTeamMemberSaga(action: AppAction) {
  const { payload } = action;
  const { id } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    yield call(deleteResponsibilityWorkingTeamMember, id);
    const responsibility = yield select(getResponsibilityFromState);
    if (responsibility.id) {
      yield put(
        fetchResponsibilityWorkingTeamMemberListByresponsibilityId(responsibility.id),
      );
    }
    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Deleted successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* replaceResponsibilityWorkingTeamMemberSaga(action: AppAction) {
  try {
    const values = action.payload as ResponsibilityWorkingTeamMemberReplacePayLoad;
    const successMessage = `Replace responsibility workingTeam member successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(replaceResponsibilityWorkingTeamMember, values);
    const responsibility = yield select(getResponsibilityFromState);
    if (responsibility.id) {
      yield put(
        fetchResponsibilityWorkingTeamMemberListByresponsibilityId(responsibility.id),
      );
    }
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* inactiveResponsibilityWorkingTeamMemberSaga(action: AppAction) {
  const { payload } = action;
  const { id } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    yield call(inactiveResponsibilityWorkingTeamMember, id);
    const responsibility = yield select(getResponsibilityFromState);
    if (responsibility.id) {
      yield put(
        fetchResponsibilityWorkingTeamMemberListByresponsibilityId(responsibility.id),
      );
    }

    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Inactive successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function getResponsibilityFromState(state: AppState): IResponsibilityWorkingTeamMember {
  const { item } = state.responsibility;
  return item;
}

export default function* watchResponsibilityWorkingTeamMember() {
  yield takeLatest(
    FETCH_RESPONSIBILITY_WORKINGTEAM_MEMBER,
    fetchResponsibilityWorkingTeamMemberSaga,
  );
  yield debounce(
    250,
    FETCH_RESPONSIBILITY_WORKINGTEAM_MEMBER_LIST,
    fetchResponsibilityWorkingTeamListSaga,
  );
  yield takeLatest(
    CREATE_RESPONSIBILITY_WORKINGTEAM_MEMBER,
    createResponsibilityWorkingTeamMemberSaga,
  );
  yield takeLatest(
    UPDATE_RESPONSIBILITY_WORKINGTEAM_MEMBER,
    updateResponsibilityWorkingTeamMemberSaga,
  );
  yield takeLatest(
    CREATE_RESPONSIBILITY_WORKINGTEAM_MEMBERS,
    createResponsibilityWorkingTeamMembersSaga,
  );
  yield takeLatest(
    DELETE_RESPONSIBILITY_WORKINGTEAM_MEMBER,
    deleteResponsibilityWorkingTeamMemberSaga,
  );
  yield takeLatest(
    REPLACE_RESPONSIBILITY_WORKINGTEAM_MEMBER,
    replaceResponsibilityWorkingTeamMemberSaga,
  );

  yield takeLatest(
    INACTIVE_RESPONSIBILITY_WORKINGTEAM_MEMBER,
    inactiveResponsibilityWorkingTeamMemberSaga,
  );
}
