import { CardContent, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './sampleDevelopmentNl.style';
import SampleDevelopmentNlDesignFileType from './SampleDevelopmentNlDesignFileType';
import SampleDevelopmentNlDesignInformation from './SampleDevelopmentNlDesignInformation';
import SampleDevelopmentNlDesignSoftware from './SampleDevelopmentNlDesignSoftware';
import { CheckBoxFastField } from '../../../components';

interface SampleDevelopmentNlDesignProps {}

const SampleDevelopmentNlDesign: React.FC<SampleDevelopmentNlDesignProps> = (props) => {
  const classes = useStyles();

  return (
    <Paper variant={'outlined'}>
      <Typography className={classes.title}>Design</Typography>
      <CardContent>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Typography className={classes.numberTitle} display="inline">
            1. Design
          </Typography>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="ownDesign" label="Own Design" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="customerDesign" label="Customer Design" />
            </Grid>
          </Grid>
        </Grid>

        <SampleDevelopmentNlDesignInformation />

        <SampleDevelopmentNlDesignFileType />

        <SampleDevelopmentNlDesignSoftware />
      </CardContent>
    </Paper>
  );
};

export default SampleDevelopmentNlDesign;
