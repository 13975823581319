import { call, put, takeLatest } from 'redux-saga/effects';
import { AppAction } from '../../../../state/app';
import {
  CREATE_OPPORTUNITY_ITEM_FROM_SEARCH_RESULT,
  CreateOpportunityItemPayload,
  CreateOpportunityItemResult,
  SEARCH_FOR_ITEM,
} from './searchItemAndAddMultipleBlockDialog.actions';
import { REQUEST, SUCCESS } from '../../../../utils/actionTypeUtil';
import { searchBlocksByItemNames } from '../../../../services/blockService';
import { AxiosResponse } from 'axios';
import { createOpportunityItem } from '../../../../services/opportunityItemService';

function* searchForItem(action: AppAction) {
  yield put({ type: REQUEST(action.type), payload: action.payload });

  const searchBlockByItemNameDto = action.payload!
    .split(/\r?\n/)
    .map((t: string) => t.trim())
    .filter((t: string) => t.length > 0)
    .map((itemText: string) => ({ itemText: itemText }));

  const result: AxiosResponse = yield call(searchBlocksByItemNames, searchBlockByItemNameDto);
  yield put({ type: SUCCESS(action.type), payload: result });
}

function* createOpportunityItemFromSearchResult(action: AppAction) {
  const {
    searchItemResults,
    opportunityId,
    npdUserId,
    sampleDevUserId,
  } = action.payload as CreateOpportunityItemPayload;

  yield put({ type: REQUEST(action.type) });
  const createOpportunityItemResults: CreateOpportunityItemResult[] = [];
  for (const searchItemResult of searchItemResults) {
    const { selectedBlock } = searchItemResult;
    try {
      yield call(createOpportunityItem, { opportunityId, npdUserId, sampleDevUserId, blockId: selectedBlock!.id });
      createOpportunityItemResults.push({ block: selectedBlock!, isError: false });
    } catch (e) {
      let message = e.message;
      if (e.response && e.response.data.message) {
        message = e.response.data.message;
      }
      createOpportunityItemResults.push({ block: selectedBlock!, isError: true, message });
    }
  }
  yield put({ type: SUCCESS(action.type), payload: createOpportunityItemResults });
}

export default function* watch() {
  yield takeLatest(SEARCH_FOR_ITEM, searchForItem);
  yield takeLatest(CREATE_OPPORTUNITY_ITEM_FROM_SEARCH_RESULT, createOpportunityItemFromSearchResult);
}
