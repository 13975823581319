import axios from '../utils/axios';
import { PageFilter } from '../state/app';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import { SubstrateListFilter } from '../state/substrate';

const baseUrl = `/api/substrates`;

export const fetchSubstrateList = async ({
  filters,
  paging,
}: {
  filters: SubstrateListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};
