import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import {
  createOpportunity,
  fetchOpportunity,
  fetchOpportunityList,
  submitForApproval,
  updateOpportunity,
  changeProgramBrand,
  submitForReassignResponsibility,
  MoveItemToNewOpportunity,
} from '../../services/opportunityService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_OPPORTUNITY,
  FETCH_OPPORTUNITY,
  FETCH_OPPORTUNITY_LIST,
  SUBMIT_FOR_APPROVAL,
  UPDATE_OPPORTUNITY,
  CHANGE_PROGRAM_AND_BRAND,
  SUBMIT_FOR_REASSIGN_RESPONSIBILITY,
  fetchOpportunityListByOpportunityId,
  MOVE_ITEM_TO_NEW_OPPORTUNITY,
  fetchOpportunity as fetchOpportunityAction,
} from './opportunity.actions';
import {
  ChangeProgramBrandPayload,
  MoveOpportunityPayload,
  OpportunityCreatePayload,
  OpportunityUpdatePayload,
} from './opportunity.types';
import * as ChangeLog from '../changeLog';
import { AppState } from '../configureStore';
import { IOpportunity } from '../../models/opportunity.model';

const formPageUrl = `/assignments/form`;

function* fetchOpportunitySaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchOpportunity, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchOpportunityListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchOpportunityList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createOpportunitySaga(action: AppAction) {
  try {
    const { values, history } = action.payload as OpportunityCreatePayload;
    const successMessage = `Created assignment successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createOpportunity, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history!.push(`${formPageUrl}/${data.id}`);
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* moveItemToNewOpportunitySaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(MoveItemToNewOpportunity, action.payload);
    const successMessage = `Move item to new Assignment: ${data.assignmentNumber} successfully.`;
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    if (data.id) {
      yield put(ChangeLog.fetchOpportunityLog(data.id));
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateOpportunitySaga(action: AppAction) {
  try {
    const { values } = action.payload as OpportunityUpdatePayload;
    const successMessage = `Updated assignment successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateOpportunity, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    if (data.id) {
      yield put(ChangeLog.fetchOpportunityLog(data.id));
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* submitForApprovalSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const successMessage = `Submit for Approval successfully.`;
    const { data } = yield call(submitForApproval, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    if (data.id) {
      yield put(ChangeLog.fetchOpportunityLog(data.id));
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* submitForReassignResponsibilitySaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const successMessage = `Reassign responsibility successfully.`;
    const { data } = yield call(submitForReassignResponsibility, action.payload);

    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
    const opportunity = yield select(getOpportunityFromState);
    const opportunityId = opportunity.id;
    if (opportunityId) {
      yield put(fetchOpportunityAction(opportunityId));
    }
  }
}

function* changeProgramBrandSaga(action: AppAction) {
  try {
    const values = action.payload as ChangeProgramBrandPayload;
    const successMessage = `Change Program and Brand successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(changeProgramBrand, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function getOpportunityFromState(state: AppState): IOpportunity {
  const { item } = state.opportunity;
  return item;
}

export default function* watchOpportunity() {
  yield takeLatest(FETCH_OPPORTUNITY, fetchOpportunitySaga);
  yield debounce(250, FETCH_OPPORTUNITY_LIST, fetchOpportunityListSaga);
  yield takeLatest(CREATE_OPPORTUNITY, createOpportunitySaga);
  yield takeLatest(UPDATE_OPPORTUNITY, updateOpportunitySaga);
  yield takeLatest(SUBMIT_FOR_APPROVAL, submitForApprovalSaga);
  yield takeLatest(CHANGE_PROGRAM_AND_BRAND, changeProgramBrandSaga);
  yield takeLatest(
    SUBMIT_FOR_REASSIGN_RESPONSIBILITY,
    submitForReassignResponsibilitySaga,
  );
  yield takeLatest(MOVE_ITEM_TO_NEW_OPPORTUNITY, moveItemToNewOpportunitySaga);
}
