import { PageFilter } from '../state/app';
import {
  EBlockArtTemplate,
  EBlockArtTemplateListFilter,
} from '../state/eBlockArtTemplate';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/blockArtTemplates`;

export const fetchEBlockArtTemplateList = async ({
  filters,
  paging,
}: {
  filters: EBlockArtTemplateListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchEBlockArtTemplate = async (id: EBlockArtTemplate['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createEBlockArtTemplate = async (bBlockArtTemplate: EBlockArtTemplate) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, bBlockArtTemplate);
};

export const updateEBlockArtTemplate = async (eBlockGroupProduct: EBlockArtTemplate) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, eBlockGroupProduct);
};
