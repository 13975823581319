import { IEBlockSize } from '../models/eBlockSize';
import { PageFilter } from '../state/app';
import { EBlockSizeListFilter } from '../state/eBlockSize';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/eBlockSizes`;

export const fetchEBlockSizeList = async ({
  filters,
  paging,
}: {
  filters: EBlockSizeListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchEBlockSize = async (id: IEBlockSize['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createEBlockSize = async (eBlockSize: IEBlockSize) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, eBlockSize);
};

export const updateEBlockSize = async (eBlockSize: IEBlockSize) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, eBlockSize);
};
