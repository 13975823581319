import { Button, Paper, TextField, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { AuthState, Credentials, login } from '../../../state/auth';
import { AppState } from '../../../state/configureStore';
import logo from './logo.png';

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 64px)',
  },
  form: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  formContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3, 2),
  },
  label: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Auth: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [credentials, setCredentials] = useState<Credentials>({
    password: '',
    username: '',
  });
  const { isLoggedIn } = useSelector<AppState, AuthState>((state) => state.auth);
  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    dispatch(login(credentials));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setCredentials((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const { location } = history;
  const { from } = location.state || {
    from: { pathname: '/dashboard', search: location.search },
  };

  return isLoggedIn ? (
    <Redirect to={from} />
  ) : (
    <div className={classes.flexContainer}>
      <Paper className={classes.formContainer}>
        <img src={logo} alt="logo" />
        <Typography component="h1" variant="h5" className={classes.label}>
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoFocus
            onChange={handleChange}
            value={credentials.username}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={handleChange}
            value={credentials.password}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
        </form>
      </Paper>
    </div>
  );
};

export default Auth;
