import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SampleDevelopmentStatusLabel } from '../../../../constants/sampleDevelopmentStatus';
import { ISampleDevelopmentPl } from '../../../../models/sampleDevelopmentPl.model';
import { PageFilter } from '../../../../state/app';
import { AppState } from '../../../../state/configureStore';
import {
  closeSampleDevelopmentPlApprovalRequestDialog,
  openSampleDevelopmentPlApprovalRequestDialog,
  SampleDevelopmentPlApprovalRequest,
  SampleDevelopmentPlApprovalRequestState,
} from '../../../../state/sampleDevelopmentPlApprovalRequest';
import { DateTimeDisplay, TableLoading } from '../../../components';
import SampleDevelopmentPlApprovalRequestDialog from '../../sampleDevelopmentPlApprovalRequest/SampleDevelopmentPlApprovalRequestDialog';
import { fetchPendingApprovalSampleDevPlList } from './pendingApprovalSampleDevPlRequestList.actions';
import { PendingApprovalSampleDevPlRequestListState } from './pendingApprovalSampleDevPlRequestList.reducer';
import { useLocation } from 'react-router-dom';
import history from '../../../../history';

const PendingApprovalSampleDevPlRequestList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const sampleDevNumber = queryString.get('sampleDevNumber');
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const {
    loading,
    totalItems,
    items: pendingApprovalSampleDevPlRequestList,
  } = useSelector<AppState, PendingApprovalSampleDevPlRequestListState>(
    (state) => state.pendingApprovalSampleDevPlRequestList,
  );

  const { openDialog } = useSelector<AppState, SampleDevelopmentPlApprovalRequestState>(
    (state) => state.sampleDevelopmentPlApprovalRequest,
  );

  const [sampleDevPlAppRequestDlg, setsampleDevPlAppRequestDlg] = useState({
    sampleDevelopmentPl: {} as ISampleDevelopmentPl,
    sampleDevelopmentPlApprovalRequest: {} as SampleDevelopmentPlApprovalRequest,
  });

  useEffect(() => {
    if (!openDialog) {
      dispatch(fetchPendingApprovalSampleDevPlList(paging));
    }
  }, [dispatch, paging, openDialog]);

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const handleCloseSampleDevPlAppRequestDlg = () => {
    dispatch(closeSampleDevelopmentPlApprovalRequestDialog());
    history.replace(`${history.location.pathname}`);
  };

  const editSampleDevPl = useCallback(
    (sampleDevPlAppRequest: SampleDevelopmentPlApprovalRequest): void => {
      setsampleDevPlAppRequestDlg({
        sampleDevelopmentPl: sampleDevPlAppRequest.sampleDevelopmentPl!,
        sampleDevelopmentPlApprovalRequest: sampleDevPlAppRequest,
      });
      dispatch(openSampleDevelopmentPlApprovalRequestDialog());
    },
    [dispatch],
  );

  useEffect(() => {
    const sampleDevPlPrefix = 'SPPL';
    if (sampleDevNumber && sampleDevNumber.startsWith(sampleDevPlPrefix)) {
      const selectedPendingApprovalSampleDevPlRequest =
        pendingApprovalSampleDevPlRequestList.filter(
          (pendingApprovalSampleDevPlRequest) =>
            pendingApprovalSampleDevPlRequest.sampleDevelopmentPl!.sampleDevNumber ===
            sampleDevNumber,
        );
      if (selectedPendingApprovalSampleDevPlRequest.length > 0) {
        editSampleDevPl(selectedPendingApprovalSampleDevPlRequest[0]);
      }
    }
  }, [sampleDevNumber, editSampleDevPl, pendingApprovalSampleDevPlRequestList]);

  return (
    <>
      <Typography variant="h6">Pending Approval Sample Development PL</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Sample Dev. Number</TableCell>
            <TableCell>Program</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Block</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={10}>
            {pendingApprovalSampleDevPlRequestList.map(
              (pendingApprovalSampleDevPl, index) => {
                return (
                  <TableRow key={pendingApprovalSampleDevPl.id}>
                    <TableCell>
                      {index + paging.pageSize * paging.pageNumber + 1}
                    </TableCell>
                    <TableCell>
                      {pendingApprovalSampleDevPl!.sampleDevelopmentPl!.sampleDevNumber}
                    </TableCell>
                    <TableCell>
                      {
                        pendingApprovalSampleDevPl!.sampleDevelopmentPl!.block!.program!
                          .name
                      }
                    </TableCell>
                    <TableCell>
                      {
                        pendingApprovalSampleDevPl!.sampleDevelopmentPl!.block!.brand!
                          .name
                      }
                    </TableCell>
                    <TableCell>
                      {pendingApprovalSampleDevPl!.sampleDevelopmentPl!.block!.item}
                    </TableCell>
                    <TableCell>
                      {pendingApprovalSampleDevPl!.sampleDevelopmentPl!.block!.blockNo}
                    </TableCell>
                    <TableCell>
                      {
                        SampleDevelopmentStatusLabel[
                          pendingApprovalSampleDevPl!.sampleDevelopmentPl!.status!
                        ]
                      }
                    </TableCell>
                    <TableCell>
                      <DateTimeDisplay
                        value={pendingApprovalSampleDevPl!.sampleDevelopmentPl!.createdAt}
                      />
                    </TableCell>
                    <TableCell>
                      {pendingApprovalSampleDevPl!.sampleDevelopmentPl!.createdBy}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={() => editSampleDevPl(pendingApprovalSampleDevPl)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              },
            )}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
      <SampleDevelopmentPlApprovalRequestDialog
        handleClose={handleCloseSampleDevPlAppRequestDlg}
        sampleDevelopmentPl={sampleDevPlAppRequestDlg.sampleDevelopmentPl}
        sampleDevelopmentPlApprovalRequest={
          sampleDevPlAppRequestDlg.sampleDevelopmentPlApprovalRequest
        }
      />
    </>
  );
};

export default PendingApprovalSampleDevPlRequestList;
