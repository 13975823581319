import { AppAction } from '../../../state/app';
import { NotificationMessage } from './Notification.types';

export const PUSH_NOTIFICATION_MESSAGE = 'PUSH_NOTIFICATION_MESSAGE';
export const CLEAR_NOTIFICATION_MESSAGE = 'CLEAR_NOTIFICATION_MESSAGE';

export const pushNotificationMessage = (payload: NotificationMessage): AppAction => {
  return {
    type: PUSH_NOTIFICATION_MESSAGE,
    payload,
  };
};

export const clearNotificationMessage = (): AppAction => {
  return {
    type: CLEAR_NOTIFICATION_MESSAGE,
  };
};
