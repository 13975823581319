import React from 'react';
import { Link } from '@material-ui/core';
import { ITask } from '../../models/task.model';

interface TaskNumberLinkButtonProps {
  task: ITask | undefined;
  smSubtaskId?: number;
}

const TaskNumberLinkButton: React.FC<TaskNumberLinkButtonProps> = (props) => {
  const { task, smSubtaskId } = props;
  const baseLink = `/tasks/form/${task?.id}`;
  const link = Boolean(smSubtaskId) ? `${baseLink}?subtask=${smSubtaskId}` : baseLink;

  return (
    <Link href={link} rel="noopener">
      {task?.taskNumber}
    </Link>
  );
};

export default TaskNumberLinkButton;
