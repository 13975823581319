import React, { useEffect, useState } from 'react';
import { Button, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { FilterButton, FilterDrawer, LargeScreenFilterContainer } from '../../components';
import { IFilterDrawerProps } from '../Layout/FilterDrawer';

export type IFilterContainerProps<T> = {
  localFilter: T;
  open: boolean;
  handleChangeFilter: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeDateFilter: (name: string, value?: string | null) => void;
}

export type IFilterContainerConfigProps<T> = {
  filters: T;
  onChangeFilter: (newFilter: T) => void;
  initialFilter: T;
  render?: (props: IFilterContainerProps<T>) => React.ReactNode;
}

function FilterContainer<T>(props: IFilterContainerConfigProps<T>) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [open, setOpen] = useState(false);
  const { filters, onChangeFilter, initialFilter } = props;
  const [localFilter, setLocalFilter] = useState<any>(filters);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const newFilter = {
      ...localFilter,
      [target.name as string]: target.value,
    };
    setLocalFilter(newFilter);
    if (isLargeScreen) {
      onChangeFilter(newFilter);
    }
  };

  const handleChangeDateFilter = (name: string, value?: string | null) => {
    const newFilter = {
      ...localFilter,
      [name as string]: value,
    };
    setLocalFilter(newFilter);
    if (isLargeScreen) {
      onChangeFilter(newFilter);
    }
  };

  useEffect(() => {
    setLocalFilter({ ...filters });
  }, [filters, setLocalFilter]);

  const Wrapper: React.FC<IFilterDrawerProps> = isLargeScreen ? LargeScreenFilterContainer : FilterDrawer;

  return (
    <>
      {!isLargeScreen && <Grid container spacing={1} style={{ padding: theme.spacing(1) }}>
        <Grid item>
          <FilterButton onClick={() => setOpen(true)}/>
        </Grid>
      </Grid>}
      <Wrapper open={open}>
        {props.render ? props.render({ localFilter, open, handleChangeDateFilter, handleChangeFilter }) : null}
        {isLargeScreen ? null : <Grid container spacing={1}>
          <Grid item xs={6} sm={6}>
            <Button
              fullWidth={true}
              variant="contained"
              color="secondary"
              onClick={() => {
                setLocalFilter({ ...initialFilter });
              }}
            >
              Reset
            </Button>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button
              fullWidth={true}
              variant="contained"
              color="primary"
              onClick={() => {
                setOpen(false);
                onChangeFilter(localFilter);
              }}
            >
              OK
            </Button>
          </Grid>
        </Grid>}
      </Wrapper>
    </>
  );
}

export default FilterContainer;

