import { PageFilter } from '../state/app';
import {
  ResponsibilityCustomerListFilter,
  ResponsibilityCustomerReplacePayLoad,
} from '../state/responsibilityCustomer';
import axios from '../utils/axios';
import { IResponsibilityCustomer } from '../models/responsibilityCustomer.model';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
const baseUrl = `/api/responsibilityCustomers`;

export const createResponsibilityCustomer = async (
  responsibilityCustomer: IResponsibilityCustomer,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, responsibilityCustomer);
};

export const fetchResponsibilityCustomer = async (id: IResponsibilityCustomer['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const fetchResponsibilityCustomerList = async ({
  filters,
  paging,
}: {
  filters: ResponsibilityCustomerListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const deleteResponsibilityCustomer = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};

export const inactiveResponsibilityCustomer = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}/inactive`;
  return await axios.put(requestUrl);
};

export const updateResponsibilityCustomer = async (
  responsibilityCustomer: IResponsibilityCustomer,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, responsibilityCustomer);
};

export const replaceResponsibilityCustomer = async (
  responsibilityCustomerReplacePayLoad: ResponsibilityCustomerReplacePayLoad,
) => {
  const requestUrl = `${baseUrl}/${responsibilityCustomerReplacePayLoad.responsibilityCustomerId}/replace`;
  return await axios.put(requestUrl, responsibilityCustomerReplacePayLoad);
};
