import React from 'react';
import { IEBlockSize } from '../../models/eBlockSize';
import { fetchEBlockSizeList } from '../../services/eBlockSizeService';
import AsyncAutoComplete from './AsyncAutoComplete';

interface EBlockSizeComboBoxProps {
  handleChange: (result: IEBlockSize) => void;
  selectedValue: IEBlockSize;
  isDisabled?: boolean;
  error?: boolean;
  helperText?: string;
  eBlockGroupProductId: number;
}

const EBlockSizeComboBox: React.FC<EBlockSizeComboBoxProps> = props => {
  const {
    handleChange,
    selectedValue,
    isDisabled = false,
    error,
    helperText,
    eBlockGroupProductId,
  } = props;

  const eBlockSizePromiseOptions = async (inputValue: string) => {
    const { data: rows } = await fetchEBlockSizeList({
      filters: {
        'CodeAndDesc.Contains': inputValue,
        'EBlockGroupProductId.EqualsTo': eBlockGroupProductId.toString(),
      },
      paging: { pageNumber: 0, pageSize: 10 },
    });
    return rows;
  };

  return (
    <AsyncAutoComplete
      name=""
      value={selectedValue}
      getOptionValue={(option: IEBlockSize) => {
        return option.id!.toString();
      }}
      getOptionLabel={(option: IEBlockSize) => {
        return `${option.code!}: ${option.description!}`;
      }}
      promiseOptions={eBlockSizePromiseOptions}
      placeholder={''}
      handleChange={handleChange}
      isDisabled={isDisabled}
      error={error}
      helperText={helperText}
    />
  );
};

export default EBlockSizeComboBox;
