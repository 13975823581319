import { IProject } from '../../models/project.model';
import { AppAction, PageFilter } from '../app';
import {
  ProjectCreatePayload,
  ProjectListFilter,
  ProjectUpdatePayload,
} from './project.types';

export const FETCH_PROJECT_DETAIL = 'FETCH_PROJECT_DETAIL';
export const FETCH_PROJECT = 'FETCH_PROJECT';
export const FETCH_PROJECT_LIST = 'FETCH_PROJECT_LIST';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const CLEAR_PROJECT = 'CLEAR_PROJECT';
export const SUBMIT_FOR_APPROVAL_PROJECT = 'SUBMIT_FOR_APPROVAL_PROJECT';
export const SUBMIT_FOR_UNLOCK_APPROVED_PROJECT = 'SUBMIT_FOR_UNLOCK_APPROVED_PROJECT';

export const fetchProjectDetail = (id: IProject['id']): AppAction => {
  return {
    type: FETCH_PROJECT_DETAIL,
    payload: id,
  };
};

export const fetchProject = (id: IProject['id']): AppAction => ({
  type: FETCH_PROJECT,
  payload: id,
});

export const fetchProjectList = (
  filters: ProjectListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_PROJECT_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createProject = (payload: ProjectCreatePayload): AppAction => ({
  type: CREATE_PROJECT,
  payload,
});

export const updateProject = (payload: ProjectUpdatePayload): AppAction => ({
  type: UPDATE_PROJECT,
  payload,
});

export const clearProject = () => ({
  type: CLEAR_PROJECT,
});

export const submitForApprovalProject = (id: IProject['id']): AppAction => ({
  type: SUBMIT_FOR_APPROVAL_PROJECT,
  payload: id,
});

export const submitForUnlockApprovedProject = (
  id: IProject['id'],
  description: string,
): AppAction => ({
  type: SUBMIT_FOR_UNLOCK_APPROVED_PROJECT,
  payload: { id, description },
});
