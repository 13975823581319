import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import { FETCH_SALESTEAM_ROLE_LIST } from './salesTeamRole.actions';
import { SalesTeamRoleState } from './salesTeamRole.types';

const getInitialSalesTeamRoleState = () => ({
  id: 0,
  name: '',
  description: '',
  mainRole: '',
});

const initialState: SalesTeamRoleState = {
  errorMessage: '',
  item: getInitialSalesTeamRoleState(),
  items: [],
  loading: false,
  totalItems: 0,
};

export default (
  state: SalesTeamRoleState = initialState,
  action: AppAction,
): SalesTeamRoleState => {
  switch (action.type) {
    case REQUEST(FETCH_SALESTEAM_ROLE_LIST):
      return { ...state, loading: true };
    case SUCCESS(FETCH_SALESTEAM_ROLE_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_SALESTEAM_ROLE_LIST):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    default:
      return state;
  }
};
