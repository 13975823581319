import React from 'react';
import { CardContent, Typography, Grid } from '@material-ui/core';
import moment from 'moment';
import { DateTimeDisplay } from './DateTimeDisplay';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from '@material-ui/lab';
import Loading from './AwLoading';
import { IChangeLog } from '../../models/changeLog.model';

export interface ChangeLogProjectProps {
  changeLogList: IChangeLog[];
  loading: boolean;
}

const ChangeLogProject: React.FC<ChangeLogProjectProps> = (props) => {
  const { changeLogList, loading } = props;

  const renderTimeLineContent = (cl: IChangeLog) => {
    const changeLogCase = cl.description;
    const timeLineContent = () => {
      switch (changeLogCase) {
        case 'ADD':
          return (
            <Typography>
              ADD{' '}
              <span style={{ fontWeight: 'bold' }}>
                {cl.type} {cl.fieldName.includes('Id') ? '' : cl.fieldName}
              </span>
              {'  '}
              <span style={{ fontWeight: 'bold' }}>{cl.changedVersion}</span> by{'  '}
              <span style={{ fontWeight: 'bold' }}>{cl.createdBy}</span>
            </Typography>
          );
        case 'DELETE':
          return (
            <Typography>
              DELETE{' '}
              <span style={{ fontWeight: 'bold' }}>
                {cl.type} {cl.fieldName.includes('Id') ? '' : cl.fieldName}
              </span>
              {'  '}
              <span style={{ fontWeight: 'bold' }}>{cl.previousVersion}</span> by{'  '}
              <span style={{ fontWeight: 'bold' }}>{cl.createdBy}</span>
            </Typography>
          );
        default:
          return (
            <Typography>
              Change{' '}
              <span style={{ fontWeight: 'bold' }}>
                {cl.type}{' '}
                {cl.fieldName.includes('Id') || cl.fieldName === cl.type
                  ? ''
                  : cl.fieldName}
              </span>
              {'  '}
              from {'  '}
              <span style={{ fontWeight: 'bold' }}>{cl.previousVersion}</span> to{'  '}
              <span style={{ fontWeight: 'bold' }}>{cl.changedVersion}</span> by{'  '}
              <span style={{ fontWeight: 'bold' }}>{cl.createdBy}</span>
            </Typography>
          );
      }
    };

    return (
      <Grid container justifyContent="flex-start" direction="row">
        {timeLineContent()}
      </Grid>
    );
  };

  const renderTimelineItems = () => (
    <Timeline>
      {changeLogList &&
        changeLogList.map((cl, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent>
              <Typography color="textSecondary">
                <DateTimeDisplay value={cl.date.toString()} />
              </Typography>
              <Typography color="textSecondary">
                {moment(cl.date.toString()).fromNow()}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>{renderTimeLineContent(cl)}</TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );

  return (
    <CardContent>
      <Loading loading={loading} height={500}>
        <div style={{ maxHeight: 500, overflowX: 'hidden', overflowY: 'auto' }}>
          {renderTimelineItems()}
        </div>
      </Loading>
    </CardContent>
  );
};

export default ChangeLogProject;
