import { AppAction } from '../../../../state/app';
import { Block } from '../../../../state/block';
import {
  CREATE_OPPORTUNITY_ITEM_FROM_SEARCH_RESULT,
  RESET_SEARCH_ITEM_AND_ADD_MULTIPLE_BLOCK_STATE,
  SEARCH_FOR_ITEM,
  SET_FORCE_CREATE_ITEMS,
  CreateOpportunityItemPayload,
  CreateOpportunityItemResult,
} from './searchItemAndAddMultipleBlockDialog.actions';
import { REQUEST, SUCCESS } from '../../../../utils/actionTypeUtil';

export interface SearchItemResult {
  itemText: string;
  blocks: ReadonlyArray<Block>;
  selectedBlock?: Block;
}

export interface SearchItemAndAddMultipleBlockState {
  searchText: string;
  searchResults: ReadonlyArray<SearchItemResult>;
  createOpportunityItemPayload?: CreateOpportunityItemPayload;
  createOpportunityItemResults: ReadonlyArray<CreateOpportunityItemResult>;
  listOfForceCreateItems: ReadonlyArray<string>;
  loading: boolean;
}

const initialState: SearchItemAndAddMultipleBlockState = {
  searchText: '',
  searchResults: [],
  loading: false,
  createOpportunityItemResults: [],
  listOfForceCreateItems: [],
};

export default function(state = initialState, action: AppAction): SearchItemAndAddMultipleBlockState {
  switch (action.type) {
    case REQUEST(SEARCH_FOR_ITEM):
      return {
        ...state,
        loading: true,
        searchText: action.payload,
      };
    case SUCCESS(SEARCH_FOR_ITEM):
      return {
        ...state,
        loading: false,
        searchResults: action.payload.data,
      };
    case REQUEST(CREATE_OPPORTUNITY_ITEM_FROM_SEARCH_RESULT):
      return {
        ...state,
        loading: true,
        createOpportunityItemPayload: action.payload,
      };
    case SUCCESS(CREATE_OPPORTUNITY_ITEM_FROM_SEARCH_RESULT):
      return {
        ...state,
        loading: false,
        createOpportunityItemResults: action.payload,
      };
    case SET_FORCE_CREATE_ITEMS:
      return {
        ...state,
        listOfForceCreateItems: [...action.payload],
      };
    case RESET_SEARCH_ITEM_AND_ADD_MULTIPLE_BLOCK_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
