import React, { useState } from 'react';
import { SearchItemResult } from '../searchItemAndAddMultipleBlockDialog.reducer';
import { Link } from '@material-ui/core';
import SelectBlockDialog from './SelectBlockDialog';
import { Block } from '../../../../../state/block';
import { Program } from '../../../../../state/program';
import { Brand } from '../../../../../state/brand';

interface SearchResultSelectBlockProps {
  searchItemResult: SearchItemResult;
  onChange: (block: Block) => void;
  program: Program;
  brand: Brand;
}

const SelectBlockForSearchItemButton: React.FC<SearchResultSelectBlockProps> = props => {
  const { searchItemResult, onChange, program, brand } = props;
  const [open, setOpen] = useState(false);

  return <>
    <Link
      onClick={() => setOpen(true)}
    >
      {searchItemResult.selectedBlock ? searchItemResult.selectedBlock.blockNo : 'Choose'}
    </Link>
    <SelectBlockDialog
      open={open}
      onClose={() => setOpen(false)}
      blocks={searchItemResult.blocks}
      onSelectBlock={onChange}
      program={program}
      brand={brand}
      itemText={searchItemResult.itemText}
    />
  </>;
};

export default SelectBlockForSearchItemButton;
