import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { IEBlockGroupProduct } from '../../../models/eBlockGroupProduct';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearEBlockGroupProduct,
  EBlockGroupProductListFilter,
  EBlockGroupProductState,
  fetchEBlockGroupProductList,
} from '../../../state/eBlockGroupProduct';
import {
  DateTimeDisplay,
  PageContainer,
  PageTitle,
  SearchField,
  SortLabel,
  TableLoading,
} from '../../components';
import { useFilter, useHasRole, useTableSorting } from '../../hooks';
import roleRoute from '../roleRoute.config';
import useStyles from './eBlockGroupProduct.style';

const EBlockGroupProductList: React.FC<RouteComponentProps> = (props) => {
  const { history } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDisabled = !useHasRole(roleRoute.createEBlockGroupProduct);
  const [filters, setFilters] = useFilter<EBlockGroupProductListFilter>({});
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const [sort, setSort] = useTableSorting({
    orderBy: 'createdAt',
    direction: 'desc',
  });

  const {
    items: eBlockGroupProducts,
    totalItems,
    loading,
  } = useSelector<AppState, EBlockGroupProductState>((state) => state.eBlockGroupProduct);

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const addNewEBlockGroupProduct = (): void => {
    history.push('/settings/eBlockGroupProducts/form');
  };

  const editEBlockGroupProduct = (id: IEBlockGroupProduct['id']): void => {
    history.push(`/settings/eBlockGroupProducts/form/${id}`);
  };

  useEffect(() => {
    dispatch(fetchEBlockGroupProductList(filters, { ...paging, sort }));
    return function cleanUp() {
      dispatch(clearEBlockGroupProduct());
    };
  }, [dispatch, paging, filters, sort]);

  return (
    <PageContainer>
      <PageTitle>EBlock Group Products</PageTitle>
      <div className={classes.filterContainer}>
        <SearchField
          filterKey="Code.StartsWith"
          filters={filters}
          label="Code"
          onChange={handleChangeFilter}
        />
        <SearchField
          filterKey="Description.StartsWith"
          filters={filters}
          label="Description"
          onChange={handleChangeFilter}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={addNewEBlockGroupProduct}
          size="large"
        >
          <AddIcon className={classes.iconButton} />
          New EBlock Group Product
        </Button>
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="code">
                Code
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="description">
                Description
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="createdAt">
                CreatedAt
              </SortLabel>
            </TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={4}>
            {eBlockGroupProducts.map((eBlockGroupProduct, index) => (
              <TableRow key={eBlockGroupProduct.id}>
                <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                <TableCell>{eBlockGroupProduct.code}</TableCell>
                <TableCell>{eBlockGroupProduct.description}</TableCell>
                <TableCell>
                  <DateTimeDisplay value={eBlockGroupProduct.createdAt} />
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    disabled={isDisabled}
                    onClick={() => editEBlockGroupProduct(eBlockGroupProduct.id)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default EBlockGroupProductList;
