import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createEBlockGroupProduct,
  fetchEBlockGroupProduct,
  fetchEBlockGroupProductList,
  updateEBlockGroupProduct,
} from '../../services/eBlockGroupProductService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_EBLOCK_GROUP_PRODUCT,
  FETCH_EBLOCK_GROUP_PRODUCT,
  FETCH_EBLOCK_GROUP_PRODUCT_LIST,
  UPDATE_EBLOCK_GROUP_PRODUCT,
} from './eBlockGroupProduct.action';
import { EBlockGroupProductCreateOrUpdatePayload } from './eBlockGroupProduct.type';

const listPageUrl = `/settings/eBlockGroupProducts`;

function* fetchEBlockGroupProductListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchEBlockGroupProductList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchEBlockGroupProductSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchEBlockGroupProduct, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createEBlockGroupProductSaga(action: AppAction) {
  try {
    const { values, history } = action.payload as EBlockGroupProductCreateOrUpdatePayload;
    const successMessage = `Created ${values.code} eblock group product successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createEBlockGroupProduct, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    const formUrl = `${listPageUrl}/form/${data.id}`;
    history.replace(formUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateEBlockGroupProductSaga(action: AppAction) {
  try {
    const { values } = action.payload as EBlockGroupProductCreateOrUpdatePayload;
    const successMessage = `Updated ${values.code} eblock group product successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateEBlockGroupProduct, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchEBlockGroupProduct() {
  yield debounce(250, FETCH_EBLOCK_GROUP_PRODUCT_LIST, fetchEBlockGroupProductListSaga);
  yield takeLatest(FETCH_EBLOCK_GROUP_PRODUCT, fetchEBlockGroupProductSaga);
  yield takeLatest(CREATE_EBLOCK_GROUP_PRODUCT, createEBlockGroupProductSaga);
  yield takeLatest(UPDATE_EBLOCK_GROUP_PRODUCT, updateEBlockGroupProductSaga);
}
