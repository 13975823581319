import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { AppState } from '../../../state/configureStore';
import {
  clearSalesGroup,
  fetchSalesGroupList,
  SalesGroupState,
} from '../../../state/salesGroup';
import {
  clearSalesTeam,
  createSalesTeam,
  fetchSalesTeam,
  SalesTeam,
  SalesTeamState,
  updateSalesTeam,
} from '../../../state/salesTeam/';
import { fetchSalesTeamRoleList, SalesTeamRoleState } from '../../../state/salesTeamRole';
import { User } from '../../../state/user';
import { Loading, PageTitle } from '../../components';
import UserComboBox from '../../components/UserComboBox';
import useStyles from './salesTeamForm.style';
import SalesTeamFormDetail from './SalesTeamFormDetail';

const SalesTeamSchema = Yup.object().shape({
  name: Yup.string().nullable(true),
  description: Yup.string().nullable(true),
  salesGroupId: Yup.number().required('SalesGroup is required'),
  ownerId: Yup.number().required('Owner is required'),
});

interface SalesTeamFormRouteParamsProps {
  id: string;
}

interface SalesTeamFormProps extends RouteComponentProps<SalesTeamFormRouteParamsProps> {}

const SalesTeamForm: React.FC<SalesTeamFormProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { match, history } = props;
  const {
    params: { id: paramsId },
  } = match;
  const isUpdateMode = paramsId !== undefined;
  const { items: salesGroups, loading: salesGroupIdLoading } = useSelector<
    AppState,
    SalesGroupState
  >((state) => state.salesGroup);
  const { item: salesTeam, loading: salesTeamIsLoading } = useSelector<
    AppState,
    SalesTeamState
  >((state) => state.salesTeam);
  const { loading: salesTeamRoleIsLoading } = useSelector<AppState, SalesTeamRoleState>(
    (state) => state.salesTeamRole,
  );

  const redirectToSalesTeamList = () => {
    history.goBack();
  };

  const handleSubmit = (values: SalesTeam, actions: FormikHelpers<SalesTeam>) => {
    if (isUpdateMode) {
      dispatch(updateSalesTeam({ values, history }));
    } else {
      dispatch(createSalesTeam({ values, history }));
    }
    actions.setSubmitting(false);
  };

  useEffect(() => {
    dispatch(fetchSalesGroupList({}, { pageNumber: 0, pageSize: 99999 }));
    dispatch(fetchSalesTeamRoleList());
    return function cleanUp() {
      dispatch(clearSalesGroup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isUpdateMode) {
      dispatch(fetchSalesTeam(+paramsId));
    } else {
      dispatch(clearSalesTeam());
    }
    return function cleanUp() {
      dispatch(clearSalesTeam());
    };
  }, [isUpdateMode, paramsId, dispatch]);

  const isLoading = salesGroupIdLoading || salesTeamIsLoading || salesTeamRoleIsLoading;

  return (
    <Loading isLoading={isLoading}>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...salesTeam }}
        validationSchema={SalesTeamSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, errors, touched, submitForm, setFieldValue }) => {
          return (
            <Form>
              <Card className={classes.root}>
                <PageTitle>
                  {!isUpdateMode
                    ? `New Sales Team`
                    : `Update Sales Team: ${salesTeam.name}`}
                </PageTitle>
                <CardContent>
                  <FormControl
                    className={classes.formControl}
                    error={!!errors.salesGroupId && !!touched.salesGroupId}
                    fullWidth
                    disabled={isUpdateMode}
                  >
                    <InputLabel htmlFor="salesGroupId">Sales Group</InputLabel>
                    <Select
                      value={values.salesGroupId || ''}
                      onChange={handleChange}
                      name="salesGroupId"
                      input={<Input name="salesGroupId" id="salesGroupId" />}
                    >
                      {salesGroups
                        .filter((f) => f.active === true)
                        .map((salesGroup) => (
                          <MenuItem value={salesGroup.id} key={salesGroup.id}>
                            {salesGroup.name} {salesGroup.description}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{errors.salesGroupId}</FormHelperText>
                  </FormControl>
                  <TextField
                    name="name"
                    type="input"
                    label="Name"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={values.name || ''}
                    onChange={handleChange}
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                  />
                  <TextField
                    name="description"
                    type="input"
                    label="Description"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={values.description || ''}
                    onChange={handleChange}
                    error={!!errors.description && !!touched.description}
                    helperText={errors.description}
                  />
                  <UserComboBox
                    handleChange={(result: User) => {
                      setFieldValue('owner', result ? result : null);
                      setFieldValue('ownerId', result ? result.id : 0);
                    }}
                    selectedValue={values.owner as User}
                    label={'Owner'}
                    error={!!errors.ownerId && touched.ownerId}
                    helperText={errors.ownerId}
                  />
                </CardContent>
                <div className={classes.buttonGroup}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={submitForm}
                  >
                    <SaveIcon className={classes.leftIcon} />
                    {!isUpdateMode ? `Create` : `Update`}
                  </Button>
                  <Button
                    color="default"
                    variant="contained"
                    onClick={redirectToSalesTeamList}
                  >
                    CANCEL
                  </Button>
                </div>
              </Card>
              {isUpdateMode && <SalesTeamFormDetail salesTeamId={+paramsId} />}
            </Form>
          );
        }}
      </Formik>
    </Loading>
  );
};

export default SalesTeamForm;
