import React from 'react';
import { Grid } from '@material-ui/core';
import { FilterContainer, SearchField } from '../../components';
import { IFilterContainerConfigProps } from '../../components/FilterContainer';
import { WorkingTeamMemberListFilter } from '../../../state/workingTeamMember';

const WorkingTeamMemberFilter: React.FC<
  IFilterContainerConfigProps<WorkingTeamMemberListFilter>
> = props => {
  return (
    <FilterContainer
      {...props}
      render={filterProps => {
        const { localFilter, handleChangeFilter } = filterProps;
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="SmWorkingTeam.Name.startsWith"
                label="Team"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="User.FirstName.startsWith"
                label="First Name"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="User.LastName.startsWith"
                label="Last Name"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="WorkingTeamRole.Name.startsWith"
                label="Role"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default WorkingTeamMemberFilter;
