import React from 'react';
import { fetchBlockPrefixList } from '../../services/blockPrefixService';
import { BlockPrefix } from '../../state/blockPrefix';
import AsyncAutoComplete from './AsyncAutoComplete';

interface BlockPrefixComboBoxProps {
  handleChange: (result: BlockPrefix) => void;
  selectedValue: BlockPrefix;
  isDisabled?: boolean;
  productTypeId: number;
  error?: boolean;
  helperText?: string;
}

const BlockPrefixComboBox: React.FC<BlockPrefixComboBoxProps> = props => {
  const { handleChange, selectedValue, isDisabled = false, productTypeId, error, helperText } = props;

  const blockPrefixPromiseOptions = async (inputValue: string) => {
    const { data: rows } = await fetchBlockPrefixList({
      filters: {
        'Prefix.startsWith': inputValue,
        'ProductTypeId.equalsTo': productTypeId,
      },
      paging: { pageNumber: 0, pageSize: 10 },
    });

    return rows;
  };

  return (
    <AsyncAutoComplete
      name="BlockPrefix"
      value={selectedValue}
      getOptionValue={(option: BlockPrefix) => {
        return option.id!.toString();
      }}
      getOptionLabel={(option: BlockPrefix) => {
        return option.prefix;
      }}
      promiseOptions={blockPrefixPromiseOptions}
      placeholder={'Type Block Prefix Name'}
      handleChange={handleChange}
      isDisabled={isDisabled}
      error={error}
      helperText={helperText}
    />
  );
};

export default BlockPrefixComboBox;
