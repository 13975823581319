import {
  Button,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../state/configureStore';
import {
  OpportunityCustomerGroup,
  OpportunityCustomerGroupState,
} from '../../../../state/opportunityCustomerGroup';
import { TableLoading } from '../../../components';
import AddOpportunityCustomerGroupDialog from '../AddOpportunityCustomerGroupDialog';
import OpportunityCustomerGroupActionMenu from './OpportunityCustomerGroupActionMenu';

interface OpportunityCustomerGroupProps {
  opportunityId: number;
  companyId: number;
}

const OpportunityCustomerGroupForm: React.FC<OpportunityCustomerGroupProps> = props => {
  const { opportunityId, companyId } = props;
  const [openOppCustGrpDlg, setOpenOppCustGrpDlg] = useState(false);
  const [localOpportunityCustomerGroups, setLocalOpportunityCustomerGroups] = useState<
    OpportunityCustomerGroup[]
  >([]);
  const { items: opportunityCustomerGroups, loading: isLoading } = useSelector<
    AppState,
    OpportunityCustomerGroupState
  >(state => state.opportunityCustomerGroup);

  useEffect(() => {
    setLocalOpportunityCustomerGroups([...opportunityCustomerGroups]);
  }, [opportunityCustomerGroups]);

  return (
    <CardContent>
      <AddOpportunityCustomerGroupDialog
        open={openOppCustGrpDlg}
        onClose={() => {
          setOpenOppCustGrpDlg(false);
        }}
        opportunityId={opportunityId}
        companyId={companyId}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setOpenOppCustGrpDlg(true);
        }}
        startIcon={<AddIcon />}
      >
        Add Customer Group
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Customer Group Name</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={isLoading} colSpan={3}>
            {localOpportunityCustomerGroups.map((opportunityCustomerGroup, index) => {
              const { customerGroup } = opportunityCustomerGroup;
              return (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{customerGroup!.name}</TableCell>
                  <TableCell>
                    <OpportunityCustomerGroupActionMenu
                      oppCustGrp={opportunityCustomerGroup}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableLoading>
        </TableBody>
      </Table>
    </CardContent>
  );
};

export default OpportunityCustomerGroupForm;
