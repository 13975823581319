import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './sampleDevelopmentNl.style';

import { CheckBoxFastField, TextFieldFastField } from '../../../components';

interface SamDevNlFinishingFrontProps {}

const SampleDevelopmentNlFinishingFront: React.FC<SamDevNlFinishingFrontProps> = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2}>
          <Typography className={classes.numberTitle} display="inline">
            Finishing
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <CheckBoxFastField name="finishCoatingFront" label="Front" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2}>
          <Typography className={classes.numberTitle} display="inline">
            1. Coating
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <CheckBoxFastField name="fcfFilmLaminate" label="Laminate Film" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfFilmMatt" label="Matt" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfFilmGloss" label="Gloss" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfFilmHalogram" label="Halogram" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfFilmOther" label="Other" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2}>
          <Typography className={classes.numberTitle} display="inline" />
        </Grid>
        <Grid item xs={4}>
          <CheckBoxFastField name="fcfChemCemical" label="Chemical" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfChemWaterBase" label="Water Base" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfChemOilBase" label="Oil Base" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfChemVanish" label="UV" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfChemSpot" label="Spot" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfChemSpotMatt" label="Matt" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfChemSpotSuperMatt" label="Supper Matt" />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfChemSpotSemiGloss" label="Semi Gloss" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfChemSpotGloss" label="Gloss" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfChemSpotHighGloss" label="High Gloss" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfChemSpotTexture" label="Texture" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfChemSpotCalendering" label="Calendering" />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4} />
        <Grid item xs={1}>
          <CheckBoxFastField name="fcfChemSpotOther" label="Other" />
        </Grid>
        <Grid item xs={3}>
          <TextFieldFastField
            className={classes.textInputRightCheckboxOther}
            name="fcfChemSpotOtherData"
            label="Other"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfChemFullArea" label="Full Area" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfChemFullAreaMatt" label="Matt" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfChemFullAreaSuperMatt" label="Supper Matt" />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfChemFullAreaSemiGloss" label="Semi Gloss" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfChemFullAreaGloss" label="Gloss" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfChemFullAreaHighGloss" label="High Gloss" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4} />
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfChemFullAreaTexture" label="Texture" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfChemFullAreaCalendering" label="Calendering" />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4} />
        <Grid item xs={1}>
          <CheckBoxFastField name="fcfChemFullAreaOther" label="Other" />
        </Grid>
        <Grid item xs={3}>
          <TextFieldFastField
            className={classes.textInputRightCheckboxOther}
            name="fcfChemFullAreaOtherData"
            label="Other"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfBlister" label="Blister" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfBlisterPet" label="PET" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfBlisterPvc" label="PVC" />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfSilkscreen" label="Silkscreen" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfSilkFullArea" label="Full Area" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfSilkSpot" label="Spot" />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfSilkMatt" label="Matt" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcfSilkGloss" label="Gloss" />
        </Grid>
        <Grid item xs={1}>
          <CheckBoxFastField name="fcfSilkOthers" label="Other" />
        </Grid>
        <Grid item xs={3}>
          <TextFieldFastField
            className={classes.textInputRightCheckboxOther}
            name="fcfSilkOthersData"
            label="Other"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2}>
          <Typography className={classes.numberTitle} display="inline">
            2. Die-Cut
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <CheckBoxFastField name="dieCutFront" label="Front" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={3} />
        <Grid item xs={3}>
          <CheckBoxFastField name="dieCutFHold" label="ปั๊มรู (Hold)" />
        </Grid>
        <Grid item xs={3}>
          <CheckBoxFastField name="dieCutFPerforate" label="ปั๊มปรุ (Perforate)" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={3} />
        <Grid item xs={3}>
          <CheckBoxFastField name="dieCutFShape" label="ปั๊มตามรูป (Shape)" />
        </Grid>
        <Grid item xs={3}>
          <CheckBoxFastField name="dieCutFFoldLine" label="ปั๊มพับ (Fold Line)" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}>
          <CheckBoxFastField name="dieCutFEmboss" label="ปั๊มนูน (Emboss)" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SampleDevelopmentNlFinishingFront;
