import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { get } from 'lodash';
import { IProjectProgram } from '../../../models/projectProgram.model';
import ExpandableLabel from '../../components/ExpandableLabel';
import { flattenDeep } from 'lodash';

export interface ProjectProgramExpandableProps {
  programNames: string;
  projectPrograms: IProjectProgram[];
}

const ProjectProgramExpandable: React.FC<ProjectProgramExpandableProps> = props => {
  const { programNames, projectPrograms } = props;

  return (
    <ExpandableLabel
      label={programNames}
      content={
        <Table style={{ minWidth: 600 }}>
          <TableHead>
            <TableRow>
              <TableCell>Program ({projectPrograms.length})</TableCell>
              <TableCell>
                Brand (
                {flattenDeep(projectPrograms.map(p => p.smProjectProgramBrands)).length})
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projectPrograms.map(pp => {
              let program = get(pp, 'program.name', '');
              return pp.smProjectProgramBrands!.map((pb, index) => {
                const needToDisplayProgram = program ? (
                  <TableCell>
                    <Typography variant="h6">{program}</Typography>
                  </TableCell>
                ) : (
                  <TableCell />
                );
                program = null;
                return (
                  <TableRow key={index} hover>
                    {needToDisplayProgram}
                    <TableCell>
                      <Typography variant="h6">{get(pb, 'brand.name', '')}</Typography>
                    </TableCell>
                  </TableRow>
                );
              });
            })}
          </TableBody>
        </Table>
      }
    />
  );
};

export default ProjectProgramExpandable;
