import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PageFilter } from '../../../state/app';
import { fetchCompanyList, clearCompany } from '../../../state/company';
import { Company, CompanyListFilter, CompanyState } from '../../../state/company';
import { AppState } from '../../../state/configureStore';
import useStyles from './companyList.style';
import { PageContainer, PageTitle, SearchField, TableLoading } from '../../components';

const CompanyList: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const classes = useStyles();
  const [filters, setFilters] = useState<CompanyListFilter>({});
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const {
    items: companies,
    totalItems,
    loading,
  } = useSelector<AppState, CompanyState>((state) => state.company);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchCompanyList(filters, paging));
    return function cleanUp() {
      dispatch(clearCompany());
    };
  }, [dispatch, paging, filters]);

  const addNewCompany = (): void => {
    history.push('/settings/companies/form');
  };

  const editCompany = (id: Company['id']): void => {
    history.push(`/settings/companies/form/${id}`);
  };

  return (
    <PageContainer>
      <PageTitle>Company</PageTitle>
      <div className={classes.filterContainer}>
        <SearchField
          filterKey="Name.startsWith"
          label="Name"
          filters={filters}
          onChange={handleChangeFilter}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={addNewCompany}
          size="large"
        >
          <AddIcon className={classes.iconButton} />
          New Company
        </Button>
      </div>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCellHeader}>No.</TableCell>
            <TableCell className={classes.tableCellHeader}>Name</TableCell>
            <TableCell className={classes.tableCellHeader}>ExternalId</TableCell>
            <TableCell className={classes.tableCellHeader}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={4}>
            {companies.map((company, index) => (
              <TableRow key={company.id}>
                <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                <TableCell>{company.name}</TableCell>
                <TableCell>{company.externalId}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => editCompany(company.id)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default CompanyList;
