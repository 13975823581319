import { Grid, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import ReactLoading from 'react-loading';

interface TableLoadingProps {
  isLoading: boolean;
  colSpan: number;
  fixHeight?: number;
}

const TableLoading: React.FC<TableLoadingProps> = (props) => {
  const { isLoading, children, colSpan, fixHeight = 500 } = props;
  if (isLoading) {
    return (
      <TableRow style={{ height: fixHeight }}>
        <TableCell colSpan={colSpan}>
          <Grid container justifyContent="center" alignItems="center">
            <ReactLoading type="bubbles" color="#3F51B5" />
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
};

export default TableLoading;
