import { IEBlockGroupProduct } from '../../models/eBlockGroupProduct';
import { AppAction, PageFilter } from '../app';
import {
  EBlockGroupProductCreateOrUpdatePayload,
  EBlockGroupProductListFilter,
} from './eBlockGroupProduct.type';

export const FETCH_EBLOCK_GROUP_PRODUCT = 'FETCH_EBLOCK_GROUP_PRODUCT';
export const FETCH_EBLOCK_GROUP_PRODUCT_LIST = 'FETCH_EBLOCK_GROUP_PRODUCT_LIST';
export const CREATE_EBLOCK_GROUP_PRODUCT = 'CREATE_EBLOCK_GROUP_PRODUCT';
export const UPDATE_EBLOCK_GROUP_PRODUCT = 'UPDATE_EBLOCK_GROUP_PRODUCT';
export const CLEAR_EBLOCK_GROUP_PRODUCT = 'CLEAR_EBLOCK_GROUP_PRODUCT';

export const fetchEBlockGroupProduct = (id: IEBlockGroupProduct['id']): AppAction => ({
  type: FETCH_EBLOCK_GROUP_PRODUCT,
  payload: id,
});

export const fetchEBlockGroupProductList = (
  filters: EBlockGroupProductListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_EBLOCK_GROUP_PRODUCT_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createEBlockGroupProduct = (
  payload: EBlockGroupProductCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_EBLOCK_GROUP_PRODUCT,
  payload,
});

export const updateEBlockGroupProduct = (
  payload: EBlockGroupProductCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_EBLOCK_GROUP_PRODUCT,
  payload,
});

export const clearEBlockGroupProduct = () => ({
  type: CLEAR_EBLOCK_GROUP_PRODUCT,
});
