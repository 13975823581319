import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_SALESTEAM,
  CREATE_SALESTEAM,
  FETCH_SALESTEAM,
  FETCH_SALESTEAM_LIST,
  UPDATE_SALESTEAM,
} from './salesTeam.actions';
import { SalesTeam, SalesTeamState } from './salesTeam.types';

const getInitialSalesTeamState = (): Readonly<SalesTeam> => ({
  salesTeamMembers: [],
});

const initialState: SalesTeamState = {
  errorMessage: '',
  item: getInitialSalesTeamState(),
  items: [] as ReadonlyArray<SalesTeam>,
  loading: false,
  totalItems: 0,
};

export default (
  state: SalesTeamState = initialState,
  action: AppAction,
): SalesTeamState => {
  switch (action.type) {
    case REQUEST(FETCH_SALESTEAM_LIST):
    case REQUEST(CREATE_SALESTEAM):
    case REQUEST(FETCH_SALESTEAM):
    case REQUEST(UPDATE_SALESTEAM):
      return { ...state, loading: true };
    case SUCCESS(FETCH_SALESTEAM_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_SALESTEAM_LIST):
    case FAILURE(CREATE_SALESTEAM):
    case FAILURE(FETCH_SALESTEAM):
    case FAILURE(UPDATE_SALESTEAM):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_SALESTEAM):
    case SUCCESS(FETCH_SALESTEAM):
    case SUCCESS(UPDATE_SALESTEAM):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_SALESTEAM:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
