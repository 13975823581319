import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useState } from 'react';

export interface ExpandableLabelProps {
  label: React.ReactNode;
  content: React.ReactNode;
}

const ExpandableLabel: React.FC<ExpandableLabelProps> = (props) => {
  const { label, content } = props;
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Link href="#" onClick={() => setOpen(true)}>
              {label}
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)} scroll="paper" maxWidth="xl">
        <DialogTitle></DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions style={{ padding: 15 }}>
          <Grid container justifyContent="center" alignItems="center">
            <Button
              startIcon={<Close />}
              variant="contained"
              color="secondary"
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExpandableLabel;
