import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Theme,
  createStyles,
  Radio,
} from '@material-ui/core';
import { SearchField, TableLoading } from '../components';
import {
  SalesTeamMemberListFilter,
  SalesTeamMember,
  SalesTeamMemberState,
  clearSalesTeamMember,
  fetchSalesTeamMemberList,
} from '../../state/salesTeamMember';
import { PageFilter } from '../../state/app';
import { AppState } from '../../state/configureStore';
import { createResponsibilitySalesTeamMembers } from '../../state/responsibilitySalesTeamMember';
import { TablePageSize } from '../../constants';
import { uniqBy } from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      minHeight: '53vh',
    },
    filterContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

interface SalesTeamMemberDialogSearchProps {
  open: boolean;
  handleClose: (res: any) => void;
}

const initialPaging = { pageNumber: 0, pageSize: TablePageSize[0] };

const SalesTeamMemberDialogSearch: React.FC<SalesTeamMemberDialogSearchProps> = (
  props,
) => {
  const { open, handleClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    items: SalesTeamMemberList,
    totalItems,
    loading,
  } = useSelector<AppState, SalesTeamMemberState>((state) => state.salesTeamMember);
  const [filters, setFilters] = useState<SalesTeamMemberListFilter>({});
  const [paging, setPaging] = useState<PageFilter>(initialPaging);
  const [selectedSalesTeamMember, setSelectedSalesTeamMember] = useState<SalesTeamMember>(
    {},
  );

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const resetForm = () => {
    handleClose(undefined);
    setSelectedSalesTeamMember({});
    setPaging({ ...initialPaging });
    setFilters({});
  };

  useEffect(() => {
    dispatch(fetchSalesTeamMemberList(filters, paging));
    return function cleanUp() {
      dispatch(clearSalesTeamMember());
    };
  }, [dispatch, paging, filters]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth={true}
      disableBackdropClick={true}
    >
      <DialogTitle>Select SalesTeam Member</DialogTitle>
      <DialogContent>
        <div className={classes.filterContainer}>
          <SearchField
            label="Team"
            onChange={handleChangeFilter}
            filterKey="SalesTeam.Name.startsWith"
            filters={filters}
          />
          <SearchField
            label="Name"
            onChange={handleChangeFilter}
            filterKey="User.FullNameWithUserName.Contains"
            filters={filters}
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox"></TableCell>
              <TableCell>No.</TableCell>
              <TableCell>Sales Group</TableCell>
              <TableCell>Team</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={10}>
              {SalesTeamMemberList.map((salesTeamMember, index) => {
                return (
                  <TableRow key={salesTeamMember.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        name=""
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked,
                        ) => {
                          if (checked) {
                            setSelectedSalesTeamMember(salesTeamMember);
                          } else {
                            setSelectedSalesTeamMember({});
                          }
                        }}
                        checked={selectedSalesTeamMember.id === salesTeamMember.id}
                      />
                    </TableCell>
                    <TableCell>
                      {index + paging.pageSize * paging.pageNumber + 1}
                    </TableCell>
                    <TableCell>{salesTeamMember?.salesTeam?.salesGroup?.name}</TableCell>
                    <TableCell>{salesTeamMember?.salesTeam?.name}</TableCell>
                    <TableCell>{salesTeamMember?.user?.fullNameWithUserName}</TableCell>
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[...TablePageSize]}
          component="div"
          count={totalItems}
          rowsPerPage={paging.pageSize}
          page={paging.pageNumber}
          onPageChange={handleChangePageNumber}
          onRowsPerPageChange={handleChangePageSize}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (setSelectedSalesTeamMember) {
              setSelectedSalesTeamMember({});
              handleClose(selectedSalesTeamMember);
            }
          }}
          color="primary"
          variant="contained"
        >
          Confirm
        </Button>
        <Button
          onClick={() => {
            resetForm();
          }}
          color="default"
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SalesTeamMemberDialogSearch;
