import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_PROJECT_APPROVAL_REQUEST,
  CREATE_PROJECT_APPROVAL_REQUEST,
  FETCH_PROJECT_APPROVAL_REQUEST,
  FETCH_PROJECT_APPROVAL_REQUEST_LIST,
  UPDATE_PROJECT_APPROVAL_REQUEST,
  UPDATE_PROJECT_APPROVAL_RESPONSE,
  OPEN_PROJECT_APPROVAL_REQUEST_DIALOG,
  CLOSE_PROJECT_APPROVAL_REQUEST_DIALOG,
} from './projectApprovalRequest.actions';
import {
  ProjectApprovalRequest,
  ProjectApprovalRequestState,
} from './projectApprovalRequest.types';

const getInitialProjectApprovalRequestState = (): ProjectApprovalRequest => ({
  id: 0,
  hasResponded: false,
  project: {},
  requestedByUser: {
    id: 0,
    firstName: '',
    lastName: '',
    activated: true,
    email: '',
    login: '',
  },
  comment: '',
});

const initialState: ProjectApprovalRequestState = {
  errorMessage: '',
  item: getInitialProjectApprovalRequestState(),
  items: [],
  loading: false,
  totalItems: 0,
  openDialog: false,
};

export default (
  state: ProjectApprovalRequestState = initialState,
  action: AppAction,
): ProjectApprovalRequestState => {
  switch (action.type) {
    case REQUEST(FETCH_PROJECT_APPROVAL_REQUEST_LIST):
    case REQUEST(CREATE_PROJECT_APPROVAL_REQUEST):
    case REQUEST(FETCH_PROJECT_APPROVAL_REQUEST):
    case REQUEST(UPDATE_PROJECT_APPROVAL_REQUEST):
    case REQUEST(UPDATE_PROJECT_APPROVAL_RESPONSE):
      return { ...state, loading: true };
    case SUCCESS(FETCH_PROJECT_APPROVAL_REQUEST_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_PROJECT_APPROVAL_REQUEST_LIST):
    case FAILURE(CREATE_PROJECT_APPROVAL_REQUEST):
    case FAILURE(FETCH_PROJECT_APPROVAL_REQUEST):
    case FAILURE(UPDATE_PROJECT_APPROVAL_REQUEST):
    case FAILURE(UPDATE_PROJECT_APPROVAL_RESPONSE):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_PROJECT_APPROVAL_REQUEST):
    case SUCCESS(FETCH_PROJECT_APPROVAL_REQUEST):
    case SUCCESS(UPDATE_PROJECT_APPROVAL_REQUEST):
      return { ...state, loading: false, item: action.payload.data };
    case SUCCESS(UPDATE_PROJECT_APPROVAL_RESPONSE):
      return { ...state, loading: false };
    case CLEAR_PROJECT_APPROVAL_REQUEST:
      return {
        ...state,
        ...initialState,
      };
    case OPEN_PROJECT_APPROVAL_REQUEST_DIALOG:
      return {
        ...state,
        openDialog: true,
      };
    case CLOSE_PROJECT_APPROVAL_REQUEST_DIALOG:
      return {
        ...state,
        openDialog: false,
      };
    default:
      return state;
  }
};
