import { Button, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ISampleDevelopmentPl } from '../../../../models/sampleDevelopmentPl.model';

import { Save as SaveIcon, Send as SendIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import SampleDevelopmentStatus from '../../../../constants/sampleDevelopmentStatus';
import { AppState } from '../../../../state/configureStore';
import {
  createSampleDevelopmentPl,
  downloadSampleDevelopmentPl,
  fetchSampleDevelopmentPl,
  SampleDevelopmentPlState,
  submitSampleDevelopmentPlForApproval,
  updateSampleDevelopmentPl,
  clearSampleDevelopmentPl,
} from '../../../../state/sampleDevelopmentPl';
import {
  Loading,
  PageContainer,
  PageTitle,
  PageTopMenuContainer,
} from '../../../components';
import SampleDevelopmentPlHead from './SampleDevelopmentPlHead';
import SampleDevelopmentPlJob from './SampleDevelopmentPlJob';
import SampleDevelopmentPlDesign from './SampleDevelopmentPlDesign';
import SampleDevelopmentPlMaterial from './SampleDevelopmentPlMaterial';
import SampleDevelopmentPlFinishing from './SampleDevelopmentPlFinishing';
import SampleDevelopmentPlAssembly from './SampleDevelopmentPlAssembly';
import SampleDevelopmentPlDetail from './SampleDevelopmentPlDetail';
import SampleDevelopmentPlPacking from './SampleDevelopmentPlPacking';
import SampleDevelopmentPlSpecialInstruction from './SampleDevelopmentPlSpecialInstruction';
import SampleDevelopmentPlQuotation from './SampleDevelopmentPlQuotation';
import SampleDevelopmentPlLaw from './SampleDevelopmentPlLaw';
import SampleDevelopmentPlSpecial from './SampleDevelopmentPlSpecial';
import SampleDevelopmentPlFilePath from './SampleDevelopmentPlFilePath';
import SampleDevelopmentPlAlfrescoLink from './SampleDevelopmentPlAlfrescoLink';
import moment from 'moment';

const statusAllowForSubmitForApproval = [
  SampleDevelopmentStatus.PENDING_FOR_APPROVAL,
  SampleDevelopmentStatus.REJECTED,
];

interface SampleDevelopmentPlFormProps {
  id: string;
}

const SampleDevelopmentPlSchema = Yup.object().shape({
  customer: Yup.object().nullable(true).required('Customer is required'),
  block: Yup.object().nullable(true).required('Block is required'),
  smTask: Yup.object().nullable(true).required('Task is required'),
  salesTeamMember: Yup.object().nullable(true).required('SalesTeamMember is required'),
});

const SampleDevelopmentPlForm: React.FC<SampleDevelopmentPlFormProps> = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: routeParamsId } = useParams<{
    id: string;
  }>();
  const sampleDevelopmentPlId = id ? id : routeParamsId;
  const isUpdateMode = sampleDevelopmentPlId !== undefined;
  const { item: sampleDevelopmentPl, loading: isLoading } = useSelector<
    AppState,
    SampleDevelopmentPlState
  >((s) => s.sampleDevelopmentPl);

  const redirectToSampleDevelopmentPlList = () => {
    history.goBack();
  };

  const handleSubmit = (values: ISampleDevelopmentPl) => {
    if (isUpdateMode) {
      dispatch(updateSampleDevelopmentPl({ values }));
    } else {
      dispatch(createSampleDevelopmentPl({ values }));
    }
  };

  useEffect(() => {
    if (sampleDevelopmentPlId) {
      dispatch(fetchSampleDevelopmentPl(+sampleDevelopmentPlId));
    }
    return function cleanUp() {
      dispatch(clearSampleDevelopmentPl());
    };
  }, [sampleDevelopmentPlId, dispatch]);

  return (
    <PageContainer>
      <Formik
        enableReinitialize={true}
        initialValues={{
          customer: null,
          smTask: null,
          block: null,
          salesTeamMember: null,
          dueDate: moment().format('YYYY-MM-DD'),
          seeModel: moment().format('YYYY-MM-DD'),
          quantity: 0,
          quantityUnit: 'PCS',
          jobTypePL: false,
          jobTypeSL: false,
          jobTypePTM: false,
          jobTypeSTM: false,
          jobDescLetterPress: false,
          jobDescOffset: false,
          jobDescFlexo: false,
          jobDescSilkScreen: false,
          jobDescThermal: false,
          jobDescHotStamping: false,
          jobDescOther: false,
          jobDescOtherData: '',
          ownDesign: false,
          customerDesign: false,
          designSample: false,
          designOriginal: false,
          designArtwork: false,
          designZip: false,
          designCD: false,
          designFilm: false,
          designMail: false,
          designFile: false,
          designTIF: false,
          designEPS: false,
          designJPG: false,
          designPDF: false,
          designAI: false,
          designQXP: false,
          designSoftware: false,
          designAcrobat: false,
          designAcrobatData: '',
          designIllustrater: false,
          designIllustraterData: '',
          designPageMaker: false,
          designPageMakerData: '',
          designPhotoshop: false,
          designPhotoshopData: '',
          designFreeHand: false,
          designFreeHandData: '',
          designQuark: false,
          designQuarkData: '',
          designOther: false,
          designOtherData: '',
          finishSizeHigh: 0,
          finishSizeUnit: 'MM',
          colorProcess: false,
          colorSolid: false,
          colorCoated: false,
          colorUncoated: false,
          colorTextile: false,
          colorFoil: false,
          colorPastel: false,
          colorSolidProcess: false,
          colorMetalic: false,
          frontColorQuantity: 0,
          frontColorProcess: false,
          frontColorSolid: false,
          frontColorSolidData: '',
          frontColorPMS: false,
          frontColorPMSData: '',
          backColorQuantity: 0,
          backColorProcess: false,
          backColorSolid: false,
          backColorSolidData: '',
          backColorPT: false,
          backColorPTData: '',
          colorSizeChart: '',
          coatingChemical: false,
          coatingSoft: false,
          coatingMedium: false,
          coatingHard: false,
          cutTypeHeat: false,
          cutTypeUltrasonic: false,
          cutTypeMosonic: false,
          cutTypeLaser: false,
          cutTypeStraight: false,
          cutTypeCenter: false,
          cutTypeBooklet: false,
          cutTypeEmd: false,
          cutTypeMitre: false,
          packingPiece: false,
          packingFold: false,
          packingRoll: false,
          packingTape: false,
          packingFoldHead: false,
          packingFoldHalf: false,
          packingWishbone: false,
          packingSheet: false,
          accessoriesLaminet: false,
          accessoriesVerane: false,
          accessoriesAdhesive: false,
          variableAmount: 0,
          variableSize: false,
          variableKeySize: false,
          variableStyleNo: false,
          variableColor: false,
          variableFabricContent: false,
          variableInstruction: false,
          variableWashingSymbol: false,
          variableArt: false,
          variableFactoryCode: false,
          variableCountry: false,
          variableBarcode: false,
          variablePrice: false,
          variableOther: false,
          variableOtherData: '',
          packingPlain: false,
          packingSpecial: false,
          packingSpecialData: '',
          specialInstruction: '',
          withoutQuotation: false,
          quotationType: false,
          quotationTypeO: false,
          quotationTypeOQty: 0,
          quotationTypeOCurrency: 'THB',
          quotationTypeM: false,
          quotationTypeMQty: 0,
          quotationTypeMCurrency: 'THB',
          quotationTypeP: false,
          quotationTypePQty: 0,
          quotationTypePCurrency: 'THB',
          quotationUnit: 'PCS',
          quotationQty: 0,
          quotation: false,
          quotationNumber: '',
          withoutLaw: false,
          haveLaw: false,
          lawFormaldehyde: false,
          lawPentachlorophenol: false,
          lawNickel: false,
          lawAzoDyes: false,
          lawChromium: false,
          lawTrademarks: false,
          lawCodeOfConduct: false,
          lawFSC: false,
          topProductLevel: false,
          intermediateProductLevel: false,
          lowerProductLevel: false,
          specialMaterial: '',
          specialColor: '',
          specialDesign: '',
          specialMainProduction: '',
          specialFinishing: '',
          specialQC: '',
          specialPacking: '',
          specialOther: '',
          labelDiUnit: 'MM',
          sampleDevelopmentPlSubstrates: [],
          labelDiWidth: 0,
          labelDiLength: 0,
          finishSizeWidth: 0,
          finishSizeLength: 0,
          smockingStitch: false,
          jobType: 'SAMPLE',
          orderCompany: null,
          orderCompanyId: 0,
          substratePrimaryOption: '1',
          substrateOther: '',
          filePath: '',
          accessoriesVanish: false,
          cutTypeCool: false,
          cutTypeDieCut: false,
          alfrescoLink: '',
          ...sampleDevelopmentPl,
        }}
        validationSchema={SampleDevelopmentPlSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        isInitialValid={true}
      >
        {({ values, handleChange, errors, submitForm, setFieldValue }) => {
          return (
            <Form>
              <PageTitle>
                {isUpdateMode
                  ? `Update Sample Development PL ${values.sampleDevNumber}`
                  : `New Sample Development PL`}
              </PageTitle>
              <PageTopMenuContainer>
                {statusAllowForSubmitForApproval.includes(
                  sampleDevelopmentPl.status!,
                ) && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      dispatch(
                        submitSampleDevelopmentPlForApproval(sampleDevelopmentPl.id!),
                      );
                    }}
                    startIcon={<SendIcon />}
                  >
                    {`Submit for Approval`}
                  </Button>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  type="submit"
                >
                  {isUpdateMode ? `Update` : `Create`}
                </Button>
                <Button variant="contained" onClick={redirectToSampleDevelopmentPlList}>
                  Cancel
                </Button>
                {isUpdateMode && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      dispatch(
                        downloadSampleDevelopmentPl(parseInt(sampleDevelopmentPlId)),
                      );
                    }}
                  >
                    Download PDF
                  </Button>
                )}
              </PageTopMenuContainer>
              <Loading isLoading={isLoading}>
                <CardContent>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={12}
                  >
                    <SampleDevelopmentPlHead
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={12}
                  >
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      item
                      xs={12}
                    >
                      <Paper variant="outlined">
                        <CardContent>
                          <Grid item xs={12}>
                            <Typography>Product Info</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentPlJob />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentPlDesign />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentPlMaterial
                              handleChange={handleChange}
                              errors={errors}
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentPlFinishing />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentPlAssembly />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentPlDetail />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentPlPacking />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentPlSpecialInstruction />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentPlQuotation />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentPlLaw />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentPlSpecial />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentPlAlfrescoLink />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentPlFilePath />
                          </Grid>
                        </CardContent>
                      </Paper>
                    </Grid>
                  </Grid>
                </CardContent>
              </Loading>
            </Form>
          );
        }}
      </Formik>
    </PageContainer>
  );
};

export default SampleDevelopmentPlForm;
