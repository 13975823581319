import { AppAction, PageFilter } from '../../../../state/app';
import { call, debounce, put, select } from 'redux-saga/effects';
import { FAILURE, REQUEST, SUCCESS } from '../../../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../../components/Notification';
import { FETCH_PENDING_SUBTASK_LIST } from './pendingTaskList.actions';
import { AppState } from '../../../../state/configureStore';
import { SubtaskListFilter } from '../../../../state/subtask';
import {
  fetchSubtaskList,
  fetchSubtaskReferenceList,
} from '../../../../services/subtaskService';

function* fetchPendingSubtaskListSaga(action: AppAction) {
  const filters = yield getCurrentUserFilter();
  try {
    yield put({ type: REQUEST(action.type) });
    const { paging } = action.payload as { paging: PageFilter };
    const response = yield call(fetchSubtaskReferenceList, { filters, paging });
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* getCurrentUserFilter(): Generator<unknown, SubtaskListFilter, any> {
  const { user } = yield select((state: AppState) => state.auth);
  return {
    'AssigneeId.EqualsTo': user.id,
    'Status.NotEqualsTo': 'DONE',
  } as SubtaskListFilter;
}

export default function* watchPendingTaskList() {
  yield debounce(250, FETCH_PENDING_SUBTASK_LIST, fetchPendingSubtaskListSaga);
}
