import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createTaskItem,
  fetchTaskItem,
  fetchTaskItemList,
  updateTaskItem,
  deleteTaskItem,
} from '../../services/taskItemService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_TASK_ITEM,
  CREATE_TASK_ITEMS,
  FETCH_TASK_ITEM,
  FETCH_TASK_ITEM_LIST,
  UPDATE_TASK_ITEM,
  DELETE_TASK_ITEM,
} from './taskItem.actions';
import { TaskItemCreateOrUpdatePayload } from './taskItem.types';
import { ITaskItem, ICreatedTaskItem } from '../../models/taskItem.model';

function* fetchTaskItemSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchTaskItem, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchTaskItemListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchTaskItemList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createTaskItemSaga(action: AppAction) {
  try {
    const { values } = action.payload as TaskItemCreateOrUpdatePayload;
    const successMessage = `Created taskItem successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createTaskItem, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createTaskItemsSaga(action: AppAction) {
  const taskItems = action.payload as ITaskItem[];
  yield put({ type: REQUEST(action.type) });
  const result: ICreatedTaskItem[] = [];

  for (const taskItem of taskItems) {
    try {
      const { data } = yield call(createTaskItem, taskItem as ITaskItem);
      result.push({ ...data, status: 'Success' });
    } catch (error) {
      result.push({
        ...taskItem,
        status: 'Error',
        errorMessage: error.response.data.message,
        hasError: true,
      });
    }
  }
  yield put({
    type: SUCCESS(action.type),
    payload: { data: result },
  });
  const successMessage = `Created taskItems successfully.`;
  yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  const callback = action.callback;
  if (callback) {
    callback();
  }
}

function* updateTaskItemSaga(action: AppAction) {
  try {
    const { values } = action.payload as TaskItemCreateOrUpdatePayload;
    const successMessage = `Updated taskItem successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateTaskItem, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteTaskItemSaga(action: AppAction) {
  const { payload } = action;
  const { id, smTaskId } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(deleteTaskItem, id);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Deleted item successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield call(fetchTaskItemListSaga, {
      type: FETCH_TASK_ITEM_LIST,
      payload: {
        filters: { 'SMTaskId.EqualsTo': smTaskId },
        paging: { pageNumber: 0, pageSize: 99999 },
      },
    });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchTaskItem() {
  yield takeLatest(FETCH_TASK_ITEM, fetchTaskItemSaga);
  yield debounce(250, FETCH_TASK_ITEM_LIST, fetchTaskItemListSaga);
  yield takeLatest(CREATE_TASK_ITEM, createTaskItemSaga);
  yield takeLatest(CREATE_TASK_ITEMS, createTaskItemsSaga);
  yield takeLatest(UPDATE_TASK_ITEM, updateTaskItemSaga);
  yield takeLatest(DELETE_TASK_ITEM, deleteTaskItemSaga);
}
