import { IResponsibilityBrand } from '../../models/responsibilityBrand.model';
import { AppAction, PageFilter, AppCallback } from '../app';
import {
  ResponsibilityBrandCreateOrUpdatePayload,
  ResponsibilityBrandListFilter,
  ResponsibilityBrandReplacePayLoad,
} from './responsibilityBrand.types';

export const FETCH_RESPONSIBILITY_BRAND = 'FETCH_RESPONSIBILITY_BRAND';
export const FETCH_RESPONSIBILITY_BRAND_LIST = 'FETCH_RESPONSIBILITY_BRAND_LIST';
export const CREATE_RESPONSIBILITY_BRAND = 'CREATE_RESPONSIBILITY_BRAND';
export const UPDATE_RESPONSIBILITY_BRAND = 'UPDATE_RESPONSIBILITY_BRAND';
export const CLEAR_RESPONSIBILITY_BRAND = 'CLEAR_RESPONSIBILITY_BRAND';
export const DELETE_RESPONSIBILITY_BRAND = 'DELETE_RESPONSIBILITY_BRAND';
export const CREATE_RESPONSIBILITY_BRANDS = 'CREATE_RESPONSIBILITY_BRANDS';
export const REPLACE_RESPONSIBILITY_BRAND = 'REPLACE_RESPONSIBILITY_BRAND';
export const INACTIVE_RESPONSIBILITY_BRAND = 'INACTIVE_RESPONSIBILITY_BRAND';

export const fetchResponsibilityBrand = (id: IResponsibilityBrand['id']): AppAction => ({
  type: FETCH_RESPONSIBILITY_BRAND,
  payload: id,
});

export const fetchResponsibilityBrandList = (
  filters: ResponsibilityBrandListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_RESPONSIBILITY_BRAND_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchResponsibilityBrandListByresponsibilityId = (
  responsibilityId: number,
): AppAction => ({
  type: FETCH_RESPONSIBILITY_BRAND_LIST,
  payload: {
    filters: { 'responsibilityId.equalsTo': responsibilityId },
    paging: { pageNumber: 0, pageSize: 99999 },
  },
});

export const createResponsibilityBrand = (
  payload: ResponsibilityBrandCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_RESPONSIBILITY_BRAND,
  payload,
});

export const createResponsibilityBrands = (
  payload: any,
  callback?: AppCallback,
): AppAction => ({
  type: CREATE_RESPONSIBILITY_BRANDS,
  payload,
  callback,
});

export const updateResponsibilityBrand = (
  payload: ResponsibilityBrandCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_RESPONSIBILITY_BRAND,
  payload,
});

export const clearResponsibilityBrand = () => ({
  type: CLEAR_RESPONSIBILITY_BRAND,
});

export const deleteResponsibilityBrand = (id: number) => ({
  type: DELETE_RESPONSIBILITY_BRAND,
  payload: { id },
});

export const replaceResponsibilityBrand = (
  payload: ResponsibilityBrandReplacePayLoad,
) => ({
  type: REPLACE_RESPONSIBILITY_BRAND,
  payload,
});

export const inactiveResponsibilityBrand = (id: number) => ({
  type: INACTIVE_RESPONSIBILITY_BRAND,
  payload: { id },
});
