import { ISquad } from '../../models/squad.model';
import { AppAction, PageFilter } from '../app';
import {
  SquadWorkingMember,
  SquadWorkingMemberCreateOrUpdatePayload,
  SquadWorkingMemberListFilter,
} from './squadWorkingMember.type';

export const FETCH_SQUAD_WORKING_MEMBER = 'FETCH_SQUAD_WORKING_MEMBER';
export const FETCH_SQUAD_WORKING_MEMBER_LIST = 'FETCH_SQUAD_WORKING_MEMBER_LIST';
export const CREATE_SQUAD_WORKING_MEMBER = 'CREATE_SQUAD_WORKING_MEMBER';
export const CREATE_SQUAD_WORKING_MEMBERS = 'CREATE_SQUAD_WORKING_MEMBERS';
export const UPDATE_SQUAD_WORKING_MEMBER = 'UPDATE_SQUAD_WORKING_MEMBER';
export const CLEAR_SQUAD_WORKING_MEMBER = 'CLEAR_SQUAD_WORKING_MEMBER';
export const DELETE_SQUAD_WORKING_MEMBER = 'DELETE_SQUAD_WORKING_MEMBER';

export const fetchSquadWorkingMemberList = (
  filters: SquadWorkingMemberListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SQUAD_WORKING_MEMBER_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchSquadWorkingMemberListBySmSquadId = (smSquadId: number) => {
  return fetchSquadWorkingMemberList(
    { 'SmSquadId.EqualsTo' : smSquadId },
    { pageNumber: 0, pageSize: 9999 },
  );
};

export const fetchSquadWorkingMember = (id: SquadWorkingMember['id']): AppAction => ({
  type: FETCH_SQUAD_WORKING_MEMBER,
  payload: id,
});

export const createSquadWorkingMember = (
  payload: SquadWorkingMemberCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_SQUAD_WORKING_MEMBER,
  payload,
});

export const createSquadWorkingMembers = (payload: SquadWorkingMember[]): AppAction => ({
  type: CREATE_SQUAD_WORKING_MEMBERS,
  payload,
});

export const updateSquadWorkingMember = (
  payload: SquadWorkingMemberCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_SQUAD_WORKING_MEMBER,
  payload,
});

export const clearSquadWorkingMember = () => ({
  type: CLEAR_SQUAD_WORKING_MEMBER,
});

export const deleteSquadWorkingMember = (
  smSquadWorkingMemberId: SquadWorkingMember['id'],
  smSquadId: ISquad['id'],
): AppAction => ({
  type: DELETE_SQUAD_WORKING_MEMBER,
  payload: { smSquadWorkingMemberId, smSquadId },
});
