import { PageFilter } from '../state/app';
import { OpportunityCustomerListFilter } from '../state/opportunityCustomer';
import axios from '../utils/axios';
import { IOpportunityCustomer } from '../models/opportunityCustomer.model';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';

const baseUrl = `/api/opportunityCustomers`;

export const createOpportunityCustomer = async (
  opportunityCustomer: IOpportunityCustomer,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, opportunityCustomer);
};

export const fetchOpportunityCustomerList = async ({
  filters,
  paging,
}: {
  filters: OpportunityCustomerListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const featOpporutnityCustomerIncludeMember = async (opportunityId: number) => {
  const requestUrl = `${baseUrl}/${opportunityId}/includeMembers`;
  return await axios.get(`${requestUrl}`);
};

export const deleteOpportunityCustomer = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};

export const updateOpportunityCustomer = async (
  opportunityCustomer: IOpportunityCustomer,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, opportunityCustomer);
};
