import React, { useState } from 'react';
import { Program } from '../../../../state/program';
import { Brand } from '../../../../state/brand';
import { Dialog } from '@material-ui/core';
import InputItemStep from './steps/InputItemStep';
import SearchResultStep from './steps/SearchResultStep';
import { resetSearchItemAndAddMultipleBlockState } from './searchItemAndAddMultipleBlockDialog.actions';
import { useDispatch } from 'react-redux';
import SummaryStep from './steps/SummaryStep';

interface SearchItemAndAddMultipleBlockDialogProps {
  onClose: () => void;
  open: boolean;
  program: Program;
  brand: Brand;
}

export enum SearchItemAndAddMultipleBlockStep {
  InputItem,
  SearchResult,
  Summary
}

export interface BaseSearchItemAndAddMultipleBlockStepProps {
  onClose: () => void;
  onChangeStep: (nextStep: SearchItemAndAddMultipleBlockStep) => void;
  program: Program;
  brand: Brand;
}

const SearchItemAndAddMultipleBlockDialog: React.FC<SearchItemAndAddMultipleBlockDialogProps> = (props) => {
  const dispatch = useDispatch();
  const { open, onClose, program, brand } = props;

  const resetState = () => {
    dispatch(resetSearchItemAndAddMultipleBlockState());
    setActiveStep(SearchItemAndAddMultipleBlockStep.InputItem);
  };
  const [activeStep, setActiveStep] = useState(SearchItemAndAddMultipleBlockStep.InputItem);
  return <Dialog
    open={open}
    onClose={onClose}
    onExited={resetState}
    fullWidth
    maxWidth={'lg'}
  >
    {activeStep === SearchItemAndAddMultipleBlockStep.InputItem &&
    <InputItemStep
      onClose={onClose}
      onChangeStep={next => setActiveStep(next)}
      program={program}
      brand={brand}
    />
    }
    {activeStep === SearchItemAndAddMultipleBlockStep.SearchResult &&
    <SearchResultStep
      onClose={onClose}
      onChangeStep={next => setActiveStep(next)}
      program={program}
      brand={brand}/>
    }
    {activeStep === SearchItemAndAddMultipleBlockStep.Summary &&
    <SummaryStep
      onClose={onClose}
      onChangeStep={nextStep => setActiveStep(nextStep)}
      program={program}
      brand={brand}/>
    }
  </Dialog>;
};

export default SearchItemAndAddMultipleBlockDialog;
