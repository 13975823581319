import { Badge, ListItemText } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../state/configureStore';
import { fetchPendingApprovalAssignmentList } from '../../../pages/pendingApprovalItemList/pendingApprovalAssignmentList/pendingApprovalAssignmentList.actions';
import { fetchPendingApprovalProgramAndBrandRequestList } from '../../../pages/pendingApprovalItemList/pendingApprovalProgramBrandRequestList/pendingApprovalProgramBrandRequestList.actions';
import { fetchPendingApprovalSampleDevHlList } from '../../../pages/pendingApprovalItemList/pendingApprovalSampleDevHlRequestList/pendingApprovalSampleDevHlRequestList.actions';
import { fetchPendingApprovalSampleDevNlList } from '../../../pages/pendingApprovalItemList/pendingApprovalSampleDevNlRequestList/pendingApprovalSampleDevNlRequestList.actions';
import { fetchPendingApprovalSampleDevPlList } from '../../../pages/pendingApprovalItemList/pendingApprovalSampleDevPlRequestList/pendingApprovalSampleDevPlRequestList.actions';
import { fetchPendingApprovalSampleDevTlList } from '../../../pages/pendingApprovalItemList/pendingApprovalSampleDevTlRequestList/pendingApprovalSampleDevTlRequestList.actions';
import { fetchPendingApprovalSampleDevWlList } from '../../../pages/pendingApprovalItemList/pendingApprovalSampleDevWlRequestList/pendingApprovalSampleDevWlRequestList.actions';

import { PendingApprovalDrawerItemState } from './pendingApprovalDrawerItem.reducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badge: { left: '-30px' },
  }),
);

const intervalRefreshBadge = 1000 * 60 * 5;

const PendingApprovalDrawerItem: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    totalPendingApprovalAssignment,
    totalPendingApprovalProgramBrandRequest,
    totalPendingApprovalSampleDevHl,
    totalPendingApprovalSampleDevNl,
    totalPendingApprovalSampleDevPl,
    totalPendingApprovalSampleDevTl,
    totalPendingApprovalSampleDevWl,
  } = useSelector<AppState, PendingApprovalDrawerItemState>(
    state => state.pendingApprovalDrawerItem,
  );

  useEffect(() => {
    function fetchData() {
      const paging = {
        pageNumber: 0,
        pageSize: 999,
      };
      dispatch(fetchPendingApprovalProgramAndBrandRequestList(paging));
      dispatch(fetchPendingApprovalAssignmentList(paging));
      dispatch(fetchPendingApprovalSampleDevNlList(paging));
      dispatch(fetchPendingApprovalSampleDevHlList(paging));
      dispatch(fetchPendingApprovalSampleDevPlList(paging));
      dispatch(fetchPendingApprovalSampleDevTlList(paging));
      dispatch(fetchPendingApprovalSampleDevWlList(paging));
    }
    fetchData();
    const interval = setInterval(fetchData, intervalRefreshBadge);
    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <>
      <ListItemText primary="Pending Approval Items" />
      <Badge
        badgeContent={
          totalPendingApprovalAssignment +
          totalPendingApprovalProgramBrandRequest +
          totalPendingApprovalSampleDevHl +
          totalPendingApprovalSampleDevNl +
          totalPendingApprovalSampleDevPl +
          totalPendingApprovalSampleDevTl +
          totalPendingApprovalSampleDevWl
        }
        color="secondary"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.badge}
      >
        {' '}
      </Badge>
    </>
  );
};

export default PendingApprovalDrawerItem;
