import { PageFilter } from '../state/app';
import { CustomerGroupMember, CustomerGroupMemberListFilter } from '../state/customerGroupMember';
import axios from '../utils/axios';

const baseUrl = `/api/customerGroupMembers`;

export const fetchCustomerGroupMemberList = async ({
  filters,
  paging,
}: {
  filters: CustomerGroupMemberListFilter;
  paging: PageFilter;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchCustomerGroupMember = async (id: CustomerGroupMember['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createCustomerGroupMember = async (customerGroupMember: CustomerGroupMember) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, customerGroupMember);
};

export const updateCustomerGroupMember = async (customerGroupMember: CustomerGroupMember) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, customerGroupMember);
};

export const deleteCustomerGroupMember = async (id: CustomerGroupMember['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};
