import React from 'react';
import { Paper, Grid, Typography, Box } from '@material-ui/core';
import useStyles from './sampleDevelopmentWl.style';
import { CheckBoxFastField } from '../../../components';

interface SampleDevelopmentWlRimProps {}

const SampleDevelopmentWlRim: React.FC<SampleDevelopmentWlRimProps> = () => {
  const classes = useStyles();

  return (
    <Box m={1}>
      <Paper variant="outlined">
        <Box m={1}>
          <Grid container spacing={1} direction="row" justifyContent="flex-start">
            <Grid sm={12} item>
              <Typography className={classes.title}>ลักษณะริม</Typography>
            </Grid>
            <Grid sm={12} item md={2}>
              <CheckBoxFastField name="heatCut" label="Heat Cut" />
            </Grid>
            <Grid sm={12} item md={2}>
              <CheckBoxFastField name="musonicCut" label="Musonic Cut" />
            </Grid>
            <Grid sm={12} item md={2}>
              <CheckBoxFastField name="wovenEdge" label="Woven Edge" />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentWlRim;
