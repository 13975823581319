import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { SearchField, TableLoading } from '../../../components';
import {
  CustomerListFilter,
  Customer,
  CustomerState,
  clearCustomer,
  fetchCustomerList,
} from '../../../../state/customer';
import { PageFilter } from '../../../../state/app';
import { AppState } from '../../../../state/configureStore';
import { createResponsibilityCustomers } from '../../../../state/responsibilityCustomer';
import { TablePageSize } from '../../../../constants';
import { uniqBy } from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      minHeight: '53vh',
    },
    filterContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

interface AddResponsibilityCustomerDialogProps {
  open: boolean;
  handleClose: () => void;
  companyId: number;
  responsibilityId: number;
}

const initialPaging = { pageNumber: 0, pageSize: TablePageSize[0] };

const AddResponsibilityCustomerDialog: React.FC<AddResponsibilityCustomerDialogProps> = (
  props,
) => {
  const { open, handleClose, companyId, responsibilityId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    items: customerList,
    totalItems,
    loading,
  } = useSelector<AppState, CustomerState>((state) => state.customer);
  const [filters, setFilters] = useState<CustomerListFilter>({});
  const [paging, setPaging] = useState<PageFilter>(initialPaging);
  const [selectedCustomers, setSelectedCustomers] = useState<Customer[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    setSelectAll(false);
  }, [filters, paging]);

  useEffect(() => {
    setFilters((filters) => {
      return { ...filters, 'CompanyId.equalsTo': companyId };
    });
  }, [setFilters, companyId]);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const resetForm = () => {
    handleClose();
    setSelectedCustomers([]);
    setPaging({ ...initialPaging });
    setFilters({
      'CompanyId.equalsTo': companyId,
    });
  };

  const handleSubmit = () => {
    const submitValue = selectedCustomers.map((selectedCustomer, index) => {
      return {
        customerId: selectedCustomer.id,
        responsibilityId: responsibilityId,
      };
    });

    dispatch(createResponsibilityCustomers(submitValue, resetForm));
  };

  useEffect(() => {
    if (companyId) {
      dispatch(fetchCustomerList(filters, paging));
    }

    return function cleanUp() {
      dispatch(clearCustomer());
    };
  }, [dispatch, paging, filters, companyId]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth={true}
      disableBackdropClick={true}
    >
      <DialogTitle>Select Customer</DialogTitle>
      <DialogContent>
        <div className={classes.filterContainer}>
          <SearchField
            label="CustomerId"
            onChange={handleChangeFilter}
            filterKey="ExternalId.StartsWith"
            filters={filters}
          />
          <SearchField
            label="Name"
            onChange={handleChangeFilter}
            filterKey="Name.startsWith"
            filters={filters}
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  onChange={(event: React.ChangeEvent<HTMLInputElement>, checked) => {
                    if (checked) {
                      const newList = [...selectedCustomers].concat(customerList);
                      setSelectedCustomers(uniqBy(newList, (c) => c.id!));
                    } else {
                      const isInCurrentList = (customer: Customer): boolean =>
                        !customerList.find((c) => c.id === customer.id);
                      setSelectedCustomers(selectedCustomers.filter(isInCurrentList));
                    }
                    setSelectAll(checked);
                  }}
                  checked={selectAll}
                />
              </TableCell>
              <TableCell>No.</TableCell>
              <TableCell>CustomerId</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={10}>
              {customerList.map((customer, index) => {
                const isCheckboxChecked =
                  selectedCustomers.findIndex(
                    (selectedCustomer) => selectedCustomer.id === customer.id,
                  ) !== -1;
                return (
                  <TableRow key={customer.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked,
                        ) => {
                          if (checked) {
                            setSelectedCustomers([...selectedCustomers, customer]);
                          } else {
                            setSelectedCustomers(
                              selectedCustomers.filter((c) => c.id !== customer.id),
                            );
                          }
                        }}
                        checked={isCheckboxChecked}
                      />
                    </TableCell>
                    <TableCell>
                      {index + paging.pageSize * paging.pageNumber + 1}
                    </TableCell>
                    <TableCell>{customer.externalId}</TableCell>
                    <TableCell>{customer.name}</TableCell>
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[...TablePageSize]}
          component="div"
          count={totalItems}
          rowsPerPage={paging.pageSize}
          page={paging.pageNumber}
          onPageChange={handleChangePageNumber}
          onRowsPerPageChange={handleChangePageSize}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Confirm
        </Button>
        <Button
          onClick={() => {
            resetForm();
          }}
          color="default"
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddResponsibilityCustomerDialog;
