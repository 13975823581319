import { ExcelPagingFilter, PageFilter } from '../state/app';
import {
  ICheckAccountOwnerCriteria,
  IOpportunityApprovalRequestReportFilter,
  IOpportunityItemSummaryReportFilter,
  IProjectSummaryReportFilter,
  ISubtaskItemCriteria,
  ISubtaskItemDurationFilter,
  ISubtaskItemSummaryCriteria,
  ISubtaskItemSummaryReportFilter,
  ISubtaskItemWorkDurationSummaryCriteria,
  ITaskItemSummaryReportFilter,
  IProgramApprovalRequestReportFilter,
  IBrandApprovalRequestReportFilter,
  IOpportunityChangeLogReportFilter,
  IProjectChangeLogReportFilter,
} from '../state/report';
import { buildUrlExcelSearchParams, buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios, { getResponseFileName } from '../utils/axios';

const baseUrl = '/api/reports';

export const downloadCheckAccountOwnerReport = async ({
  filters,
}: {
  filters: ICheckAccountOwnerCriteria;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });
  const requestUrl = `${baseUrl}/checkAccountOwnerAndSalesTeam/export`;
  return await axios.get(`${requestUrl}?${params.toString()}`, { responseType: 'blob' });
};

export const downloadSubtaskSummaryReport = async ({
  filters,
}: {
  filters: ISubtaskItemSummaryCriteria;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });
  const requestUrl = `${baseUrl}/subtaskItemSummary/export`;
  return await axios.get(`${requestUrl}?${params.toString()}`, { responseType: 'blob' });
};

export const downloadSubtaskItemReport = async ({
  filters,
}: {
  filters: ISubtaskItemCriteria;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });
  const requestUrl = `${baseUrl}/subtaskItems/export`;
  return await axios.get(`${requestUrl}?${params.toString()}`, { responseType: 'blob' });
};

export const downloadSubtaskItemWorkDurationSummaryReport = async ({
  filters,
}: {
  filters: ISubtaskItemWorkDurationSummaryCriteria;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });
  const requestUrl = `${baseUrl}/subtaskItemWorkDurationSummary/export`;
  return await axios.get(`${requestUrl}?${params.toString()}`, { responseType: 'blob' });
};

export const fetchOpportunityItemSummaryReportList = async ({
  filters,
  paging,
}: {
  filters: IOpportunityItemSummaryReportFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/opportunityItems/`;

  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const downloadOpportunityItemSummaryReport = async (
  filters: IOpportunityItemSummaryReportFilter,
  paging: ExcelPagingFilter,
) => {
  const params = buildUrlExcelSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/opportunityItems/export`;
  const response = await axios.get(`${requestUrl}?${params.toString()}`, {
    responseType: 'blob',
  });
  const fileName = getResponseFileName(response);
  saveAs(response.data, fileName);
};

export const fetchTaskItemSummaryReportList = async ({
  filters,
  paging,
}: {
  filters: ITaskItemSummaryReportFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/taskItems/`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchProjectSummaryReportList = async ({
  filters,
  paging,
}: {
  filters: IProjectSummaryReportFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/projectSummary/`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchProjectSalesSummaryReportList = async ({
  filters,
  paging,
}: {
  filters: IProjectSummaryReportFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/projectSalesSummary/`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const downloadTaskItemSummaryReport = async (
  filters: ITaskItemSummaryReportFilter,
  paging: ExcelPagingFilter,
) => {
  const params = buildUrlExcelSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/taskItems/export`;
  const response = await axios.get(`${requestUrl}?${params.toString()}`, {
    responseType: 'blob',
  });
  const fileName = getResponseFileName(response);
  saveAs(response.data, fileName);
};

export const downloadProjectSummaryReport = async (
  filters: IProjectSummaryReportFilter,
  paging: ExcelPagingFilter,
) => {
  const params = buildUrlExcelSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/projectSummary/export`;
  const response = await axios.get(`${requestUrl}?${params.toString()}`, {
    responseType: 'blob',
  });
  const fileName = getResponseFileName(response);
  saveAs(response.data, fileName);
};

export const fetchSubtaskItemSummaryReportList = async ({
  filters,
  paging,
}: {
  filters: ISubtaskItemSummaryReportFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/subtaskItems/`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const downloadSubtaskItemSummaryReport = async (
  filters: ISubtaskItemSummaryReportFilter,
  paging: ExcelPagingFilter,
) => {
  const params = buildUrlExcelSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/subtaskItems/export`;
  const response = await axios.get(`${requestUrl}?${params.toString()}`, {
    responseType: 'blob',
  });
  const fileName = getResponseFileName(response);
  saveAs(response.data, fileName);
};

export const fetchSubtaskItemDurationReportList = async ({
  filters,
  paging,
}: {
  filters: ISubtaskItemDurationFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/subtaskItemWorkDurationSummary/`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const downloadSubtaskItemDurationReport = async (
  filters: ISubtaskItemDurationFilter,
  paging: ExcelPagingFilter,
) => {
  const params = buildUrlExcelSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/subtaskItemWorkDurationSummary/export`;
  const response = await axios.get(`${requestUrl}?${params.toString()}`, {
    responseType: 'blob',
  });
  const fileName = getResponseFileName(response);
  saveAs(response.data, fileName);
};

export const fetchOpportunityApprovalRequestReportList = async ({
  filters,
  paging,
}: {
  filters: IOpportunityApprovalRequestReportFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/opportunityApprovalRequest/`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const downloadOpportunityApprovalRequestReportList = async (
  filters: IOpportunityApprovalRequestReportFilter,
  paging: ExcelPagingFilter,
) => {
  const params = buildUrlExcelSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/opportunityApprovalRequest/export`;
  const response = await axios.get(`${requestUrl}?${params.toString()}`, {
    responseType: 'blob',
  });
  const fileName = getResponseFileName(response);
  saveAs(response.data, fileName);
};

export const fetchProgramApprovalRequestReportList = async ({
  filters,
  paging,
}: {
  filters: IProgramApprovalRequestReportFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/programApprovalRequest/`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const downloadProjectSalesSummaryReport = async (
  filters: IProjectSummaryReportFilter,
  paging: ExcelPagingFilter,
) => {
  const params = buildUrlExcelSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/projectSalesSummary/export`;
  const response = await axios.get(`${requestUrl}?${params.toString()}`, {
    responseType: 'blob',
  });
  const fileName = getResponseFileName(response);
  saveAs(response.data, fileName);
};

export const downloadProgramApprovalRequestReportList = async (
  filters: IProgramApprovalRequestReportFilter,
  paging: ExcelPagingFilter,
) => {
  const params = buildUrlExcelSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/programApprovalRequest/export`;
  const response = await axios.get(`${requestUrl}?${params.toString()}`, {
    responseType: 'blob',
  });
  const fileName = getResponseFileName(response);
  saveAs(response.data, fileName);
};

export const fetchBrandApprovalRequestReportList = async ({
  filters,
  paging,
}: {
  filters: IBrandApprovalRequestReportFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/brandApprovalRequest/`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const downloadBrandApprovalRequestReportList = async (
  filters: IBrandApprovalRequestReportFilter,
  paging: ExcelPagingFilter,
) => {
  const params = buildUrlExcelSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/brandApprovalRequest/export`;
  const response = await axios.get(`${requestUrl}?${params.toString()}`, {
    responseType: 'blob',
  });
  const fileName = getResponseFileName(response);
  saveAs(response.data, fileName);
};

export const fetchProjectChangeLogReportList = async ({
  filters,
  paging,
}: {
  filters: IProjectChangeLogReportFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/projectChangeLog/`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const downloadProjectChangeLogReport = async (
  filters: IProjectChangeLogReportFilter,
  paging: ExcelPagingFilter,
) => {
  const params = buildUrlExcelSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/projectChangeLog/export`;
  const response = await axios.get(`${requestUrl}?${params.toString()}`, {
    responseType: 'blob',
  });
  const fileName = getResponseFileName(response);
  saveAs(response.data, fileName);
};

export const downloadOpportunityChangeLogReport = async (
  filters: IOpportunityChangeLogReportFilter,
  paging: ExcelPagingFilter,
) => {
  const params = buildUrlExcelSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/opportunityChangeLog/export`;
  const response = await axios.get(`${requestUrl}?${params.toString()}`, {
    responseType: 'blob',
  });
  const fileName = getResponseFileName(response);
  saveAs(response.data, fileName);
};
