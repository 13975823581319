import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import {
  createCustomerGroupMember,
  fetchCustomerGroupMember,
  fetchCustomerGroupMemberList,
  updateCustomerGroupMember,
  deleteCustomerGroupMember
} from '../../services/customerGroupMemberService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_CUSTOMERGROUPMEMBER,
  DELETE_CUSTOMERGROUPMEMBER,
  FETCH_CUSTOMERGROUPMEMBER,
  FETCH_CUSTOMERGROUPMEMBER_LIST,
  UPDATE_CUSTOMERGROUPMEMBER,
} from './customerGroupMember.actions';
import { CustomerGroupMemberCreateOrUpdatePayload } from './customerGroupMember.types';
import { AppState } from '../configureStore';
import { CustomerGroup } from '../customerGroup';

function* fetchCustomerGroupMemberSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchCustomerGroupMember, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchCustomerGroupMemberListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchCustomerGroupMemberList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createCustomerGroupMemberSaga(action: AppAction) {
  try {
    const { values } = action.payload as CustomerGroupMemberCreateOrUpdatePayload;
    const successMessage = `Created successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createCustomerGroupMember, values);
    const customerGroup = yield select(getCustomerGroupFromState);
    if (customerGroup.id) {
      yield call(fetchCustomerGroupMemberListSaga, {
        type: FETCH_CUSTOMERGROUPMEMBER_LIST,
        payload: {
          filters: { 'CustomerGroupId.EqualsTo': customerGroup.id },
          paging: { pageNumber: 0, pageSize: 99999 },
        },
      });
    }
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateCustomerGroupMemberSaga(action: AppAction) {
  try {
    const { values } = action.payload as CustomerGroupMemberCreateOrUpdatePayload;
    const successMessage = `Updated successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateCustomerGroupMember, values);
    const customerGroup = yield select(getCustomerGroupFromState);
    if (customerGroup.id) {
      yield call(fetchCustomerGroupMemberListSaga, {
        type: FETCH_CUSTOMERGROUPMEMBER_LIST,
        payload: {
          filters: { 'CustomerGroupId.EqualsTo': customerGroup.id },
          paging: { pageNumber: 0, pageSize: 99999 },
        },
      });
    }
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteOpportunityItemSaga(action: AppAction) {
  const { payload } = action;
  const { id } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    yield call(deleteCustomerGroupMember, id);
    const customerGroup = yield select(getCustomerGroupFromState);
    if (customerGroup.id) {
      yield call(fetchCustomerGroupMemberListSaga, {
        type: FETCH_CUSTOMERGROUPMEMBER_LIST,
        payload: {
          filters: { 'CustomerGroupId.EqualsTo': customerGroup.id },
          paging: { pageNumber: 0, pageSize: 99999 },
        },
      });
    }
    yield put({ type: SUCCESS(action.type)} );
    const successMessage = `Deleted successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function getCustomerGroupFromState(state: AppState): CustomerGroup {
  const { item } = state.customerGroup;
  return item;
}

export default function* watchCustomerGroupMember() {
  yield takeLatest(FETCH_CUSTOMERGROUPMEMBER, fetchCustomerGroupMemberSaga);
  yield debounce(250, FETCH_CUSTOMERGROUPMEMBER_LIST, fetchCustomerGroupMemberListSaga);
  yield takeLatest(CREATE_CUSTOMERGROUPMEMBER, createCustomerGroupMemberSaga);
  yield takeLatest(UPDATE_CUSTOMERGROUPMEMBER, updateCustomerGroupMemberSaga);
  yield takeLatest(DELETE_CUSTOMERGROUPMEMBER, deleteOpportunityItemSaga);
}
