import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_MULTIPLE_SUBTASK_ITEM_FORECAST_LIST,
  CREATE_SUBTASK_ITEM_FORECAST_LIST,
  FETCH_SUBTASK_ITEM_FORECAST_LIST,
  fetchSubtaskItemForecastListBySmSubtaskItemId,
} from './subtaskItemForecast.actions';
import {
  fetchSubtaskItemForecastList,
  removeAndCreateSubtaskItemForecasts,
} from '../../services/subtaskItemForecastService';

function* fetchSubtaskItemForecastListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSubtaskItemForecastList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createSubtaskItemForecastListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { smSubtaskItemId, smSubtaskItemForecasts } = action.payload;
    yield call(
      removeAndCreateSubtaskItemForecasts,
      smSubtaskItemId,
      smSubtaskItemForecasts,
    );
    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Update SubtaskItems Forecast Successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put(fetchSubtaskItemForecastListBySmSubtaskItemId(smSubtaskItemId));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createMultipleSubtaskItemForecastListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const subtaskItems = action.payload.subtaskItems;
    const subtaskItemForecastList = action.payload.multipleSubtaskItemForecastList;
    const selectedSubtaskItemOnTableId = action.payload.selectedSubtaskItemOnTableId;
    for (const subtaskItem of subtaskItems) {
      const smSubtaskItemId = subtaskItem.id;
      yield call(
        removeAndCreateSubtaskItemForecasts,
        smSubtaskItemId,
        subtaskItemForecastList,
      );
    }
    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Update Multiple SubtaskItems Forecast Successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    if (selectedSubtaskItemOnTableId) {
      yield put(
        fetchSubtaskItemForecastListBySmSubtaskItemId(selectedSubtaskItemOnTableId),
      );
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSubtaskItemForecast() {
  yield debounce(250, FETCH_SUBTASK_ITEM_FORECAST_LIST, fetchSubtaskItemForecastListSaga);
  yield takeLatest(CREATE_SUBTASK_ITEM_FORECAST_LIST, createSubtaskItemForecastListSaga);
  yield takeLatest(
    CREATE_MULTIPLE_SUBTASK_ITEM_FORECAST_LIST,
    createMultipleSubtaskItemForecastListSaga,
  );
}
