import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createWorkingTeamMember,
  deleteWorkingTeamMember,
  fetchWorkingTeamMemberList,
  fetchWorkingTeamMemberNotOwnerList,
  updateWorkingTeamMember,
} from '../../services/workingTeamMemberService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_WORKING_TEAM_MEMBER,
  DELETE_WORKING_TEAM_MEMBER,
  FETCH_WORKING_TEAM_MEMBER_LIST,
  FETCH_WORKING_TEAM_MEMBER_LIST_NOT_OWNER,
  UPDATE_WORKING_TEAM_MEMBER,
} from './workingTeamMember.actions';
import { WorkingTeamMemberCreateOrUpdatePayload } from './workingTeamMember.type';

function* fetchWorkingTeamMemberListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchWorkingTeamMemberList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchWorkingTeamMemberNotOwnerListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchWorkingTeamMemberNotOwnerList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createWorkingTeamMemberSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createWorkingTeamMember, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Added Working team member successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield call(fetchWorkingTeamMemberListSaga, {
      type: FETCH_WORKING_TEAM_MEMBER_LIST,
      payload: {
        filters: { 'SmWorkingTeamId.EqualsTo': action.payload.smWorkingTeamId },
        paging: { pageNumber: 0, pageSize: 99999 },
      },
    });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateWorkingTeamMemberSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateWorkingTeamMember, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Updated Working team member successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield call(fetchWorkingTeamMemberListSaga, {
      type: FETCH_WORKING_TEAM_MEMBER_LIST,
      payload: {
        filters: { 'SmWorkingTeamId.EqualsTo': action.payload.smWorkingTeamId },
        paging: { pageNumber: 0, pageSize: 99999 },
      },
    });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteWorkingTeamMemberSaga(action: AppAction) {
  const { payload } = action;
  const { id, smWorkingTeamId } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    yield call(deleteWorkingTeamMember, id);
    const successMessage = `Deleted successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield call(fetchWorkingTeamMemberListSaga, {
      type: FETCH_WORKING_TEAM_MEMBER_LIST,
      payload: {
        filters: { 'SmWorkingTeamId.EqualsTo': smWorkingTeamId },
        paging: { pageNumber: 0, pageSize: 99999 },
      },
    });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchWorkingTeamMember() {
  yield debounce(250, FETCH_WORKING_TEAM_MEMBER_LIST, fetchWorkingTeamMemberListSaga);
  yield debounce(
    250,
    FETCH_WORKING_TEAM_MEMBER_LIST_NOT_OWNER,
    fetchWorkingTeamMemberNotOwnerListSaga,
  );
  yield takeLatest(CREATE_WORKING_TEAM_MEMBER, createWorkingTeamMemberSaga);
  yield takeLatest(UPDATE_WORKING_TEAM_MEMBER, updateWorkingTeamMemberSaga);
  yield takeLatest(DELETE_WORKING_TEAM_MEMBER, deleteWorkingTeamMemberSaga);
}
