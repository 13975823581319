import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    autoComplete: {
      flexGrow: 20,
    },
    button: {
      marginBottom: '8px',
      flexGrow: 1,
    },
  }),
);

export default useStyles;
