import { AppAction, PageFilter } from '../app';
import {
  SampleDevelopmentNlApprovalRequest,
  SampleDevelopmentNlApprovalRequestListFilter,
} from './sampleDevelopmentNlApprovalRequest.types';

export const FETCH_SAMPLE_DEV_NL_APPROVAL_REQUEST_LIST =
  'FETCH_SAMPLE_DEV_NL_APPROVAL_REQUEST_LIST';
export const OPEN_SAMPLE_DEV_NL_APPROVAL_REQUEST_DIALOG =
  'OPEN_SAMPLE_DEV_NL_APPROVAL_REQUEST_DIALOG';

export const CLOSE_SAMPLE_DEV_NL_APPROVAL_REQUEST_DIALOG =
  'CLOSE_SAMPLE_DEV_NL_APPROVAL_REQUEST_DIALOG';

export const UPDATE_SAMPLE_DEV_NL_APPROVAL_REQUEST_RESPONSE =
  'UPDATE_SAMPLE_DEV_NL_APPROVAL_REQUEST_RESPONSE';
export const fetchSampleDevelopmentNlApprovalRequestList = (
  filters: SampleDevelopmentNlApprovalRequestListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SAMPLE_DEV_NL_APPROVAL_REQUEST_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const updateSampleDevelopmentNlApprovalRequestResponse = (
  payload: SampleDevelopmentNlApprovalRequest,
) => ({
  type: UPDATE_SAMPLE_DEV_NL_APPROVAL_REQUEST_RESPONSE,
  payload,
});

export const openSampleDevelopmentNlApprovalRequestDialog = () => ({
  type: OPEN_SAMPLE_DEV_NL_APPROVAL_REQUEST_DIALOG,
});

export const closeSampleDevelopmentNlApprovalRequestDialog = () => ({
  type: CLOSE_SAMPLE_DEV_NL_APPROVAL_REQUEST_DIALOG,
});
