import { PageFilter } from '../state/app';
import { SquadSalesMember, SquadSalesMemberListFilter } from '../state/squadSalesMember';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/smSquadSalesMembers`;

export const fetchSquadSalesMemberList = async ({
  filters,
  paging,
}: {
  filters: SquadSalesMemberListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchSquadSalesMember = async (id: SquadSalesMember['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createSquadSalesMember = async (squadSalesMember: SquadSalesMember) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, squadSalesMember);
};

export const updateSquadSalesMember = async (squadSalesMember: SquadSalesMember) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, squadSalesMember);
};

export const deleteSquadSalesMember = async (id: SquadSalesMember['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};
