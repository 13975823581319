import React from 'react';
import { useParams } from 'react-router-dom';
import ResponsibilityForm from './ResponsibilityForm';

interface ResponsibilityFormPageRouteParamsProps {
  id: string;
}

const ResponsibilityFormPage: React.FC<
  ResponsibilityFormPageRouteParamsProps
> = props => {
  const { id: paramsId } = useParams<ResponsibilityFormPageRouteParamsProps>();
  return <ResponsibilityForm responsibilityId={paramsId ? +paramsId : undefined} />;
};

export default ResponsibilityFormPage;
