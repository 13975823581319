import { Card, CardContent, TextField, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Block,
  BlockState,
  clearBlock,
  createBlock,
  fetchBlock,
  updateBlock,
} from '../../../state/block';
import { Brand, BrandState, clearBrand, fetchBrand } from '../../../state/brand';
import { AppState } from '../../../state/configureStore';
import {
  clearProductType,
  fetchProductType,
  ProductType,
  ProductTypeState,
} from '../../../state/productType';
import {
  clearProgram,
  fetchProgram,
  Program,
  ProgramState,
} from '../../../state/program';
import {
  BrandComboBox,
  FixedPositionButtons,
  ProductTypeComboBox,
  ProgramComboBox,
} from '../../components';
import useStyles from './blockForm.style';

const BlockSchema = Yup.object().shape({
  blockNo: Yup.string().required('BlockNo is required'),
  programId: Yup.number()
    .moreThan(0, 'Program is required')
    .required(),
  brandId: Yup.number()
    .moreThan(0, 'Brand is required')
    .required(),
  productTypeId: Yup.number()
    .moreThan(0, 'ProductType is required')
    .required(),
  item: Yup.string().required('Item is required'),
  itemDescription: Yup.string().max(1000),
});

interface BlockFormRouteParamsProps {
  id: string;
}

interface BlockFormProps extends RouteComponentProps<BlockFormRouteParamsProps> {}

const BlockForm: React.FC<BlockFormProps> = props => {
  const { match, history } = props;
  const {
    params: { id: paramsId },
  } = match;
  const classes = useStyles();
  const {
    item: {
      blockNo = '',
      id = 0,
      itemDescription = '',
      programId = 0,
      brandId = 0,
      productTypeId = 0,
      item = '',
    },
    loading: blockIsLoading,
  } = useSelector<AppState, BlockState>(state => state.block);
  const { item: program, loading: programIsLoading } = useSelector<
    AppState,
    ProgramState
  >(state => state.program);
  const { item: brand, loading: brandIsLoading } = useSelector<AppState, BrandState>(
    state => state.brand,
  );
  const { item: productType, loading: productTypeIsLoading } = useSelector<
    AppState,
    ProductTypeState
  >(state => state.productType);

  const dispatch = useDispatch();
  const redirectToBlockList = () => {
    history.push('/settings/blocks');
  };

  const handleSubmit = (values: Block, actions: FormikHelpers<Block>) => {
    if (paramsId) {
      dispatch(updateBlock(values));
    } else {
      dispatch(createBlock(values));
    }
    actions.setSubmitting(false);
  };

  useEffect(() => {
    if (paramsId) {
      dispatch(fetchBlock(+paramsId));
    } else {
      dispatch(clearBlock());
    }

    return function cleanUp() {
      dispatch(clearBlock());
    };
  }, [paramsId, dispatch]);

  useEffect(() => {
    if (programId !== 0) {
      dispatch(fetchProgram(programId));
    } else {
      dispatch(clearProgram());
    }
  }, [dispatch, programId]);

  useEffect(() => {
    if (brandId !== 0) {
      dispatch(fetchBrand(brandId));
    } else {
      dispatch(clearBrand());
    }
  }, [dispatch, brandId]);

  useEffect(() => {
    if (productTypeId !== 0) {
      dispatch(fetchProductType(productTypeId));
    } else {
      dispatch(clearProductType());
    }
  }, [dispatch, productTypeId]);

  const isLoading =
    blockIsLoading || programIsLoading || brandIsLoading || productTypeIsLoading;

  return (
    <Card className={classes.root}>
      {isLoading ? (
        <div className={classes.circularContainer}>
          <CircularProgress />
        </div>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={{
            blockNo,
            id,
            itemDescription,
            item,
            programId,
            program,
            brandId,
            brand,
            productTypeId,
            productType,
          }}
          validationSchema={BlockSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, errors, touched, submitForm, setFieldValue }) => {
            return (
              <Form>
                <Typography variant="h4" className={classes.title}>
                  {paramsId === undefined ? `New Block` : `Update Block`}
                </Typography>
                <CardContent>
                  <TextField
                    className={classes.input}
                    name="blockNo"
                    type="input"
                    label="BlockNo"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={values.blockNo}
                    onChange={handleChange}
                    error={!!errors.blockNo && !!touched.blockNo}
                    helperText={errors.blockNo}
                  />
                  <TextField
                    className={classes.input}
                    name="itemDescription"
                    type="input"
                    label="ItemDescription"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={values.itemDescription}
                    onChange={handleChange}
                    error={!!errors.itemDescription && !!touched.itemDescription}
                    helperText={errors.itemDescription}
                  />
                  <TextField
                    className={classes.input}
                    name="item"
                    type="input"
                    label="Item"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={values.item}
                    onChange={handleChange}
                    error={!!errors.item && !!touched.item}
                    helperText={errors.item}
                  />
                  <ProgramComboBox
                    handleChange={result => {
                      setFieldValue('program', result ? result : null);
                      setFieldValue('programId', result ? result.id : 0);
                    }}
                    selectedValue={values.program as Program}
                  />
                  {!!errors.programId && touched.programId && (
                    <div className={classes.error}>{errors.programId}</div>
                  )}
                  <BrandComboBox
                    handleChange={result => {
                      setFieldValue('brand', result ? result : null);
                      setFieldValue('brandId', result ? result.id : 0);
                    }}
                    selectedValue={values.brand as Brand}
                  />
                  {!!errors.brandId && touched.brandId && (
                    <div className={classes.error}>{errors.brandId}</div>
                  )}
                  <ProductTypeComboBox
                    handleChange={result => {
                      setFieldValue('productType', result ? result : null);
                      setFieldValue('productTypeId', result ? result.id : 0);
                    }}
                    selectedValue={values.productType as ProductType}
                  />
                  {!!errors.productTypeId && touched.productTypeId && (
                    <div className={classes.error}>{errors.productTypeId}</div>
                  )}
                </CardContent>
                <FixedPositionButtons
                  actions={[
                    {
                      name: paramsId === undefined ? `Create` : `Update`,
                      onClick: () => {
                        submitForm();
                      },
                      color: 'primary' as 'primary',
                      disabled: false,
                      icon: 'save',
                    },
                    {
                      name: 'CANCEL',
                      onClick: redirectToBlockList,
                      color: 'default' as 'default',
                      disabled: false,
                    },
                  ]}
                  open
                />
              </Form>
            );
          }}
        </Formik>
      )}
    </Card>
  );
};

export default BlockForm;
