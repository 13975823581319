import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import {
  createResponsibilityCustomer,
  deleteResponsibilityCustomer,
  fetchResponsibilityCustomer,
  fetchResponsibilityCustomerList,
  inactiveResponsibilityCustomer,
  updateResponsibilityCustomer,
  replaceResponsibilityCustomer,
} from '../../services/responsibilityCustomerService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_RESPONSIBILITY_CUSTOMER,
  DELETE_RESPONSIBILITY_CUSTOMER,
  FETCH_RESPONSIBILITY_CUSTOMER_LIST,
  FETCH_RESPONSIBILITY_CUSTOMER,
  CREATE_RESPONSIBILITY_CUSTOMERS,
  fetchResponsibilityCustomerListByresponsibilityId,
  UPDATE_RESPONSIBILITY_CUSTOMER,
  REPLACE_RESPONSIBILITY_CUSTOMER,
  INACTIVE_RESPONSIBILITY_CUSTOMER,
} from './responsibilityCustomer.actions';
import {
  ResponsibilityCustomerCreateOrUpdatePayload,
  ResponsibilityCustomerReplacePayLoad,
} from './responsibilityCustomer.types';
import { AppState } from '../configureStore';
import { IResponsibilityCustomer } from '../../models/responsibilityCustomer.model';

function* fetchResponsibilityCustomerSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchResponsibilityCustomer, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchResponsibilityCustomerListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchResponsibilityCustomerList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createResponsibilityCustomerSaga(action: AppAction) {
  try {
    const { values } = action.payload as ResponsibilityCustomerCreateOrUpdatePayload;
    const successMessage = `Created responsibility customer successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createResponsibilityCustomer, values);
    yield put(fetchResponsibilityCustomerListByresponsibilityId(data.responsibilityId));
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createResponsibilityCustomersSaga(action: AppAction) {
  try {
    const { payload, callback } = action;

    yield put({ type: REQUEST(action.type) });

    for (const customer of payload) {
      yield call(createResponsibilityCustomer, customer);
    }

    const successMessage = `Created responsibility customer successfully.`;
    yield put(
      fetchResponsibilityCustomerListByresponsibilityId(payload[0].responsibilityId),
    );

    yield put({ type: SUCCESS(action.type), payload });

    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));

    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateResponsibilityCustomerSaga(action: AppAction) {
  try {
    const { values } = action.payload as ResponsibilityCustomerCreateOrUpdatePayload;
    const successMessage = `Updated responsibility customer successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateResponsibilityCustomer, values);
    yield put(fetchResponsibilityCustomerListByresponsibilityId(data.responsibilityId));
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteResponsibilityCustomerSaga(action: AppAction) {
  const { payload } = action;
  const { id } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    yield call(deleteResponsibilityCustomer, id);
    const responsibility = yield select(getResponsibilityFromState);
    if (responsibility.id) {
      yield put(fetchResponsibilityCustomerListByresponsibilityId(responsibility.id));
    }
    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Deleted successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}
function* replaceResponsibilityCustomerSaga(action: AppAction) {
  try {
    const values = action.payload as ResponsibilityCustomerReplacePayLoad;
    const successMessage = `Replace responsibility customer successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(replaceResponsibilityCustomer, values);
    const responsibility = yield select(getResponsibilityFromState);
    if (responsibility.id) {
      yield put(fetchResponsibilityCustomerListByresponsibilityId(responsibility.id));
    }
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* inactiveResponsibilityCustomerSaga(action: AppAction) {
  const { payload } = action;
  const { id } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    yield call(inactiveResponsibilityCustomer, id);
    const responsibility = yield select(getResponsibilityFromState);
    if (responsibility.id) {
      yield put(fetchResponsibilityCustomerListByresponsibilityId(responsibility.id));
    }
    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Inactive successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function getResponsibilityFromState(state: AppState): IResponsibilityCustomer {
  const { item } = state.responsibility;
  return item;
}

export default function* watchResponsibilityCustomer() {
  yield takeLatest(FETCH_RESPONSIBILITY_CUSTOMER, fetchResponsibilityCustomerSaga);
  yield debounce(
    250,
    FETCH_RESPONSIBILITY_CUSTOMER_LIST,
    fetchResponsibilityCustomerListSaga,
  );
  yield takeLatest(CREATE_RESPONSIBILITY_CUSTOMER, createResponsibilityCustomerSaga);
  yield takeLatest(UPDATE_RESPONSIBILITY_CUSTOMER, updateResponsibilityCustomerSaga);
  yield takeLatest(CREATE_RESPONSIBILITY_CUSTOMERS, createResponsibilityCustomersSaga);
  yield takeLatest(DELETE_RESPONSIBILITY_CUSTOMER, deleteResponsibilityCustomerSaga);
  yield takeLatest(REPLACE_RESPONSIBILITY_CUSTOMER, replaceResponsibilityCustomerSaga);
  yield takeLatest(INACTIVE_RESPONSIBILITY_CUSTOMER, inactiveResponsibilityCustomerSaga);
}
