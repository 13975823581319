import { ProgramAndBrandRequest } from '../../../../state/programAndBrandRequest';
import { AppAction } from '../../../../state/app';
import {
  CLEAR_PENDING_APPROVAL_PROGRAMANDBRANDREQUEST_LIST,
  FETCH_PENDING_APPROVAL_PROGRAMANDBRANDREQUEST_LIST,
} from './pendingApprovalProgramBrandRequestList.actions';
import { REQUEST, SUCCESS, FAILURE } from '../../../../utils/actionTypeUtil';

export interface PendingApprovalProgramBrandRequestListState {
  loading: boolean;
  items: ReadonlyArray<ProgramAndBrandRequest>;
  errorMessage: string;
  totalItems: number;
}

const initialState: PendingApprovalProgramBrandRequestListState = {
  errorMessage: '',
  items: [],
  loading: false,
  totalItems: 0,
};

export default (
  state: PendingApprovalProgramBrandRequestListState = initialState,
  action: AppAction,
): PendingApprovalProgramBrandRequestListState => {
  switch (action.type) {
    case REQUEST(FETCH_PENDING_APPROVAL_PROGRAMANDBRANDREQUEST_LIST):
      return { ...state, loading: true };
    case SUCCESS(FETCH_PENDING_APPROVAL_PROGRAMANDBRANDREQUEST_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_PENDING_APPROVAL_PROGRAMANDBRANDREQUEST_LIST):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case CLEAR_PENDING_APPROVAL_PROGRAMANDBRANDREQUEST_LIST:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
