import {
  AppBar,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { AccountTree as ProjectIcon, Close } from '@material-ui/icons';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../state/configureStore';
import { fetchProjectDetail, ProjectState } from '../../../state/project';
import Loading from '../AwLoading';
import ProjectDetailCustomerGroupTable from './ProjectDetailCustomerGroupTable';
import ProjectDetailCustomerTable from './ProjectDetailCustomerTable';
import ProjectDetailProgramBrandTable from './ProjectDetailProgramBrandTable';
import ProjectDetailSquadMemberTable from './ProjectDetailSquadMember';
import ProjectDetailSquadTable from './ProjectDetailSquadTable';

interface ProjectDetailDialogProps {
  handleClose: () => void;
  open: boolean;
  smProjectId?: number;
}

const ProjectDetailDialog: React.FC<ProjectDetailDialogProps> = (props) => {
  const { open, handleClose, smProjectId } = props;
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const { view: project, loading } = useSelector<AppState, ProjectState>(
    (state) => state.project,
  );

  useEffect(() => {
    if (smProjectId && open) {
      dispatch(fetchProjectDetail(smProjectId));
    }
  }, [dispatch, smProjectId, open]);

  if (!open || !smProjectId) {
    return null;
  }

  const getFullName = () => get(project, 'owner.fullNameWithUserName', '');

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
      <DialogTitle style={{ paddingTop: 15 }}>
        <Grid container>
          <ProjectIcon style={{ marginTop: 5, marginRight: 10 }} />
          <Typography variant="h4">PROJECT: {project.name}</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Loading loading={loading} height={550}>
          <CardContent>
            <Grid container direction="row" style={{ padding: 10 }}>
              <Grid item xs={3}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Owner
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h6" color="primary">
                  {getFullName()}
                </Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center" style={{ padding: 10 }}>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: 15,
                  minHeight: 400,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }}
              >
                <AppBar position="static" color="default" variant="outlined">
                  <Tabs
                    value={tabIndex}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(_, newIndex) => setTabIndex(newIndex)}
                    variant="scrollable"
                  >
                    {/* <Tab label="Squad" /> */}
                    {/* <Tab label="Project Member" /> */}
                    <Tab label="Program Brand" />
                    {/* <Tab label="Customer" /> */}
                    {/* <Tab label="Customer Group" /> */}
                  </Tabs>
                </AppBar>
                <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                  {/* {tabIndex === 0 && (
                    <ProjectDetailSquadTable projectSquads={project.smProjectSquads} />
                  )}
                  {tabIndex === 1 && (
                    <ProjectDetailSquadMemberTable
                      projectSquads={project.smProjectSquads}
                    />
                  )} */}
                  {tabIndex === 0 && (
                    <ProjectDetailProgramBrandTable
                      projectPrograms={project.smProjectPrograms}
                    />
                  )}
                  {/* {tabIndex === 3 && (
                    <ProjectDetailCustomerTable
                      projectCustomers={project.smProjectCustomers}
                    />
                  )}
                  {tabIndex === 4 && (
                    <ProjectDetailCustomerGroupTable
                      projectCustomerGroups={project.smProjectCustomerGroups}
                    />
                  )} */}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Loading>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginBottom: 15 }}
        >
          <Button
            variant="contained"
            onClick={handleClose}
            color="secondary"
            startIcon={<Close />}
            size="large"
          >
            Close
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectDetailDialog;
