import React from 'react';
import { fetchCustomerList as fetchCustomerListService } from '../../services/customerService';
import { Customer } from '../../state/customer';
import AsyncAutoComplete from './AsyncAutoComplete';

interface CustomerComboBoxProps {
  handleChange: (result: Customer) => void;
  selectedValue: Customer;
  companyId?: number;
  isDisabled?: boolean;
  error?: boolean;
  helperText?: string;
}

const CustomerComboBox: React.FC<CustomerComboBoxProps> = props => {
  const {
    handleChange,
    selectedValue,
    isDisabled = false,
    companyId,
    error,
    helperText,
  } = props;

  const customerPromiseOptions = async (inputValue: string) => {
    const additionalFilters = !!companyId ? { 'CompanyId.equalsTo': companyId } : {};
    const { data: rows } = await fetchCustomerListService({
      filters: { 'DisplayName.Contains': inputValue, ...additionalFilters },
      paging: { pageNumber: 0, pageSize: 10 },
    });

    return rows;
  };

  return (
    <AsyncAutoComplete
      name="Customer"
      value={selectedValue}
      getOptionValue={(option: Customer) => {
        return option.id!.toString();
      }}
      getOptionLabel={(option: Customer) => {
        return option.displayName!;
      }}
      promiseOptions={customerPromiseOptions}
      placeholder={'Type Customer Name'}
      handleChange={handleChange}
      isDisabled={isDisabled}
      error={error}
      helperText={helperText}
    />
  );
};

export default CustomerComboBox;
