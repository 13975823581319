import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ProgramAndBrandRequestStatusLabel, TablePageSize } from '../../../constants';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearProgramAndBrandRequest,
  fetchProgramAndBrandRequestList,
  ProgramAndBrandRequest,
  ProgramAndBrandRequestListFilter,
  ProgramAndBrandRequestState,
} from '../../../state/programAndBrandRequest';
import {
  PageButtonGroup,
  PageContainer,
  PageTitle,
  SortLabel,
  TableLoading,
} from '../../components';
import { DateTimeDisplay } from '../../components/DateTimeDisplay';
import { useFilter, useHasRole, useTableSorting } from '../../hooks';
import roleRoute from '../roleRoute.config';
import ProgramAndBrandRequestFilter from './ProgramAndBrandRequestFilter';

const initialProgramAndBrandRequestFilter = {
  'Status.equalsTo': '',
};

const ProgramAndBrandRequestList: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const [filters, setFilters] = useFilter<ProgramAndBrandRequestListFilter>(
    initialProgramAndBrandRequestFilter,
  );
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: TablePageSize[0],
  });

  const isDisabled = !useHasRole(roleRoute.createProgramBrandRequest);

  const {
    items: programAndBrandRequests,
    totalItems,
    loading,
  } = useSelector<AppState, ProgramAndBrandRequestState>(
    (state) => state.programAndBrandRequest,
  );

  const [sort, setSort] = useTableSorting({
    orderBy: 'requestNumber',
    direction: 'desc',
  });

  const handleChangeFilter = (newFilter: ProgramAndBrandRequestListFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchProgramAndBrandRequestList(filters, { ...paging, sort }));
    return function cleanUp() {
      dispatch(clearProgramAndBrandRequest());
    };
  }, [dispatch, paging, filters, sort]);

  const addNewProgramAndBrandRequest = (): void => {
    history.push('/programAndBrandRequests/form');
  };

  const editProgramAndBrandRequest = (id: ProgramAndBrandRequest['id']): void => {
    history.push(`/programAndBrandRequests/form/${id}`);
  };

  return (
    <PageContainer>
      <PageTitle>Program Brand Request</PageTitle>
      <ProgramAndBrandRequestFilter
        filters={filters}
        onChangeFilter={handleChangeFilter}
        initialFilter={initialProgramAndBrandRequestFilter}
      />
      <PageButtonGroup>
        <Button
          variant="contained"
          color="primary"
          onClick={addNewProgramAndBrandRequest}
          startIcon={<AddIcon />}
          disabled={isDisabled}
        >
          New Program Brand Request
        </Button>
      </PageButtonGroup>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field={'requestNumber'}>
                Request Number
              </SortLabel>
            </TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Requested By</TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field={'createdAt'}>
                Created At
              </SortLabel>
            </TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={7}>
            {programAndBrandRequests.map((programAndBrandRequest, index) => (
              <TableRow key={programAndBrandRequest.id}>
                <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                <TableCell>{programAndBrandRequest.requestNumber}</TableCell>
                <TableCell>{programAndBrandRequest.description}</TableCell>
                <TableCell>
                  {programAndBrandRequest.requestedByUser
                    ? programAndBrandRequest.requestedByUser.fullNameWithUserName
                    : ''}
                </TableCell>
                <TableCell>
                  <DateTimeDisplay value={programAndBrandRequest.createdAt} />
                </TableCell>
                <TableCell>
                  {ProgramAndBrandRequestStatusLabel[programAndBrandRequest.status!]}
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => editProgramAndBrandRequest(programAndBrandRequest.id)}
                    disabled={isDisabled}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[...TablePageSize]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default ProgramAndBrandRequestList;
