import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginTop: 0,
    },
    textBold: {
      fontWeight: theme.typography.fontWeightBold
    }
  })
);

export default useStyles;
