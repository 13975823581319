import { Grid } from '@material-ui/core';
import React from 'react';
import { IProgramApprovalRequestReportFilter } from '../../../../state/report';
import { SearchField } from '../../../components';
import {
  FilterContainer,
  IFilterContainerConfigProps,
} from '../../../components/FilterContainer';

const ProgramApprovalRequestReportFilter: React.FC<
  IFilterContainerConfigProps<IProgramApprovalRequestReportFilter>
> = props => {
  return (
    <FilterContainer
      {...props}
      render={filterProps => {
        const { localFilter, handleChangeFilter } = filterProps;
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                label="Request Number"
                filterKey="ProgramAndBrandRequest.RequestNumber.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                label="Description"
                filterKey="ProgramAndBrandRequest.Description.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                label="RequestedBy"
                filterKey="ProgramAndBrandRequest.RequestedByUser.FullNameWithUserName.Contains"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                label="Program"
                filterKey="Name.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default ProgramApprovalRequestReportFilter;
