import { Card, CardContent, TextField, Typography } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import {
  clearCompany,
  createCompany,
  fetchCompany,
  updateCompany,
} from '../../../state/company/company.actions';
import { Company, CompanyState } from '../../../state/company/company.types';
import { AppState } from '../../../state/configureStore';
import { FixedPositionButtons } from '../../components';
import useStyles from './companyForm.style';

const CompanySchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

interface CompanyFormRouteParamsProps {
  id: string;
}

interface CompanyFormProps extends RouteComponentProps<CompanyFormRouteParamsProps> {}

const CompanyForm: React.FC<CompanyFormProps> = props => {
  const { match, history } = props;
  const {
    params: { id: paramsId },
  } = match;
  const classes = useStyles();
  const {
    item: { name = '', id = 0, externalId = '' },
  } = useSelector<AppState, CompanyState>(state => state.company);
  const dispatch = useDispatch();

  const redirectToCompanyList = () => {
    history.push('/settings/companies');
  };

  const handleSubmit = (values: Company, actions: FormikHelpers<any>) => {
    if (paramsId) {
      dispatch(updateCompany({ values, history }));
    } else {
      dispatch(createCompany({ values, history }));
    }
    actions.setSubmitting(false);
  };

  useEffect(() => {
    if (paramsId) {
      dispatch(fetchCompany(+paramsId));
    } else {
      dispatch(clearCompany());
    }

    return function cleanUp() {
      dispatch(clearCompany());
    };
  }, [paramsId, dispatch]);

  return (
    <Card className={classes.root}>
      <Formik
        enableReinitialize={true}
        initialValues={{ name, id, externalId }}
        validationSchema={CompanySchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, errors, touched, submitForm }) => {
          return (
            <Form>
              <Typography variant="h4" className={classes.title}>
                {paramsId === undefined ? `New Company` : `Update Company`}
              </Typography>
              <CardContent>
                <TextField
                  name="name"
                  type="input"
                  label="Name"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={values.name}
                  onChange={handleChange}
                  error={!!errors.name && !!touched.name}
                  helperText={errors.name}
                />
                <TextField
                  name="externalId"
                  type="input"
                  label="ExternalId"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={values.externalId}
                  onChange={handleChange}
                  error={!!errors.externalId && !!touched.externalId}
                  helperText={errors.externalId}
                />
              </CardContent>
              <FixedPositionButtons
                actions={[
                  {
                    name: paramsId === undefined ? `Create` : `Update`,
                    onClick: () => {
                      submitForm();
                    },
                    color: 'primary' as 'primary',
                    disabled: false,
                    icon: 'save',
                  },
                  {
                    name: 'CANCEL',
                    onClick: redirectToCompanyList,
                    color: 'default' as 'default',
                    disabled: false,
                  },
                ]}
                open
              />
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default CompanyForm;
