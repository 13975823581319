import {
  Button,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearSalesGroup,
  fetchSalesGroupList,
  SalesGroup,
  SalesGroupListFilter,
  SalesGroupState,
} from '../../../state/salesGroup';
import {
  PageContainer,
  PageTitle,
  SearchDropdownField,
  SearchField,
  TableLoading,
} from '../../components';
import { useFilter, useHasRole } from '../../hooks';
import roleRoute from '../roleRoute.config';
import useStyles from './salesGroupList.style';

const SalesGroupList: React.FC<RouteComponentProps> = (props) => {
  const { history } = props;
  const classes = useStyles();
  const isDisabled = !useHasRole(roleRoute.createSalesGroup);
  const [filters, setFilters] = useFilter<SalesGroupListFilter>({
    'Active.EqualsTo': true,
  });
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const {
    items: salesGroups,
    totalItems,
    loading,
  } = useSelector<AppState, SalesGroupState>((state) => state.salesGroup);
  const dispatch = useDispatch();

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchSalesGroupList(filters, paging));
    return function cleanUp() {
      dispatch(clearSalesGroup());
    };
  }, [dispatch, paging, filters]);

  const addNewSalesGroup = (): void => {
    history.push('/settings/salesGroups/form');
  };

  const editSalesGroup = (id: SalesGroup['id']): void => {
    history.push(`/settings/salesGroups/form/${id}`);
  };

  return (
    <PageContainer>
      <PageTitle>Sales Group</PageTitle>
      <div className={classes.filterContainer}>
        <SearchField
          filterKey="Name.startsWith"
          filters={filters}
          label="Name"
          onChange={handleChangeFilter}
        />
        <Grid item xs={12} md={3} lg={2}>
          <SearchDropdownField
            label="Status"
            filterKey={'Active.EqualsTo'}
            filters={filters}
            onChange={handleChangeFilter}
            id="status"
          >
            <MenuItem value={''}>All</MenuItem>
            <MenuItem value={'true'}>Active</MenuItem>
            <MenuItem value={'false'}>Inactive</MenuItem>
          </SearchDropdownField>
        </Grid>

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={addNewSalesGroup}
          size="large"
          disabled={isDisabled}
        >
          <AddIcon className={classes.iconButton} />
          New Sales Group
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={4}>
            {salesGroups.map((salesGroup, index) => (
              <TableRow key={salesGroup.id}>
                <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                <TableCell>{salesGroup.name}</TableCell>
                <TableCell>{salesGroup.description}</TableCell>
                <TableCell>
                  {salesGroup.active ? (
                    <Chip label="Active" color="primary" variant="outlined" />
                  ) : (
                    <Chip label="Inactive" color="secondary" variant="outlined" />
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => editSalesGroup(salesGroup.id)}
                    disabled={isDisabled}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default SalesGroupList;
