import { ISquad } from '../../models/squad.model';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_SQUAD,
  CREATE_SQUAD,
  FETCH_SQUAD,
  FETCH_SQUAD_LIST,
  UPDATE_SQUAD,
} from './squad.actions';
import { SquadState } from './squad.type';

const initialState: SquadState = {
  errorMessage: '',
  item: {} as Readonly<ISquad>,
  items: [] as ReadonlyArray<ISquad>,
  loading: false,
  totalItems: 0,
};

export default (state: SquadState = initialState, action: AppAction): SquadState => {
  switch (action.type) {
    case REQUEST(FETCH_SQUAD_LIST):
    case REQUEST(CREATE_SQUAD):
    case REQUEST(FETCH_SQUAD):
    case REQUEST(UPDATE_SQUAD):
      return { ...state, loading: true };
    case SUCCESS(FETCH_SQUAD_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_SQUAD_LIST):
    case FAILURE(CREATE_SQUAD):
    case FAILURE(FETCH_SQUAD):
    case FAILURE(UPDATE_SQUAD):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_SQUAD):
    case SUCCESS(FETCH_SQUAD):
    case SUCCESS(UPDATE_SQUAD):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_SQUAD:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
