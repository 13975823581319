import { Box, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { CheckBoxFastField, TextFieldFastField } from '../../../components';

interface SampleDevelopmentWlFinishingProps {}

const SampleDevelopmentWlFinishing: React.FC<SampleDevelopmentWlFinishingProps> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Finishing</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>งานเคลือบ(Coating WL):</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="coatingSoft" label="นิ่ม(Soft)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="coatingHard" label="แข็ง(Hard)" />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="ingredientChemical"
                label="สูตรนํ้ายา"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={2}>
              <Typography>ตัด(Cut Type):</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeCool" label="Cool Cut" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeHeat" label="Heat Cut" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeUltrasonic" label="Ultrasonic Cut" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeLaser" label="Laser Cut" />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <Typography>พับ(Fold):</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypePiece" label="ตัดชิ้น" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeFold" label="ตัดพับ" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeRoll" label="ม้วนส่ง" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeTape" label="เทปต่อเนื่อง" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeFoldHead" label="ตัดพับหัวท้าย" />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeFoldHalf" label="ตัดพับครึ่ง" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="cutTypeMitre" label="ตัดพับปีกนก" />
            </Grid>
            <Grid item xs={12} md={6} />
            <Grid item xs={12} md={2}>
              <Typography>Packing:</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingPiece" label="Piece" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingFold" label="Fold" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingRoll" label="Roll" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingTape" label="Tape" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingFoldHead" label="Fold Head Tail" />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingFoldHalf" label="Fold Half" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingMitre" label="Mitre" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingSheet" label="Sheet" />
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentWlFinishing;
