import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransitionProps } from '@material-ui/core/transitions';
import { SampleDevelopmentStatus } from '../../../constants';
import { ISampleDevelopmentTl } from '../../../models/sampleDevelopmentTl.model';
import { AppState } from '../../../state/configureStore';
import {
  SampleDevelopmentTlApprovalRequest,
  SampleDevelopmentTlApprovalRequestState,
  updateSampleDevelopmentTlApprovalRequestResponse,
} from '../../../state/sampleDevelopmentTlApprovalRequest';
import { SampleDevelopmentTlForm } from '../sampleDevelopmentTl';
import useStyles from './SampleDevelopmentTlApprovalRequestDialog.style';

interface SampleDevTlAppRequestDlgProps {
  handleClose: () => void;
  sampleDevelopmentTl: ISampleDevelopmentTl;
  sampleDevelopmentTlApprovalRequest: SampleDevelopmentTlApprovalRequest;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SampleDevelopmentTlApprovalRequestDialog: React.FC<
  SampleDevTlAppRequestDlgProps
> = props => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const { handleClose, sampleDevelopmentTl, sampleDevelopmentTlApprovalRequest } = props;
  const { openDialog } = useSelector<AppState, SampleDevelopmentTlApprovalRequestState>(
    ({ sampleDevelopmentTlApprovalRequest }) => sampleDevelopmentTlApprovalRequest,
  );

  const sampleDevId = sampleDevelopmentTl.id ? sampleDevelopmentTl.id.toString() : '';
  return (
    <>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Assignment Approval
            </Typography>
            <div className={classes.approveButtonGroup}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const responseRequest = { ...sampleDevelopmentTlApprovalRequest };
                  responseRequest.responseResult = SampleDevelopmentStatus.APPROVED;
                  dispatch(
                    updateSampleDevelopmentTlApprovalRequestResponse(responseRequest),
                  );
                }}
              >
                Approve
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => {
                  const responseRequest = { ...sampleDevelopmentTlApprovalRequest };
                  responseRequest.responseResult = SampleDevelopmentStatus.REJECTED;
                  dispatch(
                    updateSampleDevelopmentTlApprovalRequestResponse(responseRequest),
                  );
                }}
              >
                Reject
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <SampleDevelopmentTlForm id={sampleDevId} />
      </Dialog>
    </>
  );
};

export default SampleDevelopmentTlApprovalRequestDialog;
