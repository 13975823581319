import { AppAction, PageFilter } from '../app';
import {
  SampleDevelopmentPlApprovalRequest,
  SampleDevelopmentPlApprovalRequestListFilter,
} from './sampleDevelopmentPlApprovalRequest.types';

export const FETCH_SAMPLE_DEV_PL_APPROVAL_REQUEST_LIST =
  'FETCH_SAMPLE_DEV_PL_APPROVAL_REQUEST_LIST';
export const OPEN_SAMPLE_DEV_PL_APPROVAL_REQUEST_DIALOG =
  'OPEN_SAMPLE_DEV_PL_APPROVAL_REQUEST_DIALOG';

export const CLOSE_SAMPLE_DEV_PL_APPROVAL_REQUEST_DIALOG =
  'CLOSE_SAMPLE_DEV_PL_APPROVAL_REQUEST_DIALOG';

export const UPDATE_SAMPLE_DEV_PL_APPROVAL_REQUEST_RESPONSE =
  'UPDATE_SAMPLE_DEV_PL_APPROVAL_REQUEST_RESPONSE';
export const fetchSampleDevelopmentPlApprovalRequestList = (
  filters: SampleDevelopmentPlApprovalRequestListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SAMPLE_DEV_PL_APPROVAL_REQUEST_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const updateSampleDevelopmentPlApprovalRequestResponse = (
  payload: SampleDevelopmentPlApprovalRequest,
) => ({
  type: UPDATE_SAMPLE_DEV_PL_APPROVAL_REQUEST_RESPONSE,
  payload,
});

export const openSampleDevelopmentPlApprovalRequestDialog = () => ({
  type: OPEN_SAMPLE_DEV_PL_APPROVAL_REQUEST_DIALOG,
});

export const closeSampleDevelopmentPlApprovalRequestDialog = () => ({
  type: CLOSE_SAMPLE_DEV_PL_APPROVAL_REQUEST_DIALOG,
});
