import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import { ISubtaskItemArtTemplate } from '../../models/subtaskItemArtTemplate.model';
import {
  createSubtaskItemArtTemplate,
  deleteSubtaskItemArtTemplate,
  fetchSubtaskItemArtTemplateList,
} from '../../services/subtaskItemArtTemplateService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_SUBTASK_ITEM_ARTTEMPLATES,
  DELETE_SUBTASK_ITEM_ARTTEMPLATES,
  FETCH_SUBTASK_ITEM_ARTTEMPLATE_LIST,
} from './subtaskItemArtTemplate.actions';

function* fetchSubtaskItemArtTemplateListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSubtaskItemArtTemplateList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createSubtaskItemArtTemplatesSaga(action: AppAction) {
  const subtaskItemArtTemplates = action.payload
    .subtaskItemArtTemplates as ISubtaskItemArtTemplate[];
  const subtaskItemId = action.payload.smSubtaskItemId as number;
  yield put({ type: REQUEST(action.type) });
  const result: ISubtaskItemArtTemplate[] = [];

  for (const subtaskItemArtTemplate of subtaskItemArtTemplates) {
    try {
      const { data } = yield call(createSubtaskItemArtTemplate, subtaskItemArtTemplate);
      result.push({ ...data, status: 'Success' });
    } catch (error) {
      // result.push({
      //   ...subtaskItemArtTemplate,
      //   status: 'Error',
      //   errorMessage: error.response.data.message,
      //   hasError: true,
      // });
    }
  }
  yield put({
    type: SUCCESS(action.type),
    payload: { data: result },
  });
  const successMessage = `Created subtask item art templates successfully.`;
  yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  yield call(fetchSubtaskItemArtTemplateListSaga, {
    type: FETCH_SUBTASK_ITEM_ARTTEMPLATE_LIST,
    payload: {
      filters: { 'SmSubtaskItemId.EqualsTo': subtaskItemId },
      paging: { pageNumber: 0, pageSize: 99999 },
    },
  });
}

function* deleteSubtaskItemArtTemplateSaga(action: AppAction) {
  const { payload } = action;
  const { smSubtaskItemArtTemplateId, smSubtaskItemArtId } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(deleteSubtaskItemArtTemplate, smSubtaskItemArtTemplateId);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Deleted subtask item art template successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield call(fetchSubtaskItemArtTemplateListSaga, {
      type: FETCH_SUBTASK_ITEM_ARTTEMPLATE_LIST,
      payload: {
        filters: { 'SmSubtaskItemId.EqualsTo': smSubtaskItemArtId },
        paging: { pageNumber: 0, pageSize: 99999 },
      },
    });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSubtaskItemArtTemplate() {
  yield debounce(
    250,
    FETCH_SUBTASK_ITEM_ARTTEMPLATE_LIST,
    fetchSubtaskItemArtTemplateListSaga,
  );
  yield takeLatest(CREATE_SUBTASK_ITEM_ARTTEMPLATES, createSubtaskItemArtTemplatesSaga);
  yield takeLatest(DELETE_SUBTASK_ITEM_ARTTEMPLATES, deleteSubtaskItemArtTemplateSaga);
}
