import React from 'react';
import { Link } from '@material-ui/core';
import { IOpportunity } from '../../../models/opportunity.model';

interface OpportunityLinkButtonProps {
  opportunity: IOpportunity | null | undefined;
}

const OpportunityLinkButton: React.FC<OpportunityLinkButtonProps> = (props) => {
  const { opportunity } = props;
  return (
    <Link href={`/assignments/form/${opportunity?.id}`} target="_blank" rel="noopener">
      {opportunity?.assignmentNumber}
    </Link>
  );
};

export default OpportunityLinkButton;
