import {
  CardContent,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import {
  CheckBoxFastField,
  TextFieldFastField,
  UnitOfMeasurementsFastField,
  SubstrateComboBox,
} from '../../../components';
import { ISubstrate } from '../../../../models/substrate.model';
import { cloneDeep } from 'lodash';

interface SampleDevelopmentTlMaterialProps {
  handleChange: any;
  values: any;
  setFieldValue: any;
  errors: any;
}

const SampleDevelopmentTlMaterial: React.FC<SampleDevelopmentTlMaterialProps> = (
  props,
) => {
  const { values, setFieldValue } = props;
  const [options, setDefaultOptions] = useState([1]);

  const addOption = () => {
    const newOption = options.length + 1;
    setDefaultOptions([...options, newOption]);
  };
  useEffect(() => {
    const newOptions: number[] = [1];
    if (values.sampleDevelopmentTlSubstrates) {
      values.sampleDevelopmentTlSubstrates.forEach(
        (sampleDevelopmentTlSubstrate: ISubstrate) => {
          const substrateOption = sampleDevelopmentTlSubstrate.substrateOption!;
          if (!newOptions.includes(substrateOption)) {
            newOptions.push(substrateOption);
          }
        },
      );
      setDefaultOptions(newOptions);
    }
  }, [values.sampleDevelopmentTlSubstrates]);

  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Material</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>Raw Material: </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextFieldFastField
                name="substrateOther"
                label="Substrate Other"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel id="primary-option">Primary Option</InputLabel>
                <Select
                  labelId="primary-option"
                  value={values.substratePrimaryOption}
                  onChange={(
                    event: React.ChangeEvent<{
                      value: unknown;
                    }>,
                  ) => {
                    setFieldValue('substratePrimaryOption', event.target.value as string);
                  }}
                >
                  {options.map((option, index) => {
                    return (
                      <MenuItem key={option} value={option}>
                        {index + 1}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={8}>
              {options.map((option, index) => {
                const groupByOptionSampleDevelopmentSubstrates =
                  values.sampleDevelopmentTlSubstrates
                    ? values.sampleDevelopmentTlSubstrates.filter(
                        (sampleDevelopmentTlSubstrate: ISubstrate) => {
                          return sampleDevelopmentTlSubstrate.substrateOption! === option;
                        },
                      )
                    : null;
                return (
                  <SubstrateComboBox
                    key={index}
                    isMulti
                    handleChange={(substrates: ISubstrate[]) => {
                      const cloneSampleDevelopmentTlSubstrates: ISubstrate[] = cloneDeep(
                        values.sampleDevelopmentTlSubstrates,
                      );

                      const sampleDevelopmentTlSubstratesWithNewValue =
                        values.sampleDevelopmentTlSubstrates
                          ? cloneSampleDevelopmentTlSubstrates.filter(
                              (cloneSampleDevelopmentTlSubstrate) => {
                                return (
                                  cloneSampleDevelopmentTlSubstrate.substrateOption !==
                                  option
                                );
                              },
                            )
                          : [];

                      if (substrates !== null) {
                        const substratesWithOption = substrates.map((substrate) => ({
                          ...substrate,
                          substrateOption: option,
                        }));
                        sampleDevelopmentTlSubstratesWithNewValue.push(
                          ...(substratesWithOption as any),
                        );
                      }

                      setFieldValue(
                        'sampleDevelopmentTlSubstrates',
                        sampleDevelopmentTlSubstratesWithNewValue,
                      );
                    }}
                    selectedValue={groupByOptionSampleDevelopmentSubstrates}
                    label={`Substrate ${index + 1}`}
                  />
                );
              })}
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={10}>
              <Button variant="contained" onClick={addOption}>
                Add Option
              </Button>
            </Grid>

            <Grid item xs={12} md={1}>
              <Typography>Size: </Typography>
            </Grid>
            <Grid item xs={12} md={1}>
              <TextFieldFastField
                type="number"
                step="any"
                name="labelDiWidth"
                label="W"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextFieldFastField
                type="number"
                step="any"
                name="labelDiLength"
                label="L"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1} />
            <Grid item xs={12} md={1}>
              <Typography>Finished Size: </Typography>
            </Grid>
            <Grid item xs={12} md={1}>
              <TextFieldFastField
                type="number"
                step="any"
                name="finishSizeWidth"
                label="W"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextFieldFastField
                type="number"
                step="any"
                name="finishSizeLength"
                label="L"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextFieldFastField
                type="number"
                step="any"
                name="finishSizeHigh"
                label="H"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <UnitOfMeasurementsFastField name="finishSizeUnit" label="Unit" />
            </Grid>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={2}>
              <Typography>Customer Type: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="custTypeGarment" label="Garment" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="custTypeSportwear" label="Sportwear" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="custTypeUnderwear" label="Underwear" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="custTypeWorkwear" label="Work Wear" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="custTypeSwimmingSuit" label="Swimming Suit" />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="custTypeSock" label="Sock" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="custTypeShoe" label="Shoe" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="custTypeLeather" label="Leather" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="custTypeElectronic" label="Electronic" />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="custTypeEtc" label="Etc" />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="custTypEtcData"
                label="Etc"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={5} />
            <Grid item xs={12} md={2}>
              <Typography>Substrate Info: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateInfoSample" label="Sample" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField
                name="substrateInfoSpecification"
                label="Specification"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField
                name="substrateInfoTestingStandard"
                label="Testing Standard"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField
                name="substrateInfoTransferStandard"
                label="Transfer Standard"
              />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateInfoEtc" label="Etc" />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="substrateInfoEtcData"
                label="Etc"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={5} />
            <Grid item xs={12} md={2}>
              <Typography>Substrate Type: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateTypeOrganic" label="Organic" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateTypeSynthetic" label="Synthetic" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateTypeLeather" label="Leather" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateTypeFur" label="Fur" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateTypePlastic" label="Plastic" />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateTypeCeramic" label="Ceramic" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateTypeEtc" label="Etc" />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="substrateTypeEtcData"
                label="Etc"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={2}>
              <Typography>Color: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateColorWhite" label="White" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateColorLight" label="Light" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateColorMiddle" label="Middle" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateColorDark" label="Dark" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateColorBlack" label="Black" />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateColorEtc" label="Etc" />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="substrateTypeEtcData"
                label="Etc"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={5} />
            <Grid item xs={12} md={2}>
              <Typography>Elasticity: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateElasticityLow" label="Low" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateElasticityMedium" label="Medium" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateElasticityHigh" label="High" />
            </Grid>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateElasticityEtc" label="Etc" />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="substrateElasticityEtcData"
                label="Etc"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={5} />
            <Grid item xs={12} md={2}>
              <Typography>Surface: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateSurfaceSmooth" label="Smooth" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateSurfaceMedium" label="Medium" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateSurfaceRough" label="Rough" />
            </Grid>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="substrateSurfaceEtc" label="Etc" />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="substrateSurfaceEtcData"
                label="Etc"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentTlMaterial;
