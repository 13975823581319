import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Block } from '../../../../../state/block';
import { NoneQualifiedItemResolution, NoneQualifiedItemResolutionLabel } from '../../../../../constants';

interface ResolveNonQualifiedBlockDialogProps {
  open: boolean;
  onClose: () => void;
  onAnswer: (answer: NoneQualifiedItemResolution) => void;
  blocks: ReadonlyArray<Block>
}

const ResolveNonQualifiedBlockDialog: React.FC<ResolveNonQualifiedBlockDialogProps> = props => {
  const { open, onClose, onAnswer, blocks } = props;
  return <Dialog
    open={open}
    onClose={onClose}
    maxWidth={'xl'}
    fullWidth
  >
    <DialogContent>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Block</TableCell>
            <TableCell>Program</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>Item</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {blocks.map(block => (
            <TableRow key={block.blockNo}>
              <TableCell>{block.blockNo}</TableCell>
              <TableCell>{block.program!.name}</TableCell>
              <TableCell>{block.brand!.name}</TableCell>
              <TableCell>{block.item}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </DialogContent>
    <DialogActions>
      <Button variant={'contained'}
              onClick={() => onAnswer(NoneQualifiedItemResolution.forceCreate)}>{NoneQualifiedItemResolutionLabel[NoneQualifiedItemResolution.forceCreate]}</Button>
      <Button variant={'contained'}
              onClick={() => onAnswer(NoneQualifiedItemResolution.ignore)}>{NoneQualifiedItemResolutionLabel[NoneQualifiedItemResolution.ignore]}</Button>
    </DialogActions>
  </Dialog>;
};

export default ResolveNonQualifiedBlockDialog;

