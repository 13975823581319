import { ChangeLogState, initChangeLogState } from './changeLog.types';
import { AppAction } from '../../state/app/app.types';
import * as actions from './changeLog.actions';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';

const reducer = (
  state: ChangeLogState = initChangeLogState(),
  action: AppAction,
): ChangeLogState => {
  switch (action.type) {
    case REQUEST(actions.FETCH_OPPORTUNITY_LOG):
      return {
        ...state,
        loading: true,
      };
    case SUCCESS(actions.FETCH_OPPORTUNITY_LOG):
      return {
        ...state,
        opportunity: action.payload.data,
        loading: false,
      };
    case FAILURE(actions.FETCH_OPPORTUNITY_LOG):
      return {
        ...state,
        loading: false,
      };
    case actions.CLEAR_OPPORTUNITY_LOG:
      return initChangeLogState();
    default:
      return state;
  }
};

export default reducer;
