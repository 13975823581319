import { CardContent, Grid, Paper, Typography, Box } from '@material-ui/core';
import React from 'react';
import { CheckBoxFastField, TextFieldFastField } from '../../../components';

interface SampleDevelopmentTlDesignProps {}

const SampleDevelopmentTlDesign: React.FC<SampleDevelopmentTlDesignProps> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={3}>
              <Typography>Design</Typography>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              item
              xs={12}
            >
              <Grid item xs={12} md={2}>
                <Typography>Design:</Typography>
              </Grid>
              <Grid item xs={12} md={2} container alignItems="center" spacing={1}>
                <Grid item xs={12} md={1}>
                  <Typography display="inline">1 </Typography>
                </Grid>
                <Grid item xs={12} md={11}>
                  <CheckBoxFastField name="designOwn" label="Own Design" />
                </Grid>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designCustomer" label="Customer Design" />
              </Grid>
              <Grid item xs={12} md={6} />
              <Grid item xs={12} md={2}>
                <Typography>Information:</Typography>
              </Grid>
              <Grid item xs={12} md={2} container alignItems="center" spacing={1}>
                <Grid item xs={12} md={1}>
                  <Typography display="inline">2 </Typography>
                </Grid>
                <Grid item xs={12} md={11}>
                  <CheckBoxFastField name="designInfoOriginal" label="Original" />
                </Grid>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designInfoArtFile" label="Art File" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designInfoSpecsheet" label="Specsheet" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField
                  name="designInfoBlockFilmPlate"
                  label="Block/Film/Plate"
                />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designInfoEtc" label="Etc" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldFastField
                  name="designInfoEtcData"
                  label="Etc"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={2}>
                <Typography>Artwork: </Typography>
              </Grid>
              <Grid item xs={12} md={2} container alignItems="center" spacing={1}>
                <Grid item xs={12} md={1}>
                  <Typography display="inline">3 </Typography>
                </Grid>
                <Grid item xs={12} md={11}>
                  <CheckBoxFastField name="designArtTif" label="TIF" />
                </Grid>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designArtEps" label="EPS" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designArtJpg" label="JPG" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designArtPdf" label="PDF" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designArtAi" label="AI" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designArtQxp" label="QXP" />
              </Grid>
              <Grid item xs={12} md={8} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2} container alignItems="center" spacing={1}>
                <Grid item xs={12} md={1}>
                  <Typography display="inline">4 </Typography>
                </Grid>
                <Grid item xs={12} md={11}>
                  <CheckBoxFastField name="designSoftPagemaker" label="PageMaker" />
                </Grid>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designSoftPhotoshop" label="Photoshop" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designSoftFreehand" label="Free Hand" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designSoftQuark" label="Quark" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designSoftAcrobat" label="Acrobat" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designSoftIllustrator" label="Illustrator" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designSoftEtc" label="Etc" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldFastField
                  name="designSoftEtcData"
                  label="Etc"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentTlDesign;
