import { Button } from '@material-ui/core';
import { Search, Visibility } from '@material-ui/icons';
import React, { useState } from 'react';
import { IProject } from '../../../models/project.model';
import { fetchProjectList } from '../../../services/projectService';
import { ProjectListFilter } from '../../../state/project';
import AsyncAutoComplete from '../AsyncAutoComplete';
import useStyles from './projectComboBox.style';
import ProjectDetailDialog from './ProjectDetailDialog';
import SearchProjectDialog from './SearchProjectDialog';

interface ProjectComboBoxProps {
  handleChange: (result: IProject) => void;
  selectedValue: IProject | undefined;
  isDisabled?: boolean;
  error?: boolean;
  helperText?: string;
  label?: string;
  forceFilter?: ProjectListFilter;
  approved?: boolean;
}

const ProjectComboBox: React.FC<ProjectComboBoxProps> = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const {
    handleChange,
    selectedValue,
    isDisabled = false,
    error,
    helperText,
    label = 'Project',
    forceFilter = {},
    approved,
  } = props;
  const classes = useStyles();
  const status = approved ? 'APPROVED' : '';

  const handleClose = () => {
    setOpenDialog(false);
  };

  const salesTeamPromiseOptions = async (inputValue: string) => {
    const { data: rows } = await fetchProjectList({
      filters: {
        'Name.StartsWith': inputValue,
        'Status.equalsTo': status,
      },
      paging: {
        pageNumber: 0,
        pageSize: 10,
        sort: { orderBy: 'name', direction: 'asc' },
      },
    });
    return rows;
  };

  return (
    <div className={classes.container}>
      <AsyncAutoComplete
        name="Project"
        className={classes.autoComplete}
        value={selectedValue}
        getOptionValue={(option: IProject) => {
          return option.id ? option.id!.toString() : '';
        }}
        getOptionLabel={(option: IProject) => {
          return option.name!!;
        }}
        promiseOptions={salesTeamPromiseOptions}
        placeholder={'Type Project Name'}
        handleChange={handleChange}
        isDisabled={isDisabled}
        error={error}
        helperText={helperText}
        label={label}
      />
      <Button
        className={classes.button}
        color="primary"
        variant="outlined"
        startIcon={<Search />}
        disabled={isDisabled}
        onClick={() => {
          setOpenSearch(true);
        }}
        style={{ marginLeft: 10 }}
      >
        Search
      </Button>
      <Button
        className={classes.button}
        color="primary"
        variant="outlined"
        onClick={() => {
          setOpenDialog(true);
        }}
        disabled={!selectedValue}
        startIcon={<Visibility />}
      >
        Detail
      </Button>
      {selectedValue && (
        <ProjectDetailDialog
          open={openDialog}
          handleClose={handleClose}
          smProjectId={selectedValue.id!}
        />
      )}
      <SearchProjectDialog
        open={openSearch}
        onClose={(project: IProject | null) => {
          if (project) {
            handleChange(project);
          }
          setOpenSearch(false);
        }}
        forceFilter={{ ...forceFilter }}
      />
    </div>
  );
};

export default ProjectComboBox;
