import { copyTextToClipboard, readTextFromClipboard } from './clipboardUtil';
import { v4 as uuidv4 } from 'uuid';
import { GridApi } from 'ag-grid-community';
import { RowMode } from '../views/components/EditableTable/EditableTable';

const lineBreakChar = '\r\n';
const tabChar = '\t';

export const copyToClipboardAsTabDelimited = async (rows: Array<Array<any>>) => {
  const clipboardContent = rows
    .map(row => {
      return row.join(tabChar);
    })
    .join(lineBreakChar);
  await copyTextToClipboard(clipboardContent);
};

export const copyGridToClipboard = async (gridApi: GridApi, headerKeys: string[]) => {
  const rows = [headerKeys] as Array<Array<any>>;
  gridApi.forEachNode(({ data: gridRow }) => {
    const row = [] as Array<any>;
    Object.values(headerKeys).forEach(key => {
      row.push(gridRow[key] ? gridRow[key] : '');
    });
    rows.push(row);
  });
  await copyToClipboardAsTabDelimited(rows);
};

export const pasteGridValueFromClipboard = async (
  gridApi: GridApi,
  headerKeys: string[],
  defaultNewRowValue: any,
  rowsLimit?: number,
): Promise<any> => {
  const rows = await parseClipboardTabDelimitedContent();
  if (rows.length > 0) {
    const headers = rows[0];
    const hasHeaderInClipboard = headers.some(header => headerKeys.includes(header));
    if (hasHeaderInClipboard) {
      rows.splice(0, 1);
    }

    const transformedRows = rows.map(row => {
      const initialRowValue = {
        ...defaultNewRowValue,
        rowId: uuidv4(),
        rowMode: RowMode.Added,
      };
      return headerKeys.reduce((result: any, headerKey, headerIndex) => {
        return { ...result, [headerKey]: row[headerIndex] };
      }, initialRowValue);
    });

    gridApi.setRowData(transformedRows.slice(0, rowsLimit));
  } else {
    gridApi.setRowData([]);
  }
};

export const parseClipboardTabDelimitedContent = async (): Promise<
  Array<Array<string>>
> => {
  const clipboard = await readTextFromClipboard();
  if (clipboard) {
    const rows = clipboard.split(lineBreakChar);
    rows.pop();
    return rows.map(rowContent => rowContent.split(tabChar));
  }

  return [] as any;
};
