import { Chip } from '@material-ui/core';
import React from 'react';

interface ChipListProps {
  list: string[];
  noAllLabel?: boolean;
}

const ChipList: React.FC<ChipListProps> = props => {
  const { list = [], noAllLabel = false } = props;
  if (list.length === 0 && noAllLabel) {
    return <></>;
  } else if (list.length === 0) {
    return <Chip label={'ALL'} variant="outlined" color="primary" />;
  } else {
    return (
      <>
        {list.map(chipLabel => {
          return <Chip key={chipLabel} label={chipLabel} variant="outlined" />;
        })}
      </>
    );
  }
};

export default ChipList;
