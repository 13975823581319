import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import useStyles from './sampleDevelopmentNl.style';
import { SubstrateComboBox, TextFieldFastField } from '../../../components';
import { ISubstrate } from '../../../../models/substrate.model';
import { cloneDeep } from 'lodash';

interface SamDevNlSpecSubstrateProps {
  values: any;
  handleChange: any;
  setFieldValue: any;
}

const SampleDevelopmentNlSpecSubstrate: React.FC<SamDevNlSpecSubstrateProps> = (
  props,
) => {
  const { values, setFieldValue } = props;
  const [options, setDefaultOptions] = useState([1]);
  const classes = useStyles();

  const addOption = () => {
    const newOption = options.length + 1;
    setDefaultOptions([...options, newOption]);
  };

  useEffect(() => {
    const newOptions: number[] = [1];
    if (values.sampleDevelopmentNlSubstrates) {
      values.sampleDevelopmentNlSubstrates.forEach(
        (sampleDevelopmentNlSubstrate: ISubstrate) => {
          const substrateOption = sampleDevelopmentNlSubstrate.substrateOption!;
          if (!newOptions.includes(substrateOption)) {
            newOptions.push(substrateOption);
          }
        },
      );
      setDefaultOptions(newOptions);
    }
  }, [values.sampleDevelopmentNlSubstrates]);

  return (
    <Grid container spacing={1} direction="row" justifyContent="flex-start">
      <Typography className={classes.title}>3. Substrate</Typography>
      <Grid container spacing={1} direction="row" justifyContent="flex-start">
        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={8}>
          <TextFieldFastField
            name="substrateOther"
            label="Substrate Other"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <InputLabel id="primary-option">Primary Option</InputLabel>
            <Select
              labelId="primary-option"
              value={values.substratePrimaryOption}
              onChange={(
                event: React.ChangeEvent<{
                  value: unknown;
                }>,
              ) => {
                setFieldValue('substratePrimaryOption', event.target.value as string);
              }}
            >
              {options.map((option, index) => {
                return (
                  <MenuItem key={option} value={option}>
                    {index + 1}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={8}>
          {options.map((option, index) => {
            const groupByOptionSampleDevelopmentSubstrates =
              values.sampleDevelopmentNlSubstrates
                ? values.sampleDevelopmentNlSubstrates.filter(
                    (sampleDevelopmentNlSubstrate: ISubstrate) => {
                      return sampleDevelopmentNlSubstrate.substrateOption! === option;
                    },
                  )
                : null;
            return (
              <SubstrateComboBox
                key={option}
                isMulti
                handleChange={(substrates: ISubstrate[]) => {
                  const cloneSampleDevelopmentNlSubstrates: ISubstrate[] = cloneDeep(
                    values.sampleDevelopmentNlSubstrates,
                  );

                  const sampleDevelopmentNlSubstratesWithNewValue =
                    values.sampleDevelopmentNlSubstrates
                      ? cloneSampleDevelopmentNlSubstrates.filter(
                          (cloneSampleDevelopmentNlSubstrate) => {
                            return (
                              cloneSampleDevelopmentNlSubstrate.substrateOption !== option
                            );
                          },
                        )
                      : [];

                  if (substrates !== null) {
                    const substratesWithOption = substrates.map((substrate) => ({
                      ...substrate,
                      substrateOption: option,
                    }));
                    sampleDevelopmentNlSubstratesWithNewValue.push(
                      ...(substratesWithOption as any),
                    );
                  }

                  setFieldValue(
                    'sampleDevelopmentNlSubstrates',
                    sampleDevelopmentNlSubstratesWithNewValue,
                  );
                }}
                selectedValue={groupByOptionSampleDevelopmentSubstrates}
                label={`Substrate ${index + 1}`}
              />
            );
          })}
        </Grid>
        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={10}>
          <Button variant="contained" onClick={addOption}>
            Add Option
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SampleDevelopmentNlSpecSubstrate;
