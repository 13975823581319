import { ISampleDevelopmentNl } from '../../models/sampleDevelopmentNl.model';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  FETCH_SAMPLE_DEVELOPMENT_NL_LIST,
  FETCH_SAMPLE_DEVELOPMENT_NL,
  CREATE_SAMPLE_DEVELOPMENT_NL,
  UPDATE_SAMPLE_DEVELOPMENT_NL,
  CLEAR_SAMPLE_DEVELOPMENT_NL,
  SUBMIT_SAMPLE_DEVELOPMENT_NL_FOR_APPROVAL,
} from './sampleDevelopmentNl.actions';
import { SampleDevelopmentNlState } from './sampleDevelopmentNl.types';

const initialState: SampleDevelopmentNlState = {
  errorMessage: '',
  item: {} as Readonly<ISampleDevelopmentNl>,
  items: [] as ISampleDevelopmentNl[],
  loading: false,
  totalItems: 0,
};

export default (
  state: SampleDevelopmentNlState = initialState,
  action: AppAction,
): SampleDevelopmentNlState => {
  switch (action.type) {
    case REQUEST(FETCH_SAMPLE_DEVELOPMENT_NL_LIST):
    case REQUEST(FETCH_SAMPLE_DEVELOPMENT_NL):
    case REQUEST(CREATE_SAMPLE_DEVELOPMENT_NL):
    case REQUEST(UPDATE_SAMPLE_DEVELOPMENT_NL):
      return { ...state, loading: true };
    case SUCCESS(FETCH_SAMPLE_DEVELOPMENT_NL_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_SAMPLE_DEVELOPMENT_NL_LIST):
    case FAILURE(FETCH_SAMPLE_DEVELOPMENT_NL):
    case FAILURE(CREATE_SAMPLE_DEVELOPMENT_NL):
    case FAILURE(UPDATE_SAMPLE_DEVELOPMENT_NL):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(UPDATE_SAMPLE_DEVELOPMENT_NL):
    case SUCCESS(FETCH_SAMPLE_DEVELOPMENT_NL):
    case SUCCESS(CREATE_SAMPLE_DEVELOPMENT_NL):
    case SUCCESS(SUBMIT_SAMPLE_DEVELOPMENT_NL_FOR_APPROVAL):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_SAMPLE_DEVELOPMENT_NL:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
