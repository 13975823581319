export type SquadStatusType =
  | 'PENDING_FOR_APPROVAL'
  | 'IN_REQUEST'
  | 'REJECTED'
  | 'APPROVED';

const SquadStatus = Object.freeze({
  PENDING_FOR_APPROVAL: 'PENDING_FOR_APPROVAL' as SquadStatusType,
  IN_REQUEST: 'IN_REQUEST' as SquadStatusType,
  REJECTED: 'REJECTED' as SquadStatusType,
  APPROVED: 'APPROVED' as SquadStatusType,
});

export default SquadStatus;

export const SquadStatusLabel = Object.freeze({
  [SquadStatus.PENDING_FOR_APPROVAL]: 'Pending For Approval',
  [SquadStatus.IN_REQUEST]: 'In Request',
  [SquadStatus.REJECTED]: 'Rejected',
  [SquadStatus.APPROVED]: 'Approved',
});
