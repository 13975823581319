import { PageFilter } from '../state/app';
import { SubtaskItemChangeStatusLogListFilter } from '../state/subtaskItemChangeStatusLog/subtaskItemChangeStatusLog.types';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/smSubtaskItemChangeStatusLogs`;

export const fetchSubtaskItemChangeStatusLogList = async ({
  filters,
  paging,
}: {
  filters: SubtaskItemChangeStatusLogListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};
