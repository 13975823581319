export type SubtaskItemBlockArtTemplateStatusType = 'PENDING' | 'IN_PROGRESS' | 'DONE';

const SubtaskItemBlockArtTemplateStatus = Object.freeze({
  PENDING: 'PENDING' as SubtaskItemBlockArtTemplateStatusType,
  IN_PROGRESS: 'IN_PROGRESS' as SubtaskItemBlockArtTemplateStatusType,
  DONE: 'DONE' as SubtaskItemBlockArtTemplateStatusType,
});

export default SubtaskItemBlockArtTemplateStatus;

export const SubtaskItemBlockArtTemplateStatusLabel = Object.freeze({
  [SubtaskItemBlockArtTemplateStatus.PENDING]: 'Pending',
  [SubtaskItemBlockArtTemplateStatus.IN_PROGRESS]: 'In Progress',
  [SubtaskItemBlockArtTemplateStatus.DONE]: 'Done',
});
