import { Button } from '@material-ui/core';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ITask } from '../../../../models/task.model';
import { AuthState } from '../../../../state/auth';
import { AppState } from '../../../../state/configureStore';
import { createTaskActivityAndFetchList } from '../../../../state/taskActivity';
import useStyles from './taskActivityComment.style';
import { RichTextEditor } from '../../../components/RichTextEditor';
import { IRichTextEditorRef } from '../../../components/RichTextEditor/RichTextEditor';

interface TaskActivityCommentProps {
  smTaskId: ITask['id'];
  smSubtaskId?: number;
}

const maximumCharactersInCommentBox = 2999;

const TaskActivityComment: React.FC<TaskActivityCommentProps> = props => {
  const { smTaskId, smSubtaskId } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const richTextRef = useRef<IRichTextEditorRef>(null);

  const { userId } = useSelector<AppState, AuthState>(state => state.auth);

  const handleSubmit = () => {
    if (richTextRef.current) {
      const htmlComment = richTextRef.current.getHtmlContent();
      const text = richTextRef.current.getPlainText();
      const hasText = text.trim().length > 0;
      if (hasText) {
        richTextRef.current.clearEditorState();
        dispatch(
          createTaskActivityAndFetchList({
            comment: htmlComment,
            userId,
            smTaskId,
            smSubtaskId,
          }),
        );
      }
    }
  };

  return (
    <>
      <div className={classes.container}>
        <RichTextEditor ref={richTextRef} maxCharacters={maximumCharactersInCommentBox} />
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        className={classes.sendComment}
      >
        Send Comment
      </Button>
    </>
  );
};

export default TaskActivityComment;
