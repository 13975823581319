import { PageFilter } from '../state/app';
import axios from '../utils/axios';
import { ISampleDevelopmentHl } from '../models/sampleDevelopmentHl.model';
import { SampleDevelopmentHlListFilter } from '../state/sampleDevelopmentHl';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';

const baseUrl = `/api/sampleDevelopmentHls`;

export const fetchSampleDevelopmentHlList = async ({
  filters,
  paging,
}: {
  filters: SampleDevelopmentHlListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchSampleDevelopmentHl = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const downloadSampleDevelopmentHl = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}/export`;
  return await axios.get(requestUrl, { responseType: 'blob' });
};

export const createSampleDevelopmentHl = async (
  sampleDevelopmentHl: ISampleDevelopmentHl,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, sampleDevelopmentHl);
};

export const updateSampleDevelopmentHl = async (
  sampleDevelopmentHl: ISampleDevelopmentHl,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, sampleDevelopmentHl);
};

export const submitSampleDevelopmentHlForApproval = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}/submitForApproval`;
  return await axios.post(requestUrl);
};
