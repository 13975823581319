import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createResponsibility,
  updateResponsibility,
  fetchResponsibilityList,
  fetchResponsibility,
  submitForApprovalResponsibility,
  submitForUnlockApprovedResponsibility,
} from '../../services/responsibilityService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_RESPONSIBILITY,
  FETCH_RESPONSIBILITY_LIST,
  UPDATE_RESPONSIBILITY,
  FETCH_RESPONSIBILITY,
  SUBMIT_FOR_APPROVAL_RESPONSIBILITY,
  SUBMIT_FOR_UNLOCK_APPROVED_RESPONSIBILITY,
} from './responsibility.actions';
import { ResponsibilityCreateOrUpdatePayload } from './responsibility.types';
import history from '../../history';

const listPageUrl = `/responsibilities`;
const formPageUrl = `/responsibilities/form`;

function* fetchResponsibilitySaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchResponsibility, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchResponsibilityListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchResponsibilityList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createResponsibilitySaga(action: AppAction) {
  try {
    const { values } = action.payload as ResponsibilityCreateOrUpdatePayload;
    const successMessage = `Created responsibility successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createResponsibility, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(`${formPageUrl}/${data.id}`);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateResponsibilitySaga(action: AppAction) {
  try {
    const { values } = action.payload as ResponsibilityCreateOrUpdatePayload;
    const successMessage = `Updated responsibility successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateResponsibility, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(listPageUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* submitForApprovalResponsibilitySaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const successMessage = `Submit for Approval Responsibility successfully.`;
    const { data } = yield call(submitForApprovalResponsibility, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* submitForUnlockApprovedResponsibilitySaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const successMessage = `Submit for Unlock Approved Responsibility successfully.`;
    const { data } = yield call(
      submitForUnlockApprovedResponsibility,
      action.payload.id,
      action.payload.description,
    );
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchResponsibility() {
  yield takeLatest(FETCH_RESPONSIBILITY, fetchResponsibilitySaga);
  yield debounce(250, FETCH_RESPONSIBILITY_LIST, fetchResponsibilityListSaga);
  yield takeLatest(CREATE_RESPONSIBILITY, createResponsibilitySaga);
  yield takeLatest(UPDATE_RESPONSIBILITY, updateResponsibilitySaga);
  yield takeLatest(
    SUBMIT_FOR_APPROVAL_RESPONSIBILITY,
    submitForApprovalResponsibilitySaga,
  );
  yield takeLatest(
    SUBMIT_FOR_UNLOCK_APPROVED_RESPONSIBILITY,
    submitForUnlockApprovedResponsibilitySaga,
  );
}
