import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_BRAND,
  CREATE_BRAND,
  FETCH_BRAND,
  FETCH_BRAND_LIST,
  UPDATE_BRAND,
} from './brand.actions';
import { Brand, BrandState } from './brand.types';

const getInitialBrandState = (): Brand => ({
  id: 0,
  name: '',
  externalId: '',
});

const initialState: BrandState = {
  errorMessage: '',
  item: getInitialBrandState(),
  items: [],
  loading: false,
  totalItems: 0,
};

export default (state: BrandState = initialState, action: AppAction): BrandState => {
  switch (action.type) {
    case REQUEST(FETCH_BRAND_LIST):
    case REQUEST(CREATE_BRAND):
    case REQUEST(FETCH_BRAND):
    case REQUEST(UPDATE_BRAND):
      return { ...state, loading: true };
    case SUCCESS(FETCH_BRAND_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_BRAND_LIST):
    case FAILURE(CREATE_BRAND):
    case FAILURE(FETCH_BRAND):
    case FAILURE(UPDATE_BRAND):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_BRAND):
    case SUCCESS(FETCH_BRAND):
    case SUCCESS(UPDATE_BRAND):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_BRAND:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
