import {
  applyMiddleware,
  combineReducers,
  createStore,
  Middleware,
  Reducer,
} from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { notificationReducer } from '../views/components/Notification';
import { NotificationState } from '../views/components/Notification/';
import { progressBarReducer, ProgressBarState } from '../views/components/ProgressBar';
import addMultipleBlockDialogReducer from '../views/pages/opportunity/AddMultipleBlockDialog/addMultipleBlockDialog.reducer';
import { AddMultipleBlockDialogState } from '../views/pages/opportunity/AddMultipleBlockDialog/addMultipleBlockDialog.types';
import copyOpportunityReducer from '../views/pages/opportunity/CopyOpportunityDialog/copyOpportunity.reducer';
import { CopyOpportunityState } from '../views/pages/opportunity/CopyOpportunityDialog/copyOpportunity.types';
import { AppAction, appBootstrap, AppBootstrapState } from './app';
import { authReducer, AuthState } from './auth/';
import { blockReducer, BlockState } from './block';
import { hanesProjectReducer, HanesProjectState } from './hanesProject';
import { brandReducer, BrandState } from './brand';
import { companyReducer, CompanyState } from './company';
import { customerReducer, CustomerState } from './customer';
import { customerGroupReducer, CustomerGroupState } from './customerGroup';
import {
  customerGroupMemberReducer,
  CustomerGroupMemberState,
} from './customerGroupMember';
import { eBlockGroupProductReducer, EBlockGroupProductState } from './eBlockGroupProduct';
import { eBlockMaterialReducer, EBlockMaterialState } from './eBlockMaterial';
import { eBlockSizeReducer, EBlockSizeState } from './eBlockSize';
import { opportunityReducer, OpportunityState } from './opportunity';
import {
  opportunityApprovalRequestReducer,
  OpportunityApprovalRequestState,
} from './opportunityApprovalRequest';
import {
  opportunityCustomerReducer,
  OpportunityCustomerState,
} from './opportunityCustomer';
import {
  opportunityCustomerGroupReducer,
  OpportunityCustomerGroupState,
} from './opportunityCustomerGroup';
import { opportunityItemReducer, OpportunityItemState } from './opportunityItem';
import { productTypeReducer, ProductTypeState } from './productType';
import { programReducer, ProgramState } from './program';
import rootSaga from './rootSaga';
import { salesGroupReducer, SalesGroupState } from './salesGroup';
import { salesTeamReducer, SalesTeamState } from './salesTeam';
import { salesTeamMemberReducer, SalesTeamMemberState } from './salesTeamMember';
import { salesTeamRoleReducer, SalesTeamRoleState } from './salesTeamRole';
import { subtaskReducer, SubtaskState } from './subtask';
import { taskReducer, TaskState } from './task';
import { userReducer, UserState } from './user';

import {
  default as pendingApprovalDrawerItemReducer,
  PendingApprovalDrawerItemState,
} from '../views/components/Drawer/PendingApprovalDrawerItem/pendingApprovalDrawerItem.reducer';
import commentDashboardReducer, {
  CommentDashboardState,
} from '../views/pages/dashboard/commentDashboard/commentDashboard.reducer';
import pendingTaskListReducer, {
  PendingTaskListState,
} from '../views/pages/dashboard/PendingTaskList/pendingTaskList.reducer';
import {
  searchItemAndAddMultipleBlockReducer,
  SearchItemAndAddMultipleBlockState,
} from '../views/pages/opportunity/SearchItemAndAddMultipleBlockDialog';
import {
  default as pendingApprovalAssignmentListReducer,
  PendingApprovalAssignmentListState,
} from '../views/pages/pendingApprovalItemList/pendingApprovalAssignmentList/pendingApprovalAssignmentList.reducer';
import {
  default as pendingApprovalProgramBrandRequestListReducer,
  PendingApprovalProgramBrandRequestListState,
} from '../views/pages/pendingApprovalItemList/pendingApprovalProgramBrandRequestList/pendingApprovalProgramBrandRequestList.reducer';
import {
  default as pendingApprovalSampleDevHlRequestListReducer,
  PendingApprovalSampleDevHlRequestListState,
} from '../views/pages/pendingApprovalItemList/pendingApprovalSampleDevHlRequestList/pendingApprovalSampleDevHlRequestList.reducer';
import {
  default as pendingApprovalSampleDevNlRequestListReducer,
  PendingApprovalSampleDevNlRequestListState,
} from '../views/pages/pendingApprovalItemList/pendingApprovalSampleDevNlRequestList/pendingApprovalSampleDevNlRequestList.reducer';
import {
  default as pendingApprovalSampleDevPlRequestListReducer,
  PendingApprovalSampleDevPlRequestListState,
} from '../views/pages/pendingApprovalItemList/pendingApprovalSampleDevPlRequestList/pendingApprovalSampleDevPlRequestList.reducer';
import {
  default as pendingApprovalSampleDevTlRequestListReducer,
  PendingApprovalSampleDevTlRequestListState,
} from '../views/pages/pendingApprovalItemList/pendingApprovalSampleDevTlRequestList/pendingApprovalSampleDevTlRequestList.reducer';
import {
  default as pendingApprovalSampleDevWlRequestListReducer,
  PendingApprovalSampleDevWlRequestListState,
} from '../views/pages/pendingApprovalItemList/pendingApprovalSampleDevWlRequestList/pendingApprovalSampleDevWlRequestList.reducer';
import { adminReducer, AdminState } from './admin';
import { brandRequestItemReducer, BrandRequestItemState } from './brandRequestItem';
import {
  EBlockArtTemplate,
  eBlockArtTemplateReducer,
  EBlockArtTemplateState,
} from './eBlockArtTemplate';
import { eBlockPatternReducer, EBlockPatternState } from './eBlockPattern';
import { notificationUserReducer, NotificationUserState } from './notificationUser';
import {
  programAndBrandRequestReducer,
  ProgramAndBrandRequestState,
} from './programAndBrandRequest';
import { programRequestItemReducer, ProgramRequestItemState } from './programRequestItem';
import { projectReducer, ProjectState } from './project';
import { reportReducer, ReportState } from './report';
import { roleReducer, RoleState } from './role';
import {
  sampleDevelopmentHlReducer,
  SampleDevelopmentHlState,
} from './sampleDevelopmentHl';
import {
  sampleDevelopmentQueueReducer,
  SampleDevelopmentQueueState,
} from './sampleDevelopmentQueue';
import {
  sampleDevelopmentHlApprovalRequestReducer,
  SampleDevelopmentHlApprovalRequestState,
} from './sampleDevelopmentHlApprovalRequest';
import {
  sampleDevelopmentNlReducer,
  SampleDevelopmentNlState,
} from './sampleDevelopmentNl';
import {
  sampleDevelopmentNlApprovalRequestReducer,
  SampleDevelopmentNlApprovalRequestState,
} from './sampleDevelopmentNlApprovalRequest';
import {
  sampleDevelopmentPlReducer,
  SampleDevelopmentPlState,
} from './sampleDevelopmentPl';
import {
  sampleDevelopmentPlApprovalRequestReducer,
  SampleDevelopmentPlApprovalRequestState,
} from './sampleDevelopmentPlApprovalRequest';
import {
  sampleDevelopmentTlReducer,
  SampleDevelopmentTlState,
} from './sampleDevelopmentTl';
import {
  sampleDevelopmentTlApprovalRequestReducer,
  SampleDevelopmentTlApprovalRequestState,
} from './sampleDevelopmentTlApprovalRequest';
import {
  sampleDevelopmentWlReducer,
  SampleDevelopmentWlState,
} from './sampleDevelopmentWl';
import {
  sampleDevelopmentWlApprovalRequestReducer,
  SampleDevelopmentWlApprovalRequestState,
} from './sampleDevelopmentWlApprovalRequest';
import { substrateReducer, SubstrateState } from './substrate';
import { subtaskItemReducer, SubtaskItemState } from './subtaskItem';
import {
  subtaskItemChangeStatusLogReducer,
  SubtaskItemChangeStatusLogState,
} from './subtaskItemChangeStatusLog';
import {
  subtaskItemArtTemplateReducer,
  SubtaskItemArtTemplateState,
} from './subtaskItemArtTemplate';
import {
  subtaskItemForecastReducer,
  SubtaskItemForecastState,
} from './subtaskItemForecast';
import { subtaskItemMoqReducer, SubtaskItemMoqState } from './subtaskItemMoq';
import { subtaskTypeReducer, SubtaskTypeState } from './subtaskType';
import { taskActivityReducer, TaskActivityState } from './taskActivity';
import { taskAttachmentReducer, TaskAttachmentState } from './taskAttachment';
import { taskCreateReducer, TaskCreateState } from './taskCreate';
import { taskItemReducer, TaskItemState } from './taskItem';
import { taskLogWorkReducer, TaskLogWorkState } from './taskLogWork';
import { workingGroupReducer, WorkingGroupState } from './workingGroup';

import { workingTeamReducer, WorkingTeamState } from './workingTeam';
import { workingTeamMemberReducer, WorkingTeamMemberState } from './workingTeamMember';

import { projectCustomerReducer, ProjectCustomerState } from './projectCustomer';
import {
  projectCustomerGroupReducer,
  ProjectCustomerGroupState,
} from './projectCustomerGroup';
import { projectProgramReducer, ProjectProgramState } from './projectProgram';
import { ProjectProgramBrandState } from './projectProgramBrand';
import projectProgramBrandReducer from './projectProgramBrand/projectProgramBrand.reducer';
import { squadReducer, SquadState } from './squad';
import { squadSalesMemberReducer, SquadSalesMemberState } from './squadSalesMember';
import { squadWorkingMemberReducer, SquadWorkingMemberState } from './squadWorkingMember';
import { TeamMemberState, teamMemberReducer } from './teamMember';
import { projectSquadReducer, ProjectSquadState } from './projectSquad';
import { SquadMemberState, squadMemberReducer } from './squadMember';
import { changeLogReducer, ChangeLogState } from './changeLog';
import { changeLogProjectReducer, ChangeLogProjectState } from './changeLogProject';
import {
  projectApprovalRequestReducer,
  ProjectApprovalRequestState,
} from './projectApprovalRequest';
import { responsibilityReducer, ResponsibilityState } from './responsibility';
import {
  responsibilityBrandReducer,
  ResponsibilityBrandState,
} from './responsibilityBrand';
import {
  responsibilityCustomerReducer,
  ResponsibilityCustomerState,
} from './responsibilityCustomer';
import {
  responsibilitySalesTeamMemberReducer,
  ResponsibilitySalesTeamMemberState,
} from './responsibilitySalesTeamMember';
import {
  responsibilityWorkingTeamMemberReducer,
  ResponsibilityWorkingTeamMemberState,
} from './responsibilityWorkingTeamMember';
import pendingApprovalProjectListReducer, {
  PendingApprovalProjectListState,
} from '../views/pages/pendingApprovalItemList/pendingApprovalProjectList/pendingApprovalProjectList.reducer';
import {
  responsibilityApprovalRequestReducer,
  ResponsibilityApprovalRequestState,
} from './responsibilityApprovalRequest';
import pendingApprovalResponsibilityListReducer, {
  PendingApprovalResponsibilityListState,
} from '../views/pages/pendingApprovalItemList/pendingApprovalResponsibilityList/pendingApprovalResponsibilityList.reducer';
import programAndBrandRequestItemReducer from './programAndBrandRequestItem/programAndBrandRequestItem.reducer';
import { ProgramAndBrandRequestItemState } from './programAndBrandRequestItem';

//--Generator Needle1--//

const sagaMiddleware = createSagaMiddleware({
  onError: (error: Error) => {
    console.error(error);
  },
});
const middlewares: Middleware[] = [sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

export interface AppState {
  responsibilityApprovalRequest: ResponsibilityApprovalRequestState;
  appBootstrap: AppBootstrapState;
  auth: AuthState;
  program: ProgramState;
  progressBar: ProgressBarState;
  notification: NotificationState;
  brand: BrandState;
  salesTeam: SalesTeamState;
  user: UserState;
  hanesProject: HanesProjectState;
  salesTeamRole: SalesTeamRoleState;
  salesGroup: SalesGroupState;
  workingGroup: WorkingGroupState;
  workingTeam: WorkingTeamState;
  workingTeamMember: WorkingTeamMemberState;
  eBlockGroupProduct: EBlockGroupProductState;
  eBlockMaterial: EBlockMaterialState;
  eBlockSize: EBlockSizeState;
  eBlockPattern: EBlockPatternState;
  eBlockArtTemplate: EBlockArtTemplateState;
  salesTeamMember: SalesTeamMemberState;
  company: CompanyState;
  customer: CustomerState;
  productType: ProductTypeState;
  block: BlockState;
  opportunity: OpportunityState;
  opportunityCustomer: OpportunityCustomerState;
  opportunityItem: OpportunityItemState;
  opportunityApprovalRequest: OpportunityApprovalRequestState;
  customerGroup: CustomerGroupState;
  customerGroupMember: CustomerGroupMemberState;
  opportunityCustomerGroup: OpportunityCustomerGroupState;
  task: TaskState;
  subtask: SubtaskState;
  addMultipleBlockDialog: AddMultipleBlockDialogState;
  copyOpportunity: CopyOpportunityState;
  taskItem: TaskItemState;
  taskActivity: TaskActivityState;
  taskAttachment: TaskAttachmentState;
  taskLogWork: TaskLogWorkState;
  subtaskType: SubtaskTypeState;
  programAndBrandRequest: ProgramAndBrandRequestState;
  programAndBrandItemRequest: ProgramAndBrandRequestItemState;
  programRequestItem: ProgramRequestItemState;
  brandRequestItem: BrandRequestItemState;
  searchItemAndAddMultipleBlock: SearchItemAndAddMultipleBlockState;
  notificationUser: NotificationUserState;
  subtaskItem: SubtaskItemState;
  subtaskItemChangeStatusLog: SubtaskItemChangeStatusLogState;
  taskCreate: TaskCreateState;
  subtaskItemMoq: SubtaskItemMoqState;
  subtaskItemForecast: SubtaskItemForecastState;
  subtaskItemArtTemplate: SubtaskItemArtTemplateState;
  pendingTaskList: PendingTaskListState;
  commentDashboard: CommentDashboardState;
  admin: AdminState;
  role: RoleState;
  pendingApprovalProgramBrandRequestList: PendingApprovalProgramBrandRequestListState;
  pendingApprovalAssignmentList: PendingApprovalAssignmentListState;
  pendingApprovalSampleDevNlRequestList: PendingApprovalSampleDevNlRequestListState;
  pendingApprovalSampleDevHlRequestList: PendingApprovalSampleDevHlRequestListState;
  pendingApprovalSampleDevPlRequestList: PendingApprovalSampleDevPlRequestListState;
  pendingApprovalSampleDevTlRequestList: PendingApprovalSampleDevTlRequestListState;
  pendingApprovalSampleDevWlRequestList: PendingApprovalSampleDevWlRequestListState;
  pendingApprovalDrawerItem: PendingApprovalDrawerItemState;
  report: ReportState;
  substrate: SubstrateState;
  sampleDevelopmentNl: SampleDevelopmentNlState;
  sampleDevelopmentNlApprovalRequest: SampleDevelopmentNlApprovalRequestState;
  sampleDevelopmentHl: SampleDevelopmentHlState;
  sampleDevelopmentQueue: SampleDevelopmentQueueState;
  sampleDevelopmentHlApprovalRequest: SampleDevelopmentHlApprovalRequestState;
  sampleDevelopmentPl: SampleDevelopmentPlState;
  sampleDevelopmentPlApprovalRequest: SampleDevelopmentPlApprovalRequestState;
  sampleDevelopmentTl: SampleDevelopmentTlState;
  sampleDevelopmentTlApprovalRequest: SampleDevelopmentTlApprovalRequestState;
  sampleDevelopmentWl: SampleDevelopmentWlState;
  sampleDevelopmentWlApprovalRequest: SampleDevelopmentWlApprovalRequestState;
  project: ProjectState;
  projectCustomer: ProjectCustomerState;
  projectCustomerGroup: ProjectCustomerGroupState;
  projectProgram: ProjectProgramState;
  projectProgramBrand: ProjectProgramBrandState;
  projectSquad: ProjectSquadState;
  squad: SquadState;
  squadSalesMember: SquadSalesMemberState;
  squadWorkingMember: SquadWorkingMemberState;
  teamMember: TeamMemberState;
  squadMember: SquadMemberState;
  changeLog: ChangeLogState;
  changeLogProject: ChangeLogProjectState;
  projectApprovalRequest: ProjectApprovalRequestState;
  responsibility: ResponsibilityState;
  responsibilityBrand: ResponsibilityBrandState;
  responsibilityCustomer: ResponsibilityCustomerState;
  pendingApprovalProjectList: PendingApprovalProjectListState;
  responsibilitySalesTeamMember: ResponsibilitySalesTeamMemberState;
  responsibilityWorkingTeamMember: ResponsibilityWorkingTeamMemberState;
  pendingApprovalResponsibilityList: PendingApprovalResponsibilityListState;
  //--Generator Needle2--//
}

const rootReducer: Reducer<AppState, AppAction> = combineReducers({
  appBootstrap: appBootstrap,
  auth: authReducer,
  program: programReducer,
  progressBar: progressBarReducer,
  notification: notificationReducer,
  brand: brandReducer,
  hanesProject: hanesProjectReducer,
  salesTeam: salesTeamReducer,
  user: userReducer,
  salesTeamRole: salesTeamRoleReducer,
  salesGroup: salesGroupReducer,
  workingGroup: workingGroupReducer,
  workingTeam: workingTeamReducer,
  workingTeamMember: workingTeamMemberReducer,
  eBlockGroupProduct: eBlockGroupProductReducer,
  eBlockMaterial: eBlockMaterialReducer,
  eBlockSize: eBlockSizeReducer,
  eBlockArtTemplate: eBlockArtTemplateReducer,
  eBlockPattern: eBlockPatternReducer,
  salesTeamMember: salesTeamMemberReducer,
  company: companyReducer,
  customer: customerReducer,
  productType: productTypeReducer,
  block: blockReducer,
  opportunity: opportunityReducer,
  opportunityCustomer: opportunityCustomerReducer,
  opportunityItem: opportunityItemReducer,
  opportunityApprovalRequest: opportunityApprovalRequestReducer,
  customerGroup: customerGroupReducer,
  customerGroupMember: customerGroupMemberReducer,
  opportunityCustomerGroup: opportunityCustomerGroupReducer,
  addMultipleBlockDialog: addMultipleBlockDialogReducer,
  copyOpportunity: copyOpportunityReducer,
  task: taskReducer,
  subtask: subtaskReducer,
  taskItem: taskItemReducer,
  taskActivity: taskActivityReducer,
  taskAttachment: taskAttachmentReducer,
  taskLogWork: taskLogWorkReducer,
  subtaskType: subtaskTypeReducer,
  programAndBrandRequest: programAndBrandRequestReducer,
  programAndBrandItemRequest: programAndBrandRequestItemReducer,
  programRequestItem: programRequestItemReducer,
  brandRequestItem: brandRequestItemReducer,
  searchItemAndAddMultipleBlock: searchItemAndAddMultipleBlockReducer,
  notificationUser: notificationUserReducer,
  subtaskItem: subtaskItemReducer,
  subtaskItemChangeStatusLog: subtaskItemChangeStatusLogReducer,
  taskCreate: taskCreateReducer,
  subtaskItemMoq: subtaskItemMoqReducer,
  subtaskItemForecast: subtaskItemForecastReducer,
  subtaskItemArtTemplate: subtaskItemArtTemplateReducer,
  pendingTaskList: pendingTaskListReducer,
  commentDashboard: commentDashboardReducer,
  admin: adminReducer,
  role: roleReducer,
  pendingApprovalProgramBrandRequestList: pendingApprovalProgramBrandRequestListReducer,
  pendingApprovalAssignmentList: pendingApprovalAssignmentListReducer,
  pendingApprovalSampleDevNlRequestList: pendingApprovalSampleDevNlRequestListReducer,
  pendingApprovalSampleDevHlRequestList: pendingApprovalSampleDevHlRequestListReducer,
  pendingApprovalSampleDevPlRequestList: pendingApprovalSampleDevPlRequestListReducer,
  pendingApprovalSampleDevTlRequestList: pendingApprovalSampleDevTlRequestListReducer,
  pendingApprovalSampleDevWlRequestList: pendingApprovalSampleDevWlRequestListReducer,
  pendingApprovalDrawerItem: pendingApprovalDrawerItemReducer,
  report: reportReducer,
  substrate: substrateReducer,
  sampleDevelopmentNl: sampleDevelopmentNlReducer,
  sampleDevelopmentNlApprovalRequest: sampleDevelopmentNlApprovalRequestReducer,
  sampleDevelopmentHl: sampleDevelopmentHlReducer,
  sampleDevelopmentHlApprovalRequest: sampleDevelopmentHlApprovalRequestReducer,
  sampleDevelopmentPl: sampleDevelopmentPlReducer,
  sampleDevelopmentPlApprovalRequest: sampleDevelopmentPlApprovalRequestReducer,
  sampleDevelopmentTl: sampleDevelopmentTlReducer,
  sampleDevelopmentTlApprovalRequest: sampleDevelopmentTlApprovalRequestReducer,
  sampleDevelopmentWl: sampleDevelopmentWlReducer,
  sampleDevelopmentWlApprovalRequest: sampleDevelopmentWlApprovalRequestReducer,
  sampleDevelopmentQueue: sampleDevelopmentQueueReducer,
  project: projectReducer,
  projectCustomer: projectCustomerReducer,
  projectCustomerGroup: projectCustomerGroupReducer,
  projectProgram: projectProgramReducer,
  projectProgramBrand: projectProgramBrandReducer,
  projectSquad: projectSquadReducer,
  squad: squadReducer,
  squadSalesMember: squadSalesMemberReducer,
  squadWorkingMember: squadWorkingMemberReducer,
  teamMember: teamMemberReducer,
  squadMember: squadMemberReducer,
  changeLog: changeLogReducer,
  changeLogProject: changeLogProjectReducer,
  projectApprovalRequest: projectApprovalRequestReducer,
  pendingApprovalProjectList: pendingApprovalProjectListReducer,
  responsibility: responsibilityReducer,
  responsibilityBrand: responsibilityBrandReducer,
  responsibilityCustomer: responsibilityCustomerReducer,
  responsibilitySalesTeamMember: responsibilitySalesTeamMemberReducer,
  responsibilityWorkingTeamMember: responsibilityWorkingTeamMemberReducer,
  responsibilityApprovalRequest: responsibilityApprovalRequestReducer,
  pendingApprovalResponsibilityList: pendingApprovalResponsibilityListReducer,
  //--Generator Needle3--//
});

export default function configureStore(preloadedState: any) {
  return {
    ...createStore(rootReducer, preloadedState, applyMiddleware(...middlewares)),
    runSaga: sagaMiddleware.run(rootSaga),
  };
}
