import { PageFilter } from '../state/app';
import {
  WorkingTeamMember,
  WorkingTeamMemberListFilter,
} from '../state/workingTeamMember';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/smWorkingTeamMembers`;

export const fetchWorkingTeamMemberList = async ({
  filters,
  paging,
}: {
  filters: WorkingTeamMemberListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchWorkingTeamMemberNotOwnerList = async ({
  filters,
  paging,
}: {
  filters: WorkingTeamMemberListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/findAllNotSSOOwnerAndCSOwner`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const createWorkingTeamMember = async (workingTeamMember: WorkingTeamMember) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, workingTeamMember);
};

export const updateWorkingTeamMember = async (workingTeamMember: WorkingTeamMember) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, workingTeamMember);
};

export const deleteWorkingTeamMember = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};
