import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { PageFilter } from '../../../state/app';
import {
  Block,
  BlockListFilter,
  BlockState,
  clearBlock,
  fetchEBlockList,
} from '../../../state/block';
import { AppState } from '../../../state/configureStore';
import {
  DateTimeDisplay,
  PageContainer,
  PageTitle,
  SortLabel,
  TableLoading,
} from '../../components';
import { useFilter, useHasRole, useTableSorting } from '../../hooks';
import BlockFilter from '../block/BlockFilter';
import roleRoute from '../roleRoute.config';
import useStyles from './eBlock.style';

const initialBlockListFilter: BlockListFilter = {};

const EBlockList: React.FC<RouteComponentProps> = (props) => {
  const { history } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filters, setFilters] = useFilter<BlockListFilter>(initialBlockListFilter);
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });
  const [sort, setSort] = useTableSorting({
    orderBy: 'blockNo',
    direction: 'desc',
  });
  const isDisabled = !useHasRole(roleRoute.createEBlock);
  const {
    items: eBlocks,
    totalItems,
    loading,
  } = useSelector<AppState, BlockState>((state) => state.block);

  useEffect(() => {
    dispatch(fetchEBlockList(filters, { ...paging, sort }));
    return function cleanUp() {
      dispatch(clearBlock());
    };
  }, [dispatch, paging, filters, sort]);

  const handleChangeFilter = (newFilter: BlockListFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const editEBlock = (id: Block['id']): void => {
    history.push(`/settings/eBlocks/form/${id}`);
  };

  return (
    <PageContainer>
      <PageTitle>EBlock</PageTitle>
      <BlockFilter
        filters={filters}
        onChangeFilter={handleChangeFilter}
        initialFilter={initialBlockListFilter}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="blockNo">
                EBlock No
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={sort}
                handleSortChange={setSort}
                field="eBlockGroupProduct.description"
              >
                Group Product
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={sort}
                handleSortChange={setSort}
                field="eBlockMaterial.description"
              >
                Material
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={sort}
                handleSortChange={setSort}
                field="eBlockSize.description"
              >
                Size
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={sort}
                handleSortChange={setSort}
                field="eBlockPattern.description"
              >
                Pattern
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="program.name">
                Program
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="brand.name">
                Brand
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="item">
                Item
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="itemDescription">
                Item Description
              </SortLabel>
            </TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={5}>
            {eBlocks.map((eBlock, index) => (
              <TableRow key={eBlock.id}>
                <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                <TableCell>{eBlock.blockNo}</TableCell>
                <TableCell>{eBlock.eBlockGroupProduct!!.description}</TableCell>
                <TableCell>{eBlock.eBlockMaterial!!.description}</TableCell>
                <TableCell>{eBlock.eBlockSize!!.description}</TableCell>
                <TableCell>{eBlock.eBlockPattern!!.description}</TableCell>
                <TableCell>{eBlock.program!!.name}</TableCell>
                <TableCell>{eBlock.brand!!.name}</TableCell>
                <TableCell>{eBlock.item}</TableCell>
                <TableCell>{eBlock.itemDescription}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    disabled={isDisabled}
                    onClick={() => editEBlock(eBlock.id)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default EBlockList;
