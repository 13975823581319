import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createWorkingTeam,
  fetchWorkingTeam,
  fetchWorkingTeamList,
  updateWorkingTeam,
} from '../../services/workingTeamService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_WORKING_TEAM,
  FETCH_WORKING_TEAM,
  FETCH_WORKING_TEAM_LIST,
  UPDATE_WORKING_TEAM,
} from './workingTeam.actions';
import { WorkingTeamCreateOrUpdatePayload } from './workingTeam.type';

const listPageUrl = `/settings/workingTeams`;

function* fetchWorkingTeamListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchWorkingTeamList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchWorkingTeamSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchWorkingTeam, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createWorkingTeamSaga(action: AppAction) {
  try {
    const { values, history } = action.payload as WorkingTeamCreateOrUpdatePayload;
    const successMessage = `Created working team successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createWorkingTeam, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    const formUrl = `${listPageUrl}/form/${data.id}`;
    history.push(formUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateWorkingTeamSaga(action: AppAction) {
  try {
    const { values } = action.payload as WorkingTeamCreateOrUpdatePayload;
    const successMessage = `Updated ${values.name} working team successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateWorkingTeam, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchWorkingGroup() {
  yield debounce(250, FETCH_WORKING_TEAM_LIST, fetchWorkingTeamListSaga);
  yield takeLatest(FETCH_WORKING_TEAM, fetchWorkingTeamSaga);
  yield takeLatest(CREATE_WORKING_TEAM, createWorkingTeamSaga);
  yield takeLatest(UPDATE_WORKING_TEAM, updateWorkingTeamSaga);
}
