import { PageFilter } from '../state/app';
import { NotificationUserFilter } from '../state/notificationUser/notificationUser.types';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/smNotificationUsers`;

export const fetchNotificationUser = async ({
  filters,
  paging,
}: {
  filters: NotificationUserFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  //const res = await axios.get(`${requestUrl}?${params.toString()}`);
  const res = await axios.get(`${requestUrl}?${params.toString()}`);
  return res;
};
