export type SubtaskStatusType =
  | 'PENDING'
  | 'IN_PROGRESS'
  | 'WAITING_FOR_SALES'
  | 'WAITING_FOR_CUSTOMER'
  | 'REJECT'
  | 'CUSTOMER_REJECT'
  | 'CLOSE'
  | 'DONE'
  | 'WAITING_FOR_NPD'
  | 'APPROVE_BY_NPD';

const SubtaskStatus = Object.freeze({
  PENDING: 'PENDING' as SubtaskStatusType,
  IN_PROGRESS: 'IN_PROGRESS' as SubtaskStatusType,
  WAITING_FOR_SALES: 'WAITING_FOR_SALES' as SubtaskStatusType,
  WAITING_FOR_CUSTOMER: 'WAITING_FOR_CUSTOMER' as SubtaskStatusType,
  REJECT: 'REJECT' as SubtaskStatusType,
  CUSTOMER_REJECT: 'CUSTOMER_REJECT' as SubtaskStatusType,
  CLOSE: 'CLOSE' as SubtaskStatusType,
  DONE: 'DONE' as SubtaskStatusType,
  WAITING_FOR_NPD: 'WAITING_FOR_NPD' as SubtaskStatusType,
  APPROVE_BY_NPD: 'APPROVE_BY_NPD' as SubtaskStatusType,
});

export default SubtaskStatus;

export const SubtaskStatusLabel = Object.freeze({
  [SubtaskStatus.PENDING]: 'Pending',
  [SubtaskStatus.IN_PROGRESS]: 'In Progress',
  [SubtaskStatus.WAITING_FOR_SALES]: 'Waiting for Sales',
  [SubtaskStatus.WAITING_FOR_CUSTOMER]: 'Waiting for Customer',
  [SubtaskStatus.REJECT]: 'Rejected',
  [SubtaskStatus.CUSTOMER_REJECT]: 'Customer Rejected',
  [SubtaskStatus.CLOSE]: 'Closed',
  [SubtaskStatus.DONE]: 'Done',
  [SubtaskStatus.WAITING_FOR_NPD]: 'Waiting NPD',
  [SubtaskStatus.APPROVE_BY_NPD]: 'Approve by NPD',
});
