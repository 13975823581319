import { PageFilter } from '../state/app';
import {
  ProgramAndBrandRequest,
  ProgramAndBrandRequestListFilter,
} from '../state/programAndBrandRequest';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/programAndBrandRequests`;

export const fetchProgramAndBrandRequestList = async ({
  filters,
  paging,
}: {
  filters: ProgramAndBrandRequestListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchProgramAndBrandRequest = async (id: ProgramAndBrandRequest['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createProgramAndBrandRequest = async (
  programAndBrandRequest: ProgramAndBrandRequest,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, programAndBrandRequest);
};

export const updateProgramAndBrandRequest = async (
  programAndBrandRequest: ProgramAndBrandRequest,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, programAndBrandRequest);
};
