import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import { IProject } from '../../models/project.model';
import {
  createProjectSquad,
  deleteProjectSquad,
  fetchProjectSquad,
  fetchProjectSquadList,
  updateProjectSquad,
} from '../../services/projectSquadService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import { AppState } from '../configureStore';
import {
  CREATE_PROJECT_SQUAD,
  CREATE_PROJECT_SQUADS,
  DELETE_PROJECT_SQUAD,
  FETCH_PROJECT_SQUAD,
  FETCH_PROJECT_SQUAD_LIST,
  fetchProjectSquadListBySmProjectId,
  UPDATE_PROJECT_SQUAD,
} from './projectSquad.actions';
import { ProjectSquadCreateOrUpdatePayload } from './projectSquad.types';

function* fetchProjectSquadSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchProjectSquad, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchProjectSquadListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchProjectSquadList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createProjectSquadSaga(action: AppAction) {
  try {
    const { values } = action.payload as ProjectSquadCreateOrUpdatePayload;
    const successMessage = `Created project squad successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createProjectSquad, values);
    yield put(fetchProjectSquadListBySmProjectId(data.smProjectId));
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createProjectSquadsSaga(action: AppAction) {
  try {
    const { payload, callback } = action;

    yield put({ type: REQUEST(action.type) });
    for (const opCustGrp of payload) {
      yield call(createProjectSquad, opCustGrp);
    }

    const successMessage = `Created project squad successfully.`;
    yield put(fetchProjectSquadListBySmProjectId(payload[0].smProjectId));

    yield put({ type: SUCCESS(action.type), payload });

    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));

    if (callback) {
      callback();
    }
  } catch (error) {
    console.error(error);
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateProjectSquadSaga(action: AppAction) {
  try {
    const { values } = action.payload as ProjectSquadCreateOrUpdatePayload;
    const successMessage = `Updated project squad successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateProjectSquad, values);
    yield put(fetchProjectSquadListBySmProjectId(data.smProjectId));
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteProjectSquadSaga(action: AppAction) {
  const { payload } = action;
  const { id } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    yield call(deleteProjectSquad, id);
    const project = yield select(getProjectFromState);
    if (project.id) {
      yield put(fetchProjectSquadListBySmProjectId(project.id));
    }
    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Deleted successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function getProjectFromState(state: AppState): IProject {
  const { item } = state.project;
  return item;
}

export default function* watchProjectSquad() {
  yield takeLatest(FETCH_PROJECT_SQUAD, fetchProjectSquadSaga);
  yield debounce(250, FETCH_PROJECT_SQUAD_LIST, fetchProjectSquadListSaga);
  yield takeLatest(CREATE_PROJECT_SQUAD, createProjectSquadSaga);
  yield takeLatest(UPDATE_PROJECT_SQUAD, updateProjectSquadSaga);
  yield takeLatest(DELETE_PROJECT_SQUAD, deleteProjectSquadSaga);
  yield takeLatest(CREATE_PROJECT_SQUADS, createProjectSquadsSaga);
}
