import { AppAction, PageFilter } from '../app';
import {
  ProjectApprovalRequest,
  ProjectApprovalRequestCreateOrUpdatePayload,
  ProjectApprovalRequestListFilter,
  ProjectApprovalResponse,
} from './projectApprovalRequest.types';

export const FETCH_PROJECT_APPROVAL_REQUEST = 'FETCH_PROJECT_APPROVAL_REQUEST';
export const FETCH_PROJECT_APPROVAL_REQUEST_LIST = 'FETCH_PROJECT_APPROVAL_REQUEST_LIST';
export const CREATE_PROJECT_APPROVAL_REQUEST = 'CREATE_PROJECT_APPROVAL_REQUEST';
export const UPDATE_PROJECT_APPROVAL_REQUEST = 'UPDATE_PROJECT_APPROVAL_REQUEST';
export const CLEAR_PROJECT_APPROVAL_REQUEST = 'CLEAR_PROJECT_APPROVAL_REQUEST';
export const UPDATE_PROJECT_APPROVAL_RESPONSE = 'UPDATE_PROJECT_APPROVAL_RESPONSE';
export const OPEN_PROJECT_APPROVAL_REQUEST_DIALOG =
  'OPEN_PROJECT_APPROVAL_REQUEST_DIALOG';
export const CLOSE_PROJECT_APPROVAL_REQUEST_DIALOG =
  'CLOSE_PROJECT_APPROVAL_REQUEST_DIALOG';

export const fetchProjectApprovalRequest = (
  id: ProjectApprovalRequest['id'],
): AppAction => ({
  type: FETCH_PROJECT_APPROVAL_REQUEST,
  payload: id,
});

export const fetchProjectApprovalRequestList = (
  filters: ProjectApprovalRequestListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_PROJECT_APPROVAL_REQUEST_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchProjectApprovalRequestListBySmProjectId = (
  projectId: number,
): AppAction => ({
  type: FETCH_PROJECT_APPROVAL_REQUEST_LIST,
  payload: {
    filters: { 'SmProjectId.equalsTo': projectId },
    paging: { pageNumber: 0, pageSize: 99999 },
  },
});

export const createProjectApprovalRequest = (
  payload: ProjectApprovalRequestCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_PROJECT_APPROVAL_REQUEST,
  payload,
});

export const updateProjectApprovalRequest = (
  payload: ProjectApprovalRequestCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_PROJECT_APPROVAL_REQUEST,
  payload,
});

export const clearProjectApprovalRequest = () => ({
  type: CLEAR_PROJECT_APPROVAL_REQUEST,
});

export const updateProjectApprovalResponse = (payload: ProjectApprovalResponse) => ({
  type: UPDATE_PROJECT_APPROVAL_RESPONSE,
  payload,
});

export const openProjectApprovalRequestDialog = () => ({
  type: OPEN_PROJECT_APPROVAL_REQUEST_DIALOG,
});
export const closeProjectApprovalRequestDialog = () => ({
  type: CLOSE_PROJECT_APPROVAL_REQUEST_DIALOG,
});
