import { call, put, takeLatest } from 'redux-saga/effects';
import { syncUserFromLdap } from '../../services/adminService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import { SYNC_USER_FROM_LDAP } from './admin.actions';

function* syncUserFromLdapSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    yield call(syncUserFromLdap);
    yield put({ type: SUCCESS(action.type) });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchAdmin() {
  yield takeLatest(SYNC_USER_FROM_LDAP, syncUserFromLdapSaga);
}
