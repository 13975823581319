import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import { createOpportunityCustomerGroups } from '../../../state/opportunityCustomerGroup';
import {
  CustomerGroup,
  CustomerGroupListFilter,
  CustomerGroupState,
  fetchCustomerGroupList,
} from '../../../state/customerGroup';
import { SearchField, TableLoading } from '../../components';
import CustomerGroupMemberDialog from '../../components/CustomerGroupComboBox/CustomerGroupMemberDialog';

interface IAddOpportunityCustomerGroupDialogProps {
  open: boolean;
  onClose: () => void;
  companyId: number;
  opportunityId: number;
}

const AddOpportunityCustomerGroupDialog: React.FC<
  IAddOpportunityCustomerGroupDialogProps
> = (props) => {
  const { open, onClose, companyId, opportunityId } = props;
  const dispatch = useDispatch();
  const {
    items: customerGroups,
    totalItems,
    loading,
  } = useSelector<AppState, CustomerGroupState>((state) => state.customerGroup);

  const [filters, setFilters] = useState<CustomerGroupListFilter>({});
  const [selectedCustomerGroups, setSelectedCustomerGroups] = useState<CustomerGroup[]>(
    [],
  );
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCustGrpId, setSelectedCustGrpId] = useState<number>(0);

  useEffect(() => {
    if (open) {
      dispatch(fetchCustomerGroupList(filters, paging));
    }
  }, [dispatch, filters, paging, open]);

  useEffect(() => {
    setFilters((filters) => {
      return { ...filters, 'CompanyId.equalsTo': companyId };
    });
  }, [setFilters, companyId]);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const resetForm = () => {
    onClose();
    setSelectedCustomerGroups([]);
    setPaging({
      pageNumber: 0,
      pageSize: 15,
    });
    setFilters({
      'CompanyId.EqualsTo': companyId,
    });
  };

  const handleSubmit = () => {
    const submitValue = selectedCustomerGroups.map((custGrp) => {
      return {
        customerGroupId: custGrp.id,
        opportunityId,
      };
    });
    dispatch(createOpportunityCustomerGroups(submitValue, resetForm));
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  return (
    <div>
      <CustomerGroupMemberDialog
        handleClose={() => {
          setOpenDialog(false);
        }}
        open={openDialog}
        customerGroupId={selectedCustGrpId}
      />

      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth={true}>
        <DialogTitle>Select Customer Group</DialogTitle>
        <DialogContent>
          <SearchField
            label="Customer Group Name"
            onChange={handleChangeFilter}
            filterKey="Name.startsWith"
            filters={filters}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked) => {
                      if (checked) {
                        const isItemAdded = (customerGroup: CustomerGroup) => {
                          return (
                            selectedCustomerGroups.findIndex(
                              (cg) => cg.id! === customerGroup.id,
                            ) === -1
                          );
                        };
                        const itemToAdd = customerGroups.filter(isItemAdded);
                        setSelectedCustomerGroups([
                          ...selectedCustomerGroups,
                          ...itemToAdd,
                        ]);
                      } else {
                        setSelectedCustomerGroups([]);
                      }
                    }}
                  ></Checkbox>
                </TableCell>
                <TableCell>No.</TableCell>
                <TableCell>Customer Group Name</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableLoading isLoading={loading} colSpan={4}>
                {customerGroups.map((custGrp, index) => {
                  const isCheckboxChecked =
                    selectedCustomerGroups.findIndex((cg) => cg.id === custGrp.id) !== -1;
                  return (
                    <TableRow key={custGrp.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                            checked,
                          ) => {
                            if (checked) {
                              setSelectedCustomerGroups([
                                ...selectedCustomerGroups,
                                custGrp,
                              ]);
                            } else {
                              const newSelectedCustomerGroups = [
                                ...selectedCustomerGroups,
                              ];
                              const selectedIndex = selectedCustomerGroups.findIndex(
                                (cg) => cg.id! === custGrp.id,
                              );
                              newSelectedCustomerGroups.splice(selectedIndex, 1);
                              setSelectedCustomerGroups(newSelectedCustomerGroups);
                            }
                          }}
                          checked={isCheckboxChecked}
                        ></Checkbox>
                      </TableCell>
                      <TableCell>
                        {index + paging.pageSize * paging.pageNumber + 1}
                      </TableCell>
                      <TableCell>{custGrp.name}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setOpenDialog(true);
                            setSelectedCustGrpId(custGrp.id!);
                          }}
                        >
                          View Member
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableLoading>
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[15, 25, 35]}
            component="div"
            count={totalItems}
            rowsPerPage={paging.pageSize}
            page={paging.pageNumber}
            onPageChange={handleChangePageNumber}
            onRowsPerPageChange={handleChangePageSize}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={selectedCustomerGroups.length <= 0}
            onClick={handleSubmit}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              resetForm();
            }}
            color="default"
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddOpportunityCustomerGroupDialog;
