import {
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearSubtask,
  fetchSubtaskList,
  SubtaskListFilter,
  SubtaskState,
} from '../../../state/subtask';
import { PageContainer, PageTitle, TableLoading } from '../../components';
import { SortLabel, TaskNumberLinkButton, UserLetterAvatar } from '../../components/';
import { useFilter, useTableSorting } from '../../hooks';
import { DateDisplay, DateTimeDisplay } from '../../components/DateTimeDisplay';
import { SubtaskStatusLabel } from '../../../constants';
import TaskSubtaskFilter from './TaskSubtaskFilter';
import { ISubtask } from '../../../models/subtask.model';

const TaskSubtaskList: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const initialSubtaskFilter: SubtaskListFilter = {
    'SMTask.TaskNumber.startsWith': '',
    'SMTask.Subject.startsWith': '',
    'SMTask.Status.EqualsTo': '',
    'Status.EqualsTo': '',
    'StartDueDate.GreaterThanOrEqual': moment().startOf('month').format('YYYY-MM-DD'),
    'EndDueDate.LessThanOrEqual': moment()
      .add(2, 'month')
      .endOf('month')
      .format('YYYY-MM-DD'),
    'SmSubtaskAssignees.User.FullNameWithUserName.contains': '',
    'SmSubTaskType.Code.EqualsTo': '',
  };

  const [filters, setFilters] = useFilter<SubtaskListFilter>(initialSubtaskFilter);
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const [sort, setSort] = useTableSorting({
    orderBy: 'SMTask.taskNumber',
    direction: 'asc',
  });

  const {
    items: subtasks,
    totalItems,
    loading,
  } = useSelector<AppState, SubtaskState>((state) => state.subtask);

  const handleChangeFilter = (newFilter: SubtaskListFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchSubtaskList(filters, { ...paging, sort }));
  }, [dispatch, paging, filters, sort]);

  useEffect(() => {
    return function cleanUp() {
      dispatch(clearSubtask());
    };
  }, [dispatch]);

  const editsubtask = (subtask: ISubtask): void => {
    history.push(`/tasks/form/${subtask.smTask!.id!}?subtask=${subtask.id}`);
  };

  return (
    <PageContainer>
      <PageTitle>Subtask</PageTitle>
      <TaskSubtaskFilter
        filters={filters}
        onChangeFilter={handleChangeFilter}
        initialFilter={initialSubtaskFilter}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="SMTask.taskNumber">
                Task Number
              </SortLabel>
            </TableCell>
            <TableCell>Subject</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Requester</TableCell>
            <TableCell>Assignee</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="dueDate">
                Due Date
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="createdAt">
                Created At
              </SortLabel>
            </TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={10}>
            {subtasks.map((subtask, index) => {
              const assigneeUsers =
                subtask.smSubtaskAssignees!.length > 0
                  ? subtask.smSubtaskAssignees!.map(
                      (smSubtaskAssignee) => smSubtaskAssignee.user,
                    )
                  : [];
              return (
                <TableRow key={subtask.id}>
                  <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                  <TableCell>
                    <TaskNumberLinkButton
                      task={subtask.smTask}
                      smSubtaskId={subtask.id}
                    />
                  </TableCell>
                  <TableCell>{subtask.smTask?.subject}</TableCell>
                  <TableCell>
                    {subtask.smSubtaskType ? subtask.smSubtaskType.name : ''}
                  </TableCell>
                  <TableCell>
                    <Chip
                      variant="outlined"
                      color={
                        subtask.smTask?.requester?.activated ? 'default' : 'secondary'
                      }
                      label={
                        subtask.smTask?.requester?.activated
                          ? subtask.smTask?.requester?.fullName
                          : `${subtask.smTask?.requester?.fullName} (Inactive)`
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {assigneeUsers.length ? (
                      <UserLetterAvatar users={assigneeUsers} />
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell>{SubtaskStatusLabel[subtask.status!]}</TableCell>
                  <TableCell>
                    <DateDisplay value={subtask.dueDate} />
                  </TableCell>
                  <TableCell>
                    <DateTimeDisplay value={subtask.createdAt} />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={() => editsubtask(subtask)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default TaskSubtaskList;
