import axios from 'axios';
import { PageFilter } from '../state/app';
import { SampleDevelopmentQueueListFilter } from '../state/sampleDevelopmentQueue/sampleDevelopmentQueue.types';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';

const baseUrl = `/api/sampleDevelopmentQueues`;

export const fetchSampleDevelopmentQueueList = async ({
  filters,
  paging,
}: {
  filters: SampleDevelopmentQueueListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};
