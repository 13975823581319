import React from 'react';
import { Drawer, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  filterDrawer: {
    width: '100vw',
    [theme.breakpoints.up('md')]: {
      width: '20%',
    },
    padding: theme.spacing(2),
  },
}));

export interface IFilterDrawerProps {
  open: boolean;
}

const FilterDrawer: React.FC<IFilterDrawerProps> = props => {
  const classes = useStyles();
  const { open } = props;
  return <Drawer anchor="right"
                 open={open}
                 classes={{ paper: classes.filterDrawer }}>
    {props.children}
  </Drawer>;
};

export default FilterDrawer;
