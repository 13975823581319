import React from 'react';
import { fetchUserList as fetchUserListService } from '../../services/userService';
import { User } from '../../state/user';
import AsyncAutoComplete from './AsyncAutoComplete';

type UserComboBoxProps =
  | {
      isMulti: true;
      handleChange: (result: User[]) => void;
      selectedValue: User[];
      isDisabled?: boolean;
      error?: boolean;
      helperText?: string;
      label?: string;
    }
  | {
      isMulti?: false;
      handleChange: (result: User) => void;
      selectedValue: User;
      isDisabled?: boolean;
      error?: boolean;
      helperText?: string;
      label?: string;
    };

const UserComboBox: React.FC<UserComboBoxProps> = (props) => {
  const {
    handleChange,
    selectedValue,
    isDisabled = false,
    isMulti = false,
    error,
    helperText,
    label = 'User',
  } = props;

  const userPromiseOptions = async (inputValue: string) => {
    const { data: rows } = await fetchUserListService({
      filters: { 'FullNameWithUserName.Contains': inputValue },
      paging: { pageNumber: 0, pageSize: 10 },
    });

    return rows;
  };

  return (
    <AsyncAutoComplete
      name="User"
      value={selectedValue}
      getOptionValue={(option: User) => {
        return option.id!.toString();
      }}
      getOptionLabel={(option: User) => {
        const userFullName = option.fullNameWithUserName
          ? option.activated
            ? option.fullNameWithUserName
            : `${option.fullNameWithUserName} (Inactive)`
          : '';
        return userFullName;
        //return option.fullNameWithUserName ? option.fullNameWithUserName : '';
      }}
      promiseOptions={userPromiseOptions}
      placeholder={'Search by first name'}
      handleChange={handleChange}
      isDisabled={isDisabled}
      isMulti={isMulti}
      error={error}
      helperText={helperText}
      label={label}
    />
  );
};

export default UserComboBox;
