import React from 'react';

import { Grid, MenuItem } from '@material-ui/core';

import { OpportunityStatus, OpportunityStatusLabel } from '../../../../constants';
import { OpportunityListFilter } from '../../../../state/opportunity';
import { FilterContainer, SearchDropdownField, SearchField } from '../../../components';
import { IFilterContainerConfigProps } from '../../../components/FilterContainer';

const OpportunityFilter: React.FC<
  IFilterContainerConfigProps<OpportunityListFilter>
> = props => {
  return (
    <FilterContainer
      {...props}
      render={filterProps => {
        const { localFilter, handleChangeFilter } = filterProps;
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="AssignmentNumber.startsWith"
                filters={localFilter}
                label="Assignment Number"
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Program.Name.startsWith"
                filters={localFilter}
                label="Program"
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Brand.Name.startsWith"
                filters={localFilter}
                label="Brand"
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="SmProject.Name.startsWith"
                filters={localFilter}
                label="Project"
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchDropdownField
                label="Status"
                filterKey={'Status.equalsTo'}
                filters={localFilter}
                onChange={handleChangeFilter}
                id="status"
              >
                <MenuItem value={''}>All</MenuItem>
                {Object.entries(OpportunityStatus).map(([key, value]) => {
                  return (
                    <MenuItem key={key} value={value}>
                      {OpportunityStatusLabel[value]}
                    </MenuItem>
                  );
                })}
              </SearchDropdownField>
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="CreatedBy.startsWith"
                filters={localFilter}
                label="Created By"
                onChange={handleChangeFilter}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default OpportunityFilter;
