import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_OPPORTUNITYCUSTOMERGROUP,
  CREATE_OPPORTUNITYCUSTOMERGROUP,
  FETCH_OPPORTUNITYCUSTOMERGROUP,
  FETCH_OPPORTUNITYCUSTOMERGROUP_LIST,
  UPDATE_OPPORTUNITYCUSTOMERGROUP,
} from './opportunityCustomerGroup.actions';
import { OpportunityCustomerGroup, OpportunityCustomerGroupState } from './opportunityCustomerGroup.types';

const getInitialOpportunityCustomerGroupState = ():OpportunityCustomerGroup  => ({
});

const initialState: OpportunityCustomerGroupState = {
  errorMessage: '',
  item: getInitialOpportunityCustomerGroupState(),
  items: [],
  loading: false,
  totalItems: 0,
};

export default (state: OpportunityCustomerGroupState = initialState, action: AppAction): OpportunityCustomerGroupState => {
  switch (action.type) {
    case REQUEST(FETCH_OPPORTUNITYCUSTOMERGROUP_LIST):
    case REQUEST(CREATE_OPPORTUNITYCUSTOMERGROUP):
    case REQUEST(FETCH_OPPORTUNITYCUSTOMERGROUP):
    case REQUEST(UPDATE_OPPORTUNITYCUSTOMERGROUP):
      return { ...state, loading: true };
    case SUCCESS(FETCH_OPPORTUNITYCUSTOMERGROUP_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_OPPORTUNITYCUSTOMERGROUP_LIST):
    case FAILURE(CREATE_OPPORTUNITYCUSTOMERGROUP):
    case FAILURE(FETCH_OPPORTUNITYCUSTOMERGROUP):
    case FAILURE(UPDATE_OPPORTUNITYCUSTOMERGROUP):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_OPPORTUNITYCUSTOMERGROUP):
    case SUCCESS(FETCH_OPPORTUNITYCUSTOMERGROUP):
    case SUCCESS(UPDATE_OPPORTUNITYCUSTOMERGROUP):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_OPPORTUNITYCUSTOMERGROUP:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
