import {
  Button,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  HowToReg as HowToRegIcon,
} from '@material-ui/icons';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IOpportunityCustomer } from '../../../../models/opportunityCustomer.model';
import { AppState } from '../../../../state/configureStore';
import {
  deleteOpportunityCustomer,
  OpportunityCustomerState,
} from '../../../../state/opportunityCustomer';
import { TableLoading } from '../../../components';
import AddOpportunityCustomerDialog from '../AddOpportunityCustomerDialog';
import { ResponsibilityCustomerState } from '../../../../state/responsibilityCustomer';
import {
  ResponsibilityListFilter,
  ResponsibilityState,
  fetchResponsibilityList,
} from '../../../../state/responsibility';
import OpportunityCustomerVerifyDialog from './OpportunityCustomerVerifyDialog';
import { AuthState } from '../../../../state/auth';

interface OpportunityCustomerProps {
  opportunityId: number;
  companyId: number;
  smProjectId: number;
}

const OpportunityCustomerForm: React.FC<OpportunityCustomerProps> = (props) => {
  const { opportunityId, companyId } = props;
  const dispatch = useDispatch();
  const [localOpportunityCustomers, setLocalOpportunityCustomers] = useState<
    IOpportunityCustomer[]
  >([]);
  const [openAddOpportunityCustomerDialog, setOpenAddOpportunityCustomerDialog] =
    useState(false);
  const { items: opportunityCustomers, loading: isLoading } = useSelector<
    AppState,
    OpportunityCustomerState
  >((state) => state.opportunityCustomer);

  const { items: responsibilities, loading: isLoadingResps } = useSelector<
    AppState,
    ResponsibilityState
  >((state) => state.responsibility);

  const [openVerifyCustomer, setOpenVerifyCustomer] = useState<boolean>(false);

  const { user } = useSelector<AppState, AuthState>((state) => state.auth);
  const isAdmin = user!.roles!.find((f) => f === 'ADMIN') !== undefined;

  const isAllRecordSaved = localOpportunityCustomers.findIndex((opc) => !opc.id) === -1;

  const deleteOppCustomer = (id?: number) => {
    if (id) {
      dispatch(deleteOpportunityCustomer({ id, opportunityId }));
    } else {
      setLocalOpportunityCustomers(localOpportunityCustomers.slice(0, -1));
    }
  };

  useEffect(() => {
    setLocalOpportunityCustomers([...opportunityCustomers]);
  }, [opportunityCustomers]);

  return (
    <>
      <CardContent>
        <AddOpportunityCustomerDialog
          open={openAddOpportunityCustomerDialog}
          handleClose={() => {
            setOpenAddOpportunityCustomerDialog(false);
          }}
          opportunityId={opportunityId}
          companyId={companyId}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenAddOpportunityCustomerDialog(true)}
          disabled={!isAllRecordSaved}
          startIcon={<AddIcon />}
        >
          Add Customer
        </Button>
        {isAdmin && (
          <Button
            variant="outlined"
            onClick={() => {
              setOpenVerifyCustomer(true);
            }}
            startIcon={<HowToRegIcon />}
          >
            Verify Customer
          </Button>
        )}

        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '10%' }}>No.</TableCell>
              <TableCell style={{ width: '80%' }}>Customer</TableCell>
              <TableCell style={{ width: '10%' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={isLoading} colSpan={3}>
              {localOpportunityCustomers.map((opportunityCustomer, index) => {
                const { id, customer } = opportunityCustomer;
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{customer?.displayName}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="default"
                        size="small"
                        onClick={() => deleteOppCustomer(id)}
                        startIcon={<DeleteIcon />}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>

        <OpportunityCustomerVerifyDialog
          opportunityId={opportunityId}
          open={openVerifyCustomer}
          onClose={() => {
            setOpenVerifyCustomer(false);
          }}
        />
      </CardContent>
    </>
  );
};

export default OpportunityCustomerForm;
