import {
  AppBar,
  Button,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close as CloseIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IProjectChangeLogReport } from '../../../../models/report.model';
import { downloadProjectChangeLogReport } from '../../../../services/reportService';
import { PageFilter } from '../../../../state/app';
import { AppState } from '../../../../state/configureStore';
import {
  clearReport,
  fetchProjectChangeLogReportList,
  IProjectChangeLogReportFilter,
  ReportState,
} from '../../../../state/report';
import { PageContainer, PageTitle, TableLoading, DateDisplay } from '../../../components';
import { useFilter } from '../../../hooks';
import ProjectChangeLogReportFilter from './ProjectChangeLogReportFilter';
import useStyles from './projectSummaryReportStyle';

interface IChangeLogReportDialog {
  open: boolean;
  handleClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

const ProjectChangeLogReportDialog: React.FC<IChangeLogReportDialog> = (props) => {
  const classes = useStyles();
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const initFilter: IProjectChangeLogReportFilter = {};
  const [filters, setFilters] = useFilter<IProjectChangeLogReportFilter>(
    initFilter,
    true,
  );
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const {
    loading,
    items: projectChangeLogReportList,
    totalItems,
  } = useSelector<AppState, ReportState>((state) => state.report);

  useEffect(() => {
    if (open) {
      dispatch(fetchProjectChangeLogReportList(filters, { ...paging }));
    }
    return function cleanUp() {
      dispatch(clearReport());
    };
  }, [dispatch, paging, filters, open]);

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const handleChangeFilter = (newFilter: IProjectChangeLogReportFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const exportExcel = () => {
    downloadProjectChangeLogReport(filters, {
      pageSize: totalItems,
    }).catch((err) => console.error(err));
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Project Change Logs Report
          </Typography>
        </Toolbar>
      </AppBar>
      <PageContainer>
        <PageTitle>Project Change Logs Report</PageTitle>
        <ProjectChangeLogReportFilter
          filters={filters}
          onChangeFilter={handleChangeFilter}
          initialFilter={initFilter}
        />
        {!isSmallScreen && (
          <div className={classes.filterContainer}>
            <Button
              variant={'contained'}
              className={classes.button}
              onClick={exportExcel}
            >
              Export Excel
            </Button>
          </div>
        )}
        {open && (
          <Grid container spacing={3} className={classes.container}>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Project</TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell>Subject Type</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>PreviousVersion</TableCell>
                    <TableCell>ChangedVersion</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Created By</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableLoading isLoading={loading} colSpan={9}>
                    {projectChangeLogReportList.map(
                      (changelog: IProjectChangeLogReport, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              {index + paging.pageSize * paging.pageNumber + 1}
                            </TableCell>
                            <TableCell>{changelog.company}</TableCell>
                            <TableCell>{changelog.projectName}</TableCell>
                            <TableCell>{changelog.projectOwner}</TableCell>
                            <TableCell>{changelog.type}</TableCell>
                            <TableCell>{changelog.description}</TableCell>
                            <TableCell>{changelog.previousVersion}</TableCell>
                            <TableCell>{changelog.changedVersion}</TableCell>
                            <TableCell>
                              {<DateDisplay value={changelog.date} />}
                            </TableCell>
                            <TableCell>{changelog.createdBy}</TableCell>
                          </TableRow>
                        );
                      },
                    )}
                  </TableLoading>
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[15, 25, 35]}
                component="div"
                count={totalItems}
                rowsPerPage={paging.pageSize}
                page={paging.pageNumber}
                onPageChange={handleChangePageNumber}
                onRowsPerPageChange={handleChangePageSize}
              />
            </Grid>
          </Grid>
        )}
      </PageContainer>
    </Dialog>
  );
};

export default ProjectChangeLogReportDialog;
