import { ISampleDevelopmentQueue } from '../../models/sampleDevelopment.model';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import { FETCH_SAMPLE_DEVELOPMENT_QUEUE_LIST } from './sampleDevelopmentQueue.actions';
import { SampleDevelopmentQueueState } from './sampleDevelopmentQueue.types';

const initialState: SampleDevelopmentQueueState = {
  errorMessage: '',
  item: {} as Readonly<ISampleDevelopmentQueue>,
  items: [] as ISampleDevelopmentQueue[],
  loading: false,
  totalItems: 0,
};

export default (
  state: SampleDevelopmentQueueState = initialState,
  action: AppAction,
): SampleDevelopmentQueueState => {
  switch (action.type) {
    case REQUEST(FETCH_SAMPLE_DEVELOPMENT_QUEUE_LIST):
      return { ...state, loading: true };
    case SUCCESS(FETCH_SAMPLE_DEVELOPMENT_QUEUE_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_SAMPLE_DEVELOPMENT_QUEUE_LIST):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    default:
      return state;
  }
};
