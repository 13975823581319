import React from 'react';
import { Grid } from '@material-ui/core';
import { FilterContainer, SearchField } from '../../components';
import { IFilterContainerConfigProps } from '../../components/FilterContainer';
import { CustomerGroupMemberListFilter } from '../../../state/customerGroupMember';

const CustomerGroupMemberFilter: React.FC<IFilterContainerConfigProps<CustomerGroupMemberListFilter>> = props => {
  return (
    <FilterContainer
      {...props}
      render={(filterProps) => {
        const { localFilter, handleChangeFilter } = filterProps;
        return <Grid container spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <SearchField
              fullWidth={true}
              label="Company"
              filterKey={'CustomerGroup.Company.Name.startsWith'}
              filters={localFilter}
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <SearchField
              fullWidth={true}
              label="Customer Group"
              filterKey={'CustomerGroup.Name.startsWith'}
              filters={localFilter}
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <SearchField
              fullWidth={true}
              label="Customer Id"
              filterKey={'Customer.ExternalId.startsWith'}
              filters={localFilter}
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <SearchField
              fullWidth={true}
              label="Customer Name"
              filterKey={'Customer.Name.startsWith'}
              filters={localFilter}
              onChange={handleChangeFilter}
            />
          </Grid>
        </Grid>;
      }}
    />
  );
};

export default CustomerGroupMemberFilter;

