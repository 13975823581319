import { AppAction, ISort, PageFilter } from '../app';
import { TaskLogWorkCreateOrUpdatePayload, TaskLogWorkListFilter } from './taskLogWork.types';
import { ITaskLogWork } from '../../models/taskLogWork.model';

export const FETCH_TASKLOGWORK = 'FETCH_TASKLOGWORK';
export const FETCH_TASKLOGWORK_LIST = 'FETCH_TASKLOGWORK_LIST';
export const CREATE_TASKLOGWORK = 'CREATE_TASKLOGWORK';
export const UPDATE_TASKLOGWORK = 'UPDATE_TASKLOGWORK';
export const CLEAR_TASKLOGWORK = 'CLEAR_TASKLOGWORK';

export const fetchTaskLogWork = (id: ITaskLogWork['id']): AppAction => ({
  type: FETCH_TASKLOGWORK,
  payload: id,
});

export const fetchTaskLogWorkList = (
  filters: TaskLogWorkListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_TASKLOGWORK_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchTaskLogWorkListByTaskId = (taskId: number, sort?: ISort): AppAction => {
  return {
    type: FETCH_TASKLOGWORK_LIST,
    payload: { filters: { 'SMTaskId.EqualsTo': taskId }, paging: { pageSize: 9999, pageNumber: 0, sort } },
  };
};

export const createTaskLogWork = (
  payload: TaskLogWorkCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_TASKLOGWORK,
  payload,
});

export const updateTaskLogWork = (
  payload: TaskLogWorkCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_TASKLOGWORK,
  payload,
});

export const clearTaskLogWork = () => ({
  type: CLEAR_TASKLOGWORK,
});
