import { ISubtask } from '../../models/subtask.model';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_SUBTASK,
  CLEAR_SUBTASK_ITEM_SECTION,
  CREATE_SUBTASK,
  DELETE_SUBTASK,
  FETCH_SUBTASK,
  FETCH_SUBTASK_LIST,
  FETCH_RELATED_SUBTASK_LIST,
  UPDATE_SUBTASK,
  UPDATE_SUBTASK_STATUS,
  FETCH_SUBTASK_STATUS,
} from './subtask.actions';
import { SubtaskState } from './subtask.types';

const initialState: SubtaskState = {
  errorMessage: '',
  item: {} as Readonly<ISubtask>,
  items: [] as ISubtask[],
  loading: false,
  totalItems: 0,
  editSubtaskId: null,
};

export default (state: SubtaskState = initialState, action: AppAction): SubtaskState => {
  switch (action.type) {
    case REQUEST(FETCH_SUBTASK_LIST):
    case REQUEST(FETCH_RELATED_SUBTASK_LIST):
    case REQUEST(CREATE_SUBTASK):
    case REQUEST(FETCH_SUBTASK):
    case REQUEST(FETCH_SUBTASK_STATUS):
    case REQUEST(UPDATE_SUBTASK):
    case REQUEST(DELETE_SUBTASK):
    case REQUEST(UPDATE_SUBTASK_STATUS):
      return { ...state, loading: true };
    case SUCCESS(FETCH_SUBTASK_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case SUCCESS(FETCH_RELATED_SUBTASK_LIST):
      return {
        ...state,
        items: action.payload.items,
        loading: false,
        totalItems: action.payload.totalItems,
      };
    case FAILURE(FETCH_SUBTASK_LIST):
    case FAILURE(CREATE_SUBTASK):
    case FAILURE(FETCH_SUBTASK):
    case FAILURE(UPDATE_SUBTASK):
    case FAILURE(DELETE_SUBTASK):
    case FAILURE(UPDATE_SUBTASK_STATUS):
    case FAILURE(FETCH_RELATED_SUBTASK_LIST):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(FETCH_SUBTASK):
    case SUCCESS(FETCH_SUBTASK_STATUS):
    case SUCCESS(UPDATE_SUBTASK):
    case SUCCESS(UPDATE_SUBTASK_STATUS):
      return { ...state, loading: false, item: action.payload.data };
    case SUCCESS(CREATE_SUBTASK):
    case SUCCESS(DELETE_SUBTASK):
      return { ...state, loading: false };

    case CLEAR_SUBTASK:
      return {
        ...state,
        ...initialState,
      };
    case CLEAR_SUBTASK_ITEM_SECTION:
      return {
        ...state,
        item: {},
      };
    default:
      return state;
  }
};
