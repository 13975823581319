import { IProject } from '../models/project.model';
import { PageFilter } from '../state/app';
import { ProjectListFilter } from '../state/project';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/smProjects`;

export const fetchProjectList = async ({
  filters,
  paging,
}: {
  filters: ProjectListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchProject = async (id: IProject['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createProject = async (project: IProject) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, project);
};

export const updateProject = async (project: IProject) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, project);
};

export const submitForApprovalProject = async (id: IProject['id']) => {
  const requestUrl = `${baseUrl}/${id}/submitForApproval`;
  return await axios.post(requestUrl);
};

export const submitForUnlockApprovedProject = async (
  id: IProject['id'],
  description: string,
) => {
  const requestUrl = `${baseUrl}/${id}/submitForUnlockApproved`;
  return await axios.post(requestUrl, { description });
};
