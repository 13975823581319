import React from 'react';
import { Paper, Grid, Typography, Box } from '@material-ui/core';
import useStyles from './sampleDevelopmentNl.style';
import { TextFieldFastField } from '../../../components';

interface SampleDevelopmentNlAlfrescoLinkProps {}

const SampleDevelopmentNlAlfrescoLink: React.FC<
  SampleDevelopmentNlAlfrescoLinkProps
> = () => {
  const classes = useStyles();

  return (
    <Paper variant="outlined">
      <Box m={1}>
        <Grid container spacing={1} direction="row" justifyContent="flex-start">
          <Grid sm={12} item>
            <Typography className={classes.title}>Alfresco Link</Typography>
          </Grid>
          <Grid sm={12} item>
            <TextFieldFastField
              name="alfrescoLink"
              label="Alfresco Link"
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default SampleDevelopmentNlAlfrescoLink;
