import { Option } from '../views/components/EditableTable/SelectOptionCellEditor';

export type JobTypeType = 'SAMPLE' | 'SEE_MODEL';

const JobType = Object.freeze({
  SAMPLE: 'SAMPLE' as JobTypeType,
  SEE_MODEL: 'SEE_MODEL' as JobTypeType,
});

export default JobType;

export const JobTypeLabel = Object.freeze({
  [JobType.SAMPLE]: 'Sample',
  [JobType.SEE_MODEL]: 'งานดูแบบ',
});

export const JobTypeOptions: Array<Option> = Object.keys(JobType).map(u => {
  return { label: JobTypeLabel[u], value: u };
});
