import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
} from '@material-ui/core';
import { Edit as EditIcon, Refresh as RefreshIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  PageContainer,
  PageTitle,
  TableLoading,
  PageButtonGroup,
} from '../../components';
import { fetchUserList, UserListFilter, UserState, clearUser } from '../../../state/user';
import { useFilter } from '../../hooks';
import UserFilter from './UserFilter';
import { syncUserFromLdap, clearAdmin } from '../../../state/admin';
import { UserEditDialog } from './userEditDialog';

const initialUserListFilter: UserListFilter = {};

const UserListTable: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useFilter<UserListFilter>(initialUserListFilter);
  const [editUser, setEditUser] = useState<number | null>(null);
  const [openUserEditDialog, setOpenUserEditDialog] = useState<boolean>(false);
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const {
    items: users,
    totalItems,
    loading,
  } = useSelector<AppState, UserState>((state) => state.user);

  const handleChangeFilter = (newFilter: UserListFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchUserList(filters, paging));
  }, [dispatch, paging, filters]);

  useEffect(() => {
    return function cleanUp() {
      dispatch(clearUser());
      dispatch(clearAdmin());
    };
  }, [dispatch]);

  const handleEdit = (userId: number) => {
    setEditUser(userId);
    setOpenUserEditDialog(true);
  };

  const syncUser = () => {
    dispatch(syncUserFromLdap());
  };

  return (
    <>
      <UserEditDialog
        open={openUserEditDialog}
        onClose={() => {
          setOpenUserEditDialog(false);
        }}
        userId={editUser}
      />
      <PageContainer>
        <PageTitle>Users</PageTitle>
        <PageButtonGroup>
          <Button
            onClick={syncUser}
            variant="contained"
            color="primary"
            startIcon={<RefreshIcon />}
          >
            Sync User from LDAP
          </Button>
        </PageButtonGroup>

        <UserFilter
          filters={filters}
          onChangeFilter={handleChangeFilter}
          initialFilter={initialUserListFilter}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={3}>
              {users.map((user, index) => {
                return (
                  <TableRow key={user.id}>
                    <TableCell>
                      {index + paging.pageSize * paging.pageNumber + 1}
                    </TableCell>
                    <TableCell>{user.login}</TableCell>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={() => handleEdit(user.id!)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[15, 25, 35]}
          component="div"
          count={totalItems}
          rowsPerPage={paging.pageSize}
          page={paging.pageNumber}
          onPageChange={handleChangePageNumber}
          onRowsPerPageChange={handleChangePageSize}
        />
      </PageContainer>
    </>
  );
};

export default UserListTable;
