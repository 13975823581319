import React from 'react';
import { useParams } from 'react-router-dom';
import ProjectForm from './ProjectForm';

interface ProjectFormPageRouteParamsProps {
  id: string;
}

const ProjectFormPage: React.FC<ProjectFormPageRouteParamsProps> = props => {
  const { id: paramsId } = useParams<ProjectFormPageRouteParamsProps>();
  return <ProjectForm smProjectId={paramsId ? +paramsId : undefined} />;
};

export default ProjectFormPage;
