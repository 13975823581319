import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_REPORT,
  FETCH_BRAND_APPROVAL_REQUEST_REPORT_LIST,
  FETCH_OPPORTUNITY_APPROVAL_REQUEST_REPORT_LIST,
  FETCH_OPPORTUNITY_ITEM_SUMMARY_REPORT_LIST,
  FETCH_PROGRAM_APPROVAL_REQUEST_REPORT_LIST,
  FETCH_PROJECT_SUMMARY_REPORT_LIST,
  FETCH_SUBTASK_ITEM_SUMMARY_REPORT_LIST,
  FETCH_SUBTASK_WORK_DURATION_REPORT,
  FETCH_TASK_ITEM_SUMMARY_REPORT_LIST,
  FETCH_PROJECT_SALES_SUMMARY_REPORT_LIST,
  FETCH_PROJECT_CHANGELOG_REPORT_LIST,
  FETCH_OPPORTUNITY_CHANGELOG_REPORT_LIST,
} from './report.action';
import { ReportState } from './report.types';

const initialState: ReportState = {
  errorMessage: '',
  item: {},
  items: [] as ReadonlyArray<any>,
  loading: false,
  totalItems: 0,
};

export default (state: ReportState = initialState, action: AppAction): ReportState => {
  switch (action.type) {
    case REQUEST(FETCH_OPPORTUNITY_ITEM_SUMMARY_REPORT_LIST):
    case REQUEST(FETCH_TASK_ITEM_SUMMARY_REPORT_LIST):
    case REQUEST(FETCH_SUBTASK_ITEM_SUMMARY_REPORT_LIST):
    case REQUEST(FETCH_SUBTASK_WORK_DURATION_REPORT):
    case REQUEST(FETCH_PROJECT_SUMMARY_REPORT_LIST):
    case REQUEST(FETCH_OPPORTUNITY_APPROVAL_REQUEST_REPORT_LIST):
    case REQUEST(FETCH_PROGRAM_APPROVAL_REQUEST_REPORT_LIST):
    case REQUEST(FETCH_BRAND_APPROVAL_REQUEST_REPORT_LIST):
    case REQUEST(FETCH_PROJECT_SALES_SUMMARY_REPORT_LIST):
    case REQUEST(FETCH_PROJECT_CHANGELOG_REPORT_LIST):
    case REQUEST(FETCH_OPPORTUNITY_CHANGELOG_REPORT_LIST):
      return { ...state, loading: true };
    case SUCCESS(FETCH_OPPORTUNITY_ITEM_SUMMARY_REPORT_LIST):
    case SUCCESS(FETCH_TASK_ITEM_SUMMARY_REPORT_LIST):
    case SUCCESS(FETCH_SUBTASK_ITEM_SUMMARY_REPORT_LIST):
    case SUCCESS(FETCH_SUBTASK_WORK_DURATION_REPORT):
    case SUCCESS(FETCH_PROJECT_SUMMARY_REPORT_LIST):
    case SUCCESS(FETCH_OPPORTUNITY_APPROVAL_REQUEST_REPORT_LIST):
    case SUCCESS(FETCH_PROGRAM_APPROVAL_REQUEST_REPORT_LIST):
    case SUCCESS(FETCH_BRAND_APPROVAL_REQUEST_REPORT_LIST):
    case SUCCESS(FETCH_PROJECT_SALES_SUMMARY_REPORT_LIST):
    case SUCCESS(FETCH_PROJECT_CHANGELOG_REPORT_LIST):
    case SUCCESS(FETCH_OPPORTUNITY_CHANGELOG_REPORT_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };

    case FAILURE(FETCH_OPPORTUNITY_ITEM_SUMMARY_REPORT_LIST):
    case FAILURE(FETCH_TASK_ITEM_SUMMARY_REPORT_LIST):
    case FAILURE(FETCH_SUBTASK_ITEM_SUMMARY_REPORT_LIST):
    case FAILURE(FETCH_SUBTASK_WORK_DURATION_REPORT):
    case FAILURE(FETCH_PROJECT_SUMMARY_REPORT_LIST):
    case FAILURE(FETCH_OPPORTUNITY_APPROVAL_REQUEST_REPORT_LIST):
    case FAILURE(FETCH_PROGRAM_APPROVAL_REQUEST_REPORT_LIST):
    case FAILURE(FETCH_BRAND_APPROVAL_REQUEST_REPORT_LIST):
    case FAILURE(FETCH_PROJECT_SALES_SUMMARY_REPORT_LIST):
    case FAILURE(FETCH_PROJECT_CHANGELOG_REPORT_LIST):
    case FAILURE(FETCH_OPPORTUNITY_CHANGELOG_REPORT_LIST):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case CLEAR_REPORT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
