import { Option } from '../views/components/EditableTable/SelectOptionCellEditor';

export type UnitOfMeasurementType = 'MM' | 'CM' | 'INCH';

const UnitOfMeasurement = Object.freeze({
  MM: 'MM' as UnitOfMeasurementType,
  CM: 'CM' as UnitOfMeasurementType,
  INCH: 'INCH' as UnitOfMeasurementType,
});

export default UnitOfMeasurement;

export const UnitOfMeasurementLabel = Object.freeze({
  [UnitOfMeasurement.MM]: 'MM',
  [UnitOfMeasurement.CM]: 'CM',
  [UnitOfMeasurement.INCH]: 'INCH',
});

export const UnitOfMeasurementOptions: Array<Option> = Object.keys(UnitOfMeasurement).map(
  u => {
    return { label: UnitOfMeasurementLabel[u], value: u };
  },
);
