import { useState } from 'react';
import { getCurrentImportTemplateVersion } from '../../../../services/responsibilityService';

const useCheckImportTemplateVersion = () => {
  const [version, setVersion] = useState('');
  const [checking, setChecking] = useState(false);
  const [error, setError] = useState(false);

  const execute = async () => {
    try {
      setVersion('');
      setChecking(true);
      const { data } = await getCurrentImportTemplateVersion();
      setVersion(data);
    } catch (error) {
      setVersion('');
      setError(error.data);
    } finally {
      setChecking(false);
    }
  };

  return { version, checking, error, execute };
};

export default useCheckImportTemplateVersion;
