import React, { useState } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import ProjectCustomerForm from './ProjectCustomerForm';
// import ProjectCustomerGroupForm from './ProjectCustomerGroupForm';
import { PageContainer } from '../../../components';
import { IProject } from '../../../../models/project.model';
import ProjectProgramBrandForm from './ProjectProgramBrandForm';
// import ProjectSquadForm from './ProjectSquadForm';
import ChangeLogProject from './ChangeLogProject';
import { AppBar } from '@material-ui/core';
import ProjectApprovalListSection from './ProjectApprovalListSection';
// import ProjectSquadMemberForm from './ProjectSquadMemberForm';
import { initChangeLogProjectState } from '../../../../state/changeLogProject';

interface ProjectSubFormProps {
  project: IProject;
}

const ProjectSubForm: React.FC<ProjectSubFormProps> = props => {
  const { project } = props;
  const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <PageContainer disableOverflowX disableOverflowY>
      <AppBar position="static" color="default" variant="outlined">
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event, newIndex) => setTabIndex(newIndex)}
          variant="scrollable"
        >
          {/* <Tab label="Project Member" /> */}
          <Tab label="Program Brand" />
          {/* <Tab label="Customer" /> */}
          {/* <Tab label="Customer Group" /> */}
          {/* <Tab label="Approval Requests" /> */}
          {/* <Tab label="Squad" /> */}
          {/* <Tab label="Change logs" /> */}
        </Tabs>
      </AppBar>

      {/* {tabIndex === 0 && <ProjectSquadMemberForm smProjectId={project.id as number} />} */}
      {tabIndex === 0 && <ProjectProgramBrandForm smProjectId={project.id as number} />}
      {/* 
      {tabIndex === 1 && (
        <ProjectCustomerForm
          smProjectId={project.id as number}
          companyId={project.companyId as number}
        />
      )} */}
      {/* {tabIndex === 2 && (
        <ProjectCustomerGroupForm
          smProjectId={project.id as number}
          companyId={project.companyId as number}
        />
      )} */}
      {/* {tabIndex === 1 && (
        <ProjectApprovalListSection smProjectId={project.id as number} />
      )} */}
      {/* {tabIndex === 5 && <ProjectSquadForm smProjectId={project.id as number} />} */}
      {/* {tabIndex === 4 && <ChangeLogProject smProjectId={project.id as number} />} */}
    </PageContainer>
  );
};

export default ProjectSubForm;
