import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  fetchSampleDevelopmentNlApprovalRequestList,
  updateSampleDevelopmentNlApprovalRequesResponse,
} from '../../services/sampleDevelopmentNlApprovalRequestService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  closeSampleDevelopmentNlApprovalRequestDialog,
  FETCH_SAMPLE_DEV_NL_APPROVAL_REQUEST_LIST,
  UPDATE_SAMPLE_DEV_NL_APPROVAL_REQUEST_RESPONSE,
} from './sampleDevelopmentNlApprovalRequest.actions';

function* fetchSasmpleDevelopmentNlApprovalRequestListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(
      fetchSampleDevelopmentNlApprovalRequestList,
      action.payload,
    );
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateSasmpleDevelopmentNlApprovalRequestResponseSaga(action: AppAction) {
  try {
    const successMessage = `Updated sample development nl approval request successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(
      updateSampleDevelopmentNlApprovalRequesResponse,
      action.payload,
    );
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put(closeSampleDevelopmentNlApprovalRequestDialog());
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSampleDevelopmentNlApprovalRequest() {
  yield debounce(
    250,
    FETCH_SAMPLE_DEV_NL_APPROVAL_REQUEST_LIST,
    fetchSasmpleDevelopmentNlApprovalRequestListSaga,
  );
  yield takeLatest(
    UPDATE_SAMPLE_DEV_NL_APPROVAL_REQUEST_RESPONSE,
    updateSasmpleDevelopmentNlApprovalRequestResponseSaga,
  );
}
