import { Option } from '../views/components/EditableTable/SelectOptionCellEditor';

export type UnitOfPriceType = 'THB' | 'USD' | 'VND';

const UnitOfPrice = Object.freeze({
  THB: 'THB' as UnitOfPriceType,
  USD: 'USD' as UnitOfPriceType,
  VND: 'VND' as UnitOfPriceType,
});

export default UnitOfPrice;

export const UnitOfPriceLabel = Object.freeze({
  [UnitOfPrice.THB]: 'THB',
  [UnitOfPrice.USD]: 'USD',
  [UnitOfPrice.VND]: 'VND',
});

export const UnitOfPriceOptions: Array<Option> = Object.keys(UnitOfPrice).map(u => {
  return { label: UnitOfPriceLabel[u], value: u };
});
