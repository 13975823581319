import { AppAction, PageFilter } from '../../../../state/app';

export const FETCH_PENDING_APPROVAL_PROGRAMANDBRANDREQUEST_LIST =
  'FETCH_PENDING_APPROVAL_PROGRAMANDBRANDREQUEST_LIST';
export const CLEAR_PENDING_APPROVAL_PROGRAMANDBRANDREQUEST_LIST =
  'CLEAR_PENDING_APPROVAL_PROGRAMANDBRANDREQUEST_LIST';

export const fetchPendingApprovalProgramAndBrandRequestList = (
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_PENDING_APPROVAL_PROGRAMANDBRANDREQUEST_LIST,
    payload: {
      filters: { 'Status.equalsTo': 'WAITING_FOR_APPROVE' },
      paging,
    },
  };
};

export const clearPendingApprovalProgramAndBrandRequestList = () => ({
  type: CLEAR_PENDING_APPROVAL_PROGRAMANDBRANDREQUEST_LIST,
});
