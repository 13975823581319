import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './sampleDevelopmentNl.style';

import { CheckBoxFastField, TextFieldFastField } from '../../../components';

interface SamDevNlFinishingBackProps {}

const SampleDevelopmentNlFinishingBack: React.FC<SamDevNlFinishingBackProps> = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2}></Grid>
        <Grid item xs={4}>
          <CheckBoxFastField name="finishCoatingBack" label="Back" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2}></Grid>
        <Grid item xs={4}>
          <CheckBoxFastField name="fcbFilmLaminate" label="Laminate Film" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbFilmMatt" label="Matt" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbFilmGloss" label="Gloss" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbFilmHalogram" label="Halogram" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbFilmOther" label="Other" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2}>
          <Typography className={classes.numberTitle} display="inline" />
        </Grid>
        <Grid item xs={4}>
          <CheckBoxFastField name="fcbChemCemical" label="Chemical" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbChemWaterBase" label="Water Base" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbChemOilBase" label="Oil Base" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbChemVanish" label="UV" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbChemSpot" label="Spot" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbChemSpotMatt" label="Matt" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbChemSpotSuperMatt" label="Supper Matt" />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbChemSpotSemiGloss" label="Semi Gloss" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbChemSpotGloss" label="Gloss" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbChemSpotHighGloss" label="High Gloss" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbChemSpotTexture" label="Texture" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbChemSpotCalendering" label="Calendering" />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4} />
        <Grid item xs={1}>
          <CheckBoxFastField name="fcbChemSpotOther" label="Other" />
        </Grid>
        <Grid item xs={3}>
          <TextFieldFastField
            className={classes.textInputRightCheckboxOther}
            name="fcbChemSpotOtherData"
            label="Other"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbChemFullArea" label="Full Area" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbChemFullAreaMatt" label="Matt" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbChemFullAreaSuperMatt" label="Supper Matt" />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbChemFullAreaSemiGloss" label="Semi Gloss" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbChemFullAreaGloss" label="Gloss" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbChemFullAreaHighGloss" label="High Gloss" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbChemFullAreaTexture" label="Texture" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbChemFullAreaCalendering" label="Calendering" />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4} />
        <Grid item xs={1}>
          <CheckBoxFastField name="fcbChemFullAreaOther" label="Other" />
        </Grid>
        <Grid item xs={3}>
          <TextFieldFastField
            className={classes.textInputRightCheckboxOther}
            name="fcbChemFullAreaOtherData"
            label="Other"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbBlister" label="Blister" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbBlisterPet" label="PET" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbBlisterPvc" label="PVC" />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbSilkscreen" label="Silkscreen" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbSilkFullArea" label="Full Area" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbSilkSpot" label="Spot" />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbSilkMatt" label="Matt" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="fcbSilkGloss" label="Gloss" />
        </Grid>
        <Grid item xs={1}>
          <CheckBoxFastField name="fcbSilkOthers" label="Other" />
        </Grid>
        <Grid item xs={3}>
          <TextFieldFastField
            className={classes.textInputRightCheckboxOther}
            name="fcbSilkOthersData"
            label="Other"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2}></Grid>
        <Grid item xs={4}>
          <CheckBoxFastField name="dieCutBack" label="Back" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}>
          <CheckBoxFastField name="dieCutBHold" label="ปั๊มรู (Hold)" />
        </Grid>
        <Grid item xs={3}>
          <CheckBoxFastField name="dieCutBPerforate" label="ปั๊มปรุ (Perforate)" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}>
          <CheckBoxFastField name="dieCutBShape" label="ปั๊มตามรูป (Shape)" />
        </Grid>
        <Grid item xs={3}>
          <CheckBoxFastField name="dieCutBFoldLine" label="ปั๊มพับ (Fold Line)" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}>
          <CheckBoxFastField name="dieCutBEmboss" label="ปั๊มนูน (Emboss)" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SampleDevelopmentNlFinishingBack;
