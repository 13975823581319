import { CardContent, Grid, Paper } from '@material-ui/core';
import React from 'react';
import SampleDevelopmentNlFinishingFront from './SampleDevelopmentNlFinishingFront';
import SampleDevelopmentNlFinishingBack from './SampleDevelopmentNlFinishingBack';

interface SamDevNlFinishingProps {}

const SampleDevelopmentNlFinishing: React.FC<SamDevNlFinishingProps> = () => {
  return (
    <Paper variant="outlined">
      <CardContent>
        <Grid container spacing={1} direction="row" justifyContent="flex-start">
          <Grid item xs={12} sm={6} container>
            <SampleDevelopmentNlFinishingFront />
          </Grid>
          <Grid item xs={12} sm={6} container>
            <SampleDevelopmentNlFinishingBack />
          </Grid>
        </Grid>
      </CardContent>
    </Paper>
  );
};

export default SampleDevelopmentNlFinishing;
