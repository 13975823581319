import React, { useState, useEffect } from 'react';
import {
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
  Button,
} from '@material-ui/core';
import { ISubtaskItem } from '../../../../models/subtaskItem.model';
import { cloneDeep } from 'lodash';

interface ArtworkLinkDialogProps {
  onOk: (subtaskItems: ISubtaskItem[]) => void;
  open: boolean;
  subtaskItems: ReadonlyArray<ISubtaskItem>;
}

const ArtworkLinkDialog: React.FC<ArtworkLinkDialogProps> = props => {
  const { onOk, open, subtaskItems: subtaskItemsProps } = props;
  const [subtaskItems, setSubtaskItems] = useState<ISubtaskItem[]>([]);

  const handleChange = (index: number) => (event: any) => {
    const newSubtaskItems = cloneDeep(subtaskItems);
    newSubtaskItems[index].artworkLink = event.target.value;
    setSubtaskItems(newSubtaskItems);
  };

  const handleOk = () => {
    onOk(subtaskItems);
  };

  useEffect(() => {
    const newSubtaskItems = cloneDeep(subtaskItemsProps) as ISubtaskItem[];
    setSubtaskItems(newSubtaskItems);
  }, [subtaskItemsProps]);

  return (
    <>
      <Dialog open={open} fullWidth>
        <DialogTitle>Insert Artwork Link</DialogTitle>
        <DialogContent>
          {subtaskItems.map((subtaskItem, index) => {
            return (
              <TextField
                key={index}
                name="artworkLink"
                label={`${
                  subtaskItem.smTaskItem!.opportunityItem!.block!.blockNo
                } Artwork Link`}
                margin="dense"
                fullWidth
                variant="outlined"
                onChange={handleChange(index)}
                value={subtaskItem.artworkLink ? subtaskItem.artworkLink : ''}
              />
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ArtworkLinkDialog;
