import { AppAction, PageFilter } from '../app';
import {
  OpportunityApprovalRequest,
  OpportunityApprovalRequestCreateOrUpdatePayload,
  OpportunityApprovalRequestListFilter,
  OpportunityApprovalResponse,
} from './opportunityApprovalRequest.types';

export const FETCH_OPPORTUNITYAPPROVALREQUEST = 'FETCH_OPPORTUNITYAPPROVALREQUEST';
export const FETCH_OPPORTUNITYAPPROVALREQUEST_LIST =
  'FETCH_OPPORTUNITYAPPROVALREQUEST_LIST';
export const CREATE_OPPORTUNITYAPPROVALREQUEST = 'CREATE_OPPORTUNITYAPPROVALREQUEST';
export const UPDATE_OPPORTUNITYAPPROVALREQUEST = 'UPDATE_OPPORTUNITYAPPROVALREQUEST';
export const CLEAR_OPPORTUNITYAPPROVALREQUEST = 'CLEAR_OPPORTUNITYAPPROVALREQUEST';
export const UPDATE_OPPORTUNITY_APPROVAL_RESPONSE =
  'UPDATE_OPPORTUNITY_APPROVAL_RESPONSE';
export const OPEN_OPPORTUNITYAPPROVALREQUEST_DIALOG = 'OPEN_OPPORTUNITYAPPROVALREQUEST_DIALOG';
export const CLOSE_OPPORTUNITYAPPROVALREQUEST_DIALOG = 'CLOSE_OPPORTUNITYAPPROVALREQUEST_DIALOG';

export const fetchOpportunityApprovalRequest = (
  id: OpportunityApprovalRequest['id'],
): AppAction => ({
  type: FETCH_OPPORTUNITYAPPROVALREQUEST,
  payload: id,
});

export const fetchOpportunityApprovalRequestList = (
  filters: OpportunityApprovalRequestListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_OPPORTUNITYAPPROVALREQUEST_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchOpportunityApprovalRequestListByOpportunityId = (opportunityId: number): AppAction => ({
  type: FETCH_OPPORTUNITYAPPROVALREQUEST_LIST,
  payload: {
    filters: { 'OpportunityId.equalsTo': opportunityId },
    paging: { pageNumber: 0, pageSize: 99999 },
  },
});

export const createOpportunityApprovalRequest = (
  payload: OpportunityApprovalRequestCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_OPPORTUNITYAPPROVALREQUEST,
  payload,
});

export const updateOpportunityApprovalRequest = (
  payload: OpportunityApprovalRequestCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_OPPORTUNITYAPPROVALREQUEST,
  payload,
});

export const clearOpportunityApprovalRequest = () => ({
  type: CLEAR_OPPORTUNITYAPPROVALREQUEST,
});

export const updateOpportunityApprovalResponse = (
  payload: OpportunityApprovalResponse,
) => ({
  type: UPDATE_OPPORTUNITY_APPROVAL_RESPONSE,
  payload,
});

export const openOpportunityApprovalRequestDialog = () => ({
  type: OPEN_OPPORTUNITYAPPROVALREQUEST_DIALOG,
});
export const closeOpportunityApprovalRequestDialog = () => ({
  type: CLOSE_OPPORTUNITYAPPROVALREQUEST_DIALOG,
});
