import { ICellEditor, ICellEditorParams } from 'ag-grid-community';
import React, { useImperativeHandle, useState } from 'react';
import { ForecastFrequencyType } from '../../../constants/forecastFrequency';
import { createStyles, InputBase, MenuItem, Select, Theme, withStyles } from '@material-ui/core';

export interface Option {
  label: string;
  value: string;
}

interface SelectOptionCellEditorProps extends ICellEditorParams {
  options: ReadonlyArray<Option>;
}


const AgGridInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'white',
      fontSize: 'inherit',
      lineHeight: '1em',
      width: '100%',
    },
    input: {
      paddingLeft: theme.spacing(1),
      position: 'relative',
      fontSize: 'inherit',
      border: '1px solid #bdc3c7',
      borderRadius: '5px',
      height: '100%',
      backgroundColor: 'transparent',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  }),
)(InputBase);

const SelectOptionCellEditor = React.forwardRef<ICellEditor, SelectOptionCellEditorProps>((props, ref) => {
  const { options } = props;
  const [value, setValue] = useState<ForecastFrequencyType>(props.value);
  useImperativeHandle(ref, (): ICellEditor => {
    return {
      getValue: () => {
        return value;
      },
    };
  });

  return <Select
    value={value || ''}
    onChange={(e) => {
      setValue(e.target.value as ForecastFrequencyType);
    }}
    input={<AgGridInput/>}>
    {options.map(({ label, value }) => {
      return <MenuItem key={value} value={value}>{label}</MenuItem>;
    })}
  </Select>;
});

export default SelectOptionCellEditor;