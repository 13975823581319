import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflow: 'visible',
    padding: '0.05px',
    position: 'relative',
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  title: {
    margin: theme.spacing(2),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    marginRight: '5px',
  },
  buttonGroup: {
    position: 'absolute',
    right: '15px',
    top: '15px',
  },
}));

export default useStyles;
