export const FETCH_PROJECT_LOG = 'FETCH_PROJECT_LOG';
export const CLEAR_PROJECT_LOG = 'CLEAR_PROJECT_LOG';

export const fetchProjectLog = (smProjectId: number) => {
  return {
    type: FETCH_PROJECT_LOG,
    payload: smProjectId,
  };
};

export const clearProjectLog = () => {
  return {
    type: CLEAR_PROJECT_LOG,
  };
};
