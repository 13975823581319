import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_PROJECT_CUSTOMER,
  DELETE_PROJECT_CUSTOMER,
  FETCH_PROJECT_CUSTOMER_LIST,
  UPDATE_PROJECT_CUSTOMER,
  FETCH_PROJECT_CUSTOMER,
} from './projectCustomer.actions';
import { ProjectCustomerState } from './projectCustomer.types';
import { IProjectCustomer, defaultValue } from '../../models/projectCustomer.model';

const initialState: ProjectCustomerState = {
  errorMessage: '',
  item: defaultValue,
  items: [] as ReadonlyArray<IProjectCustomer>,
  loading: false,
  totalItems: 0,
};

export default (
  state: ProjectCustomerState = initialState,
  action: AppAction,
): ProjectCustomerState => {
  switch (action.type) {
    case REQUEST(FETCH_PROJECT_CUSTOMER_LIST):
    case REQUEST(DELETE_PROJECT_CUSTOMER):
    case REQUEST(UPDATE_PROJECT_CUSTOMER):
    case REQUEST(FETCH_PROJECT_CUSTOMER):
      return { ...state, loading: true };
    case SUCCESS(FETCH_PROJECT_CUSTOMER_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case SUCCESS(DELETE_PROJECT_CUSTOMER):
      return { ...state, loading: false };
    case FAILURE(FETCH_PROJECT_CUSTOMER_LIST):
    case FAILURE(DELETE_PROJECT_CUSTOMER):
    case FAILURE(UPDATE_PROJECT_CUSTOMER):
    case FAILURE(FETCH_PROJECT_CUSTOMER):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(UPDATE_PROJECT_CUSTOMER):
    case SUCCESS(FETCH_PROJECT_CUSTOMER):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_PROJECT_CUSTOMER:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
