import { AppAction, PageFilter } from '../app';
import { SubtaskItemForecastListFilter } from './subtaskItemForecast.types';
import { IForecast } from '../../models/forecast.model';

export const FETCH_SUBTASK_ITEM_FORECAST_LIST = 'FETCH_SUBTASK_ITEM_FORECAST_LIST';
export const CLEAR_SUBTASK_ITEM_FORECAST = 'CLEAR_SUBTASK_ITEM_FORECAST';
export const CREATE_SUBTASK_ITEM_FORECAST_LIST = 'CREATE_SUBTASK_ITEM_FORECAST_LIST';
export const CREATE_MULTIPLE_SUBTASK_ITEM_FORECAST_LIST =
  'CREATE_MULTIPLE_SUBTASK_ITEM_FORECAST_LIST';

export const fetchSubtaskItemForecastList = (
  filters: SubtaskItemForecastListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SUBTASK_ITEM_FORECAST_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchSubtaskItemForecastListBySmSubtaskItemId = (
  smSubtaskItemId: number,
): AppAction => {
  return {
    type: FETCH_SUBTASK_ITEM_FORECAST_LIST,
    payload: {
      filters: { 'SmSubtaskItemId.EqualsTo': smSubtaskItemId },
      paging: { pageNumber: 0, pageSize: 99999 },
    },
  };
};

export const createSubtaskItemForecastList = (
  smSubtaskItemId: number,
  smSubtaskItemForecasts: Array<IForecast>,
) => ({
  type: CREATE_SUBTASK_ITEM_FORECAST_LIST,
  payload: {
    smSubtaskItemId,
    smSubtaskItemForecasts,
  },
});

export const createMultipleSubtaskItemForecastList = (payload: any) => ({
  type: CREATE_MULTIPLE_SUBTASK_ITEM_FORECAST_LIST,
  payload,
});

export const clearSubtaskItemForecast = () => ({
  type: CLEAR_SUBTASK_ITEM_FORECAST,
});
