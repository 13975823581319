import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_OPPORTUNITY_CUSTOMER,
  DELETE_OPPORTUNITY_CUSTOMER,
  FETCH_OPPORTUNITY_CUSTOMER_LIST,
  FETCH_OPPORTUNITY_CUSTOMER_INCLUDE_MEMBER_LIST,
  UPDATE_OPPORTUNITY_CUSTOMER,
} from './opportunityCustomer.actions';
import { OpportunityCustomerState } from './opportunityCustomer.types';
import {
  IOpportunityCustomer,
  defaultValue,
} from '../../models/opportunityCustomer.model';

const initialState: OpportunityCustomerState = {
  errorMessage: '',
  item: defaultValue,
  items: [] as ReadonlyArray<IOpportunityCustomer>,
  loading: false,
  totalItems: 0,
};

export default (
  state: OpportunityCustomerState = initialState,
  action: AppAction,
): OpportunityCustomerState => {
  switch (action.type) {
    case REQUEST(FETCH_OPPORTUNITY_CUSTOMER_LIST):
    case REQUEST(FETCH_OPPORTUNITY_CUSTOMER_INCLUDE_MEMBER_LIST):
    case REQUEST(DELETE_OPPORTUNITY_CUSTOMER):
    case REQUEST(UPDATE_OPPORTUNITY_CUSTOMER):
      return { ...state, loading: true };
    case SUCCESS(FETCH_OPPORTUNITY_CUSTOMER_INCLUDE_MEMBER_LIST):
    case SUCCESS(FETCH_OPPORTUNITY_CUSTOMER_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case SUCCESS(DELETE_OPPORTUNITY_CUSTOMER):
      return { ...state, loading: false };
    case FAILURE(FETCH_OPPORTUNITY_CUSTOMER_LIST):
    case FAILURE(FETCH_OPPORTUNITY_CUSTOMER_INCLUDE_MEMBER_LIST):
    case FAILURE(DELETE_OPPORTUNITY_CUSTOMER):
    case FAILURE(UPDATE_OPPORTUNITY_CUSTOMER):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(UPDATE_OPPORTUNITY_CUSTOMER):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_OPPORTUNITY_CUSTOMER:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
