import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import { clearCustomer, fetchCustomerList } from '../../../state/customer';
import { Customer, CustomerListFilter, CustomerState } from '../../../state/customer';
import useStyles from './customerList.style';

const CustomerList: React.FC<RouteComponentProps> = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [filters, setFilters] = useState<CustomerListFilter>({
    'Name.startsWith': '',
  });
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const { items: customers, totalItems } = useSelector<AppState, CustomerState>(
    (state) => state.customer,
  );
  const dispatch = useDispatch();

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchCustomerList(filters, paging));
  }, [dispatch, paging, filters]);

  useEffect(() => {
    return () => {
      dispatch(clearCustomer());
    };
  }, [dispatch]);

  const addNewCustomer = (): void => {
    history.push('/settings/customers/form');
  };

  const editCustomer = (id: Customer['id']): void => {
    history.push(`/settings/customers/form/${id}`);
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Customer
      </Typography>
      <div className={classes.filterContainer}>
        <TextField
          name="Name.startsWith"
          type="search"
          label="Name"
          className={`${classes.filterInput} ${classes.lastFilterInput}`}
          margin="dense"
          value={filters['Name.startsWith']}
          onChange={handleChangeFilter}
          variant="outlined"
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={addNewCustomer}
          size="large"
        >
          <AddIcon className={classes.iconButton} />
          New Customer
        </Button>
      </div>
      <div>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellHeader}>No.</TableCell>
              <TableCell className={classes.tableCellHeader}>Name</TableCell>
              <TableCell className={classes.tableCellHeader}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer, index) => (
              <TableRow key={customer.id}>
                <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                <TableCell>{customer.name}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => editCustomer(customer.id)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[15, 25, 35]}
          component="div"
          count={totalItems}
          rowsPerPage={paging.pageSize}
          page={paging.pageNumber}
          onPageChange={handleChangePageNumber}
          onRowsPerPageChange={handleChangePageSize}
        />
      </div>
    </Paper>
  );
};

export default CustomerList;
