import { CircularProgress, Grid, Typography } from '@material-ui/core';
import dagre from 'dagre';
import { flattenDeep } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  Background,
  ConnectionLineType,
  Controls,
  isNode,
  MiniMap,
} from 'react-flow-renderer';
import * as structureService from '../../../../services/structureService';
import { SquadStructure as Model } from './squadStructure.model';

const edgeType = 'smoothstep';

export interface SquadStructureProps {
  assignmentNumber: string | undefined;
}

interface SquadStructureState {
  loading: boolean;
  data: Model[];
}

const getInitState = () => {
  return {
    loading: false,
    data: [],
  };
};

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (elements: any) => {
  dagreGraph.setGraph({ rankdir: 'TB' });
  elements.forEach((el: any) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: 320, height: 150 });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });
  dagre.layout(dagreGraph);
  return elements.map((el: any) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.position = {
        x: nodeWithPosition.x + Math.random() / 1000,
        y: nodeWithPosition.y,
      };
      el.style = {
        ...el.style,
        width: 320,
        height: 60,
      };
    }
    return el;
  });
};

const SquadStructure: React.FC<SquadStructureProps> = (props) => {
  const { assignmentNumber } = props;
  const [state, setState] = useState<SquadStructureState>(getInitState());

  useEffect(() => {
    if (assignmentNumber) {
      const func = async () => {
        setState((prev) => ({ ...prev, loading: true }));
        const { data } = await structureService.fetchSquadStructureByAssignmentNumber(
          assignmentNumber,
        );
        setState((prev) => ({
          ...prev,
          data,
        }));
        setState((prev) => ({ ...prev, loading: false }));
      };

      func();
    }
  }, [assignmentNumber]);

  const elements = useMemo(() => {
    const squadData = state.data;
    const memberData = flattenDeep(state.data.map((sq) => sq.members));

    const squads = squadData.map((sq) => {
      return {
        id: `SQUAD-${sq.id.toString()}`,
        type: 'input',
        data: {
          label: (
            <div>
              <Typography style={{ fontWeight: 'bold' }}>Squad {sq.name}</Typography>
              <Typography> {sq.owner}</Typography>
            </div>
          ),
        },
      };
    });

    const members = memberData.map((member) => {
      return {
        id: `MEMBER-${member.name}`,
        type: 'input',
        data: {
          label: (
            <div>
              <Typography style={{ fontWeight: 'bold' }}>
                Member {member.externalId}
              </Typography>
              <Typography>{member.name}</Typography>
            </div>
          ),
        },
      };
    });

    const linkSquadAndMembers = memberData.map((member, idx) => {
      return {
        id: `LINK-SQUAD${member.squadId}-MEMBER${member.name}-${idx}`,
        type: edgeType,
        source: `SQUAD-${member.squadId}`,
        target: `MEMBER-${member.name}`,
      };
    });

    const teams = memberData.map((member) => {
      return {
        id: `TEAM-${member.team.name}`,
        type: 'input',

        data: {
          label: (
            <div>
              <Typography style={{ fontWeight: 'bold' }}>
                Team {member.team.name}
              </Typography>
              <Typography>{member.team.owner}</Typography>
            </div>
          ),
        },
      };
    });

    const linkMemberAndTeams = memberData.map((member, idx) => {
      return {
        id: `LINK-MEMBER${member.squadId}-TEAM${member.team.name}-${idx}`,
        type: edgeType,
        source: `MEMBER-${member.name}`,
        target: `TEAM-${member.team.name}`,
      };
    });

    const groups = memberData.map((member) => {
      return {
        id: `GROUP-${member.group.name}`,
        type: 'input',
        data: {
          label: (
            <div>
              <Typography style={{ fontWeight: 'bold' }}>
                Group {member.group.name}
              </Typography>
              <Typography>{member.group.owner}</Typography>
            </div>
          ),
        },
      };
    });

    const linkTeamAndGroups = memberData.map((member, idx) => {
      return {
        id: `LINK-TEAM${member.team.name}-GROUP${member.team.name}-${idx}`,
        type: edgeType,
        source: `TEAM-${member.team.name}`,
        target: `GROUP-${member.group.name}`,
      };
    });

    return getLayoutedElements([
      ...squads,
      ...members,
      ...linkSquadAndMembers,
      ...teams,
      ...linkMemberAndTeams,
      ...groups,
      ...linkTeamAndGroups,
    ]);
  }, [state.data]);

  if (!assignmentNumber) {
    return null;
  }

  const onLoad = (reactFlowInstance: any) => {
    reactFlowInstance.fitView();
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      {state.loading ? (
        <Grid container justifyContent="center" style={{ paddingTop: 50 }}>
          <CircularProgress />
        </Grid>
      ) : (
        <ReactFlow
          onLoad={onLoad}
          elements={elements}
          connectionLineType={ConnectionLineType.SmoothStep}
          snapToGrid={true}
          snapGrid={[15, 15]}
        >
          <MiniMap />
          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow>
      )}
    </div>
  );
};

export default SquadStructure;
