import { PageFilter } from '../state/app';
import axios from '../utils/axios';
import { ISampleDevelopmentNl } from '../models/sampleDevelopmentNl.model';
import { SampleDevelopmentNlListFilter } from '../state/sampleDevelopmentNl';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';

const baseUrl = `/api/sampleDevelopmentNls`;

export const fetchSampleDevelopmentNlList = async ({
  filters,
  paging,
}: {
  filters: SampleDevelopmentNlListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchSampleDevelopmentNl = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const downloadSampleDevelopmentNl = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}/export`;
  return await axios.get(requestUrl, { responseType: 'blob' });
};

export const createSampleDevelopmentNl = async (
  sampleDevelopmentNl: ISampleDevelopmentNl,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, sampleDevelopmentNl);
};

export const updateSampleDevelopmentNl = async (
  sampleDevelopmentNl: ISampleDevelopmentNl,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, sampleDevelopmentNl);
};

export const submitSampleDevelopmentNlForApproval = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}/submitForApproval`;
  return await axios.post(requestUrl);
};
