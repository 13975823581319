import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState } from '../../../../state/auth';
import { AppState } from '../../../../state/configureStore';

import { Box, makeStyles, Paper, TablePagination, Typography } from '@material-ui/core';
import { ISort, PageFilter } from '../../../../state/app';
import { DateTimeDisplay } from '../../../components/DateTimeDisplay';
import TaskAndSubtaskLinkButton from '../../../components/TaskAndSubtaskLinkButton';
import {
  fetchTaskActivityListRelatedToUserId,
  clearCommentDashboard,
} from './commentDashboard.actions';
import { CommentDashboardState } from './commentDashboard.reducer';
import { DisplayHtmlContent } from '../../../components';
import { groupBy } from 'lodash';
import { TaskActivityList } from '../../task/taskActivity';
import { ITaskActivity } from '../../../../models/taskActivity.model';
import { TablePageSize } from '../../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  comment: {
    margin: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
}));

const Dashboard = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { userId } = useSelector<AppState, AuthState>((state) => state.auth);
  const { items: taskActivityList, totalItems } = useSelector<
    AppState,
    CommentDashboardState
  >((state) => state.commentDashboard);
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 10,
  });
  const sort: ISort = useMemo(() => ({ orderBy: 'createdAt', direction: 'desc' }), []);

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    if (userId) {
      dispatch(fetchTaskActivityListRelatedToUserId(userId, { ...paging, sort }));
    }
    return function cleanup() {
      dispatch(clearCommentDashboard());
    };
  }, [dispatch, userId, paging, sort]);

  const groups = groupBy(
    [...taskActivityList],
    (taskActivity) => taskActivity.smTask!.taskNumber,
  );

  return (
    <Paper className={classes.root} elevation={2}>
      <Typography variant="h6">Last comments</Typography>
      {Object.keys(groups).map((taskNumber) => {
        const taskActivities = groups[taskNumber];
        const startActivity = [...taskActivities]
          .sort((x, y) => +new Date(x.createdAt!) - +new Date(y.createdAt!))
          .pop();

        return (
          <Paper className={classes.comment} key={startActivity!.id} elevation={3}>
            <Box m={1}>
              <Typography component="span" variant="caption">
                <Box fontWeight={500}>
                  <TaskAndSubtaskLinkButton taskActivity={startActivity!} />
                  {startActivity!.user!.fullName}
                  {' at '}
                  {<DateTimeDisplay value={startActivity!.createdAt} />}
                </Box>
              </Typography>
            </Box>
            <Box m={1}>
              <DisplayHtmlContent>{startActivity!.comment!}</DisplayHtmlContent>
            </Box>
          </Paper>
        );
      })}
      <TablePagination
        rowsPerPageOptions={[...TablePageSize]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
        labelRowsPerPage="Comments per page"
      />
    </Paper>
  );
};

export default Dashboard;
