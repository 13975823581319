import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { SubtaskItemArtTemplateListFilter } from '../../../../state/subtaskItemArtTemplate';
import { SearchField } from '../../../components';
import {
  FilterContainer,
  IFilterContainerConfigProps,
} from '../../../components/FilterContainer';

const TaskSubtaskItemArtTemplateFilter: React.FC<
  IFilterContainerConfigProps<SubtaskItemArtTemplateListFilter>
> = props => {
  return (
    <FilterContainer
      {...props}
      render={({ localFilter, handleChangeFilter, handleChangeDateFilter }) => {
        return (
          <Grid container spacing={1} alignItems={'center'}>
            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                label="Task Number"
                filterKey={'SmSubtaskItem.SmSubtask.SMTask.TaskNumber.StartsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                label="Program"
                filterKey={'BlockArtTemplate.Block.Program.Name.StartsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                label="Brand"
                filterKey={'BlockArtTemplate.Block.Brand.Name.StartsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                label="Item"
                filterKey={'BlockArtTemplate.Block.Item.StartsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                label="Block No."
                filterKey={'BlockArtTemplate.Block.BlockNo.StartsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                label="Subtask Subject"
                filterKey={'SmSubtaskItem.SmSubtask.Subject.StartsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <SearchField
                fullWidth={true}
                label="Art Template No."
                filterKey={'BlockArtTemplate.ArtTemplateNumber.StartsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default TaskSubtaskItemArtTemplateFilter;
