import React from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { FilterContainer, SearchDropdownField, SearchField } from '../../components';
import { SampleDevelopmentPlListFilter } from '../../../state/sampleDevelopmentPl';
import { IFilterContainerConfigProps } from '../../components/FilterContainer';
import SampleDevelopmentStatus, {
  SampleDevelopmentStatusLabel,
} from '../../../constants/sampleDevelopmentStatus';

const SampleDevelopmentPlFilter: React.FC<
  IFilterContainerConfigProps<SampleDevelopmentPlListFilter>
> = props => {
  return (
    <FilterContainer
      {...props}
      render={filterProps => {
        const { localFilter, handleChangeFilter } = filterProps;
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="SampleDevNumber.startsWith"
                filters={localFilter}
                label="SampleDevelopment Number"
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchDropdownField
                label="Status"
                filterKey={'Status.equalsTo'}
                filters={localFilter}
                onChange={handleChangeFilter}
                id="status"
              >
                <MenuItem value={''}>All</MenuItem>
                {Object.entries(SampleDevelopmentStatus).map(([key, value]) => {
                  return (
                    <MenuItem key={key} value={value}>
                      {SampleDevelopmentStatusLabel[value]}
                    </MenuItem>
                  );
                })}
              </SearchDropdownField>
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Block.Program.Name.StartsWith"
                filters={localFilter}
                label="Program"
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Block.Brand.Name.StartsWith"
                filters={localFilter}
                label="Brand"
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Block.Item.StartsWith"
                filters={localFilter}
                label="Item"
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Block.BlockNo.StartsWith"
                filters={localFilter}
                label="Block"
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="CreatedBy.startsWith"
                filters={localFilter}
                label="Created By"
                onChange={handleChangeFilter}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default SampleDevelopmentPlFilter;
