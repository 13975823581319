import { Button, Tooltip, Typography } from '@material-ui/core';
import {
  FileCopy as FileCopyIcon,
  Save as SaveIcon,
  Send as SendIcon,
  Refresh as RefreshIcon,
  SyncProblem as SyncProblemIcon,
} from '@material-ui/icons';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { OpportunityStatus } from '../../../../constants';
import { IOpportunity } from '../../../../models/opportunity.model';
import { AuthState } from '../../../../state/auth';
import { AppState } from '../../../../state/configureStore';
import {
  submitForApproval,
  submitForReassignResponsibility,
} from '../../../../state/opportunity';
import { PageTopMenuContainer } from '../../../components';
import { CopyOpportunityDialog } from '../CopyOpportunityDialog';
import AssignmentStructureDialog from './AssignmentStructureDialog';
import OpportunityChangeProgramBrandDialog from './OpportunityChangeProgramBrandDialog';

const statusAllowForSubmitForApproval = [
  OpportunityStatus.PENDING_FOR_APPROVAL,
  OpportunityStatus.REJECTED,
];

interface OpportunityFormActionMenuProps {
  opportunity: IOpportunity;
}

const OpportunityFormActionMenu: React.FC<OpportunityFormActionMenuProps> = (props) => {
  const dispatch = useDispatch();
  const isFromAssignmentApprovals = useRouteMatch('/assignments/approvals');

  const { opportunity } = props;
  const [openCopyOpportunityDialog, setOpenCopyOpportunityDialog] =
    useState<boolean>(false);
  const [openOppChangeProgramBrandDialog, setOpenOppChangeProgramBrandDialog] =
    useState<boolean>(false);
  const { user } = useSelector<AppState, AuthState>((state) => state.auth);
  const isAdmin = user?.roles?.find((f) => f === 'ADMIN') !== undefined;
  const isNPD = user?.roles?.find((f) => f === 'NPD') !== undefined;
  const isDummy =
    opportunity.program?.name === 'FCW(DUMMY)' ||
    opportunity.brand?.name === 'FCW(DUMMY)';
  const isUpdateMode = !!opportunity.id;

  const [openStructureDialog, setOpenStructureDialog] = useState<boolean>(false);

  const [disableSubmitApprove, setDisableSubmitApprove] = useState<boolean>(false);

  return (
    <>
      <PageTopMenuContainer>
        {isUpdateMode && (
          <Tooltip
            title={
              <Typography variant="subtitle1" gutterBottom>
                Reassign Responsibility
              </Typography>
            }
          >
            <Button
              color="default"
              variant="contained"
              onClick={() => {
                dispatch(submitForReassignResponsibility(opportunity.assignmentNumber));
              }}
              startIcon={<RefreshIcon />}
            >
              {`Reassign`}
            </Button>
          </Tooltip>
        )}
        {!isFromAssignmentApprovals &&
          isUpdateMode &&
          (isAdmin || (isNPD && isDummy)) && (
            <Tooltip
              title={
                <Typography variant="subtitle1" gutterBottom>
                  Change Program And Brand
                </Typography>
              }
            >
              <Button
                color="default"
                variant="contained"
                onClick={() => {
                  setOpenOppChangeProgramBrandDialog(true);
                }}
                startIcon={<SyncProblemIcon />}
              >
                Change
              </Button>
            </Tooltip>
          )}
        {!isFromAssignmentApprovals && isUpdateMode && (
          <Tooltip
            title={
              <Typography variant="subtitle1" gutterBottom>
                Copy Assignment
              </Typography>
            }
          >
            <Button
              color="default"
              variant="contained"
              onClick={() => {
                setOpenCopyOpportunityDialog(true);
              }}
              startIcon={<FileCopyIcon />}
            >
              Copy
            </Button>
          </Tooltip>
        )}
        {statusAllowForSubmitForApproval.includes(opportunity.status!) && (
          <Tooltip
            title={
              <Typography variant="subtitle1" gutterBottom>
                Submit For Approval
              </Typography>
            }
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setDisableSubmitApprove(true);
                dispatch(submitForApproval(opportunity.id));
              }}
              startIcon={<SendIcon />}
              disabled={disableSubmitApprove}
            >
              {`Submit`}
            </Button>
          </Tooltip>
        )}
        <Tooltip
          title={
            <Typography variant="subtitle1" gutterBottom>
              {!isUpdateMode ? `Create Assignment` : `Update Assignment`}
            </Typography>
          }
        >
          <Button
            type={'submit'}
            color="primary"
            variant="contained"
            startIcon={<SaveIcon />}
          >
            {!isUpdateMode ? `Create` : `Update`}
          </Button>
        </Tooltip>
        {!isFromAssignmentApprovals && isUpdateMode && (
          <CopyOpportunityDialog
            opportunity={opportunity}
            handleClose={() => setOpenCopyOpportunityDialog(false)}
            open={openCopyOpportunityDialog}
          />
        )}
        {!isFromAssignmentApprovals && isUpdateMode && (
          <OpportunityChangeProgramBrandDialog
            opportunity={opportunity}
            open={openOppChangeProgramBrandDialog}
            onClose={() => setOpenOppChangeProgramBrandDialog(false)}
          />
        )}
      </PageTopMenuContainer>
      <AssignmentStructureDialog
        open={openStructureDialog}
        onClose={() => setOpenStructureDialog(false)}
        assignmentNumber={opportunity.assignmentNumber}
      />
    </>
  );
};

export default OpportunityFormActionMenu;
