import {
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { CompanyState, fetchCompanyList } from '../../../state/company';
import { AppState } from '../../../state/configureStore';
import {
  clearCustomer,
  createCustomer,
  Customer,
  CustomerState,
  fetchCustomer,
  updateCustomer,
} from '../../../state/customer';
import { FixedPositionButtons } from '../../components';
import useStyles from './customerForm.style';

const CustomerSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  companyId: Yup.number().required('Company is required'),
  externalId: Yup.string().required('ExternalId is required'),
  externalCompanyId: Yup.string().required('ExternalCompanyId is required'),
  externalSource: Yup.string().required('ExternalSource is required'),
});

interface CustomerFormRouteParamsProps {
  id: string;
}

interface CustomerFormProps extends RouteComponentProps<CustomerFormRouteParamsProps> {}

const CustomerForm: React.FC<CustomerFormProps> = props => {
  const { match, history } = props;
  const {
    params: { id: paramsId },
  } = match;
  const classes = useStyles();
  const {
    item: {
      name = '',
      id = 0,
      companyId = 0,
      externalId = '',
      externalCompanyId = '',
      externalSource = '',
    },
  } = useSelector<AppState, CustomerState>(state => state.customer);
  const { items: companies } = useSelector<AppState, CompanyState>(
    state => state.company,
  );
  const dispatch = useDispatch();

  const redirectToCustomerList = () => {
    history.push('/settings/customers');
  };

  const handleSubmit = (values: Customer, actions: FormikHelpers<Customer>) => {
    if (paramsId) {
      dispatch(updateCustomer({ values, history }));
    } else {
      dispatch(createCustomer({ values, history }));
    }
    actions.setSubmitting(false);
  };

  useEffect(() => {
    if (paramsId) {
      dispatch(fetchCustomer(+paramsId));
    } else {
      dispatch(clearCustomer());
    }

    dispatch(fetchCompanyList({}, { pageNumber: 0, pageSize: 99999 }));
    return function cleanUp() {
      dispatch(clearCustomer());
    };
  }, [paramsId, dispatch]);

  return (
    <Card className={classes.root}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name,
          id,
          companyId,
          externalId,
          externalCompanyId,
          externalSource,
        }}
        validationSchema={CustomerSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, errors, touched, submitForm }) => {
          return (
            <Form>
              <Typography variant="h4" className={classes.title}>
                {paramsId === undefined ? `New Customer` : `Update Customer`}
              </Typography>
              <CardContent>
                <TextField
                  name="name"
                  type="input"
                  label="Name"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={values.name}
                  onChange={handleChange}
                  error={!!errors.name && !!touched.name}
                  helperText={errors.name}
                />
                <FormControl
                  className={classes.formControl}
                  error={!!errors.companyId && !!touched.companyId}
                  fullWidth
                >
                  <InputLabel htmlFor="companyId">Company</InputLabel>
                  <Select
                    value={values.companyId}
                    onChange={handleChange}
                    name="companyId"
                    input={<Input name="companyId" id="companyId" />}
                  >
                    {companies.map(company => (
                      <MenuItem value={company.id} key={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.companyId}</FormHelperText>
                </FormControl>
                <TextField
                  name="externalId"
                  type="input"
                  label="ExternalId"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={values.externalId}
                  onChange={handleChange}
                  error={!!errors.externalId && !!touched.externalId}
                  helperText={errors.externalId}
                />
                <TextField
                  name="externalCompanyId"
                  type="input"
                  label="ExternalCompanyId"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={values.externalCompanyId}
                  onChange={handleChange}
                  error={!!errors.externalCompanyId && !!touched.externalCompanyId}
                  helperText={errors.externalCompanyId}
                />
                <TextField
                  name="externalSource"
                  type="input"
                  label="ExternalSource"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={values.externalSource}
                  onChange={handleChange}
                  error={!!errors.externalSource && !!touched.externalSource}
                  helperText={errors.externalSource}
                />
              </CardContent>
              <FixedPositionButtons
                actions={[
                  {
                    name: paramsId === undefined ? `Create` : `Update`,
                    onClick: () => {
                      submitForm();
                    },
                    color: 'primary' as 'primary',
                    disabled: false,
                    icon: 'save',
                  },
                  {
                    name: 'CANCEL',
                    onClick: redirectToCustomerList,
                    color: 'default' as 'default',
                    disabled: false,
                  },
                ]}
                open
              />
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default CustomerForm;
