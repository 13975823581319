export function getAuthToken(): string | null {
  return sessionStorage.getItem('token');
}

export function saveAuthToken(token: string | null): void {
  if (token) {
    sessionStorage.setItem('token', token);
  }
}

export function deleteAuthToken(): void {
  sessionStorage.removeItem('token');
}
