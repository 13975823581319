import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../state/configureStore';
import {
  clearWorkingTeamMember,
  fetchWorkingTeamMemberListByWorkingTeamId,
  WorkingTeamMemberState,
} from '../../../state/workingTeamMember';
import { TableLoading } from '../../components';

interface WorkingTeamMemberDialogProps {
  handleClose: () => void;
  open: boolean;
  workingTeamId: number;
}

const WorkingTeamMemberDialog: React.FC<WorkingTeamMemberDialogProps> = props => {
  const { open, handleClose, workingTeamId } = props;
  const dispatch = useDispatch();
  const { items: workingTeamMemberList, loading } = useSelector<
    AppState,
    WorkingTeamMemberState
  >(state => state.workingTeamMember);

  useEffect(() => {
    if (workingTeamId) {
      dispatch(fetchWorkingTeamMemberListByWorkingTeamId(workingTeamId));
    }
    return function cleanup() {
      dispatch(clearWorkingTeamMember());
    };
  }, [dispatch, workingTeamId]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle>WorkingTeamMember</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Team</TableCell>
              <TableCell>UserName</TableCell>
              <TableCell>FirstName</TableCell>
              <TableCell>LastName</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={5}>
              {workingTeamMemberList.map((workingTeamMember, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{workingTeamMember.smWorkingTeam!.name}</TableCell>
                    <TableCell>{workingTeamMember.user!.login}</TableCell>
                    <TableCell>{workingTeamMember.user!.firstName}</TableCell>
                    <TableCell>{workingTeamMember.user!.lastName}</TableCell>
                    <TableCell>{workingTeamMember.externalId}</TableCell>
                    <TableCell>{workingTeamMember.salesTeamRole!.name}</TableCell>
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkingTeamMemberDialog;
