import React, { useCallback, useState } from 'react';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { AppState } from '../../../../state/configureStore';
import { useDispatch, useSelector } from 'react-redux';
import { OpportunityLinkButton } from '../../../components';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import {
  editMultipleSelectedTaskItemMoqInTaskCreate,
  editSelectedTaskItemMoqInTaskCreate,
  TaskCreateState,
} from '../../../../state/taskCreate';
import {
  MultipleTaskItemMoqEditDialog,
  TaskItemMoqEditDialog,
} from '../../../components/Moq';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ITaskItemWithMoqs } from '../../../../models/taskItem.model';
import {
  Close as CloseIcon,
  Save as SaveIcon,
  SkipNext as NextIcon,
  SkipPrevious as PreviousIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftIcon: {
      marginRight: theme.spacing(1),
    },
  }),
);

interface TaskItemMoqListDetailDialogProps {
  nextClick: () => void;
  backClick: () => void;
  closeClick: () => void;
}

const TaskItemMoqListDetailDialog: React.FC<TaskItemMoqListDetailDialogProps> = (
  props,
) => {
  const { nextClick, backClick, closeClick } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleNextClick = () => {
    nextClick();
  };
  const [openTaskItemMoqEditDialog, setOpenTaskItemMoqEditDialog] = useState(false);
  const [openMultipleTaskItemMoqEditDialog, setOpenMultipleTaskItemMoqEditDialog] =
    useState(false);

  const [taskItemToEditMoq, setTaskItemToEditMoq] = useState({});
  const { selectedTaskItemsWithMoqs } = useSelector<AppState, TaskCreateState>(
    (state) => state.taskCreate,
  );

  const isTaskItemHasMoq = useCallback(
    (taskItemWithMoqs: ITaskItemWithMoqs) => taskItemWithMoqs.moqs!.length > 0,
    [],
  );

  return (
    <>
      <TaskItemMoqEditDialog
        open={openTaskItemMoqEditDialog}
        taskItemToEditMoq={taskItemToEditMoq}
        onClose={() => {
          setOpenTaskItemMoqEditDialog(false);
        }}
        onSubmit={(editedTaskItemMoq) => {
          dispatch(editSelectedTaskItemMoqInTaskCreate(editedTaskItemMoq));
        }}
      />
      <MultipleTaskItemMoqEditDialog
        open={openMultipleTaskItemMoqEditDialog}
        taskItemToEditMoqs={selectedTaskItemsWithMoqs}
        onClose={() => {
          setOpenMultipleTaskItemMoqEditDialog(false);
        }}
        onSubmit={(editedTaskItemMoqs) => {
          dispatch(editMultipleSelectedTaskItemMoqInTaskCreate(editedTaskItemMoqs));
        }}
      />
      <DialogTitle style={{ paddingTop: '8px', paddingBottom: '8px' }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          Create MOQ
          <IconButton
            color="inherit"
            aria-label="close"
            onClick={() => {
              closeClick();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Assignment Number</TableCell>
              <TableCell>Program</TableCell>
              <TableCell>Brand</TableCell>
              <TableCell>Block</TableCell>
              <TableCell>Item</TableCell>
              <TableCell>Has MOQ</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedTaskItemsWithMoqs.map((item, index) => {
              const block = item.opportunityItem?.block!;
              const opportunity = item.opportunityItem?.opportunity!;
              return (
                <TableRow key={item.opportunityItemId}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {opportunity.assignmentNumber}
                    {/* <OpportunityLinkButton opportunity={opportunity} /> */}
                  </TableCell>
                  <TableCell>{opportunity.program?.name}</TableCell>
                  <TableCell>{opportunity.brand?.name}</TableCell>
                  <TableCell>{block.blockNo}</TableCell>
                  <TableCell>{block.item}</TableCell>
                  <TableCell>
                    <Checkbox checked={isTaskItemHasMoq(item)} disabled />
                  </TableCell>
                  <TableCell>
                    {/* <Button
                      onClick={() => {
                        setTaskItemToEditMoq(item);
                        setOpenTaskItemMoqEditDialog(true);
                      }}
                      variant="contained"
                    >
                      <EditIcon className={classes.leftIcon} />
                      Edit MOQ
                    </Button> */}
                    <Tooltip title="Edit MOQ">
                      <span>
                        <IconButton
                          color="default"
                          size="small"
                          onClick={() => {
                            setTaskItemToEditMoq(item);
                            setOpenTaskItemMoqEditDialog(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        {/* <Button
          onClick={() => {
            closeClick();
          }}
          variant="contained"
          color="default"
          startIcon={<CloseIcon />}
        >
          Close
        </Button> */}
        <Button
          onClick={() => {
            setOpenMultipleTaskItemMoqEditDialog(true);
          }}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
        >
          Edit Multiple MOQ
        </Button>
        <Button
          variant="contained"
          color="default"
          startIcon={<PreviousIcon />}
          onClick={backClick}
        >
          Back
        </Button>
        <Button
          variant="contained"
          startIcon={<NextIcon />}
          onClick={handleNextClick}
          color="primary"
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
};

export default TaskItemMoqListDetailDialog;
