import { Grid, TextField, Box, Button } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { ITask } from '../../../../models/task.model';
import { Block } from '../../../../state/block';
import { Customer } from '../../../../state/customer';
import {
  CustomerComboBox,
  TaskComboBox,
  TaskBlockComboBox,
  JobTypeFastField,
  UnitOfQuantitiesFastField,
  CompanyComboBox,
  SalesTeamMemberComboBox,
  BlockEditDialog,
} from '../../../components';
import useStyles from './sampleDevelopmentHl.style';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTaskItemList } from '../../../../state/taskItem';
import { Company } from '../../../../state/company';
import { TaskItemState } from '../../../../state/taskItem';
import { AppState } from '../../../../state/configureStore';
import { SalesTeamMember } from '../../../../state/salesTeamMember';
import { setBlock, BlockState } from '../../../../state/block';

interface SampleDevelopmentHlHeadProps {
  values: any;
  handleChange: any;
  errors: any;
  setFieldValue: any;
}

const SampleDevelopmentHlHead: React.FC<SampleDevelopmentHlHeadProps> = (props) => {
  const { values, handleChange, errors, setFieldValue } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { items: taskItems } = useSelector<AppState, TaskItemState>((t) => t.taskItem);
  const { item: block } = useSelector<AppState, BlockState>((t) => t.block);

  const [openBlockEditDialog, setOpenBlockEditDialog] = useState(false);

  useEffect(() => {
    if (taskItems.length > 0) {
      setFieldValue('salesTeam', taskItems[0].opportunityItem!.opportunity!.salesTeam);
      setFieldValue('orderCompany', taskItems[0].opportunityItem!.opportunity!.company);
      setFieldValue(
        'orderCompanyId',
        taskItems[0].opportunityItem!.opportunity!.company!.id!,
      );
    }
  }, [taskItems, setFieldValue]);

  useEffect(() => {
    if (
      block &&
      block.itemDescription &&
      block.itemDescription !== values.block.itemDescription
    ) {
      setFieldValue('block', block);
    }
  }, [block, values.block, setFieldValue]);

  return (
    <>
      <BlockEditDialog
        open={openBlockEditDialog}
        onClose={() => {
          setOpenBlockEditDialog(false);
        }}
      />
      <Box m={1}>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} md={3}>
              <CustomerComboBox
                handleChange={(result) => {
                  setFieldValue('customer', result ? result : null);
                  setFieldValue('customerId', result ? result.id : null);
                }}
                selectedValue={values.customer as Customer}
                error={!!errors.customer}
                helperText={errors.customer}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TaskComboBox
                handleChange={(result) => {
                  if (!result) {
                    setFieldValue('block', null);
                    setFieldValue('blockId', null);
                    setFieldValue('salesTeamMember', null);
                    setFieldValue('salesTeamMemberId', null);
                    setFieldValue('salesTeam', null);
                    setFieldValue('orderCompany', null);
                    setFieldValue('orderCompanyId', null);
                  }
                  setFieldValue('smTask', result ? result : null);
                  setFieldValue('smTaskId', result ? result.id : null);
                }}
                selectedValue={values.smTask as ITask}
                error={!!errors.smTask}
                helperText={errors.smTask}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TaskBlockComboBox
                handleChange={(result) => {
                  if (!result) {
                    setFieldValue('salesTeamMember', null);
                    setFieldValue('salesTeamMemberId', null);
                    setFieldValue('salesTeam', null);
                    setFieldValue('orderCompany', null);
                    setFieldValue('orderCompanyId', null);
                  }
                  setFieldValue('block', result ? result : null);
                  setFieldValue('blockId', result ? result.id : null);
                  if (result) {
                    dispatch(
                      fetchTaskItemList(
                        {
                          'OpportunityItem.BlockId.EqualsTo': result.id!.toString(),
                          'SMTaskId.EqualsTo': values.smTask.id,
                        },
                        { pageNumber: 0, pageSize: 10 },
                      ),
                    );
                  }
                }}
                selectedValue={values.block as Block}
                error={!!errors.block}
                helperText={errors.block}
                taskId={values.smTask ? values.smTask.id : ''}
                isDisabled={!values.smTask}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                className={classes.textInput}
                name="type"
                type="input"
                label="Type"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                value={values.block ? values.block.productType!.description : ''}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={1} alignItems="center">
            <Grid item xs={12} md={3}>
              <JobTypeFastField
                name="jobType"
                label="Job Type"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <KeyboardDatePicker
                margin="normal"
                label="See Model"
                format="yyyy-MM-dd"
                value={values.seeModel}
                onChange={(selectedDate, selectedStringDate) => {
                  setFieldValue('seeModel', selectedStringDate);
                }}
                error={!!errors.seeModel}
                helperText={errors.seeModel}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <KeyboardDatePicker
                margin="normal"
                label="Due Date"
                format="yyyy-MM-dd"
                value={values.dueDate}
                onChange={(selectedDate, selectedStringDate) => {
                  setFieldValue('dueDate', selectedStringDate);
                }}
                error={!!errors.dueDate}
                helperText={errors.dueDate}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                className={classes.textInput}
                name="quantity"
                type="number"
                label="Quantity"
                variant="outlined"
                margin="dense"
                value={values.quantity}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <UnitOfQuantitiesFastField
                name="quantityUnit"
                label="Unit"
                margin="dense"
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} md={3}>
              <TextField
                className={classes.textInput}
                name="salesTeamName"
                type="input"
                label="SalesTeam Name"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                value={values.salesTeam ? values.salesTeam.name : ''}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <SalesTeamMemberComboBox
                handleChange={(result) => {
                  setFieldValue('salesTeamMember', result ? result : null);
                  setFieldValue('salesTeamMemberId', result ? result.id : null);
                }}
                selectedValue={values.salesTeamMember as SalesTeamMember}
                error={!!errors.salesTeamMember}
                helperText={errors.salesTeamMember}
                isDisabled={!values.smTask || !values.block}
                salesTeamId={values.salesTeam ? values.salesTeam.id : ''}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CompanyComboBox
                handleChange={() => {}}
                selectedValue={values.orderCompany as Company}
                isDisabled
              />
            </Grid>
            <Grid item xs={12} md={3} />
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} md={2}>
              <TextField
                className={classes.textInput}
                name="program"
                type="input"
                label="Program"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                value={values.block ? values.block.program!.name : ''}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                className={classes.textInput}
                name="brand"
                type="input"
                label="Brand"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                value={values.block ? values.block.brand!.name : ''}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                className={classes.textInput}
                name="item"
                type="input"
                label="Item"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                value={values.block ? values.block.item! : ''}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <div className={classes.blockDescriptionContainer}>
                <TextField
                  className={classes.textInput}
                  name="description"
                  type="input"
                  label="Description"
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                  value={values.block ? values.block.itemDescription : ''}
                  disabled
                  fullWidth
                />
                <Button
                  onClick={() => {
                    if (values.block) {
                      dispatch(setBlock(values.block));
                      setOpenBlockEditDialog(true);
                    }
                  }}
                >
                  Edit
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SampleDevelopmentHlHead;
