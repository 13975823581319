import { PageFilter } from '../state/app';
import { EBlockPatternListFilter } from '../state/eBlockPattern';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';
import { IEBlockPattern } from '../models/eBlockPattern';

const baseUrl = `/api/eBlockPatterns`;

export const fetchEBlockPatternList = async ({
  filters,
  paging,
}: {
  filters: EBlockPatternListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchEBlockPattern = async (id: IEBlockPattern['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createEBlockPattern = async (eBlockPattern: IEBlockPattern) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, eBlockPattern);
};

export const updateEBlockPattern = async (eBlockPattern: IEBlockPattern) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, eBlockPattern);
};
