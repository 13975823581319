export type SubtaskCloseReasonType = 'DUPLICATE' | 'OVER_DUE_DATE' | 'CUSTOMER_CANCEL' | 'OTHER';

const SubtaskCloseReason = {
  DUPLICATE: 'DUPLICATE' as SubtaskCloseReasonType,
  OVER_DUE_DATE: 'OVER_DUE_DATE' as SubtaskCloseReasonType,
  CUSTOMER_CANCEL: 'CUSTOMER_CANCEL' as SubtaskCloseReasonType,
  OTHER: 'OTHER' as SubtaskCloseReasonType,
};

export default SubtaskCloseReason;

export const SubtaskCloseReasonLabel = {
  [SubtaskCloseReason.DUPLICATE]: 'Duplicate',
  [SubtaskCloseReason.OVER_DUE_DATE]: 'Over due date',
  [SubtaskCloseReason.CUSTOMER_CANCEL]: 'Customer cancel request',
  [SubtaskCloseReason.OTHER]: 'Other',
} ;

