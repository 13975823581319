import {
  Badge,
  CardContent,
  Chip,
  Container,
  Fade,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import * as Yup from 'yup';
import { OpportunityStatusLabel } from '../../../../constants';
import { IOpportunity } from '../../../../models/opportunity.model';
import { IProject } from '../../../../models/project.model';
import { AccountOwner } from '../../../../state/accountOwner';
import { Brand } from '../../../../state/brand';
import { Company } from '../../../../state/company';
import { AppState } from '../../../../state/configureStore';
import {
  clearOpportunity,
  createOpportunity,
  fetchOpportunity,
  OpportunityState,
  updateOpportunity,
} from '../../../../state/opportunity';
import {
  clearOpportunityCustomer,
  fetchOpportunityCustomerListByOpportunityId,
  OpportunityCustomerState,
} from '../../../../state/opportunityCustomer';
import {
  clearOpportunityCustomerGroup,
  fetchOpportunityCustomerGroupListByOpportunityId,
} from '../../../../state/opportunityCustomerGroup';
import {
  clearOpportunityItem,
  fetchOpportunityItemListByOpportunityId,
  OpportunityItemState,
} from '../../../../state/opportunityItem';
import { Program } from '../../../../state/program';
import { ProjectProgramState } from '../../../../state/projectProgram';
import { SalesTeam } from '../../../../state/salesTeam';
import {
  AccountOwnerComboBox,
  CompanyComboBox,
  PageContainer,
  PageTitle,
} from '../../../components';
import Loading from '../../../components/AwLoading';
import GoBackButton from '../../../components/GoBackButton';
import { ProjectComboBox } from '../../../components/ProjectComboBox';
import ProjectProgramBrandComboBoxSearch from '../../../components/ProjectProgramBrandComboBoxSearch';
import ProjectProgramComboBoxSearch from '../../../components/ProjectProgramComboBoxSearch';
import OpportunityFormActionMenu from './OpportunityFormActionMenu';
import OpportunitySubForm from './OpportunitySubForm';
import useStyles from './OpportunityForm.style';
import { IResponsibility } from '../../../../models/responsibility.model';
import { pushNotificationMessage } from '../../../components/Notification';
import ChipListUser from '../../../components/ChipListUser';

const OpportunitySchema = Yup.object().shape({
  company: Yup.object().nullable(true).required('Company is required'),
  smProject: Yup.object().nullable(true).required('SmProject is required'),
  program: Yup.object().nullable(true).required('Program is required'),
  brand: Yup.object().nullable(true).required('Brand is required'),
});

export interface IOpportunityFormValue {
  company?: Company | null;
  salesTeam?: SalesTeam | null;
  program?: Program | null;
  brand?: Brand | null;
  accountOwner?: AccountOwner | null;
  accountOwnerId?: number | null;
  smProject?: IProject | null;
  projectProgramId?: number | null;
  projectId?: number | null;
  responsibility?: IResponsibility;
}

export interface UserStatus {
  name?: string | undefined;
  activated?: boolean | undefined;
  userLeaveTotalDay?: number | undefined;
}

interface OpportunityFormProps {
  opportunityId?: number;
}

const useOpportunityStore = () => {
  const { items: opportunityItems } = useSelector<AppState, OpportunityItemState>(
    (s) => s.opportunityItem,
  );
  const { items: opportunityCustomers } = useSelector<AppState, OpportunityCustomerState>(
    (state) => state.opportunityCustomer,
  );
  const { items: opportunityCustomerGroups } = useSelector<
    AppState,
    OpportunityCustomerState
  >((state) => state.opportunityCustomerGroup);

  const { item: opportunity, loading: opportunityIsLoading } = useSelector<
    AppState,
    OpportunityState
  >((state) => state.opportunity);

  const { items: projectPrograms } = useSelector<AppState, ProjectProgramState>(
    (state) => state.projectProgram,
  );

  return {
    opportunity,
    opportunityItems,
    opportunityCustomers,
    opportunityCustomerGroups,
    isLoading: opportunityIsLoading,
    projectPrograms,
  };
};

const cleanUpChildValues = (dispatch: Dispatch<any>) => {
  dispatch(clearOpportunityItem());
  dispatch(clearOpportunityCustomer());
  dispatch(clearOpportunityCustomerGroup());
};

const OpportunityForm: React.FC<OpportunityFormProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { opportunityId } = props;
  const classes = useStyles();

  const {
    isLoading,
    opportunity,
    opportunityItems,
    opportunityCustomers,
    opportunityCustomerGroups,
  } = useOpportunityStore();

  const hasOpportunityItems = opportunityItems.length > 0;
  const hasOpportunityCustomers = opportunityCustomers.length > 0;
  const hasOpportunityCustomerGroups = opportunityCustomerGroups.length > 0;
  const isUpdateMode = opportunityId !== undefined;
  // const isShowAccountOwner =
  //   isUpdateMode &&
  //   (opportunity.status === 'APPROVED' || opportunity.status === 'REJECTED');

  const initialFormValues: IOpportunityFormValue = useMemo(() => {
    const {
      company,
      salesTeam,
      program,
      brand,
      accountOwner,
      accountOwnerId,
      smProject,
    } = opportunity;

    const projectId = smProject ? smProject!.id : 0;
    const smProjectPrograms = smProject ? smProject!.smProjectPrograms : [];
    const smProjectProgram = smProjectPrograms!.find(
      (s) => s.programId === opportunity.programId,
    );
    const projectProgramId = smProjectProgram ? smProjectProgram.id : 0;
    return {
      company,
      salesTeam,
      program,
      brand,
      accountOwner,
      accountOwnerId,
      smProject,
      projectId,
      projectProgramId,
    };
  }, [opportunity]);

  const salesUsers =
    opportunity.responsibility?.responsibilitySalesTeamMembers
      ?.filter((rs) => rs.active === true)
      ?.map((rs) => {
        const status =
          rs.status === 'P' ? 'Sales' : `Sales${rs.status?.replace('S', '')}`;
        return {
          name: `${rs.salesTeamMember?.user?.fullName} (${status})`,
          activated: rs.salesTeamMember?.user?.activated,
          userLeaveTotalDay: rs.salesTeamMember?.user?.userLeaveTotalDay,
        };
      }) || [];

  const csUsers =
    opportunity?.responsibility?.responsibilityWorkingTeamMembers
      ?.filter(
        (rw) =>
          rw.active === true &&
          rw.smWorkingTeamMember?.smWorkingTeam?.smWorkingGroup?.name === 'CS',
      )
      ?.map((rw) => {
        const status = rw.status === 'P' ? 'CS' : `C${rw.status}`;
        return {
          name: `${rw.smWorkingTeamMember?.user?.fullName} (${status})`,
          activated: rw.smWorkingTeamMember?.user?.activated,
          userLeaveTotalDay: rw.smWorkingTeamMember?.user?.userLeaveTotalDay,
        };
      }) || [];

  const ssoUsers =
    opportunity?.responsibility?.responsibilityWorkingTeamMembers
      ?.filter(
        (rw) =>
          rw.active === true &&
          rw.smWorkingTeamMember?.smWorkingTeam?.smWorkingGroup?.name === 'SSO',
      )
      ?.map((rw) => {
        const status = rw.status === 'P' ? 'SSO' : `SSO${rw.status?.replace('S', '')}`;
        return {
          name: `${rw.smWorkingTeamMember?.user?.fullName} (${status})`,
          activated: rw.smWorkingTeamMember?.user?.activated,
          userLeaveTotalDay: rw.smWorkingTeamMember?.user?.userLeaveTotalDay,
        };
      }) || [];

  useEffect(() => {
    if (isUpdateMode) {
      dispatch(fetchOpportunity(opportunityId!));
    } else {
      dispatch(clearOpportunity());
    }

    return function cleanUp() {
      dispatch(clearOpportunity());
    };
  }, [opportunityId, isUpdateMode, dispatch]);

  useEffect(() => {
    if (isUpdateMode && opportunity.id) {
      dispatch(fetchOpportunityItemListByOpportunityId(opportunity.id));
      dispatch(fetchOpportunityCustomerListByOpportunityId(opportunity.id));
      dispatch(fetchOpportunityCustomerGroupListByOpportunityId(opportunity.id));
    } else {
      cleanUpChildValues(dispatch);
    }
    return function cleanUp() {
      cleanUpChildValues(dispatch);
    };
  }, [dispatch, opportunity, isUpdateMode]);

  const handleSubmit = (
    values: IOpportunityFormValue,
    _: FormikHelpers<IOpportunityFormValue>,
  ) => {
    const newOpportunity: IOpportunity = {
      ...opportunity,
      companyId: values.company!.id,
      programId: values.program!.id,
      brandId: values.brand!.id,
      smProjectId: values.smProject!.id,
      smProject: values.smProject!,
    };
    if (values.accountOwner) {
      newOpportunity.accountOwnerId = values.accountOwner!.id;
      newOpportunity.accountOwner = values.accountOwner;
    } else {
      delete newOpportunity.accountOwner;
      delete newOpportunity.accountOwnerId;
    }
    delete newOpportunity.salesTeam;
    delete newOpportunity.smProject;

    if (opportunityId) {
      dispatch(updateOpportunity({ values: newOpportunity }));
    } else {
      dispatch(createOpportunity({ values: newOpportunity, history }));
    }
  };

  const renderUserChipList = (users: UserStatus[] | undefined, role: string) => {
    return (
      <>
        <div className={classes.label}>{role}</div>
        <div className={classes.paper}>
          {users && users.length === 0 && (
            <div>
              <Chip
                label={'None'}
                className={classes.chip}
                color="secondary"
                variant="outlined"
              />
            </div>
          )}
          {users &&
            users.map((user) => {
              const displayName = !user.activated ? `${user.name} (Inactive)` : user.name;
              return (
                <div key={user.name}>
                  {/* <Badge
                    badgeContent={7 - user.userLeaveTotalDay!}
                    color="secondary"
                    invisible={user.activated}
                  > */}
                  {user.activated && (
                    <Chip
                      label={displayName}
                      className={classes.chip}
                      color="default"
                      variant="outlined"
                    />
                  )}
                  {!user.activated && (
                    <Tooltip
                      title={
                        <Typography variant="subtitle1" gutterBottom>
                          รหัสผู้ใช้งานนี้มีสถานะไม่ได้ใช้งาน
                        </Typography>
                      }
                    >
                      <Chip
                        label={displayName}
                        className={classes.chip}
                        color="secondary"
                        variant="outlined"
                      />
                    </Tooltip>
                  )}
                  {/* </Badge> */}
                </div>
              );
            })}
          {!users && (
            <div>
              <Chip
                label={'Please Reassign Responsibility'}
                className={classes.chip}
                color="secondary"
                variant="outlined"
              />
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <Fade in={true}>
      <div>
        <Container>
          <Formik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={OpportunitySchema}
            onSubmit={handleSubmit}
            validateOnChange={true}
          >
            {({ values, errors, touched, setFieldValue, setFieldTouched }) => {
              const isError = (fieldName: keyof IOpportunityFormValue) =>
                !!touched[fieldName] && !!errors[fieldName];
              const errorMessage = (fieldName: keyof IOpportunityFormValue) =>
                !!touched[fieldName] ? errors[fieldName] : '';
              return (
                <Form>
                  <PageContainer>
                    <Loading loading={isLoading} height={500}>
                      <Grid container justifyContent="flex-start" alignItems="center">
                        <Grid item xs={1}>
                          <GoBackButton />
                        </Grid>
                        <Grid item xs={11}>
                          <PageTitle>
                            {opportunityId === undefined
                              ? `New Assignment`
                              : `Update Assignment ${opportunity.assignmentNumber}`}
                          </PageTitle>
                        </Grid>
                      </Grid>

                      <OpportunityFormActionMenu opportunity={opportunity} />
                      <CardContent>
                        <CompanyComboBox
                          handleChange={(result) => {
                            if (result === null) {
                              setFieldValue('smProject', null);
                              setFieldValue('program', null);
                              setFieldValue('brand', null);
                            }
                            setFieldValue('company', result ? result : null);
                            setFieldTouched('company', true, false);
                          }}
                          selectedValue={values.company as Company}
                          isDisabled={
                            hasOpportunityCustomers || hasOpportunityCustomerGroups
                          }
                          error={isError('company')}
                          helperText={errorMessage('company')}
                        />
                        <ProjectComboBox
                          forceFilter={{}}
                          handleChange={(result) => {
                            setFieldValue('program', null);
                            setFieldValue('brand', null);

                            setFieldValue('projectId', result ? result.id : null);
                            setFieldValue('smProject', result);

                            setFieldTouched('smProject', true, false);
                            setFieldTouched('projectId', true, false);
                          }}
                          selectedValue={values.smProject as IProject}
                          error={isError('smProject')}
                          helperText={errorMessage('smProject')}
                          isDisabled={hasOpportunityItems}
                        />
                        {/* <TextField
                          margin={'dense'}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label={'Project Owner'}
                          value={
                            values.smProject ? values.smProject?.owner?.fullName : ''
                          }
                        /> */}
                        {/* {hasOpportunityItems ? (
                          <Typography color="primary">
                            <span style={{ fontWeight: 'bold' }}>NOTE:</span> Project is
                            not changeable because assignment already has block item(s).
                          </Typography>
                        ) : null} */}
                        <ProjectProgramComboBoxSearch
                          handleChange={(selectedProjectProgram) => {
                            setFieldValue(
                              'program',
                              selectedProjectProgram
                                ? selectedProjectProgram.program
                                  ? selectedProjectProgram.program
                                  : selectedProjectProgram
                                : null,
                            );
                            setFieldValue('brand', null);

                            setFieldValue(
                              'projectProgramId',
                              selectedProjectProgram
                                ? selectedProjectProgram.program
                                  ? selectedProjectProgram.id
                                  : selectedProjectProgram.smProjectProgramId
                                : 0,
                            );
                          }}
                          smProjectId={values.projectId}
                          selectedValue={values.program as Program}
                          error={isError('program')}
                          helperText={errorMessage('program')}
                          isDisabled={hasOpportunityItems || !values.smProject}
                        />
                        <ProjectProgramBrandComboBoxSearch
                          handleChange={(result) => {
                            setFieldValue('brand', result ? result : null);
                          }}
                          smProjectId={values.smProject ? values.smProject.id : null}
                          selectedValue={values.brand as Brand}
                          error={isError('brand')}
                          helperText={errorMessage('brand')}
                          smProjectProgramId={values.projectProgramId}
                          isDisabled={hasOpportunityItems || !values.program}
                        />

                        {renderUserChipList(salesUsers, 'Sales')}

                        {renderUserChipList(ssoUsers, 'SSO')}

                        {renderUserChipList(csUsers, 'CS')}

                        {isUpdateMode && (
                          <TextField
                            margin={'dense'}
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                            label={'Status'}
                            value={
                              opportunity.status
                                ? OpportunityStatusLabel[opportunity.status]
                                : ''
                            }
                            variant="filled"
                          />
                        )}
                      </CardContent>
                    </Loading>
                  </PageContainer>
                </Form>
              );
            }}
          </Formik>
        </Container>
        {opportunity.id !== undefined && (
          <Container>
            <OpportunitySubForm opportunity={opportunity} />
          </Container>
        )}
      </div>
    </Fade>
  );
};

export default OpportunityForm;
