import { REQUEST, SUCCESS, FAILURE } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CREATE_TASK_IN_TASK_CREATE,
  CLEAR_TASK_CREATE_DIALOG,
  CREATE_SUBTASKS_IN_TASK_CREATE,
  ADD_SELECTED_SUBTASK_TYPE,
  CREATE_TASK_SUBTASK_SUBTASK_ITEM_AND_ETC,
  ADD_SELECTED_TASK_ITEM_IN_TASK_CREATE,
  ADD_SELECTED_TASK_ITEMS_WITH_MOQS_IN_TASK_CREATE,
  EDIT_SELECTED_TASK_ITEM_WITH_MOQS_IN_TASK_CREATE,
  ADD_SELECTED_TASK_ITEMS_WITH_FORECASTS_IN_TASK_CREATE,
  EDIT_SELECTED_TASK_ITEM_WITH_FORECASTS_IN_TASK_CREATE,
  EDIT_MULTIPLE_SELECTED_TASK_ITEM_WITH_MOQS_IN_TASK_CREATE,
  EDIT_MULTIPLE_SELECTED_TASK_ITEM_WITH_FORECASTS_IN_TASK_CREATE,
  ADD_SELECTED_UNIT_OF_QUANTITY,
} from './taskCreate.actions';
import { TaskCreateState } from './taskCreate.types';
import { ITask } from '../../models/task.model';
import { ISubtask } from '../../models/subtask.model';
import {
  ITaskItem,
  ITaskItemWithMoqs,
  ITaskItemWithForecasts,
} from '../../models/taskItem.model';

const initialState: TaskCreateState = {
  loading: false,
  createTaskPayload: {} as ITask,
  createSubtasksPayload: [] as ISubtask[],
  selectedSubtaskType: [] as string[],
  selectedUnitOfQuantity: '' as string,
  selectedTaskItems: [] as ITaskItem[],
  selectedTaskItemsWithMoqs: [] as ITaskItemWithMoqs[],
  selectedTaskItemsWithForecasts: [] as ITaskItemWithForecasts[],
};

export default (
  state: TaskCreateState = initialState,
  action: AppAction,
): TaskCreateState => {
  switch (action.type) {
    case REQUEST(CREATE_TASK_SUBTASK_SUBTASK_ITEM_AND_ETC):
      return { ...state, loading: true };
    case SUCCESS(CREATE_TASK_SUBTASK_SUBTASK_ITEM_AND_ETC):
      return { ...state, loading: false };
    case FAILURE(CREATE_TASK_SUBTASK_SUBTASK_ITEM_AND_ETC):
      return { ...state, loading: false };
    case CREATE_TASK_IN_TASK_CREATE:
      return {
        ...state,
        createTaskPayload: action.payload,
      };
    case CREATE_SUBTASKS_IN_TASK_CREATE:
      return {
        ...state,
        createSubtasksPayload: action.payload,
      };
    case ADD_SELECTED_SUBTASK_TYPE:
      return {
        ...state,
        selectedSubtaskType: action.payload,
      };
    case ADD_SELECTED_TASK_ITEM_IN_TASK_CREATE:
      return {
        ...state,
        selectedTaskItems: action.payload,
      };
    case ADD_SELECTED_TASK_ITEMS_WITH_MOQS_IN_TASK_CREATE:
      return {
        ...state,
        selectedTaskItemsWithMoqs: action.payload,
      };
    case ADD_SELECTED_TASK_ITEMS_WITH_FORECASTS_IN_TASK_CREATE:
      return {
        ...state,
        selectedTaskItemsWithForecasts: action.payload,
      };
    case EDIT_SELECTED_TASK_ITEM_WITH_MOQS_IN_TASK_CREATE:
      return {
        ...state,
        selectedTaskItemsWithMoqs: state.selectedTaskItemsWithMoqs.map(
          taskItemWithMoqs => {
            if (
              taskItemWithMoqs.opportunityItemId! === action.payload!.opportunityItemId
            ) {
              return action.payload;
            } else {
              return taskItemWithMoqs;
            }
          },
        ),
      };
    case EDIT_MULTIPLE_SELECTED_TASK_ITEM_WITH_MOQS_IN_TASK_CREATE:
      return {
        ...state,
        selectedTaskItemsWithMoqs: state.selectedTaskItemsWithMoqs.map(
          taskItemWithMoqs => {
            const selectedTaskItemWithMoqsInPayload = action.payload!.find(
              (selectedTaskItemWithMoqs: any) =>
                selectedTaskItemWithMoqs.opportunityItemId ===
                taskItemWithMoqs.opportunityItemId!,
            );
            if (selectedTaskItemWithMoqsInPayload) {
              return selectedTaskItemWithMoqsInPayload;
            } else {
              return taskItemWithMoqs;
            }
          },
        ),
      };
    case EDIT_SELECTED_TASK_ITEM_WITH_FORECASTS_IN_TASK_CREATE:
      return {
        ...state,
        selectedTaskItemsWithForecasts: state.selectedTaskItemsWithForecasts.map(
          taskItemWithForecasts => {
            if (
              taskItemWithForecasts.opportunityItemId! ===
              action.payload!.opportunityItemId
            ) {
              return action.payload;
            } else {
              return taskItemWithForecasts;
            }
          },
        ),
      };
    case EDIT_MULTIPLE_SELECTED_TASK_ITEM_WITH_FORECASTS_IN_TASK_CREATE:
      return {
        ...state,
        selectedTaskItemsWithForecasts: state.selectedTaskItemsWithForecasts.map(
          taskItemWithForecasts => {
            const selectedTaskItemWithForecastsInPayload = action.payload!.find(
              (selectedTaskItemWithForecasts: any) =>
                selectedTaskItemWithForecasts.opportunityItemId ===
                taskItemWithForecasts.opportunityItemId!,
            );
            if (selectedTaskItemWithForecastsInPayload) {
              return selectedTaskItemWithForecastsInPayload;
            } else {
              return taskItemWithForecasts;
            }
          },
        ),
      };
    case ADD_SELECTED_UNIT_OF_QUANTITY:
      return {
        ...state,
        selectedUnitOfQuantity: action.payload,
      };
    case CLEAR_TASK_CREATE_DIALOG:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
