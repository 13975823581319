import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_WORKING_TEAM,
  CREATE_WORKING_TEAM,
  FETCH_WORKING_TEAM,
  FETCH_WORKING_TEAM_LIST,
  UPDATE_WORKING_TEAM,
} from './workingTeam.actions';
import { WorkingTeam, WorkingTeamState } from './workingTeam.type';

const initialState: WorkingTeamState = {
  errorMessage: '',
  item: {} as Readonly<WorkingTeam>,
  items: [] as ReadonlyArray<WorkingTeam>,
  loading: false,
  totalItems: 0,
};

export default (
  state: WorkingTeamState = initialState,
  action: AppAction,
): WorkingTeamState => {
  switch (action.type) {
    case REQUEST(FETCH_WORKING_TEAM_LIST):
    case REQUEST(CREATE_WORKING_TEAM):
    case REQUEST(FETCH_WORKING_TEAM):
    case REQUEST(UPDATE_WORKING_TEAM):
      return { ...state, loading: true };
    case SUCCESS(FETCH_WORKING_TEAM_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_WORKING_TEAM_LIST):
    case FAILURE(CREATE_WORKING_TEAM):
    case FAILURE(FETCH_WORKING_TEAM):
    case FAILURE(UPDATE_WORKING_TEAM):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_WORKING_TEAM):
    case SUCCESS(FETCH_WORKING_TEAM):
    case SUCCESS(UPDATE_WORKING_TEAM):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_WORKING_TEAM:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
