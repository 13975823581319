import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import { SYNC_USER_FROM_LDAP, CLEAR_ADMIN } from './admin.actions';
import { AdminState } from './admin.types';

const initialState: AdminState = {
  errorMessage: '',
  loading: false,
};

export default (state: AdminState = initialState, action: AppAction): AdminState => {
  switch (action.type) {
    case REQUEST(SYNC_USER_FROM_LDAP):
      return { ...state, loading: true };
    case SUCCESS(SYNC_USER_FROM_LDAP):
      return {
        ...state,
        loading: false,
      };
    case FAILURE(SYNC_USER_FROM_LDAP):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case CLEAR_ADMIN:
      return { ...state, ...initialState };
    default:
      return state;
  }
};
