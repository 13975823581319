import { AppAction, PageFilter, AppCallback } from '../app';
import {
  ProjectCustomerGroup,
  ProjectCustomerGroupCreateOrUpdatePayload,
  ProjectCustomerGroupListFilter,
} from './projectCustomerGroup.types';

export const FETCH_PROJECT_CUSTOMER_GROUP = 'FETCH_PROJECT_CUSTOMER_GROUP';
export const FETCH_PROJECT_CUSTOMER_GROUP_LIST = 'FETCH_PROJECT_CUSTOMER_GROUP_LIST';
export const CREATE_PROJECT_CUSTOMER_GROUP = 'CREATE_PROJECT_CUSTOMER_GROUP';
export const UPDATE_PROJECT_CUSTOMER_GROUP = 'UPDATE_PROJECT_CUSTOMER_GROUP';
export const CLEAR_PROJECT_CUSTOMER_GROUP = 'CLEAR_PROJECT_CUSTOMER_GROUP';
export const DELETE_PROJECT_CUSTOMER_GROUP = 'DELETE_PROJECT_CUSTOMER_GROUP';
export const CREATE_PROJECT_CUSTOMER_GROUPS = 'CREATE_PROJECT_CUSTOMER_GROUPS';

export const fetchProjectCustomerGroup = (id: ProjectCustomerGroup['id']): AppAction => ({
  type: FETCH_PROJECT_CUSTOMER_GROUP,
  payload: id,
});

export const fetchProjectCustomerGroupList = (
  filters: ProjectCustomerGroupListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_PROJECT_CUSTOMER_GROUP_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchProjectCustomerGroupListBySmProjectId = (
  smProjectId: number,
): AppAction => ({
  type: FETCH_PROJECT_CUSTOMER_GROUP_LIST,
  payload: {
    filters: { 'smProjectId.equalsTo': smProjectId },
    paging: { pageNumber: 0, pageSize: 99999 },
  },
});

export const createProjectCustomerGroup = (
  payload: ProjectCustomerGroupCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_PROJECT_CUSTOMER_GROUP,
  payload,
});

export const createProjectCustomerGroups = (
  payload: any,
  callback?: AppCallback,
): AppAction => ({
  type: CREATE_PROJECT_CUSTOMER_GROUPS,
  payload,
  callback,
});

export const updateProjectCustomerGroup = (
  payload: ProjectCustomerGroupCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_PROJECT_CUSTOMER_GROUP,
  payload,
});

export const clearProjectCustomerGroup = () => ({
  type: CLEAR_PROJECT_CUSTOMER_GROUP,
});

export const deleteProjectCustomerGroup = (payload: { id: number }) => ({
  type: DELETE_PROJECT_CUSTOMER_GROUP,
  payload,
});
