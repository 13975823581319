import AddMultipleBlockDialog from '../AddMultipleBlockDialog/AddMutilpleBlockDialog';
import React, { useState } from 'react';
import { AppState } from '../../../../state/configureStore';
import { Block, fetchBlock } from '../../../../state/block';
import { BlockCreateDialog, SortLabel, TableLoading } from '../../../components';
import { BlockEditDialog } from '../../../components/BlockEditDialog';
import { Brand } from '../../../../state/brand';
import { EBlockCreateDialog } from '../../../components/EBlockCreateDialog';
import { EditOpportunityItemDialog } from '../EditOpportunityItemDialog';
import { IOpportunityItem } from '../../../../models/opportunityItem.model';
import {
  moveItemToNewOpportunity,
  MoveOpportunityPayload,
  OpportunityState,
} from '../../../../state/opportunity';
import { Program } from '../../../../state/program';
import { SearchItemAndAddMultipleBlockDialog } from '../SearchItemAndAddMultipleBlockDialog';
import { User } from '../../../../state/user';
import { useTableSorting } from '../../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  Badge,
  Button,
  CardContent,
  Checkbox,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  HowToReg as HowToRegIcon,
  Transform as TransformIcon,
  LibraryAdd as LibraryAddIcon,
  Search as SearchIcon,
  Code as CodeIcon,
} from '@material-ui/icons';

import {
  createOpportunityItem,
  deleteOpportunityItem,
  fetchOpportunityItem,
  fetchOpportunityItemListByOpportunityId,
  OpportunityItemState,
} from '../../../../state/opportunityItem';
import { AuthState } from '../../../../state/auth';
import OpportunityItemSectionSplitItemDialog from './OpportunityItemSectionSplitItemDialog';

interface OpportunityItemProps {
  opportunityId: number;
  program: Program;
  brand: Brand;
}

const OpportunityItemSection: React.FC<OpportunityItemProps> = (props) => {
  const dispatch = useDispatch();
  const { opportunityId, program, brand } = props;
  const { isUseNpd } = program;
  const [sort, setSort] = useTableSorting({ orderBy: 'id', direction: 'asc' }, true);
  const [selectAll, setSelectAll] = useState(false);
  const [openSplitItemDlg, SetOpenSplitItemDlg] = useState(false);

  const [anchorTopActionButtonEl, setAnchorTopActionButtonEl] =
    useState<null | HTMLElement>(null);
  const [anchorSearchBlockActionButtonEl, setAnchorSearchBlockActionButtonEl] =
    useState<null | HTMLElement>(null);

  const [openBlockCreateDialog, setOpenBlockCreateDialog] = useState(false);
  const [openEBlockCreateDialog, setOpenEBlockCreateDialog] = useState(false);

  const [openAddMultipleBlockDialog, setOpenAddMultipleBlockDialog] = useState(false);
  const [openBlockEditDialog, setOpenBlockEditDialog] = useState(false);
  const [
    openSearchItemAndAddMultipleBlockDialog,
    setOpenSearchItemAndAddMultipleBlockDialog,
  ] = useState(false);
  const [selectedOpportunityItems, setSelectedOpportunityItems] = useState<
    ReadonlyArray<IOpportunityItem>
  >([]);
  const [openEditOpportunityItemDialog, setOpenEditOpportunityItemDialog] =
    useState(false);

  const { items: opportunityItems, loading: isLoading } = useSelector<
    AppState,
    OpportunityItemState
  >((state) => state.opportunityItem);

  const { item: opportunity } = useSelector<AppState, OpportunityState>(
    (state) => state.opportunity,
  );
  const { user } = useSelector<AppState, AuthState>((state) => state.auth);
  const isAdmin = user!.roles!.find((f) => f === 'ADMIN') !== undefined;

  const handleDeleteItem = (opportunityItem: IOpportunityItem) => {
    const { id, opportunityId } = opportunityItem;
    dispatch(deleteOpportunityItem({ id, opportunityId }));
  };

  const onClickTopActionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorTopActionButtonEl(event.currentTarget);
  };
  const onTopActionMenuClose = () => setAnchorTopActionButtonEl(null);

  const onClickSearchBlockActionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorSearchBlockActionButtonEl(event.currentTarget);
  };
  const onSearchBlockActionMenuClose = () => setAnchorSearchBlockActionButtonEl(null);

  return (
    <>
      <CardContent>
        <Button
          variant="contained"
          color={'primary'}
          startIcon={<SearchIcon />}
          onClick={onClickSearchBlockActionMenu}
        >
          Search Blocks
        </Button>
        <Menu
          anchorEl={anchorSearchBlockActionButtonEl}
          open={Boolean(anchorSearchBlockActionButtonEl)}
          onClose={onSearchBlockActionMenuClose}
        >
          <MenuItem
            onClick={() => {
              setOpenAddMultipleBlockDialog(true);
              onSearchBlockActionMenuClose();
            }}
          >
            <ListItemText primary={'Search by Block No'} />
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenSearchItemAndAddMultipleBlockDialog(true);
              onSearchBlockActionMenuClose();
            }}
          >
            <ListItemText primary={'Search by Item'} />
          </MenuItem>
        </Menu>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (opportunity.company?.externalId === 'NEX') {
              setOpenEBlockCreateDialog(true);
            } else {
              setOpenBlockCreateDialog(true);
            }
          }}
          startIcon={<AddIcon />}
        >
          Create New Block
        </Button>
        {/* {isAdmin && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<LibraryAddIcon />}
            disabled={selectedOpportunityItems.length != 1}
            onClick={() => {
              SetOpenSplitItemDlg(true);
            }}
          >
            Split New Block
          </Button>
        )} */}

        <Button
          variant="contained"
          startIcon={<CodeIcon />}
          onClick={onClickTopActionMenu}
        >
          Actions
        </Button>
        <Menu
          anchorEl={anchorTopActionButtonEl}
          open={Boolean(anchorTopActionButtonEl)}
          onClose={onTopActionMenuClose}
        >
          <MenuItem
            onClick={() => {
              selectedOpportunityItems.forEach((oi) => {
                handleDeleteItem(oi);
              });
              onTopActionMenuClose();
            }}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText
              primary={'Delete Items'}
              secondary={`(${selectedOpportunityItems.length}) items will be delete`}
            />
          </MenuItem>
          {isAdmin && (
            <MenuItem
              onClick={() => {
                const moveItemObj: MoveOpportunityPayload = {
                  opportunityId: opportunity.id,
                  opportunityItemIds: selectedOpportunityItems.map((s) => s.id),
                };

                dispatch(moveItemToNewOpportunity(moveItemObj));

                onTopActionMenuClose();
              }}
            >
              <ListItemIcon>
                <TransformIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Move Items'}
                secondary={`(${selectedOpportunityItems.length}) items will be move to new assignment`}
              />
            </MenuItem>
          )}
        </Menu>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  onChange={(event: React.ChangeEvent<HTMLInputElement>, checked) => {
                    setSelectAll(checked);
                    if (checked) {
                      setSelectedOpportunityItems([...opportunityItems]);
                    } else {
                      setSelectedOpportunityItems([]);
                    }
                  }}
                  checked={selectAll}
                />
              </TableCell>
              <TableCell>No.</TableCell>
              <TableCell>Product Type</TableCell>
              <TableCell>
                <SortLabel
                  sort={sort}
                  handleSortChange={setSort}
                  field="OpportunityItem.Block.BlockNo"
                >
                  Block
                </SortLabel>
              </TableCell>
              <TableCell>Item</TableCell>
              <TableCell>Hanes Project</TableCell>
              <TableCell>SampleDev/NPD</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={isLoading} colSpan={7}>
              {opportunityItems.map((opportunityItem, index) => {
                const { id, block } = opportunityItem;
                const isCheckboxChecked =
                  selectedOpportunityItems.findIndex((item) => item.id === id) !== -1;

                return (
                  <TableRow key={id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked,
                        ) => {
                          if (checked) {
                            setSelectedOpportunityItems(
                              selectedOpportunityItems.concat([opportunityItem]),
                            );
                          } else {
                            setSelectAll(false);
                            setSelectedOpportunityItems(
                              selectedOpportunityItems.filter(
                                (item) => item.id !== opportunityItem.id,
                              ),
                            );
                          }
                        }}
                        checked={isCheckboxChecked}
                      />
                    </TableCell>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{block?.productType?.name}</TableCell>
                    <TableCell>{block?.blockNo}</TableCell>
                    <TableCell>{block?.item}</TableCell>
                    <TableCell>{block?.hanesProjectName}</TableCell>
                    <TableCell>
                      {/* {isUseNpd
                        ? opportunityItem.npdUser?.fullNameWithUserName
                        : opportunityItem.sampleDevUser?.fullNameWithUserName} */}
                      {isUseNpd && opportunityItem.npdUser && (
                        <Badge
                          badgeContent={7 - opportunityItem.npdUser?.userLeaveTotalDay!}
                          color="secondary"
                          invisible={opportunityItem.npdUser?.activated}
                        >
                          <Chip
                            variant="outlined"
                            color={
                              opportunityItem.npdUser?.activated ? 'default' : 'secondary'
                            }
                            label={
                              opportunityItem.npdUser?.activated
                                ? opportunityItem.npdUser?.fullNameWithUserName
                                : `${opportunityItem.npdUser?.fullNameWithUserName} (Inactive)`
                            }
                          />
                        </Badge>
                      )}
                      {!isUseNpd && opportunityItem.sampleDevUser && (
                        // <Badge
                        //   badgeContent={
                        //     7 - opportunityItem.sampleDevUser?.userLeaveTotalDay!
                        //   }
                        //   color="secondary"
                        //   invisible={opportunityItem.sampleDevUser?.activated}
                        // >
                        <Chip
                          variant="outlined"
                          color={
                            opportunityItem.sampleDevUser?.activated
                              ? 'default'
                              : 'secondary'
                          }
                          label={
                            opportunityItem.sampleDevUser?.activated
                              ? opportunityItem.sampleDevUser?.fullNameWithUserName
                              : `${opportunityItem.sampleDevUser?.fullNameWithUserName} (Inactive)`
                          }
                        />
                        // </Badge>
                      )}
                    </TableCell>

                    <TableCell>
                      {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          dispatch(fetchOpportunityItem(opportunityItem.id));
                          setOpenEditOpportunityItemDialog(true);
                        }}
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button> */}

                      <Button
                        variant={'contained'}
                        color={'primary'}
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={() => {
                          dispatch(fetchBlock(opportunityItem?.blockId));
                          setOpenBlockEditDialog(true);
                        }}
                      >
                        Edit
                      </Button>

                      <Button
                        variant="contained"
                        color="default"
                        size="small"
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                          const { id, opportunityId } = opportunityItem;
                          dispatch(deleteOpportunityItem({ id, opportunityId }));
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>
      </CardContent>

      {/* <OpportunityItemSectionSplitItemDialog
        open={openSplitItemDlg}
        onClose={() => {
          SetOpenSplitItemDlg(false);
        }}
        oppItem={
          selectedOpportunityItems.length > 0 ? selectedOpportunityItems[0] : undefined
        }
      /> */}

      <EBlockCreateDialog
        open={openEBlockCreateDialog}
        handleClose={() => {
          setOpenEBlockCreateDialog(false);
        }}
        program={program}
        brand={brand}
        handleCreatedBlock={(user: User, createdBlocks: Block[]) => {
          if (createdBlocks.length !== 0) {
            let sequence = opportunityItems.length;
            createdBlocks.forEach((createdBlock) => {
              sequence++;
              const newOpportunityItem = {
                sequence,
                opportunityId,
                blockId: createdBlock.id,
                npdUserId: isUseNpd ? user.id : undefined,
                sampleDevUserId: isUseNpd ? undefined : user.id,
              };
              dispatch(createOpportunityItem(newOpportunityItem));
            });
          }
        }}
      />
      <BlockCreateDialog
        open={openBlockCreateDialog}
        handleClose={() => {
          setOpenBlockCreateDialog(false);
        }}
        program={program}
        brand={brand}
        companyMakefor={opportunity.company?.externalId!}
        handleCreatedBlock={(/*user: User,*/ createdBlocks: Array<Block>) => {
          if (createdBlocks.length !== 0) {
            let sequence = opportunityItems.length;
            createdBlocks.forEach((createdBlock) => {
              sequence++;
              const newOpportunityItem = {
                sequence,
                opportunityId,
                blockId: createdBlock.id,
                //npdUserId: isUseNpd ? user.id : undefined,
                //sampleDevUserId: isUseNpd ? undefined : user.id,
              };
              dispatch(createOpportunityItem(newOpportunityItem));
            });
          }
        }}
      />
      <AddMultipleBlockDialog
        handleClose={() => {
          dispatch(fetchOpportunityItemListByOpportunityId(opportunityId));
          setOpenAddMultipleBlockDialog(false);
        }}
        open={openAddMultipleBlockDialog}
        program={program}
        brand={brand}
      />
      <SearchItemAndAddMultipleBlockDialog
        onClose={() => {
          setOpenSearchItemAndAddMultipleBlockDialog(false);
        }}
        open={openSearchItemAndAddMultipleBlockDialog}
        program={program}
        brand={brand}
      />
      <EditOpportunityItemDialog
        onClose={() => {
          dispatch(fetchOpportunityItemListByOpportunityId(opportunityId));
          setOpenEditOpportunityItemDialog(false);
        }}
        open={openEditOpportunityItemDialog}
      />
      <BlockEditDialog
        open={openBlockEditDialog}
        onClose={() => {
          dispatch(fetchOpportunityItemListByOpportunityId(opportunityId));
          setOpenBlockEditDialog(false);
        }}
      />
    </>
  );
};

export default OpportunityItemSection;
