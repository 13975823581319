import { Tooltip } from '@material-ui/core';
import { Check, HighlightOff, HourglassEmpty, Timer, Update } from '@material-ui/icons';
import React from 'react';

import { ResponsibilityStatusLabel } from '../../../constants/responsibilityStatus';

const ResponsibilityStatusIcon = {
  [ResponsibilityStatusLabel.PENDING_FOR_APPROVAL]: (
    <Tooltip title={ResponsibilityStatusLabel.PENDING_FOR_APPROVAL}>
      <HourglassEmpty />
    </Tooltip>
  ),
  [ResponsibilityStatusLabel.IN_REQUEST]: (
    <Tooltip title={ResponsibilityStatusLabel.IN_REQUEST}>
      <Timer />
    </Tooltip>
  ),
  [ResponsibilityStatusLabel.REJECTED]: (
    <Tooltip title={ResponsibilityStatusLabel.REJECTED}>
      <HighlightOff />
    </Tooltip>
  ),
  [ResponsibilityStatusLabel.APPROVED]: (
    <Tooltip title={ResponsibilityStatusLabel.APPROVED}>
      <Check />
    </Tooltip>
  ),
  [ResponsibilityStatusLabel.PENDING_FOR_UNLOCK_APPROVED]: (
    <Tooltip title={ResponsibilityStatusLabel.PENDING_FOR_UNLOCK_APPROVED}>
      <Update />
    </Tooltip>
  ),
};

export default ResponsibilityStatusIcon;
