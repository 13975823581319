import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { SubtaskCloseReason, SubtaskStatus } from '../../../../../constants';
import { SubtaskCloseReasonLabel } from '../../../../../constants/subtaskCloseReason';
import { ISubtaskItem } from '../../../../../models/subtaskItem.model';
import { AppState } from '../../../../../state/configureStore';
import { SubtaskState } from '../../../../../state/subtask';
import { updateSubtaskItemsStatus } from '../../../../../state/subtaskItem';
import { Loading } from '../../../../components';

interface ISubtaskItemCloseDialog {
  open: boolean;
  onClose: () => void;
  subtaskItems: ReadonlyArray<ISubtaskItem>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginBottom: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      display: 'flex',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    closeReason: {
      marginLeft: theme.spacing(-1),
    },
    completedDateInput: {
      marginLeft: theme.spacing(0),
    },
  }),
);

const SubtaskItemCloseSchema = Yup.object().shape({
  closeReason: Yup.string()
    .required('Close reason is required')
    .nullable(true),
  closeOther: Yup.string().when('closeReason', {
    is: SubtaskCloseReason.OTHER,
    then: Yup.string()
      .required('Other is required')
      .nullable(true),
    otherwise: Yup.string().nullable(true),
  }),
  completedDate: Yup.string()
    .required('Close date is required')
    .nullable(true),
});

const SubtaskItemCloseDialog: React.FC<ISubtaskItemCloseDialog> = props => {
  const { open, onClose, subtaskItems } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { item: subtask, loading } = useSelector<AppState, SubtaskState>(
    state => state.subtask,
  );
  return (
    <Formik
      initialValues={{ ...subtask }}
      validationSchema={SubtaskItemCloseSchema}
      onSubmit={(values, actions) => {
        const callback = (error?: Error) => {
          if (!error) {
            actions.resetForm();
            onClose();
          }
        };
        values.status = SubtaskStatus.CLOSE;
        const payload = subtaskItems.map(subtaskItem => {
          return {
            ...values,
            id: subtaskItem.id,
            smSubtaskId: subtask.id,
            smTaskItemId: subtaskItem.smTaskItem!.id,
            smTaskItem: subtaskItem.smTaskItem,
          };
        });
        dispatch(updateSubtaskItemsStatus(payload, callback));
      }}
      enableReinitialize={true}
    >
      {({ values, handleChange, errors, setFieldValue }) => {
        return (
          <Dialog open={open} fullWidth>
            <Loading isLoading={loading}>
              <Form>
                <DialogTitle>Close Subtask Item</DialogTitle>
                <DialogContent>
                  <TextField
                    className={classes.textField}
                    name="subject"
                    label="Subject"
                    margin="dense"
                    fullWidth
                    value={values.subject || ''}
                  />
                  <TextField
                    className={classes.textField}
                    name="Block"
                    label="Block"
                    margin="dense"
                    fullWidth
                    value={subtaskItems
                      .map(
                        subtaskItem =>
                          subtaskItem.smTaskItem!.opportunityItem!.block!.blockNo,
                      )
                      .join(',')}
                  />
                  <KeyboardDatePicker
                    fullWidth
                    className={classes.completedDateInput}
                    label="Close Date"
                    format="yyyy-MM-dd"
                    value={values.completedDate!}
                    helperText={errors.completedDate}
                    error={!!errors.completedDate}
                    onChange={(selectedDate, selectedStringDate) => {
                      setFieldValue('completedDate', selectedStringDate);
                    }}
                    showTodayButton={true}
                  />
                  <FormControl className={classes.formControl}>
                    <InputLabel>Close Reason</InputLabel>
                    <Select
                      className={classes.closeReason}
                      value={values.closeReason || ''}
                      onChange={handleChange}
                      inputProps={{ name: 'closeReason' }}
                    >
                      {Object.entries(SubtaskCloseReason).map(([key, value]) => {
                        return (
                          <MenuItem key={key} value={value}>
                            {SubtaskCloseReasonLabel[value]}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <TextField
                    className={classes.textField}
                    name="closeOther"
                    label="Other"
                    margin="dense"
                    fullWidth
                    value={values.closeOther || ''}
                    onChange={handleChange}
                    error={!!errors.closeOther}
                    helperText={errors.closeOther}
                  >
                    Other
                  </TextField>
                </DialogContent>
                <DialogActions>
                  <Button color="primary" onClick={onClose}>
                    Close
                  </Button>
                  <Button type="submit" color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Form>
            </Loading>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default SubtaskItemCloseDialog;
