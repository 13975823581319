import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { IResponsibilityCustomer } from '../../../../models/responsibilityCustomer.model';
import { Customer } from '../../../../state/customer';
import {
  replaceResponsibilityCustomer,
  ResponsibilityCustomerReplacePayLoad,
} from '../../../../state/responsibilityCustomer';
import { CustomerComboBox } from '../../../components';

interface ResponsibilityCustomerReplaceDialogProps {
  responsibilityCustomer: IResponsibilityCustomer | null;
  open: boolean;
  onClose: () => void;
}

interface ResponsibilityCustomerReplaceValues {
  customer?: Customer | null;
  customerId?: number | null;
}

const ResponsibilitySchema = Yup.object().shape({
  customerId: Yup.number()
    .nullable(true)
    .required('Customer is required'),
});

type dialogStep = 'replaceResponsibilityCustomer' | 'confirmResponsibilityCustomer';

const ResponsibilityCustomerReplaceDialog: React.FC<
  ResponsibilityCustomerReplaceDialogProps
> = props => {
  const dispatch = useDispatch();
  const { open, onClose, responsibilityCustomer } = props;

  const [
    replaceResponsibilityCustomerDlgStep,
    setReplaceResponsibilityCustomerDlgStep,
  ] = useState<dialogStep>('replaceResponsibilityCustomer');
  const [
    responsibilityReplaceCustomerPayLoad,
    setResponsibilityReplaceCustomerPayLoad,
  ] = useState<ResponsibilityCustomerReplacePayLoad>();

  const initialFormValues: ResponsibilityCustomerReplaceValues = useMemo(() => {
    const { customer, customerId } = responsibilityCustomer!;
    return { customer, customerId };
  }, [responsibilityCustomer]);

  const handleSubmit = (
    values: ResponsibilityCustomerReplaceValues,
    actions: FormikHelpers<ResponsibilityCustomerReplaceValues>,
  ) => {
    setResponsibilityReplaceCustomerPayLoad({
      responsibilityCustomerId: responsibilityCustomer!.id,

      newCustomer: values.customer!,
      newCustomerId: values.customerId!,
    });

    setReplaceResponsibilityCustomerDlgStep('confirmResponsibilityCustomer');
    actions.setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'} fullWidth>
      <Formik
        enableReinitialize={true}
        initialValues={initialFormValues}
        validationSchema={ResponsibilitySchema}
        onSubmit={handleSubmit}
        validateOnChange={true}
      >
        {({ values, submitForm, errors, touched, setFieldValue, setFieldTouched }) => {
          const isError = (fieldName: keyof ResponsibilityCustomerReplaceValues) =>
            !!touched[fieldName] && !!errors[fieldName];
          const errorMessage = (fieldName: keyof ResponsibilityCustomerReplaceValues) =>
            !!touched[fieldName] ? (errors[fieldName] as string) : '';
          return (
            <Form>
              {replaceResponsibilityCustomerDlgStep ===
                'replaceResponsibilityCustomer' && (
                <>
                  <DialogTitle>Replace Responsibility Customer</DialogTitle>
                  <CardContent>
                    <CustomerComboBox
                      handleChange={result => {
                        setFieldValue('customer', result ? result : null);
                        setFieldTouched('customer', true, false);
                        setFieldValue('customerId', result ? result.id : null);
                        setFieldTouched('customerId', true, false);
                      }}
                      selectedValue={values.customer as Customer}
                      error={isError('customerId')}
                      helperText={errorMessage('customerId')}
                    />
                  </CardContent>
                  <DialogActions>
                    <Button
                      variant={'contained'}
                      color="secondary"
                      onClick={() => {
                        onClose();
                      }}
                    >
                      CANCEL
                    </Button>
                    <Button
                      variant={'contained'}
                      color="primary"
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      NEXT
                    </Button>
                  </DialogActions>
                </>
              )}
              {replaceResponsibilityCustomerDlgStep ===
                'confirmResponsibilityCustomer' && (
                <>
                  <DialogTitle>Confirm Replace Responsibility Customer</DialogTitle>
                  <CardContent>
                    <TextField
                      name="customerName"
                      type="input"
                      label="Customer"
                      variant="outlined"
                      margin="dense"
                      value={responsibilityCustomer!.customer!.name}
                      fullWidth
                    />
                    <TextField
                      name="changeToCustomer"
                      type="input"
                      label="Change To Customer"
                      variant="outlined"
                      margin="dense"
                      value={responsibilityReplaceCustomerPayLoad!.newCustomer!.name}
                      fullWidth
                    />
                  </CardContent>
                  <DialogActions>
                    <Button
                      variant={'contained'}
                      color="secondary"
                      onClick={() => {
                        setReplaceResponsibilityCustomerDlgStep(
                          'replaceResponsibilityCustomer',
                        );
                      }}
                    >
                      BACK
                    </Button>
                    <Button
                      variant={'contained'}
                      color="primary"
                      onClick={() => {
                        dispatch(
                          replaceResponsibilityCustomer(
                            responsibilityReplaceCustomerPayLoad!,
                          ),
                        );
                        setReplaceResponsibilityCustomerDlgStep(
                          'replaceResponsibilityCustomer',
                        );
                        onClose();
                      }}
                    >
                      OK
                    </Button>
                  </DialogActions>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ResponsibilityCustomerReplaceDialog;
