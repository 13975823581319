import { AppAction, PageFilter } from '../../../../state/app';

export const FETCH_PENDING_APPROVAL_RESPONSIBILITY_LIST =
  'FETCH_PENDING_APPROVAL_RESPONSIBILITY_LIST';
export const CLEAR_PENDING_APPROVAL_RESPONSIBILITY_LIST =
  'CLEAR_PENDING_APPROVAL_RESPONSIBILITY_LIST';

export const fetchPendingApprovalResponsibilityList = (paging: PageFilter): AppAction => {
  return {
    type: FETCH_PENDING_APPROVAL_RESPONSIBILITY_LIST,
    payload: {
      filters: { 'HasResponded.EqualsTo': 'false' },
      paging,
    },
  };
};

export const clearPendingApprovalResponsibilityList = () => ({
  type: CLEAR_PENDING_APPROVAL_RESPONSIBILITY_LIST,
});
