import { Box, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import {
  CheckBoxFastField,
  TextFieldFastField,
  UnitOfQuantitiesFastField,
  UnitOfPriceFastField,
} from '../../../components';

interface SampleDevelopmentWlQuotationProps {}

const SampleDevelopmentWlQuotation: React.FC<SampleDevelopmentWlQuotationProps> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Quotation</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Quotation: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="withoutQuotation" label="No Quotation" />
            </Grid>
            <Grid item xs={12} md={8} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="quotationType" label="Type" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="quotation" label="Quotation" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="quotationNumber"
                label="QN"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="quotationTypeO" label="O" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                name="quotationTypeOQty"
                label="OQty"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <UnitOfPriceFastField
                name="quotationTypeOCurrency"
                label="OCurrency"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="quotationTypeM" label="M" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                name="quotationTypeMQty"
                label="MQty"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <UnitOfPriceFastField
                name="quotationTypeMCurrency"
                label="MCurrency"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="quotationTypeP" label="P" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                name="quotationTypePQty"
                label="PQty"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <UnitOfPriceFastField
                name="quotationTypePCurrency"
                label="PCurrency"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                name="quotationQty"
                label="Qty"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <UnitOfQuantitiesFastField
                name="quotationUnit"
                label="Unit"
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentWlQuotation;
