import { Button, TableCell, TableRow, TextField } from '@material-ui/core';
import { Delete as DeleteIcon, Save as SaveIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { AppState } from '../../../state/configureStore';
import {
  createSalesTeamMember,
  SalesTeamMember,
  updateSalesTeamMember,
} from '../../../state/salesTeamMember';
import { SalesTeamRole, SalesTeamRoleState } from '../../../state/salesTeamRole';
import { User } from '../../../state/user';
import { AutoComplete } from '../../components';
import { pushNotificationMessage } from '../../components/Notification';
import UserComboBox from '../../components/UserComboBox';
import useStyles from './salesTeamFormDetail.style';

const SalesTeamMemberSchema = Yup.object().shape({
  userId: Yup.number()
    .moreThan(0, 'Please select user')
    .required('User is required'),
  salesTeamRoleId: Yup.number()
    .moreThan(0, 'Please select role')
    .required('Role is required'),
  externalId: Yup.string()
    .nullable()
    .required('External Id is required'),
});

interface SalesTeamFormTableRowProps {
  index: number;
  salesTeamId: number;
  salesTeamMember: SalesTeamMember;
  handleDelete: () => void;
}

const SalesTeamFormTableRow: React.FC<SalesTeamFormTableRowProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { index, salesTeamId, salesTeamMember, handleDelete } = props;
  const [localSalesTeamMember, setLocalSalesTeamMember] = useState({
    ...salesTeamMember,
  });
  const { items: salesTeamRoles } = useSelector<AppState, SalesTeamRoleState>(
    state => state.salesTeamRole,
  );

  const defaultSalesTeamRoleIndex = salesTeamRoles.findIndex(
    salesTeamRole => salesTeamRole.id === localSalesTeamMember.salesTeamRoleId,
  );

  const defaultSalesTeamRoleValue =
    defaultSalesTeamRoleIndex !== -1
      ? salesTeamRoles[defaultSalesTeamRoleIndex]
      : undefined;

  const handleSubmit = (values: SalesTeamMember) => {
    SalesTeamMemberSchema.validate(values, { abortEarly: false })
      .then(validatedValues => {
        if (salesTeamMember.id) {
          dispatch(updateSalesTeamMember({ ...salesTeamMember, ...validatedValues }));
        } else {
          dispatch(createSalesTeamMember({ salesTeamId, ...validatedValues }));
        }
      })
      .catch((err: Yup.ValidationError) => {
        if (err.errors) {
          err.errors.forEach(error => {
            dispatch(pushNotificationMessage({ message: error, type: 'error' }));
          });
        }
      });
  };

  useEffect(() => {
    setLocalSalesTeamMember({ ...salesTeamMember });
  }, [salesTeamMember]);

  return (
    <TableRow>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <UserComboBox
          handleChange={(value: User) => {
            setLocalSalesTeamMember({
              ...localSalesTeamMember,
              user: value,
              userId: value ? value.id : undefined,
            });
          }}
          selectedValue={localSalesTeamMember.user as User}
        />
      </TableCell>
      <TableCell>
        <AutoComplete
          name={'role'}
          placeholder={'Select Role'}
          options={salesTeamRoles}
          handleChange={(value: SalesTeamRole) => {
            setLocalSalesTeamMember({
              ...localSalesTeamMember,
              salesTeamRole: value,
              salesTeamRoleId: value ? value.id : undefined,
            });
          }}
          getOptionLabel={(option: SalesTeamRole) => option.description}
          getOptionValue={(option: SalesTeamRole) => '' + option.id}
          value={defaultSalesTeamRoleValue}
          // isDisabled={id !== 0}
        />
      </TableCell>
      <TableCell>
        <TextField
          variant={'outlined'}
          margin={'dense'}
          value={localSalesTeamMember.externalId ? localSalesTeamMember.externalId : ''}
          onChange={e => {
            setLocalSalesTeamMember({
              ...localSalesTeamMember,
              externalId: e.target.value,
            });
          }}
        />
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleSubmit(localSalesTeamMember);
          }}
          // disabled={id !== 0}
          className={classes.button}
        >
          <SaveIcon className={classes.leftIcon} />
          Save
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDelete}
          // disabled={id !== 0}
        >
          <DeleteIcon className={classes.leftIcon} />
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default SalesTeamFormTableRow;
