import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createBrand,
  fetchBrand,
  fetchBrandList,
  updateBrand,
} from '../../services/brandService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_BRAND,
  FETCH_BRAND,
  FETCH_BRAND_LIST,
  UPDATE_BRAND,
} from './brand.actions';
import { BrandCreateOrUpdatePayload } from './brand.types';

const listPageUrl = `/settings/brands`;

function* fetchBrandSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchBrand, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchBrandListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchBrandList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createBrandSaga(action: AppAction) {
  try {
    const { values, history } = action.payload as BrandCreateOrUpdatePayload;
    const successMessage = `Created ${values.name} brand successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createBrand, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(listPageUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateBrandSaga(action: AppAction) {
  try {
    const { values, history } = action.payload as BrandCreateOrUpdatePayload;
    const successMessage = `Updated ${values.name} brand successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateBrand, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(listPageUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchBrand() {
  yield takeLatest(FETCH_BRAND, fetchBrandSaga);
  yield debounce(250, FETCH_BRAND_LIST, fetchBrandListSaga);
  yield takeLatest(CREATE_BRAND, createBrandSaga);
  yield takeLatest(UPDATE_BRAND, updateBrandSaga);
}
