import React from 'react';
import { makeStyles, Grid, Paper, Typography, Box } from '@material-ui/core';
import { LastCommentDashboard } from './commentDashboard';
import { PendingTaskList } from './PendingTaskList';
import SubtaskStatus from './Subtask/SubtaskStatus';
import PendingApproveTask from './PendingApprove/PendingApproveTask';
import { useSelector } from 'react-redux';
import { AppState } from '../../../state/configureStore';
import { AuthState } from '../../../state/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    width: '100%',
    padding: '0.05px',
    position: 'relative',
    overflow: 'visible',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  const { isLoggedIn, user } = useSelector<AppState, AuthState>((state) => state.auth);

  return (
    // <Box className={classes.root}>
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="center"
      className={classes.root}
    >
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        {/* {isLoggedIn &&
          (user?.roles?.includes('MANAGER') || user?.roles?.includes('ADMIN')) && (
            <Grid item md={12}>
              <PendingApproveTask />
            </Grid>
          )} */}
        <Grid item md={12}>
          <LastCommentDashboard />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
        {/* <Grid item md={12}>
          <SubtaskStatus />
        </Grid> */}
        <PendingTaskList />
      </Grid>
    </Grid>
    // </Box>
  );
};

export default Dashboard;
