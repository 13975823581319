import {
  AppBar,
  Button,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close as CloseIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IProjectSummaryReport } from '../../../../models/report.model';
import { downloadProjectSummaryReport } from '../../../../services/reportService';
import { PageFilter } from '../../../../state/app';
import { AppState } from '../../../../state/configureStore';
import {
  clearReport,
  fetchProjectSummaryReportList,
  IProjectSummaryReportFilter,
  ReportState,
} from '../../../../state/report';
import { PageContainer, PageTitle, TableLoading } from '../../../components';
import { useFilter, useTableSorting } from '../../../hooks';
import ProjectCustomerSummaryReportFilter from './ProjectCustomerSummaryReportFilter';
import useStyles from './projectSummaryReportStyle';

interface IProjectSummaryReportDialog {
  open: boolean;
  handleClose: () => void;
}

const Transition = React.forwardRef(function transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

const ProjectSummaryReportDialog: React.FC<IProjectSummaryReportDialog> = (props) => {
  const classes = useStyles();
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const initFilter: IProjectSummaryReportFilter = {};
  const {
    loading,
    items: projectSummaryReportList,
    totalItems,
  } = useSelector<AppState, ReportState>((state) => state.report);
  const [filters, setFilters] = useFilter<IProjectSummaryReportFilter>(initFilter, true);
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });
  const [sort, setSort] = useTableSorting(
    {
      orderBy: 'Company',
      direction: 'asc',
    },
    true,
  );
  useEffect(() => {
    if (open) {
      dispatch(fetchProjectSummaryReportList(filters, { ...paging }));
    }
    return function cleanUp() {
      dispatch(clearReport());
    };
  }, [dispatch, paging, filters, open]);

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const handleChangeFilter = (newFilter: IProjectSummaryReportFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const exportExcel = () => {
    downloadProjectSummaryReport(filters, { pageSize: totalItems }).catch((err) =>
      console.error(err),
    );
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Project Summary
          </Typography>
        </Toolbar>
      </AppBar>
      <PageContainer>
        <PageTitle>Project Summary</PageTitle>
        <ProjectCustomerSummaryReportFilter
          filters={filters}
          onChangeFilter={handleChangeFilter}
          initialFilter={initFilter}
        />
        {!isSmallScreen && (
          <div className={classes.filterContainer}>
            <Button
              variant={'contained'}
              className={classes.button}
              onClick={exportExcel}
            >
              Export Excel
            </Button>
          </div>
        )}
        {open && (
          <Grid container spacing={3} className={classes.container}>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Project</TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell>Squad</TableCell>
                    <TableCell>Program</TableCell>
                    <TableCell>Brand</TableCell>
                    <TableCell>Customer Group</TableCell>
                    <TableCell>Customer Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableLoading isLoading={loading} colSpan={9}>
                    {projectSummaryReportList.map(
                      (project: IProjectSummaryReport, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              {index + paging.pageSize * paging.pageNumber + 1}
                            </TableCell>
                            <TableCell>{project.company}</TableCell>
                            <TableCell>{project.name}</TableCell>
                            <TableCell>{project.ownerName}</TableCell>
                            <TableCell>{project.squadName}</TableCell>
                            <TableCell>{project.programName}</TableCell>
                            <TableCell>{project.brandName}</TableCell>
                            <TableCell>{project.customerGroupName}</TableCell>
                            <TableCell>{project.customerDisplayName}</TableCell>
                          </TableRow>
                        );
                      },
                    )}
                  </TableLoading>
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[15, 25, 35]}
                component="div"
                count={totalItems}
                rowsPerPage={paging.pageSize}
                page={paging.pageNumber}
                onPageChange={handleChangePageNumber}
                onRowsPerPageChange={handleChangePageSize}
              />
            </Grid>
          </Grid>
        )}
      </PageContainer>
    </Dialog>
  );
};

export default ProjectSummaryReportDialog;
