import { Option } from '../views/components/EditableTable/SelectOptionCellEditor';

export type CompanyType = 'WE' | 'HTR' | 'QTP' | 'TA3';

const Company = Object.freeze({
  WE: 'WE' as CompanyType,
  HTR: 'HTR' as CompanyType,
  QTP: 'QTP' as CompanyType,
  TA3: 'TA3' as CompanyType,
});

export default Company;

export const CompanyLabel = Object.freeze({
  [Company.WE]: 'WE',
  [Company.HTR]: 'HTR',
  [Company.QTP]: 'QTP',
  [Company.TA3]: 'TAP',
});

export const CompanyOptions: Array<Option> = Object.keys(Company).map(u => {
  return { label: CompanyLabel[u], value: u };
});
