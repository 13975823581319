import { Grid, MenuItem } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubtaskStatus, SubtaskStatusLabel } from '../../../constants';
import { AppState } from '../../../state/configureStore';
import { SubtaskListFilter } from '../../../state/subtask';
import { fetchAllSubtaskTypeList, SubtaskTypeState } from '../../../state/subtaskType';
import {
  FilterContainer,
  SearchDateField,
  SearchDropdownField,
  SearchField,
} from '../../components';
import { IFilterContainerConfigProps } from '../../components/FilterContainer';

const TaskSubtaskFilter: React.FC<
  IFilterContainerConfigProps<SubtaskListFilter>
> = props => {
  const dispatch = useDispatch();
  const { items: subtaskTypeList } = useSelector<AppState, SubtaskTypeState>(
    state => state.subtaskType,
  );

  useEffect(() => {
    dispatch(fetchAllSubtaskTypeList());
  }, [dispatch]);

  return (
    <FilterContainer
      {...props}
      render={({ localFilter, handleChangeFilter, handleChangeDateFilter }) => {
        return (
          <Grid container spacing={1} alignItems={'center'}>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Task Number"
                filterKey={'SMTask.TaskNumber.startsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Subject"
                filterKey={'SMTask.Subject.startsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchDropdownField
                id={'subtask-type-filter'}
                label={'Type'}
                onChange={handleChangeFilter}
                filters={localFilter}
                filterKey={'SmSubTaskType.Code.EqualsTo'}
              >
                <MenuItem value="">All</MenuItem>
                {subtaskTypeList.map(subtaskType => {
                  return (
                    <MenuItem key={subtaskType.id} value={subtaskType.code}>
                      {subtaskType.name}
                    </MenuItem>
                  );
                })}
              </SearchDropdownField>
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Requester"
                filterKey={'SMTask.Requester.FullNameWithUserName.contains'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Assignee"
                filterKey={'SmSubtaskAssignees.User.FullNameWithUserName.contains'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchDropdownField
                id={'subtask-status-filter'}
                label={'Subtask Status'}
                onChange={handleChangeFilter}
                filters={localFilter}
                filterKey={'Status.EqualsTo'}
              >
                <MenuItem value="">ALL</MenuItem>
                {Object.entries(SubtaskStatus).map(([key, value]) => {
                  return (
                    <MenuItem key={key} value={value}>
                      {SubtaskStatusLabel[value]}
                    </MenuItem>
                  );
                })}
              </SearchDropdownField>
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchDateField
                showTodayButton={true}
                fullWidth={true}
                label="Start Subtask Due Date"
                onChange={handleChangeDateFilter}
                filters={localFilter}
                filterKey={'StartDueDate.GreaterThanOrEqual'}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchDateField
                showTodayButton={true}
                fullWidth={true}
                label="End Subtask Due Date"
                onChange={handleChangeDateFilter}
                filters={localFilter}
                filterKey={'EndDueDate.LessThanOrEqual'}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default TaskSubtaskFilter;
