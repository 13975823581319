import {
  Button,
  CardContent,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Grid,
} from '@material-ui/core';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IProjectProgram } from '../../../../models/projectProgram.model';
import { AppState } from '../../../../state/configureStore';
import {
  deleteProjectProgram,
  ProjectProgramState,
} from '../../../../state/projectProgram';

import ConfirmDialog from '../../../components/ConfirmDialog';
import AddProjectProgramDialog from '../AddProjectProgramDialog';
import AddProjectProgramBrandDialog from '../AddProjectProgramBrandDialog';
import {
  fetchProjectProgramBrandListBySmProjectIdSmProjectProgramId,
  ProjectProgramBrandState,
  fetchBrandByProjectId,
  deleteProjectProgramBrand,
} from '../../../../state/projectProgramBrand';
import { IProjectProgramBrand } from '../../../../models/projectProgramBrand.model';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { makeStyles, Theme, createStyles, fade } from '@material-ui/core/styles';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import IconButton from '@material-ui/core/IconButton';
import { ProjectStatus } from '../../../../constants';
import { ProjectState } from '../../../../state/project';

interface ProjectProgramBrandProps {
  smProjectId: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
    },
    table: {
      minWidth: 600,
    },
    group: {
      marginLeft: -10,
      paddingLeft: 10,
      borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
    },
    button: {
      padding: theme.spacing(0.25, 0.45),
      margin: theme.spacing(1),
    },
  }),
);

const ProjectProgramBrandForm: React.FC<ProjectProgramBrandProps> = (props) => {
  const { smProjectId } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedProjectProgram, setSelectedProjectProgram] = useState<IProjectProgram>(
    {},
  );
  const useProjectStore = () => {
    const { item: project } = useSelector<AppState, ProjectState>(
      (state) => state.project,
    );
    return {
      project,
    };
  };

  const { project } = useProjectStore();
  const approved = project.status === ProjectStatus.APPROVED;

  const [
    selectedProjectProgramBrand,
    setSelectedProjectProgramBrand,
  ] = useState<IProjectProgramBrand>({});

  const [localProjectPrograms, setLocalProjectPrograms] = useState<IProjectProgram[]>([]);
  const [localProjectProgramBrands, setLocalProjectProgramBrands] = useState<
    IProjectProgramBrand[]
  >([]);
  const [openAddProjectProgramDialog, setOpenAddProjectProgramDialog] = useState(false);
  const [
    openAddProjectProgramBrandDialog,
    setOpenAddProjectProgramBrandDialog,
  ] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const { items: projectPrograms, loading: isProgramLoading } = useSelector<
    AppState,
    ProjectProgramState
  >((state) => state.projectProgram);

  const { items: projectProgramBrands, loading: isBrandLoading, brands } = useSelector<
    AppState,
    ProjectProgramBrandState
  >((state) => state.projectProgramBrand);

  const isAllRecordSaved = localProjectPrograms.findIndex((opc) => !opc.id) === -1;

  const deleteProgram = () => {
    dispatch(deleteProjectProgram(selectedProjectProgram.id!));
  };

  const deleteProgramBrand = (projectProgramBrandId: number) => {
    dispatch(deleteProjectProgramBrand(projectProgramBrandId));
  };

  useEffect(() => {
    if (smProjectId) {
      dispatch(fetchBrandByProjectId(smProjectId));
    }
  }, [smProjectId]);

  useEffect(() => {
    setLocalProjectPrograms([...projectPrograms]);
  }, [projectPrograms]);

  return (
    <>
      <CardContent>
        <Grid container spacing={2}>
          <AddProjectProgramDialog
            open={openAddProjectProgramDialog}
            handleClose={() => {
              setOpenAddProjectProgramDialog(false);
            }}
            smProjectId={smProjectId}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenAddProjectProgramDialog(true)}
            disabled={!isAllRecordSaved || approved}
            startIcon={<AddIcon />}
          >
            Add Program
          </Button>
          <ConfirmDialog
            open={openConfirmDialog}
            handleCancel={() => {
              setOpenConfirmDialog(false);
            }}
            handleConfirm={() => {
              deleteProgram();
              setOpenConfirmDialog(false);
            }}
            content={
              'Do you want to delete' +
              ' ' +
              (Object.keys(selectedProjectProgram).length !== 0
                ? selectedProjectProgram!.program!.name! + ' ' + '?'
                : '')
            }
            handleClose={() => {
              setOpenConfirmDialog(false);
            }}
          />
          <Button
            disabled={
              !selectedProjectProgram.id || localProjectPrograms.length === 0 || approved
            }
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={() => {
              setOpenConfirmDialog(true);
            }}
          >
            Delete Program
          </Button>

          <AddProjectProgramBrandDialog
            open={openAddProjectProgramBrandDialog}
            handleClose={() => {
              setOpenAddProjectProgramBrandDialog(false);
            }}
            smProjectId={smProjectId}
            smProjectProgramId={selectedProjectProgram.id!}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Program And Brand</TableCell>
              </TableRow>
            </TableHead>
            <TreeView
              className={classes.table}
              defaultCollapseIcon={<LocalParkingIcon />}
              defaultExpandIcon={<LocalParkingIcon />}
            >
              {localProjectPrograms.map((projectProgram, index) => {
                const { program } = projectProgram;

                return (
                  <TreeItem
                    key={index}
                    className={classes.root}
                    nodeId={`${program!.id}${program!.name}`}
                    label={program!.name}
                    onClick={() => {
                      setSelectedProjectProgram(projectProgram);
                    }}
                  >
                    {brands
                      .filter((brand) => brand.smProjectProgramId === projectProgram.id)
                      .map((brand, index) => {
                        return (
                          <TreeItem
                            className={classes.group}
                            nodeId={`${brand.id}${brand!.brand!.name!}`}
                            key={brand.id}
                            label={
                              <div>
                                {brand!.brand!.name}
                                <IconButton
                                  size="small"
                                  disabled={!projectProgramBrands || approved}
                                  onClick={(event) => {
                                    deleteProgramBrand(brand.id!);
                                  }}
                                >
                                  <DeleteIcon color="secondary" />
                                </IconButton>
                              </div>
                            }
                          />
                        );
                      })}
                    <Button
                      variant="contained"
                      className={classes.button}
                      color="primary"
                      size="small"
                      onClick={() => setOpenAddProjectProgramBrandDialog(true)}
                      disabled={selectedProjectProgram.id === undefined || approved}
                      startIcon={<AddIcon />}
                    >
                      Add Brand
                    </Button>
                  </TreeItem>
                );
              })}
            </TreeView>
          </Table>
        </Grid>
      </CardContent>
    </>
  );
};

export default ProjectProgramBrandForm;
