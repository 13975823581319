import { ISubtaskItemForecast } from '../models/subtaskItemForecast.model';
import { PageFilter } from '../state/app';
import { SubtaskItemForecastListFilter } from '../state/subtaskItemForecast';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/smSubtaskItemForecasts`;

export const fetchSubtaskItemForecastList = async ({
  filters,
  paging,
}: {
  filters: SubtaskItemForecastListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchSubtaskItemForecast = async (id: ISubtaskItemForecast['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createSubtaskItemForecast = async (
  subtaskItemForecast: ISubtaskItemForecast,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, subtaskItemForecast);
};

export const updateSubtaskItemForecast = async (
  subtaskItemForecast: ISubtaskItemForecast,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, subtaskItemForecast);
};

export const deleteSubtaskItemForecast = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};

export const removeAndCreateSubtaskItemForecasts = async (
  smSubtaskItemId: number,
  subtaskItemForecasts: Array<ISubtaskItemForecast>,
) => {
  const requestUrl = `/api/smSubtaskItems/${smSubtaskItemId}/smSubtaskItemForecasts`;
  return await axios.post(requestUrl, subtaskItemForecasts);
};
