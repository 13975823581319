import React from 'react';

import { makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    width: '100%',
    padding: '20px',
    overflowX: (props: PageContainerProps) =>
      props.disableOverflowX ? 'visible' : 'auto',
    minHeight: (props: PageContainerProps) =>
      props.fixHeight ? props.fixHeight : 'auto',
    maxHeight: (props: PageContainerProps) =>
      props.fixHeight ? props.fixHeight + 1 : 'auto',
    overflowY: (props: PageContainerProps) =>
      props.disableOverflowY ? 'visible' : 'auto',
  },
}));

interface PageContainerProps {
  disableOverflowX?: boolean;
  disableOverflowY?: boolean;
  fixHeight?: number;
}

const PageContainer: React.FC<PageContainerProps> = props => {
  const classes = useStyles(props);
  return (
    <Paper className={classes.root} variant="outlined">
      {props.children}
    </Paper>
  );
};

export default PageContainer;
