import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { SearchField, TableLoading } from '../../../components';
import {
  WorkingTeamMemberListFilter,
  WorkingTeamMember,
  WorkingTeamMemberState,
  clearWorkingTeamMember,
  fetchWorkingTeamMemberList,
  fetchWorkingTeamMemberNotOwnerList,
} from '../../../../state/workingTeamMember';
import { PageFilter } from '../../../../state/app';
import { AppState } from '../../../../state/configureStore';
import { createResponsibilityWorkingTeamMembers } from '../../../../state/responsibilityWorkingTeamMember';
import { TablePageSize } from '../../../../constants';
import { uniqBy } from 'lodash';
import { pushNotificationMessage } from '../../../components/Notification';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      minHeight: '53vh',
    },
    filterContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

interface AddResponsibilityWorkingTeamMemberDialogProps {
  open: boolean;
  handleClose: () => void;
  responsibilityId: number;
  workingTeam?: string;
}

const initialPaging = { pageNumber: 0, pageSize: TablePageSize[0] };

const AddResponsibilityWorkingTeamMemberDialog: React.FC<
  AddResponsibilityWorkingTeamMemberDialogProps
> = (props) => {
  const { open, handleClose, responsibilityId, workingTeam } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    items: WorkingTeamMemberList,
    totalItems,
    loading,
  } = useSelector<AppState, WorkingTeamMemberState>((state) => state.workingTeamMember);
  const [filters, setFilters] = useState<WorkingTeamMemberListFilter>({
    'SmWorkingTeam.Name.startsWith': workingTeam,
  });
  const [paging, setPaging] = useState<PageFilter>(initialPaging);
  const [selectedWorkingTeamMembers, setSelectedWorkingTeamMembers] = useState<
    WorkingTeamMember[]
  >([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    setSelectAll(false);
  }, [filters, paging]);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const resetForm = () => {
    handleClose();
    setSelectedWorkingTeamMembers([]);
    setPaging({ ...initialPaging });
    setFilters({ 'SmWorkingTeam.Name.startsWith': workingTeam });
  };

  const handleSubmit = () => {
    const submitValue = selectedWorkingTeamMembers.map(
      (selectedWorkingTeamMembers, index) => {
        return {
          workingTeamMemberId: selectedWorkingTeamMembers.id,
          sequence: index + 1,
          responsibilityId: responsibilityId,
        };
      },
    );

    const membersIncorrectTeam = selectedWorkingTeamMembers
      .filter((f) => f.smWorkingTeam?.smWorkingGroup?.name !== workingTeam)
      .map((m) => m.user?.fullNameWithUserName)
      .join(', ');

    if (membersIncorrectTeam !== '') {
      dispatch(
        pushNotificationMessage({
          message: `You can't add: ${membersIncorrectTeam} in section: ${workingTeam}`,
          type: 'error',
        }),
      );
    } else {
      dispatch(createResponsibilityWorkingTeamMembers(submitValue, resetForm));
    }
  };

  useEffect(() => {
    if (open) {
      dispatch(fetchWorkingTeamMemberNotOwnerList(filters, paging));
    }

    return function cleanUp() {
      dispatch(clearWorkingTeamMember());
    };
  }, [dispatch, paging, filters, open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth={true}
      disableBackdropClick={true}
    >
      <DialogTitle>Select Member</DialogTitle>
      <DialogContent>
        <div className={classes.filterContainer}>
          <SearchField
            label="Team"
            onChange={handleChangeFilter}
            filterKey="SmWorkingTeam.Name.startsWith"
            filters={filters}
          />
          <SearchField
            label="Name"
            onChange={handleChangeFilter}
            filterKey="User.FullNameWithUserName.Contains"
            filters={filters}
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  onChange={(event: React.ChangeEvent<HTMLInputElement>, checked) => {
                    if (checked) {
                      const newList = [...selectedWorkingTeamMembers].concat(
                        WorkingTeamMemberList,
                      );
                      setSelectedWorkingTeamMembers(uniqBy(newList, (w) => w.id!));
                    } else {
                      const isInCurrentList = (
                        workingTeamMember: WorkingTeamMember,
                      ): boolean =>
                        !WorkingTeamMemberList.find((w) => w.id === workingTeamMember.id);
                      setSelectedWorkingTeamMembers(
                        selectedWorkingTeamMembers.filter(isInCurrentList),
                      );
                    }
                    setSelectAll(checked);
                  }}
                  checked={selectAll}
                />
              </TableCell>
              <TableCell>No.</TableCell>
              <TableCell>Group</TableCell>
              <TableCell>Team</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={10}>
              {WorkingTeamMemberList.map((workingTeamMember, index) => {
                const isCheckboxChecked =
                  selectedWorkingTeamMembers.findIndex(
                    (selectedWorkingTeamMember) =>
                      selectedWorkingTeamMember.id === workingTeamMember.id,
                  ) !== -1;
                return (
                  <TableRow key={workingTeamMember.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked,
                        ) => {
                          if (checked) {
                            setSelectedWorkingTeamMembers([
                              ...selectedWorkingTeamMembers,
                              workingTeamMember,
                            ]);
                          } else {
                            setSelectedWorkingTeamMembers(
                              selectedWorkingTeamMembers.filter(
                                (w) => w.id !== workingTeamMember.id,
                              ),
                            );
                          }
                        }}
                        checked={isCheckboxChecked}
                      />
                    </TableCell>
                    <TableCell>
                      {index + paging.pageSize * paging.pageNumber + 1}
                    </TableCell>
                    <TableCell>
                      {workingTeamMember?.smWorkingTeam?.smWorkingGroup?.name}
                    </TableCell>
                    <TableCell>{workingTeamMember?.smWorkingTeam?.name}</TableCell>
                    <TableCell>{workingTeamMember?.user?.fullNameWithUserName}</TableCell>
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[...TablePageSize]}
          component="div"
          count={totalItems}
          rowsPerPage={paging.pageSize}
          page={paging.pageNumber}
          onPageChange={handleChangePageNumber}
          onRowsPerPageChange={handleChangePageSize}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Confirm
        </Button>
        <Button
          onClick={() => {
            resetForm();
          }}
          color="default"
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddResponsibilityWorkingTeamMemberDialog;
