import { createMuiTheme, Theme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';

const theme = createMuiTheme({
  typography: {
    fontSize: 13,
  },
});

function props(): ComponentsProps {
  return {
    MuiTable: {
      size: 'small',
    },
    MuiDialog: {
      disableBackdropClick: true,
      disableEscapeKeyDown: true,
    },
    MuiTextField: {
      autoComplete: 'off',
    },
  };
}

function overrides(theme: Theme): Overrides {
  return {
    MuiTable: {
      root: {
        marginTop: theme.spacing(2),
        // minWidth: 650,
      },
    },

    MuiTableRow: {
      head: { height: 40 },
      root: {
        height: 50,
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    },
    MuiTableCell: {
      head: {
        // backgroundColor: '#ececec',
        // color: '#3a3a3a',
        backgroundColor: '#757575',
        color: 'white',
      },
      sizeSmall: {
        padding: theme.spacing(1),
      },
    },
    MuiFormControl: {
      marginDense: {
        marginBottom: theme.spacing(1),
      },
    },
    MuiButton: {
      root: {
        marginRight: theme.spacing(1),
      },
    },
    MuiTableSortLabel: {
      root: {
        color: 'white',
        '&:hover': {
          color: 'white',
          '&& $icon': {
            opacity: 1,
            color: 'white',
          },
        },
        '&$active': {
          color: 'white',
          '&& $icon': {
            opacity: 1,
            color: 'white',
          },
        },
      },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: '#3f51b5',
        color: 'white',
      },
    },
  };
}

export const defaultListLoadingHeight = 700;

export default Object.assign(theme, { props: props(), overrides: overrides(theme) });
