import { ISquad } from '../models/squad.model';
import { PageFilter } from '../state/app';
import { SquadListFilter } from '../state/squad';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/smSquads`;

export const fetchSquadList = async ({
  filters,
  paging,
}: {
  filters: SquadListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchSquad = async (id: ISquad['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createSquad = async (squad: ISquad) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, squad);
};

export const updateSquad = async (squad: ISquad) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, squad);
};
