import { IResponsibilityWorkingTeamMember } from '../../models/responsibilityWorkingTeamMember.model';
import { AppAction, PageFilter, AppCallback } from '../app';
import {
  ResponsibilityWorkingTeamMemberCreateOrUpdatePayload,
  ResponsibilityWorkingTeamMemberListFilter,
  ResponsibilityWorkingTeamMemberReplacePayLoad,
} from './responsibilityWorkingTeamMember.types';

export const FETCH_RESPONSIBILITY_WORKINGTEAM_MEMBER =
  'FETCH_RESPONSIBILITY_WORKINGTEAM_MEMBER';
export const FETCH_RESPONSIBILITY_WORKINGTEAM_MEMBER_LIST =
  'FETCH_RESPONSIBILITY_WORKINGTEAM_MEMBER_LIST';
export const CREATE_RESPONSIBILITY_WORKINGTEAM_MEMBER =
  'CREATE_RESPONSIBILITY_WORKINGTEAM_MEMBER';
export const UPDATE_RESPONSIBILITY_WORKINGTEAM_MEMBER =
  'UPDATE_RESPONSIBILITY_WORKINGTEAM_MEMBER';
export const CLEAR_RESPONSIBILITY_WORKINGTEAM_MEMBER =
  'CLEAR_RESPONSIBILITY_WORKINGTEAM_MEMBER';
export const DELETE_RESPONSIBILITY_WORKINGTEAM_MEMBER =
  ' DELETE_RESPONSIBILITY_WORKINGTEAM_MEMBER';
export const CREATE_RESPONSIBILITY_WORKINGTEAM_MEMBERS =
  'CREATE_RESPONSIBILITY_WORKINGTEAM_MEMBERS';
export const REPLACE_RESPONSIBILITY_WORKINGTEAM_MEMBER =
  'REPLACE_RESPONSIBILITY_WORKINGTEAM_MEMBER';
export const INACTIVE_RESPONSIBILITY_WORKINGTEAM_MEMBER =
  'INACTIVE_RESPONSIBILITY_WORKINGTEAM_MEMBER';

export const SET_RESPONSIBILITY_WORKINGTEAM_MEMBER =
  'SET_RESPONSIBILITY_WORKINGTEAM_MEMBER';

export const fetchResponsibilityWorkingTeamMember = (
  id: IResponsibilityWorkingTeamMember['id'],
): AppAction => ({
  type: FETCH_RESPONSIBILITY_WORKINGTEAM_MEMBER,
  payload: id,
});

export const fetchResponsibilityWorkingTeamMemberList = (
  filters: ResponsibilityWorkingTeamMemberListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_RESPONSIBILITY_WORKINGTEAM_MEMBER_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchResponsibilityWorkingTeamMemberListByresponsibilityId = (
  responsibilityId: number,
): AppAction => ({
  type: FETCH_RESPONSIBILITY_WORKINGTEAM_MEMBER_LIST,
  payload: {
    filters: { 'responsibilityId.equalsTo': responsibilityId },
    paging: { pageNumber: 0, pageSize: 99999 },
  },
});

export const createResponsibilityWorkingTeamMember = (
  payload: ResponsibilityWorkingTeamMemberCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_RESPONSIBILITY_WORKINGTEAM_MEMBER,
  payload,
});

export const createResponsibilityWorkingTeamMembers = (
  payload: any,
  callback?: AppCallback,
): AppAction => ({
  type: CREATE_RESPONSIBILITY_WORKINGTEAM_MEMBERS,
  payload,
  callback,
});

export const updateResponsibilityWorkingTeamMember = (
  payload: ResponsibilityWorkingTeamMemberCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_RESPONSIBILITY_WORKINGTEAM_MEMBER,
  payload,
});

export const clearResponsibilityWorkingTeamMember = () => ({
  type: CLEAR_RESPONSIBILITY_WORKINGTEAM_MEMBER,
});

export const deleteResponsibilityWorkingTeamMember = (id: number) => ({
  type: DELETE_RESPONSIBILITY_WORKINGTEAM_MEMBER,
  payload: { id },
});

export const replaceResponsibilityWorkingTeamMember = (
  payload: ResponsibilityWorkingTeamMemberReplacePayLoad,
) => ({
  type: REPLACE_RESPONSIBILITY_WORKINGTEAM_MEMBER,
  payload,
});

export const inactiveResponsibilityWorkingTeamMember = (id: number) => ({
  type: INACTIVE_RESPONSIBILITY_WORKINGTEAM_MEMBER,
  payload: { id },
});

export const setResponsibilityWorkingTeamMember = (
  payload: IResponsibilityWorkingTeamMember[],
): AppAction => ({
  type: SET_RESPONSIBILITY_WORKINGTEAM_MEMBER,
  payload,
});
