import { Button, createStyles, makeStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { useState } from 'react';
import { IProjectProgram } from '../../models/projectProgram.model';
import { fetchProjectProgramList } from '../../services/projectProgramService';
import { Program, ProgramDto } from '../../state/program';
import AsyncAutoComplete from './AsyncAutoComplete';
import ProjectProgramDialogSearch from './ProjectProgramDialogSearch';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    autoComplete: {
      flexGrow: 20,
    },
    button: {
      marginBottom: '8px',
      flexGrow: 1,
    },
  }),
);

interface ProjectProgramComboBoxSearchProps {
  handleChange: (result: IProjectProgram) => void;
  selectedValue: Program;
  isDisabled?: boolean;
  error?: boolean;
  helperText?: string;
  smProjectId?: number | null;
}

const ProjectProgramComboBoxSearch: React.FC<
  ProjectProgramComboBoxSearchProps
> = props => {
  const {
    handleChange,
    selectedValue,
    isDisabled = false,
    error,
    helperText,
    smProjectId,
  } = props;
  const classes = useStyles();
  const [openSearch, setOpenSearch] = useState(false);

  const programPromiseOptions = async (inputValue: string) => {
    const { data: rows } = await fetchProjectProgramList({
      filters: {
        'Program.Name.StartsWith': inputValue,
        'SmProjectId.EqualsTo': smProjectId!,
      },
      paging: { pageNumber: 0, pageSize: 10 },
    });
    return rows.map((res: IProjectProgram) => {
      return { ...res.program, smProjectProgramId: res.id };
    });
  };

  return (
    <div className={classes.container}>
      <AsyncAutoComplete
        name="Program"
        value={selectedValue}
        className={classes.autoComplete}
        getOptionValue={(option: ProgramDto) => {
          return option.id!.toString();
        }}
        getOptionLabel={(option: ProgramDto) => {
          return option.name;
        }}
        promiseOptions={programPromiseOptions}
        placeholder={'Type Program Name'}
        handleChange={handleChange}
        isDisabled={isDisabled}
        error={error}
        helperText={helperText}
      />
      <Button
        className={classes.button}
        color="primary"
        variant="outlined"
        startIcon={<Search />}
        disabled={isDisabled}
        onClick={() => {
          setOpenSearch(true);
        }}
        style={{ marginLeft: 10 }}
      >
        Search
      </Button>
      <ProjectProgramDialogSearch
        open={openSearch}
        smProjectId={smProjectId!}
        onClose={selectedProjectProgram => {
          if (selectedProjectProgram) {
            handleChange(selectedProjectProgram);
          }
          setOpenSearch(false);
        }}
      />
    </div>
  );
};

export default ProjectProgramComboBoxSearch;
