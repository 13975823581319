import { AppAction } from '../../../../state/app';
import { SampleDevelopmentWlApprovalRequest } from '../../../../state/sampleDevelopmentWlApprovalRequest/sampleDevelopmentWlApprovalRequest.types';
import { FAILURE, REQUEST, SUCCESS } from '../../../../utils/actionTypeUtil';
import { FETCH_PENDING_APPROVAL_SAMPLE_DEV_WL_LIST } from './pendingApprovalSampleDevWlRequestList.actions';

export interface PendingApprovalSampleDevWlRequestListState {
  loading: boolean;
  items: ReadonlyArray<SampleDevelopmentWlApprovalRequest>;
  errorMessage: string;
  totalItems: number;
}

const initialState: PendingApprovalSampleDevWlRequestListState = {
  errorMessage: '',
  items: [],
  loading: false,
  totalItems: 0,
};

export default (
  state: PendingApprovalSampleDevWlRequestListState = initialState,
  action: AppAction,
): PendingApprovalSampleDevWlRequestListState => {
  switch (action.type) {
    case REQUEST(FETCH_PENDING_APPROVAL_SAMPLE_DEV_WL_LIST):
      return { ...state, loading: true };
    case SUCCESS(FETCH_PENDING_APPROVAL_SAMPLE_DEV_WL_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_PENDING_APPROVAL_SAMPLE_DEV_WL_LIST):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    default:
      return state;
  }
};
