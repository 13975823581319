import { PageFilter } from '../state/app';
import {
  ResponsibilitySalesTeamMemberListFilter,
  ResponsibilitySalesTeamMemberReplacePayLoad,
} from '../state/responsibilitySalesTeamMember';
import axios from '../utils/axios';
import { IResponsibilitySalesTeamMember } from '../models/responsibilitySalesTeamMember.model';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
const baseUrl = `/api/responsibilitySalesTeamMembers`;

export const createResponsibilitySalesTeamMember = async (
  responsibilitySalesTeamMember: IResponsibilitySalesTeamMember,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, responsibilitySalesTeamMember);
};

export const fetchResponsibilitySalesTeamMember = async (
  id: IResponsibilitySalesTeamMember['id'],
) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const fetchResponsibilitySalesTeamMemberList = async ({
  filters,
  paging,
}: {
  filters: ResponsibilitySalesTeamMemberListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const deleteResponsibilitySalesTeamMember = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};

export const inactiveResponsibilitySalesTeamMember = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}/inactive`;
  return await axios.put(requestUrl);
};

export const updateResponsibilitySalesTeamMember = async (
  responsibilitySalesTeamMember: IResponsibilitySalesTeamMember,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, responsibilitySalesTeamMember);
};

export const replaceResponsibilitySalesTeamMember = async (
  responsibilitySalesTeamMemberReplacePayLoad: ResponsibilitySalesTeamMemberReplacePayLoad,
) => {
  const requestUrl = `${baseUrl}/${responsibilitySalesTeamMemberReplacePayLoad.responsibilitySalesTeamMemberId}/replace`;
  return await axios.put(requestUrl, responsibilitySalesTeamMemberReplacePayLoad);
};
