import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearEBlockArtTemplate,
  createEBlockArtTemplate,
  EBlockArtTemplate,
  EBlockArtTemplateState,
  fetchEBlockArtTemplate,
  fetchEBlockArtTemplateList,
} from '../../../state/eBlockArtTemplate';
import { Loading, PageTitle, TableLoading } from '../../components';
import { EBlockArtTemplateAddDialog } from '../../components/EBlockArtTemplateAddDialog';
import { EBlockArtTemplateEditDialog } from '../../components/EBlockArtTemplateEditDialog';
import useStyles from './eBlock.style';

interface EBlockFormDetailProps {
  blockId: number;
}
const EBlockFormDetail: React.FC<EBlockFormDetailProps> = (props) => {
  const { blockId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const {
    items: eBlockArtTemplates,
    loading: isLoading,
    totalItems,
  } = useSelector<AppState, EBlockArtTemplateState>((state) => state.eBlockArtTemplate);

  const [openEBlockArtTemplateEditDialog, setOpenEBlockArtTemplateEditDialog] =
    useState(false);

  const [openEBlockArtTemplateAddDialog, setOpenEBlockArtTemplateAddDialog] =
    useState(false);

  const addNewEBlockArtTemplate = (autoGenerateTask: boolean) => {
    dispatch(createEBlockArtTemplate({ blockId, autoGenerateTask }));
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    if (!!blockId) {
      dispatch(
        fetchEBlockArtTemplateList(
          { 'BlockId.EqualsTo': blockId },
          { pageNumber: 0, pageSize: 99999 },
        ),
      );
    } else {
      dispatch(clearEBlockArtTemplate());
    }
    return function cleanUp() {
      dispatch(clearEBlockArtTemplate());
    };
  }, [dispatch, blockId]);

  return (
    <Card className={classes.root}>
      <PageTitle>Art Template</PageTitle>
      <CardContent>
        <Button
          variant={'contained'}
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            // addNewEBlockArtTemplate();
            setOpenEBlockArtTemplateAddDialog(true);
          }}
        >
          Add
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Number</TableCell>
              <TableCell>IsDefault</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={isLoading} colSpan={5}>
              {eBlockArtTemplates.map((eBlockArtTemplate, index) => (
                <TableRow key={eBlockArtTemplate.id}>
                  <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                  <TableCell>{eBlockArtTemplate.artTemplateNumber}</TableCell>
                  <TableCell>
                    <Checkbox checked={eBlockArtTemplate.isDefault} color="primary" />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant={'contained'}
                      color="primary"
                      startIcon={<EditIcon />}
                      onClick={() => {
                        setOpenEBlockArtTemplateEditDialog(true);
                        dispatch(fetchEBlockArtTemplate(eBlockArtTemplate.id));
                      }}
                    >
                      EDIT
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableLoading>
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[15, 25, 35]}
          component="div"
          count={totalItems}
          rowsPerPage={paging.pageSize}
          page={paging.pageNumber}
          onPageChange={handleChangePageNumber}
          onRowsPerPageChange={handleChangePageSize}
        />
      </CardContent>
      <EBlockArtTemplateEditDialog
        open={openEBlockArtTemplateEditDialog}
        onClose={() => {
          setOpenEBlockArtTemplateEditDialog(false);
        }}
      />

      <EBlockArtTemplateAddDialog
        open={openEBlockArtTemplateAddDialog}
        onClose={(autoGenerateTask) => {
          addNewEBlockArtTemplate(autoGenerateTask);
          setOpenEBlockArtTemplateAddDialog(false);
        }}
      />
    </Card>
  );
};

export default EBlockFormDetail;
