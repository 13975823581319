import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { PageFilter } from '../../../state/app';
import { Block, BlockListFilter } from '../../../state/block';
import { AppState } from '../../../state/configureStore';
import {
  clearEBlockArtTemplate,
  EBlockArtTemplateListFilter,
  EBlockArtTemplateState,
  fetchEBlockArtTemplateList,
} from '../../../state/eBlockArtTemplate';
import {
  PageContainer,
  PageTitle,
  SearchField,
  SortLabel,
  TableLoading,
} from '../../components';
import { useFilter, useTableSorting } from '../../hooks';
import useStyles from './EBlockArtTemplateList.style';
import EBlockArtTemplateFilter from './EBlockArtTemplateFilter';

const initEBlockListFilter: EBlockArtTemplateListFilter = {};

const EBlockArtTemplateList: React.FC<RouteComponentProps> = (props) => {
  const { history } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });
  const [filters, setFilters] =
    useFilter<EBlockArtTemplateListFilter>(initEBlockListFilter);

  const [sort, setSort] = useTableSorting({
    orderBy: 'block.blockNo',
    direction: 'desc',
  });

  const {
    items: eBlockArtTemplates,
    totalItems,
    loading,
  } = useSelector<AppState, EBlockArtTemplateState>((state) => state.eBlockArtTemplate);

  useEffect(() => {
    dispatch(fetchEBlockArtTemplateList(filters, { ...paging, sort }));
    return function cleanUp() {
      dispatch(clearEBlockArtTemplate());
    };
  }, [dispatch, paging, filters, sort]);

  const handleChangeFilter = (newFilter: EBlockArtTemplateListFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const editEBlock = (id: Block['id']): void => {
    history.push(`/settings/eBlocks/form/${id}`);
  };

  return (
    <PageContainer>
      <PageTitle>EBlock Art Template</PageTitle>
      <EBlockArtTemplateFilter
        filters={filters}
        onChangeFilter={handleChangeFilter}
        initialFilter={initEBlockListFilter}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="block.blockNo">
                EBlock No
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="artTemplateNumber">
                Art Template Number
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={sort}
                handleSortChange={setSort}
                field="block.eBlockGroupProduct.description"
              >
                Group Product
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={sort}
                handleSortChange={setSort}
                field="block.eBlockMaterial.description"
              >
                Material
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={sort}
                handleSortChange={setSort}
                field="block.eBlockSize.description"
              >
                Size
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={sort}
                handleSortChange={setSort}
                field="block.eBlockPattern.description"
              >
                Pattern
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={sort}
                handleSortChange={setSort}
                field="block.program.name"
              >
                Program
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="block.brand.name">
                Brand
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="block.item">
                Item
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={sort}
                handleSortChange={setSort}
                field="block.itemDescription"
              >
                Item Description
              </SortLabel>
            </TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={5}>
            {eBlockArtTemplates.map((eBlockArtTemplate, index) => (
              <TableRow key={eBlockArtTemplate.id}>
                <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                <TableCell>{eBlockArtTemplate.block!!.blockNo}</TableCell>
                <TableCell>{eBlockArtTemplate.artTemplateNumber}</TableCell>
                <TableCell>
                  {eBlockArtTemplate.block!!.eBlockGroupProduct!!.description}
                </TableCell>
                <TableCell>
                  {eBlockArtTemplate.block!!.eBlockMaterial!!.description}
                </TableCell>
                <TableCell>
                  {eBlockArtTemplate.block!!.eBlockSize!!.description}
                </TableCell>
                <TableCell>
                  {eBlockArtTemplate.block!!.eBlockPattern!!.description}
                </TableCell>
                <TableCell>{eBlockArtTemplate.block!!.program!!.name}</TableCell>
                <TableCell>{eBlockArtTemplate.block!!.brand!!.name}</TableCell>
                <TableCell>{eBlockArtTemplate.block!!.item}</TableCell>
                <TableCell>{eBlockArtTemplate.block!!.itemDescription}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => editEBlock(eBlockArtTemplate.block!!.id)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35, 500, 1000]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default EBlockArtTemplateList;
