import { AppAction, PageFilter } from '../app';
import {
  WorkingGroup,
  WorkingGroupCreateOrUpdatePayload,
  WorkingGroupListFilter,
} from './workingGroup.type';

export const FETCH_WORKING_GROUP = 'FETCH_WORKING_GROUP';
export const FETCH_WORKING_GROUP_LIST = 'FETCH_WORKING_GROUP_LIST';
export const CREATE_WORKING_GROUP = 'CREATE_WORKING_GROUP';
export const UPDATE_WORKING_GROUP = 'UPDATE_WORKING_GROUP';
export const CLEAR_WORKING_GROUP = 'CLEAR_WORKING_GROUP';

export const fetchWorkingGroupList = (
  filters: WorkingGroupListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_WORKING_GROUP_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchWorkingGroup = (id: WorkingGroup['id']): AppAction => ({
  type: FETCH_WORKING_GROUP,
  payload: id,
});

export const createWorkingGroup = (
  payload: WorkingGroupCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_WORKING_GROUP,
  payload,
});

export const updateWorkingGroup = (
  payload: WorkingGroupCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_WORKING_GROUP,
  payload,
});

export const clearWorkingGroup = () => ({
  type: CLEAR_WORKING_GROUP,
});
