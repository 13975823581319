import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createProjectApprovalRequest,
  fetchProjectApprovalRequest,
  fetchProjectApprovalRequestList,
  updateProjectApprovalRequest,
  updateProjectApprovalResponse,
} from '../../services/projectApprovalRequestService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  closeProjectApprovalRequestDialog,
  CREATE_PROJECT_APPROVAL_REQUEST,
  FETCH_PROJECT_APPROVAL_REQUEST,
  FETCH_PROJECT_APPROVAL_REQUEST_LIST,
  UPDATE_PROJECT_APPROVAL_RESPONSE,
  UPDATE_PROJECT_APPROVAL_REQUEST,
} from './projectApprovalRequest.actions';
import { ProjectApprovalRequestCreateOrUpdatePayload } from './projectApprovalRequest.types';

const listPageUrl = `/settings/projectApprovalRequests`;

function* fetchProjectApprovalRequestSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchProjectApprovalRequest, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchProjectApprovalRequestListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchProjectApprovalRequestList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createProjectApprovalRequestSaga(action: AppAction) {
  try {
    const { values } = action.payload as ProjectApprovalRequestCreateOrUpdatePayload;
    const successMessage = `Created projectApprovalRequest successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createProjectApprovalRequest, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateProjectApprovalRequestSaga(action: AppAction) {
  try {
    const { values } = action.payload as ProjectApprovalRequestCreateOrUpdatePayload;
    const successMessage = `Updated projectApprovalRequest successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateProjectApprovalRequest, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateProjectApprovalResponseSaga(action: AppAction) {
  try {
    const successMessage = `Updated projectApprovalResponse successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateProjectApprovalResponse, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put(closeProjectApprovalRequestDialog());
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchProjectApprovalRequest() {
  yield takeLatest(FETCH_PROJECT_APPROVAL_REQUEST, fetchProjectApprovalRequestSaga);
  yield debounce(
    250,
    FETCH_PROJECT_APPROVAL_REQUEST_LIST,
    fetchProjectApprovalRequestListSaga,
  );
  yield takeLatest(CREATE_PROJECT_APPROVAL_REQUEST, createProjectApprovalRequestSaga);
  yield takeLatest(UPDATE_PROJECT_APPROVAL_REQUEST, updateProjectApprovalRequestSaga);
  yield takeLatest(UPDATE_PROJECT_APPROVAL_RESPONSE, updateProjectApprovalResponseSaga);
}
