import React from 'react';
import { fetchSalesTeamMemberList as fetchSalesTeamMemberListService } from '../../services/salesTeamMemberService';
import { SalesTeamMember } from '../../state/salesTeamMember';
import AsyncAutoComplete from './AsyncAutoComplete';

interface SalesTeamMemberComboBoxProps {
  handleChange: (result: SalesTeamMember) => void;
  selectedValue: SalesTeamMember;
  isDisabled?: boolean;
  error?: boolean;
  helperText?: string;
  salesTeamId: number;
}

const SalesTeamMemberComboBox: React.FC<SalesTeamMemberComboBoxProps> = props => {
  const {
    handleChange,
    selectedValue,
    isDisabled = false,
    error,
    helperText,
    salesTeamId,
  } = props;

  const salesTeamMemberPromiseOptions = async (inputValue: string) => {
    const { data: rows } = await fetchSalesTeamMemberListService({
      filters: {
        'SalesTeamId.EqualsTo': salesTeamId,
        'User.FullNameWithUserName.Contains': inputValue,
      },
      paging: { pageNumber: 0, pageSize: 15 },
    });

    return rows;
  };

  return (
    <AsyncAutoComplete
      name="SalesTeamMember"
      value={selectedValue}
      getOptionValue={(option: SalesTeamMember) => {
        return option.id!.toString();
      }}
      getOptionLabel={(option: SalesTeamMember) => {
        return option.user!.fullNameWithUserName!;
      }}
      promiseOptions={salesTeamMemberPromiseOptions}
      placeholder={'Type SalesTeamMember Name'}
      handleChange={handleChange}
      isDisabled={isDisabled}
      error={error}
      helperText={helperText}
    />
  );
};

export default SalesTeamMemberComboBox;
