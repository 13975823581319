import { ISubtaskItemArtTemplate } from '../../models/subtaskItemArtTemplate.model';
import { AppAction, PageFilter } from '../app';
import { SubtaskItemArtTemplateListFilter } from './subtaskItemArtTemplate.type';

export const FETCH_SUBTASK_ITEM_ARTTEMPLATE_LIST = 'FETCH_SUBTASK_ITEM_ARTTEMPLATE_LIST';
export const CREATE_SUBTASK_ITEM_ARTTEMPLATES = 'CREATE_SUBTASK_ITEM_ARTTEMPLATES';
export const DELETE_SUBTASK_ITEM_ARTTEMPLATES = 'DELETE_SUBTASK_ITEM_ARTTEMPLATES';
export const CLEAR_CREATED_SUBTASK_ITEM_ARTTEMPLATES =
  'CLEAR_CREATED_SUBTASK_ITEM_ARTTEMPLATES';

export const fetchSubtaskItemArtTemplateList = (
  filters: SubtaskItemArtTemplateListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SUBTASK_ITEM_ARTTEMPLATE_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createSubtaskItemArtTemplates = (payload: {
  subtaskItemArtTemplates: ISubtaskItemArtTemplate[];
  smSubtaskItemId: number;
}): AppAction => ({
  type: CREATE_SUBTASK_ITEM_ARTTEMPLATES,
  payload,
});

export const deleteSubtaskItemArtTemplates = (payload: {
  smSubtaskItemArtTemplateId: number;
  smSubtaskItemArtId: number;
}): AppAction => ({
  type: DELETE_SUBTASK_ITEM_ARTTEMPLATES,
  payload,
});

export const clearCreatedSubtaskItemArtTemplates = () => ({
  type: CLEAR_CREATED_SUBTASK_ITEM_ARTTEMPLATES,
});
