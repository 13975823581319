import { Grid } from '@material-ui/core';
import React from 'react';
import { TaskListFilter } from '../../../state/task';
import { FilterContainer, SearchField } from '../../components';
import { IFilterContainerConfigProps } from '../../components/FilterContainer';

const TaskFilter: React.FC<IFilterContainerConfigProps<TaskListFilter>> = props => {
  return (
    <FilterContainer
      {...props}
      render={filterProps => {
        const { localFilter, handleChangeFilter } = filterProps;
        return (
          <Grid container spacing={1} alignItems={'center'}>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Task Number"
                filterKey={'TaskNumber.startsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Requester"
                filterKey={'Requester.FullNameWithUserName.contains'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Subject"
                filterKey={'Subject.contains'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default TaskFilter;
