import { IEBlockGroupProduct } from '../../models/eBlockGroupProduct';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_EBLOCK_GROUP_PRODUCT,
  CREATE_EBLOCK_GROUP_PRODUCT,
  FETCH_EBLOCK_GROUP_PRODUCT,
  FETCH_EBLOCK_GROUP_PRODUCT_LIST,
  UPDATE_EBLOCK_GROUP_PRODUCT,
} from './eBlockGroupProduct.action';
import { EBlockGroupProductState } from './eBlockGroupProduct.type';

const initialState: EBlockGroupProductState = {
  errorMessage: '',
  item: {} as Readonly<IEBlockGroupProduct>,
  items: [] as ReadonlyArray<IEBlockGroupProduct>,
  loading: false,
  totalItems: 0,
};

export default (
  state: EBlockGroupProductState = initialState,
  action: AppAction,
): EBlockGroupProductState => {
  switch (action.type) {
    case REQUEST(FETCH_EBLOCK_GROUP_PRODUCT_LIST):
    case REQUEST(CREATE_EBLOCK_GROUP_PRODUCT):
    case REQUEST(FETCH_EBLOCK_GROUP_PRODUCT):
    case REQUEST(UPDATE_EBLOCK_GROUP_PRODUCT):
      return { ...state, loading: true };
    case SUCCESS(FETCH_EBLOCK_GROUP_PRODUCT_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_EBLOCK_GROUP_PRODUCT_LIST):
    case FAILURE(CREATE_EBLOCK_GROUP_PRODUCT):
    case FAILURE(FETCH_EBLOCK_GROUP_PRODUCT):
    case FAILURE(UPDATE_EBLOCK_GROUP_PRODUCT):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_EBLOCK_GROUP_PRODUCT):
    case SUCCESS(FETCH_EBLOCK_GROUP_PRODUCT):
    case SUCCESS(UPDATE_EBLOCK_GROUP_PRODUCT):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_EBLOCK_GROUP_PRODUCT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
