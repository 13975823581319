import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IOpportunity } from '../../../models/opportunity.model';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearOpportunityApprovalRequest,
  closeOpportunityApprovalRequestDialog,
  fetchOpportunityApprovalRequestList,
  openOpportunityApprovalRequestDialog,
  OpportunityApprovalRequest,
  OpportunityApprovalRequestListFilter,
  OpportunityApprovalRequestState,
} from '../../../state/opportunityApprovalRequest';
import { OpportunityApprovalRequestDialog } from './OpportunityApprovalRequestDialog';
import { PageContainer, PageTitle, TableLoading } from '../../components';
import { TablePageSize } from '../../../constants';
import OpportunityApprovalRequestFilter from './OpportunityApprovalRequestFilter';
import queryString from 'query-string';
import { useFilter } from '../../hooks';

const initialOpportunityApprovalRequestListFilter: OpportunityApprovalRequestListFilter =
  {
    'HasResponded.EqualsTo': 'false',
  };

const OpportunityApprovalRequestList: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useMemo(
    () => queryString.parse(history.location.search),
    [history],
  );
  const [filters, setFilters] = useFilter<OpportunityApprovalRequestListFilter>(
    initialOpportunityApprovalRequestListFilter,
  );
  const [opportunityDialog, setOpportunityDialog] = useState({
    opportunity: {} as IOpportunity,
    opportunityApprovalRequest: {} as OpportunityApprovalRequest,
  });

  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: TablePageSize[0],
  });

  const {
    items: opportunityApprovalRequests,
    totalItems,
    loading,
  } = useSelector<AppState, OpportunityApprovalRequestState>(
    (state) => state.opportunityApprovalRequest,
  );

  const handleChangeFilter = (newFilter: OpportunityApprovalRequestListFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    if (queryParams.assignmentNumber) {
      setFilters((filter) => {
        return {
          ...filter,
          'Opportunity.AssignmentNumber.startsWith':
            queryParams.assignmentNumber as string,
          'HasResponded.EqualsTo': '',
        };
      });
    }
  }, [setFilters, queryParams]);

  useEffect(() => {
    dispatch(fetchOpportunityApprovalRequestList(filters, paging));
    return () => {
      dispatch(clearOpportunityApprovalRequest());
    };
  }, [dispatch, paging, filters]);

  const handleCloseOpportunityDialog = () => {
    dispatch(fetchOpportunityApprovalRequestList(filters, paging));
    dispatch(closeOpportunityApprovalRequestDialog());
  };

  const handleOpenOpportunityDialog =
    (opportunity: IOpportunity, opportunityApprovalRequest: OpportunityApprovalRequest) =>
    () => {
      setOpportunityDialog({
        ...opportunityDialog,
        opportunity,
        opportunityApprovalRequest,
      });
      dispatch(openOpportunityApprovalRequestDialog());
    };

  return (
    <>
      <PageContainer>
        <PageTitle>Assignment Approval Request</PageTitle>
        <OpportunityApprovalRequestFilter
          filters={filters}
          onChangeFilter={handleChangeFilter}
          initialFilter={initialOpportunityApprovalRequestListFilter}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Assignment Number</TableCell>
              <TableCell>Program</TableCell>
              <TableCell>Brand</TableCell>
              <TableCell>Team</TableCell>
              <TableCell>Request By</TableCell>
              <TableCell>HasResponse</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={8}>
              {opportunityApprovalRequests.map((opportunityApprovalRequest, index) => (
                <TableRow key={opportunityApprovalRequest.id}>
                  <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                  <TableCell>
                    {opportunityApprovalRequest.opportunity.assignmentNumber}
                  </TableCell>
                  <TableCell>
                    {opportunityApprovalRequest.opportunity.program!.name}
                  </TableCell>
                  <TableCell>
                    {opportunityApprovalRequest.opportunity.brand!.name}
                  </TableCell>
                  <TableCell>
                    {/* {opportunityApprovalRequest.opportunity.salesTeam!.name} */}
                  </TableCell>
                  <TableCell>
                    {opportunityApprovalRequest.requestedByUser.fullNameWithUserName}
                  </TableCell>
                  <TableCell>
                    {opportunityApprovalRequest.hasResponded.toString()}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={handleOpenOpportunityDialog(
                        opportunityApprovalRequest.opportunity,
                        opportunityApprovalRequest,
                      )}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableLoading>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[...TablePageSize]}
          component="div"
          count={totalItems}
          rowsPerPage={paging.pageSize}
          page={paging.pageNumber}
          onPageChange={handleChangePageNumber}
          onRowsPerPageChange={handleChangePageSize}
        />
      </PageContainer>
      <OpportunityApprovalRequestDialog
        handleClose={handleCloseOpportunityDialog}
        opportunity={opportunityDialog.opportunity}
        opportunityApprovalRequest={opportunityDialog.opportunityApprovalRequest}
      />
    </>
  );
};

export default OpportunityApprovalRequestList;
