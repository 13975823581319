import { OpportunityStatusType } from '../constants/opportunityStatus';
import { AccountOwner } from '../state/accountOwner';
import { Brand } from '../state/brand';
import { Company } from '../state/company';
import { Program } from '../state/program';
import { SalesTeam } from '../state/salesTeam';
import { IProject } from './project.model';
import { IResponsibility } from './responsibility.model';

export interface IOpportunity {
  id?: number;
  companyId?: number;
  company?: Company | null;
  salesTeamId?: number;
  salesTeam?: SalesTeam | null;
  programId?: number;
  program?: Program | null;
  brandId?: number;
  brand?: Brand | null;
  assignmentNumber?: string;
  status?: OpportunityStatusType;
  createdAt?: string;
  createdBy?: string;
  accountOwner?: AccountOwner;
  accountOwnerId?: number;
  smProjectId?: number;
  smProject?: IProject;
  responsibilityId?: number;
  responsibility?: IResponsibility;
}

export const defaultValue: Readonly<IOpportunity> = {};
