import { ISubtaskItemArtTemplate } from '../../models/subtaskItemArtTemplate.model';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CREATE_SUBTASK_ITEM_ARTTEMPLATES,
  DELETE_SUBTASK_ITEM_ARTTEMPLATES,
  FETCH_SUBTASK_ITEM_ARTTEMPLATE_LIST,
  CLEAR_CREATED_SUBTASK_ITEM_ARTTEMPLATES,
} from './subtaskItemArtTemplate.actions';
import { SubtaskItemArtTemplateState } from './subtaskItemArtTemplate.type';

const initialState: SubtaskItemArtTemplateState = {
  errorMessage: '',
  item: {} as Readonly<ISubtaskItemArtTemplate>,
  items: [] as ISubtaskItemArtTemplate[],
  loading: false,
  totalItems: 0,
  createSubtaskItemArtTemplates: [] as ISubtaskItemArtTemplate[],
};

export default (
  state: SubtaskItemArtTemplateState = initialState,
  action: AppAction,
): SubtaskItemArtTemplateState => {
  switch (action.type) {
    case REQUEST(FETCH_SUBTASK_ITEM_ARTTEMPLATE_LIST):
    case REQUEST(CREATE_SUBTASK_ITEM_ARTTEMPLATES):
    case REQUEST(DELETE_SUBTASK_ITEM_ARTTEMPLATES):
      return { ...state, loading: true };
    case SUCCESS(FETCH_SUBTASK_ITEM_ARTTEMPLATE_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case SUCCESS(CREATE_SUBTASK_ITEM_ARTTEMPLATES):
      return {
        ...state,
        createSubtaskItemArtTemplates: action.payload.data,
        loading: false,
      };
    case SUCCESS(DELETE_SUBTASK_ITEM_ARTTEMPLATES):
      return { ...state, loading: false };
    case FAILURE(CREATE_SUBTASK_ITEM_ARTTEMPLATES):
    case FAILURE(DELETE_SUBTASK_ITEM_ARTTEMPLATES):
      return { ...state, loading: false };
    case FAILURE(FETCH_SUBTASK_ITEM_ARTTEMPLATE_LIST):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case CLEAR_CREATED_SUBTASK_ITEM_ARTTEMPLATES:
      return {
        ...state,
        createSubtaskItemArtTemplates: [],
      };
    default:
      return state;
  }
};
