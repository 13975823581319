import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { AuthState } from '../../../../state/auth';
import { AppState } from '../../../../state/configureStore';
import { TaskState } from '../../../../state/task';
import { createTaskLogWork, fetchTaskLogWorkList } from '../../../../state/taskLogWork';

interface ILogWorkDialogProps {
  open: boolean;
  onClose: () => void;
  subtaskId: number | null;
  taskId: number | null | undefined;
  title: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    workDescription: {
      marginTop: theme.spacing(2),
    },
  }),
);

const LogWorkDialogSchema = Yup.object().shape({
  duration: Yup.number()
    .lessThan(10000, 'Maximum is 10,000')
    .moreThan(0, 'Please add duration time')
    .required('Duration is required'),
  workDescription: Yup.string().required('Work description is required'),
});

const LogWorkDialog: React.FC<ILogWorkDialogProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, onClose, subtaskId, taskId, title } = props;
  const { userId } = useSelector<AppState, AuthState>(state => state.auth);
  const { item: task } = useSelector<AppState, TaskState>(state => state.task);
  const { taskNumber } = task;
  return (
    <Formik
      initialValues={{
        duration: 0,
        workDescription: '',
      }}
      validationSchema={LogWorkDialogSchema}
      onSubmit={(values, actions) => {
        const callback = () => {
          onClose();
          actions.resetForm();
          dispatch(
            fetchTaskLogWorkList(
            { 'SMTask.TaskNumber.startsWith': taskNumber },
              { pageNumber: 0, pageSize: 99 },
          ));
        };
        const submitValue = {
          duration: values.duration,
          workDescription: values.workDescription,
          smSubtaskId: subtaskId!,
          smTaskId: taskId!,
          userId,
        };
        dispatch(createTaskLogWork({ values: submitValue, callback }));
      }}
      enableReinitialize={true}
    >
      {({ isSubmitting, values, setFieldValue, errors, handleChange }) => {
        return (
          <Dialog open={open} fullWidth onClose={onClose}>
            <Form>
              <DialogTitle>{title}</DialogTitle>
              <DialogContent>
                <TextField
                  name="duration"
                  label="Duration (mins)"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  value={values.duration}
                  type="number"
                  error={!!errors.duration}
                  helperText={errors.duration}
                  onFocus={event => event.target.select()}
                />
                <TextField
                  name="workDescription"
                  className={classes.workDescription}
                  label="Work Description"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows="5"
                  onChange={handleChange}
                  value={values.workDescription}
                  error={!!errors.workDescription}
                  helperText={errors.workDescription}
                />
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={onClose}>
                  Close
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default LogWorkDialog;
