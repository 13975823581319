import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createCustomerGroup,
  fetchCustomerGroup,
  fetchCustomerGroupList,
  updateCustomerGroup,
} from '../../services/customerGroupService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_CUSTOMERGROUP,
  FETCH_CUSTOMERGROUP,
  FETCH_CUSTOMERGROUP_LIST,
  UPDATE_CUSTOMERGROUP,
} from './customerGroup.actions';
import { CustomerGroupCreateOrUpdatePayload } from './customerGroup.types';

const listPageUrl = `/customerGroups`;

function* fetchCustomerGroupSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchCustomerGroup, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchCustomerGroupListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchCustomerGroupList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createCustomerGroupSaga(action: AppAction) {
  try {
    const { values, history } = action.payload as CustomerGroupCreateOrUpdatePayload;
    const successMessage = `Created ${values.name} customerGroup successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createCustomerGroup, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    const formUrl = `${listPageUrl}/form/${data.id}`;
    history.push(formUrl);
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateCustomerGroupSaga(action: AppAction) {
  try {
    const { values } = action.payload as CustomerGroupCreateOrUpdatePayload;
    const successMessage = `Updated ${values.name} customerGroup successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateCustomerGroup, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchCustomerGroup() {
  yield takeLatest(FETCH_CUSTOMERGROUP, fetchCustomerGroupSaga);
  yield debounce(250, FETCH_CUSTOMERGROUP_LIST, fetchCustomerGroupListSaga);
  yield takeLatest(CREATE_CUSTOMERGROUP, createCustomerGroupSaga);
  yield takeLatest(UPDATE_CUSTOMERGROUP, updateCustomerGroupSaga);
}
