import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import history from '../../history';
import {
  createSampleDevelopmentTl,
  fetchSampleDevelopmentTl,
  fetchSampleDevelopmentTlList,
  submitSampleDevelopmentTlForApproval,
  updateSampleDevelopmentTl,
  downloadSampleDevelopmentTl,
} from '../../services/sampleDevelopmentTlService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_SAMPLE_DEVELOPMENT_TL,
  DOWNLOAD_SAMPLE_DEVELOPMENT_TL,
  FETCH_SAMPLE_DEVELOPMENT_TL,
  FETCH_SAMPLE_DEVELOPMENT_TL_LIST,
  SUBMIT_SAMPLE_DEVELOPMENT_TL_FOR_APPROVAL,
  UPDATE_SAMPLE_DEVELOPMENT_TL,
} from './sampleDevelopmentTl.actions';

const formPageUrl = `/sampleDevelopmentTl/form`;

function* fetchSampleDevelopmentTlSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchSampleDevelopmentTl, action.payload);
    if (data.salesTeamMember) {
      data.salesTeam = data.salesTeamMember.salesTeam;
    }
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchSampleDevelopmentTlListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSampleDevelopmentTlList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createSampleDevelopmentTlSaga(action: AppAction) {
  try {
    const { values } = action.payload;
    const successMessage = `Created SampleDevelopmentTl successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createSampleDevelopmentTl, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(`${formPageUrl}/${data.id}`);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateSampleDevelopmentTlSaga(action: AppAction) {
  try {
    const { values } = action.payload;
    const successMessage = `Updated SampleDevelopmentTl successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateSampleDevelopmentTl, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* submitSampleDevelopmentTlForApprovalSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const successMessage = `Submit SampleDevelopmentTl for Approval successfully.`;
    const { data } = yield call(submitSampleDevelopmentTlForApproval, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* downloadSampleDevelopmentTlSaga(action: AppAction) {
  try {
    const response = yield call(downloadSampleDevelopmentTl, action.payload);
    saveAs(response.data, 'SampleDevelopment.pdf');
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSalesTeam() {
  yield takeLatest(FETCH_SAMPLE_DEVELOPMENT_TL, fetchSampleDevelopmentTlSaga);
  yield debounce(250, FETCH_SAMPLE_DEVELOPMENT_TL_LIST, fetchSampleDevelopmentTlListSaga);
  yield takeLatest(CREATE_SAMPLE_DEVELOPMENT_TL, createSampleDevelopmentTlSaga);
  yield takeLatest(UPDATE_SAMPLE_DEVELOPMENT_TL, updateSampleDevelopmentTlSaga);
  yield takeLatest(
    SUBMIT_SAMPLE_DEVELOPMENT_TL_FOR_APPROVAL,
    submitSampleDevelopmentTlForApprovalSaga,
  );
  yield takeLatest(DOWNLOAD_SAMPLE_DEVELOPMENT_TL, downloadSampleDevelopmentTlSaga);
}
