import { CardContent, TextField } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { AuthState } from '../../../state/auth';
import { AppState } from '../../../state/configureStore';
import {
  clearSalesGroup,
  createSalesGroup,
  fetchSalesGroup,
  SalesGroup,
  SalesGroupState,
  updateSalesGroup,
} from '../../../state/salesGroup';
import {
  FixedPositionButtons,
  Loading,
  PageContainer,
  PageTitle,
} from '../../components';

const SalesGroupSchema = Yup.object().shape({
  name: Yup.string()
    .strict(true)
    .trim()
    .max(6, 'Name is exceeded character limit(6)')
    .required('Name is required'),
  description: Yup.string().nullable(true),
  ownerId: Yup.number().required('Owner is required'),
});

interface SalesGroupFormRouteParamsProps {
  id: string;
}

interface SalesGroupFormProps
  extends RouteComponentProps<SalesGroupFormRouteParamsProps> {}

const SalesGroupForm: React.FC<SalesGroupFormProps> = props => {
  const dispatch = useDispatch();
  const { match, history } = props;
  const {
    params: { id: paramsId },
  } = match;
  const isUpdateMode = paramsId !== undefined;
  const { user, loading: authIsLoading } = useSelector<AppState, AuthState>(
    state => state.auth,
  );
  const { item: salesGroup, loading: salesGroupIsLoading } = useSelector<
    AppState,
    SalesGroupState
  >(state => state.salesGroup);

  const redirectToSalesGroupList = () => {
    history.goBack();
  };

  const handleSubmit = (values: SalesGroup, actions: FormikHelpers<SalesGroup>) => {
    if (isUpdateMode) {
      dispatch(updateSalesGroup({ values, history }));
    } else {
      dispatch(createSalesGroup({ values, history }));
    }
    actions.setSubmitting(false);
  };

  useEffect(() => {
    if (isUpdateMode) {
      dispatch(fetchSalesGroup(+paramsId));
    } else {
      dispatch(clearSalesGroup());
    }
    return function cleanUp() {
      dispatch(clearSalesGroup());
    };
  }, [isUpdateMode, paramsId, dispatch]);

  const defaultValues: SalesGroup = { owner: user, ownerId: user ? user.id : undefined };
  const isLoading = authIsLoading || salesGroupIsLoading;
  return (
    <PageContainer>
      <Loading isLoading={isLoading}>
        <Formik
          enableReinitialize={true}
          initialValues={{ ...defaultValues, ...salesGroup }}
          validationSchema={SalesGroupSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, errors, touched, submitForm }) => {
            return (
              <Form>
                <PageTitle>
                  {!isUpdateMode
                    ? `New Sales Group`
                    : `Update Sales Group ${salesGroup.name}`}
                </PageTitle>
                <CardContent>
                  <TextField
                    name="name"
                    type="input"
                    label="Name"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={values.name || ''}
                    onChange={handleChange}
                    error={!!errors.name && !!touched.name}
                    helperText={errors.name}
                    InputProps={{
                      readOnly: isUpdateMode,
                    }}
                  />
                  <TextField
                    name="description"
                    type="input"
                    label="Description"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={values.description || ''}
                    onChange={handleChange}
                    error={!!errors.description && !!touched.description}
                    helperText={errors.description}
                  />
                  <TextField
                    name="owner"
                    type="input"
                    label="Owner"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={
                      values.owner
                        ? `${values.owner!!.firstName} ${values.owner!!.lastName}`
                        : ''
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </CardContent>
                <FixedPositionButtons
                  actions={[
                    {
                      name: !isUpdateMode ? `Create` : `Update`,
                      onClick: () => {
                        submitForm();
                      },
                      color: 'primary' as 'primary',
                      disabled: false,
                      icon: 'save',
                    },
                    {
                      name: 'CANCEL',
                      onClick: redirectToSalesGroupList,
                      color: 'default' as 'default',
                      disabled: false,
                    },
                  ]}
                  open
                />
              </Form>
            );
          }}
        </Formik>
      </Loading>
    </PageContainer>
  );
};

export default SalesGroupForm;
