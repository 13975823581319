import {
  Button,
  FormControl,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import {
  Add as AddIcon,
  FindReplace as FindReplaceIcon,
  ToggleOff as ToggleOffIcon,
  Save as SaveAsIcon,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../state/configureStore';
import {
  inactiveResponsibilityWorkingTeamMember,
  ResponsibilityWorkingTeamMemberState,
  setResponsibilityWorkingTeamMember,
  updateResponsibilityWorkingTeamMember,
} from '../../../../state/responsibilityWorkingTeamMember';
import { DateDisplay } from '../../../components';
import { IResponsibilityWorkingTeamMember } from '../../../../models/responsibilityWorkingTeamMember.model';
import ResponsibilityWorkingTeamMemberReplaceDialog from './ResponsibilityWorkingTeamMemberReplaceDialog';
import AddResponsibilityWorkingTeamMemberDialog from './AddResponsibilityWorkingTeamMemberDialog';

interface ResponsibilityWorkingTeamMemberFormProps {
  responsibilityId?: number;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  addButton: {
    position: 'absolute',
    right: '5px',
    top: '20px',
  },
  container: {
    marginTop: '20px',
    position: 'relative',
  },
  table: {
    marginTop: '30px',
    position: 'static',
  },
  showAll: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
  },
  button: {
    fontSize: 10,
  },
  title: {
    position: 'relative',
    left: '5px',
    top: '25px',
  },
}));

const ResponsibilityWorkingTeamMemberFormSSO: React.FC<
  ResponsibilityWorkingTeamMemberFormProps
> = (props) => {
  const { responsibilityId, disabled } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const { items: responsibilityWorkingTeamMembers } = useSelector<
    AppState,
    ResponsibilityWorkingTeamMemberState
  >((state) => state.responsibilityWorkingTeamMember);
  const [
    openAddResponsibilityWorkingTeamMemberDialog,
    setOpenAddResponsibilityWorkingTeamMemberDialog,
  ] = useState(false);
  const [
    openResponsibilityWorkingTeamMemberReplaceDialog,
    setOpenResponsibilityWorkingTeamMemberReplaceDialog,
  ] = useState(false);

  const [
    selectedResponsibilityWorkingTeamMember,
    setSelectedResponsibilityWorkingTeamMember,
  ] = useState<IResponsibilityWorkingTeamMember>({});

  const [showAll, setShowAll] = useState(false);

  const handleClose = () => {
    setOpenAddResponsibilityWorkingTeamMemberDialog(false);
  };

  const handleCloseReplace = () => {
    setOpenResponsibilityWorkingTeamMemberReplaceDialog(false);
  };

  const addNewWorkingTeamMember = () => {
    setOpenAddResponsibilityWorkingTeamMemberDialog(true);
  };

  const ReplaceWorkingTeamMember = (selectedRow: IResponsibilityWorkingTeamMember) => {
    setOpenResponsibilityWorkingTeamMemberReplaceDialog(true);
    setSelectedResponsibilityWorkingTeamMember(selectedRow);
  };

  return (
    <>
      <div className={classes.container}>
        <Typography className={classes.title} variant="h6" component="span">
          SSO
        </Typography>
        <Typography className={classes.title} component="span" color="secondary">
          &nbsp;* (Please select SSO)
        </Typography>
        <div className={classes.addButton}>
          <Button
            variant="contained"
            color="primary"
            onClick={addNewWorkingTeamMember}
            startIcon={<AddIcon />}
            disabled={disabled}
          >
            New SSO
          </Button>
        </div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Group</TableCell>
              <TableCell>Team</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Active Date</TableCell>
              <TableCell>Inactive Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {responsibilityWorkingTeamMembers
              .filter((responsibilityWorkingTeamMember) => {
                if (!showAll) {
                  return responsibilityWorkingTeamMember.active === true;
                }
                return responsibilityWorkingTeamMember;
              })
              .filter(
                (respwtm) =>
                  respwtm.smWorkingTeamMember?.smWorkingTeam?.smWorkingGroup?.name ===
                  'SSO',
              )
              .sort(
                (a, b) =>
                  a.smWorkingTeamMember?.smWorkingTeam?.name?.localeCompare(
                    b.smWorkingTeamMember?.smWorkingTeam?.name || '',
                  ) || 0,
              )
              .map((responsibilityWorkingTeamMember, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {
                        responsibilityWorkingTeamMember?.smWorkingTeamMember
                          ?.smWorkingTeam?.smWorkingGroup?.name
                      }
                    </TableCell>
                    <TableCell>
                      <Link
                        href="#"
                        onClick={() => {
                          //   setSelectedWorkingTeamMember(
                          //     responsibilityWorkingTeamMember?.smWorkingTeamMember
                          //       ?.smWorkingTeam || null,
                          //   );
                        }}
                      >
                        {
                          responsibilityWorkingTeamMember?.smWorkingTeamMember
                            ?.smWorkingTeam?.name
                        }
                      </Link>
                    </TableCell>
                    <TableCell>
                      {
                        responsibilityWorkingTeamMember?.smWorkingTeamMember?.user
                          ?.fullNameWithUserName
                      }
                    </TableCell>
                    <TableCell>
                      <DateDisplay value={responsibilityWorkingTeamMember?.activeDate} />
                    </TableCell>
                    <TableCell>
                      <DateDisplay
                        value={responsibilityWorkingTeamMember?.inactiveDate}
                      />
                    </TableCell>
                    <TableCell>
                      <FormControl>
                        <InputLabel>Status</InputLabel>
                        <Select
                          disabled={disabled}
                          style={{ width: '100px' }}
                          labelId="status"
                          id="status"
                          value={responsibilityWorkingTeamMember?.status}
                          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                            const respMembers = responsibilityWorkingTeamMembers.map(
                              (item) => {
                                if (item.id === responsibilityWorkingTeamMember.id) {
                                  item.status = event.target.value as string;
                                }
                                return item;
                              },
                            );
                            dispatch(setResponsibilityWorkingTeamMember(respMembers));
                          }}
                        >
                          <MenuItem value={'P'}>SSO</MenuItem>
                          <MenuItem value={'S1'}>SSO1</MenuItem>
                          <MenuItem value={'S2'}>SSO2</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <Button
                        className={classes.button}
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          dispatch(
                            updateResponsibilityWorkingTeamMember({
                              values: responsibilityWorkingTeamMember,
                            }),
                          );
                        }}
                        startIcon={<SaveAsIcon />}
                        disabled={disabled}
                      >
                        Update
                      </Button>
                      <Button
                        className={classes.button}
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          ReplaceWorkingTeamMember(responsibilityWorkingTeamMember!);
                        }}
                        startIcon={<FindReplaceIcon />}
                        disabled={disabled}
                      >
                        Replace
                      </Button>
                      <Button
                        className={classes.button}
                        color="secondary"
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          dispatch(
                            inactiveResponsibilityWorkingTeamMember(
                              responsibilityWorkingTeamMember.id!,
                            ),
                          );
                        }}
                        startIcon={<ToggleOffIcon />}
                        disabled={disabled}
                      >
                        Inactive
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <div className={classes.showAll}>
          <Button
            color="primary"
            size={'small'}
            onClick={() => {
              setShowAll((previousState) => !previousState);
            }}
          >
            {showAll ? 'show less' : 'show all'}
          </Button>
        </div>
      </div>

      <ResponsibilityWorkingTeamMemberReplaceDialog
        open={openResponsibilityWorkingTeamMemberReplaceDialog}
        onClose={handleCloseReplace}
        responsibilityWorkingTeamMember={selectedResponsibilityWorkingTeamMember}
      />

      <AddResponsibilityWorkingTeamMemberDialog
        open={openAddResponsibilityWorkingTeamMemberDialog}
        handleClose={handleClose}
        responsibilityId={responsibilityId!}
        workingTeam="SSO"
      />
    </>
  );
};

export default ResponsibilityWorkingTeamMemberFormSSO;
