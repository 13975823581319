import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IForecast, IForecastWithAddedProps } from '../../../models/forecast.model';
import { ITaskItemWithForecasts } from '../../../models/taskItem.model';
import { AppState } from '../../../state/configureStore';
import { TaskCreateState } from '../../../state/taskCreate';
import ForecastTable from './ForecastTable';

interface TaskItemForecastEditDialogProps {
  open: boolean;
  taskItemToEditForecast: ITaskItemWithForecasts;
  onClose: () => void;
  onSubmit: (taskItemWithForecasts: ITaskItemWithForecasts) => void;
}

const TaskItemForecastEditDialog: React.FC<TaskItemForecastEditDialogProps> = props => {
  const { open, taskItemToEditForecast, onClose, onSubmit } = props;
  const [forecasts, setForecasts] = useState<IForecastWithAddedProps[]>([]);
  const { selectedUnitOfQuantity } = useSelector<AppState, TaskCreateState>(
    state => state.taskCreate,
  );

  const defaultNewRowValue = useMemo<IForecast>(() => {
    return {
      quantity: '',
      unitOfQuantity: selectedUnitOfQuantity,
      frequency: '',
    };
  }, [selectedUnitOfQuantity]);

  const handleUpdate = (createForecastList: IForecast[]) => {
    const mapCreateForecastList = createForecastList.map(forecast => ({
      ...forecast,
      added: true,
    }));
    setForecasts(mapCreateForecastList);
    const editSelectedTaskItemForecast = {
      ...taskItemToEditForecast,
      forecasts: mapCreateForecastList,
    };
    onSubmit(editSelectedTaskItemForecast);
  };

  const handleClose = () => {
    setForecasts([]);
    onClose();
  };

  useEffect(() => {
    if (taskItemToEditForecast.forecasts) {
      setForecasts(taskItemToEditForecast.forecasts!);
    }
  }, [taskItemToEditForecast, open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        {taskItemToEditForecast.opportunityItem
          ? taskItemToEditForecast.opportunityItem!.block!.blockNo
          : ''}{' '}
        Forecast
      </DialogTitle>
      <DialogContent>
        <ForecastTable
          defaultNewRowValue={defaultNewRowValue}
          onUpdate={handleUpdate}
          forecastList={forecasts}
        />
      </DialogContent>
      <DialogActions>
        <Button type="button" variant="contained" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaskItemForecastEditDialog;
