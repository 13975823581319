import {
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { IRole } from '../../../models/role.model';
import { AuthState } from '../../../state/auth';
import { AppState } from '../../../state/configureStore';
import { fetchAllRoleList, RoleState } from '../../../state/role';
import { User } from '../../../state/user';
import {
  clearWorkingGroup,
  createWorkingGroup,
  fetchWorkingGroup,
  updateWorkingGroup,
  WorkingGroup,
  WorkingGroupState,
} from '../../../state/workingGroup';
import {
  AutoComplete,
  FixedPositionButtons,
  Loading,
  PageContainer,
  PageTitle,
} from '../../components';
import UserComboBox from '../../components/UserComboBox';

interface WorkingGroupFormRouteParamsProps {
  id: string;
}

interface WorkingGroupFormProps
  extends RouteComponentProps<WorkingGroupFormRouteParamsProps> {}

const WorkingGroupSchema = Yup.object().shape({
  name: Yup.string()
    .strict(true)
    .trim()
    // .max(6, 'Name is exceeded character limit(6)')
    .required('Name is required'),
  description: Yup.string().nullable(true),
  ownerId: Yup.number().required('Owner is required'),
  roleId: Yup.number().required('Role is required'),
});

const WorkingGroupForm: React.FC<WorkingGroupFormProps> = (props) => {
  const dispatch = useDispatch();
  const { match, history } = props;
  const {
    params: { id: paramsId },
  } = match;
  const isUpdateMode = paramsId !== undefined;

  const { user, loading: authIsLoading } = useSelector<AppState, AuthState>(
    (state) => state.auth,
  );

  const { items: roles } = useSelector<AppState, RoleState>((state) => state.role);
  const roleList = roles.filter(
    (role) => role.name === 'CS' || role.name === 'NPD' || role.name === 'SSO',
  );

  const { item: workingGroup, loading: workingGroupIsLoading } = useSelector<
    AppState,
    WorkingGroupState
  >((state) => state.workingGroup);

  const isLoading = authIsLoading || workingGroupIsLoading;
  const defaultValues: WorkingGroup = {
    owner: user,
    ownerId: user ? user.id : undefined,
    name: '',
    description: '',
    roleId: 0,
  };

  const redirectToWorkingGroupList = () => {
    history.goBack();
  };

  const handleSubmit = (values: WorkingGroup, actions: FormikHelpers<WorkingGroup>) => {
    if (isUpdateMode) {
      dispatch(updateWorkingGroup({ values, history }));
    } else {
      dispatch(createWorkingGroup({ values, history }));
    }
    actions.setSubmitting(false);
  };

  useEffect(() => {
    dispatch(fetchAllRoleList());

    if (isUpdateMode) {
      dispatch(fetchWorkingGroup(+paramsId));
    } else {
      dispatch(clearWorkingGroup());
    }

    return function cleanUp() {
      dispatch(clearWorkingGroup());
    };
  }, [isUpdateMode, paramsId, dispatch]);

  return (
    <PageContainer disableOverflowX={true}>
      <Loading isLoading={isLoading}>
        <Formik
          enableReinitialize={true}
          initialValues={{ ...defaultValues, ...workingGroup }}
          validationSchema={WorkingGroupSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, errors, touched, submitForm, setFieldValue }) => {
            return (
              <Form>
                <PageTitle>
                  {!isUpdateMode ? `New Working Group` : `Update Working Group `}
                </PageTitle>
                <CardContent>
                  <TextField
                    name="name"
                    type="input"
                    label="Name"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={values.name || ''}
                    onChange={handleChange}
                    error={!!errors.name && !!touched.name}
                    helperText={errors.name}
                    InputProps={{
                      readOnly: isUpdateMode,
                    }}
                  />
                  <TextField
                    name="description"
                    type="input"
                    label="Description"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={values.description || ''}
                    onChange={handleChange}
                    error={!!errors.description && !!touched.description}
                    helperText={errors.description}
                  />

                  <AutoComplete
                    name={'roleId'}
                    placeholder={'Role'}
                    options={roleList}
                    handleChange={(value: IRole) => {
                      setFieldValue('role', value ? value : undefined);
                      setFieldValue('roleId', value ? value.id : undefined);
                    }}
                    getOptionLabel={(option: IRole) => option.name!}
                    getOptionValue={(option: IRole) => '' + option.id!}
                    value={values.role}
                  />
                  <UserComboBox
                    handleChange={(value: User) => {
                      setFieldValue('owner', value ? value : undefined);
                      setFieldValue('ownerId', value ? value.id : undefined);
                    }}
                    selectedValue={values.owner as User}
                    label={'Owner'}
                  />
                </CardContent>
                <FixedPositionButtons
                  actions={[
                    {
                      name: !isUpdateMode ? `Create` : `Update`,
                      onClick: () => {
                        submitForm();
                      },
                      color: 'primary' as 'primary',
                      disabled: false,
                      icon: 'save',
                    },
                    {
                      name: 'CANCEL',
                      onClick: redirectToWorkingGroupList,
                      color: 'default' as 'default',
                      disabled: false,
                    },
                  ]}
                  open
                />
              </Form>
            );
          }}
        </Formik>
      </Loading>
    </PageContainer>
  );
};

export default WorkingGroupForm;
