import { AppAction } from '../../../state/app';
import { ProgressBarState } from './progressBar.types';
import { isPendingAction } from '../../../utils/actionTypeUtil';

const initialState: ProgressBarState = {
  display: 'block',
};

export default (
  state: ProgressBarState = initialState,
  action: AppAction,
): ProgressBarState => {
  switch (isPendingAction(action.type)) {
    case true:
      return { ...state, display: 'block' };
    case false:
      return { ...state, display: 'none' };
    default:
      return state;
  }
};
