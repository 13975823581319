import { AppAction, PageFilter } from '../app';
import {
  ResponsibilityApprovalRequest,
  ResponsibilityApprovalRequestCreateOrUpdatePayload,
  ResponsibilityApprovalRequestListFilter,
  ResponsibilityApprovalResponse,
} from './responsibilityApprovalRequest.types';

export const FETCH_RESPONSIBILITY_APPROVAL_REQUEST =
  'FETCH_RESPONSIBILITY_APPROVAL_REQUEST';
export const FETCH_RESPONSIBILITY_APPROVAL_REQUEST_LIST =
  'FETCH_RESPONSIBILITY_APPROVAL_REQUEST_LIST';
export const CREATE_RESPONSIBILITY_APPROVAL_REQUEST =
  'CREATE_RESPONSIBILITY_APPROVAL_REQUEST';
export const UPDATE_RESPONSIBILITY_APPROVAL_REQUEST =
  'UPDATE_RESPONSIBILITY_APPROVAL_REQUEST';
export const CLEAR_RESPONSIBILITY_APPROVAL_REQUEST =
  'CLEAR_RESPONSIBILITY_APPROVAL_REQUEST';
export const UPDATE_RESPONSIBILITY_APPROVAL_RESPONSE =
  'UPDATE_RESPONSIBILITY_APPROVAL_RESPONSE';
export const OPEN_RESPONSIBILITY_APPROVAL_REQUEST_DIALOG =
  'OPEN_RESPONSIBILITY_APPROVAL_REQUEST_DIALOG';
export const CLOSE_RESPONSIBILITY_APPROVAL_REQUEST_DIALOG =
  'CLOSE_RESPONSIBILITY_APPROVAL_REQUEST_DIALOG';

export const fetchResponsibilityApprovalRequest = (
  id: ResponsibilityApprovalRequest['id'],
): AppAction => ({
  type: FETCH_RESPONSIBILITY_APPROVAL_REQUEST,
  payload: id,
});

export const fetchResponsibilityApprovalRequestList = (
  filters: ResponsibilityApprovalRequestListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_RESPONSIBILITY_APPROVAL_REQUEST_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchResponsibilityApprovalRequestListByresponsibilityId = (
  projectId: number,
): AppAction => ({
  type: FETCH_RESPONSIBILITY_APPROVAL_REQUEST_LIST,
  payload: {
    filters: { 'ResponsibilityId.equalsTo': projectId },
    paging: { pageNumber: 0, pageSize: 99999 },
  },
});

export const createProjectApprovalRequest = (
  payload: ResponsibilityApprovalRequestCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_RESPONSIBILITY_APPROVAL_REQUEST,
  payload,
});

export const updateProjectApprovalRequest = (
  payload: ResponsibilityApprovalRequestCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_RESPONSIBILITY_APPROVAL_REQUEST,
  payload,
});

export const clearResponsibilityApprovalRequest = () => ({
  type: CLEAR_RESPONSIBILITY_APPROVAL_REQUEST,
});

export const updateResponsibilityApprovalResponse = (
  payload: ResponsibilityApprovalResponse,
) => ({
  type: UPDATE_RESPONSIBILITY_APPROVAL_RESPONSE,
  payload,
});

export const openResponsibilityApprovalRequestDialog = () => ({
  type: OPEN_RESPONSIBILITY_APPROVAL_REQUEST_DIALOG,
});
export const closeResponsibilityApprovalRequestDialog = () => ({
  type: CLOSE_RESPONSIBILITY_APPROVAL_REQUEST_DIALOG,
});
