import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  fetchSampleDevelopmentTlApprovalRequestList,
  updateSampleDevelopmentTlApprovalRequesResponse,
} from '../../services/sampleDevelopmentTlApprovalRequestService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  closeSampleDevelopmentTlApprovalRequestDialog,
  FETCH_SAMPLE_DEV_TL_APPROVAL_REQUEST_LIST,
  UPDATE_SAMPLE_DEV_TL_APPROVAL_REQUEST_RESPONSE,
} from './sampleDevelopmentTlApprovalRequest.actions';

function* fetchSasmpleDevelopmentTlApprovalRequestListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(
      fetchSampleDevelopmentTlApprovalRequestList,
      action.payload,
    );
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateSasmpleDevelopmentTlApprovalRequestResponseSaga(action: AppAction) {
  try {
    const successMessage = `Updated sample development tl approval request successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(
      updateSampleDevelopmentTlApprovalRequesResponse,
      action.payload,
    );
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put(closeSampleDevelopmentTlApprovalRequestDialog());
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSampleDevelopmentTlApprovalRequest() {
  yield debounce(
    250,
    FETCH_SAMPLE_DEV_TL_APPROVAL_REQUEST_LIST,
    fetchSasmpleDevelopmentTlApprovalRequestListSaga,
  );
  yield takeLatest(
    UPDATE_SAMPLE_DEV_TL_APPROVAL_REQUEST_RESPONSE,
    updateSasmpleDevelopmentTlApprovalRequestResponseSaga,
  );
}
