import React from 'react';
import { Grid, Fade } from '@material-ui/core';
import ReactLoading from 'react-loading';

export interface LoadingProps {
  loading: boolean;
  height?: number;
}

const Loading: React.FC<LoadingProps> = (props) => {
  const { loading, height = 300 } = props;
  return loading ? (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: height }}
    >
      <ReactLoading type="bubbles" color="#3F51B5" />
    </Grid>
  ) : (
    <Fade in={true}>
      <div>{props.children}</div>
    </Fade>
  );
};

export default Loading;
