import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ResponsibilityStatus } from '../../../constants';
import { ResponsibilityStatusType } from '../../../constants/responsibilityStatus';
import { IResponsibility } from '../../../models/responsibility.model';
import { IResponsibilityApprovalRequest } from '../../../models/responsibilityApprovalRequest.model';
import { AppState } from '../../../state/configureStore';
import {
  ResponsibilityApprovalRequest,
  ResponsibilityApprovalRequestState,
} from '../../../state/responsibilityApprovalRequest';
import { ResponsibilityForm } from '../responsibility';
import ConfirmDialog from './ConfirmDialog';
import useStyles from './ResponsibilityApprovalRequestDialog.style';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

interface ResponsibilityApprovalRequestDialogProps {
  handleClose: () => void;
  responsibility: IResponsibility;
  responsibilityApprovalRequest: IResponsibilityApprovalRequest;
}

export const ResponsibilityApprovalRequestDialog: React.FC<
  ResponsibilityApprovalRequestDialogProps
> = (props) => {
  const { handleClose, responsibility, responsibilityApprovalRequest } = props;
  const { openDialog } = useSelector<AppState, ResponsibilityApprovalRequestState>(
    ({ responsibilityApprovalRequest }) => responsibilityApprovalRequest,
  );
  const [confirmDialog, setConfirmDialog] = useState<{
    open: boolean;
    status: ResponsibilityStatusType;
  }>({ open: false, status: ResponsibilityStatus.REJECTED });

  const classes = useStyles(props);
  const handleCloseConfirmDialog = () => {
    setConfirmDialog({ ...confirmDialog, open: false });
  };
  return (
    <>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Responsibility Approval
            </Typography>
            <div className={classes.approveButtonGroup}>
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: '#7cb342' }}
                onClick={() =>
                  setConfirmDialog({ open: true, status: ResponsibilityStatus.APPROVED })
                }
              >
                Approve
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() =>
                  setConfirmDialog({ open: true, status: ResponsibilityStatus.REJECTED })
                }
              >
                Reject
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <ResponsibilityForm responsibilityId={responsibility.id} />
      </Dialog>
      <ConfirmDialog
        open={confirmDialog.open}
        handleClose={handleCloseConfirmDialog}
        responseResult={confirmDialog.status}
        responsibilityApprovalRequest={responsibilityApprovalRequest}
      />
    </>
  );
};
