import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './sampleDevelopmentNl.style';
import {
  CheckBoxFastField,
  TextFieldFastField,
  UnitOfQuantitiesFastField,
  UnitOfMeasurementsFastField,
} from '../../../components';

interface SampleDevelopmentNlPackingProductProps {}

const SampleDevelopmentNlPackingProduct: React.FC<
  SampleDevelopmentNlPackingProductProps
> = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} direction="row" justifyContent="flex-start">
      <Typography className={classes.title}>Product Packing</Typography>
      <Grid item xs={12}>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={12} sm={2}>
            <CheckBoxFastField name="prodPackingRoll" label="Roll" />
          </Grid>
          <Grid item xs={12} sm={2}>
            <CheckBoxFastField name="prodPackingCut" label="Cut" />
          </Grid>
          <Grid item xs={12} sm={2}>
            <CheckBoxFastField name="prodPackingSheet" label="Sheet" />
          </Grid>
          <Grid item xs={12} sm={2}>
            <CheckBoxFastField name="prodPackingFold" label="Fold" />
          </Grid>
          <Grid item xs={12} sm={2}>
            <CheckBoxFastField name="prodPackingContinuous" label="Continuous" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={12} sm={3}>
            <TextFieldFastField
              type="number"
              step="any"
              name="labelPerRoll"
              label="Label Per Roll"
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <UnitOfQuantitiesFastField
              name="labelPerRollUnit"
              label="Unit"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextFieldFastField
              type="number"
              step="any"
              name="maxRollDiameter"
              label="Max Roll Diameter"
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <UnitOfMeasurementsFastField name="maxRollDiameterUnit" label="Unit" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextFieldFastField
              type="number"
              step="any"
              name="coreDiameter"
              label="Core Diameter"
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <UnitOfMeasurementsFastField name="coreDiameterUnit" label="Unit" />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={12} sm={2}>
            <CheckBoxFastField name="maxSplice" label="Max Splice" />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextFieldFastField
              name="maxSpliceData"
              label="Max Splice"
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <CheckBoxFastField name="spliceRequire" label="Splice Requirement" />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextFieldFastField
              name="spliceRequireData"
              label="Splice Requirement"
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <CheckBoxFastField name="spliceTape" label="Splice Tape Color/Width(mm)" />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextFieldFastField
              name="spliceTapeData"
              label="Splice Tape Color/Width(mm)"
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SampleDevelopmentNlPackingProduct;
