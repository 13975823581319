import React, { useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../state/configureStore';
import {
  OpportunityItemState,
  updateOpportunityItem,
} from '../../../../state/opportunityItem';
import { BlockComboBox, Loading } from '../../../components';
import { Form, Formik, FormikHelpers } from 'formik';
import { Block } from '../../../../state/block';
import UserComboBox from '../../../components/UserComboBox';
import { User } from '../../../../state/user';
import * as Yup from 'yup';
import { IOpportunityItem } from '../../../../models/opportunityItem.model';

interface IEditOpportunityItemDialogProps {
  onClose: () => void;
  open: boolean;
}

interface IEditOpportunityItemFormValue {
  user?: User;
  block?: Block;
}

export const EditOpportunityItemFormSchema = Yup.object().shape({
  user: Yup.object()
    .nullable(true)
    .required('User is required'),
  block: Yup.object()
    .nullable(true)
    .required('Block is required'),
});

const EditOpportunityItemDialog: React.FC<IEditOpportunityItemDialogProps> = props => {
  const dispatch = useDispatch();
  const { item: opportunityItem, loading } = useSelector<AppState, OpportunityItemState>(
    state => state.opportunityItem,
  );
  const { onClose, open } = props;

  const initialFormValue: IEditOpportunityItemFormValue = useMemo(() => {
    const isUseNpd = !!opportunityItem.npdUserId;
    return {
      block: opportunityItem.block!,
      user: isUseNpd ? opportunityItem.npdUser! : opportunityItem.sampleDevUser!,
    };
  }, [opportunityItem]);

  const handleFormSubmit = (
    values: IEditOpportunityItemFormValue,
    actions: FormikHelpers<IEditOpportunityItemFormValue>,
  ) => {
    const isUseNpd = !!opportunityItem.npdUserId;
    const newOpportunityItem: IOpportunityItem = {
      ...opportunityItem,
      blockId: values.block!.id,
    };
    newOpportunityItem[isUseNpd ? 'npdUserId' : 'sampleDevUserId'] = values.user!.id;
    dispatch(
      updateOpportunityItem(newOpportunityItem, () => actions.setSubmitting(false)),
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>Edit Assignment Item</DialogTitle>
      <Loading isLoading={loading}>
        <Formik
          enableReinitialize={true}
          validateOnChange={true}
          validationSchema={EditOpportunityItemFormSchema}
          initialValues={initialFormValue}
          onSubmit={handleFormSubmit}
        >
          {formProps => {
            const { values, errors, setFieldValue } = formProps;
            const { block, user } = values;
            return (
              <Form>
                <DialogContent>
                  <BlockComboBox
                    handleChange={(value: Block) => {
                      setFieldValue('block', value);
                    }}
                    brandId={opportunityItem.opportunity!.brandId}
                    programId={opportunityItem.opportunity!.programId}
                    selectedValue={block as Block}
                    error={!!errors.block}
                    helperText={errors.block}
                  />
                  <UserComboBox
                    handleChange={(value: User) => {
                      setFieldValue('user', value);
                    }}
                    selectedValue={user as User}
                    error={!!errors.user}
                    helperText={errors.user}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose}>Close</Button>
                  <Button color={'primary'} type={'submit'}>
                    Save
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Loading>
    </Dialog>
  );
};

export default EditOpportunityItemDialog;
