import { IEBlockMaterial } from '../models/eBlockMaterial.model';
import { PageFilter } from '../state/app';
import { EBlockMaterialListFilter } from '../state/eBlockMaterial';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/eBlockMaterials`;
export const fetchEBlockMaterialList = async ({
  filters,
  paging,
}: {
  filters: EBlockMaterialListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchEBlockMaterial = async (id: IEBlockMaterial['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createEBlockMaterial = async (material: IEBlockMaterial) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, material);
};

export const updateEBlockMaterial = async (material: IEBlockMaterial) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, material);
};
