import { AppAction, PageFilter, AppCallback } from '../app';
import {
  BrandRequestItem,
  BrandRequestItemListFilter,
} from './brandRequestItem.types';

export const FETCH_BRANDREQUESTITEM = 'FETCH_BRANDREQUESTITEM';
export const FETCH_BRANDREQUESTITEM_LIST = 'FETCH_BRANDREQUESTITEM_LIST';
export const CREATE_BRANDREQUESTITEM = 'CREATE_BRANDREQUESTITEM';
export const UPDATE_BRANDREQUESTITEM = 'UPDATE_BRANDREQUESTITEM';
export const DELETE_BRANDREQUESTITEM = 'DELETE_BRANDREQUESTITEM';
export const CLEAR_BRANDREQUESTITEM = 'CLEAR_BRANDREQUESTITEM';

export const fetchBrandRequestItem = (id: BrandRequestItem['id']): AppAction => ({
  type: FETCH_BRANDREQUESTITEM,
  payload: id,
});

export const fetchBrandRequestItemList = (
  filters: BrandRequestItemListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_BRANDREQUESTITEM_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchBrandRequestItemListByProgramAndBrandRequestId = (programAndBrandRequestId: number): AppAction => {
  return fetchBrandRequestItemList(
    {
      'ProgramAndBrandRequestId.equalsTo': programAndBrandRequestId,
    }, {
      pageNumber: 0,
      pageSize: 9999,
    });
};

export const createBrandRequestItem = (newBrandRequestItem: BrandRequestItem, callback?: AppCallback): AppAction => ({
  type: CREATE_BRANDREQUESTITEM,
  payload: newBrandRequestItem,
  callback
});

export const updateBrandRequestItem = (newBrandRequestItem: BrandRequestItem, callback?: AppCallback): AppAction => ({
  type: UPDATE_BRANDREQUESTITEM,
  payload: newBrandRequestItem,
  callback
});

export const deleteBrandRequestItem = (brandRequestItemId: number, callback?: AppCallback): AppAction => ({
  type: DELETE_BRANDREQUESTITEM,
  payload: brandRequestItemId,
  callback
});

export const clearBrandRequestItem = () => ({
  type: CLEAR_BRANDREQUESTITEM,
});


