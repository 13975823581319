import { Button, TableCell, TableRow, TextField } from '@material-ui/core';
import { Delete as DeleteIcon, Save as SaveIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { SalesTeamRole } from '../../../state/salesTeamRole';
import { User } from '../../../state/user';
import {
  createWorkingTeamMember,
  updateWorkingTeamMember,
  WorkingTeamMember,
} from '../../../state/workingTeamMember';
import { AutoComplete } from '../../components';
import { pushNotificationMessage } from '../../components/Notification';
import UserComboBox from '../../components/UserComboBox';
import useStyles from './workingTeamFormDetail.style';

const WorkingTeamMemberSchema = Yup.object().shape({
  userId: Yup.number()
    .moreThan(0, 'Please select user')
    .required('User is required'),
  salesTeamRoleId: Yup.number()
    .moreThan(0, 'Please select role')
    .required('Role is required'),
  externalId: Yup.string()
    .nullable()
    .required('External Id is required'),
  active: Yup.boolean(),
  activeDate: Yup.date().nullable(true),
  smWorkingTeamId: Yup.number().required('Working team is required'),
});

interface WorkingTeamFormDetailRowProps {
  index: number;
  workingTeamId: number;
  workingTeamMember: WorkingTeamMember;
  salesTeamRoles: SalesTeamRole[];
  handleDelete: () => void;
}

const WorkingTeamFormDetailRow: React.FC<WorkingTeamFormDetailRowProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { index, workingTeamMember, workingTeamId, salesTeamRoles, handleDelete } = props;

  const [localWorkingTeamMember, setLocalWorkingTeamMember] = useState<WorkingTeamMember>(
    {},
  );

  const defaultSalesTeamRoleIndex = salesTeamRoles.findIndex(
    salesTeamRole => salesTeamRole.id === localWorkingTeamMember.salesTeamRoleId,
  );

  const defaultSalesTeamRoleValue =
    defaultSalesTeamRoleIndex !== -1
      ? salesTeamRoles[defaultSalesTeamRoleIndex]
      : undefined;

  const handleSubmit = (values: WorkingTeamMember) => {
    WorkingTeamMemberSchema.validate(values, { abortEarly: false })
      .then(validatedValues => {
        if (workingTeamMember.id) {
          dispatch(updateWorkingTeamMember({ ...workingTeamMember, ...validatedValues }));
        } else {
          dispatch(createWorkingTeamMember({ workingTeamId, ...validatedValues }));
        }
      })
      .catch((err: Yup.ValidationError) => {
        if (err.errors) {
          err.errors.forEach(error => {
            dispatch(pushNotificationMessage({ message: error, type: 'error' }));
          });
        }
      });
  };

  useEffect(() => {
    setLocalWorkingTeamMember({ ...workingTeamMember, active: true });
  }, [workingTeamMember]);

  return (
    <TableRow>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <UserComboBox
          handleChange={(value: User) => {
            setLocalWorkingTeamMember({
              ...localWorkingTeamMember,
              user: value,
              userId: value ? value.id : undefined,
            });
          }}
          selectedValue={localWorkingTeamMember.user as User}
        />
      </TableCell>
      <TableCell>
        <AutoComplete
          name={'role'}
          placeholder={'Select Role'}
          options={salesTeamRoles}
          handleChange={(value: SalesTeamRole) => {
            setLocalWorkingTeamMember({
              ...localWorkingTeamMember,
              salesTeamRole: value,
              salesTeamRoleId: value ? value.id : undefined,
            });
          }}
          getOptionLabel={(option: SalesTeamRole) => option.description}
          getOptionValue={(option: SalesTeamRole) => '' + option.id}
          value={defaultSalesTeamRoleValue}
        />
      </TableCell>
      <TableCell>
        <TextField
          variant={'outlined'}
          margin={'dense'}
          value={
            localWorkingTeamMember.externalId ? localWorkingTeamMember.externalId : ''
          }
          onChange={e => {
            setLocalWorkingTeamMember({
              ...localWorkingTeamMember,
              externalId: e.target.value,
            });
          }}
        />
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleSubmit(localWorkingTeamMember);
          }}
          className={classes.button}
        >
          <SaveIcon className={classes.leftIcon} />
          Save
        </Button>
        <Button variant="contained" color="secondary" onClick={handleDelete}>
          <DeleteIcon className={classes.leftIcon} />
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default WorkingTeamFormDetailRow;
