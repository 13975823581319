import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import ForecastFrequency, {
  ForecastFrequencyLabel,
  ForecastFrequencyOptions, getForecastFrequencyFromLabel,
} from '../../../constants/forecastFrequency';
import UnitOfQuantity, { UnitOfQuantityOptions } from '../../../constants/unitOfQuantity';
import { IForecast } from '../../../models/forecast.model';
import { ExcelRowTransformer, getForwardRefEditableTable } from '../EditableTable/EditableTable';
import SelectOptionCellEditor from '../EditableTable/SelectOptionCellEditor';
import { ColDef, ICellRendererParams } from 'ag-grid-community';

const EditableTable = getForwardRefEditableTable<IForecast>();

const ForecastRowValidationSchema = Yup.object().shape({
  quantity: Yup.number()
    .min(0)
    .required()
    .nullable(),
  unitOfQuantity: Yup.string()
    .oneOf(Object.values(UnitOfQuantity))
    .required()
    .nullable(),
  frequency: Yup.string()
    .oneOf(Object.values(ForecastFrequency))
    .required()
    .nullable(),
});

const ForecastFrequencyCellRenderer = (props: ICellRendererParams) => {
  return <span>{ForecastFrequencyLabel[props.value!]}</span>;
};

const columnDefs: Array<ColDef> = [
  {
    headerName: 'Quantity',
    editable: true,
    field: 'quantity',
    valueSetter: (params: any): boolean => {
      if (!isNaN(params.newValue)) {
        params.data.quantity = Number(params.newValue);
        return true;
      }
      return false;
    },
  },
  {
    headerName: 'Unit of Quantity',
    editable: true,
    field: 'unitOfQuantity',
    cellEditorFramework: SelectOptionCellEditor,
    cellEditorParams: {
      options: UnitOfQuantityOptions,
    },
  },
  {
    headerName: 'Frequency',
    editable: true,
    field: 'frequency',
    cellRendererFramework: ForecastFrequencyCellRenderer,
    cellEditorFramework: SelectOptionCellEditor,
    cellEditorParams: {
      options: ForecastFrequencyOptions,
    },
  },
];

interface ForecastTableProps {
  defaultNewRowValue: IForecast;
  onUpdate: (createForecastListPayload: IForecast[]) => void;
  forecastList: IForecast[];
  blockNo?: string;
}

const ForecastTable: React.FC<ForecastTableProps> = props => {
  const {
    defaultNewRowValue,
    blockNo = '',
    onUpdate,
    forecastList,
  } = props;

  const localRows = useMemo<ReadonlyArray<IForecast>>(() => {
    return forecastList.map((forecast) => ({ ...forecast }));
  }, [forecastList]);

  const forecastExcelRowTransformer: ExcelRowTransformer<IForecast> = useMemo(() => ({
    headers: ['quantity', 'unitOfQuantity', 'frequencyLabel'],
    objectToRow: (obj) => {
      return [
        obj.quantity,
        obj.unitOfQuantity,
        ForecastFrequencyLabel[obj.frequency!],
      ];
    },
    rowToObject: (row) => {
      return {
        ...defaultNewRowValue,
        quantity: row[0],
        unitOfQuantity: row[1],
        frequency: getForecastFrequencyFromLabel(row[2]),
      };
    },
  }), [defaultNewRowValue]);

  return (
    <>
      {blockNo && <Typography>Forecast {blockNo}</Typography>}
      <EditableTable
        rowsLimit={1}
        excelRowTransformer={forecastExcelRowTransformer}
        rowValidationSchema={ForecastRowValidationSchema}
        columnDefs={columnDefs}
        rows={localRows}
        onSave={(rows => {
          onUpdate(rows);
        })}
        getNewRow={() => {
          return defaultNewRowValue;
        }}
        domLayout='autoHeight'
      />
    </>
  );
};

export default ForecastTable;
