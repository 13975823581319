import { PageFilter } from '../state/app';
import axios from '../utils/axios';
import { ISampleDevelopmentTl } from '../models/sampleDevelopmentTl.model';
import { SampleDevelopmentTlListFilter } from '../state/sampleDevelopmentTl';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';

const baseUrl = `/api/sampleDevelopmentTls`;

export const fetchSampleDevelopmentTlList = async ({
  filters,
  paging,
}: {
  filters: SampleDevelopmentTlListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchSampleDevelopmentTl = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const downloadSampleDevelopmentTl = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}/export`;
  return await axios.get(requestUrl, { responseType: 'blob' });
};

export const createSampleDevelopmentTl = async (
  sampleDevelopmentTl: ISampleDevelopmentTl,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, sampleDevelopmentTl);
};

export const updateSampleDevelopmentTl = async (
  sampleDevelopmentTl: ISampleDevelopmentTl,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, sampleDevelopmentTl);
};

export const submitSampleDevelopmentTlForApproval = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}/submitForApproval`;
  return await axios.post(requestUrl);
};
