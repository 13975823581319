
export enum NoneQualifiedItemResolution {
  forceCreate = 'forceCreate',
  ignore = 'ignore'
}

export const NoneQualifiedItemResolutionLabel = {
  [NoneQualifiedItemResolution.forceCreate]: 'Add to Create list',
  [NoneQualifiedItemResolution.ignore]: 'Ignore',
};
