import { ISubtaskItem, IUpdatedSubtaskItem } from '../../models/subtaskItem.model';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_SUBTASK_ITEM,
  FETCH_SUBTASK_ITEM_LIST,
  UPDATE_SUBTASK_ITEM_STATUS,
  UPDATE_SUBTASK_ITEMS_STATUS,
  CLEAR_UPDATED_ITEMS,
} from './subtaskItem.actions';
import { SubtaskItemState } from './subtaskItem.types';

const initialState: SubtaskItemState = {
  errorMessage: '',
  item: {} as Readonly<ISubtaskItem>,
  items: [] as ISubtaskItem[],
  loading: false,
  totalItems: 0,
  updatedItems: [] as ReadonlyArray<IUpdatedSubtaskItem>,
};

export default (
  state: SubtaskItemState = initialState,
  action: AppAction,
): SubtaskItemState => {
  switch (action.type) {
    case REQUEST(FETCH_SUBTASK_ITEM_LIST):
    case REQUEST(UPDATE_SUBTASK_ITEM_STATUS):
    case REQUEST(UPDATE_SUBTASK_ITEMS_STATUS):
      return { ...state, loading: true };
    case SUCCESS(FETCH_SUBTASK_ITEM_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_SUBTASK_ITEM_LIST):
    case FAILURE(UPDATE_SUBTASK_ITEM_STATUS):
    case FAILURE(UPDATE_SUBTASK_ITEMS_STATUS):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(UPDATE_SUBTASK_ITEM_STATUS):
      return { ...state, loading: false, item: action.payload.data };
    case SUCCESS(UPDATE_SUBTASK_ITEMS_STATUS):
      return { ...state, loading: false, updatedItems: action.payload.data };
    case CLEAR_SUBTASK_ITEM:
      return {
        ...state,
        ...initialState,
      };
    case CLEAR_UPDATED_ITEMS:
      return {
        ...state,
        updatedItems: [],
      };
    default:
      return state;
  }
};
