import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  textInput: {
    marginTop: '18px',
  },
  textInputRightCheckbox: {
    marginTop: '1px',
  },
  textInputRightCheckboxOther: {
    marginTop: '-7px',
  },
  title: {
    margin: '7px',
  },
  numberTitle: {
    margin: '7px',
    paddingTop: '5px',
  },
  labelDimensionImg: { height: 0, paddingTop: '70.25%' },
  rollWindChartImg: {
    width: '95%',
    height: 350,
  },
  formControl: {
    width: '100%',
    marginTop: '10px',
  },
  formControlLabelRightLabel: {
    marginTop: '-9px',
  },
  blockDescriptionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
