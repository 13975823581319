export interface INotificationUser {
  smNotificationId?: number;
  recipientId?: number;
  isRead?: boolean;
  loading?: boolean;
}

export interface NotificationUserState {
  loading: boolean;
  item: Readonly<INotificationUser>;
  items: ReadonlyArray<INotificationUser>;
  errorMessage: string;
  totalItems: number;
}

export const defaultValue: Readonly<INotificationUser> = {};

export interface NotificationUserFilter {
  'User.Login.EqualsTo'?: string;
}
