import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearOpportunityCustomer,
  fetchOpportunityCustomerList,
  OpportunityCustomerListFilter,
  OpportunityCustomerState,
} from '../../../state/opportunityCustomer';
import {
  ChipList,
  OpportunityLinkButton,
  PageContainer,
  PageTitle,
  TableLoading,
} from '../../components';
import OpportunityCustomerFilter from './OpportunityCustomerFilter';
import { useFilter } from '../../hooks';

const initialOpportunityCustomerFilter: OpportunityCustomerListFilter = {};

const OpportunityCustomerList: React.FC = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useFilter<OpportunityCustomerListFilter>(
    initialOpportunityCustomerFilter,
  );
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });
  const {
    items: opportunityCustomers,
    totalItems,
    loading,
  } = useSelector<AppState, OpportunityCustomerState>(
    (state) => state.opportunityCustomer,
  );

  const handleChangeFilter = (newFilter: OpportunityCustomerListFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchOpportunityCustomerList(filters, paging));
    return function cleanUp() {
      dispatch(clearOpportunityCustomer());
    };
  }, [dispatch, paging, filters]);

  return (
    <PageContainer>
      <PageTitle>Assignment Customer</PageTitle>
      <OpportunityCustomerFilter
        filters={filters}
        onChangeFilter={handleChangeFilter}
        initialFilter={initialOpportunityCustomerFilter}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Assignment Number</TableCell>
            <TableCell>Our Company</TableCell>
            <TableCell>Program</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>Sales</TableCell>
            <TableCell>CS</TableCell>
            <TableCell>Customer Id</TableCell>
            <TableCell>Customer Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={8}>
            {opportunityCustomers.map((opportunityCustomer, index) => {
              const salesNames =
                opportunityCustomer.opportunity?.responsibility?.responsibilitySalesTeamMembers
                  ?.filter((rs) => rs.active === true)
                  ?.map((rs) => {
                    return rs.salesTeamMember?.user?.fullNameWithUserName!;
                  })!;
              const csNames =
                opportunityCustomer.opportunity?.responsibility?.responsibilityWorkingTeamMembers
                  ?.filter(
                    (rc) =>
                      rc.active === true &&
                      rc.smWorkingTeamMember?.smWorkingTeam?.smWorkingGroup?.name ===
                        'CS',
                  )
                  ?.map((rs) => {
                    return rs.smWorkingTeamMember?.user?.fullNameWithUserName!;
                  })!;
              return (
                <TableRow key={opportunityCustomer.id}>
                  <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                  <TableCell>
                    <OpportunityLinkButton
                      opportunity={opportunityCustomer.opportunity!!}
                    />
                  </TableCell>
                  <TableCell>
                    {opportunityCustomer.opportunity!!.company!!.name}
                  </TableCell>
                  <TableCell>
                    {opportunityCustomer.opportunity!!.program!!.name}
                  </TableCell>
                  <TableCell>{opportunityCustomer.opportunity!!.brand!!.name}</TableCell>
                  <TableCell>
                    <ChipList list={salesNames} noAllLabel />
                  </TableCell>
                  <TableCell>
                    <ChipList list={csNames} noAllLabel />
                  </TableCell>
                  <TableCell>{opportunityCustomer.customer!!.externalId}</TableCell>
                  <TableCell>{opportunityCustomer.customer!!.name}</TableCell>
                </TableRow>
              );
            })}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default OpportunityCustomerList;
