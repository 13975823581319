import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectStatusLabel } from '../../../../constants/projectStatus';
import { IProject } from '../../../../models/project.model';
import { IProjectApprovalRequest } from '../../../../models/projectApprovalRequest.model';
import { PageFilter } from '../../../../state/app';
import { AppState } from '../../../../state/configureStore';
import {
  closeProjectApprovalRequestDialog,
  openProjectApprovalRequestDialog,
  ProjectApprovalRequestState,
} from '../../../../state/projectApprovalRequest';
import { TableLoading } from '../../../components';
import { DateTimeDisplay } from '../../../components/DateTimeDisplay';
import ProjectProgramExpandable from '../../project/ProjectProgramExpandable';
import { ProjectApprovalRequestDialog } from '../../projectApprovalRequest/ProjectApprovalRequestDialog';
import {
  clearPendingApprovalProjectList,
  fetchPendingApprovalProjectList,
} from './pendingApprovalProjectList.actions';
import { PendingApprovalProjectListState } from './pendingApprovalProjectList.reducer';
import { TablePageSize } from '../../../../constants';

const PendingApprovalProjectList = () => {
  const dispatch = useDispatch();
  const [projectApprovalRequestDialogValue, setProjectApprovalRequestDialogValue] =
    useState({
      project: {} as IProject,
      projectApprovalRequest: {} as IProjectApprovalRequest,
    });
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 10,
  });

  const {
    loading,
    totalItems,
    items: pendingApprovalProjectList,
  } = useSelector<AppState, PendingApprovalProjectListState>(
    (state) => state.pendingApprovalProjectList,
  );

  const { openDialog } = useSelector<AppState, ProjectApprovalRequestState>(
    (state) => state.projectApprovalRequest,
  );

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const editProject = (projectApprovalRequest: IProjectApprovalRequest): void => {
    setProjectApprovalRequestDialogValue({
      project: projectApprovalRequest.smProject!,
      projectApprovalRequest,
    });
    dispatch(openProjectApprovalRequestDialog());
  };

  const handleCloseProjectApprovalRequestDialog = () => {
    dispatch(closeProjectApprovalRequestDialog());
  };

  useEffect(() => {
    if (!openDialog) {
      dispatch(fetchPendingApprovalProjectList(paging));
    }
    return function cleanup() {
      dispatch(clearPendingApprovalProjectList());
    };
  }, [dispatch, paging, openDialog]);

  const substr = (text: string) => {
    const splitLength = 15;
    const split = text.split('');

    if (split.length > splitLength) {
      return text.substring(0, splitLength) + ' ...';
    } else {
      return text;
    }
  };

  return (
    <>
      <Typography variant="h6">Pending Approval Project</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Project</TableCell>
            <TableCell>Program</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>Owner</TableCell>
            <TableCell>Is High Priority</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={11}>
            {pendingApprovalProjectList.map((pendingApprovalProject, index) => {
              return (
                <TableRow key={pendingApprovalProject.id}>
                  <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                  <TableCell>{pendingApprovalProject.description}</TableCell>
                  <TableCell>{pendingApprovalProject.smProject!.name}</TableCell>
                  <TableCell>
                    <ProjectProgramExpandable
                      programNames={substr(
                        pendingApprovalProject.smProject!.programNames!,
                      )}
                      projectPrograms={
                        pendingApprovalProject.smProject!.smProjectPrograms!
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <ProjectProgramExpandable
                      programNames={substr(pendingApprovalProject.smProject!.brandNames!)}
                      projectPrograms={
                        pendingApprovalProject.smProject!.smProjectPrograms!
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {pendingApprovalProject.smProject!.owner!.fullName}
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="secondary"
                      checked={pendingApprovalProject!.smProject!.isHighPriority!}
                    />
                  </TableCell>
                  <TableCell>
                    {ProjectStatusLabel[pendingApprovalProject.smProject!.status!]}
                  </TableCell>
                  <TableCell>
                    <DateTimeDisplay
                      value={pendingApprovalProject.smProject!.createdAt}
                    />
                  </TableCell>
                  <TableCell>{pendingApprovalProject.smProject!.createdBy}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={() => editProject(pendingApprovalProject)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[...TablePageSize]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
      <ProjectApprovalRequestDialog
        handleClose={handleCloseProjectApprovalRequestDialog}
        project={projectApprovalRequestDialogValue.project}
        projectApprovalRequest={projectApprovalRequestDialogValue.projectApprovalRequest}
      />
    </>
  );
};

export default PendingApprovalProjectList;
