import { ISubtaskType } from '../../models/subtaskType.model';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import { FETCH_SUBTASK_TYPE_LIST } from './subtaskType.actions';
import { SubtaskTypeState } from './subtaskType.types';

const initialState: SubtaskTypeState = {
  errorMessage: '',
  item: {} as Readonly<ISubtaskType>,
  items: [] as ISubtaskType[],
  loading: false,
  totalItems: 0,
};

export default (
  state: SubtaskTypeState = initialState,
  action: AppAction,
): SubtaskTypeState => {
  switch (action.type) {
    case REQUEST(FETCH_SUBTASK_TYPE_LIST):
      return { ...state, loading: true };
    case SUCCESS(FETCH_SUBTASK_TYPE_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_SUBTASK_TYPE_LIST):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    default:
      return state;
  }
};
