import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import { IProject } from '../../models/project.model';
import { IProjectProgram } from '../../models/projectProgram.model';
import {
  createProjectProgramBrand,
  deleteProjectProgramBrand,
  fetchBrandByProjectId,
  fetchProjectProgramBrand,
  fetchProjectProgramBrandList,
  updateProjectProgramBrand,
} from '../../services/projectProgramBrandService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import { AppState } from '../configureStore';
import {
  CREATE_PROJECT_PROGRAM_BRAND,
  CREATE_PROJECT_PROGRAM_BRANDS,
  DELETE_PROJECT_PROGRAM_BRAND,
  FETCH_BRAND_BY_PROJECT_ID,
  FETCH_PROJECT_PROGRAM_BRAND,
  FETCH_PROJECT_PROGRAM_BRAND_LIST,
  fetchBrandByProjectId as fetchBrandByProjectIdAction,
  fetchProjectProgramBrandListBySmProjectIdSmProjectProgramId,
  UPDATE_PROJECT_PROGRAM_BRAND,
} from './projectProgramBrand.actions';
import { ProjectProgramBrandCreateOrUpdatePayload } from './projectProgramBrand.types';

function* fetchProjectProgramBrandSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchProjectProgramBrand, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchBrandByProjectIdSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchBrandByProjectId, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchProjectProgramBrandListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchProjectProgramBrandList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createProjectProgramBrandSaga(action: AppAction) {
  try {
    const { values } = action.payload as ProjectProgramBrandCreateOrUpdatePayload;
    const successMessage = `Created project brand successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createProjectProgramBrand, values);
    yield put(
      fetchProjectProgramBrandListBySmProjectIdSmProjectProgramId(
        data.smProjectId,
        data.SmProjectProgramId,
      ),
    );
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createProjectProgramBrandsSaga(action: AppAction) {
  try {
    const { payload, callback } = action;

    yield put({ type: REQUEST(action.type) });

    for (const opCustGrp of payload) {
      yield call(createProjectProgramBrand, opCustGrp);
    }

    const successMessage = `Created project brand successfully.`;

    yield put(fetchBrandByProjectIdAction(payload[0].smProjectId));

    yield put({ type: SUCCESS(action.type), payload });

    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));

    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateProjectProgramBrandSaga(action: AppAction) {
  try {
    const { values } = action.payload as ProjectProgramBrandCreateOrUpdatePayload;
    const successMessage = `Updated project brand successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateProjectProgramBrand, values);
    yield put(
      fetchProjectProgramBrandListBySmProjectIdSmProjectProgramId(
        data.smProjectId,
        data.smProjectProgramId,
      ),
    );
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteProjectProgramBrandSaga(action: AppAction) {
  const { payload } = action;
  const { id } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    yield call(deleteProjectProgramBrand, id);
    const project = yield select(getProjectFromState);
    const projectProgram = yield select(getProjectProgramFromState);
    if (project.id) {
      yield put(fetchBrandByProjectIdAction(project.id));
    }
    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Deleted successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function getProjectFromState(state: AppState): IProject {
  const { item } = state.project;
  return item;
}

function getProjectProgramFromState(state: AppState): IProjectProgram {
  const { item } = state.projectProgram;
  return item;
}

export default function* watchProjectProgramBrand() {
  yield takeLatest(FETCH_PROJECT_PROGRAM_BRAND, fetchProjectProgramBrandSaga);
  yield debounce(250, FETCH_PROJECT_PROGRAM_BRAND_LIST, fetchProjectProgramBrandListSaga);
  yield takeLatest(CREATE_PROJECT_PROGRAM_BRAND, createProjectProgramBrandSaga);
  yield takeLatest(UPDATE_PROJECT_PROGRAM_BRAND, updateProjectProgramBrandSaga);
  yield takeLatest(DELETE_PROJECT_PROGRAM_BRAND, deleteProjectProgramBrandSaga);
  yield takeLatest(CREATE_PROJECT_PROGRAM_BRANDS, createProjectProgramBrandsSaga);
  yield takeLatest(FETCH_BRAND_BY_PROJECT_ID, fetchBrandByProjectIdSaga);
}
