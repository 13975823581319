import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_COMPANY,
  CREATE_COMPANY,
  FETCH_COMPANY,
  FETCH_COMPANY_LIST,
  UPDATE_COMPANY,
} from './company.actions';
import { Company, CompanyState } from './company.types';

const getInitialCompanyState = (): Company => ({
  id: 0,
  name: '',
  externalId: '',
});

const initialState: CompanyState = {
  errorMessage: '',
  item: getInitialCompanyState(),
  items: [],
  loading: false,
  totalItems: 0,
};

export default (state: CompanyState = initialState, action: AppAction): CompanyState => {
  switch (action.type) {
    case REQUEST(FETCH_COMPANY_LIST):
    case REQUEST(CREATE_COMPANY):
    case REQUEST(FETCH_COMPANY):
    case REQUEST(UPDATE_COMPANY):
      return { ...state, loading: true };
    case SUCCESS(FETCH_COMPANY_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_COMPANY_LIST):
    case FAILURE(CREATE_COMPANY):
    case FAILURE(FETCH_COMPANY):
    case FAILURE(UPDATE_COMPANY):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_COMPANY):
    case SUCCESS(FETCH_COMPANY):
    case SUCCESS(UPDATE_COMPANY):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_COMPANY:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
