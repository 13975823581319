import DownloadResponsibilityTemplateButton from '../../../components/DownloadResponsibilityTemplateButton';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import readXlsxFile from 'read-excel-file';
import useCheckImportTemplateVersion from './useCheckImportTemplateVersion';
import { columnsBuilder, dataBuilder, rowsBuilder } from './agGridBuilder';
import { config } from './responsibilityImportColumnDefinition';
import {
  createOrUpdateWithRowTemplate,
  publishMessage,
} from '../../../../services/responsibilityService';
import { pushNotificationMessage } from '../../../components/Notification';
import { AgGridReact } from 'ag-grid-react';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Typography,
  Grid,
  IconButton,
} from '@material-ui/core';

import {
  RotateLeft as ResetIcon,
  AttachFile as SelectFileIcon,
  TableChart as CSVIcon,
  PlayArrow as StartIcon,
  PostAddOutlined as HeaderIcon,
  Close as CloseIcon,
} from '@material-ui/icons';

const excelRowStartAt = 4;

export interface ResponsibilityImportDialogProps {
  open: boolean;
  onClose: () => void;
}

const ResponsibilityImportDialog: React.FC<ResponsibilityImportDialogProps> = (props) => {
  const { open, onClose } = props;
  const [reading, setReading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [rawExcelRows, setRawExcelRows] = useState([]);
  const gridApi = useRef<GridApi>();

  const [readyToProcess, setReadyToProcess] = useState(false);

  const { version, checking, execute } = useCheckImportTemplateVersion();

  useEffect(() => {
    if (open) {
      execute();
    }
  }, [open]);

  const dispatch = useDispatch();

  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
    gridApi.current.setRowData([]);
    params.columnApi.autoSizeAllColumns();
  };

  const handleOnLeaveDialog = () => {
    // TODO: cancel token
    //window.alert('cancel request import token');
  };

  const alertUser = useCallback(
    (e: any) => {
      if (saving) {
        e.preventDefault();
        e.returnValue = '';
      }
    },
    [saving],
  );

  useEffect(() => {
    if (open) {
      window.addEventListener('beforeunload', alertUser);
      window.addEventListener('popstate', alertUser);
      window.addEventListener('unload', handleOnLeaveDialog);
    }
    return () => {
      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('popstate', alertUser);
      window.removeEventListener('unload', handleOnLeaveDialog);
      if (saving) {
        handleOnLeaveDialog();
      }
    };
  }, [open, saving, alertUser]);

  useEffect(() => {
    if (gridApi.current) {
      const rowsBuilded = rowsBuilder(config.columns, [...rawExcelRows], excelRowStartAt);

      const columnsBuilded = columnsBuilder(
        config.columns,
        (excelIndex: number | null) => {
          if (!excelIndex) return false;
          return [...rawExcelRows]
            .slice(excelRowStartAt - 1)
            .map((r) => r[excelIndex - 1])
            .every((r) => !r);
        },
      );

      gridApi.current.setColumnDefs(columnsBuilded);
      gridApi.current.setRowData(rowsBuilded);
      setReadyToProcess(rowsBuilded.length > 0);
      if (rowsBuilded.length === 0) {
        toast.error('No row found in excel file', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  }, [rawExcelRows]);

  const readSelectedFile = useCallback(
    async (file: any) => {
      if (file && !checking) {
        try {
          setReading(true);

          const raws = await readXlsxFile(file);

          if (raws && raws.length > 0 && raws[0].length > 0) {
            const excelImportTemplateVersion = raws[0][0] || '';
            if (excelImportTemplateVersion.toString().trim() === version) {
              setRawExcelRows(raws);
            } else {
              toast.error(
                'Your excel has invalid version, please download the latest version from server and try again',
                {
                  position: toast.POSITION.TOP_CENTER,
                },
              );
            }
          }
        } catch (error) {
          dispatch(
            pushNotificationMessage({
              message: (error && error.data) || error,
              type: 'error',
            }),
          );
        } finally {
          setReading(false);
        }
      }
    },
    [dispatch, checking, version],
  );

  const handleChangeFile = (event: any) => {
    readSelectedFile(event.target.files[0]);
    event.target.value = null;
  };

  const handleReset = () => {
    setRawExcelRows([]);
  };

  const startProcess = async () => {
    setSaving(true);
    const tasks: any = [];

    if (gridApi && gridApi.current) {
      gridApi.current.forEachNode((node, index) => {
        tasks.push(
          new Promise((resolve, reject) => {
            const dataBuilded = dataBuilder(config.columns, node.data);
            node.setDataValue('status', 'SAVING...');
            createOrUpdateWithRowTemplate(dataBuilded)
              .then(({ data }) => {
                node.setDataValue('status', data.message);
                node.setDataValue('responsibilityId', data.responsibilityID);
                // resolve(true);
              })
              .catch((error) => {
                //reject(error.response.data.message);
                node.setDataValue('status', error.response.data.message);
              })
              .finally(() => {
                resolve(true);
              });
          }),
        );
      });
    }

    // for (const task of tasks) {
    //   await task;
    // }

    // setSaving(false);
    // setReadyToProcess(true);
    // toast.success('Process has finished', {
    //   position: toast.POSITION.TOP_CENTER,
    // });

    Promise.all(tasks).finally(() => {
      setSaving(false);
      setReadyToProcess(true);
      toast.success('Process has finished', {
        position: toast.POSITION.TOP_CENTER,
      });

      const respIds: number[] = [];

      if (gridApi && gridApi.current) {
        gridApi.current.forEachNode((node, index) => {
          if (node.data.status === 'CREATED' || node.data.status === 'UPDATED') {
            respIds.push(node.data.responsibilityId);
          }
        });
      }

      return publishMessage(respIds).then(() => {
        toast.success('Process Update Epicor Success', {
          position: toast.POSITION.TOP_CENTER,
        });
      });
    });
  };

  const handleExportAsCsv = () => {
    if (gridApi && gridApi.current) {
      gridApi.current.exportDataAsCsv();
      toast.info('Download has started', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const countRowByStatus = (status: string): number => {
    if (gridApi && gridApi.current) {
      if (status === '') {
        return gridApi.current.getDisplayedRowCount();
      }

      let total = 0;
      gridApi.current.forEachNode((node) => {
        if (node.data.status === status) {
          total++;
        }
      });
      return total;
    }
    return 0;
  };

  return (
    <Dialog fullScreen open={open}>
      <DialogTitle>
        <div>
          <Grid container alignItems="center">
            <Grid item xs={1}>
              <HeaderIcon style={{ width: 100, height: 100 }} />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h4">Import Responsibility</Typography>
              <Typography variant="body1">
                A powerful tool for create or update your responsibility by bulk of data.
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Grid container justifyContent="flex-end">
                <IconButton
                  style={{ backgroundColor: '#ff5252', color: 'white' }}
                  onClick={() => {
                    onClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <div style={{ marginTop: 20, marginBottom: 10 }}>
            <Button
              disabled={reading || saving}
              variant="contained"
              startIcon={reading ? <CircularProgress size={20} /> : <SelectFileIcon />}
              component="label"
            >
              Select File
              <input
                type="file"
                hidden
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleChangeFile}
              />
            </Button>
            <Button
              startIcon={<ResetIcon />}
              disabled={reading || saving}
              variant="contained"
              onClick={handleReset}
            >
              Reset
            </Button>
            <DownloadResponsibilityTemplateButton disabled={reading || saving} />
            <Button
              startIcon={<CSVIcon />}
              disabled={!readyToProcess || reading || saving}
              variant="contained"
              onClick={handleExportAsCsv}
            >
              Export As CSV
            </Button>
            <Button
              disabled={!readyToProcess || reading || saving}
              variant="contained"
              color="primary"
              style={{
                backgroundColor:
                  !readyToProcess || reading || saving ? '#0000001f' : '#7cb342',
              }}
              onClick={() => {
                startProcess();
                setReadyToProcess(false);
              }}
              startIcon={<StartIcon />}
            >
              Start Process
            </Button>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            onGridReady={onGridReady}
            gridOptions={{
              getRowStyle: (params: any) => {
                const status = params.node.data.status;
                if (status === 'READY') {
                  return { background: 'white' };
                } else if (status === 'UPDATED' || status === 'CREATED') {
                  return { background: 'rgb(207, 255, 191)' };
                } else if (status === 'SAVING...') {
                  return { background: 'rgb(251, 255, 217)' };
                } else {
                  return { background: 'rgb(255, 225, 222)' };
                }
              },
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Grid container alignItems="center" style={{ paddingLeft: 15, paddingRight: 15 }}>
          <Grid item xs={3}>
            <Typography variant="body1">TOTAL : {countRowByStatus('')}</Typography>
          </Grid>
        </Grid>
      </DialogActions>
      <ToastContainer transition={Zoom} />
    </Dialog>
  );
};

export default ResponsibilityImportDialog;
