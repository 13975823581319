import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchSalesTeamRoleList } from '../../services/salesTeamRoleService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import { FETCH_SALESTEAM_ROLE_LIST } from './salesTeamRole.actions';

function* fetchSalesTeamRoleListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data, headers } = yield call(fetchSalesTeamRoleList);
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSalesTeamRole() {
  yield takeLatest(FETCH_SALESTEAM_ROLE_LIST, fetchSalesTeamRoleListSaga);
}
