import { IProjectChangeLogProject } from '../../models/projectChangeLog';

export interface ChangeLogProjectState {
  loading: boolean;
  project: IProjectChangeLogProject[];
}

export const initChangeLogProjectState = (): ChangeLogProjectState => {
  return {
    loading: false,
    project: [],
  };
};
