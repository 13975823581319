import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createSquadSalesMember,
  fetchSquadSalesMember,
  fetchSquadSalesMemberList,
  updateSquadSalesMember,
  deleteSquadSalesMember,
} from '../../services/squadSalesMemberService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_SQUAD_SALES_MEMBER,
  CREATE_SQUAD_SALES_MEMBERS,
  FETCH_SQUAD_SALES_MEMBER,
  FETCH_SQUAD_SALES_MEMBER_LIST,
  UPDATE_SQUAD_SALES_MEMBER,
  DELETE_SQUAD_SALES_MEMBER,
} from './squadSalesMember.actions';
import {
  SquadSalesMember,
  SquadSalesMemberCreateOrUpdatePayload,
} from './squadSalesMember.type';

function* fetchSquadSalesMemberListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSquadSalesMemberList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchSquadSalesMemberSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchSquadSalesMember, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createSquadSalesMemberSaga(action: AppAction) {
  try {
    const { values } = action.payload as SquadSalesMemberCreateOrUpdatePayload;
    const successMessage = `Created squad sales member successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createSquadSalesMember, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield call(fetchSquadSalesMemberListSaga, {
      type: FETCH_SQUAD_SALES_MEMBER_LIST,
      payload: {
        filters: { 'SmSquadId.EqualsTo': action.payload.SmSquadId },
        paging: { pageNumber: 0, pageSize: 99999 },
      },
    });
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createSquadSalesMembersSaga(action: AppAction) {
  const squadSalesMembers = action.payload as SquadSalesMember[];
  yield put({ type: REQUEST(action.type) });
  const result = [];

  for (const squadSalesMember of squadSalesMembers) {
    try {
      const { data } = yield call(createSquadSalesMember, squadSalesMember);
      result.push({ ...data, status: 'Success' });
    } catch (error) {
      result.push({
        ...squadSalesMember,
        status: 'Error',
        errorMessage: error.response.data.message,
        hasError: true,
      });
    }
  }
  yield put({
    type: SUCCESS(action.type),
    payload: { data: result },
  });
  const successMessage = `Created squad sales members successfully.`;
  yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  yield call(fetchSquadSalesMemberListSaga, {
    type: FETCH_SQUAD_SALES_MEMBER_LIST,
    payload: {
      filters: { 'SmSquadId.EqualsTo': action.payload[0].smSquadId },
      paging: { pageNumber: 0, pageSize: 99999 },
    },
  });
  const callback = action.callback;
  if (callback) {
    callback();
  }
}

function* updateSquadSalesMemberSaga(action: AppAction) {
  try {
    const { values } = action.payload as SquadSalesMemberCreateOrUpdatePayload;
    const successMessage = `Updated squad sales member successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateSquadSalesMember, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield call(fetchSquadSalesMemberListSaga, {
      type: FETCH_SQUAD_SALES_MEMBER_LIST,
      payload: {
        filters: { 'SmSquadId.EqualsTo': values.smSquadId },
        paging: { pageNumber: 0, pageSize: 99999 },
      },
    });
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteSquadSalesMemberSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(
      deleteSquadSalesMember,
      action.payload.smSquadSalesMemberId,
    );
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield call(fetchSquadSalesMemberListSaga, {
      type: FETCH_SQUAD_SALES_MEMBER_LIST,
      payload: {
        filters: { 'SmSquadId.EqualsTo': action.payload.smSquadId },
        paging: { pageNumber: 0, pageSize: 99999 },
      },
    });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSquadSalesMember() {
  yield debounce(250, FETCH_SQUAD_SALES_MEMBER_LIST, fetchSquadSalesMemberListSaga);
  yield takeLatest(FETCH_SQUAD_SALES_MEMBER, fetchSquadSalesMemberSaga);
  yield takeLatest(CREATE_SQUAD_SALES_MEMBER, createSquadSalesMemberSaga);
  yield takeLatest(UPDATE_SQUAD_SALES_MEMBER, updateSquadSalesMemberSaga);
  yield takeLatest(CREATE_SQUAD_SALES_MEMBERS, createSquadSalesMembersSaga);
  yield takeLatest(DELETE_SQUAD_SALES_MEMBER, deleteSquadSalesMemberSaga);
}
