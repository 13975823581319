import { AppAction } from './app.types';
import { call, put, takeLatest } from 'redux-saga/effects';
import { BOOTSTRAP } from './app.action';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { CHECK_AUTH } from '../auth';
import { fetchUserInfo } from '../../services/userService';
import { pushNotificationMessage } from '../../views/components/Notification';

function* bootstrap(action: AppAction) {
  yield put({ type: REQUEST(action.type) });
  yield call(checkLogin);
  yield put({ type: SUCCESS(action.type) });
}

function* checkLogin() {
  try {
    yield put({ type: REQUEST(CHECK_AUTH) });
    const { data } = yield call(fetchUserInfo);
    yield put({ type: SUCCESS(CHECK_AUTH), payload: data });
  } catch (error) {
    console.error(error);
    const errorMessage = 'Authentication check failed. Please login again.';
    yield put({ type: FAILURE(CHECK_AUTH), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }

}

export default function* watchUserAuthentication() {
  yield takeLatest(BOOTSTRAP, bootstrap);
}
