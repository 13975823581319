import { ISquad } from '../../models/squad.model';
import { AppAction, PageFilter } from '../app';
import { SquadCreateOrUpdatePayload, SquadListFilter } from './squad.type';

export const FETCH_SQUAD = 'FETCH_SQUAD';
export const FETCH_SQUAD_LIST = 'FETCH_SQUAD_LIST';
export const CREATE_SQUAD = 'CREATE_SQUAD';
export const UPDATE_SQUAD = 'UPDATE_SQUAD';
export const CLEAR_SQUAD = 'CLEAR_SQUAD';

export const fetchSquadList = (
  filters: SquadListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SQUAD_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchSquad = (id: ISquad['id']): AppAction => ({
  type: FETCH_SQUAD,
  payload: id,
});

export const createSquad = (payload: SquadCreateOrUpdatePayload): AppAction => ({
  type: CREATE_SQUAD,
  payload,
});

export const updateSquad = (payload: SquadCreateOrUpdatePayload): AppAction => ({
  type: UPDATE_SQUAD,
  payload,
});

export const clearSquad = () => ({
  type: CLEAR_SQUAD,
});
