import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { ICreateEBlockForm } from './EBlockCreateDialog';
import useStyles from './eBlockCreateDialog.style';

interface IEBlockCreateSummaryProps {
  formValues: ICreateEBlockForm;
}
const EBlockCreateSummary: React.FC<IEBlockCreateSummaryProps> = props => {
  const { formValues } = props;
  const classes = useStyles();
  return (
    <Table className={classes.table}>
      <TableBody>
        <TableRow>
          <TableCell>Program</TableCell>
          <TableCell className={classes.textBold}>
            {!!formValues.program ? formValues.program.name : ''}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Brand</TableCell>
          <TableCell className={classes.textBold}>
            {!!formValues.brand ? formValues.brand.name : ''}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Sample Dev/NPD</TableCell>
          <TableCell className={classes.textBold}>
            {!!formValues.user
              ? `(${formValues.user.login}) ${formValues.user.firstName} ${formValues.user.lastName}`
              : ''}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default EBlockCreateSummary;
