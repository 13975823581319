import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import history from '../../history';
import {
  createSampleDevelopmentPl,
  fetchSampleDevelopmentPl,
  fetchSampleDevelopmentPlList,
  submitSampleDevelopmentPlForApproval,
  updateSampleDevelopmentPl,
  downloadSampleDevelopmentPl,
} from '../../services/sampleDevelopmentPlService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_SAMPLE_DEVELOPMENT_PL,
  DOWNLOAD_SAMPLE_DEVELOPMENT_PL,
  FETCH_SAMPLE_DEVELOPMENT_PL,
  FETCH_SAMPLE_DEVELOPMENT_PL_LIST,
  SUBMIT_SAMPLE_DEVELOPMENT_PL_FOR_APPROVAL,
  UPDATE_SAMPLE_DEVELOPMENT_PL,
} from './sampleDevelopmentPl.actions';

const formPageUrl = `/sampleDevelopmentPl/form`;

function* fetchSampleDevelopmentPlSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchSampleDevelopmentPl, action.payload);
    if (data.salesTeamMember) {
      data.salesTeam = data.salesTeamMember.salesTeam;
    }
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchSampleDevelopmentPlListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSampleDevelopmentPlList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createSampleDevelopmentPlSaga(action: AppAction) {
  try {
    const { values } = action.payload;
    const successMessage = `Created SampleDevelopmentPl successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createSampleDevelopmentPl, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(`${formPageUrl}/${data.id}`);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateSampleDevelopmentPlSaga(action: AppAction) {
  try {
    const { values } = action.payload;
    const successMessage = `Updated SampleDevelopmentPl successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateSampleDevelopmentPl, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* submitSampleDevelopmentPlForApprovalSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const successMessage = `Submit SampleDevelopmentPl for Approval successfully.`;
    const { data } = yield call(submitSampleDevelopmentPlForApproval, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* downloadSampleDevelopmentPlSaga(action: AppAction) {
  try {
    const response = yield call(downloadSampleDevelopmentPl, action.payload);
    saveAs(response.data, 'SampleDevelopment.pdf');
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSalesTeam() {
  yield takeLatest(FETCH_SAMPLE_DEVELOPMENT_PL, fetchSampleDevelopmentPlSaga);
  yield debounce(250, FETCH_SAMPLE_DEVELOPMENT_PL_LIST, fetchSampleDevelopmentPlListSaga);
  yield takeLatest(CREATE_SAMPLE_DEVELOPMENT_PL, createSampleDevelopmentPlSaga);
  yield takeLatest(UPDATE_SAMPLE_DEVELOPMENT_PL, updateSampleDevelopmentPlSaga);
  yield takeLatest(
    SUBMIT_SAMPLE_DEVELOPMENT_PL_FOR_APPROVAL,
    submitSampleDevelopmentPlForApprovalSaga,
  );
  yield takeLatest(DOWNLOAD_SAMPLE_DEVELOPMENT_PL, downloadSampleDevelopmentPlSaga);
}
