import React from 'react';
import { Grid } from '@material-ui/core';
import { FilterContainer, SearchField } from '../../components';
import { WorkingTeamListFilter } from '../../../state/workingTeam';
import { IFilterContainerConfigProps } from '../../components/FilterContainer';

const WorkingTeamFilter: React.FC<
  IFilterContainerConfigProps<WorkingTeamListFilter>
> = props => {
  return (
    <FilterContainer
      {...props}
      render={filterProps => {
        const { localFilter, handleChangeFilter } = filterProps;
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Name.startsWith"
                filters={localFilter}
                label="Name"
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Owner.FullNameWithUserName.Contains"
                filters={localFilter}
                label="Owner"
                onChange={handleChangeFilter}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default WorkingTeamFilter;
