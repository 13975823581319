import { CircularProgress, Typography } from '@material-ui/core';
import dagre from 'dagre';
import { flattenDeep } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  ConnectionLineType,
  isNode,
  MiniMap,
  Position,
} from 'react-flow-renderer';
import * as structureService from '../../../services/structureService';
import { Group } from './userStructure.model';

const edgeType = 'smoothstep';

export interface UserStructureProps {
  username: string;
}

interface UserStructureState {
  loading: boolean;
  data: Group[];
}

const getInitState = () => {
  return {
    loading: false,
    data: [],
  };
};

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (elements: any, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });
  elements.forEach((el: any) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: 450, height: 100 });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });
  dagre.layout(dagreGraph);
  return elements.map((el: any) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? Position.Left : Position.Top;
      el.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;
      el.position = {
        x: nodeWithPosition.x + Math.random() / 1000,
        y: nodeWithPosition.y,
      };
      el.style = {
        width: 450,
        height: 100,
      };
    }
    return el;
  });
};

const UserStructure: React.FC<UserStructureProps> = props => {
  const { username } = props;
  const [state, setState] = useState<UserStructureState>(getInitState());

  useEffect(() => {
    const func = async () => {
      setState(prev => ({ ...prev, loading: true }));
      const { data } = await structureService.fetchMemberStructures(username);
      setState(prev => ({
        ...prev,
        data,
      }));
      setState(prev => ({ ...prev, loading: false }));
    };
    func();
  }, [username]);

  const elements = useMemo(() => {
    const groupData = state.data;
    const teamData = flattenDeep(groupData.map(g => g.teams));
    const teamMemberData = flattenDeep(
      groupData.map(g => flattenDeep(g.teams.map(t => t.teamMembers))),
    );

    const groups = groupData.map(g => {
      return {
        id: 'G' + g.id.toString(),
        type: 'input',
        data: {
          label: (
            <div>
              <Typography style={{ fontWeight: 'bold' }}>Group {g.name}</Typography>
              <Typography>{g.owner.fullNameWithUserName}</Typography>
            </div>
          ),
        },
      };
    });

    const teams = teamData.map(t => {
      return {
        id: 'T' + t.id.toString(),
        type: 'input',
        data: {
          label: (
            <div>
              <Typography style={{ fontWeight: 'bold' }}>Team: {t.name}</Typography>
              <Typography>{t.owner && t.owner.fullNameWithUserName}</Typography>
            </div>
          ),
        },
      };
    });

    const linkGroupAndTeams = flattenDeep(
      groupData.map(g => {
        return g.teams.map(t => {
          return {
            id: 'LINK' + g.id.toString() + '-' + t.id.toString(),
            type: edgeType,
            source: 'G' + g.id.toString(),
            target: 'T' + t.id.toString(),
          };
        });
      }),
    );

    const teamMembers = teamMemberData.map(tm => ({
      id: 'TM' + tm.id.toString(),
      type: 'input',
      data: {
        label: (
          <div>
            <Typography style={{ fontWeight: 'bold' }}>
              Member Code : {tm.externalId}
            </Typography>
            <Typography>{tm.user.fullNameWithUserName}</Typography>
            <Typography>{tm.role && tm.role.name}</Typography>
          </div>
        ),
      },
    }));

    const linkTeamAndMembers = flattenDeep(
      teamData.map(t => {
        return t.teamMembers.map(tm => {
          return {
            id: 'LINK' + t.id.toString() + '-' + tm.id.toString(),
            type: edgeType,
            source: 'T' + t.id.toString(),
            target: 'TM' + tm.id.toString(),
          };
        });
      }),
    );

    return getLayoutedElements([
      ...groups,
      ...teams,
      ...linkGroupAndTeams,
      ...teamMembers,
      ...linkTeamAndMembers,
    ]);
  }, [state.data]);

  return (
    <div style={{ height: '100vh' }}>
      {state.loading ? (
        <CircularProgress />
      ) : (
        <ReactFlow elements={elements} connectionLineType={ConnectionLineType.SmoothStep}>
          <MiniMap />
        </ReactFlow>
      )}
    </div>
  );
};

export default UserStructure;
