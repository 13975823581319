import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createEBlockArtTemplate,
  fetchEBlockArtTemplate,
  fetchEBlockArtTemplateList,
  updateEBlockArtTemplate,
} from '../../services/eBlockArtTemplateService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_EBLOCK_ART_TEMPLATE,
  FETCH_EBLOCK_ART_TEMPLATE,
  FETCH_EBLOCK_ART_TEMPLATE_LIST,
  UPDATE_EBLOCK_ART_TEMPLATE,
} from './eBlockArtTemplate.action';
import {
  EBlockArtTemplate,
  EBlockArtTemplateCreateOrUpdatePayload,
} from './eBlockArtTemplate.type';

const listPageUrl = `/settings/eBlocks`;

function* fetchEBlockArtTemplateListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchEBlockArtTemplateList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchEBlockArtTemplateSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchEBlockArtTemplate, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createEBlockArtTemplateSaga(action: AppAction) {
  try {
    const payload = action.payload as { blockId: number };
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createEBlockArtTemplate, payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Created ${data.artTemplateNumber} art template successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield call(fetchEBlockArtTemplateListSaga, {
      type: FETCH_EBLOCK_ART_TEMPLATE_LIST,
      payload: {
        filters: { 'BlockId.EqualsTo': data.blockId },
        paging: { pageNumber: 0, pageSize: 99999 },
      },
    });
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateEBlockArtTemplateSaga(action: AppAction) {
  try {
    const values = action.payload as EBlockArtTemplate;
    const successMessage = `Updated ${values.artTemplateNumber} eblock art template successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateEBlockArtTemplate, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield call(fetchEBlockArtTemplateListSaga, {
      type: FETCH_EBLOCK_ART_TEMPLATE_LIST,
      payload: {
        filters: { 'BlockId.EqualsTo': data.blockId },
        paging: { pageNumber: 0, pageSize: 99999 },
      },
    });
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchEBlockArtTemplate() {
  yield debounce(250, FETCH_EBLOCK_ART_TEMPLATE_LIST, fetchEBlockArtTemplateListSaga);
  yield takeLatest(CREATE_EBLOCK_ART_TEMPLATE, createEBlockArtTemplateSaga);
  yield takeLatest(UPDATE_EBLOCK_ART_TEMPLATE, updateEBlockArtTemplateSaga);
  yield takeLatest(FETCH_EBLOCK_ART_TEMPLATE, fetchEBlockArtTemplateSaga);
}
