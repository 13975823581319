import { PageFilter } from '../state/app';
import {
  Block,
  BlockListFilter,
  ISearchBlockByItemNameDto,
  SampleVNDto,
} from '../state/block';
import axios from '../utils/axios';
import { BlockCreate } from '../views/components/BlockCreateDialog/BlockCreateDialog.types';

const baseUrl = `/api/blocks`;

export const fetchBlockList = async ({
  filters,
  paging,
}: {
  filters: BlockListFilter;
  paging: PageFilter;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      params.append(key, value);
    }
  });

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchBlockByBlockNo = async (blockNo: string): Promise<Block> => {
  const response = await fetchBlockList({
    filters: { 'BlockNo.equalsTo': blockNo },
    paging: { pageSize: 1, pageNumber: 0 },
  });
  return response.data[0];
};

export const searchBlocksByItemNames = async (
  searchBlockByItemNameDtos: ReadonlyArray<ISearchBlockByItemNameDto>,
): Promise<ISearchBlockByItemNameDto> => {
  const requestUrl = `${baseUrl}/searchByItemNames`;
  return await axios.post(`${requestUrl}`, searchBlockByItemNameDtos);
};

export const fetchBlock = async (id: Block['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createBlock = async (block: Block | BlockCreate) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, block);
};

export const updateBlock = async (block: Block) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, block);
};

export const syncBlock = async (sampleVNDto: SampleVNDto) => {
  const requestUrl = `${baseUrl}/sync/vietnam`;
  return await axios.post(requestUrl, sampleVNDto);
};
