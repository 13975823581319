import { ISubtaskItemChangeStatusLog } from '../../models/subtaskItemChangeStatusLog';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  FETCH_SUBTASK_ITEM_CHANGE_STATUS_LOG_LIST,
  CLEAR_SUBTASK_ITEM_CHANGE_STATUS_LOG,
} from './subtaskItemChangeStatusLog.actions';
import { SubtaskItemChangeStatusLogState } from './subtaskItemChangeStatusLog.types';

const initialState: SubtaskItemChangeStatusLogState = {
  errorMessage: '',
  item: {} as Readonly<ISubtaskItemChangeStatusLog>,
  items: [] as ISubtaskItemChangeStatusLog[],
  loading: false,
  totalItems: 0,
};

export default (
  state: SubtaskItemChangeStatusLogState = initialState,
  action: AppAction,
): SubtaskItemChangeStatusLogState => {
  switch (action.type) {
    case REQUEST(FETCH_SUBTASK_ITEM_CHANGE_STATUS_LOG_LIST):
      return { ...state, loading: true };
    case SUCCESS(FETCH_SUBTASK_ITEM_CHANGE_STATUS_LOG_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_SUBTASK_ITEM_CHANGE_STATUS_LOG_LIST):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };

    case CLEAR_SUBTASK_ITEM_CHANGE_STATUS_LOG:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};
