import { AppAction, PageFilter } from '../app';
import { UserListFilter } from './user.types';
import { IUserRole } from '../../models/userRole.model';

export const FETCH_USER_LIST = 'FETCH_USER_LIST';
export const FETCH_USER = 'FETCH_USER';
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const CLEAR_USER = 'CLEAR_USER';

export const fetchUserList = (filters: UserListFilter, paging: PageFilter): AppAction => {
  return {
    type: FETCH_USER_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchUser = (id: number): AppAction => ({
  type: FETCH_USER,
  payload: id,
});

export const updateUserRole = (userRole: IUserRole): AppAction => ({
  type: UPDATE_USER_ROLE,
  payload: userRole,
});

export const clearUser = (): AppAction => {
  return {
    type: CLEAR_USER,
  };
};
