import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createBrandRequestItem,
  deleteBrandRequestItem,
  fetchBrandRequestItem,
  fetchBrandRequestItemList,
  updateBrandRequestItem,
} from '../../services/brandRequestItemService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_BRANDREQUESTITEM,
  DELETE_BRANDREQUESTITEM,
  FETCH_BRANDREQUESTITEM,
  FETCH_BRANDREQUESTITEM_LIST,
  UPDATE_BRANDREQUESTITEM,
} from './brandRequestItem.actions';

function* fetchBrandRequestItemSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchBrandRequestItem, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchBrandRequestItemListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchBrandRequestItemList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createBrandRequestItemSaga(action: AppAction) {
  try {
    const { payload : newBrandRequestItem, callback } = action;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createBrandRequestItem, newBrandRequestItem);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Created BrandRequestItem successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateBrandRequestItemSaga(action: AppAction) {
  try {
    const { payload : newBrandRequestItem, callback } = action;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateBrandRequestItem, newBrandRequestItem);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Updated BrandRequestItem successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteBrandRequestItemSaga(action: AppAction) {
  const { payload: brandRequestItemId, callback } = action;
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(deleteBrandRequestItem, brandRequestItemId);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Deleted BrandRequestItem successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchBrandRequestItem() {
  yield takeLatest(FETCH_BRANDREQUESTITEM, fetchBrandRequestItemSaga);
  yield debounce(250, FETCH_BRANDREQUESTITEM_LIST, fetchBrandRequestItemListSaga);
  yield takeLatest(CREATE_BRANDREQUESTITEM, createBrandRequestItemSaga);
  yield takeLatest(UPDATE_BRANDREQUESTITEM, updateBrandRequestItemSaga);
  yield takeLatest(DELETE_BRANDREQUESTITEM, deleteBrandRequestItemSaga);
}
