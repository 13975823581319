import { AppAction, PageFilter } from '../app';
import { SubstrateListFilter } from './substrate.types';

export const FETCH_SUBSTRATE_LIST = 'FETCH_SUBSTRATE_LIST';
export const CLEAR_SUBSTRATE = 'CLEAR_SUBSTRATE';

export const fetchSubtaskList = (
  filters: SubstrateListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SUBSTRATE_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const clearSubstrate = () => ({
  type: CLEAR_SUBSTRATE,
});
