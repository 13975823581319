import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import UserStructure from './UserStructure';

interface UserStructureParam {
  username: string;
}

export interface UserStructurePageProps extends RouteComponentProps<UserStructureParam> {}

const UserStructurePage: React.FC<UserStructurePageProps> = props => {
  const {
    match: {
      params: { username },
    },
  } = props;

  return <UserStructure username={username} />;
};

export default UserStructurePage;
