import { Button, CircularProgress } from '@material-ui/core';
import { GetApp as DownloadIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { downloadResponsibilityTemplateExcel } from '../../../services/responsibilityService';
import { pushNotificationMessage } from '../Notification/notification.action';

interface Props {
  disabled?: boolean;
}

const DownloadResponsibilityTemplateExcelButton: React.FC<Props> = props => {
  const { disabled = false } = props;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      await downloadResponsibilityTemplateExcel();
    } catch (error) {
      dispatch(
        pushNotificationMessage({ message: error.response.data.message, type: 'error' }),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      disabled={loading || (!loading && disabled)}
      onClick={handleClick}
      startIcon={loading ? <CircularProgress size={20} /> : <DownloadIcon />}
      variant="contained"
    >
      Get Template
    </Button>
  );
};

export default DownloadResponsibilityTemplateExcelButton;
