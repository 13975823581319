import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createSquad,
  fetchSquad,
  fetchSquadList,
  updateSquad,
} from '../../services/squadService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CLEAR_SQUAD,
  CREATE_SQUAD,
  FETCH_SQUAD,
  FETCH_SQUAD_LIST,
  UPDATE_SQUAD,
} from './squad.actions';
import { SquadCreateOrUpdatePayload } from './squad.type';

const listPageUrl = `/settings/squads`;

function* fetchSquadListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSquadList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchSquadSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchSquad, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createSquadSaga(action: AppAction) {
  try {
    const { values, history } = action.payload as SquadCreateOrUpdatePayload;
    const successMessage = `Created ${values.name} working team successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createSquad, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    const formUrl = `${listPageUrl}/form/${data.id}`;
    history.push(formUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateSquadSaga(action: AppAction) {
  try {
    const { values } = action.payload as SquadCreateOrUpdatePayload;
    const successMessage = `Updated squad successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateSquad, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSquad() {
  yield debounce(250, FETCH_SQUAD_LIST, fetchSquadListSaga);
  yield takeLatest(FETCH_SQUAD, fetchSquadSaga);
  yield takeLatest(CREATE_SQUAD, createSquadSaga);
  yield takeLatest(UPDATE_SQUAD, updateSquadSaga);
}
