import { PageFilter } from '../state/app';
import { WorkingTeam, WorkingTeamListFilter } from '../state/workingTeam';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/smWorkingTeams`;

export const fetchWorkingTeamList = async ({
  filters,
  paging,
}: {
  filters: WorkingTeamListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchWorkingTeam = async (id: WorkingTeam['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createWorkingTeam = async (workingTeam: WorkingTeam) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, workingTeam);
};

export const updateWorkingTeam = async (workingTeam: WorkingTeam) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, workingTeam);
};
