import { ITask } from '../../models/task.model';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_TASK,
  CREATE_TASK,
  FETCH_TASK,
  FETCH_TASK_LIST,
  UPDATE_TASK,
} from './task.actions';
import { TaskState } from './task.types';
import { FETCH_SUBTASK_LIST, FETCH_RELATED_SUBTASK_LIST } from '../subtask';
import { FETCH_TASK_ITEM_LIST } from '../taskItem';
import { FETCH_TASK_ATTACHMENT_LIST } from '../taskAttachment';

const initialState: TaskState = {
  errorMessage: '',
  item: {} as ITask,
  items: [] as ITask[],
  loading: false,
  totalItems: 0,
  totalSubtasks: 0,
  totalTaskAttachments: 0,
  totalTaskItems: 0,
};

export default (state: TaskState = initialState, action: AppAction): TaskState => {
  switch (action.type) {
    case REQUEST(FETCH_TASK_LIST):
    case REQUEST(CREATE_TASK):
    case REQUEST(FETCH_TASK):
    case REQUEST(UPDATE_TASK):
      return { ...state, loading: true };
    case SUCCESS(FETCH_TASK_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_TASK_LIST):
    case FAILURE(CREATE_TASK):
    case FAILURE(FETCH_TASK):
    case FAILURE(UPDATE_TASK):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_TASK):
    case SUCCESS(FETCH_TASK):
    case SUCCESS(UPDATE_TASK):
      return { ...state, loading: false, item: action.payload.data };
    case SUCCESS(FETCH_RELATED_SUBTASK_LIST):
      return { ...state, totalSubtasks: +action.payload.totalItems };
    case SUCCESS(FETCH_TASK_ITEM_LIST):
      return { ...state, totalTaskItems: +action.payload.headers['x-total-count'] };
    case SUCCESS(FETCH_TASK_ATTACHMENT_LIST):
      return { ...state, totalTaskAttachments: +action.payload.headers['x-total-count'] };
    case CLEAR_TASK:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
