import React, { useCallback, useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { PageFilter } from '../../../state/app';
import { ResponsibilityListFilter } from '../../../state/responsibility';
import { useDispatch } from 'react-redux';

import { PlayForWork as DownloadIcon } from '@material-ui/icons';

import { downloadResponsibilityFilterAsImportTemplate } from '../../../services/responsibilityService';
import { pushNotificationMessage } from '../Notification/notification.action';

interface Props {
  paging: PageFilter;
  filters: ResponsibilityListFilter;
  disabled?: boolean;
}

const DownloadResponsibilityFilterAsImportTemplate: React.FC<Props> = (props) => {
  const { paging, filters, disabled } = props;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleDownloadClicked = useCallback(async () => {
    try {
      setLoading(true);
      await downloadResponsibilityFilterAsImportTemplate(filters, paging);
    } catch (error) {
      dispatch(
        pushNotificationMessage({ message: error.response.data.message, type: 'error' }),
      );
    } finally {
      setLoading(false);
    }
  }, [dispatch, paging, filters]);

  return (
    <Button
      disabled={loading || disabled}
      startIcon={loading ? <CircularProgress size={20} /> : <DownloadIcon />}
      color="primary"
      variant="contained"
      onClick={handleDownloadClicked}
    >
      Export As Template
    </Button>
  );
};

export default DownloadResponsibilityFilterAsImportTemplate;
