import React, { useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Block } from '../../../../../state/block';
import { Program } from '../../../../../state/program';
import { Brand } from '../../../../../state/brand';

interface SelectBlockDialogProps {
  open: boolean;
  onClose: () => void;
  onSelectBlock: (block: Block) => void;
  blocks: ReadonlyArray<Block>;
  program: Program;
  brand: Brand;
  itemText: string;
}

const SelectBlockDialog: React.FC<SelectBlockDialogProps> = props => {
  const {
    open,
    onClose,
    blocks,
    onSelectBlock,
    program,
    brand,
  } = props;

  const isSameProgramAndBrand = useCallback((block: Block) => block.programId === program.id && block.brandId === brand.id, [program, brand]);

  return <Dialog
    fullWidth
    maxWidth={'lg'}
    open={open}
    onClose={onClose}
  >
    <DialogTitle>Choose Block Code</DialogTitle>
    <DialogContent>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Block</TableCell>
            <TableCell>Program</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {blocks.map(block => {
            return (
              <TableRow key={block.blockNo}>
                <TableCell>{block.blockNo}</TableCell>
                <TableCell>{block.program!.name}</TableCell>
                <TableCell>{block.brand!.name}</TableCell>
                <TableCell>{block.item}</TableCell>
                <TableCell>
                  {isSameProgramAndBrand(block) ? <Button
                      color={'primary'}
                      onClick={() => {
                        onSelectBlock(block);
                        onClose();
                      }}
                    >
                      Choose
                    </Button> :
                    <Typography>Not belongs to Program and Brand</Typography>
                  }
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>;
};

export default SelectBlockDialog;
