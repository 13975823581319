import { all } from 'redux-saga/effects';
import watchCommentDashboard from '../views/pages/dashboard/commentDashboard/commentDashboard.saga';
import watchPendingTaskList from '../views/pages/dashboard/PendingTaskList/pendingTaskList.saga';
import watchAddMultipleBlockDialog from '../views/pages/opportunity/AddMultipleBlockDialog/addMultipleBlockDialog.saga';
import watchCopyOpportunity from '../views/pages/opportunity/CopyOpportunityDialog/copyOpportunity.saga';
import watchSearchItemAndAddMultipleBlockDialog from '../views/pages/opportunity/SearchItemAndAddMultipleBlockDialog/searchItemAndAddMultipleBlockDialog.saga';
import watchPendingApprovalAssignmentList from '../views/pages/pendingApprovalItemList/pendingApprovalAssignmentList/pendingApprovalAssignmentList.saga';
import watchPendingApprovalProgramBrandRequestList from '../views/pages/pendingApprovalItemList/pendingApprovalProgramBrandRequestList/pendingApprovalProgramBrandRequestList.saga';
import watchPendingApprovalSampleDevlopmentHlList from '../views/pages/pendingApprovalItemList/pendingApprovalSampleDevHlRequestList/pendingApprovalSampleDevHlRequestList.saga';
import watchPendingApprovalSampleDevlopmentNlList from '../views/pages/pendingApprovalItemList/pendingApprovalSampleDevNlRequestList/pendingApprovalSampleDevNlRequestList.saga';
import watchPendingApprovalSampleDevlopmentPlList from '../views/pages/pendingApprovalItemList/pendingApprovalSampleDevPlRequestList/pendingApprovalSampleDevPlRequestList.saga';
import watchPendingApprovalSampleDevlopmentTlList from '../views/pages/pendingApprovalItemList/pendingApprovalSampleDevTlRequestList/pendingApprovalSampleDevTlRequestList.saga';
import watchPendingApprovalSampleDevlopmentWlList from '../views/pages/pendingApprovalItemList/pendingApprovalSampleDevWlRequestList/pendingApprovalSampleDevWlRequestList.saga';
import { watchAdmin } from './admin';
import { watchAppBootstrap } from './app';
import { watchUserAuthentication } from './auth';
import { watchBlock } from './block';
import { watchHanesProject } from './hanesProject';
import { watchBrand } from './brand';
import { watchBrandRequestItem } from './brandRequestItem';
import { watchCompany } from './company';
import { watchCustomer } from './customer';
import { watchCustomerGroup } from './customerGroup';
import { watchCustomerGroupMember } from './customerGroupMember';
import { watchEBlockArtTemplate } from './eBlockArtTemplate';
import { watchEBlockGroupProduct } from './eBlockGroupProduct';
import { watchEBlockMaterial } from './eBlockMaterial';
import { watchEBlockPattern } from './eBlockPattern';
import { watchEBlockSize } from './eBlockSize';
import { watchNotificationUser } from './notificationUser';
import { watchOpportunity } from './opportunity';
import { watchOpportunityApprovalRequest } from './opportunityApprovalRequest';
import { watchOpportunityCustomer } from './opportunityCustomer';
import { watchOpportunityCustomerGroup } from './opportunityCustomerGroup';
import { watchOpportunityItem } from './opportunityItem';
import { watchProductType } from './productType';
import { watchProgram } from './program';
import { watchProgramAndBrandRequest } from './programAndBrandRequest';
import { watchProgramRequestItem } from './programRequestItem';
import { watchProject } from './project';
import { watchProjectCustomer } from './projectCustomer';
import { watchProjectCustomerGroup } from './projectCustomerGroup';
import { watchProjectProgram } from './projectProgram';
import { watchProjectProgramBrand } from './projectProgramBrand';
import { watchProjectSquad } from './projectSquad';
import { watchReport } from './report';
import { watchRole } from './role';
import { watchSalesGroup } from './salesGroup';
import { watchSalesTeam } from './salesTeam';
import { watchSalesTeamMember } from './salesTeamMember';
import { watchSalesTeamRole } from './salesTeamRole';
import { watchSampleDevelopmentHl } from './sampleDevelopmentHl';
import { watchSampleDevelopmentQueue } from './sampleDevelopmentQueue';
import { watchSampleDevelopmentHlApprovalRequest } from './sampleDevelopmentHlApprovalRequest';
import { watchSampleDevelopmentNl } from './sampleDevelopmentNl';
import { watchSampleDevelopmentNlApprovalRequest } from './sampleDevelopmentNlApprovalRequest';
import { watchSampleDevelopmentPl } from './sampleDevelopmentPl';
import { watchSampleDevelopmentPlApprovalRequest } from './sampleDevelopmentPlApprovalRequest';
import { watchSampleDevelopmentTl } from './sampleDevelopmentTl';
import { watchSampleDevelopmentTlApprovalRequest } from './sampleDevelopmentTlApprovalRequest';
import { watchSampleDevelopmentWl } from './sampleDevelopmentWl';
import { watchSampleDevelopmentWlApprovalRequest } from './sampleDevelopmentWlApprovalRequest';
import { watchSquad } from './squad';
import { watchSubstrate } from './substrate';
import { watchSubtask } from './subtask';
import { watchSubtaskItem } from './subtaskItem';
import { watchSubtaskItemChangeStatusLog } from './subtaskItemChangeStatusLog';
import { watchSubtaskItemArtTemplateMoq } from './subtaskItemArtTemplate';
import { watchSubtaskItemForecast } from './subtaskItemForecast';
import { watchSubtaskItemMoq } from './subtaskItemMoq';
import { watchSubtaskType } from './subtaskType';
import { watchTask } from './task';
import { watchTaskActivity } from './taskActivity';
import { watchTaskAttachment } from './taskAttachment';
import { watchTaskCreate } from './taskCreate';
import { watchTaskItem } from './taskItem';
import { watchTaskLogWork } from './taskLogWork';
import { watchUser } from './user';
import { watchWorkingGroup } from './workingGroup';
import { watchWorkingTeam } from './workingTeam';
import { watchWorkingTeamMember } from './workingTeamMember';
import { watchSquadSalesMember } from './squadSalesMember';
import { watchTeamMember } from './teamMember';
import { watchSquadWorkingMember } from './squadWorkingMember';
import { watchSquadMember } from './squadMember';
import { watchChangeLog } from './changeLog';
import { watchChangeLogProject } from './changeLogProject';
import { watchProjectApprovalRequest } from './projectApprovalRequest';
import { watchResponsibility } from './responsibility';
import { watchResponsibilityBrand } from './responsibilityBrand';
import watchPendingApprovalProjectList from '../views/pages/pendingApprovalItemList/pendingApprovalProjectList/pendingApprovalProjectList.saga';
import { watchResponsibilityCustomer } from './responsibilityCustomer';
import { watchResponsibilitySalesTeamMember } from './responsibilitySalesTeamMember';
import { watchResponsibilityWorkingTeamMember } from './responsibilityWorkingTeamMember';
import { watchResponsibilityApprovalRequest } from './responsibilityApprovalRequest';
import watchPendingApprovalResponsibilityList from '../views/pages/pendingApprovalItemList/pendingApprovalResponsibilityList/pendingApprovalResponsibilityList.saga';
import { watchProgramAndBrandRequestItem } from './programAndBrandRequestItem';
//--Generator Needle1--//

export default function* rootSaga() {
  yield all([
    watchAppBootstrap(),
    watchUserAuthentication(),
    watchProgram(),
    watchBrand(),
    watchSalesTeam(),
    watchUser(),
    watchSalesTeamRole(),
    watchSalesGroup(),
    watchWorkingGroup(),
    watchWorkingTeam(),
    watchSalesTeamMember(),
    watchCompany(),
    watchCustomer(),
    watchProductType(),
    watchBlock(),
    watchOpportunity(),
    watchOpportunityCustomer(),
    watchOpportunityItem(),
    watchOpportunityApprovalRequest(),
    watchCustomerGroup(),
    watchCustomerGroupMember(),
    watchOpportunityCustomerGroup(),
    watchAddMultipleBlockDialog(),
    watchCopyOpportunity(),
    watchTask(),
    watchTaskItem(),
    watchTaskActivity(),
    watchTaskAttachment(),
    watchSubtask(),
    watchTaskLogWork(),
    watchSubtaskType(),
    watchProgramAndBrandRequest(),
    watchProgramAndBrandRequestItem(),
    watchProgramRequestItem(),
    watchBrandRequestItem(),
    watchSearchItemAndAddMultipleBlockDialog(),
    watchNotificationUser(),
    watchSubtaskItem(),
    watchSubtaskItemChangeStatusLog(),
    watchTaskCreate(),
    watchSubtaskItemMoq(),
    watchSubtaskItemForecast(),
    watchReport(),
    watchPendingTaskList(),
    watchCommentDashboard(),
    watchAdmin(),
    watchRole(),
    watchPendingApprovalProgramBrandRequestList(),
    watchPendingApprovalAssignmentList(),
    watchSubstrate(),
    watchSampleDevelopmentNl(),
    watchSampleDevelopmentNlApprovalRequest(),
    watchPendingApprovalSampleDevlopmentNlList(),
    watchSampleDevelopmentHl(),
    watchSampleDevelopmentQueue(),
    watchSampleDevelopmentHlApprovalRequest(),
    watchPendingApprovalSampleDevlopmentHlList(),
    watchSampleDevelopmentPl(),
    watchSampleDevelopmentPlApprovalRequest(),
    watchPendingApprovalSampleDevlopmentPlList(),
    watchSampleDevelopmentTl(),
    watchSampleDevelopmentTlApprovalRequest(),
    watchPendingApprovalSampleDevlopmentTlList(),
    watchSampleDevelopmentWl(),
    watchSampleDevelopmentWlApprovalRequest(),
    watchPendingApprovalSampleDevlopmentWlList(),
    watchEBlockGroupProduct(),
    watchEBlockMaterial(),
    watchEBlockSize(),
    watchEBlockPattern(),
    watchEBlockArtTemplate(),
    watchSubtaskItemArtTemplateMoq(),
    watchProject(),
    watchWorkingTeamMember(),
    watchProjectCustomer(),
    watchProjectCustomerGroup(),
    watchSquad(),
    watchProjectProgram(),
    watchProjectProgramBrand(),
    watchProjectSquad(),
    watchSquadSalesMember(),
    watchTeamMember(),
    watchSquadWorkingMember(),
    watchSquadMember(),
    watchChangeLog(),
    watchChangeLogProject(),
    watchProjectApprovalRequest(),
    watchPendingApprovalProjectList(),
    watchResponsibility(),
    watchResponsibilityBrand(),
    watchResponsibilityCustomer(),
    watchResponsibilitySalesTeamMember(),
    watchResponsibilityWorkingTeamMember(),
    watchResponsibilityApprovalRequest(),
    watchPendingApprovalResponsibilityList(),
    watchHanesProject(),
    //--Generator Needle2--//
  ]);
}
