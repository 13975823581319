import React from 'react';

interface ImageProps {
  width: string;
  src: string;
  alt: string;
}

const Image: React.FC<ImageProps> = props => {
  const { width, src, alt } = props;

  return (
    <div style={{ width }}>
      <img
        style={{ display: 'block', width: '100%', height: 'auto' }}
        src={src}
        alt={alt}
      />
    </div>
  );
};

export default Image;
