import { AppAction, PageFilter } from '../app';
import {
  Program,
  ProgramCreateOrUpdatePayload,
  ProgramListFilter,
} from './program.types';

export const FETCH_PROGRAM = 'FETCH_PROGRAM';
export const FETCH_PROGRAM_LIST = 'FETCH_PROGRAM_LIST';
export const CREATE_PROGRAM = 'CREATE_PROGRAM';
export const UPDATE_PROGRAM = 'UPDATE_PROGRAM';
export const CLEAR_PROGRAM = 'CLEAR_PROGRAM';

export const fetchProgram = (id: Program['id']): AppAction => ({
  type: FETCH_PROGRAM,
  payload: id,
});

export const fetchProgramList = (
  filters: ProgramListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_PROGRAM_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createProgram = (payload: ProgramCreateOrUpdatePayload): AppAction => ({
  type: CREATE_PROGRAM,
  payload,
});

export const updateProgram = (payload: ProgramCreateOrUpdatePayload): AppAction => ({
  type: UPDATE_PROGRAM,
  payload,
});

export const clearProgram = () => ({
  type: CLEAR_PROGRAM,
});
