import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createBlock,
  fetchBlock,
  fetchBlockList,
  syncBlock,
  updateBlock,
} from '../../services/blockService';
import { createEBlock, fetchEBlockList } from '../../services/eBlockService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_BLOCK,
  CREATE_BLOCKS,
  CREATE_EBLOCKS,
  FETCH_BLOCK,
  FETCH_BLOCK_LIST,
  FETCH_EBLOCK_LIST,
  SYNC_BLOCK,
  UPDATE_BLOCK,
} from './block.actions';
import { Block, IBlockCreate, SampleVNDto } from './block.types';

function* fetchBlockSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchBlock, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchBlockListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchBlockList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchEBlockListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchEBlockList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createBlockSaga(action: AppAction) {
  try {
    const newBlock = action.payload as Block;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createBlock, newBlock);
    const successMessage = `Created ${newBlock.blockNo} block successfully.`;
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createBlocksSaga(action: AppAction) {
  const blockCreates = action.payload as IBlockCreate[];
  yield put({ type: REQUEST(action.type) });
  const result: IBlockCreate[] = [];

  for (const blockCreate of blockCreates) {
    const { block } = blockCreate;
    try {
      const { data } = yield call(createBlock, block as Block);
      result.push({ block: data, status: 'Success' });
    } catch (error) {
      result.push({
        block: block as Block,
        status: 'Error',
        errorMessage: error.response.data.message,
        hasError: true,
      });
    }
  }
  yield put({
    type: SUCCESS(action.type),
    payload: { data: result },
  });
  const successMessage = `Created blocks successfully.`;
  yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
}

function* createEBlocksSaga(action: AppAction) {
  const blockCreates = action.payload as IBlockCreate[];
  yield put({ type: REQUEST(action.type) });
  const result: IBlockCreate[] = [];

  for (const blockCreate of blockCreates) {
    const { block } = blockCreate;
    try {
      const { data } = yield call(createEBlock, block as Block);
      result.push({ block: data, status: 'Success' });
    } catch (error) {
      result.push({
        block: block as Block,
        status: 'Error',
        errorMessage: error.response.data.message,
        hasError: true,
      });
    }
  }
  yield put({
    type: SUCCESS(action.type),
    payload: { data: result },
  });
  const successMessage = `Created blocks successfully.`;
  yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
}

function* updateBlockSaga(action: AppAction) {
  try {
    const newBlock = action.payload as Block;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateBlock, newBlock);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Updated ${newBlock.blockNo} block successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* syncBlockSaga(action: AppAction) {
  try {
    const newBlock = action.payload as SampleVNDto;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(syncBlock, newBlock);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Sync ${newBlock.blockNo} block successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchBlock() {
  yield takeLatest(FETCH_BLOCK, fetchBlockSaga);
  yield debounce(250, FETCH_BLOCK_LIST, fetchBlockListSaga);
  yield debounce(250, FETCH_EBLOCK_LIST, fetchEBlockListSaga);
  yield takeLatest(CREATE_BLOCK, createBlockSaga);
  yield takeLatest(UPDATE_BLOCK, updateBlockSaga);
  yield takeLatest(CREATE_BLOCKS, createBlocksSaga);
  yield takeLatest(CREATE_EBLOCKS, createEBlocksSaga);
  yield takeLatest(SYNC_BLOCK, syncBlockSaga);
}
