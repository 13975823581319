import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchUserList, fetchUser, updateUserRole } from '../../services/userService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  FETCH_USER_LIST,
  FETCH_USER,
  UPDATE_USER_ROLE,
  fetchUser as fetchUserAction,
} from './user.actions';

function* fetchUserSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchUser, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchUserListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchUserList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateUserRoleSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const userId = action.payload.userId;
    yield call(updateUserRole, action.payload);
    yield put({ type: SUCCESS(action.type) });
    const successMessage = 'Update user role successfully';
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put(fetchUserAction(userId));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchUser() {
  yield takeLatest(FETCH_USER_LIST, fetchUserListSaga);
  yield takeLatest(FETCH_USER, fetchUserSaga);
  yield takeLatest(UPDATE_USER_ROLE, updateUserRoleSaga);
}
