import React from 'react';
import { ITaskItem } from '../../models/taskItem.model';
import { fetchTaskItemList as fetchTaskItemListService } from '../../services/taskItemService';
import { Block } from '../../state/block';
import AsyncAutoComplete from './AsyncAutoComplete';

interface TaskBlockComboBoxProps {
  handleChange: (result: Block) => void;
  selectedValue: Block;
  isDisabled?: boolean;
  taskId?: number;
  error?: boolean;
  helperText?: string;
  label?: string;
}

const TaskBlockComboBox: React.FC<TaskBlockComboBoxProps> = props => {
  const {
    handleChange,
    selectedValue,
    isDisabled = false,
    taskId,
    error,
    helperText,
    label = 'Block No',
  } = props;

  const taskBlockPromiseOptions = async (inputValue: string) => {
    const { data: taskItems } = await fetchTaskItemListService({
      filters: {
        'SMTaskId.EqualsTo': taskId!.toString(),
        'OpportunityItem.Block.BlockNo.StartsWith': inputValue,
      },
      paging: { pageNumber: 0, pageSize: 10 },
    });

    const rows = taskItems.map((taskItem: ITaskItem) => taskItem.opportunityItem!.block);
    return rows;
  };

  return (
    <AsyncAutoComplete
      name="Block"
      value={selectedValue}
      getOptionValue={(option: Block) => {
        return option.id!.toString();
      }}
      getOptionLabel={(option: Block) => {
        return option.blockNo!;
      }}
      promiseOptions={taskBlockPromiseOptions}
      placeholder={'Type Block Name'}
      handleChange={handleChange}
      isDisabled={isDisabled}
      error={error}
      helperText={helperText}
      label={label}
    />
  );
};

export default TaskBlockComboBox;
