import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createTaskLogWork,
  fetchTaskLogWork,
  fetchTaskLogWorkList,
  updateTaskLogWork,
} from '../../services/taskLogWorkService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_TASKLOGWORK,
  FETCH_TASKLOGWORK,
  FETCH_TASKLOGWORK_LIST,
  UPDATE_TASKLOGWORK,
} from './taskLogWork.actions';
import { TaskLogWorkCreateOrUpdatePayload } from './taskLogWork.types';

function* fetchTaskLogWorkSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchTaskLogWork, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchTaskLogWorkListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchTaskLogWorkList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createTaskLogWorkSaga(action: AppAction) {
  try {
    const { values, callback } = action.payload as TaskLogWorkCreateOrUpdatePayload;
    const successMessage = `Created  taskLogWork successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createTaskLogWork, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    callback();
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateTaskLogWorkSaga(action: AppAction) {
  try {
    const { values } = action.payload as TaskLogWorkCreateOrUpdatePayload;
    const successMessage = `Updated taskLogWork successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateTaskLogWork, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchTaskLogWork() {
  yield takeLatest(FETCH_TASKLOGWORK, fetchTaskLogWorkSaga);
  yield debounce(250, FETCH_TASKLOGWORK_LIST, fetchTaskLogWorkListSaga);
  yield takeLatest(CREATE_TASKLOGWORK, createTaskLogWorkSaga);
  yield takeLatest(UPDATE_TASKLOGWORK, updateTaskLogWorkSaga);
}
