import { PageFilter } from '../state/app';
import {
  ResponsibilityBrandListFilter,
  ResponsibilityBrandReplacePayLoad,
} from '../state/responsibilityBrand';
import axios from '../utils/axios';
import { IResponsibilityBrand } from '../models/responsibilityBrand.model';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
const baseUrl = `/api/responsibilityBrands`;

export const createResponsibilityBrand = async (
  responsibilityBrand: IResponsibilityBrand,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, responsibilityBrand);
};

export const fetchResponsibilityBrand = async (id: IResponsibilityBrand['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const fetchResponsibilityBrandList = async ({
  filters,
  paging,
}: {
  filters: ResponsibilityBrandListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const deleteResponsibilityBrand = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};

export const inactiveResponsibilityBrand = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}/inactive`;
  return await axios.put(requestUrl);
};

export const updateResponsibilityBrand = async (
  responsibilityBrand: IResponsibilityBrand,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, responsibilityBrand);
};

export const replaceResponsibilityBrand = async (
  responsibilityBrandReplacePayLoad: ResponsibilityBrandReplacePayLoad,
) => {
  const requestUrl = `${baseUrl}/${responsibilityBrandReplacePayLoad.responsibilityBrandId}/replace`;
  return await axios.put(requestUrl, responsibilityBrandReplacePayLoad);
};
