import { IResponsibilityWorkingTeamMember } from '../../models/responsibilityWorkingTeamMember.model';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_RESPONSIBILITY_WORKINGTEAM_MEMBER,
  FETCH_RESPONSIBILITY_WORKINGTEAM_MEMBER,
  FETCH_RESPONSIBILITY_WORKINGTEAM_MEMBER_LIST,
  UPDATE_RESPONSIBILITY_WORKINGTEAM_MEMBER,
  CREATE_RESPONSIBILITY_WORKINGTEAM_MEMBER,
  REPLACE_RESPONSIBILITY_WORKINGTEAM_MEMBER,
  SET_RESPONSIBILITY_WORKINGTEAM_MEMBER,
} from './responsibilityWorkingTeamMember.actions';
import { ResponsibilityWorkingTeamMemberState } from './responsibilityWorkingTeamMember.types';

const getInitialResponsibilityWorkingTeamMemberState = (): IResponsibilityWorkingTeamMember => ({});

const initialState: ResponsibilityWorkingTeamMemberState = {
  errorMessage: '',
  item: getInitialResponsibilityWorkingTeamMemberState(),
  items: [],
  loading: false,
  totalItems: 0,
};

export default (
  state: ResponsibilityWorkingTeamMemberState = initialState,
  action: AppAction,
): ResponsibilityWorkingTeamMemberState => {
  switch (action.type) {
    case REQUEST(FETCH_RESPONSIBILITY_WORKINGTEAM_MEMBER_LIST):
    case REQUEST(CREATE_RESPONSIBILITY_WORKINGTEAM_MEMBER):
    case REQUEST(FETCH_RESPONSIBILITY_WORKINGTEAM_MEMBER):
    case REQUEST(UPDATE_RESPONSIBILITY_WORKINGTEAM_MEMBER):
    case REQUEST(REPLACE_RESPONSIBILITY_WORKINGTEAM_MEMBER):
      return { ...state, loading: true };
    case SUCCESS(FETCH_RESPONSIBILITY_WORKINGTEAM_MEMBER_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_RESPONSIBILITY_WORKINGTEAM_MEMBER_LIST):
    case FAILURE(CREATE_RESPONSIBILITY_WORKINGTEAM_MEMBER):
    case FAILURE(FETCH_RESPONSIBILITY_WORKINGTEAM_MEMBER):
    case FAILURE(UPDATE_RESPONSIBILITY_WORKINGTEAM_MEMBER):
    case FAILURE(REPLACE_RESPONSIBILITY_WORKINGTEAM_MEMBER):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_RESPONSIBILITY_WORKINGTEAM_MEMBER):
    case SUCCESS(FETCH_RESPONSIBILITY_WORKINGTEAM_MEMBER):
    case SUCCESS(UPDATE_RESPONSIBILITY_WORKINGTEAM_MEMBER):
    case SUCCESS(REPLACE_RESPONSIBILITY_WORKINGTEAM_MEMBER):
      return { ...state, loading: false, item: action.payload.data };
    case SET_RESPONSIBILITY_WORKINGTEAM_MEMBER:
      return { ...state, items: action.payload };
    case CLEAR_RESPONSIBILITY_WORKINGTEAM_MEMBER:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
