import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import { CHECK_AUTH, LOGIN, LOGOUT, SET_TOKEN } from './auth.action';
import { AuthState } from './auth.types';

const initialState: AuthState = {
  displayName: '',
  firstName: '',
  lastName: '',
  userId: 0,
  errorMessage: '',
  isLoggedIn: false,
  loading: false,
  token: '',
  user: {},
};

export default (state: AuthState = initialState, action: AppAction): AuthState => {
  switch (action.type) {
    case REQUEST(LOGIN):
    case REQUEST(CHECK_AUTH):
    case REQUEST(LOGOUT):
      return { ...state, loading: true };
    case SUCCESS(LOGIN):
      return {
        ...state,
        errorMessage: '',
        isLoggedIn: true,
        loading: false,
      };
    case SUCCESS(CHECK_AUTH):
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        displayName: action.payload.login,
        userId: action.payload.id,
        user: action.payload,
      };
    case FAILURE(LOGIN):
    case FAILURE(CHECK_AUTH):
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        errorMessage: action.payload.errorMessage,
      };
    case SUCCESS(LOGOUT):
    case FAILURE(LOGOUT):
      return { ...state, ...initialState, token: '', isLoggedIn: false };
    case SET_TOKEN:
      return { ...state, token: action.payload, isLoggedIn: true };
    default:
      return state;
  }
};
