import { User } from '../user';
import { AppState } from '../configureStore';

export interface Credentials {
  username: string;
  password: string;
}

export interface AuthState {
  loading: boolean;
  errorMessage: string;
  displayName: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  userId: number;
  isLoggedIn: boolean;
  token: string;
  user?: User;
}

export const authStateSelector = (appState: AppState) => appState.auth;
