import {
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../state/configureStore';
import { TableLoading } from '../../../components';
import {
  fetchOpportunityApprovalRequestListByOpportunityId,
  OpportunityApprovalRequestState,
} from '../../../../state/opportunityApprovalRequest';

interface ApprovalListSectionProps {
  opportunityId: number;
}

const ApprovalListSection: React.FC<ApprovalListSectionProps> = (props) => {
  const { opportunityId } = props;
  const dispatch = useDispatch();

  const { items: opportunityApprovalRequests, loading: isLoading } = useSelector<
    AppState,
    OpportunityApprovalRequestState
  >((state) => state.opportunityApprovalRequest);

  useEffect(() => {
    dispatch(fetchOpportunityApprovalRequestListByOpportunityId(opportunityId));
  }, [opportunityId, dispatch]);

  return (
    <>
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Requested By</TableCell>
              <TableCell>Has Responded</TableCell>
              <TableCell>Result</TableCell>
              <TableCell>Comment</TableCell>
              {/* <TableCell>Account Owner</TableCell> */}
              <TableCell>Responded By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={isLoading} colSpan={7}>
              {opportunityApprovalRequests.map((approvalRequest, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {approvalRequest.requestedByUser.fullNameWithUserName}
                    </TableCell>
                    <TableCell>{approvalRequest.hasResponded ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{approvalRequest.responseResult}</TableCell>
                    <TableCell>{approvalRequest.comment}</TableCell>
                    {/* <TableCell>{approvalRequest.accountOwner ? approvalRequest.accountOwner.firstName : ''}</TableCell> */}
                    <TableCell>
                      {approvalRequest.respondedByUser
                        ? approvalRequest.respondedByUser.fullNameWithUserName
                        : ''}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>
      </CardContent>
    </>
  );
};

export default ApprovalListSection;
