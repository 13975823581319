import { PageFilter } from '../state/app';
import {
  OpportunityCustomerGroup,
  OpportunityCustomerGroupListFilter,
} from '../state/opportunityCustomerGroup';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/opportunityCustomerGroups`;

export const fetchOpportunityCustomerGroupList = async ({
  filters,
  paging,
}: {
  filters: OpportunityCustomerGroupListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchOpportunityCustomerGroup = async (
  id: OpportunityCustomerGroup['id'],
) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createOpportunityCustomerGroup = async (
  opportunityCustomerGroup: OpportunityCustomerGroup,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, opportunityCustomerGroup);
};

export const updateOpportunityCustomerGroup = async (
  opportunityCustomerGroup: OpportunityCustomerGroup,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, opportunityCustomerGroup);
};

export const deleteOpportunityCustomerGroup = async (
  id: OpportunityCustomerGroup['id'],
) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};
