import { Grid } from '@material-ui/core';
import React from 'react';
import { IProjectSummaryReportFilter } from '../../../../state/report';
import { SearchField } from '../../../components';
import {
  FilterContainer,
  IFilterContainerConfigProps,
} from '../../../components/FilterContainer';

const ProjectCustomerSummaryReportFilter: React.FC<
  IFilterContainerConfigProps<IProjectSummaryReportFilter>
> = props => {
  return (
    <FilterContainer
      {...props}
      render={filterProps => {
        const { localFilter, handleChangeFilter } = filterProps;
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Company"
                filterKey="Company.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Project"
                filterKey="Name.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Owner"
                filterKey="OwnerName.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Squad"
                filterKey="SquadName.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Program"
                filterKey="ProgramName.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Brand"
                filterKey="BrandName.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Customer Group"
                filterKey="CustomerGroupName.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Customer"
                filterKey="CustomerDisplayName.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default ProjectCustomerSummaryReportFilter;
