import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PageFilter } from '../../../state/app';
import { clearProductType, fetchProductTypeList } from '../../../state/productType';
import { ProductTypeListFilter, ProductTypeState } from '../../../state/productType';
import { AppState } from '../../../state/configureStore';
import useStyles from './productTypeList.style';
import { PageContainer, PageTitle, SearchField, TableLoading } from '../../components';

const ProductTypeList: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [filters, setFilters] = useState<ProductTypeListFilter>({});
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const {
    items: productTypes,
    totalItems,
    loading,
  } = useSelector<AppState, ProductTypeState>((state) => state.productType);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchProductTypeList(filters, paging));
    return function cleanUp() {
      dispatch(clearProductType());
    };
  }, [dispatch, paging, filters]);

  return (
    <PageContainer>
      <PageTitle>ProductType</PageTitle>
      <div className={classes.filterContainer}>
        <SearchField
          filterKey="Name.startsWith"
          filters={filters}
          label="Name"
          onChange={handleChangeFilter}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={2}>
            {productTypes.map((productType, index) => (
              <TableRow key={productType.id}>
                <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                <TableCell>{productType.name}</TableCell>
              </TableRow>
            ))}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default ProductTypeList;
