import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CREATE_SUBTASK_ITEM_FORECAST_LIST,
  CLEAR_SUBTASK_ITEM_FORECAST,
  FETCH_SUBTASK_ITEM_FORECAST_LIST,
} from './subtaskItemForecast.actions';
import { SubtaskItemForecastState } from './subtaskItemForecast.types';
import { ISubtaskItemForecast } from '../../models/subtaskItemForecast.model';
import { CREATE_MULTIPLE_SUBTASK_ITEM_MOQ_LIST } from '../subtaskItemMoq';

const initialState: SubtaskItemForecastState = {
  errorMessage: '',
  item: {} as Readonly<ISubtaskItemForecast>,
  items: [] as ISubtaskItemForecast[],
  loading: false,
  totalItems: 0,
};

export default (
  state: SubtaskItemForecastState = initialState,
  action: AppAction,
): SubtaskItemForecastState => {
  switch (action.type) {
    case REQUEST(FETCH_SUBTASK_ITEM_FORECAST_LIST):
    case REQUEST(CREATE_SUBTASK_ITEM_FORECAST_LIST):
    case REQUEST(CREATE_MULTIPLE_SUBTASK_ITEM_MOQ_LIST):
      return { ...state, loading: true };
    case SUCCESS(CREATE_SUBTASK_ITEM_FORECAST_LIST):
    case SUCCESS(CREATE_MULTIPLE_SUBTASK_ITEM_MOQ_LIST):
    case FAILURE(CREATE_SUBTASK_ITEM_FORECAST_LIST):
      return {
        ...state,
        loading: false,
      };
    case SUCCESS(FETCH_SUBTASK_ITEM_FORECAST_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_SUBTASK_ITEM_FORECAST_LIST):
    case FAILURE(CREATE_MULTIPLE_SUBTASK_ITEM_MOQ_LIST):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case CLEAR_SUBTASK_ITEM_FORECAST:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
