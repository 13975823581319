import { Button, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ISampleDevelopmentTl } from '../../../../models/sampleDevelopmentTl.model';

import { Save as SaveIcon, Send as SendIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import SampleDevelopmentStatus from '../../../../constants/sampleDevelopmentStatus';
import { AppState } from '../../../../state/configureStore';
import {
  createSampleDevelopmentTl,
  downloadSampleDevelopmentTl,
  fetchSampleDevelopmentTl,
  SampleDevelopmentTlState,
  submitSampleDevelopmentTlForApproval,
  updateSampleDevelopmentTl,
  clearSampleDevelopmentTl,
} from '../../../../state/sampleDevelopmentTl';
import {
  Loading,
  PageContainer,
  PageTitle,
  PageTopMenuContainer,
} from '../../../components';
import SampleDevelopmentTlHead from './SampleDevelopmentTlHead';
import SampleDevelopmentTlJob from './SampleDevelopmentTlJob';
import SampleDevelopmentTlDesign from './SampleDevelopmentTlDesign';
import SampleDevelopmentTlPrinting from './SampleDevelopmentTlPrinting';
import SampleDevelopmentTlMaterial from './SampleDevelopmentTlMaterial';
import SampleDevelopmentTlWashing from './SampleDevelopmentTlWashing';
import SampleDevelopmentTlTransfer from './SampleDevelopmentTlTransfer';
import SampleDevelopmentTlSpecialInstruction from './SampleDevelopmentTlSpecialInstruction';
import SampleDevelopmentTlMarketing from './SampleDevelopmentTlMarketing';
import SampleDevelopmentTlService from './SampleDevelopmentTlService';
import SampleDevelopmentTlSpecial from './SampleDevelopmentTlSpecial';
import SampleDevelopmentTlFilePath from './SampleDevelopmentTlFilePath';
import SampleDevelopmentTlAlfrescoLink from './SampleDevelopmentTlAlfrescoLink';
import moment from 'moment';

const statusAllowForSubmitForApproval = [
  SampleDevelopmentStatus.PENDING_FOR_APPROVAL,
  SampleDevelopmentStatus.REJECTED,
];

interface SampleDevelopmentTlFormProps {
  id: string;
}

const SampleDevelopmentTlSchema = Yup.object().shape({
  customer: Yup.object().nullable(true).required('Customer is required'),
  block: Yup.object().nullable(true).required('Block is required'),
  smTask: Yup.object().nullable(true).required('Task is required'),
  salesTeamMember: Yup.object().nullable(true).required('SalesTeamMember is required'),
});

const SampleDevelopmentTlForm: React.FC<SampleDevelopmentTlFormProps> = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: routeParamsId } = useParams<{
    id: '';
  }>();
  const sampleDevelopmentTlId = id ? id : routeParamsId;
  const isUpdateMode = sampleDevelopmentTlId !== undefined;
  const { item: sampleDevelopmentTl, loading: isLoading } = useSelector<
    AppState,
    SampleDevelopmentTlState
  >((s) => s.sampleDevelopmentTl);

  const redirectToSampleDevelopmentTlList = () => {
    history.goBack();
  };

  const handleSubmit = (values: ISampleDevelopmentTl) => {
    if (isUpdateMode) {
      dispatch(updateSampleDevelopmentTl({ values }));
    } else {
      dispatch(createSampleDevelopmentTl({ values }));
    }
  };

  useEffect(() => {
    if (sampleDevelopmentTlId) {
      dispatch(fetchSampleDevelopmentTl(+sampleDevelopmentTlId));
    }
    return function cleanUp() {
      dispatch(clearSampleDevelopmentTl());
    };
  }, [sampleDevelopmentTlId, dispatch]);

  return (
    <PageContainer>
      <Formik
        enableReinitialize={true}
        initialValues={{
          customer: null,
          smTask: null,
          block: null,
          salesTeamMember: null,
          dueDate: moment().format('YYYY-MM-DD'),
          seeModel: moment().format('YYYY-MM-DD'),
          quantity: 0,
          quantityUnit: 'PCS',
          jobTypeDirectPrint: false,
          jobTypeHeatTransfer: false,
          jobTypePlastisol: false,
          jobTypeProcessPlastisol: false,
          jobTypeLithoOffsetPlastisol: false,
          jobTypeSublimation: false,
          jobTypeOffsetSublimation: false,
          jobTypeSolventBase: false,
          jobTypeWaterBase: false,
          jobTypeOilBase: false,
          jobTypeStickerDieCut: false,
          jobTypeEtc: false,
          jobTypeEtcData: '',
          jobTypeReflective: false,
          jobTypeRainbowReflective: false,
          jobTypeFoil: false,
          jobTypeFlock: false,
          jobTypePet: false,
          jobTypePet75: false,
          jobTypePet105: false,
          jobTypePaper75: false,
          jobTypePaper105: false,
          jobTypeHotPeelPaper: false,
          jobTypeMattePaper: false,
          jobTypeGlossPaper: false,
          jobTypeTexturePaper: false,
          jobTypeTransparentPaper: false,
          jobTypeMetallic: false,
          jobTypeIridescent: false,
          jobTypeGlitter: false,
          jobTypeGlowDark: false,
          jobTypeSuede: false,
          jobTypePuff: false,
          jobTypeThermachromic: false,
          jobTypeEtc2: false,
          jobTypeEtc2Data: '',
          designOwn: false,
          designCustomer: false,
          designInfoOriginal: false,
          designInfoArtFile: false,
          designInfoSpecsheet: false,
          designInfoBlockFilmPlate: false,
          designInfoEtc: false,
          designInfoEtcData: '',
          designArtTif: false,
          designArtEps: false,
          designArtJpg: false,
          designArtPdf: false,
          designArtAi: false,
          designArtQxp: false,
          designSoftPagemaker: false,
          designSoftPhotoshop: false,
          designSoftFreehand: false,
          designSoftQuark: false,
          designSoftAcrobat: false,
          designSoftIllustrator: false,
          designSoftEtc: false,
          designSoftEtcData: '',
          printColorSolidSeries: false,
          printColorSolidSeriesData: '',
          printColorProcessSeries: false,
          printColorProcessSeriesData: '',
          printColorEtc: false,
          printColorEtcData: '',
          printBackUpClear: false,
          printBackUpWhite: false,
          printBackUpUnistrenght: false,
          printBackUpDyeBlockingSilver: false,
          printBackUpEtc: false,
          printBackUpEtcData: '',
          printPowderAdhesive: false,
          printAdhesive: false,
          printAdhesiveAndPowder: false,
          printTTAdhesive: false,
          printTTAdhesiveAndPowder: false,
          printAdhesiveEtc: false,
          printAdhesiveEtcData: '',
          dieCutShape: false,
          dieCutPiece: false,
          dieCutSheet: false,
          dieCutEtc: false,
          dieCutEtcData: '',
          dieCutDistanceX: 0,
          dieCutDistanceY: 0,
          dieCutDistanceUnit: 'MM',
          dieCutQuantity: 0,
          dieCutQuantityUnit: 'PCS',
          custTypeGarment: false,
          custTypeSportwear: false,
          custTypeUnderwear: false,
          custTypeWorkwear: false,
          custTypeSwimmingSuit: false,
          custTypeSock: false,
          custTypeShoe: false,
          custTypeLeather: false,
          custTypeElectronic: false,
          custTypeEtc: false,
          custTypEtcData: '',
          substrateInfoSample: false,
          substrateInfoSpecification: false,
          substrateInfoTestingStandard: false,
          substrateInfoTransferStandard: false,
          substrateInfoEtc: false,
          substrateInfoEtcData: '',
          substrateTypeOrganic: false,
          substrateTypeSynthetic: false,
          substrateTypeLeather: false,
          substrateTypeFur: false,
          substrateTypePlastic: false,
          substrateTypeCeramic: false,
          substrateTypeEtc: false,
          substrateTypeEtcData: '',
          substrateColorWhite: false,
          substrateColorLight: false,
          substrateColorMiddle: false,
          substrateColorDark: false,
          substrateColorBlack: false,
          substrateColorEtc: false,
          substrateColorEtcData: '',
          substrateElasticityLow: false,
          substrateElasticityMedium: false,
          substrateElasticityHigh: false,
          substrateElasticityEtc: false,
          substrateElasticityEtcData: '',
          substrateSurfaceSmooth: false,
          substrateSurfaceMedium: false,
          substrateSurfaceRough: false,
          substrateSurfaceEtc: false,
          substrateSurfaceEtcData: '',
          washingInfoStandardEU: false,
          washingInfoStandardUS: false,
          washingInfoStandardAsia: false,
          washingInfoStandardNike: false,
          washingInfoStandardDecathlon: false,
          washingInfoStandardEtc: false,
          washingInfoStandardEtcData: '',
          washingInfoTest5Washes: false,
          washingInfoTest10Washes: false,
          washingInfoTest15Washes: false,
          washingInfoTest20Washes: false,
          washingInfoTest30Washes: false,
          washingInfoTest50Washes: false,
          washingInfoTestEtcWashes: false,
          washingInfoTestEtcDataWashes: '',
          washingInfoMacBrand: '',
          washingInfoMacSeries: '',
          washingInfoMacSpeed: '',
          washingInfoMacDelicate: false,
          washingInfoMacNormal: false,
          washingInfoMacHeavy: false,
          washingInfoDryTumbleLow: false,
          washingInfoDryTumbleMed: false,
          washingInfoDryTumbleHigh: false,
          washingInfoDryTumbleLine: false,
          washingInfoDryTumbleLayFlat: false,
          washingInfoDetergentVolumn: 0,
          washingInfoDetergentBrand: '',
          washingInfoWaterVolumn: 0,
          washingInfoWaterHot60C: false,
          washingInfoWaterWarm40C: false,
          washingInfoWaterCold30C: false,
          washingInfoFabricVolumn: 0,
          transferInfoMacBrand: '',
          transferInfoMacSeries: '',
          transferInfoTemperature: false,
          transferInfoTemperatureData: '',
          transferInfoTemperatureC: false,
          transferInfoTemperatureF: false,
          transferInfoPressure: false,
          transferInfoPressureData: '',
          transferInfoPressurePsiBar: false,
          transferInfoPressureKgCm2: false,
          transferInfoDwellTime: false,
          transferInfoDwellTimeData: '',
          transferInfoDwellTimeSec: false,
          variableAmount: 0,
          variableSize: false,
          variableKeySize: false,
          variableStyleNo: false,
          variableColor: false,
          variableFabricContent: false,
          variableInstruction: false,
          variableWashingSymbol: false,
          variableArt: false,
          variableFactoryCode: false,
          variableCountry: false,
          variableBarcode: false,
          variablePrice: false,
          variableOther: false,
          variableOtherData: '',
          packingPlain: false,
          packingEtc: false,
          packingEtcData: '',
          specialInstruction: '',
          topProductLevel: false,
          intermediateProductLevel: false,
          lowerProductLevel: false,
          withoutQuotation: false,
          quotation: false,
          quotationNumber: '',
          flatPrice: false,
          progressiveRate: false,
          progressiveRateQty: 0,
          progressiveRateQtyUnit: 'PCS',
          progressiveRatePrice: 0,
          progressiveRatePriceUnit: 'THB',
          forecast: 0,
          haveRegulation: false,
          withoutRegulation: false,
          regulationFreePVC: false,
          regulationFreePhalate: false,
          regulationFreeEtc: false,
          regulationFreeEtcData: '',
          regulationStandardUS: false,
          regulationStandardEU: false,
          regulationStandardEtc: false,
          regulationStandardEtcData: '',
          serviceTransfer: false,
          serviceTransferWashing: false,
          samplePieceOriginal: false,
          samplePieceOriginalQty: 0,
          samplePieceWe: false,
          samplePieceWeQty: 0,
          samplePieceBlock: '',
          samplePieceSpec: '',
          fabricCustomer: false,
          fabricCustomerQty: 0,
          fabricCustomerType: '',
          fabricWe: false,
          fabricWeQty: 0,
          fabricWeType: '',
          specialRequirement: '',
          deliveryDate: null,
          tradingSaleDecal: false,
          tradingSaleRollingMac: false,
          tradingSaleHire: false,
          tradingSalePress: false,
          specialMaterial: '',
          specialPrinting: '',
          specialDesign: '',
          specialMainProduction: '',
          specialFinishing: '',
          specialQC: '',
          specialPacking: '',
          specialOther: '',
          sampleDevelopmentTlSubstrates: [],
          finishSizeUnit: 'MM',
          labelDiUnit: 'MM',
          labelDiWidth: 0,
          labelDiLength: 0,
          finishSizeWidth: 0,
          finishSizeLength: 0,
          finishSizeHigh: 0,
          jobType: 'SAMPLE',
          orderCompany: null,
          orderCompanyId: 0,
          substratePrimaryOption: '1',
          substrateOther: '',
          filePath: '',
          alfrescoLink: '',
          ...sampleDevelopmentTl,
        }}
        validationSchema={SampleDevelopmentTlSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        isInitialValid={true}
      >
        {({ values, handleChange, errors, submitForm, setFieldValue }) => {
          return (
            <Form>
              <PageTitle>
                {isUpdateMode
                  ? `Update Sample Development TL ${values.sampleDevNumber}`
                  : `New Sample Development TL`}
              </PageTitle>
              <PageTopMenuContainer>
                {statusAllowForSubmitForApproval.includes(
                  sampleDevelopmentTl.status!,
                ) && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      dispatch(
                        submitSampleDevelopmentTlForApproval(sampleDevelopmentTl.id!),
                      );
                    }}
                    startIcon={<SendIcon />}
                  >
                    {`Submit for Approval`}
                  </Button>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  type="submit"
                >
                  {isUpdateMode ? `Update` : `Create`}
                </Button>
                <Button variant="contained" onClick={redirectToSampleDevelopmentTlList}>
                  Cancel
                </Button>
                {isUpdateMode && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      dispatch(
                        downloadSampleDevelopmentTl(parseInt(sampleDevelopmentTlId)),
                      );
                    }}
                  >
                    Download PDF
                  </Button>
                )}
              </PageTopMenuContainer>
              <Loading isLoading={isLoading}>
                <CardContent>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={12}
                  >
                    <SampleDevelopmentTlHead
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={12}
                  >
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      item
                      xs={12}
                    >
                      <Paper variant="outlined">
                        <CardContent>
                          <Grid item xs={12}>
                            <Typography>Product Info</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentTlJob />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentTlDesign />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentTlPrinting />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentTlMaterial
                              handleChange={handleChange}
                              errors={errors}
                              setFieldValue={setFieldValue}
                              values={values}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentTlWashing />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentTlTransfer />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentTlSpecialInstruction />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentTlMarketing />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentTlService
                              handleChange={handleChange}
                              errors={errors}
                              setFieldValue={setFieldValue}
                              values={values}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentTlSpecial />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentTlAlfrescoLink />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentTlFilePath />
                          </Grid>
                        </CardContent>
                      </Paper>
                    </Grid>
                  </Grid>
                </CardContent>
              </Loading>
            </Form>
          );
        }}
      </Formik>
    </PageContainer>
  );
};

export default SampleDevelopmentTlForm;
