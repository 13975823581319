import React from 'react';
import { FastField, FieldProps } from 'formik';
import { Checkbox, FormControlLabel } from '@material-ui/core';

interface CheckboxFastFieldProps {
  name: string;
  label: string;
  [x: string]: any;
}

const CheckboxFastField: React.FC<CheckboxFastFieldProps> = props => {
  const { name, label, handleChange, ...other } = props;
  return (
    <FastField name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }: FieldProps<any>) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={field.value}
                onChange={e => form.setFieldValue(name, e.target.checked)}
                {...other}
              />
            }
            label={label}
          />
        );
      }}
    </FastField>
  );
};

export default CheckboxFastField;
