import { AppAction, AppCallback, PageFilter } from '../app';
import { Block, BlockListFilter, IBlockCreate, SampleVNDto } from './block.types';

export const FETCH_BLOCK = 'FETCH_BLOCK';
export const FETCH_EBLOCK = 'FETCH_EBLOCK';
export const FETCH_BLOCK_LIST = 'FETCH_BLOCK_LIST';
export const CREATE_BLOCK = 'CREATE_BLOCK';
export const UPDATE_BLOCK = 'UPDATE_BLOCK';
export const CLEAR_BLOCK = 'CLEAR_BLOCK';
export const CREATE_BLOCKS = 'CREATE_BLOCKS';
export const CREATE_EBLOCKS = 'CREATE_EBLOCKS';
export const FETCH_EBLOCK_LIST = 'FETCH_EBLOCK_LIST';
export const SET_BLOCK = 'SET_BLOCK';
export const SYNC_BLOCK = 'SYNC_BLOCK';

export const fetchBlock = (id: Block['id']): AppAction => ({
  type: FETCH_BLOCK,
  payload: id,
});

export const fetchEBlock = (id: Block['id']): AppAction => ({
  type: FETCH_EBLOCK,
  payload: id,
});

export const fetchBlockList = (
  filters: BlockListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_BLOCK_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchEBlockList = (
  filters: BlockListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_EBLOCK_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createBlock = (newBlock: Block, callback?: AppCallback): AppAction => ({
  type: CREATE_BLOCK,
  payload: newBlock,
  callback,
});

export const createBlocks = (payload: IBlockCreate[]): AppAction => ({
  type: CREATE_BLOCKS,
  payload,
});

export const createEBlocks = (payload: IBlockCreate[]): AppAction => ({
  type: CREATE_EBLOCKS,
  payload,
});

export const updateBlock = (newBlock: Block, callback?: AppCallback): AppAction => ({
  type: UPDATE_BLOCK,
  payload: newBlock,
});

export const setBlock = (block: Block): AppAction => ({
  type: SET_BLOCK,
  payload: block,
});

export const clearBlock = () => ({
  type: CLEAR_BLOCK,
});

export const syncBlock = (block: SampleVNDto): AppAction => ({
  type: SYNC_BLOCK,
  payload: block,
});
