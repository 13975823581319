import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    charactersRemaining: {
      marginTop: 5,
    },
    buttonStyle: {
      marginRight: 0,
    },
    editor: {
      height: '300px',
    },
  }),
);

export default useStyles;
