import {
  Button,
  Card,
  CardContent,
  Fade,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SubtaskStatusLabel } from '../../../../constants';
import { AppState } from '../../../../state/configureStore';
import {
  clearSubtask,
  clearSubtaskItemSection,
  fetchRelatedSubtaskListByTaskId,
  SubtaskState,
} from '../../../../state/subtask';
import { PageContainer, UserLetterAvatar } from '../../../components';
import { DateDisplay } from '../../../components/DateTimeDisplay';
import SubtaskActionMenu from './SubtaskActionMenu';
import SubtaskCreateDialog from './SubtaskCreateDialog';
import SubtaskEditDialog from './SubtaskEditDialog';
import useStyles from './subtaskList.style';
import { History } from 'history';
import history from '../../../../history';

interface SubtaskListProps {
  smTaskId: number;
}

const SubtaskList: React.FC<SubtaskListProps> = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const openModalWithSubtaskId = queryString.get('subtask');

  const { smTaskId } = props;
  const classes = useStyles();
  const { items: subtaskList } = useSelector<AppState, SubtaskState>(
    (state) => state.subtask,
  );

  const [openSubtaskCreateDialog, setOpenSubtaskCreateDialog] = useState(false);
  const [openSubtaskEditDialog, setOpenSubtaskEditDialog] = useState(false);
  const [editSubtaskId, setEditSubtaskId] = useState<number | null>(null);

  useEffect(() => {
    dispatch(fetchRelatedSubtaskListByTaskId(smTaskId));
  }, [dispatch, smTaskId]);

  useEffect(() => {
    return function cleanUp() {
      dispatch(clearSubtask());
    };
  }, [dispatch]);

  useEffect(() => {
    if (openModalWithSubtaskId !== null) {
      setEditSubtaskId(+openModalWithSubtaskId);
      setOpenSubtaskEditDialog(true);
    }
  }, [openModalWithSubtaskId]);

  const handleSetEditSubtaskId = (subtaskId: number) => {
    setEditSubtaskId(subtaskId);
    setOpenSubtaskEditDialog(true);
  };

  return (
    <>
      {/* <Card className={classes.root}> */}

      <Paper variant="outlined">
        <CardContent>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(clearSubtaskItemSection());
              setOpenSubtaskCreateDialog(true);
            }}
            className={classes.button}
          >
            <AddIcon className={classes.leftIcon} />
            New Subtask
          </Button>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>Subject</TableCell>
                <TableCell>Type Name</TableCell>
                <TableCell>Assignee</TableCell>
                <TableCell>DueDate</TableCell>
                <TableCell>CompleteDate</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subtaskList.map((subtask, index) => {
                const assigneeUsers =
                  subtask.smSubtaskAssignees!.length > 0
                    ? subtask.smSubtaskAssignees!.map(
                        (smSubtaskAssignee) => smSubtaskAssignee.user,
                      )
                    : [];
                return (
                  <TableRow key={subtask.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{subtask.subject}</TableCell>
                    <TableCell>{subtask.smSubtaskType!.name}</TableCell>
                    <TableCell>
                      {assigneeUsers.length > 0 ? (
                        <UserLetterAvatar users={assigneeUsers} />
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell>
                      <DateDisplay value={subtask.dueDate} />
                    </TableCell>
                    <TableCell>
                      <DateDisplay value={subtask.completedDate} />
                    </TableCell>
                    <TableCell>{SubtaskStatusLabel[subtask.status!]}</TableCell>
                    <TableCell>
                      <SubtaskActionMenu
                        subtask={subtask}
                        setEditSubtaskId={handleSetEditSubtaskId}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </CardContent>
      </Paper>

      <SubtaskCreateDialog
        open={openSubtaskCreateDialog}
        handleClose={() => {
          setOpenSubtaskCreateDialog(false);
        }}
        subtaskId={null}
      />
      <SubtaskEditDialog
        open={openSubtaskEditDialog}
        handleClose={() => {
          dispatch(fetchRelatedSubtaskListByTaskId(smTaskId));
          setEditSubtaskId(null);
          setOpenSubtaskEditDialog(false);

          history.replace(history.location.pathname);
        }}
        subtaskId={editSubtaskId}
      />
    </>
  );
};

export default SubtaskList;
