import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Add as AddIcon,
  FindReplace as FindReplaceIcon,
  ToggleOff as ToggleOffIcon,
  Save as SaveAsIcon,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IResponsibilitySalesTeamMember } from '../../../../models/responsibilitySalesTeamMember.model';
import { PageFilter } from '../../../../state/app';
import { AppState } from '../../../../state/configureStore';
import {
  fetchResponsibilitySalesTeamMemberList,
  inactiveResponsibilitySalesTeamMember,
  ResponsibilitySalesTeamMemberListFilter,
  ResponsibilitySalesTeamMemberState,
  setResponsibilitySalesTeamMember,
  updateResponsibilitySalesTeamMember,
} from '../../../../state/responsibilitySalesTeamMember';
import { useFilter } from '../../../hooks';
import AddResponsibilitySalesTeamMemberDialog from './AddResponsibilitySalesTeamMemberDialog';
import ResponsibilitySalesTeamMemberReplaceDialog from './ResponsibilitySalesTeamMemberReplaceDialog';
import { DateDisplay } from '../../../components';
import { SalesTeamMember } from '../../../../state/salesTeamMember/salesTeamMember.types';
import SalesTeamMemberDialog from '../../../components/SalesTeamComboBox/SalesTeamMemberDialog';

const useStyles = makeStyles((theme) => ({
  addButton: {
    position: 'absolute',
    right: '5px',
    top: '20px',
  },
  container: {
    marginTop: '20px',
    position: 'relative',
  },
  table: {
    marginTop: '30px',
    position: 'static',
  },
  showAll: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
  },
  button: {
    fontSize: 10,
  },
  title: {
    position: 'relative',
    left: '5px',
    top: '25px',
  },
}));

interface ResponsibilitySalesTeamMemberFormProps {
  responsibilityId?: number;
  disabled?: boolean;
}

const ResponsibilitySalesTeamMemberForm: React.FC<
  ResponsibilitySalesTeamMemberFormProps
> = (props) => {
  const { responsibilityId, disabled } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [
    openAddResponsibilitySalesTeamMemberDialog,
    setOpenAddResponsibilitySalesTeamMemberDialog,
  ] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const [
    openResponsibilitySalesTeamMemberReplaceDialog,
    setOpenResponsibilitySalesTeamMemberReplaceDialog,
  ] = useState(false);

  const [selectedSalesTeamMember, setSelectedSalesTeamMember] =
    useState<SalesTeamMember | null>(null);

  const [
    selectedResponsibilitySalesTeamMember,
    setSelectedResponsibilitySalesTeamMember,
  ] = useState<IResponsibilitySalesTeamMember>({});

  const {
    items: responsibilitySalesTeamMembers,
    totalItems,
    loading,
  } = useSelector<AppState, ResponsibilitySalesTeamMemberState>(
    (state) => state.responsibilitySalesTeamMember,
  );

  const [filters, setFilters] = useFilter<ResponsibilitySalesTeamMemberListFilter>({
    'ResponsibilityId.EqualsTo': responsibilityId,
  });
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 99999,
  });

  const addNewSalesTeamMember = () => {
    setOpenAddResponsibilitySalesTeamMemberDialog(true);
  };

  const ReplaceSalesTeamMember = (selectedRow: IResponsibilitySalesTeamMember) => {
    setOpenResponsibilitySalesTeamMemberReplaceDialog(true);
    setSelectedResponsibilitySalesTeamMember(selectedRow);
  };

  const handleClose = () => {
    setOpenAddResponsibilitySalesTeamMemberDialog(false);
  };

  const handleCloseReplace = () => {
    setOpenResponsibilitySalesTeamMemberReplaceDialog(false);
  };

  useEffect(() => {
    if (responsibilityId) {
      dispatch(fetchResponsibilitySalesTeamMemberList(filters, paging));
    }
  }, [responsibilityId, dispatch]);

  return (
    <>
      <div className={classes.container}>
        <Typography className={classes.title} variant="h6" component="span">
          Sales
        </Typography>
        <Typography className={classes.title} component="span" color="secondary">
          &nbsp;* (Please select Sales)
        </Typography>
        <div className={classes.addButton}>
          <Button
            variant="contained"
            color="primary"
            onClick={addNewSalesTeamMember}
            startIcon={<AddIcon />}
            disabled={disabled}
          >
            New Sales
          </Button>
        </div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Sales Group</TableCell>
              <TableCell>Sales Team</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Active Date</TableCell>
              <TableCell>Inactive Date</TableCell>
              <TableCell>Status.</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {responsibilitySalesTeamMembers
              .filter((responsibilitySalesTeamMember) => {
                if (!showAll) {
                  return responsibilitySalesTeamMember.active === true;
                }
                return responsibilitySalesTeamMember;
              })
              .map((responsibilitySalesTeamMember, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {
                        responsibilitySalesTeamMember?.salesTeamMember?.salesTeam
                          ?.salesGroup?.name
                      }
                    </TableCell>
                    <TableCell>
                      <Link
                        href="#"
                        onClick={() => {
                          setSelectedSalesTeamMember(
                            responsibilitySalesTeamMember?.salesTeamMember?.salesTeam ||
                              null,
                          );
                        }}
                      >
                        {responsibilitySalesTeamMember?.salesTeamMember?.salesTeam?.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {
                        responsibilitySalesTeamMember?.salesTeamMember?.user
                          ?.fullNameWithUserName
                      }
                    </TableCell>
                    <TableCell>
                      <DateDisplay value={responsibilitySalesTeamMember?.activeDate} />
                    </TableCell>
                    <TableCell>
                      <DateDisplay value={responsibilitySalesTeamMember?.inactiveDate} />
                    </TableCell>
                    <TableCell>
                      <FormControl>
                        <InputLabel>Status</InputLabel>
                        <Select
                          disabled={disabled}
                          style={{ width: '100px' }}
                          labelId="status"
                          id="status"
                          value={responsibilitySalesTeamMember?.status}
                          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                            const respMembers = responsibilitySalesTeamMembers.map(
                              (item) => {
                                if (item.id === responsibilitySalesTeamMember.id) {
                                  item.status = event.target.value as string;
                                }
                                return item;
                              },
                            );

                            dispatch(setResponsibilitySalesTeamMember(respMembers));
                          }}
                        >
                          <MenuItem value={'P'}>Sales</MenuItem>
                          <MenuItem value={'S1'}>Sales1</MenuItem>
                          <MenuItem value={'S2'}>Sales2</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <Button
                        className={classes.button}
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          dispatch(
                            updateResponsibilitySalesTeamMember({
                              values: responsibilitySalesTeamMember,
                            }),
                          );
                        }}
                        startIcon={<SaveAsIcon />}
                        disabled={disabled}
                      >
                        Update
                      </Button>
                      <Button
                        className={classes.button}
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          ReplaceSalesTeamMember(responsibilitySalesTeamMember!);
                        }}
                        startIcon={<FindReplaceIcon />}
                        disabled={disabled}
                      >
                        Replace
                      </Button>
                      <Button
                        className={classes.button}
                        color="secondary"
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          dispatch(
                            inactiveResponsibilitySalesTeamMember(
                              responsibilitySalesTeamMember.id!,
                            ),
                          );
                        }}
                        startIcon={<ToggleOffIcon />}
                        disabled={disabled}
                      >
                        Inactive
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <div className={classes.showAll}>
          <Button
            color="primary"
            size={'small'}
            onClick={() => {
              setShowAll((previousState) => !previousState);
            }}
          >
            {showAll ? 'show less' : 'show all'}
          </Button>
        </div>
      </div>
      <AddResponsibilitySalesTeamMemberDialog
        open={openAddResponsibilitySalesTeamMemberDialog}
        handleClose={handleClose}
        responsibilityId={responsibilityId!}
      />
      <ResponsibilitySalesTeamMemberReplaceDialog
        open={openResponsibilitySalesTeamMemberReplaceDialog}
        onClose={handleCloseReplace}
        responsibilitySalesTeamMember={selectedResponsibilitySalesTeamMember}
      />
      <SalesTeamMemberDialog
        salesTeamId={selectedSalesTeamMember?.id!}
        open={selectedSalesTeamMember !== null}
        handleClose={() => setSelectedSalesTeamMember(null)}
      />
    </>
  );
};

export default ResponsibilitySalesTeamMemberForm;
