import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflow: 'visible',
    padding: '0.05px',
  },
  title: {
    margin: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  input: {
    marginTop: theme.spacing(2),
  },
  error: {
    marginLeft: theme.spacing(1),
    marginTop: '0.5rem',
    color: 'red',
  },
  circularContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
