import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { SearchField, TableLoading } from '../../../components';
import {
  BrandListFilter,
  Brand,
  BrandState,
  clearBrand,
  fetchBrandList,
} from '../../../../state/brand';
import { PageFilter } from '../../../../state/app';
import { AppState } from '../../../../state/configureStore';
import { createResponsibilityBrands } from '../../../../state/responsibilityBrand';
import { TablePageSize } from '../../../../constants';
import { uniqBy } from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      minHeight: '53vh',
    },
    filterContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

interface AddResponsibilityBrandDialogProps {
  open: boolean;
  handleClose: () => void;
  responsibilityId?: number;
}

const initialPaging = { pageNumber: 0, pageSize: TablePageSize[0] };

const AddResponsibilityBrandDialog: React.FC<AddResponsibilityBrandDialogProps> = (
  props,
) => {
  const { open, handleClose, responsibilityId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    items: brandList,
    totalItems,
    loading,
  } = useSelector<AppState, BrandState>((state) => state.brand);
  const [filters, setFilters] = useState<BrandListFilter>({});
  const [paging, setPaging] = useState<PageFilter>(initialPaging);
  const [selectedBrands, setSelectedBrands] = useState<Brand[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    setSelectAll(false);
  }, [filters, paging]);

  useEffect(() => {
    setFilters((filters) => {
      return { ...filters };
    });
  }, [setFilters]);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const resetForm = () => {
    handleClose();
    setSelectedBrands([]);
    setPaging({ ...initialPaging });
    setFilters({});
  };

  const handleSubmit = () => {
    const submitValue = selectedBrands.map((selectedBrand, index) => {
      return {
        brandId: selectedBrand.id,
        responsibilityId,
      };
    });

    dispatch(createResponsibilityBrands(submitValue, resetForm));
  };

  useEffect(() => {
    dispatch(fetchBrandList(filters, paging));
    return function cleanUp() {
      dispatch(clearBrand());
    };
  }, [dispatch, paging, filters]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth={true}
      disableBackdropClick={true}
    >
      <DialogTitle>Select Brand</DialogTitle>
      <DialogContent>
        <div className={classes.filterContainer}>
          <SearchField
            label="Name"
            onChange={handleChangeFilter}
            filterKey="Name.startsWith"
            filters={filters}
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  onChange={(event: React.ChangeEvent<HTMLInputElement>, checked) => {
                    if (checked) {
                      const newList = [...selectedBrands].concat(brandList);
                      setSelectedBrands(uniqBy(newList, (c) => c.id!));
                    } else {
                      const isInCurrentList = (brand: Brand): boolean =>
                        !brandList.find((c) => c.id === brand.id);
                      setSelectedBrands(selectedBrands.filter(isInCurrentList));
                    }
                    setSelectAll(checked);
                  }}
                  checked={selectAll}
                />
              </TableCell>
              <TableCell>No.</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={10}>
              {brandList.map((brand, index) => {
                const isCheckboxChecked =
                  selectedBrands.findIndex(
                    (selectedBrand) => selectedBrand.id === brand.id,
                  ) !== -1;
                return (
                  <TableRow key={brand.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked,
                        ) => {
                          if (checked) {
                            setSelectedBrands([...selectedBrands, brand]);
                          } else {
                            setSelectedBrands(
                              selectedBrands.filter((c) => c.id !== brand.id),
                            );
                          }
                        }}
                        checked={isCheckboxChecked}
                      />
                    </TableCell>
                    <TableCell>
                      {index + paging.pageSize * paging.pageNumber + 1}
                    </TableCell>
                    <TableCell>{brand.name}</TableCell>
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[...TablePageSize]}
          component="div"
          count={totalItems}
          rowsPerPage={paging.pageSize}
          page={paging.pageNumber}
          onPageChange={handleChangePageNumber}
          onRowsPerPageChange={handleChangePageSize}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Confirm
        </Button>
        <Button
          onClick={() => {
            resetForm();
          }}
          color="default"
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddResponsibilityBrandDialog;
