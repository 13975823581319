import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearWorkingGroup,
  fetchWorkingGroupList,
  WorkingGroup,
  WorkingGroupListFilter,
  WorkingGroupState,
} from '../../../state/workingGroup';
import { PageContainer, PageTitle, SearchField, TableLoading } from '../../components';
import { useFilter, useHasRole } from '../../hooks';
import roleRoute from '../roleRoute.config';
import useStyles from './workingGroupList.style';

const WorkingGroupList: React.FC<RouteComponentProps> = (props) => {
  const { history } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDisabled = !useHasRole(roleRoute.createWorkingGroup);

  const {
    items: workingGroups,
    totalItems,
    loading,
  } = useSelector<AppState, WorkingGroupState>((state) => state.workingGroup);

  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });
  const [filters, setFilters] = useFilter<WorkingGroupListFilter>({});

  useEffect(() => {
    dispatch(fetchWorkingGroupList(filters, paging));
    return function cleanUp() {
      dispatch(clearWorkingGroup());
    };
  }, [dispatch, paging, filters]);

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const addNewWorkingGroup = (): void => {
    history.push('/settings/workingGroups/form');
  };

  const editWorkingGroup = (id: WorkingGroup['id']): void => {
    history.push(`/settings/workingGroups/form/${id}`);
  };

  return (
    <PageContainer>
      <PageTitle>Working Group</PageTitle>
      <div className={classes.filterContainer}>
        <SearchField
          filterKey="Name.startsWith"
          filters={filters}
          label="Name"
          onChange={handleChangeFilter}
        />
        <SearchField
          filterKey="Owner.FullNameWithUserName.Contains"
          filters={filters}
          label="Owner"
          onChange={handleChangeFilter}
        />
        <SearchField
          filterKey="Role.Name.StartsWith"
          filters={filters}
          label="Role"
          onChange={handleChangeFilter}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={addNewWorkingGroup}
          size="large"
          disabled={isDisabled}
        >
          <AddIcon className={classes.iconButton} />
          New Working Group
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Owner</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={5}>
            {workingGroups.map((workingGroup, index) => (
              <TableRow key={workingGroup.id}>
                <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                <TableCell>{workingGroup.name}</TableCell>
                <TableCell>{workingGroup.description}</TableCell>
                <TableCell>{workingGroup.owner!.fullNameWithUserName!}</TableCell>
                <TableCell>{workingGroup.role!.name!}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => editWorkingGroup(workingGroup.id)}
                    disabled={isDisabled}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default WorkingGroupList;
