import { AppAction } from '../../../../state/app';
import { put, debounce, call } from 'redux-saga/effects';
import { REQUEST, SUCCESS, FAILURE } from '../../../../utils/actionTypeUtil';
import { FETCH_COMMENT_DASHBOARD_LIST } from './commentDashboard.actions';
import { pushNotificationMessage } from '../../../components/Notification';
import { fetchTaskActivityListRelatedToUserId } from '../../../../services/taskActivityService';

function* fetchCommentDashboardListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchTaskActivityListRelatedToUserId, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchCommentDashboard() {
  yield debounce(250, FETCH_COMMENT_DASHBOARD_LIST, fetchCommentDashboardListSaga);
}
