import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  fetchSubtaskItemList,
  updateSubtaskItem,
} from '../../services/subtaskItemService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';

import {
  FETCH_SUBTASK_ITEM_LIST,
  UPDATE_SUBTASK_ITEM_STATUS,
  UPDATE_SUBTASK_ITEMS_STATUS,
  fetchSubtaskItemListBySubtaskId,
} from './subtaskItem.actions';
import { ISubtaskItem, IUpdatedSubtaskItem } from '../../models/subtaskItem.model';
import { fetchSubtask } from '../subtask';

function* fetchSubtaskItemListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSubtaskItemList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateSubtaskItemStatusSaga(action: AppAction) {
  const { payload, type, callback } = action;
  const subtaskId = payload.smSubtaskId;
  try {
    yield put({ type: REQUEST(type) });
    const response = yield call(updateSubtaskItem, payload);
    yield put({ type: SUCCESS(type), payload: response });
    const successMessage = 'Update Subtask Item Status Success.';
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put(fetchSubtask(subtaskId));
    yield put(fetchSubtaskItemListBySubtaskId(subtaskId));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(type), payload: { errorMessage: error.message } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
    yield put(fetchSubtaskItemListBySubtaskId(subtaskId));
    if (callback) {
      callback(error);
    }
    return;
  }

  if (callback) {
    callback();
  }
}

function* updateSubtaskItemsStatusSaga(action: AppAction) {
  const subtaskItems = action.payload as ISubtaskItem[];
  yield put({ type: REQUEST(action.type) });
  const result: IUpdatedSubtaskItem[] = [];
  let hasError = false;
  for (const subtaskItem of subtaskItems) {
    try {
      const { data } = yield call(updateSubtaskItem, subtaskItem as ISubtaskItem);
      result.push({ ...data, updateStatus: 'Success' });
    } catch (error) {
      hasError = true;
      const errorMessage = `${subtaskItem.smTaskItem!.opportunityItem!.block!.blockNo} ${
        error.response.data.message
      }`;
      yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
      result.push({
        ...subtaskItem,
        updateStatus: 'Error',
        errorMessage,
        hasError: true,
      });
    }
  }

  yield put({
    type: SUCCESS(action.type),
    payload: { data: result },
  });
  const successMessage = `Update SubtaskItems status successfully.`;
  if (!hasError) {
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  }
  const callback = action.callback;
  if (callback) {
    callback();
  }
  if (subtaskItems.length > 0) {
    const { smSubtaskId } = subtaskItems[0];
    yield put(fetchSubtask(smSubtaskId!));
    yield put(fetchSubtaskItemListBySubtaskId(smSubtaskId!));
  }
}

export default function* watchSubtaskItem() {
  yield debounce(250, FETCH_SUBTASK_ITEM_LIST, fetchSubtaskItemListSaga);
  yield takeLatest(UPDATE_SUBTASK_ITEM_STATUS, updateSubtaskItemStatusSaga);
  yield takeLatest(UPDATE_SUBTASK_ITEMS_STATUS, updateSubtaskItemsStatusSaga);
}
