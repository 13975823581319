import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import { FETCH_HANES_PROJECT_LIST } from './hanesProject.action';
import { HanesProject, HanesProjectState } from './hanesProject.type';

const getInitialState = (): HanesProject => ({
  item: '',
  name: '',
});

const initialState: HanesProjectState = {
  errorMessage: '',
  item: getInitialState(),
  items: [],
  loading: false,
  totalItems: 0,
};

export default (
  state: HanesProjectState = initialState,
  action: AppAction,
): HanesProjectState => {
  switch (action.type) {
    case REQUEST(FETCH_HANES_PROJECT_LIST):
      return { ...state, loading: true };
    case SUCCESS(FETCH_HANES_PROJECT_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_HANES_PROJECT_LIST):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    default:
      return state;
  }
};
