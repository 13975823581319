import { Customer } from '../state/customer';
import { IProject } from './project.model';

export interface IProjectCustomer {
  id?: number;
  name?: string;
  sequence?: number;
  smProjectId?: number;
  customerId?: number;
  customer?: Customer | null;
  project?: IProject | null;
  active?: boolean;
  activeDate?: string;
  inactiveDate?: string;
}

export const defaultValue: Readonly<IProjectCustomer> = {};
