import { call, put, select, takeLatest } from 'redux-saga/effects';
import history from '../../history';
import { ISubtask } from '../../models/subtask.model';
import { ISubtaskType } from '../../models/subtaskType.model';
import {
  ITaskItem,
  ITaskItemWithForecasts,
  ITaskItemWithMoqs,
} from '../../models/taskItem.model';
import { fetchSubtaskItemList } from '../../services/subtaskItemService';
import { createSubtask } from '../../services/subtaskService';
import { createTaskItem } from '../../services/taskItemService';
import { createTask } from '../../services/taskService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import { AppState } from '../configureStore';
import {
  clearTaskCreateDialog,
  CREATE_TASK_SUBTASK_SUBTASK_ITEM_AND_ETC,
} from './taskCreate.actions';
import { removeAndCreateSubtaskItemMoqs } from '../../services/subtaskItemMoqService';
import { clearTaskItem } from '../taskItem';
import { removeAndCreateSubtaskItemForecasts } from '../../services/subtaskItemForecastService';

function* createTaskAndSubtaskAndSubtaskItemSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const {
      createTaskPayload,
      createSubtasksPayload,
      selectedTaskItemsWithMoqs,
      selectedTaskItemsWithForecasts,
    } = yield select((state: AppState) => state.taskCreate);
    const { selectedItems } = yield select((state: AppState) => state.taskItem);
    const { items: subtaskTypeList } = yield select(
      (state: AppState) => state.subtaskType,
    );
    const { data: createdTask } = yield call(createTask, createTaskPayload);
    const taskId = createdTask.id;
    const subtaskTypePriceId = subtaskTypeList.find(
      (subtaskType: ISubtaskType) => subtaskType.code === 'PRICE',
    ).id;
    let hasSubtaskPrice = false;
    let createdSubtaskWithSubtaskPriceType = null;
    for (const subtask of createSubtasksPayload) {
      const subtaskWithSmTaskId = { ...subtask, smTaskId: taskId };
      const { data: createdSubtask } = yield call(
        createSubtask,
        subtaskWithSmTaskId as ISubtask,
      );

      if (subtaskWithSmTaskId.smSubtaskTypeId === subtaskTypePriceId) {
        hasSubtaskPrice = true;
        createdSubtaskWithSubtaskPriceType = createdSubtask;
      }
    }

    for (const taskItem of selectedItems) {
      const taskItemWithSmTaskId = { ...taskItem, smTaskId: taskId };
      yield call(createTaskItem, taskItemWithSmTaskId as ITaskItem);
    }

    if (hasSubtaskPrice) {
      const { data: subtaskItems } = yield call(fetchSubtaskItemList, {
        filters: { 'SMSubtaskId.EqualsTo': createdSubtaskWithSubtaskPriceType.id },
        paging: { pageNumber: 0, pageSize: 99999 },
      });

      for (const subtaskItem of subtaskItems) {
        const opItemId = subtaskItem.smTaskItem!.opportunityItemId!;
        const moqs = selectedTaskItemsWithMoqs.find(
          (taskItemWithMoqs: ITaskItemWithMoqs) =>
            taskItemWithMoqs.opportunityItemId === opItemId,
        ).moqs!;
        yield call(removeAndCreateSubtaskItemMoqs, subtaskItem.id, moqs);

        const forecasts = selectedTaskItemsWithForecasts.find(
          (taskItemWithForecasts: ITaskItemWithForecasts) =>
            taskItemWithForecasts.opportunityItemId === opItemId,
        ).forecasts!;
        yield call(removeAndCreateSubtaskItemForecasts, subtaskItem.id, forecasts);
      }
    }
    history.push(`/tasks/form/${taskId}`);
    yield put({ type: SUCCESS(action.type) });
    yield put(clearTaskCreateDialog());
    yield put(clearTaskItem());
    yield put(
      pushNotificationMessage({ message: `Create Task Successfully`, type: 'success' }),
    );
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchTaskCreate() {
  yield takeLatest(
    CREATE_TASK_SUBTASK_SUBTASK_ITEM_AND_ETC,
    createTaskAndSubtaskAndSubtaskItemSaga,
  );
}
