import {
  CLEAR_PENDING_SUBTASK_LIST,
  FETCH_PENDING_SUBTASK_LIST,
} from './pendingTaskList.actions';

import { FAILURE, REQUEST, SUCCESS } from '../../../../utils/actionTypeUtil';
import { AppAction } from '../../../../state/app';
import { ISubtask } from '../../../../models/subtask.model';

export interface PendingTaskListState {
  loading: boolean;
  items: ReadonlyArray<ISubtask>;
  errorMessage: string;
  totalItems: number;
}

const initialState: PendingTaskListState = {
  loading: false,
  items: [],
  errorMessage: '',
  totalItems: 0,
};

export default (
  state: PendingTaskListState = initialState,
  action: AppAction,
): PendingTaskListState => {
  switch (action.type) {
    case REQUEST(FETCH_PENDING_SUBTASK_LIST):
      return { ...state, loading: true };
    case SUCCESS(FETCH_PENDING_SUBTASK_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_PENDING_SUBTASK_LIST):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case CLEAR_PENDING_SUBTASK_LIST:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
