import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createTask,
  fetchTask,
  fetchTaskList,
  updateTask,
} from '../../services/taskService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import { CREATE_TASK, FETCH_TASK, FETCH_TASK_LIST, UPDATE_TASK } from './task.actions';
import { TaskCreateOrUpdatePayload } from './task.types';

function* fetchTaskSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchTask, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchTaskListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchTaskList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createTaskSaga(action: AppAction) {
  try {
    const { values, callback } = action.payload as TaskCreateOrUpdatePayload;
    const successMessage = `Created task successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createTask, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    callback();
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateTaskSaga(action: AppAction) {
  try {
    const { values } = action.payload as TaskCreateOrUpdatePayload;
    const successMessage = `Updated task successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateTask, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchTask() {
  yield takeLatest(FETCH_TASK, fetchTaskSaga);
  yield debounce(250, FETCH_TASK_LIST, fetchTaskListSaga);
  yield takeLatest(CREATE_TASK, createTaskSaga);
  yield takeLatest(UPDATE_TASK, updateTaskSaga);
}
