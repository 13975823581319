import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { AuthState } from '../../state/auth';
import { AppState } from '../../state/configureStore';
import { AppBootstrapState } from '../../state/app';
import { RoleType } from '../pages/roleRoute.config';

interface PrivateRouteProps extends RouteProps {
  roles?: RoleType[];
}

export const PrivateRoute = ({ component, roles, ...rest }: PrivateRouteProps) => {
  const { isLoggedIn, user } = useSelector<AppState, AuthState>((state) => state.auth);
  const { loading } = useSelector<AppState, AppBootstrapState>(
    (state) => state.appBootstrap,
  );
  if (!component) {
    throw Error('component is undefined');
  }

  const Component = component;
  const render = (props: RouteComponentProps<any>): React.ReactNode => {
    if (loading) {
      return null;
    }
    if (isLoggedIn && roles && user!.roles) {
      if (user!.roles.some((userRole) => roles.includes(userRole as RoleType))) {
        return <Component {...props} />;
      } else {
        return (
          <Redirect
            to={{
              pathname: '/dashboard',
            }}
          />
        );
      }
    } else if (isLoggedIn) {
      return <Component {...props} />;
    }
    return (
      <Redirect
        to={{
          pathname: '/',
          search: props.location.search,
          state: { from: props.location },
        }}
      />
    );
  };

  return <Route {...rest} render={render} />;
};

export default PrivateRoute;
