import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PageFilter } from '../../../state/app';
import {
  BrandListFilter,
  BrandState,
  fetchBrandList,
  clearBrand,
} from '../../../state/brand';
import { AppState } from '../../../state/configureStore';
import useStyles from './brandList.style';
import { PageContainer, PageTitle, SearchField, TableLoading } from '../../components';
import { useFilter } from '../../hooks';

const BrandList: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [filters, setFilters] = useFilter<BrandListFilter>({
    'Name.startsWith': '',
  });
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const {
    items: brands,
    totalItems,
    loading,
  } = useSelector<AppState, BrandState>((state) => state.brand);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchBrandList(filters, paging));
    return function cleanUp() {
      dispatch(clearBrand());
    };
  }, [dispatch, paging, filters]);

  return (
    <PageContainer>
      <PageTitle>Brand</PageTitle>
      <div className={classes.filterContainer}>
        <SearchField
          filterKey="Name.startsWith"
          filters={filters}
          label="Name"
          onChange={handleChangeFilter}
        />
      </div>
      <div>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellHeader}>No.</TableCell>
              <TableCell className={classes.tableCellHeader}>Name</TableCell>
              <TableCell className={classes.tableCellHeader}>ExternalId</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={3}>
              {brands.map((brand, index) => (
                <TableRow key={brand.id}>
                  <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                  <TableCell>{brand.name}</TableCell>
                  <TableCell>{brand.externalId}</TableCell>
                </TableRow>
              ))}
            </TableLoading>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[15, 25, 35]}
          component="div"
          count={totalItems}
          rowsPerPage={paging.pageSize}
          page={paging.pageNumber}
          onPageChange={handleChangePageNumber}
          onRowsPerPageChange={handleChangePageSize}
        />
      </div>
    </PageContainer>
  );
};

export default BrandList;
