import { Button, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ISampleDevelopmentHl } from '../../../../models/sampleDevelopmentHl.model';

import { Save as SaveIcon, Send as SendIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import SampleDevelopmentStatus from '../../../../constants/sampleDevelopmentStatus';
import { AppState } from '../../../../state/configureStore';
import {
  createSampleDevelopmentHl,
  downloadSampleDevelopmentHl,
  fetchSampleDevelopmentHl,
  SampleDevelopmentHlState,
  submitSampleDevelopmentHlForApproval,
  updateSampleDevelopmentHl,
  clearSampleDevelopmentHl,
} from '../../../../state/sampleDevelopmentHl';
import {
  Loading,
  PageContainer,
  PageTitle,
  PageTopMenuContainer,
} from '../../../components';
import SampleDevelopmentHlHead from './SampleDevelopmentHlHead';
import SampleDevelopmentHlJob from './SampleDevelopmentHlJob';
import SampleDevelopmentHlDesign from './SampleDevelopmentHlDesign';
import SampleDevelopmentHlMaterial from './SampleDevelopmentHlMaterial';
import SampleDevelopmentHlFinishing from './SampleDevelopmentHlFinishing';
import SampleDevelopmentHlAssembly from './SampleDevelopmentHlAssembly';
import SampleDevelopmentHlQuotation from './SampleDevelopmentHlQuotation';
import SampleDevelopmentHlLaw from './SampleDevelopmentHlLaw';
import SampleDevelopmentHlProductLevel from './SampleDevelopmentHlProductLevel';
import SampleDevelopmentHlSpecial from './SampleDevelopmentHlSpecial';
import SampleDevelopmentHlFilePath from './SampleDevelopmentHlFilePath';
import SampleDevelopmentHlAlfrescoLink from './SampleDevelopmentHlAlfrescoLink';
import moment from 'moment';

const statusAllowForSubmitForApproval = [
  SampleDevelopmentStatus.PENDING_FOR_APPROVAL,
  SampleDevelopmentStatus.REJECTED,
];

interface SampleDevelopmentHlFormProps {
  id: string;
}

const SampleDevelopmentHlSchema = Yup.object().shape({
  customer: Yup.object().nullable(true).required('Customer is required'),
  block: Yup.object().nullable(true).required('Block is required'),
  smTask: Yup.object().nullable(true).required('Task is required'),
  salesTeamMember: Yup.object().nullable(true).required('SalesTeamMember is required'),
});

const SampleDevelopmentHlForm: React.FC<SampleDevelopmentHlFormProps> = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: routeParamsId } = useParams<{
    id: string;
  }>();
  const sampleDevelopmentHlId = id ? id : routeParamsId;
  const isUpdateMode = sampleDevelopmentHlId !== undefined;
  const { item: sampleDevelopmentHl, loading: isLoading } = useSelector<
    AppState,
    SampleDevelopmentHlState
  >((s) => s.sampleDevelopmentHl);

  const redirectToSampleDevelopmentHlList = () => {
    history.goBack();
  };

  const handleSubmit = (values: ISampleDevelopmentHl) => {
    if (isUpdateMode) {
      dispatch(updateSampleDevelopmentHl({ values }));
    } else {
      dispatch(createSampleDevelopmentHl({ values }));
    }
  };

  useEffect(() => {
    if (sampleDevelopmentHlId) {
      dispatch(fetchSampleDevelopmentHl(+sampleDevelopmentHlId));
    }
    return function cleanUp() {
      dispatch(clearSampleDevelopmentHl());
    };
  }, [sampleDevelopmentHlId, dispatch]);

  return (
    <PageContainer>
      <Formik
        enableReinitialize={true}
        initialValues={{
          customer: null,
          smTask: null,
          block: null,
          salesTeamMember: null,
          dueDate: moment().format('YYYY-MM-DD'),
          seeModel: moment().format('YYYY-MM-DD'),
          quantity: 0,
          quantityUnit: 'PCS',
          jobTypeHL: false,
          jobTypeSO: false,
          jobTypeHTM: false,
          jobDescLetterPress: false,
          jobDescOffset: false,
          jobDescFlexo: false,
          jobDescSilkScreen: false,
          jobDescThermal: false,
          jobDescHotStamping: false,
          jobDescOther: false,
          jobDescOtherData: '',
          ownDesign: false,
          customerDesign: false,
          designSample: false,
          designOriginal: false,
          designArtwork: false,
          designZip: false,
          designCD: false,
          designFilm: false,
          designMail: false,
          designFile: false,
          designTIF: false,
          designEPS: false,
          designJPG: false,
          designPDF: false,
          designAI: false,
          designQXP: false,
          designSoftware: false,
          designAcrobat: false,
          designAcrobatData: '',
          designIllustrater: false,
          designIllustraterData: '',
          designPageMaker: false,
          designPageMakerData: '',
          designPhotoshop: false,
          designPhotoshopData: '',
          designFreeHand: false,
          designFreeHandData: '',
          designQuark: false,
          designQuarkData: '',
          designOther: false,
          designOtherData: '',
          colorProcess: false,
          colorSolid: false,
          colorCoated: false,
          colorUncoated: false,
          colorTextile: false,
          colorFoil: false,
          colorPastel: false,
          colorSolidProcess: false,
          colorMetalic: false,
          frontColorQuantity: 0,
          frontColorProcess: false,
          frontColorSolid: false,
          frontColorSolidData: '',
          frontColorPMS: false,
          frontColorPMSData: '',
          backColorQuantity: 0,
          backColorProcess: false,
          backColorSolid: false,
          backColorSolidData: '',
          backColorPT: false,
          backColorPTData: '',
          colorSizeChart: '',
          frontFinishLaminate: false,
          frontFinishLamMatt: false,
          frontFinishLamGloss: false,
          frontFinishLamHalogram: false,
          frontFinishLamWindow: false,
          frontFinishCheOilBased: false,
          frontFinishCheWaterBased: false,
          frontFinishCheVanish: false,
          frontFinishCheUV: false,
          frontFinishCheBlister: false,
          frontFinishChePET: false,
          frontFinishChePVC: false,
          backFinishLaminate: false,
          backFinishLamMatt: false,
          backFinishLamGloss: false,
          backFinishLamHalogram: false,
          backFinishLamWindow: false,
          backFinishCheOilBased: false,
          backFinishCheWaterBased: false,
          backFinishCheVanish: false,
          backFinishCheUV: false,
          backFinishCheBlister: false,
          backFinishChePET: false,
          backFinishChePVC: false,
          prodPackingPiece: false,
          prodPackingSheet: false,
          dieCutHold: false,
          dieCutShape: false,
          dieCutEmboss: false,
          dieCutPerforate: false,
          dieCutFoldLine: false,
          bindingFold: false,
          bindingTransparent: false,
          bindingStaples: false,
          bindingStaplesData: '',
          gluingLaminate: false,
          gluingSleeve: false,
          gluingGlue: false,
          gluingBag: false,
          gluingBox: false,
          gluingWindowPlastic: false,
          gluingDoubleSidedTape: false,
          ratchCorrugated: false,
          stringSingle: false,
          stringDouble: false,
          stringOther: false,
          stringOtherData: '',
          stringType: '',
          stringSize: '',
          stringColor: '',
          stringLengthKnot: 0,
          stringLengthKnotUnit: 'CM',
          stringLengthEnd: 0,
          stringLengthEndUnit: 'CM',
          pinType: '',
          pinSize: '',
          pinColor: '',
          holeSingle: false,
          holeDouble: false,
          holeOther: false,
          holeOtherData: '',
          holeSize: 0,
          holeSizeUnit: 'MM',
          holePositionSingle: false,
          holePositionDouble: false,
          holePositionFold: false,
          holePositionDistanceX: 0,
          holePositionDistanceXUnit: 'MM',
          holePositionDistanceY: 0,
          holePositionDistanceYUnit: 'MM',
          eyeletNickleFree: false,
          eyeletRustness: false,
          eyeletPlain: false,
          eyeletType: '',
          eyeletSize: '',
          eyeletColor: '',
          variableAmount: 0,
          variableSize: false,
          variableKeySize: false,
          variableStyleNo: false,
          variableColor: false,
          variableFabricContent: false,
          variableInstruction: false,
          variableWashingSymbol: false,
          variableArt: false,
          variableFactoryCode: false,
          variableCountry: false,
          variableBarcode: false,
          variablePrice: false,
          variableOther: false,
          variableOtherData: '',
          packingPlain: false,
          specialInstruction: '',
          withoutQuotation: false,
          quotationType: false,
          quotationTypeO: false,
          quotationTypeOQty: 0,
          quotationTypeOCurrency: 'THB',
          quotationTypeM: false,
          quotationTypeMQty: 0,
          quotationTypeMCurrency: 'THB',
          quotationTypeP: false,
          quotationTypePQty: 0,
          quotationTypePCurrency: 'THB',
          quotationUnit: 'PCS',
          quotationQty: 0,
          withoutLaw: false,
          haveLaw: false,
          lawFormaldehyde: false,
          lawPentachlorophenol: false,
          lawNickel: false,
          lawAzoDyes: false,
          lawChromium: false,
          lawTrademarks: false,
          lawCodeOfConduct: false,
          lawFSC: false,
          filePath: '',
          topProductLevel: false,
          intermediateProductLevel: false,
          lowerProductLevel: false,
          labelDiWidth: 0,
          labelDiLength: 0,
          finishSizeWidth: 0,
          finishSizeLength: 0,
          finishSizeHigh: 0,
          finishSizeUnit: 'MM',
          frontFinishCheMatt: false,
          frontFinishCheGloss: false,
          frontFinishCheSpot: false,
          backFinishCheMatt: false,
          backFinishCheGloss: false,
          backFinishCheSpot: false,
          quotation: false,
          quotationNumber: '',
          holePositionSingleNum: 0,
          holePositionDoubleNum: 0,
          holePositionFoldNum: 0,
          labelDiUnit: 'MM',
          jobType: 'SAMPLE',
          orderCompany: null,
          orderCompanyId: 0,
          gluingCamshell: false,
          gluingFoam: false,
          gluingCorrugatedLaminate: false,
          frontFinishLamAdhesive: false,
          frontFinishLamHeatSeal: false,
          frontFinishLamSoftTouch: false,
          frontFinishLamFullArea: false,
          frontFinishLamSpot: false,
          frontFinishCheUVSilkscreen: false,
          frontFinishCheCalendering: false,
          frontFinishCheHighGloss: false,
          frontFinishCheExtraMatt: false,
          frontFinishCheSoftTouch: false,
          frontFinishCheFullArea: false,
          backFinishLamAdhesive: false,
          backFinishLamHeatSeal: false,
          backFinishLamSoftTouch: false,
          backFinishLamFullArea: false,
          backFinishLamSpot: false,
          backFinishCheUVSilkscreen: false,
          backFinishCheCalendering: false,
          backFinishCheHighGloss: false,
          backFinishCheExtraMatt: false,
          backFinishCheSoftTouch: false,
          backFinishCheFullArea: false,
          foilHotStamping: false,
          foilColdStamping: false,
          foilColorData: '',
          dieCutPiece: false,
          adhesiveTapeDesc: '',
          substratePrimaryOption: '1',
          substrateOther: '',
          frontFinishChemical: false,
          backFinishChemical: false,
          frontFinishLaminateDesc: '',
          backFinishLaminateDesc: '',
          frontFinishCheSatin: false,
          backFinishCheSatin: false,
          gluingDoubleSidedTapeData: '',
          gluingCorrugatedLaminateData: '',
          gluingCamshellData: '',
          gluingFoamData: '',
          frontFinishCheWaterResistance: false,
          backFinishCheWaterResistance: false,
          frontFinishCheSpecial: false,
          frontFinishCheSpecialData: '',
          backFinishCheSpecial: false,
          backFinishCheSpecialData: '',
          alfrescoLink: '',
          jobTypeFSC: false,
          jobTypeGRS: false,
          jobTypeFood: false,
          jobTypeDirect: false,
          jobTypeIndirect: false,
          jobTypeExternal: false,
          ...sampleDevelopmentHl,
        }}
        validationSchema={SampleDevelopmentHlSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        isInitialValid={true}
      >
        {({ values, handleChange, errors, submitForm, setFieldValue }) => {
          return (
            <Form>
              <PageTitle>
                {isUpdateMode
                  ? `Update Sample Development HL ${values.sampleDevNumber}`
                  : `New Sample Development HL`}
              </PageTitle>
              <PageTopMenuContainer>
                {statusAllowForSubmitForApproval.includes(
                  sampleDevelopmentHl.status!,
                ) && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      dispatch(
                        submitSampleDevelopmentHlForApproval(sampleDevelopmentHl.id!),
                      );
                    }}
                    startIcon={<SendIcon />}
                  >
                    {`Submit for Approval`}
                  </Button>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  type="submit"
                >
                  {isUpdateMode ? `Update` : `Create`}
                </Button>
                <Button variant="contained" onClick={redirectToSampleDevelopmentHlList}>
                  Cancel
                </Button>
                {isUpdateMode && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      dispatch(
                        downloadSampleDevelopmentHl(parseInt(sampleDevelopmentHlId)),
                      );
                    }}
                  >
                    Download PDF
                  </Button>
                )}
              </PageTopMenuContainer>
              <Loading isLoading={isLoading}>
                <CardContent>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={12}
                  >
                    <SampleDevelopmentHlHead
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={12}
                  >
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      item
                      xs={12}
                    >
                      <Paper variant="outlined">
                        <CardContent>
                          <Grid item xs={12}>
                            <Typography>Product Info</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentHlJob setFieldValue={setFieldValue} />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentHlDesign />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentHlMaterial
                              setFieldValue={setFieldValue}
                              values={values}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentHlFinishing />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentHlAssembly />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentHlAlfrescoLink />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentHlFilePath />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentHlQuotation />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentHlLaw />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentHlProductLevel />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentHlSpecial />
                          </Grid>
                        </CardContent>
                      </Paper>
                    </Grid>
                  </Grid>
                </CardContent>
              </Loading>
            </Form>
          );
        }}
      </Formik>
    </PageContainer>
  );
};

export default SampleDevelopmentHlForm;
