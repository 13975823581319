import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import {
  createResponsibilitySalesTeamMember,
  deleteResponsibilitySalesTeamMember,
  fetchResponsibilitySalesTeamMember,
  fetchResponsibilitySalesTeamMemberList,
  replaceResponsibilitySalesTeamMember,
  inactiveResponsibilitySalesTeamMember,
  updateResponsibilitySalesTeamMember,
} from '../../services/responsibilitySalesTeamMemberService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_RESPONSIBILITY_SALESTEAM_MEMBER,
  DELETE_RESPONSIBILITY_SALESTEAM_MEMBER,
  FETCH_RESPONSIBILITY_SALESTEAM_MEMBER_LIST,
  FETCH_RESPONSIBILITY_SALESTEAM_MEMBER,
  CREATE_RESPONSIBILITY_SALESTEAM_MEMBERS,
  fetchResponsibilitySalesTeamMemberListByresponsibilityId,
  UPDATE_RESPONSIBILITY_SALESTEAM_MEMBER,
  REPLACE_RESPONSIBILITY_SALESTEAM_MEMBER,
  INACTIVE_RESPONSIBILITY_SALESTEAM_MEMBER,
} from './responsibilitySalesTeamMember.actions';
import {
  ResponsibilitySalesTeamMemberCreateOrUpdatePayload,
  ResponsibilitySalesTeamMemberReplacePayLoad,
} from './responsibilitySalesTeamMember.types';
import { AppState } from '../configureStore';
import { IResponsibilitySalesTeamMember } from '../../models/responsibilitySalesTeamMember.model';

function* fetchResponsibilitySalesTeamMemberSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchResponsibilitySalesTeamMember, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchResponsibilitySalesTeamListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchResponsibilitySalesTeamMemberList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createResponsibilitySalesTeamMemberSaga(action: AppAction) {
  try {
    const {
      values,
    } = action.payload as ResponsibilitySalesTeamMemberCreateOrUpdatePayload;
    const successMessage = `Created responsibility salesTeam member  successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createResponsibilitySalesTeamMember, values);
    yield put(
      fetchResponsibilitySalesTeamMemberListByresponsibilityId(data.responsibilityId),
    );
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createResponsibilitySalesTeamMembersSaga(action: AppAction) {
  try {
    const { payload, callback } = action;

    yield put({ type: REQUEST(action.type) });

    for (const opCustGrp of payload) {
      yield call(createResponsibilitySalesTeamMember, opCustGrp);
    }

    const successMessage = `Created responsibility salesTeam member successfully.`;
    yield put(
      fetchResponsibilitySalesTeamMemberListByresponsibilityId(
        payload[0].responsibilityId,
      ),
    );

    yield put({ type: SUCCESS(action.type), payload });

    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));

    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateResponsibilitySalesTeamMemberSaga(action: AppAction) {
  try {
    const {
      values,
    } = action.payload as ResponsibilitySalesTeamMemberCreateOrUpdatePayload;
    const successMessage = `Updated responsibility salesTeam member successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateResponsibilitySalesTeamMember, values);
    yield put(
      fetchResponsibilitySalesTeamMemberListByresponsibilityId(data.responsibilityId),
    );
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteResponsibilitySalesTeamMemberSaga(action: AppAction) {
  const { payload } = action;
  const { id } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    yield call(deleteResponsibilitySalesTeamMember, id);
    const responsibility = yield select(getResponsibilityFromState);
    if (responsibility.id) {
      yield put(
        fetchResponsibilitySalesTeamMemberListByresponsibilityId(responsibility.id),
      );
    }
    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Deleted successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* replaceResponsibilitySalesTeamMemberSaga(action: AppAction) {
  try {
    const values = action.payload as ResponsibilitySalesTeamMemberReplacePayLoad;
    const successMessage = `Replace responsibility customer successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(replaceResponsibilitySalesTeamMember, values);
    const responsibility = yield select(getResponsibilityFromState);
    if (responsibility.id) {
      yield put(
        fetchResponsibilitySalesTeamMemberListByresponsibilityId(responsibility.id),
      );
    }
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* inactiveResponsibilitySalesTeamMemberSaga(action: AppAction) {
  const { payload } = action;
  const { id } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    yield call(inactiveResponsibilitySalesTeamMember, id);
    const responsibility = yield select(getResponsibilityFromState);
    if (responsibility.id) {
      yield put(
        fetchResponsibilitySalesTeamMemberListByresponsibilityId(responsibility.id),
      );
    }

    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Inactive successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function getResponsibilityFromState(state: AppState): IResponsibilitySalesTeamMember {
  const { item } = state.responsibility;
  return item;
}

export default function* watchResponsibilitySalesTeamMember() {
  yield takeLatest(
    FETCH_RESPONSIBILITY_SALESTEAM_MEMBER,
    fetchResponsibilitySalesTeamMemberSaga,
  );
  yield debounce(
    250,
    FETCH_RESPONSIBILITY_SALESTEAM_MEMBER_LIST,
    fetchResponsibilitySalesTeamListSaga,
  );
  yield takeLatest(
    CREATE_RESPONSIBILITY_SALESTEAM_MEMBER,
    createResponsibilitySalesTeamMemberSaga,
  );
  yield takeLatest(
    UPDATE_RESPONSIBILITY_SALESTEAM_MEMBER,
    updateResponsibilitySalesTeamMemberSaga,
  );
  yield takeLatest(
    CREATE_RESPONSIBILITY_SALESTEAM_MEMBERS,
    createResponsibilitySalesTeamMembersSaga,
  );
  yield takeLatest(
    DELETE_RESPONSIBILITY_SALESTEAM_MEMBER,
    deleteResponsibilitySalesTeamMemberSaga,
  );
  yield takeLatest(
    REPLACE_RESPONSIBILITY_SALESTEAM_MEMBER,
    replaceResponsibilitySalesTeamMemberSaga,
  );
  yield takeLatest(
    INACTIVE_RESPONSIBILITY_SALESTEAM_MEMBER,
    inactiveResponsibilitySalesTeamMemberSaga,
  );
}
