import { ISubtaskItem } from '../models/subtaskItem.model';
import { PageFilter } from '../state/app';
import { SubtaskItemListFilter } from '../state/subtaskItem';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/smSubtaskItems`;

export const fetchSubtaskItemList = async ({
  filters,
  paging,
}: {
  filters: SubtaskItemListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchSubtaskItem = async (id: ISubtaskItem['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createSubtaskItem = async (subtaskItem: ISubtaskItem) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, subtaskItem);
};

export const updateSubtaskItem = async (subtaskItem: ISubtaskItem) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, subtaskItem);
};

export const deleteSubtaskItem = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};
