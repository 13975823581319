import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { PendingApprovalAssignmentList } from './pendingApprovalAssignmentList';
import { PendingApprovalProgramBrandRequestList } from './pendingApprovalProgramBrandRequestList';
import { PendingApprovalSampleDevHlRequestList } from './pendingApprovalSampleDevHlRequestList';
import { PendingApprovalSampleDevNlRequestList } from './pendingApprovalSampleDevNlRequestList';
import { PendingApprovalSampleDevPlRequestList } from './pendingApprovalSampleDevPlRequestList';
import { PendingApprovalSampleDevTlRequestList } from './pendingApprovalSampleDevTlRequestList';
import { PendingApprovalSampleDevWlRequestList } from './pendingApprovalSampleDevWlRequestList';
import { PendingApprovalProjectList } from './pendingApprovalProjectList';
import { PendingApprovalResponsibilityList } from './pendingApprovalResponsibilityList';

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { Background } from 'react-flow-renderer';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const PendingApprovalItemList = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={3} direction="row" className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <PendingApprovalResponsibilityList />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <PendingApprovalAssignmentList />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <PendingApprovalProjectList />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <PendingApprovalProgramBrandRequestList />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <PendingApprovalSampleDevNlRequestList />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <PendingApprovalSampleDevHlRequestList />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <PendingApprovalSampleDevPlRequestList />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <PendingApprovalSampleDevTlRequestList />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <PendingApprovalSampleDevWlRequestList />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PendingApprovalItemList;
