import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createSquadWorkingMember,
  fetchSquadWorkingMember,
  fetchSquadWorkingMemberList,
  updateSquadWorkingMember,
  deleteSquadWorkingMember,
} from '../../services/squadWorkingMemberService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CLEAR_SQUAD_WORKING_MEMBER,
  CREATE_SQUAD_WORKING_MEMBER,
  FETCH_SQUAD_WORKING_MEMBER,
  FETCH_SQUAD_WORKING_MEMBER_LIST,
  UPDATE_SQUAD_WORKING_MEMBER,
  CREATE_SQUAD_WORKING_MEMBERS,
  DELETE_SQUAD_WORKING_MEMBER,
} from './squadWorkingMember.actions';
import {
  SquadWorkingMemberCreateOrUpdatePayload,
  SquadWorkingMember,
} from './squadWorkingMember.type';

function* fetchSquadWorkingMemberListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSquadWorkingMemberList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchSquadWorkingMemberSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchSquadWorkingMember, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createSquadWorkingMemberSaga(action: AppAction) {
  try {
    const { values } = action.payload as SquadWorkingMemberCreateOrUpdatePayload;
    const successMessage = `Created squad working member successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createSquadWorkingMember, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield call(fetchSquadWorkingMemberListSaga, {
      type: FETCH_SQUAD_WORKING_MEMBER_LIST,
      payload: {
        filters: { 'SmSquadId.EqualsTo': action.payload.SmSquadId },
        paging: { pageNumber: 0, pageSize: 99999 },
      },
    });
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createSquadWorkingMembersSaga(action: AppAction) {
  const squadWorkingMembers = action.payload as SquadWorkingMember[];
  yield put({ type: REQUEST(action.type) });
  const result = [];

  for (const squadWorkingMember of squadWorkingMembers) {
    try {
      const { data } = yield call(createSquadWorkingMember, squadWorkingMember);
      result.push({ ...data, status: 'Success' });
    } catch (error) {
      result.push({
        ...squadWorkingMember,
        status: 'Error',
        errorMessage: error.response.data.message,
        hasError: true,
      });
    }
  }
  yield put({
    type: SUCCESS(action.type),
    payload: { data: result },
  });
  const successMessage = `Created squad sales members successfully.`;
  yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  yield call(fetchSquadWorkingMemberListSaga, {
    type: FETCH_SQUAD_WORKING_MEMBER_LIST,
    payload: {
      filters: { 'SmSquadId.EqualsTo': action.payload[0].smSquadId },
      paging: { pageNumber: 0, pageSize: 99999 },
    },
  });
  const callback = action.callback;
  if (callback) {
    callback();
  }
}

function* updateSquadWorkingMemberSaga(action: AppAction) {
  try {
    const { values } = action.payload as SquadWorkingMemberCreateOrUpdatePayload;
    const successMessage = `Updated squad working member successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateSquadWorkingMember, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield call(fetchSquadWorkingMemberListSaga, {
      type: FETCH_SQUAD_WORKING_MEMBER_LIST,
      payload: {
        filters: { 'SmSquadId.EqualsTo': values.smSquadId },
        paging: { pageNumber: 0, pageSize: 99999 },
      },
    });
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteSquadWorkingMemberSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(
      deleteSquadWorkingMember,
      action.payload.smSquadWorkingMemberId,
    );
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield call(fetchSquadWorkingMemberListSaga, {
      type: FETCH_SQUAD_WORKING_MEMBER_LIST,
      payload: {
        filters: { 'SmSquadId.EqualsTo': action.payload.smSquadId },
        paging: { pageNumber: 0, pageSize: 99999 },
      },
    });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSquadWorkingMember() {
  yield debounce(250, FETCH_SQUAD_WORKING_MEMBER_LIST, fetchSquadWorkingMemberListSaga);
  yield takeLatest(FETCH_SQUAD_WORKING_MEMBER, fetchSquadWorkingMemberSaga);
  yield takeLatest(CREATE_SQUAD_WORKING_MEMBER, createSquadWorkingMemberSaga);
  yield takeLatest(CREATE_SQUAD_WORKING_MEMBERS, createSquadWorkingMembersSaga);
  yield takeLatest(UPDATE_SQUAD_WORKING_MEMBER, updateSquadWorkingMemberSaga);
  yield takeLatest(DELETE_SQUAD_WORKING_MEMBER, deleteSquadWorkingMemberSaga);
}
