import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { AppState } from '../../../../state/configureStore';
import { AuthState } from '../../../../state/auth';
import { createTaskInTaskCreate, TaskCreateState } from '../../../../state/taskCreate';
import { ITask } from '../../../../models/task.model';
import {
  Close as CloseIcon,
  Save as SaveIcon,
  SkipNext as NextIcon,
  NavigateNext as NavigateNextIcon,
} from '@material-ui/icons';

const TaskFormDialogSchema = Yup.object().shape({
  subject: Yup.string().required('Subject is required'),
  description: Yup.string(),
  dueDate: Yup.date().required('Due Date is required').nullable(true),
});

interface TaskFormDialogProps {
  nextClick: () => void;
  closeClick: () => void;
}

const TaskFormDialog: React.FC<TaskFormDialogProps> = (props) => {
  const dispatch = useDispatch();
  const { nextClick, closeClick } = props;
  const { user, userId } = useSelector<AppState, AuthState>((state) => state.auth);
  const {
    createTaskPayload: {
      subject = '',
      description = '',
      dueDate = null,
      requester = user,
      requesterId = userId,
    },
  } = useSelector<AppState, TaskCreateState>((state) => state.taskCreate);
  const handleSubmit = (values: ITask) => {
    dispatch(createTaskInTaskCreate(values));
    nextClick();
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          subject,
          description,
          dueDate,
          requester,
          requesterId,
        }}
        validationSchema={TaskFormDialogSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        {({ values, handleChange, errors, submitForm, setFieldValue }) => {
          return (
            <Form>
              <DialogTitle style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  New Task
                  <IconButton
                    color="inherit"
                    aria-label="close"
                    onClick={() => {
                      closeClick();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <TextField
                  name="subject"
                  type="input"
                  label="Subject"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={values.subject}
                  onChange={handleChange}
                  error={!!errors.subject}
                  helperText={errors.subject}
                />
                <TextField
                  name="description"
                  type="input"
                  label="Description"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={values.description}
                  onChange={handleChange}
                  error={!!errors.description}
                  helperText={errors.description}
                  multiline
                  rows="5"
                />
                <TextField
                  name="Requester"
                  type="input"
                  label="Requester"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={user!.fullName}
                  disabled
                />
                <KeyboardDatePicker
                  margin="normal"
                  label="Due Date"
                  format="yyyy-MM-dd"
                  value={values.dueDate}
                  onChange={(selectedDate, selectedStringDate) => {
                    setFieldValue('dueDate', selectedStringDate);
                  }}
                  showTodayButton={true}
                  helperText={errors.dueDate}
                  error={!!errors.dueDate}
                  fullWidth
                />
              </DialogContent>
              <DialogActions style={{ marginRight: '5px', marginBottom: '10px' }}>
                {/* <Button
                  onClick={() => {
                    closeClick();
                  }}
                  color="default"
                  variant="contained"
                  startIcon={<CloseIcon />}
                >
                  Close
                </Button> */}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<NextIcon />}
                >
                  Next
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default TaskFormDialog;
