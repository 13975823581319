import { Grid } from '@material-ui/core';
import React from 'react';
import { CheckBoxFastField, TextFieldFastField } from '../../../components';

interface SamDevNlPrintingFrontProps {}

const SampleDevelopmentNlPrintingFront: React.FC<SamDevNlPrintingFrontProps> = () => {
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12} sm={12}>
          <CheckBoxFastField name="printingFront" label="Front" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2} />
        <Grid item xs={4}>
          Color
        </Grid>
        <Grid item xs={4}>
          Pantone
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2}>
          <CheckBoxFastField name="printingFProcess" label="Process" />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFastField
            name="printingFProcessColor"
            label="Color"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFastField
            name="printingFProcessPantone"
            label="Pantone"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2}>
          <CheckBoxFastField name="printingFSolid" label="Solid" />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFastField
            name="printingFSolidColor"
            label="Color"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFastField
            name="printingFSolidPantone"
            label="Pantone"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2}>
          <CheckBoxFastField name="printingFMetalic" label="Metalic" />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFastField
            name="printingFMetalicColor"
            label="Color"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFastField
            name="printingFMetalicPantone"
            label="Pantone"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2}>
          <CheckBoxFastField name="printingFFoil" label="Foil" />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFastField
            name="printingFFoilColor"
            label="Color"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFastField
            name="printingFFoilPantone"
            label="Pantone"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2}>
          <CheckBoxFastField name="printingFOther" label="Other" />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFastField
            name="printingFOtherColor"
            label="Color"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFastField
            name="printingFOtherPantone"
            label="Pantone"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SampleDevelopmentNlPrintingFront;
