import { IOpportunity } from '../../models/opportunity.model';
import { AppAction, PageFilter } from '../app';
import {
  OpportunityCreatePayload,
  OpportunityListFilter,
  OpportunityUpdatePayload,
  ChangeProgramBrandPayload,
  MoveOpportunityPayload,
} from './opportunity.types';

export const FETCH_OPPORTUNITY = 'FETCH_OPPORTUNITY';
export const FETCH_OPPORTUNITY_LIST = 'FETCH_OPPORTUNITY_LIST';
export const CREATE_OPPORTUNITY = 'CREATE_OPPORTUNITY';
export const UPDATE_OPPORTUNITY = 'UPDATE_OPPORTUNITY';
export const CLEAR_OPPORTUNITY = 'CLEAR_OPPORTUNITY';
export const SUBMIT_FOR_APPROVAL = 'SUBMIT_FOR_APPROVAL';
export const CHANGE_PROGRAM_AND_BRAND = 'CHANGE_PROGRAM_AND_BRAND';
export const SUBMIT_FOR_REASSIGN_RESPONSIBILITY = 'SUBMIT_FOR_REASSIGN_RESPONSIBILITY';
export const MOVE_ITEM_TO_NEW_OPPORTUNITY = 'MOVE_ITEM_TO_NEW_OPPORTUNITY';

export const fetchOpportunity = (id: IOpportunity['id']): AppAction => ({
  type: FETCH_OPPORTUNITY,
  payload: id,
});

export const fetchOpportunityList = (
  filters: OpportunityListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_OPPORTUNITY_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createOpportunity = (payload: OpportunityCreatePayload): AppAction => ({
  type: CREATE_OPPORTUNITY,
  payload,
});

export const updateOpportunity = (payload: OpportunityUpdatePayload): AppAction => ({
  type: UPDATE_OPPORTUNITY,
  payload,
});

export const clearOpportunity = () => ({
  type: CLEAR_OPPORTUNITY,
});

export const submitForApproval = (id: IOpportunity['id']): AppAction => ({
  type: SUBMIT_FOR_APPROVAL,
  payload: id,
});

export const submitForReassignResponsibility = (
  assignmentNumber: IOpportunity['assignmentNumber'],
): AppAction => ({
  type: SUBMIT_FOR_REASSIGN_RESPONSIBILITY,
  payload: assignmentNumber,
});

export const changeProgramAndBrand = (payload: ChangeProgramBrandPayload): AppAction => ({
  type: CHANGE_PROGRAM_AND_BRAND,
  payload,
});

export const moveItemToNewOpportunity = (payload: MoveOpportunityPayload): AppAction => ({
  type: MOVE_ITEM_TO_NEW_OPPORTUNITY,
  payload,
});

export const fetchOpportunityListByOpportunityId = (
  opportunityId: number,
): AppAction => ({
  type: FETCH_OPPORTUNITY_LIST,
  payload: {
    filters: { 'OpportunityId.equalsTo': opportunityId },
    paging: { pageNumber: 0, pageSize: 99999 },
  },
});
