import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IForecast, IForecastWithAddedProps } from '../../../models/forecast.model';
import { ISubtaskItem } from '../../../models/subtaskItem.model';
import { clearSubtaskItemForecast, createMultipleSubtaskItemForecastList } from '../../../state/subtaskItemForecast';
import ForecastTable from './ForecastTable';

interface MultipleSubtaskItemForecastEditDialogProps {
  open: boolean;
  subtaskItems: ReadonlyArray<ISubtaskItem>;
  onClose: () => void;
  selectedSubtaskItemOnTable: ISubtaskItem;
}

const defaultNewRowValue: IForecast = {
  quantity: '',
  unitOfQuantity: '',
  frequency: '',
};

const MultipleSubtaskItemForecastEditDialog: React.FC<MultipleSubtaskItemForecastEditDialogProps> = props => {
  const { open, subtaskItems, onClose, selectedSubtaskItemOnTable } = props;
  const [forecasts, setForecasts] = useState<IForecastWithAddedProps[]>([]);

  const dispatch = useDispatch();

  const handleUpdate = (createForecastList: IForecast[]) => {
    const mapCreateForecastList = createForecastList.map(forecast => ({
      ...forecast,
    }));

    const createMultipleSubtaskItemForecastListPayload = {
      subtaskItems: [...subtaskItems] as ISubtaskItem[],
      multipleSubtaskItemForecastList: mapCreateForecastList,
      selectedSubtaskItemOnTableId: selectedSubtaskItemOnTable.id!,
    };

    dispatch(
      createMultipleSubtaskItemForecastList(createMultipleSubtaskItemForecastListPayload),
    );

    setForecasts(mapCreateForecastList);
  };

  const handleClose = () => {
    setForecasts([]);
    onClose();
  };

  useEffect(() => {
    return function cleanUp() {
      dispatch(clearSubtaskItemForecast());
    };
  }, [dispatch]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Edit Multiple Forecast</DialogTitle>
      <DialogContent>
        <ForecastTable
          defaultNewRowValue={defaultNewRowValue}
          onUpdate={handleUpdate}
          forecastList={forecasts}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MultipleSubtaskItemForecastEditDialog;
