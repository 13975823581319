import { Grid, Paper, Typography } from '@material-ui/core';
import {
  ContentState,
  convertToRaw,
  Editor,
  EditorState,
  getDefaultKeyBinding,
  KeyBindingUtil,
  RawDraftContentState,
  RichUtils,
} from 'draft-js';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import useStyles from './RichTextEditor.style';
import './style.css';
import Toolbar from './Toolbar';
import { stateToHTML } from 'draft-js-export-html';

interface RichTextEditorProps {
  maxCharacters?: number;
  disableDisplayCharactersRemaining?: boolean;
}

export interface IRichTextEditorRef {
  getRawContent: () => RawDraftContentState;
  clearEditorState: () => void;
  getCurrentEditorState: () => EditorState;
  getPlainText: () => string;
  hasText: () => boolean;
  getHtmlContent: () => string;
}

function RichTextEditor(props: RichTextEditorProps, ref: any) {
  const { maxCharacters = 10, disableDisplayCharactersRemaining = false } = props;
  const classes = useStyles();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [charactersRemaining, setCharactersRemaining] = useState<number>(maxCharacters);

  const handleChangeInlineFormat = (toggleInlineFormat: string) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, toggleInlineFormat));
  };

  const handleChangeBlockFormat = (toggleBlockFormat: string) => {
    setEditorState(RichUtils.toggleBlockType(editorState, toggleBlockFormat));
  };

  const handleKeyCommand = (command: string, editorState: EditorState) => {
    let newState: EditorState | null = RichUtils.handleKeyCommand(editorState, command);

    if (!newState && command === 'strikethrough') {
      newState = RichUtils.toggleInlineStyle(editorState, 'STRIKETHROUGH');
    }

    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const keyBindingFn = (event: any) => {
    if (KeyBindingUtil.hasCommandModifier(event) && event.shiftKey && event.key === 'X') {
      return 'strikethrough';
    }

    return getDefaultKeyBinding(event);
  };

  const handleChange = (currentEditorState: EditorState) => {
    const newContent = currentEditorState.getCurrentContent();
    const oldContent = editorState.getCurrentContent();
    if (newContent === oldContent || newContent.getPlainText().length <= maxCharacters) {
      setEditorState(currentEditorState);
    } else {
      const newEditorState = EditorState.undo(
        EditorState.push(
          editorState,
          ContentState.createFromText(oldContent.getPlainText()),
          'delete-character',
        ),
      );
      setEditorState(newEditorState);
    }
  };

  useImperativeHandle(ref, () => ({
    getRawContent: () => {
      return convertToRaw(editorState.getCurrentContent());
    },
    clearEditorState: () => {
      setEditorState(EditorState.createEmpty());
    },
    getPlainText: () => {
      return editorState.getCurrentContent().getPlainText();
    },
    hasText: () => {
      return editorState.getCurrentContent().hasText();
    },
    getCurrentEditorState: () => {
      return editorState;
    },
    getHtmlContent: () => {
      return stateToHTML(editorState.getCurrentContent());
    },
  }));

  useEffect(() => {
    setCharactersRemaining(
      maxCharacters -
        editorState
          .getCurrentContent()
          .getPlainText()
          .trim().length,
    );
  }, [maxCharacters, editorState]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Toolbar
          editorState={editorState}
          onChangeBlockFormat={handleChangeBlockFormat}
          onChangeInlineFormat={handleChangeInlineFormat}
        />
        <Paper variant="outlined" elevation={0}>
          <Editor
            editorState={editorState}
            onChange={handleChange}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={keyBindingFn}
          />
        </Paper>
        {!disableDisplayCharactersRemaining && (
          <Typography className={classes.charactersRemaining}>
            Characters Remaining: {charactersRemaining}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default React.forwardRef<IRichTextEditorRef, RichTextEditorProps>(RichTextEditor);
