import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  Add as AddIcon,
  FindReplace as FindReplaceIcon,
  ToggleOff as ToggleOffIcon,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IResponsibilityCustomer } from '../../../../models/responsibilityCustomer.model';
import { PageFilter } from '../../../../state/app';
import { AppState } from '../../../../state/configureStore';
import {
  fetchResponsibilityCustomerList,
  inactiveResponsibilityCustomer,
  ResponsibilityCustomerListFilter,
  ResponsibilityCustomerState,
} from '../../../../state/responsibilityCustomer';
import { useFilter } from '../../../hooks';
import AddResponsibilityCustomerDialog from './AddResponsibilityCustomerDialog';
import ResponsibilityCustomerReplaceDialog from './ResponsibilityCustomerReplaceDialog';
import { DateDisplay } from '../../../components';

const useStyles = makeStyles((theme) => ({
  addButton: {
    position: 'absolute',
    right: '5px',
    top: '20px',
  },
  container: {
    marginTop: '20px',
    position: 'relative',
  },
  table: {
    marginTop: '30px',
    position: 'static',
  },
  showAll: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
  },
  button: {
    fontSize: 10,
  },
  title: {
    position: 'relative',
    left: '5px',
    top: '25px',
  },
}));

interface ResponsibilityCustomerFormProps {
  responsibilityId?: number;
  companyId?: number;
  disabled?: boolean;
}

const ResponsibilityCustomerForm: React.FC<ResponsibilityCustomerFormProps> = (props) => {
  const { responsibilityId, companyId, disabled } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [openAddResponsibilityCustomerDialog, setOpenAddResponsibilityCustomerDialog] =
    useState(false);

  const [showAll, setShowAll] = useState(false);

  const [
    openResponsibilityCustomerReplaceDialog,
    setOpenResponsibilityCustomerReplaceDialog,
  ] = useState(false);

  const [selectedResponsibilityCustomer, setSelectedResponsibilityCustomer] =
    useState<IResponsibilityCustomer>({});

  const {
    items: responsibilityCustomers,
    totalItems,
    loading,
  } = useSelector<AppState, ResponsibilityCustomerState>(
    (state) => state.responsibilityCustomer,
  );

  const [filters, setFilters] = useFilter<ResponsibilityCustomerListFilter>({
    'ResponsibilityId.EqualsTo': responsibilityId,
  });
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const addNewCustomer = () => {
    setOpenAddResponsibilityCustomerDialog(true);
  };

  const ReplaceCustomer = (selectedRow: IResponsibilityCustomer) => {
    setOpenResponsibilityCustomerReplaceDialog(true);
    setSelectedResponsibilityCustomer(selectedRow);
  };

  const handleClose = () => {
    setOpenAddResponsibilityCustomerDialog(false);
  };

  const handleCloseReplace = () => {
    setOpenResponsibilityCustomerReplaceDialog(false);
  };

  useEffect(() => {
    if (responsibilityId) {
      dispatch(fetchResponsibilityCustomerList(filters, paging));
    }
  }, [responsibilityId, dispatch]);

  return (
    <>
      <div className={classes.container}>
        <Typography className={classes.title} variant="h6" component="h6">
          Customer
        </Typography>
        <div className={classes.addButton}>
          <Button
            variant="contained"
            color="primary"
            onClick={addNewCustomer}
            startIcon={<AddIcon />}
            disabled={disabled}
          >
            New Customer
          </Button>
        </div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Active Date</TableCell>
              <TableCell>Inactive Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.button}>
            {responsibilityCustomers
              .filter((responsibilityCustomer) => {
                if (!showAll) {
                  return responsibilityCustomer.active === true;
                }
                return responsibilityCustomer;
              })
              .map((responsibilityCustomer, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{responsibilityCustomer.customer?.name}</TableCell>
                    <TableCell>
                      <DateDisplay value={responsibilityCustomer.activeDate} />
                    </TableCell>
                    <TableCell>
                      <DateDisplay value={responsibilityCustomer.inactiveDate} />
                    </TableCell>
                    <TableCell>
                      <Button
                        className={classes.button}
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          ReplaceCustomer(responsibilityCustomer!);
                        }}
                        startIcon={<FindReplaceIcon />}
                        disabled={disabled}
                      >
                        Replace
                      </Button>
                      <Button
                        className={classes.button}
                        color="secondary"
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          dispatch(
                            inactiveResponsibilityCustomer(responsibilityCustomer.id!),
                          );
                        }}
                        startIcon={<ToggleOffIcon />}
                        disabled={disabled}
                      >
                        Inactive
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <div className={classes.showAll}>
          <Button
            color="primary"
            size={'small'}
            onClick={() => {
              setShowAll((previousState) => !previousState);
            }}
          >
            {showAll ? 'show less' : 'show all'}
          </Button>
        </div>
      </div>
      <AddResponsibilityCustomerDialog
        companyId={companyId!}
        open={openAddResponsibilityCustomerDialog}
        handleClose={handleClose}
        responsibilityId={responsibilityId!}
      />
      <ResponsibilityCustomerReplaceDialog
        open={openResponsibilityCustomerReplaceDialog}
        onClose={handleCloseReplace}
        responsibilityCustomer={selectedResponsibilityCustomer}
      />
    </>
  );
};

export default ResponsibilityCustomerForm;
