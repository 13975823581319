import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_CUSTOMER,
  CREATE_CUSTOMER,
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_LIST,
  UPDATE_CUSTOMER,
} from './customer.actions';
import { Customer, CustomerState } from './customer.types';

const getInitialCustomerState = (): Customer => ({
  id: 0,
  name: '',
  companyId: 0,
  externalCompanyId: '',
  externalId: '',
  externalSource: '',
});

const initialState: CustomerState = {
  errorMessage: '',
  item: getInitialCustomerState(),
  items: [],
  loading: false,
  totalItems: 0,
};

export default (
  state: CustomerState = initialState,
  action: AppAction,
): CustomerState => {
  switch (action.type) {
    case REQUEST(FETCH_CUSTOMER_LIST):
    case REQUEST(CREATE_CUSTOMER):
    case REQUEST(FETCH_CUSTOMER):
    case REQUEST(UPDATE_CUSTOMER):
      return { ...state, loading: true };
    case SUCCESS(FETCH_CUSTOMER_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_CUSTOMER_LIST):
    case FAILURE(CREATE_CUSTOMER):
    case FAILURE(FETCH_CUSTOMER):
    case FAILURE(UPDATE_CUSTOMER):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_CUSTOMER):
    case SUCCESS(FETCH_CUSTOMER):
    case SUCCESS(UPDATE_CUSTOMER):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_CUSTOMER:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
