import React, { useState, useEffect } from 'react';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import {
  FormatBold as FormatBoldIcon,
  FormatItalic as FormatItalicIcon,
  FormatListBulleted as FormatListBulletedIcon,
  FormatListNumbered as FormatListNumberedIcon,
  FormatStrikethrough as FormatStrikethroughIcon,
  FormatUnderlined as FormatUnderlinedIcon,
} from '@material-ui/icons/';
import { EditorState } from 'draft-js';

import { isEqual } from 'lodash';

interface ToolbarProps {
  onChangeBlockFormat: (toggleBlockFormat: string) => void;
  onChangeInlineFormat: (toggleFormatValue: string) => void;
  editorState: EditorState;
}

const Toolbar: React.FC<ToolbarProps> = props => {
  const { editorState, onChangeBlockFormat, onChangeInlineFormat } = props;
  const [inlineFormats, setInlineFormats] = useState<string[]>([]);
  const [blockFormat, setBlockFormat] = useState<string>('');
  const currentInlineStyle = editorState.getCurrentInlineStyle().toJS();
  const selection = editorState.getSelection();
  const currentBlockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const handleChangeInlineFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[],
  ) => {
    const moreFormatValue =
      newFormats.length > inlineFormats.length ? newFormats : inlineFormats;
    const lessFormatValue =
      newFormats.length > inlineFormats.length ? inlineFormats : newFormats;

    const toggleFormatValue = moreFormatValue.filter(
      format => !lessFormatValue.includes(format),
    )[0];

    onChangeInlineFormat(toggleFormatValue);
  };

  const handleChangeBlockFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormat: string | null,
  ) => {
    if (newFormat) {
      onChangeBlockFormat(newFormat);
    }
  };

  useEffect(() => {
    if (!isEqual(currentInlineStyle, inlineFormats)) {
      setInlineFormats(currentInlineStyle);
    }
  }, [currentInlineStyle, inlineFormats]);

  useEffect(() => {
    setBlockFormat(currentBlockType);
  }, [currentBlockType]);

  return (
    <>
      <ToggleButtonGroup
        value={inlineFormats}
        size="small"
        onChange={handleChangeInlineFormat}
        onMouseDown={e => e.preventDefault()}
      >
        <ToggleButton value="BOLD">
          <FormatBoldIcon />
        </ToggleButton>
        <ToggleButton value="ITALIC">
          <FormatItalicIcon />
        </ToggleButton>
        <ToggleButton value="UNDERLINE">
          <FormatUnderlinedIcon />
        </ToggleButton>
        <ToggleButton value="STRIKETHROUGH">
          <FormatStrikethroughIcon />
        </ToggleButton>
      </ToggleButtonGroup>

      <ToggleButtonGroup
        exclusive
        value={blockFormat}
        size="small"
        onChange={handleChangeBlockFormat}
        onMouseDown={e => e.preventDefault()}
      >
        <ToggleButton value="unordered-list-item">
          <FormatListBulletedIcon />
        </ToggleButton>
        <ToggleButton value="ordered-list-item">
          <FormatListNumberedIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

export default Toolbar;
