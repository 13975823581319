import { AppAction } from '../../../../state/app';
import { FAILURE, REQUEST, SUCCESS } from '../../../../utils/actionTypeUtil';
import {
  CLEAR_DIALOG,
  CONFIRM_ALL,
  CONFIRM_ITEM,
  SEARCH_ALL,
  SEARCH_ITEM,
  SET_SEARCH_BLOCK_FORM,
} from './addMultipleBlockDialog.actions';
import {
  AddMultipleBlockDialogState,
  ISearchBlock,
  ISearchBlockForm,
} from './addMultipleBlockDialog.types';

const initialState: AddMultipleBlockDialogState = {
  form: {
    searchBlocks: [],
  } as ISearchBlockForm,
  hasSearch: false,
  hasConfirm: false,
  isLoading: false,
};

export default (
  state: AddMultipleBlockDialogState = initialState,
  action: AppAction,
): AddMultipleBlockDialogState => {
  const { form } = state;
  const { searchBlocks } = form;
  switch (action.type) {
    case REQUEST(SEARCH_ITEM):
      return {
        ...state,
        form: {
          ...form,
          searchBlocks: replaceItem(searchBlocks, action.payload.index, {
            status: 'Loading',
            hasVerifyError: false,
          }),
        },
      };
    case SUCCESS(SEARCH_ITEM):
      return {
        ...state,
        form: {
          ...form,
          searchBlocks: replaceItem(searchBlocks, action.payload.index, {
            status: 'Success',
            block: action.payload.data,
            hasVerifyError: false,
          }),
        },
      };
    case FAILURE(SEARCH_ITEM):
      return {
        ...state,
        form: {
          ...form,
          searchBlocks: replaceItem(searchBlocks, action.payload.index, {
            status: 'Error',
            message: action.payload.errorMessage,
            hasVerifyError: true,
          }),
        },
      };
    case REQUEST(CONFIRM_ITEM):
      return {
        ...state,
        form: {
          ...form,
          searchBlocks: replaceItem(searchBlocks, action.payload.index, {
            status: 'Submitting',
          }),
        },
      };
    case SUCCESS(CONFIRM_ITEM):
      return {
        ...state,
        form: {
          ...form,
          searchBlocks: replaceItem(searchBlocks, action.payload.index, {
            status: 'Success',
          }),
        },
      };
    case FAILURE(CONFIRM_ITEM):
      return {
        ...state,
        form: {
          ...form,
          searchBlocks: replaceItem(searchBlocks, action.payload.index, {
            status: 'Error',
            message: action.payload.errorMessage,
          }),
        },
      };
    case REQUEST(CONFIRM_ALL):
      return {
        ...state,
        isLoading: true,
        form: {
          ...form,
          searchBlocks: setAllAsPendingConfirm(searchBlocks),
        },
      };
    case REQUEST(SEARCH_ALL): {
      return {
        ...state,
        isLoading: true,
        form: {
          ...form,
          searchBlocks: setAllAsPending(searchBlocks),
        },
      };
    }
    case SUCCESS(CONFIRM_ALL): {
      return {
        ...state,
        isLoading: false,
        hasConfirm: true,
      };
    }
    case SUCCESS(SEARCH_ALL):
    case FAILURE(SEARCH_ALL): {
      return {
        ...state,
        hasSearch: true,
        isLoading: false,
      };
    }
    case SET_SEARCH_BLOCK_FORM:
      return {
        ...state,
        hasSearch: false,
        isLoading: false,
        hasConfirm: false,
        form: { ...action.payload },
      };
    case CLEAR_DIALOG: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

function replaceItem(
  items: ReadonlyArray<ISearchBlock>,
  index: number,
  newItem: ISearchBlock,
): ReadonlyArray<ISearchBlock> {
  const newItems = [...items];
  newItems[index] = { ...newItems[index], ...newItem };
  return newItems;
}

function setAllAsPending(items: ReadonlyArray<ISearchBlock>) {
  return items.map(item => {
    return { ...item, status: 'Pending' };
  });
}

function setAllAsPendingConfirm(items: ReadonlyArray<ISearchBlock>) {
  return items.map(item => {
    if (item.hasVerifyError) {
      return { ...item, status: 'Skipped' };
    }
    return { ...item, status: 'Pending Confirm' };
  });
}
