import { Card, CardContent, TextField } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import {
  clearCustomerGroup,
  createCustomerGroup,
  fetchCustomerGroup,
  updateCustomerGroup,
  CustomerGroup,
  CustomerGroupState,
} from '../../../state/customerGroup';
import { AppState } from '../../../state/configureStore';
import {
  CompanyComboBox,
  FixedPositionButtons,
  Loading,
  PageTitle,
} from '../../components';
import useStyles from './customerGroupForm.style';
import { Company } from '../../../state/company';
import {
  clearCustomerGroupMember,
  fetchCustomerGroupMemberList,
} from '../../../state/customerGroupMember';
import CustomerGroupMemberForm from './CustomerGroupMemberForm';

const CustomerGroupSchema = Yup.object().shape({
  companyId: Yup.number()
    .moreThan(0, 'Company is required')
    .nullable(true)
    .required('Company is required'),
  name: Yup.string()
    .nullable(true)
    .required('Name is required'),
});

interface CustomerGroupFormRouteParamsProps {
  id: string;
}

interface CustomerGroupFormProps
  extends RouteComponentProps<CustomerGroupFormRouteParamsProps> {}

const CustomerGroupForm: React.FC<CustomerGroupFormProps> = props => {
  const dispatch = useDispatch();
  const { match, history } = props;
  const {
    params: { id: paramsId },
  } = match;
  const isUpdateMode = paramsId !== undefined;
  const classes = useStyles();
  const { item: customerGroup, loading } = useSelector<AppState, CustomerGroupState>(
    state => state.customerGroup,
  );

  const redirectToCustomerGroupList = () => {
    history.goBack();
  };

  const handleSubmit = (values: CustomerGroup, actions: FormikHelpers<CustomerGroup>) => {
    if (isUpdateMode) {
      dispatch(updateCustomerGroup({ values, history }));
    } else {
      dispatch(createCustomerGroup({ values, history }));
    }
    actions.setSubmitting(false);
  };

  useEffect(() => {
    if (isUpdateMode) {
      dispatch(fetchCustomerGroup(+paramsId));
      dispatch(
        fetchCustomerGroupMemberList(
          {
            'CustomerGroupId.equalsTo': +paramsId,
          },
          { pageNumber: 0, pageSize: 9999 },
        ),
      );
    } else {
      dispatch(clearCustomerGroup());
      dispatch(clearCustomerGroupMember());
    }

    return function cleanUp() {
      dispatch(clearCustomerGroup());
      dispatch(clearCustomerGroupMember());
    };
  }, [paramsId, dispatch, isUpdateMode]);

  return (
    <>
      <Card className={classes.root}>
        <Formik
          enableReinitialize={true}
          initialValues={{ ...customerGroup }}
          validationSchema={CustomerGroupSchema}
          onSubmit={handleSubmit}
          validateOnChange={false}
          isInitialValid={true}
        >
          {({ values, handleChange, errors, submitForm, setFieldValue }) => {
            return (
              <Form>
                <Loading isLoading={loading}>
                  <PageTitle>
                    {isUpdateMode
                      ? `Update Customer Group ${customerGroup.name}`
                      : `New Customer Group`}
                  </PageTitle>
                  <CardContent>
                    <CompanyComboBox
                      handleChange={result => {
                        setFieldValue('company', result ? result : undefined);
                        setFieldValue('companyId', result ? result.id : undefined);
                      }}
                      selectedValue={values.company as Company}
                      helperText={errors.companyId}
                      error={!!errors.companyId}
                    />
                    <TextField
                      name="name"
                      type="input"
                      label="Name"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      value={values.name || ''}
                      onChange={handleChange}
                      error={!!errors.name}
                      helperText={errors.name}
                    />
                  </CardContent>
                  <FixedPositionButtons
                    actions={[
                      {
                        name: isUpdateMode ? `Update` : `Create`,
                        onClick: () => submitForm(),
                        color: 'primary' as 'primary',
                        disabled: false,
                        icon: 'save',
                      },
                      {
                        name: 'CANCEL',
                        onClick: redirectToCustomerGroupList,
                        color: 'default' as 'default',
                        disabled: false,
                      },
                    ]}
                    open
                  />
                </Loading>
              </Form>
            );
          }}
        </Formik>
      </Card>
      {isUpdateMode && (
        <CustomerGroupMemberForm
          companyId={customerGroup.companyId as number}
          customerGroupId={customerGroup.id as number}
        />
      )}
    </>
  );
};

export default CustomerGroupForm;
