import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../state/configureStore';
import useStyles from './progressBar.style';
import { ProgressBarState } from './progressBar.types';

const ProgressBar: React.FC = () => {
  const { display } = useSelector<AppState, ProgressBarState>(state => state.progressBar);
  const classes = useStyles({ display });

  return (
    <div className={classes.progress}>
      <LinearProgress color="secondary" />
    </div>
  );
};

export default ProgressBar;
