import React, { useEffect } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { ISubtaskItemSummaryReportFilter } from '../../../../../state/report';
import {
  IFilterContainerConfigProps,
  FilterContainer,
} from '../../../../components/FilterContainer';
import {
  SearchField,
  SearchDateField,
  SearchDropdownField,
} from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../state/configureStore';
import {
  fetchAllSubtaskTypeList,
  SubtaskTypeState,
} from '../../../../../state/subtaskType';

const SubtaskItemSummaryReportFilterComponent: React.FC<
  IFilterContainerConfigProps<ISubtaskItemSummaryReportFilter>
> = (props) => {
  const dispatch = useDispatch();
  const { items: subtaskTypeList } = useSelector<AppState, SubtaskTypeState>(
    (state) => state.subtaskType,
  );

  useEffect(() => {
    dispatch(fetchAllSubtaskTypeList());
  }, [dispatch]);

  return (
    <FilterContainer
      {...props}
      render={(filterProps) => {
        const { localFilter, handleChangeFilter, handleChangeDateFilter } = filterProps;
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Program"
                filterKey="SmTaskItem.OpportunityItem.Block.Program.Name.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Brand"
                filterKey="SmTaskItem.OpportunityItem.Block.Brand.Name.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Block No"
                filterKey="SmTaskItem.OpportunityItem.Block.BlockNo.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            {/* <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Assignment Number"
                filterKey="SmTaskItem.OpportunityItem.Opportunity.AssignmentNumber.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid> */}
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="TaskNumber"
                filterKey="SmSubtask.SMTask.TaskNumber.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            {/* <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Subject"
                filterKey="SmSubtask.Subject.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid> */}
            <Grid item xs={12} md={3} lg={2}>
              <SearchDropdownField
                id={'subtask-type-filter'}
                label={'Type'}
                onChange={handleChangeFilter}
                filters={localFilter}
                filterKey={'SmSubtask.SmSubTaskType.Code.EqualsTo'}
              >
                <MenuItem value="">All</MenuItem>
                {subtaskTypeList.map((subtaskType) => {
                  return (
                    <MenuItem key={subtaskType.id} value={subtaskType.code}>
                      {subtaskType.name}
                    </MenuItem>
                  );
                })}
              </SearchDropdownField>
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Requester"
                filterKey="SmSubtask.Requester.FullNameWithUserName.contains"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Assignee"
                filterKey="SmSubtask.SmSubtaskAssignees.User.FullNameWithUserName.contains"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            {/* <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Account Owner"
                filterKey="SmTaskItem.OpportunityItem.Opportunity.AccountOwner.FirstName.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid> */}
            <Grid item xs={12} md={3} lg={2}>
              <SearchDateField
                showTodayButton={true}
                fullWidth={true}
                label="Start Due Date"
                onChange={handleChangeDateFilter}
                filters={localFilter}
                filterKey={'SmSubtask.StartDueDate.GreaterThanOrEqual'}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchDateField
                showTodayButton={true}
                fullWidth={true}
                label="End Due Date"
                onChange={handleChangeDateFilter}
                filters={localFilter}
                filterKey={'SmSubtask.EndDueDate.LessThanOrEqual'}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchDateField
                showTodayButton={true}
                fullWidth={true}
                label="Start Created Date"
                onChange={handleChangeDateFilter}
                filters={localFilter}
                filterKey={'StartCreatedAt.GreaterThanOrEqual'}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchDateField
                showTodayButton={true}
                fullWidth={true}
                label="End Created Date"
                onChange={handleChangeDateFilter}
                filters={localFilter}
                filterKey={'EndCreatedAt.LessThanOrEqual'}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default SubtaskItemSummaryReportFilterComponent;
