import { ISubtaskItemMoq } from '../models/subtaskItemMoq.model';
import { PageFilter } from '../state/app';
import { SubtaskItemMoqListFilter } from '../state/subtaskItemMoq';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/smSubtaskItemMoqs`;

export const fetchSubtaskItemMoqList = async ({
  filters,
  paging,
}: {
  filters: SubtaskItemMoqListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchSubtaskItemMoq = async (id: ISubtaskItemMoq['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createSubtaskItemMoq = async (subtaskItemMoq: ISubtaskItemMoq) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, subtaskItemMoq);
};

export const updateSubtaskItemMoq = async (subtaskItemMoq: ISubtaskItemMoq) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, subtaskItemMoq);
};

export const deleteSubtaskItemMoq = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};

export const removeAndCreateSubtaskItemMoqs = async (
  smSubtaskItemId: number,
  subtaskItemMoqs: Array<ISubtaskItemMoq>,
) => {
  const requestUrl = `/api/smSubtaskItems/${smSubtaskItemId}/smSubtaskItemMoqs`;
  return await axios.post(requestUrl, subtaskItemMoqs);
};
