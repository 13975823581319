import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_EBLOCK_PATTERN,
  CREATE_EBLOCK_PATTERN,
  FETCH_EBLOCK_PATTERN,
  FETCH_EBLOCK_PATTERN_LIST,
  UPDATE_EBLOCK_PATTERN,
} from './eBlockPattern.action';
import { EBlockPatternState } from './eBlockPattern.type';
import { IEBlockPattern } from '../../models/eBlockPattern';

const initialState: EBlockPatternState = {
  errorMessage: '',
  item: {} as Readonly<IEBlockPattern>,
  items: [] as ReadonlyArray<IEBlockPattern>,
  loading: false,
  totalItems: 0,
};

export default (
  state: EBlockPatternState = initialState,
  action: AppAction,
): EBlockPatternState => {
  switch (action.type) {
    case REQUEST(FETCH_EBLOCK_PATTERN_LIST):
    case REQUEST(CREATE_EBLOCK_PATTERN):
    case REQUEST(FETCH_EBLOCK_PATTERN):
    case REQUEST(UPDATE_EBLOCK_PATTERN):
      return { ...state, loading: true };
    case SUCCESS(FETCH_EBLOCK_PATTERN_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_EBLOCK_PATTERN_LIST):
    case FAILURE(CREATE_EBLOCK_PATTERN):
    case FAILURE(FETCH_EBLOCK_PATTERN):
    case FAILURE(UPDATE_EBLOCK_PATTERN):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_EBLOCK_PATTERN):
    case SUCCESS(FETCH_EBLOCK_PATTERN):
    case SUCCESS(UPDATE_EBLOCK_PATTERN):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_EBLOCK_PATTERN:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
