import React from 'react';
import { ITask } from '../../models/task.model';
import { fetchTaskList as fetchTaskListService } from '../../services/taskService';
import AsyncAutoComplete from './AsyncAutoComplete';

interface TaskComboBoxProps {
  handleChange: (result: ITask) => void;
  selectedValue: ITask;
  isDisabled?: boolean;
  error?: boolean;
  helperText?: string;
}

const TaskComboBox: React.FC<TaskComboBoxProps> = props => {
  const { handleChange, selectedValue, isDisabled = false, error, helperText } = props;

  const taskPromiseOptions = async (inputValue: string) => {
    const { data: rows } = await fetchTaskListService({
      filters: { 'TaskNumber.Contains': inputValue },
      paging: { pageNumber: 0, pageSize: 10 },
    });

    return rows;
  };

  return (
    <AsyncAutoComplete
      name="Task"
      value={selectedValue}
      getOptionValue={(option: ITask) => {
        return option.id!.toString();
      }}
      getOptionLabel={(option: ITask) => {
        return option.taskNumber!;
      }}
      promiseOptions={taskPromiseOptions}
      placeholder={'Type Task Number'}
      handleChange={handleChange}
      isDisabled={isDisabled}
      error={error}
      helperText={helperText}
    />
  );
};

export default TaskComboBox;
