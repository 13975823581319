import { PageFilter } from '../state/app';
import axios from '../utils/axios';
import { ISampleDevelopmentPl } from '../models/sampleDevelopmentPl.model';
import { SampleDevelopmentPlListFilter } from '../state/sampleDevelopmentPl';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';

const baseUrl = `/api/sampleDevelopmentPls`;

export const fetchSampleDevelopmentPlList = async ({
  filters,
  paging,
}: {
  filters: SampleDevelopmentPlListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchSampleDevelopmentPl = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const downloadSampleDevelopmentPl = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}/export`;
  return await axios.get(requestUrl, { responseType: 'blob' });
};

export const createSampleDevelopmentPl = async (
  sampleDevelopmentPl: ISampleDevelopmentPl,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, sampleDevelopmentPl);
};

export const updateSampleDevelopmentPl = async (
  sampleDevelopmentPl: ISampleDevelopmentPl,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, sampleDevelopmentPl);
};

export const submitSampleDevelopmentPlForApproval = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}/submitForApproval`;
  return await axios.post(requestUrl);
};
