import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 'auto',
  },
  iconButton: {
    marginRight: theme.spacing(1),
  },
  hasRespondedFilter: {
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  searchField: {
    marginTop: theme.spacing(0),
    margin: theme.spacing(1),
  },
}));

export default useStyles;
