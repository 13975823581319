import { CardContent, Grid, Paper, Typography, Box } from '@material-ui/core';
import React from 'react';
import {
  CheckBoxFastField,
  TextFieldFastField,
  UnitOfMeasurementsFastField,
  UnitOfQuantitiesFastField,
} from '../../../components';
import { Image } from '../../../components/';

import distanceImg from './images/distance.png';

interface SampleDevelopmentTlPrintingProps {}

const SampleDevelopmentTlPrinting: React.FC<SampleDevelopmentTlPrintingProps> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={3}>
              <Typography>Printing</Typography>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              item
              xs={12}
            >
              <Grid item xs={12} md={2}>
                <Typography>Color:</Typography>
              </Grid>
              <Grid item xs={12} md={2} container alignItems="center" spacing={1}>
                <Grid item xs={12} md={1}>
                  <Typography display="inline">1 </Typography>
                </Grid>
                <Grid item xs={12} md={11}>
                  <CheckBoxFastField name="printColorSolidSeries" label="Solid Series" />
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextFieldFastField
                  name="printColorSolidSeriesData"
                  label="ColorSolidSeries"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField
                  name="printColorProcessSeries"
                  label="4 Process Series"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextFieldFastField
                  name="printColorProcessSeriesData"
                  label="4 Process Series"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="printColorEtc" label="Etc" />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextFieldFastField
                  name="printColorEtcData"
                  label="Etc"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={5} />
              <Grid item xs={12} md={2}>
                <Typography>Back up:</Typography>
              </Grid>
              <Grid item xs={12} md={2} container alignItems="center" spacing={1}>
                <Grid item xs={12} md={1}>
                  <Typography display="inline">2 </Typography>
                </Grid>
                <Grid item xs={12} md={11}>
                  <CheckBoxFastField name="printBackUpClear" label="Clear" />
                </Grid>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="printBackUpWhite" label="White" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="printBackUpUnistrenght" label="Unistrenght" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField
                  name="printBackUpDyeBlockingSilver"
                  label="Dye Blocking Silver"
                />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="printBackUpEtc" label="Etc" />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextFieldFastField
                  name="printBackUpEtcData"
                  label="Etc"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={5} />
              <Grid item xs={12} md={2}>
                <Typography>Adhesive: </Typography>
              </Grid>
              <Grid item xs={12} md={2} container alignItems="center" spacing={1}>
                <Grid item xs={12} md={1}>
                  <Typography display="inline">3 </Typography>
                </Grid>
                <Grid item xs={12} md={11}>
                  <CheckBoxFastField name="printPowderAdhesive" label="Powder Adhesive" />
                </Grid>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="printAdhesive" label="Adhesive" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField
                  name="printAdhesiveAndPowder"
                  label="Adhesive+Powder Adhesive"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="printTTAdhesive" label="T/T Adhesive" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField
                  name="printTTAdhesiveAndPowder"
                  label="T/T Adhesive+Powder Adhesive"
                />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="printAdhesiveEtc" label="Adhesive Etc" />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextFieldFastField
                  name="printAdhesiveEtcData"
                  label="Adhesive Etc"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={5} />
              <Grid item xs={12} md={2}>
                <Typography>Die Cut</Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="dieCutShape" label="Cut In Shape" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="dieCutPiece" label="Cut In Piece" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="dieCutSheet" label="Sheet" />
              </Grid>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="dieCutEtc" label="Etc" />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextFieldFastField
                  name="dieCutEtcData"
                  label="Etc"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={5} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <Image width="100px" src={distanceImg} alt="Distance" />
              </Grid>
              <Grid item xs={12} md={1}>
                <TextFieldFastField
                  type="number"
                  step="any"
                  name="dieCutDistanceX"
                  label="X"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={1}>
                <TextFieldFastField
                  type="number"
                  step="any"
                  name="dieCutDistanceY"
                  label="Y"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={1}>
                <UnitOfMeasurementsFastField name="dieCutDistanceUnit" label="Unit" />
              </Grid>
              <Grid item xs={12} md={1}>
                <TextFieldFastField
                  type="number"
                  step="any"
                  name="dieCutQuantity"
                  label="Quantity"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={1}>
                <UnitOfQuantitiesFastField name="dieCutQuantityUnit" label="Unit" />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentTlPrinting;
