import { ITask } from '../../models/task.model';
import { AppAction, PageFilter } from '../app';
import { TaskCreateOrUpdatePayload, TaskListFilter } from './task.types';

export const FETCH_TASK = 'FETCH_TASK';
export const FETCH_TASK_LIST = 'FETCH_TASK_LIST';
export const CREATE_TASK = 'CREATE_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const CLEAR_TASK = 'CLEAR_TASK';
export const CREATE_SUBTASK = 'CREATE_SUBTASK';

export const fetchTask = (id: ITask['id']): AppAction => ({
  type: FETCH_TASK,
  payload: id,
});

export const fetchTaskList = (filters: TaskListFilter, paging: PageFilter): AppAction => {
  return {
    type: FETCH_TASK_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createTask = (payload: TaskCreateOrUpdatePayload): AppAction => ({
  type: CREATE_TASK,
  payload,
});

export const updateTask = (payload: TaskCreateOrUpdatePayload): AppAction => {
  return {
    type: UPDATE_TASK,
    payload,
  };
};

export const clearTask = () => ({
  type: CLEAR_TASK,
});
