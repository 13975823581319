import { IProjectProgramBrand } from '../models/projectProgramBrand.model';
import { PageFilter } from '../state/app';
import { ProjectProgramBrandListFilter } from '../state/projectProgramBrand';
import axios from '../utils/axios';

const baseUrl = `/api/smProjectProgramBrands`;

export const createProjectProgramBrand = async (
  projectProgramBrand: IProjectProgramBrand,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, projectProgramBrand);
};

export const fetchProjectProgramBrand = async (id: IProjectProgramBrand['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const fetchBrandByProjectId = async (projectId: number) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('smProjectId.equalsTo', projectId.toString());
  params.append('pageSize', '99999');
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchProjectProgramBrandList = async ({
  filters,
  paging,
}: {
  filters: ProjectProgramBrandListFilter;
  paging: PageFilter;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const deleteProjectProgramBrand = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};

export const updateProjectProgramBrand = async (
  projectProgramBrand: IProjectProgramBrand,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, projectProgramBrand);
};
