import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearSalesTeamMember,
  fetchSalesTeamMemberList,
  SalesTeamMemberListFilter,
  SalesTeamMemberState,
} from '../../../state/salesTeamMember';
import useStyles from './salesTeamMemberList.style';
import { PageContainer, PageTitle, TableLoading } from '../../components';
import Button from '@material-ui/core/Button';
import { exportSalesTeamMembersAsExcel } from '../../../services/salesTeamMemberService';
import SalesTeamMemberFilter from './SalesTeamMemberFilter';
import { useFilter } from '../../hooks';

const initialSalesTeamMemberListFilter: SalesTeamMemberListFilter = {};

const SalesTeamMemberList: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [filters, setFilters] = useFilter<SalesTeamMemberListFilter>(
    initialSalesTeamMemberListFilter,
  );
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });
  const {
    items: salesTeamMembers,
    totalItems,
    loading,
  } = useSelector<AppState, SalesTeamMemberState>((state) => state.salesTeamMember);

  useEffect(() => {
    dispatch(fetchSalesTeamMemberList(filters, paging));

    return function cleanUp() {
      dispatch(clearSalesTeamMember());
    };
  }, [dispatch, paging, filters]);

  const handleChangeFilter = (newFilter: SalesTeamMemberListFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const exportExcel = () => {
    exportSalesTeamMembersAsExcel(filters).catch((err) => console.error(err));
  };

  return (
    <PageContainer>
      <PageTitle>SSO</PageTitle>
      <SalesTeamMemberFilter
        filters={filters}
        onChangeFilter={handleChangeFilter}
        initialFilter={initialSalesTeamMemberListFilter}
      />
      <div className={classes.filterContainer}>
        <Button variant={'contained'} className={classes.button} onClick={exportExcel}>
          Export Excel
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Sales Team</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={5}>
            {salesTeamMembers.map((salesTeamMember, index) => (
              <TableRow key={salesTeamMember.id}>
                <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                <TableCell>{salesTeamMember.salesTeam!!.name}</TableCell>
                <TableCell>{salesTeamMember.user!!.firstName}</TableCell>
                <TableCell>{salesTeamMember.user!!.lastName}</TableCell>
                <TableCell>{salesTeamMember.externalId}</TableCell>
                <TableCell>{salesTeamMember.salesTeamRole!!.name}</TableCell>
              </TableRow>
            ))}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default SalesTeamMemberList;
