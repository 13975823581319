import React from 'react';
import { FastField, FieldProps } from 'formik';
import { TextField } from '@material-ui/core';

interface TextFieldFastFieldProps {
  name: string;
  step?: string;
  [x: string]: any;
}

const TextFieldFastField: React.FC<TextFieldFastFieldProps> = props => {
  const { name, step, ...other } = props;
  return (
    <FastField name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }: FieldProps<any>) => {
        return (
          <TextField
            type="input"
            InputLabelProps={{ shrink: true }}
            inputProps={{ step }}
            {...field}
            {...other}
          />
        );
      }}
    </FastField>
  );
};

export default TextFieldFastField;
