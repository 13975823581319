import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearWorkingTeamMember,
  fetchWorkingTeamMemberList,
  WorkingTeamMemberListFilter,
  WorkingTeamMemberState,
} from '../../../state/workingTeamMember';
import useStyles from './workingTeamMemberList.style';
import { PageContainer, PageTitle, TableLoading } from '../../components';
import WorkingTeamMemberFilter from './WorkingTeamMemberFilter';
import { useFilter } from '../../hooks';

const initialWorkingTeamMemberListFilter: WorkingTeamMemberListFilter = {};

const WorkingTeamMemberList: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [filters, setFilters] = useFilter<WorkingTeamMemberListFilter>(
    initialWorkingTeamMemberListFilter,
  );
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });
  const {
    items: workingTeamMembers,
    totalItems,
    loading,
  } = useSelector<AppState, WorkingTeamMemberState>((state) => state.workingTeamMember);

  useEffect(() => {
    dispatch(fetchWorkingTeamMemberList(filters, paging));

    return function cleanUp() {
      dispatch(clearWorkingTeamMember());
    };
  }, [dispatch, paging, filters]);

  const handleChangeFilter = (newFilter: WorkingTeamMemberListFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  return (
    <PageContainer>
      <PageTitle>Working Team Member</PageTitle>
      <WorkingTeamMemberFilter
        filters={filters}
        onChangeFilter={handleChangeFilter}
        initialFilter={initialWorkingTeamMemberListFilter}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Team</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>CS Code</TableCell>
            <TableCell>Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={5}>
            {workingTeamMembers.map((workingTeamMember, index) => (
              <TableRow key={workingTeamMember.id}>
                <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                <TableCell>{workingTeamMember.smWorkingTeam!!.name}</TableCell>
                <TableCell>{workingTeamMember.user!!.firstName}</TableCell>
                <TableCell>{workingTeamMember.user!!.lastName}</TableCell>
                <TableCell>{workingTeamMember.externalId}</TableCell>
                <TableCell>{workingTeamMember.salesTeamRole!.name}</TableCell>
              </TableRow>
            ))}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default WorkingTeamMemberList;
