import { PageFilter } from '../state/app';
import { Company, CompanyListFilter } from '../state/company';
import axios from '../utils/axios';

const baseUrl = `/api/companies`;

export const fetchCompanyList = async ({
  filters,
  paging,
}: {
  filters: CompanyListFilter;
  paging: PageFilter;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchCompany = async (id: Company['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createCompany = async (company: Company) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, company);
};

export const updateCompany = async (company: Company) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, company);
};
