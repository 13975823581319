import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createProgramAndBrandRequest,
  fetchProgramAndBrandRequest,
  fetchProgramAndBrandRequestList,
  updateProgramAndBrandRequest,
} from '../../services/programAndBrandRequestService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_PROGRAMANDBRANDREQUEST,
  FETCH_PROGRAMANDBRANDREQUEST,
  FETCH_PROGRAMANDBRANDREQUEST_LIST,
  UPDATE_PROGRAMANDBRANDREQUEST,
} from './programAndBrandRequest.actions';
import history from '../../history';

const formPageUrl = `/programAndBrandRequests/form`;

function* fetchProgramAndBrandRequestSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchProgramAndBrandRequest, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchProgramAndBrandRequestListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchProgramAndBrandRequestList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createProgramAndBrandRequestSaga(action: AppAction) {
  try {
    const { payload: newProgramAndBrandRequest, callback } = action;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createProgramAndBrandRequest, newProgramAndBrandRequest);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Created ProgramAndBrandRequest successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(`${formPageUrl}/${data.id}`);
    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateProgramAndBrandRequestSaga(action: AppAction) {
  try {
    const { payload: newProgramAndBrandRequest, callback } = action;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateProgramAndBrandRequest, newProgramAndBrandRequest);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Updated ProgramAndBrandRequest successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchProgramAndBrandRequest() {
  yield takeLatest(FETCH_PROGRAMANDBRANDREQUEST, fetchProgramAndBrandRequestSaga);
  yield debounce(
    250,
    FETCH_PROGRAMANDBRANDREQUEST_LIST,
    fetchProgramAndBrandRequestListSaga,
  );
  yield takeLatest(CREATE_PROGRAMANDBRANDREQUEST, createProgramAndBrandRequestSaga);
  yield takeLatest(UPDATE_PROGRAMANDBRANDREQUEST, updateProgramAndBrandRequestSaga);
}
