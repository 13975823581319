import axios from '../utils/axios';

const baseUrl = `/api/structures/`;

export const fetchUserStructures = async (username: string) => {
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}/user/${username}`);
};

export const fetchMemberStructures = async (username: string) => {
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}/member/${username}`);
};

export const fetchSquadStructureByAssignmentNumber = async (assignmentNumber: string) => {
  const requestUrl = `${baseUrl}squad/byAssignmentNumber/${assignmentNumber}`;
  return await axios.get(requestUrl);
};
