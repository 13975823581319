import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IMoq, IMoqWithAddedProps } from '../../../models/moq.model';
import { ITaskItemWithMoqs } from '../../../models/taskItem.model';
import { AppState } from '../../../state/configureStore';
import { TaskCreateState } from '../../../state/taskCreate';
import MoqTable from './MoqTable';

interface TaskItemMoqEditDialogProps {
  open: boolean;
  taskItemToEditMoq: ITaskItemWithMoqs;
  onClose: () => void;
  onSubmit: (taskItemWithMoqs: ITaskItemWithMoqs) => void;
}

const TaskItemMoqEditDialog: React.FC<TaskItemMoqEditDialogProps> = props => {
  const { open, taskItemToEditMoq, onClose, onSubmit } = props;
  const [moqs, setMoqs] = useState<IMoqWithAddedProps[]>([]);
  const { selectedUnitOfQuantity } = useSelector<AppState, TaskCreateState>(
    state => state.taskCreate,
  );

  const defaultNewRowValue = useMemo<IMoq>(() => {
    return {
      price: '',
      quantity: '',
      unitOfQuantity: selectedUnitOfQuantity,
      unitOfPrice: '',
    };
  }, [selectedUnitOfQuantity]);

  const handleClose = () => {
    setMoqs([]);
    onClose();
  };

  const handleUpdate = (createMoqList: IMoq[]) => {
    const mapCreateMoqList = createMoqList.map(moq => ({ ...moq, added: true }));
    setMoqs(mapCreateMoqList);
    const editSelectedTaskItemMoq = { ...taskItemToEditMoq, moqs: mapCreateMoqList };
    onSubmit(editSelectedTaskItemMoq);
  };

  useEffect(() => {
    if (taskItemToEditMoq.moqs) {
      setMoqs(taskItemToEditMoq.moqs!);
    }
  }, [taskItemToEditMoq, open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        {taskItemToEditMoq.opportunityItem
          ? taskItemToEditMoq.opportunityItem!.block!.blockNo
          : ''}{' '}
        MOQ
      </DialogTitle>
      <DialogContent>
        <MoqTable
          defaultNewRowValue={defaultNewRowValue}
          onUpdate={handleUpdate}
          moqList={moqs}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="default">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaskItemMoqEditDialog;
