import { Customer } from '../state/customer';
import { IOpportunity } from './opportunity.model';

export interface IOpportunityCustomer {
  id?: number;
  name?: string;
  sequence?: number;
  opportunityId?: number;
  customerId?: number;
  customer?: Customer | null;
  opportunity?: IOpportunity | null;
  ssoUsers?: ssoUsers[] | null;
}

export interface ssoUsers {
  userFullName?: string;
  status?: string;
  active?: boolean;
}

export const defaultValue: Readonly<IOpportunityCustomer> = {};
