import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import * as service from './changeLog.service';
import { call, put, takeLatest } from 'redux-saga/effects';
import { AppAction } from '../app';
import { pushNotificationMessage } from '../../views/components/Notification';
import * as actions from './changeLog.actions';

function* fetchOpportunityLog(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(service.fetchChangeLog, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watch() {
  yield takeLatest(actions.FETCH_OPPORTUNITY_LOG, fetchOpportunityLog);
}
