import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../state/configureStore';
import {
  clearSalesTeamMember,
  fetchSalesTeamMemberListBySalesTeamId,
  SalesTeamMemberState,
} from '../../../state/salesTeamMember';
import { TableLoading } from '../index';

interface SalesTeamMemberDialogProps {
  handleClose: () => void;
  open: boolean;
  salesTeamId: number;
}

const SalesTeamMemberDialog: React.FC<SalesTeamMemberDialogProps> = (props) => {
  const { open, handleClose, salesTeamId } = props;
  const dispatch = useDispatch();
  const { items: salesTeamMemberList, loading } = useSelector<
    AppState,
    SalesTeamMemberState
  >((state) => state.salesTeamMember);

  useEffect(() => {
    if (salesTeamId) {
      dispatch(fetchSalesTeamMemberListBySalesTeamId(salesTeamId));
    }
    return function cleanup() {
      dispatch(clearSalesTeamMember());
    };
  }, [dispatch, salesTeamId]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle>SSO</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sales Team</TableCell>
              <TableCell>UserName</TableCell>
              <TableCell>FirstName</TableCell>
              <TableCell>LastName</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={5}>
              {salesTeamMemberList.map((salesTeamMember, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{salesTeamMember.salesTeam!.name}</TableCell>
                    <TableCell>{salesTeamMember.user!.login}</TableCell>
                    <TableCell>{salesTeamMember.user!.firstName}</TableCell>
                    <TableCell>{salesTeamMember.user!.lastName}</TableCell>
                    <TableCell>{salesTeamMember.externalId}</TableCell>
                    <TableCell>{salesTeamMember.salesTeamRole!.description}</TableCell>
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SalesTeamMemberDialog;
