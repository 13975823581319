import { CopyOpportunityPayload } from '../../../../state/opportunity';

export const COPY_OPPORTUNITY = 'COPY_OPPORTUNITY';
export const CLEAR_COPY_OPPORTUNITY = 'CLEAR_COPY_OPPORTUNITY';

export const copyOpportunity = (payload: CopyOpportunityPayload) => ({
  type: COPY_OPPORTUNITY,
  payload,
});

export const clearCopyOpportunity = () => ({
  type: CLEAR_COPY_OPPORTUNITY,
});
