import { Button, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ISampleDevelopmentWl } from '../../../../models/sampleDevelopmentWl.model';

import { Save as SaveIcon, Send as SendIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import SampleDevelopmentStatus from '../../../../constants/sampleDevelopmentStatus';
import { AppState } from '../../../../state/configureStore';
import {
  createSampleDevelopmentWl,
  downloadSampleDevelopmentWl,
  fetchSampleDevelopmentWl,
  SampleDevelopmentWlState,
  submitSampleDevelopmentWlForApproval,
  updateSampleDevelopmentWl,
  clearSampleDevelopmentWl,
} from '../../../../state/sampleDevelopmentWl';
import {
  Loading,
  PageContainer,
  PageTitle,
  PageTopMenuContainer,
} from '../../../components';
import SampleDevelopmentWlHead from './SampleDevelopmentWlHead';
import SampleDevelopmentWlJob from './SampleDevelopmentWlJob';
import SampleDevelopmentWlDesign from './SampleDevelopmentWlDesign';
import SampleDevelopmentWlMaterial from './SampleDevelopmentWlMaterial';
import SampleDevelopmentWlFinishing from './SampleDevelopmentWlFinishing';
import SampleDevelopmentWlAssembly from './SampleDevelopmentWlAssembly';
import SampleDevelopmentWlDetail from './SampleDevelopmentWlDetail';
import SampleDevelopmentWlPacking from './SampleDevelopmentWlPacking';
import SampleDevelopmentWlSpecialInstruction from './SampleDevelopmentWlSpecialInstruction';
import SampleDevelopmentWlQuotation from './SampleDevelopmentWlQuotation';
import SampleDevelopmentWlLaw from './SampleDevelopmentWlLaw';
import SampleDevelopmentWlProductLevel from './SampleDevelopmentWlProductLevel';
import SampleDevelopmentWlSpecial from './SampleDevelopmentWlSpecial';
import SampleDevelopmentWlAlfrescoLink from './SampleDevelopmentWlAlfrescoLink';
import SampleDevelopmentWlFilePath from './SampleDevelopmentWlFilePath';
import moment from 'moment';
import SampleDevelopmentWlRim from './SampleDevelopmentWlRim';

const statusAllowForSubmitForApproval = [
  SampleDevelopmentStatus.PENDING_FOR_APPROVAL,
  SampleDevelopmentStatus.REJECTED,
];

interface SampleDevelopmentWlFormProps {
  id: string;
}

const SampleDevelopmentWlSchema = Yup.object().shape({
  customer: Yup.object().nullable(true).required('Customer is required'),
  block: Yup.object().nullable(true).required('Block is required'),
  smTask: Yup.object().nullable(true).required('Task is required'),
  salesTeamMember: Yup.object().nullable(true).required('SalesTeamMember is required'),
  sampleDevelopmentWlYarns: Yup.array().of(
    Yup.object().shape({
      sequence: Yup.number().required('Sequence is required'),
      color: Yup.string().required('Color is required'),
      pickQty: Yup.number().required('Pick Qty is required'),
    }),
  ),
});

const SampleDevelopmentWlForm: React.FC<SampleDevelopmentWlFormProps> = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: routeParamsId } = useParams<{
    id: string;
  }>();
  const sampleDevelopmentWlId = id ? id : routeParamsId;
  const isUpdateMode = sampleDevelopmentWlId !== undefined;
  const { item: sampleDevelopmentWl, loading: isLoading } = useSelector<
    AppState,
    SampleDevelopmentWlState
  >((s) => s.sampleDevelopmentWl);

  const redirectToSampleDevelopmentWlList = () => {
    history.goBack();
  };

  const handleSubmit = (values: ISampleDevelopmentWl) => {
    if (isUpdateMode) {
      dispatch(updateSampleDevelopmentWl({ values }));
    } else {
      dispatch(createSampleDevelopmentWl({ values }));
    }
  };

  useEffect(() => {
    if (sampleDevelopmentWlId) {
      dispatch(fetchSampleDevelopmentWl(+sampleDevelopmentWlId));
    }
    return function cleanUp() {
      dispatch(clearSampleDevelopmentWl());
    };
  }, [sampleDevelopmentWlId, dispatch]);

  return (
    <PageContainer>
      <Formik
        enableReinitialize={true}
        initialValues={{
          customer: null,
          smTask: null,
          block: null,
          salesTeamMember: null,
          dueDate: moment().format('YYYY-MM-DD'),
          seeModel: moment().format('YYYY-MM-DD'),
          quantity: 0,
          quantityUnit: 'PCS',
          quotationUnit: 'PCS',
          finishSizeUnit: 'MM',
          labelDiUnit: 'MM',
          jobTypeWL: false,
          jobDescBoardLoom: false,
          jobDescNeedleLoom: false,
          jobDescShuttleLoom: false,
          jobDescSatin: false,
          jobDescTaffeta: false,
          jobDescNormal: false,
          jobDescUpGround: false,
          jobDescDamask: false,
          jobDescTwill: false,
          jobDescReverse: false,
          jobDescDoubleFace: false,
          ownDesign: false,
          customerDesign: false,
          designSample: false,
          designOriginal: false,
          designArtwork: false,
          designZip: false,
          designCD: false,
          designFilm: false,
          designMail: false,
          designFile: false,
          designTIF: false,
          designEPS: false,
          designJPG: false,
          designPDF: false,
          designAI: false,
          designQXP: false,
          designSoftware: false,
          designAcrobat: false,
          designAcrobatData: '',
          designIllustrater: false,
          designIllustraterData: '',
          designPageMaker: false,
          designPageMakerData: '',
          designPhotoshop: false,
          designPhotoshopData: '',
          designFreeHand: false,
          designFreeHandData: '',
          designQuark: false,
          designQuarkData: '',
          designOther: false,
          designOtherData: '',
          finishSizeHigh: 0,
          colorProcess: false,
          colorSolid: false,
          colorCoated: false,
          colorUncoated: false,
          colorTextile: false,
          colorFoil: false,
          colorPastel: false,
          colorSolidProcess: false,
          colorMetalic: false,
          frontColorQuantity: 0,
          frontColorProcess: false,
          frontColorSolid: false,
          frontColorSolidData: '',
          frontColorPMS: false,
          frontColorPMSData: '',
          backColorQuantity: 0,
          backColorProcess: false,
          backColorSolid: false,
          backColorSolidData: '',
          backColorPT: false,
          backColorPTData: '',
          colorSizeChart: '',
          coatingChemical: false,
          coatingSoft: false,
          coatingMedium: false,
          coatingHard: false,
          cutTypeHeat: false,
          cutTypeUltrasonic: false,
          cutTypeMosonic: false,
          cutTypeLaser: false,
          cutTypeStraight: false,
          cutTypeCenter: false,
          cutTypeBooklet: false,
          cutTypeEmd: false,
          cutTypeMitre: false,
          cutTypeManhatton: false,
          cutTypeULoop: false,
          packingPiece: false,
          packingFold: false,
          packingRoll: false,
          packingTape: false,
          packingFoldHead: false,
          packingFoldHalf: false,
          packingMitre: false,
          packingSheet: false,
          accessoriesLaminet: false,
          accessoriesAdhesive: false,
          accessoriesVerane: false,
          accessoriesCapoc: false,
          accessoriesSmockingStitch: false,
          variableAmount: 0,
          variableSize: false,
          variableKeySize: false,
          variableStyleNo: false,
          variableColor: false,
          variableFabricContent: false,
          variableInstruction: false,
          variableWashingSymbol: false,
          variableArt: false,
          variableFactoryCode: false,
          variableCountry: false,
          variableBarcode: false,
          variablePrice: false,
          variableOther: false,
          variableOtherData: '',
          packingPlain: false,
          packingSpecial: false,
          packingSpecialData: '',
          specialInstruction: '',
          withoutQuotation: false,
          quotationType: false,
          quotationTypeO: false,
          quotationTypeOQty: 0,
          quotationTypeOCurrency: 'THB',
          quotationTypeM: false,
          quotationTypeMQty: 0,
          quotationTypeMCurrency: 'THB',
          quotationTypeP: false,
          quotationTypePQty: 0,
          quotationTypePCurrency: 'THB',
          quotationQty: 0,
          quotation: false,
          quotationNumber: '',
          withoutLaw: false,
          haveLaw: false,
          lawFormaldehyde: false,
          lawPentachlorophenol: false,
          lawNickel: false,
          lawAzoDyes: false,
          lawChromium: false,
          lawTrademarks: false,
          lawCodeOfConduct: false,
          topProductLevel: false,
          intermediateProductLevel: false,
          lowerProductLevel: false,
          specialMaterial: '',
          specialColor: '',
          specialDesign: '',
          specialMainProduction: '',
          specialFinishing: '',
          specialQC: '',
          specialPacking: '',
          specialOther: '',
          labelDiWidth: 0,
          labelDiLength: 0,
          finishSizeWidth: 0,
          finishSizeLength: 0,
          jobType: 'SAMPLE',
          orderCompany: null,
          orderCompanyId: 0,
          substratePrimaryOption: '1',
          substrateOther: '',
          alfrescoLink: '',
          filePath: '',
          heatCut: false,
          musonicCut: false,
          wovenEdge: false,
          cutTypeCool: false,
          cutTypePiece: false,
          cutTypeFold: false,
          cutTypeRoll: false,
          cutTypeTape: false,
          cutTypeFoldHead: false,
          cutTypeFoldHalf: false,
          accessoriesColor1: '',
          accessoriesColor2: '',
          ...sampleDevelopmentWl,
        }}
        validationSchema={SampleDevelopmentWlSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        isInitialValid={true}
      >
        {({ values, handleChange, errors, submitForm, setFieldValue }) => {
          return (
            <Form>
              <PageTitle>
                {isUpdateMode
                  ? `Update Sample Development WL ${values.sampleDevNumber}`
                  : `New Sample Development WL`}
              </PageTitle>
              <PageTopMenuContainer>
                {statusAllowForSubmitForApproval.includes(
                  sampleDevelopmentWl.status!,
                ) && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      dispatch(
                        submitSampleDevelopmentWlForApproval(sampleDevelopmentWl.id!),
                      );
                    }}
                    startIcon={<SendIcon />}
                  >
                    {`Submit for Approval`}
                  </Button>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  type="submit"
                >
                  {isUpdateMode ? `Update` : `Create`}
                </Button>
                <Button variant="contained" onClick={redirectToSampleDevelopmentWlList}>
                  Cancel
                </Button>
                {isUpdateMode && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      dispatch(
                        downloadSampleDevelopmentWl(parseInt(sampleDevelopmentWlId)),
                      );
                    }}
                  >
                    Download PDF
                  </Button>
                )}
              </PageTopMenuContainer>
              <Loading isLoading={isLoading}>
                <CardContent>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={12}
                  >
                    <SampleDevelopmentWlHead
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    item
                    xs={12}
                  >
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      item
                      xs={12}
                    >
                      <Paper variant="outlined">
                        <CardContent>
                          <Grid item xs={12}>
                            <Typography>Product Info</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentWlJob />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentWlDesign />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentWlMaterial
                              handleChange={handleChange}
                              values={values}
                              setFieldValue={setFieldValue}
                              errors={errors}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentWlRim />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentWlFinishing />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentWlAssembly />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentWlDetail />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentWlPacking />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentWlSpecialInstruction />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentWlQuotation />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentWlLaw />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentWlProductLevel />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentWlSpecial />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentWlAlfrescoLink />
                          </Grid>
                          <Grid item xs={12}>
                            <SampleDevelopmentWlFilePath />
                          </Grid>
                        </CardContent>
                      </Paper>
                    </Grid>
                  </Grid>
                </CardContent>
              </Loading>
            </Form>
          );
        }}
      </Formik>
    </PageContainer>
  );
};

export default SampleDevelopmentWlForm;
