import { IResponsibilitySalesTeamMember } from '../../models/responsibilitySalesTeamMember.model';
import { AppAction, PageFilter, AppCallback } from '../app';
import {
  ResponsibilitySalesTeamMemberCreateOrUpdatePayload,
  ResponsibilitySalesTeamMemberListFilter,
  ResponsibilitySalesTeamMemberReplacePayLoad,
  ResponsibilitySalesTeamMemberState,
} from './responsibilitySalesTeamMember.types';

export const FETCH_RESPONSIBILITY_SALESTEAM_MEMBER =
  'FETCH_RESPONSIBILITY_SALESTEAM_MEMBER';
export const FETCH_RESPONSIBILITY_SALESTEAM_MEMBER_LIST =
  'FETCH_RESPONSIBILITY_SALESTEAM_MEMBER_LIST';
export const CREATE_RESPONSIBILITY_SALESTEAM_MEMBER =
  'CREATE_RESPONSIBILITY_SALESTEAM_MEMBER';
export const UPDATE_RESPONSIBILITY_SALESTEAM_MEMBER =
  'UPDATE_RESPONSIBILITY_SALESTEAM_MEMBER';
export const CLEAR_RESPONSIBILITY_SALESTEAM_MEMBER =
  'CLEAR_RESPONSIBILITY_SALESTEAM_MEMBER';
export const DELETE_RESPONSIBILITY_SALESTEAM_MEMBER =
  ' DELETE_RESPONSIBILITY_SALESTEAM_MEMBER';
export const CREATE_RESPONSIBILITY_SALESTEAM_MEMBERS =
  'CREATE_RESPONSIBILITY_SALESTEAM_MEMBERS';
export const REPLACE_RESPONSIBILITY_SALESTEAM_MEMBER =
  'REPLACE_RESPONSIBILITY_SALESTEAM_MEMBER';
export const INACTIVE_RESPONSIBILITY_SALESTEAM_MEMBER =
  'INACTIVE_RESPONSIBILITY_SALESTEAM_MEMBER';
export const SET_RESPONSIBILITY_SALETEAM_MEMBER = 'SET_RESPONSIBILITY_SALETEAM_MEMBER';

export const fetchResponsibilitySalesTeamMember = (
  id: IResponsibilitySalesTeamMember['id'],
): AppAction => ({
  type: FETCH_RESPONSIBILITY_SALESTEAM_MEMBER,
  payload: id,
});

export const fetchResponsibilitySalesTeamMemberList = (
  filters: ResponsibilitySalesTeamMemberListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_RESPONSIBILITY_SALESTEAM_MEMBER_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchResponsibilitySalesTeamMemberListByresponsibilityId = (
  responsibilityId: number,
): AppAction => ({
  type: FETCH_RESPONSIBILITY_SALESTEAM_MEMBER_LIST,
  payload: {
    filters: { 'responsibilityId.equalsTo': responsibilityId },
    paging: { pageNumber: 0, pageSize: 99999 },
  },
});

export const createResponsibilitySalesTeamMember = (
  payload: ResponsibilitySalesTeamMemberCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_RESPONSIBILITY_SALESTEAM_MEMBER,
  payload,
});

export const createResponsibilitySalesTeamMembers = (
  payload: any,
  callback?: AppCallback,
): AppAction => ({
  type: CREATE_RESPONSIBILITY_SALESTEAM_MEMBERS,
  payload,
  callback,
});

export const updateResponsibilityCustomer = (
  payload: ResponsibilitySalesTeamMemberCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_RESPONSIBILITY_SALESTEAM_MEMBER,
  payload,
});

export const clearResponsibilitySalesTeamMember = () => ({
  type: CLEAR_RESPONSIBILITY_SALESTEAM_MEMBER,
});

export const deleteResponsibilitySalesTeamMember = (id: number) => ({
  type: DELETE_RESPONSIBILITY_SALESTEAM_MEMBER,
  payload: { id },
});

export const replaceResponsibilitySalesTeamMember = (
  payload: ResponsibilitySalesTeamMemberReplacePayLoad,
) => ({
  type: REPLACE_RESPONSIBILITY_SALESTEAM_MEMBER,
  payload,
});

export const inactiveResponsibilitySalesTeamMember = (id: number) => ({
  type: INACTIVE_RESPONSIBILITY_SALESTEAM_MEMBER,
  payload: { id },
});

export const setResponsibilitySalesTeamMember = (
  payload: IResponsibilitySalesTeamMember[],
): AppAction => ({
  type: SET_RESPONSIBILITY_SALETEAM_MEMBER,
  payload,
});

export const updateResponsibilitySalesTeamMember = (
  payload: ResponsibilitySalesTeamMemberCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_RESPONSIBILITY_SALESTEAM_MEMBER,
  payload,
});
