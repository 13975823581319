import { PageFilter } from '../state/app';
import {
  ResponsibilityWorkingTeamMemberListFilter,
  ResponsibilityWorkingTeamMemberReplacePayLoad,
} from '../state/responsibilityWorkingTeamMember';
import axios from '../utils/axios';
import { IResponsibilityWorkingTeamMember } from '../models/responsibilityWorkingTeamMember.model';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
const baseUrl = `/api/responsibilityWorkingTeamMembers`;

export const createResponsibilityWorkingTeamMember = async (
  responsibilityWorkingTeamMember: IResponsibilityWorkingTeamMember,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, responsibilityWorkingTeamMember);
};

export const fetchResponsibilityWorkingTeamMember = async (
  id: IResponsibilityWorkingTeamMember['id'],
) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const fetchResponsibilityWorkingTeamMemberList = async ({
  filters,
  paging,
}: {
  filters: ResponsibilityWorkingTeamMemberListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const deleteResponsibilityWorkingTeamMember = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};

export const inactiveResponsibilityWorkingTeamMember = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}/inactive`;
  return await axios.put(requestUrl);
};

export const updateResponsibilityWorkingTeamMember = async (
  responsibilityWorkingTeamMember: IResponsibilityWorkingTeamMember,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, responsibilityWorkingTeamMember);
};

export const replaceResponsibilityWorkingTeamMember = async (
  responsibilityWorkingTeamMemberReplacePayLoad: ResponsibilityWorkingTeamMemberReplacePayLoad,
) => {
  const requestUrl = `${baseUrl}/${responsibilityWorkingTeamMemberReplacePayLoad.responsibilityWorkingTeamMemberId}/replace`;
  return await axios.put(requestUrl, responsibilityWorkingTeamMemberReplacePayLoad);
};
