import { PageFilter, AppAction } from '../../../../state/app';

export const FETCH_COMMENT_DASHBOARD_LIST = 'FETCH_COMMENT_DASHBOARD_LIST';
export const CLEAR_COMMENT_DASHBOARD = 'CLEAR_COMMENT_DASHBOARD';

export const fetchTaskActivityListRelatedToUserId = (
  userId: number,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_COMMENT_DASHBOARD_LIST,
    payload: {
      userId,
      paging,
    },
  };
};

export const clearCommentDashboard = (): AppAction => ({
  type: CLEAR_COMMENT_DASHBOARD,
});
