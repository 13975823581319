// export type SubtaskType = 'ARTWORK' | 'SAMPLE' | 'PRICE' | 'OTHER' | 'PROOF' | 'MOCKUP';

// const SubtaskTypeCode = Object.freeze({
//   PROOF: 'PROOF' as SubtaskType,
//   MOCKUP: 'MOCKUP' as SubtaskType,
// });
// export default SubtaskTypeCode;

// export const SubtaskStatusLabel = Object.freeze({
//   [SubtaskTypeCode.PROOF]: 'Proof',
//   [SubtaskTypeCode.MOCKUP]: 'Mockup',
// });

export const SubtaskStatus = {
  proof: ['Pending', 'In Progress', 'Waiting Customer', 'Done'],
  mockup: ['Pending', 'In Progress', 'Waiting Customer', 'Done'],
  verifyData: ['Pending', 'In Progress', 'Done'],
  artwork: [
    'Pending',
    'In Progress',
    'Waiting NPD',
    'Approve By NPD',
    'Waiting Customer',
    'Done',
  ],
};
