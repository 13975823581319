import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createProductType,
  fetchProductType,
  fetchProductTypeList,
  updateProductType,
} from '../../services/productTypeService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_PRODUCTTYPE,
  FETCH_PRODUCTTYPE,
  FETCH_PRODUCTTYPE_LIST,
  UPDATE_PRODUCTTYPE,
} from './productType.actions';
import { ProductTypeCreateOrUpdatePayload } from './productType.types';

const listPageUrl = `/settings/productTypes`;

function* fetchProductTypeSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchProductType, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchProductTypeListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchProductTypeList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createProductTypeSaga(action: AppAction) {
  try {
    const { values, history } = action.payload as ProductTypeCreateOrUpdatePayload;
    const successMessage = `Created ${values.name} productType successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createProductType, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(listPageUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateProductTypeSaga(action: AppAction) {
  try {
    const { values, history } = action.payload as ProductTypeCreateOrUpdatePayload;
    const successMessage = `Updated ${values.name} productType successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateProductType, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(listPageUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchProductType() {
  yield takeLatest(FETCH_PRODUCTTYPE, fetchProductTypeSaga);
  yield debounce(250, FETCH_PRODUCTTYPE_LIST, fetchProductTypeListSaga);
  yield takeLatest(CREATE_PRODUCTTYPE, createProductTypeSaga);
  yield takeLatest(UPDATE_PRODUCTTYPE, updateProductTypeSaga);
}
