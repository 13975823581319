import { CardContent, Grid, Paper, Typography, Box, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { CheckBoxFastField, TextFieldFastField } from '../../../components';
import { SampleDevelopmentQueueDialog } from '../../../components/SampleDevelopmentQueueDialog';

interface SampleDevelopmentHlJobProps {
  setFieldValue: any;
}

const SampleDevelopmentHlJob: React.FC<SampleDevelopmentHlJobProps> = (props) => {
  const { setFieldValue } = props;
  const [openSampleDevQueueDialog, setOpenSampleDevQueueDialog] = useState(false);

  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Job</Typography>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              item
              xs={12}
            >
              <Grid item xs={12} md={2}>
                <Typography>Job Type ประเภทงาน:</Typography>
              </Grid>
              <Grid item xs={12} md={1}>
                <CheckBoxFastField name="jobTypeHL" label="HL" />
              </Grid>
              <Grid item xs={12} md={1}>
                <CheckBoxFastField name="jobTypeSO" label="SO" />
              </Grid>
              <Grid item xs={12} md={1}>
                <CheckBoxFastField name="jobTypeHTM" label="HTM" />
              </Grid>
              <Grid item xs={12} md={1}>
                <CheckBoxFastField name="jobTypeFSC" label="FSC" />
              </Grid>
              <Grid item xs={12} md={1}>
                <CheckBoxFastField name="jobTypeGRS" label="GRS" />
              </Grid>
              <Grid item xs={12} md={1}>
                <CheckBoxFastField name="jobTypeFood" label="FOOD" />
              </Grid>
              <Grid item xs={12} md={1}>
                <CheckBoxFastField name="jobTypeDirect" label="Direct" />
              </Grid>
              <Grid item xs={12} md={1}>
                <CheckBoxFastField name="jobTypeIndirect" label="Indirect" />
              </Grid>
              <Grid item xs={12} md={1}>
                <CheckBoxFastField name="jobTypeExternal" label="External" />
              </Grid>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>Job Description ลักษณะงาน:</Typography>
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="jobDescLetterPress" label="Letter Presss" />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="jobDescOffset" label="Offset" />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="jobDescFlexo" label="Flexo" />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="jobDescSilkScreen" label="Silk Screen" />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="jobDescThermal" label="Thermal" />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="jobDescHotStamping" label="Hot Stamping" />
            </Grid>
            <Grid item xs={12} md={1}>
              <CheckBoxFastField name="jobDescOther" label="Other" />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="jobDescOtherData"
                label="Other"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <Typography>Queue:</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="sampleDevelopmentQueue.queueDigitalProofCode"
                label="Digital Proof Code"
                variant="outlined"
                margin="dense"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="sampleDevelopmentQueue.queueDigitalProofName"
                label="Digital Proof Name"
                variant="outlined"
                margin="dense"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="sampleDevelopmentQueue.queuePlateCode"
                label="Plate Code"
                variant="outlined"
                margin="dense"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="sampleDevelopmentQueue.queuePlateName"
                label="Plate Name"
                variant="outlined"
                margin="dense"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => {
                  setOpenSampleDevQueueDialog(true);
                }}
              >
                Queue
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Paper>

      <SampleDevelopmentQueueDialog
        open={openSampleDevQueueDialog}
        onClose={(selectedQueue) => {
          setOpenSampleDevQueueDialog(false);
          if (selectedQueue) {
            setFieldValue('sampleDevelopmentQueueId', selectedQueue!.id);
            setFieldValue('sampleDevelopmentQueue', selectedQueue);
          }
        }}
      ></SampleDevelopmentQueueDialog>
    </Box>
  );
};

export default SampleDevelopmentHlJob;
