import { AppAction } from '../../../../state/app';
import { SampleDevelopmentNlApprovalRequest } from '../../../../state/sampleDevelopmentNlApprovalRequest/sampleDevelopmentNlApprovalRequest.types';
import { FAILURE, REQUEST, SUCCESS } from '../../../../utils/actionTypeUtil';
import { FETCH_PENDING_APPROVAL_SAMPLE_DEV_NL_LIST } from './pendingApprovalSampleDevNlRequestList.actions';

export interface PendingApprovalSampleDevNlRequestListState {
  loading: boolean;
  items: ReadonlyArray<SampleDevelopmentNlApprovalRequest>;
  errorMessage: string;
  totalItems: number;
}

const initialState: PendingApprovalSampleDevNlRequestListState = {
  errorMessage: '',
  items: [],
  loading: false,
  totalItems: 0,
};

export default (
  state: PendingApprovalSampleDevNlRequestListState = initialState,
  action: AppAction,
): PendingApprovalSampleDevNlRequestListState => {
  switch (action.type) {
    case REQUEST(FETCH_PENDING_APPROVAL_SAMPLE_DEV_NL_LIST):
      return { ...state, loading: true };
    case SUCCESS(FETCH_PENDING_APPROVAL_SAMPLE_DEV_NL_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_PENDING_APPROVAL_SAMPLE_DEV_NL_LIST):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    default:
      return state;
  }
};
