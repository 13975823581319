import { AppAction, AppBootstrapState } from './app.types';
import { REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { BOOTSTRAP } from './app.action';

const initialState: AppBootstrapState = {
  loading: true,
};

export default (state: AppBootstrapState = initialState, action: AppAction): AppBootstrapState => {
  switch (action.type) {
    case REQUEST(BOOTSTRAP):
      return { ...state, loading: true };
    case SUCCESS(BOOTSTRAP):
      return { ...state, loading: false };
    default:
      return state;
  }
};
