import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_BLOCK,
  CREATE_BLOCK,
  CREATE_BLOCKS,
  CREATE_EBLOCKS,
  FETCH_BLOCK,
  FETCH_BLOCK_LIST,
  FETCH_EBLOCK_LIST,
  SET_BLOCK,
  UPDATE_BLOCK,
  FETCH_EBLOCK,
  SYNC_BLOCK,
} from './block.actions';
import { Block, BlockState, IBlockCreate } from './block.types';

const getInitialBlockState = (): Block => ({});

const initialState: BlockState = {
  errorMessage: '',
  item: getInitialBlockState(),
  items: [],
  loading: false,
  totalItems: 0,
  errors: [],
  createBlocks: [] as ReadonlyArray<IBlockCreate>,
};

export default (state: BlockState = initialState, action: AppAction): BlockState => {
  switch (action.type) {
    case REQUEST(FETCH_BLOCK_LIST):
    case REQUEST(CREATE_BLOCK):
    case REQUEST(FETCH_BLOCK):
    case REQUEST(FETCH_EBLOCK):
    case REQUEST(UPDATE_BLOCK):
    case REQUEST(CREATE_BLOCKS):
    case REQUEST(CREATE_EBLOCKS):
      return { ...state, loading: true };
    case SUCCESS(FETCH_EBLOCK_LIST):
    case SUCCESS(FETCH_BLOCK_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case REQUEST(SYNC_BLOCK):
      return { ...state, loading: true };
    case SUCCESS(SYNC_BLOCK):
      return { ...state, loading: false };
    case FAILURE(SYNC_BLOCK):
    case FAILURE(FETCH_BLOCK_LIST):
    case FAILURE(FETCH_EBLOCK_LIST):
    case FAILURE(CREATE_BLOCK):
    case FAILURE(FETCH_BLOCK):
    case FAILURE(FETCH_EBLOCK):
    case FAILURE(UPDATE_BLOCK):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };

    case SUCCESS(CREATE_BLOCK):
    case SUCCESS(FETCH_BLOCK):
    case SUCCESS(FETCH_EBLOCK):
    case SUCCESS(UPDATE_BLOCK):
      return { ...state, loading: false, item: action.payload.data };
    case SUCCESS(CREATE_EBLOCKS):
    case SUCCESS(CREATE_BLOCKS):
      return {
        ...state,
        createBlocks: action.payload.data,
        loading: false,
      };
    case FAILURE(CREATE_EBLOCKS):
    case FAILURE(CREATE_BLOCKS):
      return { ...state, loading: false };
    case SET_BLOCK:
      return {
        ...state,
        item: action.payload,
      };
    case CLEAR_BLOCK:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
