import { ITaskAttachment, ITaskAttachmentUpload } from '../../models/taskAttachment.model';
import { AppAction, PageFilter } from '../app';
import { TaskAttachmentCreateOrUpdatePayload, TaskAttachmentListFilter } from './taskAttachment.types';

export const FETCH_TASK_ATTACHMENT = 'FETCH_TASK_ATTACHMENT';
export const FETCH_TASK_ATTACHMENT_LIST = 'FETCH_TASK_ATTACHMENT_LIST';
export const CREATE_TASK_ATTACHMENT = 'CREATE_TASK_ATTACHMENT';
export const UPDATE_TASK_ATTACHMENT = 'UPDATE_TASK_ATTACHMENT';
export const CLEAR_TASK_ATTACHMENT = 'CLEAR_TASK_ATTACHMENT';
export const UPLOAD_TASK_ATTACHMENT = 'UPLOAD_TASK_ATTACHMENT';
export const DOWNLOAD_TASK_ATTACHMENT = 'DOWNLOAD_TASK_ATTACHMENT';
export const UPLOAD_TASK_ATTACHMENTS = 'UPLOAD_TASK_ATTACHMENTS';

export const fetchTaskAttachment = (id: ITaskAttachment['id']): AppAction => ({
  type: FETCH_TASK_ATTACHMENT,
  payload: id,
});

export const fetchTaskAttachmentList = (
  filters: TaskAttachmentListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_TASK_ATTACHMENT_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchTaskAttachmentListByTaskId = (taskId: number): AppAction => {
  return {
    type: FETCH_TASK_ATTACHMENT_LIST,
    payload: { filters: { 'SMTaskId.EqualsTo': taskId }, paging: { pageSize: 9999, pageNumber: 0 } },
  };
};

export const createTaskAttachment = (
  payload: TaskAttachmentCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_TASK_ATTACHMENT,
  payload,
});

export const updateTaskAttachment = (
  payload: TaskAttachmentCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_TASK_ATTACHMENT,
  payload,
});

export const clearTaskAttachment = () => ({
  type: CLEAR_TASK_ATTACHMENT,
});

export const uploadTaskAttachment = (payload: ITaskAttachmentUpload): AppAction => ({
  type: UPLOAD_TASK_ATTACHMENT,
  payload,
});

export const uploadTaskAttachments = (payload: any): AppAction => ({
  type: UPLOAD_TASK_ATTACHMENTS,
  payload,
});
