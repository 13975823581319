import React, { useEffect, useState } from 'react';
import {
  Dialog,
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Slide,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
  Tooltip,
  Avatar,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Close as CloseIcon, GetApp as GetAppIcon } from '@material-ui/icons';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  PageContainer,
  PageTitle,
  TableLoading,
  UserLetterAvatar,
} from '../../../../components';
import {
  ISubtaskItemSummaryReportFilter,
  fetchSubtaskItemSummaryReportList,
  clearReport,
  ReportState,
} from '../../../../../state/report';
import { useFilter, useTableSorting } from '../../../../hooks';
import { PageFilter } from '../../../../../state/app';
import { AppState } from '../../../../../state/configureStore';
import { ISubtaskItemSummaryReport } from '../../../../../models/report.model';
import SubtaskItemSummaryReportFilterComponent from './SubtaskItemSummaryReportFilter';
import useStyles from './SubtaskItemSummaryReportStyle';
import { downloadSubtaskItemSummaryReport } from '../../../../../services/reportService';
import SortLabel from '../../../../components/SortLabel';
import { DateTimeDisplay, DateDisplay } from '../../../../components/DateTimeDisplay';
import { SubtaskItemStatusLabel } from '../../../../../constants';
import moment from 'moment';

interface SubtaskItemSummaryReportDialogProps {
  open: boolean;
  handleClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

const initialSubtaskItemsSummaryReportFilter: ISubtaskItemSummaryReportFilter = {
  'SmSubtask.SmSubTaskType.Code.EqualsTo': '',
  'SmSubtask.StartDueDate.GreaterThanOrEqual': moment()
    .startOf('month')
    .format('YYYY-MM-DD'),
  'SmSubtask.EndDueDate.LessThanOrEqual': moment()
    .add(2, 'month')
    .endOf('month')
    .format('YYYY-MM-DD'),
  'StartCreatedAt.GreaterThanOrEqual': null,
  'EndCreatedAt.LessThanOrEqual': null,
};

const SubtaskItemSummaryReportDialog: React.FC<SubtaskItemSummaryReportDialogProps> = (
  props,
) => {
  const { open, handleClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    loading,
    items: SubtaskItemSummaryReportList,
    totalItems,
  } = useSelector<AppState, ReportState>((state) => state.report);

  const [filters, setFilters] = useFilter<ISubtaskItemSummaryReportFilter>(
    initialSubtaskItemsSummaryReportFilter,
    true,
  );

  const [sort, setSort] = useTableSorting(
    {
      orderBy: 'CreatedAt',
      direction: 'desc',
    },
    true,
  );

  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  useEffect(() => {
    if (open) {
      dispatch(fetchSubtaskItemSummaryReportList(filters, { ...paging, sort }));
    }
    return function cleanUp() {
      dispatch(clearReport());
    };
  }, [dispatch, paging, filters, open, sort]);

  const handleChangeFilter = (newFilter: ISubtaskItemSummaryReportFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const exportExcel = () => {
    downloadSubtaskItemSummaryReport(filters, { pageSize: totalItems, sort }).catch(
      (err) => console.error(err),
    );
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            SubtaskItem Summary
          </Typography>
        </Toolbar>
      </AppBar>
      <PageContainer>
        <PageTitle>SubtaskItem Summary</PageTitle>
        <SubtaskItemSummaryReportFilterComponent
          filters={filters}
          onChangeFilter={handleChangeFilter}
          initialFilter={initialSubtaskItemsSummaryReportFilter}
        />
        {!isSmallScreen && (
          <div className={classes.filterContainer}>
            <Button
              variant={'contained'}
              className={classes.button}
              startIcon={<GetAppIcon />}
              onClick={exportExcel}
            >
              Export Excel
            </Button>
          </div>
        )}
        {open && (
          <Grid container spacing={3} className={classes.container}>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmTaskItem.OpportunityItem.Opportunity.Company.Name"
                      >
                        Company
                      </SortLabel>
                    </TableCell>
                    {/* <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmTaskItem.OpportunityItem.Opportunity.SalesTeam.Name"
                      >
                        Sales Team
                      </SortLabel>
                    </TableCell> */}
                    {/* <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmTaskItem.OpportunityItem.Opportunity.AccountOwner.FirstName"
                      >
                        Account Owner
                      </SortLabel>
                    </TableCell> */}
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmSubtask.SMTask.TaskNumber"
                      >
                        Task Number
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmTaskItem.OpportunityItem.Block.Program.Name"
                      >
                        Program
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmTaskItem.OpportunityItem.Block.Brand.Name"
                      >
                        Brand
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmSubtask.Requester.FullNameWithUserName"
                      >
                        Requester
                      </SortLabel>
                    </TableCell>
                    <TableCell>Assignee</TableCell>
                    <TableCell style={{ minWidth: '90px' }}>
                      <SortLabel sort={sort} handleSortChange={setSort} field="CreatedAt">
                        Created Date
                      </SortLabel>
                    </TableCell>
                    <TableCell style={{ minWidth: '90px' }}>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmSubtask.DueDate"
                      >
                        Due Date
                      </SortLabel>
                    </TableCell>
                    <TableCell style={{ minWidth: '90px' }}>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="CompletedDate"
                      >
                        Completed Date
                      </SortLabel>
                    </TableCell>
                    <TableCell>Overdue</TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmSubtask.SmSubTaskType.Code"
                      >
                        Subtask Type
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmTaskItem.OpportunityItem.Block.BlockNo"
                      >
                        Block No
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmTaskItem.OpportunityItem.Block.Item"
                      >
                        Item Name
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmTaskItem.OpportunityItem.Block.ProductType.Name"
                      >
                        Product Type
                      </SortLabel>
                    </TableCell>
                    {/* <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmTaskItem.OpportunityItem.Opportunity.AssignmentNumber"
                      >
                        Assignment Number
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmSubtask.Subject"
                      >
                        Subject
                      </SortLabel>
                    </TableCell> */}
                    <TableCell>
                      <SortLabel sort={sort} handleSortChange={setSort} field="Status">
                        Subtask Item Status
                      </SortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableLoading isLoading={loading} colSpan={20}>
                    {SubtaskItemSummaryReportList.map(
                      (subtaskItemSummaryReport: ISubtaskItemSummaryReport, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {index + paging.pageSize * paging.pageNumber + 1}
                          </TableCell>
                          <TableCell>{subtaskItemSummaryReport.company}</TableCell>
                          {/* <TableCell>
                            {subtaskItemSummaryReport.salesTeam?.join(',')}
                          </TableCell>
                          <TableCell>{subtaskItemSummaryReport.accountOwner}</TableCell> */}
                          <TableCell>{subtaskItemSummaryReport.taskNumber}</TableCell>
                          <TableCell>{subtaskItemSummaryReport.program}</TableCell>
                          <TableCell>{subtaskItemSummaryReport.brand}</TableCell>
                          <TableCell>
                            <Tooltip title={subtaskItemSummaryReport.requester!}>
                              <Avatar>
                                {`${subtaskItemSummaryReport.requester
                                  ?.split(' ')[0]
                                  .charAt(0)
                                  .toUpperCase()}${subtaskItemSummaryReport.requester
                                  ?.split(' ')[1]
                                  .charAt(0)
                                  .toUpperCase()}`}
                              </Avatar>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            {subtaskItemSummaryReport.assignees!.length > 0 ? (
                              <UserLetterAvatar
                                users={subtaskItemSummaryReport.assignees!}
                              />
                            ) : (
                              ''
                            )}
                          </TableCell>
                          <TableCell>
                            <DateDisplay value={subtaskItemSummaryReport.createdDate} />
                          </TableCell>
                          <TableCell>
                            <DateDisplay value={subtaskItemSummaryReport.dueDate} />
                          </TableCell>
                          <TableCell>
                            <DateDisplay value={subtaskItemSummaryReport.completedDate} />
                          </TableCell>
                          <TableCell>
                            {subtaskItemSummaryReport.isOverDue!.toString()}
                          </TableCell>
                          <TableCell>
                            {subtaskItemSummaryReport.smSubtaskTypeName}
                          </TableCell>
                          <TableCell>{subtaskItemSummaryReport.blockNo}</TableCell>
                          <TableCell>{subtaskItemSummaryReport.itemName}</TableCell>
                          <TableCell>{subtaskItemSummaryReport.productType}</TableCell>
                          {/* <TableCell>
                            {subtaskItemSummaryReport.assignmentNumber}
                          </TableCell>
                          <TableCell>{subtaskItemSummaryReport.subject}</TableCell> */}
                          <TableCell>
                            {
                              SubtaskItemStatusLabel[
                                subtaskItemSummaryReport.subtaskItemStatus!
                              ]
                            }
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableLoading>
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[15, 25, 35]}
                component="div"
                count={totalItems}
                rowsPerPage={paging.pageSize}
                page={paging.pageNumber}
                onPageChange={handleChangePageNumber}
                onRowsPerPageChange={handleChangePageSize}
              />
            </Grid>
          </Grid>
        )}
      </PageContainer>
    </Dialog>
  );
};

export default SubtaskItemSummaryReportDialog;
