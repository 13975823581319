import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import history from '../../history';
import { fetchSampleDevelopmentQueueList } from '../../services/sampleDevelopmentQueueService';

import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import { FETCH_SAMPLE_DEVELOPMENT_QUEUE_LIST } from './sampleDevelopmentQueue.actions';

function* fetchSampleDevelopmentQueueListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSampleDevelopmentQueueList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSampleDevelopmentQueue() {
  yield debounce(
    250,
    FETCH_SAMPLE_DEVELOPMENT_QUEUE_LIST,
    fetchSampleDevelopmentQueueListSaga,
  );
}
