import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_OPPORTUNITYAPPROVALREQUEST,
  CREATE_OPPORTUNITYAPPROVALREQUEST,
  FETCH_OPPORTUNITYAPPROVALREQUEST,
  FETCH_OPPORTUNITYAPPROVALREQUEST_LIST,
  UPDATE_OPPORTUNITYAPPROVALREQUEST,
  UPDATE_OPPORTUNITY_APPROVAL_RESPONSE,
  OPEN_OPPORTUNITYAPPROVALREQUEST_DIALOG,
  CLOSE_OPPORTUNITYAPPROVALREQUEST_DIALOG,
} from './opportunityApprovalRequest.actions';
import {
  OpportunityApprovalRequest,
  OpportunityApprovalRequestState,
} from './opportunityApprovalRequest.types';

const getInitialOpportunityApprovalRequestState = (): OpportunityApprovalRequest => ({
  id: 0,
  hasResponded: false,
  opportunity: {
    companyId: 0,
    brandId: 0,
    programId: 0,
    salesTeamId: 0,
  },
  requestedByUser: {
    id: 0,
    firstName: '',
    lastName: '',
    activated: true,
    email: '',
    login: '',
  },
  // accountOwner: {
  //   id: 0,
  //   firstName: '',
  //   lastName: '',
  // },
  comment: '',
});

const initialState: OpportunityApprovalRequestState = {
  errorMessage: '',
  item: getInitialOpportunityApprovalRequestState(),
  items: [],
  loading: false,
  totalItems: 0,
  openDialog: false,
};

export default (
  state: OpportunityApprovalRequestState = initialState,
  action: AppAction,
): OpportunityApprovalRequestState => {
  switch (action.type) {
    case REQUEST(FETCH_OPPORTUNITYAPPROVALREQUEST_LIST):
    case REQUEST(CREATE_OPPORTUNITYAPPROVALREQUEST):
    case REQUEST(FETCH_OPPORTUNITYAPPROVALREQUEST):
    case REQUEST(UPDATE_OPPORTUNITYAPPROVALREQUEST):
    case REQUEST(UPDATE_OPPORTUNITY_APPROVAL_RESPONSE):
      return { ...state, loading: true };
    case SUCCESS(FETCH_OPPORTUNITYAPPROVALREQUEST_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_OPPORTUNITYAPPROVALREQUEST_LIST):
    case FAILURE(CREATE_OPPORTUNITYAPPROVALREQUEST):
    case FAILURE(FETCH_OPPORTUNITYAPPROVALREQUEST):
    case FAILURE(UPDATE_OPPORTUNITYAPPROVALREQUEST):
    case FAILURE(UPDATE_OPPORTUNITY_APPROVAL_RESPONSE):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_OPPORTUNITYAPPROVALREQUEST):
    case SUCCESS(FETCH_OPPORTUNITYAPPROVALREQUEST):
    case SUCCESS(UPDATE_OPPORTUNITYAPPROVALREQUEST):
      return { ...state, loading: false, item: action.payload.data };
    case SUCCESS(UPDATE_OPPORTUNITY_APPROVAL_RESPONSE):
      return { ...state, loading: false };
    case CLEAR_OPPORTUNITYAPPROVALREQUEST:
      return {
        ...state,
        ...initialState,
      };
    case OPEN_OPPORTUNITYAPPROVALREQUEST_DIALOG:
      return {
        ...state,
        openDialog: true,
      };
    case CLOSE_OPPORTUNITYAPPROVALREQUEST_DIALOG:
      return {
        ...state,
        openDialog: false,
      };
    default:
      return state;
  }
};
