import { AppAction, PageFilter } from '../app';
import { SampleDevelopmentQueueListFilter } from './sampleDevelopmentQueue.types';

export const FETCH_SAMPLE_DEVELOPMENT_QUEUE_LIST = 'FETCH_SAMPLE_DEVELOPMENT_QUEUE_LIST';

export const fetchSampleDevelopmentQueueList = (
  filters: SampleDevelopmentQueueListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SAMPLE_DEVELOPMENT_QUEUE_LIST,
    payload: {
      filters,
      paging,
    },
  };
};
