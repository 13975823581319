import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransitionProps } from '@material-ui/core/transitions';
import { SampleDevelopmentStatus } from '../../../constants';
import { ISampleDevelopmentNl } from '../../../models/sampleDevelopmentNl.model';
import { AppState } from '../../../state/configureStore';
import {
  SampleDevelopmentNlApprovalRequest,
  SampleDevelopmentNlApprovalRequestState,
  updateSampleDevelopmentNlApprovalRequestResponse,
} from '../../../state/sampleDevelopmentNlApprovalRequest';
import { SampleDevelopmentNlForm } from '../sampleDevelopmentNl';
import useStyles from './SampleDevelopmentNlApprovalRequestDialog.style';

interface SampleDevNlAppRequestDlgProps {
  handleClose: () => void;
  sampleDevelopmentNl: ISampleDevelopmentNl;
  sampleDevelopmentNlApprovalRequest: SampleDevelopmentNlApprovalRequest;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SampleDevelopmentNlApprovalRequestDialog: React.FC<
  SampleDevNlAppRequestDlgProps
> = props => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const { handleClose, sampleDevelopmentNl, sampleDevelopmentNlApprovalRequest } = props;
  const { openDialog } = useSelector<AppState, SampleDevelopmentNlApprovalRequestState>(
    ({ sampleDevelopmentNlApprovalRequest }) => sampleDevelopmentNlApprovalRequest,
  );

  const sampleDevId = sampleDevelopmentNl.id ? sampleDevelopmentNl.id.toString() : '';
  return (
    <>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Assignment Approval
            </Typography>
            <div className={classes.approveButtonGroup}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const responseRequest = { ...sampleDevelopmentNlApprovalRequest };
                  responseRequest.responseResult = SampleDevelopmentStatus.APPROVED;
                  dispatch(
                    updateSampleDevelopmentNlApprovalRequestResponse(responseRequest),
                  );
                }}
              >
                Approve
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => {
                  const responseRequest = { ...sampleDevelopmentNlApprovalRequest };
                  responseRequest.responseResult = SampleDevelopmentStatus.REJECTED;
                  dispatch(
                    updateSampleDevelopmentNlApprovalRequestResponse(responseRequest),
                  );
                }}
              >
                Reject
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <SampleDevelopmentNlForm id={sampleDevId} />
      </Dialog>
    </>
  );
};

export default SampleDevelopmentNlApprovalRequestDialog;
