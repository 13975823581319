import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ISubtaskItem } from '../../../../models/subtaskItem.model';
import { PageFilter } from '../../../../state/app';
import { Block } from '../../../../state/block';
import { AppState } from '../../../../state/configureStore';
import {
  clearEBlockArtTemplate,
  EBlockArtTemplate,
  EBlockArtTemplateState,
  fetchEBlockArtTemplateList,
} from '../../../../state/eBlockArtTemplate';
import {
  clearCreatedSubtaskItemArtTemplates,
  createSubtaskItemArtTemplates,
  SubtaskItemArtTemplateState,
} from '../../../../state/subtaskItemArtTemplate';
import { SearchField, TableLoading } from '../../../components';

interface SubtaskItemArtTemplateCreateDialogProps {
  open: boolean;
  handleClose: () => void;
  block: Block;
  subtaskItem: ISubtaskItem;
}

const SubtaskItemArtTemplateCreateDialog: React.FC<
  SubtaskItemArtTemplateCreateDialogProps
> = (props) => {
  const { open, handleClose, block, subtaskItem } = props;
  const dispatch = useDispatch();
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const initFilters = {
    'BlockId.EqualsTo': block!.id,
    'ArtTemplateNumber.StartsWith': '',
  };
  const [filters, setFilters] = useState(initFilters);

  const {
    items: eBlockArtTemplates,
    loading: isLoading,
    totalItems,
  } = useSelector<AppState, EBlockArtTemplateState>((state) => state.eBlockArtTemplate);

  const [step, setStep] = useState('selectArtTemplate');

  const { selectedItems } = useSelector<AppState, EBlockArtTemplateState>(
    (state) => state.eBlockArtTemplate,
  );

  const { createSubtaskItemArtTemplates: createdItems } = useSelector<
    AppState,
    SubtaskItemArtTemplateState
  >((state) => state.subtaskItemArtTemplate);

  const initSelectedArtTemplateItems = useMemo(
    () => selectedItems.map((selectedItem) => selectedItem!),
    [selectedItems],
  );
  const [selectedArtTemplateItems, setSelectedArtTemplateItems] = useState<
    EBlockArtTemplate[]
  >(initSelectedArtTemplateItems);

  useEffect(() => {
    dispatch(fetchEBlockArtTemplateList(filters, { ...paging }));
    return function cleanUp() {
      dispatch(clearEBlockArtTemplate());
    };
  }, [dispatch, filters, paging]);

  useEffect(() => {
    setFilters({
      'BlockId.EqualsTo': block!.id,
      'ArtTemplateNumber.StartsWith': '',
    });
  }, [block]);

  useEffect(() => {
    if (!open) {
      setSelectedArtTemplateItems([]);
      dispatch(clearCreatedSubtaskItemArtTemplates());
    }
  }, [open]);

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
      {step === 'selectArtTemplate' && (
        <>
          <DialogTitle>Select Art Template</DialogTitle>
          <DialogContent>
            <SearchField
              filterKey="ArtTemplateNumber.StartsWith"
              filters={filters}
              label="Art Template Number"
              onChange={handleChangeFilter}
              fullWidth={true}
            />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked) => {
                        if (checked) {
                          const items = eBlockArtTemplates.map((i) => i);
                          setSelectedArtTemplateItems(items);
                        } else {
                          setSelectedArtTemplateItems([]);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>No.</TableCell>
                  <TableCell>Art Template Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableLoading isLoading={isLoading} colSpan={3}>
                  {eBlockArtTemplates.map((eBlockArtTemplate, index) => {
                    const { id } = eBlockArtTemplate;
                    const isCheckboxChecked =
                      selectedArtTemplateItems.findIndex((item) => item.id === id) !== -1;
                    return (
                      <TableRow key={eBlockArtTemplate.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                              checked,
                            ) => {
                              if (checked) {
                                setSelectedArtTemplateItems([
                                  ...selectedArtTemplateItems,
                                  eBlockArtTemplate,
                                ]);
                              } else {
                                const newSelectedArtTemplateItems = [
                                  ...selectedArtTemplateItems,
                                ];
                                const selectedIndex = selectedArtTemplateItems.findIndex(
                                  (item) => item.id! === id,
                                );
                                newSelectedArtTemplateItems.splice(selectedIndex, 1);
                                setSelectedArtTemplateItems(newSelectedArtTemplateItems);
                              }
                            }}
                            checked={isCheckboxChecked}
                          />
                        </TableCell>
                        <TableCell>
                          {index + paging.pageSize * paging.pageNumber + 1}
                        </TableCell>
                        <TableCell>{eBlockArtTemplate!.artTemplateNumber}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableLoading>
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[15, 25, 35]}
              component="div"
              count={totalItems}
              rowsPerPage={paging.pageSize}
              page={paging.pageNumber}
              onPageChange={handleChangePageNumber}
              onRowsPerPageChange={handleChangePageSize}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                setStep('confirmArtTemplate');
              }}
              color="primary"
            >
              Next
            </Button>
          </DialogActions>
        </>
      )}
      {step === 'confirmArtTemplate' && (
        <>
          <DialogTitle>Confirm Art Template</DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Art Template Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedArtTemplateItems.map((selectedArtTemplateItem, index) => {
                  return (
                    <TableRow key={selectedArtTemplateItem.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{selectedArtTemplateItem.artTemplateNumber}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setStep('selectArtTemplate');
              }}
              color="primary"
            >
              Back
            </Button>
            <Button
              onClick={() => {
                const subtaskitemArtTemplateItems = selectedArtTemplateItems.map(
                  (item) => ({
                    smSubtaskItemId: subtaskItem.id,
                    blockArtTemplateId: item.id,
                  }),
                );
                dispatch(
                  createSubtaskItemArtTemplates({
                    subtaskItemArtTemplates: subtaskitemArtTemplateItems,
                    smSubtaskItemId: subtaskItem.id!,
                  }),
                );
                setStep('createdArtTemplate');
              }}
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </>
      )}
      {step === 'createdArtTemplate' && (
        <>
          <DialogTitle>Created Assignment Item</DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Art Template Number</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {createdItems.map((createdItem, index) => {
                  return (
                    <TableRow key={createdItem.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {createdItem.blockArtTemplate!.artTemplateNumber}
                      </TableCell>
                      <TableCell>{createdItem.status}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
                setStep('selectArtTemplate');
              }}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default SubtaskItemArtTemplateCreateDialog;
