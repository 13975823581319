import {
  Button,
  Chip,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Tooltip,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ITask } from '../../../models/task.model';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import { clearTask, fetchTaskList, TaskListFilter, TaskState } from '../../../state/task';
import {
  PageButtonGroup,
  PageContainer,
  PageTitle,
  SortLabel,
  TableLoading,
} from '../../components';
import { DateDisplay } from '../../components/DateTimeDisplay';
import { useFilter, useTableSorting } from '../../hooks';
import TaskFilter from './TaskFilter';
import { TaskCreateDialog } from './taskCreateDialog';
import { TablePageSize } from '../../../constants';

const TaskList: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const initTaskListFilterState: TaskListFilter = {
    'TaskNumber.startsWith': '',
    'Requester.FullName.StartsWith': '',
  };
  const [openTaskCreateDialog, setOpenTaskCreateDialog] = useState(false);
  const [filters, setFilters] = useFilter<TaskListFilter>(initTaskListFilterState);
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 10,
  });
  const [sort, setSort] = useTableSorting({ orderBy: 'taskNumber', direction: 'desc' });
  const {
    items: tasks,
    totalItems,
    loading,
  } = useSelector<AppState, TaskState>((state) => state.task);

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchTaskList(filters, { ...paging, sort }));
    return function cleanUp() {
      dispatch(clearTask());
    };
  }, [dispatch, paging, filters, sort]);

  const addNewTask = (): void => {
    setOpenTaskCreateDialog(true);
  };

  const editTask = (id: ITask['id']): void => {
    history.push(`/tasks/form/${id}`);
  };

  return (
    <PageContainer>
      <TaskCreateDialog
        open={openTaskCreateDialog}
        onClose={() => {
          setOpenTaskCreateDialog(false);
        }}
      />
      <PageTitle>Task</PageTitle>
      <TaskFilter
        initialFilter={initTaskListFilterState}
        filters={filters}
        onChangeFilter={(newFilter) => setFilters(newFilter)}
      />
      <PageButtonGroup>
        <Button
          variant="contained"
          color="primary"
          onClick={addNewTask}
          startIcon={<AddIcon />}
        >
          New Task
        </Button>
      </PageButtonGroup>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="taskNumber">
                Task Number
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="createdAt">
                Created Date
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="dueDate">
                Due Date
              </SortLabel>
            </TableCell>
            <TableCell>Requester</TableCell>
            <TableCell>Subject</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={7}>
            {tasks.map((task, index) => {
              return (
                <TableRow key={task.id}>
                  <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                  <TableCell>
                    <Link
                      variant="h6"
                      href="#"
                      style={{ fontWeight: 'bold' }}
                      color="primary"
                      onClick={() => editTask(task.id)}
                    >
                      {task.taskNumber}
                    </Link>
                  </TableCell>

                  <TableCell>
                    <DateDisplay value={task.createdAt} />
                  </TableCell>
                  <TableCell>
                    <DateDisplay value={task.dueDate} />
                  </TableCell>
                  <TableCell>
                    <Chip
                      variant="outlined"
                      color={task.requester?.activated ? 'default' : 'secondary'}
                      label={
                        task.requester?.activated
                          ? task.requester?.fullName
                          : `${task.requester?.fullName} (Inactive)`
                      }
                    />
                    {/* {task.requester?.fullNameWithUserName} */}
                  </TableCell>
                  <TableCell>{task.subject}</TableCell>
                  <TableCell>
                    <Tooltip title="Edit">
                      <span>
                        <IconButton
                          color="default"
                          size="small"
                          onClick={() => editTask(task.id)}
                        >
                          <EditIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[...TablePageSize]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default TaskList;
