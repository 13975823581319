import { ITask } from '../models/task.model';
import { PageFilter } from '../state/app';
import { TaskListFilter } from '../state/task';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios, { getResponseFileName } from '../utils/axios';
import { saveAs } from 'file-saver';

const baseUrl = `/api/tasks`;

export const fetchTaskList = async ({
  filters,
  paging,
}: {
  filters: TaskListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchTask = async (id: ITask['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createTask = async (task: ITask) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, task);
};

export const updateTask = async (task: ITask) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, task);
};

export const exportTaskAsExcel = async (taskId: number) => {
  const requestUrl = `${baseUrl}`;
  const response = await axios.get(`${requestUrl}/${taskId}/export`, {
    responseType: 'blob',
  });
  const fileName = getResponseFileName(response);
  saveAs(response.data, fileName);
};
