import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './sampleDevelopmentNl.style';
import rollWindChartImg from './images/rollWindChart.png';
import { Image, TextFieldFastField, CheckBoxFastField } from '../../../components';

interface SampleDevelopmentNlPackingCartonProps {}

const SampleDevelopmentNlPackingCarton: React.FC<
  SampleDevelopmentNlPackingCartonProps
> = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} direction="row" justifyContent="flex-start">
      <Typography className={classes.title}>Carton Packing</Typography>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} sm={3}>
          <CheckBoxFastField name="cartonPackStandard" label="Standard" />
        </Grid>

        <Grid item xs={12} sm={4}>
          <CheckBoxFastField name="cartonPackSpecial" label="Special Requirement" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextFieldFastField
            name="cartonPackSpecialData"
            label="Special Requirement"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Image width="100%" src={rollWindChartImg} alt="Single" />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextFieldFastField
            type="number"
            step="any"
            name="deliverPosition"
            label="Delivered Unwind Position"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SampleDevelopmentNlPackingCarton;
