import { ColumnApi } from 'ag-grid-community';
import React from 'react';
import {
  IResponsibilityImportExcelColumnConfig,
  IResponsibilityImportData,
  IExcelRow,
} from './responsibilityImportColumnDefinition';

export interface AgGridColumnDef {
  headerName: string;
  field: string;
  suppressMovable: boolean;
  cellRenderer?: (value: any) => React.ReactNode;
}

const defaultColDef = {
  resizable: true,
  suppressMovable: true,
};

type HiddenCallback = (index: number | null) => boolean;

export const columnsBuilder = (
  columns: IResponsibilityImportExcelColumnConfig[],
  hiddenCallback: HiddenCallback,
) => {
  const cloneColumns = [...columns];
  cloneColumns.unshift({
    propertyName: 'no',
    caption: 'No',
    excelIndex: null,
  });

  cloneColumns.unshift({
    propertyName: 'status',
    caption: 'Status',
    excelIndex: null,
  });

  return cloneColumns.map((c) => {
    return {
      headerName: c.caption,
      field: c.propertyName,
      hide: hiddenCallback(c.excelIndex),
      ...defaultColDef,
    };
  });
};

export const rowsBuilder = (
  columns: IResponsibilityImportExcelColumnConfig[],
  excelRows: IExcelRow[],
  startRow: number,
) => {
  const excelAtStartRows = [...excelRows].splice(startRow - 1, excelRows.length - 1);

  return excelAtStartRows.map((er, index) => {
    const rowItem: any = {};

    columns.forEach((c) => {
      rowItem[c.propertyName] = (c.excelIndex && er[c.excelIndex - 1]) || '';
    });

    rowItem.no = index + 1;
    rowItem.status = 'READY';
    return rowItem;
  });
};

export const dataBuilder = (
  columns: IResponsibilityImportExcelColumnConfig[],
  data: any,
): IResponsibilityImportData => {
  const result: any = {};

  columns.forEach((c) => {
    if (data.hasOwnProperty(c.propertyName)) {
      result[c.propertyName] = data[c.propertyName];
    }
  });

  return result;
};
