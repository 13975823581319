import { CardContent, TextField } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { IEBlockGroupProduct } from '../../../models/eBlockGroupProduct';
import { AuthState } from '../../../state/auth';
import { AppState } from '../../../state/configureStore';
import {
  clearEBlockGroupProduct,
  createEBlockGroupProduct,
  EBlockGroupProductState,
  fetchEBlockGroupProduct,
  updateEBlockGroupProduct,
} from '../../../state/eBlockGroupProduct';
import {
  FixedPositionButtons,
  Loading,
  PageContainer,
  PageTitle,
} from '../../components';

const EBlockGroupProductSchema = Yup.object().shape({
  code: Yup.string()
    .strict(true)
    .trim()
    .max(2, 'Code is exceeded character limit(2)')
    .required('Code is required'),
  description: Yup.string().nullable(true),
});

interface GroupProductFormRouteParamsProps {
  id: string;
}

interface GroupProductFormProps
  extends RouteComponentProps<GroupProductFormRouteParamsProps> {}

const EBlockGroupProductForm: React.FC<GroupProductFormProps> = props => {
  const dispatch = useDispatch();
  const { loading: authIsLoading } = useSelector<AppState, AuthState>(
    state => state.auth,
  );
  const { match, history } = props;
  const {
    params: { id: paramsId },
  } = match;

  const isUpdateMode = paramsId !== undefined;

  const { item: groupProduct, loading: groupProductIsLoading } = useSelector<
    AppState,
    EBlockGroupProductState
  >(state => state.eBlockGroupProduct);

  const isLoading = authIsLoading || groupProductIsLoading;

  const defaultValues: IEBlockGroupProduct = {};

  const handleSubmit = (
    values: IEBlockGroupProduct,
    actions: FormikHelpers<IEBlockGroupProduct>,
  ) => {
    if (isUpdateMode) {
      dispatch(updateEBlockGroupProduct({ values, history }));
    } else {
      dispatch(createEBlockGroupProduct({ values, history }));
    }
    actions.setSubmitting(false);
  };

  const redirectToSalesGroupList = () => {
    history.goBack();
  };

  useEffect(() => {
    if (isUpdateMode) {
      dispatch(fetchEBlockGroupProduct(+paramsId));
    } else {
      dispatch(clearEBlockGroupProduct());
    }
    return function cleanUp() {
      dispatch(clearEBlockGroupProduct());
    };
  }, [isUpdateMode, paramsId, dispatch]);

  return (
    <PageContainer>
      <Loading isLoading={isLoading}>
        <Formik
          enableReinitialize={true}
          initialValues={{ ...defaultValues, ...groupProduct }}
          validationSchema={EBlockGroupProductSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, errors, touched, submitForm }) => {
            return (
              <Form>
                <PageTitle>
                  {!isUpdateMode
                    ? `New EBlock Group Product`
                    : `Update EBlock Group Product : ${groupProduct.code}`}
                </PageTitle>
                <CardContent>
                  <TextField
                    name="code"
                    type="input"
                    label="Code"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={values.code || ''}
                    onChange={handleChange}
                    error={!!errors.code && !!touched.code}
                    helperText={errors.code}
                    InputProps={{
                      readOnly: isUpdateMode,
                    }}
                  />
                  <TextField
                    name="description"
                    type="input"
                    label="Description"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={values.description || ''}
                    onChange={handleChange}
                    error={!!errors.description && !!touched.description}
                    helperText={errors.description}
                  />
                </CardContent>
                <FixedPositionButtons
                  actions={[
                    {
                      name: !isUpdateMode ? `Create` : `Update`,
                      onClick: () => {
                        submitForm();
                      },
                      color: 'primary' as 'primary',
                      disabled: false,
                      icon: 'save',
                    },
                    {
                      name: 'CANCEL',
                      onClick: redirectToSalesGroupList,
                      color: 'default' as 'default',
                      disabled: false,
                    },
                  ]}
                  open
                />
              </Form>
            );
          }}
        </Formik>
      </Loading>
    </PageContainer>
  );
};

export default EBlockGroupProductForm;
