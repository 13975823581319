import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { Program } from '../../../../state/program';
import { Brand } from '../../../../state/brand';
import { useDispatch, useSelector } from 'react-redux';
import { User } from '../../../../state/user';
import UserComboBox from '../../../components/UserComboBox';
import * as Yup from 'yup';
import {
  clearDialog,
  confirmAll,
  searchAll,
  setSearchBlockForm,
} from './addMultipleBlockDialog.actions';
import { AppState } from '../../../../state/configureStore';
import { Show } from '../../../components';
import { AddMultipleBlockDialogState } from './addMultipleBlockDialog.types';

interface AddMultipleBlockDialogProps {
  handleClose: () => void;
  open: boolean;
  program: Program;
  brand: Brand;
}

interface IAddMultipleBlockForm {
  userId?: number;
  user?: User;
  blockNoText?: string;
}

const AddMultipleBlockSchema = Yup.object().shape({
  // userId: Yup.number().nullable(true).required('User is required'),
  blockNoText: Yup.string().nullable(true).required('Please provide Block Numbers'),
});

const initialActiveStep = 0;

const AddMultipleBlockDialog: React.FC<AddMultipleBlockDialogProps> = (props) => {
  const dispatch = useDispatch();
  const { open, handleClose, program, brand } = props;
  const [activeStep, setActiveStep] = React.useState(initialActiveStep);
  const { form, isLoading: isSearching, hasSearch, hasConfirm } = useSelector<
    AppState,
    AddMultipleBlockDialogState
  >((state) => state.addMultipleBlockDialog);

  const { searchBlocks } = form;

  const handleSubmit = (
    values: IAddMultipleBlockForm,
    actions: FormikHelpers<IAddMultipleBlockForm>,
  ) => {
    const blockNoList = values
      .blockNoText!.split(/\r?\n/)
      .filter((t) => t.trim().length > 0);
    dispatch(
      setSearchBlockForm({
        ...values,
        searchBlocks: blockNoList.map((blockNo) => ({
          blockNo,
          status: '',
        })),
        program,
        brand,
      }),
    );
    actions.setSubmitting(false);
    setActiveStep(1);
  };

  const back = () => {
    setActiveStep(activeStep - 1);
  };

  const verify = () => {
    dispatch(searchAll());
  };

  const confirm = () => {
    dispatch(confirmAll());
  };

  const cleanUp = () => {
    dispatch(clearDialog());
    setActiveStep(0);
  };

  const close = () => {
    handleClose();
  };

  return (
    <Dialog open={open} onClose={close} maxWidth={'md'} onExiting={cleanUp} fullWidth>
      <DialogTitle>Add Multiple Blocks</DialogTitle>
      <Stepper activeStep={activeStep}>
        <Step>
          <StepLabel>Input Blocks</StepLabel>
        </Step>
        <Step>
          <StepLabel>Verify & Confirm</StepLabel>
        </Step>
      </Stepper>
      {activeStep === 0 && (
        <Formik
          enableReinitialize={true}
          initialValues={{ ...form } as IAddMultipleBlockForm}
          validationSchema={AddMultipleBlockSchema}
          onSubmit={handleSubmit}
          validateOnChange={false}
          isInitialValid={true}
        >
          {({ values, handleChange, errors, submitForm, setFieldValue }) => {
            return (
              <Form>
                <DialogContent>
                  {/* <UserComboBox
                    handleChange={(result: User) => {
                      setFieldValue('user', result ? result : undefined);
                      setFieldValue('userId', result ? result.id : undefined);
                    }}
                    selectedValue={values.user as User}
                    helperText={errors.userId}
                    error={!!errors.userId}
                    label={'Sample Dev/NPD'}
                  /> */}
                  <TextField
                    name="blockNoText"
                    type="input"
                    label="Block No"
                    fullWidth
                    value={values.blockNoText}
                    onChange={handleChange}
                    error={!!errors.blockNoText}
                    helperText={errors.blockNoText}
                    multiline={true}
                    rows={10}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={close}>Close</Button>
                  <Button variant={'contained'} onClick={submitForm} color="primary">
                    Next
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      )}
      {activeStep === 1 && (
        <>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Block No</TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Result</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchBlocks.map((searchBlock) => (
                  <TableRow key={searchBlock.blockNo}>
                    <TableCell>{searchBlock.blockNo}</TableCell>
                    <TableCell>
                      {searchBlock.block ? searchBlock.block.item : ''}
                    </TableCell>
                    <TableCell>{searchBlock.status}</TableCell>
                    <TableCell>{searchBlock.message}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary" disabled={isSearching}>
              Close
            </Button>
            <Show show={!hasConfirm}>
              <Button onClick={back} disabled={isSearching}>
                Back
              </Button>
            </Show>
            <Show show={!hasConfirm}>
              <Button onClick={verify} color="primary" disabled={isSearching}>
                Verify
              </Button>
            </Show>
            <Show show={hasSearch && !hasConfirm}>
              <Button
                variant={'contained'}
                onClick={confirm}
                color="primary"
                disabled={isSearching}
              >
                Confirm
              </Button>
            </Show>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default AddMultipleBlockDialog;
