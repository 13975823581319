import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import history from '../../history';
import {
  createSampleDevelopmentHl,
  fetchSampleDevelopmentHl,
  fetchSampleDevelopmentHlList,
  submitSampleDevelopmentHlForApproval,
  updateSampleDevelopmentHl,
  downloadSampleDevelopmentHl,
} from '../../services/sampleDevelopmentHlService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_SAMPLE_DEVELOPMENT_HL,
  DOWNLOAD_SAMPLE_DEVELOPMENT_HL,
  FETCH_SAMPLE_DEVELOPMENT_HL,
  FETCH_SAMPLE_DEVELOPMENT_HL_LIST,
  SUBMIT_SAMPLE_DEVELOPMENT_HL_FOR_APPROVAL,
  UPDATE_SAMPLE_DEVELOPMENT_HL,
} from './sampleDevelopmentHl.actions';

const formPageUrl = `/sampleDevelopmentHl/form`;

function* fetchSampleDevelopmentHlSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchSampleDevelopmentHl, action.payload);
    if (data.salesTeamMember) {
      data.salesTeam = data.salesTeamMember.salesTeam;
    }
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchSampleDevelopmentHlListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSampleDevelopmentHlList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createSampleDevelopmentHlSaga(action: AppAction) {
  try {
    const { values } = action.payload;
    const successMessage = `Created SampleDevelopmentHl successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createSampleDevelopmentHl, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(`${formPageUrl}/${data.id}`);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateSampleDevelopmentHlSaga(action: AppAction) {
  try {
    const { values } = action.payload;
    const successMessage = `Updated SampleDevelopmentHl successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateSampleDevelopmentHl, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* submitSampleDevelopmentHlForApprovalSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const successMessage = `Submit SampleDevelopmentHl for Approval successfully.`;
    const { data } = yield call(submitSampleDevelopmentHlForApproval, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* downloadSampleDevelopmentHlSaga(action: AppAction) {
  try {
    const response = yield call(downloadSampleDevelopmentHl, action.payload);
    saveAs(response.data, 'SampleDevelopment.pdf');
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSalesTeam() {
  yield takeLatest(FETCH_SAMPLE_DEVELOPMENT_HL, fetchSampleDevelopmentHlSaga);
  yield debounce(250, FETCH_SAMPLE_DEVELOPMENT_HL_LIST, fetchSampleDevelopmentHlListSaga);
  yield takeLatest(CREATE_SAMPLE_DEVELOPMENT_HL, createSampleDevelopmentHlSaga);
  yield takeLatest(UPDATE_SAMPLE_DEVELOPMENT_HL, updateSampleDevelopmentHlSaga);
  yield takeLatest(
    SUBMIT_SAMPLE_DEVELOPMENT_HL_FOR_APPROVAL,
    submitSampleDevelopmentHlForApprovalSaga,
  );
  yield takeLatest(DOWNLOAD_SAMPLE_DEVELOPMENT_HL, downloadSampleDevelopmentHlSaga);
}
