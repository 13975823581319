import ProjectProgramExpandable from '../../project/ProjectProgramExpandable';
import React, { useEffect, useState } from 'react';
import { PendingApprovalResponsibilityListState } from './pendingApprovalResponsibilityList.reducer';
import { AppState } from '../../../../state/configureStore';
import { ChipList, TableLoading } from '../../../components';
import { DateTimeDisplay } from '../../../components/DateTimeDisplay';
import { IResponsibility } from '../../../../models/responsibility.model';
import { IResponsibilityApprovalRequest } from '../../../../models/responsibilityApprovalRequest.model';
import { PageFilter } from '../../../../state/app';
import { ProjectApprovalRequestDialog } from '../../projectApprovalRequest/ProjectApprovalRequestDialog';
import { ResponsibilityApprovalRequestDialog } from '../../responsibilityApprovalRequest/ResponsibilityApprovalRequestDialog';
import { ResponsibilityStatusLabel } from '../../../../constants/responsibilityStatus';
import { Edit as EditIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  closeResponsibilityApprovalRequestDialog,
  openResponsibilityApprovalRequestDialog,
  ResponsibilityApprovalRequestState,
} from '../../../../state/responsibilityApprovalRequest';
import {
  clearPendingApprovalResponsibilityList,
  fetchPendingApprovalResponsibilityList,
} from './pendingApprovalResponsibilityList.actions';
import { TablePageSize } from '../../../../constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
}));

const PendingApprovalResponsibilityList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [
    responsibilityApprovalRequestDialogValue,
    setResponsibilityApprovalRequestDialogValue,
  ] = useState({
    responsibility: {} as IResponsibility,
    responsibilityApprovalRequest: {} as IResponsibilityApprovalRequest,
  });
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 10,
  });

  const {
    loading,
    totalItems,
    items: pendingApprovalResponsibilityList,
  } = useSelector<AppState, PendingApprovalResponsibilityListState>(
    (state) => state.pendingApprovalResponsibilityList,
  );

  const { openDialog } = useSelector<AppState, ResponsibilityApprovalRequestState>(
    (state) => state.responsibilityApprovalRequest,
  );

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const editResponsibility = (
    responsibilityApprovalRequest: IResponsibilityApprovalRequest,
  ): void => {
    setResponsibilityApprovalRequestDialogValue({
      responsibility: responsibilityApprovalRequest.responsibility!,
      responsibilityApprovalRequest,
    });
    dispatch(openResponsibilityApprovalRequestDialog());
  };

  const handleCloseResponsibilityApprovalRequestDialog = () => {
    dispatch(closeResponsibilityApprovalRequestDialog());
  };

  useEffect(() => {
    if (!openDialog) {
      dispatch(fetchPendingApprovalResponsibilityList(paging));
    }
    return function cleanup() {
      dispatch(clearPendingApprovalResponsibilityList());
    };
  }, [dispatch, paging, openDialog]);

  return (
    <>
      <Typography variant="h6">Pending Approval Responsibility</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Program</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>SSO</TableCell>
            <TableCell>CS</TableCell>
            <TableCell>NPD</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Requester</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={12}>
            {pendingApprovalResponsibilityList.map(
              (pendingApprovalResponsibility, index) => {
                const customerNames =
                  pendingApprovalResponsibility?.responsibility?.responsibilityCustomers
                    ?.filter((rc) => rc.active === true)
                    ?.map((rc) => {
                      return rc.customer?.displayName || '';
                    }) || [];
                const brandNames =
                  pendingApprovalResponsibility?.responsibility?.responsibilityBrands
                    ?.filter((rb) => rb.active === true)
                    ?.map((rb) => {
                      return rb.brand?.name || '';
                    }) || [];
                const salesNames =
                  pendingApprovalResponsibility?.responsibility?.responsibilitySalesTeamMembers
                    ?.filter((rs) => rs.active === true)
                    ?.map((rs) => {
                      return rs.salesTeamMember?.user?.fullNameWithUserName || '';
                    }) || [];
                const csNames =
                  pendingApprovalResponsibility?.responsibility?.responsibilityWorkingTeamMembers
                    ?.filter(
                      (rc) =>
                        rc.active === true &&
                        rc.smWorkingTeamMember?.smWorkingTeam?.smWorkingGroup?.name ===
                          'CS',
                    )
                    ?.map((rs) => {
                      return rs.smWorkingTeamMember?.user?.fullNameWithUserName || '';
                    }) || [];
                const npdNames =
                  pendingApprovalResponsibility?.responsibility?.responsibilityWorkingTeamMembers
                    ?.filter(
                      (rc) =>
                        rc.active === true &&
                        rc.smWorkingTeamMember?.smWorkingTeam?.smWorkingGroup?.name ===
                          'NPD',
                    )
                    ?.map((rs) => {
                      return (
                        `${rs.smWorkingTeamMember?.smWorkingTeam?.name}: ${rs.smWorkingTeamMember?.user?.fullNameWithUserName}` ||
                        ''
                      );
                    }) || [];
                return (
                  <TableRow key={pendingApprovalResponsibility.id}>
                    <TableCell>
                      {index + paging.pageSize * paging.pageNumber + 1}
                    </TableCell>
                    <TableCell>
                      {pendingApprovalResponsibility?.responsibility?.id}
                    </TableCell>

                    <TableCell>
                      {pendingApprovalResponsibility?.responsibility?.company?.name}
                    </TableCell>
                    <TableCell>
                      {pendingApprovalResponsibility?.responsibility?.program?.name}
                    </TableCell>
                    <TableCell>
                      <ChipList list={brandNames} />
                    </TableCell>
                    <TableCell>
                      <ChipList list={customerNames} />
                    </TableCell>
                    <TableCell>
                      <ChipList list={salesNames} noAllLabel />
                    </TableCell>
                    <TableCell>
                      <ChipList list={csNames} noAllLabel />
                    </TableCell>
                    <TableCell>
                      <ChipList list={npdNames} noAllLabel />
                    </TableCell>
                    <TableCell>
                      {
                        ResponsibilityStatusLabel[
                          pendingApprovalResponsibility?.responsibility?.status!
                        ]
                      }
                    </TableCell>
                    <TableCell>
                      {
                        pendingApprovalResponsibility?.requestedByUser
                          ?.fullNameWithUserName
                      }
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={() => editResponsibility(pendingApprovalResponsibility)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              },
            )}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[...TablePageSize]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />

      <ResponsibilityApprovalRequestDialog
        handleClose={handleCloseResponsibilityApprovalRequestDialog}
        responsibility={responsibilityApprovalRequestDialogValue.responsibility}
        responsibilityApprovalRequest={
          responsibilityApprovalRequestDialogValue.responsibilityApprovalRequest
        }
      />
    </>
  );
};

export default PendingApprovalResponsibilityList;
