import {
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ITask } from '../../../../models/task.model';
import { AppState } from '../../../../state/configureStore';
import {
  clearTaskItem,
  deleteTaskItem,
  fetchTaskItemListByTaskId,
  TaskItemState,
} from '../../../../state/taskItem';
import TaskItemCreateDialog from './TaskItemCreateDialog';
import useStyles from './taskItemList.style';
import { useTableSorting } from '../../../hooks';
import { ChipList, OpportunityLinkButton, SortLabel } from '../../../components';
import SalesTeamMemberDialog from '../../../components/SalesTeamComboBox/SalesTeamMemberDialog';
import ChipListUser from '../../../components/ChipListUser';

interface TaskItemListProps {
  smTaskId: ITask['id'];
}

const TaskItemList: React.FC<TaskItemListProps> = (props) => {
  const { smTaskId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openTaskItemCreateDialog, setOpenTaskItemCreateDialog] = useState(false);
  const [openSalesTeamMemberDialog, setOpenSalesTeamMemberDialog] = useState(false);
  const [selectedSalesTeamId, setSelectedSalesTeamId] = useState(0);
  const [sort, setSort] = useTableSorting({ orderBy: 'id', direction: 'asc' }, true);
  const { items: itemList, createdItems } = useSelector<AppState, TaskItemState>(
    (state) => state.taskItem,
  );

  useEffect(() => {
    dispatch(fetchTaskItemListByTaskId(smTaskId!, sort));
  }, [dispatch, smTaskId, createdItems, sort]);

  useEffect(() => {
    return function cleanUp() {
      dispatch(clearTaskItem());
    };
  }, [dispatch]);

  const deleteSMTaskItem = (id: number, smTaskId: ITask['id']) => {
    if (id) {
      dispatch(deleteTaskItem({ id, smTaskId }));
    }
  };
  return (
    <>
      <Paper variant="outlined">
        <CardContent>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenTaskItemCreateDialog(true);
            }}
            className={classes.button}
          >
            <AddIcon className={classes.leftIcon} />
            New Task Item
          </Button>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>
                  <SortLabel
                    sort={sort}
                    handleSortChange={setSort}
                    field="OpportunityItem.Opportunity.AssignmentNumber"
                  >
                    Assignment Number
                  </SortLabel>
                </TableCell>
                <TableCell>Program</TableCell>
                <TableCell>Brand</TableCell>
                <TableCell>
                  <SortLabel
                    sort={sort}
                    handleSortChange={setSort}
                    field="OpportunityItem.Block.BlockNo"
                  >
                    Block
                  </SortLabel>
                </TableCell>
                <TableCell>Item ++</TableCell>
                <TableCell>Item Description</TableCell>
                <TableCell>Sales</TableCell>
                <TableCell>SSO</TableCell>
                <TableCell>CS</TableCell>
                <TableCell>NPD/Sample Dev.</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {itemList.map((item, index) => {
                const block = item.opportunityItem!.block!;
                const opportunity = item.opportunityItem!.opportunity!;
                const ssoNames =
                  opportunity.responsibility?.responsibilityWorkingTeamMembers
                    ?.filter(
                      (f) =>
                        f.active === true &&
                        f.smWorkingTeamMember?.smWorkingTeam?.smWorkingGroup?.name ===
                          'SSO',
                    )
                    ?.map((rs) => {
                      const status =
                        rs.status === 'P' ? 'SSO' : `SSO${rs.status?.replace('S', '')}`;
                      return {
                        name: rs.smWorkingTeamMember?.user?.activated
                          ? `${rs.smWorkingTeamMember?.user?.firstName}`
                          : `${rs.smWorkingTeamMember?.user?.firstName} (Inactive)`,
                        activated: rs.smWorkingTeamMember?.user?.activated,
                        userLeaveTotalDay:
                          rs.smWorkingTeamMember?.user?.userLeaveTotalDay,
                      };
                    }) || [];

                const csNames =
                  opportunity.responsibility?.responsibilityWorkingTeamMembers
                    ?.filter(
                      (f) =>
                        f.active === true &&
                        f.smWorkingTeamMember?.smWorkingTeam?.smWorkingGroup?.name ===
                          'CS',
                    )
                    ?.map((rs) => {
                      const status = rs.status === 'P' ? 'CS' : `C${rs.status}`;
                      return {
                        name: rs.smWorkingTeamMember?.user?.activated
                          ? `${rs.smWorkingTeamMember?.user?.firstName}`
                          : `${rs.smWorkingTeamMember?.user?.firstName} (Inactive)`,
                        activated: rs.smWorkingTeamMember?.user?.activated,
                        userLeaveTotalDay:
                          rs.smWorkingTeamMember?.user?.userLeaveTotalDay,
                      };
                    }) || [];

                // const salesName =
                //   opportunity.responsibility?.responsibilitySalesTeamMembers
                //     ?.filter((f) => f.active === true)
                //     .map((s) => s.salesTeamMember?.salesTeam?.owner?.fullName || '') || [];
                const salesNames =
                  opportunity.responsibility?.responsibilitySalesTeamMembers
                    ?.filter((rs) => rs.active === true)
                    ?.map((rs) => {
                      const status =
                        rs.status === 'P'
                          ? 'Sales'
                          : `Sales${rs.status?.replace('S', '')}`;
                      return {
                        name: rs.salesTeamMember?.user?.activated
                          ? `${rs.salesTeamMember?.user?.firstName}`
                          : `${rs.salesTeamMember?.user?.firstName} (Inactive)`,
                        activated: rs.salesTeamMember?.user?.activated,
                        userLeaveTotalDay: rs.salesTeamMember?.user?.userLeaveTotalDay,
                      };
                    }) || [];

                let npdFullName = '';
                if (item.opportunityItem?.npdUser) {
                  const userActive = item.opportunityItem.npdUser.activated;
                  npdFullName = `${item.opportunityItem?.npdUser?.firstName} ${
                    userActive ? '' : '(Inactive)'
                  }`;
                } else if (item.opportunityItem?.sampleDevUser) {
                  const userActive = item.opportunityItem.sampleDevUser.activated;
                  npdFullName = `${item.opportunityItem.sampleDevUser.firstName} ${
                    userActive ? '' : '(Inactive)'
                  }`;
                }
                // const npdFullName = item.opportunityItem?.npdUser
                //   ? item.opportunityItem?.npdUser.activated
                //     ? item.opportunityItem?.npdUser?.firstName
                //     : `${item.opportunityItem?.npdUser?.firstName} (Inactive)`
                //   : item.opportunityItem?.sampleDevUser?.activated
                //   ? item.opportunityItem?.sampleDevUser?.firstName
                //   : `${item.opportunityItem?.sampleDevUser?.firstName} (Inactive)`;

                // const npdActivated = item.opportunityItem?.npdUser
                //   ? item.opportunityItem?.npdUser.activated
                //   : item.opportunityItem?.sampleDevUser?.activated;
                let npdActivated = true;
                if (item.opportunityItem?.npdUser) {
                  npdActivated = item.opportunityItem.npdUser.activated ?? true;
                } else if (item.opportunityItem?.sampleDevUser) {
                  npdActivated = item.opportunityItem.sampleDevUser.activated ?? true;
                }
                const npdUser = {
                  name: npdFullName,
                  activated: npdActivated,
                };

                return (
                  <TableRow key={item.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <OpportunityLinkButton opportunity={opportunity} />
                    </TableCell>
                    <TableCell>{opportunity.program?.name}</TableCell>
                    <TableCell>{opportunity.brand?.name}</TableCell>
                    <TableCell>{block.blockNo}</TableCell>
                    <TableCell>{block.item}</TableCell>
                    <TableCell>{block.itemDescription}</TableCell>
                    <TableCell>
                      <ChipListUser list={salesNames} noAllLabel />
                    </TableCell>
                    <TableCell>
                      <ChipListUser list={ssoNames} noAllLabel />
                    </TableCell>
                    <TableCell>
                      <ChipListUser list={csNames} noAllLabel />
                    </TableCell>
                    <TableCell>
                      {npdUser.name !== '' && (
                        <Chip
                          label={npdUser.name}
                          variant="outlined"
                          color={npdUser.activated ? 'default' : 'secondary'}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {/* <Button
                      variant="contained"
                      color="default"
                      onClick={() => {
                        setSelectedSalesTeamId(opportunity.salesTeamId!);
                        setOpenSalesTeamMemberDialog(true);
                      }}
                    >
                      See Team Member
                    </Button> */}
                      <Button
                        variant="contained"
                        color="default"
                        size="small"
                        startIcon={<DeleteIcon />}
                        onClick={() => deleteSMTaskItem(item.id!, smTaskId)}
                      >
                        Delete
                      </Button>
                      {/* <Tooltip title="Delete">
                        <span>
                          <IconButton
                            color="secondary"
                            size="small"
                            onClick={() => {
                              deleteSMTaskItem(item.id!, smTaskId);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </Tooltip> */}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </CardContent>
      </Paper>
      {openTaskItemCreateDialog && (
        <TaskItemCreateDialog
          open={openTaskItemCreateDialog}
          handleClose={() => {
            setOpenTaskItemCreateDialog(false);
          }}
        />
      )}
      <SalesTeamMemberDialog
        open={openSalesTeamMemberDialog}
        handleClose={() => setOpenSalesTeamMemberDialog(false)}
        salesTeamId={selectedSalesTeamId}
      />
    </>
  );
};

export default TaskItemList;
