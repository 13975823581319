import React from 'react';
import { Grid } from '@material-ui/core';
import { FilterContainer, SearchField } from '../../components';
import { OpportunityItemListFilter } from '../../../state/opportunityItem';
import { IFilterContainerConfigProps } from '../../components/FilterContainer';

const OpportunityItemFilter: React.FC<
  IFilterContainerConfigProps<OpportunityItemListFilter>
> = props => {
  return (
    <FilterContainer
      {...props}
      render={filterProps => {
        const { localFilter, handleChangeFilter } = filterProps;
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Assignment Number"
                filterKey="Opportunity.AssignmentNumber.startsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Our Company"
                filterKey="Opportunity.Company.Name.startsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Program"
                filterKey="Opportunity.Program.Name.startsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Brand"
                filterKey="Opportunity.Brand.Name.startsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Sales"
                filterKey="Opportunity.Responsibility.ResponsibilitySalesTeamMember.SalesTeamMember.User.FullNameWithUserName.Contains"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="CS"
                filterKey="Opportunity.Responsibility.ResponsibilityWorkingTeamMember.SmWorkingTeamMember.User.FullNameWithUserName.Contains"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Block No"
                filterKey="Block.BlockNo.startsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Item"
                filterKey="Block.Item.startsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Sample Dev"
                filterKey="SampleDevUser.Login.startsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="NPD User"
                filterKey="NPDUser.Login.startsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default OpportunityItemFilter;
