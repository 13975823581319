import { PageFilter } from '../state/app';
import { ProjectProgramListFilter } from '../state/projectProgram';
import axios from '../utils/axios';
import { IProjectProgram } from '../models/projectProgram.model';

const baseUrl = `/api/smProjectPrograms`;

export const createProjectProgram = async (projectProgram: IProjectProgram) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, projectProgram);
};

export const fetchProjectProgram = async (id: IProjectProgram['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const fetchProjectProgramList = async ({
  filters,
  paging,
}: {
  filters: ProjectProgramListFilter;
  paging: PageFilter;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const deleteProjectProgram = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};

export const updateProjectProgram = async (projectProgram: IProjectProgram) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, projectProgram);
};
