import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import { fetchSquadMemberList } from '../../services/squadMemberService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import { FETCH_SQUAD_MEMBER_LIST } from './squadMember.actions';

function* fetchSquadMemberListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSquadMemberList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSquadMember() {
  yield debounce(250, FETCH_SQUAD_MEMBER_LIST, fetchSquadMemberListSaga);
}
