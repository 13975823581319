import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
} from '@material-ui/core/';
import { ISubtaskType } from '../../../../models/subtaskType.model';
import {
  addSelectedSubtaskTypeInTaskCreate,
  TaskCreateState,
} from '../../../../state/taskCreate';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../state/configureStore';
import {
  Close as CloseIcon,
  Save as SaveIcon,
  SkipNext as NextIcon,
  SkipPrevious as PreviousIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
    },
    paper: {
      width: 200,
      height: 230,
      overflow: 'auto',
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
  }),
);

interface SubtaskTypeSelectDialogProps {
  nextClick: () => void;
  backClick: () => void;
  closeClick: () => void;
  subtaskTypeList: ISubtaskType[];
}

const SubtaskTypeSelectDialog: React.FC<SubtaskTypeSelectDialogProps> = (props) => {
  const dispatch = useDispatch();
  const { nextClick, backClick, subtaskTypeList, closeClick } = props;
  const { selectedSubtaskType } = useSelector<AppState, TaskCreateState>(
    (state) => state.taskCreate,
  );
  const classes = useStyles();
  const [selectedSubtaskTypeNames, setSelectedSubTaskTypeNames] = useState<Set<string>>(
    new Set<string>(),
  );

  useEffect(() => {
    setSelectedSubTaskTypeNames(new Set<string>(selectedSubtaskType));
  }, [selectedSubtaskType, setSelectedSubTaskTypeNames]);

  return (
    <>
      <DialogTitle style={{ paddingTop: '8px', paddingBottom: '8px' }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          Select Subtask Type
          <IconButton
            color="inherit"
            aria-label="close"
            onClick={() => {
              closeClick();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ paddingRight: '400px', paddingLeft: '40px' }}>
        <Grid container spacing={2} alignItems="center">
          <FormControl component="fieldset">
            {/* <FormLabel component="legend">Select request you need.</FormLabel> */}
            <FormGroup>
              {subtaskTypeList.map((subtaskType) => {
                return (
                  <FormControlLabel
                    key={subtaskType.id!}
                    control={
                      <Checkbox
                        checked={selectedSubtaskTypeNames.has(subtaskType.name!)}
                        onChange={(e) => {
                          const newSelectedSet = new Set(selectedSubtaskTypeNames);
                          if (e.target.checked) {
                            newSelectedSet.add(e.target.value);
                          } else {
                            newSelectedSet.delete(e.target.value);
                          }
                          setSelectedSubTaskTypeNames(newSelectedSet);
                        }}
                        value={subtaskType.name!}
                      />
                    }
                    label={subtaskType.name}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginRight: '5px', marginBottom: '10px' }}>
        {/* <Button
          onClick={() => {
            closeClick();
          }}
          variant="contained"
          color="default"
          startIcon={<CloseIcon />}
        >
          Close
        </Button> */}
        <Button
          onClick={() => {
            backClick();
          }}
          startIcon={<PreviousIcon />}
          variant="contained"
          color="default"
        >
          Back
        </Button>
        <Button
          onClick={() => {
            dispatch(
              addSelectedSubtaskTypeInTaskCreate(Array.from(selectedSubtaskTypeNames)),
            );
            nextClick();
          }}
          disabled={selectedSubtaskTypeNames.size === 0}
          variant="contained"
          color="primary"
          startIcon={<NextIcon />}
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
};

export default SubtaskTypeSelectDialog;
