import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { get } from 'lodash';
import React, { useState } from 'react';

import { IProjectProgram } from '../../../models/projectProgram.model';
import { IProjectProgramBrand } from '../../../models/projectProgramBrand.model';

export interface ProjectDetailProgramBrandTableProps {
  projectPrograms?: IProjectProgram[];
}

const ProjectDetailProgramBrandTable: React.FC<
  ProjectDetailProgramBrandTableProps
> = props => {
  const { projectPrograms } = props;

  const [selectedProgram, setSelectedProgram] = useState<IProjectProgram | undefined>(
    undefined,
  );

  if (!projectPrograms) {
    return null;
  }

  const getBrands = (): IProjectProgramBrand[] => {
    return get(selectedProgram, 'smProjectProgramBrands', []);
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>Program</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projectPrograms!.map((projectProgram, index) => {
                const { program } = projectProgram;
                return (
                  <TableRow
                    hover
                    key={index}
                    selected={
                      selectedProgram && projectProgram!.id! === selectedProgram!.id!
                    }
                    onClick={() => {
                      setSelectedProgram(projectProgram);
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{program!.name}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>Brand</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getBrands().map((projectProgramBrand, index) => {
                const { brand } = projectProgramBrand;
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{brand!.name}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectDetailProgramBrandTable;
