import { IProjectProgramBrand } from '../../models/projectProgramBrand.model';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_PROJECT_PROGRAM_BRAND,
  CREATE_PROJECT_PROGRAM_BRAND,
  FETCH_PROJECT_PROGRAM_BRAND,
  FETCH_PROJECT_PROGRAM_BRAND_LIST,
  UPDATE_PROJECT_PROGRAM_BRAND,
  FETCH_BRAND_BY_PROJECT_ID,
} from './projectProgramBrand.actions';
import { ProjectProgramBrandState } from './projectProgramBrand.types';

const getInitialProjectProgramBrandState = (): IProjectProgramBrand => ({});

const initialState: ProjectProgramBrandState = {
  errorMessage: '',
  item: getInitialProjectProgramBrandState(),
  items: [],
  loading: false,
  totalItems: 0,
  brands: [],
};

export default (
  state: ProjectProgramBrandState = initialState,
  action: AppAction,
): ProjectProgramBrandState => {
  switch (action.type) {
    case REQUEST(FETCH_PROJECT_PROGRAM_BRAND_LIST):
    case REQUEST(CREATE_PROJECT_PROGRAM_BRAND):
    case REQUEST(FETCH_PROJECT_PROGRAM_BRAND):
    case REQUEST(UPDATE_PROJECT_PROGRAM_BRAND):
    case REQUEST(FETCH_BRAND_BY_PROJECT_ID):
      return { ...state, loading: true };
    case SUCCESS(FETCH_PROJECT_PROGRAM_BRAND_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_PROJECT_PROGRAM_BRAND_LIST):
    case FAILURE(CREATE_PROJECT_PROGRAM_BRAND):
    case FAILURE(FETCH_PROJECT_PROGRAM_BRAND):
    case FAILURE(UPDATE_PROJECT_PROGRAM_BRAND):
    case FAILURE(FETCH_BRAND_BY_PROJECT_ID):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_PROJECT_PROGRAM_BRAND):
    case SUCCESS(FETCH_PROJECT_PROGRAM_BRAND):
    case SUCCESS(UPDATE_PROJECT_PROGRAM_BRAND):
      return { ...state, loading: false, item: action.payload.data };
    case SUCCESS(FETCH_BRAND_BY_PROJECT_ID):
      return { ...state, loading: false, brands: action.payload.data };
    case CLEAR_PROJECT_PROGRAM_BRAND:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
