import { Button, Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IProject } from '../../../models/project.model';
import { authStateSelector } from '../../../state/auth/auth.types';
import ProjectList from '../../pages/project/ProjectList';
import { pushNotificationMessage } from '../Notification/notification.action';
import { DoneAll, Close } from '@material-ui/icons';

import { ProjectListFilter } from '../../../state/project';

export interface SearchProjectDialogProps {
  forceFilter?: ProjectListFilter;
  open: boolean;
  onClose: (selectedProject: IProject | null) => void;
}

const SearchProjectDialog: React.FC<SearchProjectDialogProps> = (props) => {
  const dispatch = useDispatch();

  const { open, onClose, forceFilter } = props;
  const [selectedProject, setSelectedProject] = useState<IProject | null>(null);

  const { user } = useSelector(authStateSelector);

  return (
    <Dialog open={open} maxWidth="lg">
      <DialogContent>
        {user!.userName! ? (
          <ProjectList
            searchMode={true}
            onSelect={(project: IProject | null) => {
              setSelectedProject(project);
            }}
            forceFilter={forceFilter}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginBottom: 15 }}
        >
          <Button
            startIcon={<DoneAll />}
            color="primary"
            variant="contained"
            onClick={() => {
              if (selectedProject) {
                onClose(selectedProject);
              } else {
                dispatch(
                  pushNotificationMessage({
                    message: 'Please select project',
                    type: 'error',
                  }),
                );
              }
            }}
          >
            Confirm
          </Button>
          <Button
            startIcon={<Close />}
            color="secondary"
            variant="outlined"
            onClick={() => onClose(null)}
          >
            Close
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default SearchProjectDialog;
