import { Avatar, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { User } from '../../state/user';

interface UserLetterAvatarProps {
  users: User[];
}

const useStyles = makeStyles((theme) => ({
  userContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  user: {
    position: 'relative',
    marginLeft: '-12px',
  },
  secondary: {
    //color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: '#f50057',
  },
  default: {
    backgroundColor: '#bdbdbd',
  },
}));

const UserLetterAvatar: React.FC<UserLetterAvatarProps> = (props) => {
  const { users } = props;
  const classes = useStyles();
  return (
    <div className={classes.userContainer}>
      {users.map((user) => {
        const displayName =
          user.firstName && user.lastName
            ? `${user.firstName.charAt(0).toUpperCase()}${user.lastName
                .charAt(0)
                .toUpperCase()}`
            : user.userName?.charAt(0).toUpperCase();
        return (
          <Tooltip
            title={
              <Typography variant="body2" gutterBottom>
                {user.activated
                  ? user.fullNameWithUserName
                  : `${user.fullNameWithUserName} (Inactive)`}
              </Typography>
            }
            className={classes.user}
            key={user.id}
          >
            <Avatar className={user.activated ? classes.default : classes.secondary}>
              {displayName}
            </Avatar>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default UserLetterAvatar;
