import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ISubtaskItem } from '../../../models/subtaskItem.model';
import { ISubtaskItemMoq } from '../../../models/subtaskItemMoq.model';
import { IMoq } from '../../../models/moq.model';
import { AppState } from '../../../state/configureStore';
import {
  createSubtaskItemMoqForSubtaskItem,
  fetchSubtaskItemMoqList,
  SubtaskItemMoqState,
} from '../../../state/subtaskItemMoq';

import MoqTable from './MoqTable';

interface SubtaskItemMoqTableProps {
  subtaskItem: ISubtaskItem;
}

const SubtaskItemMoqTable: React.FC<SubtaskItemMoqTableProps> = props => {
  const { subtaskItem } = props;
  const dispatch = useDispatch();
  const { items: moqList } = useSelector<AppState, SubtaskItemMoqState>(
    state => state.subtaskItemMoq,
  );

  const defaultNewRowValue = useMemo<ISubtaskItemMoq>(() => {
    return {
      smSubtaskItemId: subtaskItem.id!,
      price: '',
      quantity: '',
      unitOfQuantity: '',
      unitOfPrice: '',
    };
  }, [subtaskItem]);

  const handleUpdate = (subtaskItemMoqs: IMoq[]) => {
    dispatch(createSubtaskItemMoqForSubtaskItem(subtaskItem.id!, subtaskItemMoqs));
  };

  useEffect(() => {
    dispatch(
      fetchSubtaskItemMoqList(
        { 'SmSubtaskItemId.EqualsTo': subtaskItem.id! },
        { pageNumber: 0, pageSize: 99 },
      ),
    );
  }, [subtaskItem, dispatch]);

  return (
    <MoqTable
      blockNo={subtaskItem.smTaskItem!.opportunityItem!.block!.blockNo}
      defaultNewRowValue={defaultNewRowValue}
      onUpdate={handleUpdate}
      moqList={moqList}
    />
  );
};

export default SubtaskItemMoqTable;
