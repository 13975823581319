import { Grid } from '@material-ui/core';
import React from 'react';
import { ITaskItemSummaryReportFilter } from '../../../../../state/report';
import { SearchDateField, SearchField } from '../../../../components';
import {
  FilterContainer,
  IFilterContainerConfigProps,
} from '../../../../components/FilterContainer';

const TaskItemSummaryReportFilterComponent: React.FC<
  IFilterContainerConfigProps<ITaskItemSummaryReportFilter>
> = props => {
  return (
    <FilterContainer
      {...props}
      render={filterProps => {
        const { localFilter, handleChangeFilter, handleChangeDateFilter } = filterProps;
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Program"
                filterKey="SmTaskItem.OpportunityItem.Opportunity.Program.Name.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Brand"
                filterKey="SmTaskItem.OpportunityItem.Opportunity.Brand.Name.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Block No"
                filterKey="SmTaskItem.OpportunityItem.Block.BlockNo.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Assignment Number"
                filterKey="SmTaskItem.OpportunityItem.Opportunity.AssignmentNumber.StartsWith"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Sales"
                filterKey="SmTaskItem.OpportunityItem.Opportunity.Responsibility.ResponsibilitySalesTeamMember.SalesTeamMember.User.FullNameWithUserName.Contains"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="CS"
                filterKey="SmTaskItem.OpportunityItem.Opportunity.Responsibility.ResponsibilityWorkingTeamMember.SmWorkingTeamMember.User.FullNameWithUserName.Contains"
                onChange={handleChangeFilter}
                filters={localFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchDateField
                showTodayButton={true}
                fullWidth={true}
                label="Start Due Date"
                onChange={handleChangeDateFilter}
                filters={localFilter}
                filterKey={'SmSubtask.StartDueDate.GreaterThanOrEqual'}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchDateField
                showTodayButton={true}
                fullWidth={true}
                label="End Due Date"
                onChange={handleChangeDateFilter}
                filters={localFilter}
                filterKey={'SmSubtask.EndDueDate.LessThanOrEqual'}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchDateField
                showTodayButton={true}
                fullWidth={true}
                label="Start Created Date"
                onChange={handleChangeDateFilter}
                filters={localFilter}
                filterKey={'StartCreatedAt.GreaterThanOrEqual'}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchDateField
                showTodayButton={true}
                fullWidth={true}
                label="End Created Date"
                onChange={handleChangeDateFilter}
                filters={localFilter}
                filterKey={'EndCreatedAt.LessThanOrEqual'}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default TaskItemSummaryReportFilterComponent;
