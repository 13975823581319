import { Box, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { CheckBoxFastField, TextFieldFastField } from '../../../components';

interface SampleDevelopmentWlDetailProps {}

const SampleDevelopmentWlDetail: React.FC<SampleDevelopmentWlDetailProps> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={3}>
              <Typography>Detail</Typography>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              item
              xs={12}
            >
              <Grid item xs={12} md={2}>
                <Typography>Detail:</Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextFieldFastField
                  type="number"
                  step="any"
                  name="variableAmount"
                  label="Variable Amount"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={8} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="variableSize" label="Size" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="variableKeySize" label="Key Size" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="variableStyleNo" label="Style No" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="variableColor" label="Color" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="variableFabricContent" label="Fabric Content" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="variableInstruction" label="Instruction" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="variableWashingSymbol" label="Washing Symbol" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="variableArt" label="Art, RN, CA, NO" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="variableFactoryCode" label="Factory Code" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="variableCountry" label="Country" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="variableBarcode" label="Barcode" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="variablePrice" label="Price" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="variableOther" label="Other" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldFastField
                  name="variableOtherData"
                  label="Other"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentWlDetail;
