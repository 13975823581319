import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { IResponsibilityWorkingTeamMember } from '../../../../models/responsibilityWorkingTeamMember.model';
import { WorkingTeamMember } from '../../../../state/workingTeamMember';
import {
  replaceResponsibilityWorkingTeamMember,
  ResponsibilityWorkingTeamMemberReplacePayLoad,
} from '../../../../state/responsibilityWorkingTeamMember';
import {
  WorkingTeamMemberComboBox,
  WorkingTeamMemberComboBoxSearch,
} from '../../../components';

interface ResponsibilityWorkingTeamMemberReplaceDialogProps {
  responsibilityWorkingTeamMember: IResponsibilityWorkingTeamMember | null;
  open: boolean;
  onClose: () => void;
  smWorkingTeamId?: number | null;
}

interface ResponsibilityWorkingTeamMemberReplaceValues {
  smWorkingTeamMember?: WorkingTeamMember | null;
  smWorkingTeamMemberId?: number | null;
}

const ResponsibilitySchema = Yup.object().shape({
  smWorkingTeamMemberId: Yup.number()
    .nullable(true)
    .required('WorkingTeamMember is required'),
});

type dialogStep =
  | 'replaceResponsibilityWorkingTeamMember'
  | 'confirmResponsibilityWorkingTeamMember';

const ResponsibilityWorkingTeamMemberReplaceDialog: React.FC<ResponsibilityWorkingTeamMemberReplaceDialogProps> = (
  props,
) => {
  const dispatch = useDispatch();
  const { open, onClose, responsibilityWorkingTeamMember, smWorkingTeamId } = props;

  const [
    replaceResponsibilityWorkingTeamMemberDlgStep,
    setReplaceResponsibilityWorkingTeamMemberDlgStep,
  ] = useState<dialogStep>('replaceResponsibilityWorkingTeamMember');
  const [
    responsibilityReplaceWorkingTeamMemberPayLoad,
    setResponsibilityReplaceWorkingTeamMemberPayLoad,
  ] = useState<ResponsibilityWorkingTeamMemberReplacePayLoad>();

  const initialFormValues: ResponsibilityWorkingTeamMemberReplaceValues = useMemo(() => {
    const {
      smWorkingTeamMember,
      smWorkingTeamMemberId,
    } = responsibilityWorkingTeamMember!;
    return { smWorkingTeamMember, smWorkingTeamMemberId };
  }, [responsibilityWorkingTeamMember]);

  const handleSubmit = (
    values: ResponsibilityWorkingTeamMemberReplaceValues,
    actions: FormikHelpers<ResponsibilityWorkingTeamMemberReplaceValues>,
  ) => {
    setResponsibilityReplaceWorkingTeamMemberPayLoad({
      responsibilityWorkingTeamMemberId: responsibilityWorkingTeamMember!.id,
      newWorkingTeamMember: values.smWorkingTeamMember!,
      newWorkingTeamMemberId: values.smWorkingTeamMemberId!,
    });

    setReplaceResponsibilityWorkingTeamMemberDlgStep(
      'confirmResponsibilityWorkingTeamMember',
    );
    actions.setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'} fullWidth>
      <Formik
        enableReinitialize={true}
        initialValues={initialFormValues}
        validationSchema={ResponsibilitySchema}
        onSubmit={handleSubmit}
        validateOnChange={true}
      >
        {({ values, submitForm, errors, touched, setFieldValue, setFieldTouched }) => {
          const isError = (
            fieldName: keyof ResponsibilityWorkingTeamMemberReplaceValues,
          ) => !!touched[fieldName] && !!errors[fieldName];
          const errorMessage = (
            fieldName: keyof ResponsibilityWorkingTeamMemberReplaceValues,
          ) => (!!touched[fieldName] ? (errors[fieldName] as string) : '');

          return (
            <Form>
              {replaceResponsibilityWorkingTeamMemberDlgStep ===
                'replaceResponsibilityWorkingTeamMember' && (
                <>
                  <DialogTitle>Replace Member </DialogTitle>
                  <CardContent>
                    {/* <WorkingTeamMemberComboBox
                        handleChange={result => {
                          setFieldValue('smWorkingTeamMember', result ? result : null);
                          setFieldTouched('smWorkingTeamMember', true, false);
                          setFieldValue('smWorkingTeamMemberId', result ? result.id : null);
                          setFieldTouched('smWorkingTeamMemberId', true, false);
                        }}
                        selectedValue={values.smWorkingTeamMember as WorkingTeamMember}
                        error={isError('smWorkingTeamMemberId')}
                        helperText={errorMessage('smWorkingTeamMemberId')}
                        smWorkingTeamId={ smWorkingTeamId!}
                      /> */}
                    <WorkingTeamMemberComboBoxSearch
                      handleChange={(result: WorkingTeamMember) => {
                        setFieldValue('smWorkingTeamMember', result ? result : null);
                        setFieldTouched('smWorkingTeamMember', true, false);
                        setFieldValue('smWorkingTeamMemberId', result ? result.id : null);
                        setFieldTouched('smWorkingTeamMemberId', true, false);
                      }}
                      selectedValue={values.smWorkingTeamMember as WorkingTeamMember}
                      error={isError('smWorkingTeamMemberId')}
                      helperText={errorMessage('smWorkingTeamMemberId')}
                      handleClose={undefined!}
                    />
                  </CardContent>
                  <DialogActions>
                    <Button
                      variant={'contained'}
                      color="secondary"
                      onClick={() => {
                        onClose();
                      }}
                    >
                      CANCEL
                    </Button>
                    <Button
                      variant={'contained'}
                      color="primary"
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      NEXT
                    </Button>
                  </DialogActions>
                </>
              )}
              {replaceResponsibilityWorkingTeamMemberDlgStep ===
                'confirmResponsibilityWorkingTeamMember' && (
                <>
                  <DialogTitle>Confirm Replace Member</DialogTitle>
                  <CardContent>
                    <TextField
                      name="Name"
                      type="input"
                      label="Name"
                      variant="outlined"
                      margin="dense"
                      value={`${
                        responsibilityWorkingTeamMember!.smWorkingTeamMember
                          ?.smWorkingTeam?.name
                      } ${
                        responsibilityWorkingTeamMember!.smWorkingTeamMember!.user!
                          .fullName
                      }`}
                      fullWidth
                    />
                    <TextField
                      name="changeToName"
                      type="input"
                      label="Change To Name"
                      variant="outlined"
                      margin="dense"
                      value={`${
                        responsibilityReplaceWorkingTeamMemberPayLoad!
                          .newWorkingTeamMember?.smWorkingTeam?.name
                      } ${
                        responsibilityReplaceWorkingTeamMemberPayLoad!
                          .newWorkingTeamMember?.user?.fullNameWithUserName
                      }`}
                      fullWidth
                    />
                  </CardContent>
                  <DialogActions>
                    <Button
                      variant={'contained'}
                      color="secondary"
                      onClick={() => {
                        setReplaceResponsibilityWorkingTeamMemberDlgStep(
                          'replaceResponsibilityWorkingTeamMember',
                        );
                      }}
                    >
                      BACK
                    </Button>
                    <Button
                      variant={'contained'}
                      color="primary"
                      onClick={() => {
                        dispatch(
                          replaceResponsibilityWorkingTeamMember(
                            responsibilityReplaceWorkingTeamMemberPayLoad!,
                          ),
                        );
                        setReplaceResponsibilityWorkingTeamMemberDlgStep(
                          'replaceResponsibilityWorkingTeamMember',
                        );
                        onClose();
                      }}
                    >
                      OK
                    </Button>
                  </DialogActions>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ResponsibilityWorkingTeamMemberReplaceDialog;
