import { CardContent, Grid, Paper, Typography, Box } from '@material-ui/core';
import React from 'react';
import { CheckBoxFastField } from '../../../components';

interface SampleDevelopmentPlAssemblyProps {}

const SampleDevelopmentPlAssembly: React.FC<SampleDevelopmentPlAssemblyProps> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>HL Assembly</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>HL Assembly:</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="accessoriesLaminet" label="Laminet" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="smockingStitch" label="Smocking Stitch" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="accessoriesVerane" label="Verane" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="accessoriesVanish" label="Vanish" />
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentPlAssembly;
