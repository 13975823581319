import {
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../state/configureStore';
import {
  clearTaskActivity,
  fetchTaskActivityListBySubtaskId,
  fetchTaskActivityListByTaskId,
  TaskActivityState,
} from '../../../../state/taskActivity';
import useStyles from './taskActivityList.style';
import { DateTimeDisplay } from '../../../components/DateTimeDisplay';
import { Loading, DisplayHtmlContent } from '../../../components';

interface TaskActivityListProps {
  smTaskId: number;
  smSubtaskId?: number;
}

const TaskActivityList: React.FC<TaskActivityListProps> = props => {
  const { smTaskId, smSubtaskId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { items: taskActivityList, loading: taskActivityIsLoading } = useSelector<
    AppState,
    TaskActivityState
  >(state => state.taskActivity);

  useEffect(() => {
    if (smSubtaskId) {
      dispatch(fetchTaskActivityListBySubtaskId(smSubtaskId!));
    } else {
      dispatch(fetchTaskActivityListByTaskId(smTaskId));
    }
    return function cleanUp() {
      dispatch(clearTaskActivity());
    };
  }, [smTaskId, dispatch, smSubtaskId]);

  return (
    <Loading isLoading={taskActivityIsLoading}>
      {taskActivityList.map(taskActivity => {
        return (
          <ExpansionPanel
            defaultExpanded={true}
            className={classes.expansionPanel}
            key={taskActivity.id!}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.expansionPanelSummary}
            >
              <Box ml={1}>
                <Typography variant={'caption'}>
                  {taskActivity.smSubtask
                    ? ` [Subtask ${taskActivity.smSubtask!.smSubtaskType!.name}] By `
                    : null}
                  {taskActivity.user!.fullName}
                  {' - '}
                  {<DateTimeDisplay value={taskActivity.createdAt} />}
                </Typography>
              </Box>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetail}>
              <DisplayHtmlContent>{taskActivity.comment!}</DisplayHtmlContent>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </Loading>
  );
};

export default TaskActivityList;
