import { baseLinks } from './baseLinks';
import { DrawerListLink } from './drawer.types';
import PendingApprovalDrawerItem from './PendingApprovalDrawerItem/PendingApprovalDrawerItem';

export const adminLinks: DrawerListLink[] = [
  ...baseLinks,
  {
    name: 'Pending Approval Items',
    path: '/pendingApprovalItems',
    component: PendingApprovalDrawerItem,
  },
  {
    name: 'Users',
    path: '/users',
  },
];
