import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransitionProps } from '@material-ui/core/transitions';
import { SampleDevelopmentStatus } from '../../../constants';
import { ISampleDevelopmentWl } from '../../../models/sampleDevelopmentWl.model';
import { AppState } from '../../../state/configureStore';
import {
  SampleDevelopmentWlApprovalRequest,
  SampleDevelopmentWlApprovalRequestState,
  updateSampleDevelopmentWlApprovalRequestResponse,
} from '../../../state/sampleDevelopmentWlApprovalRequest';
import { SampleDevelopmentWlForm } from '../sampleDevelopmentWl';
import useStyles from './SampleDevelopmentWlApprovalRequestDialog.style';

interface SampleDevWlAppRequestDlgProps {
  handleClose: () => void;
  sampleDevelopmentWl: ISampleDevelopmentWl;
  sampleDevelopmentWlApprovalRequest: SampleDevelopmentWlApprovalRequest;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SampleDevelopmentWlApprovalRequestDialog: React.FC<
  SampleDevWlAppRequestDlgProps
> = props => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const { handleClose, sampleDevelopmentWl, sampleDevelopmentWlApprovalRequest } = props;
  const { openDialog } = useSelector<AppState, SampleDevelopmentWlApprovalRequestState>(
    ({ sampleDevelopmentWlApprovalRequest }) => sampleDevelopmentWlApprovalRequest,
  );

  const sampleDevId = sampleDevelopmentWl.id ? sampleDevelopmentWl.id.toString() : '';
  return (
    <>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Assignment Approval
            </Typography>
            <div className={classes.approveButtonGroup}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const responseRequest = { ...sampleDevelopmentWlApprovalRequest };
                  responseRequest.responseResult = SampleDevelopmentStatus.APPROVED;
                  dispatch(
                    updateSampleDevelopmentWlApprovalRequestResponse(responseRequest),
                  );
                }}
              >
                Approve
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => {
                  const responseRequest = { ...sampleDevelopmentWlApprovalRequest };
                  responseRequest.responseResult = SampleDevelopmentStatus.REJECTED;
                  dispatch(
                    updateSampleDevelopmentWlApprovalRequestResponse(responseRequest),
                  );
                }}
              >
                Reject
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <SampleDevelopmentWlForm id={sampleDevId} />
      </Dialog>
    </>
  );
};

export default SampleDevelopmentWlApprovalRequestDialog;
