export type OpportunityStatusType =
  | 'PENDING_FOR_APPROVAL'
  | 'IN_REQUEST'
  | 'REJECTED'
  | 'APPROVED';

const OpportunityStatus = Object.freeze({
  PENDING_FOR_APPROVAL: 'PENDING_FOR_APPROVAL' as OpportunityStatusType,
  IN_REQUEST: 'IN_REQUEST' as OpportunityStatusType,
  REJECTED: 'REJECTED' as OpportunityStatusType,
  APPROVED: 'APPROVED' as OpportunityStatusType,
});

export default OpportunityStatus;

export const OpportunityStatusLabel = Object.freeze({
  [OpportunityStatus.PENDING_FOR_APPROVAL]: 'Pending For Approval',
  [OpportunityStatus.IN_REQUEST]: 'In Request',
  [OpportunityStatus.REJECTED]: 'Rejected',
  [OpportunityStatus.APPROVED]: 'Approved',
});
