import { Box, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { CheckBoxFastField } from '../../../components';

interface SampleDevelopmentWlJobProps {}

const SampleDevelopmentWlJob: React.FC<SampleDevelopmentWlJobProps> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Job</Typography>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              item
              xs={12}
            >
              <Grid item xs={12} md={2}>
                <Typography>Job Type:</Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeWL" label="WL" />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              item
              xs={12}
            >
              <Grid item xs={2}>
                <Typography>Job Description:</Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobDescBoardLoom" label="Board Loom" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobDescNeedleLoom" label="Needle Loom" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobDescShuttleLoom" label="Shuttle Loom" />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              item
              xs={12}
            >
              <Grid item xs={2}>
                <Typography>Pattern:</Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobDescSatin" label="Satin" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobDescTaffeta" label="Taffeta" />
              </Grid>
              <Grid item xs={12} md={6} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobDescNormal" label="Normal" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobDescUpGround" label="Up Ground" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobDescDamask" label="Damask" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobDescTwill" label="Twill" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobDescReverse" label="Reverse" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobDescDoubleFace" label="Double Face" />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentWlJob;
