import { PageFilter } from '../state/app';
import { ProjectCustomerListFilter } from '../state/projectCustomer';
import axios from '../utils/axios';
import { IProjectCustomer } from '../models/projectCustomer.model';

const baseUrl = `/api/smProjectCustomers`;

export const createProjectCustomer = async (projectCustomer: IProjectCustomer) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, projectCustomer);
};

export const fetchProjectCustomer = async (id: IProjectCustomer['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const fetchProjectCustomerList = async ({
  filters,
  paging,
}: {
  filters: ProjectCustomerListFilter;
  paging: PageFilter;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const deleteProjectCustomer = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};

export const updateProjectCustomer = async (projectCustomer: IProjectCustomer) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, projectCustomer);
};
