import { ISampleDevelopmentTl } from '../../models/sampleDevelopmentTl.model';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_SAMPLE_DEVELOPMENT_TL,
  CREATE_SAMPLE_DEVELOPMENT_TL,
  FETCH_SAMPLE_DEVELOPMENT_TL,
  FETCH_SAMPLE_DEVELOPMENT_TL_LIST,
  SUBMIT_SAMPLE_DEVELOPMENT_TL_FOR_APPROVAL,
  UPDATE_SAMPLE_DEVELOPMENT_TL,
} from './sampleDevelopmentTl.actions';
import { SampleDevelopmentTlState } from './sampleDevelopmentTl.types';

const initialState: SampleDevelopmentTlState = {
  errorMessage: '',
  item: {} as Readonly<ISampleDevelopmentTl>,
  items: [] as ISampleDevelopmentTl[],
  loading: false,
  totalItems: 0,
};

export default (
  state: SampleDevelopmentTlState = initialState,
  action: AppAction,
): SampleDevelopmentTlState => {
  switch (action.type) {
    case REQUEST(FETCH_SAMPLE_DEVELOPMENT_TL_LIST):
    case REQUEST(FETCH_SAMPLE_DEVELOPMENT_TL):
    case REQUEST(CREATE_SAMPLE_DEVELOPMENT_TL):
    case REQUEST(UPDATE_SAMPLE_DEVELOPMENT_TL):
      return { ...state, loading: true };
    case SUCCESS(FETCH_SAMPLE_DEVELOPMENT_TL_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_SAMPLE_DEVELOPMENT_TL_LIST):
    case FAILURE(FETCH_SAMPLE_DEVELOPMENT_TL):
    case FAILURE(CREATE_SAMPLE_DEVELOPMENT_TL):
    case FAILURE(UPDATE_SAMPLE_DEVELOPMENT_TL):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(UPDATE_SAMPLE_DEVELOPMENT_TL):
    case SUCCESS(FETCH_SAMPLE_DEVELOPMENT_TL):
    case SUCCESS(CREATE_SAMPLE_DEVELOPMENT_TL):
    case SUCCESS(SUBMIT_SAMPLE_DEVELOPMENT_TL_FOR_APPROVAL):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_SAMPLE_DEVELOPMENT_TL:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
