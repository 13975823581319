import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { AppState } from '../../../state/configureStore';
import { User } from '../../../state/user';
import {
  clearWorkingGroup,
  fetchWorkingGroupList,
  WorkingGroupState,
} from '../../../state/workingGroup';
import {
  clearWorkingTeam,
  createWorkingTeam,
  fetchWorkingTeam,
  updateWorkingTeam,
  WorkingTeam,
  WorkingTeamState,
} from '../../../state/workingTeam';
import {
  FixedPositionButtons,
  Loading,
  PageContainer,
  PageTitle,
} from '../../components';
import UserComboBox from '../../components/UserComboBox';
import useStyles from './workingTeamForm.style';
import WorkingTeamFormDetail from './WorkingTeamFormDetail';

const WorkingTeamSchema = Yup.object().shape({
  description: Yup.string().nullable(true),
  smWorkingGroupId: Yup.number().required('Working group is required'),
});

interface WorkingTeamFormRouteParamsProps {
  id: string;
}

interface WorkingTeamFormProps
  extends RouteComponentProps<WorkingTeamFormRouteParamsProps> {}

const WorkingTeamForm: React.FC<WorkingTeamFormProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { match, history } = props;
  const {
    params: { id: paramsId },
  } = match;
  const isUpdateMode = paramsId !== undefined;

  const { items: workingGroups, loading: workingGroupIdLoading } = useSelector<
    AppState,
    WorkingGroupState
  >(state => state.workingGroup);

  const { item: workingTeam, loading: workingTeamIsLoading } = useSelector<
    AppState,
    WorkingTeamState
  >(state => state.workingTeam);

  const isLoading = workingGroupIdLoading || workingTeamIsLoading;

  const redirectToWorkingGroupList = () => {
    history.goBack();
  };

  const handleSubmit = (values: WorkingTeam, actions: FormikHelpers<WorkingTeam>) => {
    if (isUpdateMode) {
      dispatch(updateWorkingTeam({ values, history }));
    } else {
      dispatch(createWorkingTeam({ values, history }));
    }
    actions.setSubmitting(false);
  };

  useEffect(() => {
    dispatch(fetchWorkingGroupList({}, { pageNumber: 0, pageSize: 99999 }));
    // dispatch(fetchWorkingTeamRoleList());
    return function cleanUp() {
      dispatch(clearWorkingGroup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isUpdateMode) {
      dispatch(fetchWorkingTeam(+paramsId));
    } else {
      dispatch(clearWorkingTeam());
    }
    return function cleanUp() {
      dispatch(clearWorkingTeam());
    };
  }, [isUpdateMode, paramsId]);

  return (
    // <PageContainer>
    <Loading isLoading={isLoading}>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...workingTeam }}
        validationSchema={WorkingTeamSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, errors, touched, submitForm, setFieldValue }) => {
          return (
            <Form>
              <Card className={classes.root}>
                <PageTitle>
                  {!isUpdateMode ? `New Working Team` : `Update Working Team`}
                </PageTitle>

                <CardContent>
                  <FormControl
                    className={classes.formControl}
                    error={!!errors.smWorkingGroupId && !!touched.smWorkingGroupId}
                    fullWidth
                    disabled={isUpdateMode}
                  >
                    <InputLabel htmlFor="smWorkingGroupId">Working Group</InputLabel>
                    <Select
                      value={values.smWorkingGroupId || ''}
                      onChange={handleChange}
                      name="smWorkingGroupId"
                      input={<Input name="smWorkingGroupId" id="smWorkingGroupId" />}
                    >
                      {workingGroups.map(workingGroup => (
                        <MenuItem value={workingGroup.id} key={workingGroup.id}>
                          {workingGroup.name} {workingGroup.description}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.smWorkingGroupId}</FormHelperText>
                  </FormControl>
                  {isUpdateMode ? (
                    <TextField
                      name="name"
                      type="input"
                      label="Name"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      value={values.name || ''}
                      onChange={handleChange}
                      InputProps={{
                        readOnly: true,
                      }}
                      placeholder={'Auto Generated'}
                    />
                  ) : (
                    <span />
                  )}
                  <TextField
                    name="description"
                    type="input"
                    label="Description"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={values.description || ''}
                    onChange={handleChange}
                    error={!!errors.description && !!touched.description}
                    helperText={errors.description}
                  />
                  <UserComboBox
                    handleChange={(value: User) => {
                      setFieldValue('owner', value ? value : undefined);
                      setFieldValue('ownerId', value ? value.id : undefined);
                    }}
                    selectedValue={values.owner as User}
                    label={'Owner'}
                  />
                </CardContent>
              </Card>

              <FixedPositionButtons
                actions={[
                  {
                    name: !isUpdateMode ? `Create` : `Update`,
                    onClick: () => {
                      submitForm();
                    },
                    color: 'primary' as 'primary',
                    disabled: false,
                    icon: 'save',
                  },
                  {
                    name: 'CANCEL',
                    onClick: redirectToWorkingGroupList,
                    color: 'default' as 'default',
                    disabled: false,
                  },
                ]}
                open
              />

              {isUpdateMode && <WorkingTeamFormDetail workingTeamId={+paramsId} />}
            </Form>
          );
        }}
      </Formik>
    </Loading>
    // </PageContainer>
  );
};

export default WorkingTeamForm;
