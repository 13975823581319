import { AppAction, PageFilter, AppCallback } from '../app';
import {
  ProgramAndBrandRequest,
  ProgramAndBrandRequestListFilter,
} from './programAndBrandRequest.types';

export const FETCH_PROGRAMANDBRANDREQUEST = 'FETCH_PROGRAMANDBRANDREQUEST';
export const FETCH_PROGRAMANDBRANDREQUEST_LIST = 'FETCH_PROGRAMANDBRANDREQUEST_LIST';
export const CREATE_PROGRAMANDBRANDREQUEST = 'CREATE_PROGRAMANDBRANDREQUEST';
export const UPDATE_PROGRAMANDBRANDREQUEST = 'UPDATE_PROGRAMANDBRANDREQUEST';
export const CLEAR_PROGRAMANDBRANDREQUEST = 'CLEAR_PROGRAMANDBRANDREQUEST';

export const fetchProgramAndBrandRequest = (
  id: ProgramAndBrandRequest['id'],
): AppAction => ({
  type: FETCH_PROGRAMANDBRANDREQUEST,
  payload: id,
});

export const fetchProgramAndBrandRequestList = (
  filters: ProgramAndBrandRequestListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_PROGRAMANDBRANDREQUEST_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createProgramAndBrandRequest = (
  newProgramAndBrandRequest: ProgramAndBrandRequest,
  callback?: AppCallback,
): AppAction => ({
  type: CREATE_PROGRAMANDBRANDREQUEST,
  payload: newProgramAndBrandRequest,
  callback,
});

export const updateProgramAndBrandRequest = (
  newProgramAndBrandRequest: ProgramAndBrandRequest,
  callback?: AppCallback,
): AppAction => ({
  type: UPDATE_PROGRAMANDBRANDREQUEST,
  payload: newProgramAndBrandRequest,
  callback,
});

export const changeProgramAndBrandRequestStatus = (
  programAndBrandRequest: ProgramAndBrandRequest,
  status: string,
  callback?: AppCallback,
): AppAction => {
  const localProgramAndBrandRequest = { ...programAndBrandRequest, status };
  return {
    type: UPDATE_PROGRAMANDBRANDREQUEST,
    payload: localProgramAndBrandRequest,
    callback,
  };
};

export const clearProgramAndBrandRequest = () => ({
  type: CLEAR_PROGRAMANDBRANDREQUEST,
});
