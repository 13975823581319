import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ITaskItemWithForecasts } from '../../../../models/taskItem.model';
import { AppState } from '../../../../state/configureStore';
import {
  createTaskSubtaskSubtaskItemAndEtc,
  editMultipleSelectedTaskItemForecastInTaskCreate,
  editSelectedTaskItemForecastInTaskCreate,
  TaskCreateState,
} from '../../../../state/taskCreate';
import { OpportunityLinkButton } from '../../../components';
import {
  MultipleTaskItemForecastEditDialog,
  TaskItemForecastEditDialog,
} from '../../../components/Forecast';
import {
  Close as CloseIcon,
  Save as SaveIcon,
  SkipNext as NextIcon,
  SkipPrevious as PreviousIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftIcon: {
      marginRight: theme.spacing(1),
    },
  }),
);

interface TaskItemForecastListDetailDialogProps {
  nextClick: () => void;
  backClick: () => void;
  closeClick: () => void;
}

const TaskItemForecastListDetailDialog: React.FC<
  TaskItemForecastListDetailDialogProps
> = (props) => {
  const classes = useStyles();
  const { nextClick, backClick, closeClick } = props;
  const dispatch = useDispatch();

  const handleNextClick = () => {
    dispatch(createTaskSubtaskSubtaskItemAndEtc());
    nextClick();
  };
  const [openTaskItemForecastEditDialog, setOpenTaskItemForecastEditDialog] =
    useState(false);

  const [
    openMultipleTaskItemForecastEditDialog,
    setOpenMultipleTaskItemForecastEditDialog,
  ] = useState(false);

  const [taskItemToEditForecast, setTaskItemToEditForecast] =
    useState<ITaskItemWithForecasts>({});

  const { selectedTaskItemsWithForecasts } = useSelector<AppState, TaskCreateState>(
    (state) => state.taskCreate,
  );

  const isTaskItemHasForecast = useCallback(
    (taskItemWithForecasts: ITaskItemWithForecasts) =>
      taskItemWithForecasts.forecasts!.length > 0,
    [],
  );

  return (
    <>
      <TaskItemForecastEditDialog
        open={openTaskItemForecastEditDialog}
        taskItemToEditForecast={taskItemToEditForecast}
        onClose={() => {
          setOpenTaskItemForecastEditDialog(false);
        }}
        onSubmit={(editedTaskItemForecast) => {
          dispatch(editSelectedTaskItemForecastInTaskCreate(editedTaskItemForecast));
        }}
      />

      <MultipleTaskItemForecastEditDialog
        open={openMultipleTaskItemForecastEditDialog}
        taskItemToEditForecasts={selectedTaskItemsWithForecasts}
        onClose={() => {
          setOpenMultipleTaskItemForecastEditDialog(false);
        }}
        onSubmit={(editedTaskItemForecasts) => {
          dispatch(
            editMultipleSelectedTaskItemForecastInTaskCreate(editedTaskItemForecasts),
          );
        }}
      />

      <DialogTitle style={{ paddingTop: '8px', paddingBottom: '8px' }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          Create Forecast
          <IconButton
            color="inherit"
            aria-label="close"
            onClick={() => {
              closeClick();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Assignment Number</TableCell>
              <TableCell>Program</TableCell>
              <TableCell>Brand</TableCell>
              <TableCell>Block</TableCell>
              <TableCell>Item</TableCell>
              <TableCell>Has Forecast</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedTaskItemsWithForecasts.map((item, index) => {
              const block = item.opportunityItem?.block!;
              const opportunity = item.opportunityItem?.opportunity!;
              return (
                <TableRow key={item.opportunityItemId}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {opportunity.assignmentNumber}
                    {/* <OpportunityLinkButton opportunity={opportunity} /> */}
                  </TableCell>
                  <TableCell>{opportunity.program?.name}</TableCell>
                  <TableCell>{opportunity.brand?.name}</TableCell>
                  <TableCell>{block.blockNo}</TableCell>
                  <TableCell>{block.item}</TableCell>
                  <TableCell>
                    <Checkbox checked={isTaskItemHasForecast(item)} disabled />
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Edit Forecast">
                      <span>
                        <IconButton
                          color="default"
                          size="small"
                          onClick={() => {
                            setTaskItemToEditForecast(item);
                            setOpenTaskItemForecastEditDialog(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    {/* <Button
                      onClick={() => {
                        setTaskItemToEditForecast(item);
                        setOpenTaskItemForecastEditDialog(true);
                      }}
                      variant="contained"
                    >
                      <EditIcon className={classes.leftIcon} />
                      Edit Forecast
                    </Button> */}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        {/* <Button
          onClick={() => {
            closeClick();
          }}
          variant="contained"
          color="default"
          startIcon={<CloseIcon />}
        >
          Close
        </Button> */}
        <Button
          onClick={() => {
            setOpenMultipleTaskItemForecastEditDialog(true);
          }}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
        >
          Edit Multiple Forecast
        </Button>
        <Button
          variant="contained"
          color="default"
          startIcon={<PreviousIcon />}
          onClick={backClick}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<NextIcon />}
          onClick={handleNextClick}
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
};

export default TaskItemForecastListDetailDialog;
