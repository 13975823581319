import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createResponsibilityApprovalRequest,
  fetchResponsibilityApprovalRequest,
  fetchResponsibilityApprovalRequestList,
  updateResponsibilityApprovalRequest,
  updateResponsibilityApprovalResponse,
} from '../../services/responsibilityApprovalRequestService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  closeResponsibilityApprovalRequestDialog,
  CREATE_RESPONSIBILITY_APPROVAL_REQUEST,
  FETCH_RESPONSIBILITY_APPROVAL_REQUEST,
  FETCH_RESPONSIBILITY_APPROVAL_REQUEST_LIST,
  UPDATE_RESPONSIBILITY_APPROVAL_RESPONSE,
  UPDATE_RESPONSIBILITY_APPROVAL_REQUEST,
} from './responsibilityApprovalRequest.actions';
import { ResponsibilityApprovalRequestCreateOrUpdatePayload } from './responsibilityApprovalRequest.types';

const listPageUrl = `/settings/responsibilityApprovalRequests`;

function* fetchResponsibilityApprovalRequestSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchResponsibilityApprovalRequest, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchResponsibilityApprovalRequestListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchResponsibilityApprovalRequestList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createResponsibilityApprovalRequestSaga(action: AppAction) {
  try {
    const {
      values,
    } = action.payload as ResponsibilityApprovalRequestCreateOrUpdatePayload;
    const successMessage = `Created responsibilityApprovalRequest successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createResponsibilityApprovalRequest, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateResponsibilityApprovalRequestSaga(action: AppAction) {
  try {
    const {
      values,
    } = action.payload as ResponsibilityApprovalRequestCreateOrUpdatePayload;
    const successMessage = `Updated responsibilityApprovalRequest successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateResponsibilityApprovalRequest, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateResponsibilityApprovalResponseSaga(action: AppAction) {
  try {
    const successMessage = `Updated responsibilityApprovalResponse successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateResponsibilityApprovalResponse, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put(closeResponsibilityApprovalRequestDialog());
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchResponsibilityApprovalRequest() {
  yield takeLatest(
    FETCH_RESPONSIBILITY_APPROVAL_REQUEST,
    fetchResponsibilityApprovalRequestSaga,
  );
  yield debounce(
    250,
    FETCH_RESPONSIBILITY_APPROVAL_REQUEST_LIST,
    fetchResponsibilityApprovalRequestListSaga,
  );
  yield takeLatest(
    CREATE_RESPONSIBILITY_APPROVAL_REQUEST,
    createResponsibilityApprovalRequestSaga,
  );
  yield takeLatest(
    UPDATE_RESPONSIBILITY_APPROVAL_REQUEST,
    updateResponsibilityApprovalRequestSaga,
  );
  yield takeLatest(
    UPDATE_RESPONSIBILITY_APPROVAL_RESPONSE,
    updateResponsibilityApprovalResponseSaga,
  );
}
