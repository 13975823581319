import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { TextFieldFastField } from '../../../components';
import useStyles from './sampleDevelopmentNl.style';

interface SamDevNlSpecDetailProps {}

const SampleDevelopmentNlSpecDetail: React.FC<SamDevNlSpecDetailProps> = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Typography className={classes.title}>2. Detail เปลี่ยนแปลง</Typography>
      <Grid item xs={12} md={2}>
        <Grid item xs={12} md={8}>
          <TextFieldFastField
            type="number"
            step="any"
            className={classes.textInput}
            name="variableAmount"
            label="Variable Amount"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextFieldFastField
          name="variableOtherData"
          label="Other"
          variant="outlined"
          margin="dense"
          fullWidth
          multiline={true}
          rows={3}
        />
      </Grid>
    </Grid>
  );
};

export default SampleDevelopmentNlSpecDetail;
