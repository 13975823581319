import { Box, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { CheckBoxFastField, TextFieldFastField } from '../../../components';

interface SampleDevelopmentHlFinishingProps {}

const SampleDevelopmentHlFinishing: React.FC<SampleDevelopmentHlFinishingProps> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Finishing</Typography>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              item
              xs={6}
            >
              <Grid item xs={12}>
                <Typography>ด้านหน้า(Front)</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishLaminate" label="Laminate Film" />
              </Grid>
              <Grid item xs={12} md={9} />

              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishLamAdhesive" label="1. Adhesive" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishLamHeatSeal" label="HeatSeal" />
              </Grid>
              <Grid item xs={12} md={5} />
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishLamMatt" label="2. Matt" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishLamGloss" label="Gloss" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishLamHalogram" label="Halogram" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishLamSoftTouch" label="SoftTouch" />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldFastField
                  name="frontFinishLaminateDesc"
                  label="Laminate Film Desc."
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishLamWindow" label="3. Window" />
              </Grid>
              <Grid item xs={12} md={8} />
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishLamFullArea" label="4. Full Area" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishLamSpot" label="Spot" />
              </Grid>
              <Grid item xs={12} md={5} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishChemical" label="Chemical" />
              </Grid>
              <Grid item xs={12} md={9} />
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishCheOilBased" label="1. Oil Based" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishCheWaterBased" label="Water Based" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishCheUV" label="UV" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField
                  name="frontFinishCheUVSilkscreen"
                  label="UV Silkscreen"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishCheVanish" label="Vanish" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishCheCalendering" label="Calendering" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishCheHighGloss" label="1.1 HighGloss" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishCheGloss" label="Gloss" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishCheMatt" label="Matt" />
              </Grid>
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishCheExtraMatt" label="Extra Matt" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishCheSoftTouch" label="Soft Touch" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishCheSatin" label="Satin" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishCheSpecial" label="Special" />
              </Grid>
              <Grid item xs={12} md={7}>
                <TextFieldFastField
                  name="frontFinishCheSpecialData"
                  label="Special"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishCheFullArea" label="1.2 Full Area" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishCheSpot" label="Spot" />
              </Grid>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishCheBlister" label="2. Blister" />
              </Grid>
              <Grid item xs={12} md={8} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishChePET" label="2.1 PET" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="frontFinishChePVC" label="PVC" />
              </Grid>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField
                  name="frontFinishCheWaterResistance"
                  label="3. Water Resistance"
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              item
              xs={6}
            >
              <Grid item xs={12}>
                <Typography>ด้านหลัง(Back)</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishLaminate" label="Laminate Film" />
              </Grid>
              <Grid item xs={12} md={9} />
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishLamAdhesive" label="1. Adhesive" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishLamHeatSeal" label="HeatSeal" />
              </Grid>
              <Grid item xs={12} md={5} />
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishLamMatt" label="2. Matt" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishLamGloss" label="Gloss" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishLamHalogram" label="Halogram" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishLamSoftTouch" label="SoftTouch" />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldFastField
                  name="backFinishLaminateDesc"
                  label="Laminate Film Desc."
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishLamWindow" label="3. Window" />
              </Grid>
              <Grid item xs={12} md={8} />
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishLamFullArea" label="4. Full Area" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishLamSpot" label="Spot" />
              </Grid>
              <Grid item xs={12} md={5} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishChemical" label="Chemical" />
              </Grid>
              <Grid item xs={12} md={9} />
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishCheOilBased" label="1. Oil Based" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishCheWaterBased" label="Water Based" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishCheUV" label="UV" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField
                  name="backFinishCheUVSilkscreen"
                  label="UV Silkscreen"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishCheVanish" label="Vanish" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishCheCalendering" label="Calendering" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishCheHighGloss" label="1.1 HighGloss" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishCheGloss" label="Gloss" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishCheMatt" label="Matt" />
              </Grid>
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishCheExtraMatt" label="Extra Matt" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishCheSoftTouch" label="Soft Touch" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishCheSatin" label="Satin" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishCheSpecial" label="Special" />
              </Grid>
              <Grid item xs={12} md={7}>
                <TextFieldFastField
                  name="backFinishCheSpecialData"
                  label="Special"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishCheFullArea" label="1.2 Full Area" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishCheSpot" label="Spot" />
              </Grid>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishCheBlister" label="2. Blister" />
              </Grid>
              <Grid item xs={12} md={8} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishChePET" label="2.1 PET" />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckBoxFastField name="backFinishChePVC" label="PVC" />
              </Grid>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <CheckBoxFastField
                  name="backFinishCheWaterResistance"
                  label="3. Water Resistance"
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              item
              xs={12}
            >
              <Grid item xs={12} md={2}>
                <Typography>Packing</Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="prodPackingPiece" label="ชิ้น(Piece)" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="prodPackingSheet" label="แผ่น(Sheet)" />
              </Grid>
              <Grid item xs={12} md={6} />
              <Grid item xs={12} md={2}>
                <Typography>งานปั้ม(Die Cut)</Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="dieCutHold" label="รู(Hold)" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="dieCutShape" label="ตามรูป(Shape)" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="dieCutEmboss" label="นูน(Emboss)" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="dieCutPerforate" label="ปั้มปรุ(Perforate)" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="dieCutFoldLine" label="ปั้มพับ(Fold Line)" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="dieCutPiece" label="ตัดชิ้น(Piece)" />
              </Grid>
              <Grid item xs={12} md={8} />
              <Grid item xs={12} md={2}>
                <Typography>Foil</Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField
                  name="foilHotStamping"
                  label="ปั้ม Foil(Hot Stamping)"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField
                  name="foilColdStamping"
                  label="ปั้ม Foil(Cold Stamping)"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextFieldFastField
                  name="foilColorData"
                  label="Foil สี"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentHlFinishing;
