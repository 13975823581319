import {
  Button,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { AppState } from '../../../state/configureStore';
import {
  EBlockArtTemplate,
  EBlockArtTemplateState,
  updateEBlockArtTemplate,
} from '../../../state/eBlockArtTemplate';
import { FixedPositionButtons, Loading, PageTitle } from '../index';

interface EBlockArtTemplateEditDialogProps {
  open: boolean;
  onClose: () => void;
}

const EBlockArtTemplateSchema = Yup.object().shape({
  artTemplateNumber: Yup.string(),
  isDefault: Yup.boolean(),
});

const defaultValues: EBlockArtTemplate = {};

const EBlockArtTemplateEditDialog: React.FC<EBlockArtTemplateEditDialogProps> = props => {
  const dispatch = useDispatch();
  const { open, onClose } = props;

  const { item: eBlockArtTemplate, loading } = useSelector<
    AppState,
    EBlockArtTemplateState
  >(s => s.eBlockArtTemplate);

  const handleSubmit = (values: EBlockArtTemplate) => {
    dispatch(updateEBlockArtTemplate(values));
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'} fullWidth>
      <DialogTitle>Edit EBlock Art Template</DialogTitle>
      <Loading isLoading={loading}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{ ...defaultValues, ...eBlockArtTemplate }}
          validationSchema={EBlockArtTemplateSchema}
        >
          {({ values, handleChange, errors, touched, submitForm, setFieldValue }) => {
            return (
              <Form>
                <CardContent>
                  <TextField
                    name="artTemplateNumber"
                    type="input"
                    label="ArtTemplateNumber"
                    variant="outlined"
                    margin="dense"
                    value={values.artTemplateNumber}
                    fullWidth
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="isDefault"
                        checked={values.isDefault}
                        color="primary"
                        onChange={e => setFieldValue('isDefault', e.target.checked)}
                      />
                    }
                    label="Is Default"
                    labelPlacement="end"
                  />
                </CardContent>
                <DialogActions>
                  <Button
                    variant={'contained'}
                    color="secondary"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    variant={'contained'}
                    color="primary"
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    SAVE
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Loading>
    </Dialog>
  );
};

export default EBlockArtTemplateEditDialog;
