import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createSalesTeam,
  fetchSalesTeam,
  fetchSalesTeamList,
  updateSalesTeam,
} from '../../services/salesTeamService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_SALESTEAM,
  FETCH_SALESTEAM,
  FETCH_SALESTEAM_LIST,
  UPDATE_SALESTEAM,
} from './salesTeam.actions';
import { SalesTeamCreateOrUpdatePayload } from './salesTeam.types';

const listPageUrl = `/settings/salesTeams`;
const formPageUrl = `/settings/salesTeams/form`;

function* fetchSalesTeamSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchSalesTeam, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchSalesTeamListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSalesTeamList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createSalesTeamSaga(action: AppAction) {
  try {
    const { values, history } = action.payload as SalesTeamCreateOrUpdatePayload;
    const successMessage = `Created ${values.name} SalesTeam successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createSalesTeam, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(`${formPageUrl}/${data.id}`);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateSalesTeamSaga(action: AppAction) {
  try {
    const { values, history } = action.payload as SalesTeamCreateOrUpdatePayload;
    const successMessage = `Updated ${values.name} SalesTeam successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateSalesTeam, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(listPageUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSalesTeam() {
  yield takeLatest(FETCH_SALESTEAM, fetchSalesTeamSaga);
  yield debounce(250, FETCH_SALESTEAM_LIST, fetchSalesTeamListSaga);
  yield takeLatest(CREATE_SALESTEAM, createSalesTeamSaga);
  yield takeLatest(UPDATE_SALESTEAM, updateSalesTeamSaga);
}
