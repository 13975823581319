import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      marginTop: '0.5rem',
      color: 'red',
    },
    dialog: {
      // minHeight: '53vh',
    },
  }),
);

export default useStyles;
