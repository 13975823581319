import { AppAction, PageFilter } from '../app';
import {
  Customer,
  CustomerCreateOrUpdatePayload,
  CustomerListFilter,
} from './customer.types';

export const FETCH_CUSTOMER = 'FETCH_CUSTOMER';
export const FETCH_CUSTOMER_LIST = 'FETCH_CUSTOMER_LIST';
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER';

export const fetchCustomer = (id: Customer['id']): AppAction => ({
  type: FETCH_CUSTOMER,
  payload: id,
});

export const fetchCustomerList = (
  filters: CustomerListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_CUSTOMER_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createCustomer = (payload: CustomerCreateOrUpdatePayload): AppAction => ({
  type: CREATE_CUSTOMER,
  payload,
});

export const updateCustomer = (payload: CustomerCreateOrUpdatePayload): AppAction => ({
  type: UPDATE_CUSTOMER,
  payload,
});

export const clearCustomer = () => ({
  type: CLEAR_CUSTOMER,
});


