import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_CUSTOMERGROUP,
  CREATE_CUSTOMERGROUP,
  FETCH_CUSTOMERGROUP,
  FETCH_CUSTOMERGROUP_LIST,
  UPDATE_CUSTOMERGROUP,
} from './customerGroup.actions';
import { CustomerGroup, CustomerGroupState } from './customerGroup.types';

const getInitialCustomerGroupState = ():CustomerGroup  => ({
});

const initialState: CustomerGroupState = {
  errorMessage: '',
  item: getInitialCustomerGroupState(),
  items: [],
  loading: false,
  totalItems: 0,
};

export default (state: CustomerGroupState = initialState, action: AppAction): CustomerGroupState => {
  switch (action.type) {
    case REQUEST(FETCH_CUSTOMERGROUP_LIST):
    case REQUEST(CREATE_CUSTOMERGROUP):
    case REQUEST(FETCH_CUSTOMERGROUP):
    case REQUEST(UPDATE_CUSTOMERGROUP):
      return { ...state, loading: true };
    case SUCCESS(FETCH_CUSTOMERGROUP_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_CUSTOMERGROUP_LIST):
    case FAILURE(CREATE_CUSTOMERGROUP):
    case FAILURE(FETCH_CUSTOMERGROUP):
    case FAILURE(UPDATE_CUSTOMERGROUP):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_CUSTOMERGROUP):
    case SUCCESS(FETCH_CUSTOMERGROUP):
    case SUCCESS(UPDATE_CUSTOMERGROUP):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_CUSTOMERGROUP:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
