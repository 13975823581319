import { AppAction, PageFilter } from '../../../../state/app';
import { SubtaskListFilter } from '../../../../state/subtask';

export const FETCH_PENDING_SUBTASK_LIST = 'FETCH_PENDING_SUBTASK_LIST';
export const CLEAR_PENDING_SUBTASK_LIST = 'CLEAR_PENDING_SUBTASK_LIST';

export const fetchPendingSubtaskList = (paging: PageFilter): AppAction => {
  return {
    type: FETCH_PENDING_SUBTASK_LIST,
    payload: {
      paging,
    },
  };
};

export const clearPendingSubtaskList = () => {
  return {
    type: CLEAR_PENDING_SUBTASK_LIST,
  };
};
