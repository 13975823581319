import { AppAction, PageFilter } from '../app';

export const FETCH_ROLE_LIST = 'FETCH_ROLE_LIST';

export const fetchRoleList = (filters: unknown, paging: PageFilter): AppAction => {
  return {
    type: FETCH_ROLE_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchAllRoleList = (): AppAction => {
  return fetchRoleList(
    {},
    {
      pageNumber: 0,
      pageSize: 100,
    },
  );
};
