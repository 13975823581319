import { PageFilter } from '../state/app';
import {
  SampleDevelopmentPlApprovalRequest,
  SampleDevelopmentPlApprovalRequestListFilter,
} from '../state/sampleDevelopmentPlApprovalRequest/sampleDevelopmentPlApprovalRequest.types';
import axios from '../utils/axios';
const baseUrl = `/api/sampleDevelopmentPlApprovalRequests`;

export const fetchSampleDevelopmentPlApprovalRequestList = async ({
  filters,
  paging,
}: {
  filters: SampleDevelopmentPlApprovalRequestListFilter;
  paging: PageFilter;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const updateSampleDevelopmentPlApprovalRequesResponse = async (
  sampleDevelopmentPlApprovalRequest: SampleDevelopmentPlApprovalRequest,
) => {
  const requestUrl = `${baseUrl}/response`;
  return await axios.put(requestUrl, sampleDevelopmentPlApprovalRequest);
};
