import {
  AppBar,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close as CloseIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IProgramApprovalRequestReport } from '../../../../models/report.model';
import { PageFilter } from '../../../../state/app';
import { AppState } from '../../../../state/configureStore';
import {
  clearReport,
  fetchProgramApprovalRequestReportList,
  IProgramApprovalRequestReportFilter,
  ReportState,
} from '../../../../state/report';
import {
  DateTimeDisplay,
  PageContainer,
  PageTitle,
  TableLoading,
} from '../../../components';
import { useFilter } from '../../../hooks';
import ProgramApprovalRequestReportFilter from './ProgramApprovalRequestReportFilter';
import useStyles from './programApprovalRequestReportStyle';
import { downloadProgramApprovalRequestReportList } from '../../../../services/reportService';

interface IProgramApprovalRequestReportDialog {
  open: boolean;
  handleClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

const ProgramApprovalRequestReportDialog: React.FC<
  IProgramApprovalRequestReportDialog
> = (props) => {
  const classes = useStyles();
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    loading,
    items: programApprovalRequestReportList,
    totalItems,
  } = useSelector<AppState, ReportState>((state) => state.report);

  const initFilter: IProgramApprovalRequestReportFilter = {};

  const [filters, setFilters] = useFilter<IProgramApprovalRequestReportFilter>(
    initFilter,
    true,
  );

  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  useEffect(() => {
    if (open) {
      dispatch(fetchProgramApprovalRequestReportList(filters, { ...paging }));
    }
    return function cleanUp() {
      dispatch(clearReport());
    };
  }, [dispatch, paging, filters, open]);

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const handleChangeFilter = (newFilter: IProgramApprovalRequestReportFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const exportExcel = () => {
    downloadProgramApprovalRequestReportList(filters, {
      pageSize: totalItems,
    }).catch((err) => console.error(err));
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Program Approval Request Report
          </Typography>
        </Toolbar>
      </AppBar>
      <PageContainer>
        <PageTitle>Program Approval Request Report</PageTitle>
        <ProgramApprovalRequestReportFilter
          filters={filters}
          onChangeFilter={handleChangeFilter}
          initialFilter={initFilter}
        />
        {!isSmallScreen && (
          <div className={classes.filterContainer}>
            <Button
              variant={'contained'}
              className={classes.button}
              onClick={exportExcel}
            >
              Export Excel
            </Button>
          </div>
        )}
        {open && (
          <Grid container spacing={3} className={classes.container}>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>Request Number</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Requested By</TableCell>
                    <TableCell>Requested Date</TableCell>
                    <TableCell>Responded</TableCell>
                    <TableCell>Responded Date</TableCell>
                    <TableCell>Program</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableLoading isLoading={loading} colSpan={9}></TableLoading>
                  {programApprovalRequestReportList.map(
                    (item: IProgramApprovalRequestReport, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {index + paging.pageSize * paging.pageNumber + 1}
                          </TableCell>
                          <TableCell>{item.requestNumber}</TableCell>
                          <TableCell>{item.description}</TableCell>
                          <TableCell>{item.status}</TableCell>
                          <TableCell>{item.requestedBy}</TableCell>
                          <TableCell>
                            <DateTimeDisplay value={item.requestedDate} />
                          </TableCell>
                          <TableCell>{item.respondedBy}</TableCell>
                          <TableCell>
                            <DateTimeDisplay value={item.respondedDate} />
                          </TableCell>
                          <TableCell>{item.name}</TableCell>
                        </TableRow>
                      );
                    },
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[15, 25, 35, 500]}
                component="div"
                count={totalItems}
                rowsPerPage={paging.pageSize}
                page={paging.pageNumber}
                onPageChange={handleChangePageNumber}
                onRowsPerPageChange={handleChangePageSize}
              />
            </Grid>
          </Grid>
        )}
      </PageContainer>
    </Dialog>
  );
};

export default ProgramApprovalRequestReportDialog;
