import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_WORKING_GROUP,
  CREATE_WORKING_GROUP,
  FETCH_WORKING_GROUP,
  FETCH_WORKING_GROUP_LIST,
  UPDATE_WORKING_GROUP,
} from './workingGroup.actions';
import { WorkingGroup, WorkingGroupState } from './workingGroup.type';

const initialState: WorkingGroupState = {
  errorMessage: '',
  item: {} as Readonly<WorkingGroup>,
  items: [] as ReadonlyArray<WorkingGroup>,
  loading: false,
  totalItems: 0,
};

export default (
  state: WorkingGroupState = initialState,
  action: AppAction,
): WorkingGroupState => {
  switch (action.type) {
    case REQUEST(FETCH_WORKING_GROUP_LIST):
    case REQUEST(CREATE_WORKING_GROUP):
    case REQUEST(FETCH_WORKING_GROUP):
    case REQUEST(UPDATE_WORKING_GROUP):
      return { ...state, loading: true };
    case SUCCESS(FETCH_WORKING_GROUP_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_WORKING_GROUP_LIST):
    case FAILURE(CREATE_WORKING_GROUP):
    case FAILURE(FETCH_WORKING_GROUP):
    case FAILURE(UPDATE_WORKING_GROUP):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_WORKING_GROUP):
    case SUCCESS(FETCH_WORKING_GROUP):
    case SUCCESS(UPDATE_WORKING_GROUP):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_WORKING_GROUP:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
