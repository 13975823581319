import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { OpportunityStatus } from '../../../constants';
import { OpportunityStatusType } from '../../../constants/opportunityStatus';
import { IFindAllOpportunityApprovalRequest } from '../../../models/findAllOpportunityApprovalRequest.model';
import { IOpportunity } from '../../../models/opportunity.model';
import { AppState } from '../../../state/configureStore';
import {
  OpportunityApprovalRequest,
  OpportunityApprovalRequestState,
} from '../../../state/opportunityApprovalRequest';
import { OpportunityForm } from '../opportunity';
import ConfirmDialog from './ConfirmDialog';
import useStyles from './OpportunityApprovalRequestDialog.style';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

interface OpportunityApprovalRequestDialogProps {
  handleClose: () => void;
  opportunity: IOpportunity;
  opportunityApprovalRequest: IFindAllOpportunityApprovalRequest;
}

export const OpportunityApprovalRequestDialog: React.FC<
  OpportunityApprovalRequestDialogProps
> = (props) => {
  const { handleClose, opportunity, opportunityApprovalRequest } = props;
  const { openDialog } = useSelector<AppState, OpportunityApprovalRequestState>(
    ({ opportunityApprovalRequest }) => opportunityApprovalRequest,
  );
  const [confirmDialog, setConfirmDialog] = useState<{
    open: boolean;
    status: OpportunityStatusType;
  }>({ open: false, status: OpportunityStatus.REJECTED });

  const classes = useStyles(props);
  const handleCloseConfirmDialog = () => {
    setConfirmDialog({ ...confirmDialog, open: false });
  };
  return (
    <>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Assignment Approval
            </Typography>
            <div className={classes.approveButtonGroup}>
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: '#7cb342' }}
                onClick={() =>
                  setConfirmDialog({ open: true, status: OpportunityStatus.APPROVED })
                }
              >
                Approve
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() =>
                  setConfirmDialog({ open: true, status: OpportunityStatus.REJECTED })
                }
              >
                Reject
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <OpportunityForm opportunityId={opportunity.id} />
      </Dialog>
      <ConfirmDialog
        open={confirmDialog.open}
        handleClose={handleCloseConfirmDialog}
        responseResult={confirmDialog.status}
        opportunityApprovalRequest={opportunityApprovalRequest}
      />
    </>
  );
};
