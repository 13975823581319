import queryString from 'query-string';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import history from '../../history';

function useFilter<T>(
  initFilter: T,
  ignoreFilterOnUrl: boolean = false,
): [T, Dispatch<SetStateAction<T>>] {
  const { 'sort.orders': _, ...filterParameterObj } = getQueryParameterObj();
  const initValue = { ...initFilter, ...filterParameterObj };
  const [filter, setFilter] = useState<T>(initValue);
  useEffect(() => {
    if (!ignoreFilterOnUrl) {
      history.replace(`${history.location.pathname}?${buildUrlWithFilterParams(filter)}`);
    }
  }, [filter, ignoreFilterOnUrl]);
  return [filter, setFilter];
}

const getQueryParameterObj = () => queryString.parse(history.location.search);

const buildUrlWithFilterParams = (filters: any): URLSearchParams => {
  const { 'sort.orders': sortOrders } = getQueryParameterObj();

  const params = new URLSearchParams();
  if (sortOrders) {
    params.append('sort.orders', sortOrders as string);
  }
  Object.entries(filters).forEach(([key, value]) => {
    if (typeof value === 'string' && value.length > 0) {
      params.append(key, value);
    } else if (value) {
      params.append(key, String(value));
    }
  });
  return params;
};

export default useFilter;
