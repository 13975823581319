import { AxiosResponse } from 'axios';
import { IUserRole } from '../models/userRole.model';
import { PageFilter } from '../state/app';
import { User, UserListFilter } from '../state/user';
import axios from '../utils/axios';

const baseUrl = `/api/users`;

export const fetchUser = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const fetchUserList = async ({
  filters,
  paging,
}: {
  filters: UserListFilter;
  paging: PageFilter;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchUserInfo = async (): Promise<AxiosResponse<User>> => {
  const requestUrl = `${baseUrl}/me`;
  return await axios.get(requestUrl);
};

export const updateUserRole = async (userRole: IUserRole) => {
  const requestUrl = `${baseUrl}/role`;
  return await axios.put(requestUrl, userRole);
};
