import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ProjectStatus } from '../../../constants';
import { ProjectStatusType } from '../../../constants/projectStatus';
import { IProject } from '../../../models/project.model';
import { IProjectApprovalRequest } from '../../../models/projectApprovalRequest.model';
import { AppState } from '../../../state/configureStore';
import {
  ProjectApprovalRequest,
  ProjectApprovalRequestState,
} from '../../../state/projectApprovalRequest';
import { ProjectForm } from '../project';
import ConfirmDialog from './ConfirmDialog';
import useStyles from './ProjectApprovalRequestDialog.style';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

interface ProjectApprovalRequestDialogProps {
  handleClose: () => void;
  project: IProject;
  projectApprovalRequest: IProjectApprovalRequest;
}

export const ProjectApprovalRequestDialog: React.FC<ProjectApprovalRequestDialogProps> = (
  props,
) => {
  const { handleClose, project, projectApprovalRequest } = props;
  const { openDialog } = useSelector<AppState, ProjectApprovalRequestState>(
    ({ projectApprovalRequest }) => projectApprovalRequest,
  );
  const [confirmDialog, setConfirmDialog] = useState<{
    open: boolean;
    status: ProjectStatusType;
  }>({ open: false, status: ProjectStatus.REJECTED });

  const classes = useStyles(props);
  const handleCloseConfirmDialog = () => {
    setConfirmDialog({ ...confirmDialog, open: false });
  };
  return (
    <>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Project Approval
            </Typography>
            <div className={classes.approveButtonGroup}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  setConfirmDialog({ open: true, status: ProjectStatus.APPROVED })
                }
              >
                Approve
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() =>
                  setConfirmDialog({ open: true, status: ProjectStatus.REJECTED })
                }
              >
                Reject
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <ProjectForm smProjectId={project.id} />
      </Dialog>
      <ConfirmDialog
        open={confirmDialog.open}
        handleClose={handleCloseConfirmDialog}
        responseResult={confirmDialog.status}
        projectApprovalRequest={projectApprovalRequest}
      />
    </>
  );
};
