import { ITaskActivity } from '../../models/taskActivity.model';
import { AppAction, PageFilter } from '../app';
import {
  TaskActivityCreateOrUpdatePayload,
  TaskActivityListFilter,
} from './taskActivity.types';

export const FETCH_TASK_ACTIVITY = 'FETCH_TASK_ACTIVITY';
export const FETCH_TASK_ACTIVITY_LIST = 'FETCH_TASK_ACTIVITY_LIST';
export const CREATE_TASK_ACTIVITY = 'CREATE_TASK_ACTIVITY';
export const UPDATE_TASK_ACTIVITY = 'UPDATE_TASK_ACTIVITY';
export const CLEAR_TASK_ACTIVITY = 'CLEAR_TASK_ACTIVITY';
export const CREATE_TASK_ACTIVITY_AND_FETCH_LIST = 'CREATE_TASK_ACTIVITY_AND_FETCH_LIST';

export const fetchTaskActivity = (id: ITaskActivity['id']): AppAction => ({
  type: FETCH_TASK_ACTIVITY,
  payload: id,
});

export const fetchTaskActivityList = (
  filters: TaskActivityListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_TASK_ACTIVITY_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchTaskActivityListByTaskId = (taskId: number): AppAction => {
  return {
    type: FETCH_TASK_ACTIVITY_LIST,
    payload: {
      filters: { 'SMTaskId.EqualsTo': taskId },
      paging: {
        pageSize: 9999,
        pageNumber: 0,
        sort: { orderBy: 'createdAt', direction: 'desc' },
      },
    },
  };
};

export const fetchTaskActivityListBySubtaskId = (subtaskId: number): AppAction => {
  return {
    type: FETCH_TASK_ACTIVITY_LIST,
    payload: {
      filters: { 'SMSubtaskId.EqualsTo': subtaskId },
      paging: {
        pageSize: 9999,
        pageNumber: 0,
        sort: { orderBy: 'createdAt', direction: 'desc' },
      },
    },
  };
};

export const createTaskActivity = (payload: ITaskActivity): AppAction => ({
  type: CREATE_TASK_ACTIVITY,
  payload,
});

export const createTaskActivityAndFetchList = (payload: ITaskActivity): AppAction => ({
  type: CREATE_TASK_ACTIVITY_AND_FETCH_LIST,
  payload,
});

export const updateTaskActivity = (
  payload: TaskActivityCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_TASK_ACTIVITY,
  payload,
});

export const clearTaskActivity = () => ({
  type: CLEAR_TASK_ACTIVITY,
});
