import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SampleDevelopmentStatusLabel } from '../../../../constants/sampleDevelopmentStatus';
import { ISampleDevelopmentWl } from '../../../../models/sampleDevelopmentWl.model';
import { PageFilter } from '../../../../state/app';
import { AppState } from '../../../../state/configureStore';
import {
  closeSampleDevelopmentWlApprovalRequestDialog,
  openSampleDevelopmentWlApprovalRequestDialog,
  SampleDevelopmentWlApprovalRequest,
  SampleDevelopmentWlApprovalRequestState,
} from '../../../../state/sampleDevelopmentWlApprovalRequest';
import { DateTimeDisplay, TableLoading } from '../../../components';
import SampleDevelopmentWlApprovalRequestDialog from '../../sampleDevelopmentWlApprovalRequest/SampleDevelopmentWlApprovalRequestDialog';
import { fetchPendingApprovalSampleDevWlList } from './pendingApprovalSampleDevWlRequestList.actions';
import { PendingApprovalSampleDevWlRequestListState } from './pendingApprovalSampleDevWlRequestList.reducer';
import { useLocation } from 'react-router-dom';
import history from '../../../../history';

const PendingApprovalSampleDevWlRequestList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const sampleDevNumber = queryString.get('sampleDevNumber');
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const {
    loading,
    totalItems,
    items: pendingApprovalSampleDevWlRequestList,
  } = useSelector<AppState, PendingApprovalSampleDevWlRequestListState>(
    (state) => state.pendingApprovalSampleDevWlRequestList,
  );

  const { openDialog } = useSelector<AppState, SampleDevelopmentWlApprovalRequestState>(
    (state) => state.sampleDevelopmentWlApprovalRequest,
  );

  const [sampleDevWlAppRequestDlg, setsampleDevWlAppRequestDlg] = useState({
    sampleDevelopmentWl: {} as ISampleDevelopmentWl,
    sampleDevelopmentWlApprovalRequest: {} as SampleDevelopmentWlApprovalRequest,
  });

  useEffect(() => {
    if (!openDialog) {
      dispatch(fetchPendingApprovalSampleDevWlList(paging));
    }
  }, [dispatch, paging, openDialog]);

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const handleCloseSampleDevWlAppRequestDlg = () => {
    dispatch(closeSampleDevelopmentWlApprovalRequestDialog());
    history.replace(`${history.location.pathname}`);
  };

  const editSampleDevWl = useCallback(
    (sampleDevWlAppRequest: SampleDevelopmentWlApprovalRequest): void => {
      setsampleDevWlAppRequestDlg({
        sampleDevelopmentWl: sampleDevWlAppRequest.sampleDevelopmentWl!,
        sampleDevelopmentWlApprovalRequest: sampleDevWlAppRequest,
      });
      dispatch(openSampleDevelopmentWlApprovalRequestDialog());
    },
    [dispatch],
  );

  useEffect(() => {
    const sampleDevWlPrefix = 'SPWL';
    if (sampleDevNumber && sampleDevNumber.startsWith(sampleDevWlPrefix)) {
      const selectedPendingApprovalSampleDevWlRequest =
        pendingApprovalSampleDevWlRequestList.filter(
          (pendingApprovalSampleDevWlRequest) =>
            pendingApprovalSampleDevWlRequest.sampleDevelopmentWl!.sampleDevNumber ===
            sampleDevNumber,
        );
      if (selectedPendingApprovalSampleDevWlRequest.length > 0) {
        editSampleDevWl(selectedPendingApprovalSampleDevWlRequest[0]);
      }
    }
  }, [sampleDevNumber, editSampleDevWl, pendingApprovalSampleDevWlRequestList]);

  return (
    <>
      <Typography variant="h6">Pending Approval Sample Development WL</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Sample Dev. Number</TableCell>
            <TableCell>Program</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Block</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={10}>
            {pendingApprovalSampleDevWlRequestList.map(
              (pendingApprovalSampleDevWl, index) => {
                return (
                  <TableRow key={pendingApprovalSampleDevWl.id}>
                    <TableCell>
                      {index + paging.pageSize * paging.pageNumber + 1}
                    </TableCell>
                    <TableCell>
                      {pendingApprovalSampleDevWl!.sampleDevelopmentWl!.sampleDevNumber}
                    </TableCell>
                    <TableCell>
                      {
                        pendingApprovalSampleDevWl!.sampleDevelopmentWl!.block!.program!
                          .name
                      }
                    </TableCell>
                    <TableCell>
                      {
                        pendingApprovalSampleDevWl!.sampleDevelopmentWl!.block!.brand!
                          .name
                      }
                    </TableCell>
                    <TableCell>
                      {pendingApprovalSampleDevWl!.sampleDevelopmentWl!.block!.item}
                    </TableCell>
                    <TableCell>
                      {pendingApprovalSampleDevWl!.sampleDevelopmentWl!.block!.blockNo}
                    </TableCell>
                    <TableCell>
                      {
                        SampleDevelopmentStatusLabel[
                          pendingApprovalSampleDevWl!.sampleDevelopmentWl!.status!
                        ]
                      }
                    </TableCell>
                    <TableCell>
                      <DateTimeDisplay
                        value={pendingApprovalSampleDevWl!.sampleDevelopmentWl!.createdAt}
                      />
                    </TableCell>
                    <TableCell>
                      {pendingApprovalSampleDevWl!.sampleDevelopmentWl!.createdBy}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={() => editSampleDevWl(pendingApprovalSampleDevWl)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              },
            )}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
      <SampleDevelopmentWlApprovalRequestDialog
        handleClose={handleCloseSampleDevWlAppRequestDlg}
        sampleDevelopmentWl={sampleDevWlAppRequestDlg.sampleDevelopmentWl}
        sampleDevelopmentWlApprovalRequest={
          sampleDevWlAppRequestDlg.sampleDevelopmentWlApprovalRequest
        }
      />
    </>
  );
};

export default PendingApprovalSampleDevWlRequestList;
