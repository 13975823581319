export const FETCH_OPPORTUNITY_LOG = 'FETCH_OPPORTUNITY_LOG';
export const CLEAR_OPPORTUNITY_LOG = 'CLEAR_OPPORTUNITY_LOG';

export const fetchOpportunityLog = (opportunityId: number) => {
  return {
    type: FETCH_OPPORTUNITY_LOG,
    payload: opportunityId,
  };
};

export const clearOpportunityLog = () => {
  return {
    type: CLEAR_OPPORTUNITY_LOG,
  };
};
