import {
  ChangeLogProjectState,
  initChangeLogProjectState,
} from './changeLogProject.types';
import { AppAction } from '../../state/app/app.types';
import * as actions from './changeLogProject.actions';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';

const reducer = (
  state: ChangeLogProjectState = initChangeLogProjectState(),
  action: AppAction,
): ChangeLogProjectState => {
  switch (action.type) {
    case REQUEST(actions.FETCH_PROJECT_LOG):
      return {
        ...state,
        loading: true,
      };
    case SUCCESS(actions.FETCH_PROJECT_LOG):
      return {
        ...state,
        project: action.payload.data,
        loading: false,
      };
    case FAILURE(actions.FETCH_PROJECT_LOG):
      return {
        ...state,
        loading: false,
      };
    case actions.CLEAR_PROJECT_LOG:
      return initChangeLogProjectState();
    default:
      return state;
  }
};

export default reducer;
