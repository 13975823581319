import { ISampleDevelopmentWl } from '../../models/sampleDevelopmentWl.model';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  FETCH_SAMPLE_DEVELOPMENT_WL_LIST,
  FETCH_SAMPLE_DEVELOPMENT_WL,
  CREATE_SAMPLE_DEVELOPMENT_WL,
  UPDATE_SAMPLE_DEVELOPMENT_WL,
  CLEAR_SAMPLE_DEVELOPMENT_WL,
  SUBMIT_SAMPLE_DEVELOPMENT_WL_FOR_APPROVAL,
} from './sampleDevelopmentWl.actions';
import { SampleDevelopmentWlState } from './sampleDevelopmentWl.types';

const initialState: SampleDevelopmentWlState = {
  errorMessage: '',
  item: {} as Readonly<ISampleDevelopmentWl>,
  items: [] as ISampleDevelopmentWl[],
  loading: false,
  totalItems: 0,
};

export default (
  state: SampleDevelopmentWlState = initialState,
  action: AppAction,
): SampleDevelopmentWlState => {
  switch (action.type) {
    case REQUEST(FETCH_SAMPLE_DEVELOPMENT_WL_LIST):
    case REQUEST(FETCH_SAMPLE_DEVELOPMENT_WL):
    case REQUEST(CREATE_SAMPLE_DEVELOPMENT_WL):
    case REQUEST(UPDATE_SAMPLE_DEVELOPMENT_WL):
      return { ...state, loading: true };
    case SUCCESS(FETCH_SAMPLE_DEVELOPMENT_WL_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_SAMPLE_DEVELOPMENT_WL_LIST):
    case FAILURE(FETCH_SAMPLE_DEVELOPMENT_WL):
    case FAILURE(CREATE_SAMPLE_DEVELOPMENT_WL):
    case FAILURE(UPDATE_SAMPLE_DEVELOPMENT_WL):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(UPDATE_SAMPLE_DEVELOPMENT_WL):
    case SUCCESS(FETCH_SAMPLE_DEVELOPMENT_WL):
    case SUCCESS(CREATE_SAMPLE_DEVELOPMENT_WL):
    case SUCCESS(SUBMIT_SAMPLE_DEVELOPMENT_WL_FOR_APPROVAL):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_SAMPLE_DEVELOPMENT_WL:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
