import React from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { FilterContainer, SearchDropdownField, SearchField } from '../../components';
import { ProgramAndBrandRequestStatus, ProgramAndBrandRequestStatusLabel } from '../../../constants';
import { ProgramAndBrandRequestListFilter } from '../../../state/programAndBrandRequest';
import { IFilterContainerConfigProps } from '../../components/FilterContainer';

const ProgramAndBrandRequestFilter: React.FC<IFilterContainerConfigProps<ProgramAndBrandRequestListFilter>> = props => {
  return (
    <FilterContainer
      {...props}
      render={(filterProps) => {
        const { localFilter, handleChangeFilter } = filterProps;
        return <Grid container spacing={1} alignItems={'center'}>
          <Grid item xs={12} md={3} lg={2}>
            <SearchField
              fullWidth={true}
              label="Request Number"
              filterKey={'RequestNumber.startsWith'}
              filters={localFilter}
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <SearchField
              fullWidth={true}
              label="Description"
              filterKey={'Description.startsWith'}
              filters={localFilter}
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <SearchField
              fullWidth={true}
              label="Requested By"
              filterKey={'RequestedByUser.FullNameWithUserName.contains'}
              filters={localFilter}
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <SearchDropdownField
              label="Status"
              filterKey={'Status.equalsTo'}
              filters={localFilter}
              onChange={handleChangeFilter}
              id="status"
            >
              <MenuItem value={''}>All</MenuItem>
              {Object.entries(ProgramAndBrandRequestStatus).map(([key, value]) => {
                return <MenuItem key={key} value={value}>{ProgramAndBrandRequestStatusLabel[value]}</MenuItem>;
              })}
            </SearchDropdownField>
          </Grid>
        </Grid>;
      }}
    />
  );
};

export default ProgramAndBrandRequestFilter;

