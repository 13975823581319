import { ISampleDevelopmentNl } from '../../models/sampleDevelopmentNl.model';
import { AppAction, PageFilter } from '../app';
import { SampleDevelopmentNlListFilter } from './sampleDevelopmentNl.types';

export const FETCH_SAMPLE_DEVELOPMENT_NL_LIST = 'FETCH_SAMPLE_DEVELOPMENT_NL_LIST';
export const FETCH_SAMPLE_DEVELOPMENT_NL = 'FETCH_SAMPLE_DEVELOPMENT_NL';
export const CREATE_SAMPLE_DEVELOPMENT_NL = 'CREATE_SAMPLE_DEVELOPMENT_NL';
export const UPDATE_SAMPLE_DEVELOPMENT_NL = 'UPDATE_SAMPLE_DEVELOPMENT_NL';
export const CLEAR_SAMPLE_DEVELOPMENT_NL = 'CLEAR_SAMPLE_DEVELOPMENT_NL';
export const SUBMIT_SAMPLE_DEVELOPMENT_NL_FOR_APPROVAL =
  'SUBMIT_SAMPLE_DEVELOPMENT_NL_FOR_APPROVAL';
export const DOWNLOAD_SAMPLE_DEVELOPMENT_NL = 'DOWNLOAD_SAMPLE_DEVELOPMENT_NL';

export const fetchSampleDevelopmentNlList = (
  filters: SampleDevelopmentNlListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SAMPLE_DEVELOPMENT_NL_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const downloadSampleDevelopmentNl = (id: number): AppAction => {
  return {
    type: DOWNLOAD_SAMPLE_DEVELOPMENT_NL,
    payload: id,
  };
};

export const fetchSampleDevelopmentNl = (id: number): AppAction => ({
  type: FETCH_SAMPLE_DEVELOPMENT_NL,
  payload: id,
});

export const createSampleDevelopmentNl = (payload: {
  values: ISampleDevelopmentNl;
}): AppAction => ({
  type: CREATE_SAMPLE_DEVELOPMENT_NL,
  payload,
});

export const updateSampleDevelopmentNl = (payload: {
  values: ISampleDevelopmentNl;
}): AppAction => ({
  type: UPDATE_SAMPLE_DEVELOPMENT_NL,
  payload,
});

export const clearSampleDevelopmentNl = () => ({
  type: CLEAR_SAMPLE_DEVELOPMENT_NL,
});

export const submitSampleDevelopmentNlForApproval = (id: number): AppAction => ({
  type: SUBMIT_SAMPLE_DEVELOPMENT_NL_FOR_APPROVAL,
  payload: id,
});
