import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IForecast } from '../../../models/forecast.model';
import { ISubtaskItem } from '../../../models/subtaskItem.model';
import { ISubtaskItemForecast } from '../../../models/subtaskItemForecast.model';
import { AppState } from '../../../state/configureStore';
import {
  createSubtaskItemForecastList,
  fetchSubtaskItemForecastList,
  SubtaskItemForecastState,
} from '../../../state/subtaskItemForecast';
import ForecastTable from './ForecastTable';

interface SubtaskItemForecastTableProps {
  subtaskItem: ISubtaskItem;
}

const SubtaskItemForecastTable: React.FC<SubtaskItemForecastTableProps> = props => {
  const { subtaskItem } = props;
  const dispatch = useDispatch();
  const { items: forecastList } = useSelector<AppState, SubtaskItemForecastState>(
    state => state.subtaskItemForecast,
  );

  const defaultNewRowValue = useMemo<ISubtaskItemForecast>(() => {
    return {
      smSubtaskItemId: subtaskItem.id!,
      quantity: '',
      unitOfQuantity: '',
      frequency: '',
    };
  }, [subtaskItem]);

  const handleUpdate = (forecasts: IForecast[]) => {
    dispatch(createSubtaskItemForecastList(subtaskItem.id!, forecasts));
  };

  useEffect(() => {
    dispatch(
      fetchSubtaskItemForecastList(
        { 'SmSubtaskItemId.EqualsTo': subtaskItem.id! },
        { pageNumber: 0, pageSize: 99 },
      ),
    );
  }, [subtaskItem, dispatch]);

  return (
    <ForecastTable
      blockNo={subtaskItem.smTaskItem!.opportunityItem!.block!.blockNo!}
      defaultNewRowValue={defaultNewRowValue}
      onUpdate={handleUpdate}
      forecastList={forecastList}
    />
  );
};

export default SubtaskItemForecastTable;
