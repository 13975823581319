import { ISubstrate } from '../../models/substrate.model';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import { CLEAR_SUBSTRATE, FETCH_SUBSTRATE_LIST } from './substrate.actions';
import { SubstrateState } from './substrate.types';

const initialState: SubstrateState = {
  errorMessage: '',
  item: {} as Readonly<ISubstrate>,
  items: [] as ISubstrate[],
  loading: false,
  totalItems: 0,
};

export default (
  state: SubstrateState = initialState,
  action: AppAction,
): SubstrateState => {
  switch (action.type) {
    case REQUEST(FETCH_SUBSTRATE_LIST):
      return { ...state, loading: true };
    case SUCCESS(FETCH_SUBSTRATE_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_SUBSTRATE_LIST):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case CLEAR_SUBSTRATE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
