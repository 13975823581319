import { AppAction, PageFilter, AppCallback } from '../app';
import {
  OpportunityCustomerGroup,
  OpportunityCustomerGroupCreateOrUpdatePayload,
  OpportunityCustomerGroupListFilter,
} from './opportunityCustomerGroup.types';

export const FETCH_OPPORTUNITYCUSTOMERGROUP = 'FETCH_OPPORTUNITYCUSTOMERGROUP';
export const FETCH_OPPORTUNITYCUSTOMERGROUP_LIST = 'FETCH_OPPORTUNITYCUSTOMERGROUP_LIST';
export const CREATE_OPPORTUNITYCUSTOMERGROUP = 'CREATE_OPPORTUNITYCUSTOMERGROUP';
export const UPDATE_OPPORTUNITYCUSTOMERGROUP = 'UPDATE_OPPORTUNITYCUSTOMERGROUP';
export const CLEAR_OPPORTUNITYCUSTOMERGROUP = 'CLEAR_OPPORTUNITYCUSTOMERGROUP';
export const DELETE_OPPORTUNITYCUSTOMERGROUP = 'DELETE_OPPORTUNITYCUSTOMERGROUP';
export const CREATE_OPPORTUNITYCUSTOMERGROUPS = 'CREATE_OPPORTUNITYCUSTOMERGROUPS';

export const fetchOpportunityCustomerGroup = (
  id: OpportunityCustomerGroup['id'],
): AppAction => ({
  type: FETCH_OPPORTUNITYCUSTOMERGROUP,
  payload: id,
});

export const fetchOpportunityCustomerGroupList = (
  filters: OpportunityCustomerGroupListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_OPPORTUNITYCUSTOMERGROUP_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchOpportunityCustomerGroupListByOpportunityId = (
  opportunityId: number,
): AppAction => ({
  type: FETCH_OPPORTUNITYCUSTOMERGROUP_LIST,
  payload: {
    filters: { 'OpportunityId.equalsTo': opportunityId },
    paging: { pageNumber: 0, pageSize: 99999 },
  },
});

export const createOpportunityCustomerGroup = (
  payload: OpportunityCustomerGroupCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_OPPORTUNITYCUSTOMERGROUP,
  payload,
});

export const createOpportunityCustomerGroups = (
  payload: any,
  callback?: AppCallback,
): AppAction => ({
  type: CREATE_OPPORTUNITYCUSTOMERGROUPS,
  payload,
  callback,
});

export const updateOpportunityCustomerGroup = (
  payload: OpportunityCustomerGroupCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_OPPORTUNITYCUSTOMERGROUP,
  payload,
});

export const clearOpportunityCustomerGroup = () => ({
  type: CLEAR_OPPORTUNITYCUSTOMERGROUP,
});

export const deleteOpportunityCustomerGroup = (payload: { id: number }) => ({
  type: DELETE_OPPORTUNITYCUSTOMERGROUP,
  payload,
});
