import { CardContent, Grid, Paper, Typography, Box } from '@material-ui/core';
import React from 'react';
import { CheckBoxFastField, TextFieldFastField } from '../../../components';

interface SampleDevelopmentPlJobProps {}

const SampleDevelopmentPlJob: React.FC<SampleDevelopmentPlJobProps> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Job</Typography>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              item
              xs={12}
            >
              <Grid item xs={12} md={3}>
                <Typography>Job Type:</Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypePL" label="PL" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeSL" label="SL" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypePTM" label="PTM" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobTypeSTM" label="STM" />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography>Job Description:</Typography>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              item
              xs={12}
            >
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobDescLetterPress" label="Letter Presss" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobDescOffset" label="Offset" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobDescFlexo" label="Flexo" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobDescSilkScreen" label="Silk Screen" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobDescThermal" label="Thermal" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobDescHotStamping" label="Hot Stamping" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="jobDescOther" label="Other" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldFastField
                  name="jobDescOtherData"
                  label="Other"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentPlJob;
