import { AppAction, PageFilter } from '../app';
import {
  CustomerGroup,
  CustomerGroupCreateOrUpdatePayload,
  CustomerGroupListFilter,
} from './customerGroup.types';

export const FETCH_CUSTOMERGROUP = 'FETCH_CUSTOMERGROUP';
export const FETCH_CUSTOMERGROUP_LIST = 'FETCH_CUSTOMERGROUP_LIST';
export const CREATE_CUSTOMERGROUP = 'CREATE_CUSTOMERGROUP';
export const UPDATE_CUSTOMERGROUP = 'UPDATE_CUSTOMERGROUP';
export const CLEAR_CUSTOMERGROUP = 'CLEAR_CUSTOMERGROUP';

export const fetchCustomerGroup = (id: CustomerGroup['id']): AppAction => ({
  type: FETCH_CUSTOMERGROUP,
  payload: id,
});

export const fetchCustomerGroupList = (
  filters: CustomerGroupListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_CUSTOMERGROUP_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createCustomerGroup = (
  payload: CustomerGroupCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_CUSTOMERGROUP,
  payload,
});

export const updateCustomerGroup = (
  payload: CustomerGroupCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_CUSTOMERGROUP,
  payload,
});

export const clearCustomerGroup = () => ({
  type: CLEAR_CUSTOMERGROUP,
});
