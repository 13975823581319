export type SubtaskItemStatusType =
  | 'PENDING'
  | 'IN_PROGRESS'
  | 'DONE'
  | 'WAITING_FOR_SALES'
  | 'WAITING_FOR_CUSTOMER'
  | 'REJECT'
  | 'CUSTOMER_REJECT'
  | 'CLOSE'
  | 'WAITING_FOR_NPD'
  | 'APPROVE_BY_NPD';

const SubtaskItemStatus = Object.freeze({
  PENDING: 'PENDING' as SubtaskItemStatusType,
  IN_PROGRESS: 'IN_PROGRESS' as SubtaskItemStatusType,
  DONE: 'DONE' as SubtaskItemStatusType,
  WAITING_FOR_SALES: 'WAITING_FOR_SALES' as SubtaskItemStatusType,
  WAITING_FOR_CUSTOMER: 'WAITING_FOR_CUSTOMER' as SubtaskItemStatusType,
  REJECT: 'REJECT' as SubtaskItemStatusType,
  CUSTOMER_REJECT: 'CUSTOMER_REJECT' as SubtaskItemStatusType,
  CLOSE: 'CLOSE' as SubtaskItemStatusType,
  WAITING_FOR_NPD: 'WAITING_FOR_NPD' as SubtaskItemStatusType,
  APPROVE_BY_NPD: 'APPROVE_BY_NPD' as SubtaskItemStatusType,
});

export default SubtaskItemStatus;

export const SubtaskItemStatusLabel = Object.freeze({
  [SubtaskItemStatus.PENDING]: 'Pending',
  [SubtaskItemStatus.IN_PROGRESS]: 'In Progress',
  [SubtaskItemStatus.DONE]: 'Done',
  [SubtaskItemStatus.WAITING_FOR_SALES]: 'Waiting Sales',
  [SubtaskItemStatus.WAITING_FOR_CUSTOMER]: 'Waiting Customer',
  [SubtaskItemStatus.REJECT]: 'Reject',
  [SubtaskItemStatus.CUSTOMER_REJECT]: 'Customer Reject',
  [SubtaskItemStatus.CLOSE]: 'Close',
  [SubtaskItemStatus.WAITING_FOR_NPD]: 'Waiting NPD',
  [SubtaskItemStatus.APPROVE_BY_NPD]: 'Approve By NPD',
});
