import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PageFilter } from '../../../state/app';
import {
  BlockListFilter,
  BlockState,
  SampleVNDto,
  clearBlock,
  fetchBlockList,
  syncBlock,
} from '../../../state/block';
import { AppState } from '../../../state/configureStore';
import { PageContainer, PageTitle, TableLoading } from '../../components';
import { useFilter } from '../../hooks';
import BlockFilter from './BlockFilter';
import { FileCopy as FileCopyIcon } from '@material-ui/icons';
import { AuthState } from '../../../state/auth';

const initialBlockListFilter: BlockListFilter = {};

const BlockList: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useFilter<BlockListFilter>(initialBlockListFilter);
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const {
    items: blocks,
    totalItems,
    loading,
  } = useSelector<AppState, BlockState>((state) => state.block);

  const { user } = useSelector<AppState, AuthState>((state) => state.auth);
  const isAdmin = user!.roles!.find((f) => f === 'ADMIN') !== undefined;

  const handleChangeFilter = (newFilter: BlockListFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchBlockList(filters, paging));
    return function cleanUp() {
      dispatch(clearBlock());
    };
  }, [dispatch, paging, filters]);

  return (
    <PageContainer>
      <PageTitle>Block</PageTitle>
      <BlockFilter
        filters={filters}
        onChangeFilter={handleChangeFilter}
        initialFilter={initialBlockListFilter}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Block No</TableCell>
            <TableCell>Program</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Item Description</TableCell>
            <TableCell>Sync Data</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={7}>
            {blocks.map((block, index) => (
              <TableRow key={block.id}>
                <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                <TableCell>{block.blockNo}</TableCell>
                <TableCell>{block.program!!.name}</TableCell>
                <TableCell>{block.brand!!.name}</TableCell>
                <TableCell>{block.item}</TableCell>
                <TableCell>{block.itemDescription}</TableCell>
                <TableCell>
                  {isAdmin && (
                    <Tooltip title="Sync Block To Sample Vietnam">
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<FileCopyIcon />}
                        onClick={() => {
                          const bb: SampleVNDto = {
                            blockNo: block.blockNo,
                            program: block.program?.name,
                            brand: block.brand?.name,
                            item: block.item,
                            itemName: block.itemDescription,
                            makefor: 'VN',
                            productType: block.productType?.name,
                          };
                          dispatch(syncBlock(bb));
                        }}
                      >
                        Vietnam
                      </Button>
                    </Tooltip>
                  )}
                  {isAdmin && (
                    <Tooltip title="Sync Block To Sample Vietnam Hai Duong">
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<FileCopyIcon />}
                        onClick={() => {
                          const bb: SampleVNDto = {
                            blockNo: block.blockNo,
                            program: block.program?.name,
                            brand: block.brand?.name,
                            item: block.item,
                            itemName: block.itemDescription,
                            makefor: 'WVD',
                            productType: block.productType?.name,
                          };
                          dispatch(syncBlock(bb));
                        }}
                      >
                        Hai Duong
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default BlockList;
