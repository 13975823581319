import React, { useEffect, useRef, useState } from 'react';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

export interface BaseSearchDropdownFieldProps {
  id: string;
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | { name?: string; value: unknown }>;
  filters: any;
  filterKey: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  label: {
    paddingLeft: theme.spacing(2)
  }
}));

const SearchDropdownField: React.FC<BaseSearchDropdownFieldProps> = props => {
  const classes = useStyles();
  const { id, label, onChange, filters, filterKey } = props;
  const inputLabel = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  return <FormControl
    margin={'dense'}
    className={classes.root}>
    <InputLabel
      shrink
      className={classes.label}
      id={id}
      ref={inputLabel}>{label}</InputLabel>
    <Select
      labelId={id}
      name={filterKey}
      displayEmpty
      value={filters[filterKey]}
      onChange={onChange}
      labelWidth={labelWidth}
      style={{ minWidth: '200px' }}
    >
      {props.children}
    </Select>
  </FormControl>;
};

export default SearchDropdownField;
