import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  FETCH_USER_LIST,
  CLEAR_USER,
  FETCH_USER,
  UPDATE_USER_ROLE,
} from './user.actions';
import { UserState } from './user.types';

const getInitialUserState = () => ({
  id: 0,
  login: '',
  firstName: '',
  lastName: '',
  email: '',
  activated: false,
});

const initialState: UserState = {
  errorMessage: '',
  item: getInitialUserState(),
  items: [],
  loading: false,
  totalItems: 0,
};

export default (state: UserState = initialState, action: AppAction): UserState => {
  switch (action.type) {
    case REQUEST(FETCH_USER_LIST):
    case REQUEST(FETCH_USER):
    case REQUEST(UPDATE_USER_ROLE):
      return { ...state, loading: true };
    case SUCCESS(FETCH_USER_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case SUCCESS(FETCH_USER):
      return {
        ...state,
        item: action.payload.data,
        loading: false,
      };
    case SUCCESS(UPDATE_USER_ROLE):
      return { ...state, loading: false };
    case FAILURE(FETCH_USER_LIST):
    case FAILURE(FETCH_USER):
    case FAILURE(UPDATE_USER_ROLE):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case CLEAR_USER:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
