import { AppAction } from '../../../state/app';
import {
  PUSH_NOTIFICATION_MESSAGE,
  CLEAR_NOTIFICATION_MESSAGE,
} from './notification.action';
import { NotificationState } from './Notification.types';

const initialState: NotificationState = {
  messages: [],
};

export default (state = initialState, action: AppAction) => {
  switch (action.type) {
    case PUSH_NOTIFICATION_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case CLEAR_NOTIFICATION_MESSAGE:
      return {
        ...state,
        messages: [],
      };
    default:
      return state;
  }
};
