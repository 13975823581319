import { CardContent, TextField } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { IEBlockPattern } from '../../../models/eBlockPattern';
import { AuthState } from '../../../state/auth';
import { AppState } from '../../../state/configureStore';
import {
  clearEBlockPattern,
  createEBlockPattern,
  EBlockPatternState,
  fetchEBlockPattern,
  updateEBlockPattern,
} from '../../../state/eBlockPattern';
import {
  FixedPositionButtons,
  Loading,
  PageContainer,
  PageTitle,
} from '../../components';
import EBlockGroupProductComboBox from '../../components/EBlockGroupProductComboBox';

const EBlockPatternSchema = Yup.object().shape({
  eBlockGroupProductId: Yup.number().required('EBlock Group Product is required'),
  code: Yup.string()
    .strict(true)
    .trim()
    .max(3, 'Code is exceeded character limit(3)')
    .required('Code is required'),
  description: Yup.string().nullable(true),
});

interface PatternFormRouteParamsProps {
  id: string;
}

interface PatternFormProps extends RouteComponentProps<PatternFormRouteParamsProps> {}

const EBlockPatternForm: React.FC<PatternFormProps> = props => {
  const dispatch = useDispatch();
  const { loading: authIsLoading } = useSelector<AppState, AuthState>(
    state => state.auth,
  );
  const { match, history } = props;
  const {
    params: { id: paramsId },
  } = match;

  const isUpdateMode = paramsId !== undefined;

  const { item: pattern, loading: patternIsLoading } = useSelector<
    AppState,
    EBlockPatternState
  >(state => state.eBlockPattern);

  const isLoading = authIsLoading || patternIsLoading;

  const defaultValues: IEBlockPattern = {};

  const handleSubmit = (
    values: IEBlockPattern,
    actions: FormikHelpers<IEBlockPattern>,
  ) => {
    if (isUpdateMode) {
      dispatch(updateEBlockPattern({ values, history }));
    } else {
      dispatch(createEBlockPattern({ values, history }));
    }
    actions.setSubmitting(false);
  };

  const redirectEBlockPatternList = () => {
    history.goBack();
  };

  useEffect(() => {
    if (isUpdateMode) {
      dispatch(fetchEBlockPattern(+paramsId));
    } else {
      dispatch(clearEBlockPattern());
    }
    return function cleanUp() {
      dispatch(clearEBlockPattern());
    };
  }, [isUpdateMode, paramsId, dispatch]);

  return (
    <PageContainer>
      <Loading isLoading={isLoading}>
        <Formik
          enableReinitialize={true}
          initialValues={{ ...defaultValues, ...pattern }}
          validationSchema={EBlockPatternSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, errors, touched, submitForm, setFieldValue }) => {
            return (
              <Form>
                <PageTitle>
                  {!isUpdateMode
                    ? `New EBlock Pattern`
                    : `Update EBlock Pattern ${pattern.code}`}
                </PageTitle>
                <CardContent>
                  <EBlockGroupProductComboBox
                    // isDisabled={isUpdateMode}
                    handleChange={(result: IEBlockPattern) => {
                      setFieldValue('eBlockGroupProductId', result ? result.id : null);
                      setFieldValue('eBlockGroupProduct', result ? result : null);
                    }}
                    selectedValue={values.eBlockGroupProduct as IEBlockPattern}
                    helperText={errors.eBlockGroupProductId}
                  />
                  <TextField
                    name="code"
                    type="input"
                    label="Code"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={values.code || ''}
                    onChange={handleChange}
                    error={!!errors.code && !!touched.code}
                    helperText={errors.code}
                    InputProps={{
                      readOnly: isUpdateMode,
                    }}
                  />
                  <TextField
                    name="description"
                    type="input"
                    label="Description"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={values.description || ''}
                    onChange={handleChange}
                    error={!!errors.description && !!touched.description}
                    helperText={errors.description}
                  />
                </CardContent>
                <FixedPositionButtons
                  actions={[
                    {
                      name: !isUpdateMode ? `Create` : `Update`,
                      onClick: () => {
                        submitForm();
                      },
                      color: 'primary' as 'primary',
                      disabled: false,
                      icon: 'save',
                    },
                    {
                      name: 'CANCEL',
                      onClick: redirectEBlockPatternList,
                      color: 'default' as 'default',
                      disabled: false,
                    },
                  ]}
                  open
                />
              </Form>
            );
          }}
        </Formik>
      </Loading>
    </PageContainer>
  );
};

export default EBlockPatternForm;
