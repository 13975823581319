import { CardContent, Grid, Paper, Typography, Box } from '@material-ui/core';
import React from 'react';
import {
  CheckBoxFastField,
  TextFieldFastField,
  UnitOfMeasurementsFastField,
  Image,
} from '../../../components';

import holePositionSingleImg from '../../sampleDevelopmentHl/sampleDevelopmentHlForm/images/single.png';
import holePositionDoubleImg from '../../sampleDevelopmentHl/sampleDevelopmentHlForm/images/double.png';
import holePositionFoldImg from '../../sampleDevelopmentHl/sampleDevelopmentHlForm/images/fold.png';
import holePositionDistanceImg from '../../sampleDevelopmentHl/sampleDevelopmentHlForm/images/distance.png';

interface SampleDevelopmentNlHlAssemblyProps {}

const SampleDevelopmentNlHlAssembly: React.FC<
  SampleDevelopmentNlHlAssemblyProps
> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Hl Assembly</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>เข้าเล่ม(Binding): </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="bindingFold" label="พับ(Fold)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="bindingTransparent" label="ใส(Transparent)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="bindingStaples" label="ลวดเย็บ(Staples)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="bindingStaplesData"
                label="ลวดเย็บ(Staples)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <Typography>ปะ(Gluing): </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="gluingLaminate" label="ปะกบ(Laminate)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="gluingSleeve" label="ปะซอง(Sleeve)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="gluingGlue" label="ปะกาว(Glue)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="adhesiveTapeDesc"
                label="ปะกาว(Glue)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="gluingBag" label="ปะถุง(Bag)" />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="gluingBox" label="ปะลิ้นกล่อง(Box)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField
                name="gluingWindowPlastic"
                label="ปะหน้าต่างพลาสติก(Window Plastic)"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField
                name="gluingDoubleSidedTape"
                label="ปะเทป 2 หน้า(Double Sided Tape)"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="gluingDoubleSidedTapeData"
                label="Double Sided Tape"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField
                name="gluingCorrugatedLaminate"
                label="ปะลูกฟูก(Corrugated Laminate)"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="gluingCorrugatedLaminateData"
                label="Corrugated Laminate"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="gluingCamshell" label="Camshell" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="gluingCamshellData"
                label="Camshell"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="gluingFoam" label="โฟม(Foam)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="gluingFoamData"
                label="Foam"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} />
            <Grid item xs={12} md={2}>
              <Typography>ร้อยเชือก(String):</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="stringSingle" label="เดี่ยว(Single)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="stringDouble" label="คู่(Double)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="stringOther" label="อื่นๆ(Other)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="stringOtherData"
                label="อื่นๆ(Other)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="stringType"
                label="ชนิด(Type)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="stringSize"
                label="ขนาด(Size)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="stringColor"
                label="สี(Color)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                name="stringLengthKnot"
                label="ความยาวถึงปม(Length Knot)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <UnitOfMeasurementsFastField name="stringLengthKnotUnit" label="Unit" />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                name="stringLengthEnd"
                label="ความยาวปมถึงปลาย(Length End)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <UnitOfMeasurementsFastField name="stringLengthEndUnit" label="Unit" />
            </Grid>
            <Grid item xs={12} md={6} />
            <Grid item xs={12} md={2}>
              <Typography>เข็มกลัด(Pin): </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="pinType"
                label="ชนิด(Type)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="pinSize"
                label="ขนาด(Size)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="pinColor"
                label="สี(Color)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={2}>
              <Typography>รู(Hole): </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="holeSingle" label="เดี่ยว(Single)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="holeDouble" label="คู่(Double)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="holeOther" label="อื่นๆ(Other)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="holeOtherData"
                label="อื่นๆ(Other)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                name="holeSize"
                label="ขนาด(Size)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <UnitOfMeasurementsFastField name="holeSizeUnit" label="Unit" />
            </Grid>
            <Grid item xs={12} md={6} />
            <Grid item xs={12} md={2}>
              <Typography>ตำแหน่งรู(Hole Position): </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="holePositionSingle" label="เดี่ยว(Single)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <Image width="100px" src={holePositionSingleImg} alt="เดี่ยว(Single)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                min="1"
                max="8"
                name="holePositionSingleNum"
                label="เดี่ยว(Single)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="holePositionDouble" label="คู่(Double)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <Image width="100px" src={holePositionDoubleImg} alt="คู่(Double)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                min="1"
                max="8"
                name="holePositionDoubleNum"
                label="คู่(Double)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="holePositionFold" label="พับทบ(Fold)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <Image width="100px" src={holePositionFoldImg} alt="พับทบ(Fold)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                min="1"
                max="8"
                name="holePositionFoldNum"
                label="พับทบ(Fold)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <Image
                width="100px"
                src={holePositionDistanceImg}
                alt="ระยะห่างจากแกน(Distance)"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                name="holePositionDistanceX"
                label="ระยะห่างจากแกน X(Distance X)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <UnitOfMeasurementsFastField
                name="holePositionDistanceXUnit"
                label="Unit"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                type="number"
                step="any"
                name="holePositionDistanceY"
                label="ระยะห่างจากแกน Y(Distance Y)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <UnitOfMeasurementsFastField
                name="holePositionDistanceYUnit"
                label="Unit"
              />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <Typography>ตาไก่(Eyelet): </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="eyeletNickleFree" label="ไร้สาร(Nickle Free)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="eyeletRustness" label="ไร้สนิม(Rustness)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="eyeletPlain" label="ธรรมดา(Plain)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="eyeletType"
                label="ชนิด(Type)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="eyeletSize"
                label="ขนาด(Size)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="eyeletColor"
                label="สี(Color)"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentNlHlAssembly;
