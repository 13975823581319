import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_PROGRAMANDBRANDREQUESTITEM,
  CREATE_PROGRAMANDBRANDREQUESTITEM,
  FETCH_PROGRAMANDBRANDREQUESTITEM,
  FETCH_PROGRAMANDBRANDREQUESTITEM_LIST,
  UPDATE_PROGRAMANDBRANDREQUESTITEM,
} from './programAndBrandRequestItem.actions';
import {
  ProgramAndBrandRequestItem,
  ProgramAndBrandRequestItemState,
} from './programAndBrandRequestItem.types';

const getInitialProgramAndBrandRequestItemState = (): ProgramAndBrandRequestItem => ({});

const initialState: ProgramAndBrandRequestItemState = {
  errorMessage: '',
  item: getInitialProgramAndBrandRequestItemState(),
  items: [],
  loading: false,
  totalItems: 0,
};

export default (
  state: ProgramAndBrandRequestItemState = initialState,
  action: AppAction,
): ProgramAndBrandRequestItemState => {
  switch (action.type) {
    case REQUEST(FETCH_PROGRAMANDBRANDREQUESTITEM_LIST):
    case REQUEST(CREATE_PROGRAMANDBRANDREQUESTITEM):
    case REQUEST(FETCH_PROGRAMANDBRANDREQUESTITEM):
    case REQUEST(UPDATE_PROGRAMANDBRANDREQUESTITEM):
      return { ...state, loading: true };
    case SUCCESS(FETCH_PROGRAMANDBRANDREQUESTITEM_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_PROGRAMANDBRANDREQUESTITEM_LIST):
    case FAILURE(CREATE_PROGRAMANDBRANDREQUESTITEM):
    case FAILURE(FETCH_PROGRAMANDBRANDREQUESTITEM):
    case FAILURE(UPDATE_PROGRAMANDBRANDREQUESTITEM):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_PROGRAMANDBRANDREQUESTITEM):
    case SUCCESS(FETCH_PROGRAMANDBRANDREQUESTITEM):
    case SUCCESS(UPDATE_PROGRAMANDBRANDREQUESTITEM):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_PROGRAMANDBRANDREQUESTITEM:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
