import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_PROGRAMREQUESTITEM,
  CREATE_PROGRAMREQUESTITEM,
  FETCH_PROGRAMREQUESTITEM,
  FETCH_PROGRAMREQUESTITEM_LIST,
  UPDATE_PROGRAMREQUESTITEM,
} from './programRequestItem.actions';
import { ProgramRequestItem, ProgramRequestItemState } from './programRequestItem.types';

const getInitialProgramRequestItemState = ():ProgramRequestItem  => ({});

const initialState: ProgramRequestItemState = {
  errorMessage: '',
  item: getInitialProgramRequestItemState(),
  items: [],
  loading: false,
  totalItems: 0,
};

export default (state: ProgramRequestItemState = initialState, action: AppAction): ProgramRequestItemState => {
  switch (action.type) {
    case REQUEST(FETCH_PROGRAMREQUESTITEM_LIST):
    case REQUEST(CREATE_PROGRAMREQUESTITEM):
    case REQUEST(FETCH_PROGRAMREQUESTITEM):
    case REQUEST(UPDATE_PROGRAMREQUESTITEM):
      return { ...state, loading: true };
    case SUCCESS(FETCH_PROGRAMREQUESTITEM_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_PROGRAMREQUESTITEM_LIST):
    case FAILURE(CREATE_PROGRAMREQUESTITEM):
    case FAILURE(FETCH_PROGRAMREQUESTITEM):
    case FAILURE(UPDATE_PROGRAMREQUESTITEM):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_PROGRAMREQUESTITEM):
    case SUCCESS(FETCH_PROGRAMREQUESTITEM):
    case SUCCESS(UPDATE_PROGRAMREQUESTITEM):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_PROGRAMREQUESTITEM:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
