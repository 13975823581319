import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_TASK_ACTIVITY,
  CREATE_TASK_ACTIVITY,
  FETCH_TASK_ACTIVITY,
  FETCH_TASK_ACTIVITY_LIST,
  UPDATE_TASK_ACTIVITY,
} from './taskActivity.actions';
import { TaskActivityState } from './taskActivity.types';
import { ITaskActivity } from '../../models/taskActivity.model';

const initialState: TaskActivityState = {
  errorMessage: '',
  item: {} as Readonly<ITaskActivity>,
  items: [],
  loading: false,
  totalItems: 0,
  commentDashboardItems: [] as ReadonlyArray<ITaskActivity>,
  commentDashboardTotalItems: 0,
};

export default (
  state: TaskActivityState = initialState,
  action: AppAction,
): TaskActivityState => {
  switch (action.type) {
    case REQUEST(FETCH_TASK_ACTIVITY_LIST):
    case REQUEST(CREATE_TASK_ACTIVITY):
    case REQUEST(FETCH_TASK_ACTIVITY):
    case REQUEST(UPDATE_TASK_ACTIVITY):
      return { ...state, loading: true };
    case SUCCESS(FETCH_TASK_ACTIVITY_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_TASK_ACTIVITY_LIST):
    case FAILURE(CREATE_TASK_ACTIVITY):
    case FAILURE(FETCH_TASK_ACTIVITY):
    case FAILURE(UPDATE_TASK_ACTIVITY):
    case SUCCESS(CREATE_TASK_ACTIVITY):
    case SUCCESS(FETCH_TASK_ACTIVITY):
    case SUCCESS(UPDATE_TASK_ACTIVITY):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_TASK_ACTIVITY:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
