import { PageFilter } from '../state/app';
import { WorkingGroup, WorkingGroupListFilter } from '../state/workingGroup';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/smWorkingGroups`;

export const fetchWorkingGroupList = async ({
  filters,
  paging,
}: {
  filters: WorkingGroupListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchWorkingGroup = async (id: WorkingGroup['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createWorkingGroup = async (workingGroup: WorkingGroup) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, workingGroup);
};

export const updateWorkingGroup = async (workingGroup: WorkingGroup) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, workingGroup);
};
