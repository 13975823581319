import { RoleType } from '../pages/roleRoute.config';
import { useSelector } from 'react-redux';
import { AppState } from '../../state/configureStore';
import { AuthState } from '../../state/auth';

const useHasRole = (roles: RoleType[]): boolean => {
  const { user } = useSelector<AppState, AuthState>(state => state.auth);

  let hasRole = false;
  if (user!.roles) {
    const userRole = user!.roles![0] as RoleType;
    hasRole = roles.includes(userRole);
  }
  return hasRole;
};

export default useHasRole;
