import { Button, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ISampleDevelopmentNl } from '../../../../models/sampleDevelopmentNl.model';
import { Save as SaveIcon, Send as SendIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import SampleDevelopmentStatus from '../../../../constants/sampleDevelopmentStatus';
import { AppState } from '../../../../state/configureStore';
import {
  createSampleDevelopmentNl,
  downloadSampleDevelopmentNl,
  fetchSampleDevelopmentNl,
  SampleDevelopmentNlState,
  submitSampleDevelopmentNlForApproval,
  updateSampleDevelopmentNl,
  clearSampleDevelopmentNl,
} from '../../../../state/sampleDevelopmentNl';
import {
  Loading,
  PageContainer,
  PageTitle,
  PageTopMenuContainer,
} from '../../../components';
import useStyles from './sampleDevelopmentNl.style';
import SampleDevelopmentNlDesign from './SampleDevelopmentNlDesign';
import SampleDevelopmentNlFinishing from './SampleDevelopmentNlFinishing';
import SampleDevelopmentNlHead from './SampleDevelopmentNlHead';
import SampleDevelopmentNlJobType from './SampleDevelopmentNlJobType';
import SampleDevelopmentNlPacking from './SampleDevelopmentNlPacking';
import SampleDevelopmentNlPrinting from './SampleDevelopmentNlPrinting';
import SampleDevelopmentNlSpec from './SampleDevelopmentNlSpec';
import SampleDevelopmentNlFilePath from './SampleDevelopmentNlFilePath';
import SampleDevelopmentNlAlfrescoLink from './SampleDevelopmentNlAlfrescoLink';
import SampleDevelopmentNlRemark from './SampleDevelopmentNlRemark';
import SampleDevelopmentNlHlAssembly from './SampleDevelopmentNlHlAssembly';
import moment from 'moment';

const statusAllowForSubmitForApproval = [
  SampleDevelopmentStatus.PENDING_FOR_APPROVAL,
  SampleDevelopmentStatus.REJECTED,
];

interface SampleDevelopmentNlFormProps {
  id: string;
}

const SampleDevelopmentNlSchema = Yup.object().shape({
  customer: Yup.object().nullable(true).required('Customer is required'),
  block: Yup.object().nullable(true).required('Block is required'),
  smTask: Yup.object().nullable(true).required('Task is required'),
  salesTeamMember: Yup.object().nullable(true).required('SalesTeamMember is required'),
});

const SampleDevelopmentNlForm: React.FC<SampleDevelopmentNlFormProps> = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { id: routeParamsId } = useParams<{ id: string }>();
  const sampleDevelopmentNlId = id ? id : routeParamsId;

  const isUpdateMode = sampleDevelopmentNlId !== undefined;
  const { item: sampleDevelopmentNl, loading: isLoading } = useSelector<
    AppState,
    SampleDevelopmentNlState
  >((s) => s.sampleDevelopmentNl);

  const redirectToSampleDevelopmentNlList = () => {
    history.goBack();
  };

  const handleSubmit = (values: ISampleDevelopmentNl) => {
    if (isUpdateMode) {
      dispatch(updateSampleDevelopmentNl({ values }));
    } else {
      dispatch(createSampleDevelopmentNl({ values }));
    }
  };

  useEffect(() => {
    if (sampleDevelopmentNlId) {
      dispatch(fetchSampleDevelopmentNl(+sampleDevelopmentNlId));
    }
    return function cleanUp() {
      dispatch(clearSampleDevelopmentNl());
    };
  }, [sampleDevelopmentNlId, dispatch]);

  return (
    <PageContainer>
      <Formik
        enableReinitialize={true}
        initialValues={{
          customer: null,
          smTask: null,
          block: null,
          salesTeamMember: null,
          dueDate: moment().format('YYYY-MM-DD'),
          seeModel: moment().format('YYYY-MM-DD'),
          quantity: 0,
          quantityUnit: 'PCS',
          labelDiWidth: 0,
          labelDiLength: 0,
          labelDiUnit: 'MM',
          dimensionRequired: false,
          noDimensionRequired: false,
          gapUnit: 'MM',
          coreDiameterUnit: 'MM',
          finishSizeUnit: 'MM',
          labelPerRollUnit: 'PCS',
          maxRollDiameterUnit: 'MM',
          minGapCD: 0,
          minGapCDUnit: 'MM',
          minGapMDUnit: 'MM',
          noCDUnit: 'PCS',
          prodInfoSticker: false,
          prodInfoFilm: false,
          prodInfoPaper: false,
          prodInfoFabric: false,
          prodInfoOther: false,
          prodInfoOtherData: '',
          prodInfoFlexo: false,
          prodInfoDigital: false,
          prodInfoOffset: false,
          prodInfoSilkscreen: false,
          prodInfoGravure: false,
          prodInfoColdStamping: false,
          prodInfoHotStamping: false,
          prodInfoLetterPress: false,
          gapCD: 0,
          gapMD: 0,
          noCD: 0,
          minGapMD: 0,
          variableAmount: 0,
          variableSize: false,
          variableKeySize: false,
          variableStyleNo: false,
          variableColor: false,
          variableFabricContent: false,
          variableInstruction: false,
          variableWashingSymbol: false,
          variableArt: false,
          variableFactoryCode: false,
          variableCountry: false,
          variableBarcode: false,
          variablePrice: false,
          variableOther: false,
          variableOtherData: '',
          custGrpTireIndustry: false,
          custGrpTicket: false,
          custGrpFood: false,
          custGrpConsumerProd: false,
          custGrpHouseWare: false,
          custGrpElectronic: false,
          custGrpCosmetic: false,
          custGrpPharmaceutical: false,
          custGrpLogistic: false,
          custGrpAirline: false,
          custGrpGarment: false,
          custGrpElectricAppliances: false,
          custGrpOther: false,
          custGrpOtherData: '',
          substrateInfoSample: false,
          substrateInfoSpecification: false,
          substrateInfoTestingStandard: false,
          substrateInfoApplication: false,
          substrateInfoApplicationData: '',
          specialRequirementData: '',
          ownDesign: false,
          customerDesign: false,
          infoOriginal: false,
          infoFile: false,
          infoSpecsheet: false,
          infoBlockFilmPlate: false,
          infoOther: false,
          infoOtherData: '',
          fileTypeTiff: false,
          fileTypeEps: false,
          fileTypeJpg: false,
          fileTypePdf: false,
          fileTypeAi: false,
          fileTypeQxp: false,
          softwareAcrobat: false,
          softwareIllustrator: false,
          softwarePageMaker: false,
          softwarePhotoshop: false,
          softwareFreeHand: false,
          softwareQuark: false,
          softwareEtc: false,
          softwareEtcData: '',
          printingFront: false,
          printingFProcess: false,
          printingFProcessColor: '',
          printingFProcessPantone: '',
          printingFSolid: false,
          printingFSolidColor: '',
          printingFSolidPantone: '',
          printingFMetalic: false,
          printingFMetalicColor: '',
          printingFMetalicPantone: '',
          printingFFoil: false,
          printingFFoilColor: '',
          printingFFoilPantone: '',
          printingFOther: false,
          printingFOtherColor: '',
          printingFOtherPantone: '',
          printingBack: false,
          printingBAProcessColor: '',
          printingBAProcessPantone: '',
          printingBASolidColor: '',
          printingBASolidPantone: '',
          printingBAMetalicColor: '',
          printingBAMetalicPantone: '',
          printingBAFoilColor: '',
          printingBAFoilPantone: '',
          printingBAOtherColor: '',
          printingBAOtherPantone: '',
          printingBLProcessColor: '',
          printingBLProcessPantone: '',
          printingBLSolidColor: '',
          printingBLSolidPantone: '',
          printingBLMetalicColor: '',
          printingBLMetalicPantone: '',
          printingBLFoilColor: '',
          printingBLFoilPantone: '',
          printingBLOtherColor: '',
          printingBLOtherPantone: '',
          printingBLProcess: false,
          printingBLSolid: false,
          printingBLMetalic: false,
          printingBLFoil: false,
          printingBLOther: false,
          printingBAProcess: false,
          printingBASolid: false,
          printingBAMetalic: false,
          printingBAFoil: false,
          printingBAOther: false,
          finishCoatingFront: false,
          fcfFilmLaminate: false,
          fcfFilmMatt: false,
          fcfFilmGloss: false,
          fcfFilmHalogram: false,
          fcfFilmOther: false,
          fcfChemCemical: false,
          fcfChemWaterBase: false,
          fcfChemOilBase: false,
          fcfChemVanish: false,
          fcfChemSpot: false,
          fcfChemSpotMatt: false,
          fcfChemSpotSuperMatt: false,
          fcfChemSpotSemiGloss: false,
          fcfChemSpotGloss: false,
          fcfChemSpotHighGloss: false,
          fcfChemSpotTexture: false,
          fcfChemSpotCalendering: false,
          fcfChemSpotOther: false,
          fcfChemSpotOtherData: '',
          fcfChemFullArea: false,
          fcfChemFullAreaMatt: false,
          fcfChemFullAreaSuperMatt: false,
          fcfChemFullAreaSemiGloss: false,
          fcfChemFullAreaGloss: false,
          fcfChemFullAreaHighGloss: false,
          fcfChemFullAreaTexture: false,
          fcfChemFullAreaCalendering: false,
          fcfChemFullAreaOther: false,
          fcfChemFullAreaOtherData: '',
          fcfChemMatt: false,
          fcfChemSuperArea: false,
          fcfChemSemiGloss: false,
          fcfChemGloss: false,
          fcfChemHighGloss: false,
          fcfChemTexture: false,
          fcfChemCelendering: false,
          fcfChemOther: false,
          fcfBlister: false,
          fcfBlisterPet: false,
          fcfBlisterPvc: false,
          fcfSilkscreen: false,
          fcfSilkFullArea: false,
          fcfSilkSpot: false,
          fcfSilkMatt: false,
          fcfSilkGloss: false,
          fcfSilkOthers: false,
          fcfSilkOthersData: '',
          finishCoatingBack: false,
          fcbFilmLaminate: false,
          fcbFilmMatt: false,
          fcbFilmGloss: false,
          fcbFilmHalogram: false,
          fcbFilmOther: false,
          fcbChemCemical: false,
          fcbChemWaterBase: false,
          fcbChemOilBase: false,
          fcbChemVanish: false,
          fcbChemSpot: false,
          fcbChemFullArea: false,
          fcbChemSpotMatt: false,
          fcbChemSpotSuperMatt: false,
          fcbChemSpotSemiGloss: false,
          fcbChemSpotGloss: false,
          fcbChemSpotHighGloss: false,
          fcbChemSpotTexture: false,
          fcbChemSpotCalendering: false,
          fcbChemSpotOther: false,
          fcbChemSpotOtherData: '',
          fcbChemFullAreaMatt: false,
          fcbChemFullAreaSuperMatt: false,
          fcbChemFullAreaSemiGloss: false,
          fcbChemFullAreaGloss: false,
          fcbChemFullAreaHighGloss: false,
          fcbChemFullAreaTexture: false,
          fcbChemFullAreaCalendering: false,
          fcbChemFullAreaOther: false,
          fcbChemFullAreaOtherData: '',
          fcbBlister: false,
          fcbBlisterPet: false,
          fcbBlisterPvc: false,
          fcbSilkscreen: false,
          fcbSilkFullArea: false,
          fcbSilkSpot: false,
          fcbSilkMatt: false,
          fcbSilkGloss: false,
          fcbSilkOthers: false,
          fcbSilkOthersData: '',
          dieCutFront: false,
          dieCutFHold: false,
          dieCutFPerforate: false,
          dieCutFEmboss: false,
          dieCutFFoldLine: false,
          dieCutFShape: false,
          dieCutBack: false,
          dieCutBHold: false,
          dieCutBPerforate: false,
          dieCutBEmboss: false,
          dieCutBFoldLine: false,
          dieCutBShape: false,
          prodPackingRoll: false,
          prodPackingCut: false,
          prodPackingSheet: false,
          prodPackingFold: false,
          prodPackingContinuous: false,
          labelPerRoll: 0,
          maxRollDiameter: 0,
          coreDiameter: 0,
          maxSplice: false,
          maxSpliceData: '',
          spliceRequire: false,
          spliceRequireData: '',
          spliceTape: false,
          spliceTapeData: '',
          cartonPackStandard: false,
          cartonPackSpecial: false,
          cartonPackSpecialData: '',
          deliverPosition: 0,
          sampleDevelopmentNlSubstrates: [],
          filePath: '',
          softwareAcrobatData: '',
          softwareIllustratorData: '',
          softwarePageMakerData: '',
          softwarePhotoshopData: '',
          softwareFreeHandData: '',
          softwareQuarkData: '',
          jobType: 'SAMPLE',
          orderCompany: null,
          orderCompanyId: 0,
          substratePrimaryOption: '1',
          substrateOther: '',
          remark: '',
          bindingFold: false,
          bindingTransparent: false,
          bindingStaples: false,
          bindingStaplesData: '',
          gluingLaminate: false,
          gluingSleeve: false,
          gluingGlue: false,
          gluingBag: false,
          gluingBox: false,
          gluingWindowPlastic: false,
          gluingDoubleSidedTape: false,
          ratchCorrugated: false,
          stringSingle: false,
          stringDouble: false,
          stringOther: false,
          stringOtherData: '',
          stringType: '',
          stringSize: '',
          stringColor: '',
          stringLengthKnot: 0,
          stringLengthKnotUnit: 'CM',
          stringLengthEnd: 0,
          stringLengthEndUnit: 'CM',
          pinType: '',
          pinSize: '',
          pinColor: '',
          holeSingle: false,
          holeDouble: false,
          holeOther: false,
          holeOtherData: '',
          holeSize: 0,
          holeSizeUnit: 'MM',
          holePositionSingle: false,
          holePositionDouble: false,
          holePositionFold: false,
          holePositionDistanceX: 0,
          holePositionDistanceXUnit: 'MM',
          holePositionDistanceY: 0,
          holePositionDistanceYUnit: 'MM',
          eyeletNickleFree: false,
          eyeletRustness: false,
          eyeletPlain: false,
          eyeletType: '',
          eyeletSize: '',
          eyeletColor: '',
          gluingDoubleSidedTapeData: '',
          gluingCorrugatedLaminateData: '',
          gluingCamshellData: '',
          gluingFoamData: '',
          holePositionSingleNum: 0,
          holePositionDoubleNum: 0,
          holePositionFoldNum: 0,
          gluingCamshell: false,
          gluingFoam: false,
          gluingCorrugatedLaminate: false,
          alfrescoLink: '',
          ...sampleDevelopmentNl,
        }}
        validationSchema={SampleDevelopmentNlSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        isInitialValid={true}
      >
        {({ values, handleChange, errors, submitForm, setFieldValue }) => {
          return (
            <Form>
              <PageTitle>
                {isUpdateMode
                  ? `Update Sample Development NL ${values.sampleDevNumber}`
                  : `New Sample Development NL`}
              </PageTitle>
              <PageTopMenuContainer>
                {statusAllowForSubmitForApproval.includes(
                  sampleDevelopmentNl.status!,
                ) && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      dispatch(
                        submitSampleDevelopmentNlForApproval(sampleDevelopmentNl.id!),
                      );
                    }}
                    startIcon={<SendIcon />}
                  >
                    {`Submit for Approval`}
                  </Button>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  type="submit"
                >
                  {isUpdateMode ? `Update` : `Create`}
                </Button>
                <Button variant="contained" onClick={redirectToSampleDevelopmentNlList}>
                  Cancel
                </Button>
                {isUpdateMode && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      dispatch(
                        downloadSampleDevelopmentNl(parseInt(sampleDevelopmentNlId)),
                      );
                    }}
                  >
                    Download PDF
                  </Button>
                )}
              </PageTopMenuContainer>
              <Loading isLoading={isLoading}>
                <CardContent>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <SampleDevelopmentNlHead
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="flex-start"
                    >
                      <Grid item xs={12}>
                        <Paper variant="outlined">
                          <Typography className={classes.title}>Product Info</Typography>
                          <SampleDevelopmentNlJobType />
                          <CardContent>
                            <SampleDevelopmentNlSpec
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              setFieldValue={setFieldValue}
                            />
                          </CardContent>
                          <CardContent>
                            <SampleDevelopmentNlDesign />
                          </CardContent>
                          <CardContent>
                            <SampleDevelopmentNlPrinting />
                          </CardContent>
                          <CardContent>
                            <SampleDevelopmentNlFinishing />
                          </CardContent>
                          <CardContent>
                            <SampleDevelopmentNlHlAssembly />
                          </CardContent>
                          <CardContent>
                            <SampleDevelopmentNlPacking />
                          </CardContent>
                          <CardContent>
                            <SampleDevelopmentNlAlfrescoLink />
                          </CardContent>
                          <CardContent>
                            <SampleDevelopmentNlFilePath />
                          </CardContent>
                          <CardContent>
                            <SampleDevelopmentNlRemark />
                          </CardContent>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Loading>
            </Form>
          );
        }}
      </Formik>
    </PageContainer>
  );
};

export default SampleDevelopmentNlForm;
