import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_CUSTOMERGROUPMEMBER,
  CREATE_CUSTOMERGROUPMEMBER, DELETE_CUSTOMERGROUPMEMBER,
  FETCH_CUSTOMERGROUPMEMBER,
  FETCH_CUSTOMERGROUPMEMBER_LIST,
  UPDATE_CUSTOMERGROUPMEMBER,
} from './customerGroupMember.actions';
import { CustomerGroupMember, CustomerGroupMemberState } from './customerGroupMember.types';

const getInitialCustomerGroupMemberState = (): CustomerGroupMember => ({});

const initialState: CustomerGroupMemberState = {
  errorMessage: '',
  item: getInitialCustomerGroupMemberState() as Readonly<CustomerGroupMember>,
  items: [] as ReadonlyArray<CustomerGroupMember>,
  loading: false,
  totalItems: 0,
};

export default (state: CustomerGroupMemberState = initialState, action: AppAction): CustomerGroupMemberState => {
  switch (action.type) {
    case REQUEST(FETCH_CUSTOMERGROUPMEMBER_LIST):
    case REQUEST(CREATE_CUSTOMERGROUPMEMBER):
    case REQUEST(FETCH_CUSTOMERGROUPMEMBER):
    case REQUEST(UPDATE_CUSTOMERGROUPMEMBER):
    case REQUEST(DELETE_CUSTOMERGROUPMEMBER):
      return { ...state, loading: true };
    case SUCCESS(FETCH_CUSTOMERGROUPMEMBER_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_CUSTOMERGROUPMEMBER_LIST):
    case FAILURE(CREATE_CUSTOMERGROUPMEMBER):
    case FAILURE(FETCH_CUSTOMERGROUPMEMBER):
    case FAILURE(UPDATE_CUSTOMERGROUPMEMBER):
    case FAILURE(DELETE_CUSTOMERGROUPMEMBER):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_CUSTOMERGROUPMEMBER):
    case SUCCESS(FETCH_CUSTOMERGROUPMEMBER):
    case SUCCESS(UPDATE_CUSTOMERGROUPMEMBER):
      return { ...state, loading: false, item: action.payload.data };
    case SUCCESS(DELETE_CUSTOMERGROUPMEMBER):
      return { ...state, loading: false };
    case CLEAR_CUSTOMERGROUPMEMBER:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
