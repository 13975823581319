import { IProjectProgram } from '../../models/projectProgram.model';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_PROJECT_PROGRAM,
  CREATE_PROJECT_PROGRAM,
  FETCH_PROJECT_PROGRAM,
  FETCH_PROJECT_PROGRAM_LIST,
  UPDATE_PROJECT_PROGRAM,
} from './projectProgram.actions';
import { ProjectProgramState } from './projectProgram.types';

const getInitialProjectProgramState = (): IProjectProgram => ({});

const initialState: ProjectProgramState = {
  errorMessage: '',
  item: getInitialProjectProgramState(),
  items: [],
  loading: false,
  totalItems: 0,
};

export default (
  state: ProjectProgramState = initialState,
  action: AppAction,
): ProjectProgramState => {
  switch (action.type) {
    case REQUEST(FETCH_PROJECT_PROGRAM_LIST):
    case REQUEST(CREATE_PROJECT_PROGRAM):
    case REQUEST(FETCH_PROJECT_PROGRAM):
    case REQUEST(UPDATE_PROJECT_PROGRAM):
      return { ...state, loading: true };
    case SUCCESS(FETCH_PROJECT_PROGRAM_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_PROJECT_PROGRAM_LIST):
    case FAILURE(CREATE_PROJECT_PROGRAM):
    case FAILURE(FETCH_PROJECT_PROGRAM):
    case FAILURE(UPDATE_PROJECT_PROGRAM):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_PROJECT_PROGRAM):
    case SUCCESS(FETCH_PROJECT_PROGRAM):
    case SUCCESS(UPDATE_PROJECT_PROGRAM):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_PROJECT_PROGRAM:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
