import { CardContent, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './sampleDevelopmentNl.style';
import SampleDevelopmentNlPrintingBack from './SampleDevelopmentNlPrintingBack';
import SampleDevelopmentNlPrintingFront from './SampleDevelopmentNlPrintingFront';

interface SampleDevelopmentNlPrintingProps {}

const SampleDevelopmentNlPrinting: React.FC<SampleDevelopmentNlPrintingProps> = () => {
  const classes = useStyles();

  return (
    <Paper variant="outlined">
      <Typography className={classes.title}>Printing</Typography>
      <CardContent>
        <Grid container spacing={1} direction="row" justifyContent="flex-start">
          <Grid item xs={12} sm={6} container>
            <SampleDevelopmentNlPrintingFront />
          </Grid>
          <Grid item xs={12} sm={6} container>
            <SampleDevelopmentNlPrintingBack />
          </Grid>
        </Grid>
      </CardContent>
    </Paper>
  );
};

export default SampleDevelopmentNlPrinting;
