import { ITaskLogWork } from '../models/taskLogWork.model';
import { PageFilter } from '../state/app';
import { TaskLogWorkListFilter } from '../state/taskLogWork';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/taskLogWorks`;

export const fetchTaskLogWorkList = async ({
  filters,
  paging,
}: {
  filters: TaskLogWorkListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchTaskLogWork = async (id: ITaskLogWork['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createTaskLogWork = async (taskLogWork: ITaskLogWork) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, taskLogWork);
};

export const updateTaskLogWork = async (taskLogWork: ITaskLogWork) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, taskLogWork);
};
