import { makeStyles } from '@material-ui/core/styles';
import { ProgressBarState } from './progressBar.types';

const useStyles = makeStyles({
  progress: (props: ProgressBarState) => ({
    display: props.display,
    position: 'fixed',
    zIndex: 9999,
    width: '100%',
  }),
});

export default useStyles;
