import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { IEBlockMaterial } from '../../../models/eBlockMaterial.model';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearEBlockMaterial,
  EBlockMaterialListFilter,
  EBlockMaterialState,
  fetchEBlockMaterialList,
} from '../../../state/eBlockMaterial';
import {
  DateTimeDisplay,
  PageContainer,
  PageTitle,
  SearchField,
  SortLabel,
  TableLoading,
} from '../../components';
import { useFilter, useHasRole, useTableSorting } from '../../hooks';
import roleRoute from '../roleRoute.config';
import useStyles from './eBlockMaterial.style';

const EBlockMaterialList: React.FC<RouteComponentProps> = (props) => {
  const { history } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDisabled = !useHasRole(roleRoute.createEBlockMaterial);
  const [filters, setFilters] = useFilter<EBlockMaterialListFilter>({});
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });
  const [sort, setSort] = useTableSorting({
    orderBy: 'createdAt',
    direction: 'desc',
  });

  const {
    items: eBlockMaterials,
    totalItems,
    loading,
  } = useSelector<AppState, EBlockMaterialState>((state) => state.eBlockMaterial);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const addNewEBlockMaterial = (): void => {
    history.push('/settings/eBlockMaterials/form');
  };

  const editEBlockMaterial = (id: IEBlockMaterial['id']): void => {
    history.push(`/settings/eBlockMaterials/form/${id}`);
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchEBlockMaterialList(filters, { ...paging, sort }));
    return function cleanUp() {
      dispatch(clearEBlockMaterial());
    };
  }, [dispatch, paging, filters, sort]);

  return (
    <PageContainer>
      <PageTitle>EBlock Materials</PageTitle>
      <div className={classes.filterContainer}>
        <SearchField
          filterKey="Code.StartsWith"
          filters={filters}
          label="Code"
          onChange={handleChangeFilter}
        />
        <SearchField
          filterKey="Description.StartsWith"
          filters={filters}
          label="Description"
          onChange={handleChangeFilter}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={addNewEBlockMaterial}
          size="large"
        >
          <AddIcon className={classes.iconButton} />
          New EBlock Material
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="code">
                Code
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="description">
                Description
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="createdAt">
                CreatedAt
              </SortLabel>
            </TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={4}>
            {eBlockMaterials.map((eBlockMaterial, index) => (
              <TableRow key={eBlockMaterial.id}>
                <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                <TableCell>{eBlockMaterial.code}</TableCell>
                <TableCell>{eBlockMaterial.description}</TableCell>
                <TableCell>
                  <DateTimeDisplay value={eBlockMaterial.createdAt} />
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    disabled={isDisabled}
                    onClick={() => editEBlockMaterial(eBlockMaterial.id)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default EBlockMaterialList;
