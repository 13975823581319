import { ITaskItem } from '../../models/taskItem.model';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_SELECTED_TASK_ITEMS,
  CLEAR_TASK_ITEM,
  CREATE_TASK_ITEM,
  CREATE_TASK_ITEMS,
  DELETE_TASK_ITEM,
  FETCH_TASK_ITEM,
  FETCH_TASK_ITEM_LIST,
  SELECTED_TASK_ITEMS,
  UPDATE_TASK_ITEM,
} from './taskItem.actions';
import { TaskItemState } from './taskItem.types';

const initialState: TaskItemState = {
  errorMessage: '',
  item: {} as Readonly<ITaskItem>,
  items: [] as ITaskItem[],
  createdItems: [],
  loading: false,
  totalItems: 0,
  selectedItems: [] as ITaskItem[],
};

export default (
  state: TaskItemState = initialState,
  action: AppAction,
): TaskItemState => {
  switch (action.type) {
    case REQUEST(FETCH_TASK_ITEM_LIST):
    case REQUEST(CREATE_TASK_ITEM):
    case REQUEST(FETCH_TASK_ITEM):
    case REQUEST(UPDATE_TASK_ITEM):
    case REQUEST(CREATE_TASK_ITEMS):
    case REQUEST(DELETE_TASK_ITEM):
      return { ...state, loading: true };
    case SUCCESS(FETCH_TASK_ITEM_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_TASK_ITEM_LIST):
    case FAILURE(CREATE_TASK_ITEM):
    case FAILURE(FETCH_TASK_ITEM):
    case FAILURE(UPDATE_TASK_ITEM):
    case FAILURE(CREATE_TASK_ITEMS):
    case FAILURE(DELETE_TASK_ITEM):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_TASK_ITEM):
    case SUCCESS(FETCH_TASK_ITEM):
    case SUCCESS(UPDATE_TASK_ITEM):
      return { ...state, loading: false, item: action.payload.data };
    case SUCCESS(CREATE_TASK_ITEMS):
      return { ...state, loading: false, createdItems: action.payload.data };
    case SUCCESS(DELETE_TASK_ITEM):
      return { ...state, loading: false };
    case SELECTED_TASK_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case CLEAR_SELECTED_TASK_ITEMS:
      return {
        ...state,
        selectedItems: [],
      };
    case CLEAR_TASK_ITEM:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};
