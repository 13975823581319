import { AppAction, PageFilter } from '../../../../state/app';

export const FETCH_PENDING_APPROVAL_PROJECT_LIST = 'FETCH_PENDING_APPROVAL_PROJECT_LIST';
export const CLEAR_PENDING_APPROVAL_PROJECT_LIST = 'CLEAR_PENDING_APPROVAL_PROJECT_LIST';

export const fetchPendingApprovalProjectList = (paging: PageFilter): AppAction => {
  return {
    type: FETCH_PENDING_APPROVAL_PROJECT_LIST,
    payload: {
      filters: { 'HasResponded.EqualsTo': 'false' },
      paging,
    },
  };
};

export const clearPendingApprovalProjectList = () => ({
  type: CLEAR_PENDING_APPROVAL_PROJECT_LIST,
});
