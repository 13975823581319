import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_SALESTEAM_MEMBER,
  DELETE_SALESTEAM_MEMBER,
  FETCH_SALESTEAM_MEMBER_LIST, UPDATE_SALESTEAM_MEMBER,
} from './salesTeamMember.actions';
import { SalesTeamMember, SalesTeamMemberState } from './salesTeamMember.types';

const getInitialSalesTeamRoleState = (): SalesTeamMember => ({
  id: 0,
  salesTeamRoleId: 0,
  userId: 0,
});

const initialState: SalesTeamMemberState = {
  errorMessage: '',
  item: getInitialSalesTeamRoleState(),
  items: [],
  loading: false,
  totalItems: 0,
};

export default (
  state: SalesTeamMemberState = initialState,
  action: AppAction,
): SalesTeamMemberState => {
  switch (action.type) {
    case REQUEST(FETCH_SALESTEAM_MEMBER_LIST):
    case REQUEST(DELETE_SALESTEAM_MEMBER):
    case REQUEST(UPDATE_SALESTEAM_MEMBER):
      return { ...state, loading: true };
    case SUCCESS(FETCH_SALESTEAM_MEMBER_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems:  +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_SALESTEAM_MEMBER_LIST):
    case FAILURE(DELETE_SALESTEAM_MEMBER):
    case FAILURE(UPDATE_SALESTEAM_MEMBER):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case CLEAR_SALESTEAM_MEMBER:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
