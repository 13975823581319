import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../state/configureStore';
import {
  fetchTaskLogWorkListByTaskId,
  TaskLogWorkState,
} from '../../../../state/taskLogWork';
import SortLabel from '../../../components/SortLabel';
import { useTableSorting } from '../../../hooks';
import { TableLoading } from '../../../components';
import { DateTimeDisplay } from '../../../components/DateTimeDisplay';

interface ILogWorkList {
  smTaskId: number;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflow: 'visible',
  },
  textTotalDuration: {
    textAlign: 'right',
    fontWeight: 'bold',
  },
  numberTotalDuration: {
    fontWeight: 'bold',
  },
  table: {
    marginTop: 0,
  },
});

const LogWorkList: React.FC<ILogWorkList> = props => {
  const classes = useStyles();
  const { smTaskId } = props;
  const dispatch = useDispatch();

  const { items: logWorkList, loading } = useSelector<AppState, TaskLogWorkState>(
    state => state.taskLogWork,
  );

  const totalDuration = logWorkList
    .reduce((previousValue, currentValue) => previousValue + currentValue.duration!, 0)
    .toLocaleString();

  const [sort, handleSortChange] = useTableSorting(
    {
      orderBy: 'createdAt',
      direction: 'desc',
    },
    true,
  );

  useEffect(() => {
    dispatch(fetchTaskLogWorkListByTaskId(smTaskId, sort));
  }, [dispatch, smTaskId, sort]);

  return (
    <Card className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>
              <SortLabel
                sort={sort}
                handleSortChange={handleSortChange}
                field="smSubtask.subject"
              >
                Subtask
              </SortLabel>
            </TableCell>
            <TableCell>Work Description</TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={handleSortChange} field="duration">
                Duration(minutes)
              </SortLabel>
            </TableCell>
            <TableCell>User</TableCell>
            <TableCell>
              <SortLabel
                sort={sort}
                handleSortChange={handleSortChange}
                field="createdAt"
              >
                Created At
              </SortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={6}>
            {logWorkList.map((item, index) => {
              const { smSubtask, user } = item;
              return (
                <TableRow key={item.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.smSubtaskId ? smSubtask!.subject : ''}</TableCell>
                  <TableCell>{item.workDescription}</TableCell>
                  <TableCell>{item.duration!.toLocaleString()}</TableCell>
                  <TableCell>{user!.fullName}</TableCell>
                  <TableCell>
                    <DateTimeDisplay value={item.createdAt} />
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell colSpan={3} className={classes.textTotalDuration}>
                Total Duration
              </TableCell>
              <TableCell className={classes.numberTotalDuration}>
                {totalDuration}
              </TableCell>
              <TableCell colSpan={2} />
            </TableRow>
          </TableLoading>
        </TableBody>
      </Table>
    </Card>
  );
};

export default LogWorkList;
