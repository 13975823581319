import { AppAction, PageFilter } from '../app';
import { SquadMemberListFilter } from './squadMember.type';

export const FETCH_SQUAD_MEMBER_LIST = 'FETCH_SQUAD_MEMBER_LIST';
export const CLEAR_SQUAD_MEMBER = 'CLEAR_SQUAD_MEMBER';

export const fetchSquadMemberList = (
  filters: SquadMemberListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SQUAD_MEMBER_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const clearSquadMember = () => ({
  type: CLEAR_SQUAD_MEMBER,
});
