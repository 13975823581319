import { ProjectStatusType } from '../constants/projectStatus';
import { Company } from '../state/company';
import { User } from '../state/user';
import { IProjectProgram } from './projectProgram.model';
import { IProjectSquad } from './projectSquad.model';
import { IProjectCustomer } from './projectCustomer.model';
import { IProjectCustomerGroup } from './projectCustomerGroup.model';

export interface IProject {
  id?: number;
  name?: string;
  status?: ProjectStatusType;
  createdAt?: string;
  createdBy?: string;
  ownerId?: number | null;
  owner?: User | null;
  companyId?: number | null;
  company?: Company | null;
  programNames?: string | null;
  brandNames?: string | null;
  smProjectPrograms?: IProjectProgram[];
  smProjectSquads?: IProjectSquad[];
  smProjectCustomers?: IProjectCustomer[];
  smProjectCustomerGroups?: IProjectCustomerGroup[];
  isHighPriority?: boolean | null;
}

export const defaultValue: Readonly<IProject> = {};
