import { call, debounce, put, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  createTaskAttachment,
  fetchTaskAttachment,
  fetchTaskAttachmentList,
  updateTaskAttachment,
  uploadTaskAttachment,
} from '../../services/taskAttachmentService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_TASK_ATTACHMENT,
  FETCH_TASK_ATTACHMENT,
  FETCH_TASK_ATTACHMENT_LIST, fetchTaskAttachmentListByTaskId,
  UPDATE_TASK_ATTACHMENT,
  UPLOAD_TASK_ATTACHMENT,
  UPLOAD_TASK_ATTACHMENTS,
} from './taskAttachment.actions';
import { TaskAttachmentCreateOrUpdatePayload } from './taskAttachment.types';
import { ITaskAttachmentUpload } from '../../models/taskAttachment.model';

function* fetchTaskAttachmentSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchTaskAttachment, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchTaskAttachmentListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchTaskAttachmentList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createTaskAttachmentSaga(action: AppAction) {
  try {
    const { values } = action.payload as TaskAttachmentCreateOrUpdatePayload;
    const successMessage = `Created taskAttachment successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createTaskAttachment, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateTaskAttachmentSaga(action: AppAction) {
  try {
    const { values } = action.payload as TaskAttachmentCreateOrUpdatePayload;
    const successMessage = `Updated taskAttachment successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateTaskAttachment, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* uploadTaskAttachmentSaga(action: AppAction) {
  try {
    const payload = action.payload as ITaskAttachmentUpload;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(uploadTaskAttachment, payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* uploadTaskAttachmentsSaga(action: AppAction) {
  const { smTaskId } = action.payload;
  yield put({ type: REQUEST(action.type) });
  for (const file of action.payload.files) {
    yield call(uploadTaskAttachmentSaga, {
      type: UPLOAD_TASK_ATTACHMENT,
      payload: {
        smTaskId: smTaskId,
        file,
      },
    });
  }
  yield put({ type: SUCCESS(action.type) });
  yield put(fetchTaskAttachmentListByTaskId(smTaskId));
}

export default function* watchTaskAttachment() {
  yield takeLatest(FETCH_TASK_ATTACHMENT, fetchTaskAttachmentSaga);
  yield debounce(250, FETCH_TASK_ATTACHMENT_LIST, fetchTaskAttachmentListSaga);
  yield takeLatest(CREATE_TASK_ATTACHMENT, createTaskAttachmentSaga);
  yield takeLatest(UPDATE_TASK_ATTACHMENT, updateTaskAttachmentSaga);
  yield takeEvery(UPLOAD_TASK_ATTACHMENT, uploadTaskAttachmentSaga);
  yield debounce(100, UPLOAD_TASK_ATTACHMENTS, uploadTaskAttachmentsSaga);
}
