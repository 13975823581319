import { defaultValue } from '../../../../models/opportunity.model';
import { FAILURE, REQUEST, SUCCESS } from '../../../../utils/actionTypeUtil';
import { AppAction } from '../../../../state/app';
import { CopyOpportunityState } from './copyOpportunity.types';
import { COPY_OPPORTUNITY, CLEAR_COPY_OPPORTUNITY } from './copyOpportunity.actions';

const initialState: CopyOpportunityState = {
  errorMessage: '',
  item: defaultValue,
  loading: false,
};

export default (
  state: CopyOpportunityState = initialState,
  action: AppAction,
): CopyOpportunityState => {
  switch (action.type) {
    case REQUEST(COPY_OPPORTUNITY):
      return { ...state, loading: true };
    case FAILURE(COPY_OPPORTUNITY):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(COPY_OPPORTUNITY):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_COPY_OPPORTUNITY:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
