import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransitionProps } from '@material-ui/core/transitions';
import { SampleDevelopmentStatus } from '../../../constants';
import { ISampleDevelopmentPl } from '../../../models/sampleDevelopmentPl.model';
import { AppState } from '../../../state/configureStore';
import {
  SampleDevelopmentPlApprovalRequest,
  SampleDevelopmentPlApprovalRequestState,
  updateSampleDevelopmentPlApprovalRequestResponse,
} from '../../../state/sampleDevelopmentPlApprovalRequest';
import { SampleDevelopmentPlForm } from '../sampleDevelopmentPl';
import useStyles from './SampleDevelopmentPlApprovalRequestDialog.style';

interface SampleDevPlAppRequestDlgProps {
  handleClose: () => void;
  sampleDevelopmentPl: ISampleDevelopmentPl;
  sampleDevelopmentPlApprovalRequest: SampleDevelopmentPlApprovalRequest;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SampleDevelopmentPlApprovalRequestDialog: React.FC<
  SampleDevPlAppRequestDlgProps
> = props => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const { handleClose, sampleDevelopmentPl, sampleDevelopmentPlApprovalRequest } = props;
  const { openDialog } = useSelector<AppState, SampleDevelopmentPlApprovalRequestState>(
    ({ sampleDevelopmentPlApprovalRequest }) => sampleDevelopmentPlApprovalRequest,
  );

  const sampleDevId = sampleDevelopmentPl.id ? sampleDevelopmentPl.id.toString() : '';
  return (
    <>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Assignment Approval
            </Typography>
            <div className={classes.approveButtonGroup}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const responseRequest = { ...sampleDevelopmentPlApprovalRequest };
                  responseRequest.responseResult = SampleDevelopmentStatus.APPROVED;
                  dispatch(
                    updateSampleDevelopmentPlApprovalRequestResponse(responseRequest),
                  );
                }}
              >
                Approve
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => {
                  const responseRequest = { ...sampleDevelopmentPlApprovalRequest };
                  responseRequest.responseResult = SampleDevelopmentStatus.REJECTED;
                  dispatch(
                    updateSampleDevelopmentPlApprovalRequestResponse(responseRequest),
                  );
                }}
              >
                Reject
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <SampleDevelopmentPlForm id={sampleDevId} />
      </Dialog>
    </>
  );
};

export default SampleDevelopmentPlApprovalRequestDialog;
