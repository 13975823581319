import { PageFilter } from '../state/app';
import { TeamMemberListFilter } from '../state/teamMember';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/teamMembers`;
export const fetchTeamMemberList = async ({
  filters,
  paging,
}: {
  filters: TeamMemberListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const findAllTeamMember = async ({
  filters,
  paging,
}: {
  filters: TeamMemberListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/findAllTeamMember`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};
