import {
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import * as Yup from 'yup';

import ProjectStatus, { ProjectStatusLabel } from '../../../../constants/projectStatus';
import { Company } from '../../../../state/company';
import { AppState } from '../../../../state/configureStore';
import {
  clearProject,
  createProject,
  fetchProject,
  ProjectState,
  updateProject,
} from '../../../../state/project';
import {
  clearProjectCustomer,
  fetchProjectCustomerListBySmProjectId,
  ProjectCustomerState,
} from '../../../../state/projectCustomer';
import {
  clearProjectCustomerGroup,
  fetchProjectCustomerGroupListBySmProjectId,
} from '../../../../state/projectCustomerGroup';
import {
  clearProjectProgram,
  fetchProjectProgramListBySmProjectId,
  ProjectProgramState,
} from '../../../../state/projectProgram';
import { fetchProjectSquadListBySmProjectId } from '../../../../state/projectSquad';
import { User } from '../../../../state/user';
import { CompanyComboBox, PageContainer, PageTitle } from '../../../components';
import Loading from '../../../components/AwLoading';
import GoBackButton from '../../../components/GoBackButton';
import UserComboBox from '../../../components/UserComboBox';
import ApprovalListSection from '../../opportunity/OpportunityForm/ApprovalListSection';
import ProjectFormActionMenu from './ProjectFormActionMenu';
import ProjectSubForm from './ProjectSubForm';

const ProjectSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  ownerId: Yup.number(),
  isHighPriority: Yup.boolean(),
  // companyId: Yup.number().required('Company is required'),
});

interface ProjectFormProps {
  smProjectId?: number;
}

export interface IProjectFormValue {
  name?: string;
  owner?: User | null;
  ownerId?: number | null;
  company?: Company | null;
  companyId?: number | null;
  isHighPriority?: boolean | null;
}

const useProjectStore = () => {
  const { items: projectCustomers } = useSelector<AppState, ProjectCustomerState>(
    (state) => state.projectCustomer,
  );
  const { items: projectCustomerGroups } = useSelector<AppState, ProjectCustomerState>(
    (state) => state.projectCustomerGroup,
  );

  const { items: projectPrograms } = useSelector<AppState, ProjectProgramState>(
    (state) => state.projectProgram,
  );

  const { item: project, loading: projectIsLoading } = useSelector<
    AppState,
    ProjectState
  >((state) => state.project);

  return {
    project,
    projectCustomers,
    projectCustomerGroups,
    projectPrograms,
    isLoading: projectIsLoading,
  };
};

const cleanUpChildValues = (dispatch: Dispatch<any>) => {
  dispatch(clearProjectCustomer());
  dispatch(clearProjectCustomerGroup());
  dispatch(clearProjectProgram());
};

const ProjectForm: React.FC<ProjectFormProps> = (props) => {
  const dispatch = useDispatch();
  const { smProjectId } = props;
  const isUpdateMode = smProjectId !== undefined;

  const { isLoading, project, projectCustomers, projectCustomerGroups } =
    useProjectStore();

  const hasProjectCustomers = projectCustomers.length > 0;
  const hasProjectCustomerGroups = projectCustomerGroups.length > 0;

  const approved = project.status === ProjectStatus.APPROVED;

  const initialFormValues: IProjectFormValue = useMemo(() => {
    const {
      name = '',
      owner,
      ownerId,
      status,
      company,
      companyId,
      id,
      isHighPriority = false,
    } = project;
    return { name, owner, ownerId, status, company, companyId, id, isHighPriority };
  }, [project]);

  useEffect(() => {
    if (isUpdateMode) {
      dispatch(fetchProject(smProjectId!));
    } else {
      dispatch(clearProject());
    }

    return function cleanUp() {
      dispatch(clearProject());
    };
  }, [smProjectId, isUpdateMode, dispatch]);

  useEffect(() => {
    if (isUpdateMode && project.id) {
      dispatch(fetchProjectCustomerListBySmProjectId(project.id));
      dispatch(fetchProjectCustomerGroupListBySmProjectId(project.id));
      dispatch(fetchProjectProgramListBySmProjectId(project.id));
      dispatch(fetchProjectSquadListBySmProjectId(project.id));
    } else {
      cleanUpChildValues(dispatch);
    }
    return function cleanUp() {
      cleanUpChildValues(dispatch);
    };
  }, [dispatch, project, isUpdateMode]);

  const handleSubmit = (values: IProjectFormValue) => {
    if (isUpdateMode) {
      dispatch(updateProject({ values }));
    } else {
      dispatch(createProject({ values }));
    }
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialFormValues}
        validationSchema={ProjectSchema}
        onSubmit={handleSubmit}
        validateOnChange={true}
      >
        {({ values, errors, touched, setFieldValue, setFieldTouched, handleChange }) => {
          const isError = (fieldName: keyof IProjectFormValue) =>
            !!touched[fieldName] && !!errors[fieldName];
          const errorMessage = (fieldName: keyof IProjectFormValue) =>
            !!touched[fieldName] ? errors[fieldName] : '';
          return (
            <Form>
              <PageContainer>
                <PageTitle>
                  <Grid container justifyContent="flex-start" alignItems="center">
                    <Grid item xs={1}>
                      <GoBackButton />
                    </Grid>
                    <Grid item xs={11}>
                      <PageTitle>
                        {smProjectId === undefined
                          ? `New Project`
                          : `Update Project ${project.name}`}
                      </PageTitle>
                    </Grid>
                  </Grid>
                </PageTitle>
                <Loading loading={isLoading} height={400}>
                  <ProjectFormActionMenu project={project} />
                  <CardContent>
                    {/* <CompanyComboBox
                      handleChange={(result: Company) => {
                        setFieldValue('company', result ? result : null);
                        setFieldValue('companyId', result ? result.id : null);
                      }}
                      selectedValue={values.company as Company}
                      isDisabled={hasProjectCustomers || hasProjectCustomerGroups}
                      error={isError('companyId')}
                      helperText={errorMessage('companyId')}
                    /> */}

                    <TextField
                      name="name"
                      type="input"
                      label="Name"
                      margin="dense"
                      fullWidth
                      value={values.name}
                      onChange={handleChange}
                      error={!!errors.name}
                      helperText={errors.name}
                      disabled={approved}
                    />

                    <UserComboBox
                      handleChange={(result: User) => {
                        setFieldValue('owner', result ? result : null);
                        setFieldValue('ownerId', result ? result.id : null);
                      }}
                      isDisabled={approved}
                      selectedValue={values.owner as User}
                      label="Owner"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isHighPriority"
                          checked={values.isHighPriority!}
                          onChange={handleChange}
                          value={values.isHighPriority}
                          disabled={approved}
                        />
                      }
                      label={'Is High Priority'}
                    />
                    {isUpdateMode && (
                      <TextField
                        margin={'dense'}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="filled"
                        fullWidth
                        label={'Status'}
                        value={project.status ? ProjectStatusLabel[project.status!] : ''}
                      />
                    )}
                  </CardContent>
                </Loading>
              </PageContainer>
            </Form>
          );
        }}
      </Formik>
      {isUpdateMode && <ProjectSubForm project={project} />}
    </>
  );
};

export default ProjectForm;
