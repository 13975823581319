import * as Yup from 'yup';
import AwLoading from '../../../components/AwLoading';
import GoBackButton from '../../../components/GoBackButton';
import React, { useEffect, useMemo, useState } from 'react';
import ResponsibilityBrandForm from './ResponsibilityBrandForm';
import ResponsibilityCustomerForm from './ResponsibilityCustomerForm';
import ResponsibilityFormActionMenu from './ResponsibilityFormActionMenu';
import ResponsibilitySalesTeamMemberForm from './ResponsibilitySalesTeamMemberForm';
import ResponsibilityWorkingTeamMemberForm from './ResponsibilityWorkingTeamMemberForm';
import { AppState } from '../../../../state/configureStore';
import { Company } from '../../../../state/company';
import { Program } from '../../../../state/program';
import { ResponsibilityStatusType } from '../../../../constants/responsibilityStatus';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  clearResponsibility,
  fetchResponsibility,
  ResponsibilityState,
  createResponsibility,
  updateResponsibility,
} from '../../../../state/responsibility';
import {
  CompanyComboBox,
  PageContainer,
  PageTitle,
  ProgramComboBox,
} from '../../../components';
import ResponsibilityStatus, {
  ResponsibilityStatusLabel,
} from '../../../../constants/responsibilityStatus';
import ResponsibilityWorkingTeamMemberFormNPD from './ResponsibilityWorkingTeamMemberFormNPD';
import ResponsibilityWorkingTeamMemberFormSSO from './ResponsibilityWorkingTeamMemberFormSSO';

interface ResponsibilityFormProps {
  responsibilityId?: number;
}

const ResponsibilitySchema = Yup.object().shape({
  programId: Yup.number().required('Program is required'),
  companyId: Yup.number().required('Company is required'),
});

interface ResponsibilityFormValues {
  company?: Company | null;
  companyId?: number | null;
  program?: Program | null;
  programId?: number | null;
  status?: ResponsibilityStatusType;
  isHighPriorityProgram?: boolean | null;
}

const ResponsibilityForm: React.FC<ResponsibilityFormProps> = (props) => {
  const dispatch = useDispatch();
  const { responsibilityId } = props;
  const isUpdateMode = responsibilityId !== undefined;

  const { item: responsibility, loading: isLoading } = useSelector<
    AppState,
    ResponsibilityState
  >((state) => state.responsibility);
  const [isHigh, setIsHigh] = useState(false);

  const approvedOrPendingForUnlock =
    responsibility.status === ResponsibilityStatus.APPROVED ||
    responsibility.status === ResponsibilityStatus.PENDING_FOR_UNLOCK_APPROVED;
  //const isAllRecordSaved = localProjectCustomers.findIndex(opc => !opc.id) === -1;

  const initialFormValues: ResponsibilityFormValues = useMemo(() => {
    const { company, companyId, program, programId, status } = responsibility;
    return {
      company,
      companyId,
      program,
      programId,
      status,
    };
  }, [responsibility]);

  useEffect(() => {
    if (isUpdateMode && responsibilityId) {
      dispatch(fetchResponsibility(responsibilityId!));
    } else {
      dispatch(clearResponsibility());
    }

    return function cleanUp() {
      dispatch(clearResponsibility());
    };
  }, [responsibilityId, isUpdateMode, dispatch]);

  const handleSubmit = (values: any) => {
    if (!isUpdateMode) {
      dispatch(createResponsibility({ values }));
    } else {
      dispatch(updateResponsibility({ values }));
    }
  };

  useEffect(() => {
    setIsHigh(responsibility?.program?.isHighPriority!);
  }, [responsibility]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialFormValues}
        validationSchema={ResponsibilitySchema}
        onSubmit={handleSubmit}
        validateOnChange={true}
      >
        {({ values, errors, touched, setFieldValue, setFieldTouched, handleChange }) => {
          const isError = (fieldName: keyof ResponsibilityFormValues) =>
            !!touched[fieldName] && !!errors[fieldName];
          const errorMessage = (fieldName: keyof ResponsibilityFormValues) =>
            !!touched[fieldName] ? errors[fieldName] : '';

          return (
            <Form>
              <PageContainer>
                <PageTitle>
                  <Grid container justifyContent="flex-start" alignItems="center">
                    <Grid item xs={1}>
                      <GoBackButton />
                    </Grid>
                    <Grid item xs={11}>
                      <PageTitle>
                        {(responsibilityId === undefined
                          ? `New Responsibility`
                          : `Update Responsibility`) +
                          ' ' +
                          (responsibilityId || '')}
                      </PageTitle>
                    </Grid>
                  </Grid>
                </PageTitle>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ paddingLeft: 20 }}
                >
                  <Typography variant="h6">
                    {' '}
                    {responsibility?.createdBy
                      ? `Created By : ${responsibility?.createdBy}`
                      : ''}
                  </Typography>
                </Grid>
                <AwLoading loading={isLoading} height={400}>
                  <ResponsibilityFormActionMenu responsibility={responsibility} />
                  <CardContent>
                    <Typography variant="h6" component="h6">
                      General Information
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <CompanyComboBox
                          handleChange={(result: Company) => {
                            setFieldValue('company', result ? result : null);
                            setFieldValue('companyId', result ? result.id : null);
                          }}
                          selectedValue={values.company as Company}
                          error={isError('companyId')}
                          helperText={errorMessage('companyId')}
                          isDisabled={isUpdateMode}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <ProgramComboBox
                          handleChange={(result: Program) => {
                            setFieldValue('program', result ? result : null);
                            setFieldValue('programId', result ? result.id : null);
                            setIsHigh(result ? result.isHighPriority : false);
                          }}
                          selectedValue={values.program as Program}
                          error={isError('programId')}
                          helperText={errorMessage('programId')}
                          isDisabled={isUpdateMode}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={responsibility?.program?.isHighPriority || isHigh}
                            />
                          }
                          label={'Is High Priority Program'}
                          style={{ paddingTop: '25px' }}
                        />
                      </Grid>
                    </Grid>

                    {isUpdateMode && (
                      <>
                        <TextField
                          margin={'dense'}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="filled"
                          fullWidth
                          label={'Status'}
                          value={
                            responsibility.status
                              ? ResponsibilityStatusLabel[responsibility?.status]
                              : ''
                          }
                        />
                        <ResponsibilityBrandForm
                          responsibilityId={responsibilityId}
                          disabled={approvedOrPendingForUnlock}
                        />
                        <ResponsibilityCustomerForm
                          responsibilityId={responsibilityId}
                          companyId={values.companyId!}
                          disabled={approvedOrPendingForUnlock}
                        />
                        <ResponsibilitySalesTeamMemberForm
                          responsibilityId={responsibilityId}
                          disabled={approvedOrPendingForUnlock}
                        />

                        <ResponsibilityWorkingTeamMemberFormSSO
                          responsibilityId={responsibilityId}
                          disabled={approvedOrPendingForUnlock}
                        />

                        <ResponsibilityWorkingTeamMemberForm
                          responsibilityId={responsibilityId}
                          disabled={approvedOrPendingForUnlock}
                        />

                        <ResponsibilityWorkingTeamMemberFormNPD
                          responsibilityId={responsibilityId}
                          disabled={approvedOrPendingForUnlock}
                        />
                      </>
                    )}
                  </CardContent>
                </AwLoading>
              </PageContainer>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ResponsibilityForm;
