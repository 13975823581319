import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Block, BlockState, createBlocks, IBlockCreate } from '../../../state/block';
import { BlockPrefix } from '../../../state/blockPrefix';
import { Brand } from '../../../state/brand';
import { AppState } from '../../../state/configureStore';
import { ProductType } from '../../../state/productType';
import { Program } from '../../../state/program';
import { User } from '../../../state/user';
import BlockPrefixComboBox from '../BlockPrefixComboBox';
import BrandComboBox from '../BrandComboBox';
import ProductTypeComboBox from '../ProductTypeComboBox';
import ProgramComboBox from '../ProgramComboBox';
import UserComboBox from '../UserComboBox';
import useStyles from './BlockCreateDialog.style';
import BlockCreateSummary from './BlockCreateSummary';
import BlockItemAndItemDescriptionTable from './BlockItemAndItemDescriptionTable';
import ConfirmTable from './ConfirmTable';
import BlockHanesProjectTable from './BlockHanesProjectTable';
import HanesProjectComboBox from '../HanesProjectComboBox';
import { TransitionProps } from '@material-ui/core/transitions';
import { HanesProject } from '../../../state/hanesProject';

interface BlockCreateDialogProps {
  handleClose: () => void;
  open: boolean;
  program: Program;
  brand: Brand;
  companyMakefor: string;
  handleCreatedBlock: (/*user: User,*/ createdBlock: Array<Block>) => void;
}

type dialogStep =
  | 'fillBlockDetail'
  | 'selectNpdUser'
  | 'confirmBlockDetail'
  | 'createBlock'
  | 'fillHanesProject';

const BlockCreateSchema = Yup.object().shape({
  productTypeId: Yup.number()
    .moreThan(0, 'Product Type is required')
    .required('Product Type is required'),
  programId: Yup.number()
    .moreThan(0, 'Program is required')
    .required('Program is required'),
  brandId: Yup.number().moreThan(0, 'Brand is required').required('Brand is required'),
  blockPrefixId: Yup.number()
    .moreThan(0, 'Prefix is required')
    .required('Prefix is required'),
  itemAndItemDescriptions: Yup.array()
    .of(
      Yup.object().shape({
        item: Yup.string().required('item is required'),
        itemDescription: Yup.string(),
      }),
    )
    .min(1, 'item is required'),
  // userId: Yup.number().moreThan(0, 'User is required').required('User is required'),
});

interface IBlockItemAndItemDescription {
  item: string;
  itemDescription: string;
  rowIndex: string;
  hanesProjectName: string;
}

export interface ICreateBlockForm {
  productType?: ProductType;
  productTypeId?: ProductType['id'];
  programId?: Program['id'];
  program?: Program;
  brandId?: Brand['id'];
  brand?: Brand;
  blockPrefixId?: BlockPrefix['id'];
  blockPrefix?: BlockPrefix;
  itemAndItemDescriptions?: IBlockItemAndItemDescription[];
  userId?: User['id'];
  user?: Partial<User> | null;
  isThermalPrinting: boolean;
  companyMakefor?: string | null;
}

const defaultBlockFormState = {
  isThermalPrinting: false,
  productTypeId: 0,
  blockPrefixId: 0,
  programId: 0,
  brandId: 0,
  itemAndItemDescriptions: [],
  userId: 0,
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

const BlockCreateDialog: React.FC<BlockCreateDialogProps> = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { open, handleClose, program, brand, handleCreatedBlock, companyMakefor } = props;

  const [blockCreateStep, setBlockCreateStep] = useState<dialogStep>('fillBlockDetail');
  const [blockCreates, setBlockCreates] = useState<IBlockCreate[]>([]);
  const { loading: blockIsLoading, createBlocks: createdBlocks } = useSelector<
    AppState,
    BlockState
  >((state) => state.block);

  const resetToDefaultState = () => {
    setBlockCreateStep('fillBlockDetail');
    setBlockCreates([]);
  };

  const handleSubmit = (
    values: ICreateBlockForm,
    actions: FormikHelpers<ICreateBlockForm>,
  ) => {
    const blocks: Block[] = values!.itemAndItemDescriptions!.map(
      ({ item, itemDescription, hanesProjectName }) => ({
        item,
        program: values.program,
        programId: values.programId,
        brand: values.brand,
        brandId: values.brandId,
        productType: values.productType,
        productTypeId: values.productTypeId,
        blockPrefix: values.blockPrefix,
        blockPrefixId: values.blockPrefixId,
        itemDescription,
        blockSuffix: values.isThermalPrinting ? '.TM' : '',
        companyMakefor: values.companyMakefor,
        hanesProjectName,
      }),
    );

    const newBlockCreates = blocks.map((block) => ({ block }));

    setBlockCreates(newBlockCreates);

    const isFCW =
      newBlockCreates[0].block.program?.name.substring(0, 3) === 'FCW' ||
      newBlockCreates[0].block.program?.name === 'HANES';

    if (isFCW) {
      setBlockCreateStep('fillHanesProject');
    } else {
      setBlockCreateStep('confirmBlockDetail');
    }

    actions.setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...defaultBlockFormState,
        program,
        brand,
        programId: program.id,
        brandId: brand.id,
        companyMakefor: companyMakefor,
      }}
      validationSchema={BlockCreateSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps: FormikProps<ICreateBlockForm>) => {
        const { values, errors, touched, submitForm, setFieldValue, handleReset } =
          formikProps;
        return (
          <Form>
            <Dialog
              TransitionComponent={Transition}
              classes={{ paper: classes.dialog }}
              open={open}
              onClose={handleClose}
              onExiting={() => handleReset()}
              maxWidth="lg"
              fullWidth={true}
            >
              {blockCreateStep === 'fillBlockDetail' && (
                <>
                  <DialogTitle>Block Create</DialogTitle>
                  <DialogContent>
                    <ProgramComboBox
                      handleChange={(result) => {
                        setFieldValue('program', result ? result : null);
                        setFieldValue('programId', result ? result.id : 0);
                      }}
                      selectedValue={values.program as Program}
                      isDisabled={!!program}
                      error={!!errors.programId && touched.programId}
                      helperText={errors.programId}
                    />
                    <BrandComboBox
                      handleChange={(result) => {
                        setFieldValue('brand', result ? result : null);
                        setFieldValue('brandId', result ? result.id : 0);
                      }}
                      selectedValue={values.brand as Brand}
                      isDisabled={!!brand}
                      error={!!errors.brandId && touched.brandId}
                      helperText={errors.brandId}
                    />
                    <ProductTypeComboBox
                      handleChange={(result) => {
                        setFieldValue('productType', result ? result : null);
                        setFieldValue('productTypeId', result ? result.id : 0);
                        setFieldValue('blockPrefix', null);
                        setFieldValue('blockPrefixId', 0);
                      }}
                      selectedValue={values.productType as ProductType}
                      error={!!errors.productTypeId && touched.productTypeId}
                      helperText={errors.productTypeId}
                    />
                    <BlockPrefixComboBox
                      productTypeId={values.productTypeId as number}
                      handleChange={(result) => {
                        setFieldValue('blockPrefix', result ? result : null);
                        setFieldValue('blockPrefixId', result ? result.id : 0);
                      }}
                      selectedValue={values.blockPrefix as BlockPrefix}
                      error={!!errors.blockPrefixId && touched.blockPrefixId}
                      helperText={errors.blockPrefixId}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isThermalPrinting}
                          onChange={(e) =>
                            setFieldValue('isThermalPrinting', e.target.checked)
                          }
                          value="isThermalPrinting"
                        />
                      }
                      label="Use Thermal Printing (.TM Block)?"
                    />
                    {/* <UserComboBox
                      handleChange={(result: User) => {
                        setFieldValue('user', result ? result : null);
                        setFieldValue('userId', result ? result.id : 0);
                      }}
                      selectedValue={values.user as User}
                      label={'Sample Dev/NPD'}
                      error={!!errors.userId && touched.userId}
                      helperText={errors.userId}
                    /> */}
                    <FieldArray
                      name="itemAndItemDescriptions"
                      render={(arrayHelpers) => (
                        <BlockItemAndItemDescriptionTable
                          onChange={(newRowValue) => {
                            values.itemAndItemDescriptions!.forEach(
                              (itemAndItemDescription, index) => {
                                if (
                                  itemAndItemDescription.rowIndex === newRowValue.rowIndex
                                ) {
                                  arrayHelpers.replace(index, newRowValue);
                                }
                              },
                            );
                          }}
                          onAdd={(newRowValue) => {
                            arrayHelpers.push(newRowValue);
                          }}
                          onDelete={(selectedRowIndex) => {
                            if (selectedRowIndex.length > 0) {
                              const newRowValues = values.itemAndItemDescriptions!.filter(
                                (_, index) => !selectedRowIndex.includes(index),
                              );
                              setFieldValue('itemAndItemDescriptions', newRowValues);
                            }
                          }}
                          onPaste={(newRowValues) => {
                            setFieldValue(
                              'itemAndItemDescriptions',
                              newRowValues ? newRowValues : null,
                            );
                          }}
                          value={values.itemAndItemDescriptions!}
                        />
                      )}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                    <Button
                      variant={'contained'}
                      onClick={() => {
                        submitForm();
                      }}
                      color="primary"
                    >
                      Next
                    </Button>
                  </DialogActions>
                </>
              )}
              {blockCreateStep === 'fillHanesProject' && (
                <>
                  <DialogTitle>Hanes Project</DialogTitle>
                  <DialogContent>
                    {/* <BlockHanesProjectTable blockCreates={blockCreates} /> */}
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Item</TableCell>
                          <TableCell>Hanes Project</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {blockCreates.map((blockCreate, index) => {
                          const { block } = blockCreate;
                          const hanesProject = {
                            name: block?.hanesProjectName,
                            item: block?.item,
                          };
                          return (
                            <TableRow key={index}>
                              <TableCell>
                                <TextField
                                  style={{ marginTop: '9px' }}
                                  id="item"
                                  // label="Item"
                                  value={block?.item}
                                  size="small"
                                  fullWidth
                                />
                              </TableCell>
                              <TableCell>
                                <HanesProjectComboBox
                                  handleChange={(result) => {
                                    blockCreates[index].block!.hanesProjectName =
                                      result?.name;

                                    const ddddd = blockCreates.map((s) => s);

                                    setBlockCreates(ddddd);
                                  }}
                                  selectedValue={hanesProject as HanesProject}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setBlockCreateStep('fillBlockDetail');
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      variant={'contained'}
                      onClick={() => {
                        setBlockCreateStep('confirmBlockDetail');
                      }}
                      color="primary"
                    >
                      Next
                    </Button>
                  </DialogActions>
                </>
              )}
              {blockCreateStep === 'confirmBlockDetail' && (
                <>
                  <DialogTitle>Summary Create Blocks</DialogTitle>
                  <DialogContent>
                    <BlockCreateSummary formValues={values} />
                    <ConfirmTable blockCreates={blockCreates} />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setBlockCreateStep('fillBlockDetail');
                      }}
                      color="primary"
                    >
                      Back
                    </Button>
                    <Button
                      variant={'contained'}
                      onClick={() => {
                        dispatch(createBlocks(blockCreates));
                        setBlockCreateStep('createBlock');
                      }}
                      color="primary"
                    >
                      Submit to Create Block
                    </Button>
                  </DialogActions>
                </>
              )}
              {blockCreateStep === 'createBlock' && blockIsLoading && (
                <>
                  <DialogTitle>Creating Blocks</DialogTitle>
                  <DialogContent className={classes.loadingContainer}>
                    <CircularProgress size={50} />
                    <DialogContentText className={classes.loadingMessage}>
                      Creating Blocks...
                    </DialogContentText>
                  </DialogContent>
                </>
              )}
              {blockCreateStep === 'createBlock' && !blockIsLoading && (
                <>
                  <DialogTitle>Created Blocks</DialogTitle>
                  <DialogContent>
                    <BlockCreateSummary formValues={values} />
                    <ConfirmTable blockCreates={createdBlocks} />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant={'contained'}
                      onClick={() => {
                        handleClose();
                        handleCreatedBlock(
                          // values.user!,
                          createdBlocks
                            .filter((cb) => !cb.hasError && !!cb.block)
                            .map((cb) => cb.block!),
                        );
                        resetToDefaultState();
                      }}
                      color="primary"
                    >
                      Confirm
                    </Button>
                  </DialogActions>
                </>
              )}
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BlockCreateDialog;
