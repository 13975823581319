import { Grid } from '@material-ui/core';
import React from 'react';
import useStyles from './sampleDevelopmentNl.style';
import { CheckBoxFastField, TextFieldFastField } from '../../../components';

interface SamDevNlPrintingBackProps {}

const SampleDevelopmentNlPrintingBack: React.FC<SamDevNlPrintingBackProps> = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <CheckBoxFastField name="printingBack" label="Back" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2}>
          1. Liner
        </Grid>
        <Grid item xs={2}>
          2. Adhesive
        </Grid>
        <Grid item xs={2}>
          1. Color
        </Grid>
        <Grid item xs={2}>
          2. Color
        </Grid>
        <Grid item xs={2}>
          1. Pantone
        </Grid>
        <Grid item xs={2}>
          2. Pantone
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2}>
          <CheckBoxFastField name="printingBLProcess" label="Process" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="printingBAProcess" label="Process" />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBLProcessColor"
            label="Color"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBAProcessColor"
            label="Color"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBLProcessPantone"
            label="Pantone"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBAProcessPantone"
            label="Pantone"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2}>
          <CheckBoxFastField name="printingBLSolid" label="Solid" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="printingBASolid" label="Solid" />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBLSolidColor"
            label="Color"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBASolidColor"
            label="Color"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBLSolidPantone"
            label="Pantone"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBASolidPantone"
            label="Pantone"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2}>
          <CheckBoxFastField name="printingBLMetalic" label="Metalic" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="printingBAMetalic" label="Metalic" />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBLMetalicColor"
            label="Color"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBAMetalicColor"
            label="Color"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBLMetalicPantone"
            label="Pantone"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBAMetalicPantone"
            label="Pantone"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2}>
          <CheckBoxFastField name="printingBLFoil" label="Foil" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="printingBAFoil" label="Foil" />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBLFoilColor"
            label="Color"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBAFoilColor"
            label="Color"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBLFoilPantone"
            label="Pantone"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBAFoilPantone"
            label="Pantone"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={2}>
          <CheckBoxFastField name="printingBLOther" label="Other" />
        </Grid>
        <Grid item xs={2}>
          <CheckBoxFastField name="printingBAOther" label="Other" />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBLOtherColor"
            label="Color"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBAOtherColor"
            label="Color"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBLOtherPantone"
            label="Pantone"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="printingBAOtherPantone"
            label="Pantone"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SampleDevelopmentNlPrintingBack;
