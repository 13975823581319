import { AppAction, PageFilter } from '../app';
import {
  SalesTeam,
  SalesTeamCreateOrUpdatePayload,
  SalesTeamListFilter,
} from './salesTeam.types';

export const FETCH_SALESTEAM = 'FETCH_SALESTEAM';
export const FETCH_SALESTEAM_LIST = 'FETCH_SALESTEAM_LIST';
export const CREATE_SALESTEAM = 'CREATE_SALESTEAM';
export const UPDATE_SALESTEAM = 'UPDATE_SALESTEAM';
export const CLEAR_SALESTEAM = 'CLEAR_SALESTEAM';

export const fetchSalesTeam = (id: SalesTeam['id']): AppAction => ({
  type: FETCH_SALESTEAM,
  payload: id,
});

export const fetchSalesTeamList = (
  filters: SalesTeamListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SALESTEAM_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createSalesTeam = (payload: SalesTeamCreateOrUpdatePayload): AppAction => ({
  type: CREATE_SALESTEAM,
  payload,
});

export const updateSalesTeam = (payload: SalesTeamCreateOrUpdatePayload): AppAction => ({
  type: UPDATE_SALESTEAM,
  payload,
});

export const clearSalesTeam = () => ({
  type: CLEAR_SALESTEAM,
});
