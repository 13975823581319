import React from 'react';

export interface ShowProps {
  show: boolean
}

const Show: React.FC<ShowProps> = (props) => {
  const { show, children } = props;
  if (show) {
    return <>
      {children}
    </>;
  }
  return null;
};
export default Show;
