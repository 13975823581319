import React, { useCallback, useEffect, useState } from 'react';
import {
  BaseSearchItemAndAddMultipleBlockStepProps,
  SearchItemAndAddMultipleBlockStep,
} from '../SearchItemAndAddMultipleBlockDialog';
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Loading, Show } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../state/configureStore';
import {
  SearchItemAndAddMultipleBlockState,
  SearchItemResult,
} from '../searchItemAndAddMultipleBlockDialog.reducer';
import SelectBlockForSearchItemButton from './SelectBlockForSearchItemButton';
import {
  createOpportunityItemFromSearchItemResults,
  CreateOpportunityItemPayload,
  setForceCreateItems,
} from '../searchItemAndAddMultipleBlockDialog.actions';
import UserComboBox from '../../../../components/UserComboBox';
import { Form, Formik } from 'formik';
import { User } from '../../../../../state/user';
import * as Yup from 'yup';
import { OpportunityState } from '../../../../../state/opportunity';
import { Block } from '../../../../../state/block';
import SearchItemAndAddMultipleBlockStepper from '../SearchItemAndAddMultipleBlockStepper';
import ResolveNonQualifiedBlockButton from './ResolveNonQualifiedBlockButton';
import {
  selectSearchItemAndAddMultipleBlock,
  selectSearchItemResultWhereBlockNotFound,
} from '../searchItemAndAddMultipleBlockDialog.selector';
import {
  NoneQualifiedItemResolution,
  NoneQualifiedItemResolutionLabel,
} from '../../../../../constants';

interface SearchResultStepFormValues {
  user?: User;
  listOfFoundBlocks: ReadonlyArray<SearchItemResult>;
  listOfNoneQualifiedBlocks: ReadonlyArray<NoneQualifiedItemAnswer>;
}

interface NoneQualifiedItemAnswer {
  searchItemResult: SearchItemResult;
  choice?: NoneQualifiedItemResolution;
}

const SearchResultStepFormValidationSchema = Yup.object().shape({
  // user: Yup.object()
  //   .nullable(true)
  //   .required('Sample Dev/NPD User is required'),
  listOfFoundBlocks: Yup.array().of(
    Yup.object().shape({
      selectedBlock: Yup.object().nullable(true).required('Please Choose block'),
    }),
  ),
  listOfNoneQualifiedBlocks: Yup.array().of(
    Yup.object().shape({
      choice: Yup.string().nullable(true).required('Please select choice'),
    }),
  ),
});

const SearchResultStep: React.FC<BaseSearchItemAndAddMultipleBlockStepProps> = (
  props,
) => {
  const dispatch = useDispatch();
  const { onClose, onChangeStep, program, brand } = props;
  const { item: opportunity } = useSelector<AppState, OpportunityState>(
    (s) => s.opportunity,
  );
  const { loading, searchResults } = useSelector<
    AppState,
    SearchItemAndAddMultipleBlockState
  >(selectSearchItemAndAddMultipleBlock);
  const listOfNotFoundBlocks = useSelector(selectSearchItemResultWhereBlockNotFound);
  const isSameProgramAndBrand = useCallback(
    (b: Block) => b.programId === program.id && b.brandId === brand.id,
    [program, brand],
  );
  const hasAtLeastOneBlockFromSameProgramAndBrand = useCallback(
    (searchItemResult: SearchItemResult) =>
      searchItemResult.blocks.filter(isSameProgramAndBrand).length > 0,
    [isSameProgramAndBrand],
  );
  const [listOfFoundBlocks, setListOfFoundBlocks] = useState<
    ReadonlyArray<SearchItemResult>
  >([]);
  const listOfNoneQualifiedBlocks: ReadonlyArray<NoneQualifiedItemAnswer> = searchResults
    .filter((s) => s.blocks.length > 0 && !hasAtLeastOneBlockFromSameProgramAndBrand(s))
    .map((searchItemResult) => ({ searchItemResult }));

  useEffect(() => {
    const foundBlocks = searchResults.filter(hasAtLeastOneBlockFromSameProgramAndBrand);
    foundBlocks.forEach((searchResult) => {
      const { blocks } = searchResult;
      const blocksWithSameProgramAndBrand = blocks.filter(isSameProgramAndBrand);
      if (blocksWithSameProgramAndBrand.length === 1) {
        searchResult.selectedBlock = blocksWithSameProgramAndBrand[0];
      }
    });

    setListOfFoundBlocks([...foundBlocks]);
  }, [
    searchResults,
    setListOfFoundBlocks,
    hasAtLeastOneBlockFromSameProgramAndBrand,
    isSameProgramAndBrand,
  ]);

  const onSubmit = (values: SearchResultStepFormValues) => {
    const { user } = values;
    const payload: CreateOpportunityItemPayload = {
      searchItemResults: values.listOfFoundBlocks,
      opportunityId: opportunity.id!,
    };

    // if (program.isUseNpd) {
    //   payload.npdUserId = user!.id;
    // } else {
    //   payload.sampleDevUserId = user!.id;
    // }

    const forceCreateItems = values.listOfNoneQualifiedBlocks
      .filter((ans) => ans.choice === NoneQualifiedItemResolution.forceCreate)
      .map((ans) => ans.searchItemResult.itemText);

    dispatch(setForceCreateItems(forceCreateItems));
    dispatch(createOpportunityItemFromSearchItemResults(payload));
    onChangeStep(SearchItemAndAddMultipleBlockStep.Summary);
  };

  return (
    <>
      <Loading isLoading={loading}>
        <Formik
          enableReinitialize={true}
          initialValues={
            { listOfFoundBlocks, listOfNoneQualifiedBlocks } as SearchResultStepFormValues
          }
          onSubmit={onSubmit}
          validationSchema={SearchResultStepFormValidationSchema}
        >
          {(formikProps) => {
            const { setFieldValue, values, errors } = formikProps;
            return (
              <Form>
                <DialogContent>
                  <SearchItemAndAddMultipleBlockStepper
                    activeStep={SearchItemAndAddMultipleBlockStep.SearchResult}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {/* <UserComboBox
                        label={'Sample Dev/NPD'}
                        handleChange={(user: User) => {
                          setFieldValue('user', user);
                        }}
                        selectedValue={values.user as User}
                        error={!!errors.user}
                        helperText={errors.user ? errors.user : ''}
                      /> */}
                    </Grid>
                    <Show show={values.listOfFoundBlocks.length > 0}>
                      <Grid item xs={12}>
                        <Typography variant={'h5'}>List of Found Blocks</Typography>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Item Name</TableCell>
                              <TableCell>List of Blocks</TableCell>
                              <TableCell colSpan={2}>Select Block</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.listOfFoundBlocks.map((searchItemResult, index) => (
                              <TableRow key={index}>
                                <TableCell>{searchItemResult.itemText}</TableCell>
                                <TableCell>
                                  {searchItemResult.blocks
                                    .map((b) => b.blockNo)
                                    .join(', ')}
                                </TableCell>
                                <TableCell>
                                  {
                                    <SelectBlockForSearchItemButton
                                      searchItemResult={searchItemResult}
                                      onChange={(block) => {
                                        const selectedBlock = `listOfFoundBlocks[${index}].selectedBlock` as any;
                                        setFieldValue(selectedBlock, block);
                                      }}
                                      program={program}
                                      brand={brand}
                                    />
                                  }
                                </TableCell>
                                <TableCell>
                                  <Typography color={'error'}>
                                    {errors.listOfFoundBlocks &&
                                    errors.listOfFoundBlocks[index]
                                      ? errors.listOfFoundBlocks[index]!.selectedBlock
                                      : ''}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Show>
                    <Show show={values.listOfNoneQualifiedBlocks.length > 0}>
                      <Grid item xs={12}>
                        <Typography variant={'h5'}>
                          List of none Qualified Blocks
                        </Typography>
                        <Typography variant={'caption'}>
                          Block with same item is found but doesn't belongs to same
                          Program/Brand
                        </Typography>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Item Name</TableCell>
                              <TableCell>Action</TableCell>
                              <TableCell colSpan={2} />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.listOfNoneQualifiedBlocks.map(
                              (noneQualifiedItemAnswer, index) => {
                                const {
                                  searchItemResult,
                                  choice,
                                } = noneQualifiedItemAnswer;
                                const errorMessage =
                                  errors.listOfNoneQualifiedBlocks &&
                                  errors.listOfNoneQualifiedBlocks[index]
                                    ? errors.listOfNoneQualifiedBlocks[index]!.choice
                                    : '';
                                return (
                                  <TableRow key={index}>
                                    <TableCell>{searchItemResult.itemText}</TableCell>
                                    <TableCell>
                                      <ResolveNonQualifiedBlockButton
                                        searchItemResult={searchItemResult}
                                        onAnswer={(answer) => {
                                          const choice = `listOfNoneQualifiedBlocks[${index}].choice` as any;
                                          setFieldValue(choice, answer);
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      {NoneQualifiedItemResolutionLabel[choice!]}
                                    </TableCell>
                                    <TableCell>
                                      <Typography color={'error'}>
                                        {errorMessage}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                );
                              },
                            )}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Show>
                    <Show show={listOfNotFoundBlocks.length > 0}>
                      <Grid item xs={12}>
                        <Typography variant={'h5'}>List of Not Found Blocks</Typography>
                        <Typography variant={'caption'}>
                          Block with same item is not found, needs to be create.
                        </Typography>
                        <TextField
                          variant={'outlined'}
                          fullWidth
                          multiline
                          rows={10}
                          rowsMax={50}
                          inputProps={{
                            readOnly: true,
                          }}
                          value={listOfNotFoundBlocks.map((s) => s.itemText).join('\n')}
                        />
                      </Grid>
                    </Show>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose}>Close</Button>
                  <Button
                    onClick={() =>
                      onChangeStep(SearchItemAndAddMultipleBlockStep.InputItem)
                    }
                  >
                    Back
                  </Button>
                  <Button variant={'contained'} type={'submit'} color={'primary'}>
                    Next
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Loading>
    </>
  );
};

export default SearchResultStep;
