import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { FieldArray, Form, Formik, FormikHelpers, FormikProps, getIn } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { BrandComboBox, ProductTypeComboBox, ProgramComboBox } from '..';
import { IEBlockGroupProduct } from '../../../models/eBlockGroupProduct';
import { IEBlockMaterial } from '../../../models/eBlockMaterial.model';
import { IEBlockPattern } from '../../../models/eBlockPattern';
import { IEBlockSize } from '../../../models/eBlockSize';
import { Block, BlockState, createEBlocks, IBlockCreate } from '../../../state/block';
import { Brand } from '../../../state/brand';
import { AppState } from '../../../state/configureStore';
import { ProductType } from '../../../state/productType';
import { Program } from '../../../state/program';
import { User } from '../../../state/user';
import BlockCreateSummary from '../BlockCreateDialog/BlockCreateSummary';
import EBlockGroupProductComboBox from '../EBlockGroupProductComboBox';
import EBlockInterCompProdTypeCombo from '../EBlockInterCompanyProductType';
import EBlockMaterialComboBox from '../EBlockMaterialComboBox';
import EBlockPatternComboBox from '../EBlockPatternComboBox';
import EBlockSizeComboBox from '../EBlockSizeComboBox';
import UserComboBox from '../UserComboBox';
import EBlockConfirmTable from './EBlockConfirmTable';
import useStyles from './eBlockCreateDialog.style';
import EBlockCreateSummary from './EBlockCreateSummary';

interface EBlockCreateDialogProps {
  handleClose: () => void;
  open: boolean;
  program: Program;
  brand: Brand;
  handleCreatedBlock: (user: User, createdBlock: Block[]) => void;
}

const EBlockCreateSchema = Yup.object().shape({
  programId: Yup.number()
    .moreThan(0, 'Program is required')
    .required('Program is required'),
  brandId: Yup.number()
    .moreThan(0, 'Brand is required')
    .required('Brand is required'),
  itemAndItemDescriptions: Yup.array()
    .of(
      Yup.object().shape({
        item: Yup.string().required('item is required'),
        itemDescription: Yup.string(),
        eBlockGroupProductId: Yup.number().required('group product is required'),
        eBlockMaterialId: Yup.number().required('material is required'),
        eBlockSizeId: Yup.number().required('size is required'),
        eBlockPatternId: Yup.number().required('pattern is required'),
        interCompanyProductTypeId: Yup.number().required(
          'inter company product type is required',
        ),
        blockArtTemplateQty: Yup.number().required('art template qty is required'),
      }),
    )
    .min(1, 'item is required'),
  userId: Yup.number()
    .moreThan(0, 'User is required')
    .required('User is required'),
});

interface IBlockItemAndItemDescription {
  item: string;
  itemDescription: string;
  eBlockGroupProductId: number;
  eBlockGroupProduct: IEBlockGroupProduct;
  eBlockMaterialId: number;
  eBlockMaterial: IEBlockMaterial;
  eBlockSizeId: number;
  eBlockSize: IEBlockSize;
  eBlockPatternId: number;
  eBlockPattern: IEBlockPattern;
  interCompanyProductTypeId: number;
  interCompanyProductType: ProductType;
  blockArtTemplateQty: number;
}

export interface ICreateEBlockForm {
  programId?: Program['id'];
  program?: Program;
  brandId?: Brand['id'];
  brand?: Brand;
  itemAndItemDescriptions?: IBlockItemAndItemDescription[];
  userId?: User['id'];
  user?: Partial<User> | null;
}

const initEBlockRow = {
  blockArtTemplateQty: 0,
};

const defaultEBlockFormState = {
  blockPrefixId: 0,
  programId: 0,
  brandId: 0,
  itemAndItemDescriptions: [],
  userId: 0,
};

type dialogStep =
  | 'fillEBlockDetail'
  | 'selectNpdUser'
  | 'confirmEBlockDetail'
  | 'createEBlock';

const EBlockCreateDialog: React.FC<EBlockCreateDialogProps> = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [eBlockCreateStep, setEBlockCreateStep] = useState<dialogStep>(
    'fillEBlockDetail',
  );
  const [eBlockCreates, setEBlockCreates] = useState<IBlockCreate[]>([]);
  const { open, handleClose, program, brand, handleCreatedBlock } = props;
  const { loading: blockIsLoading, createBlocks: createdBlocks } = useSelector<
    AppState,
    BlockState
  >(state => state.block);

  const resetToDefaultState = () => {
    setEBlockCreateStep('fillEBlockDetail');
    setEBlockCreates([]);
  };

  const handleSubmit = (
    values: ICreateEBlockForm,
    actions: FormikHelpers<ICreateEBlockForm>,
  ) => {
    const blocks: Block[] = values!.itemAndItemDescriptions!.map(
      ({
        item,
        itemDescription,
        eBlockGroupProductId,
        eBlockGroupProduct,
        eBlockMaterialId,
        eBlockMaterial,
        eBlockSizeId,
        eBlockSize,
        eBlockPatternId,
        eBlockPattern,
        interCompanyProductTypeId,
        interCompanyProductType,
        blockArtTemplateQty,
      }) => ({
        item,
        program: values.program,
        programId: values.programId,
        brand: values.brand,
        brandId: values.brandId,
        itemDescription,
        eBlockGroupProductId,
        eBlockGroupProduct,
        eBlockMaterialId,
        eBlockMaterial,
        eBlockSizeId,
        eBlockSize,
        eBlockPatternId,
        eBlockPattern,
        interCompanyProductTypeId,
        interCompanyProductType,
        blockArtTemplateQty,
      }),
    );

    const newBlockCreates = blocks.map(block => ({ block }));
    setEBlockCreates(newBlockCreates);
    setEBlockCreateStep('confirmEBlockDetail');
    actions.setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...defaultEBlockFormState,
        program,
        brand,
        programId: program.id,
        brandId: brand.id,
      }}
      validationSchema={EBlockCreateSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps: FormikProps<ICreateEBlockForm>) => {
        const {
          values,
          errors,
          touched,
          submitForm,
          setFieldValue,
          handleReset,
        } = formikProps;
        return (
          <Form>
            <Dialog
              classes={{ paper: classes.dialog }}
              open={open}
              onClose={handleClose}
              onExiting={() => handleReset()}
              maxWidth="xl"
              fullWidth={true}
            >
              {eBlockCreateStep === 'fillEBlockDetail' && (
                <>
                  <DialogTitle>EBlock Create</DialogTitle>
                  <DialogContent>
                    <ProgramComboBox
                      handleChange={result => {
                        setFieldValue('program', result ? result : null);
                        setFieldValue('programId', result ? result.id : 0);
                      }}
                      selectedValue={values.program as Program}
                      isDisabled={!!program}
                      error={!!errors.programId && touched.programId}
                      helperText={errors.programId}
                    />
                    <BrandComboBox
                      handleChange={result => {
                        setFieldValue('brand', result ? result : null);
                        setFieldValue('brandId', result ? result.id : 0);
                      }}
                      selectedValue={values.brand as Brand}
                      isDisabled={!!brand}
                      error={!!errors.brandId && touched.brandId}
                      helperText={errors.brandId}
                    />
                    <UserComboBox
                      handleChange={(result: User) => {
                        setFieldValue('user', result ? result : null);
                        setFieldValue('userId', result ? result.id : 0);
                      }}
                      selectedValue={values.user as User}
                      label={'Sample Dev/NPD'}
                      error={!!errors.userId && touched.userId}
                      helperText={errors.userId}
                    />

                    <FieldArray
                      name="itemAndItemDescriptions"
                      render={arrayHelpers => (
                        <>
                          <Button
                            variant={'contained'}
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={() => {
                              arrayHelpers.push(initEBlockRow);
                            }}
                          >
                            Add
                          </Button>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Group Product</TableCell>
                                <TableCell>Material</TableCell>
                                <TableCell>Size</TableCell>
                                <TableCell>Pattern</TableCell>
                                <TableCell>Item</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Art Template Qty.</TableCell>
                                <TableCell>Inter Company Product Type</TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.itemAndItemDescriptions &&
                                values.itemAndItemDescriptions.map(
                                  (itemAndItemDescription, index) => {
                                    const getErrorMessage = (
                                      fieldName: string,
                                      rowIndex: number,
                                    ) =>
                                      getIn(
                                        errors,
                                        `itemAndItemDescriptions[${rowIndex}].${fieldName}`,
                                      );
                                    return (
                                      <TableRow key={index}>
                                        <TableCell>
                                          <EBlockGroupProductComboBox
                                            handleChange={(
                                              result: IEBlockGroupProduct,
                                            ) => {
                                              arrayHelpers.replace(index, {
                                                ...values.itemAndItemDescriptions![index],
                                                eBlockGroupProductId: result
                                                  ? result.id
                                                  : 0,
                                                eBlockGroupProduct: result
                                                  ? result
                                                  : null,
                                                eBlockSizeId: 0,
                                                eBlockSize: null,
                                                eBlockPatternId: 0,
                                                eBlockPattern: null,
                                              });
                                            }}
                                            selectedValue={
                                              itemAndItemDescription.eBlockGroupProduct as IEBlockGroupProduct
                                            }
                                            error={
                                              !!getErrorMessage(
                                                'eBlockGroupProductId',
                                                index,
                                              )
                                            }
                                            helperText={getErrorMessage(
                                              'eBlockGroupProductId',
                                              index,
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <EBlockMaterialComboBox
                                            handleChange={(result: IEBlockMaterial) => {
                                              arrayHelpers.replace(index, {
                                                ...values.itemAndItemDescriptions![index],
                                                eBlockMaterialId: result ? result.id : 0,
                                                eBlockMaterial: result ? result : null,
                                              });
                                            }}
                                            selectedValue={
                                              itemAndItemDescription.eBlockMaterial as IEBlockMaterial
                                            }
                                            error={
                                              !!getErrorMessage('eBlockMaterialId', index)
                                            }
                                            helperText={getErrorMessage(
                                              'eBlockMaterialId',
                                              index,
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <EBlockSizeComboBox
                                            handleChange={(result: IEBlockSize) => {
                                              arrayHelpers.replace(index, {
                                                ...values.itemAndItemDescriptions![index],
                                                eBlockSizeId: result ? result.id : 0,
                                                eBlockSize: result ? result : null,
                                              });
                                            }}
                                            selectedValue={
                                              itemAndItemDescription.eBlockSize as IEBlockSize
                                            }
                                            error={
                                              !!getErrorMessage('eBlockSizeId', index)
                                            }
                                            helperText={getErrorMessage(
                                              'eBlockSizeId',
                                              index,
                                            )}
                                            eBlockGroupProductId={
                                              values.itemAndItemDescriptions![index]
                                                .eBlockGroupProductId
                                            }
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <EBlockPatternComboBox
                                            handleChange={(result: IEBlockPattern) => {
                                              arrayHelpers.replace(index, {
                                                ...values.itemAndItemDescriptions![index],
                                                eBlockPatternId: result ? result.id : 0,
                                                eBlockPattern: result ? result : null,
                                              });
                                            }}
                                            selectedValue={
                                              itemAndItemDescription.eBlockPattern as IEBlockPattern
                                            }
                                            error={
                                              !!getErrorMessage('eBlockPatternId', index)
                                            }
                                            helperText={getErrorMessage(
                                              'eBlockPatternId',
                                              index,
                                            )}
                                            eBlockGroupProductId={
                                              values.itemAndItemDescriptions![index]
                                                .eBlockGroupProductId
                                            }
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            className={classes.textInput}
                                            name="item"
                                            type="input"
                                            variant="outlined"
                                            margin="dense"
                                            value={
                                              values.itemAndItemDescriptions![index].item
                                            }
                                            onChange={event => {
                                              const newItem = event.target.value;
                                              arrayHelpers.replace(index, {
                                                ...values.itemAndItemDescriptions![index],
                                                item: newItem,
                                              });
                                            }}
                                            error={!!getErrorMessage('item', index)}
                                            helperText={getErrorMessage('item', index)}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            className={classes.textInput}
                                            name="itemDescription"
                                            type="input"
                                            variant="outlined"
                                            margin="dense"
                                            value={
                                              values.itemAndItemDescriptions![index]
                                                .itemDescription
                                            }
                                            onChange={event => {
                                              const newDesc = event.target.value;
                                              arrayHelpers.replace(index, {
                                                ...values.itemAndItemDescriptions![index],
                                                itemDescription: newDesc,
                                              });
                                            }}
                                            error={
                                              !!getErrorMessage('itemDescription', index)
                                            }
                                            helperText={getErrorMessage(
                                              'itemDescriptionl',
                                              index,
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            className={classes.textInput}
                                            name="blockArtTemplateQty"
                                            type="number"
                                            variant="outlined"
                                            margin="dense"
                                            value={
                                              values.itemAndItemDescriptions![index]
                                                .blockArtTemplateQty
                                            }
                                            onChange={event => {
                                              const qty = event.target.value;
                                              arrayHelpers.replace(index, {
                                                ...values.itemAndItemDescriptions![index],
                                                blockArtTemplateQty: qty,
                                              });
                                            }}
                                            error={
                                              !!getErrorMessage(
                                                'blockArtTemplateQty',
                                                index,
                                              )
                                            }
                                            helperText={getErrorMessage(
                                              'blockArtTemplateQty',
                                              index,
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <EBlockInterCompProdTypeCombo
                                            handleChange={(result: ProductType) => {
                                              arrayHelpers.replace(index, {
                                                ...values.itemAndItemDescriptions![index],
                                                interCompanyProductTypeId: result
                                                  ? result.id
                                                  : 0,
                                                interCompanyProductType: result
                                                  ? result
                                                  : null,
                                              });
                                            }}
                                            selectedValue={
                                              itemAndItemDescription.interCompanyProductType as ProductType
                                            }
                                            error={
                                              !!getErrorMessage(
                                                'interCompanyProductTypeId',
                                                index,
                                              )
                                            }
                                            helperText={getErrorMessage(
                                              'interCompanyProductTypeId',
                                              index,
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <Button
                                            onClick={() => {
                                              arrayHelpers.remove(index);
                                            }}
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<DeleteIcon />}
                                          >
                                            DELETE
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  },
                                )}
                            </TableBody>
                          </Table>
                        </>
                      )}
                    ></FieldArray>
                  </DialogContent>

                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                    <Button
                      variant={'contained'}
                      onClick={() => {
                        submitForm();
                      }}
                      color="primary"
                    >
                      Next
                    </Button>
                  </DialogActions>
                </>
              )}
              {eBlockCreateStep === 'confirmEBlockDetail' && (
                <>
                  <DialogTitle>Summary Create EBlocks</DialogTitle>
                  <DialogContent>
                    <EBlockCreateSummary formValues={values} />
                    <EBlockConfirmTable blockCreates={eBlockCreates} />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setEBlockCreateStep('fillEBlockDetail');
                      }}
                      color="primary"
                    >
                      Back
                    </Button>
                    <Button
                      variant={'contained'}
                      onClick={() => {
                        dispatch(createEBlocks(eBlockCreates));
                        setEBlockCreateStep('createEBlock');
                      }}
                      color="primary"
                    >
                      Submit to Create Block
                    </Button>
                  </DialogActions>
                </>
              )}
              {eBlockCreateStep === 'createEBlock' && blockIsLoading && (
                <>
                  <DialogTitle>Creating EBlocks</DialogTitle>
                  <DialogContent className={classes.loadingContainer}>
                    <CircularProgress size={50} />
                    <DialogContentText className={classes.loadingMessage}>
                      Creating EBlocks...
                    </DialogContentText>
                  </DialogContent>
                </>
              )}
              {eBlockCreateStep === 'createEBlock' && !blockIsLoading && (
                <>
                  <DialogTitle>Created EBlocks</DialogTitle>
                  <DialogContent>
                    <EBlockCreateSummary formValues={values} />
                    <EBlockConfirmTable blockCreates={createdBlocks} />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant={'contained'}
                      onClick={() => {
                        handleClose();
                        handleCreatedBlock(
                          values.user!,
                          createdBlocks
                            .filter(cb => !cb.hasError && !!cb.block)
                            .map(cb => cb.block!),
                        );
                        resetToDefaultState();
                      }}
                      color="primary"
                    >
                      Confirm
                    </Button>
                  </DialogActions>
                </>
              )}
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EBlockCreateDialog;
