import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import React from 'react';
import * as Yup from 'yup';
import { AccountOwner } from '../../../state/accountOwner';
import {
  OpportunityApprovalRequest,
  OpportunityApprovalResponse,
  updateOpportunityApprovalResponse,
} from '../../../state/opportunityApprovalRequest';
import { AccountOwnerComboBox } from '../../components';
import { useDispatch } from 'react-redux';
import { Form, Formik, FormikHelpers } from 'formik';
import useStyles from './confirmDialog.style';
import { OpportunityStatusType } from '../../../constants/opportunityStatus';
import { IFindAllOpportunityApprovalRequest } from '../../../models/findAllOpportunityApprovalRequest.model';
import { Close as CloseIcon, Check as CheckIcon } from '@material-ui/icons';

interface ConfirmDialogProps {
  handleClose: () => void;
  open: boolean;
  responseResult: OpportunityStatusType;
  opportunityApprovalRequest: IFindAllOpportunityApprovalRequest;
}

const ConfirmSchema = Yup.object().shape({
  // selectedAccountOwnerId: Yup.number()
  //   .moreThan(0, 'Account Owner is required')
  //   .required('Account Owner is required'),
  comment: Yup.string().nullable(),
  responseResult: Yup.string(),
});

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const {
    open,
    handleClose,
    responseResult,
    opportunityApprovalRequest: {
      // accountOwner,
      comment,
      id: opportunityApprovalRequestId = 0,
    },
  } = props;
  // const selectedAccountOwnerId = accountOwner ? accountOwner.id : 0;
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleSubmit = (
    values: OpportunityApprovalResponse,
    actions: FormikHelpers<any>,
  ) => {
    dispatch(updateOpportunityApprovalResponse(values));
    actions.setSubmitting(false);
    handleClose();
  };

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          // accountOwner,
          // selectedAccountOwnerId,
          comment: comment ? comment : '',
          responseResult,
          opportunityApprovalRequestId,
        }}
        validationSchema={ConfirmSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, errors, touched, setFieldValue }) => {
          return (
            <Form>
              <DialogTitle>Please Confirm!</DialogTitle>
              <DialogContent>
                <DialogContentText>Status: {values.responseResult}</DialogContentText>
                <TextField
                  name="comment"
                  type="input"
                  label="Comment"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={values.comment}
                  onChange={handleChange}
                  error={!!errors.comment && !!touched.comment}
                  helperText={errors.comment}
                  multiline={true}
                  rows={4}
                />
                {/* <AccountOwnerComboBox
                  handleChange={result => {
                    setFieldValue('accountOwner', result ? result : null);
                    setFieldValue('selectedAccountOwnerId', result ? result.id : 0);
                  }}
                  selectedValue={values.accountOwner as AccountOwner}
                />
                {!!errors.selectedAccountOwnerId && touched.selectedAccountOwnerId && (
                  <div className={classes.error}>{errors.selectedAccountOwnerId}</div>
                )} */}
              </DialogContent>
              <DialogActions style={{ marginRight: '5px', marginBottom: '10px' }}>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  variant="outlined"
                  startIcon={<CloseIcon />}
                >
                  Cancel
                </Button>
                <Button
                  type={'submit'}
                  color="primary"
                  variant="outlined"
                  startIcon={<CheckIcon />}
                >
                  Confirm
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ConfirmDialog;
