import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    width: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      position: 'absolute',
      marginTop: theme.spacing(10),
      marginRight: theme.spacing(5),
      right: theme.spacing(2),
      top: theme.spacing(2),
    },
  },
}));

const PageButtonGroup: React.FC = props => {
  const classes = useStyles();

  return (
    <div className={classes.buttonGroup}>
      <Grid container spacing={1}>
        {React.Children.map(props.children, x => (
          <Grid item xs={12} md={'auto'}>
            {x}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default PageButtonGroup;
