import { IOpportunity } from '../models/opportunity.model';
import { PageFilter } from '../state/app';
import {
  ChangeProgramBrandPayload,
  CopyOpportunityPayload,
  MoveOpportunityPayload,
  OpportunityListFilter,
} from '../state/opportunity';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/opportunities`;

export const fetchOpportunityList = async ({
  filters,
  paging,
}: {
  filters: OpportunityListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchOpportunity = async (id: IOpportunity['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createOpportunity = async (opportunity: IOpportunity) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, opportunity);
};

export const updateOpportunity = async (opportunity: IOpportunity) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, opportunity);
};

export const submitForApproval = async (id: IOpportunity['id']) => {
  const requestUrl = `${baseUrl}/${id}/submitForApproval`;
  return await axios.post(requestUrl);
};

export const submitForReassignResponsibility = async (
  assignmentNumber: IOpportunity['assignmentNumber'],
) => {
  const requestUrl = `${baseUrl}/${assignmentNumber}/reassignResponsibility`;
  return await axios.put(requestUrl);
};

export const copyOpportunity = async (copyOpportunityObj: CopyOpportunityPayload) => {
  const { opportunityId } = copyOpportunityObj;
  const requestUrl = `${baseUrl}/${opportunityId}/copy`;
  return await axios.post(requestUrl, copyOpportunityObj);
};

export const MoveItemToNewOpportunity = async (
  moveOpplrtunityPayload: MoveOpportunityPayload,
) => {
  const { opportunityId, opportunityItemIds } = moveOpplrtunityPayload;
  const requestUrl = `${baseUrl}/${opportunityId}/move`;
  return await axios.post(requestUrl, opportunityItemIds);
};

export const changeProgramBrand = async (
  changeProgramBrandPayload: ChangeProgramBrandPayload,
) => {
  const requestUrl = `${baseUrl}/changeProgramAndBrand`;
  return await axios.post(requestUrl, changeProgramBrandPayload);
};
