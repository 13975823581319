import { Divider, Drawer, IconButton, List } from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon } from '@material-ui/icons/';
import React from 'react';
import { useSelector } from 'react-redux';
import { AuthState } from '../../../state/auth';
import { AppState } from '../../../state/configureStore';
import { adminLinks } from './adminLinks';
import useStyles from './drawer.style';
import { LeftDrawerProps } from './drawer.types';
import DrawerItem from './DrawerItem';
import { guestLinks } from './links';
import { managerLinks } from './managerLinks';
import { userLinks } from './userLinks';
import { artworkDesignLinks } from './artworkDesignLinks';

const LeftDrawer: React.FC<LeftDrawerProps> = (props) => {
  const classes = useStyles(props);
  const { isLoggedIn, user } = useSelector<AppState, AuthState>((state) => state.auth);
  const { open, handleDrawerClose } = props;

  let listToRender = guestLinks;
  if (isLoggedIn) {
    if (user!.roles && user!.roles!.includes('MANAGER')) {
      listToRender = managerLinks;
    } else if (user!.roles && user!.roles!.includes('ADMIN')) {
      listToRender = adminLinks;
    } else if (user!.roles && user!.roles!.includes('ARTWORK_DESIGN')) {
      listToRender = artworkDesignLinks;
    } else {
      listToRender = userLinks;
    }
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {listToRender.map((link, index) => {
          return (
            <DrawerItem
              key={index}
              link={link}
              level={1}
              handleDrawerClose={handleDrawerClose}
            />
          );
        })}
      </List>
    </Drawer>
  );
};

export default LeftDrawer;
