import {
  Button,
  Checkbox,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProjectStatusLabel, TablePageSize } from '../../../constants';
import { IProject } from '../../../models/project.model';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  fetchProjectList,
  ProjectListFilter,
  ProjectState,
} from '../../../state/project';
import { defaultListLoadingHeight } from '../../../theme';
import { PageButtonGroup, PageContainer, PageTitle } from '../../components';
import Loading from '../../components/AwLoading';
import { DateTimeDisplay } from '../../components/DateTimeDisplay';
import ProjectDetailDialog from '../../components/ProjectComboBox/ProjectDetailDialog';
import SortLabel from '../../components/SortLabel';
import { useFilter, useHasRole, useTableSorting } from '../../hooks';
import roleRoute from '../roleRoute.config';
import ProjectFilter from './ProjectFilter';
import ProjectProgramExpandable from './ProjectProgramExpandable';
import ProjectStatusIcon from './ProjectStatusIcon';

export interface ProjectListProps {
  searchMode?: boolean;
  onSelect?: (project: IProject | null) => void;
  forceFilter?: ProjectListFilter;
}

const ProjectList: React.FC<ProjectListProps> = (props) => {
  const { searchMode = false, onSelect = () => {}, forceFilter = null } = props;

  const initialProjectFilter: ProjectListFilter = {
    'Status.equalsTo': '',
    'IsHighPriority.equalsTo': '',
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedProject, setSelectedProject] = useState<IProject | null>(null);
  const [selectToView, setSelectToView] = useState<IProject | null>(null);
  const [openProjectDetail, setOpenProjectDetail] = useState<boolean>(false);

  const isDisabled = !useHasRole(roleRoute.createProject);
  const [filters, setFilters] = useFilter<ProjectListFilter>({
    ...initialProjectFilter,
    ...(forceFilter || {}),
  });

  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: TablePageSize[0],
  });
  const [sort, setSort] = useTableSorting(
    {
      orderBy: 'createdAt',
      direction: 'desc',
    },
    true,
  );

  const {
    items: projects,
    totalItems,
    loading,
  } = useSelector<AppState, ProjectState>((state) => state.project);

  useEffect(() => {
    dispatch(fetchProjectList(filters, { ...paging, sort }));
  }, [dispatch, paging, filters, sort]);

  const addNewProject = (): void => {
    history.push('/projects/form');
  };

  const editProject = (id: IProject['id']): void => {
    history.push(`/projects/form/${id}`);
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const applyProjectFilter = (filters: React.SetStateAction<ProjectListFilter>) => {
    setFilters({ ...filters });
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const substr = (text: string) => {
    const splitLength = 15;
    const split = text.split('');

    if (split.length > splitLength) {
      return text.substring(0, splitLength) + ' ...';
    } else {
      return text;
    }
  };

  const isItemSelected = (id: number | null) =>
    (selectedProject && selectedProject.id === id) || false;

  const enhancedSearchRow = (project: IProject) => {
    return {
      role: searchMode ? 'checkBox' : '',
      onClick: () => {
        onSelect(project);
        setSelectedProject(project);
      },
      selected: isItemSelected(project!.id!),
    };
  };

  return (
    <PageContainer>
      <PageTitle>Project</PageTitle>
      <ProjectFilter
        filters={filters}
        onChangeFilter={(newFilter) => applyProjectFilter(newFilter)}
        initialFilter={initialProjectFilter}
      />
      {!searchMode ? (
        <PageButtonGroup>
          <Button
            variant="contained"
            color="primary"
            onClick={addNewProject}
            startIcon={<AddIcon />}
            disabled={isDisabled}
          >
            New Project
          </Button>
        </PageButtonGroup>
      ) : null}
      <Loading loading={loading} height={defaultListLoadingHeight}>
        <Table>
          <TableHead>
            <TableRow>
              {searchMode ? <TableCell>*</TableCell> : null}
              {/* <TableCell>Company</TableCell> */}
              <TableCell>
                <SortLabel sort={sort} handleSortChange={setSort} field="name">
                  Name
                </SortLabel>
              </TableCell>
              <TableCell>Program</TableCell>
              <TableCell>Brand</TableCell>
              <TableCell>Owner</TableCell>
              <TableCell>Is High Priority</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>
                <SortLabel sort={sort} handleSortChange={setSort} field="createdAt">
                  Created At
                </SortLabel>
              </TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map((project, index) => (
              <TableRow key={project.id} hover {...enhancedSearchRow(project)}>
                {searchMode ? (
                  <TableCell padding="checkbox">
                    <Checkbox checked={isItemSelected(project!.id!)} />
                  </TableCell>
                ) : null}

                <TableCell>
                  <Link
                    href="#"
                    onClick={() => {
                      setSelectToView(project);
                      setOpenProjectDetail(true);
                    }}
                  >
                    {project.name!}
                  </Link>
                </TableCell>
                <TableCell>
                  <ProjectProgramExpandable
                    programNames={substr(project.programNames!)}
                    projectPrograms={project.smProjectPrograms!}
                  />
                </TableCell>
                <TableCell>
                  <ProjectProgramExpandable
                    programNames={substr(project.brandNames!)}
                    projectPrograms={project.smProjectPrograms!}
                  />
                </TableCell>
                <TableCell>
                  <Link
                    href="#"
                    onClick={() => {
                      setSelectToView(project);
                      setOpenProjectDetail(true);
                    }}
                  >
                    {project.owner ? project.owner!.fullName! : ''}
                  </Link>
                </TableCell>
                <TableCell>
                  <Checkbox color="secondary" checked={project!.isHighPriority!} />
                </TableCell>
                <TableCell>
                  <div>{ProjectStatusIcon[ProjectStatusLabel[project.status!]]}</div>
                </TableCell>
                <TableCell>
                  <DateTimeDisplay value={project.createdAt} />
                </TableCell>
                <TableCell>{project.createdBy}</TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <span>
                      <IconButton
                        size="small"
                        onClick={() => editProject(project.id)}
                        disabled={isDisabled}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[...TablePageSize]}
          component="div"
          count={totalItems}
          rowsPerPage={paging.pageSize}
          page={paging.pageNumber}
          onPageChange={handleChangePageNumber}
          onRowsPerPageChange={handleChangePageSize}
        />
      </Loading>
      {selectToView ? (
        <ProjectDetailDialog
          open={openProjectDetail}
          handleClose={() => {
            setOpenProjectDetail(false);
          }}
          smProjectId={selectToView!.id!}
        />
      ) : null}
    </PageContainer>
  );
};

export default ProjectList;
