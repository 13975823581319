import { PageFilter } from '../state/app';
import {
  SquadWorkingMember,
  SquadWorkingMemberListFilter,
} from '../state/squadWorkingMember';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/smSquadWorkingMembers`;

export const fetchSquadWorkingMemberList = async ({
  filters,
  paging,
}: {
  filters: SquadWorkingMemberListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchSquadWorkingMember = async (id: SquadWorkingMember['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createSquadWorkingMember = async (
  squadWorkingMember: SquadWorkingMember,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, squadWorkingMember);
};

export const updateSquadWorkingMember = async (
  squadWorkingMember: SquadWorkingMember,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, squadWorkingMember);
};

export const deleteSquadWorkingMember = async (id: SquadWorkingMember['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};
