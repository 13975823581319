import { Block } from '../state/block';
import { User } from '../state/user';
import { IOpportunity } from './opportunity.model';

export interface IOpportunityItem {
  id?: number;
  sequence?: number;
  opportunityId?: number;
  opportunity?: IOpportunity | null,
  blockId?: number;
  block?: Block | null;
  sampleDevUserId?: number;
  npdUserId?: number;
  npdUser?: User | null;
  sampleDevUser?: User | null;
}

export const defaultValue: Readonly<IOpportunityItem> = {};
