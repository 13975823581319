import { call, put, takeLatest } from 'redux-saga/effects';
import { login, logout } from '../../services/authService';
import { fetchUserInfo } from '../../services/userService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { deleteAuthToken, saveAuthToken } from '../../utils/authUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import { CHECK_AUTH, LOGIN, LOGOUT, setToken } from './auth.action';
import { setAuthorizationBearerToken } from '../../utils/axios';

function* loginSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(login, action.payload);
    const { token } = data;
    saveAuthToken(token);
    setAuthorizationBearerToken();
    yield call(checkAuthSaga, { type: CHECK_AUTH });
    yield put({ type: SUCCESS(action.type) });
    yield put(setToken(token));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* logoutSaga() {
  try {
    yield put({ type: REQUEST(LOGOUT) });
    yield call(logout);
  } catch (e) {
    console.error(e);
  }
  deleteAuthToken();
  yield put({ type: SUCCESS(LOGOUT) });
}

function* checkAuthSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchUserInfo);
    yield put({ type: SUCCESS(action.type), payload: data });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchUserAuthentication() {
  yield takeLatest(LOGIN, loginSaga);
  yield takeLatest(LOGOUT, logoutSaga);
  yield takeLatest(CHECK_AUTH, checkAuthSaga);
}
