import { ISampleDevelopmentPl } from '../../models/sampleDevelopmentPl.model';
import { AppAction, PageFilter } from '../app';
import { SampleDevelopmentPlListFilter } from './sampleDevelopmentPl.types';

export const FETCH_SAMPLE_DEVELOPMENT_PL_LIST = 'FETCH_SAMPLE_DEVELOPMENT_PL_LIST';
export const FETCH_SAMPLE_DEVELOPMENT_PL = 'FETCH_SAMPLE_DEVELOPMENT_PL';
export const CREATE_SAMPLE_DEVELOPMENT_PL = 'CREATE_SAMPLE_DEVELOPMENT_PL';
export const UPDATE_SAMPLE_DEVELOPMENT_PL = 'UPDATE_SAMPLE_DEVELOPMENT_PL';
export const CLEAR_SAMPLE_DEVELOPMENT_PL = 'CLEAR_SAMPLE_DEVELOPMENT_PL';
export const SUBMIT_SAMPLE_DEVELOPMENT_PL_FOR_APPROVAL =
  'SUBMIT_SAMPLE_DEVELOPMENT_PL_FOR_APPROVAL';
export const DOWNLOAD_SAMPLE_DEVELOPMENT_PL = 'DOWNLOAD_SAMPLE_DEVELOPMENT_PL';

export const fetchSampleDevelopmentPlList = (
  filters: SampleDevelopmentPlListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SAMPLE_DEVELOPMENT_PL_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const downloadSampleDevelopmentPl = (id: number): AppAction => {
  return {
    type: DOWNLOAD_SAMPLE_DEVELOPMENT_PL,
    payload: id,
  };
};

export const fetchSampleDevelopmentPl = (id: number): AppAction => ({
  type: FETCH_SAMPLE_DEVELOPMENT_PL,
  payload: id,
});

export const createSampleDevelopmentPl = (payload: {
  values: ISampleDevelopmentPl;
}): AppAction => ({
  type: CREATE_SAMPLE_DEVELOPMENT_PL,
  payload,
});

export const updateSampleDevelopmentPl = (payload: {
  values: ISampleDevelopmentPl;
}): AppAction => ({
  type: UPDATE_SAMPLE_DEVELOPMENT_PL,
  payload,
});

export const clearSampleDevelopmentPl = () => ({
  type: CLEAR_SAMPLE_DEVELOPMENT_PL,
});

export const submitSampleDevelopmentPlForApproval = (id: number): AppAction => ({
  type: SUBMIT_SAMPLE_DEVELOPMENT_PL_FOR_APPROVAL,
  payload: id,
});
