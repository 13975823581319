import React from 'react';
import { useUtils } from '@material-ui/pickers';

export interface DateTimeDisplayProps {
  value?: string | null;
}

export interface BaseDateTimeDisplayProps {
  value?: string | null;
  format: string;
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>
}

const BaseDateDisplay: React.FC<BaseDateTimeDisplayProps> = (props) => {
  const pickerContext = useUtils();
  const { value, format, component: Component } = props;
  let displayValue = '';
  if (value && pickerContext.isValid(value)) {
    const date = pickerContext.date(value);
    displayValue = pickerContext.format(date, format);
  }
  if (Component) {
    return <Component>{displayValue}</Component>;
  }
  return <span>{displayValue}</span>;
};

export default BaseDateDisplay;
