import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      margin: theme.spacing(2),
      flex: 1,
    },
    error: {
      marginLeft: theme.spacing(1),
      marginTop: '0.5rem',
      color: 'red',
    },
    project: {
      margin: theme.spacing(3),
      position: 'relative',
    },
    approveButtonGroup: {
      display: (props: any) => {
        return props.projectApprovalRequest.hasResponded ? 'none' : 'flex';
      },
    },
    button: {
      marginLeft: theme.spacing(1),
    },
    buttonGroup: {
      position: 'absolute',
      right: '15px',
      top: '15px',
    },
    projectCustomer: {
      margin: theme.spacing(3),
      position: 'relative',
    },
    projectItem: {
      margin: theme.spacing(3),
      position: 'relative',
    },
  }),
);

export default useStyles;
