import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createCustomer,
  fetchCustomer,
  fetchCustomerList,
  updateCustomer,
} from '../../services/customerService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_CUSTOMER,
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_LIST,
  UPDATE_CUSTOMER,
} from './customer.actions';
import { CustomerCreateOrUpdatePayload } from './customer.types';

const listPageUrl = `/settings/customers`;

function* fetchCustomerSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchCustomer, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchCustomerListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchCustomerList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createCustomerSaga(action: AppAction) {
  try {
    const { values, history } = action.payload as CustomerCreateOrUpdatePayload;
    const successMessage = `Created ${values.name} customer successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createCustomer, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(listPageUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateCustomerSaga(action: AppAction) {
  try {
    const { values, history } = action.payload as CustomerCreateOrUpdatePayload;
    const successMessage = `Updated ${values.name} customer successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateCustomer, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(listPageUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchCustomer() {
  yield takeLatest(FETCH_CUSTOMER, fetchCustomerSaga);
  yield debounce(250, FETCH_CUSTOMER_LIST, fetchCustomerListSaga);
  yield takeLatest(CREATE_CUSTOMER, createCustomerSaga);
  yield takeLatest(UPDATE_CUSTOMER, updateCustomerSaga);
}
