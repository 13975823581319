import { Grid } from '@material-ui/core';
import React from 'react';
import { EBlockArtTemplateListFilter } from '../../../state/eBlockArtTemplate';
import { SearchField } from '../../components';
import {
  FilterContainer,
  IFilterContainerConfigProps,
} from '../../components/FilterContainer';

const EBlockArtTemplateFilter: React.FC<
  IFilterContainerConfigProps<EBlockArtTemplateListFilter>
> = props => {
  return (
    <FilterContainer
      {...props}
      render={filterProps => {
        const { localFilter, handleChangeFilter } = filterProps;
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Block.BlockNo.StartsWith"
                filters={localFilter}
                label="EBlock"
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="ArtTemplateNumber.StartsWith"
                filters={localFilter}
                label="Art Template"
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Block.EBlockGroupProduct.Description.StartsWith"
                filters={localFilter}
                label="Group Product"
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Block.EBlockMaterial.Description.StartsWith"
                filters={localFilter}
                label="Material"
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Block.EBlockSize.Description.StartsWith"
                filters={localFilter}
                label="Size"
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Block.EBlockPattern.Description.StartsWith"
                filters={localFilter}
                label="Pattern"
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Block.Program.Name.StartsWith"
                filters={localFilter}
                label="Program"
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Block.Brand.Name.StartsWith"
                filters={localFilter}
                label="Brand"
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="Block.Item.StartsWith"
                filters={localFilter}
                label="Item"
                onChange={handleChangeFilter}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default EBlockArtTemplateFilter;
