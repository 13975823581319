import { IProjectSquad } from '../../models/projectSquad.model';
import { AppAction, PageFilter, AppCallback } from '../app';
import {
  ProjectSquadCreateOrUpdatePayload,
  ProjectSquadListFilter,
} from './projectSquad.types';

export const FETCH_PROJECT_SQUAD = 'FETCH_PROJECT_SQUAD';
export const FETCH_PROJECT_SQUAD_LIST = 'FETCH_PROJECT_SQUAD_LIST';
export const CREATE_PROJECT_SQUAD = 'CREATE_PROJECT_SQUAD';
export const UPDATE_PROJECT_SQUAD = 'UPDATE_PROJECT_SQUAD';
export const CLEAR_PROJECT_SQUAD = 'CLEAR_PROJECT_SQUAD';
export const DELETE_PROJECT_SQUAD = 'DELETE_PROJECT_SQUAD';
export const CREATE_PROJECT_SQUADS = 'CREATE_PROJECT_SQUADS';

export const fetchProjectSquad = (id: IProjectSquad['id']): AppAction => ({
  type: FETCH_PROJECT_SQUAD,
  payload: id,
});

export const fetchProjectSquadList = (
  filters: ProjectSquadListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_PROJECT_SQUAD_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchProjectSquadListBySmProjectId = (smProjectId: number): AppAction => ({
  type: FETCH_PROJECT_SQUAD_LIST,
  payload: {
    filters: { 'smProjectId.equalsTo': smProjectId },
    paging: { pageNumber: 0, pageSize: 99999 },
  },
});

export const createProjectSquad = (payload: any): AppAction => ({
  type: CREATE_PROJECT_SQUAD,
  payload,
});

export const createProjectSquads = (payload: any, callback?: AppCallback): AppAction => ({
  type: CREATE_PROJECT_SQUADS,
  payload,
  callback,
});

export const updateProjectSquad = (
  payload: ProjectSquadCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_PROJECT_SQUAD,
  payload,
});

export const clearProjectSquad = () => ({
  type: CLEAR_PROJECT_SQUAD,
});

export const deleteProjectSquad = (id: number) => ({
  type: DELETE_PROJECT_SQUAD,
  payload: { id },
});
