import React from 'react';
import { IEBlockGroupProduct } from '../../models/eBlockGroupProduct';
import { fetchEBlockGroupProductList } from '../../services/eBlockGroupProductService';
import AsyncAutoComplete from './AsyncAutoComplete';

interface EBlockGroupProductComboBoxProps {
  handleChange: (result: IEBlockGroupProduct) => void;
  selectedValue: IEBlockGroupProduct;
  isDisabled?: boolean;
  error?: boolean;
  helperText?: string;
}

const EBlockGroupProductComboBox: React.FC<EBlockGroupProductComboBoxProps> = props => {
  const { handleChange, selectedValue, isDisabled = false, error, helperText } = props;

  const eBlockGroupProductPromiseOptions = async (inputValue: string) => {
    const { data: rows } = await fetchEBlockGroupProductList({
      filters: { 'CodeAndDesc.Contains': inputValue },
      paging: { pageNumber: 0, pageSize: 10 },
    });

    return rows;
  };

  return (
    <AsyncAutoComplete
      name=""
      value={selectedValue}
      getOptionValue={(option: IEBlockGroupProduct) => {
        return option.id!.toString();
      }}
      getOptionLabel={(option: IEBlockGroupProduct) => {
        return `${option.code!}: ${option.description!}`;
      }}
      promiseOptions={eBlockGroupProductPromiseOptions}
      placeholder={''}
      handleChange={handleChange}
      isDisabled={isDisabled}
      error={error}
      helperText={helperText}
    />
  );
};

export default EBlockGroupProductComboBox;
