import { PageFilter } from '../state/app';
import { SalesTeam, SalesTeamListFilter } from '../state/salesTeam';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/salesTeams`;

export const fetchSalesTeamList = async ({
  filters,
  paging,
}: {
  filters: SalesTeamListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchSalesTeamRelativeUserList = async ({
  filters,
  paging,
}: {
  filters: SalesTeamListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}/relativeUser`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchSalesTeam = async (id: SalesTeam['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createSalesTeam = async (salesTeam: SalesTeam) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, salesTeam);
};

export const updateSalesTeam = async (salesTeam: SalesTeam) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, salesTeam);
};
