import { Button, makeStyles } from '@material-ui/core';
import { Save as SaveIcon, Update } from '@material-ui/icons';
import { update } from 'lodash';
import React, { useState } from 'react';
import ResponsibilityStatus from '../../../../constants/responsibilityStatus';
import { Send as SendIcon } from '@material-ui/icons';
import { IResponsibility } from '../../../../models/responsibility.model';
import {
  ResponsibilityState,
  submitForApprovalResponsibility,
} from '../../../../state/responsibility';
import { PageTopMenuContainer } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmUnlockApprovedDialog from '../../responsibility/responsibilityForm/ConfirmUnlockApprovedDialog';
import { AppState } from '../../../../state/configureStore';

interface ConfirmUnlockApprovedDialogProps {
  handleClose: () => void;
  open: boolean;
  responsibilityId: number;
}

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#7cb342',
    '&:hover': {
      backgroundColor: '#689f38',
    },
    color: 'white',
  },
}));

const statusAllowForSubmitForApprovalResponsibility = [
  ResponsibilityStatus.PENDING_FOR_APPROVAL,
  ResponsibilityStatus.REJECTED,
];

const statusAllowForSubmitForUnlockApprovedResponsibility = [
  ResponsibilityStatus.APPROVED,
];
interface ResponsibilityFormActionMenuProps {
  responsibility: IResponsibility;
}

const ResponsibilityFormActionMenu: React.FC<ResponsibilityFormActionMenuProps> = (
  props,
) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const useResponsibilityStore = () => {
    const { item: responsibility } = useSelector<AppState, ResponsibilityState>(
      (state) => state.responsibility,
    );
    return {
      responsibility,
    };
  };

  const { responsibility } = useResponsibilityStore();
  const approvedOrPendingForUnlock =
    responsibility.status === ResponsibilityStatus.APPROVED ||
    responsibility.status === ResponsibilityStatus.PENDING_FOR_UNLOCK_APPROVED;
  const isUpdateMode = !!responsibility.id;
  const [openConfirmUnlockApprovedDialog, setOpenConfirmUnlockApprovedDialog] = useState(
    false,
  );

  return (
    <>
      <PageTopMenuContainer>
        {statusAllowForSubmitForApprovalResponsibility.includes(
          responsibility.status!,
        ) && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              dispatch(submitForApprovalResponsibility(responsibility.id));
            }}
            startIcon={<SendIcon />}
          >
            {`Submit for Approval`}
          </Button>
        )}
        {statusAllowForSubmitForUnlockApprovedResponsibility.includes(
          responsibility.status!,
        ) && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setOpenConfirmUnlockApprovedDialog(true);
            }}
            startIcon={<SendIcon />}
          >
            {`Submit for Unlock Approved`}
          </Button>
        )}
        {!isUpdateMode && (
          <Button
            className={classes.button}
            type={'submit'}
            variant="contained"
            startIcon={<SaveIcon />}
            disabled={approvedOrPendingForUnlock}
          >
            {!isUpdateMode ? `Create` : `Update`}
          </Button>
        )}
      </PageTopMenuContainer>
      <ConfirmUnlockApprovedDialog
        open={openConfirmUnlockApprovedDialog}
        handleClose={() => {
          setOpenConfirmUnlockApprovedDialog(false);
        }}
        responsibilityId={responsibility.id!}
      />
    </>
  );
};

export default ResponsibilityFormActionMenu;
