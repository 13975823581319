import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IMoq, IMoqWithAddedProps } from '../../../models/moq.model';
import { ITaskItemWithMoqs } from '../../../models/taskItem.model';
import { AppState } from '../../../state/configureStore';
import { TaskCreateState } from '../../../state/taskCreate';
import MoqTable from './MoqTable';

interface MultipleTaskItemMoqEditDialogProps {
  open: boolean;
  taskItemToEditMoqs: ITaskItemWithMoqs[];
  onClose: () => void;
  onSubmit: (multipleTaskItemWithMoqs: ITaskItemWithMoqs[]) => void;
}

const MultipleTaskItemMoqEditDialog: React.FC<MultipleTaskItemMoqEditDialogProps> = (
  props,
) => {
  const { open, taskItemToEditMoqs, onClose, onSubmit } = props;
  const [moqs, setMoqs] = useState<IMoqWithAddedProps[]>([]);
  const { selectedUnitOfQuantity } = useSelector<AppState, TaskCreateState>(
    (state) => state.taskCreate,
  );

  const defaultNewRowValue = useMemo<IMoq>(() => {
    return {
      price: '',
      quantity: '',
      unitOfQuantity: selectedUnitOfQuantity,
      unitOfPrice: '',
    };
  }, [selectedUnitOfQuantity]);

  const handleClose = () => {
    setMoqs([]);
    onClose();
  };

  const handleUpdate = (createMoqList: IMoq[]) => {
    const mapCreateMoqList = createMoqList.map((moq) => ({ ...moq, added: true }));
    setMoqs(mapCreateMoqList);

    const editSelectedTaskItemMoqs = taskItemToEditMoqs.map((taskItemToEditMoq) => {
      return { ...taskItemToEditMoq, moqs: mapCreateMoqList };
    });
    onSubmit(editSelectedTaskItemMoqs);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onExiting={() => setMoqs([])}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>Edit Multiple MOQ</DialogTitle>
      <DialogContent>
        <MoqTable
          defaultNewRowValue={defaultNewRowValue}
          onUpdate={handleUpdate}
          moqList={moqs}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MultipleTaskItemMoqEditDialog;
