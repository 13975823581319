import { Button, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Assignment as AssignmentIcon,
  Delete as DeleteIcon,
  Code as CodeIcon,
} from '@material-ui/icons';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { OpportunityCustomerGroup } from '../../../../state/opportunityCustomerGroup';
import { deleteOpportunityCustomerGroup } from '../../../../state/opportunityCustomerGroup';
import CustomerGroupMemberDialog from '../../../components/CustomerGroupComboBox/CustomerGroupMemberDialog';

interface IOpportunityCustomerGroupActionMenu {
  oppCustGrp: OpportunityCustomerGroup;
}

const useStyles = makeStyles({
  listItemIcon: {
    minWidth: '40px',
  },
});

const OpportunityCustomerGroupActionMenu: React.FC<
  IOpportunityCustomerGroupActionMenu
> = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { oppCustGrp } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCustGrpId, setSelectedCustGrpId] = useState<number>(0);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const deleteOppCustGroup = (id: number) => {
    dispatch(deleteOpportunityCustomerGroup({ id }));
  };

  return (
    <div>
      <CustomerGroupMemberDialog
        handleClose={() => setOpenDialog(false)}
        open={openDialog}
        customerGroupId={selectedCustGrpId}
      />

      <Button
        variant="contained"
        color="default"
        size="small"
        startIcon={<CodeIcon />}
        onClick={handleClick}
      >
        Actions
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem
          onClick={() => {
            deleteOppCustGroup(oppCustGrp.id!);
            handleMenuClose();
          }}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDialog(true);
            setSelectedCustGrpId(oppCustGrp.customerGroupId!);
            handleMenuClose();
          }}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <AssignmentIcon />
          </ListItemIcon>
          View Customer Member
        </MenuItem>
      </Menu>
    </div>
  );
};

export default OpportunityCustomerGroupActionMenu;
