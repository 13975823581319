export type RoleType =
  | 'ADMIN'
  | 'MANAGER'
  | 'SALES'
  | 'SAMPLE_DEVELOPMENT'
  | 'NPD'
  | 'COST_ESTIMATOR'
  | 'USER';

export const groups = {
  admin: 'ADMIN' as RoleType,
  manager: 'MANAGER' as RoleType,
  sales: 'SALES' as RoleType,
  sampleDevelopment: 'SAMPLE_DEVELOPMENT' as RoleType,
  npd: 'NPD' as RoleType,
  costEstimator: 'COST_ESTIMATOR' as RoleType,
  user: 'USER' as RoleType,
};

export const allGroups = Object.values(groups);

export const roleRoute = {
  viewSalesGroup: allGroups,
  createSalesGroup: [groups.admin, groups.manager, groups.sales],
  viewSalesTeam: allGroups,
  createSalesTeam: [groups.admin, groups.manager, groups.sales],
  viewSalesTeamMember: allGroups,
  createSalesTeamMember: [groups.admin, groups.manager, groups.sales],
  viewOpportunity: allGroups,
  createOpportunity: [groups.admin, groups.manager, groups.sales, groups.npd],
  viewReport: allGroups,
  viewUser: [groups.admin, groups.manager],
  viewPendingApprovalItems: [groups.admin, groups.manager],
  viewCustomerGroup: allGroups,
  createCustomerGroup: [groups.admin, groups.manager, groups.sales],
  viewProgramBrandRequest: allGroups,
  createProgramBrandRequest: [groups.admin, groups.manager, groups.sales],
  viewEBlockGroupProduct: [groups.admin, groups.manager, groups.sales],
  createEBlockGroupProduct: [groups.admin, groups.manager, groups.sales],
  viewEBlockMaterial: [groups.admin, groups.manager, groups.sales],
  createEBlockMaterial: [groups.admin, groups.manager, groups.sales],
  viewEBlockSize: [groups.admin, groups.manager, groups.sales],
  createEBlockSize: [groups.admin, groups.manager, groups.sales],
  viewEBlockPattern: [groups.admin, groups.manager, groups.sales],
  createEBlockPattern: [groups.admin, groups.manager, groups.sales],
  viewEBlock: [groups.admin, groups.manager, groups.sales],
  createEBlock: [groups.admin, groups.manager, groups.sales],
  viewProject: [groups.admin, groups.manager, groups.sales],
  createProject: [groups.admin, groups.manager, groups.sales],
  viewWorkingGroup: allGroups,
  createWorkingGroup: allGroups,
  viewWorkingTeam: allGroups,
  createWorkingTeam: allGroups,
  viewSquad: allGroups,
  createSquad: [groups.admin, groups.manager],
  viewResponsibility: allGroups,
  createResponsibility: [groups.admin, groups.manager],
  createProgram: [groups.admin, groups.manager],
};

export default roleRoute;
