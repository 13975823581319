import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  CloudDownload as CloudUploadIcon,
  OpenInBrowser as OpenInBrowserIcon,
} from '@material-ui/icons';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { PageContainer, PageTitle } from '../../components';
import { OpportunityApprovalRequestDialog } from '../opportunityApprovalRequest/OpportunityApprovalRequestDialog';
import { OpportunityApprovalRequestReportDialog } from './opportunity';
import CheckAccountOwnerDlg from './opportunity/CheckAccountOwnerDlg';
import { OpportunityItemSummaryReportDialog } from './opportunity/opportunityItemSummaryReportDialog';
// import { OpportunityChangeLogReportDialog } from './opportunity/OpportunityChangeLogReportDialog';
import {
  ProjectSummaryReportDialog,
  ProjectSalesSummaryReportDialog,
  ProjectChangeLogReportDialog,
} from './project';
import useStyles from './reportList.style';
import { SubtaskItemDurationReportDialog } from './subtask/subtaskItemDurationReportDialog';
import SubtaskItemSummaryDlg from './subtask/SubtaskItemSummaryDlg';
import { SubtaskItemSummaryReportDialog } from './subtask/subtaskItemSummaryReportDialog';
import SubtaskItemWorkDurationSummaryDlg from './subtask/SubtaskItemWorkDurationSummaryDlg';
import { TaskItemSummaryReportDialog } from './task/taskItemSummaryReportDialog';
import { ProgramApprovalRequestReportDialog } from './program';
import { BrandApprovalRequestReportDialog } from './brand';
import { stubFalse } from 'lodash';

const ReportList: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const [openCheckAccountOwnerDlg, setOpenCheckAccountOwnerDlg] =
    useState<boolean>(false);
  const [openSubtaskItemSummaryDlg, setOpenSubtaskItemSummaryDlg] =
    useState<boolean>(false);
  const [
    openSubtaskItemWorkDurationSummaryDlg,
    setOpenSubtaskItemWorkDurationSummaryDlg,
  ] = useState<boolean>(false);
  const [
    openOpportunityItemSummaryReportDialog,
    setOpenOpportunityItemSummaryReportDialog,
  ] = useState<boolean>(false);
  const [openOpportunityChangeLogReportDialog, setOpenOpportunityChangeLogReportDialog] =
    useState<boolean>(false);
  const [openTaskItemSummaryReportDialog, setOpenTaskItemSummaryReportDialog] =
    useState<boolean>(false);
  const [openProjectChangeLogReportDialog, setOpenProjectChangeLogReportDialog] =
    useState<boolean>(false);
  const [openProCustRptDlg, setOpenProCustRptDlg] = useState<boolean>(false);
  const [openProSalesRptDlg, setOpenProSalesRptDlg] = useState<boolean>(false);
  const [openOpportunityAppReqReportDialog, setOpenOpportunityAppReqReportDialog] =
    useState<boolean>(false);

  const [openProgramAppReqReportDialog, setOpenProgramAppReqReportDialog] =
    useState<boolean>(false);

  const [openBrandAppReqReportDialog, setOpenBrandAppReqReportDialog] =
    useState<boolean>(false);

  const [openSubtaskItemSummaryReportDialog, setOpenSubtaskItemSummaryReportDialog] =
    useState<boolean>(false);

  const [openSubtaskItemDurationReportDialog, setOpenSubtaskItemDurationReportDialog] =
    useState<boolean>(false);

  const handleCloseDialog = () => {
    setOpenCheckAccountOwnerDlg(false);
    setOpenSubtaskItemSummaryDlg(false);
    setOpenSubtaskItemWorkDurationSummaryDlg(false);
    //setOpenSubtaskItemReportDialog(false);
    setOpenOpportunityItemSummaryReportDialog(false);
    setOpenTaskItemSummaryReportDialog(false);
    setOpenProCustRptDlg(false);
    setOpenProSalesRptDlg(false);
    setOpenOpportunityAppReqReportDialog(false);
    setOpenSubtaskItemSummaryReportDialog(false);
    setOpenSubtaskItemDurationReportDialog(false);
    setOpenProgramAppReqReportDialog(false);
    setOpenBrandAppReqReportDialog(false);
    setOpenProjectChangeLogReportDialog(false);
    setOpenOpportunityChangeLogReportDialog(false);
  };

  return (
    <PageContainer disableOverflowX>
      <PageTitle>Report List</PageTitle>
      <Grid container spacing={3}>
        {/* <Grid item xs={12} className={classes.categoryContainer}>
          <Typography variant="h6" className={classes.categoryTitle}>
            Assignment
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>Check Account Owner Report</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    startIcon={<CloudUploadIcon />}
                    onClick={() => {
                      setOpenCheckAccountOwnerDlg(true);
                    }}
                  >
                    Download Excel
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>2</TableCell>
                <TableCell>Assignment Item Summary</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    onClick={() => {
                      setOpenOpportunityItemSummaryReportDialog(true);
                    }}
                  >
                    Open Dialog
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid> */}

        {/* <Grid item xs={12} className={classes.categoryContainer}>
          <Typography variant="h6" className={classes.categoryTitle}>
            Task
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>Task Item Summary</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    onClick={() => {
                      setOpenTaskItemSummaryReportDialog(true);
                    }}
                  >
                    Open Dialog
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid> */}

        <Grid item xs={12} className={classes.categoryContainer}>
          <Typography variant="h6" className={classes.categoryTitle}>
            Task/Subtask
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* <TableRow>
                <TableCell>1</TableCell>
                <TableCell>Subtask Summary Report</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    startIcon={<CloudUploadIcon />}
                    onClick={() => {
                      setOpenSubtaskItemSummaryDlg(true);
                    }}
                  >
                    Download Excel
                  </Button>
                </TableCell>
              </TableRow> */}
              {/* <TableRow>
                <TableCell>2</TableCell>
                <TableCell>Subtask Item Work Duration Summary Report</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    startIcon={<CloudUploadIcon />}
                    onClick={() => {
                      setOpenSubtaskItemWorkDurationSummaryDlg(true);
                    }}
                  >
                    Download Excel
                  </Button>
                </TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>Subtask Item Summary</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Button
                    size="small"
                    variant="contained"
                    color="default"
                    startIcon={<OpenInBrowserIcon />}
                    onClick={() => {
                      setOpenSubtaskItemSummaryReportDialog(true);
                    }}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>2</TableCell>
                <TableCell>Subtask Item Duration</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Button
                    size="small"
                    variant="contained"
                    color="default"
                    startIcon={<OpenInBrowserIcon />}
                    onClick={() => {
                      setOpenSubtaskItemDurationReportDialog(true);
                    }}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>

        {/* <Grid item xs={12} className={classes.categoryContainer}>
          <Typography variant="h6" className={classes.categoryTitle}>
            Project
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>Project Change Logs Report</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    onClick={() => {
                      setOpenProjectChangeLogReportDialog(true);
                    }}
                  >
                    Open Dialog
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid> */}

        {/* <Grid item xs={12} className={classes.categoryContainer}>
          <Typography variant="h6" className={classes.categoryTitle}>
            Approval Request
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>Assignment Approval Request Report</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    onClick={() => {
                      setOpenOpportunityAppReqReportDialog(true);
                    }}
                  >
                    Open Dialog
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>2</TableCell>
                <TableCell>Program Approval Request Report</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    onClick={() => {
                      setOpenProgramAppReqReportDialog(true);
                    }}
                  >
                    Open Dialog
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>3</TableCell>
                <TableCell>Brand Approval Request Report</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    onClick={() => {
                      setOpenBrandAppReqReportDialog(true);
                    }}
                  >
                    Open Dialog
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid> */}
      </Grid>

      <CheckAccountOwnerDlg
        open={openCheckAccountOwnerDlg}
        handleClose={handleCloseDialog}
      />

      <SubtaskItemSummaryDlg
        open={openSubtaskItemSummaryDlg}
        handleClose={handleCloseDialog}
      />

      <SubtaskItemWorkDurationSummaryDlg
        open={openSubtaskItemWorkDurationSummaryDlg}
        handleClose={handleCloseDialog}
      />

      <SubtaskItemSummaryReportDialog
        open={openSubtaskItemSummaryReportDialog}
        handleClose={handleCloseDialog}
      />

      <OpportunityItemSummaryReportDialog
        open={openOpportunityItemSummaryReportDialog}
        handleClose={handleCloseDialog}
      />

      {/* <OpportunityChangeLogReportDialog
        open={openOpportunityChangeLogReportDialog}
        handleClose={handleCloseDialog}
      /> */}

      <TaskItemSummaryReportDialog
        open={openTaskItemSummaryReportDialog}
        handleClose={handleCloseDialog}
      />

      <ProjectSummaryReportDialog
        open={openProCustRptDlg}
        handleClose={handleCloseDialog}
      />

      <ProjectSalesSummaryReportDialog
        open={openProSalesRptDlg}
        handleClose={handleCloseDialog}
      />

      <ProjectChangeLogReportDialog
        open={openProjectChangeLogReportDialog}
        handleClose={handleCloseDialog}
      />

      <SubtaskItemDurationReportDialog
        open={openSubtaskItemDurationReportDialog}
        handleClose={handleCloseDialog}
      />

      <OpportunityApprovalRequestReportDialog
        open={openOpportunityAppReqReportDialog}
        handleClose={handleCloseDialog}
      />

      <ProgramApprovalRequestReportDialog
        open={openProgramAppReqReportDialog}
        handleClose={handleCloseDialog}
      />

      <BrandApprovalRequestReportDialog
        open={openBrandAppReqReportDialog}
        handleClose={handleCloseDialog}
      />
    </PageContainer>
  );
};

export default ReportList;
