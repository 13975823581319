import { ITaskItem } from '../models/taskItem.model';
import { PageFilter } from '../state/app';
import { TaskItemListFilter } from '../state/taskItem';
import axios from '../utils/axios';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';

const baseUrl = `/api/taskItems`;

export const fetchTaskItemList = async ({
  filters,
  paging,
}: {
  filters: TaskItemListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchTaskItem = async (id: ITaskItem['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createTaskItem = async (taskItem: ITaskItem) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, taskItem);
};

export const updateTaskItem = async (taskItem: ITaskItem) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, taskItem);
};

export const deleteTaskItem = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};
