import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import SearchField from './SearchField';
import TableLoading from './TableLoading';
import { AppState } from '../../state/configureStore';
import { ProjectProgramState } from '../../state/projectProgram';
import { useDispatch, useSelector } from 'react-redux';
import { PageFilter } from '../../state/app';
import { TablePageSize } from '../../constants';
import {
  HanesProject,
  HanesProjectFilter,
  HanesProjectState,
} from '../../state/hanesProject/hanesProject.type';
import { fetchHanesProjectList } from '../../state/hanesProject';

export interface HanesProjectSearchDialogProps {
  open: boolean;
  item: string | undefined;
  onClose: (res: any) => void;
}

const HanesProjectSearchDialog = (props: HanesProjectSearchDialogProps) => {
  const { open, onClose, item } = props;
  const dispatch = useDispatch();

  const {
    items: hanesProjects,
    totalItems,
    loading,
  } = useSelector<AppState, HanesProjectState>((state) => state.hanesProject);

  const [filters, setFilters] = useState<HanesProjectFilter>({
    'ProjectName.StartsWith': '',
  });
  const initialPaging = { pageNumber: 0, pageSize: TablePageSize[0] };
  const [paging, setPaging] = useState<PageFilter>(initialPaging);

  const [selectedHanesProject, setSelectedHanesProject] = useState<HanesProject>();

  useEffect(() => {
    setFilters((filter) => {
      return {
        ...filter,
        'ProjectName.StartsWith': item,
      };
    });
  }, [setFilters, item]);

  useEffect(() => {
    if (open) {
      dispatch(fetchHanesProjectList(filters, paging));
    }
    return function cleanUp() {
      //   dispatch(clearProjectProgram());
    };
  }, [dispatch, filters, open, paging]);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true}>
      <DialogTitle>Select Hanes Project</DialogTitle>
      <DialogContent>
        <SearchField
          label="Name"
          onChange={handleChangeFilter}
          filterKey="ProjectName.StartsWith"
          filters={filters}
          fullWidth
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Hanes Project</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={2}>
              {hanesProjects.map((hanesProject, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked,
                        ) => {
                          if (checked) {
                            setSelectedHanesProject(hanesProject);
                          } else {
                            setSelectedHanesProject(undefined);
                          }
                        }}
                        checked={selectedHanesProject?.name === hanesProject.name}
                      />
                    </TableCell>
                    <TableCell>{hanesProject.name}</TableCell>
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[...TablePageSize]}
          component="div"
          count={totalItems}
          rowsPerPage={paging.pageSize}
          page={paging.pageNumber}
          onPageChange={handleChangePageNumber}
          onRowsPerPageChange={handleChangePageSize}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={selectedHanesProject === undefined}
          onClick={() => {
            if (selectedHanesProject) {
              setSelectedHanesProject(undefined);
              onClose(selectedHanesProject);
            }
          }}
        >
          Confirm
        </Button>
        <Button
          onClick={() => {
            setSelectedHanesProject(undefined);
            onClose(undefined);
          }}
          color="default"
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HanesProjectSearchDialog;
