import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '15px',
  },
  sendComment: {
    marginTop: '5px',
  },
}));

export default useStyles;
