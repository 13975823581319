import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import {
  createProjectCustomerGroup,
  deleteProjectCustomerGroup,
  fetchProjectCustomerGroup,
  fetchProjectCustomerGroupList,
  updateProjectCustomerGroup,
} from '../../services/projectCustomerGroupService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_PROJECT_CUSTOMER_GROUP,
  CREATE_PROJECT_CUSTOMER_GROUPS,
  DELETE_PROJECT_CUSTOMER_GROUP,
  FETCH_PROJECT_CUSTOMER_GROUP,
  FETCH_PROJECT_CUSTOMER_GROUP_LIST,
  fetchProjectCustomerGroupListBySmProjectId,
  UPDATE_PROJECT_CUSTOMER_GROUP,
} from './projectCustomerGroup.actions';
import { ProjectCustomerGroupCreateOrUpdatePayload } from './projectCustomerGroup.types';
import { AppState } from '../configureStore';
import { IProject } from '../../models/project.model';

function* fetchProjectCustomerGroupSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchProjectCustomerGroup, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchProjectCustomerGroupListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchProjectCustomerGroupList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createProjectCustomerGroupSaga(action: AppAction) {
  try {
    const { values } = action.payload as ProjectCustomerGroupCreateOrUpdatePayload;
    const successMessage = `Created customer group successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createProjectCustomerGroup, values);
    yield put(fetchProjectCustomerGroupListBySmProjectId(data.smProjectId));
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createProjectCustomerGroupsSaga(action: AppAction) {
  try {
    const { payload, callback } = action;

    yield put({ type: REQUEST(action.type) });

    for (const opCustGrp of payload) {
      yield call(createProjectCustomerGroup, opCustGrp);
    }

    const successMessage = `Created customer group successfully.`;
    yield put(fetchProjectCustomerGroupListBySmProjectId(payload[0].smProjectId));

    yield put({ type: SUCCESS(action.type), payload });

    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));

    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateProjectCustomerGroupSaga(action: AppAction) {
  try {
    const { values } = action.payload as ProjectCustomerGroupCreateOrUpdatePayload;
    const successMessage = `Updated customer group successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateProjectCustomerGroup, values);
    yield put(fetchProjectCustomerGroupListBySmProjectId(data.smProjectId));
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteProjectCustomerGroupSaga(action: AppAction) {
  const { payload } = action;
  const { id } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    yield call(deleteProjectCustomerGroup, id);
    const project = yield select(getProjectFromState);
    if (project.id) {
      yield put(fetchProjectCustomerGroupListBySmProjectId(project.id));
    }
    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Deleted successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function getProjectFromState(state: AppState): IProject {
  const { item } = state.project;
  return item;
}

export default function* watchProjectCustomerGroup() {
  yield takeLatest(FETCH_PROJECT_CUSTOMER_GROUP, fetchProjectCustomerGroupSaga);
  yield debounce(
    250,
    FETCH_PROJECT_CUSTOMER_GROUP_LIST,
    fetchProjectCustomerGroupListSaga,
  );
  yield takeLatest(CREATE_PROJECT_CUSTOMER_GROUP, createProjectCustomerGroupSaga);
  yield takeLatest(UPDATE_PROJECT_CUSTOMER_GROUP, updateProjectCustomerGroupSaga);
  yield takeLatest(DELETE_PROJECT_CUSTOMER_GROUP, deleteProjectCustomerGroupSaga);
  yield takeLatest(CREATE_PROJECT_CUSTOMER_GROUPS, createProjectCustomerGroupsSaga);
}
