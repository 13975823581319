import { IMoq } from '../../models/moq.model';
import { AppAction, PageFilter } from '../app';
import { SubtaskItemMoqListFilter } from './subtaskItemMoq.types';

export const FETCH_SUBTASK_ITEM_MOQ_LIST = 'FETCH_SUBTASK_ITEM_MOQ_LIST';
export const CLEAR_SUBTASK_ITEM_MOQ = 'CLEAR_SUBTASK_ITEM_MOQ';
export const CREATE_SUBTASK_ITEM_MOQ_LIST = 'CREATE_SUBTASK_ITEM_MOQ_LIST';
export const CREATE_MULTIPLE_SUBTASK_ITEM_MOQ_LIST =
  'CREATE_MULTIPLE_SUBTASK_ITEM_MOQ_LIST';

export const fetchSubtaskItemMoqList = (
  filters: SubtaskItemMoqListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SUBTASK_ITEM_MOQ_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchSubtaskItemMoqListBySmSubtaskItemId = (
  smSubtaskItemId: number,
): AppAction => {
  return {
    type: FETCH_SUBTASK_ITEM_MOQ_LIST,
    payload: {
      filters: { 'SmSubtaskItemId.EqualsTo': smSubtaskItemId },
      paging: { pageNumber: 0, pageSize: 99999 },
    },
  };
};

export const createSubtaskItemMoqForSubtaskItem = (
  smSubtaskItemId: number,
  smSubtaskItemMoqs: Array<IMoq>,
) => ({
  type: CREATE_SUBTASK_ITEM_MOQ_LIST,
  payload: {
    smSubtaskItemId,
    smSubtaskItemMoqs,
  },
});

export const createMultipleSubtaskItemMoqList = (payload: any) => ({
  type: CREATE_MULTIPLE_SUBTASK_ITEM_MOQ_LIST,
  payload,
});

export const clearSubtaskItemMoq = () => ({
  type: CLEAR_SUBTASK_ITEM_MOQ,
});
