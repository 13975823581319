import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createSalesTeamMember,
  deleteSalesTeamMember,
  fetchSalesTeamMemberList,
  updateSalesTeamMember,
} from '../../services/salesTeamMemberService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_SALESTEAM_MEMBER,
  DELETE_SALESTEAM_MEMBER,
  FETCH_SALESTEAM_MEMBER_LIST,
  UPDATE_SALESTEAM_MEMBER,
} from './salesTeamMember.actions';

function* createSalesTeamMemberSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createSalesTeamMember, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Added SalesTeam member successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield call(fetchSalesTeamMemberListSaga, {
      type: FETCH_SALESTEAM_MEMBER_LIST,
      payload: {
        filters: { 'SalesTeamId.EqualsTo': action.payload.salesTeamId },
        paging: { pageNumber: 0, pageSize: 99999 },
      },
    });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchSalesTeamMemberListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSalesTeamMemberList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateSalesTeamMemberSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateSalesTeamMember, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Updated SalesTeam member successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield call(fetchSalesTeamMemberListSaga, {
      type: FETCH_SALESTEAM_MEMBER_LIST,
      payload: {
        filters: { 'SalesTeamId.EqualsTo': action.payload.salesTeamId },
        paging: { pageNumber: 0, pageSize: 99999 },
      },
    });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteSalesTeamMemberSaga(action: AppAction) {
  const { payload } = action;
  const { id, salesTeamId } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    yield call(deleteSalesTeamMember, id);
    const successMessage = `Deleted successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield call(fetchSalesTeamMemberListSaga, {
      type: FETCH_SALESTEAM_MEMBER_LIST,
      payload: {
        filters: { 'SalesTeamId.EqualsTo': salesTeamId },
        paging: { pageNumber: 0, pageSize: 99999 },
      },
    });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSalesTeamMember() {
  yield takeLatest(CREATE_SALESTEAM_MEMBER, createSalesTeamMemberSaga);
  yield debounce(250, FETCH_SALESTEAM_MEMBER_LIST, fetchSalesTeamMemberListSaga);
  yield takeLatest(UPDATE_SALESTEAM_MEMBER, updateSalesTeamMemberSaga);
  yield takeLatest(DELETE_SALESTEAM_MEMBER, deleteSalesTeamMemberSaga);
}
