import { AppAction, PageFilter } from '../app';
import {
  Company,
  CompanyCreateOrUpdatePayload,
  CompanyListFilter,
} from './company.types';

export const FETCH_COMPANY = 'FETCH_COMPANY';
export const FETCH_COMPANY_LIST = 'FETCH_COMPANY_LIST';
export const CREATE_COMPANY = 'CREATE_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const CLEAR_COMPANY = 'CLEAR_COMPANY';

export const fetchCompany = (id: Company['id']): AppAction => ({
  type: FETCH_COMPANY,
  payload: id,
});

export const fetchCompanyList = (
  filters: CompanyListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_COMPANY_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createCompany = (payload: CompanyCreateOrUpdatePayload): AppAction => ({
  type: CREATE_COMPANY,
  payload,
});

export const updateCompany = (payload: CompanyCreateOrUpdatePayload): AppAction => ({
  type: UPDATE_COMPANY,
  payload,
});

export const clearCompany = () => ({
  type: CLEAR_COMPANY,
});


