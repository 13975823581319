import { CardContent, Grid, Paper, Typography, Box } from '@material-ui/core';
import React from 'react';
import { CheckBoxFastField, TextFieldFastField } from '../../../components';

interface SampleDevelopmentTlWashingProps {}

const SampleDevelopmentTlWashing: React.FC<SampleDevelopmentTlWashingProps> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Washing</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>Standard Test: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoStandardEU" label="EU Standard" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoStandardUS" label="US Standard" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoStandardAsia" label="Asia Standard" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoStandardNike" label="Nike Standard" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField
                name="washingInfoStandardDecathlon"
                label="Decathlon Standard"
              />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoStandardEtc" label="Etc" />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="washingInfoStandardEtcData"
                label="Etc"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={5} />
            <Grid item xs={12} md={2}>
              <Typography>Test: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoTest5Washes" label="5 Washes" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoTest10Washes" label="10 Washes" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoTest15Washes" label="15 Washes" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoTest20Washes" label="20 Washes" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoTest30Washes" label="30 Washes" />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoTest50Washes" label="50 Washes" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoTestEtcWashes" label="Etc" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextFieldFastField
                name="washingInfoTestEtcDataWashes"
                label="Etc"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={2}>
              <Typography>Machine: </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="washingInfoMacBrand"
                label="Brand"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="washingInfoMacSeries"
                label="Series"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="washingInfoMacSpeed"
                label="Speed"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoMacDelicate" label="Delicate" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoMacNormal" label="Normal" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoMacHeavy" label="Heavy" />
            </Grid>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={2}>
              <Typography>Drying: </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoDryTumbleLow" label="Tumble Low" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoDryTumbleMed" label="Tumble Med" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoDryTumbleHigh" label="Tumble High" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoDryTumbleLine" label="Line Dry" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoDryTumbleLayFlat" label="Lay Flat" />
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>Detergent: </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                type="number"
                step="any"
                name="washingInfoDetergentVolumn"
                label="Volumn"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                name="washingInfoDetergentBrand"
                label="Brand"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={2}>
              <Typography>Water: </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                type="number"
                step="any"
                name="washingInfoWaterVolumn"
                label="Volumn"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoWaterHot60C" label="Hot(60C)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoWaterWarm40C" label="Warm(40C)" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="washingInfoWaterCold30C" label="Cold(30C)" />
            </Grid>
            <Grid item xs={12} md={1} />
            <Grid item xs={12} md={2}>
              <Typography>Fabric: </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldFastField
                type="number"
                step="any"
                name="washingInfoFabricVolumn"
                label="Volumn"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentTlWashing;
