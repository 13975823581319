import React from 'react';
import { FastField, FieldProps } from 'formik';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { UnitOfMeasurementOptions as unitOfMeasurements } from '../../../constants/unitOfMeasurement';

interface UnitOfMeasurementsFastFieldProps {
  name: string;
  label: string;
  [x: string]: any;
}

const UnitOfMeasurementsFastField: React.FC<UnitOfMeasurementsFastFieldProps> = props => {
  const { name, label, ...other } = props;
  return (
    <FastField name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }: FieldProps<any>) => {
        return (
          <FormControl fullWidth>
            <InputLabel id={name}>{label}</InputLabel>
            <Select
              labelId={name}
              onChange={(
                event: React.ChangeEvent<{
                  value: unknown;
                }>,
              ) => {
                form.setFieldValue(name, event.target.value as string);
              }}
              {...field}
              {...other}
            >
              {unitOfMeasurements.map(unitOfMeasurement => (
                <MenuItem key={unitOfMeasurement.value} value={unitOfMeasurement.value}>
                  {unitOfMeasurement.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }}
    </FastField>
  );
};

export default UnitOfMeasurementsFastField;
