import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { Send as SendIcon } from '@material-ui/icons';
import {
  changeProgramAndBrandRequestStatus,
  ProgramAndBrandRequest,
} from '../../../state/programAndBrandRequest';
import { ProgramAndBrandRequestStatus } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { PageTopMenuContainer } from '../../components';
import { AppState } from '../../../state/configureStore';
import { AuthState } from '../../../state/auth';

interface ProgramAndBrandRequestActionMenuProps {
  programAndBrandRequest: ProgramAndBrandRequest;
}

const allowSubmitForApprovalStatuses = [
  ProgramAndBrandRequestStatus.PENDING,
  ProgramAndBrandRequestStatus.REJECT,
];
const allowApprovalStatuses = [ProgramAndBrandRequestStatus.WAITING_FOR_APPROVE];

const ProgramAndBrandRequestActionMenu: React.FC<ProgramAndBrandRequestActionMenuProps> = (
  props,
) => {
  const dispatch = useDispatch();
  const { programAndBrandRequest } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const [openConfirmUnlockApprovedDialog, setOpenConfirmUnlockApprovedDialog] = useState(
    false,
  );
  const { user } = useSelector<AppState, AuthState>((state) => state.auth);
  const isAdminOrManager =
    user!.roles!.find((u) => u === 'ADMIN' || u === 'MANAGER') !== undefined;

  return (
    <>
      {allowSubmitForApprovalStatuses.includes(programAndBrandRequest.status!) && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            dispatch(
              changeProgramAndBrandRequestStatus(
                programAndBrandRequest,
                ProgramAndBrandRequestStatus.WAITING_FOR_APPROVE,
              ),
            );
          }}
          startIcon={<SendIcon />}
        >
          Submit For Approval
        </Button>
      )}
      {isAdminOrManager &&
        allowApprovalStatuses.includes(programAndBrandRequest.status!) && (
          <>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                dispatch(
                  changeProgramAndBrandRequestStatus(
                    programAndBrandRequest,
                    ProgramAndBrandRequestStatus.APPROVE,
                  ),
                );
              }}
            >
              Approve
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                dispatch(
                  changeProgramAndBrandRequestStatus(
                    programAndBrandRequest,
                    ProgramAndBrandRequestStatus.REJECT,
                  ),
                );
              }}
            >
              Reject
            </Button>
          </>
        )}
    </>
  );
};

export default ProgramAndBrandRequestActionMenu;
