import { AppBar, Fade, Tab, Tabs, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { IOpportunity } from '../../../../models/opportunity.model';
import { Brand } from '../../../../state/brand';
import { Program } from '../../../../state/program';
import { PageContainer } from '../../../components';
import ApprovalListSection from './ApprovalListSection';
import ChangeLogOpportunity from './ChangeLogOpportunity';
import OpportunityCustomerForm from './OpportunityCustomerForm';
import OpportunityCustomerGroupForm from './OpportunityCustomerGroupForm';
import OpportunityItemSection from './OpportunityItemSection';

interface OpportunitySubFormProps {
  opportunity: IOpportunity;
}

const useStyles = makeStyles({
  tab: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#3f51b5',
      height: 4,
    },
    '& .MuiTab-root.Mui-selected': {
      background: '#d9d9d9',
    },
  },
});

const OpportunitySubForm: React.FC<OpportunitySubFormProps> = (props) => {
  const { opportunity } = props;
  const [tabIndex, setTabIndex] = useState<number>(0);
  const classes = useStyles();

  return (
    <Fade in={true}>
      <PageContainer fixHeight={500}>
        <AppBar position="static" color="default" variant="outlined">
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, newIndex) => setTabIndex(newIndex)}
            variant="scrollable"
            className={classes.tab}
          >
            <Tab label="Customer" />
            <Tab label="Customer Group" />
            <Tab label="Block Item" />
            <Tab label="Approval Requests" />
            <Tab label="Change Logs" />
          </Tabs>
        </AppBar>
        {tabIndex === 0 && (
          <OpportunityCustomerForm
            opportunityId={opportunity.id as number}
            companyId={opportunity.companyId as number}
            smProjectId={opportunity.smProjectId as number}
          />
        )}
        {tabIndex === 1 && (
          <OpportunityCustomerGroupForm
            opportunityId={opportunity.id as number}
            companyId={opportunity.companyId as number}
          />
        )}
        {tabIndex === 2 && (
          <OpportunityItemSection
            opportunityId={opportunity.id as number}
            program={opportunity.program as Program}
            brand={opportunity.brand as Brand}
          />
        )}
        {tabIndex === 3 && (
          <ApprovalListSection opportunityId={opportunity.id as number} />
        )}
        {tabIndex === 4 && (
          <ChangeLogOpportunity opportunityId={opportunity.id as number} />
        )}
      </PageContainer>
    </Fade>
  );
};

export default OpportunitySubForm;
