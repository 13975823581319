import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as changeLog from '../../../../state/changeLog';
import { ChangeLog } from '../../../components';

export interface ChangeLogOpportunityProps {
  opportunityId: number;
}

const ChangeLogOpportunity: React.FC<ChangeLogOpportunityProps> = props => {
  const dispatch = useDispatch();

  const { opportunityId } = props;

  useEffect(() => {
    dispatch(changeLog.fetchOpportunityLog(opportunityId));
    return () => {
      dispatch(changeLog.clearOpportunityLog());
    };
  }, [dispatch, opportunityId]);

  const { loading, opportunity: changeLogOpportunityList } = useSelector(
    changeLog.selectState,
  );

  return <ChangeLog loading={loading} changeLogList={changeLogOpportunityList} />;
};

export default ChangeLogOpportunity;
