import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ITask } from '../../../models/task.model';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import { ChipList, PageContainer, PageTitle, TableLoading } from '../../components';
import {
  clearTaskItem,
  fetchTaskItemList,
  TaskItemListFilter,
  TaskItemState,
} from '../../../state/taskItem';
import { useTableSorting, useFilter } from '../../hooks';
import SortLabel from '../../components/SortLabel';
import TaskItemFilter from './TaskItemFilter';

const initialTaskItemFilter: TaskItemListFilter = {};

const TaskTaskItemList: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const [filters, setFilters] = useFilter<TaskItemListFilter>(initialTaskItemFilter);

  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const [newSort, setNewSort] = useTableSorting({
    orderBy: 'SMTask.taskNumber',
    direction: 'asc',
  });

  const {
    items: taskItems,
    totalItems,
    loading,
  } = useSelector<AppState, TaskItemState>((state) => state.taskItem);

  const handleChangeFilter = (newFilter: TaskItemListFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchTaskItemList(filters, { ...paging, sort: newSort }));
  }, [dispatch, paging, filters, newSort]);

  useEffect(() => {
    return function cleanUp() {
      dispatch(clearTaskItem());
    };
  }, [dispatch]);

  const editTask = (id: ITask['id']): void => {
    history.push(`/tasks/form/${id}`);
  };

  return (
    <PageContainer>
      <PageTitle>Task Item</PageTitle>
      <TaskItemFilter
        filters={filters}
        onChangeFilter={handleChangeFilter}
        initialFilter={initialTaskItemFilter}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="SMTask.taskNumber"
              >
                Task Number
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="OpportunityItem.Opportunity.assignmentNumber"
              >
                Assignment Number
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="OpportunityItem.Opportunity.Program.Name"
              >
                Program
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="OpportunityItem.Opportunity.Brand.Name"
              >
                Brand
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="OpportunityItem.Block.BlockNo"
              >
                Block
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="OpportunityItem.Block.Item"
              >
                Item
              </SortLabel>
            </TableCell>
            {/* <TableCell>Sales</TableCell>
            <TableCell>CS</TableCell> */}
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={8}>
            {taskItems.map((taskItem, index) => {
              const salesNames =
                taskItem.opportunityItem?.opportunity?.responsibility?.responsibilitySalesTeamMembers
                  ?.filter((rs) => rs.active === true)
                  ?.map((rs) => {
                    return rs.salesTeamMember?.user?.fullNameWithUserName!;
                  })!;
              const csNames =
                taskItem.opportunityItem?.opportunity?.responsibility?.responsibilityWorkingTeamMembers
                  ?.filter((rc) => rc.active === true)
                  ?.map((rs) => {
                    return rs.smWorkingTeamMember?.user?.fullNameWithUserName!;
                  })!;

              return (
                <TableRow key={taskItem.id}>
                  <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                  <TableCell>{taskItem.smTask!.taskNumber!}</TableCell>
                  <TableCell>
                    {taskItem.opportunityItem!.opportunity!.assignmentNumber}
                  </TableCell>
                  <TableCell>
                    {taskItem.opportunityItem!.opportunity!.program!.name}
                  </TableCell>
                  <TableCell>
                    {taskItem.opportunityItem!.opportunity!.brand!.name}
                  </TableCell>
                  <TableCell>{taskItem.opportunityItem!.block!.blockNo}</TableCell>
                  <TableCell>{taskItem.opportunityItem!.block!.item}</TableCell>
                  {/* <TableCell>
                    <ChipList list={salesNames} noAllLabel/>
                  </TableCell>
                  <TableCell>
                    <ChipList list={csNames} noAllLabel/>
                  </TableCell> */}
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={() => editTask(taskItem.smTask!.id!)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default TaskTaskItemList;
