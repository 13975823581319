import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createProgram,
  fetchProgram,
  fetchProgramList,
  updateProgram,
} from '../../services/programService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_PROGRAM,
  FETCH_PROGRAM,
  FETCH_PROGRAM_LIST,
  UPDATE_PROGRAM,
} from './program.actions';
import { ProgramCreateOrUpdatePayload } from './program.types';

const listPageUrl = `/settings/programs`;

function* fetchProgramSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchProgram, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchProgramListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchProgramList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createProgramSaga(action: AppAction) {
  try {
    const { values, history } = action.payload as ProgramCreateOrUpdatePayload;
    const successMessage = `Created ${values.name} program successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createProgram, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(listPageUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateProgramSaga(action: AppAction) {
  try {
    const { values, history } = action.payload as ProgramCreateOrUpdatePayload;
    const successMessage = `Updated ${values.name} program successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateProgram, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    history.push(listPageUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchProgram() {
  yield takeLatest(FETCH_PROGRAM, fetchProgramSaga);
  yield debounce(250, FETCH_PROGRAM_LIST, fetchProgramListSaga);
  yield takeLatest(CREATE_PROGRAM, createProgramSaga);
  yield takeLatest(UPDATE_PROGRAM, updateProgramSaga);
}
