import { makeStyles, Theme } from '@material-ui/core/styles';
import { LeftDrawerProps } from './drawer.types';

export default makeStyles((theme: Theme) => ({
  drawer: (props: LeftDrawerProps) => ({
    width: props.drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  }),
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  drawerPaper: (props: LeftDrawerProps) => ({
    width: props.drawerWidth,
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
    color: '#5876a3',
  }),
}));
