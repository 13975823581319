import { Option } from '../views/components/EditableTable/SelectOptionCellEditor';

export type ForecastFrequencyType =
  | 'ALL_TIME'
  | 'ONE_YEAR'
  | 'SIX_MONTHS'
  | 'FOURTH_MONTHS'
  | 'THREE_MONTHS'
  | 'ONE_MONTH';

const ForecastFrequency = Object.freeze({
  ALL_TIME: 'ALL_TIME' as ForecastFrequencyType,
  ONE_YEAR: 'ONE_YEAR' as ForecastFrequencyType,
  SIX_MONTHS: 'SIX_MONTHS' as ForecastFrequencyType,
  FOURTH_MONTHS: 'FOURTH_MONTHS' as ForecastFrequencyType,
  THREE_MONTHS: 'THREE_MONTHS' as ForecastFrequencyType,
  ONE_MONTH: 'ONE_MONTH' as ForecastFrequencyType,
});

export default ForecastFrequency;

export const ForecastFrequencyLabel = Object.freeze({
  [ForecastFrequency.ALL_TIME]: 'All Time',
  [ForecastFrequency.ONE_YEAR]: '1 Year',
  [ForecastFrequency.SIX_MONTHS]: '6 Months',
  [ForecastFrequency.FOURTH_MONTHS]: '4 Months',
  [ForecastFrequency.THREE_MONTHS]: '3 Months',
  [ForecastFrequency.ONE_MONTH]: '1 Month',
});

export const getForecastFrequencyFromLabel = (label: string): ForecastFrequencyType => {
  return Object.keys(ForecastFrequency).find(
    key => ForecastFrequencyLabel[key] === label,
  ) as ForecastFrequencyType;
};

export const ForecastFrequencyOptions: Array<Option> = Object.keys(ForecastFrequency).map(
  f => {
    return { label: ForecastFrequencyLabel[f], value: f };
  },
);
