import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Edit as EditIcon, OpenInNew as OpenInNewIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubtaskItemStatusLabel } from '../../../../constants';
import { ISubtaskItem } from '../../../../models/subtaskItem.model';
import { ISubtaskType } from '../../../../models/subtaskType.model';
import { AppState } from '../../../../state/configureStore';
import { SubtaskState } from '../../../../state/subtask';
import { SubtaskItemState } from '../../../../state/subtaskItem';
import { SubtaskTypeState } from '../../../../state/subtaskType';
import {
  MultipleSubtaskItemForecastEditDialog,
  SubtaskItemForecastTable,
} from '../../../components/Forecast';
import {
  MultipleSubtaskItemMoqEditDialog,
  SubtaskItemMoqTable,
} from '../../../components/Moq';
import SubtaskItemActionMenu from './SubtaskItemActionMenu';
import SubtaskItemArtTemplateTable from './SubtaskItemArtTemplateTable';
import SubtaskItemEditStatusDialog from './SubtaskItemEditStatusDialog';
import SubtaskItemTimelineDialog from './SubtaskItemTimelineDialog';
import { Timer as TimerIcon } from '@material-ui/icons';
import {
  SubtaskItemChangeStatusLogState,
  fetchSubtaskItemChangeStatusLogList,
} from '../../../../state/subtaskItemChangeStatusLog';
import { SubtaskStatus } from '../../../../constants/subtaskType';

const SubtaskItemTable: React.FC = () => {
  const [openSubtaskEditStatusDialog, setOpenSubtaskEditStatusDialog] =
    useState<boolean>(false);
  const [openMultipleSubtaskItemMoqEditDialog, setOpenMultipleSubtaskItemMoqEditDialog] =
    useState<boolean>(false);
  const [
    openMultipleSubtaskItemForecastEditDialog,
    setOpenMultipleSubtaskItemForecastEditDialog,
  ] = useState<boolean>(false);

  const [openTimelineDialog, setOpenTimelineDialog] = useState<boolean>(false);
  const [selectedSubtaskItemId, setSelectedSubtaskItemId] = useState<number>(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedSubtaskItems, setSelectedSubtaskItems] = useState<
    ReadonlyArray<ISubtaskItem>
  >([]);
  const noSelectedSubtaskItems = selectedSubtaskItems.length === 0;
  const { items: subtaskItemList } = useSelector<AppState, SubtaskItemState>(
    (state) => state.subtaskItem,
  );
  const [selectedSubtaskItemOnTable, setSelectedSubtaskItemOnTable] =
    useState<ISubtaskItem>({});
  const { item: subtask } = useSelector<AppState, SubtaskState>((state) => state.subtask);
  const { items: subtaskTypeList } = useSelector<AppState, SubtaskTypeState>(
    (state) => state.subtaskType,
  );
  const findSubtaskTypePrice = subtaskTypeList.find(
    (subtaskType: ISubtaskType) => subtaskType.code === 'PRICE',
  );

  const findSubtaskTypeArtwork = subtaskTypeList.find(
    (subtaskType: ISubtaskType) => subtaskType.code === 'ARTWORK',
  );

  const isSubtaskTypePrice = findSubtaskTypePrice
    ? subtask.smSubtaskTypeId! === findSubtaskTypePrice.id!
    : false;

  const isSubtaskTypeArtwork = findSubtaskTypeArtwork
    ? subtask.smSubtaskTypeId! === findSubtaskTypeArtwork.id!
    : false;

  const [isEBlock, setIsEBlock] = useState(false);

  const noSelectedSubtaskItemsOnTable =
    Object.keys(selectedSubtaskItemOnTable).length === 0 &&
    selectedSubtaskItemOnTable.constructor === Object;

  useEffect(() => {
    const newSelectedSubtaskItemOnTable = subtaskItemList.find(
      (subtaskItem) => subtaskItem.id! === selectedSubtaskItemOnTable.id!,
    );
    if (newSelectedSubtaskItemOnTable) {
      setSelectedSubtaskItemOnTable(newSelectedSubtaskItemOnTable);
    }
  }, [subtaskItemList, selectedSubtaskItemOnTable]);

  const { items: subtaskItemChangeStatusLogs, loading: logsLoading } = useSelector<
    AppState,
    SubtaskItemChangeStatusLogState
  >((state) => state.subtaskItemChangeStatusLog);

  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedSubtaskItemOnTable.smSubtaskId !== undefined) {
      dispatch(
        fetchSubtaskItemChangeStatusLogList(
          { 'SmSubtaskItemId.EqualsTo': selectedSubtaskItemOnTable.id },
          {
            pageNumber: 0,
            pageSize: 999,
          },
        ),
      );
    }
    if (selectedSubtaskItemOnTable.status !== undefined) {
      const index = steps.findIndex(
        (f) => f === SubtaskItemStatusLabel[selectedSubtaskItemOnTable.status!],
      );
      setActiveStep(index);
    }
  }, [selectedSubtaskItemOnTable]);

  // const steps = [
  //   'Pending',
  //   'In Progress',
  //   'Waiting NPD',
  //   'Approve By NPD',
  //   'Waiting Customer',
  //   'Done',
  // ];

  const [steps, setSteps] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState<number>(0);

  const isOpenStepper =
    !subtask.useCommonStatus && !noSelectedSubtaskItemsOnTable && !logsLoading;

  useEffect(() => {
    // if (subtask.smSubtaskType?.code === 'PROOF') {
    //   setSteps(SubtaskStatus.proof);
    // } else if (subtask.smSubtaskType?.code === 'MOCKUP') {
    //   setSteps(SubtaskStatus.mockup);
    // } else if (subtask.smSubtaskType?.code === 'ARTWORK') {
    //   setSteps(SubtaskStatus.artwork);
    // }

    if (subtask.smSubtaskType?.code === 'PROOF') {
      setSteps(SubtaskStatus.proof);
    } else if (subtask.smSubtaskType?.code === 'MOCKUP') {
      setSteps(SubtaskStatus.mockup);
    } else if (subtask.smSubtaskType?.code === 'VERIFY DATA') {
      setSteps(SubtaskStatus.verifyData);
    } else {
      setSteps(SubtaskStatus.artwork);
    }
  }, [subtask]);

  return (
    <Box>
      <Box m={1}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setOpenSubtaskEditStatusDialog(true);
          }}
          startIcon={<EditIcon />}
          disabled={noSelectedSubtaskItems}
        >
          {`Change Status`}
        </Button>
        {isSubtaskTypePrice && (
          <>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOpenMultipleSubtaskItemMoqEditDialog(true);
              }}
              startIcon={<EditIcon />}
              disabled={noSelectedSubtaskItems}
            >
              {`Change MOQ`}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOpenMultipleSubtaskItemForecastEditDialog(true);
              }}
              startIcon={<EditIcon />}
              disabled={noSelectedSubtaskItems}
            >
              {`Change Forecast`}
            </Button>
          </>
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked) => {
                      setSelectAll(checked);
                      if (checked) {
                        setSelectedSubtaskItems([...subtaskItemList]);
                      } else {
                        setSelectedSubtaskItems([]);
                      }
                    }}
                    checked={selectAll}
                  />
                </TableCell>
                <TableCell>No.</TableCell>
                <TableCell>Block</TableCell>
                <TableCell>Program</TableCell>
                <TableCell>Brand</TableCell>
                <TableCell>Item</TableCell>
                {isSubtaskTypeArtwork && <TableCell>Artwork Link</TableCell>}
                <TableCell>Status</TableCell>
                <TableCell>Timeline</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subtaskItemList.map((subtaskItem, index) => {
                const isCheckboxChecked =
                  selectedSubtaskItems.findIndex((item) => item.id === subtaskItem.id) !==
                  -1;
                return (
                  <TableRow
                    key={subtaskItem.id!}
                    onClick={() => {
                      setSelectedSubtaskItemOnTable(subtaskItem);
                      setIsEBlock(
                        subtaskItem.smTaskItem!.opportunityItem!.block!.isEBlock!,
                      );
                    }}
                    selected={selectedSubtaskItemOnTable.id! === subtaskItem.id!}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked,
                        ) => {
                          if (checked) {
                            setSelectedSubtaskItems(
                              selectedSubtaskItems.concat([subtaskItem]),
                            );
                          } else {
                            setSelectAll(false);
                            setSelectedSubtaskItems(
                              selectedSubtaskItems.filter(
                                (item) => item.id !== subtaskItem.id,
                              ),
                            );
                          }
                        }}
                        checked={isCheckboxChecked}
                      />
                    </TableCell>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {subtaskItem.smTaskItem!.opportunityItem!.block!.blockNo}
                    </TableCell>
                    <TableCell>
                      {
                        subtaskItem.smTaskItem!.opportunityItem!.opportunity!.program!
                          .name
                      }
                    </TableCell>
                    <TableCell>
                      {subtaskItem.smTaskItem!.opportunityItem!.opportunity!.brand!.name}
                    </TableCell>
                    <TableCell>
                      {subtaskItem.smTaskItem!.opportunityItem!.block!.item}
                    </TableCell>
                    {isSubtaskTypeArtwork && (
                      <TableCell>
                        {subtaskItem.artworkLink && (
                          <IconButton
                            component="span"
                            onClick={() => {
                              window.open(subtaskItem.artworkLink, '_blank');
                            }}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    )}
                    <TableCell>{SubtaskItemStatusLabel[subtaskItem.status!]}</TableCell>
                    <TableCell>
                      <Button
                        color="default"
                        size="small"
                        variant="contained"
                        onClick={() => {
                          console.log(subtaskItem);
                          setSelectedSubtaskItemId(subtaskItem.id ?? 0);
                          setOpenTimelineDialog(true);
                        }}
                        startIcon={<TimerIcon />}
                      >
                        Open
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              {/* {!isOpenStepper && <ReactLoading type="spin" color="#3F51B5" />} */}
              {isOpenStepper && (
                <>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => {
                      const stepProps: { completed?: boolean } = {};
                      const labelProps: { optional?: React.ReactNode } = {};
                      stepProps.completed = index < activeStep;

                      return (
                        <Step key={label} {...stepProps} style={{ height: '50px' }}>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  <Typography variant="h6" gutterBottom>
                    Change Status Item:{' '}
                    {selectedSubtaskItemOnTable.smTaskItem?.opportunityItem?.block?.item}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box mt={2}>
                {!noSelectedSubtaskItemsOnTable && (
                  <>
                    <Box m={1}>
                      <SubtaskItemActionMenu subtaskItem={selectedSubtaskItemOnTable} />
                    </Box>
                    {isSubtaskTypeArtwork && isEBlock && (
                      <>
                        <Box m={1}>
                          <Card>
                            <CardContent>
                              <SubtaskItemArtTemplateTable
                                subtaskItem={selectedSubtaskItemOnTable}
                              />
                            </CardContent>
                          </Card>
                        </Box>
                      </>
                    )}
                    {isSubtaskTypePrice && (
                      <>
                        <Box m={1}>
                          <Card>
                            <CardContent>
                              <SubtaskItemForecastTable
                                subtaskItem={selectedSubtaskItemOnTable}
                              />
                            </CardContent>
                          </Card>
                        </Box>
                        <Box m={1}>
                          <Card>
                            <CardContent>
                              <SubtaskItemMoqTable
                                subtaskItem={selectedSubtaskItemOnTable}
                              />
                            </CardContent>
                          </Card>
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={6}>
          <Box mt={2}>
            {!noSelectedSubtaskItemsOnTable && (
              <>
                <Box m={1}>
                  <SubtaskItemActionMenu subtaskItem={selectedSubtaskItemOnTable} />
                </Box>
                {isSubtaskTypeArtwork && isEBlock && (
                  <>
                    <Box m={1}>
                      <Card>
                        <CardContent>
                          <SubtaskItemArtTemplateTable
                            subtaskItem={selectedSubtaskItemOnTable}
                          />
                        </CardContent>
                      </Card>
                    </Box>
                  </>
                )}
                {isSubtaskTypePrice && (
                  <>
                    <Box m={1}>
                      <Card>
                        <CardContent>
                          <SubtaskItemForecastTable
                            subtaskItem={selectedSubtaskItemOnTable}
                          />
                        </CardContent>
                      </Card>
                    </Box>
                    <Box m={1}>
                      <Card>
                        <CardContent>
                          <SubtaskItemMoqTable subtaskItem={selectedSubtaskItemOnTable} />
                        </CardContent>
                      </Card>
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        </Grid> */}
      </Grid>

      <SubtaskItemEditStatusDialog
        onClose={() => {
          setOpenSubtaskEditStatusDialog(false);
          setSelectedSubtaskItems([]);
          setSelectAll(false);
        }}
        open={openSubtaskEditStatusDialog}
        subtaskItems={selectedSubtaskItems}
      />
      <MultipleSubtaskItemMoqEditDialog
        open={openMultipleSubtaskItemMoqEditDialog}
        subtaskItems={selectedSubtaskItems}
        onClose={() => {
          setOpenMultipleSubtaskItemMoqEditDialog(false);
          setSelectedSubtaskItems([]);
          setSelectAll(false);
        }}
        selectedSubtaskItemOnTable={selectedSubtaskItemOnTable}
      />
      <MultipleSubtaskItemForecastEditDialog
        open={openMultipleSubtaskItemForecastEditDialog}
        subtaskItems={selectedSubtaskItems}
        onClose={() => {
          setOpenMultipleSubtaskItemForecastEditDialog(false);
          setSelectedSubtaskItems([]);
          setSelectAll(false);
        }}
        selectedSubtaskItemOnTable={selectedSubtaskItemOnTable}
      />

      <SubtaskItemTimelineDialog
        open={openTimelineDialog}
        subtaskItemId={selectedSubtaskItemId}
        handleClose={() => {
          setOpenTimelineDialog(false);
        }}
      ></SubtaskItemTimelineDialog>
    </Box>
  );
};

export default SubtaskItemTable;
