import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_OPPORTUNITY_ITEM,
  FETCH_OPPORTUNITY_ITEM_LIST,
  DELETE_OPPORTUNITY_ITEM,
  UPDATE_OPPORTUNITY_ITEM, FETCH_OPPORTUNITY_ITEM,
} from './opportunityItem.actions';
import { OpportunityItemState } from './opportunityItem.types';
import { defaultValue, IOpportunityItem } from '../../models/opportunityItem.model';

const initialState: OpportunityItemState = {
  errorMessage: '',
  item: defaultValue,
  items: [] as ReadonlyArray<IOpportunityItem>,
  loading: false,
  totalItems: 0,
};

export default (
  state: OpportunityItemState = initialState,
  action: AppAction,
): OpportunityItemState => {
  switch (action.type) {
    case REQUEST(FETCH_OPPORTUNITY_ITEM_LIST):
    case REQUEST(FETCH_OPPORTUNITY_ITEM):
    case REQUEST(DELETE_OPPORTUNITY_ITEM):
    case REQUEST(UPDATE_OPPORTUNITY_ITEM):
      return { ...state, loading: true };
    case SUCCESS(FETCH_OPPORTUNITY_ITEM_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case SUCCESS(FETCH_OPPORTUNITY_ITEM):
      return {
        ...state,
        item: action.payload.data,
        loading: false,
      };
    case SUCCESS(DELETE_OPPORTUNITY_ITEM):
      return { ...state, loading: false };
    case FAILURE(FETCH_OPPORTUNITY_ITEM_LIST):
    case FAILURE(FETCH_OPPORTUNITY_ITEM):
    case FAILURE(DELETE_OPPORTUNITY_ITEM):
    case FAILURE(UPDATE_OPPORTUNITY_ITEM):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(UPDATE_OPPORTUNITY_ITEM):
      return { ...state, loading: false, item: action.payload.data };

    case CLEAR_OPPORTUNITY_ITEM:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
