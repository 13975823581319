import { AppAction } from '../app';
import { ITask } from '../../models/task.model';
import { ISubtask } from '../../models/subtask.model';
import {
  ITaskItem,
  ITaskItemWithMoqs,
  ITaskItemWithForecasts,
} from '../../models/taskItem.model';

export const CREATE_TASK_IN_TASK_CREATE = 'CREATE_TASK_IN_TASK_CREATE';
export const ADD_SELECTED_SUBTASK_TYPE = 'ADD_SELECTED_SUBTASK_TYPE';
export const ADD_SELECTED_TASK_ITEM_IN_TASK_CREATE =
  'ADD_SELECTED_TASK_ITEM_IN_TASK_CREATE';
export const CREATE_SUBTASKS_IN_TASK_CREATE = 'CREATE_SUBTASKS_IN_TASK_CREATE';
export const CLEAR_TASK_CREATE_DIALOG = 'CLEAR_TASK_CREATE_DIALOG';
export const CREATE_TASK_SUBTASK_SUBTASK_ITEM_AND_ETC =
  'CREATE_TASK_SUBTASK_SUBTASK_ITEM_AND_ETC';
export const ADD_SELECTED_TASK_ITEMS_WITH_MOQS_IN_TASK_CREATE =
  'ADD_SELECTED_TASK_ITEMS_WITH_MOQS_IN_TASK_CREATE';
export const EDIT_SELECTED_TASK_ITEM_WITH_MOQS_IN_TASK_CREATE =
  'EDIT_SELECTED_TASK_ITEM_WITH_MOQS_IN_TASK_CREATE';
export const EDIT_MULTIPLE_SELECTED_TASK_ITEM_WITH_MOQS_IN_TASK_CREATE =
  'EDIT_MULTIPLE_SELECTED_TASK_ITEM_WITH_MOQS_IN_TASK_CREATE';
export const ADD_SELECTED_TASK_ITEMS_WITH_FORECASTS_IN_TASK_CREATE =
  'ADD_SELECTED_TASK_ITEMS_WITH_FORECASTS_IN_TASK_CREATE';
export const EDIT_SELECTED_TASK_ITEM_WITH_FORECASTS_IN_TASK_CREATE =
  'EDIT_SELECTED_TASK_ITEM_WITH_FORECASTS_IN_TASK_CREATE';
export const EDIT_MULTIPLE_SELECTED_TASK_ITEM_WITH_FORECASTS_IN_TASK_CREATE =
  'EDIT_MULTIPLE_SELECTED_TASK_ITEM_WITH_FORECASTS_IN_TASK_CREATE';
export const ADD_SELECTED_UNIT_OF_QUANTITY = 'ADD_SELECTED_UNIT_OF_QUANTITY';

export const createTaskInTaskCreate = (task: ITask): AppAction => ({
  type: CREATE_TASK_IN_TASK_CREATE,
  payload: task,
});

export const addSelectedSubtaskTypeInTaskCreate = (subtaskType: string[]) => ({
  type: ADD_SELECTED_SUBTASK_TYPE,
  payload: subtaskType,
});

export const createSubtasksInTaskCreate = (subtasks: ISubtask[]) => ({
  type: CREATE_SUBTASKS_IN_TASK_CREATE,
  payload: subtasks,
});

export const clearTaskCreateDialog = () => ({
  type: CLEAR_TASK_CREATE_DIALOG,
});

export const createTaskSubtaskSubtaskItemAndEtc = () => ({
  type: CREATE_TASK_SUBTASK_SUBTASK_ITEM_AND_ETC,
});

export const addSelectedTaskItemInTaskCreate = (taskItems: ITaskItem[]) => ({
  type: ADD_SELECTED_TASK_ITEM_IN_TASK_CREATE,
  payload: taskItems,
});

export const addSelectedTaskItemMoqInTaskCreate = (
  taskItemsWithMoqs: ITaskItemWithMoqs[],
) => ({
  type: ADD_SELECTED_TASK_ITEMS_WITH_MOQS_IN_TASK_CREATE,
  payload: taskItemsWithMoqs,
});

export const editSelectedTaskItemMoqInTaskCreate = (
  taskItemWithMoqs: ITaskItemWithMoqs,
) => ({
  type: EDIT_SELECTED_TASK_ITEM_WITH_MOQS_IN_TASK_CREATE,
  payload: taskItemWithMoqs,
});

export const editMultipleSelectedTaskItemMoqInTaskCreate = (
  multipleTaskItemWithMoqs: ITaskItemWithMoqs[],
) => ({
  type: EDIT_MULTIPLE_SELECTED_TASK_ITEM_WITH_MOQS_IN_TASK_CREATE,
  payload: multipleTaskItemWithMoqs,
});

export const addSelectedTaskItemForecastInTaskCreate = (
  taskItemsWithForecasts: ITaskItemWithForecasts[],
) => ({
  type: ADD_SELECTED_TASK_ITEMS_WITH_FORECASTS_IN_TASK_CREATE,
  payload: taskItemsWithForecasts,
});

export const editSelectedTaskItemForecastInTaskCreate = (
  taskItemWithForecasts: ITaskItemWithForecasts,
) => ({
  type: EDIT_SELECTED_TASK_ITEM_WITH_FORECASTS_IN_TASK_CREATE,
  payload: taskItemWithForecasts,
});

export const editMultipleSelectedTaskItemForecastInTaskCreate = (
  multipleTaskItemWithForecasts: ITaskItemWithForecasts[],
) => ({
  type: EDIT_MULTIPLE_SELECTED_TASK_ITEM_WITH_FORECASTS_IN_TASK_CREATE,
  payload: multipleTaskItemWithForecasts,
});

export const addSelectedUnitOfQuantityInTaskCreate = (unitOfQuantiy: string) => ({
  type: ADD_SELECTED_UNIT_OF_QUANTITY,
  payload: unitOfQuantiy,
});
