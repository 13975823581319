import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_NOTIFICATION_USER,
  FETCH_NOTIFICATION_USER,
} from './notificationUser.actions';
import {
  defaultValue,
  INotificationUser,
  NotificationUserState,
} from './notificationUser.types';

const initialState: NotificationUserState = {
  errorMessage: '',
  item: defaultValue,
  items: [] as ReadonlyArray<INotificationUser>,
  loading: false,
  totalItems: 0,
};

export default (
  state: NotificationUserState = initialState,
  action: AppAction,
): NotificationUserState => {
  switch (action.type) {
    case REQUEST(FETCH_NOTIFICATION_USER):
      return { ...state, loading: true };
    case SUCCESS(FETCH_NOTIFICATION_USER):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_NOTIFICATION_USER):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case CLEAR_NOTIFICATION_USER:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
