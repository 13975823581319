import { AppAction, PageFilter } from '../app';
import {
  EBlockPatternCreateOrUpdatePayload,
  EBlockPatternListFilter,
} from './eBlockPattern.type';
import { IEBlockPattern } from '../../models/eBlockPattern';

export const FETCH_EBLOCK_PATTERN = 'FETCH_EBLOCK_PATTERN';
export const FETCH_EBLOCK_PATTERN_LIST = 'FETCH_EBLOCK_PATTERN_LIST';
export const CREATE_EBLOCK_PATTERN = 'CREATE_EBLOCK_PATTERN';
export const UPDATE_EBLOCK_PATTERN = 'UPDATE_EBLOCK_PATTERN';
export const CLEAR_EBLOCK_PATTERN = 'CLEAR_EBLOCK_PATTERN';

export const fetchEBlockPattern = (id: IEBlockPattern['id']): AppAction => ({
  type: FETCH_EBLOCK_PATTERN,
  payload: id,
});

export const fetchEBlockPatternList = (
  filters: EBlockPatternListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_EBLOCK_PATTERN_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createEBlockPattern = (
  payload: EBlockPatternCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_EBLOCK_PATTERN,
  payload,
});

export const updateEBlockPattern = (
  payload: EBlockPatternCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_EBLOCK_PATTERN,
  payload,
});

export const clearEBlockPattern = () => ({
  type: CLEAR_EBLOCK_PATTERN,
});
