import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Theme,
  createStyles,
  Slide,
} from '@material-ui/core';
import { SearchField, TableLoading } from '../../components';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import { createOpportunityCustomers } from '../../../state/opportunityCustomer';
import { TablePageSize } from '../../../constants';
import { uniqBy } from 'lodash';
import {
  CustomerListFilter,
  Customer,
  CustomerState,
  clearCustomer,
  fetchCustomerList,
} from '../../../state/customer';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close as CloseIcon, Done as DoneIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      minHeight: '53vh',
    },
    filterContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

interface AddOpportunityCustomerDialogProps {
  open: boolean;
  handleClose: () => void;
  companyId: number;
  opportunityId: number;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

const initialPaging = { pageNumber: 0, pageSize: TablePageSize[0] };

const AddOpportunityCustomerDialog: React.FC<AddOpportunityCustomerDialogProps> = (
  props,
) => {
  const { open, handleClose, opportunityId, companyId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    items: customerList,
    totalItems,
    loading,
  } = useSelector<AppState, CustomerState>((state) => state.customer);

  const [filters, setFilters] = useState<CustomerListFilter>({});
  const [paging, setPaging] = useState<PageFilter>(initialPaging);
  const [selectedCustomers, setSelectedCustomers] = useState<Customer[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    setSelectAll(false);
  }, [filters, paging]);

  useEffect(() => {
    setFilters((filters) => {
      return {
        ...filters,
        'CompanyId.equalsTo': companyId,
      };
    });
  }, [setFilters, companyId]);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const resetForm = () => {
    handleClose();
    setSelectedCustomers([]);
    setPaging({ ...initialPaging });
    setFilters((filters) => {
      return {
        ...filters,
        'CompanyId.equalsTo': companyId,
      };
    });
  };

  const handleSubmit = () => {
    const submitValue = selectedCustomers.map((selectedCustomer, index) => {
      return {
        customerId: selectedCustomer.id,
        sequence: index + 1,
        opportunityId,
      };
    });

    dispatch(createOpportunityCustomers(submitValue, resetForm));
  };

  useEffect(() => {
    dispatch(fetchCustomerList(filters, paging));
    return function cleanUp() {
      dispatch(clearCustomer());
    };
  }, [dispatch, paging, filters]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
      TransitionComponent={Transition}
    >
      <DialogTitle>Select Customer</DialogTitle>
      <DialogContent>
        <div className={classes.filterContainer}>
          <SearchField
            label="CustomerId"
            onChange={handleChangeFilter}
            filterKey="ExternalId.StartsWith"
            filters={filters}
          />
          <SearchField
            label="Name"
            onChange={handleChangeFilter}
            filterKey="Name.startsWith"
            filters={filters}
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  onChange={(event: React.ChangeEvent<HTMLInputElement>, checked) => {
                    if (checked) {
                      const newList = [...selectedCustomers].concat(customerList);
                      setSelectedCustomers(uniqBy(newList, (c) => c.id!));
                    } else {
                      const isInCurrentList = (customer: Customer): boolean =>
                        !customerList.find((c) => c.id === customer.id);
                      setSelectedCustomers(selectedCustomers.filter(isInCurrentList));
                    }
                    setSelectAll(checked);
                  }}
                  checked={selectAll}
                />
              </TableCell>
              <TableCell>No.</TableCell>
              <TableCell>CustomerId</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={10}>
              {customerList.map((customer, index) => {
                const isCheckboxChecked =
                  selectedCustomers.findIndex(
                    (selectedCustomer) => selectedCustomer.id === customer.id,
                  ) !== -1;
                return (
                  <TableRow key={customer.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked,
                        ) => {
                          if (checked) {
                            setSelectedCustomers([...selectedCustomers, customer]);
                          } else {
                            setSelectedCustomers(
                              selectedCustomers.filter((c) => c.id !== customer.id),
                            );
                          }
                        }}
                        checked={isCheckboxChecked}
                      />
                    </TableCell>
                    <TableCell>
                      {index + paging.pageSize * paging.pageNumber + 1}
                    </TableCell>
                    <TableCell>{customer.externalId}</TableCell>
                    <TableCell>{customer.name}</TableCell>
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[...TablePageSize]}
          component="div"
          count={totalItems}
          rowsPerPage={paging.pageSize}
          page={paging.pageNumber}
          onPageChange={handleChangePageNumber}
          onRowsPerPageChange={handleChangePageSize}
        />
      </DialogContent>
      <DialogActions style={{ marginRight: '5px', marginBottom: '10px' }}>
        <Button
          onClick={() => {
            resetForm();
          }}
          color="default"
          variant="contained"
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          startIcon={<DoneIcon />}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOpportunityCustomerDialog;
