import { AppAction } from '../../../../state/app';
import { SearchItemResult } from './searchItemAndAddMultipleBlockDialog.reducer';
import { Block } from '../../../../state/block';

export const SEARCH_FOR_ITEM = 'SEARCH_ITEM_AND_ADD_MULTIPLE_BLOCK/SEARCH_ITEM';
export const CREATE_OPPORTUNITY_ITEM_FROM_SEARCH_RESULT = 'SEARCH_ITEM_AND_ADD_MULTIPLE_BLOCK/CREATE_OPPORTUNITY_ITEM_FROM_SEARCH_RESULT';
export const RESET_SEARCH_ITEM_AND_ADD_MULTIPLE_BLOCK_STATE = 'SEARCH_ITEM_AND_ADD_MULTIPLE_BLOCK/RESET';
export const SET_FORCE_CREATE_ITEMS = 'SEARCH_ITEM_AND_ADD_MULTIPLE_BLOCK/SET_FORCE_CREATE_ITEMS';

export interface CreateOpportunityItemResult {
  block: Block,
  isError: boolean;
  message?: string;
}

export interface CreateOpportunityItemPayload {
  sampleDevUserId?: number;
  npdUserId?: number;
  opportunityId: number;
  searchItemResults: ReadonlyArray<SearchItemResult>;
  createOpportunityItemResults?: ReadonlyArray<CreateOpportunityItemResult>
}

export const searchForItem = (searchText: string): AppAction => {
  return {
    type: SEARCH_FOR_ITEM,
    payload: searchText,
  };
};

export const createOpportunityItemFromSearchItemResults = (createOpportunityItemPayload: CreateOpportunityItemPayload): AppAction => {
  return {
    type: CREATE_OPPORTUNITY_ITEM_FROM_SEARCH_RESULT,
    payload: createOpportunityItemPayload,
  };
};

export const resetSearchItemAndAddMultipleBlockState = (): AppAction => {
  return {
    type: RESET_SEARCH_ITEM_AND_ADD_MULTIPLE_BLOCK_STATE,
  };
};

export const setForceCreateItems = (items: ReadonlyArray<string>): AppAction => {
  return {
    type: SET_FORCE_CREATE_ITEMS,
    payload: items,
  };
};
