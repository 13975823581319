import React, { useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Slide,
  TextField,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../state/configureStore';
import { BlockState, updateBlock } from '../../../state/block';
import { Loading, ProductTypeComboBox } from '../index';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { OpportunityState } from '../../../state/opportunity';
import { ProductType } from '../../../state/productType';
import {
  Close as CloseIcon,
  Save as SaveIcon,
  Search,
  Visibility,
} from '@material-ui/icons';
import HanesProjectSearchDialog from '../HanesProjectSearchDialog';
import { TransitionProps } from '@material-ui/core/transitions';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'flex-end',
    },
  }),
);

interface BlockEditDialogProps {
  open: boolean;
  onClose: () => void;
  disabledEditItem?: boolean;
}

interface BlockEditFormValue {
  item?: string;
  itemDescription?: string;
  companyMakefor?: string;
  productType?: ProductType;
  productTypeId?: number;
  hanesProjectName?: string;
}

const BlockEditFormValidationSchema = Yup.object().shape({
  item: Yup.string().nullable(true).required('Item is required'),
  itemDescription: Yup.string().nullable(true),
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

const BlockEditDialog: React.FC<BlockEditDialogProps> = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { open, onClose, disabledEditItem = false } = props;
  const { item: block, loading } = useSelector<AppState, BlockState>((s) => s.block);
  const { item: opportunity } = useSelector<AppState, OpportunityState>(
    (state) => state.opportunity,
  );

  const [openHanesProjectDlg, setOpenHanesProjectDlg] = useState(false);

  const handleSubmit = (values: BlockEditFormValue) => {
    const newBlock = { ...block, ...values };
    dispatch(updateBlock(newBlock));
  };

  const blockEditFormValue = useMemo<BlockEditFormValue>(() => {
    return {
      item: block.item,
      itemDescription: block.itemDescription,
      productType: block.productType,
      productTypeId: block.productTypeId,
      companyMakefor: opportunity.company?.externalId,
      hanesProjectName: block.hanesProjectName!,
    };
  }, [block]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={'md'}
      TransitionComponent={Transition}
      fullWidth
    >
      <DialogTitle>Edit Block</DialogTitle>
      <Loading isLoading={loading}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{ ...blockEditFormValue }}
          validateOnChange={false}
          validationSchema={BlockEditFormValidationSchema}
        >
          {(formikProps) => {
            const { values, errors, handleChange, touched, setFieldValue } = formikProps;
            const isError = (fieldName: keyof BlockEditFormValue) =>
              !!touched[fieldName] && !!errors[fieldName];
            const errorMessage = (fieldName: keyof BlockEditFormValue) =>
              !!touched[fieldName] ? errors[fieldName] : '';
            return (
              <Form>
                <DialogContent>
                  <ProductTypeComboBox
                    handleChange={(result) => {
                      setFieldValue('productType', result ? result : 0);
                      setFieldValue('productTypeId', result ? result.id : 0);
                    }}
                    selectedValue={values.productType as ProductType}
                    error={!!errors.productTypeId && touched.productTypeId}
                    helperText={errors.productTypeId}
                    isDisabled={values.productType?.name !== 'DUMMY'}
                  />
                  <TextField
                    margin={'dense'}
                    onChange={handleChange}
                    label={'Item'}
                    name={'item'}
                    value={values.item || ''}
                    fullWidth
                    error={isError('item')}
                    helperText={errorMessage('item')}
                    disabled={disabledEditItem}
                  />
                  <TextField
                    margin={'dense'}
                    onChange={handleChange}
                    label={'Spec Detail/Description'}
                    name={'itemDescription'}
                    value={values.itemDescription || ''}
                    fullWidth
                    error={isError('itemDescription')}
                    helperText={errorMessage('itemDescription')}
                  />
                  <div className={classes.container}>
                    <TextField
                      margin={'dense'}
                      onChange={handleChange}
                      label={'Hanes Project'}
                      name={'hanesProjectName'}
                      value={values.hanesProjectName || ''}
                      fullWidth
                      error={isError('hanesProjectName')}
                      helperText={errorMessage('hanesProjectName')}
                    />
                    <Button
                      color="primary"
                      variant="outlined"
                      startIcon={<Search />}
                      style={{ marginLeft: 10, marginBottom: 8 }}
                      onClick={() => {
                        setOpenHanesProjectDlg(true);
                      }}
                    >
                      Search
                    </Button>
                  </div>

                  <HanesProjectSearchDialog
                    open={openHanesProjectDlg}
                    item={values.item}
                    onClose={(selected) => {
                      if (selected) {
                        setFieldValue('hanesProjectName', selected.name);
                      }
                      setOpenHanesProjectDlg(false);
                    }}
                  />
                </DialogContent>
                <DialogActions style={{ marginRight: '5px', marginBottom: '10px' }}>
                  <Button
                    variant={'contained'}
                    onClick={onClose}
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    type={'submit'}
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Loading>
    </Dialog>
  );
};

export default BlockEditDialog;
