export interface IResponsibilityImportExcelDefinition {
  columns: IResponsibilityImportExcelColumnConfig[];
}

export interface IResponsibilityImportData {
  responsibilityId: number | undefined;
  companyName: string;
  programName: string;
  brandNames: string;
  customerIds: string;

  salesGroup1: string;
  salesGroup2: string;
  salesGroup3: string;

  salesTeam1: string;
  salesTeam2: string;
  salesTeam3: string;

  salesName1: string;
  salesName2: string;
  salesName3: string;

  salesNameStatus1: string;
  salesNameStatus2: string;
  salesNameStatus3: string;

  ssoGroup1: string;
  ssoGroup2: string;
  ssoGroup3: string;
  ssoGroup4: string;

  ssoTeam1: string;
  ssoTeam2: string;
  ssoTeam3: string;
  ssoTeam4: string;

  ssoName1: string;
  ssoName2: string;
  ssoName3: string;
  ssoName4: string;

  ssoNameStatus1: string;
  ssoNameStatus2: string;
  ssoNameStatus3: string;
  ssoNameStatus4: string;

  csGroup1: string;
  csGroup2: string;
  csGroup3: string;
  csGroup4: string;
  csGroup5: string;

  csTeam1: string;
  csTeam2: string;
  csTeam3: string;
  csTeam4: string;
  csTeam5: string;

  csName1: string;
  csName2: string;
  csName3: string;
  csName4: string;
  csName5: string;

  csNameStatus1: string;
  csNameStatus2: string;
  csNameStatus3: string;
  csNameStatus4: string;
  csNameStatus5: string;

  npdHLName: string;
  npdPLName: string;
  npdNLName: string;
  npdTLName: string;
  npdWLName: string;
}

export interface IResponsibilityImportExcelColumnConfig {
  propertyName: string;
  caption: string;
  excelIndex: number | null;
}

export type IExcelRow = string[];

export const config: IResponsibilityImportExcelDefinition = {
  columns: [
    {
      propertyName: 'responsibilityId',
      caption: 'Responsibility ID',
      excelIndex: 1,
    },
    {
      propertyName: 'companyName',
      caption: 'Company',
      excelIndex: 8,
    },
    {
      propertyName: 'programName',
      caption: 'Program',
      excelIndex: 9,
    },
    {
      propertyName: 'brandNames',
      caption: 'Brand Names',
      excelIndex: 10,
    },
    {
      propertyName: 'customerIds',
      caption: 'Customer IDs',
      excelIndex: 11,
    },
    {
      propertyName: 'salesGroup1',
      caption: 'Sales Group 1',
      excelIndex: 12,
    },
    {
      propertyName: 'salesTeam1',
      caption: 'Sales Team 1',
      excelIndex: 14,
    },
    {
      propertyName: 'salesName1',
      caption: 'Sales Name 1',
      excelIndex: 15,
    },
    {
      propertyName: 'salesNameStatus1',
      caption: 'Sales Name Status 1',
      excelIndex: 16,
    },
    {
      propertyName: 'salesGroup2',
      caption: 'Sales Group 2',
      excelIndex: 12,
    },
    {
      propertyName: 'salesTeam2',
      caption: 'Sales Team 2',
      excelIndex: 14,
    },
    {
      propertyName: 'salesName2',
      caption: 'Sales Name 2',
      excelIndex: 17,
    },
    {
      propertyName: 'salesNameStatus2',
      caption: 'Sales Name Status 2',
      excelIndex: 18,
    },
    {
      propertyName: 'salesGroup3',
      caption: 'Sales Group 3',
      excelIndex: 12,
    },
    {
      propertyName: 'salesTeam3',
      caption: 'Sales Team 3',
      excelIndex: 14,
    },
    {
      propertyName: 'salesName3',
      caption: 'Sales Name 3',
      excelIndex: 19,
    },
    {
      propertyName: 'salesNameStatus3',
      caption: 'Sales Name Status 3',
      excelIndex: 20,
    },
    /******* SSO1 *******/
    {
      propertyName: 'ssoGroup1',
      caption: 'SSO Group 1',
      excelIndex: 21,
    },
    {
      propertyName: 'ssoTeam1',
      caption: 'SSO Team 1',
      excelIndex: 22,
    },
    {
      propertyName: 'ssoName1',
      caption: 'SSO Name 1',
      excelIndex: 24,
    },
    {
      propertyName: 'ssoNameStatus1',
      caption: 'SSO Name Status 1',
      excelIndex: 25,
    },
    /******* SSO2 *******/
    {
      propertyName: 'ssoGroup2',
      caption: 'SSO Group 2',
      excelIndex: 21,
    },
    {
      propertyName: 'ssoTeam2',
      caption: 'SSO Team 2',
      excelIndex: 22,
    },
    {
      propertyName: 'ssoName2',
      caption: 'SSO Name 2',
      excelIndex: 26,
    },
    {
      propertyName: 'ssoNameStatus2',
      caption: 'SSO Name Status 2',
      excelIndex: 27,
    },
    /******* SSO3 *******/
    {
      propertyName: 'ssoGroup3',
      caption: 'SSO Group 3',
      excelIndex: 21,
    },
    {
      propertyName: 'ssoTeam3',
      caption: 'SSO Team 3',
      excelIndex: 22,
    },
    {
      propertyName: 'ssoName3',
      caption: 'SSO Name 3',
      excelIndex: 28,
    },
    {
      propertyName: 'ssoNameStatus3',
      caption: 'SSO Name Status 3',
      excelIndex: 29,
    },
    /******* SSO4 *******/
    {
      propertyName: 'ssoGroup4',
      caption: 'SSO Group 4',
      excelIndex: 21,
    },
    {
      propertyName: 'ssoTeam4',
      caption: 'SSO Team 4',
      excelIndex: 22,
    },
    {
      propertyName: 'ssoName4',
      caption: 'SSO Name 4',
      excelIndex: 30,
    },
    {
      propertyName: 'ssoNameStatus4',
      caption: 'SSO Name Status 4',
      excelIndex: 31,
    },
    /******* CS1 *******/
    {
      propertyName: 'csGroup1',
      caption: 'CS Group 1',
      excelIndex: 32,
    },
    {
      propertyName: 'csTeam1',
      caption: 'CS Team 1',
      excelIndex: 33,
    },
    {
      propertyName: 'csName1',
      caption: 'CS Name 1',
      excelIndex: 35,
    },
    {
      propertyName: 'csNameStatus1',
      caption: 'CS Name Status 1',
      excelIndex: 36,
    },
    /******* CS2 *******/
    {
      propertyName: 'csGroup2',
      caption: 'CS Group 2',
      excelIndex: 32,
    },
    {
      propertyName: 'csTeam2',
      caption: 'CS Team 2',
      excelIndex: 33,
    },
    {
      propertyName: 'csName2',
      caption: 'CS Name 2',
      excelIndex: 37,
    },
    {
      propertyName: 'csNameStatus2',
      caption: 'CS Name Status 2',
      excelIndex: 38,
    },
    /******* CS3 *******/
    {
      propertyName: 'csGroup3',
      caption: 'CS Group 3',
      excelIndex: 32,
    },
    {
      propertyName: 'csTeam3',
      caption: 'CS Team 3',
      excelIndex: 33,
    },
    {
      propertyName: 'csName3',
      caption: 'CS Name 3',
      excelIndex: 39,
    },
    {
      propertyName: 'csNameStatus3',
      caption: 'CS Name Status 3',
      excelIndex: 40,
    },
    /******* CS4 *******/
    {
      propertyName: 'csGroup4',
      caption: 'CS Group 4',
      excelIndex: 32,
    },
    {
      propertyName: 'csTeam4',
      caption: 'CS Team 4',
      excelIndex: 33,
    },
    {
      propertyName: 'csName4',
      caption: 'CS Name 4',
      excelIndex: 41,
    },
    {
      propertyName: 'csNameStatus4',
      caption: 'CS Name Status 4',
      excelIndex: 42,
    },
    /******* CS4 *******/
    {
      propertyName: 'csGroup5',
      caption: 'CS Group 5',
      excelIndex: 32,
    },
    {
      propertyName: 'csTeam5',
      caption: 'CS Team 5',
      excelIndex: 33,
    },
    {
      propertyName: 'csName5',
      caption: 'CS Name 5',
      excelIndex: 43,
    },
    {
      propertyName: 'csNameStatus5',
      caption: 'CS Name Status 5',
      excelIndex: 44,
    },
    /******* NPD HL *******/
    {
      propertyName: 'npdHLTeam',
      caption: 'NPD HL Team',
      excelIndex: 45,
    },
    {
      propertyName: 'npdHLName',
      caption: 'NPD HL Name',
      excelIndex: 46,
    },
    /******* NPD PL *******/
    {
      propertyName: 'npdPLTeam',
      caption: 'NPD PL Team',
      excelIndex: 47,
    },
    {
      propertyName: 'npdPLName',
      caption: 'NPD PL Name',
      excelIndex: 48,
    },
    /******* NPD NL *******/
    {
      propertyName: 'npdNLTeam',
      caption: 'NPD NL Team',
      excelIndex: 49,
    },
    {
      propertyName: 'npdNLName',
      caption: 'NPD NL Name',
      excelIndex: 50,
    },
    /******* NPD TL *******/
    {
      propertyName: 'npdTLTeam',
      caption: 'NPD TL Team',
      excelIndex: 51,
    },
    {
      propertyName: 'npdTLName',
      caption: 'NPD TL Name',
      excelIndex: 52,
    },
    /******* NPD WL *******/
    {
      propertyName: 'npdWLTeam',
      caption: 'NPD WL Team',
      excelIndex: 53,
    },
    {
      propertyName: 'npdWLName',
      caption: 'NPD WL Name',
      excelIndex: 54,
    },
  ],
};
