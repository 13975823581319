import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createProgramRequestItem,
  fetchProgramRequestItem,
  fetchProgramRequestItemList,
  updateProgramRequestItem,
  deleteProgramRequestItem
} from '../../services/programRequestItemService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_PROGRAMREQUESTITEM,
  DELETE_PROGRAMREQUESTITEM,
  FETCH_PROGRAMREQUESTITEM,
  FETCH_PROGRAMREQUESTITEM_LIST,
  UPDATE_PROGRAMREQUESTITEM,
} from './programRequestItem.actions';

function* fetchProgramRequestItemSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchProgramRequestItem, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchProgramRequestItemListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchProgramRequestItemList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createProgramRequestItemSaga(action: AppAction) {
  try {
    const { payload : newProgramRequestItem, callback } = action;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createProgramRequestItem, newProgramRequestItem);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Created ProgramRequestItem successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateProgramRequestItemSaga(action: AppAction) {
  try {
    const { payload : newProgramRequestItem, callback } = action;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateProgramRequestItem, newProgramRequestItem);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Updated ProgramRequestItem successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteProgramRequestItemSaga(action: AppAction) {
  const { payload: programRequestItemId, callback } = action;
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(deleteProgramRequestItem, programRequestItemId);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    const successMessage = `Deleted ProgramRequestItem successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchProgramRequestItem() {
  yield takeLatest(FETCH_PROGRAMREQUESTITEM, fetchProgramRequestItemSaga);
  yield debounce(250, FETCH_PROGRAMREQUESTITEM_LIST, fetchProgramRequestItemListSaga);
  yield takeLatest(CREATE_PROGRAMREQUESTITEM, createProgramRequestItemSaga);
  yield takeLatest(UPDATE_PROGRAMREQUESTITEM, updateProgramRequestItemSaga);
  yield takeLatest(DELETE_PROGRAMREQUESTITEM, deleteProgramRequestItemSaga);
}
