import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createTaskActivity,
  fetchTaskActivity,
  fetchTaskActivityList,
  updateTaskActivity,
} from '../../services/taskActivityService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_TASK_ACTIVITY,
  CREATE_TASK_ACTIVITY_AND_FETCH_LIST,
  FETCH_TASK_ACTIVITY,
  FETCH_TASK_ACTIVITY_LIST,
  fetchTaskActivityListBySubtaskId,
  fetchTaskActivityListByTaskId,
  UPDATE_TASK_ACTIVITY,
} from './taskActivity.actions';
import { TaskActivityCreateOrUpdatePayload } from './taskActivity.types';
import { ITaskActivity } from '../../models/taskActivity.model';

function* fetchTaskActivitySaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchTaskActivity, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchTaskActivityListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchTaskActivityList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createTaskActivitySaga(action: AppAction) {
  try {
    const value = action.payload as ITaskActivity;
    const successMessage = `Added comment successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createTaskActivity, value);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateTaskActivitySaga(action: AppAction) {
  try {
    const { values } = action.payload as TaskActivityCreateOrUpdatePayload;
    const successMessage = `Updated taskActivity successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateTaskActivity, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createTaskActivityAndFetchListSaga(action: AppAction) {
  try {
    const value = action.payload as ITaskActivity;
    yield put({ type: REQUEST(CREATE_TASK_ACTIVITY) });
    const { data } = yield call(createTaskActivity, value);
    const successMessage = `Added comment successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    yield put({ type: SUCCESS(CREATE_TASK_ACTIVITY), payload: { data } });
    if (value.smSubtaskId) {
      yield put(fetchTaskActivityListBySubtaskId(value.smSubtaskId));
    } else {
      yield put(fetchTaskActivityListByTaskId(value.smTaskId!));
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchTaskActivity() {
  yield takeLatest(FETCH_TASK_ACTIVITY, fetchTaskActivitySaga);
  yield debounce(250, FETCH_TASK_ACTIVITY_LIST, fetchTaskActivityListSaga);
  yield takeLatest(CREATE_TASK_ACTIVITY, createTaskActivitySaga);
  yield takeLatest(
    CREATE_TASK_ACTIVITY_AND_FETCH_LIST,
    createTaskActivityAndFetchListSaga,
  );
  yield takeLatest(UPDATE_TASK_ACTIVITY, updateTaskActivitySaga);
}
