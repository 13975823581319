import { AppAction, PageFilter } from '../app';
import {
  ProductType,
  ProductTypeCreateOrUpdatePayload,
  ProductTypeListFilter,
} from './productType.types';

export const FETCH_PRODUCTTYPE = 'FETCH_PRODUCTTYPE';
export const FETCH_PRODUCTTYPE_LIST = 'FETCH_PRODUCTTYPE_LIST';
export const CREATE_PRODUCTTYPE = 'CREATE_PRODUCTTYPE';
export const UPDATE_PRODUCTTYPE = 'UPDATE_PRODUCTTYPE';
export const CLEAR_PRODUCTTYPE = 'CLEAR_PRODUCTTYPE';

export const fetchProductType = (id: ProductType['id']): AppAction => ({
  type: FETCH_PRODUCTTYPE,
  payload: id,
});

export const fetchProductTypeList = (
  filters: ProductTypeListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_PRODUCTTYPE_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createProductType = (
  payload: ProductTypeCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_PRODUCTTYPE,
  payload,
});

export const updateProductType = (
  payload: ProductTypeCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_PRODUCTTYPE,
  payload,
});

export const clearProductType = () => ({
  type: CLEAR_PRODUCTTYPE,
});
