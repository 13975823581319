import {
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { AppState } from '../../../state/configureStore';
import { Customer } from '../../../state/customer';
import {
  createCustomerGroupMember,
  CustomerGroupMember,
  CustomerGroupMemberState,
  deleteCustomerGroupMember,
  updateCustomerGroupMember,
} from '../../../state/customerGroupMember';
import { CustomerComboBox, Loading, PageTitle } from '../../components';
import useStyles from './customerGroupMemberForm.style';
import { pushNotificationMessage } from '../../components/Notification';

interface CustomerGroupMemberProps {
  customerGroupId: number;
  companyId: number;
}

const CustomerGroupMemberSchema = Yup.object().shape({
  id: Yup.number(),
  customerId: Yup.number().required('Customer is required'),
  customerGroupId: Yup.number().required('Customer Group is required'),
});

const CustomerGroupMemberForm: React.FC<CustomerGroupMemberProps> = (props) => {
  const dispatch = useDispatch();
  const { customerGroupId, companyId } = props;
  const classes = useStyles();
  const [localCustomerGroupMembers, setLocalCustomerGroupMembers] = useState<
    CustomerGroupMember[]
  >([]);
  const { items: customerGroupMembers, loading: isLoading } = useSelector<
    AppState,
    CustomerGroupMemberState
  >((state) => state.customerGroupMember);

  const isAllRecordSaved = localCustomerGroupMembers.findIndex((opc) => !opc.id) === -1;
  const addNewCustomerGroupMember = () => {
    if (isAllRecordSaved) {
      const newItem = { customerGroupId };
      setLocalCustomerGroupMembers([...localCustomerGroupMembers, newItem]);
    }
  };

  const remove = (id?: number) => {
    if (id) {
      dispatch(deleteCustomerGroupMember({ id }));
    } else {
      setLocalCustomerGroupMembers(localCustomerGroupMembers.slice(0, -1));
    }
  };

  const handleSubmit = (values: CustomerGroupMember) => {
    CustomerGroupMemberSchema.validate(values, { abortEarly: false })
      .then((validatedValues) => {
        if (validatedValues.id) {
          dispatch(updateCustomerGroupMember({ values: { ...validatedValues } }));
        } else {
          dispatch(createCustomerGroupMember({ values: { ...validatedValues } }));
        }
      })
      .catch((err: Yup.ValidationError) => {
        if (err.errors) {
          err.errors.forEach((error) => {
            dispatch(pushNotificationMessage({ message: error, type: 'error' }));
          });
        }
      });
  };

  useEffect(() => {
    setLocalCustomerGroupMembers([...customerGroupMembers]);
  }, [customerGroupMembers]);

  return (
    <Card className={classes.root}>
      <PageTitle>Customer</PageTitle>
      <Loading isLoading={isLoading}>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Loading isLoading={isLoading}>
                {localCustomerGroupMembers.map((customerGroupMember, index) => {
                  const { customer } = customerGroupMember;
                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell className={classes.comboBox}>
                        <CustomerComboBox
                          handleChange={(value: Customer) => {
                            const newList = [...localCustomerGroupMembers];
                            newList[index] = {
                              ...newList[index],
                              customer: value,
                              customerId: value ? (value.id as number) : undefined,
                            };

                            setLocalCustomerGroupMembers(newList);
                          }}
                          selectedValue={customer as Customer}
                          companyId={companyId}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            handleSubmit(customerGroupMember);
                          }}
                          className={classes.button}
                        >
                          <SaveIcon className={classes.leftIcon} />
                          Save
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => remove(customerGroupMember.id)}
                        >
                          <DeleteIcon className={classes.leftIcon} />
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </Loading>
            </TableBody>
          </Table>
          <Button
            variant="contained"
            color="primary"
            onClick={() => addNewCustomerGroupMember()}
            className={classes.button}
            disabled={!isAllRecordSaved}
          >
            <AddIcon className={classes.leftIcon} />
            Add Customer
          </Button>
        </CardContent>
      </Loading>
    </Card>
  );
};

export default CustomerGroupMemberForm;
