import { CardContent, Grid, Paper, Typography, Box } from '@material-ui/core';
import React from 'react';
import { CheckBoxFastField, TextFieldFastField } from '../../../components';

interface SampleDevelopmentHlDesignProps {}

const SampleDevelopmentHlDesign: React.FC<SampleDevelopmentHlDesignProps> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={3}>
              <Typography>Design</Typography>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              item
              xs={12}
            >
              <Grid item xs={12} md={2}>
                <Typography>Design:</Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="ownDesign" label="Own Design" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="customerDesign" label="Customer Design" />
              </Grid>
              <Grid item xs={12} md={5} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designSample" label="Sample" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designOriginal" label="Original" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designArtwork" label="Artwork" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designZip" label="Zip" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designCD" label="CD" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designFilm" label="Film" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designMail" label="Mail" />
              </Grid>
              <Grid item xs={12} md={8} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designFile" label="File" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designTIF" label="TIF" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designEPS" label="EPS" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designJPG" label="JPG" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designPDF" label="PDF" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designAI" label="AI" />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designQXP" label="QXP" />
              </Grid>
              <Grid item xs={12} md={8} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designSoftware" label="Software" />
              </Grid>
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designAcrobat" label="Acrobat" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldFastField
                  name="designAcrobatData"
                  label="Acrobat"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designIllustrater" label="Illustrater" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldFastField
                  name="designIllustraterData"
                  label="Illustrater"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designPageMaker" label="PageMaker" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldFastField
                  name="designPageMakerData"
                  label="PageMaker"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designPhotoshop" label="Photoshop" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldFastField
                  name="designPhotoshopData"
                  label="Photoshop"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designFreeHand" label="Free Hand" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldFastField
                  name="designFreeHandData"
                  label="Free Hand"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designQuark" label="Quark" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldFastField
                  name="designQuarkData"
                  label="Quark"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={2}>
                <CheckBoxFastField name="designOther" label="Other" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldFastField
                  name="designOtherData"
                  label="Other"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentHlDesign;
