import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { IResponsibilitySalesTeamMember } from '../../../../models/responsibilitySalesTeamMember.model';
import { SalesTeamMember } from '../../../../state/salesTeamMember';
import {
  replaceResponsibilitySalesTeamMember,
  ResponsibilitySalesTeamMemberReplacePayLoad,
} from '../../../../state/responsibilitySalesTeamMember';
import {
  SalesTeamMemberComboBox,
  SalesTeamMemberComboBoxSearch,
} from '../../../components';

interface ResponsibilitySalesTeamMemberReplaceDialogProps {
  responsibilitySalesTeamMember: IResponsibilitySalesTeamMember | null;
  open: boolean;
  onClose: () => void;
  salesTeamId?: number | null;
}

interface ResponsibilitySalesTeamMemberReplaceValues {
  salesTeamMember?: SalesTeamMember;
  salesTeamMemberId?: number | null;
}

const ResponsibilitySchema = Yup.object().shape({
  salesTeamMemberId: Yup.number().nullable(true).required('SalesTeamMember is required'),
});

type dialogStep =
  | 'replaceResponsibilitySalesTeamMember'
  | 'confirmResponsibilitySalesTeamMember';

const ResponsibilitySalesTeamMemberReplaceDialog: React.FC<
  ResponsibilitySalesTeamMemberReplaceDialogProps
> = (props) => {
  const dispatch = useDispatch();
  const { open, onClose, responsibilitySalesTeamMember, salesTeamId } = props;

  const [
    replaceResponsibilitySalesTeamMemberDlgStep,
    setReplaceResponsibilitySalesTeamMemberDlgStep,
  ] = useState<dialogStep>('replaceResponsibilitySalesTeamMember');
  const [
    responsibilityReplaceSalesTeamMemberPayLoad,
    setResponsibilityReplaceSalesTeamMemberPayLoad,
  ] = useState<ResponsibilitySalesTeamMemberReplacePayLoad>();

  const initialFormValues: ResponsibilitySalesTeamMemberReplaceValues = useMemo(() => {
    const { salesTeamMember, salesTeamMemberId } = responsibilitySalesTeamMember!;
    return { salesTeamMember, salesTeamMemberId };
  }, [responsibilitySalesTeamMember]);

  const handleSubmit = (
    values: ResponsibilitySalesTeamMemberReplaceValues,
    actions: FormikHelpers<ResponsibilitySalesTeamMemberReplaceValues>,
  ) => {
    setResponsibilityReplaceSalesTeamMemberPayLoad({
      responsibilitySalesTeamMemberId: responsibilitySalesTeamMember!.id,
      newSalesTeamMember: values.salesTeamMember!,
      newSalesTeamMemberId: values.salesTeamMemberId!,
    });

    setReplaceResponsibilitySalesTeamMemberDlgStep(
      'confirmResponsibilitySalesTeamMember',
    );
    actions.setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'} fullWidth>
      <Formik
        enableReinitialize={true}
        initialValues={initialFormValues}
        validationSchema={ResponsibilitySchema}
        onSubmit={handleSubmit}
        validateOnChange={true}
      >
        {({ values, submitForm, errors, touched, setFieldValue, setFieldTouched }) => {
          const isError = (fieldName: keyof ResponsibilitySalesTeamMemberReplaceValues) =>
            !!touched[fieldName] && !!errors[fieldName];
          const errorMessage = (
            fieldName: keyof ResponsibilitySalesTeamMemberReplaceValues,
          ) => (!!touched[fieldName] ? (errors[fieldName] as string) : '');

          return (
            <Form>
              {replaceResponsibilitySalesTeamMemberDlgStep ===
                'replaceResponsibilitySalesTeamMember' && (
                <>
                  <DialogTitle>Replace Responsibility SalesTeam Member</DialogTitle>
                  <CardContent>
                    <SalesTeamMemberComboBoxSearch
                      handleChange={(result: SalesTeamMember) => {
                        setFieldValue('salesTeamMember', result ? result : null);
                        setFieldTouched('salesTeamMember', true, false);
                        setFieldValue('salesTeamMemberId', result ? result.id : null);
                        setFieldTouched('salesTeamMemberId', true, false);
                      }}
                      selectedValue={values.salesTeamMember as SalesTeamMember}
                      error={isError('salesTeamMemberId')}
                      helperText={errorMessage('salesTeamMemberId')}
                      handleClose={undefined!}
                    />
                  </CardContent>
                  <DialogActions>
                    <Button
                      variant={'contained'}
                      color="secondary"
                      onClick={() => {
                        onClose();
                      }}
                    >
                      CANCEL
                    </Button>
                    <Button
                      variant={'contained'}
                      color="primary"
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      NEXT
                    </Button>
                  </DialogActions>
                </>
              )}
              {replaceResponsibilitySalesTeamMemberDlgStep ===
                'confirmResponsibilitySalesTeamMember' && (
                <>
                  <DialogTitle>
                    Confirm Replace Responsibility SalesTeam Member{' '}
                  </DialogTitle>
                  <CardContent>
                    <TextField
                      name="Name"
                      type="input"
                      label="Name"
                      variant="outlined"
                      margin="dense"
                      value={`${
                        responsibilitySalesTeamMember!.salesTeamMember!.salesTeam!.name
                      } ${
                        responsibilitySalesTeamMember!.salesTeamMember!.user!.fullName
                      }`}
                      fullWidth
                    />
                    <TextField
                      name="changeToName"
                      type="input"
                      label="Change To Name"
                      variant="outlined"
                      margin="dense"
                      value={`${
                        responsibilityReplaceSalesTeamMemberPayLoad!.newSalesTeamMember
                          ?.salesTeam?.name
                      } ${
                        responsibilityReplaceSalesTeamMemberPayLoad!.newSalesTeamMember
                          ?.user?.fullNameWithUserName
                      }`}
                      fullWidth
                    />
                  </CardContent>
                  <DialogActions>
                    <Button
                      variant={'contained'}
                      color="secondary"
                      onClick={() => {
                        setReplaceResponsibilitySalesTeamMemberDlgStep(
                          'replaceResponsibilitySalesTeamMember',
                        );
                      }}
                    >
                      BACK
                    </Button>
                    <Button
                      variant={'contained'}
                      color="primary"
                      onClick={() => {
                        dispatch(
                          replaceResponsibilitySalesTeamMember(
                            responsibilityReplaceSalesTeamMemberPayLoad!,
                          ),
                        );
                        setReplaceResponsibilitySalesTeamMemberDlgStep(
                          'replaceResponsibilitySalesTeamMember',
                        );
                        onClose();
                      }}
                    >
                      OK
                    </Button>
                  </DialogActions>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ResponsibilitySalesTeamMemberReplaceDialog;
