import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    marginTop: '20px',
    minWidth: 650,
  },
  tableCellHeader: {
    backgroundColor: 'rgb(63, 81, 181)',
    color: theme.palette.common.white,
  },
  title: {
    margin: theme.spacing(2),
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  filterInput: {
    margin: theme.spacing(1),
  },
  lastFilterInput: {
    marginRight: 'auto',
  },
  button: {
    margin: theme.spacing(1)
  },
  iconButton: {
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
