import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { AppState } from '../../../../../state/configureStore';
import { SubtaskState } from '../../../../../state/subtask';
import { updateSubtaskItemsStatus } from '../../../../../state/subtaskItem';
import { SubtaskItemStatus } from '../../../../../constants';
import { Loading } from '../../../../components';
import { ISubtaskItem } from '../../../../../models/subtaskItem.model';

interface ISubtaskItemDoneDialogProps {
  open: boolean;
  onClose: () => void;
  subtaskItems: ReadonlyArray<ISubtaskItem>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    completedDateInput: {
      // marginLeft: theme.spacing(2),
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
  }),
);

const SubtaskItemDoneSchema = Yup.object().shape({
  completedDate: Yup.string().required('Completed date is required').nullable(true),
});

const SubtaskItemDoneDialog: React.FC<ISubtaskItemDoneDialogProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, onClose, subtaskItems } = props;
  const { item: subtask, loading } = useSelector<AppState, SubtaskState>(
    (state) => state.subtask,
  );

  return (
    <Formik
      initialValues={{ ...subtask }}
      validationSchema={SubtaskItemDoneSchema}
      onSubmit={(values, actions) => {
        const callback = (error?: Error) => {
          if (!error) {
            onClose();
            actions.resetForm();
          }
        };

        values.status = SubtaskItemStatus.DONE;
        const payload = subtaskItems.map((subtaskItem) => ({
          ...values,
          id: subtaskItem.id,
          smSubtaskId: subtask.id,
          smTaskItemId: subtaskItem.smTaskItem?.id,
          smTaskItem: subtaskItem.smTaskItem,
        }));

        dispatch(updateSubtaskItemsStatus(payload, callback));
      }}
      enableReinitialize={true}
    >
      {({ isSubmitting, values, setFieldValue, errors }) => {
        return (
          <Dialog open={open} fullWidth onClose={onClose}>
            <Loading isLoading={loading}>
              <Form>
                <DialogTitle>Complete Date Subtask Item</DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.textField}
                        name="subject"
                        label="Subject"
                        margin="dense"
                        fullWidth
                        value={values.subject || ''}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <KeyboardDatePicker
                        label="Due Date"
                        format="yyyy-MM-dd"
                        value={values.dueDate}
                        onChange={() => {}}
                        showTodayButton={true}
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <KeyboardDatePicker
                        className={classes.completedDateInput}
                        label="Completed Date"
                        format="yyyy-MM-dd"
                        value={values.completedDate}
                        helperText={errors.completedDate}
                        error={!!errors.completedDate}
                        onChange={(selectedDate, selectedStringDate) => {
                          setFieldValue('completedDate', selectedStringDate);
                        }}
                        showTodayButton={true}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    size="small"
                    variant="contained"
                    color="default"
                    onClick={onClose}
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Form>
            </Loading>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default SubtaskItemDoneDialog;
