import { call, debounce, put } from 'redux-saga/effects';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import { pushNotificationMessage } from '../../views/components/Notification';
import { fetchHanesProjectList } from '../../services/hanesProjectService';

import { FETCH_HANES_PROJECT_LIST } from './hanesProject.action';

function* fetchHanesProjectListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchHanesProjectList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchHanesProject() {
  yield debounce(250, FETCH_HANES_PROJECT_LIST, fetchHanesProjectListSaga);
}
