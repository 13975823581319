import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { IBlockCreate } from '../../../state/block';

interface ConfirmTableProps {
  blockCreates: ReadonlyArray<IBlockCreate>;
}

const EBlockConfirmTable = (props: ConfirmTableProps) => {
  const { blockCreates } = props;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Group Product</TableCell>
          <TableCell>Material</TableCell>
          <TableCell>Size</TableCell>
          <TableCell>Pattern</TableCell>
          <TableCell>Item</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Inter Company Product Type</TableCell>
          <TableCell>EBlock</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Message</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {blockCreates.map((blockCreate, index) => {
          const { block, status, errorMessage } = blockCreate;
          return (
            <TableRow key={index}>
              <TableCell>
                {block!.eBlockGroupProduct!.code}:{' '}
                {block!.eBlockGroupProduct!.description}
              </TableCell>
              <TableCell>
                {block!.eBlockMaterial!.code}: {block!.eBlockMaterial!.description}
              </TableCell>
              <TableCell>
                {block!.eBlockSize!.code}: {block!.eBlockSize!.description}
              </TableCell>
              <TableCell>
                {block!.eBlockPattern!.code}: {block!.eBlockPattern!.description}
              </TableCell>
              <TableCell>{block!.item}</TableCell>
              <TableCell>{block!.itemDescription}</TableCell>
              <TableCell>{block!.interCompanyProductType!.name}</TableCell>
              <TableCell>{block!.blockNo}</TableCell>
              <TableCell>{status}</TableCell>
              <TableCell>{errorMessage}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default EBlockConfirmTable;
