import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  createSalesGroup,
  fetchSalesGroup,
  fetchSalesGroupList,
  updateSalesGroup,
} from '../../services/salesGroupService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_SALESGROUP,
  FETCH_SALESGROUP,
  FETCH_SALESGROUP_LIST,
  UPDATE_SALESGROUP,
} from './salesGroup.actions';
import { SalesGroupCreateOrUpdatePayload } from './salesGroup.types';

const listPageUrl = `/settings/salesGroups`;

function* fetchSalesGroupSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchSalesGroup, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchSalesGroupListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSalesGroupList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createSalesGroupSaga(action: AppAction) {
  try {
    const { values, history } = action.payload as SalesGroupCreateOrUpdatePayload;
    const successMessage = `Created ${values.name} salesGroup successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createSalesGroup, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    const formUrl = `${listPageUrl}/form/${data.id}`;
    history.push(formUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateSalesGroupSaga(action: AppAction) {
  try {
    const { values } = action.payload as SalesGroupCreateOrUpdatePayload;
    const successMessage = `Updated ${values.name} salesGroup successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateSalesGroup, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchSalesGroup() {
  yield takeLatest(FETCH_SALESGROUP, fetchSalesGroupSaga);
  yield debounce(250, FETCH_SALESGROUP_LIST, fetchSalesGroupListSaga);
  yield takeLatest(CREATE_SALESGROUP, createSalesGroupSaga);
  yield takeLatest(UPDATE_SALESGROUP, updateSalesGroupSaga);
}
