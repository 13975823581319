import {
  EBlockArtTemplate,
  EBlockArtTemplateCreateOrUpdatePayload,
  EBlockArtTemplateListFilter,
} from './eBlockArtTemplate.type';

import { AppAction, PageFilter } from '../app';

export const FETCH_EBLOCK_ART_TEMPLATE_LIST = 'FETCH_EBLOCK_ART_TEMPLATE_LIST';
export const FETCH_EBLOCK_ART_TEMPLATE = 'FETCH_EBLOCK_ART_TEMPLATE';
export const CREATE_EBLOCK_ART_TEMPLATE = 'CREATE_EBLOCK_ART_TEMPLATE';
export const CLEAR_EBLOCK_ART_TEMPLATE = 'CLEAR_EBLOCK_ART_TEMPLATE';
export const UPDATE_EBLOCK_ART_TEMPLATE = 'UPDATE_EBLOCK_ART_TEMPLATE';

export const fetchEBlockArtTemplateList = (
  filters: EBlockArtTemplateListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_EBLOCK_ART_TEMPLATE_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchEBlockArtTemplate = (id: EBlockArtTemplate['id']): AppAction => ({
  type: FETCH_EBLOCK_ART_TEMPLATE,
  payload: id,
});

export const createEBlockArtTemplate = (payload: {
  blockId: number;
  autoGenerateTask: boolean;
}): AppAction => ({
  type: CREATE_EBLOCK_ART_TEMPLATE,
  payload,
});

export const clearEBlockArtTemplate = () => ({
  type: CLEAR_EBLOCK_ART_TEMPLATE,
});

export const updateEBlockArtTemplate = (payload: EBlockArtTemplate): AppAction => ({
  type: UPDATE_EBLOCK_ART_TEMPLATE,
  payload,
});
