import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_SQUAD_WORKING_MEMBER,
  CREATE_SQUAD_WORKING_MEMBER,
  CREATE_SQUAD_WORKING_MEMBERS,
  DELETE_SQUAD_WORKING_MEMBER,
  FETCH_SQUAD_WORKING_MEMBER,
  FETCH_SQUAD_WORKING_MEMBER_LIST,
  UPDATE_SQUAD_WORKING_MEMBER,
} from './squadWorkingMember.actions';
import { SquadWorkingMember, SquadWorkingMemberState } from './squadWorkingMember.type';

const initialState: SquadWorkingMemberState = {
  errorMessage: '',
  item: {} as Readonly<SquadWorkingMember>,
  items: [] as ReadonlyArray<SquadWorkingMember>,
  loading: false,
  totalItems: 0,
};

export default (
  state: SquadWorkingMemberState = initialState,
  action: AppAction,
): SquadWorkingMemberState => {
  switch (action.type) {
    case REQUEST(FETCH_SQUAD_WORKING_MEMBER_LIST):
    case REQUEST(CREATE_SQUAD_WORKING_MEMBER):
    case REQUEST(CREATE_SQUAD_WORKING_MEMBERS):
    case REQUEST(FETCH_SQUAD_WORKING_MEMBER):
    case REQUEST(UPDATE_SQUAD_WORKING_MEMBER):
    case REQUEST(DELETE_SQUAD_WORKING_MEMBER):
      return { ...state, loading: true };
    case SUCCESS(FETCH_SQUAD_WORKING_MEMBER_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_SQUAD_WORKING_MEMBER_LIST):
    case FAILURE(CREATE_SQUAD_WORKING_MEMBER):
    case FAILURE(CREATE_SQUAD_WORKING_MEMBERS):
    case FAILURE(FETCH_SQUAD_WORKING_MEMBER):
    case FAILURE(UPDATE_SQUAD_WORKING_MEMBER):
    case FAILURE(DELETE_SQUAD_WORKING_MEMBER):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_SQUAD_WORKING_MEMBER):
    case SUCCESS(CREATE_SQUAD_WORKING_MEMBERS):
    case SUCCESS(FETCH_SQUAD_WORKING_MEMBER):
    case SUCCESS(UPDATE_SQUAD_WORKING_MEMBER):
      return { ...state, loading: false, item: action.payload.data };
    case SUCCESS(DELETE_SQUAD_WORKING_MEMBER):
      return { ...state, loading: false };
    case CLEAR_SQUAD_WORKING_MEMBER:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
