import { TableSortLabel } from '@material-ui/core';
import React from 'react';
import { ISort } from '../../state/app';
import { SortChangeEventHandler } from '../hooks/useTableSorting';

interface ISortLabelProps {
  sort: ISort;
  handleSortChange: SortChangeEventHandler;
  field: string;
}

const SortLabel: React.FC<ISortLabelProps> = props => {
  const { sort, handleSortChange, field } = props;
  return (    
    <TableSortLabel
      active={sort.orderBy === field}
      direction={sort.direction}
      onClick={handleSortChange(field)}
    >
      {props.children}
    </TableSortLabel>
  );
};

export default SortLabel;
