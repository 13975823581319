import { AppAction } from '../app';
import { Credentials } from './auth.types';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_DISPLAY_NAME = 'SET_DISPLAY_NAME';
export const SET_TOKEN = 'SET_TOKEN';
export const CHECK_AUTH = 'CHECK_AUTH';

export const login = (credential: Credentials): AppAction => {
  return {
    type: LOGIN,
    payload: credential,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const setToken = (token: string = '') => {
  return {
    payload: token,
    type: SET_TOKEN,
  };
};

export const checkAuth = () => {
  return {
    type: CHECK_AUTH,
  };
};
