import { AppAction, PageFilter } from '../app';
import {
  SalesGroup,
  SalesGroupCreateOrUpdatePayload,
  SalesGroupListFilter,
} from './salesGroup.types';

export const FETCH_SALESGROUP = 'FETCH_SALESGROUP';
export const FETCH_SALESGROUP_LIST = 'FETCH_SALESGROUP_LIST';
export const CREATE_SALESGROUP = 'CREATE_SALESGROUP';
export const UPDATE_SALESGROUP = 'UPDATE_SALESGROUP';
export const CLEAR_SALESGROUP = 'CLEAR_SALESGROUP';

export const fetchSalesGroup = (id: SalesGroup['id']): AppAction => ({
  type: FETCH_SALESGROUP,
  payload: id,
});

export const fetchSalesGroupList = (
  filters: SalesGroupListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_SALESGROUP_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const createSalesGroup = (
  payload: SalesGroupCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_SALESGROUP,
  payload,
});

export const updateSalesGroup = (
  payload: SalesGroupCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_SALESGROUP,
  payload,
});

export const clearSalesGroup = () => ({
  type: CLEAR_SALESGROUP,
});
