import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Save as SaveIcon } from '@material-ui/icons';
import React from 'react';

const useStyles = makeStyles(() => ({
  container: (props: FixedPositionButtonsProps) => {
    return {
      zIndex: 1200,
      position: 'absolute',
      top: '100px',
      right: '50px',
      display: props.open ? 'flex' : 'none',
      flexDirection: 'row',
    };
  },
  button: {
    marginRight: '5px',
    display: 'flex',
  },
  leftIcon: {
    marginRight: '5px',
  },
}));

interface ActionProps {
  name: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  color: 'inherit' | 'primary' | 'secondary' | 'default' | undefined;
  disabled: boolean;
  icon?: string | undefined;
}

interface FixedPositionButtonsProps {
  actions: ActionProps[];
  open: boolean;
}

const renderIcon = (icon: string | undefined, style: string) => {
  switch (icon) {
    case 'save':
      return <SaveIcon className={style} />;
    default:
      return '';
  }
};

const FixedPositionButtons: React.FC<FixedPositionButtonsProps> = props => {
  const classes = useStyles(props);
  const { actions } = props;

  return (
    <div className={classes.container}>
      {actions.map(({ name, onClick, color, disabled, icon }) => {
        return (
          <div key={name}>
            <Button
              className={classes.button}
              variant="contained"
              color={color}
              onClick={onClick}
              disabled={disabled}
            >
              {renderIcon(icon, classes.leftIcon)}
              {name}
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default FixedPositionButtons;
