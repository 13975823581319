import { PageFilter } from '../state/app';
import {
  ProgramAndBrandRequestItem,
  ProgramAndBrandRequestItemListFilter,
} from '../state/programAndBrandRequestItem';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';

const baseUrl = `/api/ProgramAndBrandRequestItems`;

export const fetchProgramAndBrandRequestItemList = async ({
  filters,
  paging,
}: {
  filters: ProgramAndBrandRequestItemListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchProgramAndBrandRequestItem = async (
  id: ProgramAndBrandRequestItem['id'],
) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createProgramAndBrandRequestItem = async (
  ProgramAndBrandRequestItem: ProgramAndBrandRequestItem,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, ProgramAndBrandRequestItem);
};

export const updateProgramAndBrandRequestItem = async (
  ProgramAndBrandRequestItem: ProgramAndBrandRequestItem,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, ProgramAndBrandRequestItem);
};

export const deleteProgramAndBrandRequestItem = async (
  ProgramAndBrandRequestItem: ProgramAndBrandRequestItem,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.delete(requestUrl, { data: ProgramAndBrandRequestItem });
};
