import { CardContent, TextField } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { IEBlockGroupProduct } from '../../../models/eBlockGroupProduct';
import { IEBlockSize } from '../../../models/eBlockSize';
import { AuthState } from '../../../state/auth';
import { AppState } from '../../../state/configureStore';
import {
  clearEBlockSize,
  createEBlockSize,
  EBlockSizeState,
  fetchEBlockSize,
  updateEBlockSize,
} from '../../../state/eBlockSize';
import {
  FixedPositionButtons,
  Loading,
  PageContainer,
  PageTitle,
} from '../../components';
import EBlockGroupProductComboBox from '../../components/EBlockGroupProductComboBox';

const EBlockSizeSchema = Yup.object().shape({
  eBlockGroupProductId: Yup.number()
    .required('EBlock group product is required')
    .nullable(true),
  code: Yup.string()
    .strict(true)
    .trim()
    .max(3, 'Code is exceeded character limit(3)')
    .required('Code is required'),
  description: Yup.string().nullable(true),
});

interface SizeFormRouteParamsProps {
  id: string;
}

interface SizeFormProps extends RouteComponentProps<SizeFormRouteParamsProps> {}

const EBlockSizeForm: React.FC<SizeFormProps> = props => {
  const dispatch = useDispatch();
  const { loading: authIsLoading } = useSelector<AppState, AuthState>(
    state => state.auth,
  );
  const { match, history } = props;
  const {
    params: { id: paramsId },
  } = match;

  const isUpdateMode = paramsId !== undefined;

  const { item: size, loading: sizeIsLoading } = useSelector<AppState, EBlockSizeState>(
    state => state.eBlockSize,
  );

  const isLoading = authIsLoading || sizeIsLoading;

  const defaultValues: IEBlockSize = {};

  const handleSubmit = (values: IEBlockSize, actions: FormikHelpers<IEBlockSize>) => {
    if (isUpdateMode) {
      dispatch(updateEBlockSize({ values, history }));
    } else {
      dispatch(createEBlockSize({ values, history }));
    }
    actions.setSubmitting(false);
  };

  const redirectEBlockSizeList = () => {
    history.goBack();
  };

  useEffect(() => {
    if (isUpdateMode) {
      dispatch(fetchEBlockSize(+paramsId));
    } else {
      dispatch(clearEBlockSize());
    }
    return function cleanUp() {
      dispatch(clearEBlockSize());
    };
  }, [isUpdateMode, paramsId, dispatch]);

  return (
    <PageContainer>
      <Loading isLoading={isLoading}>
        <Formik
          enableReinitialize={true}
          initialValues={{ ...defaultValues, ...size }}
          validationSchema={EBlockSizeSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, errors, touched, submitForm, setFieldValue }) => {
            return (
              <Form>
                <PageTitle>
                  {!isUpdateMode ? `New EBlock Size` : `Update EBlock Size ${size.code}`}
                </PageTitle>
                <CardContent>
                  <EBlockGroupProductComboBox
                    isDisabled={isUpdateMode}
                    handleChange={(result: IEBlockGroupProduct) => {
                      setFieldValue('eBlockGroupProductId', result.id);
                    }}
                    selectedValue={values.eBlockGroupProduct as IEBlockGroupProduct}
                    error={
                      !!errors.eBlockGroupProductId && !!touched.eBlockGroupProductId
                    }
                    helperText={errors.eBlockGroupProductId}
                  />
                  <TextField
                    name="code"
                    type="input"
                    label="Code"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={values.code || ''}
                    onChange={handleChange}
                    error={!!errors.code && !!touched.code}
                    helperText={errors.code}
                    InputProps={{
                      readOnly: isUpdateMode,
                    }}
                  />
                  <TextField
                    name="description"
                    type="input"
                    label="Description"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={values.description || ''}
                    onChange={handleChange}
                    error={!!errors.description && !!touched.description}
                    helperText={errors.description}
                  />
                </CardContent>
                <FixedPositionButtons
                  actions={[
                    {
                      name: !isUpdateMode ? `Create` : `Update`,
                      onClick: () => {
                        submitForm();
                      },
                      color: 'primary' as 'primary',
                      disabled: false,
                      icon: 'save',
                    },
                    {
                      name: 'CANCEL',
                      onClick: redirectEBlockSizeList,
                      color: 'default' as 'default',
                      disabled: false,
                    },
                  ]}
                  open
                />
              </Form>
            );
          }}
        </Formik>
      </Loading>
    </PageContainer>
  );
};

export default EBlockSizeForm;
