import DateFnsUtils from '@date-io/date-fns';

import SampleLocale from "date-fns/locale/en-US";
type Locale = typeof SampleLocale;

class SMDateFnsUtils extends DateFnsUtils {
  public dateTime12hFormat = "yyyy-MM-dd hh:mm:ss aaaa";
  public dateTime24hFormat = "yyyy-MM-dd HH:mm:ss";
  public time12hFormat = "hh:mm a";
  public time24hFormat = "HH:mm";
  public dateFormat = "yyyy-MM-dd";

  constructor({ locale }: { locale?: Locale } = {}) {
    super({locale});
  }
}

export default SMDateFnsUtils;
