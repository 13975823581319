import { Button, createStyles, makeStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { useState } from 'react';
import { IProjectProgramBrand } from '../../models/projectProgramBrand.model';
import { fetchProjectProgramBrandList } from '../../services/projectProgramBrandService';
import { Brand } from '../../state/brand';
import AsyncAutoComplete from './AsyncAutoComplete';
import ProjectProgramBrandDialogSearch from './ProjectProgramBrandDialogSearch';

interface ProjectProgramBrandComboBoxSearchProps {
  handleChange: (result: Brand) => void;
  selectedValue: Brand;
  isDisabled?: boolean;
  error?: boolean;
  helperText?: string;
  smProjectId?: number | null;
  smProjectProgramId?: number | null;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    autoComplete: {
      flexGrow: 20,
    },
    button: {
      marginBottom: '8px',
      flexGrow: 1,
    },
  }),
);

const ProjectProgramBrandComboBoxSearch: React.FC<
  ProjectProgramBrandComboBoxSearchProps
> = props => {
  const classes = useStyles();
  const {
    handleChange,
    selectedValue,
    isDisabled = false,
    error,
    helperText,
    smProjectId,
    smProjectProgramId,
  } = props;
  const [openSearch, setOpenSearch] = useState(false);

  const programPromiseOptions = async (inputValue: string) => {
    const { data: rows } = await fetchProjectProgramBrandList({
      filters: {
        'Brand.Name.StartsWith': inputValue,
        'SmProjectId.EqualsTo': smProjectId!,
        'SmProjectProgramId.EqualsTo': smProjectProgramId!,
      },
      paging: { pageNumber: 0, pageSize: 10 },
    });
    return rows.map((res: IProjectProgramBrand) => res.brand);
  };

  return (
    <div className={classes.container}>
      <AsyncAutoComplete
        name="Brand"
        value={selectedValue}
        className={classes.autoComplete}
        getOptionValue={(option: Brand) => {
          return option.id!.toString();
        }}
        getOptionLabel={(option: Brand) => {
          return option.name!;
        }}
        promiseOptions={programPromiseOptions}
        placeholder={'Type Brand Name'}
        handleChange={handleChange}
        isDisabled={isDisabled}
        error={error}
        helperText={helperText}
      />
      <Button
        className={classes.button}
        color="primary"
        variant="outlined"
        startIcon={<Search />}
        disabled={isDisabled}
        onClick={() => {
          setOpenSearch(true);
        }}
        style={{ marginLeft: 10 }}
      >
        Search
      </Button>

      <ProjectProgramBrandDialogSearch
        open={openSearch}
        smProjectId={smProjectId!}
        smProjectProgramId={smProjectProgramId!}
        onClose={selectedBrand => {
          if (selectedBrand) {
            handleChange(selectedBrand);
          }
          setOpenSearch(false);
        }}
      />
    </div>
  );
};

export default ProjectProgramBrandComboBoxSearch;
