import React from 'react';
import { Route } from 'react-router-dom';
import { PrivateRoute } from '../components';
import { Auth } from './auth';
import { BlockForm, BlockList } from './block';
import { BrandForm, BrandList } from './brand';
import { CompanyForm, CompanyList } from './company';
import { CustomerForm, CustomerList } from './customer';
import { CustomerGroupForm, CustomerGroupList } from './customerGroup';
import { CustomerGroupMemberList } from './customerGroupMember';
import { Dashboard } from './dashboard';
import { EBlockForm, EBlockList } from './eBlock';
import { EBlockArtTemplateList } from './eBlockArtTemplate';
import { EBlockGroupProductForm, EBlockGroupProductList } from './eBlockGroupProduct';
import { EBlockMaterialForm, EBlockMaterialList } from './eBlockMaterial';
import { EBlockPatternForm, EBlockPatternList } from './eBlockPattern';
import { EBlockSizeForm, EBlockSizeList } from './eBlockSize';
import { OpportunityFormPage, OpportunityList } from './opportunity';
import { OpportunityApprovalRequestList } from './opportunityApprovalRequest';
import { OpportunityCustomerList } from './opportunityCustomer';
import { OpportunityCustomerGroupList } from './opportunityCustomerGroup';
import { OpportunityItemList } from './opportunityItem';
import { PendingApprovalItemList } from './pendingApprovalItemList';
import { ProductTypeForm, ProductTypeList } from './productType';
import { ProgramForm, ProgramList } from './program';
import {
  ProgramAndBrandRequestForm,
  ProgramAndBrandRequestList,
} from './programAndBrandRequest';
import { ProjectFormPage, ProjectList } from './project';
import { ReportList } from './report';
import { ResponsibilityFormPage, ResponsibilityList } from './responsibility/';
import { roleRoute } from './roleRoute.config';
import { SalesGroupForm, SalesGroupList } from './salesGroup';
import { SalesTeamForm, SalesTeamList } from './salesTeam';
import { SalesTeamMemberList } from './salesTeamMember';
import { SampleDevelopmentHlForm, SampleDevelopmentHlList } from './sampleDevelopmentHl';
import { SampleDevelopmentNlForm, SampleDevelopmentNlList } from './sampleDevelopmentNl';
import { SampleDevelopmentPlForm, SampleDevelopmentPlList } from './sampleDevelopmentPl';
import { SampleDevelopmentTlForm, SampleDevelopmentTlList } from './sampleDevelopmentTl';
import { SampleDevelopmentWlForm, SampleDevelopmentWlList } from './sampleDevelopmentWl';
import { SquadList } from './squad';
import SquadForm from './squad/SquadForm';
import { SquadMemberList } from './squadMember';
import UserStructurePage from './structure/UserStructurePage';
import { TaskForm, TaskList } from './task';
import { TaskSubtaskItemArtTemplateList } from './task/taskSubtaskItemArtTemplate';
import { TaskSubtaskList } from './taskSubtask';
import { TaskSubtaskItemList } from './taskSubtaskItem';
import { TaskTaskItemList } from './taskTaskItem';
import { UserList } from './user';
import { WorkingGroupList } from './workingGroup';
import WorkingGroupForm from './workingGroup/WorkingGroupForm';
import { WorkingTeamList } from './workingTeam';
import WorkingTeamForm from './workingTeam/WorkingTeamForm';
import { WorkingTeamMemberList } from './workingTeamMember';
//--Generator Needle1--//

const AppRoute: React.FC = () => {
  return (
    <>
      <Route path="/" exact component={Auth} />
      <PrivateRoute path="/dashboard" exact component={Dashboard} />
      <PrivateRoute path="/settings/programs" exact component={ProgramList} />
      <PrivateRoute
        path="/settings/programs/form"
        exact
        component={ProgramForm}
        roles={roleRoute.createProgram}
      />
      <PrivateRoute
        path="/settings/programs/form/:id"
        exact
        component={ProgramForm}
        roles={roleRoute.createProgram}
      />
      <PrivateRoute path="/settings/brands" exact component={BrandList} />
      <PrivateRoute path="/settings/brands/form" exact component={BrandForm} />
      <PrivateRoute path="/settings/brands/form/:id" exact component={BrandForm} />
      <PrivateRoute
        path="/settings/salesTeams"
        roles={roleRoute.viewSalesTeam}
        exact
        component={SalesTeamList}
      />
      <PrivateRoute
        path="/settings/salesTeams/form"
        roles={roleRoute.createSalesTeam}
        exact
        component={SalesTeamForm}
      />
      <PrivateRoute
        path="/settings/salesTeams/form/:id"
        exact
        roles={roleRoute.createSalesTeam}
        component={SalesTeamForm}
      />
      <PrivateRoute
        path="/settings/salesTeamMembers"
        exact
        roles={roleRoute.viewSalesTeamMember}
        component={SalesTeamMemberList}
      />
      <PrivateRoute
        path="/settings/salesGroups"
        exact
        roles={roleRoute.viewSalesGroup}
        component={SalesGroupList}
      />
      <PrivateRoute
        path="/settings/workingGroups"
        exact
        roles={roleRoute.viewWorkingGroup}
        component={WorkingGroupList}
      />
      {/* <PrivateRoute
        path="/settings/squads"
        exact
        roles={roleRoute.viewSquad}
        component={SquadList}
      />
      <PrivateRoute
        path="/settings/squadMembers"
        exact
        roles={roleRoute.viewSquad}
        component={SquadMemberList}
      />
      <PrivateRoute
        path="/settings/squads/form"
        exact
        roles={roleRoute.createSquad}
        component={SquadForm}
      />
      <PrivateRoute
        path="/settings/squads/form/:id"
        exact
        roles={roleRoute.viewSquad}
        component={SquadForm}
      /> */}
      <PrivateRoute
        path="/settings/workingTeams"
        exact
        roles={roleRoute.viewWorkingGroup}
        component={WorkingTeamList}
      />
      <PrivateRoute
        path="/settings/workingTeamMembers"
        exact
        roles={roleRoute.viewWorkingGroup}
        component={WorkingTeamMemberList}
      />
      <PrivateRoute
        path="/settings/salesGroups/form"
        exact
        roles={roleRoute.createSalesGroup}
        component={SalesGroupForm}
      />
      <PrivateRoute
        path="/settings/salesGroups/form/:id"
        exact
        roles={roleRoute.createSalesGroup}
        component={SalesGroupForm}
      />
      <PrivateRoute
        path="/settings/workingGroups/form"
        exact
        roles={roleRoute.createSalesGroup}
        component={WorkingGroupForm}
      />
      <PrivateRoute
        path="/settings/workingTeams/form"
        exact
        roles={roleRoute.createWorkingTeam}
        component={WorkingTeamForm}
      />
      <PrivateRoute
        path="/settings/workingTeams/form/:id"
        exact
        roles={roleRoute.createWorkingTeam}
        component={WorkingTeamForm}
      />
      <PrivateRoute
        path="/settings/workingGroups/form/:id"
        exact
        roles={roleRoute.createSalesGroup}
        component={WorkingGroupForm}
      />
      <PrivateRoute
        path="/settings/eBlockGroupProducts"
        exact
        roles={roleRoute.viewEBlockGroupProduct}
        component={EBlockGroupProductList}
      />
      <PrivateRoute
        path="/settings/eBlockGroupProducts/form"
        exact
        roles={roleRoute.createEBlockGroupProduct}
        component={EBlockGroupProductForm}
      />
      <PrivateRoute
        path="/settings/eBlockGroupProducts/form/:id"
        exact
        roles={roleRoute.createEBlockGroupProduct}
        component={EBlockGroupProductForm}
      />
      <PrivateRoute
        path="/settings/eBlockMaterials"
        exact
        roles={roleRoute.viewEBlockMaterial}
        component={EBlockMaterialList}
      />
      <PrivateRoute
        path="/settings/eBlockMaterials/form"
        exact
        roles={roleRoute.createEBlockMaterial}
        component={EBlockMaterialForm}
      />
      <PrivateRoute
        path="/settings/eBlockMaterials/form/:id"
        exact
        roles={roleRoute.createEBlockMaterial}
        component={EBlockMaterialForm}
      />
      <PrivateRoute
        path="/settings/eBlockSizes"
        exact
        roles={roleRoute.viewEBlockSize}
        component={EBlockSizeList}
      />
      <PrivateRoute
        path="/settings/eBlockSizes/form"
        exact
        roles={roleRoute.createEBlockSize}
        component={EBlockSizeForm}
      />
      <PrivateRoute
        path="/settings/eBlockSizes/form/:id"
        exact
        roles={roleRoute.createEBlockSize}
        component={EBlockSizeForm}
      />
      <PrivateRoute
        path="/settings/eBlockPatterns"
        exact
        roles={roleRoute.viewEBlockPattern}
        component={EBlockPatternList}
      />
      <PrivateRoute
        path="/settings/eBlockPatterns/form"
        exact
        roles={roleRoute.createEBlockPattern}
        component={EBlockPatternForm}
      />
      <PrivateRoute
        path="/settings/eBlockPatterns/form/:id"
        exact
        roles={roleRoute.createEBlockPattern}
        component={EBlockPatternForm}
      />
      <PrivateRoute
        path="/settings/eBlocks"
        exact
        roles={roleRoute.viewEBlock}
        component={EBlockList}
      />
      <PrivateRoute
        path="/settings/eBlocks/form/:id"
        exact
        roles={roleRoute.createEBlock}
        component={EBlockForm}
      />
      <PrivateRoute
        path="/settings/eBlocksArtTemplates"
        exact
        roles={roleRoute.viewEBlock}
        component={EBlockArtTemplateList}
      />

      <PrivateRoute path="/settings/companies" exact component={CompanyList} />
      <PrivateRoute path="/settings/companies/form" exact component={CompanyForm} />
      <PrivateRoute path="/settings/companies/form/:id" exact component={CompanyForm} />
      <PrivateRoute path="/settings/customers" exact component={CustomerList} />
      <PrivateRoute path="/settings/customers/form" exact component={CustomerForm} />
      <PrivateRoute path="/settings/customers/form/:id" exact component={CustomerForm} />
      <PrivateRoute path="/settings/productTypes" exact component={ProductTypeList} />
      <PrivateRoute
        path="/settings/productTypes/form"
        exact
        component={ProductTypeForm}
      />
      <PrivateRoute
        path="/settings/productTypes/form/:id"
        exact
        component={ProductTypeForm}
      />
      <PrivateRoute path="/settings/blocks" exact component={BlockList} />
      <PrivateRoute path="/settings/blocks/form" exact component={BlockForm} />
      <PrivateRoute path="/settings/blocks/form/:id" exact component={BlockForm} />
      <PrivateRoute
        path="/assignments"
        exact
        roles={roleRoute.viewOpportunity}
        component={OpportunityList}
      />
      <PrivateRoute
        path="/assignments/form"
        exact
        roles={roleRoute.createOpportunity}
        component={OpportunityFormPage}
      />
      <PrivateRoute
        path="/assignments/form/:id"
        exact
        roles={roleRoute.createOpportunity}
        component={OpportunityFormPage}
      />
      <PrivateRoute
        path="/assignments/approvals"
        exact
        roles={roleRoute.viewOpportunity}
        component={OpportunityApprovalRequestList}
      />
      <PrivateRoute
        path="/assignments/customers"
        exact
        roles={roleRoute.viewOpportunity}
        component={OpportunityCustomerList}
      />
      <PrivateRoute
        path="/assignments/items"
        exact
        roles={roleRoute.viewOpportunity}
        component={OpportunityItemList}
      />
      <PrivateRoute
        path="/customerGroups"
        exact
        roles={roleRoute.viewCustomerGroup}
        component={CustomerGroupList}
      />
      <PrivateRoute
        path="/customerGroups/form"
        exact
        roles={roleRoute.createCustomerGroup}
        component={CustomerGroupForm}
      />
      <PrivateRoute
        path="/customerGroups/form/:id"
        exact
        roles={roleRoute.createCustomerGroup}
        component={CustomerGroupForm}
      />
      <PrivateRoute
        path="/customerGroupMembers"
        exact
        component={CustomerGroupMemberList}
      />
      <PrivateRoute
        path="/opportunityCustomerGroups"
        exact
        roles={roleRoute.viewOpportunity}
        component={OpportunityCustomerGroupList}
      />
      <PrivateRoute path="/tasks" exact component={TaskList} />
      <PrivateRoute path="/tasks/form" exact component={TaskForm} />
      <PrivateRoute path="/tasks/form/:id" exact component={TaskForm} />
      <PrivateRoute path="/tasks/subtasks" exact component={TaskSubtaskList} />
      <PrivateRoute
        path="/tasks/subtasks/subtaskItems"
        exact
        component={TaskSubtaskItemList}
      />
      <PrivateRoute
        path="/tasks/subtasks/subtaskItemArtTemplates"
        exact
        component={TaskSubtaskItemArtTemplateList}
      />
      <PrivateRoute path="/tasks/taskItems" exact component={TaskTaskItemList} />
      <PrivateRoute
        path="/programAndBrandRequests"
        exact
        roles={roleRoute.viewProgramBrandRequest}
        component={ProgramAndBrandRequestList}
      />
      <PrivateRoute
        path="/programAndBrandRequests/form"
        exact
        roles={roleRoute.createProgramBrandRequest}
        component={ProgramAndBrandRequestForm}
      />
      <PrivateRoute
        path="/programAndBrandRequests/form/:id"
        exact
        roles={roleRoute.createProgramBrandRequest}
        component={ProgramAndBrandRequestForm}
      />
      <PrivateRoute
        path="/reports"
        roles={roleRoute.viewReport}
        exact
        component={ReportList}
      />
      <PrivateRoute path="/users" roles={roleRoute.viewUser} exact component={UserList} />
      <PrivateRoute
        path="/pendingApprovalItems"
        roles={roleRoute.viewPendingApprovalItems}
        exact
        component={PendingApprovalItemList}
      />
      <PrivateRoute
        path="/sampleDevelopmentNl"
        exact
        component={SampleDevelopmentNlList}
      />
      <PrivateRoute
        path="/sampleDevelopmentNl/form"
        exact
        component={SampleDevelopmentNlForm}
      />
      <PrivateRoute
        path="/sampleDevelopmentNl/form/:id"
        exact
        component={SampleDevelopmentNlForm}
      />
      <PrivateRoute
        path="/sampleDevelopmentHl"
        exact
        component={SampleDevelopmentHlList}
      />
      <PrivateRoute
        path="/sampleDevelopmentHl/form"
        exact
        component={SampleDevelopmentHlForm}
      />
      <PrivateRoute
        path="/sampleDevelopmentHl/form/:id"
        exact
        component={SampleDevelopmentHlForm}
      />
      <PrivateRoute
        path="/sampleDevelopmentPl"
        exact
        component={SampleDevelopmentPlList}
      />
      <PrivateRoute
        path="/sampleDevelopmentPl/form"
        exact
        component={SampleDevelopmentPlForm}
      />
      <PrivateRoute
        path="/sampleDevelopmentPl/form/:id"
        exact
        component={SampleDevelopmentPlForm}
      />
      <PrivateRoute
        path="/sampleDevelopmentTl"
        exact
        component={SampleDevelopmentTlList}
      />
      <PrivateRoute
        path="/sampleDevelopmentTl/form"
        exact
        component={SampleDevelopmentTlForm}
      />
      <PrivateRoute
        path="/sampleDevelopmentTl/form/:id"
        exact
        component={SampleDevelopmentTlForm}
      />
      <PrivateRoute
        path="/sampleDevelopmentWl"
        exact
        component={SampleDevelopmentWlList}
      />
      <PrivateRoute
        path="/sampleDevelopmentWl/form"
        exact
        component={SampleDevelopmentWlForm}
      />
      <PrivateRoute
        path="/sampleDevelopmentWl/form/:id"
        exact
        component={SampleDevelopmentWlForm}
      />
      <PrivateRoute path="/responsibilities" exact component={ResponsibilityList} />
      <PrivateRoute
        path="/responsibilities/form"
        exact
        component={ResponsibilityFormPage}
      />
      <PrivateRoute
        path="/responsibilities/form/:id"
        exact
        component={ResponsibilityFormPage}
      />
      <PrivateRoute path="/projects" exact component={ProjectList} />
      <PrivateRoute path="/projects/form" exact component={ProjectFormPage} />
      <PrivateRoute path="/projects/form/:id" exact component={ProjectFormPage} />
      <PrivateRoute
        path="/structures/user/:username"
        exact
        component={UserStructurePage}
      />
      {/* --Generator Needle2-- */}
    </>
  );
};

export default AppRoute;
