import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import {
  createOpportunityCustomerGroup,
  deleteOpportunityCustomerGroup,
  fetchOpportunityCustomerGroup,
  fetchOpportunityCustomerGroupList,
  updateOpportunityCustomerGroup,
} from '../../services/opportunityCustomerGroupService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_OPPORTUNITYCUSTOMERGROUP,
  CREATE_OPPORTUNITYCUSTOMERGROUPS,
  DELETE_OPPORTUNITYCUSTOMERGROUP,
  FETCH_OPPORTUNITYCUSTOMERGROUP,
  FETCH_OPPORTUNITYCUSTOMERGROUP_LIST,
  fetchOpportunityCustomerGroupListByOpportunityId,
  UPDATE_OPPORTUNITYCUSTOMERGROUP,
} from './opportunityCustomerGroup.actions';
import { OpportunityCustomerGroupCreateOrUpdatePayload } from './opportunityCustomerGroup.types';
import { AppState } from '../configureStore';
import { IOpportunity } from '../../models/opportunity.model';

function* fetchOpportunityCustomerGroupSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchOpportunityCustomerGroup, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchOpportunityCustomerGroupListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchOpportunityCustomerGroupList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createOpportunityCustomerGroupSaga(action: AppAction) {
  try {
    const { values } = action.payload as OpportunityCustomerGroupCreateOrUpdatePayload;
    const successMessage = `Created successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createOpportunityCustomerGroup, values);
    yield put(fetchOpportunityCustomerGroupListByOpportunityId(data.opportunityId));
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createOpportunityCustomerGroupsSaga(action: AppAction) {
  try {
    const { payload, callback } = action;

    yield put({ type: REQUEST(action.type) });

    for (const opCustGrp of payload) {
      yield call(createOpportunityCustomerGroup, opCustGrp);
    }

    const successMessage = `Created successfully.`;
    yield put(fetchOpportunityCustomerGroupListByOpportunityId(payload[0].opportunityId));

    yield put({ type: SUCCESS(action.type), payload });

    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));

    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateOpportunityCustomerGroupSaga(action: AppAction) {
  try {
    const { values } = action.payload as OpportunityCustomerGroupCreateOrUpdatePayload;
    const successMessage = `Updated successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateOpportunityCustomerGroup, values);
    yield put(fetchOpportunityCustomerGroupListByOpportunityId(data.opportunityId));
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteOpportunityCustomerGroupSaga(action: AppAction) {
  const { payload } = action;
  const { id } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    yield call(deleteOpportunityCustomerGroup, id);
    const opportunity = yield select(getOpportunityFromState);
    if (opportunity.id) {
      yield put(fetchOpportunityCustomerGroupListByOpportunityId(opportunity.id));
    }
    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Deleted successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function getOpportunityFromState(state: AppState): IOpportunity {
  const { item } = state.opportunity;
  return item;
}

export default function* watchOpportunityCustomerGroup() {
  yield takeLatest(FETCH_OPPORTUNITYCUSTOMERGROUP, fetchOpportunityCustomerGroupSaga);
  yield debounce(
    250,
    FETCH_OPPORTUNITYCUSTOMERGROUP_LIST,
    fetchOpportunityCustomerGroupListSaga,
  );
  yield takeLatest(CREATE_OPPORTUNITYCUSTOMERGROUP, createOpportunityCustomerGroupSaga);
  yield takeLatest(UPDATE_OPPORTUNITYCUSTOMERGROUP, updateOpportunityCustomerGroupSaga);
  yield takeLatest(DELETE_OPPORTUNITYCUSTOMERGROUP, deleteOpportunityCustomerGroupSaga);
  yield takeLatest(CREATE_OPPORTUNITYCUSTOMERGROUPS, createOpportunityCustomerGroupsSaga);
}
