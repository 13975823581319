import {
  IBrandApprovalRequestReportFilter,
  ICheckAccountOwnerCriteria,
  IOpportunityApprovalRequestReportFilter,
  IOpportunityItemSummaryReportFilter,
  IProgramApprovalRequestReportFilter,
  IProjectSummaryReportFilter,
  ISubtaskItemCriteria,
  ISubtaskItemDurationFilter,
  ISubtaskItemSummaryCriteria,
  ISubtaskItemSummaryReportFilter,
  ISubtaskItemWorkDurationSummaryCriteria,
  ITaskItemSummaryReportFilter,
  IOpportunityChangeLogReportFilter,
  IProjectChangeLogReportFilter,
} from '.';
import { AppAction, PageFilter } from '../app';

export const DOWNLOAD_CHECK_ACCOUNT_OWNER_REPORT = 'DOWNLOAD_CHECK_ACCOUNT_OWNER_REPORT';
export const DOWNLOAD_SUBTASK_SUMMARY_REPORT = 'DOWNLOAD_SUBTASK_SUMMARY_REPORT';
export const DOWNLOAD_SUBTASK_ITEM_WORK_DURATION_SUMMARY_REPORT =
  'DOWNLOAD_SUBTASK_ITEM_WORK_DURATION_SUMMARY_REPORT';
export const DOWNLOAD_SUBTASK_ITEM_REPORT = 'DOWNLOAD_SUBTASK_ITEM_REPORT';
export const FETCH_OPPORTUNITY_ITEM_SUMMARY_REPORT_LIST =
  'FETCH_OPPORTUNITY_ITEM_SUMMARY_REPORT_LIST';
export const FETCH_TASK_ITEM_SUMMARY_REPORT_LIST = 'FETCH_TASK_ITEM_SUMMARY_REPORT_LIST';
export const FETCH_SUBTASK_WORK_DURATION_REPORT = 'FETCH_SUBTASK_WORK_DURATION_REPORT';
export const FETCH_SUBTASK_ITEM_SUMMARY_REPORT_LIST =
  'FETCH_SUBTASK_ITEM_SUMMARY_REPORT_LIST';
export const FETCH_PROJECT_SUMMARY_REPORT_LIST = 'FETCH_PROJECT_SUMMARY_REPORT_LIST';
export const FETCH_OPPORTUNITY_APPROVAL_REQUEST_REPORT_LIST =
  'FETCH_OPPORTUNITY_APPROVAL_REQUEST_REPORT_LIST';
export const DOWNLOAD_OPPORTUNITY_APPROVAL_REQUEST_REPORT_LIST =
  'DOWNLOAD_OPPORTUNITY_APPROVAL_REQUEST_REPORT_LIST';
export const FETCH_PROGRAM_APPROVAL_REQUEST_REPORT_LIST =
  'FETCH_PROGRAM_APPROVAL_REQUEST_REPORT_LIST';
export const DOWNLOAD_PROGRAM_APPROVAL_REQUEST_REPORT_LIST =
  'DOWNLOAD_PROGRAM_APPROVAL_REQUEST_REPORT_LIST';
export const FETCH_BRAND_APPROVAL_REQUEST_REPORT_LIST =
  'FETCH_BRAND_APPROVAL_REQUEST_REPORT_LIST';
export const DOWNLOAD_BRAND_APPROVAL_REQUEST_REPORT_LIST =
  'DOWNLOAD_BRAND_APPROVAL_REQUEST_REPORT_LIST';
export const FETCH_PROJECT_SALES_SUMMARY_REPORT_LIST =
  'FETCH_PROJECT_SALES_SUMMARY_REPORT_LIST';
export const CLEAR_REPORT = 'CLEAR_REPORT';
export const FETCH_PROJECT_CHANGELOG_REPORT_LIST = 'FETCH_PROJECT_CHANGELOG_REPORT_LIST';
export const FETCH_OPPORTUNITY_CHANGELOG_REPORT_LIST =
  'FETCH_OPPORTUNITY_CHANGELOG_REPORT_LIST';

export const downloadCheckAccountOwnerReport = (
  filters: ICheckAccountOwnerCriteria,
): AppAction => {
  return {
    type: DOWNLOAD_CHECK_ACCOUNT_OWNER_REPORT,
    payload: {
      filters,
    },
  };
};

export const downloadSubtaskItemSummaryReport = (
  filters: ISubtaskItemSummaryCriteria,
): AppAction => {
  return {
    type: DOWNLOAD_SUBTASK_SUMMARY_REPORT,
    payload: {
      filters,
    },
  };
};

export const downloadSubtaskItemWorkDurationSummaryReport = (
  filters: ISubtaskItemWorkDurationSummaryCriteria,
): AppAction => {
  return {
    type: DOWNLOAD_SUBTASK_ITEM_WORK_DURATION_SUMMARY_REPORT,
    payload: {
      filters,
    },
  };
};

export const downloadSubtaskItemReport = (filters: ISubtaskItemCriteria): AppAction => {
  return {
    type: DOWNLOAD_SUBTASK_ITEM_REPORT,
    payload: {
      filters,
    },
  };
};

export const clearReport = (): AppAction => ({
  type: CLEAR_REPORT,
});

export const fetchOpportunityItemSummaryReportList = (
  filters: IOpportunityItemSummaryReportFilter,
  paging: PageFilter,
): AppAction => ({
  type: FETCH_OPPORTUNITY_ITEM_SUMMARY_REPORT_LIST,
  payload: {
    filters,
    paging,
  },
});

export const fetchOpportunityChangeLogReportList = (
  filters: IOpportunityChangeLogReportFilter,
  paging: PageFilter,
): AppAction => ({
  type: FETCH_OPPORTUNITY_CHANGELOG_REPORT_LIST,
  payload: {
    filters,
    paging,
  },
});

export const fetchTaskItemSummaryReportList = (
  filters: ITaskItemSummaryReportFilter,
  paging: PageFilter,
): AppAction => ({
  type: FETCH_TASK_ITEM_SUMMARY_REPORT_LIST,
  payload: {
    filters,
    paging,
  },
});

export const fetchProjectSummaryReportList = (
  filters: IProjectSummaryReportFilter,
  paging: PageFilter,
): AppAction => ({
  type: FETCH_PROJECT_SUMMARY_REPORT_LIST,
  payload: {
    filters,
    paging,
  },
});

export const fetchProjectSalesSummaryReportList = (
  filters: IProjectSummaryReportFilter,
  paging: PageFilter,
): AppAction => ({
  type: FETCH_PROJECT_SALES_SUMMARY_REPORT_LIST,
  payload: {
    filters,
    paging,
  },
});

export const fetchProjectChangeLogReportList = (
  filters: IProjectChangeLogReportFilter,
  paging: PageFilter,
): AppAction => ({
  type: FETCH_PROJECT_CHANGELOG_REPORT_LIST,
  payload: {
    filters,
    paging,
  },
});

export const fetchOpportunityApprovalRequestReportList = (
  filters: IOpportunityApprovalRequestReportFilter,
  paging: PageFilter,
): AppAction => ({
  type: FETCH_OPPORTUNITY_APPROVAL_REQUEST_REPORT_LIST,
  payload: {
    filters,
    paging,
  },
});

export const downloadOpportunityApprovalRequestReportList = (
  filters: IOpportunityApprovalRequestReportFilter,
): AppAction => {
  return {
    type: DOWNLOAD_OPPORTUNITY_APPROVAL_REQUEST_REPORT_LIST,
    payload: {
      filters,
    },
  };
};

export const fetchSubtaskItemSummaryReportList = (
  filters: ISubtaskItemSummaryReportFilter,
  paging: PageFilter,
): AppAction => ({
  type: FETCH_SUBTASK_ITEM_SUMMARY_REPORT_LIST,
  payload: {
    filters,
    paging,
  },
});

export const fetchSubtaskItemDurationReportList = (
  filters: ISubtaskItemDurationFilter,
  paging: PageFilter,
): AppAction => ({
  type: FETCH_SUBTASK_WORK_DURATION_REPORT,
  payload: {
    filters,
    paging,
  },
});

export const fetchProgramApprovalRequestReportList = (
  filters: IProgramApprovalRequestReportFilter,
  paging: PageFilter,
): AppAction => ({
  type: FETCH_PROGRAM_APPROVAL_REQUEST_REPORT_LIST,
  payload: {
    filters,
    paging,
  },
});

export const downloadProgramApprovalRequestReportList = (
  filters: IProgramApprovalRequestReportFilter,
): AppAction => {
  return {
    type: DOWNLOAD_PROGRAM_APPROVAL_REQUEST_REPORT_LIST,
    payload: {
      filters,
    },
  };
};

export const fetchBrandApprovalRequestReportList = (
  filters: IBrandApprovalRequestReportFilter,
  paging: PageFilter,
): AppAction => ({
  type: FETCH_BRAND_APPROVAL_REQUEST_REPORT_LIST,
  payload: {
    filters,
    paging,
  },
});

export const downloadBrandApprovalRequestReportList = (
  filters: IBrandApprovalRequestReportFilter,
): AppAction => {
  return {
    type: DOWNLOAD_BRAND_APPROVAL_REQUEST_REPORT_LIST,
    payload: {
      filters,
    },
  };
};
