import {
  AppBar,
  Button,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close as CloseIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IOpportunityItemSummaryReport } from '../../../../../models/report.model';
import { downloadOpportunityItemSummaryReport } from '../../../../../services/reportService';
import { PageFilter } from '../../../../../state/app';
import { AppState } from '../../../../../state/configureStore';
import {
  clearReport,
  fetchOpportunityItemSummaryReportList,
  IOpportunityItemSummaryReportFilter,
  ReportState,
} from '../../../../../state/report';
import {
  ChipList,
  DateTimeDisplay,
  PageContainer,
  PageTitle,
  TableLoading,
} from '../../../../components';
import SortLabel from '../../../../components/SortLabel';
import { useFilter, useTableSorting } from '../../../../hooks';
import OpportunityItemSummaryReportFilterComponent from './OpportunityItemSummaryReportFilter';
import useStyles from './OpportunityItemSummaryReportStyle';

interface OpportunityItemSummaryReportDialogProps {
  open: boolean;
  handleClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});
const date = new Date();
const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 2);
const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, +1);
const initialOpportunityItemSummaryReportFilter: IOpportunityItemSummaryReportFilter = {
  'Opportunity.CreatedAt.GreaterThanOrEqual': firstDayOfMonth.toISOString().split('T')[0],
  'Opportunity.CreatedAt.LessThanOrEqual': lastDayOfMonth.toISOString().split('T')[0],
};

const OpportunityItemSummaryReportDialog: React.FC<
  OpportunityItemSummaryReportDialogProps
> = (props) => {
  const { open, handleClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    loading,
    items: opportunityItemSummaryReportList,
    totalItems,
  } = useSelector<AppState, ReportState>((state) => state.report);

  const [filters, setFilters] = useFilter<IOpportunityItemSummaryReportFilter>(
    initialOpportunityItemSummaryReportFilter,
    true,
  );

  const [sort, setSort] = useTableSorting(
    {
      orderBy: 'Opportunity.AssignmentNumber',
      direction: 'desc',
    },
    true,
  );

  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  useEffect(() => {
    if (open) {
      dispatch(fetchOpportunityItemSummaryReportList(filters, { ...paging, sort }));
    }
    return function cleanUp() {
      dispatch(clearReport());
    };
  }, [dispatch, paging, filters, open, sort]);

  const handleChangeFilter = (newFilter: IOpportunityItemSummaryReportFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const exportExcel = () => {
    downloadOpportunityItemSummaryReport(filters, {
      pageSize: totalItems,
      sort,
    }).catch((err) => console.error(err));
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Assignment Item Summary
          </Typography>
        </Toolbar>
      </AppBar>
      <PageContainer>
        <PageTitle>Assignment Item Summary</PageTitle>
        <OpportunityItemSummaryReportFilterComponent
          filters={filters}
          onChangeFilter={handleChangeFilter}
          initialFilter={initialOpportunityItemSummaryReportFilter}
        />
        {!isSmallScreen && (
          <div className={classes.filterContainer}>
            <Button
              variant={'contained'}
              className={classes.button}
              onClick={exportExcel}
            >
              Export Excel
            </Button>
          </div>
        )}
        {open && (
          <Grid container spacing={3} className={classes.container}>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="Opportunity.Company.Name"
                      >
                        Company
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="Opportunity.AssignmentNumber"
                      >
                        Assignment Number
                      </SortLabel>
                    </TableCell>
                    <TableCell>Project</TableCell>
                    <TableCell>Project Owner</TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="Block.Program.Name"
                      >
                        Program
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="Block.Brand.Name"
                      >
                        Brand
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="Block.Item"
                      >
                        Item
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="Block.BlockNo"
                      >
                        Block No
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="Block.CreatedAt"
                      >
                        Block Created
                      </SortLabel>
                    </TableCell>
                    {/* <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="Opportunity.AccountOwner.FirstName"
                      >
                        Account Owner
                      </SortLabel>
                    </TableCell> */}
                    <TableCell>Sales</TableCell>
                    <TableCell>CS</TableCell>
                    <TableCell>Tasks Number</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableLoading isLoading={loading} colSpan={10}>
                    {opportunityItemSummaryReportList.map(
                      (item: IOpportunityItemSummaryReport, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {index + paging.pageSize * paging.pageNumber + 1}
                          </TableCell>
                          <TableCell>{item.company}</TableCell>
                          <TableCell>{item.assignmentNumber}</TableCell>
                          <TableCell>{item.projectName}</TableCell>
                          <TableCell>{item.projectOwner}</TableCell>
                          <TableCell>{item.program}</TableCell>
                          <TableCell>{item.brand}</TableCell>
                          <TableCell>{item.item}</TableCell>
                          <TableCell>{item.blockNo}</TableCell>
                          <TableCell>
                            <DateTimeDisplay value={item.blockCreatedAt} />
                          </TableCell>
                          {/* <TableCell>{item.accountOwner}</TableCell> */}
                          <TableCell>
                            <ChipList list={item.sales ? item.sales : []} noAllLabel />
                          </TableCell>
                          <TableCell>
                            <ChipList list={item.cs ? item.cs : []} noAllLabel />
                          </TableCell>
                          <TableCell>{item.tasksNumber!.join(', ')}</TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableLoading>
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[15, 25, 35, 500, 10000]}
                component="div"
                count={totalItems}
                rowsPerPage={paging.pageSize}
                page={paging.pageNumber}
                onPageChange={handleChangePageNumber}
                onRowsPerPageChange={handleChangePageSize}
              />
            </Grid>
          </Grid>
        )}
      </PageContainer>
    </Dialog>
  );
};

export default OpportunityItemSummaryReportDialog;
