import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_SQUAD_SALES_MEMBER,
  CREATE_SQUAD_SALES_MEMBER,
  CREATE_SQUAD_SALES_MEMBERS,
  FETCH_SQUAD_SALES_MEMBER,
  FETCH_SQUAD_SALES_MEMBER_LIST,
  UPDATE_SQUAD_SALES_MEMBER,
  DELETE_SQUAD_SALES_MEMBER,
} from './squadSalesMember.actions';
import { SquadSalesMember, SquadSalesMemberState } from './squadSalesMember.type';

const initialState: SquadSalesMemberState = {
  errorMessage: '',
  item: {} as Readonly<SquadSalesMember>,
  items: [] as ReadonlyArray<SquadSalesMember>,
  loading: false,
  totalItems: 0,
};

export default (
  state: SquadSalesMemberState = initialState,
  action: AppAction,
): SquadSalesMemberState => {
  switch (action.type) {
    case REQUEST(FETCH_SQUAD_SALES_MEMBER_LIST):
    case REQUEST(CREATE_SQUAD_SALES_MEMBER):
    case REQUEST(CREATE_SQUAD_SALES_MEMBERS):
    case REQUEST(FETCH_SQUAD_SALES_MEMBER):
    case REQUEST(UPDATE_SQUAD_SALES_MEMBER):
    case REQUEST(DELETE_SQUAD_SALES_MEMBER):
      return { ...state, loading: true };
    case SUCCESS(FETCH_SQUAD_SALES_MEMBER_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_SQUAD_SALES_MEMBER_LIST):
    case FAILURE(CREATE_SQUAD_SALES_MEMBER):
    case FAILURE(FETCH_SQUAD_SALES_MEMBER):
    case FAILURE(UPDATE_SQUAD_SALES_MEMBER):
    case FAILURE(CREATE_SQUAD_SALES_MEMBERS):
    case FAILURE(DELETE_SQUAD_SALES_MEMBER):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_SQUAD_SALES_MEMBER):
    case SUCCESS(FETCH_SQUAD_SALES_MEMBER):
    case SUCCESS(UPDATE_SQUAD_SALES_MEMBER):
    case SUCCESS(CREATE_SQUAD_SALES_MEMBERS):
      return { ...state, loading: false, item: action.payload.data };
    case SUCCESS(DELETE_SQUAD_SALES_MEMBER):
      return { ...state, loading: false };

    case CLEAR_SQUAD_SALES_MEMBER:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
