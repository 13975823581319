import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
} from '@material-ui/core';
import React, { useState } from 'react';
import SquadStructure from '../../structure/squad/SquadStructure';

export interface StructureDialogProps {
  open: boolean;
  onClose: () => void;
  assignmentNumber: string | undefined;
}

const StructureDialog: React.FC<StructureDialogProps> = (props) => {
  const { open, onClose, assignmentNumber } = props;

  const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <Dialog open={open} fullScreen={true}>
      <DialogTitle>
        Assignment Structure{' '}
        <span style={{ fontWeight: 'bold' }}>{assignmentNumber}</span>
      </DialogTitle>
      <DialogContent>
        <AppBar position="static" color="default" variant="outlined">
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, newIndex) => setTabIndex(newIndex)}
            variant="scrollable"
          >
            <Tab label="Member" />
          </Tabs>
        </AppBar>
        {tabIndex === 0 && <SquadStructure assignmentNumber={assignmentNumber} />}
      </DialogContent>
      <DialogActions>
        <AppBar
          position="static"
          color="default"
          variant="outlined"
          style={{ padding: 15 }}
        >
          <Grid container justifyContent="center">
            <Button variant="contained" color="primary" onClick={() => onClose()}>
              Close Dialog
            </Button>
          </Grid>
        </AppBar>
      </DialogActions>
    </Dialog>
  );
};

export default StructureDialog;
