import { PageFilter } from '../state/app';
import { CustomerGroup, CustomerGroupListFilter } from '../state/customerGroup';
import axios from '../utils/axios';

const baseUrl = `/api/customerGroups`;

export const fetchCustomerGroupList = async ({
  filters,
  paging,
}: {
  filters: CustomerGroupListFilter;
  paging: PageFilter;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchCustomerGroup = async (id: CustomerGroup['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createCustomerGroup = async (customerGroup: CustomerGroup) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, customerGroup);
};

export const updateCustomerGroup = async (customerGroup: CustomerGroup) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, customerGroup);
};
