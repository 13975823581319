import React from 'react';
import { useParams } from 'react-router-dom';
import OpportunityForm from './OpportunityForm';

interface OpportunityFormPageRouteParamsProps {
  id: string;
}

const OpportunityFormPage: React.FC<OpportunityFormPageRouteParamsProps> = props => {
  const { id: paramsId } = useParams<OpportunityFormPageRouteParamsProps>();
  return <OpportunityForm opportunityId={paramsId ? +paramsId : undefined}/>;
};

export default OpportunityFormPage;
