import { Box, Button } from '@material-ui/core';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  FileCopy as CopyIcon,
  MoveToInbox as PasteIcon,
} from '@material-ui/icons';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import {
  copyGridToClipboard,
  pasteGridValueFromClipboard,
} from '../../../utils/agGridUtil';
import { v4 as uuidv4 } from 'uuid';
import { isTemplateExpression } from 'typescript';
import SelectOptionCellEditor from '../EditableTable/SelectOptionCellEditor';

const BlockItemAndItemDescriptionRowValidationSchema = Yup.object().shape({
  item: Yup.string().required('Item is required'),
  itemDescription: Yup.string().nullable(),
  hanesProjectName: Yup.string().nullable(),
});

interface IBlockItemAndItemDescription {
  item: string;
  itemDescription: string;
  hanesProjectName: string;
  rowIndex?: string;
}

export const IBlockItemAndItemDescriptionKeys: Array<keyof IBlockItemAndItemDescription> =
  ['item', 'itemDescription', 'hanesProjectName'];

interface BlockItemAndItemDescriptionTableProps {
  onAdd: (newRowValue: IBlockItemAndItemDescription) => void;
  onChange: (newRowValue: IBlockItemAndItemDescription) => void;
  onDelete: (selectedRowIndex: number[]) => void;
  onPaste: (newRowValues: IBlockItemAndItemDescription[]) => void;
  value: IBlockItemAndItemDescription[];
}

const cellStyle = (params: any) => {
  const { colDef, data } = params;
  if (colDef.field) {
    try {
      BlockItemAndItemDescriptionRowValidationSchema.validateSyncAt(colDef.field, data);
    } catch (e) {
      return { border: '1px solid red' };
    }
  }
  return { border: 'none' };
};

const columnDefs = [
  { headerName: '', checkboxSelection: true, width: 50 },
  {
    headerName: 'Item Name',
    sortable: true,
    filter: true,
    editable: true,
    field: 'item',
    cellStyle,
  },
  {
    headerName: 'Spec Detail/Description',
    sortable: true,
    filter: true,
    editable: true,
    field: 'itemDescription',
    cellStyle,
  },
  // {
  //   headerName: 'Hanes Project',
  //   sortable: true,
  //   filter: true,
  //   editable: true,
  //   field: 'hanesProjectName',
  //   cellStyle,
  //   cellEditorFramework: SelectOptionCellEditor,
  //   cellEditorParams: {
  //     options: UnitOfQuantityOptions,
  //   },
  // },
];

const defaultNewRowValue = {
  item: '',
  itemDescription: '',
  hanesProjectName: '',
};

const BlockItemAndItemDescriptionTable: React.FC<
  BlockItemAndItemDescriptionTableProps
> = (props) => {
  const { onChange, value, onDelete, onPaste, onAdd } = props;
  const gridApi = useRef<GridApi>();

  const handleAdd = () => {
    onAdd({ ...defaultNewRowValue, rowIndex: uuidv4() });
  };

  const handleDelete = () => {
    const selectedNodes = gridApi.current!.getSelectedNodes();
    const selectedRowIndex = selectedNodes.map((node) => node.rowIndex);
    onDelete(selectedRowIndex);
  };

  const handleCopy = () => {
    copyGridToClipboard(gridApi.current!, IBlockItemAndItemDescriptionKeys);
  };

  const handlePaste = async () => {
    await pasteGridValueFromClipboard(
      gridApi.current!,
      IBlockItemAndItemDescriptionKeys,
      defaultNewRowValue,
    );
    const rowData: any[] = [];
    gridApi.current!.forEachNode((node) => {
      rowData.push(node.data);
    });
    onPaste(rowData);
  };

  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
    gridApi.current.setRowData(value);
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    if (gridApi.current !== undefined) {
      gridApi.current.setRowData(value);
    }
  }, [value]);

  return (
    <>
      <Box m={1}>
        <Button
          onClick={handleCopy}
          variant="contained"
          size="small"
          startIcon={<CopyIcon />}
        >
          Copy
        </Button>
        <Button
          onClick={handlePaste}
          variant="contained"
          size="small"
          startIcon={<PasteIcon />}
        >
          Paste
        </Button>
        <Button
          onClick={handleAdd}
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
        >
          Add
        </Button>
        <Button
          onClick={handleDelete}
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      </Box>
      <div className="ag-theme-balham" style={{ height: '100%' }}>
        <AgGridReact
          onGridReady={onGridReady}
          columnDefs={columnDefs}
          domLayout="autoHeight"
          rowSelection="multiple"
          singleClickEdit
          suppressRowClickSelection
          stopEditingWhenGridLosesFocus
          onCellValueChanged={(event: any) => {
            const newRowValue = {
              rowIndex: event.data.rowIndex,
              item: event.data.item,
              itemDescription: event.data.itemDescription,
              hanesProjectName: event.data.hanesProjectName,
            };
            onChange(newRowValue);
          }}
        />
      </div>
    </>
  );
};

export default BlockItemAndItemDescriptionTable;
