import { AppAction, PageFilter, AppCallback } from '../app';
import {
  ProgramRequestItem,
  ProgramRequestItemListFilter,
} from './programRequestItem.types';

export const FETCH_PROGRAMREQUESTITEM = 'FETCH_PROGRAMREQUESTITEM';
export const FETCH_PROGRAMREQUESTITEM_LIST = 'FETCH_PROGRAMREQUESTITEM_LIST';
export const CREATE_PROGRAMREQUESTITEM = 'CREATE_PROGRAMREQUESTITEM';
export const UPDATE_PROGRAMREQUESTITEM = 'UPDATE_PROGRAMREQUESTITEM';
export const DELETE_PROGRAMREQUESTITEM = 'DELETE_PROGRAMREQUESTITEM';
export const CLEAR_PROGRAMREQUESTITEM = 'CLEAR_PROGRAMREQUESTITEM';

export const fetchProgramRequestItem = (id: ProgramRequestItem['id']): AppAction => ({
  type: FETCH_PROGRAMREQUESTITEM,
  payload: id,
});

export const fetchProgramRequestItemList = (
  filters: ProgramRequestItemListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_PROGRAMREQUESTITEM_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchProgramRequestItemListByProgramAndBrandRequestId = (programAndBrandRequestId: number): AppAction => {
  return fetchProgramRequestItemList(
    {
      'ProgramAndBrandRequestId.equalsTo': programAndBrandRequestId,
    }, {
      pageNumber: 0,
      pageSize: 9999,
    });
};

export const createProgramRequestItem = (newProgramRequestItem: ProgramRequestItem, callback?: AppCallback): AppAction => ({
  type: CREATE_PROGRAMREQUESTITEM,
  payload: newProgramRequestItem,
  callback
});

export const updateProgramRequestItem = (newProgramRequestItem: ProgramRequestItem, callback?: AppCallback): AppAction => ({
  type: UPDATE_PROGRAMREQUESTITEM,
  payload: newProgramRequestItem,
  callback
});

export const deleteProgramRequestItem = (programRequestItemId: number, callback?: AppCallback): AppAction => ({
  type: DELETE_PROGRAMREQUESTITEM,
  payload: programRequestItemId,
  callback
});

export const clearProgramRequestItem = () => ({
  type: CLEAR_PROGRAMREQUESTITEM,
});


