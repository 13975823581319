import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PageFilter } from '../../../state/app';
import {
  clearCustomerGroupMember,
  CustomerGroupMemberListFilter,
  CustomerGroupMemberState,
  fetchCustomerGroupMemberList,
} from '../../../state/customerGroupMember';
import { AppState } from '../../../state/configureStore';
import { Loading, PageContainer, PageTitle } from '../../components';
import CustomerGroupMemberFilter from './CustomerGroupMemberFilter';
import { useFilter } from '../../hooks';

const initialCustomerGroupMemberListFilter: CustomerGroupMemberListFilter = {};

const CustomerGroupMemberList: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useFilter<CustomerGroupMemberListFilter>(
    initialCustomerGroupMemberListFilter,
  );
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const {
    items: customerGroupMembers,
    totalItems,
    loading,
  } = useSelector<AppState, CustomerGroupMemberState>(
    (state) => state.customerGroupMember,
  );

  const handleChangeFilter = (newFilter: CustomerGroupMemberListFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchCustomerGroupMemberList(filters, paging));
    return function cleanUp() {
      dispatch(clearCustomerGroupMember());
    };
  }, [dispatch, paging, filters]);

  return (
    <PageContainer>
      <PageTitle>Customer Group Member</PageTitle>
      <CustomerGroupMemberFilter
        filters={filters}
        onChangeFilter={handleChangeFilter}
        initialFilter={initialCustomerGroupMemberListFilter}
      />
      <Loading isLoading={loading}>
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Customer Group</TableCell>
                <TableCell>Customer Id</TableCell>
                <TableCell>Customer Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerGroupMembers.map((customerGroupMember, index) => (
                <TableRow key={customerGroupMember.id}>
                  <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                  <TableCell>
                    {customerGroupMember.customerGroup!!.company!!.name}
                  </TableCell>
                  <TableCell>{customerGroupMember.customerGroup!!.name}</TableCell>
                  <TableCell>{customerGroupMember.customer!!.externalId}</TableCell>
                  <TableCell>{customerGroupMember.customer!!.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[15, 25, 35]}
            component="div"
            count={totalItems}
            rowsPerPage={paging.pageSize}
            page={paging.pageNumber}
            onPageChange={handleChangePageNumber}
            onRowsPerPageChange={handleChangePageSize}
          />
        </div>
      </Loading>
    </PageContainer>
  );
};

export default CustomerGroupMemberList;
