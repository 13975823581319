import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './sampleDevelopmentNl.style';

import { CheckBoxFastField, TextFieldFastField } from '../../../components';

interface SamDevNlSpecSubstrateInfoProps {}

const SampleDevelopmentNlSpecSubstrateInfo: React.FC<
  SamDevNlSpecSubstrateInfoProps
> = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Typography>Substrate info:</Typography>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="substrateInfoSample" label="Sample" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="substrateInfoSpecification" label="Specification" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField
            name="substrateInfoTestingStandard"
            label="Testing Standard"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="substrateInfoApplication" label="Application" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="substrateInfoApplicationData"
            label="Application"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SampleDevelopmentNlSpecSubstrateInfo;
