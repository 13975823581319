import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { Edit as EditIcon, OpenInNew as OpenInNewIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearSubtaskItem,
  fetchSubtaskItemList,
  SubtaskItemListFilter,
  SubtaskItemState,
} from '../../../state/subtaskItem';
import { PageContainer, PageTitle, TableLoading } from '../../components';
import SortLabel from '../../components/SortLabel';
import { useFilter, useTableSorting } from '../../hooks';
import TaskSubtaskItemFilter from './TaskSubtaskItemFilter';
const initialSubtaskItemFilter: SubtaskItemListFilter = {
  'SmSubtask.SmSubTaskType.Name.EqualsTo': '',
};

const TaskSubtaskItemList: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const [filters, setFilters] = useFilter<SubtaskItemListFilter>(
    initialSubtaskItemFilter,
  );

  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const [newSort, setNewSort] = useTableSorting({
    orderBy: 'SmSubtask.SMTask.taskNumber',
    direction: 'asc',
  });

  const {
    items: subtaskItems,
    totalItems,
    loading,
  } = useSelector<AppState, SubtaskItemState>((state) => state.subtaskItem);

  const handleChangeFilter = (newFilter: SubtaskItemListFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchSubtaskItemList(filters, { ...paging, sort: newSort }));
  }, [dispatch, paging, filters, newSort]);

  useEffect(() => {
    return function cleanUp() {
      dispatch(clearSubtaskItem());
    };
  }, [dispatch]);

  const editSubtask = (taskId: number, subtaskId: number): void => {
    history.push(`/tasks/form/${taskId}?subtask=${subtaskId}`);
  };

  return (
    <PageContainer>
      <PageTitle>Subtask Item</PageTitle>
      <TaskSubtaskItemFilter
        filters={filters}
        onChangeFilter={handleChangeFilter}
        initialFilter={initialSubtaskItemFilter}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="SmSubtask.SMTask.taskNumber"
              >
                Task Number
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="SmTaskItem.OpportunityItem.Opportunity.assignmentNumber"
              >
                Assignment Number
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="SmTaskItem.OpportunityItem.Opportunity.Program.Name"
              >
                Program
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="SmTaskItem.OpportunityItem.Opportunity.Brand.Name"
              >
                Brand
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="SmTaskItem.OpportunityItem.Block.BlockNo"
              >
                Block
              </SortLabel>
            </TableCell>
            <TableCell>
              <SortLabel
                sort={newSort}
                handleSortChange={setNewSort}
                field="SmTaskItem.OpportunityItem.Block.Item"
              >
                Item
              </SortLabel>
            </TableCell>
            <TableCell>Subtask Type</TableCell>
            <TableCell>Subtask Item Status</TableCell>
            <TableCell>Artwork Link</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={11}>
            {subtaskItems.map((subtaskItem, index) => {
              return (
                <TableRow key={subtaskItem.id}>
                  <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                  <TableCell>{subtaskItem.smSubtask!.smTask!.taskNumber!}</TableCell>
                  <TableCell>
                    {
                      subtaskItem.smTaskItem!.opportunityItem!.opportunity!
                        .assignmentNumber
                    }
                  </TableCell>
                  <TableCell>
                    {subtaskItem.smTaskItem!.opportunityItem!.opportunity!.program!.name}
                  </TableCell>
                  <TableCell>
                    {subtaskItem.smTaskItem!.opportunityItem!.opportunity!.brand!.name}
                  </TableCell>
                  <TableCell>
                    {subtaskItem.smTaskItem!.opportunityItem!.block!.blockNo}
                  </TableCell>
                  <TableCell>
                    {subtaskItem.smTaskItem!.opportunityItem!.block!.item}
                  </TableCell>
                  <TableCell> {subtaskItem.smSubtask!.smSubtaskType!.name}</TableCell>
                  <TableCell>{subtaskItem.status}</TableCell>
                  <TableCell>
                    {subtaskItem.smSubtask!.smSubtaskType!.code === 'ARTWORK' &&
                      subtaskItem.artworkLink && (
                        <IconButton
                          component="span"
                          onClick={() => {
                            window.open(subtaskItem.artworkLink, '_blank');
                          }}
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={() =>
                        editSubtask(
                          subtaskItem.smSubtask!.smTaskId!,
                          subtaskItem.smSubtaskId!,
                        )
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default TaskSubtaskItemList;
