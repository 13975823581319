import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './sampleDevelopmentNl.style';

import { CheckBoxFastField, TextFieldFastField } from '../../../components';

interface SamDevNlDesignSoftwareProps {}

const SampleDevelopmentNlDesignSoftware: React.FC<SamDevNlDesignSoftwareProps> = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Typography className={classes.numberTitle} display="inline">
        4. Software
      </Typography>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="softwareAcrobat" label="Acrobat" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldFastField
            name="softwareAcrobatData"
            label="Acrobat"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="softwareIllustrator" label="Illustrator" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldFastField
            name="softwareIllustratorData"
            label="Illustrator"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="softwarePageMaker" label="Page Maker" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldFastField
            name="softwarePageMakerData"
            label="Page Maker"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="softwarePhotoshop" label="Photoshop" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldFastField
            name="softwarePhotoshopData"
            label="Photoshop"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="softwareFreeHand" label="Free Hand" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldFastField
            name="softwareFreeHandData"
            label="Free Hand"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="softwareQuark" label="Quark" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldFastField
            name="softwareQuarkData"
            label="Quark"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="softwareEtc" label="Etc" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldFastField
            className={classes.textInputRightCheckbox}
            name="softwareEtcData"
            label="Etc"
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SampleDevelopmentNlDesignSoftware;
