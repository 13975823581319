import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slide,
  Typography,
} from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { IOpportunity } from '../../../../models/opportunity.model';
import { AppState } from '../../../../state/configureStore';
import ProjectComboBox from '../../../components/ProjectComboBox/ProjectComboBox';
import { clearCopyOpportunity, copyOpportunity } from './copyOpportunity.actions';
import { CopyOpportunityState, ICopyOpportunity } from './copyOpportunity.types';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  NavigateNext as NavigateNextIcon,
  Close as CloseIcon,
  DirectionsRun as DirectionsRunIcon,
  Done as DoneIcon,
  NavigateBefore as BackIcon,
} from '@material-ui/icons';

interface CopyOpportunityDialogProps {
  open: boolean;
  opportunity: IOpportunity | null;
  handleClose: () => void;
}

const CopyOpportunitySchema = Yup.object().shape({
  opportunityId: Yup.number()
    .moreThan(0, 'Assignment Number is required')
    .required('Assignment Number is required'),
  companyId: Yup.number().moreThan(0, 'Assignment Number is required'),
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

const CopyCustomerOptions = {
  COPY: 'copy',
  DO_NOT_COPY: 'doNotCopy',
};

const UseCompanyOptions = {
  EXISTING: 'existing',
  NEW: 'new',
};

const CopyOpportunityDialogSteps = {
  REVIEW_STEP: 0,
  COMPANY_OPTION_STEP: 1,
  CUSTOMER_OPTION_STEP: 2,
  RESULT_STEP: 3,
};

const CopyOpportunityDialog: React.FC<CopyOpportunityDialogProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleClose, open, opportunity } = props;
  const [step, setStep] = useState(CopyOpportunityDialogSteps.REVIEW_STEP);
  const [useCompanyOption, setUseCompanyOption] = useState('');
  const [copyCustomerOption, SetCopyCustomerOption] = useState('');
  const {
    item: successCopyOpportunity,
    errorMessage,
    loading,
  } = useSelector<AppState, CopyOpportunityState>((state) => state.copyOpportunity);

  if (!opportunity || opportunity!.smProject === null) {
    return null;
  }

  const handleChangeCompanyRadio = (event: any, setFieldValue: any) => {
    setFieldValue('company', opportunity!.company!);
    setFieldValue('companyId', opportunity!.companyId!);
    setFieldValue('smProjectId', null);
    setFieldValue('smProject', null);
    setUseCompanyOption(event.target.value);
  };

  const handleChangeCustomerRadio = (event: any) => {
    SetCopyCustomerOption(event.target.value);
  };

  const resetToDefault = () => {
    setStep(CopyOpportunityDialogSteps.REVIEW_STEP);
    setUseCompanyOption('');
    SetCopyCustomerOption('');
    dispatch(clearCopyOpportunity());
  };

  const handleSubmit = (
    values: ICopyOpportunity,
    actions: FormikHelpers<ICopyOpportunity>,
  ) => {
    const shouldCopyCustomerAndCustomerGroup = !(
      copyCustomerOption === CopyCustomerOptions.DO_NOT_COPY ||
      useCompanyOption === UseCompanyOptions.NEW
    );
    const copyCustomerObj = {
      copyCustomers: shouldCopyCustomerAndCustomerGroup,
      copyCustomerGroups: shouldCopyCustomerAndCustomerGroup,
    };
    dispatch(copyOpportunity({ ...values, ...copyCustomerObj, history }));
    actions.setSubmitting(false);
  };

  return (
    opportunity && (
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth={true}
        scroll="body"
        TransitionComponent={Transition}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            opportunity,
            opportunityId: opportunity.id!,
            company: opportunity.company!,
            companyId: opportunity.company!.id!,
            smProjectId: opportunity.smProjectId!,
            smProject: opportunity.smProject!,
          }}
          validationSchema={CopyOpportunitySchema}
          onSubmit={handleSubmit}
        >
          {({ values, submitForm, setFieldValue }) => {
            return (
              <Form>
                {step === CopyOpportunityDialogSteps.REVIEW_STEP && (
                  <>
                    <DialogTitle>Copy From Assignment</DialogTitle>
                    <DialogContent>
                      <Typography>
                        Assignment Number: {opportunity.assignmentNumber!}
                      </Typography>
                      <Typography>Project: {opportunity.smProject?.name}</Typography>
                      <Typography>Company: {opportunity.company!.name}</Typography>
                      <Typography>Program: {opportunity.program!.name}</Typography>
                      <Typography>Brand: {opportunity.brand!.name}</Typography>
                    </DialogContent>
                    <DialogActions style={{ marginRight: '5px', marginBottom: '10px' }}>
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        color="default"
                        size="small"
                        startIcon={<CloseIcon />}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          setStep(CopyOpportunityDialogSteps.COMPANY_OPTION_STEP);
                        }}
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<NavigateNextIcon />}
                      >
                        Next
                      </Button>
                    </DialogActions>
                  </>
                )}
                {step === CopyOpportunityDialogSteps.COMPANY_OPTION_STEP && (
                  <>
                    <DialogTitle>Copy Assignment Option</DialogTitle>
                    <DialogContent>
                      <FormControl component="fieldset">
                        <RadioGroup
                          name="company"
                          onChange={(e) => handleChangeCompanyRadio(e, setFieldValue)}
                          value={useCompanyOption}
                        >
                          <FormControlLabel
                            value={UseCompanyOptions.EXISTING}
                            control={<Radio />}
                            label="Use Same Company and project"
                          />
                          <FormControlLabel
                            value={UseCompanyOptions.NEW}
                            control={<Radio />}
                            label="Copy to another company or project"
                          />
                        </RadioGroup>
                        {useCompanyOption === UseCompanyOptions.NEW && (
                          <Typography>
                            Using this option will not Copy Customers and Customer Groups
                            to new Assignment.
                          </Typography>
                        )}
                      </FormControl>
                      {useCompanyOption === UseCompanyOptions.NEW && (
                        <ProjectComboBox
                          forceFilter={{}}
                          selectedValue={values.smProject}
                          handleChange={(result) => {
                            setFieldValue('company', result ? result.company : null);
                            setFieldValue('companyId', result ? result.companyId : null);
                            setFieldValue('smProjectId', result ? result.id : 0);
                            setFieldValue('smProject', result ? result : 0);
                          }}
                        />
                      )}
                    </DialogContent>
                    <DialogActions style={{ marginRight: '5px', marginBottom: '10px' }}>
                      <Button
                        onClick={() => {
                          setStep(CopyOpportunityDialogSteps.REVIEW_STEP);
                        }}
                        variant="contained"
                        color="default"
                        size="small"
                        startIcon={<BackIcon />}
                      >
                        Back
                      </Button>
                      <Button
                        onClick={() => {
                          if (useCompanyOption === UseCompanyOptions.NEW) {
                            submitForm();
                            setStep(CopyOpportunityDialogSteps.RESULT_STEP);
                          } else if (useCompanyOption !== '') {
                            setStep(CopyOpportunityDialogSteps.CUSTOMER_OPTION_STEP);
                          }
                        }}
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<NavigateNextIcon />}
                      >
                        {useCompanyOption === UseCompanyOptions.NEW ? 'Confirm' : 'Next'}
                      </Button>
                    </DialogActions>
                  </>
                )}
                {step === CopyOpportunityDialogSteps.CUSTOMER_OPTION_STEP &&
                  useCompanyOption === UseCompanyOptions.EXISTING && (
                    <>
                      <DialogTitle>
                        Do you want to Copy Customer and Customer Groups?
                      </DialogTitle>
                      <DialogContent>
                        <FormControl component="fieldset">
                          <RadioGroup
                            name="customer"
                            onChange={handleChangeCustomerRadio}
                            value={copyCustomerOption}
                          >
                            <FormControlLabel
                              value={CopyCustomerOptions.COPY}
                              control={<Radio />}
                              label="Copy all"
                            />
                            <FormControlLabel
                              value={CopyCustomerOptions.DO_NOT_COPY}
                              control={<Radio />}
                              label="Do not Copy"
                            />
                          </RadioGroup>
                        </FormControl>
                      </DialogContent>
                      <DialogActions style={{ marginRight: '5px', marginBottom: '10px' }}>
                        <Button
                          onClick={() => {
                            setStep(CopyOpportunityDialogSteps.COMPANY_OPTION_STEP);
                          }}
                          variant="contained"
                          color="default"
                          size="small"
                          startIcon={<BackIcon />}
                        >
                          Back
                        </Button>
                        <Button
                          onClick={() => {
                            if (copyCustomerOption !== '') {
                              submitForm();
                              setStep(CopyOpportunityDialogSteps.RESULT_STEP);
                            }
                          }}
                          variant="contained"
                          color="primary"
                          size="small"
                          startIcon={<DoneIcon />}
                        >
                          Confirm
                        </Button>
                      </DialogActions>
                    </>
                  )}
                {step === CopyOpportunityDialogSteps.RESULT_STEP && (
                  <>
                    <DialogTitle>Result</DialogTitle>
                    {loading && (
                      <DialogContent>
                        <Typography>Copying...</Typography>
                      </DialogContent>
                    )}
                    {!loading && (
                      <>
                        <DialogContent>
                          <Typography>
                            {errorMessage
                              ? `Error occurs, please try again \n ${errorMessage}`
                              : `Your New Assignment is ${successCopyOpportunity.assignmentNumber!}`}
                          </Typography>
                        </DialogContent>
                        <DialogActions
                          style={{ marginRight: '5px', marginBottom: '10px' }}
                        >
                          {!errorMessage && (
                            <Button
                              onClick={() => {
                                history.push(
                                  `/assignments/form/${successCopyOpportunity.id!}`,
                                );
                                resetToDefault();
                              }}
                              color="primary"
                              variant="contained"
                              size="small"
                              startIcon={<DirectionsRunIcon />}
                            >
                              Go to Your New Assignment
                            </Button>
                          )}
                          <Button
                            onClick={() => {
                              handleClose();
                              resetToDefault();
                            }}
                            variant="contained"
                            color="default"
                            size="small"
                            startIcon={<CloseIcon />}
                          >
                            Close
                          </Button>
                        </DialogActions>
                      </>
                    )}
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    )
  );
};

export default CopyOpportunityDialog;
