import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { SearchField, TableLoading } from '../../components';
import {
  ProgramListFilter,
  Program,
  ProgramState,
  clearProgram,
  fetchProgramList,
} from '../../../state/program';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import { createProjectPrograms } from '../../../state/projectProgram';
import { TablePageSize } from '../../../constants';
import { uniqBy } from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      minHeight: '53vh',
    },
    filterContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

interface AddProjectProgramDialogProps {
  open: boolean;
  handleClose: () => void;
  smProjectId: number;
}

const initialPaging = { pageNumber: 0, pageSize: TablePageSize[0] };

const AddProjectProgramDialog: React.FC<AddProjectProgramDialogProps> = (props) => {
  const { open, handleClose, smProjectId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    items: programList,
    totalItems,
    loading,
  } = useSelector<AppState, ProgramState>((state) => state.program);
  const [filters, setFilters] = useState<ProgramListFilter>({});
  const [paging, setPaging] = useState<PageFilter>(initialPaging);
  const [selectedPrograms, setSelectedPrograms] = useState<Program[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    setSelectAll(false);
  }, [filters, paging]);

  useEffect(() => {
    setFilters((filters) => {
      return { ...filters };
    });
  }, [setFilters]);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const resetForm = () => {
    handleClose();
    setSelectedPrograms([]);
    setPaging({ ...initialPaging });
    setFilters({});
  };

  const handleSubmit = () => {
    const submitValue = selectedPrograms.map((selectedProgram, index) => {
      return {
        programId: selectedProgram.id,
        smProjectId: smProjectId,
      };
    });

    dispatch(createProjectPrograms(submitValue, resetForm));
  };

  useEffect(() => {
    dispatch(fetchProgramList(filters, paging));
    return function cleanUp() {
      dispatch(clearProgram());
    };
  }, [dispatch, paging, filters]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth={true}
      disableBackdropClick={true}
    >
      <DialogTitle>Select Program</DialogTitle>
      <DialogContent>
        <div className={classes.filterContainer}>
          <SearchField
            label="Name"
            onChange={handleChangeFilter}
            filterKey="Name.startsWith"
            filters={filters}
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  onChange={(event: React.ChangeEvent<HTMLInputElement>, checked) => {
                    if (checked) {
                      const newList = [...selectedPrograms].concat(programList);
                      setSelectedPrograms(uniqBy(newList, (c) => c.id!));
                    } else {
                      const isInCurrentList = (program: Program): boolean =>
                        !programList.find((c) => c.id === program.id);
                      setSelectedPrograms(selectedPrograms.filter(isInCurrentList));
                    }
                    setSelectAll(checked);
                  }}
                  checked={selectAll}
                />
              </TableCell>
              <TableCell>No.</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={10}>
              {programList.map((program, index) => {
                const isCheckboxChecked =
                  selectedPrograms.findIndex(
                    (selectedProgram) => selectedProgram.id === program.id,
                  ) !== -1;
                return (
                  <TableRow key={program.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked,
                        ) => {
                          if (checked) {
                            setSelectedPrograms([...selectedPrograms, program]);
                          } else {
                            setSelectedPrograms(
                              selectedPrograms.filter((c) => c.id !== program.id),
                            );
                          }
                        }}
                        checked={isCheckboxChecked}
                      />
                    </TableCell>
                    <TableCell>
                      {index + paging.pageSize * paging.pageNumber + 1}
                    </TableCell>
                    <TableCell>{program.name}</TableCell>
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[...TablePageSize]}
          component="div"
          count={totalItems}
          rowsPerPage={paging.pageSize}
          page={paging.pageNumber}
          onPageChange={handleChangePageNumber}
          onRowsPerPageChange={handleChangePageSize}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Confirm
        </Button>
        <Button
          onClick={() => {
            resetForm();
          }}
          color="default"
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProjectProgramDialog;
