import {
  Button,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AuthState } from '../../../state/auth';
import { BlockState, clearBlock, fetchBlock } from '../../../state/block';
import { AppState } from '../../../state/configureStore';
import {
  Loading,
  PageContainer,
  PageTitle,
  FixedPositionButtons,
} from '../../components';
import EBlockFormDetail from './EBlockFormDetail';

interface EBlockFormRouteParamsProps {
  id: string;
}

interface EBlockFormProps extends RouteComponentProps<EBlockFormRouteParamsProps> {}

const EBlockForm: React.FC<EBlockFormProps> = props => {
  const dispatch = useDispatch();
  const { user, loading: authIsLoading } = useSelector<AppState, AuthState>(
    state => state.auth,
  );
  const { item: block, loading: eBlockIsLoading } = useSelector<AppState, BlockState>(
    state => state.block,
  );
  const isLoading = authIsLoading || eBlockIsLoading;
  const { match, history } = props;
  const {
    params: { id: paramsId },
  } = match;
  const isUpdateMode = paramsId !== undefined;

  useEffect(() => {
    if (isUpdateMode) {
      dispatch(fetchBlock(+paramsId));
    }
    return function cleanUp() {
      dispatch(clearBlock());
    };
  }, [isUpdateMode, paramsId, dispatch]);

  const redirectToSalesGroupList = () => {
    history.goBack();
  };

  return (
    <PageContainer>
      <Loading isLoading={isLoading}>
        <PageTitle>Update EBlock</PageTitle>
        <CardContent>
          <TextField
            name="eBlockNumber"
            type="input"
            label="EBlock Number"
            variant="outlined"
            margin="dense"
            value={block.blockNo}
            fullWidth
          />
          <TextField
            name="eBlockGroupProduct"
            type="input"
            label="Group Product"
            variant="outlined"
            margin="dense"
            value={
              block.eBlockGroupProduct
                ? `${block.eBlockGroupProduct!.code}: ${
                    block.eBlockGroupProduct!.description
                  }`
                : ''
            }
            fullWidth
          />
          <TextField
            name="eBlockMaterial"
            type="input"
            label="Material"
            variant="outlined"
            margin="dense"
            value={
              block.eBlockMaterial
                ? `${block.eBlockMaterial!.code}: ${block.eBlockMaterial!.description}`
                : ''
            }
            fullWidth
          />
          <TextField
            name="eBlockSize"
            type="input"
            label="Size"
            variant="outlined"
            margin="dense"
            value={
              block.eBlockSize
                ? `${block.eBlockSize!.code}: ${block.eBlockSize!.description}`
                : ''
            }
            fullWidth
          />
          <TextField
            name="eBlockPattern"
            type="input"
            label="Pattern"
            variant="outlined"
            margin="dense"
            value={
              block.eBlockPattern
                ? `${block.eBlockPattern!.code}: ${block.eBlockPattern!.description}`
                : ''
            }
            fullWidth
          />

          <EBlockFormDetail blockId={+paramsId} />
        </CardContent>
        <FixedPositionButtons
          actions={[
            {
              name: 'Close',
              onClick: redirectToSalesGroupList,
              color: 'default' as 'default',
              disabled: false,
            },
          ]}
          open
        />
      </Loading>
    </PageContainer>
  );
};

export default EBlockForm;
