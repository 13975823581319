import { PageFilter } from '../state/app';
import { Block, BlockListFilter } from '../state/block';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';
import axios from '../utils/axios';
import { BlockCreate } from '../views/components/BlockCreateDialog/BlockCreateDialog.types';

const baseUrl = `/api/eBlocks`;

export const fetchEBlockList = async ({
  filters,
  paging,
}: {
  filters: BlockListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const createEBlock = async (block: Block | BlockCreate) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, block);
};
