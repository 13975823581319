import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import {
  createResponsibilityBrand,
  deleteResponsibilityBrand,
  fetchResponsibilityBrand,
  fetchResponsibilityBrandList,
  inactiveResponsibilityBrand,
  updateResponsibilityBrand,
  replaceResponsibilityBrand,
} from '../../services/responsibilityBrandService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_RESPONSIBILITY_BRAND,
  DELETE_RESPONSIBILITY_BRAND,
  FETCH_RESPONSIBILITY_BRAND,
  FETCH_RESPONSIBILITY_BRAND_LIST,
  CREATE_RESPONSIBILITY_BRANDS,
  fetchResponsibilityBrandListByresponsibilityId,
  UPDATE_RESPONSIBILITY_BRAND,
  REPLACE_RESPONSIBILITY_BRAND,
  INACTIVE_RESPONSIBILITY_BRAND,
} from './responsibilityBrand.actions';
import {
  ResponsibilityBrandCreateOrUpdatePayload,
  ResponsibilityBrandReplacePayLoad,
} from './responsibilityBrand.types';
import { AppState } from '../configureStore';
import { IResponsibilityBrand } from '../../models/responsibilityBrand.model';

function* fetchResponsibilityBrandSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchResponsibilityBrand, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchResponsibilityBrandListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchResponsibilityBrandList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createResponsibilityBrandSaga(action: AppAction) {
  try {
    const { values } = action.payload as ResponsibilityBrandCreateOrUpdatePayload;
    const successMessage = `Created responsibility brand successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createResponsibilityBrand, values);
    yield put(fetchResponsibilityBrandListByresponsibilityId(data.responsibilityId));
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createResponsibilityBrandsSaga(action: AppAction) {
  try {
    const { payload, callback } = action;

    yield put({ type: REQUEST(action.type) });

    for (const opCustGrp of payload) {
      yield call(createResponsibilityBrand, opCustGrp);
    }

    const successMessage = `Created responsibility brand successfully.`;
    yield put(
      fetchResponsibilityBrandListByresponsibilityId(payload[0].responsibilityId),
    );

    yield put({ type: SUCCESS(action.type), payload });

    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));

    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateResponsibilityBrandSaga(action: AppAction) {
  try {
    const { values } = action.payload as ResponsibilityBrandCreateOrUpdatePayload;
    const successMessage = `Updated responsibility brand successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateResponsibilityBrand, values);
    yield put(fetchResponsibilityBrandListByresponsibilityId(data.responsibilityId));
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* deleteResponsibilityBrandSaga(action: AppAction) {
  const { payload } = action;
  const { id } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    yield call(deleteResponsibilityBrand, id);
    const responsibility = yield select(getResponsibilityFromState);
    if (responsibility.id) {
      yield put(fetchResponsibilityBrandListByresponsibilityId(responsibility.id));
    }
    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Deleted successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* inactiveResponsibilityBrandSaga(action: AppAction) {
  const { payload } = action;
  const { id } = payload;
  try {
    yield put({ type: REQUEST(action.type) });
    yield call(inactiveResponsibilityBrand, id);
    const responsibility = yield select(getResponsibilityFromState);
    if (responsibility.id) {
      yield put(fetchResponsibilityBrandListByresponsibilityId(responsibility.id));
    }
    yield put({ type: SUCCESS(action.type) });
    const successMessage = `Inactive successfully.`;
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function getResponsibilityFromState(state: AppState): IResponsibilityBrand {
  const { item } = state.responsibility;
  return item;
}

function* replaceResponsibilityBrandSaga(action: AppAction) {
  try {
    const values = action.payload as ResponsibilityBrandReplacePayLoad;
    const successMessage = `Replace Brand successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(replaceResponsibilityBrand, values);
    const responsibility = yield select(getResponsibilityFromState);
    if (responsibility.id) {
      yield put(fetchResponsibilityBrandListByresponsibilityId(responsibility.id));
    }
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchResponsibilityBrand() {
  yield takeLatest(FETCH_RESPONSIBILITY_BRAND, fetchResponsibilityBrandSaga);
  yield debounce(250, FETCH_RESPONSIBILITY_BRAND_LIST, fetchResponsibilityBrandListSaga);
  yield takeLatest(CREATE_RESPONSIBILITY_BRAND, createResponsibilityBrandSaga);
  yield takeLatest(UPDATE_RESPONSIBILITY_BRAND, updateResponsibilityBrandSaga);
  yield takeLatest(CREATE_RESPONSIBILITY_BRANDS, createResponsibilityBrandsSaga);
  yield takeLatest(DELETE_RESPONSIBILITY_BRAND, deleteResponsibilityBrandSaga);
  yield takeLatest(REPLACE_RESPONSIBILITY_BRAND, replaceResponsibilityBrandSaga);
  yield takeLatest(INACTIVE_RESPONSIBILITY_BRAND, inactiveResponsibilityBrandSaga);
}
