import { Button, useMediaQuery, useTheme } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import React from 'react';

interface IFilterButtonProps {
  onClick: () => void;
  [key: string]: any;
}

const FilterButton: React.FC<IFilterButtonProps> = props => {
  const { onClick, ...rest } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (!isSmallScreen) {
    return null;
  }

  return <Button
    variant="contained"
    color="primary"
    onClick={onClick}
    startIcon={<FilterList/>}
    {...rest}
  >
    Filter
  </Button>;
};

export default FilterButton;
