import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './sampleDevelopmentNl.style';
import { CheckBoxFastField } from '../../../components';

interface SamDevNlDesignFileTypeProps {}

const SampleDevelopmentNlDesignFileType: React.FC<SamDevNlDesignFileTypeProps> = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Typography className={classes.numberTitle} display="inline">
        3. File Type
      </Typography>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="fileTypeTiff" label="TIFF" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="fileTypeEps" label="EPS" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="fileTypeJpg" label="JPG" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="fileTypePdf" label="PDF" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="fileTypeAi" label="AI" />
        </Grid>
        <Grid item xs={12} md={2}>
          <CheckBoxFastField name="fileTypeQxp" label="QXP" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SampleDevelopmentNlDesignFileType;
