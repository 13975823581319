import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearSampleDevelopmentTl,
  fetchSampleDevelopmentTlList,
  SampleDevelopmentTlState,
  SampleDevelopmentTlListFilter,
} from '../../../state/sampleDevelopmentTl';
import {
  PageContainer,
  PageTitle,
  TableLoading,
  PageButtonGroup,
  DateTimeDisplay,
  SortLabel,
} from '../../components';
import { useFilter, useTableSorting } from '../../hooks';
import { SampleDevelopmentStatusLabel } from '../../../constants/sampleDevelopmentStatus';
import SampleDevelopmentTlFilter from './SampleDevelopmentTlFilter';

const initialSampleDevelopmentTlListFilter: SampleDevelopmentTlListFilter = {
  'Status.equalsTo': '',
};

const SampleDevelopmentTlList: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const [filters, setFilters] = useFilter<SampleDevelopmentTlListFilter>(
    initialSampleDevelopmentTlListFilter,
  );

  const [sort, setSort] = useTableSorting({
    orderBy: 'sampleDevNumber',
    direction: 'desc',
  });

  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const {
    items: sampleDevelopmentTlList,
    totalItems,
    loading,
  } = useSelector<AppState, SampleDevelopmentTlState>(
    (state) => state.sampleDevelopmentTl,
  );

  const handleChangeFilter = (newFilter: SampleDevelopmentTlListFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchSampleDevelopmentTlList(filters, { ...paging, sort }));
    return function cleanUp() {
      dispatch(clearSampleDevelopmentTl());
    };
  }, [dispatch, paging, filters, sort]);

  const addNewSampleDevelopmentTl = (): void => {
    history.push('/sampleDevelopmentTl/form');
  };

  const editSampleDevelopmentTl = (id: number): void => {
    history.push(`/sampleDevelopmentTl/form/${id}`);
  };

  return (
    <PageContainer>
      <PageTitle>Sample Development TL</PageTitle>
      <SampleDevelopmentTlFilter
        filters={filters}
        onChangeFilter={handleChangeFilter}
        initialFilter={initialSampleDevelopmentTlListFilter}
      />
      <PageButtonGroup>
        <Button
          variant="contained"
          color="primary"
          onClick={addNewSampleDevelopmentTl}
          startIcon={<AddIcon />}
        >
          New Sample Development TL
        </Button>
      </PageButtonGroup>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>
              <SortLabel sort={sort} handleSortChange={setSort} field="sampleDevNumber">
                Sample Development Number
              </SortLabel>
            </TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Program</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Block</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={3}>
            {sampleDevelopmentTlList.map((sampleDevelopmentTl, index) => (
              <TableRow key={sampleDevelopmentTl.id}>
                <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                <TableCell>{sampleDevelopmentTl.sampleDevNumber}</TableCell>
                <TableCell>
                  {SampleDevelopmentStatusLabel[sampleDevelopmentTl.status!]}
                </TableCell>
                <TableCell>{sampleDevelopmentTl.block!.program!.name}</TableCell>
                <TableCell>{sampleDevelopmentTl.block!.brand!.name}</TableCell>
                <TableCell>{sampleDevelopmentTl.block!.item}</TableCell>
                <TableCell>{sampleDevelopmentTl.block!.blockNo}</TableCell>
                <TableCell>
                  <DateTimeDisplay value={sampleDevelopmentTl.createdAt} />
                </TableCell>
                <TableCell>{sampleDevelopmentTl.createdBy}</TableCell>
                <TableCell>
                  <Tooltip title={'Edit'}>
                    <span>
                      <IconButton
                        size="small"
                        onClick={() => editSampleDevelopmentTl(sampleDevelopmentTl.id!)}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default SampleDevelopmentTlList;
