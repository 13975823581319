import { AppAction, PageFilter } from '../app';
import { NotificationUserFilter } from './notificationUser.types';

export const FETCH_NOTIFICATION_USER = 'FETCH_NOTIFICATION_USER';
export const CLEAR_NOTIFICATION_USER = 'CLEAR_NOTIFICATION_USER';

export const fetchNotificationUser = (
  filters: NotificationUserFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_NOTIFICATION_USER,
    payload: {
      filters,
      paging,
    },
  };
};

export const clearNotificationUser = () => ({
  type: CLEAR_NOTIFICATION_USER,
});
