import { call, put, takeLatest } from 'redux-saga/effects';
import { copyOpportunity } from '../../../../services/opportunityService';
import { AppAction } from '../../../../state/app';
import { FAILURE, REQUEST, SUCCESS } from '../../../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../../../views/components/Notification';
import { COPY_OPPORTUNITY } from './copyOpportunity.actions';

function* copyOpportunitySaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const successMessage = `Copy Assignment successfully.`;
    const { data } = yield call(copyOpportunity, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchCopyOpportunity() {
  yield takeLatest(COPY_OPPORTUNITY, copyOpportunitySaga);
}
