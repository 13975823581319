import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_RESPONSIBILITY_APPROVAL_REQUEST,
  CREATE_RESPONSIBILITY_APPROVAL_REQUEST,
  FETCH_RESPONSIBILITY_APPROVAL_REQUEST,
  FETCH_RESPONSIBILITY_APPROVAL_REQUEST_LIST,
  UPDATE_RESPONSIBILITY_APPROVAL_REQUEST,
  UPDATE_RESPONSIBILITY_APPROVAL_RESPONSE,
  OPEN_RESPONSIBILITY_APPROVAL_REQUEST_DIALOG,
  CLOSE_RESPONSIBILITY_APPROVAL_REQUEST_DIALOG,
} from './responsibilityApprovalRequest.actions';
import {
  ResponsibilityApprovalRequest,
  ResponsibilityApprovalRequestState,
} from './responsibilityApprovalRequest.types';

const getInitialResponsibilityApprovalRequestState = (): ResponsibilityApprovalRequest => ({
  id: 0,
  hasResponded: false,
  responsibility: {},
  requestedByUser: {
    id: 0,
    firstName: '',
    lastName: '',
    activated: true,
    email: '',
    login: '',
  },
  comment: '',
});

const initialState: ResponsibilityApprovalRequestState = {
  errorMessage: '',
  item: getInitialResponsibilityApprovalRequestState(),
  items: [],
  loading: false,
  totalItems: 0,
  openDialog: false,
};

export default (
  state: ResponsibilityApprovalRequestState = initialState,
  action: AppAction,
): ResponsibilityApprovalRequestState => {
  switch (action.type) {
    case REQUEST(FETCH_RESPONSIBILITY_APPROVAL_REQUEST_LIST):
    case REQUEST(CREATE_RESPONSIBILITY_APPROVAL_REQUEST):
    case REQUEST(FETCH_RESPONSIBILITY_APPROVAL_REQUEST):
    case REQUEST(UPDATE_RESPONSIBILITY_APPROVAL_REQUEST):
    case REQUEST(UPDATE_RESPONSIBILITY_APPROVAL_RESPONSE):
      return { ...state, loading: true };
    case SUCCESS(FETCH_RESPONSIBILITY_APPROVAL_REQUEST_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_RESPONSIBILITY_APPROVAL_REQUEST_LIST):
    case FAILURE(CREATE_RESPONSIBILITY_APPROVAL_REQUEST):
    case FAILURE(FETCH_RESPONSIBILITY_APPROVAL_REQUEST):
    case FAILURE(UPDATE_RESPONSIBILITY_APPROVAL_REQUEST):
    case FAILURE(UPDATE_RESPONSIBILITY_APPROVAL_RESPONSE):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_RESPONSIBILITY_APPROVAL_REQUEST):
    case SUCCESS(FETCH_RESPONSIBILITY_APPROVAL_REQUEST):
    case SUCCESS(UPDATE_RESPONSIBILITY_APPROVAL_REQUEST):
      return { ...state, loading: false, item: action.payload.data };
    case SUCCESS(UPDATE_RESPONSIBILITY_APPROVAL_RESPONSE):
      return { ...state, loading: false };
    case CLEAR_RESPONSIBILITY_APPROVAL_REQUEST:
      return {
        ...state,
        ...initialState,
      };
    case OPEN_RESPONSIBILITY_APPROVAL_REQUEST_DIALOG:
      return {
        ...state,
        openDialog: true,
      };
    case CLOSE_RESPONSIBILITY_APPROVAL_REQUEST_DIALOG:
      return {
        ...state,
        openDialog: false,
      };
    default:
      return state;
  }
};
