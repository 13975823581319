/**
 * Appends REQUEST async action type
 */

const REQUEST_SUFFIX = '_PENDING';

export const REQUEST: (actionType: string) => string = actionType =>
  `${actionType}${REQUEST_SUFFIX}`;

/**
 * Appends SUCCESS async action type
 */

export const SUCCESS: (actionType: string) => string = actionType =>
  `${actionType}_FULFILLED`;

/**
 * Appends FAILURE async action type
 */

export const FAILURE: (actionType: string) => string = actionType =>
  `${actionType}_REJECTED`;

export const isPendingAction: (actionType: string) => boolean = actionType =>
  actionType.endsWith(REQUEST_SUFFIX);
