import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PageFilter } from '../../../state/app';
import {
  clearOpportunityCustomerGroup,
  fetchOpportunityCustomerGroupList,
  OpportunityCustomerGroupListFilter,
  OpportunityCustomerGroupState,
} from '../../../state/opportunityCustomerGroup';
import { AppState } from '../../../state/configureStore';
import {
  OpportunityLinkButton,
  PageContainer,
  PageTitle,
  TableLoading,
  ChipList,
} from '../../components';
import OpportunityCustomerGroupFilter from './OpportunityCustomerGroupFilter';
import { useFilter } from '../../hooks';

const initialOpportunityCustomerGroupFilter: OpportunityCustomerGroupListFilter = {};

const OpportunityCustomerGroupList: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useFilter<OpportunityCustomerGroupListFilter>(
    initialOpportunityCustomerGroupFilter,
  );
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const {
    items: opportunityCustomerGroups,
    totalItems,
    loading,
  } = useSelector<AppState, OpportunityCustomerGroupState>(
    (state) => state.opportunityCustomerGroup,
  );

  const handleChangeFilter = (newFilter: OpportunityCustomerGroupListFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchOpportunityCustomerGroupList(filters, paging));
    return function cleanUp() {
      dispatch(clearOpportunityCustomerGroup());
    };
  }, [dispatch, paging, filters]);

  return (
    <PageContainer>
      <PageTitle>Assignment Customer Group</PageTitle>
      <OpportunityCustomerGroupFilter
        filters={filters}
        onChangeFilter={handleChangeFilter}
        initialFilter={initialOpportunityCustomerGroupFilter}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Assignment Number</TableCell>
            <TableCell>Our Company</TableCell>
            <TableCell>Program</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>Sales</TableCell>
            <TableCell>CS</TableCell>
            <TableCell>Customer Group</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={7}>
            {opportunityCustomerGroups.map((opportunityCustomerGroup, index) => {
              const salesNames =
                opportunityCustomerGroup.opportunity?.responsibility?.responsibilitySalesTeamMembers
                  ?.filter((rs) => rs.active === true)
                  ?.map((rs) => {
                    return rs.salesTeamMember?.user?.fullNameWithUserName!;
                  })!;
              const csNames =
                opportunityCustomerGroup.opportunity?.responsibility?.responsibilityWorkingTeamMembers
                  ?.filter(
                    (rc) =>
                      rc.active === true &&
                      rc.smWorkingTeamMember?.smWorkingTeam?.smWorkingGroup?.name ===
                        'CS',
                  )
                  ?.map((rs) => {
                    return rs.smWorkingTeamMember?.user?.fullNameWithUserName!;
                  })!;
              return (
                <TableRow key={opportunityCustomerGroup.id}>
                  <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                  <TableCell>
                    <OpportunityLinkButton
                      opportunity={opportunityCustomerGroup.opportunity!!}
                    />
                  </TableCell>
                  <TableCell>
                    {opportunityCustomerGroup.opportunity!!.company!!.name}
                  </TableCell>
                  <TableCell>
                    {opportunityCustomerGroup.opportunity!!.program!!.name}
                  </TableCell>
                  <TableCell>
                    {opportunityCustomerGroup.opportunity!!.brand!!.name}
                  </TableCell>
                  <TableCell>
                    <ChipList list={salesNames} noAllLabel />
                  </TableCell>
                  <TableCell>
                    <ChipList list={csNames} noAllLabel />
                  </TableCell>
                  <TableCell>{opportunityCustomerGroup.customerGroup!!.name}</TableCell>
                </TableRow>
              );
            })}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default OpportunityCustomerGroupList;
