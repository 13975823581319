import React from 'react';
import { Grid } from '@material-ui/core';
import { FilterContainer, SearchField } from '../../components';
import { IFilterContainerConfigProps } from '../../components/FilterContainer';
import { SalesTeamMemberListFilter } from '../../../state/salesTeamMember';

const SalesTeamMemberFilter: React.FC<
  IFilterContainerConfigProps<SalesTeamMemberListFilter>
> = (props) => {
  return (
    <FilterContainer
      {...props}
      render={(filterProps) => {
        const { localFilter, handleChangeFilter } = filterProps;
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="SalesTeam.Name.startsWith"
                label="Sales Team"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="User.FirstName.startsWith"
                label="First Name"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="User.LastName.startsWith"
                label="Last Name"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                filterKey="SalesTeamRole.Name.startsWith"
                label="Role"
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default SalesTeamMemberFilter;
