import { PageFilter } from '../state/app';
import {
  SampleDevelopmentHlApprovalRequest,
  SampleDevelopmentHlApprovalRequestListFilter,
} from '../state/sampleDevelopmentHlApprovalRequest/sampleDevelopmentHlApprovalRequest.types';
import axios from '../utils/axios';
const baseUrl = `/api/sampleDevelopmentHlApprovalRequests`;

export const fetchSampleDevelopmentHlApprovalRequestList = async ({
  filters,
  paging,
}: {
  filters: SampleDevelopmentHlApprovalRequestListFilter;
  paging: PageFilter;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const updateSampleDevelopmentHlApprovalRequesResponse = async (
  sampleDevelopmentHlApprovalRequest: SampleDevelopmentHlApprovalRequest,
) => {
  const requestUrl = `${baseUrl}/response`;
  return await axios.put(requestUrl, sampleDevelopmentHlApprovalRequest);
};
