import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ISubtaskItem } from '../../../models/subtaskItem.model';
import { useDispatch } from 'react-redux';
import {
  clearSubtaskItemMoq,
  createMultipleSubtaskItemMoqList,
} from '../../../state/subtaskItemMoq';
import { ISubtaskItemMoq } from '../../../models/subtaskItemMoq.model';
import { IMoq, IMoqWithAddedProps } from '../../../models/moq.model';
import MoqTable from './MoqTable';

interface MultipleSubtaskItemMoqEditDialogProps {
  open: boolean;
  subtaskItems: ReadonlyArray<ISubtaskItem>;
  onClose: () => void;
  selectedSubtaskItemOnTable: ISubtaskItem;
}

const defaultNewRowValue: IMoq = {
  price: '',
  quantity: '',
  unitOfQuantity: '',
  unitOfPrice: '',
};

const MultipleSubtaskItemMoqEditDialog: React.FC<
  MultipleSubtaskItemMoqEditDialogProps
> = (props) => {
  const { open, subtaskItems, onClose, selectedSubtaskItemOnTable } = props;
  const [moqs, setMoqs] = useState<IMoqWithAddedProps[]>([]);

  const dispatch = useDispatch();

  const handleUpdate = (createMoqList: IMoq[]) => {
    const mapCreateMoqList = createMoqList.map((moq) => ({ ...moq, added: true }));
    setMoqs(mapCreateMoqList);

    const createMultipleSubtaskItemMoqListPayload = {
      subtaskItems: [...subtaskItems] as ISubtaskItem[],
      multipleSubtaskItemMoqList: mapCreateMoqList as ISubtaskItemMoq[],
      selectedSubtaskItemOnTableId: selectedSubtaskItemOnTable.id!,
    };
    dispatch(createMultipleSubtaskItemMoqList(createMultipleSubtaskItemMoqListPayload));
  };

  const handleClose = () => {
    setMoqs([]);
    onClose();
  };

  useEffect(() => {
    return function cleanUp() {
      dispatch(clearSubtaskItemMoq());
    };
  }, [dispatch]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Edit Multiple MOQ</DialogTitle>
      <DialogContent>
        <MoqTable
          defaultNewRowValue={defaultNewRowValue}
          onUpdate={handleUpdate}
          moqList={moqs}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MultipleSubtaskItemMoqEditDialog;
