import {
  AppBar,
  Avatar,
  Button,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close as CloseIcon, GetApp as GetAppIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { SubtaskItemStatusLabel } from '../../../../../constants';
import { ISubtaskItemWorkDurationReport } from '../../../../../models/report.model';
import { downloadSubtaskItemDurationReport } from '../../../../../services/reportService';
import { PageFilter } from '../../../../../state/app';
import { AppState } from '../../../../../state/configureStore';
import {
  clearReport,
  fetchSubtaskItemDurationReportList,
  ISubtaskItemDurationFilter,
  ReportState,
} from '../../../../../state/report';
import {
  PageContainer,
  PageTitle,
  SortLabel,
  TableLoading,
  UserLetterAvatar,
} from '../../../../components';
import { DateTimeDisplay } from '../../../../components/DateTimeDisplay';
import { useFilter, useTableSorting } from '../../../../hooks';
import SubtaskItemWorkDurationFilterComponent from './SubtaskItemDurationReportFilter';
import useStyles from './SubtaskItemDurationReportStyle';

interface SubtaskItemDurationReportDialogProps {
  open: boolean;
  handleClose: () => void;
}
const Transition = React.forwardRef(function transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

const SubtaskItemDurationReportDialog: React.FC<SubtaskItemDurationReportDialogProps> = (
  props,
) => {
  const classes = useStyles();
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const initialSubtaskItemWorkDurationFilter: ISubtaskItemDurationFilter = {
    'SmSubtaskItem.SmSubtask.SmSubTaskType.Code.EqualsTo': '',
    'StartDate.GreaterThanOrEqual': null,
    'EndDate.LessThanOrEqual': null,
  };
  const {
    loading,
    items: subtaskItemDurationReportList,
    totalItems,
  } = useSelector<AppState, ReportState>((state) => state.report);

  const [filters, setFilters] = useFilter<ISubtaskItemDurationFilter>(
    initialSubtaskItemWorkDurationFilter,
    true,
  );

  const [sort, setSort] = useTableSorting(
    {
      orderBy: 'SmSubtaskItem.SmSubtask.SMTask.TaskNumber',
      direction: 'desc',
    },
    true,
  );

  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  useEffect(() => {
    if (open) {
      dispatch(fetchSubtaskItemDurationReportList(filters, { ...paging, sort }));
    }
    return function cleanUp() {
      dispatch(clearReport());
    };
  }, [dispatch, paging, filters, open, sort]);

  const handleChangeFilter = (newFilter: ISubtaskItemDurationFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };
  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const exportExcel = () => {
    downloadSubtaskItemDurationReport(filters, { pageSize: totalItems, sort }).catch(
      (err) => console.error(err),
    );
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Subtask Item Duration
          </Typography>
        </Toolbar>
      </AppBar>
      <PageContainer>
        <PageTitle>Subtask Item Duration</PageTitle>
        <SubtaskItemWorkDurationFilterComponent
          filters={filters}
          onChangeFilter={handleChangeFilter}
          initialFilter={initialSubtaskItemWorkDurationFilter}
        />
        {!isSmallScreen && (
          <div className={classes.filterContainer}>
            <Button
              variant={'contained'}
              className={classes.button}
              startIcon={<GetAppIcon />}
              onClick={exportExcel}
            >
              Export Excel
            </Button>
          </div>
        )}
        {open && (
          <Grid container spacing={3} className={classes.container}>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmSubtaskItem.SmSubtask.SMTask.TaskNumber"
                      >
                        Tasks Number
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmSubtaskItem.SmSubtask.SmSubTaskType.Code"
                      >
                        Subtask Type
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmSubtaskItem.SmTaskItem.OpportunityItem.Block.Program.Name"
                      >
                        Program
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmSubtaskItem.SmTaskItem.OpportunityItem.Block.Brand.Name"
                      >
                        Brand
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmSubtaskItem.SmTaskItem.OpportunityItem.Block.BlockNo"
                      >
                        Block No
                      </SortLabel>
                    </TableCell>
                    {/* <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmSubtaskItem.SmSubtask.Subject"
                      >
                        Subtask Subject
                      </SortLabel>
                    </TableCell> */}
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmSubtaskItem.SmSubtask.SMTask.Requester.FullNameWithUserName"
                      >
                        Requester
                      </SortLabel>
                    </TableCell>
                    <TableCell>Assignee</TableCell>
                    <TableCell>Responded</TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="StatusFrom"
                      >
                        From Status
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel sort={sort} handleSortChange={setSort} field="StatusTo">
                        To Status
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel sort={sort} handleSortChange={setSort} field="StartDate">
                        Start Date
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel sort={sort} handleSortChange={setSort} field="EndDate">
                        End Date
                      </SortLabel>
                    </TableCell>
                    <TableCell>Duration</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableLoading isLoading={loading} colSpan={14}>
                    {subtaskItemDurationReportList.map(
                      (
                        subtaskItemWorkDurationReport: ISubtaskItemWorkDurationReport,
                        index,
                      ) => {
                        const startDate = subtaskItemWorkDurationReport.startDate
                          ?.toString()
                          .split('T')[0];
                        const startTime = subtaskItemWorkDurationReport.startDate
                          ?.toString()
                          .split('T')[1]
                          .split('.')[0];
                        const endDate = subtaskItemWorkDurationReport.endDate
                          ?.toString()
                          .split('T')[0];
                        const endTime = subtaskItemWorkDurationReport.endDate
                          ?.toString()
                          .split('T')[1]
                          .split('.')[0];
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              {index + paging.pageSize * paging.pageNumber + 1}
                            </TableCell>
                            <TableCell>
                              {subtaskItemWorkDurationReport.taskNumber}
                            </TableCell>
                            <TableCell>
                              {subtaskItemWorkDurationReport.subtaskType}
                            </TableCell>
                            <TableCell>{subtaskItemWorkDurationReport.program}</TableCell>
                            <TableCell>{subtaskItemWorkDurationReport.brand}</TableCell>
                            <TableCell>{subtaskItemWorkDurationReport.blockNo}</TableCell>
                            <TableCell>
                              <Tooltip title={subtaskItemWorkDurationReport.requester!}>
                                <Avatar>
                                  {`${subtaskItemWorkDurationReport.requester
                                    ?.split(' ')[0]
                                    .charAt(0)
                                    .toUpperCase()}${subtaskItemWorkDurationReport.requester
                                    ?.split(' ')[1]
                                    .charAt(0)
                                    .toUpperCase()}`}
                                </Avatar>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              {subtaskItemWorkDurationReport.assignees!.length > 0 ? (
                                <UserLetterAvatar
                                  users={subtaskItemWorkDurationReport.assignees!}
                                />
                              ) : (
                                ''
                              )}
                            </TableCell>
                            <TableCell>
                              <Tooltip
                                title={
                                  subtaskItemWorkDurationReport.respondedBy
                                    ? subtaskItemWorkDurationReport.respondedBy
                                    : ''
                                }
                              >
                                <Avatar>
                                  {subtaskItemWorkDurationReport.respondedBy!.split(' ')
                                    .length >= 2
                                    ? `${subtaskItemWorkDurationReport.respondedBy
                                        ?.split(' ')[0]
                                        .charAt(0)
                                        .toUpperCase()}${subtaskItemWorkDurationReport.respondedBy
                                        ?.split(' ')[1]
                                        .charAt(0)
                                        .toUpperCase()}`
                                    : ''}
                                </Avatar>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              {
                                SubtaskItemStatusLabel[
                                  subtaskItemWorkDurationReport.statusFrom!
                                ]
                              }
                            </TableCell>
                            <TableCell>
                              {
                                SubtaskItemStatusLabel[
                                  subtaskItemWorkDurationReport.statusTo!
                                ]
                              }
                            </TableCell>
                            <TableCell>
                              <DateTimeDisplay
                                value={subtaskItemWorkDurationReport.startDate}
                              />
                            </TableCell>
                            <TableCell>
                              <DateTimeDisplay
                                value={subtaskItemWorkDurationReport.endDate}
                              />
                            </TableCell>
                            <TableCell>
                              {subtaskItemWorkDurationReport.duration}
                            </TableCell>
                          </TableRow>
                        );
                      },
                    )}
                  </TableLoading>
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[15, 25, 35]}
                component="div"
                count={totalItems}
                rowsPerPage={paging.pageSize}
                page={paging.pageNumber}
                onPageChange={handleChangePageNumber}
                onRowsPerPageChange={handleChangePageSize}
              />
            </Grid>
          </Grid>
        )}
      </PageContainer>
    </Dialog>
  );
};

export default SubtaskItemDurationReportDialog;
