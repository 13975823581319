import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchField, TableLoading } from '.';
import { TablePageSize } from '../../constants';
import { IProjectProgram } from '../../models/projectProgram.model';
import { PageFilter } from '../../state/app';
import { AppState } from '../../state/configureStore';
import { Program } from '../../state/program';
import {
  fetchProjectProgramList,
  ProjectProgramListFilter,
  ProjectProgramState,
  clearProjectProgram,
} from '../../state/projectProgram';

export interface SearchProjectDialogProps {
  open: boolean;
  smProjectId: number;
  onClose: (res: any) => void;
}

const ProjectProgramDialogSearch: React.FC<SearchProjectDialogProps> = (props) => {
  const dispatch = useDispatch();
  const { open, onClose, smProjectId } = props;

  const [filters, setFilters] = useState<ProjectProgramListFilter>({
    'SmProjectProgram.Program.Name.StartsWith': '',
  });
  const initialPaging = { pageNumber: 0, pageSize: TablePageSize[0] };
  const [paging, setPaging] = useState<PageFilter>(initialPaging);
  const [selectedProgramId, setSelectedProgramId] = useState<number>();

  const [selectedProjectProgram, setSelectedProjectProgram] = useState<IProjectProgram>();
  const {
    items: projectPrograms,
    totalItems,
    loading,
  } = useSelector<AppState, ProjectProgramState>((state) => state.projectProgram);

  useEffect(() => {
    setFilters((filter) => {
      return {
        ...filter,
        'smProjectId.equalsTo': smProjectId,
      };
    });
  }, [setFilters, smProjectId]);

  useEffect(() => {
    if (open) {
      dispatch(fetchProjectProgramList(filters, paging));
    }
    return function cleanUp() {
      dispatch(clearProjectProgram());
    };
  }, [dispatch, filters, open, paging]);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Select Program</DialogTitle>
      <DialogContent>
        <div>
          <SearchField
            label="Name"
            onChange={handleChangeFilter}
            filterKey="Program.Name.StartsWith"
            filters={filters}
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Program</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={10}>
              {projectPrograms.map((projectProgram, index) => {
                return (
                  <TableRow key={projectProgram.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked,
                        ) => {
                          if (checked) {
                            setSelectedProgramId(projectProgram!.programId);
                            setSelectedProjectProgram(projectProgram);
                          } else {
                            setSelectedProjectProgram(undefined);
                            setSelectedProgramId(undefined);
                          }
                        }}
                        checked={selectedProgramId === projectProgram!.programId}
                      />
                    </TableCell>
                    <TableCell>{projectProgram.program!.name}</TableCell>
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[...TablePageSize]}
          component="div"
          count={totalItems}
          rowsPerPage={paging.pageSize}
          page={paging.pageNumber}
          onPageChange={handleChangePageNumber}
          onRowsPerPageChange={handleChangePageSize}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            if (selectedProjectProgram) {
              setSelectedProjectProgram(undefined);
              setSelectedProgramId(undefined);
              onClose(selectedProjectProgram);
            }
          }}
        >
          Confirm
        </Button>
        <Button
          onClick={() => {
            setSelectedProjectProgram(undefined);
            setSelectedProgramId(undefined);
            onClose(undefined);
          }}
          color="default"
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectProgramDialogSearch;
