import { ITaskItem } from '../../models/taskItem.model';
import { AppAction, ISort, PageFilter } from '../app';
import { TaskItemCreateOrUpdatePayload, TaskItemListFilter } from './taskItem.types';

export const FETCH_TASK_ITEM = 'FETCH_TASK_ITEM';
export const FETCH_TASK_ITEM_LIST = 'FETCH_TASK_ITEM_LIST';
export const CREATE_TASK_ITEM = 'CREATE_TASK_ITEM';
export const CREATE_TASK_ITEMS = 'CREATE_TASK_ITEMS';
export const UPDATE_TASK_ITEM = 'UPDATE_TASK_ITEM';
export const CLEAR_TASK_ITEM = 'CLEAR_TASK_ITEM';
export const DELETE_TASK_ITEM = 'DELETE_TASK_ITEM';
export const SELECTED_TASK_ITEMS = 'SELECTED_TASK_ITEMS';
export const CLEAR_SELECTED_TASK_ITEMS = 'CLEAR_SELECTED_TASK_ITEMS';

export const fetchTaskItem = (id: ITaskItem['id']): AppAction => ({
  type: FETCH_TASK_ITEM,
  payload: id,
});

export const fetchTaskItemList = (
  filters: TaskItemListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_TASK_ITEM_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchTaskItemListByTaskId = (taskId: number, sort?: ISort): AppAction => {
  return {
    type: FETCH_TASK_ITEM_LIST,
    payload: {
      filters: { 'SMTaskId.EqualsTo': taskId },
      paging: { pageSize: 9999, pageNumber: 0, sort },
    },
  };
};

export const createTaskItem = (payload: TaskItemCreateOrUpdatePayload): AppAction => ({
  type: CREATE_TASK_ITEM,
  payload,
});

export const createTaskItems = (
  payload: ITaskItem[],
  callback?: () => void,
): AppAction => ({
  type: CREATE_TASK_ITEMS,
  payload,
  callback,
});

export const updateTaskItem = (payload: TaskItemCreateOrUpdatePayload): AppAction => ({
  type: UPDATE_TASK_ITEM,
  payload,
});

export const clearTaskItem = () => ({
  type: CLEAR_TASK_ITEM,
});

export const deleteTaskItem = (payload: any): AppAction => ({
  type: DELETE_TASK_ITEM,
  payload,
});

export const selectedTaskItems = (payload: ITaskItem[]): AppAction => ({
  type: SELECTED_TASK_ITEMS,
  payload,
});

export const clearSelectedTaskItems = (): AppAction => ({
  type: CLEAR_SELECTED_TASK_ITEMS,
});
