import { IProjectCustomer } from '../../models/projectCustomer.model';
import { AppAction, AppCallback, PageFilter } from '../app';
import { ProjectCustomerListFilter } from './projectCustomer.types';

export const CREATE_PROJECT_CUSTOMER = 'CREATE_PROJECT_CUSTOMER';
export const CREATE_PROJECT_CUSTOMERS = 'CREATE_PROJECT_CUSTOMERS';
export const FETCH_PROJECT_CUSTOMER_LIST = 'FETCH_PROJECT_CUSTOMER_LIST';
export const CLEAR_PROJECT_CUSTOMER = 'CLEAR_PROJECT_CUSTOMER';
export const DELETE_PROJECT_CUSTOMER = 'DELETE_PROJECT_CUSTOMER';
export const UPDATE_PROJECT_CUSTOMER = 'UPDATE_PROJECT_CUSTOMER';
export const FETCH_PROJECT_CUSTOMER = 'FETCH_PROJECT_CUSTOMER';

export const createProjectCustomer = (payload: any): AppAction => ({
  type: CREATE_PROJECT_CUSTOMER,
  payload,
});

export const createProjectCustomers = (
  payload: any,
  callback?: AppCallback,
): AppAction => ({
  type: CREATE_PROJECT_CUSTOMERS,
  payload,
  callback,
});

export const fetchProjectCustomer = (id: IProjectCustomer['id']): AppAction => ({
  type: FETCH_PROJECT_CUSTOMER,
  payload: id,
});

export const fetchProjectCustomerList = (
  filters: ProjectCustomerListFilter,
  paging: PageFilter,
): AppAction => ({
  type: FETCH_PROJECT_CUSTOMER_LIST,
  payload: {
    filters,
    paging,
  },
});

export const fetchProjectCustomerListBySmProjectId = (
  smProjectId: number,
): AppAction => ({
  type: FETCH_PROJECT_CUSTOMER_LIST,
  payload: {
    filters: { 'smProjectId.equalsTo': smProjectId },
    paging: { pageNumber: 0, pageSize: 99999 },
  },
});

export const clearProjectCustomer = (): AppAction => ({
  type: CLEAR_PROJECT_CUSTOMER,
});

export const deleteProjectCustomer = (payload: {
  id: number;
  smProjectId: number;
}): AppAction => ({
  type: DELETE_PROJECT_CUSTOMER,
  payload,
});

export const updateProjectCustomer = (payload: IProjectCustomer): AppAction => ({
  type: UPDATE_PROJECT_CUSTOMER,
  payload,
});
