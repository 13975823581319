import React from 'react';
import { Grid } from '@material-ui/core';
import { FilterContainer, SearchField } from '../../components';
import { TaskItemListFilter } from '../../../state/taskItem';
import { IFilterContainerConfigProps } from '../../components/FilterContainer';

const TaskItemFilter: React.FC<IFilterContainerConfigProps<TaskItemListFilter>> = (
  props,
) => {
  return (
    <FilterContainer
      {...props}
      render={({ localFilter, handleChangeFilter, handleChangeDateFilter }) => {
        return (
          <Grid container spacing={1} alignItems={'center'}>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Task Number"
                filterKey={'SMTask.TaskNumber.startsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Assignment Number"
                filterKey={'OpportunityItem.Opportunity.AssignmentNumber.StartsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Program"
                filterKey={'OpportunityItem.Opportunity.Program.Name.StartsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Brand"
                filterKey={'OpportunityItem.Opportunity.Brand.Name.StartsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Block"
                filterKey={'OpportunityItem.Block.BlockNo.StartsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Item"
                filterKey={'OpportunityItem.Block.Item.StartsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            {/* <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="Sales"
                filterKey={
                  'OpportunityItem.Opportunity.Responsibility.ResponsibilitySalesTeamMember.SalesTeamMember.User.FullNameWithUserName.Contains'
                }
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid> */}
            {/* <Grid item xs={12} md={3} lg={2}>
              <SearchField
                fullWidth={true}
                label="CS"
                filterKey={
                  'OpportunityItem.Opportunity.Responsibility.ResponsibilityWorkingTeamMember.SmWorkingTeamMember.User.FullNameWithUserName.Contains'
                }
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid> */}
          </Grid>
        );
      }}
    />
  );
};

export default TaskItemFilter;
