import {
  AppBar,
  Button,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close as CloseIcon } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ITaskItemSummaryReport } from '../../../../../models/report.model';
import { downloadTaskItemSummaryReport } from '../../../../../services/reportService';
import { PageFilter } from '../../../../../state/app';
import { AppState } from '../../../../../state/configureStore';
import {
  clearReport,
  fetchTaskItemSummaryReportList,
  ITaskItemSummaryReportFilter,
  ReportState,
} from '../../../../../state/report';
import {
  PageContainer,
  PageTitle,
  SortLabel,
  TableLoading,
  UserLetterAvatar,
  ChipList,
} from '../../../../components';
import { DateDisplay, DateTimeDisplay } from '../../../../components/DateTimeDisplay';
import { useFilter, useTableSorting } from '../../../../hooks';
import TaskItemSummaryReportFilter from './TaskItemSummaryReportFilter';
import useStyles from './TaskItemSummaryReportStyle';

interface TaskItemSummaryReportDialogProps {
  open: boolean;
  handleClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

const initialTaskItemSummaryReportFilter: ITaskItemSummaryReportFilter = {
  'SmSubtask.StartDueDate.GreaterThanOrEqual': moment()
    .startOf('month')
    .format('YYYY-MM-DD'),
  'SmSubtask.EndDueDate.LessThanOrEqual': moment()
    .add(2, 'month')
    .endOf('month')
    .format('YYYY-MM-DD'),
  'StartCreatedAt.GreaterThanOrEqual': null,
  'EndCreatedAt.LessThanOrEqual': null,
};

const TaskItemSummaryReportDialog: React.FC<TaskItemSummaryReportDialogProps> = (
  props,
) => {
  const { open, handleClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    loading,
    items: taskItemSummaryReportList,
    totalItems,
  } = useSelector<AppState, ReportState>((state) => state.report);

  const [filters, setFilters] = useFilter<ITaskItemSummaryReportFilter>(
    initialTaskItemSummaryReportFilter,
    true,
  );
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const [sort, setSort] = useTableSorting(
    {
      orderBy: 'SmTaskItem.OpportunityItem.Opportunity.Program.Name',
      direction: 'desc',
    },
    true,
  );

  useEffect(() => {
    if (open) {
      dispatch(fetchTaskItemSummaryReportList(filters, { ...paging, sort }));
    }
    return function cleanUp() {
      dispatch(clearReport());
    };
  }, [dispatch, paging, filters, open, sort]);

  const handleChangeFilter = (newFilter: ITaskItemSummaryReportFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const exportExcel = () => {
    downloadTaskItemSummaryReport(filters, { pageSize: totalItems, sort }).catch((err) =>
      console.error(err),
    );
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Task Item Summary
          </Typography>
        </Toolbar>
      </AppBar>
      <PageContainer>
        <PageTitle>Task Item Summary</PageTitle>
        <TaskItemSummaryReportFilter
          filters={filters}
          onChangeFilter={handleChangeFilter}
          initialFilter={initialTaskItemSummaryReportFilter}
        />
        {!isSmallScreen && (
          <div className={classes.filterContainer}>
            <Button
              variant={'contained'}
              className={classes.button}
              onClick={exportExcel}
            >
              Export Excel
            </Button>
          </div>
        )}
        {open && (
          <Grid container spacing={3} className={classes.container}>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmTaskItem.OpportunityItem.Opportunity.Program.Name"
                      >
                        Program
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmTaskItem.OpportunityItem.Opportunity.Brand.Name"
                      >
                        Brand
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmTaskItem.OpportunityItem.Block.BlockNo"
                      >
                        Block No
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmTaskItem.OpportunityItem.Opportunity.AssignmentNumber"
                      >
                        Assignment Number
                      </SortLabel>
                    </TableCell>
                    <TableCell>Sales</TableCell>
                    <TableCell>CS</TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmSubtask.SMTask.TaskNumber"
                      >
                        Task Number
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmSubtask.SMTask.Requester.FullNameWithUserName"
                      >
                        Requester
                      </SortLabel>
                    </TableCell>
                    <TableCell>Assignee</TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmSubtask.Subject"
                      >
                        Subject
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmSubtask.CreatedAt"
                      >
                        Created Date
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmSubtask.DueDate"
                      >
                        Due Date
                      </SortLabel>
                    </TableCell>
                    <TableCell>
                      <SortLabel
                        sort={sort}
                        handleSortChange={setSort}
                        field="SmSubtask.CompletedDate"
                      >
                        Completed Date
                      </SortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableLoading isLoading={loading} colSpan={10}>
                    {taskItemSummaryReportList.map(
                      (taskItemSummaryReport: ITaskItemSummaryReport, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {index + paging.pageSize * paging.pageNumber + 1}
                          </TableCell>
                          <TableCell>{taskItemSummaryReport.program}</TableCell>
                          <TableCell>{taskItemSummaryReport.brand}</TableCell>
                          <TableCell>{taskItemSummaryReport.blockNo}</TableCell>
                          <TableCell>{taskItemSummaryReport.assignmentNumber}</TableCell>
                          <TableCell>
                            <ChipList
                              list={
                                taskItemSummaryReport.sales
                                  ? taskItemSummaryReport.sales
                                  : []
                              }
                              noAllLabel
                            />
                          </TableCell>
                          <TableCell>
                            <ChipList
                              list={
                                taskItemSummaryReport.cs ? taskItemSummaryReport.cs : []
                              }
                              noAllLabel
                            />
                          </TableCell>
                          <TableCell>{taskItemSummaryReport.taskNumber}</TableCell>
                          <TableCell>{taskItemSummaryReport.taskRequester}</TableCell>
                          <TableCell>
                            {taskItemSummaryReport.subtaskAssignees!.length > 0 ? (
                              <UserLetterAvatar
                                users={taskItemSummaryReport.subtaskAssignees!}
                              />
                            ) : (
                              ''
                            )}
                          </TableCell>
                          <TableCell>{taskItemSummaryReport.subtaskSubject}</TableCell>
                          <TableCell>
                            <DateTimeDisplay
                              value={taskItemSummaryReport.subtaskCreatedDate}
                            />
                          </TableCell>
                          <TableCell>
                            <DateDisplay value={taskItemSummaryReport.subtaskDueDate} />
                          </TableCell>
                          <TableCell>
                            <DateDisplay
                              value={taskItemSummaryReport.subtaskCompletedDate}
                            />
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableLoading>
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[15, 25, 35]}
                component="div"
                count={totalItems}
                rowsPerPage={paging.pageSize}
                page={paging.pageNumber}
                onPageChange={handleChangePageNumber}
                onRowsPerPageChange={handleChangePageSize}
              />
            </Grid>
          </Grid>
        )}
      </PageContainer>
    </Dialog>
  );
};

export default TaskItemSummaryReportDialog;
