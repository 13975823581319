import {
  Button,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Check,
  Edit as EditIcon,
  FileCopy as FileCopyIcon,
  HighlightOff,
  HourglassEmpty,
  Timer,
} from '@material-ui/icons';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { OpportunityStatusLabel, TablePageSize } from '../../../constants';
import { IProject } from '../../../models/project.model';
import { IOpportunity } from '../../../models/opportunity.model';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearOpportunity,
  fetchOpportunityList,
  OpportunityListFilter,
  OpportunityState,
} from '../../../state/opportunity';
import { defaultListLoadingHeight } from '../../../theme';
import { PageButtonGroup, PageContainer, PageTitle } from '../../components';
import Loading from '../../components/AwLoading';
import { DateTimeDisplay } from '../../components/DateTimeDisplay';
import SortLabel from '../../components/SortLabel';
import { useFilter, useHasRole, useTableSorting } from '../../hooks';
import roleRoute from '../roleRoute.config';
import { CopyOpportunityDialog } from './CopyOpportunityDialog';
import { OpportunityFilter } from './OpportunityFilter';

import ProjectDetailDialog from '../../components/ProjectComboBox/ProjectDetailDialog';

const initialOpportunityFilter: OpportunityListFilter = {
  'Status.equalsTo': '',
};

const statusIcons = {
  [OpportunityStatusLabel.PENDING_FOR_APPROVAL]: (
    <Tooltip title={OpportunityStatusLabel.PENDING_FOR_APPROVAL}>
      <HourglassEmpty />
    </Tooltip>
  ),
  [OpportunityStatusLabel.IN_REQUEST]: (
    <Tooltip title={OpportunityStatusLabel.IN_REQUEST}>
      <Timer />
    </Tooltip>
  ),
  [OpportunityStatusLabel.REJECTED]: (
    <Tooltip title={OpportunityStatusLabel.REJECTED}>
      <HighlightOff />
    </Tooltip>
  ),
  [OpportunityStatusLabel.APPROVED]: (
    <Tooltip title={OpportunityStatusLabel.APPROVED}>
      <Check />
    </Tooltip>
  ),
};

const OpportunityList: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const isDisabled = !useHasRole(roleRoute.createOpportunity);

  const [filters, setFilters] = useFilter<OpportunityListFilter>(
    initialOpportunityFilter,
  );

  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: TablePageSize[0],
  });
  const [sort, setSort] = useTableSorting({
    orderBy: 'assignmentNumber',
    direction: 'desc',
  });
  const [openCopyOpportunityDialog, setOpenCopyOpportunityDialog] =
    useState<boolean>(false);

  const [selectedProject, setSelectedProject] = useState<IProject | null>(null);

  const [selectedOpportunityToCopy, setSelectedOpportunityToCopy] =
    useState<IOpportunity | null>(null);

  const {
    items: opportunities,
    totalItems,
    loading,
  } = useSelector<AppState, OpportunityState>((state) => state.opportunity);

  useEffect(() => {
    dispatch(fetchOpportunityList(filters, { ...paging, sort }));
    return () => {
      dispatch(clearOpportunity());
    };
  }, [dispatch, paging, filters, sort]);

  const addNewOpportunity = (): void => {
    history.push('/assignments/form');
  };

  const editOpportunity = (id: IOpportunity['id']): void => {
    history.push(`/assignments/form/${id}`);
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const applyAssignmentFilter = (
    filters: React.SetStateAction<OpportunityListFilter>,
  ) => {
    setFilters({ ...filters });
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  return (
    <PageContainer>
      <PageTitle>Assignments</PageTitle>
      <OpportunityFilter
        filters={filters}
        onChangeFilter={(newFilter) => applyAssignmentFilter(newFilter)}
        initialFilter={initialOpportunityFilter}
      />
      <PageButtonGroup>
        <Button
          variant="contained"
          color="primary"
          onClick={addNewOpportunity}
          startIcon={<AddIcon />}
          disabled={isDisabled}
        >
          New Assignment
        </Button>
      </PageButtonGroup>
      <Loading loading={loading} height={defaultListLoadingHeight}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>
                <SortLabel
                  sort={sort}
                  handleSortChange={setSort}
                  field="assignmentNumber"
                >
                  Assignment Number
                </SortLabel>
              </TableCell>
              <TableCell>Project</TableCell>
              <TableCell>
                <SortLabel sort={sort} handleSortChange={setSort} field="company.Name">
                  Company
                </SortLabel>
              </TableCell>
              <TableCell>Program</TableCell>
              <TableCell>Brand</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>
                <SortLabel sort={sort} handleSortChange={setSort} field="createdAt">
                  Created At
                </SortLabel>
              </TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {opportunities.map((opportunity, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                  <TableCell>
                    <Link
                      variant="h6"
                      href="#"
                      style={{ fontWeight: 'bold' }}
                      color="primary"
                      onClick={() => editOpportunity(opportunity.id)}
                    >
                      {opportunity.assignmentNumber}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href="#"
                      onClick={() => {
                        setSelectedProject(opportunity?.smProject || null);
                      }}
                    >
                      {opportunity?.smProject?.name}
                    </Link>
                  </TableCell>
                  <TableCell>{opportunity.company!.name}</TableCell>
                  <TableCell>{opportunity.program!.name}</TableCell>
                  <TableCell>{opportunity.brand!.name}</TableCell>
                  <TableCell>
                    <div>{statusIcons[OpportunityStatusLabel[opportunity.status!]]}</div>
                  </TableCell>
                  <TableCell>
                    <DateTimeDisplay value={opportunity.createdAt} />
                  </TableCell>
                  <TableCell>{opportunity.createdBy}</TableCell>
                  <TableCell>
                    <Tooltip title="Edit">
                      <span>
                        <IconButton
                          size="small"
                          onClick={() => editOpportunity(opportunity.id)}
                          disabled={isDisabled}
                        >
                          <EditIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Copy">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setOpenCopyOpportunityDialog(true);
                          setSelectedOpportunityToCopy(opportunity);
                        }}
                        disabled={isDisabled}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Loading>
      <TablePagination
        rowsPerPageOptions={[...TablePageSize]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
      <CopyOpportunityDialog
        open={openCopyOpportunityDialog}
        opportunity={selectedOpportunityToCopy}
        handleClose={() => {
          setOpenCopyOpportunityDialog(false);
        }}
      />
      <ProjectDetailDialog
        smProjectId={selectedProject?.id}
        open={selectedProject !== null}
        handleClose={() => setSelectedProject(null)}
      />
    </PageContainer>
  );
};

export default OpportunityList;
