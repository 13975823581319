import { IResponsibilityCustomer } from '../../models/responsibilityCustomer.model';
import { AppAction, PageFilter, AppCallback } from '../app';
import {
  ResponsibilityCustomerCreateOrUpdatePayload,
  ResponsibilityCustomerListFilter,
  ResponsibilityCustomerReplacePayLoad,
} from './responsibilityCustomer.types';

export const FETCH_RESPONSIBILITY_CUSTOMER = 'FETCH_RESPONSIBILITY_CUSTOMER';
export const FETCH_RESPONSIBILITY_CUSTOMER_LIST = 'FETCH_RESPONSIBILITY_CUSTOMER_LIST';
export const CREATE_RESPONSIBILITY_CUSTOMER = 'CREATE_RESPONSIBILITY_CUSTOMER';
export const UPDATE_RESPONSIBILITY_CUSTOMER = 'UPDATE_RESPONSIBILITY_CUSTOMER';
export const CLEAR_RESPONSIBILITY_CUSTOMER = 'CLEAR_RESPONSIBILITY_CUSTOMER';
export const DELETE_RESPONSIBILITY_CUSTOMER = 'DELETE_RESPONSIBILITY_CUSTOMER';
export const CREATE_RESPONSIBILITY_CUSTOMERS = 'CREATE_RESPONSIBILITY_CUSTOMERS';
export const REPLACE_RESPONSIBILITY_CUSTOMER = 'REPLACE_RESPONSIBILITY_CUSTOMER';
export const INACTIVE_RESPONSIBILITY_CUSTOMER = 'INACTIVE_RESPONSIBILITY_CUSTOMER';

export const fetchResponsibilityCustomer = (
  id: IResponsibilityCustomer['id'],
): AppAction => ({
  type: FETCH_RESPONSIBILITY_CUSTOMER,
  payload: id,
});

export const fetchResponsibilityCustomerList = (
  filters: ResponsibilityCustomerListFilter,
  paging: PageFilter,
): AppAction => {
  return {
    type: FETCH_RESPONSIBILITY_CUSTOMER_LIST,
    payload: {
      filters,
      paging,
    },
  };
};

export const fetchResponsibilityCustomerListByresponsibilityId = (
  responsibilityId: number,
): AppAction => ({
  type: FETCH_RESPONSIBILITY_CUSTOMER_LIST,
  payload: {
    filters: { 'responsibilityId.equalsTo': responsibilityId },
    paging: { pageNumber: 0, pageSize: 99999 },
  },
});

export const createResponsibilityCustomer = (
  payload: ResponsibilityCustomerCreateOrUpdatePayload,
): AppAction => ({
  type: CREATE_RESPONSIBILITY_CUSTOMER,
  payload,
});

export const createResponsibilityCustomers = (
  payload: any,
  callback?: AppCallback,
): AppAction => ({
  type: CREATE_RESPONSIBILITY_CUSTOMERS,
  payload,
  callback,
});

export const updateResponsibilityCustomer = (
  payload: ResponsibilityCustomerCreateOrUpdatePayload,
): AppAction => ({
  type: UPDATE_RESPONSIBILITY_CUSTOMER,
  payload,
});

export const clearResponsibilityCustomer = () => ({
  type: CLEAR_RESPONSIBILITY_CUSTOMER,
});

export const deleteResponsibilityCustomer = (id: number) => ({
  type: DELETE_RESPONSIBILITY_CUSTOMER,
  payload: { id },
});

export const replaceResponsibilityCustomer = (
  payload: ResponsibilityCustomerReplacePayLoad,
) => ({
  type: REPLACE_RESPONSIBILITY_CUSTOMER,
  payload,
});

export const inactiveResponsibilityCustomer = (id: number) => ({
  type: INACTIVE_RESPONSIBILITY_CUSTOMER,
  payload: { id },
});
