import React from 'react';
import { Paper, CardContent, Grid, Typography, Box } from '@material-ui/core';
import useStyles from './sampleDevelopmentNl.style';
import SampleDevelopmentNlPackingProduct from './SampleDevelopmentNlPackingProduct';
import SampleDevelopmentNlPackingCarton from './SampleDevelopmentNlPackingCarton';

interface SampleDevelopmentNlPackingProps {}

const SampleDevelopmentNlPacking: React.FC<SampleDevelopmentNlPackingProps> = () => {
  const classes = useStyles();

  return (
    <Paper variant="outlined">
      <CardContent>
        <Grid container spacing={1} direction="row" justifyContent="flex-start">
          <Grid container>
            <Typography className={classes.title}>Packing & QC</Typography>
          </Grid>
          <Box m={1}>
            <SampleDevelopmentNlPackingProduct />
            <SampleDevelopmentNlPackingCarton />
          </Box>
        </Grid>
      </CardContent>
    </Paper>
  );
};

export default SampleDevelopmentNlPacking;
