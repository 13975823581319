import { Button, IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ISubtask } from '../../../../models/subtask.model';
import { AppState } from '../../../../state/configureStore';
import { deleteSubtask } from '../../../../state/subtask';
import { TaskState } from '../../../../state/task';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';

interface ISubtaskActionMenuProps {
  subtask: ISubtask;
  setEditSubtaskId: (subtaskId: number) => void;
}

const SubtaskActionMenu: React.FC<ISubtaskActionMenuProps> = (props) => {
  const { subtask, setEditSubtaskId } = props;
  const dispatch = useDispatch();
  const { item: task } = useSelector<AppState, TaskState>((state) => state.task);
  const deleteSMSubtask = (subtask: ISubtask) => {
    const { id } = task;
    dispatch(deleteSubtask({ id: subtask.id, smTaskId: id }));
  };

  return (
    <>
      <Button
        variant={'contained'}
        color="primary"
        size="small"
        startIcon={<EditIcon />}
        onClick={() => {
          setEditSubtaskId(subtask.id!);
        }}
      >
        EDIT
      </Button>

      <Button
        variant={'contained'}
        size="small"
        color="default"
        startIcon={<DeleteIcon />}
        onClick={() => deleteSMSubtask(subtask)}
      >
        DELETE
      </Button>
    </>
  );
};

export default SubtaskActionMenu;
