import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading, TableLoading } from '..';
import { ISampleDevelopmentQueue } from '../../../models/sampleDevelopment.model';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  fetchSampleDevelopmentQueueList,
  SampleDevelopmentQueueListFilter,
  SampleDevelopmentQueueState,
} from '../../../state/sampleDevelopmentQueue';
import SampleDevelopmentQueueDialogFilter from './SampleDevelopmentQueueDialogFilter';

interface SampleDevelopmentQueueDialogProps {
  open: boolean;
  onClose: (selectedQueue?: ISampleDevelopmentQueue) => void;
}

const SampleDevelopmentQueueDialog: React.FC<SampleDevelopmentQueueDialogProps> = (
  props,
) => {
  const dispatch = useDispatch();
  const { open, onClose } = props;
  const {
    items: sampleDevelopmentQueues,
    loading,
    totalItems,
  } = useSelector<AppState, SampleDevelopmentQueueState>((q) => q.sampleDevelopmentQueue);

  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 10,
  });

  const [filters, setFilters] = useState<SampleDevelopmentQueueListFilter>({
    'ProductTypeName.EqualsTo': 'HL',
  });

  useEffect(() => {
    dispatch(fetchSampleDevelopmentQueueList(filters, { ...paging }));
    return function cleanUp() {
      //dispatch(clearOpportunityItem());
    };
  }, [dispatch, paging, filters]);

  const handleChangeFilter = (newFilter: SampleDevelopmentQueueListFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const [selectedQueue, setSelectedQueue] = useState<ISampleDevelopmentQueue>({});

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'} fullWidth>
      <DialogTitle>Select Queue</DialogTitle>
      <Loading isLoading={loading}></Loading>
      <DialogContent>
        <SampleDevelopmentQueueDialogFilter
          filters={filters}
          onChangeFilter={handleChangeFilter}
          initialFilter={filters}
        ></SampleDevelopmentQueueDialogFilter>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Queue Digital Proof Code</TableCell>
              <TableCell>Queue Digital Proof Name</TableCell>
              <TableCell>Queue Plate Code</TableCell>
              <TableCell>Queue Plate Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={5}>
              {sampleDevelopmentQueues.map((queue) => {
                const isCheckboxChecked = queue.id === selectedQueue.id;
                return (
                  <TableRow key={queue.id}>
                    <TableCell>
                      <Checkbox
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked,
                        ) => {
                          if (checked) {
                            setSelectedQueue({ ...queue });
                          }
                        }}
                        checked={isCheckboxChecked}
                      ></Checkbox>
                    </TableCell>
                    <TableCell>{queue.queueDigitalProofCode}</TableCell>
                    <TableCell>{queue.queueDigitalProofName}</TableCell>
                    <TableCell>{queue.queuePlateCode}</TableCell>
                    <TableCell>{queue.queuePlateName}</TableCell>
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 15, 30, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={paging.pageSize}
          page={paging.pageNumber}
          onPageChange={handleChangePageNumber}
          onRowsPerPageChange={handleChangePageSize}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            onClose(selectedQueue);
          }}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SampleDevelopmentQueueDialog;
