import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      marginTop: '0.5rem',
      color: 'red',
    },
    dialog: {
      minHeight: '53vh',
    },
    textInput: {
      zIndex: 0,
    },
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    loadingMessage: {
      marginBottom: 0,
      marginLeft: '15px',
    },
    tableContainer: {
      marginTop: theme.spacing(3),
    },
  }),
);

export default useStyles;
