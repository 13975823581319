import { PageFilter } from '../state/app';
import { TaskActivityListFilter } from '../state/taskActivity';
import axios from '../utils/axios';
import { ITaskActivity } from '../models/taskActivity.model';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';

const baseUrl = `/api/taskActivities`;

export const fetchTaskActivityList = async ({
  filters,
  paging,
}: {
  filters: TaskActivityListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchTaskActivity = async (id: ITaskActivity['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createTaskActivity = async (taskActivity: ITaskActivity) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, taskActivity);
};

export const updateTaskActivity = async (taskActivity: ITaskActivity) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, taskActivity);
};

export const fetchTaskActivityListRelatedToUserId = async ({
  userId,
  paging,
}: {
  userId: number;
  paging: PageFilter;
}) => {
  const requestUrl = `${baseUrl}/users/${userId}`;
  const params = buildUrlSearchParams({}, paging);
  return await axios.get(`${requestUrl}?${params.toString()}`);
};
