import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  comment: {
    padding: '5px',
    marginTop: '10px',
    '&:hover': {
      borderLeft: '5px solid rgb(63, 81, 181)',
    },
    borderLeft: '5px solid rgb(255, 255, 255)',
  },
  commentTypo: {
    paddingLeft: '50px',
    marginTop: '2px',
  },
  avatar: {
    margin: theme.spacing(1),
    marginTop: -5,
    width: 10,
    height: 10,
  },
  expansionPanel: {
    padding: '5px',
    '&:hover': {
      borderLeft: '5px solid rgb(63, 81, 181)',
    },
    borderLeft: '5px solid rgb(255, 255, 255)',
  },
  expansionPanelSummary: {
    marginLeft: -30,
    marginBottom: -10,
  },
  expansionPanelDetail: {
    paddingLeft: 45,
    paddingBottom: 8,
  },
}));

export default useStyles;
