import { CardContent, Grid, Paper, Typography, Box } from '@material-ui/core';
import React from 'react';
import { CheckBoxFastField, TextFieldFastField } from '../../../components';

interface SampleDevelopmentPlPackingProps {}

const SampleDevelopmentPlPacking: React.FC<SampleDevelopmentPlPackingProps> = () => {
  return (
    <Box m={1}>
      <Paper variant="outlined">
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography>Packing</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>Packing:</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingPlain" label="Plain" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckBoxFastField name="packingSpecial" label="Special" />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldFastField
                name="packingSpecialData"
                label="Special"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default SampleDevelopmentPlPacking;
