import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { v4 as uuidv4 } from 'uuid';
import {
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  GridApi,
  GridReadyEvent,
} from 'ag-grid-community';
import { useDispatch } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  FileCopy as CopyIcon,
  MoveToInbox as PasteIcon,
  Save as SaveIcon,
} from '@material-ui/icons';
import { ObjectSchema } from 'yup';
import { pushNotificationMessage } from '../Notification';
import {
  copyGridToClipboard,
  copyToClipboardAsTabDelimited,
  parseClipboardTabDelimitedContent,
  pasteGridValueFromClipboard,
} from '../../../utils/agGridUtil';
import { ChangeDetectionStrategyType } from 'ag-grid-react/lib/changeDetectionService';
import { indigo, yellow } from '@material-ui/core/colors';

export enum RowMode {
  UnModified,
  Added,
  Modified,
}

export interface GridRowState {
  rowId: string;
  rowMode?: RowMode;
}

export interface ExcelRowTransformer<T> {
  headers: string[];
  objectToRow: (obj: T) => any[];
  rowToObject: (row: string[]) => T;
}

export interface EditableTableProps<S> extends AgGridReactProps {
  rows: ReadonlyArray<S>;
  onSave: (rows: Array<S>) => void;
  getNewRow: () => S;
  rowValidationSchema?: ObjectSchema;
  rowsLimit?: number;
  tableContainerStyle?: CSSProperties;
  excelRowTransformer?: ExcelRowTransformer<S>;
}

const getRowStyle = (params: any) => {
  const row = params.data as GridRowState;
  switch (row.rowMode) {
    case RowMode.Added:
      return { 'background-color': yellow[100] };
    case RowMode.Modified:
      return { 'background-color': indigo[100] };
    default:
      return { 'background-color': 'white' };
  }
};

function EditableTable<S, T extends S & GridRowState>(
  props: EditableTableProps<S>,
  ref: any,
) {
  const dispatch = useDispatch();
  const {
    rows,
    rowValidationSchema,
    onSave,
    rowsLimit,
    getNewRow,
    columnDefs,
    tableContainerStyle,
    excelRowTransformer,
    ...rest
  } = props;

  const [localRows, setLocalRows] = useState<Array<T>>([]);
  const [initialRows, setInitialRows] = useState<Array<T>>([]);
  const gridApi = useRef<GridApi>();

  const columnKeys = useMemo<Array<string>>(() => {
    if (!columnDefs) {
      return [];
    }

    return columnDefs.map((c: ColDef) => c.field).filter((s) => !!s) as Array<string>;
  }, [columnDefs]);

  const localColumnDefs = useMemo<Array<ColDef | ColGroupDef>>(() => {
    if (columnDefs) {
      return [{ headerName: '', checkboxSelection: true, width: 50 }, ...columnDefs];
    }
    return [];
  }, [columnDefs]);

  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
    gridApi.current.setRowData(localRows as Array<any>);
    params.api.sizeColumnsToFit();
  };

  const getCurrentGridRowData = (): Array<T> => {
    if (gridApi.current) {
      gridApi.current.stopEditing();
      const rows = [] as Array<T>;
      gridApi.current.forEachNode((rowNode) => {
        rows.push({ ...rowNode.data });
      });
      return rows;
    }
    return [];
  };

  const refreshLocalRowsFromGrid = () => {
    setLocalRows(getCurrentGridRowData());
  };

  const handleCopy = () => {
    refreshLocalRowsFromGrid();
    if (excelRowTransformer) {
      const { headers, objectToRow } = excelRowTransformer;
      const rows = [headers] as Array<Array<any>>;
      gridApi.current!.forEachNode(({ data }) => {
        rows.push(objectToRow(data));
      });
      copyToClipboardAsTabDelimited(rows);
    } else {
      copyGridToClipboard(gridApi.current!, columnKeys);
    }
  };

  const handlePaste = async () => {
    if (excelRowTransformer) {
      const { headers, rowToObject } = excelRowTransformer;
      const rows = await parseClipboardTabDelimitedContent();
      if (rows.length > 0) {
        const firstRow = rows[0];
        const hasHeaderInClipboard = headers.some((header) => firstRow.includes(header));
        if (hasHeaderInClipboard) {
          rows.splice(0, 1);
        }
      }
      const transformedRows = rows.map((row) => ({
        rowId: uuidv4(),
        rowMode: RowMode.Added,
        ...rowToObject(row),
      }));
      gridApi.current!.setRowData(transformedRows.slice(0, rowsLimit));
    } else {
      await pasteGridValueFromClipboard(
        gridApi.current!,
        columnKeys,
        getNewRow(),
        rowsLimit,
      );
    }
    refreshLocalRowsFromGrid();
  };

  const handleAdd = () => {
    if (rowsLimit && localRows.length >= rowsLimit) {
      return;
    }
    const newRow = { ...getNewRow(), rowId: uuidv4(), rowMode: RowMode.Added } as T;
    setLocalRows([...getCurrentGridRowData(), newRow]);
  };

  const handleSave = () => {
    gridApi.current!.stopEditing();
    const newRows = [] as T[];
    gridApi.current!.forEachNode(({ data }) => {
      newRows.push({ ...data });
    });
    let isValid = true;
    if (!!rowValidationSchema) {
      for (const row of newRows) {
        try {
          rowValidationSchema.validateSync(row);
        } catch (e) {
          isValid = false;
        }
      }
    }
    setLocalRows(newRows);

    if (isValid) {
      onSave(newRows);
    } else {
      dispatch(
        pushNotificationMessage({
          message: 'Please fill all right format value in red border cells',
          type: 'error',
        }),
      );
      gridApi.current!.redrawRows();
    }
  };

  const handleDelete = () => {
    if (gridApi.current) {
      const rows = getCurrentGridRowData();
      const selectedRowsId = gridApi.current.getSelectedRows().map((row) => row.rowId);
      setLocalRows(rows.filter((row) => selectedRowsId.indexOf(row.rowId) < 0));
    }
  };

  const getCellStyle = useCallback(
    (params: any) => {
      const { colDef, data } = params;
      if (rowValidationSchema && colDef.field) {
        try {
          rowValidationSchema.validateSyncAt(colDef.field, data);
        } catch (e) {
          return { border: '1px solid red' };
        }
      }
      return { border: 'none' };
    },
    [rowValidationSchema],
  );

  useImperativeHandle(ref, () => {
    return {
      getGridApi: () => gridApi.current,
      getCurrentGridRowData,
    };
  });

  useEffect(() => {
    const localRows = rows.map(
      (row) =>
        ({
          ...row,
          rowId: uuidv4(),
          rowMode: RowMode.UnModified,
        } as T),
    );
    setLocalRows(localRows);
    setInitialRows(localRows.map((l) => ({ ...l })));
  }, [rows]);

  useEffect(() => {
    if (gridApi.current) {
      gridApi.current.setRowData(localRows.map((r) => ({ ...r })));
    }
  }, [localRows]);

  return (
    <>
      <Box m={1}>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<SaveIcon />}
        >
          Save
        </Button>
        <Button
          onClick={handleAdd}
          variant="contained"
          size="small"
          color="primary"
          startIcon={<AddIcon />}
        >
          Add
        </Button>
        <Button
          onClick={handleCopy}
          variant="contained"
          size="small"
          startIcon={<CopyIcon />}
        >
          Copy
        </Button>
        <Button
          onClick={handlePaste}
          variant="contained"
          size="small"
          startIcon={<PasteIcon />}
        >
          Paste
        </Button>
        <Button
          onClick={handleDelete}
          variant="contained"
          color="default"
          size="small"
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      </Box>
      <div className="ag-theme-balham" style={tableContainerStyle}>
        <AgGridReact
          columnDefs={localColumnDefs}
          defaultColDef={{
            cellStyle: getCellStyle,
          }}
          getRowStyle={getRowStyle}
          onGridReady={onGridReady}
          singleClickEdit
          suppressRowClickSelection
          deltaRowDataMode={true}
          getRowNodeId={(row: T) => row.rowId}
          rowSelection={'multiple'}
          rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
          onCellValueChanged={(event: CellValueChangedEvent) => {
            const {
              newValue,
              rowIndex,
              data,
              colDef: { field },
            } = event;
            const originalRow = initialRows[rowIndex] as any;
            const isRowChange = !Boolean(originalRow) || newValue !== originalRow[field!];
            const rows = getCurrentGridRowData();
            rows[rowIndex] = {
              ...data,
              rowMode:
                data.rowMode === RowMode.Added
                  ? RowMode.Added
                  : isRowChange
                  ? RowMode.Modified
                  : RowMode.UnModified,
            };
            gridApi.current!.setRowData(rows);
          }}
          {...rest}
        />
      </div>
    </>
  );
}

export function getForwardRefEditableTable<T>() {
  return React.forwardRef<GridApi, EditableTableProps<T>>(EditableTable);
}
