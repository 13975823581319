import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_PRODUCTTYPE,
  CREATE_PRODUCTTYPE,
  FETCH_PRODUCTTYPE,
  FETCH_PRODUCTTYPE_LIST,
  UPDATE_PRODUCTTYPE,
} from './productType.actions';
import { ProductType, ProductTypeState } from './productType.types';

const getInitialProductTypeState = (): ProductType => ({
  id: 0,
  name: '',
  description: '',
});

const initialState: ProductTypeState = {
  errorMessage: '',
  item: getInitialProductTypeState(),
  items: [],
  loading: false,
  totalItems: 0,
};

export default (
  state: ProductTypeState = initialState,
  action: AppAction,
): ProductTypeState => {
  switch (action.type) {
    case REQUEST(FETCH_PRODUCTTYPE_LIST):
    case REQUEST(CREATE_PRODUCTTYPE):
    case REQUEST(FETCH_PRODUCTTYPE):
    case REQUEST(UPDATE_PRODUCTTYPE):
      return { ...state, loading: true };
    case SUCCESS(FETCH_PRODUCTTYPE_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_PRODUCTTYPE_LIST):
    case FAILURE(CREATE_PRODUCTTYPE):
    case FAILURE(FETCH_PRODUCTTYPE):
    case FAILURE(UPDATE_PRODUCTTYPE):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_PRODUCTTYPE):
    case SUCCESS(FETCH_PRODUCTTYPE):
    case SUCCESS(UPDATE_PRODUCTTYPE):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_PRODUCTTYPE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
