import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import { CLEAR_SQUAD_MEMBER, FETCH_SQUAD_MEMBER_LIST } from './squadMember.actions';
import { SquadMember, SquadMemberState } from './squadMember.type';

const initialState: SquadMemberState = {
  errorMessage: '',
  item: {} as Readonly<SquadMember>,
  items: [] as ReadonlyArray<SquadMember>,
  loading: false,
  totalItems: 0,
  selectedItems: [] as SquadMember[],
};

export default (
  state: SquadMemberState = initialState,
  action: AppAction,
): SquadMemberState => {
  switch (action.type) {
    case REQUEST(FETCH_SQUAD_MEMBER_LIST):
      return { ...state, loading: true };
    case SUCCESS(FETCH_SQUAD_MEMBER_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_SQUAD_MEMBER_LIST):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };

    case CLEAR_SQUAD_MEMBER:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
