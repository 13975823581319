import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../state/configureStore';
import { fetchSubtaskTypeList, SubtaskTypeState } from '../../../../state/subtaskType';
import { clearTaskCreateDialog } from '../../../../state/taskCreate';
import ConfirmDialog from '../../../components/ConfirmDialog';
import SubtaskListDetailDialog from './SubtaskListDetailDialog';
import SubtaskTypeSelectDialog from './SubtaskTypeSelectDialog';
import TaskFormDialog from './TaskFormDialog';
import TaskItemForecastListDetailDialog from './TaskItemForecastListDetailDialog';
import TaskItemListDialog from './TaskItemListDialog';
import TaskItemMoqListDetailDialog from './TaskItemMoqListDetailDialog';
import { TransitionProps } from '@material-ui/core/transitions';

type dialogStep =
  | 'Create Task'
  | 'Select Subtask Type'
  | 'Create Subtask'
  | 'Select Item'
  | 'Set MOQ'
  | 'Set Forecast'
  | 'Finish';

interface TaskCreateDialogProps {
  open: boolean;
  onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

const TaskCreateDialog: React.FC<TaskCreateDialogProps> = (props) => {
  const { open, onClose } = props;
  const dispatch = useDispatch();
  const [step, setStep] = useState<dialogStep>('Create Task');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { items: subtaskTypeList } = useSelector<AppState, SubtaskTypeState>(
    (state) => state.subtaskType,
  );

  const taskFormNextClick = () => {
    setStep('Select Subtask Type');
  };
  const subtaskTypeSelectNextClick = () => {
    setStep('Create Subtask');
  };
  const subtaskTypeSelectBackClick = () => {
    setStep('Create Task');
  };
  const subtaskListDetailNextClick = () => {
    setStep('Select Item');
  };
  const subtaskListDetailBackClick = () => {
    setStep('Select Subtask Type');
  };
  const taskItemListBackClick = () => {
    setStep('Create Subtask');
  };
  const taskItemListNextClick = () => {
    setStep('Set MOQ');
  };
  const taskItemMoqListBackClick = () => {
    setStep('Select Item');
  };
  const taskItemMoqListNextClick = () => {
    setStep('Set Forecast');
  };
  const taskItemForecastListBackClick = () => {
    setStep('Set MOQ');
  };
  const taskItemForecastListNextClick = () => {
    setStep('Finish');
  };
  const finishClick = () => {
    setStep('Finish');
  };
  const handleClose = () => {
    setOpenConfirmDialog(true);
  };

  useEffect(() => {
    dispatch(
      fetchSubtaskTypeList(
        { 'IsSpecialType.EqualsTo': 'false' },
        { pageNumber: 0, pageSize: 15 },
      ),
    );
  }, [dispatch]);
  return (
    <>
      <ConfirmDialog
        open={openConfirmDialog}
        content={'Are you sure you want to close this dialog?'}
        handleCancel={() => {
          setOpenConfirmDialog(false);
        }}
        handleConfirm={() => {
          setOpenConfirmDialog(false);
          onClose();
          setStep('Create Task');
          dispatch(clearTaskCreateDialog());
        }}
        handleClose={() => {
          setOpenConfirmDialog(false);
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        {step === 'Create Task' && (
          <TaskFormDialog nextClick={taskFormNextClick} closeClick={handleClose} />
        )}
        {step === 'Select Subtask Type' && (
          <SubtaskTypeSelectDialog
            nextClick={subtaskTypeSelectNextClick}
            backClick={subtaskTypeSelectBackClick}
            subtaskTypeList={subtaskTypeList}
            closeClick={handleClose}
          />
        )}
        {step === 'Create Subtask' && (
          <SubtaskListDetailDialog
            nextClick={subtaskListDetailNextClick}
            backClick={subtaskListDetailBackClick}
            subtaskTypeList={subtaskTypeList}
            closeClick={handleClose}
          />
        )}
        {step === 'Select Item' && (
          <TaskItemListDialog
            nextClick={taskItemListNextClick}
            backClick={taskItemListBackClick}
            finishClick={finishClick}
            closeClick={handleClose}
          />
        )}
        {step === 'Set MOQ' && (
          <TaskItemMoqListDetailDialog
            nextClick={taskItemMoqListNextClick}
            backClick={taskItemMoqListBackClick}
            closeClick={handleClose}
          />
        )}
        {step === 'Set Forecast' && (
          <TaskItemForecastListDetailDialog
            nextClick={taskItemForecastListNextClick}
            backClick={taskItemForecastListBackClick}
            closeClick={handleClose}
          />
        )}
        {step === 'Finish' && (
          <>
            <DialogTitle>Creating...</DialogTitle>
            <DialogContent>
              <CircularProgress />
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
};

export default TaskCreateDialog;
