import { Grid, Paper, Typography, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CloudDownload } from '@material-ui/icons';
import { saveAs } from 'file-saver';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { baseUrl } from '../../../../services/taskAttachmentService';
import { AppState } from '../../../../state/configureStore';
import {
  clearTaskAttachment,
  fetchTaskAttachmentListByTaskId,
  TaskAttachmentState,
  uploadTaskAttachments,
} from '../../../../state/taskAttachment';
import axios from '../../../../utils/axios';

interface TaskAttachmentProps {
  smTaskId: number;
}

const fileSizeInBytes = 50000000;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    attachmentContainer: {
      display: 'flex',
      marginTop: '15px',
      marginBottom: '15px',
      padding: '0.05px',
    },
    attachmentTypo: {
      padding: '5px',
      marginLeft: '15px',
      display: 'inline-block',
    },
    container: {
      marginTop: '15px',
      padding: '0.05px',
    },
    leftIcon: {
      marginRight: '5px',
    },
    title: {
      marginLeft: '15px',
      marginTop: '15px',
    },
    iconButton: {
      marginTop: -9,
      '&:hover': {
        backgroundColor: 'rgb(255, 255, 255)',
      },
    },
    dropzoneArea: {
      height: '100%',
    },
  }),
);

const TaskAttachment: React.FC<TaskAttachmentProps> = props => {
  const { smTaskId } = props;
  const classes = useStyles();
  const { items: attachmentList } = useSelector<AppState, TaskAttachmentState>(
    state => state.taskAttachment,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTaskAttachmentListByTaskId(smTaskId));
    return function cleanUp() {
      dispatch(clearTaskAttachment());
    };
  }, [dispatch, smTaskId]);

  return (
    <>
      <Paper>
        <Grid container>
          <Grid item xs={6}>
            <DropzoneArea
              dropzoneClass={classes.dropzoneArea}
              key={attachmentList.length}
              acceptedFiles={['']}
              filesLimit={20}
              dropzoneText="Upload File"
              showPreviewsInDropzone={false}
              maxFileSize={fileSizeInBytes}
              onChange={uploadFiles => {
                dispatch(uploadTaskAttachments({ smTaskId, files: uploadFiles }));
              }}
              showAlerts={true}
            />
          </Grid>
          <Grid item xs={6}>
            {attachmentList.length > 0 && (
              <div>
                <Typography className={classes.title} variant="h6">
                  File List
                </Typography>
                {attachmentList.map(attachment => {
                  const downloadUrl = `${baseUrl}/${attachment.id}/download`;
                  return (
                    <div key={attachment.id} className={classes.attachmentContainer}>
                      <Typography className={classes.attachmentTypo}>
                        {attachment.fileName}
                      </Typography>

                      <Tooltip title="Download" placement="right">
                        <IconButton
                          className={classes.iconButton}
                          onClick={async () => {
                            const response = await axios({
                              method: 'GET',
                              url: `${downloadUrl}`,
                              responseType: 'blob',
                            });
                            saveAs(response.data, attachment.fileName);
                          }}
                        >
                          <CloudDownload />
                        </IconButton>
                      </Tooltip>
                    </div>
                  );
                })}
              </div>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default TaskAttachment;
