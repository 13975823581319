import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2),
    // marginTop: '-30px',
    // marginRight: '-25px',
  },
}));

const PageTopMenuContainer: React.FC = (props) => {
  const classes = useStyles();
  return <div className={classes.root}>{props.children}</div>;
};

export default PageTopMenuContainer;
