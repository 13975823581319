import { PageFilter } from '../state/app';
import {
  ProjectApprovalRequest,
  ProjectApprovalRequestListFilter,
  ProjectApprovalResponse,
} from '../state/projectApprovalRequest';
import axios from '../utils/axios';

const baseUrl = `/api/smProjectApprovalRequests`;

export const fetchProjectApprovalRequestList = async ({
  filters,
  paging,
}: {
  filters: ProjectApprovalRequestListFilter;
  paging: PageFilter;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchProjectApprovalRequest = async (id: ProjectApprovalRequest['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createProjectApprovalRequest = async (
  projectApprovalRequest: ProjectApprovalRequest,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, projectApprovalRequest);
};

export const updateProjectApprovalRequest = async (
  projectApprovalRequest: ProjectApprovalRequest,
) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, projectApprovalRequest);
};

export const updateProjectApprovalResponse = async (
  projectApprovalResponse: ProjectApprovalResponse,
) => {
  const requestUrl = `${baseUrl}/${projectApprovalResponse.projectApprovalRequestId}/response`;
  return await axios.put(requestUrl, projectApprovalResponse);
};
