import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Edit as EditIcon,
  People as PeopleIcon,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearWorkingTeam,
  fetchWorkingTeamList,
  WorkingTeam,
  WorkingTeamListFilter,
  WorkingTeamState,
} from '../../../state/workingTeam';
import {
  PageButtonGroup,
  PageContainer,
  PageTitle,
  TableLoading,
} from '../../components';
import WorkingTeamMemberDialog from './WorkingTeamMemberDialog';
import { useFilter, useHasRole } from '../../hooks';
import roleRoute from '../roleRoute.config';
import useStyles from './workingTeamList.style';
import WorkingTeamFilter from './WorkingTeamFilter';

const initialWorkingTeamFilter: WorkingTeamListFilter = {
  'Name.startsWith': '',
};

const WorkingTeamList: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { history } = props;
  const isDisabled = !useHasRole(roleRoute.createWorkingTeam);

  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });

  const [filters, setFilters] = useFilter<WorkingTeamListFilter>({
    'Name.startsWith': '',
  });

  const [viewMemberOfWorkingTeamId, setViewMemberOfWorkingTeamId] = useState<
    number | null
  >(null);

  const {
    items: workingTeams,
    totalItems,
    loading,
  } = useSelector<AppState, WorkingTeamState>((state) => state.workingTeam);

  useEffect(() => {
    dispatch(fetchWorkingTeamList(filters, paging));
    return function cleanUp() {
      dispatch(clearWorkingTeam());
    };
  }, [dispatch, paging, filters]);

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const addNewWorkingTeam = (): void => {
    history.push('/settings/workingTeams/form');
  };

  const editWorkingTeam = (id: WorkingTeam['id']): void => {
    history.push(`/settings/workingTeams/form/${id}`);
  };

  const applyWorkingTeamFilter = (
    filters: React.SetStateAction<WorkingTeamListFilter>,
  ) => {
    setFilters({ ...filters });
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  return (
    <PageContainer>
      <PageTitle>Working Team</PageTitle>
      <WorkingTeamFilter
        filters={filters}
        onChangeFilter={(newFilter) => applyWorkingTeamFilter(newFilter)}
        initialFilter={initialWorkingTeamFilter}
      />
      <PageButtonGroup>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={addNewWorkingTeam}
          size="large"
          disabled={isDisabled}
        >
          <AddIcon className={classes.iconButton} />
          New Working Team
        </Button>
      </PageButtonGroup>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Owner</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={3}>
            {workingTeams.map((workingTeam, index) => {
              return (
                <TableRow key={workingTeam.id}>
                  <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                  <TableCell>{workingTeam.name}</TableCell>
                  <TableCell>{workingTeam.description}</TableCell>
                  <TableCell>
                    {workingTeam.owner !== undefined
                      ? workingTeam.owner.fullNameWithUserName
                      : ''}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={'Edit'}>
                      <span>
                        <IconButton
                          size="small"
                          onClick={() => editWorkingTeam(workingTeam.id)}
                          disabled={isDisabled}
                        >
                          <EditIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title={'View Members'}>
                      <IconButton
                        size={'small'}
                        onClick={() => setViewMemberOfWorkingTeamId(workingTeam.id!)}
                      >
                        <PeopleIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
      {viewMemberOfWorkingTeamId !== null && (
        <WorkingTeamMemberDialog
          workingTeamId={viewMemberOfWorkingTeamId!}
          handleClose={() => {
            setViewMemberOfWorkingTeamId(null);
          }}
          open={viewMemberOfWorkingTeamId !== null}
        />
      )}
    </PageContainer>
  );
};

export default WorkingTeamList;
