import { AppAction, AppCallback, PageFilter } from '../app';
import { OpportunityItemListFilter } from './opportunityItem.types';
import { IOpportunityItem } from '../../models/opportunityItem.model';

export const CREATE_OPPORTUNITY_ITEM = 'CREATE_OPPORTUNITY_ITEM';
export const FETCH_OPPORTUNITY_ITEM_LIST = 'FETCH_OPPORTUNITY_ITEM_LIST';
export const FETCH_OPPORTUNITY_ITEM = 'FETCH_OPPORTUNITY_ITEM';
export const CLEAR_OPPORTUNITY_ITEM = 'CLEAR_OPPORTUNITY_ITEM';
export const DELETE_OPPORTUNITY_ITEM = 'DELETE_OPPORTUNITY_ITEM';
export const UPDATE_OPPORTUNITY_ITEM = 'UPDATE_OPPORTUNITY_ITEM';

export const createOpportunityItem = (payload: any): AppAction => ({
  type: CREATE_OPPORTUNITY_ITEM,
  payload,
});

export const fetchOpportunityItemList = (
  filters: OpportunityItemListFilter,
  paging: PageFilter,
): AppAction => ({
  type: FETCH_OPPORTUNITY_ITEM_LIST,
  payload: {
    filters,
    paging,
  },
});

export const fetchOpportunityItem = (id: IOpportunityItem['id']): AppAction => ({
  type: FETCH_OPPORTUNITY_ITEM,
  payload: id,
});

export const fetchOpportunityItemListByOpportunityId = (opportunityId: number) => {
  return {
    type: FETCH_OPPORTUNITY_ITEM_LIST,
    payload: {
      filters: { 'OpportunityId.equalsTo': opportunityId },
      paging: { pageSize: 9999, pageNumber: 0, sort: { orderBy: 'sequence', direction: 'asc' } },
    },
  }
};

export const clearOpportunityItem = (): AppAction => ({
  type: CLEAR_OPPORTUNITY_ITEM,
});

export const deleteOpportunityItem = (payload: any): AppAction => ({
  type: DELETE_OPPORTUNITY_ITEM,
  payload,
});

export const updateOpportunityItem = (payload: IOpportunityItem, callback?: AppCallback): AppAction => ({
  type: UPDATE_OPPORTUNITY_ITEM,
  payload,
  callback
});
