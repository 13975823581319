import { saveAs } from 'file-saver';
import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import {
  downloadCheckAccountOwnerReport,
  downloadSubtaskItemReport,
  downloadSubtaskItemWorkDurationSummaryReport,
  downloadSubtaskSummaryReport,
  fetchBrandApprovalRequestReportList,
  fetchOpportunityApprovalRequestReportList,
  fetchOpportunityItemSummaryReportList,
  fetchProgramApprovalRequestReportList,
  fetchProjectSummaryReportList,
  fetchSubtaskItemDurationReportList,
  fetchSubtaskItemSummaryReportList,
  fetchTaskItemSummaryReportList,
  fetchProjectSalesSummaryReportList,
  fetchProjectChangeLogReportList,
  // fetchOpportunityChangeLogReportList,
} from '../../services/reportService';
import { REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  DOWNLOAD_CHECK_ACCOUNT_OWNER_REPORT,
  DOWNLOAD_SUBTASK_ITEM_REPORT,
  DOWNLOAD_SUBTASK_ITEM_WORK_DURATION_SUMMARY_REPORT,
  DOWNLOAD_SUBTASK_SUMMARY_REPORT,
  FETCH_BRAND_APPROVAL_REQUEST_REPORT_LIST,
  FETCH_OPPORTUNITY_APPROVAL_REQUEST_REPORT_LIST,
  FETCH_OPPORTUNITY_ITEM_SUMMARY_REPORT_LIST,
  FETCH_PROGRAM_APPROVAL_REQUEST_REPORT_LIST,
  FETCH_PROJECT_SALES_SUMMARY_REPORT_LIST,
  FETCH_PROJECT_SUMMARY_REPORT_LIST,
  FETCH_SUBTASK_ITEM_SUMMARY_REPORT_LIST,
  FETCH_SUBTASK_WORK_DURATION_REPORT,
  FETCH_TASK_ITEM_SUMMARY_REPORT_LIST,
  FETCH_PROJECT_CHANGELOG_REPORT_LIST,
  FETCH_OPPORTUNITY_CHANGELOG_REPORT_LIST,
} from './report.action';

function* downloadCheckAccountOwnerReportSaga(action: AppAction) {
  try {
    const response = yield call(downloadCheckAccountOwnerReport, action.payload);
    saveAs(response.data, 'AccountOwnerAndSalesTeam.xlsx');
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* downloadSubtaskSummaryReportSaga(action: AppAction) {
  try {
    const response = yield call(downloadSubtaskSummaryReport, action.payload);
    saveAs(response.data, 'SubtaskSummary.xlsx');
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* downloadSubtaskItemWorkDurationSummaryReportSaga(action: AppAction) {
  try {
    const response = yield call(
      downloadSubtaskItemWorkDurationSummaryReport,
      action.payload,
    );
    saveAs(response.data, 'SubtaskItemWorkDurationSummary.xlsx');
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* downloadSubtaskItemReportSaga(action: AppAction) {
  try {
    const response = yield call(downloadSubtaskItemReport, action.payload);
    saveAs(response.data, 'SubtaskItemReport.xlsx');
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchOpportunityItemSummaryReportListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchOpportunityItemSummaryReportList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

// function* fetchOpportunityChangeLogReportListSaga(action: AppAction) {
//   try {
//     yield put({ type: REQUEST });
//     const response = yield call(fetchOpportunityChangeLogReportList, action.payload);
//     const { data, headers } = response;
//     yield put({ type: SUCCESS(action.type), payload: { data, headers } });
//   } catch (error) {
//     const errorMessage: string = error.response.data.message;
//     yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
//   }
// }

function* fetchTaskItemSummaryReportListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchTaskItemSummaryReportList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchProjectSummaryReportListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchProjectSummaryReportList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchProjectSalesSummaryReportListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchProjectSalesSummaryReportList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchProjectChangeLogReportListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchProjectChangeLogReportList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchOpportunityApprovalRequestReportListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(
      fetchOpportunityApprovalRequestReportList,
      action.payload,
    );
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchProgramApprovalRequestReportListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchProgramApprovalRequestReportList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchBrandApprovalRequestReportListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchBrandApprovalRequestReportList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchSubtaskItemSummaryReportListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSubtaskItemSummaryReportList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchSubtaskWorkDurationReportSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchSubtaskItemDurationReportList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchReport() {
  yield takeLatest(
    DOWNLOAD_CHECK_ACCOUNT_OWNER_REPORT,
    downloadCheckAccountOwnerReportSaga,
  );
  yield takeLatest(DOWNLOAD_SUBTASK_SUMMARY_REPORT, downloadSubtaskSummaryReportSaga);
  yield takeLatest(
    DOWNLOAD_SUBTASK_ITEM_WORK_DURATION_SUMMARY_REPORT,
    downloadSubtaskItemWorkDurationSummaryReportSaga,
  );
  yield takeLatest(DOWNLOAD_SUBTASK_ITEM_REPORT, downloadSubtaskItemReportSaga);
  yield debounce(
    250,
    FETCH_OPPORTUNITY_ITEM_SUMMARY_REPORT_LIST,
    fetchOpportunityItemSummaryReportListSaga,
  );
  yield debounce(
    250,
    FETCH_TASK_ITEM_SUMMARY_REPORT_LIST,
    fetchTaskItemSummaryReportListSaga,
  );
  yield debounce(
    250,
    FETCH_SUBTASK_ITEM_SUMMARY_REPORT_LIST,
    fetchSubtaskItemSummaryReportListSaga,
  );
  yield debounce(
    250,
    FETCH_SUBTASK_WORK_DURATION_REPORT,
    fetchSubtaskWorkDurationReportSaga,
  );
  yield debounce(
    250,
    FETCH_PROJECT_SUMMARY_REPORT_LIST,
    fetchProjectSummaryReportListSaga,
  );
  yield debounce(
    250,
    FETCH_PROJECT_SALES_SUMMARY_REPORT_LIST,
    fetchProjectSalesSummaryReportListSaga,
  );
  yield debounce(
    250,
    FETCH_PROJECT_CHANGELOG_REPORT_LIST,
    fetchProjectChangeLogReportListSaga,
  );
  yield debounce(
    250,
    FETCH_OPPORTUNITY_APPROVAL_REQUEST_REPORT_LIST,
    fetchOpportunityApprovalRequestReportListSaga,
  );
  yield debounce(
    250,
    FETCH_PROGRAM_APPROVAL_REQUEST_REPORT_LIST,
    fetchProgramApprovalRequestReportListSaga,
  );
  yield debounce(
    250,
    FETCH_BRAND_APPROVAL_REQUEST_REPORT_LIST,
    fetchBrandApprovalRequestReportListSaga,
  );
}
