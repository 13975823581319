import { call, debounce, put } from 'redux-saga/effects';
import { fetchSampleDevelopmentTlApprovalRequestList } from '../../../../services/sampleDevelopmentTlApprovalRequestService';
import { AppAction } from '../../../../state/app';
import { FAILURE, REQUEST, SUCCESS } from '../../../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../../components/Notification';
import { FETCH_PENDING_APPROVAL_SAMPLE_DEV_TL_LIST } from './pendingApprovalSampleDevTlRequestList.actions';

function* fetchPendingApprovalSampleDevlopmentTlListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(
      fetchSampleDevelopmentTlApprovalRequestList,
      action.payload,
    );
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchPendingApprovalSampleDevlopmentTlList() {
  yield debounce(
    250,
    FETCH_PENDING_APPROVAL_SAMPLE_DEV_TL_LIST,
    fetchPendingApprovalSampleDevlopmentTlListSaga,
  );
}
