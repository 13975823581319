import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_WORKING_TEAM_MEMBER,
  CREATE_WORKING_TEAM_MEMBER,
  DELETE_WORKING_TEAM_MEMBER,
  FETCH_WORKING_TEAM_MEMBER_LIST,
  FETCH_WORKING_TEAM_MEMBER_LIST_NOT_OWNER,
  UPDATE_WORKING_TEAM_MEMBER,
} from './workingTeamMember.actions';
import { WorkingTeamMember, WorkingTeamMemberState } from './workingTeamMember.type';

const getInitialSalesTeamRoleState = (): WorkingTeamMember => ({
  id: 0,
  smWorkingTeamId: 0,
  userId: 0,
  active: false,
  externalId: '',
  salesTeamRoleId: 0,
});

const initialState: WorkingTeamMemberState = {
  errorMessage: '',
  item: getInitialSalesTeamRoleState(),
  items: [],
  loading: false,
  totalItems: 0,
};

export default (
  state: WorkingTeamMemberState = initialState,
  action: AppAction,
): WorkingTeamMemberState => {
  switch (action.type) {
    case REQUEST(FETCH_WORKING_TEAM_MEMBER_LIST):
    case REQUEST(FETCH_WORKING_TEAM_MEMBER_LIST_NOT_OWNER):
    case REQUEST(CREATE_WORKING_TEAM_MEMBER):
    case REQUEST(UPDATE_WORKING_TEAM_MEMBER):
      return { ...state, loading: true };
    case SUCCESS(FETCH_WORKING_TEAM_MEMBER_LIST):
    case SUCCESS(FETCH_WORKING_TEAM_MEMBER_LIST_NOT_OWNER):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_WORKING_TEAM_MEMBER_LIST):
    case FAILURE(FETCH_WORKING_TEAM_MEMBER_LIST_NOT_OWNER):
    case FAILURE(CREATE_WORKING_TEAM_MEMBER):
    case FAILURE(UPDATE_WORKING_TEAM_MEMBER):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_WORKING_TEAM_MEMBER):
    case SUCCESS(UPDATE_WORKING_TEAM_MEMBER):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_WORKING_TEAM_MEMBER:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
