import { AppAction } from '../../../../state/app';
import { SUCCESS } from '../../../../utils/actionTypeUtil';
import { FETCH_PENDING_APPROVAL_ASSIGNMENT_LIST } from '../../../pages/pendingApprovalItemList/pendingApprovalAssignmentList/pendingApprovalAssignmentList.actions';
import { FETCH_PENDING_APPROVAL_PROGRAMANDBRANDREQUEST_LIST } from '../../../pages/pendingApprovalItemList/pendingApprovalProgramBrandRequestList/pendingApprovalProgramBrandRequestList.actions';
import { FETCH_PENDING_APPROVAL_SAMPLE_DEV_HL_LIST } from '../../../pages/pendingApprovalItemList/pendingApprovalSampleDevHlRequestList/pendingApprovalSampleDevHlRequestList.actions';
import { FETCH_PENDING_APPROVAL_SAMPLE_DEV_NL_LIST } from '../../../pages/pendingApprovalItemList/pendingApprovalSampleDevNlRequestList/pendingApprovalSampleDevNlRequestList.actions';
import { FETCH_PENDING_APPROVAL_SAMPLE_DEV_PL_LIST } from '../../../pages/pendingApprovalItemList/pendingApprovalSampleDevPlRequestList/pendingApprovalSampleDevPlRequestList.actions';
import { FETCH_PENDING_APPROVAL_SAMPLE_DEV_TL_LIST } from '../../../pages/pendingApprovalItemList/pendingApprovalSampleDevTlRequestList/pendingApprovalSampleDevTlRequestList.actions';
import { FETCH_PENDING_APPROVAL_SAMPLE_DEV_WL_LIST } from '../../../pages/pendingApprovalItemList/pendingApprovalSampleDevWlRequestList/pendingApprovalSampleDevWlRequestList.actions';

export interface PendingApprovalDrawerItemState {
  totalPendingApprovalAssignment: number;
  totalPendingApprovalProgramBrandRequest: number;
  totalPendingApprovalSampleDevNl: number;
  totalPendingApprovalSampleDevHl: number;
  totalPendingApprovalSampleDevPl: number;
  totalPendingApprovalSampleDevTl: number;
  totalPendingApprovalSampleDevWl: number;
}

const initialState: PendingApprovalDrawerItemState = {
  totalPendingApprovalAssignment: 0,
  totalPendingApprovalProgramBrandRequest: 0,
  totalPendingApprovalSampleDevNl: 0,
  totalPendingApprovalSampleDevHl: 0,
  totalPendingApprovalSampleDevPl: 0,
  totalPendingApprovalSampleDevTl: 0,
  totalPendingApprovalSampleDevWl: 0,
};

export default (
  state: PendingApprovalDrawerItemState = initialState,
  action: AppAction,
): PendingApprovalDrawerItemState => {
  switch (action.type) {
    case SUCCESS(FETCH_PENDING_APPROVAL_ASSIGNMENT_LIST):
      return {
        ...state,
        totalPendingApprovalAssignment: +action.payload.headers['x-total-count'],
      };
    case SUCCESS(FETCH_PENDING_APPROVAL_PROGRAMANDBRANDREQUEST_LIST):
      return {
        ...state,
        totalPendingApprovalProgramBrandRequest: +action.payload.headers['x-total-count'],
      };
    case SUCCESS(FETCH_PENDING_APPROVAL_SAMPLE_DEV_NL_LIST):
      return {
        ...state,
        totalPendingApprovalSampleDevNl: +action.payload.headers['x-total-count'],
      };
    case SUCCESS(FETCH_PENDING_APPROVAL_SAMPLE_DEV_HL_LIST):
      return {
        ...state,
        totalPendingApprovalSampleDevHl: +action.payload.headers['x-total-count'],
      };
    case SUCCESS(FETCH_PENDING_APPROVAL_SAMPLE_DEV_PL_LIST):
      return {
        ...state,
        totalPendingApprovalSampleDevPl: +action.payload.headers['x-total-count'],
      };
    case SUCCESS(FETCH_PENDING_APPROVAL_SAMPLE_DEV_TL_LIST):
      return {
        ...state,
        totalPendingApprovalSampleDevTl: +action.payload.headers['x-total-count'],
      };
    case SUCCESS(FETCH_PENDING_APPROVAL_SAMPLE_DEV_WL_LIST):
      return {
        ...state,
        totalPendingApprovalSampleDevWl: +action.payload.headers['x-total-count'],
      };

    default:
      return state;
  }
};
