import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import { WorkingGroupCreateOrUpdatePayload } from '.';
import {
  createWorkingGroup,
  fetchWorkingGroup,
  fetchWorkingGroupList,
  updateWorkingGroup,
} from '../../services/workingGroupService';
import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { pushNotificationMessage } from '../../views/components/Notification';
import { AppAction } from '../app';
import {
  CREATE_WORKING_GROUP,
  FETCH_WORKING_GROUP,
  FETCH_WORKING_GROUP_LIST,
  UPDATE_WORKING_GROUP,
} from './workingGroup.actions';

const listPageUrl = `/settings/workingGroups`;

function* fetchWorkingGroupListSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const response = yield call(fetchWorkingGroupList, action.payload);
    const { data, headers } = response;
    yield put({ type: SUCCESS(action.type), payload: { data, headers } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* fetchWorkingGroupSaga(action: AppAction) {
  try {
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(fetchWorkingGroup, action.payload);
    yield put({ type: SUCCESS(action.type), payload: { data } });
  } catch (error) {
    const errorMessage: string = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* createWorkingGroupSaga(action: AppAction) {
  try {
    const { values, history } = action.payload as WorkingGroupCreateOrUpdatePayload;
    const successMessage = `Created ${values.name} Group successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(createWorkingGroup, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
    const formUrl = `${listPageUrl}/form/${data.id}`;
    history.push(formUrl);
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

function* updateWorkingGroupSaga(action: AppAction) {
  try {
    const { values } = action.payload as WorkingGroupCreateOrUpdatePayload;
    const successMessage = `Updated ${values.name} workingGroup successfully.`;
    yield put({ type: REQUEST(action.type) });
    const { data } = yield call(updateWorkingGroup, values);
    yield put({ type: SUCCESS(action.type), payload: { data } });
    yield put(pushNotificationMessage({ message: successMessage, type: 'success' }));
  } catch (error) {
    const errorMessage = error.response.data.message;
    yield put({ type: FAILURE(action.type), payload: { errorMessage } });
    yield put(pushNotificationMessage({ message: errorMessage, type: 'error' }));
  }
}

export default function* watchWorkingGroup() {
  yield debounce(250, FETCH_WORKING_GROUP_LIST, fetchWorkingGroupListSaga);
  yield takeLatest(FETCH_WORKING_GROUP, fetchWorkingGroupSaga);
  yield takeLatest(CREATE_WORKING_GROUP, createWorkingGroupSaga);
  yield takeLatest(UPDATE_WORKING_GROUP, updateWorkingGroupSaga);
}
