import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
  },
  title: {
    margin: theme.spacing(2),
  },
}));

export default useStyles;