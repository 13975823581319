import React from 'react';
import { fetchBrandList as fetchBrandListService } from '../../services/brandService';
import { Brand } from '../../state/brand';
import AsyncAutoComplete, { AsyncAutoCompleteOption } from './AsyncAutoComplete';

interface BrandComboBoxProps {
  handleChange: (result: Brand & AsyncAutoCompleteOption) => void;
  selectedValue: Brand | null | { label: string | null; value: string | null };
  isDisabled?: boolean;
  error?: boolean;
  helperText?: string;
  creatable?: boolean;
}

const BrandComboBox: React.FC<BrandComboBoxProps> = (props) => {
  const {
    handleChange,
    selectedValue,
    isDisabled = false,
    error,
    helperText,
    creatable = false,
  } = props;

  const brandPromiseOptions = async (inputValue: string) => {
    const { data: rows } = await fetchBrandListService({
      filters: { 'Name.startsWith': inputValue },
      paging: { pageNumber: 0, pageSize: 10 },
    });

    return rows;
  };

  return (
    <AsyncAutoComplete
      name="Brand"
      value={selectedValue}
      getOptionValue={(option: Brand & AsyncAutoCompleteOption) => {
        return option.id ? option.id!.toString() : option.value;
      }}
      getOptionLabel={(option: Brand & AsyncAutoCompleteOption) => {
        return option.name ? option.name : option.label;
      }}
      promiseOptions={brandPromiseOptions}
      placeholder={'Type Brand Name'}
      handleChange={handleChange}
      isDisabled={isDisabled}
      error={error}
      helperText={helperText}
      creatable={creatable}
    />
  );
};

export default BrandComboBox;
