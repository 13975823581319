import queryString from 'query-string';
import { useEffect, useState } from 'react';
import history from '../../history';
import { ISort, OrderDirection } from '../../state/app';

export type SortChangeEventHandler = (field: string) => () => void;

const useTableSorting = (
  initialSort: ISort,
  ignoreSortOnUrl: boolean = false,
): [ISort, SortChangeEventHandler] => {
  const { 'sort.orders': sortOrders } = getQueryParameterObj() as any;
  let sortParameterObj = {};

  if (sortOrders && !ignoreSortOnUrl) {
    const direction = sortOrders[0] === '-' ? 'desc' : 'asc';
    const orderBy =
      sortOrders[0] === '-' ? sortOrders.substring(1) : sortOrders.substring(0);
    sortParameterObj = {
      orderBy,
      direction,
    };
  }

  const initValue = { ...initialSort, ...sortParameterObj };
  const [sort, setSort] = useState<ISort>(initValue);
  const handleSortChange = (field: string) => {
    return () => {
      const direction: OrderDirection =
        sort.orderBy === field && sort.direction === 'asc' ? 'desc' : 'asc';
      setSort({ ...sort, orderBy: field, direction });
    };
  };

  useEffect(() => {
    if (!ignoreSortOnUrl) {
      history.replace(`${history.location.pathname}?${buildUrlWithSortParams(sort)}`);
    }
  }, [sort, ignoreSortOnUrl]);

  return [sort, handleSortChange];
};

const getQueryParameterObj = () => queryString.parse(history.location.search);

const buildUrlWithSortParams = (sort: any): URLSearchParams => {
  const { 'sort.orders': _, ...filterParameterObj } = getQueryParameterObj();
  const params = new URLSearchParams();
  const direction = sort.direction === 'asc' ? sort.orderBy : `-${sort.orderBy}`;
  params.append('sort.orders', direction);

  if (Object.keys(filterParameterObj).length !== 0) {
    Object.entries(filterParameterObj).forEach(([key, value]) => {
      if (typeof value === 'string' && value.length > 0) {
        params.append(key, value);
      } else if (value) {
        params.append(key, String(value));
      }
    });
  }
  return params;
};

export default useTableSorting;
