import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IOpportunityItem } from '../../../../models/opportunityItem.model';
import { PageFilter } from '../../../../state/app';
import { AppState } from '../../../../state/configureStore';
import {
  clearOpportunityItem,
  fetchOpportunityItemList,
  OpportunityItemListFilter,
  OpportunityItemState,
} from '../../../../state/opportunityItem';
import { TaskState } from '../../../../state/task';
import { createTaskItems, TaskItemState } from '../../../../state/taskItem';
import { SearchField, SortLabel, TableLoading } from '../../../components';
import useStyles from './taskItemCreateDialog.style';
import { useTableSorting } from '../../../hooks';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  Close as CloseIcon,
  Save as SaveIcon,
  SkipNextOutlined as NextIcon,
  SkipPreviousOutlined as PreviousIcon,
  Done as DoneIcon,
} from '@material-ui/icons';
import { TablePageSize } from '../../../../constants';

interface TaskItemCreateDialogProps {
  open: boolean;
  handleClose: () => void;
}

type dialogStep =
  | 'selectAssignmentItem'
  | 'confirmAssignmentItem'
  | 'createdAssignmentItem';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

const TaskItemCreateDialog: React.FC<TaskItemCreateDialogProps> = (props) => {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [sort, setSort] = useTableSorting(
    {
      orderBy: 'Opportunity.AssignmentNumber',
      direction: 'desc',
    },
    true,
  );
  const [filters, setFilters] = useState<OpportunityItemListFilter>({});
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 10,
  });
  const [selectedOpportunityItems, setSelectedOpportunityItems] = useState<
    IOpportunityItem[]
  >([]);
  const [step, setStep] = useState<dialogStep>('selectAssignmentItem');
  const {
    items: opportunityItems,
    totalItems,
    loading,
  } = useSelector<AppState, OpportunityItemState>((state) => state.opportunityItem);

  const {
    item: { id: taskId },
  } = useSelector<AppState, TaskState>((state) => state.task);

  const { createdItems } = useSelector<AppState, TaskItemState>(
    (state) => state.taskItem,
  );

  useEffect(() => {
    const newPaging = { ...paging, sort };
    dispatch(fetchOpportunityItemList(filters, newPaging));
    return function cleanUp() {
      setStep('selectAssignmentItem');
      dispatch(clearOpportunityItem());
    };
  }, [dispatch, paging, filters, sort]);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth={true}
      disableBackdropClick={true}
      TransitionComponent={Transition}
    >
      {step === 'selectAssignmentItem' && (
        <>
          <DialogTitle>Select Assignment Item</DialogTitle>
          <DialogContent>
            <div className={classes.filterContainer}>
              <SearchField
                label="Assignment Number"
                onChange={handleChangeFilter}
                filterKey="Opportunity.AssignmentNumber.startsWith"
                filters={filters}
              />
              <SearchField
                label="Program"
                onChange={handleChangeFilter}
                filterKey="Opportunity.Program.Name.startsWith"
                filters={filters}
              />
              <SearchField
                label="Brand"
                onChange={handleChangeFilter}
                filterKey="Opportunity.Brand.Name.startsWith"
                filters={filters}
              />
              <SearchField
                label="Block No"
                onChange={handleChangeFilter}
                filterKey="Block.BlockNo.startsWith"
                filters={filters}
              />
              <SearchField
                label="Item"
                onChange={handleChangeFilter}
                filterKey="Block.Item.startsWith"
                filters={filters}
              />
              <SearchField
                label="Assignee"
                onChange={handleChangeFilter}
                filterKey="Assignee.FullName.Contains"
                filters={filters}
              />
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked) => {
                        if (checked) {
                          const isItemAdded = (opItem: IOpportunityItem) => {
                            return (
                              selectedOpportunityItems.findIndex(
                                (selectedOpportunityItem) =>
                                  selectedOpportunityItem.id! === opItem.id,
                              ) === -1
                            );
                          };
                          const itemToAdd = opportunityItems.filter(isItemAdded);
                          setSelectedOpportunityItems([
                            ...selectedOpportunityItems,
                            ...itemToAdd,
                          ]);
                        } else {
                          setSelectedOpportunityItems([]);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>No.</TableCell>
                  <TableCell>
                    <SortLabel
                      sort={sort}
                      handleSortChange={setSort}
                      field="Opportunity.AssignmentNumber"
                    >
                      Assignment Number
                    </SortLabel>
                  </TableCell>
                  <TableCell>Program</TableCell>
                  <TableCell>Brand</TableCell>
                  <TableCell>
                    <SortLabel
                      sort={sort}
                      handleSortChange={setSort}
                      field="Block.BlockNo"
                    >
                      Block No
                    </SortLabel>
                  </TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell>Sample Dev</TableCell>
                  <TableCell>NPD</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableLoading isLoading={loading} colSpan={10}>
                  {opportunityItems.map((opportunityItem, index) => {
                    const { id } = opportunityItem;
                    const isCheckboxChecked =
                      selectedOpportunityItems.findIndex(
                        (selectedOpportunityItem) => selectedOpportunityItem.id === id,
                      ) !== -1;
                    return (
                      <TableRow key={opportunityItem.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                              checked,
                            ) => {
                              if (checked) {
                                setSelectedOpportunityItems([
                                  ...selectedOpportunityItems,
                                  opportunityItem,
                                ]);
                              } else {
                                const newSelectedOpportunityItems = [
                                  ...selectedOpportunityItems,
                                ];
                                const selectedIndex = selectedOpportunityItems.findIndex(
                                  (selectedOpportunityItem) =>
                                    selectedOpportunityItem.id! === id,
                                );
                                newSelectedOpportunityItems.splice(selectedIndex, 1);
                                setSelectedOpportunityItems(newSelectedOpportunityItems);
                              }
                            }}
                            checked={isCheckboxChecked}
                          />
                        </TableCell>
                        <TableCell>
                          {index + paging.pageSize * paging.pageNumber + 1}
                        </TableCell>
                        <TableCell>
                          {opportunityItem.opportunity!!.assignmentNumber}
                        </TableCell>
                        <TableCell>
                          {opportunityItem.opportunity!!.program!!.name}
                        </TableCell>
                        <TableCell>
                          {opportunityItem.opportunity!!.brand!!.name}
                        </TableCell>
                        <TableCell>{opportunityItem.block!!.blockNo}</TableCell>
                        <TableCell>{opportunityItem.block!!.item}</TableCell>
                        <TableCell>
                          {opportunityItem.sampleDevUser
                            ? opportunityItem.sampleDevUser!.fullName
                            : ''}
                        </TableCell>
                        <TableCell>
                          {opportunityItem.npdUser
                            ? opportunityItem.npdUser!.fullName
                            : ''}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableLoading>
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[...TablePageSize]}
              component="div"
              count={totalItems}
              rowsPerPage={paging.pageSize}
              page={paging.pageNumber}
              onPageChange={handleChangePageNumber}
              onRowsPerPageChange={handleChangePageSize}
            />
          </DialogContent>
          <DialogActions style={{ marginRight: '5px', marginBottom: '10px' }}>
            <Button
              onClick={handleClose}
              color="default"
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setStep('confirmAssignmentItem');
              }}
              color="primary"
              variant="contained"
              startIcon={<NextIcon />}
            >
              Next
            </Button>
          </DialogActions>
        </>
      )}
      {step === 'confirmAssignmentItem' && (
        <>
          <DialogTitle>Confirm Assignment Item</DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Assignment Number</TableCell>
                  <TableCell>Program</TableCell>
                  <TableCell>Brand</TableCell>
                  <TableCell>Block No</TableCell>
                  <TableCell>Item</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedOpportunityItems.map((selectedOpportunityItem, index) => {
                  return (
                    <TableRow key={selectedOpportunityItem.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {selectedOpportunityItem.opportunity!!.assignmentNumber}
                      </TableCell>
                      <TableCell>
                        {selectedOpportunityItem.opportunity!!.program!!.name}
                      </TableCell>
                      <TableCell>
                        {selectedOpportunityItem.opportunity!!.brand!!.name}
                      </TableCell>
                      <TableCell>{selectedOpportunityItem.block!!.blockNo}</TableCell>
                      <TableCell>{selectedOpportunityItem.block!!.item}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions style={{ marginRight: '5px', marginBottom: '10px' }}>
            <Button
              onClick={() => {
                setStep('selectAssignmentItem');
              }}
              color="default"
              variant="contained"
              startIcon={<PreviousIcon />}
            >
              Back
            </Button>
            <Button
              onClick={() => {
                const taskItems = selectedOpportunityItems.map(
                  (selectedOpportunityItem) => ({
                    smTaskId: taskId,
                    opportunityItemId: selectedOpportunityItem.id,
                    opportunityItem: selectedOpportunityItem,
                  }),
                );
                dispatch(createTaskItems(taskItems));
                setStep('createdAssignmentItem');
              }}
              color="primary"
              variant="contained"
              startIcon={<DoneIcon />}
            >
              Confirm
            </Button>
          </DialogActions>
        </>
      )}
      {step === 'createdAssignmentItem' && (
        <>
          <DialogTitle>Created Assignment Item</DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Assignment Number</TableCell>
                  <TableCell>Program</TableCell>
                  <TableCell>Brand</TableCell>
                  <TableCell>Block No</TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {createdItems.map((createdItem, index) => {
                  return (
                    <TableRow key={createdItem.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {createdItem.opportunityItem!.opportunity!.assignmentNumber}
                      </TableCell>
                      <TableCell>
                        {createdItem.opportunityItem!.opportunity!.program!.name}
                      </TableCell>
                      <TableCell>
                        {createdItem.opportunityItem!.opportunity!.brand!.name}
                      </TableCell>
                      <TableCell>{createdItem.opportunityItem!.block!.blockNo}</TableCell>
                      <TableCell>{createdItem.opportunityItem!.block!.item}</TableCell>
                      <TableCell>{createdItem.status}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions style={{ marginRight: '5px', marginBottom: '10px' }}>
            <Button
              onClick={() => {
                handleClose();
                setStep('selectAssignmentItem');
              }}
              color="primary"
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default TaskItemCreateDialog;
