export type TaskStatusType = 'PENDING' | 'IN_PROGRESS' | 'DONE';

const TaskStatus = Object.freeze({
  PENDING: 'PENDING' as TaskStatusType,
  IN_PROGRESS: 'IN_PROGRESS' as TaskStatusType,
  DONE: 'DONE' as TaskStatusType
});

export default TaskStatus;

export const TaskStatusLabel = Object.freeze({
  [TaskStatus.PENDING]: 'Pending',
  [TaskStatus.IN_PROGRESS]: 'In Progress',
  [TaskStatus.DONE]: 'Done'
});
