import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  filterInput: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 'auto'
  },
  iconButton: {
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
