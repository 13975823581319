import { FAILURE, REQUEST, SUCCESS } from '../../utils/actionTypeUtil';
import { AppAction } from '../app';
import {
  CLEAR_RESPONSIBILITY,
  CREATE_RESPONSIBILITY,
  FETCH_RESPONSIBILITY_LIST,
  UPDATE_RESPONSIBILITY,
  FETCH_RESPONSIBILITY,
  SUBMIT_FOR_APPROVAL_RESPONSIBILITY,
  SUBMIT_FOR_UNLOCK_APPROVED_RESPONSIBILITY,
} from './responsibility.actions';
import { ResponsibilityState } from './responsibility.types';
import { IResponsibility } from '../../models/responsibility.model';

const getInitialResponsibilityState = (): Readonly<IResponsibility> => ({});

const initialState: ResponsibilityState = {
  errorMessage: '',
  item: getInitialResponsibilityState(),
  items: [] as ReadonlyArray<IResponsibility>,
  loading: false,
  totalItems: 0,
};

export default (
  state: ResponsibilityState = initialState,
  action: AppAction,
): ResponsibilityState => {
  switch (action.type) {
    case REQUEST(FETCH_RESPONSIBILITY_LIST):
    case REQUEST(CREATE_RESPONSIBILITY):
    case REQUEST(UPDATE_RESPONSIBILITY):
    case REQUEST(FETCH_RESPONSIBILITY):
    case REQUEST(SUBMIT_FOR_APPROVAL_RESPONSIBILITY):
    case REQUEST(SUBMIT_FOR_UNLOCK_APPROVED_RESPONSIBILITY):
      return { ...state, loading: true };
    case SUCCESS(FETCH_RESPONSIBILITY_LIST):
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        totalItems: +action.payload.headers['x-total-count'],
      };
    case FAILURE(FETCH_RESPONSIBILITY_LIST):
    case FAILURE(CREATE_RESPONSIBILITY):
    case FAILURE(UPDATE_RESPONSIBILITY):
    case FAILURE(FETCH_RESPONSIBILITY):
    case FAILURE(SUBMIT_FOR_APPROVAL_RESPONSIBILITY):
    case FAILURE(SUBMIT_FOR_UNLOCK_APPROVED_RESPONSIBILITY):
      return { ...state, loading: false, errorMessage: action.payload.errorMessage };
    case SUCCESS(CREATE_RESPONSIBILITY):
    case SUCCESS(FETCH_RESPONSIBILITY_LIST):
    case SUCCESS(UPDATE_RESPONSIBILITY):
    case SUCCESS(FETCH_RESPONSIBILITY):
    case SUCCESS(SUBMIT_FOR_APPROVAL_RESPONSIBILITY):
    case SUCCESS(SUBMIT_FOR_UNLOCK_APPROVED_RESPONSIBILITY):
      return { ...state, loading: false, item: action.payload.data };
    case CLEAR_RESPONSIBILITY:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
