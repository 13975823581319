import { IOpportunityCustomer } from '../../models/opportunityCustomer.model';
import { AppAction, AppCallback, PageFilter } from '../app';
import { OpportunityCustomerListFilter } from './opportunityCustomer.types';

export const CREATE_OPPORTUNITY_CUSTOMER = 'CREATE_OPPORTUNITY_CUSTOMER';
export const CREATE_OPPORTUNITY_CUSTOMERS = 'CREATE_OPPORTUNITY_CUSTOMERS';
export const FETCH_OPPORTUNITY_CUSTOMER_LIST = 'FETCH_OPPORTUNITY_CUSTOMER_LIST';
export const CLEAR_OPPORTUNITY_CUSTOMER = 'CLEAR_OPPORTUNITY_CUSTOMER';
export const DELETE_OPPORTUNITY_CUSTOMER = 'DELETE_OPPORTUNITY_CUSTOMER';
export const UPDATE_OPPORTUNITY_CUSTOMER = 'UPDATE_OPPORTUNITY_CUSTOMER';
export const FETCH_OPPORTUNITY_CUSTOMER_INCLUDE_MEMBER_LIST =
  'FETCH_OPPORTUNITY_CUSTOMER_INCLUDE_MEMBER_LIST';

export const createOpportunityCustomer = (payload: any): AppAction => ({
  type: CREATE_OPPORTUNITY_CUSTOMER,
  payload,
});

export const createOpportunityCustomers = (
  payload: any,
  callback?: AppCallback,
): AppAction => ({
  type: CREATE_OPPORTUNITY_CUSTOMERS,
  payload,
  callback,
});

export const fetchOpportunityCustomerList = (
  filters: OpportunityCustomerListFilter,
  paging: PageFilter,
): AppAction => ({
  type: FETCH_OPPORTUNITY_CUSTOMER_LIST,
  payload: {
    filters,
    paging,
  },
});

export const fetchOpportunityCustomerListByOpportunityId = (
  opportunityId: number,
): AppAction => ({
  type: FETCH_OPPORTUNITY_CUSTOMER_LIST,
  payload: {
    filters: { 'OpportunityId.equalsTo': opportunityId },
    paging: { pageNumber: 0, pageSize: 99999 },
  },
});

export const featOpporutnityCustomerIncludeMember = (payload: {
  opportunityId: number;
}) => ({
  type: FETCH_OPPORTUNITY_CUSTOMER_INCLUDE_MEMBER_LIST,
  payload,
});

export const clearOpportunityCustomer = (): AppAction => ({
  type: CLEAR_OPPORTUNITY_CUSTOMER,
});

export const deleteOpportunityCustomer = (payload: {
  id: number;
  opportunityId: number;
}): AppAction => ({
  type: DELETE_OPPORTUNITY_CUSTOMER,
  payload,
});

export const updateOpportunityCustomer = (payload: IOpportunityCustomer): AppAction => ({
  type: UPDATE_OPPORTUNITY_CUSTOMER,
  payload,
});
