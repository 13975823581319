import React, { useMemo } from 'react';
import { ISubstrate } from '../../models/substrate.model';
import { fetchSubstrateList as fetchSubstrateListService } from '../../services/substrateService';
import AsyncAutoComplete from './AsyncAutoComplete';
import { useSelector } from 'react-redux';
import { AppState } from '../../state/configureStore';
import { TaskItemState } from '../../state/taskItem';

type SubstrateComboBoxProps =
  | {
      isMulti: true;
      handleChange: (result: ISubstrate[]) => void;
      selectedValue: ISubstrate[];
      isDisabled?: boolean;
      error?: boolean;
      helperText?: string;
      label?: string;
    }
  | {
      isMulti?: false;
      handleChange: (result: ISubstrate) => void;
      selectedValue: ISubstrate;
      isDisabled?: boolean;
      error?: boolean;
      helperText?: string;
      label?: string;
    };

const SubstrateComboBox: React.FC<SubstrateComboBoxProps> = props => {
  const {
    handleChange,
    selectedValue,
    isDisabled = false,
    isMulti = false,
    error,
    helperText,
    label = 'Substrate',
  } = props;

  const { items: taskItems } = useSelector<AppState, TaskItemState>(t => t.taskItem);
  const substrateCompanyName = useMemo(() => {
    if (taskItems.length > 0) {
      const companyName = taskItems[0].opportunityItem!.opportunity!.company!.name;
      if (companyName === 'TAP') {
        return 'TAP';
      }
    }
    return 'WE-TH';
  }, [taskItems]);

  const substratePromiseOptions = async (inputValue: string) => {
    const { data: rows } = await fetchSubstrateListService({
      filters: {
        'PartNum.StartsWith': inputValue,
        // 'Company.EqualsTo': substrateCompanyName,
      },
      paging: { pageNumber: 0, pageSize: 10 },
    });

    return rows;
  };

  return (
    <AsyncAutoComplete
      name="Substrate"
      value={selectedValue}
      getOptionValue={(option: ISubstrate) => {
        return `${option.partNum!} ${option.partDescription}`;
      }}
      getOptionLabel={(option: ISubstrate) => {
        return `${option.partNum!} ${option.partDescription}`;
      }}
      promiseOptions={substratePromiseOptions}
      placeholder={'Search by substrate name'}
      handleChange={handleChange}
      isDisabled={isDisabled}
      isMulti={isMulti}
      error={error}
      helperText={helperText}
      label={label}
    />
  );
};

export default SubstrateComboBox;
