import { PageFilter } from '../state/app';
import { Brand, BrandListFilter } from '../state/brand';
import axios from '../utils/axios';

const baseUrl = `/api/brands`;

export const fetchBrandList = async ({
  filters,
  paging,
}: {
  filters: BrandListFilter;
  paging: PageFilter;
}) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  params.append('pageSize', paging.pageSize.toString());
  params.append('pageNumber', paging.pageNumber.toString());

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchBrand = async (id: Brand['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const createBrand = async (brand: Brand) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, brand);
};

export const updateBrand = async (brand: Brand) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, brand);
};
